(function () {

	'use strict';

	angular
		.module('portalApp')
		.controller('ReportsCtrl', ReportsController);

	/**
	 * @ngInject
	 */
	function ReportsController(reportsService, $log, $scope, appI18n, configurationService, $q, appConfig) {

		$log = $log.getInstance('ReportsCtrl', 'color:blue');
		$log.debug('loaded');

		const vm = this;

		vm.title = '';
		activate();

		function activate() {
			loadSiteMapName();
			reportsService.registerHandler(vm);
			$scope.$on('$destroy', () => {
				reportsService.registerHandler();
			});
			appI18n.translate([
				'reports.create-report.title',
				'reports.my-reports.title',
				'reports.training-document.main-title',
				'reports.ecg-export.main-title',
				'reports.report-review.main-title',
				'reports.abpm-reports.title',
				'reports.holter-reports.title',
				'reports.ecg-source-tracings.title',
				'reports.data-status-forms.title'
			])
				.then((translations) => {
					vm.abpmReportsTab = translations['reports.abpm-reports.title'];
					vm.holterReportsTitle = translations['reports.holter-reports.title'];
					vm.ecgSourceTracingsTitle = translations['reports.ecg-source-tracings.title'];
					vm.dataStatusFormsTitle = translations['reports.data-status-forms.title'];
					vm.createTab = translations['reports.create-report.title'];
					vm.myReportTab = translations['reports.my-reports.title'];
					vm.trainingTab = translations['reports.training-document.main-title'];
					vm.ecgTab = translations['reports.ecg-export.main-title'];
					vm.reportReviewTab = translations['reports.report-review.main-title'];

					const defers = $q.all([
						configurationService.getStudySettings(),
						reportsService.getPermission(),
						configurationService.getUserStudySettings(),

					]);
					const availableTabs = [{
						displayName: vm.createTab,
						trackId: appConfig.trackId.reportsCreateReport,
						ref: appConfig.routes.createReport
					}, {
						displayName: vm.myReportTab,
						trackId: appConfig.trackId.reportsMyReports,
						ref: appConfig.routes.myReports
					}];

					defers.then((resolves) => {
						vm.studyConfig = resolves[0];

						if (resolves[2].data.showReportReview) {
							availableTabs.push({
								displayName: vm.reportReviewTab,
								trackId: appConfig.trackId.reportsReportReview,
								ref: appConfig.routes.reportReview
							});
						}
						if (vm.studyConfig.usesTrainingMode) {
							availableTabs.push({
								displayName: vm.trainingTab,
								trackId: appConfig.trackId.reportsReportsTraining,
								ref: appConfig.routes.reportsTraining
							});
						}
						// show ABPM tab if the study uses Ambulo2400 or if no Microlife and SpaceLabs devices are configured
						if (vm.studyConfig.hasAbpm &&
							(vm.studyConfig.hasAmbulo2400 || !(vm.studyConfig.hasAbpmMicrolife || vm.studyConfig.hasAbpmSpaceLabs))) {
							availableTabs.push({
								displayName: vm.abpmReportsTab,
								ref: appConfig.routes.reports.abpm,
								trackId: appConfig.trackId.clinicalDataAbpmReports
							});
						}
						if (vm.studyConfig.hasHolter) {
							availableTabs.push({
								displayName: vm.holterReportsTitle,
								ref: appConfig.routes.reports.holter,
								trackId: appConfig.trackId.clinicalDataHolterReports
							});
						}
						if (vm.studyConfig.hasEcg) {
							availableTabs.push({
								displayName: vm.ecgSourceTracingsTitle,
								ref: appConfig.routes.reports.ecgSourceTracing,
								trackId: appConfig.trackId.clinicalDataEcgSourceTracing
							});
						}
						if (vm.studyConfig.hasDataStatusForms) {
							availableTabs.push({
								displayName: vm.dataStatusFormsTitle,
								ref: appConfig.routes.reports.dataStatusForm,
								trackId: appConfig.trackId.clinicalDataStatusForms
							});
						}
						if (resolves[2].data.showEcgDataExport && resolves[1].data) {
							availableTabs.push({
								displayName: vm.ecgTab,
								trackId: appConfig.trackId.reportsEcgDataExport,
								ref: appConfig.routes.ecgDataExport
							});
							vm.actions = [{
								displayName: vm.ecgTab,
								action: vm.openExportECG,
								visibleIn: [appConfig.routes.ecgDataExport],
								cssClass: '-es-primary',
								iconClass: 'es-icon-reports'
							}];
						}
						vm.tabs = availableTabs;
					});
				});
		}

		function loadSiteMapName() {
			configurationService.getSiteMapLabel(appConfig.siteMapModuleIds.reports).then(
				function (label) {
					vm.moduleLabel = label;
				}
			);
		}

		vm.getTitle = function () {
			return vm.title;
		};

		vm.setTitle = function (title) {
			vm.title = title;
		};

		vm.openExportECG = function () {
			reportsService.showOpenExportECG();
		};


	}

})();
