class CustomPopupController {
	constructor(popupService, appI18n) {
		'ngInject';
		this.srv = popupService;
		this.appI18n = appI18n;
		this.popupName = 'custom';
	}

	$onInit() {
		this.$API = this.srv.register(this.popupName);
		this.setButtons();
	}

	$onDestroy() {
		this.srv.unregister(this.popupName);
	}

	setButtons() {
		const self = this;

		const submitPopupBtn = {
			action: () => {
				self.$API.hide({ answer: 'ok' });
			},
			displayName: this.appI18n.translateImmediate('app.buttons.ok'),
			cssClass: '-es-primary', disabled: false
		};

		this.btns = [submitPopupBtn];
	}
}

export default CustomPopupController;
