/*
 * iSpiroAuditReportPopupController
 * */
class ISpiroAuditReportPopupController {

	constructor(
		$log, $state, loadingIndicatorService,
		appI18n, appConfig, $stateParams, $q,
		iSpiroService, ertBasePopupService, notificationService) {
		'ngInject';

		this.$log = $log;
		this.iSpiroService = iSpiroService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.ertBasePopupService = ertBasePopupService;
		this.notificationService = notificationService;
		this.iSpiroService = iSpiroService;
		this.appI18n = appI18n;
		this.appConfig = appConfig;
		this.$q = $q;
		this.$state = $state;

		this.isLoading = false;
		this.$API = null;
		this.errors = {
			genericError: false
		};
	}

	$onInit() {
		this.$log = this.$log.getInstance('ISpiroAuditReportPopupController');
		this.$log.debug('loaded');

		this.popupHeading = 'clinicaldata.subject.i-spiro.auditReportPopupTitle';

		this.$API = this.setApi();
		this.ertBasePopupService.register('iSpiroAuditReportPopup', this.$API);
		this.componentReady && this.componentReady({
			$API: this.$API
		});
	}

	initButtons() {
		this.submitBtn = {
			action: () => {
				this.doSubmit();
			},
			cssClass: '-es-primary',
			displayName: 'app.buttons.submit',
			isDisabled: false
		};
		this.cancelBtn = {
			action: () => {
				this.cancelAction();
			},
			displayName: 'app.buttons.cancel',
			isDisabled: false
		};


		this.actions = [this.submitBtn, this.cancelBtn];
	}

	initSiteControl() {
		this.selectedSite = null;
		this.sitesOptions = {
			label: 'app.common.siteId',
			disabled: false,
			id: 'sitesDropdown',
			required: false,
			placeholder: 'clinicaldata.subject.create.selectSite',
			uiValue: 'displaySiteName'
		};
	}

	initDownloadTypeControl() {
		const self = this;

		this.downloadOptionType = 1;
		this.downloadTypeRadioGroupCompReady = (api) => {
			self.radioGroupApi = api;
			if (self.selectedSite) {
				self.radioGroupApi.setValue(3);
			} else {
				self.radioGroupApi.setValue(1);
			}
		};
		this.downloadTypeRadioGroupOptions = {
			required: true,
			default: 1,
			inline: false,
			label: this.translations['reports.report-review.popup.downloadType']
		};
		this.downloadTypeRadioButtonOptions = [{
			id: 'allSitesOneFile',
			label: this.translations['clinicaldata.subject.i-spiro.auditReport.studyOrSiteStudy'],

			value: 1
		}, {
			id: 'allSitesMultipleFiles',
			label: this.translations['clinicaldata.subject.i-spiro.auditReport.studyOrSiteStudyZipped'],
			value: 2
		}, {
			id: 'singleSite',
			label: this.translations['clinicaldata.subject.i-spiro.auditReport.studyOrSiteSite'],
			value: 3
		}];
		this.downloadTypeRadioGroupChange = (newValue) => {
			self.downloadOptionType = newValue;
			const isSingleSiteOption = newValue === 3;

			self.sitesOptions.required = isSingleSiteOption;
			self.sitesOptions.disabled = !isSingleSiteOption;
		};
	}

	doSubmit() {
		this.iSpiroAuditReportForm.showValidation = true;
		if (this.iSpiroAuditReportForm.$invalid) {
			return;
		}

		this.loadingIndicatorService.show();
		this.iSpiroService.generateAuditReport(
			this.downloadOptionType === 2,
			this.downloadOptionType === 3 ? this.selectedSite.siteId : null)
			.then(() => {
				this.notificationService.showSuccess(
					supplant(
						this.appI18n.translateImmediate('clinicaldata.subject.i-spiro.auditReport.successMessage'),
						[this.$state.href(this.appConfig.routes.downloads)])

				);
				this.cancelAction();
			}, () => {
				this.errors.genericError = true;
			}).finally(() => {
				this.loadingIndicatorService.hide();
			});
	}

	setApi() {
		const $API = {
			open: (countryId, siteId) => {
				this.errors.genericError = false;
				this.loadingIndicatorService.show();
				this.onPopupShow().then(() => {
					siteId && this.preselectSite(siteId.toString());
					this.open = true;
				}).finally(() => {
					this.loadingIndicatorService.hide();
				});

			},
			close: () => {
				this.sites = null;
				this.preselectedSiteId = null;
				this.sitesOptions.disabled = false;
				this.selectedSite = null;
				this.open = false;
			}
		};

		return $API;
	}

	onPopupShow() {
		return this.$q.all([
			this.iSpiroService.getSitesMinimal().then((res) => {
				this.sites = res.data;
			}),
			this.appI18n.translate(['clinicaldata.subject.buttons.create',
				'reports.report-review.popup.downloadType',
				'clinicaldata.subject.i-spiro.auditReport.studyOrSiteSite',
				'clinicaldata.subject.i-spiro.auditReport.studyOrSiteStudy',
				'clinicaldata.subject.i-spiro.auditReport.studyOrSiteStudyZipped'
			]).then(
				(res) => {
					this.translations = res;

					this.initSiteControl();
					this.initDownloadTypeControl();
					this.initButtons();
				}
			)
		]);
	}

	cancelAction() {
		this.$API.close();
	}

	onSiteChange(newVal) {
		this.selectedSite = newVal;
	}

	preselectSite(siteId) {
		this.preselectedSiteId = siteId;
		const siteToSelect = this.sites.find((s) => {
			return s.siteId === siteId;
		});

		this.selectedSite = siteToSelect || null;
		if (this.selectedSite) {
			this.onSiteChange(this.selectedSite);
		}
	}
}
export default ISpiroAuditReportPopupController;
