/*
 * esFormLegendComponent
 * */

import templateUrl from './esFormLegend.html';
import controller from './esFormLegend.controller';
import './esFormLegend.scss';

const esFormLegendComponent = {
	bindings: {
		text: '@?'
	},
	templateUrl,
	controller
};

export default esFormLegendComponent;
