/*
 * globalMessageService
 * */
let globalMessageApi;

export default class globalMessageService {

	static id() {
		return 'globalMessageService';
	}

	constructor($log) {
		'ngInject';
		$log = $log.getInstance('globalMessageService', 'color:green');
		this.register = this._register;
		this.unregister = this._unregister;
		this.show = this._show;
		this.hide = this._hide;
		this.isActive = this._isActive;
	}

	_show(headingTxt, subHeadingTxt) {
		globalMessageApi && globalMessageApi.show(headingTxt, subHeadingTxt);
	}

	_hide() {
		globalMessageApi && globalMessageApi.hide();
	}

	_isActive() {
		return globalMessageApi && globalMessageApi.isActive();
	}

	_register($API) {
		globalMessageApi = $API;
	}

	_unregister() {
		globalMessageApi = undefined;
	}
}
