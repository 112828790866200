/*
 * editRolesPopupController
 * */

const ACCESS_LEVELS = {
	STUDY: 'PTL',
	COUNTRY: 'CRY',
	SITE: 'SITE'
};

class EditRolesPopupController {
	constructor($log, $stateParams, $state, appConfig, loadingIndicatorService,
				appI18n, studyPermissionsService, notificationService, signatureService, $timeout) {
		'ngInject';
		this.$log = $log;
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.loadingIndicatorService = loadingIndicatorService;
		this.studyPermissionsService = studyPermissionsService;
		this.notificationService = notificationService;
		this.signatureService = signatureService;
		this.$timeout = $timeout;

		this.options = {};
		this.user = {};
		this.userRolesApi = {};
		this.rolesToAdd = [];
		this.rolesToRemove = [];
		this.open = false;
	}

	$onInit() {
		this.$log = this.$log.getInstance('EditRolesPopupController');
		this.$log.debug('loaded');

		this.loadingIndicatorService.show();

		this.signatureService.checkSignatureWorkflowAsync(this.$stateParams.signKey, this.$stateParams.tokenId)
			.then(() => {
				return this.studyPermissionsService.getUser(
					this.$stateParams.siteId, this.$stateParams.userId,
					this.$stateParams.isSiteAdmin, this.$stateParams.countryId);
			}).then((user) => {
				this.setActions();

				this.setUserRolesData(user);
				this.user = this.formatUserData(user);
				this.openPopup();
				this.loadingIndicatorService.hide();
			}, (error) => {
				if (error.signature && !error.signature.valid) {
					return this.onSignatureCancel(error.signature.state);
				} else if (error.signature && error.signature.valid) {
					return this.doSubmit(error.signature.state);
				}
				this.notificationService.showError(this.appI18n.translateImmediate('app.toaster.tryLaterError'));
				this.cancelAction();
			}).finally(() => {
				this.loadingIndicatorService.hide();
			});
	}

	setUserRolesApi($API) {
		this.userRolesApi = $API;
	}

	cancelAction() {
		this.open = false;
		this.$state.go('^');
	}

	openPopup() {
		this.$timeout(() => {
			this.open = true;
		});
	}

	setActions() {
		this.signSubmitBtn = {
			action: () => {
				this.initiateSignature();
			},
			cssClass: '-es-primary',
			displayName: 'app.buttons.sign-submit',
			isDisabled: true
		};

		this.cancelBtn = {
			displayName: 'app.buttons.cancel',
			action: () => {
				this.cancelAction();
			}
		};
		this.actions = [
			this.signSubmitBtn,
			this.cancelBtn
		];
	}

	onRoleChange(rolesToAdd, rolesToRemove) {
		this.rolesToAdd = rolesToAdd;
		this.rolesToRemove = rolesToRemove;
		this.signSubmitBtn.isDisabled = (!rolesToAdd.length && !rolesToRemove.length);
	}

	formatUserData(user) {
		let constraint = '';

		switch (user.accessLevel) {
			case ACCESS_LEVELS.STUDY: {
				constraint = 'study';
				break;
			}
			case ACCESS_LEVELS.COUNTRY: {
				constraint = 'country {countryName}'.supplant(user);
				break;
			}
			case ACCESS_LEVELS.SITE: {
				const sitePI = user.siteInvestigatorCountry.split('|');

				constraint = 'site {0} ({1})'.supplant(sitePI);
				break;
			}
		}

		return {
			userId: user.userId,
			fullName: user.fullName,
			personEmail: user.personEmail,
			accessLevelMsg: constraint,
			accessLevel: user.accessLevel,
			protocolId: user.protocolId,
			countryId: user.countryId,
			siteId: user.siteId,
			personId: user.personId
		};
	}

	setUserRolesData(user) {
		this.userCurrentRoles = user.assignedRoles || [];
		this.availableRoles = user.rolesToAdd || [];
	}

	initiateSignature() {

		const sigType = this.appConfig.sigType.editRoles;

		// save state for signature process
		const state = {
			user: this.user,
			userCurrentRoles: this.userCurrentRoles,
			availableRoles: this.availableRoles,
			rolesToAdd: this.rolesToAdd,
			rolesToRemove: this.rolesToRemove,

			userRolesComponentState: this.userRolesApi.getComponentState(),
			sigType: this.sigType
		};

		const additionalInfo = {
			action: this.appI18n.translateImmediate('signature.action.editUser'),
			infoKey1: this.appI18n.translateImmediate('signature.info.site'),
			infoValue1: this.user.sponsorSiteId,
			infoKey2: this.appI18n.translateImmediate('signature.info.subject'),
			infoValue2: this.user.userId
		};

		this.signatureService.sign(sigType, additionalInfo, state);
	}

	onSignatureCancel(state) {
		this.user = state.user;
		this.userCurrentRoles = state.userCurrentRoles;
		this.availableRoles = state.availableRoles;
		this.setActions();
		this.userRolesState = state.userRolesComponentState;

		this.openPopup();
	}

	doSubmit(state) {
		const payload = this.getPayloadModel(state);

		// loading indicator is already shown
		return this.studyPermissionsService.editUserRoles(payload).then((/*response*/) => {
			this.notificationService.showSuccess(
				this.appI18n.translateImmediate('study-permissions.messages.changeRolesDelayMessage')
			);
		}, (error) => {
			// the error normally is handled on the interceptor level, so no need to show it here
			this.$log.error(error);
		}).finally(() => {
			this.$state.go('^');
		});
	}

	getPayloadModel(state) {
		const user = state.user;

		return {
			accessLevel: user.accessLevel,
			protocolId: user.protocolId,
			countryId: user.countryId,
			siteId: user.siteId,
			userId: user.personId,
			rolesAdd: state.rolesToAdd,
			rolesRemove: state.rolesToRemove
		};
	}
}

export default EditRolesPopupController;
