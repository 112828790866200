(function() {
	'use strict';

	angular
		.module('portalApp')
		.controller('StudySelectionController', StudySelectionController);

/**
 * @ngInject
 */
	function StudySelectionController($log, $state, $scope, $rootScope, appConfig, appSession, userService, studySelectService) {
		$log = $log.getInstance("StudySelectionController");
		$log.debug(" loaded");

		var vm = this;

		vm.showStudySelector = false;
		vm.appSession = appSession;
		vm.switchStudy = switchStudy;
		vm.openStudyConfiguration = openStudyConfiguration;
		vm.study = appSession.getStudy();
		vm.recentStudies = appSession.getRecentStudies();
		vm.openStudySelector = openStudySelector;
		vm.userIsInternal = null;
		vm.openItem = false;
		vm.toggleDropdown = function(){
			vm.openItem = !vm.openItem;
		}

		getUserIsInternal();

		$rootScope.$on(appConfig.broadcast.recentStudiesUpdated, onUpdateRecentStudies);
		$rootScope.$on(appConfig.broadcast.studySwitched, onUpdateRecentStudies);

		function onUpdateRecentStudies() {
			vm.study = appSession.getStudy();
			vm.recentStudies = appSession.getRecentStudies();
		}

		function getUserIsInternal() {
			userService.getUserIsInternal().then(function(isInternal) {
				vm.userIsInternal = isInternal;
			});
		}

		function switchStudy(study) {
			vm.appSession.switchStudy(study, false, true);
		}

		function openStudyConfiguration() {
			$state.go(appConfig.routes.studyConfiguration);
		}
		
		function openStudySelector() {
			studySelectService.openHeaderStudySelector();
		}
	}
})();
