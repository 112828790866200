(function () {

	'use strict';

	angular
		.module('portalApp')
		.controller('createSponsorUserWizardCtrl', Controller);

	/**
	 * @ngInject
	 */
	function Controller($scope, $log, studyPermissionsService, studyPermissionsTrans, $stateParams,
						WizardHandler, appI18n, appConfig, notificationService,
						loadingIndicatorService, signatureService, popupService) {
		var vm = this;

		$log = $log.getInstance('CreateSponsorUserWizardCtrl');
		$log.debug('CreateSponsorUserWizardCtrl loaded');

		vm.loadingError = '';
		vm.model = {};
		vm.options = { formState: {} };
		vm.forms = {};
		vm.actions = [];
		vm.localized = {};
		vm.isWizardOpen = false;
		vm.PopUpWithHelp = true;
		vm.cancel = cancel;
		vm.activate = activate;
		vm.checkSponsorUserInfo = checkSponsorUserInfo;
		vm.availableRoles = [];
		vm.rolesForHelp = [];
		vm.selectedCountries = [];
		vm.countryValidaion = {isValid: true};
		vm.accessTypeOptions = [];
		studyPermissionsService.registerCreateSponsorUserHandler(vm);
		$scope.$on('$destroy', () => {
			studyPermissionsService.registerCreateSponsorUserHandler();
		});
		$scope.$on('wizard:stepChanged', onStepChanged);

		function getFields() {
			return angular.copy({
				sponsorUserInfo: [
					{
						wrapper: 'twoColumn',
						fieldGroup: [
							{
								key: 'email',
								type: 'emailInline',
								templateOptions: {
									label: vm.localized.contact.email
								},
								ngModelElAttrs: {
									maxlength: '150'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.emailIsRequired"
								}
							},
							{
								key: 'repeatEmail',
								type: 'emailInline',
								templateOptions: {
									label: vm.localized.contact.repeatEmail
								},
								ngModelElAttrs: {
									maxlength: '150'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.emailIsRequired"
								},
								validators: {
									fieldMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											return value === vm.model.email;
										},
										message: '"{emailMatch}"'.supplant(vm.localized.messages)
									}
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'titleId',
								type: 'selectInline',
								templateOptions: {
									label: vm.localized.contact.title,
									options: []
								},
								controller: /* @ngInject */ function ($scope) {
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.titleIsRequired",
									"templateOptions.options": "formState.config.titles"
								}
							},
							{
								key: 'firstName',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.firstName
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.firstNameIsRequired"
								}
							},
							{
								key: 'lastName',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.lastName
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.lastNameIsRequired"
								}
							}
						]
					},
					{
						wrapper: 'twoColumn',
						fieldGroup: [
							{
								key: 'accessType',
								type: 'selectInline',
								templateOptions: {
									label: vm.localized.contact.accessTypes,
									options: [],
									onChange: function($viewValue) {
										setAccessLevelLbl($viewValue);
										resetSelectedRolesOnChangingAccessType($viewValue);
									}
								},
								controller: /* @ngInject */ function ($scope) {
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.accessTypeRequired",
									"templateOptions.options": "formState.config.accessTypeOptions",
									'templateOptions.disabled': 'formState.config.preselected && formState.config.accessTypeOptions.length === 1'
								}
							}
						]
					},
					{
						wrapper: 'twoColumn',
						fieldGroup: [
							{
								key: 'selectRolesStudyLevel',
								type: 'selectInline',
								templateOptions: {
									label: vm.localized.contact.sponsorRoles,
									options: vm.sponsorRolesStudyLevel,
									required: true
								},
								controller: /* @ngInject */ function ($scope) {
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.sponsorRolesRequired",
									'templateOptions.disabled': 'formState.config.preselected'
								},
								hideExpression: "model.accessType !== 'StudyLevel'"
							},
							{
								key: 'selectRolesCountryLevel',
								type: 'selectInline',
								templateOptions: {
									label: vm.localized.contact.sponsorRoles,
									options: vm.sponsorRolesCountryLevel,
									required: true
								},
								controller: /* @ngInject */ function ($scope) {
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.sponsorRolesRequired",
									'templateOptions.disabled': 'formState.config.preselected'
								},
								hideExpression: "model.accessType !== 'CountryLevel'"
							},
							{
								key: 'selectRoles',
								type: 'checkList',
								templateOptions: {
									label: vm.receiverRoles.length > 0 ? vm.localized.contact.receiverRoles : vm.localized.messages.noRolesAvailableMessage,
									options: vm.receiverRoles,
									required: false
								},
								controller: /* @ngInject */ function ($scope) {
								},
								hideExpression: "model.accessType !== 'CountryLevel' && model.accessType !== 'StudyLevel' || (model.accessType === 'CountryLevel') || (formState.config.preselected && formState.config.receiverRoles.length === 0)",
								expressionProperties: {
									'templateOptions.disabled': 'formState.config.preselected'
								}
							},
							{
								key:'country',
								type: 'esAutoComplete',
								wrapper: ['loading', 'validation'],
								templateOptions: {
									label: vm.localized.contact.country,
									options: []
								},
								controller: /* @ngInject */ function ($scope, sqfService) {
									$scope.to.loading = sqfService.getCountriesWithId().then(function (response) {
										const allowedCountries = filterCountries(response.data);
										$scope.to.options = prepareCountriesForSelection(allowedCountries);
										vm.options.formState.config.countries = allowedCountries;
										$scope.to.countryValidaion = vm.countryValidaion;

										if (vm.options.formState.config
											&& vm.options.formState.config.allowedCountryNames != null
											&& vm.options.formState.config.preselected) {
											vm.selectedCountries = allowedCountries.map(it => ({
												label: it.name,
												value: it.value,
												id: it.id,
												selected: true
											}));
										} else {
											vm.selectedCountries = [];
										}

										if (vm.selectedCountries.length > 0) {
											$scope.selectedItems = vm.selectedCountries;
										} else if (vm.state) {
											$scope.selectedItems = vm.state.selectedCountries;
										} else {
											$scope.selectedItems = []
										}
										$scope.prepareAutoCompleteChips();

										return response;
									}, function (data) {
										vm.loadingError = data;
									});
								},
								expressionProperties: {
									'templateOptions.customRequired': "formState.config.requiredFields.countryIsRequired",
									'templateOptions.disabled': 'formState.config.preselected'
								},
								hideExpression: "model.accessType !== 'CountryLevel'",
							}
						]
					}
				],
				sponsorUserConfirmation: [
					{
						wrapper: 'twoColumn',
						fieldGroup: [
							{
								template:
									'<p class="disclaimer">{{model.disclaimer}}</p>',
							},
							{
								template: `<div class="es-card -es-data-medium"><header class="es-card-header"><h4>User</h4></header>
									<div class="es-card-body"> <div class="es-content-box"> <table> <tbody>
									<tr><th style="width:48%">{{model.confirmationLabels.lastName}}</th><td>{{model.lastName}}</td></tr>
									<tr><th style="width:48%">{{model.confirmationLabels.firstName}}</th><td>{{model.firstName}}</td></tr>
									<tr><th style="width:48%">{{model.confirmationLabels.email}}</th><td>{{model.email}}</td></tr>
									</tbody></table></div></div></div>`
							},
							{
								template: `<div class="es-card -es-data-medium" style="margin-top:5px; min-height:145px !important;"><header class="es-card-header"><h4>Roles</h4></header>
									<div class="es-card-body"> <div class="es-content-box"><table><tbody>
									<tr><th style="width:48%">{{model.confirmationLabels.roles}}</th><td>{{model.selectedRolesLabel}}</td></tr>
									<tr ng-if="model.selectedReceiverRolesLabel && model.selectedReceiverRolesLabel.length > 0"><th style="width:48%">{{model.confirmationLabels.reportingNeeds}}</th><td>{{model.selectedReceiverRolesLabel}}</td></tr>
									</tbody></table></div></div></div>`
							},
							{
								key: 'confirmation',
								type: 'checkList',
								templateOptions: {
									options: vm.confirmation,
									required: true,
									customWidth: true
								},
								controller: /* @ngInject */ function ($scope) {
								},
								hideExpression: "model.accessType !== 'StudyLevel' && model.accessType !== 'CountryLevel'",
							}
						]
					},
					{
						wrapper: "twoColumn",
						fieldGroup: [
							{
								template:
									'<p class="disclaimer"></p>',
							},
							{
								template: `<div class="es-card -es-data-medium" style="margin-top:39px; min-height:129px !important;"><header class="es-card-header"><h4>{{model.confirmationLabels.accessType}}</h4></header>
									<div class="es-card-body"><div class="es-content-box"><table><tbody>
									<tr><td>{{model.selectedAccessLevel}}</td></tr></tbody></table></div></div></div>`
							},
							{
								template: `<div class="es-card -es-data-medium" style="margin-top:5px; min-height:145px !important;"><header class="es-card-header"><h4>Countries</h4></header>
									<div class="es-card-body"> <div class="es-content-box"><table><tbody>
									<tr><td>{{model.selectedCountriesLabel}}</td></tr>
									</tbody></table></div></div></div>`
							}
						]
					}
				]
			});
		}

		function resetSelectedRolesOnChangingAccessType(accessType) {
			vm.model.selectRolesCountryLevel = null;
			vm.model.selectRolesStudyLevel = null;

			if (isSponsor()) {
				let selectedReceiverRoles = Object.entries(vm.model.selectRoles);
				selectedReceiverRoles.forEach((id) => {
					if (id[1] === true) {
						vm.model.selectRoles[id[0]] = false;
					}
				});
			} else {
				preselectRoles(accessType);
			}
		}

		function setAccessLevelLbl(accessLevel) {
			if (accessLevel === 'StudyLevel') {
				vm.model.selectedAccessLevel = 'Study Level';
			} else if(accessLevel === 'CountryLevel') {
				vm.model.selectedAccessLevel =  'Country Level';
			} else {
				return '';
			}
		}

		function setSelectedCountriesLbl() {
			let selectedCountriesLbl = '';
			if (vm.model.selectedAccessLevel === 'Study Level') {
				selectedCountriesLbl = 'All Countries';
			} else if (vm.model.selectedAccessLevel === 'Country Level') {
				vm.model.selectedCountries.forEach((country) => {
					if(country.selected) {
						selectedCountriesLbl += country.label + ', ';
					}
				});
				selectedCountriesLbl = selectedCountriesLbl.slice(0, -2);
			}
			vm.model.selectedCountriesLabel = selectedCountriesLbl;
		}


		function setSelectedRolesLbl() {
			let selectedRolesLbl = '';
			let selectedReceiverRolesLbl = '';
			if (vm.model.selectedAccessLevel === 'Study Level') {
				let roleId = vm.model.selectRolesStudyLevel;
				selectedRolesLbl = getRoleName(roleId);
			} else if (vm.model.selectedAccessLevel === 'Country Level') {
				let roleId = vm.model.selectRolesCountryLevel;
				selectedRolesLbl = getRoleName(roleId);
			}

			let selectedReceiverRoles = Object.entries(vm.model.selectRoles);
				selectedReceiverRoles.forEach((id) => {
					if(id[1] === true) {
						selectedReceiverRolesLbl += getRoleName(id[0].toString()) + ', ';
					}
				});
			selectedReceiverRolesLbl = selectedReceiverRolesLbl.slice(0, -2);

			vm.model.selectedRolesLabel = selectedRolesLbl;
			vm.model.selectedReceiverRolesLabel = selectedReceiverRolesLbl;
		}

		function prepareConfirmation() {
			vm.confirmation = [];
			vm.confirmation.push({ name: appI18n.translateImmediate('study-permissions.messages.sponsorConfirmationMessage'),
			 value: appI18n.translateImmediate('study-permissions.messages.sponsorConfirmationMessage'), selected: false });
		}

		function prepareDisclaimer() {
			vm.model.disclaimer = appI18n.translateImmediate('study-permissions.messages.sponsorDisclaimerMessage')
		}

		function getRoleName(roleId) {
			let role = vm.availableRoles.find((r) => {
				return (r.value === roleId);
			});
			return role !== undefined ? role.name : '';
		}

		function wizard() {
			return WizardHandler.wizard("sponsorUserWizard");
		}

		function clear() {
			vm.model = {};
			vm.options = { formState: {} };
			vm.forms = {};
			vm.loadingError = '';
			vm.state = null;
			vm.userExists = false;
		}

		function onStepChanged(event, data) {
			if (data.step.wzTitle === vm.localized.wizardTitles.sponsorUserInfo) {
				setActions(1);
			} else if (data.step.wzTitle === vm.localized.wizardTitles.sponsorUserConfirmation) {
				setActions(2);
			}
		}

		function setActions(stepIndex, reset) {
			if (vm.currentStep !== stepIndex || reset) {
				vm.actions.length = 0;
				if (stepIndex === 1) {
					vm.actions.push({
						displayName: vm.localized.buttons.next,
						action: goToNextStep,
						iconName: '',
						cssClass: '-es-primary'
					});
					vm.actions.push({
						displayName: vm.localized.buttons.cancel,
						action: cancel,
						iconName: 'close',
						cssClass: 'btn-danger'
					});
				} else if (stepIndex === 2) {
					vm.actions.push({
						displayName: vm.localized.buttons['sign-submit'], action: saveSponsorUser, cssClass: '-es-primary'
					});

					vm.actions.push({
						displayName: vm.localized.buttons.back,
						action: goToPreviousStep,
						iconName: 'chevron-left'
					});
					vm.actions.push({
						displayName: vm.localized.buttons.cancel,
						action: cancel,
						iconName: 'close',
						cssClass: 'btn-danger'
					});
				}

				vm.currentStep = stepIndex;
			}
		}



		function closeWizard() {
			vm.isWizardOpen = false;
		}

		function cancel() {
			var isDirty = (vm.forms.sponsorUserInfo && vm.forms.sponsorUserInfo.$dirty) ||
				(vm.forms.sponsorUserAddressRoles && vm.forms.sponsorUserAddressRoles.$dirty);
			if (isDirty) {

				popupService.show('confirmation', {
					headerTitle: appI18n.translateImmediate('app.dialog.confirmTitle'),
					contentMsg: appI18n.translateImmediate('app.messages.unsavedChanges')
				}).then((resp) => {
					if (resp.answer === 'ok') {
						closeWizard();
					} else {
						// just close confirmation
					}
				}, (error) => {
					$log.error(`Error: ${error}`);
				});
			} else {
				closeWizard();
			}
		}



		function checkSponsorUserInfo() {
			if (!vm.forms.sponsorUserInfo.$valid) { return false; }
			vm.isLoading = true;

			return studyPermissionsService.checkSponsorUserAlreadyExists(vm.model.email)
				.then((userExists) => {
					vm.userExists = userExists;
					return !userExists;
				}).finally(() => {
					vm.isLoading = false;
				});
		}

		function getSelectedRoles() {
			var roles = [];
			let roleId = -1;
			if (vm.model.selectedAccessLevel === 'Study Level') {
				roleId = +(vm.model.selectRolesStudyLevel);
			} else if (vm.model.selectedAccessLevel === 'Country Level') {
				roleId = +(vm.model.selectRolesCountryLevel);
			}
			roles.push(roleId);
			let selectedReceiverRoles = Object.entries(vm.model.selectRoles);
			selectedReceiverRoles.forEach((id) => {
				if(id[1] === true) {
					roles.push(id[0]);
				}
			});

			return roles;
		}


		function getSelectedCountries() {
			var countries = [];
			if (vm.model.selectedAccessLevel !== 'Country Level' || !vm.model.selectedCountries) {
				return countries;
			}
			vm.model.selectedCountries.forEach((country) => {
				if(country.selected) {
					countries.push(+(country.id));
				}
			});
			return countries;
		}

		function goToPreviousStep() {
			wizard().previous();
		}
		function validateCountry() {
			if (vm.model.selectedAccessLevel !== 'Country Level') {
				return vm.countryValidaion.isValid = true;
			}
			if (!vm.model.selectedCountries || vm.model.selectedCountries.length === 0) {
				vm.countryValidaion.isValid = false;
			}
			if(vm.model.selectedCountries.length > 0) {
				vm.countryValidaion.isValid = true
			}
			return vm.countryValidaion.isValid;
		}
		function goToNextStep() {
			if (vm.currentStep === 1) {
				if(!validateCountry()) {
					vm.forms.sponsorUserInfo.$setSubmitted();
					return false;
				}
				if (!vm.forms.sponsorUserInfo.$valid) {
					vm.forms.sponsorUserInfo.$setSubmitted();
				} else {
					setSelectedCountriesLbl();
					setSelectedRolesLbl();
					wizard().next();
					return true;
				}
				return false;
			} else if (vm.currentStep === 2) {
				if (!vm.forms.sponsorUserConfirmation.$valid) {
					vm.forms.sponsorUserConfirmation.$setSubmitted();
					return false;
				}
				return true;
			}
		}

		function setupSignature() {
			vm.state = vm.model;
			vm.state.options = vm.options;
			vm.state.forms = vm.forms;
			vm.sigType = appConfig.sigType.createSponsorUser;
			vm.additionalInfo = {
				action: appI18n.translateImmediate('signature.action.createSponsorUser'),
				infoKey1: appI18n.translateImmediate('signature.info.userMail'),
				infoValue1: vm.model.email
			};
		}

		function saveSponsorUser() {
			if (!goToNextStep()) { return; }
			setupSignature();
			signatureService.sign(vm.sigType, vm.additionalInfo, vm.state);
		}

		function submitSponsorUser() {
			vm.model.roles = getSelectedRoles();
			vm.model.countries = getSelectedCountries();
			vm.model = vm.state;
			vm.model.signKey = $stateParams.signKey;
			vm.model.signToken = $stateParams.tokenId;
			studyPermissionsService.createSponsorUser(vm.model).then((response) => {
				if (response.data.isSuccessful) {
					signatureService.removeStateParams();
					notificationService.showSuccess(
						appI18n.translateImmediate('study-permissions.messages.createdSponsorUser'));
				} else {
					const error = response.data.messages;

					handleError(error);
				}
			}, (error) => {
				const msgs = [error.data.message || error.data];

				handleError(msgs);
			});
		}

		function prepareRolesForSelection() {
			vm.sponsorRolesCountryLevel = [{value: '', name: 'Select'}];
			vm.sponsorRolesStudyLevel = [{value: '', name: 'Select'}];
			vm.receiverRoles = [];
			vm.availableRoles.forEach((role) => {
				//for receiver roles
				if (role.name.includes('Receiver')) {	//for sponsor receiver roles
					vm.receiverRoles.push({ value: role.value, name: role.name });
				} else {
					//monitor roles should be available for selection at study level and country level
					if (role.name === 'Monitor' && role.hasCountryLevelAccess) {
						vm.sponsorRolesCountryLevel.push({ value: role.value, name: role.name });
					}
					if (role.hasStudyLevelAccess) {
						vm.sponsorRolesStudyLevel.push({ value: role.value, name: role.name });
					}
				}
			});
			vm.options.formState.config.receiverRoles = vm.receiverRoles;
		}

		function isSponsor() {
			return vm.availableRoles.some((role) => role.name === 'Sponsor Contact' || role.name === 'Primary Sponsor Contact');
		}

		function preselectAccessAndRoles() {
			if (!isSponsor()) {
				vm.options.formState.config.preselected = true;
				vm.model.accessType = vm.options.formState.config.accessTypeOptions[0].value;
				setAccessLevelLbl(vm.model.accessType);
				preselectRoles(vm.model.accessType);
			}
		}

		function preselectRoles(accessType) {
			if (accessType === 'StudyLevel') {
				if (vm.sponsorRolesStudyLevel.length > 0) {
					vm.model.selectRolesStudyLevel = vm.sponsorRolesStudyLevel[1].value;
				}
				vm.model.selectRoles = vm.receiverRoles.reduce((memo, item) => {
					memo[item.value] = true;
					return memo;
				}, {});
			} else {
				if (vm.sponsorRolesCountryLevel.length > 0) {
					vm.model.selectRolesCountryLevel = vm.sponsorRolesCountryLevel[1].value;
				}
			}
		}

		function prepareCountriesForSelection(countries) {
			let countriesForSelection = [];
			countries.forEach((country) => {
				if (country.value.length > 0) {
					countriesForSelection.push({
						id: country.id,
						label: country.name,
						value: country.value,
						selected: vm.options.formState.config && vm.options.formState.config.allowedCountryNames != null
					});
				}
			});
			return countriesForSelection;
		}

		function filterCountries(countries) {
			if (vm.options.formState.config
				&& vm.options.formState.config.allowedCountryNames != null
				&& vm.options.formState.config.preselected) {
				return countries.filter((country) => vm.options.formState.config.allowedCountryNames.includes(country.name));
			} else {
				return countries;
			}
		}

		function noRolesMessageWithCloseWizard() {
			popupService.show('info', {
				headerTitle: appI18n.translateImmediate('study-permissions.wizardTitles.noRoles'),
				contentMsg: appI18n.translateImmediate('study-permissions.messages.noRolesMessage')
			}).then((resp) => {
				if (resp.answer === 'ok') {
					closeWizard();
				}
			}, (error) => {
				$log.error(`Error: ${error}`);
				closeWizard();
			});
		}

		function prepareSponsorUserConfirmationLabels() {
			vm.model.confirmationLabels = {
				firstName: vm.localized.contact.firstName,
				lastName: vm.localized.contact.lastName,
				email: vm.localized.contact.email,
				accessType: vm.localized.contact.accessTypes,
				roles: vm.localized.contact.sponsorRoles,
				reportingNeeds: vm.localized.contact.reportingNeeds,
				countries: 'Countries'
			};
		}

		function prepareHelpContent() {
			vm.helpContent = [
				{
					key: vm.localized.contact.accessTypes,
					value: vm.accessTypeOptions.filter((accessType) => { return accessType.value !== '' ;})
				},
				{
					key: vm.localized.contact.sponsorRoles,
					value: vm.rolesForHelp.filter((role) => { return role.name.includes('Receiver') === false; })
				},
				{
					key: vm.localized.contact.receiverRoles,
					value: vm.rolesForHelp.filter((role) => { return role.name.includes('Receiver') === true; })
				}
			];
		}

		function activate() {
			clear();

			studyPermissionsTrans.getWizardLocalized().then((localized) => {
				loadingIndicatorService.show();
				vm.localized = localized;
				vm.modalTitle = vm.localized.wizardTitles.createSponsorUserWizard;

				studyPermissionsService.getSponsorConfig().then((response) => {
					vm.availableRoles = response.data.config.roles;
					vm.rolesForHelp = response.data.config.rolesForHelp;
					vm.accessTypeOptions = response.data.config.accessTypeOptions;
					/* Business Rule:
					 - If no roles available for selection, close the wizard
					 - If only receiver roles available, close the wizard
					*/
					if (vm.availableRoles && (vm.availableRoles.length === 0 || vm.availableRoles.filter(it => !it.name.includes('Receiver')).length === 0)) {
						noRolesMessageWithCloseWizard();
						return;
					}
					vm.model = response.data.model;
					vm.model.selectRoles = {};
					vm.options.formState.config = response.data.config;
					prepareRolesForSelection();
					preselectAccessAndRoles();
					prepareConfirmation();
					prepareHelpContent();
					prepareSponsorUserConfirmationLabels();
					prepareDisclaimer();
					vm.fields = getFields();
					if ($stateParams.signKey) {
						vm.state = signatureService.getState($stateParams.signKey);
						if (!vm.state || vm.state && vm.state.sigType !== appConfig.sigType.createSponsorUser) {
							vm.isWizardOpen = true;
							return;
						}
					}

					if ($stateParams.signKey) {
						const unbindState = $scope.$watch(() => {
							return !!vm.state;
						}, () => {
							vm.state = signatureService.getState($stateParams.signKey);
							if (vm.state) {
								signatureService.removeState($stateParams.signKey);

								vm.model = vm.state;
								vm.options = vm.state.options;
								unbindState();

								if ($stateParams.tokenId) {
									submitSponsorUser();
								}

							}
						});

						signatureService.validateSignature($stateParams.tokenId, $stateParams.signKey);

						if (!$stateParams.tokenId) {
							const unbind = $scope.$watch(() => {
								return !!wizard() && !!vm.state;
							}, () => {
								const wz = wizard();

								if (wz && vm.state) {
									wz.goTo(wz.totalStepCount() - 1);
									unbind();
								}
								vm.isWizardOpen = true;
							});
						}
						return;
					}

					vm.isWizardOpen = true;
				}).catch((error) => {
					handleError(error);
				}).finally(() => {
					loadingIndicatorService.hide();
				});
			});
		}

		function handleError(error) {
			notificationService.showError(appI18n.translateImmediate('app.toaster.tryLaterError'));
			$log.error(JSON.stringify(error));
		}
	}
})();
