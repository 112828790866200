import templateUrl from './myDownloads.html';
import controller from './myDownloads.controller';

const myDownloads = {
	templateUrl,
	controller,
	bindings: {
		viewConfig: '<?'
	}
};

export default myDownloads;
