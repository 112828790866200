import baseRowController from '../../../common/table/baseRow/baseRow.controller';

class TicketsTableController extends baseRowController {
	constructor($log) {
		'ngInject';
		super();
		this.$log = $log;
	}

	$onInit() {
		//this.$log = this.$log.getInstance('TicketsTableController');
		//this.$log.debug('loaded');
		this.registerRow(this.row);
	}
}

export default TicketsTableController;
