/*
 * iconLinkModule
 * */

import iconLinkComponent from './iconLink.component';

const iconLinkModule = angular.module('app.common.iconLink', [])
	.component('iconLink', iconLinkComponent)
	.name;

export default iconLinkModule;
