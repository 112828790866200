(function() {
	'use strict';

	angular
		.module('portalApp')
		.controller('SqfModalWizardCtrl', SqfModalWizardController);

	/**
	 * @ngInject
	 */
	function SqfModalWizardController($timeout, $log, $scope, sqfTranslation, $q, WizardHandler, sqfService,
									  loadingIndicatorService, $state, appConfig) {
		$log = $log.getInstance("SqfModalWizardCtrl");
		$log.debug(" loaded");
		var vm = this;
		sqfService.registerSqfWizardHandler(vm);

		vm.form = {};
		vm.sqfId = 0;
		vm.canEnter = canEnter;
		vm.loading = true;
		vm.showSqfWizard = showSqfWizard;
		vm.isWizardOpen = false;
		vm.retryCount = 0;
		vm.showSitePIStep = showSitePIStep;
		vm.pageTite = 'Site Qualification Wizard';
		vm.hideIndicators = $state.current.name !== appConfig.routes.sqfListSaved;

		$scope.shared = {
			newSqf: false,
			setActions: setActions,
			closeWizard: closeWizard,
			showGlobalLoading: showGlobalLoading,
			hideGlobalLoading: hideGlobalLoading,
			hasAdditionalSiteInfo: true,
			localized: {},
			yesNoAnswer: yesNoAnswer
		};
		vm.actions = [];

		function canEnter(wizard, stepIndex) {
			var currentIndex;
			if (wizard === 'sqfWizard') {
				return true;
			}
			else if (wizard === '1' || wizard === '2') {
				currentIndex = WizardHandler.wizard(wizard).currentStepNumber();
				if (stepIndex === currentIndex) {
					return false;
				}
			}
			return true;
		}

		function yesNoAnswer(answer) {
			if (answer === null || answer === '' || typeof answer === 'undefined') {
				return '';
			}

			return (answer > 0 || answer === true || answer === 'true') ? $scope.shared.localized.options.yes : $scope.shared.localized.options.no;
		}


		function showSqfWizard(sqfId, hasAdditionalSiteInfo, hasAdditionalSiteInfoAfterSelection) {
			$scope.shared.hideContactConfirmDialog = false;
			loadingIndicatorService.show();
			sqfTranslation.getWizardLocalized().then(function (localized) {
				$scope.shared.localized = localized;
				vm.retryCount = 0;
				vm.sqfId = sqfId;
				$scope.shared.hasAdditionalSiteInfo = hasAdditionalSiteInfo;
				$scope.shared.hasAdditionalSiteInfoAfterSelection = hasAdditionalSiteInfoAfterSelection;
				vm.isInitialLoading = true;
				vm.isWizardOpen = true;
				vm.pageTitle = localized.wizardTitles.sqfWizard + ' -' +  sqfService.getStudyName();
				if (vm.sqfId > 0) {
					$scope.shared.newSqf = false;
					showSummaryStep();
				}
				else {
					$scope.shared.newSqf = true;
					showSitePIStep();
				}
			});
		}

		function showSummaryStep() {
			vm.retryCount += 1;
			var mainWizard = wizard();
			if (mainWizard) {
				vm.retryCount = 0;
				var loadedPromise = sqfService.showSummaryView(vm.sqfId);
				if (loadedPromise) {
					loadedPromise.finally(() => {
						mainWizard.goTo($scope.shared.localized.wizardTitles.summary);
						loadingIndicatorService.hide();
						vm.isInitialLoading = false;
					});
				}
				else {
					loadingIndicatorService.hide();
					vm.isInitialLoading = false;
				};
			} else if (vm.retryCount <= 5) {
				$timeout(showSummaryStep, 300);
			}
		}

		function showSitePIStep() {
			vm.retryCount += 1;
			var mainWizard = wizard();
			if (mainWizard) {
				vm.retryCount = 0;
				var loadedPromise = sqfService.showSitePIWizard(vm.sqfId);
				if (loadedPromise) {
					loadedPromise.finally(() => {
						mainWizard.goTo($scope.shared.localized.wizardTitles.siteInfo);
						loadingIndicatorService.hide();
						vm.isInitialLoading = false;
					});
				}
				else {
					loadingIndicatorService.hide();
					vm.isInitialLoading = false;
				};
			} else if (vm.retryCount <= 5) {
				$timeout(showSitePIStep, 300);
			}
		}

		function setActions(actions) {
			vm.actions = actions;
		}

		function wizard() {
			return WizardHandler.wizard('sqfWizard');
		}

		function closeWizard() {
			vm.isWizardOpen = false;
		}

		function showGlobalLoading() {
			loadingIndicatorService.show();
		}

		function hideGlobalLoading() {
			loadingIndicatorService.hide();
		}
	}
})();
