(function () {

	'use strict';

	angular
		.module('portalApp')
		.service('documentService', DocumentService);

	/**
	 * @ngInject
	 */

	function DocumentService($http, $log, appConfig, appSession, $cookies, appI18n,
							 $window, $location, $interval, popupService, analyticsService) {

		$log = $log.getInstance("DocumentService", "color:purple");
		$log.debug("loaded");

		var documentService = {
			getReturnRequestReport: getReturnRequestReport,
			getMyReports: getMyReports,
			getTrainingDocuments: getTrainingDocuments,
			getDataStatusFormsReport: getDataStatusFormsReport,
			getEcgSourceTracingReport: getEcgSourceTracingReport,
			getAbpmReport: getAbpmReport,
			getHolterReport: getHolterReport,
			getEventReport: getEventReport,
			getEventReportAcrossStudies: getEventReportAcrossStudies,
			getEproAlertUrl: getEproAlertUrl,
			getEventReportsForEventIds: getEventReportsForEventIds,
			getEventReportsForTypes: getEventReportsForTypes,
			downloadReport: downloadReport,
			openDownloadReport: openDownloadReport,
			getDownloadReportUrl: getDownloadReportUrl,
			getDownloadReportData: getDownloadReportData,
			getDownloadReportNames: getDownloadReportNames,
			registerDownloadErrorHandler: registerDownloadErrorHandler,
			getFileName: getFileName,
			getEventReportsForReportIds,
			getEventReportsForReportIdsAsync,
			getEventReportsForReportIdsAsyncCount,
			getEventReportDownloads,
			getMyDownloadsDocument,
			getEventReportDownloadInfo
		};

		var status = {};
		var showDownloadErrorHandler;

		return documentService;

		function getEventReportDownloadInfo(queueId) {
			const study = appSession.getStudy();
			const url = appConfig.apiUrls.downloads.eventReportDownloadInfo.supplant({
				'studyId': study.id,
				'studyModeId': study.studyModeId,
				queueId
			});

			$log.debug(`documentService.getEventReportDownloadInfo with url: ${url}`);
			return $http.get(url);
		}

		function getEventReportDownloads(oDataQuery) {
			const study = appSession.getStudy();
			const url = appConfig.apiUrls.downloads.eventReportDownloads.supplant({
				'studyId': study.id,
				'studyModeId': study.studyModeId,
				oDataQuery
			});

			$log.debug(`documentService.getEventReportDownloads with url: ${url}`);
			return $http.get(url);
		}

		// should pass in parameters as reportName,reportDate,site, subject, visit, event, id
		function getFileName(reportName, reportDate, ...additionalInfo) {

			var studyName = appSession.getStudy().name || '';

			reportName = reportName || '';
			reportName = `${studyName} - ${reportName}`;

			var date = reportDate || new Date();
			var formattedDate = moment(date).format('YYYY-MM-DD HH-mm-ss');
			reportName += ` - ${formattedDate}`;

			additionalInfo.forEach((name) => {
				reportName += ` - ${name}`;
			});

			return encodeURIComponent(reportName);
		}

		function openDownloadReport(reportId, downloadDocumentType, fileName, fileExtension) {
			var w = 400;
			var h = 400;
			var url = getDownloadReportUrl(reportId, downloadDocumentType, fileName, fileExtension);

			var left = (screen.width / 2) - (w / 2);
			var top = (screen.height / 2) - (h / 2);

			var open = $window.open(url, '_preview', `top=${top},left=${left},width=${w},height=${h}`);
			if (!open) {
				$window.location.assign(url);
			}
		}

		function registerDownloadErrorHandler(handler) {
			showDownloadErrorHandler = handler;
		}

		function downloadReport(reportId, scope, downloadDocumentType, numberOfReports,
								fileName, fileExtension) {
			if (!downloadDocumentType) {
				downloadDocumentType = appConfig.downloadDocumentType.general;
			}

			analyticsService.trackEvent(`${appConfig.trackCategory.download}::${downloadDocumentType}`, numberOfReports || 1);

			const intervalTimer = $interval(() => {
				const value = $cookies.get(`${appConfig.constants.downloadCookieName}-${reportId}`);

				if (value) {
					checkDownloadStatus(reportId)
						.then((response) => {
							if (response.data === appConfig.downloadStatus.failure ||
								response.data === appConfig.downloadStatus.partialSuccess) {
								getDownloadReportNames(reportId)
									.then((res) => {
										const rows = [];
										const columns = {};

										res.data.forEach((el, i) => {
											rows.push({ id: i });
											columns[i] = [{ data: el }];
										});

										popupService.show('table', {
											headerTitle: appI18n.translateImmediate(
												'reports.report-review.info.download-error-title'),
											description: appI18n.translateImmediate(
												'my-downloads.popup.description-download-error'),
											headers: [{ header: appI18n.translateImmediate(
												'my-downloads.popup.column') }],
											rows,
											columns
										});

									});
								$interval.cancel(intervalTimer);
							} else if (response.data === appConfig.downloadStatus.success ||
									   response.data !== appConfig.downloadStatus.inProgress) {
								$interval.cancel(intervalTimer);
							}
						}).catch((error) => {
							$log.error(`Error while checking download status: ${error}`);
							$interval.cancel(intervalTimer);
						}).finally(() => {
							$cookies.remove(`${appConfig.constants.downloadCookieName}-${reportId}`,
								{ path: '/', domain: 'ert.com' });
						});
				}
			}, 1000);

			// clear timer when destroyed
			scope.$on('$destroy', () => {
				$interval.cancel(intervalTimer);
			});

			if (iOS()) {
				downloadReportForiOs(reportId, scope, downloadDocumentType, fileName, fileExtension);
			} else {
				downloadReportInternal(reportId, scope, downloadDocumentType, fileName, fileExtension);
			}
		}

		function downloadReportInternal(reportId, scope, downloadDocumentType, fileName, fileExtension) {
			var url = getDownloadReportUrl(reportId, downloadDocumentType, fileName, fileExtension);
			var open = $window.open(url, '_blank');

			if (!open) {
				url += '&isAttachment=true';
				$window.location.assign(url);
			}
		}

		function downloadReportForiOs(reportId, scope, downloadDocumentType, fileName, fileExtension) {
			var url = getDownloadReportUrl(reportId, downloadDocumentType, fileName, fileExtension);
			$window.location.assign(url);
		}

		function iOS() {
			var result = /iPad|iPhone|iPod/.test(navigator.userAgent) && !$window.MSStream;
			return result;
		}

		function checkDownloadStatus(reportId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.documents.downloadReportStatus.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				id: reportId
			});
			return sendGetRequest(url);
		}

		function getDownloadReportData(reportId, downloadDocumentType, fileName, fileExtension) {
			var url = getDownloadReportUrl(reportId, downloadDocumentType, fileName, fileExtension);
			return $http.get(url);
		}

		function getDownloadReportNames(reportId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.documents.downloadReportFailedNames.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				id: reportId
			});
			return sendGetRequest(url);
		}

		function getDownloadReportUrl(reportId, downloadDocumentType, fileName, fileExtension) {
			const currentStudy = appSession.getStudy();
			var reportName = '';
			if (fileName) {
				reportName = fileName;
				if (fileExtension) {
					reportName += '.' + fileExtension;
				}
			}
			reportName = encodeURIComponent(reportName);

			var url = appConfig.apiUrls.documents.downloadReport.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				id: reportId,
				type: downloadDocumentType,
				reportName: reportName

			});
			url += '&redirectUrl=' + encodeURIComponent($location.absUrl());
			var timeStamp = new Date().getTime(); //prevent cache
			url += '&token=' + appSession.getSessionToken() + '&timeStamp=' + timeStamp;
			$log.debug('downloadReport with url:' + url);
			return url;
		}

		function sendGetRequest(url) {
			//return $http.get(url, {responseType: 'arraybuffer'});
			return $http.get(url);
		}

		function getEcgSourceTracingReport(documentId, reportName, fileType) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.documents.ecgSourceTracings.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				id: documentId,
				name: reportName,
				type: fileType
			});
			$log.debug('documentService.getEcgSourceTracingReport with url: {url}', {
				url: url
			});
			return sendGetRequest(url);
		}

		function getAbpmReport(filePath, reportName) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.documents.abpmReports.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				path: filePath,
				name: reportName
			});
			$log.debug('documentService.getAbpmReport with url: {url}', {
				url: url
			});
			return sendGetRequest(url);
		}

		function getHolterReport(reports) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.documents.holterReports.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId
			});
			$log.debug('documentService.getHolterReport with url: {url}', {
				url: url
			});
			//return $http.post(url, reports, {responseType: 'arraybuffer' });
			return $http.post(url, reports);
		}

		function getMyDownloadsDocument(queueIds) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.documents.myDownloads.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				queueId: queueIds.join('&queueId=')
			});
			$log.debug('documentService.getMyDownloadsDocument with url: {url}', {
				url: url
			});
			return sendGetRequest(url);
		}

		function getDataStatusFormsReport(reports) {
			let data;
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.documents.dataStatusForms.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				'downloadAll': reports ? 'false' : 'true'
			});

			if (reports) {
				data = JSON.stringify({
					reportList: reports
				});
			}

			$log.debug('documentService.getDataStatusFormsReport with url: {url}', { url });
			return $http.post(url, data);
		}

		function getReturnRequestReport(documentId, orderId) {
			var url = appConfig.apiUrls.documents.returnRequests.supplant({
				'studyId': appSession.getStudy().id,
				'studyModeId': appSession.getStudy().studyModeId,
				'id': documentId,
				'orderId': orderId
			});
			$log.debug("documentService.getReturnRequestReport with url: {url}", {
				url: url
			});
			return sendGetRequest(url);
		}

		function getMyReports(reports) {
			var url = appConfig.apiUrls.documents.myReports.supplant({
				'studyId': appSession.getStudy().id,
				'studyModeId': appSession.getStudy().studyModeId
			});
			$log.debug("documentService.getMyReports with url: {url}", {
				url: url
			});
			var data = JSON.stringify({
				reportList: reports
			});
			//return $http.post(url, data, {responseType: 'arraybuffer'});
			return $http.post(url, data);
		}

		function getTrainingDocuments(reports) {
			var url = appConfig.apiUrls.documents.trainingDocuments.supplant({
				'studyId': appSession.getStudy().id,
				'studyModeId': appSession.getStudy().studyModeId
			});
			$log.debug("documentService.getTrainingDocuments with url: {url}", {
				url: url
			});
			var data = JSON.stringify({
				reportList: reports
			});
			//return $http.post(url, data, {responseType: 'arraybuffer' });
			return $http.post(url, data);
		}

		function getEventReport(siteId, subjectId, reportId) {
			const study = appSession.getStudy();
			const url = appConfig.apiUrls.documents.eventReports.supplant({
				'studyId': study.id,
				siteId,
				subjectId,
				'studyModeId': study.studyModeId,
				reportId
			});

			$log.debug('documentService.getEventReport with url: {url}', { url });
			return sendGetRequest(url);
		}

		function getEventReportAcrossStudies(studyId, studyModeId, siteId, subjectId, reportId) {
			const url = appConfig.apiUrls.documents.eventReports.supplant({
				studyId,
				siteId,
				subjectId,
				studyModeId,
				reportId
			});

			$log.debug('documentService.getEventReport with url: {url}', { url });
			return sendGetRequest(url);
		}

		function getEventReportsForTypes(siteId, subjectId, eproSubjectId, types) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.documents.eventReportsForTypes.supplant({
				'studyId': study.id,
				'studyModeId': study.studyModeId,
				'siteId': siteId,
				'subjectId': subjectId,
				'eproSubjectId': eproSubjectId || ''
			});
			url += '&type=' + types.join('&type=');
			$log.debug('documentService.getEventReport with url: {url}', {
				url: url
			});
			return sendGetRequest(url);
		}

		function getEventReportsForReportIds(reportIds, useZip) {
			const currentStudy = appSession.getStudy();

			let url = appConfig.apiUrls.documents.eventReportsForReportIds.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				useZip: useZip ? 'true' : 'false'
			});

			url += `&reportId=${reportIds.join('&reportId=')}`;
			$log.debug(`documentService.getEventReportsForReportIds with url: ${url}`);
			return sendGetRequest(url);
		}

		function getEventReportsForReportIdsAsyncCount(reports, timeout) {
			const study = appSession.getStudy();
			let url = appConfig.apiUrls.documents.eventReportsForReportIdsAsyncCount.supplant({
				'studyId': study.id,
				'studyModeId': study.studyModeId
			});

			$log.debug(`documentService.getEventReportsForReportIdsAsyncCount with url: ${url}`);
			return $http.post(url, reports, { timeout: timeout.promise });
		}

		function getEventReportsForReportIdsAsync(reports) {
			const study = appSession.getStudy();
			let url = appConfig.apiUrls.documents.eventReportsForReportIdsAsync.supplant({
				'studyId': study.id,
				'studyModeId': study.studyModeId
			});

			$log.debug(`documentService.getEventReportsForReportIdsAsync with url: ${url}`);
			return $http.post(url, reports);
		}

		function getEventReportsForEventIds(siteId, subjectId, eproSubjectId, eventIds) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.documents.eventReportsForEventIds.supplant({
				'studyId': study.id,
				'studyModeId': study.studyModeId,
				'siteId': siteId,
				'subjectId': subjectId,
				'eproSubjectId': eproSubjectId || ''
			});
			url += '&eventId=' + eventIds.join('&eventId=');
			$log.debug('documentService.getEventReport with url: {url}', {
				url: url
			});
			return sendGetRequest(url);
		}

		function getEproAlertUrl(siteId, eproSubjectId, tdtId, eventId, docId, token, reportName) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.documents.eproAlert.supplant({
				'studyId': study.id,
				'studyModeId': study.studyModeId,
				'tdtId': tdtId,
				'siteId': siteId,
				'eproSubjectId': eproSubjectId,
				'id': docId,
				'eventId': eventId,
				'token': token,
				name: reportName
			});
			$log.debug('documentService.getEproAlert with url: {url}', {
				url: url
			});
			return sendGetRequest(url);
		}
	}

})();
