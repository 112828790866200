class DeviceMicroPortalViewerController {
	constructor(
		$log,
		$stateParams,
		appSession,
		appConfig,
		$sce
	) {
		'ngInject';
		this.$log = $log;
		this.$stateParams = $stateParams;
		this.appSession = appSession;
		this.appConfig = appConfig;
		this.$sce = $sce;
	}

	$onInit() {
		this.$log = this.$log.getInstance('DeviceMicroPortalViewerController');
		this.$log.debug('loaded');
		this.deviceMicroPortalViewerUrl = this.getDeviceMicroPortalViewerUrl();
	}
	
	getDeviceMicroPortalViewerUrl() {
		const currentStudy = this.appSession.getStudy();
		const token = this.appSession.getSessionToken();
		const deviceMicroPortalViewerUrl = this.appConfig.external.deviceMicroPortalViewerUrl;
		// eslint-disable-next-line max-len
		const url = `${deviceMicroPortalViewerUrl}?study_id=${currentStudy.number}&id_token=${token}`;
		
		this.$log.debug(`deviceMicroPortalURL=${url}`);

		return this.$sce.trustAsResourceUrl(url);
	}

}

export default DeviceMicroPortalViewerController;
