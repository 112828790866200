angular.module('portalApp').directive('ertTitleCard', [function () {
    return {
        restrict: 'E',
        scope: {},
        bindToController: {
            data: '='
        },
        templateUrl: 'scripts/directives/titleCard/ertTitleCard.html',
        controllerAs: 'tc',
        controller: /* @ngInject */ function ($scope, $element) {
            var tc = this;

            tc.hasTitle = function() {
                if(!tc.data) { return false}
                return tc.data.title || tc.data.titleI18n;
            };
            tc.hasHeader = function() {
                if(!tc.data) { return false}
                return tc.data.header || tc.data.headerI18n;
            };
        },
        link: function ($scope, $element, $attrs) {

        }
    }
}]);