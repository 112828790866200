/*
 * downloadsModule
 * */

import downloadsComponent from './downloads.component';

const downloadsModule = angular.module('app.components.myDownloads.downloads', [])
	.component('downloads', downloadsComponent)
	.name;

export default downloadsModule;
