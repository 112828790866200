(function() {

	'use strict';
	angular
		.module("portalApp")
		.service("spirometryService", Service);

	/**
	 * @ngInject
	 */
	function Service($log, $http, appConfig, appSession, $sce) {
		$log = $log.getInstance("spirometryService");
		$log.debug("spirometryService loaded");

		const service = {
			getSubjectInfo,
			getSpiroViewerUrl
		};

		return service;

		function getSubjectInfo(siteId, subjectId, eventId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.spirometry.getSubjectInfo.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				siteId:siteId, subjectId: subjectId, eventId:eventId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function getSpiroViewerUrl(siteId, subjectId, eventId, viewerVersion, subjectInfo) {
			//var
			const currentStudy = appSession.getStudy();
			const token = appSession.getSessionToken();
			const spiroViewerUrl = viewerVersion === appConfig.constants.spiroViewerVersion.spiro2019 ?
				  appConfig.external.spiroViewer2019Url :
				  appConfig.external.spiroViewerUrl;
			let url;

			if (viewerVersion === appConfig.constants.spiroViewerVersion.spiro2019) {
				url = `${spiroViewerUrl}?study=${currentStudy.id}&mode=${currentStudy.studyModeId}&site=${siteId}&subject=${subjectId}&event=${eventId}&jwt=${token}`;
			} else {
				const si = encodeURIComponent(JSON.stringify(subjectInfo));
				url = `${spiroViewerUrl}?study=${currentStudy.id}&mode=${currentStudy.studyModeId}&site=${siteId}&subject=${subjectId}&event=${eventId}&si=${si}&jwt=${token}`;
			}

			$log.debug('spiroviewUrl=' + url);
			//test url
			//url = "http://epf-spiroviewer-02.int.test.ert.com/#/spirometryViewer?study=21237&mode=0&site=1323359&subject=468105&event=3528337&jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2dzc28tMDEuZGV2LmVydC5jb20vaWRwL29hdXRoMiIsImlhdCI6MTUyNzQ5OTU3NCwiZXhwIjoxNzAwMjk5NTc0LCJzdWIiOiI3OTgwMDUwOC0yYmZhLTQyMWYtYjU2OC03NWVmZTAxNzAzZjQiLCJuYW1lIjoiZmFrZSIsImZhbWlseV9uYW1lIjoiQmxhc2Noa2UiLCJnaXZlbl9uYW1lIjoiTWFydGluIiwic3RhdHVzIjoiQWN0aXZlIiwiZW1haWwiOiJtYXJ0aW4uYmxhc2Noa2VAZXJ0LmNvbSIsImF1ZCI6Im1zcDMifQ.OmCiBguo3nobyRYe9eKIybYgMCrRi6dkgiaaj5bUfik";
			return $sce.trustAsResourceUrl(url);
		}
	}
})();
