/*
 * pageHeaderModule
 * */

import pageHeaderComponent from './pageHeader.component';

const pageHeaderModule = angular.module('app.common.pageHeader', [])
	.component('pageHeader', pageHeaderComponent)
	.name;

export default pageHeaderModule;
