/*
 * userRolesController
 * */
import UserRole from '../userRole.model';

class UserRoleStateModel extends UserRole {
	constructor(roleObj, selected, disabled, initialValue) {
		super(roleObj);
		this.selected = selected;
		this.disabled = disabled;
		this.initialValue = initialValue;
	}
}

class UserRolesController {
	constructor($log, popupService, appI18n, appConfig) {
		'ngInject';
		this.$log = $log;
		this.popupService = popupService;
		this.appI18n = appI18n;
		this.appConfig = appConfig;
	}

	$onInit() {
		this.$log = this.$log.getInstance('UserRolesController');
		this.$log.debug('loaded');

		if (!this.state) {
			// no need to generate user roles if state was provided
			this.formatUserRoles(this.availableRoles, this.currentRoles);
		}

		this.$API = {
			getComponentState: () => {
				return {
					availableRoles: this.availableRoles,
					currentRoles: this.currentRoles,
					userHasPiRole: this.userHasPiRole,
					userHasShippingContactRole: this.userHasShippingContactRole

				};
			}
		};

		this.componentReady && this.componentReady({ $API: this.$API });
	}

	$onChanges(changesObj) {
		if (changesObj.state) {
			if (changesObj.state.currentValue) {
				this.restoreComponentState(changesObj.state.currentValue);
			}

		}
	}

	roleCheckboxChange(role) {
		const isShippingContact = (role.roleId === this.appConfig.roleIds.shippingContact);

		if (isShippingContact && role.selected) {
			this.showRoleChangeConfirmation().then((resp) => {
				if (resp.answer === 'ok') {
					//ok, just close
				} else {
					// roll back the selection
					role.selected = false;
				}

				this.notifyRoleChange();
			});
			return;
		}

		if (this.checkIsLastRole()) {
			this.showSingleRoleInfo().then((/*resp*/) => {
				role.selected = true;
				this.notifyRoleChange();
			});
			return;
		}
		this.notifyRoleChange();
	}

	checkIsLastRole() {
		return !this.getRolesToAdd().length && this.getRolesToRemove().length === this.currentRoles.length;
	}

	notifyRoleChange() {
		this.onRoleChanges && this.onRoleChanges({
			rolesToAdd: this.getRolesToAdd(),
			rolesToRemove: this.getRolesToRemove()
		});
	}

	getRolesToAdd() {
		return this.availableRoles.filter((userRole) => {
			return userRole.selected && !userRole.initialValue;
		}).map((role) => {
			return new UserRole(role);
		});
	}

	getRolesToRemove() {
		return this.currentRoles.filter((userRole) => {
			return !userRole.selected && userRole.initialValue;
		}).map((role) => {
			return new UserRole(role);
		});
	}

	formatUserRoles(availableRoles, currentRoles) {

		this.availableRoles = availableRoles.map((role) => {
			const selected = false;
			const disabled = false;
			const initialValue = false;

			return new UserRoleStateModel(role, selected, disabled, initialValue);
		});

		this.currentRoles = currentRoles.map((role) => {
			let disabled = false;

			if (role.roleId === this.appConfig.roleIds.principalInvestigator) {
				this.userHasPiRole = true;
				disabled = true;
			}
			if (role.roleId === this.appConfig.roleIds.shippingContact) {
				this.userHasShippingContactRole = true;
				disabled = true;
			}

			const selected = true;
			const initialValue = true;

			return new UserRoleStateModel(role, selected, disabled, initialValue);
		});
	}

	showRoleChangeConfirmation() {
		return this.popupService.show('confirmation', {
			headerTitle: this.appI18n.translateImmediate('app.common.warning'),
			contentMsg: this.appI18n.translateImmediate('study-permissions.messages.shippingContactAddingWarning')
		});
	}

	showSingleRoleInfo() {
		return this.popupService.show('info', {
			headerTitle: this.appI18n.translateImmediate('app.common.warning'),
			contentMsg: this.appI18n.translateImmediate('study-permissions.messages.singleRoleRemovalWarning')
		});
	}

	restoreComponentState(state) {
		this.availableRoles = state.availableRoles;
		this.currentRoles = state.currentRoles;
		this.userHasPiRole = state.userHasPiRole;
		this.userHasShippingContactRole = state.userHasShippingContactRole;
		this.notifyRoleChange();
	}
}

export default UserRolesController;
