(function() {

	'use strict';

	angular
		.module('portalApp')
		.service('refMatService', RefMatService);

	/**
	 *	@ngInject
	 */

	 function RefMatService(appConfig, $log, $http, appSession) {

		$log = $log.getInstance("RefMatService", "color:red");
		$log.debug("loaded");

		var newEditFolderCtrl;
		var newEditFileCtrl;
		var refreshHandler;

	 	var refMatService = {
	 		getFolders: getFolders,
	 		getFiles: getFiles,
	 		getAssignedStudies: getAssignedStudies,
	 		getAvailableStudies: getAvailableStudies,
	 		getPermission: getPermission,
	 		getFolder: getFolder,
	 		deleteFolder: deleteFolder,
	 		saveFolder: saveFolder,
	 		openNewEditFolder: openNewEditFolder,
	 		registerNewEditFolderController: registerNewEditFolderController,
	 		deleteFile : deleteFile,
	 		addFile: addFile,
	 		updateFile: updateFile,
	 		updateFileStatus: updateFileStatus,
	 		registerNewEditFileController: registerNewEditFileController,
			openNewEditFile: openNewEditFile,
			getFile: getFile,
			registerRefresh: registerRefresh,
			refreshGrid: refreshGrid

	 	};

	 	return refMatService;

	 	function registerRefresh(handler) {
			refreshHandler = handler;
		}

		function refreshGrid() {
			if (refreshHandler) {
				refreshHandler();
			}
		}

	 	function getFolders(options) {
	 		var url = appConfig.apiUrls.refMat.folders.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });
	 		if (options) {
				url += "&" + options;
			}
			else {
				url += "&$orderby=FolderName";
			}
			
	 		return $http.get(url);
	 	}

	 	function getFiles(folderId, options){
	 		var url = appConfig.apiUrls.refMat.files.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 'folderId': folderId });
			if (options) {
				url += "&" + options;
			}
			else {
				url += "&$orderby=FileName";
			}
	 		return $http.get(url);
	 	}

	 	function getAssignedStudies(folderId) {
	 		var url = appConfig.apiUrls.refMat.assignedstudies.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 'folderId': folderId });
			
	 		return $http.get(url);

	 	}

	 	function getAvailableStudies(folderId) {
	 		var url = appConfig.apiUrls.refMat.availablestudies.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 'folderId': folderId });
			
	 		return $http.get(url);

	 	}

	 	function getPermission() {
	 		var url = appConfig.apiUrls.refMat.permissions.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });
			
	 		return $http.get(url);

	 	}

	 	function getFolder(folderId) {
	 		var url = appConfig.apiUrls.refMat.folder.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 'folderId': folderId });
			
	 		return $http.get(url);

	 	}

	 	function deleteFolder(folderId) {
	 		var url = appConfig.apiUrls.refMat.folder.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 'folderId': folderId });
			
	 		return $http.delete(url);

	 	}

	 	function saveFolder(folder) {
	 		var url = appConfig.apiUrls.refMat.folders.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });
	 		return $http.post(url, folder);
	 	}

	 	function openNewEditFolder(folderId, permission) {
	 		if (newEditFolderCtrl) {
	 			newEditFolderCtrl.showNewRefmat(folderId, permission);
	 		}
	 	}

	 	function registerNewEditFolderController(controller) {
	 		newEditFolderCtrl = controller;
	 	}

	 	function deleteFile(folderId, fileId) {
	 		var url = appConfig.apiUrls.refMat.file.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 
	 				'folderId': folderId, 'fileId' : fileId });
			
	 		return $http.delete(url);

	 	}

	 	function addFile(file) {
	 		var url = appConfig.apiUrls.refMat.addfile.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 
	 				'folderId': folderId, 'unzipIfZipped' : file.unzip, 'isActive': file.isFileActive.toString() });
	 		return $http.post(url, file);
	 	}

	 	function updateFile(file) {
	 		var url = appConfig.apiUrls.refMat.updatefile.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 
	 				'fileId': file.fileId, 'isActive': file.isFileActive.toString(), 'folderId': file.folderId });
	 		return $http.post(file);
	 	}

	 	function updateFileStatus(file) {
	 		var url = appConfig.apiUrls.refMat.updatefilestatus.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 
	 				'fileId': file.fileId, 'isActive': file.isFileActive.toString(), 'folderId': file.folderId });
	 		return $http.post(url);
	 	}

	 	function getFile(folderId, fileId){
	 		var url = appConfig.apiUrls.refMat.file.supplant(
	 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 
	 				'fileId': fileId, 'folderId': folderId });
	 		//return $http.get(url, {responseType: "arraybuffer"});
		    return $http.get(url);
	 	}

	 	function openNewEditFile(file, permission, multipleUpload) {
	 		if (newEditFileCtrl) {
	 			const f = angular.copy(file);
	 			newEditFileCtrl.loadRefMatFile(f, permission);
				newEditFileCtrl.multipleUpload = !!multipleUpload;
	 		}
	 	}

	 	function registerNewEditFileController(controller) {
	 		newEditFileCtrl = controller;
	 	}

	 }

})();