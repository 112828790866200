/*
 * contentMessageController
 * */

class ContentMessageController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
		this.iconClass = this.getTypeClass(this.type);
		this.modeClass = this.getModeCssClass(this.viewMode);
	}

	$onInit() {
		this.$log = this.$log.getInstance('ContentMessageController');
		this.$log.debug('loaded');
	}

	getTypeClass(type) {
		let cssClass = '';

		switch (type) {
			case 'info':
				cssClass = 'es-icon-alert-info';
				break;
			case 'success':
				cssClass = 'es-icon-alert-success';
				break;
			case 'warning':
				cssClass = 'es-icon-alert-warning';
				break;
			case 'error':
				cssClass = 'es-icon-alert-danger';
				break;
			default:
				cssClass = 'es-icon-bell';
				break;
		}
		return cssClass;
	}

	getModeCssClass(mode) {
		let cssClass = '';

		switch (mode) {
			case 'centered':
				cssClass = '-es-centered';
				break;
			default:
				cssClass = '';
				break;
		}
		return cssClass;
	}
}

export default ContentMessageController;
