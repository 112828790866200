/*
 * tabController
 * */

class TabController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('TabController');
		this.$log.debug('loaded');
		this.tabs.addTab(this);
	}

	defineRenderMode() {
		if (this.reloadOnActive) {
			return this.active;
		} else {
			return true;
		}
	}
}

export default TabController;
