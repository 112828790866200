import templateUrl from './badgeLabel.html';
import controller from './badgeLabel.controller';
import './badgeLabel.scss';

const badgeLabelComponent = {
	bindings: {
		label: '@',
		content: '@',
		labelPosition: '@'
	},
	templateUrl,
	controller
};

export default badgeLabelComponent;
