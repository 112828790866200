class ertRadioButtonGroupController {
	constructor() {
		'ngInject';
		this.model = null;
		this.input = null;
	}

	$onInit() {
		this.model = this.options.default;
		this.setApi();
	}

	$postLink() {
	}

	valueChange() {
		this.onChange({ newValue: this.model });
	}

	setApi() {
		if (!this.componentReady) { return; }
		this.$API = {
			setValue: (value) => {
				this.model = value;
				this.onChange({ newValue: this.model });
			}
		};

		// publish api when component is ready
		this.componentReady({ $API: this.$API });
	}

	checkNotValid() {
		/* eslint-disable */
		this.input = this.fg[this.name];
		return (!this.input.$valid && (this.input.$dirty || this.input.$$parentForm.showValidation));
		/* eslint-enable */
	}

}

export default ertRadioButtonGroupController;
