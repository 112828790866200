/*
 * tabComponent
 * */

import templateUrl from './tab.html';
import controller from './tab.controller';
import './tab.scss';

const tabComponent = {
	bindings: {
		name: '@',
		route: '@',
		reloadOnActive: '<?'
	},
	require: {
		tabs: '^tabs'
	},
	transclude: true,
	templateUrl,
	controller
};

export default tabComponent;
