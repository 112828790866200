/*
 * tableController
 * */

import { CELL_DATA_TYPES } from './cellDataType';

class tableController {
	constructor($log, $timeout, sortingService) {
		'ngInject';
		this.$log = $log;
		this.$timeout = $timeout;
		this.sortingService = sortingService;
		this.rows = [];
	}

	$onInit() {
		this.setApi();
	}

	setApi() {
		this.$API = {
			selectRow: (row, triggerUpdate = true) => {
				if (triggerUpdate) {
					this.rowSelected(row);
					return;
				}
				this.setSelectedRow(row);
			}
		};

		// publish api when component is ready
		if (!this.componentReady) { return; }

		this.componentReady({ $API: this.$API });
	}

	isTextualCell(type) {
		return type === CELL_DATA_TYPES.TEXT;
	}

	isNumericCell(type) {
		return type === CELL_DATA_TYPES.NUMBER;
	}

	doSort(header) {
		const reverse = this.getSortOrder(header);
		const sortData = { fieldName: header.fieldName, reverse },
			uriBuilder = this.getUriBuilder(sortData);

		this.cleanSorting();
		this.setSorted(header, reverse);
		this.sort({ uriBuilder });
		this.notifyGrid(uriBuilder);
	}

	getUriBuilder(sortData) {
		return this.sortingService.getUriBuilder(sortData);
	}

	getSortOrder(header) {
		if (header.sorted) {
			return !header.reverse;
		}

		return false;
	}

	notifyGrid(uriBuilder) {
		if (this.grid) {
			this.grid.onSortChange(uriBuilder);
		}
	}

	cleanSorting() {
		this.headers.forEach((h) => {
			h.sorted = false;
			h.reverse = false;
		});
	}

	setSorted(header, reverse = false) {
		header.sorted = true;
		header.reverse = reverse;
	}

	addRow(row) {
		this.rows.push(row);
	}

	rowSelected(row) {
		this.setSelectedRow(row);
		this.onRowSelected && this.onRowSelected({ row });
	}

	setSelectedRow(row) {
		this.$timeout(() => {
			this.rows.forEach((i) => {
				i.selected = (i === row);
			});
		});
	}
}

export default tableController;
