import subjectsComponent from './subjects.component';
import subjectsTableModule from './subjectsTable/index';
import subjectInfoPopupModule from './subjectInfoPopup/index';
import editContactDataPopupModule from './editContactDataPopup/index';
import sendPinPopupModule from './sendPinPopup/index';
import activatePopupModule from './activatePopup/index';
import iSpiroPopupModule from './iSpiroPopup/index';
import kardiaPopupModule from './kardiaPopup/index';

const subjectsModule = angular.module('app.components.clinicalData.subjects', [
	subjectsTableModule,
	subjectInfoPopupModule,
	editContactDataPopupModule,
	sendPinPopupModule,
	activatePopupModule,
	iSpiroPopupModule,
	kardiaPopupModule
]).component('clinicalDataSubjects', subjectsComponent).name;

export default subjectsModule;
