/*
 * studyMetricsDetailsPopupModule
 * */

import studyMetricsDetailsPopupComponent from './studyMetricsDetailsPopup.component';

const studyMetricsDetailsPopupModule = angular
	.module('app.components.dashboard.studyMetricsWidget.studyMetricsDetailsPopup', [])
	.component('studyMetricsDetailsPopup', studyMetricsDetailsPopupComponent)
	.name;

export default studyMetricsDetailsPopupModule;
