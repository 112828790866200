/*
 * countriesModule
 * */

import countriesComponent from './countries.component';
import countriesInfoPopupModule from './countriesInfoPopup/index';

const countriesModule = angular.module('app.components.clinicalData.countries', [
	countriesInfoPopupModule
]).component('clinicalDataCountries', countriesComponent).name;

export default countriesModule;
