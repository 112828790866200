(function() {

	'use strict';

	angular
		.module("portalApp")
		.controller("NewEditAnnouncementCtrl", NewEditAnnouncementCtrl);

	/**
	 * @ngInject
	 */

	function NewEditAnnouncementCtrl(announcementService, $log, appConfig, $state, FileUploader, $filter,
		studySelectService, appSession, appI18n,documentService, $scope, loadingIndicatorService) {

		$log = $log.getInstance("NewEditAnnouncementCtrl", "color:magenta");
		$log.debug("loaded");

		var vm = this;
		vm.dataLoading = false;
		vm.announcement = {studies: []};

		function activate() {

			announcementService.registerNewAnnouncement(vm);

			$scope.$on('$destroy', () => {
				console.log('DESTROYED');
				announcementService.registerNewAnnouncement();
			});

			vm.load = true;
			initFileUploader(vm);
			appI18n.translate( [
				"announcements.edit.save",
				"announcements.new.title",
				"announcements.edit.title",
				"announcements.edit.error-message",
				"announcements.edit.sponsor",
				"announcements.edit.study-name",
				"announcements.edit.study#",
				"announcements.edit.global-message",
				"announcements.edit.file-upload-error",
				"announcements.edit.priority-message",
				"app.buttons.chooseSingleFile"
			])
			.then(function(translations) {
				vm.saveTitle = translations["announcements.edit.save"];
				vm.newTitle = translations["announcements.new.title"];
				vm.editTitle = translations["announcements.edit.title"];
				vm.saveErrorMessage = translations["announcements.edit.error-message"];
				vm.sponsorTitle = translations["announcements.edit.sponsor"];
				vm.studyTitle = translations["announcements.edit.study-name"];
				vm.studyNumTitle = translations["announcements.edit.study#"];
				vm.globalMessage = translations["announcements.edit.global-message"];
				vm.fileUploadError = translations["announcements.edit.file-upload-error"];
				vm.priorityMsg = translations["announcements.edit.priority-message"];
				vm.chooseSingleFile = translations["app.buttons.chooseSingleFile"];

				vm.actions = [{
					action: vm.saveAnnouncement,
					displayName: vm.saveTitle,
					isDisabled: vm.dataLoading,
					isPrimary: true
				}];
				vm.studyOptions.columnDefs =
				[
					{displayName: vm.sponsorTitle, fieldName: 'sponsorName', isSortable: true, type: 'string'},
					{displayName: vm.studyTitle, fieldName: 'protocolName', isSortable: true, type: 'string'},
					{displayName: vm.studyNumTitle, fieldName: 'protocolAccount', isSortable: true, type: 'string'}
				];
			});

			announcementService.getPermission()
			.then(function(response) {
				if (!response.data) {
					$state.go(appConfig.routes.announcements);
				}

			}, function(response) {
				$log.error("Get permission failed with error: " + response.data);
					$state.go(appConfig.routes.announcements);
			});
		}

		activate();

		vm.showGrid = function() {
			vm.showStudyGrid = true;
			vm.showStudyTable = false;
		};

		vm.showNewAnnouncement = function(row) {
			$log.debug("showNewAnnouncement");
			loadingIndicatorService.show();
			vm.id = 0;
			vm.announcement = {};
			vm.announcement.details = {};
			vm.selectedRows = [];
			vm.saveClicked = false;
			vm.errorMessage = "";
			vm.announcement.deletedStudies = [];
			vm.announcement.fileChanged = false;
			vm.active = true;

			vm.id = row.announcementId;
			vm.newEditTitle = vm.newTitle;
			if (vm.id > 0) {
				vm.newEditTitle = vm.editTitle;
				vm.loadAnnouncement();
			} else {
				vm.announcement.details.active = true;
				vm.showStudyGrid = true;
				vm.showStudyTable = false;
			}


		};

		vm.showData = function() {
			vm.dataLoading = true;
			announcementService.getAnnouncementFile(vm.announcement.details.announcementId)
				.then(function(response) {
					$log.debug(" announcement file content data loaded.");
					documentService.openDownloadReport(response.data, appConfig.downloadDocumentType.announcement);
					vm.dataLoading = false;
				}, function(response){
					$log.debug("error in loading announcement file content data: " +  response.data);
					vm.dataLoading = false;
				});
		};

		vm.loadAnnouncement = function() {
			vm.announcement.fileChanged = false;
			announcementService.getAnnouncementDetails(vm.id, true)
				.then(function (response) {
					$log.debug(" announcement details data loaded.");
					vm.announcement.details = response.data;

					if (!vm.announcement.details.isGlobal) {
						vm.originalStudies = angular.copy(vm.announcement.details.linkedStudies);
						vm.selectedRows = vm.announcement.details.linkedStudies;
					}
					vm.showStudyTable = true;
					vm.showStudyGrid = false;
				}, function (response) {
					$log.debug("error in loading linked studies detail data: " + response.data);
				}).finally(() => {
					loadingIndicatorService.hide();
					vm.newAnnouncementActive = true;
				});

		};

		vm.studySelected = function() {
			return vm.selectedRows && vm.selectedRows.length > 0;
		};

		vm.resetSelectedStudies = function() {
			if (vm.announcement.details.isGlobal) {
				vm.selectedRows = [];
				vm.showStudyTable = false;
				vm.showStudyGrid = false;
			}
			else {
				vm.showStudyTable = false;
				vm.showStudyGrid = true;
			}
		};

		vm.hasFileError = function() {
			return  vm.announcement.file && !vm.isFileTypeValid(vm.announcement.file.file);
		}
		vm.isFileTypeValid = function(item) {
			var type = item.type.slice(item.type.lastIndexOf('/') + 1);
			var result = appConfig.fileUpload.announcement.validFileType.indexOf(type) !== -1;
			return result;

		};

		vm.isFileInvalid = function() {
			return vm.saveClicked && (
					(vm.id === 0 && !vm.announcement.file) ||
					(vm.hasFileError()) ||
					(!vm.announcement.file && !vm.announcement.details.contentType)
				);
		};

		vm.saveAnnouncement = function() {
			vm.errorMessage = "";
			vm.saveClicked = true;
			vm.announcement.studies = [];

			//Get selected studies from portal grid
			angular.forEach(vm.selectedRows, function(value) {
				vm.announcement.studies.push(value.protocolId);
			});

			if (!vm.announcement.details.title || !vm.announcement.details.priority ||
				(!vm.announcement.details.isGlobal && (!vm.announcement.studies || vm.announcement.studies.length === 0)) ||
				(vm.isFileInvalid())) {
				return;
			}

			loadingIndicatorService.show();

			//Find deleted items after comparing the original array with new array
			vm.announcement.deletedStudies = [];
			angular.forEach(vm.originalStudies, function(value) {
				var found = $filter('filter')(vm.announcement.studies, value.protocolId, false);
				if (found.length === 0) {
					vm.announcement.deletedStudies.push(value.protocolId);
				}
			});

			var announcement = {'announcementId': vm.id, 'title': vm.announcement.details.title,
				'priority': vm.announcement.details.priority,
				'isActive': vm.announcement.details.active, 'isGlobal': vm.announcement.details.isGlobal,
				'linkedStudies': vm.announcement.studies, 'deletedStudies' : vm.announcement.deletedStudies};

			if (vm.announcement.fileChanged) {

				var url = appConfig.apiUrls.announcements.announcements.supplant(
					{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });
				var fileItem = vm.announcement.file;
				fileItem.url = url + "&token=" + appSession.getSessionToken();
				fileItem.formData.push({ announcement: JSON.stringify(announcement) });
				vm.uploader.uploadItem(fileItem);

			} else {
				announcementService.updateAnnouncement(announcement)
					.then(function(response) {
						loadingIndicatorService.hide();
						$log.log('Announcement updated successfully: ' + response.data);

						closeNewAnnouncement();
					}, function(response) {
						loadingIndicatorService.hide();
						vm.errorMessage = vm.saveErrorMessage;
						$log.log('Error status: ' + response.data);
					});
			}
		};

		vm.updateFileChange = function (fileItem) {
			vm.announcement.fileChanged = true;
			vm.announcement.file = fileItem;
		}
		function initFileUploader(vm) {
			vm.uploader = new FileUploader();

			vm.uploader.onProgressItem = function (fileItem, progress) {
				$log.log(`onProgressItem file: ${fileItem.file.name} progress: ${progress}`);
			};

			vm.uploader.onSuccessItem = function (fileItem, response, status, headers) {
				loadingIndicatorService.hide();
				$log.log(`Success ${fileItem.file.name} uploaded. Response: ${response}`);
				$log.debug('onSuccessItem', fileItem, response, status, headers);
				closeNewAnnouncement();
			};

			vm.uploader.onErrorItem = function (fileItem, response, status, headers) {
				loadingIndicatorService.hide();

				vm.errorMessage = vm.saveErrorMessage;
				$log.log('Error status:', response);
				$log.debug('onErrorItem', fileItem, response, status, headers);

			};

			vm.uploader.onCompleteAll = function () {
				vm.uploader.clearQueue();
			};
			vm.uploader.onAfterAddingFile = function (fileItem) {
				vm.updateFileChange(fileItem);
				$log.debug('onAfterAddingFile', fileItem);
			};
		}

		vm.studyOptions = {
			rowDefs: {
				multiSelection: true,
				identifier: 'protocolId'
			},
			gridRowActions: [],
			gridActions: [],

			removeExportControls: true,
			stateParamPrefix: 'ans',

			getData: getStudies
		};


		function getStudies(options) {
			return studySelectService.getStudies(options).then(response => {
				loadingIndicatorService.hide();
				vm.newAnnouncementActive = true;
				return response;
			});
		}

		function closeNewAnnouncement(){
			vm.newAnnouncementActive = false;
			vm.selectedRows = [];
			announcementService.refreshGrid();

		}
	}

})();
