class MyDownloads {

	constructor($log, $timeout, $q, appI18n, appConfig, remoteFileService, fileUploadPopupService) {
		'ngInject';
		this.log = $log;
		this.$timeout = $timeout;
		this.appI18n = appI18n;
		this.fileUploadPopupService = fileUploadPopupService;
		this.remoteFileService = remoteFileService;
		this.$q = $q;
		this.appConfig = appConfig;
		this.tabs = [];
		this.dataManagementFilesId = 'dm-files-upload';
	}

	$onInit() {
		this.$q.all({
			translations: this.getTranslations()
		}).then((resp) => {
			this.translations = resp.translations;
			this.title = this.translations['my-downloads.title'];
			this.getTabs(this.viewConfig);
			this.getPageActions(this.viewConfig);
		});
	}

	getTranslations() {
		return this.appI18n.translate([
			'my-downloads.title',
			'my-downloads.uploadFile',
			'data-management-files.tabName'
		]);
	}

	getTabs(settings) {
		this.tabs.push({
			displayName: this.translations['my-downloads.title'],
			ref: this.appConfig.routes.downloads
		});

		if (settings.showDMFilesTab) {
			this.tabs.push({
				displayName: this.translations['data-management-files.tabName'],
				ref: this.appConfig.routes.dataManagementFiles
			});
		}

	}

	getPageActions(settings) {
		if (settings.showUploadFilesBtn) {

			const uploadUrl = this.remoteFileService.getDocumentManagementPostFileUrl();
			const filters = [
				this.fileUploadPopupService.getFileTypeFilter(['zip', 'txt', 'xml', 'csv']),
				this.fileUploadPopupService.getFileSizeFilter(3, 'GB')
			];

			this.actions = [{
				id: this.dataManagementFilesId,
				displayName: this.translations['my-downloads.uploadFile'],
				action: () => {
					this.fileUploadPopupService.show('documentsManagement', {
						uploadUrl, filters,
						infoMessage: 'data-management-files.messages.fileWarningMessage' });
				},
				hidden: false,
				visibleIn: [this.appConfig.routes.dataManagementFiles],
				iconClass: 'es-icon-download flipped',
				cssClass: '-es-primary'
			}];
		}
	}

}

export default MyDownloads;
