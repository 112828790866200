/*
 * iconsRowModule
 * */

import iconTextRowDirective from './iconTextRow.directive';

const iconsRowModule = angular.module('app.common.card.iconTextRow', [])
	.directive('ertCardIconTextRow', iconTextRowDirective)
	.name;

export default iconsRowModule;
