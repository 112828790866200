(function() {
	'use strict';

	angular
		.module('portalApp')
		.controller('MyDeviceAccountPopupController', MyDeviceAccountPopupController);

	/**
	 * @ngInject
	 */
	function MyDeviceAccountPopupController($log, appI18n, configurationService) {
		$log = $log.getInstance('MyDeviceAccountPopupController');
		$log.debug(' loaded');

		const vm = this;

		vm.showMyDeviceAccountPopup = showMyDeviceAccountPopup;
		vm.myDeviceAccountPopupTitle = appI18n.translateImmediate('deviceManagement.my-device-account.title');
		vm.assign = assign;

		configurationService.registerMyDeviceAccountHandler(vm.showMyDeviceAccountPopup);
		configurationService.getMyDeviceAccountInfo()
			.then((response) => {
				vm.info = response.data;
			})
			.catch((error) => {
				$log.error(`error getting my device account info: ${error}`);
				vm.errorMessage = appI18n.translateImmediate(
					'deviceManagement.my-device-account.error');
			});

		function showMyDeviceAccountPopup() {
			vm.myDeviceAccountVisible = true;
		}

		function assign() {
			vm.info.loading = true;
			configurationService.assignDevice()
				.then((response) => {
					vm.info.isDeviceInfo = true;
					if (!vm.info.deviceInfos) {
						vm.info.deviceInfos = {};
					}
					vm.info.deviceInfos[response.data.deviceId] = response.data.devicePassword;
				})
				.catch((error) => {
					vm.errorMessage = appI18n.translateImmediate(
						'deviceManagement.my-device-account.error');
					$log.error(`Error in assigning device: ${error}`);
				})
				.finally(() => {
					vm.info.loading = false;
				});
		}
	}
})();
