/*
 * countriesInfoPopupModule
 * */

import countriesInfoPopupComponent from './countriesInfoPopup.component';

const countriesInfoPopupModule = angular.module('app.components.clinicalData.countries.countriesInfoPopup', [])
	.component('countriesInfoPopup', countriesInfoPopupComponent)
	.name;

export default countriesInfoPopupModule;
