/*
 * pageBodyComponent
 * */

import templateUrl from './pageBody.html';
import controller from './pageBody.controller';
import './pageBody.scss';

const pageBodyComponent = {
	bindings: {},
	templateUrl,
	controller,
	transclude: true
};

export default pageBodyComponent;
