/*
 * dateRangeController
 * */

class DateRangeController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
		this.model = {
			from: '',
			to: ''
		};
	}

	$onInit() {
		this.$log = this.$log.getInstance('DateRangeController');
		this.$log.debug('loaded');
	}

	$onChanges(changesObj) {
		if (changesObj.model && !changesObj.model.isFirstChange()) {
			this.model.from = changesObj.model.currentValue.from || '';
			this.model.to = changesObj.model.currentValue.to || '';
		}
	}

	enterAction($event) {
		if (this.onEnter) {
			this.onEnter($event);
		}
	}

	valueChange(/*newModel*/) {
		const isValid = this.validateFromToValues(this.model.from, this.model.to);

		this.highlightWrongInputs(this.model.from, this.model.to);
		const model = {
			'from': this.model.from,
			'to': this.model.to
		};

		this.rangeChanged({ model, isValid });
	}

	/*
	 * Validates inputs values, highlights inputs with wrong values
	 * */
	validateFromToValues(from, to) {
		// to guarantee the same behaviour while validating parameters by moment.js
		// null and undefined values should be converted to '' which is
		// validated as an invalid moment
		from = (from === null || angular.isUndefined(from)) ? '' : from;
		to = (to === null || angular.isUndefined(to)) ? '' : to;


		const fromInvalid = !moment(new Date(from)).isValid(),
			toInvalid = !moment(new Date(to)).isValid();

		const fromIsEmptyString = (from === '');
		const toIsEmptyString = (to === '');

		// both inputs have no value -> return false
		if (fromIsEmptyString && toIsEmptyString) {
			return false;
		}

		if (fromIsEmptyString && !toInvalid) {
			return true;
		}

		if (toIsEmptyString && !fromInvalid) {
			return true;
		}

		// some input validation failed -> return false
		if (fromInvalid || toInvalid) {
			return false;
		}

		// if from is less that to -> return true
		return moment(new Date(from)).isBefore(moment(new Date(to)));
	}

	highlightWrongInputs(from, to) {
		from = (from === '' || angular.isUndefined(from)) ? null : from;
		to = (to === '' || angular.isUndefined(to)) ? null : to;

		// null is validated as true by moment.js
		this.fromInvalid = !moment(new Date(from)).isValid();
		this.toInvalid = !moment(new Date(to)).isValid();
	}

	onFromPaste(evt) {
		this.model.from = evt.originalEvent.clipboardData.getData('text/plain');
		this.valueChange(this.model.from);
	}
	onToPaste(evt) {
		this.model.to = evt.originalEvent.clipboardData.getData('text/plain');
		this.valueChange(this.model.to);
	}

}

export default DateRangeController;
