import calendarGridDirective from './calendarGrid.directive';
/* eslint-disable */
import calendar from '../calendarGrid/body/calendar.html';
import calendarGridBody from '../calendarGrid/body/calendarGridBody.html';
import calendarGridBodyGroup from '../calendarGrid/body/calendarGridBodyGroup.html';
import calendarGridNoResults from '../ertGrid/body/ertGridNoResults.html';

import ertGridActions from '../calendarGrid/header/calendarGridActions.html';
import ertGridFilters from '../ertGrid/header/ertGridFilters.html';
import ertGridHeader from '../calendarGrid/header/calendarGridHeader.html';

import filterActions from '../ertGrid/header/ertGridActions/filterActions.html';
/* eslint-enable */

const calendarGridModule = angular.module('app.common.calendarGrid', [])
	  .directive('calendarGrid', calendarGridDirective)
	  .name;

export default calendarGridModule;
