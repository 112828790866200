/*
 * resentVisitsTableModule
 * */
import resentVisitsTableDirective from './resentVisitsTable.directive';

const resentVisitsTableModule = angular.module('app.components.dashboard.visitOverviewWidget.resentVisitsTable', [])
	.directive('resentVisitsTable', resentVisitsTableDirective)
	.name;

export default resentVisitsTableModule;
