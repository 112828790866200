const qrCodeDirective = (qrCodeService) => {
	return {
		scope: {
			text: '='
		},
		template: '',
		restrict: 'E',
		link: (scope, el) => {
			qrCodeService.make(el[0], scope.text);
		}
	};
};

export default qrCodeDirective;
