(function () {
	'use strict';

	angular
		.module('portalApp')
		.service('iSpiroService', ISpiroService);

	/*
	 * @ngInject
	 */
	function ISpiroService($log, $http, appSession, appConfig, loadingIndicatorService, popupService, appI18n) {

		return {
			getQrCode,
			getCreateSubjectData,
			createSubject,
			editSubject,
			lostDevice,
			remoteCloseDevice,
			resetPin,
			getEditSubjectData,
			getDispenseSubjectData,
			getEditDeviceValueData,
			getSubjects,
			getSubjectMinimal,
			getSitesMinimal,
			getSiteDetails,
			getVisits,
			getVisitTasks,
			updateDeviceValues,
			getActions,
			getStudyActivities,
			getScheduledEvents,
			confirmAndCancelScheduledEvent,
			cancelScheduledEvent,
			generateAuditReport,
			scheduleEvent,
			rescheduleEvent,
			activateVideoSession
		};

		function getSiteDetails(siteId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.siteDetails
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  siteId
				  });

			return $http.get(url, { cache: false, params: { cacheContext: 'clinicalData' } });
		}

		function confirmAndCancelScheduledEvent(
			scheduledEventId, siteId, subjectKey, visitId, visitScheduleTaskId, scheduleDateTime, refreshCallback) {

			popupService.show('confirmation', {
				headerTitle: appI18n.translateImmediate('app.dialog.confirmTitle'),
				contentMsg: appI18n.translateImmediate('clinicaldata.scheduledEvents.confirmCancel')
			}).then((resp) => {
				if (resp.answer === 'ok') {

					loadingIndicatorService.show();
					return cancelScheduledEvent(
						scheduledEventId, siteId, subjectKey, visitId, visitScheduleTaskId, scheduleDateTime)
						.then((resp) => {
							if (resp.data) {
								refreshCallback && refreshCallback();
							} else {
								this.showCancelError();
							}
						})
						.finally(() => {
							loadingIndicatorService.hide();
						});
				} else {
					// just close confirmation
				}
			}, (error) => {
				this.$log.error(`Error: ${error}`);
			});
		}

		function generateAuditReport(useZip, siteId) {
			const currentStudy = appSession.getStudy();

			let url = appConfig.apiUrls.clinicalData.iSpiro.generateAuditReport.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId });

			if (useZip) {
				url += '&useZip=true';
			}
			if (siteId && +siteId > 0) {
				url += `&siteId=${siteId}`;
			}
			return $http.post(url);
		}

		function getStudyActivities() {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.studyActivities
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
				  });

			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
		}

		function getActions(countryId, siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.actions
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  countryId,
					  siteId,
					  subjectId
				  });

			return $http.get(url);
		}

		function lostDevice(countryId, siteId, subjectId, actionId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.lostDevice
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  countryId,
					  siteId,
					  subjectId,
					  actionId
				  });

			return $http.post(url);
		}

		function resetPin(countryId, siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.resetPin
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  countryId,
					  siteId,
					  subjectId
				  });

			return $http.post(url);
		}

		function remoteCloseDevice(countryId, siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.remoteClose
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  countryId,
					  siteId,
					  subjectId
				  });

			return $http.post(url);
		}
		function getSubjects(countryId, siteId, actionId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.subjects
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  countryId: countryId || '',
					  siteId: siteId || '',
					  actionId
				  });

			return $http.get(url);
		}

		function getSubjectMinimal(siteId, technicalSubjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.subject
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  siteId,
					  technicalSubjectId
				  });

			return $http.get(url);
		}

		function getSitesMinimal(studyObj, countryId) {
			let currentStudy = {};

			if (studyObj && studyObj.id && studyObj.studyModeId !== null) {
				currentStudy = studyObj;
			} else {
				currentStudy = appSession.getStudy();
			}
			const url = appConfig.apiUrls.clinicalData.iSpiro.sitesMinimal.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'countryId': countryId || ''
			});

			$log.debug('clinicalDataService.getSites with url: {url}', {
				url
			});
			return $http.get(url, {
				cache: true,
				params: {
					cacheContext: 'clinicalData'
				}
			});
		}

		function getQrCode(siteId, subjectId, actionId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.qrCode
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  siteId,
					  subjectId,
					  actionId
				  });

			return $http.get(url);
		}

		function getScheduledEvents(siteId, subjectKey, from, to) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.scheduledEvents
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  siteId,
					  subjectKeyFilter: subjectKey || '',
					  from,
					  to
				  });

			return $http.get(url);
		}

		function cancelScheduledEvent(scheduledEventId, siteId, subjectKey, visitId, visitScheduleTaskId,
									  scheduleDateTime) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.cancelScheduledEvent
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  siteId,
					  subjectKey: subjectKey || '',
					  scheduledEventId,
					  visitId,
					  visitScheduleTaskId,
					  scheduleDateTime
				  });

			return $http.delete(url);
		}

		function getCreateSubjectData(countryId, siteId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.createSubject.data.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'countryId': countryId || '0',
				'siteId': siteId || '0'
			});

			return $http.get(url);
		}

		function createSubject(subject) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.createSubject.create.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId
			});

			return $http.post(url, subject);
		}

		function editSubject(payload, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.editSubject.update.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				subjectId
			});

			return $http.put(url, payload);
		}

		function updateDeviceValues(payload, subjectId, forDispense, isRemoteAction) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.editSubject.updateDeviceValues.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				subjectId,
				'forDispense': forDispense ? 'true' : 'false',
				'isRemoteAction': isRemoteAction ? 'true' : 'false'
			});

			return $http.put(url, payload);
		}

		function getVisits() {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.visits.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId
			});

			return $http.get(url);
		}

		function getVisitTasks(visitId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.visitTasks.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				visitId
			});

			return $http.get(url);
		}

		function getEditSubjectData(countryId, siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.editSubject.data.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'countryId': countryId || '0',
				'siteId': siteId || '0',
				subjectId
			});

			return $http.get(url);
		}

		function getDispenseSubjectData(countryId, siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.editSubject.dispense.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'countryId': countryId || '0',
				'siteId': siteId || '0',
				subjectId
			});

			return $http.get(url);
		}

		function getEditDeviceValueData(countryId, siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.editSubject.editDeviceValues.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'countryId': countryId || '0',
				'siteId': siteId || '0',
				subjectId
			});

			return $http.get(url);
		}


		function scheduleEvent(newEvent) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.scheduleEvent.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId
			});

			return $http.post(url, newEvent);
		}

		function rescheduleEvent(rescheduledEvent) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.rescheduleEvent.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId
			});

			return $http.put(url, rescheduledEvent);

		}

		function activateVideoSession(siteId, technicalSubjectId, subjectKey, visitId, visitScheduleTaskId,
									  scheduleDateTime, scheduledEventId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.iSpiro.activateVideoSession
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  siteId,
					  subjectKey,
					  technicalSubjectId,
					  visitId,
					  visitScheduleTaskId,
					  scheduleDateTime,
					  scheduledEventId,
				  });

			return $http.post(url);
		}
	}
})();
