(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('ContactUsCtrl', ContactUsController);

	/**
	 * @ngInject
	 */
	function ContactUsController($log, appI18n, dialogService, contactusService,$state,contactService, appConfig,
							helpService) {
		$log = $log.getInstance('ContactUsController');
		$log.debug('ContactUsCtrl:: loaded');

		var vm = this;
		vm.data = contactService.getSharedData();
		vm.model = {};
		vm.isLoading = true;
		vm.submit = submit;
		vm.addEmail = addEmail;
		vm.removeEmail = removeEmail;
		vm.showEmails = showEmails;
		vm.isValidEmail = isValidEmail;
		vm.openHelp = openHelp;
		vm.showSuccess = false;

		active();
		function active() {
			vm.serverErrorMessage = appI18n.translateImmediate('error');
			vm.title = appI18n.translateImmediate('help.contactus.title');
			contactusService.getSiteInfo().then((response) => {
				vm.siteInfo = response.data;
				vm.isLoading = false;
			});
		}

		function openHelp() {
			var moduleId = $state.current.moduleId || appConfig.constants.defaultHelpModuleId;
			helpService.showModuleHelp(moduleId);
		}

		function addEmail() {
			vm.model.emails = vm.model.emails || [];
			if (vm.model.emails.indexOf(vm.model.cc) === -1) {
				if (!isValidEmail()) {
					return;
				}
				vm.model.emails.push(vm.model.cc);
			}

			vm.model.cc = '';
		}

		function removeEmail(email) {
			_.pull(vm.model.emails, email);
		}

		function showEmails() {
			return vm.model.emails && vm.model.emails.length > 0;
		}

		function isValidEmail() {
			vm.errorEmail = '';
			var value = vm.model.cc;

			if(!value ||  value.length === 0) {
				return false;
			}

			var pattern = appConfig.constants.patterns.emailPattern;
			var rexp = new RegExp(pattern);

			if (!rexp.test(value)) {
				vm.errorEmail = appI18n.translateImmediate('app.messages.emailPatternField');
				return false;
			}
			return true;
		}

		function submit() {
			if (vm.contactForm.$invalid) {
				return;
			}

			vm.errorMessage = '';
			vm.isLoading = true;
			var model = vm.model;
			contactusService.submit(model).then((response) => {
				vm.isLoading = false;
				$log.debug('sent');
				if (response && response.data) {
					clearData();
					vm.showSuccess = true;
				} else {
					vm.errorMessage = vm.serverErrorMessage;
				}

			}, (error) => {
				vm.isLoading = false;
				$log.error(`can't sent data: ${error.data} ${error.statusText}`);
				vm.errorMessage = error.data || error.statusText || vm.serverErrorMessage;
			});
		}

		function clearData() {
			contactService.clearSharedData();
		}

		function getLabel(label) {
			return appI18n.translateImmediate(label) || '';
		}

		function dateIsInPast(dateString) {
			if (!dateString) {
				return true;
			}
			var dob = parseDate(dateString);
			var now = new Date();
			var tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
			return dob < tomorrow;
		}
		function parseDate(s) {
			var months = {
				jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
				jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
			};
			var p = s.split('-');
			if (p.length === 3) {
				return new Date(p[2], months[p[1].toLowerCase()], p[0]);
			}
			// for partial dob, return minimum possible value
			else if (p.length === 2) {
				return new Date(p[1], months[p[0].toLowerCase()], 1);
			}
			else if (p.length === 1) {
				return new Date(p[0], 0, 1);
			}
			return null;
		}

		vm.contactFormFields = [
			{
				className: 'display-flex',
				fieldGroup: [
					{
						className: 'flex-1',
						type: 'esInput',
						wrapper: 'esColumnControlWrap',
						key: 'lastName',
						templateOptions: {
							label: getLabel('help.contactus.last-name'),
							id: 'lastName',
							required: true
						},
						ngModelElAttrs: {
							maxlength: '30'
						}
					},
					{
						className: 'flex-1',
						type: 'esInput',
						wrapper: 'esColumnControlWrap',
						key: 'firstName',
						templateOptions: {
							label: getLabel('help.contactus.first-name'),
							id: 'firstName',
							required: true
						},
						ngModelElAttrs: {
							maxlength: '30'
						}

					}
				]
			},
			{
				className: 'display-flex',
				fieldGroup: [
					{
						className: 'flex-1',
						type: 'esInput',
						wrapper: 'esColumnControlWrap',
						key: 'phone',
						templateOptions: {
							label: getLabel('help.contactus.phone'),
							id: 'phone',
							required: true,
							patternValidationMessage: appI18n.translateImmediate('app.messages.phonePatternField'),
							pattern: '^(?=\\+?[\\d -]{1,30}[xX]?[\\d -]{1,30}$)(?=^(?:(?![-]{2}).)*$).*$'
						},
						ngModelElAttrs: {
							maxlength: '30'
						}
					},
					{
						className: 'flex-1',
						type: 'esInput',
						wrapper: 'esColumnControlWrap',
						key: 'email',
						templateOptions: {
							label: getLabel('help.contactus.email'),
							required: true,
							id: 'email',
							patternValidationMessage: appI18n.translateImmediate('app.messages.emailPatternField'),
							pattern: appConfig.constants.patterns.emailPattern
						},
						ngModelElAttrs: {
							maxlength: '150'
						}
					}
				]
			},
			{
				template: `<div class="es-content-box"><h3 class="top20">${getLabel('help.contactus.issue-description')}</h3></div>`
			},
			{
				key: 'issueDate',
				type: 'datePickerInline',
				templateOptions: {
					label: getLabel('help.contactus.issue-date'),
					id: 'issueDate',
					required: true,
					placeholder: 'DD-MMM-YYYY',
					validationPattern: "^((((0?[1-9]|[12][0-9]|3[01])[-]([Jj][Aa][Nn]|[Mm][Aa][Rr]|[Mm][Aa][Yy]|[Jj][Uu][Ll]|[Aa][Uu][Gg]|[Oo][Cc][Tt]|[Dd][Ee][Cc]))|((0?[1-9]|[12][0-9]|30)[-]([Aa][Pp][Rr]|[Jj][Uu][Nn]|[Ss][Ee][Pp]|[Nn][Oo][Vv]))|((0?[1-9]|[12][0-9])[-]([Ff][Ee][Bb])))[-]((19|20)\\d\\d))?$"
				},
				validators: {
					customValidation: {
						expression: function (viewValue, modelValue, scope) {
							var value = modelValue || viewValue;
							var rexp = new RegExp(scope.to.validationPattern);
							return !value || rexp.test(value);
						},
						message: () => getLabel('app.messages.dateFormatForPicker')
					},
					dobInPast: {
						expression: function (viewValue, modelValue, scope) {
							var value = modelValue || viewValue;

							var rexp = new RegExp(scope.to.validationPattern);
							if (!rexp.test(value)) return true;

							return dateIsInPast(value);
						},
						message: () => getLabel('app.messages.DateInPast')
					}
				}
			},
			{
				key: 'issue',
				type: 'esSgTextArea',
				templateOptions: {
					className: '-es-size-from-rows',
					required: true,
					label: getLabel('help.contactus.issue'),
					rows: 5,
					placeholder: '',
					id: 'issue'
				},
				ngModelElAttrs: {
					maxlength: '5000'
				}
			},
			{
				key: 'haveContacted',
				type: 'radioInline',
				templateOptions: {
					labelClass: 'contacted-label',
					controlDivClass: 'contacted-control',
					label: getLabel('help.contactus.previously-contact'),
					options: [
						{
							"name": getLabel('help.contactus.Yes'),
							"value": "true"
						},
						{
							"name": getLabel('help.contactus.No'),
							"value": "false"
						}]
				}
			},
			{
				type: 'inputInline',
				key: 'ticketNumber',
				templateOptions: {
					labelClass: 'ticket-label',
					controlDivClass: 'ticket-control',
					label: getLabel('help.contactus.previously-contact-ticket'),
					id: 'ticketNumber'
				},
				hideExpression: 'model.haveContacted !== "true"',
				ngModelElAttrs: {
					maxlength: '100'
				}

			}

		];

	}
})();
