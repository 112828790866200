(function() {

	'use strict';
	angular
		.module("portalApp")
		.service("sqfService", SqfService);

	/**
	 * @ngInject
	 */
	function SqfService($log, $http, appConfig,appSession) {
		$log = $log.getInstance("SqfService");
		$log.debug("SqfService loaded");

		var sqfService = {
			getSqfs: getSqfs,
			getSitePIInfo: getSitePIInfo,
			saveSitePIInfo: saveSitePIInfo,
			getTimezones: getTimezones,
			getCountries: getCountries,
			getCountriesWithId: getCountriesWithId,
			getUsStates: getUsStates,
			getSqfSummary: getSqfSummary,
			deleteContact: deleteContact,
			submitSqf: submitSqf,
			deleteSqf: deleteSqf,
			getContact: getContact,
			getContacts: getContacts,
			saveContact: saveContact,
			getRoleContacts: getRoleContacts,
			saveRoleContacts: saveRoleContacts,
			getSiteInfo: getSiteInfo,
			saveSiteInfo: saveSiteInfo,
			registerSqfWizardHandler: registerSqfWizardHandler,
			registerSummaryHandler: registerSummaryHandler,
			registerSitePIHandler: registerSitePIHandler,
			registerContactHandler: registerContactHandler,
			registerRolesHandler: registerRolesHandler,
			registerSiteInfoHandler: registerSiteInfoHandler,
			showSqfWizard: showSqfWizard,
			showSitePIWizard: showSitePIWizard,
			showContactWizard: showContactWizard,
			showRolesView: showRolesView,
			showSiteInfoView: showSiteInfoView,
			showSummaryView: showSummaryView,
			getStudyName: getStudyName
		};

		var sqfWizardHandler;
		var sitePIHandler;
		var contactHandler;
		var rolesHandler;
		var siteInfoHandler;
		var summaryHandler;
		return sqfService;

		function getStudyName() {
			var result = '';
			var study = appSession.getStudy();
			if (study && study.number) {
				result = ` ${study.name} (${study.number})`;
			}
			return result;
		}

		function registerSqfWizardHandler(handler) {
			sqfWizardHandler = handler;
		}

		function registerSummaryHandler(handler) {
			summaryHandler = handler;
		}

		function registerSitePIHandler(handler) {
			sitePIHandler = handler;
		}

		function registerContactHandler(handler) {
			contactHandler = handler;
		}

		function registerRolesHandler(handler) {
			rolesHandler = handler;
		}

		function registerSiteInfoHandler(handler) {
			siteInfoHandler = handler;
		}

		function showSqfWizard(sqfId, hasAdditionalSiteInfo, hasAdditionalSiteInfoAfterSelection) {
			if (sqfWizardHandler) {
				sqfWizardHandler.showSqfWizard(sqfId, hasAdditionalSiteInfo, hasAdditionalSiteInfoAfterSelection);
			}
		}

		function showSitePIWizard(sqfId) {
			if (sitePIHandler) {
				return sitePIHandler.showWizard(sqfId);
			}
			else {
				return null;
			}
		}

		function showSummaryView(sqfId) {
			if (summaryHandler) {
				var promise = summaryHandler.showView(sqfId);
				return promise;
			}
			else {
				return null;
			}
		}

		function showContactWizard(sqfId, contactId, contacts) {
			if (contactHandler) {
				contactHandler.showWizard(sqfId, contactId, contacts);
			}
		}

		function showRolesView(sqfId) {
			if (rolesHandler) {
				rolesHandler.showView(sqfId);
			}
		}

		function showSiteInfoView(sqfId) {
			if (siteInfoHandler) {
				siteInfoHandler.showView(sqfId);
			}
		}

		function getSqfs(submitted, options) {
			const currentStudy = appSession.getStudy();
			var url = submitted ? appConfig.apiUrls.sqf.submittedSqf : appConfig.apiUrls.sqf.savedSqf;
			var url = url.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId
			});
			if (options) {
				url += "&" + options;
			}
			$log.debug(url);
			return $http.get(url);
		}

		function getSitePIInfo(sqfId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.getSitePI.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function saveSitePIInfo(data) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.saveSitePI.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId
			});
			$log.debug(url);
			return $http.post(url, data);
		}

		function getSqfSummary(sqfId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.getSqfSummary.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function getContact(sqfId, contactId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.getContact.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId, contactId: contactId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function getContacts(sqfId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.getContacts.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function saveContact(sqfId, contactId, data) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.saveContact.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId, contactId: contactId
			});
			$log.debug(url);
			return $http.post(url, data);
		}

		function getSiteInfo(sqfId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.getSiteInfo.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function saveSiteInfo(sqfId, data) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.saveSiteInfo.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId
			});
			$log.debug(url);
			return $http.post(url, data);
		}

		function getRoleContacts(sqfId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.getRoleContacts.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function saveRoleContacts(sqfId, data) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.saveRoleContacts.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId
			});
			$log.debug(url);
			return $http.post(url, data);
		}

		function getTimezones() {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.getTimezones.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function getCountries() {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.getCountries.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function getCountriesWithId() {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.getCountriesWithId.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function getUsStates() {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.getUsStates.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId
			});
			$log.debug(url);
			return $http.get(url);
		}

		function deleteContact(sqfId, contactId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.deleteContact.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId, contactId: contactId
			});
			$log.debug(url);
			return $http.delete(url);
		}

		function submitSqf(sqfId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.submitSqf.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId
			});
			$log.debug(url);
			return $http.put(url, {});
		}

		function deleteSqf(sqfId) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sqf.deleteSqf.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				sqfId: sqfId
			});
			$log.debug(url);
			return $http.delete(url);
		}
	}
})();
