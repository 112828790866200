/*
 * countriesInfoPopupComponent
 * */

import templateUrl from './countriesInfoPopup.html';
import controller from './countriesInfoPopup.controller';
import './countriesInfoPopup.scss';

const countriesInfoPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default countriesInfoPopupComponent;
