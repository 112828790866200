const popupService = () => {

	const popupAPIs = [];

	return {
		// Private methods. Don't use them directly
		register(name) {

			const $API = {
				props: null,
				show(config) {
					this.props = config;
					this.open = true;

					return new Promise((res, rej) => {
						this.resolve = res;
						this.reject = rej;
					});
				},
				hide(response) {
					this.open = false;
					this.props = null;
					this.resolve(response);
					this.resolve = null;
					this.reject = null;
				}
			};

			if (popupAPIs[name]) {
				throw new Error(`Popup with name ${name} already exists.`);
			}
			popupAPIs[name] = $API;

			return $API;
		},
		unregister(name) {
			popupAPIs[name] = null;
		},
		// Private methods. Don't use them directly (end)

		show(popupName, config) {
			const pp = this.get(popupName);

			return pp && pp.show(config);
		},
		hide(popupName, response) {
			const pp = this.get(popupName);

			pp.hide(response);
		},
		get(popupName) {
			const popup = popupName && popupAPIs[popupName];

			if (!popup) {
				new Error(`Popup with name ${popupName} does not exist`);
				return;
			}

			return popup;
		}
	};
};

export default popupService;

