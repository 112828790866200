/*
 * visitCalendarLegendController
 * */

class VisitCalendarLegendController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('VisitCalendarLegendController');
		this.$log.debug('loaded');
	}
}

export default VisitCalendarLegendController;
