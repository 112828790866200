/*
 * contentMessageModule
 * */

import contentMessageComponent from './contentMessage.component';

const contentMessageModule = angular.module('app.common.contentMessage', [])
	.component('contentMessage', contentMessageComponent)
	.name;

export default contentMessageModule;
