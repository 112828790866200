import templateUrl from './ertNgWizardToolbar.html';
import controller from './ertNgWizardToolbar.controller';
import './ertNgWizardToolbar.scss';

/*eslint-disable indent*/
//TODO: remove this later
const wizardToolbar = {
    restrict: 'E',
    bindings: {
        title: '@'
    },
    templateUrl,
    controller,
    transclude: true
};

export default wizardToolbar;
/*eslint-enable indent*/
