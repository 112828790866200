import templateUrl from './fileItem.html';
import controller from './fileItem.controller';
import './fileItem.scss';

const fileItemComponent = {
	restrict: 'E',
	bindings: {
		item: '<',
		readOnly: '<',
		addedBy: '@',
		onFileOpen: '&',
		onFileRemove: '&'
	},
	templateUrl,
	controller
};

export default fileItemComponent;

