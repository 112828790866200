/*
 * editContactDataPopupController
 * */

class EditContactDataPopupController {
	constructor($log, $stateParams, eproService, configurationService, appSession, appI18n, dialogService, $timeout,
				clinicalDataService, popupService, loadingIndicatorService) {
		'ngInject';
		this.$log = $log;
		this.$stateParams = $stateParams;
		this.eproService = eproService;
		this.configurationService = configurationService;
		this.appSession = appSession;
		this.appI18n = appI18n;
		this.dialogService = dialogService;
		this.clinicalDataService = clinicalDataService;
		this.popupService = popupService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.asterisks = '********';
		this.$timeout = $timeout;

		this.actions = [{
			action: () => {
				this.doEditContact();
			}, cssClass: '-es-primary',
			displayName: 'app.buttons.ok'
		}, {
			action: () => {
				this.closeEditContact();
			},
			displayName: 'app.buttons.cancel'
		}];
	}

	$onInit() {
		this.$log = this.$log.getInstance('EditContactDataPopupController');
		this.$log.debug('loaded');

		this.$API = {
			open: (row) => {
				this.row = row;
				this.subjectId = row.subjectKey;
				this.isVisible = true;
			},
			close: () => {
				this.isVisible = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({ $API: this.$API });
		}
	}

	closeEditContact() {
		this.isVisible = false;
	}

	toggleDeletePhone() {
		this.model.deletePhone = !this.model.deletePhone;
	}

	toggleDeleteMobile() {
		this.model.deleteMobile = !this.model.deleteMobile;
	}

	toggleDeleteEmail() {
		this.model.deleteEmail = !this.model.deleteEmail;
	}

	initForm(eproSubjectId) {
		this.model = {};
		this.model.phoneFormat = 0;
		this.model.mobileFormat = 0;
		this.contactData = {};
		this.validation = {
			required: {},
			errors: {}
		};

		this.eproSubjectId = eproSubjectId;

		return this.eproService.getEditContactData(eproSubjectId).then((response) => {
			this.contactData = response.data;

			this.placeholderTextEmail = this.appI18n.translateImmediate('app.common.email');
			this.placeholderTextPhone = this.appI18n.translateImmediate('app.common.phone');
			this.placeholderTextMobile = this.appI18n.translateImmediate('app.common.mobile');

			this.emailPlaceholder = this.contactData.hasEmail
				? this.asterisks
				: this.placeholderTextEmail;
			this.phonePlaceholder = this.contactData.hasPhone
				? this.asterisks
				: this.placeholderTextPhone;
			this.mobilePlaceholder = this.contactData.hasMobile
				? this.asterisks
				: this.placeholderTextMobile;


			if (response.data.showInactiveWarning) {
				this.showInactiveWarning();
			}
			return response;
		}, () => {
			this.setFormError('clinicaldata.subject.contact.errors.genericLoadError');
		});
	}

	validateForm() {
		this.validation.errors.formError = null;
		this.validateEmail();
		const validPhoneNumbers = this.validatePhoneNumbers();

		if (validPhoneNumbers && !this.model.deleteEmail && !this.model.deletePhone && !this.model.deleteMobile &&
			!this.model.email && ((this.model.phoneFormat === 0 && !this.model.phoneUS && !this.model.mobileUS) ||
				(this.model.phoneFormat === 1 && !this.model.phoneInternational && !this.model.mobileInternational))) {
			this.setFormError('clinicaldata.subject.contact.errors.nothingToDo');
			return false;
		}

		for (const err in this.validation.errors) {
			if (this.validation.errors[err]) {
				return false;
			}
		}
		return true;
	}

	setFormError(errorMessage) {
		this.loadingIndicatorService.hide();
		this.appI18n.translate(errorMessage).then((translation) => {
			this.validation.errors.formError = translation;
		});
	}

	validatePhoneNumbers() {
		let valid = true;

		if (this.model.phoneFormat === 0) {
			valid = this.validatePhoneUS() && valid;
			valid = this.validateMobileUS() && valid;
			this.validation.errors.phoneFormatInternational = false;
			this.validation.errors.mobileFormatInternational = false;
		} else {
			valid = this.validatePhoneInternational() && valid;
			valid = this.validateMobileInternational() && valid;
			this.validation.errors.phoneFormatUS = false;
			this.validation.errors.mobileFormatUS = false;
		}

		return valid;
	}

	updateUiMaskField(fieldName, hasContactData) {
		if (hasContactData && !this.model[fieldName]) {
			// workaround to restore the asterisks after leaving an ui-mask field
			this.model[fieldName] = undefined;
			this.$timeout(() => {
				this.model[fieldName] = '';
				this.addressForm[fieldName].$setViewValue('');
			}, 0);
		}
	}

	validatePhoneUS() {
		if (this.model.phoneUS && this.model.phoneUS.length !== 10) {
			this.validation.errors.phoneFormatUS = true;
			return false;
		}

		this.validation.errors.phoneFormatUS = false;
		return true;
	}

	validatePhoneInternational() {
		if (this.model.phoneInternational && this.model.phoneInternational.length > 15) {
			this.validation.errors.phoneFormatInternational = true;
			return false;
		}

		this.validation.errors.phoneFormatInternational = false;
		return true;
	}

	validateMobileUS() {
		if (this.model.mobileUS && this.model.mobileUS.length !== 10) {
			this.validation.errors.mobileFormatUS = true;
			return false;
		}

		this.validation.errors.mobileFormatUS = false;
		return true;
	}

	validateMobileInternational() {
		if (this.model.mobileInternational && this.model.mobileInternational.length > 15) {
			this.validation.errors.mobileFormatInternational = true;
			return false;
		}

		this.validation.errors.mobileFormatInternational = false;
		return true;
	}

	enterEmailField() {
		// according to the requirements, we need to remove the asterisks
		// if the field gets the focus and has stored contact data
		if (this.contactData && this.contactData.hasEmail) {
			this.emailPlaceholder = '';
		}
	}

	leaveEmailField() {
		// if the user enters the field and navigates to another field without
		// adding data, we need to restore the asterisks
		if (!this.model.email && this.contactData && this.contactData.hasEmail) {
			this.emailPlaceholder = this.asterisks;
		}
	}

	validateEmail() {
		this.validation.errors.emailFormat = !this.addressForm.email.$valid;
	}

	showInactiveWarning() {
		this.popupService.show('confirmation', {
			headerTitle: this.appI18n.translateImmediate('clinicaldata.subject.create.inactiveDelayTitle'),
			contentMsg: this.appI18n.translateImmediate('clinicaldata.subject.create.inactiveDelay')
		}).then((resp) => {
			if (resp.answer === 'ok') {
				//ok, just close
			} else {
				this.closeEditContact();
			}
		}, (error) => {
			this.$log.error(`Error: ${error}`);
		});
	}

	doEditContact() {
		const isValid = this.validateForm();

		if (isValid) {
			this.loadingIndicatorService.show();
			this.$log.debug(angular.toJson(this.row));
			const siteId = this.row.siteId;

			this.eproService.editContact(siteId, this.eproSubjectId, this.model).then((response) => {
				if (response.data.isValidationError) {
					this.setGenericSaveError(response);
				} else {
					this.closeEditContact();
				}
				this.loadingIndicatorService.hide();
			}, (response) => {
				this.loadingIndicatorService.hide();
				this.setGenericSaveError(response);
			});
		}
	}

	setGenericSaveError(response) {
		const msg = (response.data && response.data.translationKey)
			? response.data.translationKey : 'clinicaldata.subject.create.errors.genericSaveError';

		this.setFormError(msg);
	}
}

export default EditContactDataPopupController;
