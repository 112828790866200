import templateUrl from './ertInput.html';
import controller from './ertInput.controller';
import './ertInput.scss';

const ertInput = {
	bindings: {
		name: '@',
		id: '@',
		value: '=',
		options: '<',
		disable: '<',
		onChange: '&',
		componentReady: '&'
	},
	transclude: true,
	require: {
		fg: '^form'
	},
	templateUrl,
	controller
};

export default ertInput;
