export default class NotificationService {

	constructor($log, appSession) {
		'ngInject';
		this.$log = $log.getInstance('notification.service', 'color:green');
		this.appSession = appSession;
		this.notifications = require('./notification');
		this.notifications.configure({ appearanceWidth: 'small' });
	}

	showInfo(text) {
		this.show({
			text,
			type: 'info'
		});
	}

	showSuccess(text) {
		this.show({
			text,
			type: 'success'
		});
	}

	showWarning(text) {
		this.show({
			text,
			type: 'warning'
		});
	}

	showError(text) {
		this.show({
			text,
			type: 'danger'
		});
	}

	show(config) {
		this.notifications.message(config);
	}
}
