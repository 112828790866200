/*
 * openDcrTableDirective
 * */

import templateUrl from './openDcrTable.html';
import controller from './openDcrTable.controller';
import './openDcrTable.scss';

const openDcrTableDirective = () => {
	return {
		scope: {
			row: '<'
		},
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		controller,
		link(/*scope, element, attr, ctr*/) {},
		replace: true
	};
};

export default openDcrTableDirective;
