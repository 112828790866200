/*TODO: REMOVE THIS*/

(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('CalendarController', CalendarController);

	/*
	 * @ngInject
	 */
	function CalendarController($log, $state, $stateParams, eproService, configurationService,
															appSession, oDataHelper, $filter, appConfig, appI18n, $window,
															dialogService, clinicalDataService) {

		var vm = this;
		$log = $log.getInstance('CalendarController');
		$log.debug(' loaded');

		vm.currentDate = Date.now();

		vm.calendarActive = false;
		vm.calendarItems = [];
		vm.calendarActions = [];
		vm.currentPage = 1;
		vm.pageSize = 30;
		vm.fromFilter = '';
		vm.toFilter = '';
		vm.customFilterVisible = false;
		vm.isClearButtonVisible = false;
		vm.isPhoneCalendar = false;
		vm.isVisitCalendar = false;

		vm.getCountries = getCountries;
		vm.getSites = getSites;
		vm.getSubjects = getSubjects;
		vm.refreshSites = refreshSites;
		vm.refreshSubjects = refreshSubjects;
		vm.refreshSubject = refreshSubject;
		vm.updateCalendarData = updateCalendarData;

		vm.showCalendar = showCalendar;
		vm.getVisitIconCss = getVisitIconCss;
		vm.goToSubject = goToSubject;
		vm.switchToVisitCalendarView = switchToVisitCalendarView;
		vm.switchToPhoneCalendarView = switchToPhoneCalendarView;

		vm.nextPage = nextPage;
		vm.previousPage = previousPage;
		vm.switchMode = switchMode;

		vm.calendarMode = true;

		vm.resetFilter = resetFilter;
		vm.getODataQuery = getODataQuery;
		vm.filterData = filterData;
		vm.isFilterButtonDisabled = isFilterButtonDisabled;
		vm.setPredefinedFilter = setPredefinedFilter;
		vm.showCustomFilter = showCustomFilter;
		vm.clearFilterValues = clearFilterValues;

		activate();

		function activate() {
			appI18n.translate([
				'clinicaldata.calendar.filterCurrentMonth',
				'clinicaldata.calendar.filterTwoMonths',
				'clinicaldata.calendar.filterThreeMonths',
				'clinicaldata.calendar.filterFromTo',
				'clinicaldata.calendar.noResultsTxt',
				'clinicaldata.calendar.filterSelect',
				'clinicaldata.calendar.phoneTitle',
				'clinicaldata.calendar.siteVisitTitle'
			]).then(function (translations) {
				vm.filterText1 = translations['clinicaldata.calendar.filterCurrentMonth'];
				vm.filterText2 = translations['clinicaldata.calendar.filterTwoMonths'];
				vm.filterText3 = translations['clinicaldata.calendar.filterThreeMonths'];
				vm.filterTextFromTo = translations['clinicaldata.calendar.filterFromTo'];
				vm.filterTextSelect = translations['clinicaldata.calendar.filterSelect'];
				vm.noResultsTxt = translations['clinicaldata.calendar.noResultsTxt'];
				vm.phoneTitleTxt = translations['clinicaldata.calendar.phoneTitle'];
				vm.siteTitleTxt = translations['clinicaldata.calendar.siteVisitTitle'];

				vm.currentFilterText = vm.filterTextSelect;

			});

			clinicalDataService.setCalendarHandler(vm);
			configurationService.getStudySettings().then(
				function(data) {
					vm.isPhoneCalendar = data.hasPhoneCalendar;
					vm.isVisitCalendar = data.hasVisitCalendar;
					vm.iconActions = [{action: printDetails, iconName: 'print'}];

				}
			);
		}

		function switchMode(mode) {
			vm.currentPage = 1;
			if (mode) {
				vm.switchToVisitCalendarView();
			}
			else {
				vm.switchToPhoneCalendarView();
			}

		}

		function printDetails() {
			if (vm.isLoading) {
				return;
			}
			$window.print();
		}

		function isFilterButtonDisabled() {
			return vm.currentFilterText === vm.filterTextSelect;
		}

		function switchToPhoneCalendarView() {
			vm.calendarTitle = vm.phoneTitleTxt;
			vm.currentCalendarMode = false;
			vm.clearFilterValues();
			vm.updateCalendarData();
		}

		function switchToVisitCalendarView() {
			vm.calendarTitle = vm.siteTitleTxt;
			vm.currentCalendarMode = true;
			vm.clearFilterValues();
			vm.updateCalendarData();
		}

		function setPredefinedFilter(nextMonths) {
			var date = new Date();
			var from = new Date(date.getFullYear(), date.getMonth(), 1);
			vm.selectedFromFilter = $filter('date')(new Date(from), 'dd-MMM-yyyy');
			var to = new Date(date.getFullYear(), date.getMonth() + nextMonths, 0);
			vm.selectedToFilter = $filter('date')(new Date(to), 'dd-MMM-yyyy');
			vm.customFilterVisible = false;
			if (vm.currentFilterText === vm['filterText' + nextMonths]) {
				vm.isClearButtonVisible = true;

			}
			else {
				vm.currentFilterText = vm['filterText' + nextMonths];
				vm.isClearButtonVisible = false;
			}
		}

		function showCustomFilter() {
			if (!vm.customFilterVisible) {
				vm.isClearButtonVisible = false;
				vm.currentFilterText = vm.filterTextFromTo;
				vm.customFilterVisible = true;
				vm.fromFilter = null;
				vm.toFilter = null;
				vm.selectedFromFilter = '';
				vm.selectedToFilter = '';
			}
		}

		function comparator(actual, expected) {
			return actual == expected;
		}

		function refreshSites(country) {
			vm.currentPage = 1;
			vm.selectedCountryId = country.countryId;
			vm.selectedCountry = country;
			vm.selectedSiteId = 0;
			vm.selectedSubjectId = 0;
			getSites(country.countryId);
			vm.subjects = [{subjectKey: 'None'}];
			vm.selectedSubject = vm.subjects[0];
			vm.calendarItems = [];
			vm.clearFilterValues();
		}

		function refreshSubjects(site) {
			vm.currentPage = 1;
			vm.selectedSiteId = site.siteId;
			vm.selectedSite = site;
			vm.selectedSubjectId = 0;
			vm.clearFilterValues();
			if (site.siteId === 0) {
				vm.subjects = [{subjectKey: 'None'}];
				vm.selectedSubject = vm.subjects[0];
				vm.calendarItems = [];
			}
			else {
				getSubjects();
				vm.updateCalendarData();
			}
		}

		function refreshSubject(subject) {
			vm.currentPage = 1;
			vm.selectedSubjectId = subject.subjectId;
			vm.selectedSubject = subject;
			vm.clearFilterValues();
			vm.updateCalendarData();
		}

		function getSites() {
			clinicalDataService.getSitesMinimal(null, vm.selectedCountryId)
				.then(function(response) {
					vm.sites = response.data;
					vm.sites.unshift({displaySiteName: 'Please select', siteId: 0});
					var sites = $filter('filter')(vm.sites, vm.selectedSiteId, comparator);
					if (sites.length === 1) {
						vm.selectedSite = sites[0];
					}
				});
		}

		function getSubjects() {
			clinicalDataService.getSubjectsMinimal(vm.selectedCountryId, vm.selectedSiteId)
				.then(function(response) {
					vm.subjects = response.data;
					vm.subjects.unshift({subjectKey: 'All', subjectId: 0});
					var subjects = $filter('filter')(vm.subjects, vm.selectedSubjectId || 0, comparator);
					if (subjects.length === 1) {
						vm.selectedSubject = subjects[0];
					}
				});
		}

		function getCountries() {
			clinicalDataService.getCountriesMinimal()
				.then(function(response) {
					if (!response.data.$$state) {
						vm.countries = response.data;
						var countries = $filter('filter')(vm.countries, vm.selectedCountryId, comparator);
						if (countries.length === 1) {
							vm.selectedCountry = countries[0];
						}
					}
				});
		}

		function goToSubject(item, includeVisit) {
			var params = {
				country: vm.selectedCountryId,
				site: vm.selectedSiteId,
				subject: item.subjectId,
				eproSubject: item.eproSubjectId
			};
			if (includeVisit) {
				params['visit'] = item.visitName;
			}
			$state.go(appConfig.routes.clDataVisits, params);
		}

		function filterData() {
			vm.currentPage = 1;
			if (vm.customFilterVisible) {
				vm.fromFilter = vm.rangeFromFilter;
				vm.toFilter = vm.rangeToFilter;
			}
			else {
				vm.fromFilter = vm.selectedFromFilter;
				vm.toFilter = vm.selectedToFilter;
			}
			vm.isClearButtonVisible = true;
			vm.updateCalendarData();
		}

		function resetFilter() {
			vm.clearFilterValues();
			vm.updateCalendarData();
			vm.isClearButtonVisible = false;
		}

		function clearFilterValues() {
			vm.isClearButtonVisible = false;
			vm.customFilterVisible = false;
			vm.currentFilterText = vm.filterTextSelect;
			vm.fromFilter = '';
			vm.toFilter = '';
			vm.selectedFromFilter = '';
			vm.selectedToFilter = '';
			vm.rangeFromFilter = '';
			vm.rangeToFilter = '';
		}

		function showCalendar(countryId, siteId, subjectId) {
			vm.currentFilterText = vm.filterTextSelect;
			vm.clearFilterValues();

			vm.selectedCountryId = countryId;
			vm.selectedSiteId = siteId;
			vm.selectedSubjectId = subjectId;

			vm.currentPage = 1;
			vm.calendarActive = true;
			vm.calendarItems = [];

			vm.getCountries();
			vm.getSites();
			vm.getSubjects();

			if (vm.isVisitCalendar) {
				vm.calendarTitle = vm.siteTitleTxt;
			}
			else {
				vm.calendarTitle = vm.phoneTitleTxt;
			}

			vm.currentCalendarMode = vm.isVisitCalendar;
			vm.updateCalendarData();
		}

		function updateCalendarData() {
			vm.isCalendarLoading = true;
			if (vm.selectedSubjectId) {
				clinicalDataService.getSubjectCalendar(vm.selectedCountryId, vm.selectedSiteId,
																							 vm.selectedSubjectId, vm.currentCalendarMode,
																							vm.fromFilter, vm.toFilter, vm.getODataQuery())
					.then(function (response) {
						vm.calendarItems = response.data;
						vm.isCalendarLoading = false;
					});
			}
			else {
				clinicalDataService.getSiteCalendar(vm.selectedCountryId, vm.selectedSiteId,
																						vm.currentCalendarMode,
																						vm.fromFilter, vm.toFilter, vm.getODataQuery())
					.then(function (response) {
						vm.calendarItems = response.data;
						vm.isCalendarLoading = false;
					});
			}
		}

		function nextPage() {
			if (!vm.isCalendarLoading && vm.calendarItems.length >= vm.pageSize) {
				vm.currentPage += 1;
				vm.updateCalendarData();
			}
		}

		function previousPage() {
			if (vm.currentPage > 1) {
				vm.currentPage -= 1;
				vm.updateCalendarData();
			}
		}

		function getODataQuery() {
			var query = oDataHelper.buildUriFor('');
			if (vm.currentPage > 1) {
				query.skip((vm.currentPage - 1) * vm.pageSize);
			}
			query.top(vm.pageSize);
			return query.toUri();
		}

		function getVisitIconCss(visitIconStyle) {
			switch (visitIconStyle) {
			case 1: return 'event-icon icon-calendar-normal normal-visit-color ert-visit-checked';
			case 2: return 'event-icon icon icon-calendar-unscheduled unscheduled-visit-color';
			case 3: return 'event-icon icon icon-calendar-termination termination-visit-color';
			case 4: return 'event-icon icon icon-calendar-randomization normal-visit-color ert-visit-checked';
			case 5: return 'event-icon icon icon-calendar-normal planned-visit-color';
			case 6: return 'event-icon icon icon-calendar-normal-overdue overdue-visit-color';
			case 7: return 'event-icon icon icon-calendar-randomization-overdue overdue-visit-color';
			case 8: return 'event-icon icon icon-calendar-randomization planned-visit-color';
			}
		}
	}
})();
