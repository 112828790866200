/*
 * notificationMessageComponent
 * */

import templateUrl from './notificationMessage.html';
import controller from './notificationMessage.controller';
import './notificationMessage.scss';

const notificationMessageComponent = {
	templateUrl,
	controller
};

export default notificationMessageComponent;
