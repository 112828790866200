/*
 * ecoaSessionViewerController
 * */

class EcoaSessionViewerController {
	constructor($log, $scope, $state, $stateParams, $filter, $sce, $document, eproService,
				configurationService, userService, appSession,
				appConfig, appI18n, $q, $window) {
		'ngInject';
		this.$log = $log;
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$filter = $filter;
		this.$sce = $sce;
		this.$document = $document;
		this.eproService = eproService;
		this.configurationService = configurationService;
		this.userService = userService;
		this.appSession = appSession;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.$q = $q;
		this.$window = $window;
	}

	getToggleViewTitle(showAllResponses) {
		return showAllResponses ? 'grid.header.btn-hide-available-answers' : 'grid.header.btn-show-available-answers';
	}

	$onInit() {
		this.siteId = +this.$stateParams.site;
		this.eproSubjectId = +this.$stateParams.eproSubject;
		this.eventId = +this.$stateParams.event;
		this.tdtId = +this.$stateParams.tdt;
		this.mdtId = this.$stateParams.mdt ? +this.$stateParams.mdt : undefined;
		this.alertTypeScoring = 1;
		this.$log = this.$log.getInstance('EcoaSessionViewer');
		this.$log.debug(' loaded');

		this.clinicalPrompts = {};
		this.showAllResponses = false;
		this.isIE = !!this.$document[0].documentMode;

		this.language = 1; // set language to english

		this.options = {
			showCollapseButton: true,
			useToggleViewMode: true,
			removeExportControls: true,
			toggleView: () => {
				this.showAllResponses = !this.showAllResponses;
				this.options.toggleViewTitle = this.getToggleViewTitle(this.showAllResponses);
			},
			gridActions: [],
			toggleViewTitle: this.getToggleViewTitle(this.showAllResponses)
		};


		if (!this.isCoaPhone()) {
			this.options.getData = (oDataQuery) => {
				return this.getDailySessions(oDataQuery);
			};
			this.options.columnDefs = [{
				translationKey: 'app.common.date',
				fieldName: 'sessionDate',
				type: 'date',
				formatter: 'dateFormat',
				isDefaultFilter: true
			}];

			if (!this.isEcoaHandheldOrTablet()) {
				this.options.columnDefs.push({
					translationKey: 'clinicaldata.ecoa.hasAlerts',
					fieldName: 'alertCount',
					formatter: 'yesNo',
					type: 'enum',
					enumType: 'number',
					enums: [
						{
							translationKey: 'app.common.yes',
							operator: 'ne',
							values: ['0']
						},
						{
							translationKey: 'app.common.no',
							operator: 'eq',
							values: ['0']
						}
					]
				});
			}
			if (this.isAm3()) {
				this.options.columnDefs.push({
					translationKey: 'clinicaldata.ecoa.hasAcq',
					fieldName: 'hasAcq',
					formatter: 'yesNo',
					type: 'enum',
					enumType: 'number',
					enums: [
						{
							translationKey: 'app.common.yes',
							operator: 'eq',
							values: ['1']
						},
						{
							translationKey: 'app.common.no',
							operator: 'ne',
							values: ['1']
						}
					]
				});
			}
		} else {
			this.options.columnDefs = [{
				translationKey: 'app.common.date',
				fieldName: 'sessionDate',
				type: 'date',
				formatter: 'dateFormat',
				isDefaultFilter: true
			}];
			this.options.removePaginationControls = true;
			this.options.removeFilterControls = true;
			this.options.getData = (oDataQuery) => {
				return this.getEcoaPhoneData(oDataQuery);
			};
		}

		this.selectedDailySession = null;
		this.dailySessions = {};
		this.dateFormat = 'dateFormat';

		this.activate();
	}

	activate() {

		this.appI18n.translate([
			'grid.no-data-text',
			'clinicaldata.ecoa.visitClosed',
			'clinicaldata.ecoa.pef',
			'clinicaldata.ecoa.fev1',
			'clinicaldata.ecoa.fvc',
			'clinicaldata.ecoa.mef25',
			'clinicaldata.ecoa.mef50',
			'clinicaldata.ecoa.module',
			'clinicaldata.ecoa.complete',
			'clinicaldata.ecoa.incomplete',
			'clinicaldata.ecoa.missed',
			'clinicaldata.ecoa.moreAlerts'
		])
			.then((translations) => {
				this.translations = translations;
				this.noResultsTxt = translations['grid.no-data-text'];
				this.visitClosedTxt = translations['clinicaldata.ecoa.visitClosed'];
				this.pef = translations['clinicaldata.ecoa.pef'];
				this.fev1 = translations['clinicaldata.ecoa.fev1'];
				this.fvc = translations['clinicaldata.ecoa.fvc'];
				this.mef25 = translations['clinicaldata.ecoa.mef25'];
				this.mef50 = translations['clinicaldata.ecoa.mef50'];
				this.moduleTxt = translations['clinicaldata.ecoa.module'];
				this.completeTxt = translations['clinicaldata.ecoa.complete'];
				this.incompleteTxt = translations['clinicaldata.ecoa.incomplete'];
				this.missedTxt = translations['clinicaldata.ecoa.missed'];
				this.moreAlertsTxt = translations['clinicaldata.ecoa.moreAlerts'];

				if (this.isEcoaHandheldOrTablet()) {
					this.showAlertsContainer = false;
				} else {
					this.showAlertsContainer = true;
				}

				if (this.isCoaPhone()) {
					this.isCloseVisitLoading = true;
					this.getClosedVisit()
						.then((response) => {
							if (response.data && response.data.visitClosedBy) {
								this.options.rowData = [];
								this.options.isRowDataLoadingComplete = true;
								this.isCloseVisitLoading = false;
								const tsText = this.$filter('ertFilter')(
									response.data.visitClosedTimeStamp, 'dateTimeFormat');

								this.noResultsTxt = supplant(
									this.visitClosedTxt, [tsText, response.data.visitClosedBy]);
								this.visitClosed = true;
							} else {
								this.isCloseVisitLoading = false;
								this.loadData();
							}
						});
				} else {
					this.loadData();
				}
			});
		this.configurationService.getUserPermissionOnSite(this.appConfig.businessPermissions.ecoaAudio, this.siteId)
			.then((perm) => {
				this.userHasEcoaAudioPermission = perm.data;
			}, () => {
				this.userHasEcoaAudioPermission = false;
				this.$log.error('error getting ecoa audio permissions');
			});
	}

	loadData() {
		const requests = [];

		requests.push(this.configurationService.getEproSettings());
		if (this.isAm3()) {
			requests.push(this.eproService.getParameterDefinitions(this.mdtId));
		}

		const defersSettings = this.$q.all(requests);

		defersSettings.then((resolve) => {
			this.settings = resolve[0].data;
			if (resolve.length > 1 && resolve[1].data && resolve[1].data.length === 1) {
				this.parameterDefs = resolve[1].data[0];
				let count = 0;

				if (this.parameterDefs.pefIsUsed) {
					count++;
				}
				if (this.parameterDefs.fev1IsUsed) {
					count++;
				}
				if (this.parameterDefs.fvcIsUsed) {
					count++;
				}
				if (this.parameterDefs.mef50) {
					count++;
				}
				if (this.parameterDefs.mef25) {
					count++;
				}
				this.parameterCount = count;
			} else {
				this.parameterCount = 0;
				this.parameterDefs = {
					hasParameters: false
				};
			}
			this.load = true;
		});

		this.$scope.$watch('$ctrl.options.rowData', () => {
			if (this.options.rowData) {
				if (this.options.rowData.length > 0) {
					this.selectDaily(this.options.rowData[0]);
				}
			}
		});
	}

	isCoaPhone() {
		return this.tdtId === this.appConfig.testDataType.coaPhone;
	}

	isAm3() {
		return this.tdtId === this.appConfig.testDataType.coaAm;
	}

	isEcoaHandheldOrTablet() {
		return this.tdtId === this.appConfig.testDataType.coaDiary ||
			this.tdtId === this.appConfig.testDataType.coaSite ||
			this.tdtId === this.appConfig.testDataType.syndication;
	}

	toggleSession(session) {
		session.uncollapsed = !session.uncollapsed;
		for (let i = 0; i < this.selectedDailySession.sessions.length; i++) {
			if (this.selectedDailySession.sessions[i].uncollapsed !== session.uncollapsed) {
				return;
			}
		}
		this.options.expandAllVisible = !session.uncollapsed;
	}

	hasAlerts(session) {
		if (session.alerts && session.alerts.length > 0) {
			return true;
		}
		return false;
	}

	selectDaily(daily) {
		this.options.expandAllVisible = true;
		this.selectedDailySession = daily;

		if (this.isCoaPhone()) {
			const moduleIds = [];

			for (let i = 0; i < daily.clinicalPrompts.length; ++i) {
				const clinicalPrompts = daily.clinicalPrompts[i];

				this.clinicalPrompts[clinicalPrompts.moduleId] = clinicalPrompts.prompts;
				moduleIds.push(clinicalPrompts.moduleId);
			}

			for (let i = 0; i < daily.sessions.length; ++i) {
				const session = daily.sessions[i];

				session.loadedPrompts = {};
				if (!session.alerts) {
					session.alerts = [];
				}
				if (this.settings.showQuestionnaire) {
					moduleIds.forEach((el) => {
						session.loadedPrompts[el] = true;
					});
				} else {
					session.responses = [{}];
				}
				if (this.settings.showScoringResults) {
					this.getScoringResults(session, daily.sessions[i].eventId)
						.then((response) => {
							session.scorings = response.data;
						}).finally(() => {
							session.dataLoaded = true;
						});
				} else {
					session.dataLoaded = true;
				}
				session.uncollapsed = false;
			}
			this.setGroupHeaders(daily.sessions);
		} else if (daily.eventIdAsString) {
			if (!this.updateCanceler) {
				this.updateCanceler = this.$q.defer();
			} else {
				this.updateCanceler.resolve();
				this.updateCanceler = this.$q.defer();
			}

			this.getSessionData(daily, this.updateCanceler).then((response) => {
				daily.sessions = response.data;

				for (let i = 0; i < daily.sessions.length; ++i) {
					const session = daily.sessions[i];
					const requests = [];

					session.loadedPrompts = {};

					session.alerts = this.getAlerts(session);

					if (this.settings.showScoringResults && !this.isAm3()) {
						requests.push(this.getScoringResults(session, session.eventId));
					}
					if (this.settings.showQuestionnaire) {
						requests.push(this.getResponses(
							session, session.eventId, session.sessionDate,
							session.sessionTime, session.sessionTypeId, session.sessionId));
					}
					if (this.parameterDefs.hasParameters) {
						requests.push(this.getParameters(
							session, session.eventId, session.sessionDate,
							session.sessionTime, session.sessionTypeId));
					}

					const defers = this.$q.all(requests);

					defers.then((resolves) => {
						let questionnaireIndex = 0;

						if (this.settings.showScoringResults && !this.isAm3()) {
							session.scorings = resolves[0].data;
							questionnaireIndex++;
						}
						if (this.settings.showQuestionnaire) {
							const modules = [];

							session.responses = resolves[questionnaireIndex].data;
							session.responses.forEach((el) => {
								const moduleIndex = _.findIndex(modules, {
									'module': el.module,
									'repeatKey': el.repeatKey
								});

								if (moduleIndex < 0) {
									modules.push({
										module: el.module,
										repeatKey: el.repeatKey,
										moduleId: el.moduleId
									});
									this.getClinicalPrompts(session, el.moduleId, this.language);
								}
							});
							session.modules = modules;
						}
						if (this.parameterDefs.hasParameters) {
							session.parameters = resolves[resolves.length - 1].data;
						}
						session.dataLoaded = true;
						this.$log.debug('loaded session');
					});
					session.uncollapsed = false;
				}
				this.setGroupHeaders(daily.sessions);
			});
		} else {
			daily.sessions = [];
		}
	}

	allSessionsLoaded() {
		return _.every(this.selectedDailySession.sessions, { 'dataLoaded': true });
	}

	setGroupHeaders(sessions) {
		sessions.forEach((el) => {
			el.itemCount = 1;
			el.uncollapsed = false;
		});
		this.options.rowDefs = {
			groupHeader: {
				groups: sessions
			}
		};
	}

	getAlerts(session) {
		const alerts = [];

		for (let i = 1; i < 5; i++) {
			if (session[`measurementAlert${i}`]) {
				alerts.push(session[`measurementAlert${i}`]);
			}
		}

		for (let i = 1; i < 15; i++) {
			if (session[`questionAlert${i}`]) {
				alerts.push(session[`questionAlert${i}`]);
			}
		}

		return alerts;
	}

	getParameters(session, eventId, sessionDate, sessionTime, sessionTypeId) {
		return this.eproService.getParameters(
			this.siteId, this.eproSubjectId, eventId, sessionDate,
			sessionTime, sessionTypeId, this.mdtId);
	}

	getResponses(session, eventId, sessionDate, sessionTime, sessionTypeId, sessionId) {
		return this.eproService.getResponses(
			this.siteId, this.eproSubjectId, eventId, sessionDate, sessionTime,
			sessionTypeId, this.tdtId, this.mdtId, sessionId);
	}

	getClinicalPrompts(session, moduleId, languageId) {
		if (!this.clinicalPrompts[moduleId]) {
			this.clinicalPrompts[moduleId] = [];
			this.eproService.getClinicalPrompts(moduleId, languageId, this.mdtId)
				.then((response) => {
					this.clinicalPrompts[moduleId] = response.data;
					session.loadedPrompts[moduleId] = true;
				});
		} else {
			session.loadedPrompts[moduleId] = true;
		}
	}

	getScoringResults(session, eventId) {
		return this.eproService.getScoringResults(this.siteId, this.eproSubjectId,
												  eventId, this.tdtId);
	}

	getScoringResultsForSession(session) {
		if (session.scoringsDisplay) {
			return session.scoringsDisplay;
		}

		const scorings = [];

		session.scorings.forEach((el) => {
			scorings.push(`${el.method}: ${el.score}`);
		});

		session.scoringsDisplay = scorings;
		return session.scoringsDisplay;
	}

	getSessionData(daily, canceler) {
		return this.eproService.getSessionData(
			this.siteId, this.eproSubjectId, daily.sessionDate,
			daily.eventIdAsString, daily.modalityId, canceler);
	}

	getDailySessions(options) {
		return this.eproService.getDailySessions(this.siteId, this.eproSubjectId,
												 this.eventId, this.tdtId, this.mdtId, options);
	}

	getEcoaPhoneData() {
		return this.eproService.getEcoaPhoneQuestionnaire(this.siteId, this.eproSubjectId, this.eventId, this.tdtId);
	}

	getClosedVisit() {
		return this.eproService.getClosedVisit(this.siteId, this.eproSubjectId, this.eventId, this.tdtId);
	}

	gotoPaperEntry(id) {
		const parameters = {
			entryId: id,
			readOnly: 1
		};
		const url = this.$state.href(this.appConfig.routes.cdeEcoaEntry, parameters);

		this.$window.open(url, '_blank');
	}

	getResponseAudioSource(response) {
		return this.$sce.trustAsResourceUrl(this.eproService.getResponseAudioSourceUrl(
			this.siteId, this.eproSubjectId, response));

	}

	getResponseDisplay(response) {
		const quetions = _.filter(this.clinicalPrompts[response.moduleId], x => {
			return x.promptId === response.promptId;
		});

		if (quetions && quetions.length > 0 && quetions[0].promptImplementationDataType === 'Boolean') {
			response.responseDisplay = (+response.response) === 1 ? 'true' : 'false';
		}
		return response.responseDisplay;
	}

	getSessionStartDate(session) {
		let result = '';

		if (session.sessionDate && session.sessionStartDate) {
			const sessionDate = this.$filter('date')(session.sessionDate, 'dd-MMM-yyyy');
			const sessionStartDate = this.$filter('date')(session.sessionStartDate, 'dd-MMM-yyyy');

			if (sessionDate !== sessionStartDate) {
				result = `(${sessionStartDate})`;
			}
		}
		return result;
	}

}

export default EcoaSessionViewerController;
