(function() {
	'use strict';

	angular
		.module('portalApp')
		.controller('NewDcrController', NewDcrController);

	/**
	 * @ngInject
	 */
	function NewDcrController($log, $scope, appI18n, WizardHandler, $stateParams, dataManagementService, appConfig,
							  $timeout, configurationService, signatureService, loadingIndicatorService, notificationService, $q,
								bodyCssClassService) {
		$log = $log.getInstance("NewDcrController");
		$log.debug("new dcr controller instantiated");

		var vm = this;
		vm.subjectDemographics = null;
		vm.visitDetails = null;
		vm.selectedSubjectKey = null;
		vm.selectedEventName = null;
		vm.eventDetails = null;
		vm.form = {};
		vm.formModelPath = {
			site: $stateParams.site,
			subject: $stateParams.subject,
			visit: $stateParams.visit,
			event: $stateParams.event
		};
		vm.formModelQuery = {};
		vm.submit = submit;
		vm.nextStep = nextStep;
		vm.currentStep = '';
		vm.isPathSelected = isPathSelected;
		vm.validatePath = validatePath;
		vm.validateText = validateText;
		vm.isTextEntered = isTextEntered;
		vm.isFilesUploaded = isFilesUploaded;
		vm.preSign = preSign;
		vm.resetState = resetState;

		vm.showFewerSitesMessage = false;
		vm.subjectsLoaded = false;
		vm.loadingCount = 0;
		vm.canUploadFiles = false;
		vm.sigType = appConfig.sigType.createDcr;
		vm.data = dataManagementService.getSharedData();

		var nextBtnLabel,
			backBtnLabel,
			cancelBtnLabel,
			closeAction,
			backAction,
			proceedAction,
			dataObjLabel;

		loadingIndicatorService.show();

		internationalize()
			.then(setupActions)
			.then(checkShouldShowAttachments)
			.then(getSites)
			.then(initForms)
			.finally(() => {
				if (!$stateParams.signKey || !$stateParams.tokenId) {
					loadingIndicatorService.hide();
					showWizardPopup();
				}
			});

		function internationalize() {
			return appI18n.translate([
				'data-management.wizard.select-step',
				'data-management.select-site',
				'data-management.select-subject',
				'data-management.site',
				'data-management.subject',
				'data-management.subjectId',
				'data-management.wizard.query-type-subject-status',
				'data-management.wizard.headings.dataObj',
				'app.buttons.next',
				'app.buttons.back',
				'app.buttons.cancel',
				'data-management.new.save-success'
			]).then((translations) => {
				vm.saveSuccess = translations['data-management.new.save-success'];
				vm.wizardSelectStepTitle = translations['data-management.wizard.select-step'];
				vm.selectSiteTxt = translations['data-management.select-site'];
				vm.selectSubjectTxt = translations['data-management.select-subject'];
				vm.siteLabel = translations['data-management.site'];
				vm.subjectLabel = translations['data-management.subject'];
				vm.subjectIdLabel = translations['data-management.subjectId'];
				vm.agreementErrorMessage = null;
				nextBtnLabel = translations['app.buttons.next'];
				backBtnLabel = translations['app.buttons.back'];
				cancelBtnLabel = translations['app.buttons.cancel'];
				dataObjLabel = translations['data-management.wizard.headings.dataObj'];

			});
		}

		function initForms() {
			if ($stateParams.signKey) {

				const unbind = $scope.$watch(
					() => {
						return !!wizard();
					},
					() => {
						const wz = wizard();

						if (wz) {
							vm.state = signatureService.getState($stateParams.signKey);
							if (vm.state) {
								signatureService.removeState($stateParams.signKey);
								//reset data model
								vm.sites = vm.state.sites;

								vm.formModelPath.site = vm.state.siteId;
								vm.formModelPath.subject = vm.state.subjectId;
								vm.formModelPath.subjectId = vm.state.enteredSubjectId;
								vm.formModelPath.visit = vm.state.visitName;
								vm.formModelPath.event = vm.state.eventId;
								vm.formModelQuery.queryText = vm.state.message;

								vm.data.attachments = vm.state.attachments;
								vm.data.agreement = vm.data.attachments && vm.data.attachments.length > 0 ? true : false;

								setFormFieldsPath();

								// move to last step and submit form
								$timeout(() => {
									wz.goTo(wz.totalStepCount() - 1);
									vm.submit();
								}, 0);
							}
							unbind();
						}
					});
			} else {
				setFormFieldsPath();
			}
		}

		function showLocalLoadingIndicator() {
			vm.isLoading = true;
		}

		function hideLocalLoadingIndicator() {
			vm.isLoading = false;
		}

		function showWizardPopup() {
			vm.show = true;
		}

		function hideWizardPopup() {
			vm.show = false;
			bodyCssClassService.unsetClass({customClass:'signature-active'});
		}

		function setupActions() {
			closeAction = {
				action: closeNewDcrRequest,
				displayName: cancelBtnLabel,
				cssClass: 'es-button -es-cancel',
				iconName: 'row-close',
				iconOnTheLeft: true
			};
			backAction = {
				action: previousStep,
				displayName: backBtnLabel,
				iconName: 'previous',
				iconOnTheLeft: true,
				isDisabled: vm.isLoading
			};
			proceedAction = {
				action: nextStep,
				displayName: nextBtnLabel,
				iconName: 'next',
				isPrimary: true,
				isDisabled: vm.isLoading
			};

			vm.actions = [
				proceedAction,
				backAction,
				closeAction
			];
		}

		function isBackDisabled() {
			var wz = wizard();
			return wz ? wz.currentStepNumber() === 1 : false;
		}

		function onStepChanged() {
			if (vm.wizardInit && wizard()) {
				vm.wizardInit = false;
			}
			updateActions();
		}

		function wizard() {
			return WizardHandler.wizard('newDcrWizard');
		}

		function updateActions() {
			backAction.isDisabled = isBackDisabled();
			proceedAction.isDisabled = false;

			var wz = wizard();
			if (wz) {
				var currentIndex = wz.currentStepNumber();

				if (currentIndex > 1 && currentIndex === wz.totalStepCount()) {
					proceedAction.displayName = appI18n.translateImmediate('app.buttons.sign-submit');
					vm.state = {};
					vm.state.siteId = vm.formModelPath.site;
					vm.state.sites = vm.sites;
					vm.state.subjectId = vm.formModelPath.subject;
					vm.state.enteredSubjectId = vm.formModelPath.subjectId;
					vm.state.visitName = vm.formModelPath.visit;
					vm.state.eventId = vm.formModelPath.event;
					vm.state.message = vm.formModelQuery.queryText;
					vm.state.attachments = vm.data.getUploadedFiles();
					vm.selectedSponsorSiteId = getSponsorSiteId(vm.state.siteId);

					vm.additionalInfo = {
						action: appI18n.translateImmediate('signature.action.newDcr'),
						infoKey1: appI18n.translateImmediate('signature.info.site'),
						infoValue1: vm.selectedSponsorSiteId,
						infoKey2: appI18n.translateImmediate('signature.info.subject'),
						infoValue2: vm.selectedSubjectKey || vm.formModelPath.subjectId
					};
				} else {
					proceedAction.displayName = 'Next';
				}
			}
		}

		function nextStep() {
			const wz = wizard(),
				  currentIndex = wz.currentStepNumber();

			switch (currentIndex) {
				case 1:
					validatePath();
					break;
				case 2:
					if (validateText()) {
						preSign(wz, currentIndex);
					}
					break;
				case 3:
					if (vm.isFilesUploaded(true)) {
						preSign(wz, currentIndex);
					}
					break;
				default:
					wz.next();
					break;
			}
		}

		function preSign(wizard, currentIndex) {
			const isLastStep = currentIndex === wizard.totalStepCount();

			if (!isLastStep) {
				wizard.next();
				return;
			}
			updateActions();
			signatureService.sign(vm.sigType, vm.additionalInfo, vm.state);
		}

		function previousStep() {
			var currentIndex = wizard().currentStepNumber();
			if (currentIndex > 1) {
				wizard().previous();
			}
		}

		function closeNewDcrRequest() {
			hideWizardPopup();
		}

		function checkShouldShowAttachments() {
			vm.data.attachments = [];
			vm.data.agreement = null;
			return configurationService.getUserStudySettings().then(response => {
				vm.canUploadFiles = response.data.expertStudySetup.showQueryAttachment;
				vm.hasVisitDemographics = response.data.studyConfig.hasVisitDemographics;
				$scope.$on('wizard:stepChanged', onStepChanged);
				updateActions();
			});
		}

		function setDataLoadError() {
			vm.isLoading = false;
			appI18n.translate("app.errors.genericLoadError").then(function(translation) {
				vm.dataLoadErrorMsg = translation;
			});
		}

		function resetSubjectData() {
			vm.formFieldsPath[2].templateOptions.options = [];
		}

		function resetVisitData() {
			vm.formFieldsPath[3].templateOptions.options = [];
			vm.visitDetails = null;
			vm.formModelPath.visit = null;
		}

		function resetEventData() {
			vm.formFieldsPath[4].templateOptions.options = [];
			vm.eventDetails = null;
			vm.selectedEventName = null;
			vm.formModelPath.event = null;

		}

		function resetDemographicData() {
			vm.subjectDemographics = null;
		}

		function setFormFieldsPath() {

			vm.formFieldsPath = [{
				template: `<div class="es-content-box"><div class="es-text-group"><h3>${dataObjLabel}</h3></div></div>`
			},
								 {
									 key: 'site',
									 type: 'esSgSelect',
									 id: 'site',
									 templateOptions: {
										 label: vm.siteLabel,
										 options: vm.sites,
										 required: true,
										 valueProp: 'siteId',
										 labelProp: 'displaySiteName',
										 dropDownLabel: vm.selectSiteTxt,
										 id: 'site',
										 formId: 'site'
									 },
									 /**
									  * @ngInject
									  */
									 controller: function( /*$scope*/ ) {}
								 },
								 {
									 key: 'subject',
									 type: 'esSgSelect',
									 id: 'subject',
									 templateOptions: {
										 label: vm.subjectLabel,
										 options: [],
										 required: true,
										 valueProp: 'subjectId',
										 labelProp: 'subjectKey',
										 dropDownLabel: vm.selectSubjectTxt,
										 id: 'subject',
										 formId: 'subject',
										 onChange: function(newValue) {
											 if (newValue === '-1') {
												 vm.selectedSubjectKey = null;
												 resetVisitData();
												 resetEventData();
												 resetDemographicData();
											 } else if (newValue) {
												 vm.formModelPath.subjectId = null;
											 }
										 }
									 },
									 validators: {
										 validSubject: {
											 expression: function($viewValue, $modelValue) {
												 var value = $modelValue || $viewValue;
												 value = Number(value) != 0;
												 return value;
											 },
											 message: () => appI18n.translateImmediate("data-management.site-no-subjects")
										 }
									 },
									 /**
									  * @ngInject
									  */
									 controller: function($scope) {
										 if ($scope.model.site) {
											 loadSubjects($scope.model.site);
										 }
										 $scope.$watch('model.site', function(newValue, oldValue) {
											 if (newValue !== oldValue) {
												 $scope.model.subject = '';
												 resetSubjectData();
												 resetVisitData();
												 resetEventData();
												 resetDemographicData();
												 vm.visitDetails = null;
												 vm.selectedEventName = null;
												 if (newValue) {
													 loadSubjects(newValue);
												 }
											 }
										 });

										 function loadSubjects(siteId) {
											 vm.loadingCount = vm.loadingCount + 1;
											 showLocalLoadingIndicator();
											 getSubjects(siteId).then(
												 function(response) {
													 var ddOptions;
													 if (response.data.length > 0) {
														 $scope.to.dropDownLabel =
															 appI18n.translateImmediate("data-management.select-subject");
														 ddOptions = response.data;
														 vm.subjects = response.data;
														 vm.selectedSubjectKey = getSubjectKey(vm.formModelPath.subject);
													 } else {
														 $scope.to.dropDownLabel = '';
														 ddOptions = [{
															 subjectId: 0,
															 subjectKey: appI18n.translateImmediate("data-management.no-subjects")
														 }];
													 }

													 ddOptions.unshift({
														 subjectId: -1,
														 subjectKey: appI18n.translateImmediate('data-management.missing-subjects')
													 });
													 $scope.to.options = ddOptions;
													 vm.subjectsLoaded = true;
													 vm.loadingCount = vm.loadingCount - 1;
													 hideLocalLoadingIndicator();
													 return response;
												 },
												 function() {
													 vm.loadingCount = vm.loadingCount - 1;
													 setDataLoadError();
													 hideLocalLoadingIndicator();
												 });
										 }
									 }
								 },
								 {
									 key: 'visit',
									 type: 'esSgSelect',
									 id: 'visit',
									 hideExpression: function($viewValue, $modelValue, scope) {
										 return scope.model.subject == -1;
									 },
									 templateOptions: {
										 label: 'Visit',
										 options: [],
										 required: false,
										 valueProp: 'visitName',
										 labelProp: 'visitDisplayName',
										 dropDownLabel: 'Select a visit (optional)',
										 id: 'visit',
										 formId: 'visit',
										 onChange: () => {
											 vm.selectedSubjectKey = getSubjectKey(vm.formModelPath.subject);
										 }
									 },
									 /**
									  * @ngInject
									  */
									 controller: function($scope) {
										 if ($scope.model.subject && $scope.model.site) {
											 loadVisit($scope.model.subject);
										 }
										 $scope.$watch('model.subject', function(newValue, oldValue) {
											 if (newValue !== oldValue) {
												 $scope.model.visit = null;
												 resetVisitData();
												 resetEventData();
												 resetDemographicData();
												 vm.visitDetails = null;
												 vm.eventDetails = null;
												 vm.selectedEventName = null;
												 if (newValue) {
													 loadVisit(newValue);
												 }
											 }
										 });

										 function loadVisit(subjectId) {
											 if (subjectId == -1) {
												 showSubjectIdEntry();
												 return;
											 }
											 hideSubjectIdEntry();

											 vm.loadingCount = vm.loadingCount + 1;
											 vm.selectedSubjectKey = getSubjectKey(subjectId) || vm.selectedSubjectKey;
											 var site = $scope.model.site;
											 showLocalLoadingIndicator();
											 vm.isQueryInformationLoading = true;

											 resetDemographicData();

											 getVisits(site, subjectId).then(
												 function(response) {
													 const visits = response.data;

													 if (visits.length > 0) {
														 $scope.to.dropDownLabel = 'Select a visit (optional)';
														 $scope.to.options = visits;
														 vm.visits = visits;

											             if (vm.hasVisitDemographics && vm.formModelPath.visit) {
                                                             getVisitDemographics(site, subjectId,
                                                                                  vm.formModelPath.visit);
                                                         }
													 } else {
														 $scope.to.dropDownLabel = '';
														 $scope.to.options = [{
															 visitName: '',
															 visitDisplayName: 'No visits'
														 }];
													 }
													 vm.loadingCount = vm.loadingCount - 1;
													 hideLocalLoadingIndicator();
													 return visits;
												 },
												 function() {
													 vm.loadingCount = vm.loadingCount - 1;
													 setDataLoadError();
													 hideLocalLoadingIndicator();
												 });

											 dataManagementService.getDemographics(site, subjectId)
												 .then((response) => {
													 vm.subjectDemographics = response.data;
												 }, () => {
													 setDataLoadError();
												 }).finally(() => {
													 vm.isQueryInformationLoading = false;
												 });
										 }
									 }
								 },
								 {
									 key: 'event',
									 type: 'esSgSelect',
									 id: 'event',
									 hideExpression: function($viewValue, $modelValue, scope) {
										 return scope.model.subject == -1;
									 },
									 templateOptions: {
										 label: 'Event',
										 options: [],
										 required: false,
										 valueProp: 'eventId',
										 labelProp: 'eventName',
										 id: 'event',
										 formId: 'visit',
										 dropDownLabel: 'Select an event (optional)'
									 },
									 /**
									  * @ngInject
									  */
									 controller: function($scope) {

										 if ($scope.model.subject && $scope.model.site && $scope.model.visit) {
											 loadEvents($scope.model.visit);
											 if ($scope.model.event) {
												 loadEventDetailData($scope.model.event);
											 }
										 }

										 $scope.$watch('model.visit', function(newValue, oldValue) {
											 if (newValue !== oldValue) {
												 $scope.model.event = null;
												 resetEventData();
												 vm.visitDetails = null;
												 vm.eventDetails = null;
												 vm.selectedEventName = null;

												 if (newValue) {
													 loadEvents(newValue);
												 }
											 }
										 });

										 $scope.$watch('model.event', function(newValue, oldValue) {
											 if (newValue !== oldValue) {
												 vm.eventDetails = null;
												 if (newValue) {
													 loadEventDetailData(newValue);
												 }
											 }
										 });

										 function loadEventDetailData(eventId) {
											 var site = $scope.model.site;
											 var subject = $scope.model.subject;
											 var visitName = $scope.model.visit;
											 loadEventDetails(site, subject, visitName, eventId);
											 vm.selectedEventName = getEventName(eventId) || vm.selectedEventName;
										 }

										 function loadEvents(visitName) {
											 vm.loadingCount = vm.loadingCount + 1;
											 var site = $scope.model.site;
											 var subject = $scope.model.subject;

											 showLocalLoadingIndicator();
											 if (vm.hasVisitDemographics && vm.visits) {
                                                 getVisitDemographics(site, subject, visitName);
                                             }

											 getEvents(site, subject, visitName).then(function(response) {
												 if (response.data.length > 0) {
													 $scope.to.dropDownLabel = 'Select an event (optional)';
													 $scope.to.options = response.data;
													 vm.events = response.data;

													 if ($scope.model.event) {
														 vm.selectedEventName = getEventName($scope.model.event) || vm.selectedEventName;
													 }
													 hideLocalLoadingIndicator();
												 } else {
													 $scope.to.dropDownLabel = '';
													 $scope.to.options = [{
														 eventId: 0,
														 eventName: 'No events'
													 }];
													 hideLocalLoadingIndicator();
												 }
												 vm.loadingCount = vm.loadingCount - 1;
												 return response;
											 },
																					  function() {
																						  vm.loadingCount = vm.loadingCount - 1;
																						  setDataLoadError();
																						  hideLocalLoadingIndicator();
																					  });

											 loadVisitDetails(site, subject, visitName);
										 }
									 }
								 },
								 {
									 key: 'subjectId',
									 type: 'esSgInput',
									 id: 'subjectId',
									 expressionProperties: {
										 'hide': function($viewValue, $modelValue, scope) {
											 return scope.model.subject != -1;
										 }
									 },
									 templateOptions: {
										 type: 'text',
										 label: vm.subjectIdLabel,
										 required: true,
										 id: 'subjectId',
										 formId: 'subjectId',
									 }
								 },
								];
		}

		vm.formFieldsQuery = [
			/*
			 *{
			 *  template: 'For further data corrections or comments please enter your note here'
			 *},
			 */
			{
				key: 'queryText',
				type: 'esSgTextArea',
				templateOptions: {
					className: 'ert-height-from-rows',
					required: true,
					label: '',
					rows: 10,
					placeholder: 'Please enter your data correction details here',
					id: 'query_text'
				},
				ngModelElAttrs: {
					maxlength: '2000'
				},
			}
		];

    function getVisitDemographics(site, subject, visitName) {
        const visit = _.find(vm.visits, (v) => {
			return v.visitName === visitName;
		});

		if (visit.transmittalId) {
			resetDemographicData();
			vm.isQueryInformationLoading = true;
			dataManagementService.getDemographics(
				site, subject, visit.transmittalId)
				.then(function(response) {
					vm.subjectDemographics = response.data;
				}).finally(() => {
					vm.isQueryInformationLoading = false;
				});
		}

    }
		function isTextEntered() {
			var textEntered = vm.formModelQuery && vm.formModelQuery.queryText;
			return !!textEntered;
		}

		function isFilesUploaded(showError) {
			var valid = vm.data.isValid && vm.data.isValid(showError);
			return !!valid;
		}

		function isPathSelected() {
			var pathSelected = vm.formModelPath && vm.formModelPath.site &&
				((vm.formModelPath.subject === '-1' && vm.formModelPath.subjectId) ||
				 (vm.formModelPath.subject && vm.formModelPath.subject !== '-1'));
			return !!pathSelected;
		}

		function getSponsorSiteId(siteId) {
			const convertedSiteId = parseInt(siteId, 10);

			if (isNaN(convertedSiteId)) {
				return undefined;
			}

			vm.sites = vm.sites || [];
			const site = vm.sites.find((site) => {
				return site.siteId === convertedSiteId;
			});

			return site ? site.sponsorSiteId : undefined;
		}

		function getSubjectKey(subjectId) {
			if (!subjectId)
				return undefined;

			vm.subjects = vm.subjects || [];
			for (var i = 0; i < vm.subjects.length; ++i) {
				if (vm.subjects[i].subjectId == subjectId) {
					return vm.subjects[i].subjectKey;
				}
			}
			return undefined;
		}


		function getEventName(eventId) {
			vm.events = vm.events || [];
			for (var i = 0; i < vm.events.length; ++i) {
				if (vm.events[i].eventId === eventId) {
					return vm.events[i].eventName;
				}
			}
			return undefined;
		}

		function validatePath() {
			if (vm.formController.$valid) {
				wizard().next();
				return true;
			} else {
				vm.formController.$setSubmitted();
				return false;
			}
		}

		function validateText() {
			if (vm.formControllerQuery.$valid) {
				return true;
			} else {
				vm.formControllerQuery.$setSubmitted();
				return false;
			}
		}


		function submit() {
			vm.errorMsg = '';
			proceedAction.isDisabled = true;
			vm.isLoading = true;
			loadingIndicatorService.show();
			disableWizardButtons();
			const ticket = vm.state;

			ticket.sites = null;
			ticket.signKey = $stateParams.signKey;
			ticket.signToken = $stateParams.tokenId;

			if (!signatureService.validateSignature(ticket.signToken, ticket.signKey)) {
				vm.resetState();
				return;
			}

			dataManagementService.createTicket(ticket)
				.then((response) => {
					if (response.data && response.data.isValidationError) {
						vm.errorMsg = appI18n.translateImmediate(response.data.translationKey);
						notificationService.showError(vm.errorMsg);
						showWizardPopup();
					} else {
						closeNewDcrRequest();
						notificationService.showSuccess(vm.saveSuccess);
						dataManagementService.refreshTicketsGrid();
					}
				}, (response) => {
					$log.error('error creating ticket with error: {data}, status: {status}', {
						data: response.data,
						status: response.status
					});
					showWizardPopup();
				}).finally(() => {
					vm.resetState();
					bodyCssClassService.unsetClass({customClass:'signature-active'});
				});
		}

		function resetState() {
			vm.isSignatureValid = false;
			vm.isLoading = false;
			loadingIndicatorService.hide();
			enableWizardButtons();
			signatureService.removeStateParams();
		}

		function getSites() {
			vm.showFewerSitesMessage = false;
			return dataManagementService.getSites().then((response) => {
				if (response.data.sites.length < Number(response.data.sitesCount)) {
					vm.showFewerSitesMessage = true;
				} else {
					vm.showFewerSitesMessage = false;
				}

				if (response.data.sites.length > 0) {
					vm.sites = response.data.sites;
				} else {
					vm.sites = [{
						siteId: 0,
						displaySiteName: appI18n.translateImmediate('data-management.no-sites')
					}];
				}
				return response;
			}, () => {
				setDataLoadError();
			});
		}

		function getSubjects(site) {
			return dataManagementService.getSubjects(site);
		}

		function getVisits(site, subject) {
			return dataManagementService.getSubjectVisits(site, subject);
		}

		function getEvents(site, subject, visit) {
			return dataManagementService.getSubjectVisitEvents(site, subject, visit);
		}

		function loadVisitDetails(site, subject, visit) {
			if (site && subject && visit) {
				vm.isQueryInformationLoading = true;
				dataManagementService.getSubjectVisitDetails(site, subject, visit)
					.then(function(response) {
						vm.visitDetails = response.data;
						vm.visitDetails.visitName = visit;
					}).finally(() => {
						vm.isQueryInformationLoading = false;
					});
			} else {
				vm.visitDetails = null;
			}
		}

		function loadEventDetails(site, subject, visit, eventId) {
			if (site && subject && visit && eventId) {
				vm.isQueryInformationLoading = true;
				dataManagementService.getSubjectEventDetails(site, subject, visit, eventId)
					.then((response) => {
						vm.eventDetails = response.data;
					}).finally(() => {
						vm.isQueryInformationLoading = false;
					});
			} else {
				vm.eventDetails = null;
			}
		}

		function disableWizardButtons() {
			for (var i = 0; i < vm.actions.length; i++) {
				vm.actions[i].isDisabled = true;
			}
		}

		function enableWizardButtons() {
			for (var i = 0; i < vm.actions.length; i++) {
				vm.actions[i].isDisabled = false;
			}
		}

		function showSubjectIdEntry() {
			vm.formOptions.showSubjectIdEntryFields = true;
		}

		function hideSubjectIdEntry() {
			vm.formOptions.showSubjectIdEntryFields = false;
		}
	}
})();
