var logger = require('./logger');

(function () {
    'use strict';

    /**
     * @ngInject
     */
    angular.module('portalApp')
    .config(['$provide', 'appConfig', 'tokenService', function ($provide, appConfig, tokenService) {

        $provide.decorator('$log', ['$delegate', function ($delegate) {

            logger($delegate, appConfig, tokenService);

            return $delegate;
        }]);
    }]);

})();
