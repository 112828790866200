import templateUrl from './subjects.html';
import controller from './subjects.controller';
import './subjects.scss';

const subjectsComponent = {
	bindings: {
		title: '@'
	},
	templateUrl,
	controller
};

export default subjectsComponent;
