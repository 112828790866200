import confirmationPopupController from '../confirmation/confirmationPopup.controller';

class ConfirmationTextAreaPopupController extends confirmationPopupController {
	constructor(popupService, appI18n, $scope) {
		super();
		'ngInject';
		this.srv = popupService;
		this.appI18n = appI18n;
		this.$scope = $scope;
		this.popupName = 'confirmationTextArea';
		this.reason = '';
		this.valueChange = (newValue) => { this.reason = newValue; };
	}

	setButtons() {
		const self = this;
		const cancelPopupBtn = {
			action: () => {
				self.$API.hide({ answer: 'cancel' });

			},
			displayName: this.appI18n.translateImmediate('app.buttons.cancel'),
			cssClass: '-es-cancel -es-modal', iconName: 'close', disabled: false
		};

		const submitPopupBtn = {
			action: () => {
				if (self.confirmationPopupForm.$invalid) {
					self.confirmationPopupForm.showValidation = true;
					return;
				}
				self.$API.hide(
					{
						answer: 'ok',
						reason: self.reason
					});
			},
			displayName: this.appI18n.translateImmediate('app.buttons.ok'),
			cssClass: '-es-primary -es-modal', disabled: false
		};

		this.btns = [cancelPopupBtn, submitPopupBtn];
	}
}

export default ConfirmationTextAreaPopupController;
