/*
 * resentVisitsTableDirective
 * */

import templateUrl from './resentVisitsTable.html';
import controller from './resentVisitsTable.controller';
import './resentVisitsTable.scss';

const resentVisitsTableDirective = () => {

	return {
		scope: {
			row: '<',
			showCalendar: '<'
		},
		controller,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		replace: true,
		link(/*scope, element, attr, ctr*/) {}
	};
};

export default resentVisitsTableDirective;
