/*
 * visitCalendarPopupModule
 * */

import visitCalendarPopupComponent from './visitCalendarPopup.component';
import visitCalendarLegendModule from './visitCalendarLegend/index';
import visitCalendarBreadcrumbsModule from './visitCalendarBreadcrumbs/index';

const visitCalendarPopupModule = angular.module('app.components.dashboard.visitOverviewWidget.visitCalendarPopup', [
	visitCalendarLegendModule,
	visitCalendarBreadcrumbsModule
]).component('visitCalendarPopup', visitCalendarPopupComponent)
	.name;

export default visitCalendarPopupModule;
