/*
 * baseDatePickerComponent
 * */

import templateUrl from './baseDatePicker.html';
import controller from './baseDatePicker.controller';
import './baseDatePicker.scss';

const baseDatePickerComponent = {
	bindings: {
		cssClass: '@',
		isInvalid: '<?',
		orientation: '@',
		defaultValue: '<?'
	},
	templateUrl,
	controller,
	transclude: true
};

export default baseDatePickerComponent;
