(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('StudyModeSwitchController', StudyModeSwitchController);

	/**
	 * @ngInject
	 */
	function StudyModeSwitchController($log, $state, $rootScope, $scope, appI18n, appConfig,
																		 appSession, userService) {
		var vm = this;

		$log = $log.getInstance('StudyModeSwitchController');
		$log.debug(' loaded');
		
		vm.appSession = appSession;
		vm.switchStudyMode = switchStudyMode;
		vm.iconCssClass = iconCssClass;
		vm.currentModeName = null;
		vm.currentMode = undefined;
		vm.modeNames = undefined;
		vm.availableModes = undefined;
		vm.hasTrainingMode = undefined;
		vm.hasUatMode = undefined;
		vm.hasProficiencyMode = undefined;
		vm.hasSampleMode = undefined;

		activate();

		function iconCssClass() {
			var currentMode = appSession.getStudyModeId();

			switch (currentMode) {
			case 1:
				return 'icon-training-mode';
			case 2:
				return 'icon-proficiency-mode';
			case 3:
				return 'icon-sample-mode';
			case 4:
				return 'icon-client-uat';
			default:
				return 'icon-application-mode';
			}
		}

		function activate() {
			getAvailableModes();
			getModeNames();
		}

		function getAvailableModes() {
			userService.getAvailableStudyModes().
				then(function (modes) {
					vm.availableModes = modes;
					vm.hasTrainingMode = modes.indexOf(1) > -1;
					vm.hasUatMode = modes.indexOf(4) > -1;
					vm.hasProficiencyMode = modes.indexOf(2) > -1;
					vm.hasSampleMode = modes.indexOf(3) > -1;
					
				});
		}

		function getModeNames() {
			vm.currentMode = appSession.getStudyModeId();

			appI18n.translate([
				'header.mode.training',
				'header.mode.proficiency',
				'header.mode.sample',
				'header.mode.uat',
				'header.mode.study'
			]).then(function(translations) {
				vm.modeNames = [];
				vm.modeNames[0] = translations['header.mode.study'];
				vm.modeNames[1] = translations['header.mode.training'];
				vm.modeNames[2] = translations['header.mode.proficiency'];
				vm.modeNames[3] = translations['header.mode.sample'];
				vm.modeNames[4] = translations['header.mode.uat'];

				vm.currentModeName = vm.modeNames[vm.currentMode];
			});
		}

		function switchStudyMode(mode) {
			vm.appSession.switchStudyMode(mode);
		}
	}
})();
