(function () {
	'use strict';
	angular.module('portalApp').directive('ertTabNavigation', TabNavigation);

	TabNavigation.$inject = ['$log', '$state', 'analyticsService', 'appConfig'];

	function TabNavigation($log, $state, analyticsService, appConfig) {
		$log = $log.getInstance('TabNavigation');

		return {
			restrict: 'EA',
			templateUrl: 'scripts/directives/tabs/tabNavigation.html',
			scope: {
				tabs: '=',
				actions: '=',
				inlineLabel: '='
			},
			link: function (scope, el, attrs) {
				$log.debug(' loaded');
				scope.$state = $state;

				scope.trackClick = (tab) => {
					const trackInfo = tab.trackId || tab.displayName;
					const label = tab.trackLabel;

					if (trackInfo) {
						analyticsService.trackEventWithTitle(`${appConfig.trackCategory.navigateTo}::${trackInfo}`,
						appConfig.trackAction.tab, label);
					}
				};
				scope.getCurrentTab = function () {
					for (var i = 0; i < scope.tabs.length; i++) {
						if (scope.tabs[i].ref === $state.current.name) {
							return scope.tabs[i].displayName;
						}
					}
				};
			}
		};
	}
})();
