(function () {
	'use strict';

	angular.module('portalApp').controller('EmailConfirmationCtrl', EmailConfirmationCtrl);

	/**
	 * Controller to handle login view
	 *
	 * @ngInject
	 */
	function EmailConfirmationCtrl($log, $state, $scope,appConfig, appI18n,emailConfirmationService, accountService) {

		$log = $log.getInstance('LoginCtrl', 'color:#021b41; font-size:1em; background-color:#dedede;');
		$log.debug('loaded');

		var vm = this;
		vm.username = '';
		vm.password = '';
		vm.showRedirectMessage = false;

		vm.hasError = false;
		vm.errorMessage = '';
		vm.dataLoading = false;

		vm.showResendConfirmation = showResendConfirmation;
		vm.showLoginHelp = showLoginHelp;
		vm.onHideLoginHelp = onHideLoginHelp;
		vm.submitResend = submitResend;

		// -------------------------------------------
		// start: site registration
		// -------------------------------------------

		$scope.form = {};

		activate();

		function activate() {
		}

		function showResendConfirmation() {
			initResendConfirmationForm();
			vm.resendConfirmationActions = [{
				action: () => {
					vm.resendForm.$setSubmitted();
					if (vm.resendForm.$valid) {
						vm.submitResend();
					}
				},
				isDisabled: false,
				isPrimary: true,
				displayName: 'app.buttons.submit'
			}, {
				action: () => { vm.resendConfirmation.isFormVisible = false; },
				isDisabled: false,
				isPrimary: false,
				displayName: 'app.buttons.cancel'
			}, {
				action: () => { vm.resendConfirmation.isFormVisible = false; },
				isHidden: true,
				isPrimary: true,
				displayName: 'app.buttons.close'
			}];
			vm.resendConfirmation.isFormVisible = true;
		}

		vm.initEmailConfirmationForm = function () {
			vm.emailConfirmation = {
				isFormVisible: false,
				formTitle: 'Confirm Email',
				userCanLoginToPortal: false,
				isLoading: false,
				serviceResult: {},
				serviceError: {},
				errorMsg: '',
				currentStep: 'loading',
				isTokenFromUrl: false,
				isManualConfirmation: false,
				isTempPasswordEnabled: false,
				newPassword: '',
				newPasswordConfirmation: '',
				formModel: {
					email: '',
					password: '',
					token: '',
					newPassword: '',
					newPasswordConfirmation: ''
				}
			};
		};

		vm.initEmailConfirmationForm();

		vm.showEmailConfirmation = function () {
			vm.initEmailConfirmationForm();
			vm.emailConfirmationActions = [{
				action: () => {
					vm.emailConfirmationForm.$setSubmitted();
					if (vm.emailConfirmationForm.$valid) {
						vm.submitConfirmation();
					}
				},
				isDisabled: false,
				isPrimary: true,
				displayName: 'app.buttons.submit'
			}, {
				action: () => { vm.emailConfirmation.isFormVisible = false; },
				isDisabled: false,
				isPrimary: false,
				displayName: 'app.buttons.cancel'
			}, {
				action: () => { vm.emailConfirmation.isFormVisible = false; },
				isDisabled: false,
				isPrimary: true,
				isHidden: true,
				displayName: 'app.buttons.close'
			}
										  ];
			vm.emailConfirmation.isFormVisible = true;
		};

		// -------------------------------------------
		// start: email confirmation
		// -------------------------------------------

		vm.confirmManually = function () {

			vm.emailConfirmation.isLoading = true;

			vm.emailConfirmation.isTokenFromUrl = false;
			vm.emailConfirmation.isManualConfirmation = true;
			vm.emailConfirmation.isTempPasswordEnabled = true;

			vm.emailConfirmation.isLoading = false;
			vm.emailConfirmation.currentStep = 'confirm';
		};

		vm.confirmFromToken = function (token) {

			vm.emailConfirmation.isLoading = true;
			changeToolbarButtonsState(vm.emailConfirmationActions, true);

			emailConfirmationService.loadRegistration(token)
				.then(function (response) {
					vm.emailConfirmation.serviceResult = response.data;
					vm.emailConfirmation.isLoading = false;
					vm.emailConfirmationActions[0].isHidden = true;
					vm.emailConfirmationActions[1].isHidden = true;
					vm.emailConfirmationActions[2].isHidden = false;
					if (response.data.isValid) {
						vm.emailConfirmation.formModel.email = response.data.confirmationEmail;
						vm.emailConfirmation.formModel.token = token;

						vm.emailConfirmation.isTokenFromUrl = !!vm.emailConfirmation.formModel.token; // hide token input field if token is defined already
						vm.emailConfirmation.isManualConfirmation = !(vm.emailConfirmation.formModel.email);
						vm.emailConfirmation.isTempPasswordEnabled = true;

						vm.emailConfirmation.userCanLoginToPortal = response.data.userCanLoginToPortal;
						if (response.data.userIsAlreadyConfirmed) {
							if (response.data.userCanLoginToPortal) {
								vm.emailConfirmation.confirmedUser = vm.emailConfirmation.formModel.email;
							}
							vm.emailConfirmation.currentStep = 'alreadyConfirmed';
							vm.emailConfirmation.formTitle = 'Already Confirmed';
						} else if (response.data.confirmationSucceeded) {
							vm.emailConfirmation.currentStep = 'confirmed';
							vm.emailConfirmation.formTitle = 'Confirmed';
						} else {
							vm.emailConfirmation.currentStep = 'confirm';
						}
					}
					else {
						vm.emailConfirmation.errorMsg = response.data.message;
					}
				}, function (response) {
					vm.emailConfirmation.serviceError = response.data;
					$log.error('Error loading registration data for token: \'{0}\', error: \'{1}\'', token, vm.emailConfirmation.serviceError);

					vm.emailConfirmation.isLoading = false;
				}).finally(() => {
					changeToolbarButtonsState(vm.emailConfirmationActions, false);
				});
		};

		function changeToolbarButtonsState(btnArray, disable) {
			btnArray.forEach((el) => {
				el.isDisabled = disable;
			});
		}

		vm.closeEmailConfirmationPopup = function () {
			vm.emailConfirmation.isFormVisible = false;
		};

		vm.submitConfirmation = function () {

			vm.emailConfirmation.errorMsg = '';
			vm.emailConfirmation.isLoading = true;
			changeToolbarButtonsState(vm.emailConfirmationActions, true);

			var email = vm.emailConfirmation.formModel.email;
			var token = vm.emailConfirmation.formModel.token;
			var password = vm.emailConfirmation.formModel.password;

			emailConfirmationService.confirmRegistration(email, token, password)
			.then(function (response) {
				vm.emailConfirmation.serviceResult = response.data;
				vm.emailConfirmation.isLoading = false;

				if (response.data.isValid) {
					vm.emailConfirmation.userCanLoginToPortal = response.data.userCanLoginToPortal;
					vm.emailConfirmationActions[0].isHidden = true;
					vm.emailConfirmationActions[1].isHidden = true;
					vm.emailConfirmationActions[2].isHidden = false;
					if (response.data.userIsAlreadyConfirmed) {
						vm.emailConfirmation.currentStep = 'alreadyConfirmed';
						vm.emailConfirmation.formTitle = 'Already Confirmed';
					} else if (response.data.confirmationSucceeded) {
						vm.emailConfirmation.currentStep = 'confirmed';
						vm.emailConfirmation.formTitle = 'Confirmed';
					} else {
						vm.emailConfirmation.currentStep = 'confirm';
					}
				}
				else {
					vm.emailConfirmation.errorMsg = response.data.message;
				}

			}, function (response) {
				vm.emailConfirmation.errorMsg = response.data;
				$log.error('Error confirming email registration for email: \'{0}\', token: \'{1}\', error: \'{2}\'', email, token, vm.emailConfirmation.serviceError);

				vm.emailConfirmation.isLoading = false;
			}).finally(() => {
				changeToolbarButtonsState(vm.emailConfirmationActions, false);
			});

		};

		if ($state.params.helpMode) {
			vm.showLoginHelp();
		}

		if ($state.params.resendMode) {
			vm.showResendConfirmation();
		}

		if ($state.params.confirmMode) {
			$log.debug('confirmmode');
			vm.showEmailConfirmation();
			if ($state.params.t) {
				vm.confirmFromToken($state.params.t);
			}
			else {
				vm.confirmManually();
			}
		}

		if ($state.params.prefillUserName) {
			vm.username = $state.params.prefillUserName;
		}

		vm.onHideEmailConfirmation = function () {
			goToLogin(vm.emailConfirmation.confirmedUser);
		};

		function initResendConfirmationForm() {
			vm.resendConfirmation = {
				isFormVisible: false,
				customerCare: appConfig.external.customerCare,
				isLoading: false,
				serviceError: null,
				currentStep: 'email',
				formModel: {
					email: ''
				}
			};
		}

		function submitResend() {
			vm.resendConfirmation.isLoading = true;
			changeToolbarButtonsState(vm.resendConfirmationActions, true);

			var email = vm.resendConfirmation.formModel.email;

			emailConfirmationService.resendConfirmation(email).then(
				function () {
					vm.resendConfirmation.isLoading = false;
					vm.resendConfirmation.currentStep = 'mailsent';
					vm.resendConfirmationActions[0].isHidden = true;
					vm.resendConfirmationActions[1].isHidden = true;
					vm.resendConfirmationActions[2].isHidden = false;
				},
				function () {
					vm.resendConfirmation.serviceError = true;
					vm.resendConfirmation.isLoading = false;
				}
			).finally(() => {
				changeToolbarButtonsState(vm.resendConfirmationActions, false);
			});
		}

		// -------------------------------------------
		// end: email confirmation
		// -------------------------------------------

		function setError(translationKey) {

			if (translationKey) {
				appI18n.translate(translationKey).then(function (translation) {
					vm.errorMessage = translation;
					vm.hasError = true;
				});
				vm.dataLoading = false;
			}
			else {
				vm.errorMessage = '';
				vm.hasError = false;
			}
		}

		function onHideLoginHelp() {
			goToLogin();
		}

		function goToLogin(prefillUserName) {
			if (prefillUserName) {
				$state.go(appConfig.routes.login, {'prefillUserName': prefillUserName});
			}
			else {
				$state.go(appConfig.routes.login);
			}
		}

		function showLoginHelp() {
			vm.loginHelp = {};

			appI18n.translate('login.help.title').then(function (t) {
				vm.loginHelp.formTitle = t;
			});

			vm.loginHelp.isFormVisible = true;
		}
	}

})();
