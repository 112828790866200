/*
 * notificationMessageController
 * */

class NotificationMessageController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
		this._removeMe = 'notificationMessage';
	}

	$onInit() {
		this.$log = this.$log.getInstance('NotificationMessageController');
		this.$log.debug('loaded');
	}
}

export default NotificationMessageController;
