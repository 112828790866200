/*
 * SubjectsController
 * */

class SubjectsController {

	constructor($log, $q, $state, $stateParams, clinicalDataService, configurationService,
		userService, iSpiroService, kardiaService, appConfig, appI18n, loadingIndicatorService,
		analyticsService, ertBasePopupService) {
		'ngInject';
		this.$log = $log;
		this.$q = $q;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.loadingIndicatorService = loadingIndicatorService;
		this.clinicalDataService = clinicalDataService;
		this.configurationService = configurationService;
		this.ertBasePopupService = ertBasePopupService;
		this.userService = userService;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.iSpiroService = iSpiroService;
		this.kardiaService = kardiaService;

		this.subjects = [];
		this.analyticsService = analyticsService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('SubjectsController');
		this.$log.debug('loaded');

		this.loadingIndicatorService.show();
		this.countryId = this.$stateParams.country;
		this.siteId = this.$stateParams.site;

		this.options = this.getGridConfig();

		// TODO: Change this, it generates memory leak
		this.clinicalDataService.setEditContactDataHandler(this);

		this.$q.all({
			columns: this.configurationService.getClinicalDataColumns(
				this.appConfig.clinicalDataModuleNames.subject),
			studySettings: this.configurationService.getStudySettings(),
			translations: this.appI18n.translate([
				'clinicaldata.subject.buttons.dispenseDevice',
				'clinicaldata.subject.buttons.closeDevice',
				'clinicaldata.subject.buttons.lostDevice',
				'clinicaldata.subject.buttons.editDeviceValues',
				'clinicaldata.subject.buttons.scheduleEvent',
				'clinicaldata.subject.buttons.resetPin',
				'clinicaldata.subject.i-spiro.button-close',
				'clinicaldata.subject.buttons.info',
				'clinicaldata.subject.buttons.calendar',
				'clinicaldata.subject.status.buttons.deactivate',
				'clinicaldata.subject.buttons.editSubject',
				'clinicaldata.subject.status.buttons.activate',
				'clinicaldata.subject.buttons.editContactData',
				'clinicaldata.subject.buttons.sendPin',
				'clinicaldata.subject.buttons.noActionAvailable',
				'app.common.site',
				'app.common.country'
			]),
			iSpiroDeviceActionPermission: this.userService.getUserHasPermission(
				this.appConfig.businessPermissions.iSpiroDeviceAction),
			kardiaActionPermission: this.userService.getUserHasPermission(
				this.appConfig.businessPermissions.kardiaAction)
		}).then((resp) => {
			this.translations = resp.translations;

			this.options.columnDefsStatic[0].displayName = `${this.translations['app.common.site']} /
				${this.translations['app.common.country']}`;

			this.options.gridRowActions = this.generateGridRowActions(
				resp.studySettings,
				resp.iSpiroDeviceActionPermission,
				resp.kardiaActionPermission
			);
			this.updateColumnList(resp.columns);
			this.load = true;
		}, (error) => {
			this.$log.debug(`error loading configuration for subjects: ${error}`);
		}).finally(() => {
			this.loadingIndicatorService.hide();
		});
	}

	getGridConfig() {
		return {
			columnDefsStatic: [
				{
					displayName: 'Site / Country',
					fieldName: 'siteAndInvestigatorAndCountry',
					type: 'string',
					formatter: 'pipe'
				},
				{
					displayName: 'Site / PI',
					fieldName: 'siteAndInvestigator',
					type: 'string',
					formatter: 'pipe'
				},
				{
					translationKey: 'app.common.subjectKey',
					fieldName: 'subjectKey',
					type: 'string',
					isDefaultFilter: true,
					isPrimary: true
				},
				{
					fieldName: 'patientStateValue',
					type: 'string'
				},
				{
					fieldName: 'eproSubjectStatus',
					type: 'string'
				},
				{
					fieldName: 'avgFev1Grade',
					type: 'string'
				},
				{
					fieldName: 'avgFvcGrade',
					type: 'string'
				},
				{
					fieldName: 'orFev1AcceptableRate',
					type: 'number'
				},
				{
					fieldName: 'orFvcAcceptableRate',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageCombined',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageSlow',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageDlco',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageForced',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageMbw',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageBody',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageHfs',
					type: 'number'
				},
				{
					fieldName: 'numberOfCompletedVisits',
					type: 'number'
				},
				{
					fieldName: 'numberOfMissedVisits',
					type: 'number'
				},
				{
					fieldName: 'subjectActivatedOn',
					type: 'date',
					formatter: 'dateFormat'
				},
				{
					fieldName: 'lastVisitDate',
					type: 'date',
					formatter: 'dateFormat'
				},
				{
					fieldName: 'lastVisitName',
					type: 'string'
				},
				{
					fieldName: 'nextVisitDate',
					type: 'date',
					formatter: 'dateFormat'
				},
				{
					fieldName: 'nextVisitName',
					type: 'string'
				},
				{
					fieldName: 'numberOfOpenDcrToErt',
					type: 'number'
				},
				{
					fieldName: 'numberOfQueriesToSite',
					type: 'number'
				},
				{
					fieldName: 'screeningCompliance',
					type: 'number'
				},
				{
					fieldName: 'treatmentCompliance',
					type: 'number'
				},
				{
					fieldName: 'diaryProSerialNumber',
					type: 'string'
				},
				{
					fieldName: 'overallCompliance',
					type: 'number'
				},
				{
					fieldName: 'numberOfSubjectDays',
					type: 'number'
				},
				{
					fieldName: 'subjectDemographics',
					type: 'string',
					formatter: 'pipe'
				}
			],
			sortOrders: [{
				fieldName: 'subjectKey'
			}],
			defaultRowAction: (row) => {
				this.rowClicked(row);
			},
			gridRowActions: [],
			gridActions: [],
			getData: (oDataQuery) => {
				return this.loadSubjects(oDataQuery);
			}
		};
	}

	updateColumnList(pdColumnConfig) {
		const columns = [];

		if (!this.countryId) {
			columns.push(this.options.columnDefsStatic[0]);
		} else if (!this.siteId) {
			columns.push(this.options.columnDefsStatic[1]);
		}

		pdColumnConfig.forEach(el => {
			const column = this.options.columnDefsStatic.find(c => c.fieldName === el.fieldName);

			if (column) {
				if (column.fieldName !== 'subjectKey') {
					column.displayName = el.label;
				}
				columns.push(column);
			}
		});
		this.options.columnDefs = columns;
	}

	generateGridRowActions(studySettings, iSpiroDeviceActionsPermission, kardiaPermission) {
		const gridRowActions = [];

		if (!studySettings.hideVisitCalendar) {
			gridRowActions.push({
				displayName: this.translations['clinicaldata.subject.buttons.calendar'],
				iconCssClass: 'es-icon-calendar',
				action: (row) => {
					const calObj = {
						countryId: row.countryId,
						siteId: row.siteId,
						subjectId: row.subjectId,
						siteName: `${row.sponsorSiteId} - ${row.investigator}`,
						subjectKey: row.subjectKey
					};

					this.calendarPopupApi.open(calObj);
				}
			});
		}

		if (studySettings.hasSubjectRelatedDevices) {

			gridRowActions.push({
				displayName: this.translations['clinicaldata.subject.buttons.info'],
				iconCssClass: studySettings.hasEcoaHandheld ? 'es-icon-qrcode' : 'es-icon-info-circle',
				action: (row) => {
					this.showSubjectInfoPopup(row);
				}
			});
		}

		if (studySettings.isKardiaStudy && kardiaPermission) {
			const kardiaActionsLoading = [{
				iconCssClass: 'es-loading-icon ert-dropdown-content-loading'
			}];

			gridRowActions.push({
				displayName: 'More',
				iconCssClass: 'es-icon-more',
				subItemsFactory: (row) => {
					if (!row.kardiaActions) {
						return kardiaActionsLoading;
					}
					return row.kardiaActions;
				},
				subItemsFactoryAction: (row) => {
					row.kardiaActions = undefined;
					this.kardiaService.getActions(row.countryId, row.siteId, row.subjectId)
						.then((response) => {
							const kardiaActions = [];

							if (response.data) {
								response.data.actions.forEach((action) => {
									kardiaActions.push(
										this.getKardiaAction(
											action, response.data.technicalSubjectId));
								});
							}
							row.kardiaActions = kardiaActions;
						})
						.catch(() => {
							row.kardiaActions = [this.getKardiaAction(this.appConfig.kardiaActionId.noActionAvailable)];
						});
				}
			});
		}

		if (studySettings.isiSpiroStudy && iSpiroDeviceActionsPermission) {

			const iSpiroActionsLoading = [{
				iconCssClass: 'es-loading-icon ert-dropdown-content-loading'
			}];

			gridRowActions.push({
				displayName: 'More',
				iconCssClass: 'es-icon-more',
				subItemsFactory: (row) => {
					if (!row.iSpiroActions) {
						return iSpiroActionsLoading;
					}
					return row.iSpiroActions;
				},
				subItemsFactoryAction: (row) => {
					row.iSpiroActions = undefined;
					this.iSpiroService.getActions(row.countryId, row.siteId, row.subjectId)
						.then((response) => {
							const iSpiroActions = [];

							if (response.data) {
								response.data.actions.forEach((action) => {
									iSpiroActions.push(
										this.getISpiroAction(
											action, response.data.technicalSubjectId));
								});
							}
							row.iSpiroActions = iSpiroActions;
						})
						.catch(() => {
							row.iSpiroActions = [this.getISpiroAction(this.appConfig.iSpiroActionId.noActionAvailable)];
						});
				}
			});
		}

		if (studySettings.usesEProSubjectModuleAction) {
			this.addEproActions();
		}

		return gridRowActions;
	}

	getISpiroAction(id, technicalSubjectId) {
		switch (+id) {
			case this.appConfig.iSpiroActionId.dispense:
				return {
					displayName: this.translations['clinicaldata.subject.buttons.dispenseDevice'],
					iconCssClass: 'es-icon-supply-ordering',
					action: (row) => {
						this.clinicalDataService.showDispenseDevicePopup(
							row.countryId, row.siteId, technicalSubjectId,
							`${row.sponsorSiteId} - ${row.investigator}`, row.subjectKey);
					}
				};
			case this.appConfig.iSpiroActionId.edit: {
				const isRemoteAction = true;

				return {
					displayName: this.translations['clinicaldata.subject.buttons.editDeviceValues'],
					iconCssClass: 'es-icon-function-edit',
					action: (row) => {
						this.clinicalDataService.showEditDeviceValuesPopup(
							isRemoteAction, row.countryId, row.siteId, technicalSubjectId,
							`${row.sponsorSiteId} - ${row.investigator}`, row.subjectKey);
					}
				};
			}
			case this.appConfig.iSpiroActionId.close:
				return {
					displayName: this.translations['clinicaldata.subject.buttons.closeDevice'],
					iconCssClass: 'es-icon-row-close',
					action: (row) => {
						this.clinicalDataService.showRemoteCloseDevicePopup(
							row.countryId, row.siteId, technicalSubjectId,
							`${row.sponsorSiteId} - ${row.investigator}`, row.subjectKey);
					}
				};
			case this.appConfig.iSpiroActionId.lost:
				return {
					displayName: this.translations['clinicaldata.subject.buttons.lostDevice'],
					iconCssClass: 'es-icon-missed',
					action: (row) => {
						this.clinicalDataService.showLostDevicePopup(
							row.countryId, row.siteId, technicalSubjectId,
							`${row.sponsorSiteId} - ${row.investigator}`, row.subjectKey);
					}
				};
			case this.appConfig.iSpiroActionId.resetPin:
				return {
					displayName: this.translations['clinicaldata.subject.buttons.resetPin'],
					iconCssClass: 'es-icon-unlock',
					action: (row) => {
						this.clinicalDataService.showResetPinPopup(
							row.countryId, row.siteId, technicalSubjectId,
							`${row.sponsorSiteId} - ${row.investigator}`, row.subjectKey);
					}
				};
			case this.appConfig.iSpiroActionId.scheduleEvent:
				return {
					displayName: this.translations['clinicaldata.subject.buttons.scheduleEvent'],
					iconCssClass: 'es-icon-calendar',
					action: (row) => {
						this.clinicalDataService.showScheduleEventPopup(
							row.countryId, row.siteId, `${row.sponsorSiteId} - ${row.investigator}`,
							row.technicalSubjectId, row.subjectKey);
					}
				};
			case this.appConfig.iSpiroActionId.noActionAvailable:
				return {
					displayName: this.translations['clinicaldata.subject.buttons.noActionAvailable'],
					iconCssClass: 'es-icon-info'
				};
		}
		return null;
	}

	addISpiroActions() {

		this.userService.getUserHasPermission('EditISpiroSubject').then((canEdit) => {
			const iSpiroActions = [];

			if (canEdit) {
				iSpiroActions.push({
					displayName: this.translations['clinicaldata.subject.buttons.editSubject'],
					iconCssClass: 'es-icon-function-edit',
					action: (row) => {
						this.showEditSubjectPopup(row);
					},
					enabledIf: (row) => {
						return row.allowEditISpiroSubject;
					}
				});
			}
			this.options.gridRowActions = this.options.gridRowActions.concat(iSpiroActions);
		});
	}

	getKardiaAction(id, technicalSubjectId) {
		switch (+id) {
			case this.appConfig.kardiaActionId.dispense:
				return {
					displayName: this.translations['clinicaldata.subject.buttons.dispenseDevice'],
					iconCssClass: 'es-icon-supply-ordering',
					action: (row) => {
						this.clinicalDataService.showDispenseKardiaPopup(
							row.countryId, row.siteId, technicalSubjectId,
							`${row.sponsorSiteId} - ${row.investigator}`, row.subjectKey);
					}
				};
			case this.appConfig.kardiaActionId.close:
				return {
					displayName: this.translations['clinicaldata.subject.buttons.closeDevice'],
					iconCssClass: 'es-icon-row-close',
					action: (row) => {
						this.clinicalDataService.showCloseKardiaPopup(
							row.countryId, row.siteId, technicalSubjectId,
							`${row.sponsorSiteId} - ${row.investigator}`, row.subjectKey);
					}
				};
			case this.appConfig.iSpiroActionId.noActionAvailable:
				return {
					displayName: this.translations['clinicaldata.subject.buttons.noActionAvailable'],
					iconCssClass: 'es-icon-info'
				};
		}
		return null;
	}


	addEproActions() {
		this.userService.getEproPermissions().then(
			(epro) => {
				const eproActions = [];

				if (epro.editSubject) {

					eproActions.push({
						displayName: this.translations['clinicaldata.subject.buttons.editSubject'],
						iconCssClass: 'es-icon-function-edit',
						action: (row) => {
							this.showEditSubjectPopup(row);
						},
						enabledIf: (row) => {
							return row.eproSubjectId ? row.isEproSubjectActive : true;
						}
					});
					eproActions.push({
						displayName: this.translations['clinicaldata.subject.status.buttons.deactivate'],
						iconCssClass: 'es-icon-row-close',
						action: (row) => {
							this.showDeactivatePopup(row.siteId, row.eproSubjectId);
						},
						enabledIf: (row) => {
							return row.eproSubjectId ? row.isEproSubjectActive : false;
						}
					});
					eproActions.push({
						displayName: this.translations['clinicaldata.subject.status.buttons.activate'],
						iconCssClass: 'es-icon-row-checkmark',
						action: (row) => {
							this.showActivatePopup(row.siteId, row.eproSubjectId);
						},
						enabledIf: (row) => {
							return row.eproSubjectId ? !row.isEproSubjectActive : false;
						}
					});
				}

				if (epro.changeContactAddress) {

					eproActions.push({
						enabledIfFieldName: 'allowEditContactAddress',
						displayName: this.translations['clinicaldata.subject.buttons.editContactData'],
						iconCssClass: 'es-icon-user-name',
						action: (row) => {
							this.showEditContactDataPopup(row);
						}
					});
				}

				if (epro.changePin) {

					eproActions.push({
						enabledIfFieldName: 'allowChangePin',
						displayName: this.translations['clinicaldata.subject.buttons.sendPin'],
						iconCssClass: 'es-icon-unlock',
						action: (row) => {
							this.showSendPinPopup(row);
						}
					});
				}

				if (eproActions.length > 0) {
					const eproGroup = [{
						displayName: 'More',
						iconCssClass: 'es-icon-more',
						subItemsFactory: () => {
							return eproActions;
						}
					}];

					this.options.gridRowActions = this.options.gridRowActions.concat(eproGroup);
				}
			}
		);
	}

	rowClicked(row) {
		this.analyticsService.trackEventWithTitle(
			`${this.appConfig.trackCategory.navigateTo}::${this.appConfig.trackId.clinicalDataVisit}`,
			this.appConfig.trackAction.gridEntry, this.appConfig.trackLabel.levelSubject);
		this.$state.go(this.appConfig.routes.clDataVisits, {
			country: row.countryId,
			site: row.siteId,
			subject: row.subjectId,
			eproSubject: row.eproSubjectId
		});
	}

	loadSubjects(oDataQuery) {
		return this.clinicalDataService.getSubjects(this.countryId, this.siteId, oDataQuery);
	}

	showSubjectInfoPopup(row) {
		this.infoPopupApi.open(row.siteId, row.subjectId, row.subjectKey, row.eproSubjectId);
	}

	getInfoPopupApi($API) {
		this.infoPopupApi = $API;
	}

	getCalendarPopupApi($API) {
		this.calendarPopupApi = $API;
	}

	getEditContactDataPopupApi($API) {
		this.editContactDataPopupApi = $API;
	}

	showEditContactDataPopup(row) {
		this.editContactDataPopupApi.open(row);
	}

	showDeactivatePopup(siteId, eproSubjectId) {
		this.activatePopupApi.open(siteId, eproSubjectId, true);
	}

	getActivatePopupApi($API) {
		this.activatePopupApi = $API;
	}

	showActivatePopup(siteId, eproSubjectId) {
		this.activatePopupApi.open(siteId, eproSubjectId);
	}

	getSendPinPopupApi($API) {
		this.SendPinPopupApi = $API;
	}

	showSendPinPopup(row) {
		this.SendPinPopupApi.open(row);
	}

	showEditSubjectPopup(row) {
		this.clinicalDataService.openEditSubject(row);
	}
}

export default SubjectsController;
