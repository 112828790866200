(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('SqfSiteInfoCtrl', SqfSiteInfoController);

	/**
	 * @ngInject
	 */
	function SqfSiteInfoController($scope, $stateParams, $log, $timeout, sqfService, WizardHandler, dialogService) {
		var vm = this;

		$log = $log.getInstance("SqfSiteInfoCtrl");

		$log.debug("SqfSiteInfoCtrl loaded, sqfId:" + $stateParams.sqfId);

		vm.localizedSiteInfo = $scope.shared.localized.additionalSiteInfo;
		vm.name = $scope.shared.localized.wizardTitles.additionalSiteInfo;
		vm.dataLoading = true;
		vm.saving = false;
		vm.loadingError = '';
		vm.errorMsgs = [];
		vm.sqfId = 0;
		vm.model = {};
		vm.options = {formState: {}};
		vm.forms = {};
		vm.confirmHandler = {showDialog: false};
		vm.actions = [];
		sqfService.registerSiteInfoHandler(vm);

		vm.showView = showView;
		vm.showSummary = showSummary;
		vm.saveSiteInfo = saveSiteInfo;
		vm.fields = [
			{
				wrapper: 'panel',
				templateOptions: {label: vm.localizedSiteInfo.siteTransmissionInfo},
				hideExpression: "!formState.config.showTransmissionInfo",
				fieldGroup: [					
					{
						key: 'hasDataTransferRequirements',
						type: 'radioInlineMessage',
						templateOptions: {
							label: vm.localizedSiteInfo.hasDataTransferRequirements,
							options: [
								{
									"name": $scope.shared.localized.options.yes,
									"value": "true"
								},
								{
									"name": $scope.shared.localized.options.no,
									"value": "false"
								}]
						},
						expressionProperties: {
							'templateOptions.required': "formState.config.hasDataTransferRequirementsIsRequired"
						}
					},
					{
						key: 'dataTransferRequirements',
						type: 'esSgTextAreaAligned',
						templateOptions: {
							placeholder: vm.localizedSiteInfo.dataTransferRequirements,
							rows: 10,
							id: 'requirementsField'
						},
						ngModelElAttrs: {
							maxlength: '2000'
						},
						hideExpression: "model.hasDataTransferRequirements !== 'true'",
						validators: {
							maxValue: {
								expression: function (viewValue, modelValue) {
									var value = modelValue || viewValue;
									if(value) {
										var newLines = value.match(/(\r\n|\n|\r)/g);
										var addition = newLines !== null ? newLines.length : 0;

										$log.debug(`value length:${value.length},newLines length:${addition}, toal length:${value.length + addition}, value=${value}`);
										if (value.length + addition > 2000) {
										 	return false;
										}
									}
									return true;
								},
								message: "'{message} {value}'".supplant({ message: $scope.shared.localized.messages.maxLength, value: 2000 })
							}
						}
					}
				]
			},
			{
				wrapper: 'panel',
				templateOptions: {label: vm.localizedSiteInfo.siteStartUpInformation},
				hideExpression: "!formState.config.showSiteStartupInfo",
				fieldGroup: [
					{
						key: 'equipmentNeededDate',
						type: 'datePickerInline',
						templateOptions: {
							label: vm.localizedSiteInfo.equipmentNeededDate,
							controlDivClass: ''
						},
						ngModelElAttrs: {
							maxlength: '11'
						},
						expressionProperties: {
							'templateOptions.required': "formState.config.equipmentNeededDateIsRequired"
						},
						validators: {
							future: {
								expression: function (viewValue, modelValue) {
									var value = modelValue || viewValue;
									if (value) {
										value = value.replace(/-/g, ' ');
										var now = new Date();

										now.setHours(0,0,0,0);

										var entered = new Date(value);

										if (entered && entered.getYear && entered.getYear() > 0) {
											return entered > now;
										}
									}
									return true;
								},
								message: "'{message}'".supplant({ message: $scope.shared.localized.messages.equipmentNeededDateInFuture })
							}
						}
					},
					{
						template: "<strong>{equipmentNeededDateSummary}</strong>".supplant(vm.localizedSiteInfo)
					}
				]
			},
			{
				wrapper: 'panel',
				templateOptions: {label: vm.localizedSiteInfo.siteDetails},
				hideExpression: "!formState.config.showSiteDetails",
				fieldGroup: [
					{
						template: "<strong>{siteDetailsSummary}</strong>".supplant(vm.localizedSiteInfo)
					},
					{
						key: 'canRecordA4ECG',
						type: 'radioInlineMessage',
						templateOptions: {
							label: vm.localizedSiteInfo.canRecordA4ECG,
							options: [
								{
									"name": $scope.shared.localized.options.yes,
									"value": "true"
								},
								{
									"name": $scope.shared.localized.options.no,
									"value": "false"
								}],
							required: true
						}
					}
				]
			}
		];
		vm.originalFields = angular.copy(vm.fields);

		if ($stateParams.sqfId > 0) {
			showView($stateParams.sqfId);
		}

		function clear() {
			vm.dataLoading = true;
			vm.saving = false;
			vm.loadingError = '';
			vm.errorMsgs = [];
			vm.sqfId = 0;
			vm.model = {};
			vm.options = {formState: {}};
			vm.forms = {};
		}

		function showSummary(confirmed) {
			if (confirmed || vm.options.formState.config.allowEdit === false || vm.forms.$dirty !== true) {
				WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.summary);
				sqfService.showSummaryView(vm.sqfId);
			}
			else {
				vm.confirmHandler = dialogService.createConfirmHandler();
				vm.confirmHandler.show().then(function (response) {
					if (response === true) {
						WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.summary);
						sqfService.showSummaryView(vm.sqfId);
						vm.confirmHandler.hide();
					}
				});
			}
		}


		function setActions() {
			vm.actions.length = 0;
			if ($scope.shared.newSqf === true) {
				vm.actions.push({displayName: $scope.shared.localized.buttons.next, action: saveSiteInfo,
								 isPrimary: true});
			}
			else {
				vm.actions.push({displayName: $scope.shared.localized.buttons.save, action: saveSiteInfo,
								 isPrimary: true});
				vm.actions.push({displayName: $scope.shared.localized.buttons.cancel, action: showSummary});
			}
			$scope.shared.handleHide = function(){$scope.shared.closeWizard();};
			updateActions();
			$scope.shared.setActions(vm.actions);
		}

		function updateActions() {
			vm.actions[0].isDisabled = vm.dataLoading || vm.saving;
			if (vm.actions.length > 1) {
				vm.actions[1].isDisabled = vm.saving;
			}
		}

		function showView(sqfId) {
			clear();
			vm.sqfId = sqfId;
			setActions();
			sqfService.getSiteInfo(vm.sqfId)
				.then(function (response) {
					if (!vm.loadingError) {
						vm.model = angular.copy(response.data.model);
						vm.options.formState.config = response.data.config;
						$timeout(() => {
							vm.model.equipmentNeededDate = response.data.model.equipmentNeededDate;
						});

					}
					vm.dataLoading = false;
					updateActions();
				}, function (error) {
					vm.loadingError = error.data.message || error.data;
				});
		}

		function saveSiteInfo() {
			vm.errorMsgs = [];
			if (!vm.forms.$valid) {
				vm.forms.$setSubmitted();
				return;
			}
			vm.saving = true;
			updateActions();
			sqfService.saveSiteInfo(vm.sqfId, vm.model)
				.then(function (response) {
					if (response.data.isSuccessful) {
						showSummary(true);
					} else {
						vm.saving = false;
						updateActions();
						vm.errorMsgs = response.data.messages;
					}
				}, function (error) {
					vm.errorMsgs = [error.data.message || error.data];
					vm.saving = false;
					updateActions();
				});
		}
	}
})();
