/**
 * Created by AMorbia on 9/17/2015.
 */
(function () {
	'use strict';

	angular.module('portalApp').factory('oDataHelper', oDataHelper);

	function oDataHelper() {
		let uriBuilders = {};

		return {
			buildUriFor,
			getUri,
			getCombinedUri,
			getUriBuilderForBuilderName,
			combineUriBuilder,
			removeUriBuilder,
			clearUriBuilders
		};

		function buildUriFor(from) {
			return new ODataUriBuilder(from);
		}

		function combineUriBuilder(name, oDataUriBuilder) {
			if (!name) {
				throw Error('Any name was provided for oDataUriBuilder object');
			}
			uriBuilders[name] = oDataUriBuilder;
		}

		function clearUriBuilders() {
			uriBuilders = {};
		}

		function removeUriBuilder(name) {
			delete uriBuilders[name];
		}

		function getUriBuilderForBuilderName(name) {
			if (uriBuilders.hasOwnProperty(name)) {
				return uriBuilders[name];
			}
			return null;
		}

		function getUri(name) {
			const builder = getUriBuilderForBuilderName(name);

			return `${builder.toUri()}`;
		}

		function getCombinedUri() {
			const uriParts = [];

			for (const key in uriBuilders) {
				const builder = getUriBuilderForBuilderName(key);

				if (builder) {
					const uri = builder.toUri();

					if (uri) {
						uriParts.push(builder.toUri());
					}
				}
			}

			return uriParts.join('&');
		}
	}

	function ODataUriBuilder(from) {
		this._privateData = {};
		this._privateData.from = from || '';
		this._privateData.parameter = [];
		this._privateData.where = [];
		this._privateData.orderBy = [];
		this._privateData.skip = 0;
		this._privateData.top = 0;
		this.aliasMap = {};
		this.opMap = {
			'eq': {
				aliases: ['==', 'equals']
			},
			'ne': {
				aliases: ['!=', '~=', 'notequals']
			},
			'lt': {
				aliases: ['<', 'lessthan']
			},
			'le': {
				aliases: ['<=', 'lessthanorequal']
			},
			'gt': {
				aliases: ['>', 'greaterthan']
			},
			'ge': {
				aliases: ['>=', 'greaterthanorequal']
			},
			'startswith': {
				isFunction: true
			},
			'endswith': {
				isFunction: true
			},
			'contains': {
				aliases: ['substringof'],
				isFunction: true
			}
		};

		for (const op in (this.opMap)) {
			updateAliasMap(this.aliasMap, op, this.opMap[op]);
		}

		function updateAliasMap(aliasMap, op, config) {
			const key = op.toLowerCase();

			config.key = key;
			aliasMap[key] = config;

			if (config.aliases) {
				config.aliases.forEach((alias) => {
					aliasMap[alias.toLowerCase()] = config;
				});
			}
		}
	}

	ODataUriBuilder.prototype.where = function (property, operator, value, type) {
		this._privateData.where.push({ property, operator, value, type });
		return this;
	};

	ODataUriBuilder.prototype.parameter = function (name, value) {
		this._privateData.parameter.push({ name, value });
		return this;
	};

	ODataUriBuilder.prototype.orderBy = function (properties, isDescending) {
		this._privateData.orderBy.push({ properties, isDescending });
		return this;
	};

	ODataUriBuilder.prototype.skip = function (count) {
		this._privateData.skip = count;
		return this;
	};

	ODataUriBuilder.prototype.top = function (count) {
		this._privateData.top = count;
		return this;
	};

	ODataUriBuilder.prototype.toUri = function () {
		const uriData = [];

		if (this._privateData.from.length > 0) {
			uriData.push(this._privateData.from, '?');
		}
		let str = this.buildParameter();

		if (str.length > 0) {
			uriData.push(str);
		}

		str = this.buildWhere();
		if (str.length > 0) {
			if (uriData.length > 0 && uriData[uriData.length - 1] !== '&') {
				uriData.push('&');
			}
			uriData.push('$filter=', encodeURIComponent(str));
		}

		str = this.buildOrderBy();
		if (str.length > 0) {
			if (uriData.length > 0 && uriData[uriData.length - 1] !== '&') {
				uriData.push('&');
			}
			uriData.push('$orderby=', str);
		}

		const skip = this._privateData.skip;

		if (skip !== null && skip > 0) {
			if (uriData.length > 0 && uriData[uriData.length - 1] !== '&') {
				uriData.push('&');
			}
			uriData.push('$skip=', skip);
		}

		const top = this._privateData.top;

		if (top !== null && top > 0) {
			if (uriData.length > 0 && uriData[uriData.length - 1] !== '&') {
				uriData.push('&');
			}
			uriData.push('$top=', top);
		}
		return uriData.join('');
	};

	ODataUriBuilder.prototype.buildParameter = function () {
		const temp = [];
		const parameter = this._privateData.parameter;

		if (parameter !== null && parameter.length > 0) {
			parameter.forEach((item) => {
				if (temp.length > 1) {
					temp.push('&');
				}
				temp.push(item.name, '=', item.value);
			});
		}
		return temp.join('');
	};

	ODataUriBuilder.prototype.buildWhere = function () {
		const self = this;
		const where = this._privateData.where;
		const temp = [];
		let isOrOpen = false;

		if (where !== null && where.length > 0) {
			for (let i = 0; i < where.length; i++) {
				if (i > 0) {
					// concatenate filter values for the same property with OR
					if (isOrOpen && where[i].property === where[i - 1].property) {
						temp.push('or');
					} else {
						if (isOrOpen) {
							temp.push(')');
							isOrOpen = false;
						}
						temp.push('and');
					}
				}

				if (!isOrOpen && where[i].type.startsWith('enum') && i + 1 < where.length &&
					where[i].property === where[i + 1].property) {
					temp.push('(');
					isOrOpen = true;
				}

				const op = self.aliasMap[where[i].operator.toLowerCase()];

				if (!op) {
					throw new Error(`ODataUriBuilder: Unable to resolve operator: ${where[i].operator}`);
				}

				if (op.isFunction === true) {
					temp.push(`${op.key}(${where[i].property},${where[i].value})`);
				} else {
					temp.push(where[i].property, op.key, where[i].value);
				}

				if (isOrOpen && i + 1 >= where.length) {
					temp.push(')');
				}
			}
		}
		return temp.join(' ');
	};

	ODataUriBuilder.prototype.buildOrderBy = function () {
		const temp = [];
		const orderBy = this._privateData.orderBy;

		if (orderBy !== null && orderBy.length > 0) {
			orderBy.forEach((item) => {
				if (temp.length > 0) {
					temp.push(',');
				}
				const props = item.properties.split(',');

				props.forEach((prop) => {
					temp.push(prop);
					if (item.isDescending) {
						temp.push(' desc');
					}
				});
			});
		}
		return temp.join('');
	};

})();

