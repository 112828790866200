const MinimumVersionParts = 3;
const BrowserRules = [
  ['edge', /Edge\/([0-9\._]+)/],
  ['edge-ios', /EdgiOS\/([0-9\._]+)/],
  ['edge-chromium', /EdgA?\/([0-9\.]+)/],
  ['samsung', /SamsungBrowser\/([0-9\.]+)/],
  ['chromium-webview', /(?!Chrom.*OPR)wv\).*Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
  ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
  ['chrome-ios', /CriOS\/([0-9\.]+)(:?\s|$)/],
  ['phantomjs', /PhantomJS\/([0-9\.]+)(:?\s|$)/],
  ['firefox', /Firefox\/([0-9\.]+)(?:\s|$)/],
  ['firefox-ios', /FxiOS\/([0-9\.]+)/],
  ['opera-mini', /Opera Mini.*Version\/([0-9\.]+)/],
  ['opera', /Opera\/([0-9\.]+)(?:\s|$)/],
  ['opera', /OPR\/([0-9\.]+)(:?\s|$)/],
  ['ie', /Trident\/7\.0.*rv\:([0-9\.]+).*\).*Gecko$/],
  ['ie', /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/],
  ['ie', /MSIE\s(7\.0)/],
  ['bb10', /BB10;\sTouch.*Version\/([0-9\.]+)/],
  ['android', /Android\s([0-9\.]+)/],
  ['safari', /Version\/([0-9\._]+).*Safari/],
  ['facebook', /FB[AS]V\/([0-9\.]+)/],
  ['instagram', /Instagram\s([0-9\.]+)/],
  ['ios-webview', /AppleWebKit\/([0-9\.]+).*Mobile/],
  ['ios-webview', /AppleWebKit\/([0-9\.]+).*Gecko\)$/]
];
const OperatingSystemRules = [
  ['iOS', /iP(hone|od|ad)/],
  ['Android OS', /Android/],
  ['BlackBerry OS', /BlackBerry|BB10/],
  ['Windows Mobile', /IEMobile/],
  ['Amazon OS', /Kindle/],
  ['Windows 3.11', /Win16/],
  ['Windows 95', /(Windows 95)|(Win95)|(Windows_95)/],
  ['Windows 98', /(Windows 98)|(Win98)/],
  ['Windows 2000', /(Windows NT 5.0)|(Windows 2000)/],
  ['Windows XP', /(Windows NT 5.1)|(Windows XP)/],
  ['Windows Server 2003', /(Windows NT 5.2)/],
  ['Windows Vista', /(Windows NT 6.0)/],
  ['Windows 7', /(Windows NT 6.1)/],
  ['Windows 8', /(Windows NT 6.2)/],
  ['Windows 8.1', /(Windows NT 6.3)/],
  ['Windows 10', /(Windows NT 10.0)/],
  ['Windows ME', /Windows ME/],
  ['Windows CE', /Windows CE|WinCE|Microsoft Pocket Internet Explorer/],
  ['Open BSD', /OpenBSD/],
  ['Sun OS', /SunOS/],
  ['Chrome OS', /CrOS/],
  ['Linux', /(Linux)|(X11)/],
  ['Mac OS', /(Mac_PowerPC)|(Macintosh)/],
  ['QNX', /QNX/],
  ['BeOS', /BeOS/],
  ['OS/2', /OS\/2/]
];

/**
 * Object to detect browser and its version
 * @type {{init: Function, searchString: Function, searchVersion: Function, dataBrowser: *[]}}
 * @example
 * BrowserDetect.init();
 * console.log("You are using <b>" + BrowserDetect.browserName + "</b> with version <b>" + BrowserDetect.browserVersion + "</b>")
 */

var BrowserDetect = {

  init: function () {
    var detectedBrowser = this.detectBrowser(navigator.userAgent);
    this.browserName = detectedBrowser.name || "Other";
    this.browserVersion = this.parseBrowserVersion(detectedBrowser.details[1]) || "Unknown";
    this.operatingSystemName = this.detectOperatingSystem(navigator.userAgent).name;
  },

  detectBrowser: function(userAgent) {
    var browser = this.findUserAgentPartMatchRule(BrowserRules, userAgent);
    return browser;   
  },

  detectOperatingSystem: function(userAgent) {
    var operatingSystem = this.findUserAgentPartMatchRule(OperatingSystemRules, userAgent);
    return operatingSystem;   
  },

  findUserAgentPartMatchRule: function(rules, userAgent) {
    var userAgentPart = {
      name: '',
      details: ''
    };
    var matchRule = rules.find((rule) => {
      userAgentPart.details = rule[1].exec(userAgent);
      return userAgentPart.details !== null;
    }); 
    userAgentPart.name = matchRule[0];
    return userAgentPart;
  },

  parseBrowserVersion(version) {
    var versionParts = version && version.split('.').join('_').split('_').slice(0, 3);
    if(!versionParts) {
      return [];
    }
    if(versionParts.length < MinimumVersionParts){
      versionParts = [
        ...versionParts,
        ...new Array(MinimumVersionParts - versionParts.length).fill('0')
      ]
    }
    return versionParts.join('.');
  }
};

BrowserDetect.init();

module.exports = BrowserDetect;

