import tableComponent from './table.component';
import noDataRowModule from '../noDataRow/index';
import exportButtonModule from '../exportButton/index';
import sortingModule from '../sorting';
import loadingRowModule from '../loadingRow';
import oDataService from '../oData/oData.service';
import tableExportService from '../tableExport/tableExport.service';
import rowActionModule from '../rowAction';


const tableModule = angular.module('app.common.table', [
	noDataRowModule,
	sortingModule,
	loadingRowModule,
	exportButtonModule,
	rowActionModule
])
	  .component('tableWrap', tableComponent)
	  .service('oDataService', oDataService)
	  .service('tableExportService', tableExportService)
	  .name;

export default tableModule;
