/*
 * dashboardComponent
 * */

import templateUrl from './dashboard.html';
import controller from './dashboard.controller';
import './dashboard.scss';

const dashboardComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default dashboardComponent;
