class PreviewPopupController {
	constructor($scope, $http, $log, previewPopupService, popupService, notificationService, appI18n, appConfig) {
		'ngInject';
		this.$scope = $scope;
		this.$http = $http;
		this.$log = $log;
		this.notificationService = notificationService;
		this.previewPopupService = previewPopupService;
		this.popupService = popupService;
		this.appI18n = appI18n;
		this.appConfig = appConfig;
		this.open = false;
		this.isLoading = false;
	}

	$onInit() {
		const self = this;
		this.appUrl = window.location.protocol + "//" + window.location.host;
		this.redactionUrl = this.appUrl + "/redaction.html";

		this.previewPopupService.onShowPopup.subscribe((config) => {
			if (config.url && config.filename) {
				self.config = config;
				self.open = true;
				self.isLoading = true;

				// wait for component fully changed
				setTimeout(() => {
					self.loadFile(config.url, config.filename, config.boxes);
				}, 100);
			} else {
				self.notificationService.showError(self.appI18n.translateImmediate('app.errors.genericLoadError'));
				self.$log.error(`error load file: ${error}`);
			}
		});
	}

	$onDestroy() {
		this.closeRedactionTool();
	}

	openRedactionTool(payload) {
		const strWindowFeatures = [
			'location=no',
			'menubar=no',
			'directories=no',
			'scrollbar=yes',
			'status=yes',
			`height=${+screen.availHeight}`,
			`width=${+screen.availWidth}`,
		].join(',')

		this.previewElement = window.open(this.redactionUrl, 'redactionWindow', strWindowFeatures)
		this.redactionToolPayload = payload

		this.abortController = new AbortController();
		window.addEventListener('message', this.redactionToolEventListener.bind(this), { signal: this.abortController.signal })
		this.checkRedactionToolLaunch()
	}

	redactionToolEventListener(event) {
		if (event.origin !== this.appUrl || !event.data) {
			return
		}
		
		if (event.data.type === 'LOADED') {
			this.isLoading = false;
			this.isPopupLoaded = true
			this.previewElement.postMessage({ type: 'INITIAL', payload: this.redactionToolPayload }, this.appUrl)
		} else if  (event.data.type === 'SAVE') {
			if (event.data.payload) {
				this.save(event.data.payload.blob, event.data.payload.boxes);
			}
			this.hide();
		} else if (event.data.type === 'CLOSED') {
			this.hide();
		} else if (event.data.type === 'ERROR') {
			this.$log.error(event.data.payload);
		}
	}

	checkRedactionToolLaunch() {
		setTimeout(() => {
			if (!this.isPopupLoaded && this.previewElement) {
				this.previewElement.postMessage({ type: 'LAUNCH_CHECKING' }, this.appUrl)
				this.checkRedactionToolLaunch()
			}
		}, 100)
	}

	loadFile(url, filename, boxes) {
		const self = this;
		const config = { responseType: 'blob' };
		const httpPromise = this.$http.get(url, config);

		httpPromise.then(function (response) {
			self.isLoading = false;
			self.closeRedactionTool();

			// Wait for redaction window close before open new one
			setTimeout(() => {
				self.openRedactionTool({ 
					blob: response.data,
					filename,
					boxes,
					isEditable: !!self.config.changeCallback,
					messages: {
						saveBtn: self.appI18n.translateImmediate('app.buttons.save'),
						closeBtn: self.appI18n.translateImmediate('app.buttons.close'),
						unsavedChanges: self.appI18n.translateImmediate('app.messages.unsavedChanges'),
						uploadErrorMsg: self.appI18n.translateImmediate('app.fileUpload.uploadErrorMsg')
					}
				})
			}, 50)
		}, function (error) {
			self.isLoading = false;
			self.open = false;
			self.notificationService.showError(self.appI18n.translateImmediate('app.errors.genericLoadError'));
			self.$log.error(`error load file: ${error}`);
		});
	}

	save(blob, boxes) {
		if (this.config.changeCallback && blob) {
			const file = new File([blob], this.config.filename);
			this.config.changeCallback(file, boxes, true);
		}

		this.hide()
	}

	closeRedactionTool() {
		if (this.previewElement) {
			this.isPopupLoaded = false;
			this.previewElement.postMessage({ type: 'CLOSE_BY_PARENT' }, this.appUrl);
			this.previewElement = null;
		}

		if (this.abortController) {
			this.abortController.abort();
			this.abortController = null;
		}
	}

	hide() {
		if (this.open) {
			this.closeRedactionTool();

			// Wait for redaction tool closed
			setTimeout(() => {
				this.loading = false;
				this.open = false;
				this.$scope.$apply();
			}, 50)
		}
	}
}

export default PreviewPopupController;
