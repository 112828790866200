(function() {

    'use strict';

    angular
        .module('portalApp')
        .controller('RefMaterialCtrl', ReferenceMaterialController);

    /**
     * @ngInject
     */

    function ReferenceMaterialController($scope, $log, appConfig, refMatService, appI18n,
        dialogService, documentService, configurationService, loadingIndicatorService) {

        $log = $log.getInstance("ReferenceMaterialController");
        $log.debug("loaded");

        var vm = this;

        vm.showStudies = false;

        vm.folderOptions = {
            rowDefs: {
                multiSelection: false,
                reportsLoaded: true,
                identifier: 'folderId',
                groupHeader: {
                    groupByDisplayName: function(row) {
                        return row.folderName;
                    },
                    groupByFieldName: function(row) {
                        return row.folderId;
                    },
                    descriptionArray: function(row) {
                        return '';
                    },
                    numberOfItems: function(row) {
                        return row.numberOfFiles;
                    },
                    numberOfItemsText: 'Number of Files',
                    expandGroupByNameOrFirst: function() {
                        return undefined;
                    },
                    actions: []
                }
            },
            gridRowActions: [],
            gridActions: [],

            pageSize: 10,
            sortOrders: [{
                fieldName: 'folderName',
                reverseOrder: false
            }],
            removeExportControls: true,

            getData: getFolders
        };

        function getFolders(options) {
            return refMatService.getFolders(options);
        }

        function refreshGrid() {
            vm.reload = true;
        }

        activate();

        function activate() {
			loadingIndicatorService.show();
            loadSiteMapName();
            appI18n.translate([
                    'refmat.new-folder-title',
                    'refmat.title',
                    'refmat.study-title',
                    'refmat.edit-title',
                    'refmat.delete-title',
                    'refmat.add-file-title',
                    'refmat.preview-title',
                    'refmat.studies-title',
                    'refmat.error-loading'
                ])
                .then(function(translations) {
                    vm.newFolderTitle = translations['refmat.new-folder-title'];
                    vm.title = translations['refmat.title'];
                    vm.studyTitle = translations['refmat.study-title'];
                    vm.editTitle = translations['refmat.edit-title'];
                    vm.deleteTitle = translations['refmat.delete-title'];
                    vm.addFileTitle = translations['refmat.add-file-title'];
                    vm.previewTitle = translations['refmat.preview-title'];
                    vm.studiesTitle = translations['refmat.studies-title'];
                    vm.errorLoading = translations['refmat.error-loading'];

                    refMatService.getPermission()
                        .then(function(response) {
                            vm.permission = response.data;
                            if (response.data.isAdmin) {
                                vm.actions = [{
                                    displayName: vm.newFolderTitle,
                                    iconClass: 'es-icon-reference-material',
                                    cssClass: '-es-primary',
                                    action: function() {
                                        refMatService.openNewEditFolder(0, vm.permission);
                                    }
                                }];

                                vm.folderOptions.rowDefs.groupHeader.actions.push({
                                    displayName: vm.editTitle,
                                    iconCssClass: 'es-icon-function-edit',
                                    action: function(group) {
                                        refMatService.openNewEditFolder(group.id, vm.permission);
                                    }
                                }, {
                                    displayName: vm.deleteTitle,
                                    iconCssClass: 'es-icon-function-delete',
                                    action: function(group) {
                                        vm.deleteFolderContents(group);
                                    }
                                });
                            }
                            if (response.data.canEditFiles || response.data.isAdmin) {
                                vm.folderOptions.columnDefs = [{
                                        displayName: 'Folder Name',
                                        fieldName: 'folderName',
                                        type: 'string',
                                        showOnlyInFilter: true,
                                        isDefaultFilter: true
                                    },
                                    {
                                        displayName: 'Study Name',
                                        fieldName: 'studyNames',
                                        type: 'string',
                                        showOnlyInFilter: true
                                    },
                                    {
                                        displayName: 'Study #',
                                        fieldName: 'studyNumbers',
                                        type: 'string',
                                        showOnlyInFilter: true
                                    },
                                    {
                                        displayName: 'File Name',
										cssClass: '-es-width-3',
                                        fieldName: 'fileName',
                                        type: 'string',
                                        removeFromFilter: true
                                    },
                                    {
                                        displayName: 'Modified(UTC)',
										cssClass: '-es-width-1',
                                        fieldName: 'modifiedOn',
                                        type: 'date',
                                        removeFromFilter: true,
                                        formatter: 'dateTimeHSFormat'
                                    },
                                    {
                                        displayName: 'Modified by',
										cssClass: '-es-width-2',
                                        fieldName: 'modifiedBy',
                                        type: 'string',
                                        removeFromFilter: true
                                    },
                                    {
                                        displayName: 'Created(UTC)',
										cssClass: '-es-width-1',
                                        fieldName: 'createdOn',
                                        type: 'date',
                                        removeFromFilter: true,
                                        formatter: 'dateTimeHSFormat'
                                    },
                                    {
                                        displayName: 'Created by',
										cssClass: '-es-width-2',
                                        fieldName: 'createdBy',
                                        type: 'string',
                                        removeFromFilter: true
                                    },
                                    {
                                        displayName: 'Version',
										cssClass: '-es-width-1',
                                        fieldName: 'version',
                                        type: 'string',
                                        removeFromFilter: true,
                                    },
                                    {
                                        displayName: 'Size',
										cssClass: '-es-width-1',
                                        fieldName: 'size',
                                        type: 'string',
                                        removeFromFilter: true,
                                    },
                                    {
                                        displayName: 'Folder Active',
                                        fieldName: 'isActive',
                                        showOnlyInFilter: true,
                                        type: 'enum',
                                        enumType: 'number',
                                        enums: [{
                                                displayName: 'Yes',
                                                values: [1]
                                            },
                                            {
                                                displayName: 'No',
                                                values: [0, -1]
                                            }
                                        ]
                                    },
                                    {
                                        displayName: 'File Active',
                                        fieldName: 'isFileActive',
                                        type: 'string',
                                        removeFromFilter: true,
                                        formatter: 'yesNo',
										cssClass: '-es-width-1'
                                    }

                                ];
                                vm.folderOptions.rowDefs.groupHeader.actions.push({
                                    displayName: vm.addFileTitle,
                                    iconCssClass: 'es-icon-android-add-circle',
                                    action: function(group) {
                                        refMatService.openNewEditFile({
                                            folderId: group.id,
                                            fileId: 0,
                                            isActive: true
                                        }, vm.permission, true);
                                    }
                                });

                                vm.folderOptions.rowDefs.groupHeader.descriptionArray = function(row) {
                                    return [row.displayStudyName, (row.isActive ? "Active" : "Inactive")];
                                };
								vm.folderOptions.rowDefs.groupHeader.descriptionCssClass = '-es-width-6';


                                vm.folderOptions.gridRowActions.push({
                                    displayName: vm.editTitle,
                                    iconCssClass: 'es-icon-function-edit',
                                    action: function(row) {
                                        vm.showNewEditFile = true;
                                        refMatService.openNewEditFile(row, vm.permission, false);
                                    }
                                }, {
                                    displayName: vm.deleteTitle,
                                    iconCssClass: 'es-icon-function-delete',
                                    action: function(row) {
                                        vm.deleteFileData(row);
                                    }
                                });
                            } else {
                                vm.folderOptions.columnDefs = [{
                                        displayName: 'Folder Name',
                                        fieldName: 'folderName',
                                        type: 'string',
                                        isHidden: true,
                                        isDefaultFilter: true
                                    },
                                    {
                                        displayName: 'Study Name',
                                        fieldName: 'studyNames',
                                        type: 'string',
                                        removeFromFilter: true,
                                        isHidden: true
                                    },
                                    {
                                        displayName: 'Study Number',
                                        fieldName: 'studyNumbers',
                                        type: 'string',
                                        removeFromFilter: true,
                                        isHidden: true
                                    },
                                    {
                                        displayName: 'File Name',
                                        fieldName: 'fileName',
                                        type: 'string',
                                        removeFromFilter: true
                                    },
                                    {
                                        displayName: 'Modified(UTC)',
                                        fieldName: 'modifiedOn',
                                        type: 'date',
                                        removeFromFilter: true,
                                        formatter: 'dateTimeHSFormat'
                                    },
                                    {
                                        displayName: 'Modified by',
                                        fieldName: 'modifiedBy',
                                        type: 'string',
                                        removeFromFilter: true
                                    },
                                    {
                                        displayName: 'Created(UTC)',
                                        fieldName: 'createdOn',
                                        type: 'date',
                                        removeFromFilter: true,
                                        formatter: 'dateTimeHSFormat'
                                    },
                                    {
                                        displayName: 'Created by',
                                        fieldName: 'createdBy',
                                        type: 'string',
                                        removeFromFilter: true
                                    },
                                    {
                                        displayName: 'Version',
                                        fieldName: 'version',
                                        type: 'number',
                                        removeFromFilter: true
                                    },
                                    {
                                        displayName: 'Size',
                                        fieldName: 'size',
                                        type: 'number',
                                        removeFromFilter: true
                                    }
                                ];
                            }
                            vm.folderOptions.gridRowActions.push({
                                displayName: vm.previewTitle,
                                iconCssClass: 'es-icon-reports',
                                action: function(row) {
                                    vm.showData(row);
                                }
                            });
                            vm.load = true;
                        }, function() {
                            vm.errorMessage = vm.errorLoading;
                        }).finally(() => {
							loadingIndicatorService.hide();
						});
                    refMatService.registerRefresh(refreshGrid);

                });

        }

        vm.deleteFolderContents = function(group) {
            vm.confirmFolderHandler = dialogService.createConfirmHandler();
            vm.confirmFolderHandler.show()
                .then(function(result) {
                    if (result === true) {
	                    loadingIndicatorService.show();
                        vm.errorMessage = "";
                        refMatService.deleteFolder(group.id)
                            .then(function() {
                                $log.debug(" folder deleted successfully.");
	                            loadingIndicatorService.hide();
                                vm.confirmFolderHandler.hide();
                                refMatService.refreshGrid();
                            }, function(error) {
                                $log.debug("error in deleting announcement: " + error);
	                            loadingIndicatorService.hide();
                                vm.confirmFolderHandler.showErrorMessages(vm.deleteErrorMessage);
                            });
                    }
                });
        };

        vm.deleteFileData = function(row) {
            vm.confirmFileHandler = dialogService.createConfirmHandler();
            vm.confirmFileHandler.show()
                .then(function(result) {
                    if (result === true) {
	                    loadingIndicatorService.show();
                        vm.errorMessage = "";
                        refMatService.deleteFile(row.folderId, row.fileId)
                            .then(function() {
                                $log.debug(" file deleted successfully.");
	                            loadingIndicatorService.hide();
                                vm.confirmFileHandler.hide();
                                refMatService.refreshGrid();
                            }, function(error) {
                                $log.debug("error in deleting announcement: " + error);
	                            loadingIndicatorService.hide();
                                vm.confirmFileHandler.showErrorMessages(vm.deleteErrorMessage);
                            });
                    }
                });
        };

        vm.showData = function(row) {
            refMatService.getFile(row.folderId, row.fileId)
                .then(function(response) {
                    documentService.downloadReport(response.data, $scope, appConfig.downloadDocumentType.refMat);
                });
        };

        function loadSiteMapName() {
            configurationService.getSiteMapLabel(appConfig.siteMapModuleIds.refmat).then(
                function(label) {
                    vm.moduleLabel = label;
                }
            );
        }
    }

})();
