(function () {

	'use strict';

	angular
	.module('portalApp')
	.service('contactusService', ContactusService);

	/**
	 * @ngInject
	 */
	function ContactusService(appConfig, $http, $log, appSession) {
		$log = $log.getInstance("ContactusService", "color:blue");
		$log.debug("loaded");

		var service = {
			getSiteInfo: getSiteInfo,
			submit: submit
		};

		return service;

		function log (name, url) {
			$log.debug(`ContactusService.${name} with url: ${url}`);
		}
		function getSiteInfo() {
			var url = appConfig.apiUrls.contactus.siteInfo.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId});
			log('getSiteInfo', url);

			return $http.get(url);
		}

		function submit(model) {
			var url = appConfig.apiUrls.contactus.siteInfo.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId});
			log('submit', url);

			var data = JSON.stringify(model);
			return $http.post(url, data);
		}
	}
})();
