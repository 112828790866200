/*
 * dateRangeModule
 * */

import dateRangeComponent from './dateRange.component';

const dateRangeModule = angular.module('app.common.filters.dateRange', [])
	.component('dateRange', dateRangeComponent)
	.name;

export default dateRangeModule;
