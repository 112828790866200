import templateUrl from './ecgSourceTracing.html';
import controller from './ecgSourceTracing.controller';

const ecgSourceTracing = {
	restrict: 'E',
	templateUrl,
	controller
};

export default ecgSourceTracing;
