/*
 * pageBodyController
 * */

class PageBodyController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('PageBodyController');
		this.$log.debug('loaded');
	}
}

export default PageBodyController;
