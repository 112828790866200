/*
 * bodyCssClassDirective
 * */

export default class bodyCssClassDirective {

	controller(bodyCssClassService) {
		'ngInject';
		this.bodyCssClassService = bodyCssClassService;
		this.restrict = 'A';
	}

	link(scope, element, attrs, ctrl) {
		ctrl.bodyCssClassService.getBodyCssClassSubject().subscribe((config) => {
			if (config.add) {
				config.customClass && element.addClass(config.customClass);
			} else {
				config.customClass && element.removeClass(config.customClass);
			}
		});
	}
}
