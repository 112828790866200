import templateUrl from './unassignDevice.html';
import controller from './unassignDevice.controller';
import './unassignDevice.scss';

const unassignDevice = {
	restrict: 'E',
	bindings: {
		reasons: '<',
		onSubmit: '&',
		onCancel: '&',
		onReasonChange: '&'
	},
	templateUrl,
	controller
};

export default unassignDevice;
