/*
 * studyMetricsDetailsPopupComponent
 * */

import templateUrl from './studyMetricsDetailsPopup.html';
import controller from './studyMetricsDetailsPopup.controller';
import './studyMetricsDetailsPopup.scss';

const studyMetricsDetailsPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default studyMetricsDetailsPopupComponent;
