(function() {
    'use strict';
    angular
        .module('portalApp')
        .controller('NewOrderCtrl', NewOrderController);

    /**
     * @ngInject
     */
    function NewOrderController(logisticsService, $log, $stateParams,
								$state, WizardHandler, appI18n, $scope, $timeout, $q,
								signatureService, configurationService, appConfig,
								notificationService, loadingIndicatorService) {

        $log = $log.getInstance("NewOrderCtrl", "color:magenta");
        $log.debug("loaded");

        var vm = this;
        vm.signature = {};
        vm.skipSite = false;
        vm.signStatus = false;
        vm.sites = {};
        vm.itemData = {};
        vm.selectedSite = null;
        vm.comments = '';
        vm.hasPendingOrder = false;
        vm.hasOrderItems = false;
        vm.currentStep = '';
        vm.errorMsg = '';
        vm.qtyVisited = false;
        vm.nextStep = nextStep;
        vm.currentStep = "";

		vm.validateQty = validateQty;
        vm.clearFields = clearFields;
        vm.reset = reset;
        vm.showNewOrder = showNewOrder;
        vm.isSiteSelected = isSiteSelected;
        vm.selectSite = selectSite;
        vm.getSupplyOrderData = getSupplyOrderData;
        vm.updateOrderStatus = updateOrderStatus;
        vm.isValidQty = isValidQty;
        vm.isValidComment = isValidComment;
        vm.onBlurQty = onBlurQty;
        vm.isItemSelected = isItemSelected;
        vm.saveOrder = saveOrder;
        vm.onPopupClose = () => {
        	// TODO: handle here the confirmation message if applicable
        	vm.newOrderActive = false;
		}

        activate();

        function activate() {
			vm.sigType = appConfig.sigType.createOrder;
			logisticsService.registerNewOrder(vm);

	        logisticsService.getConfig().then((response) => {
		        vm.config = response.data;
	        });

			vm.actions = [
				{
					isPrimary: true,
					action: nextStep,
					displayName: 'app.buttons.next'
				},
				{
					action: previousStep,
					displayName: 'app.buttons.back'
				},
				{
					action: closeNewOrder,
					displayName: 'app.buttons.cancel'
				}
			];

			$scope.$on("wizard:stepChanged", onStepChanged);

			logisticsService.getPermission()
				.then(function(response) {
					if (!response.data) {
						$state.go(appConfig.routes.supplyOrder);
					}
				}, function() {
					$state.go(appConfig.routes.supplyOrder);
				});

			appI18n.translate([
				'logistics.new-order.title',
				'logistics.new-order.siteid',
				'logistics.new-order.investigator',
				'logistics.new-order.step1-title',
				'logistics.new-order.step2-title',
				'logistics.new-order.step3-title',
				'logistics.new-order.save-success'
			])
				.then(function(translations) {
					vm.saveSuccess = translations['logistics.new-order.save-success'];
					vm.newOrderName = translations['logistics.new-order.title'];
					vm.siteTitle = translations['logistics.new-order.siteid'];
					vm.investigatorTitle = translations['logistics.new-order.investigator'];
					vm.step1Title = translations['logistics.new-order.step1-title'];
					vm.step2Title = translations['logistics.new-order.step2-title'];
					vm.step3Title = translations['logistics.new-order.step3-title'];

					vm.columns = [{
						displayName: vm.siteTitle,
						fieldName: 'sponsorSiteId'
					},
								  {
									  displayName: vm.investigatorTitle,
									  fieldName: 'investigator'
								  }
								 ];
					vm.options = {
						hasFilter: true,
						hasSelectableItems: true
					};

				});

			if ($stateParams.signKey) {
				if ($stateParams.tokenId) {
					vm.isAutoSubmit = true;
				}

				const unbind = $scope.$watchGroup([() => { return !!wizard(); },
												   () => { return !!vm.newOrderActive; }],
					() => {
						const wz = wizard();

						if (wz && vm.newOrderActive) {
							vm.state = signatureService.getState($stateParams.signKey);
							signatureService.removeState($stateParams.signKey);

							if (vm.state) {
								// reset data model
								vm.comments = vm.state.comments;
								vm.sites = vm.state.sites;
								vm.selectedSite = vm.sites.find(function(site) {
									return site.siteId == vm.state.selectedSite.siteId;
								});
								vm.itemData = vm.state.itemData;
								vm.hasPendingOrder = vm.state.hasPendingOrder;
								vm.hasOrderItems = vm.state.hasOrderItems;
								vm.hasInfo = vm.state.hasInfo;

								// move to last step and submit form
								$timeout(() => {
									wz.goTo(wz.totalStepCount() - 1);
									vm.saveOrder();
								}, 0);
							}
							unbind();
						}
					});
			}

		}

		function clearFields() {
			if (vm.currentStep !== 'Finish') {
				vm.orderStatus = false;
				vm.orderCancel = false;
				vm.orderSubmitted = false;
			}
			vm.selectedSite = null;
		};

		function reset() {
            vm.signature = {};
            vm.signStatus = false;
            vm.sites = {};
            vm.itemData = {};
            vm.selectedSite = null;
            vm.comments = "";
            vm.hasPendingOrder = false;
            vm.hasOrderItems = false;
            vm.currentStep = "";
            vm.errorMsg = "";
            vm.qtyVisited = false;
            vm.nextStep = nextStep;
            vm.currentStep = "";
            vm.hasInfo = false;
            vm.hasSiteError = false;
            vm.siteErrorMessage = "";
            vm.skipSite = false;
            vm.moveNext = true;
            vm.actions[1].isDisabled = true;
            vm.actions[0].isDisabled = false;
            vm.actions[2].isDisabled = false;
            vm.actions[0].displayName = 'app.buttons.next';

            vm.clearFields();
        }

        function showNewOrder() {
            vm.reset();
            vm.newOrderActive = true;

            $log.debug("showNewOrder");
            vm.isSiteLoading = true;
            logisticsService.getSitesForPermission()
                .then(function(response) {
                    vm.sites = response.data;
                    if (response.data.length === 0) {
                        vm.hasSiteError = true;
                        vm.actions[0].isDisabled = true;
                        appI18n.translate("logistics.new-order.no-site-error").then(function(translation) {
                            vm.siteErrorMessage = translation;
                        });
                    } else if (response.data.length === 1) {
                        vm.skipSite = true;
                        if (!$stateParams.signKey) {
                            vm.selectSite(response.data[0]);
                        }
                    } else {
                        vm.skipSite = false;
                    }

                    if (response.data.length > 0 && response.data[0].siteCount !== response.data.length) {
                        appI18n.translate("logistics.new-order.less-site-error").then(function(translation) {
                            vm.siteErrorMessage = translation;
                        });
                    }
                    vm.isSiteLoading = false;

                }, function(response) {
                    $log.error("get sites failed with error :" + response.data);
                    vm.hasSiteError = true;
                    appI18n.translate("logistics.new-order.site-loading-error").then(function(translation) {
                        vm.siteErrorMessage = translation;
                    });
                    vm.isSiteLoading = false;
                }).finally(() => {
					if (!vm.isAutoSubmit) {
						loadingIndicatorService.hide();
					}
				});


        }

        function isSiteSelected() {
            return vm.selectedSite !== null;
        }

        function selectSite(site) {
            vm.selectedSite = site;
            vm.qtyVisited = false;
            vm.signature = {};
            vm.getSupplyOrderData();
        }

        function getSupplyOrderData() {
            vm.hasPendingOrder = false;
            vm.hasOrderItems = false;
            vm.itemData = {};
            vm.isLoading = true;
            vm.hasSiteSetupError = false;
            vm.siteSetupErrorMessage = "";
			vm.itemDataPromise = $q.defer();

			// cancel previous requests to avoid strange data load issues
			if (!vm.updateCanceler) {
				vm.updateCanceler = $q.defer();
			} else {
				vm.updateCanceler.resolve();
				vm.updateCanceler = $q.defer();
			}

			var defers = $q.all([
				logisticsService.getSupplyOrderData(vm.selectedSite.siteId, vm.updateCanceler),
				configurationService.getUserStudySettings()
			]);
			defers.then((resolves) => {
                vm.itemData = resolves[0].data;

                if (!vm.itemData.isEquipmentDispatched) {
                    vm.hasSiteSetupError = true;

                    appI18n.translate("logistics.new-order.no-shipment-error")
						.then(function(translation) {
							vm.siteSetupErrorMessage = translation;
						});

				}
                vm.updateOrderStatus();

                if (resolves[1].data.studyConfig.hasMasterscope ||
					resolves[1].data.studyConfig.hasCoaAm ||
					resolves[1].data.studyConfig.hasSpiroSphere) {
                    vm.hasInfo = true;
                } else {
                    vm.hasInfo = false;
                }

				vm.itemDataPromise.resolve();
				updateActions();
                vm.isLoading = false;
			});
        };

        function updateOrderStatus() {
            var isValid = vm.itemData && vm.itemData.items && vm.itemData.items.length > 0;
            if (isValid) {
                angular.forEach(vm.itemData.items, function(item) {
                    if (item.pendingQuantity > 0) {
                        vm.hasPendingOrder = true;
                    } else {
                        vm.hasOrderItems = true;
                    }
                });
            }
        };

        function isValidQty(item) {
            if (item.quantity === '' || item.quantity === null || item.quantity === undefined) {
                return true;
            } else {
            	let quantity = +item.quantity;
                return Number.isInteger(quantity) && quantity > 0 && quantity <= item.maxQuantity;
            }
        };

        function isValidComment() {
            return vm.comments.length <= 1800;
        };

		function validateQty(item) {
			item.qtyValid = isValidQty(item);
		}

        function onBlurQty(item) {
            item.qtyVisited = true;
            vm.qtyVisited = true;
        }

        function isItemSelected() {
			const isValid = vm.itemData && vm.itemData.items && vm.itemData.items.length > 0;
			let isValidQty = false;
			let isInvalidQty = false;

			if (isValid) {
				for (const item of vm.itemData.items) {
					const quantity = +item.quantity;
					const maxQuantity = +item.maxQuantity;

					if (!quantity || !maxQuantity) {
						continue;
					}

					if (!Number.isInteger(quantity)) {
						isInvalidQty = true;
						break;
					}

					if (quantity > 0 && quantity <= maxQuantity) {
						isValidQty = true;
					} else if (quantity > 0 && quantity > maxQuantity) {
						isInvalidQty = true;
						break;
					}
				}
			}

			return isValid && isValidQty && !isInvalidQty;
        }

        function saveOrder() {
            vm.signature = {
                key: $stateParams.signKey,
                token: $stateParams.tokenId
            };

			if (!signatureService.validateSignature(vm.signature.token, vm.signature.key)) {
				return;
			}

            vm.isLoading = true;
            vm.orderCancel = false;
            vm.orderSubmitting = true;
            vm.actions[1].isDisabled = true;
            vm.actions[0].isDisabled = true;
            vm.actions[2].isDisabled = true;

            logisticsService.createOrder(vm.selectedSite.siteId, vm.comments, vm.itemData.items, vm.signature)
                .then(function(response) {
                    if (response.data && response.data.isValidationError) {
                        appI18n.translate(response.data.translationKey).then(function(translation) {
                            vm.errorMsg = translation;
                            vm.orderSubmitting = false;
                            vm.orderStatus = false;
                            vm.actions[1].isDisabled = false;
                            vm.actions[0].isDisabled = false;
                            vm.actions[2].isDisabled = false;
                        });
                    } else {
                        vm.orderSubmitting = false;
                        vm.orderSubmitted = true;
                        vm.orderStatus = true;
                        closeNewOrder();
                        notificationService.showSuccess(vm.saveSuccess);
                    }

                }, function(response) {
                    vm.actions[1].isDisabled = false;
                    vm.actions[0].isDisabled = false;
                    vm.actions[2].isDisabled = false;
                    if (response.status === 400 && response.data.message) {
                        appI18n.translate("logistics.new-order.auth-failure").then(function(translation) {
                            vm.errorMsg = translation;
                            vm.orderSubmitting = false;
                            vm.orderStatus = false;
                        });
                    } else {
                        appI18n.translate("logistics.new-order.save-error").then(function(translation) {
                            vm.errorMsg = translation;
                            vm.orderSubmitting = false;
                            vm.orderSubmitted = true;
                            vm.orderStatus = false;
                        });
                    }

                    $log.error("logisticsService.createOrder failed with error:  `{data}` and status: `{status}`", {
                        data: response.data,
                        status: response.status
                    });
                }).finally(() => {
                    vm.isLoading = false;
					vm.isAutoSubmit = false;
					loadingIndicatorService.hide();
				});
        };

		function closeNewOrder() {
			if (vm.orderSubmitted) {
                logisticsService.refreshOrderGrid();
            }
            vm.newOrderActive = false;
            vm.orderStatus = false;
            vm.orderCancel = true;
            vm.orderSubmitted = false;
        }

        function previousStep() {
            var currentIndex = wizard().currentStepNumber();
            if (currentIndex > 0) {
                wizard().previous();
            }
        }

        function isNextDisabled() {
            var currentIndex = wizard().currentStepNumber();
            var disabled = (vm.skipSite === true && currentIndex > 2) || (vm.skipSite === false && currentIndex > 3);
            $log.debug("isNextDisabled: index: " + currentIndex + " disabled: " + disabled);
            return disabled;
        }

        function isBackDisabled() {
            var currentIndex = wizard().currentStepNumber();
            var disabled = currentIndex < 2;
            $log.debug("isBackdisabled: index: " + currentIndex + " disabled: " + disabled);
            return disabled;
        }

        function onStepChanged() {
            updateActions();
        }

        function wizard() {
            return WizardHandler.wizard('newOrderWzd');
        }

        function updateActions() {
            var currentIndex = wizard().currentStepNumber();
            if (vm.actions.length > 0) {
                vm.actions[1].isDisabled = isBackDisabled();
                vm.actions[0].isDisabled = isNextDisabled();

				if ((vm.skipSite === true && currentIndex === 1) || (vm.skipSite === false && currentIndex === 2)) {
                    //Disable Next button for invalid conditions
                    vm.actions[0].displayName = 'app.buttons.next';
					vm.itemDataPromise.promise.then(() => {
						vm.actions[0].isDisabled = !vm.itemData.items ||
							vm.itemData.items.length == 0 || !vm.hasOrderItems ||
							vm.hasSiteSetupError;
					});
				} else if ((vm.skipSite === true && currentIndex === 2) || (vm.skipSite === false && currentIndex === 3)) {
                    vm.actions[0].displayName = 'app.buttons.sign-submit';
                    vm.state = {};
                    vm.state.sites = vm.sites;
                    vm.state.selectedSite = vm.selectedSite;
                    vm.state.comments = vm.comments;
                    vm.state.itemData = vm.itemData;
                    vm.state.hasPendingOrder = vm.hasPendingOrder;
                    vm.state.hasOrderItems = vm.hasOrderItems;
                    vm.state.hasInfo = vm.hasInfo;

                    vm.additionalInfo = {
                        action: appI18n.translateImmediate('signature.action.createOrder'),
                        infoKey1: appI18n.translateImmediate('signature.info.site'),
                        infoValue1: vm.selectedSite.sponsorSiteId
                    };
                } else {
                    vm.actions[0].displayName = 'app.buttons.next';
                }
            }
        }

        function nextStep() {
            if (!wizard()) {
                return;
            }
            var currentIndex = wizard().currentStepNumber();
            if (vm.skipSite === false) {
                switch (currentIndex) {
                    case 1:
                        if (vm.isSiteSelected()) {
                            wizard().next();
                        } else {
                            vm.moveNext = false;
                        }
                        break;
                    case 2:
                        vm.qtyVisited = true;
                        vm.isSiteSelected() && vm.isItemSelected() && vm.isValidComment() ? wizard().next() : vm.moveNext = false;
                        break;
                    case 3:
                        vm.sigVisited = true;
                        if (vm.isSiteSelected() && vm.isItemSelected()) {
							signatureService.sign(vm.sigType, vm.additionalInfo, vm.state);
                        } else {
                            vm.moveNext = false;
                        }
                        break;
                    default:
                        wizard().next();
                        break;
                }
            } else {
                switch (currentIndex) {
                    case 1:
                        vm.qtyVisited = true;
                        vm.isSiteSelected() && vm.isItemSelected() && vm.isValidComment() ?
                            wizard().next() :
                            vm.moveNext = false;
                        break;
                    case 2:
                        vm.sigVisited = true;
                        if (vm.isSiteSelected() && vm.isItemSelected()) {
							signatureService.sign(vm.sigType, vm.additionalInfo, vm.state);
                        } else {
                            vm.moveNext = false;
                        }
                        break;
                    default:
                        wizard().next();
                        break;
                }

            }
        }


    }

})();
