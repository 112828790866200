/*
 * sortingService
 * */

const cs = require('../../../scripts/utils/custom');
const parser = require('odata-v4-parser');
const toCamelCase = cs.toCamelCase;
const toPascalCase = cs.toPascalCase;
const URL_PARAM_NAME = 'sort';

export default class sortingService {

	static id() {
		return 'sortingService';
	}

	constructor($log, analyticsService, oDataService, $location, appConfig) {
		'ngInject';
		this.$log = $log.getInstance('sortingService', 'color:green');
		this.analyticsService = analyticsService;
		this.oDataService = oDataService;
		this.$location = $location;
		this.appConfig = appConfig;
	}

	setDataToUrl(data) {
		this.$location.search(URL_PARAM_NAME, data);
	}

	getDataFromUri() {
		const value = this.$location.search()[URL_PARAM_NAME];

		if (value) {
			return `$${URL_PARAM_NAME}=${value}`;
		}
		return '';
	}

	initSorting(gridId, headerColumns) {
		const data = this.getUrlSortingData(headerColumns);

		// add default sorting if needed
		if (!data.sorting) {
			const defaultSorting = _.orderBy(_.filter(headerColumns, 'sorted'), ['sortOrder'], ['asc']);
			const query = this.getSortingQueryObj(defaultSorting);

			this.setDataToUrl(query.buildOrderBy());
		} else {
			headerColumns.forEach((item) => {
				const sortColumn = _.find(data.sorting, (sortItem) => {
					return sortItem.fieldName === item.fieldName;
				});

				if (sortColumn) {
					item.sorted = true;
				} else {
					item.sorted = false;
				}
			});
		}

		this.oDataService.combineUriBuilder(gridId, URL_PARAM_NAME, data.query);

		return headerColumns;
	}

	getUriBuilder(sortData) {
		return this.getODataUriBuilder(false, 0, sortData);
	}

	getODataUriBuilder(ignorePaging, from, sortOrders) {
		const uriBuilder = this.oDataService.buildUriFor('');

		if (!sortOrders) {
			return uriBuilder;
		}

		if (!angular.isArray(sortOrders)) {
			sortOrders = [sortOrders];
		}

		if (sortOrders && sortOrders.length) {
			sortOrders.forEach((el) => {
				uriBuilder.orderBy(toPascalCase(el.fieldName), el.reverse);
			});
		}

		return uriBuilder;
	}

	getSortingQueryObj(sortInfo) {

		if (angular.isArray(sortInfo)) {
			sortInfo.forEach((i) => {
				if (!i.fieldName) {
					throw Error ('The sort info object does not match the required format');
				}
			});
		} else if (!sortInfo.fieldName) {
			throw Error ('The sort info object does not match the required format');
		}

		const info = `${sortInfo.fieldName} | reverse order: ${sortInfo.reverse}`;

		this.$log.debug(`${URL_PARAM_NAME}: ${info}`);
		this.analyticsService.trackEvent(this.appConfig.trackCategory.gridSort, info);

		return this.getODataUriBuilder(false, 0, sortInfo);
	}

	getUrlSortingData(headers) {
		const search = this.$location.search();
		let sortOrder;

		try {
			const oData = search[URL_PARAM_NAME];

			if (oData) {
				sortOrder = this.restoreSorting(headers, oData);

				return {
					query: this.getODataUriBuilder(false, 0, sortOrder),
					sorting: sortOrder
				};

			}
			return {
				query: this.getODataUriBuilder(),
				sorting: sortOrder
			};
		} catch (e) {
			this.$log.warn(`Error while getting url sorting data: ${e}`);
			this.$location.search(URL_PARAM_NAME, undefined);
			return {
				query: this.getODataUriBuilder(),
				sorting: sortOrder
			};
		}
	}

	restoreSorting(headers, oData) {
		const parsed = parser.query(`$orderby=${oData}`);
		const sortOrder = [];
		let index = 1;

		parsed.value.options[0].value.items.forEach((item) => {
			sortOrder.push({
				reverse: item.value.direction === -1,
				fieldName: toCamelCase(item.value.expr.raw),
				sortOrder: index++
			});
		});

		headers.forEach((item) => {
			const headerItem = _.find(sortOrder, (sortOrderItem) => {
				return item.fieldName === sortOrderItem.fieldName;
			});

			if (headerItem) {
				item.sorted = true;
				item.reverse = headerItem.reverse;
				item.sortOrder = headerItem.sortOrder;
			}
		});
		return sortOrder;
	}
}
