import templateUrl from './logistics.html';
import controller from './logistics.controller';
import './logistics.scss';

const logisticsComponent = {
	bindings: {
		title: '@'
	},
	templateUrl,
	controller
};

export default logisticsComponent;
