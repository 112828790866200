/*
 * IconsRowController
 * */

class IconsRowController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
		this.$log = this.$log.getInstance(`IconsRowController: ${this.type}`);
		this.$log.debug('loaded');
		this.checkType(this.type);
	}


	checkType(type) {
		switch (type) {
			case 'email':
				this.email = true;
				break;
			case 'phone':
				this.phone = true;
				break;
			case 'mobile':
				this.mobile = true;
				break;
			case 'fax':
				this.fax = true;
				break;
			case 'address':
				this.address = true;
				break;
			case 'country':
				this.country = true;
				break;
			case 'location':
				this.location = true;
				break;
			case 'user':
				this.user = true;
				break;
			case 'visit':
				this.visit = true;
				break;
			default:
				this.unknownType = true;
		}
	}

	getHeadingWidth() {
		return this.headingSize && `${this.headingSize}px` || 'auto';
	}
}

export default IconsRowController;
