/*
 * editRolesPopupModule
 * */

import editRolesPopupComponent from './editRolesPopup.component';

const editRolesPopupModule = angular.module('app.components.studyPermissions.editRolesPopup', [])
	.component('editRolesPopup', editRolesPopupComponent)
	.name;

export default editRolesPopupModule;
