/*
 * dispenseISpiroDevicePopupModule
 * */

import dispenseISpiroDevicePopupComponent from './dispenseISpiroDevicePopup.component';

const dispenseISpiroDevicePopupModule = angular.module('app.components.dispenseISpiroDevicePopup', [])
	.component('dispenseISpiroDevicePopup', dispenseISpiroDevicePopupComponent)
	.name;

export default dispenseISpiroDevicePopupModule;
