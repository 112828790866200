/*
 * sideNavigationComponent
 * */

import templateUrl from './sideNavigation.html';
import controller from './sideNavigation.controller';
import './sideNavigation.scss';

const sideNavigationComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default sideNavigationComponent;
