(function() {

	'use strict';
	angular
		.module('portalApp')
		.controller('TraningDocumentsCtrl', TrainingDocumentsController);

	/**
	 * @ngInject
	 */
	function TrainingDocumentsController($scope, appConfig, reportsService, $log, documentService, $filter, appI18n, appSession,
		configurationService) {

		$log = $log.getInstance("TraningDocumentsCtrl", "color:blue");
		$log.debug("loaded");

		var vm = this;

		vm.trainingDocumentOptions = {
			rowDefs: {
				multiSelection: true,
				identifier: 'documentId'
			},
			gridRowActions: [
				{
					displayName: 'Reports',
					iconCssClass: 'es-icon es-icon-reports',
					enabledIf: (row) => {
						return row.documentId;
					},
					subItemsFactory: (row) => {
						if (row.trainingDocument) {
							return row.trainingDocument;
						}
						const trainingDocument = [];

						trainingDocument.push({
							displayName: row.reportDisplayName,
							iconFileType: 'pdf',
							action: () => {
								const report = [];

								report.push({
									id: row.documentId,
									name: row.reportFileName,
									type: 'pdf'
								});
								documentService.getTrainingDocuments(report)
									.then((response) => {
										documentService.downloadReport(
											response.data, $scope,
											appConfig.downloadDocumentType.trainingDocument,
											1, row.operatorName + " " + row.testDataTypeName,
											'pdf');
									});
							}
						});
						row.trainingDocument = trainingDocument;

						return trainingDocument;
					}
				}
			],
			sortOrders: [{
				fieldName: 'dateCreated'
			}],

			getData: getTrainingDocuments
		};

		function getTrainingDocuments(options) {
			return reportsService.getTrainingDocuments(options);
		}

		function closeOpenError() {
			vm.openError = false;
			vm.modalErrorMessage = "";
		}

		function getSelectedDocuments() {
			return $filter('filter')(vm.trainingDocumentOptions.rowData, { selected: true }, false);
		}

		function download() {
			var selectedReports = [];
			//Get selected documents from portal grid
			var selectedDocuments = getSelectedDocuments();

			angular.forEach(selectedDocuments, function (value) {
				selectedReports.push({
					id: value.documentId,
					name: value.reportFileName,
					type: 'pdf'
				});
			});
			documentService.getTrainingDocuments(selectedReports)
				.then(function (response) {
					documentService.downloadReport(response.data, $scope, appConfig.downloadDocumentType.trainingDocument, selectedReports.length);
				}, function (response) {
					$log.error("Eror in retrieving training documents: " + response.data);
				});

		}

		function activate() {
			configurationService.getStudySettings()
			.then(function(data) {
				if (!data.usesTrainingMode) {
					return;
				}

				appI18n.translate( [
					"reports.training-document.title",
					"reports.training-document.close",
					"reports.training-document.site",
					"reports.training-document.operator",
					"reports.training-document.email",
					"reports.training-document.assessment",
					"reports.training-document.date",
					"reports.training-document.download",
					"reports.training-document.main-title"
				])
				.then(function (translations) {
					vm.title = translations["reports.training-document.title"];
					vm.close = translations["reports.training-document.close"];
					vm.site = translations["reports.training-document.site"];
					vm.operator = translations["reports.training-document.operator"];
					vm.email = translations["reports.training-document.email"];
					vm.assessment = translations["reports.training-document.assessment"];
					vm.date = translations["reports.training-document.date"];
					vm.download = translations["reports.training-document.download"];

					reportsService.setTitle(translations["reports.training-document.main-title"]);

					vm.trainingDocumentOptions.columnDefs =  [
						{displayName: vm.site, fieldName: 'siteInvestigator', isSortable: true, type: 'string', formatter: 'pipe'},
						{displayName: vm.operator, fieldName: 'operatorName', isSortable: true, type: 'string', isDefaultFilter: true},
						{displayName: vm.email, fieldName: 'operatorLoginName', isSortable: true, type: 'string'},
						{displayName: vm.assessment, fieldName: 'testDataTypeName', isSortable: true, type: 'string'},
						{displayName: vm.date, fieldName: 'dateCreated', isSortable: true, type: 'date', formatter: 'dateTimeFormat'}
					];

					vm.trainingDocumentOptions.gridActions = [{
						displayName: vm.download,
						action: function () {
							download();
						},
						isDisabled: () => {
							// wait the data is loaded
							if (vm.trainingDocumentOptions.rowData && vm.trainingDocumentOptions.rowData.length) {
								return getSelectedDocuments().length === 0;
							}
							return true;
						}
					}];
					vm.load = true;

				});
			});
		
		}

		activate();
	}


})();
