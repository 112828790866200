/*
 * visitCalendarBreadcrumbsComponent
 * */

import templateUrl from './visitCalendarBreadcrumbs.html';
import controller from './visitCalendarBreadcrumbs.controller';
import './visitCalendarBreadcrumbs.scss';

const visitCalendarBreadcrumbsComponent = {
	bindings: {
		countries: '<',
		sites: '<',
		subjects: '<',
		selectedCountryName: '<',
		selectedSiteName: '<',
		selectedSubjectName: '<',
		onCountryChanged: '&',
		onSiteChanged: '&',
		onSubjectChanged: '&'
	},
	templateUrl,
	controller
};

export default visitCalendarBreadcrumbsComponent;
