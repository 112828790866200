import templateUrl from './holterReport.html';
import controller from './holterReport.controller';

const holterReport = {
	restrict: 'E',
	templateUrl,
	controller
};

export default holterReport;
