import templateUrl from './scheduleISpiroEventPopup.html';
import controller from './scheduleISpiroEventPopup.controller';
import './scheduleISpiroEventPopup.scss';

const scheduleISpiroEventPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default scheduleISpiroEventPopupComponent;
