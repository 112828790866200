/*
 * tabsController
 * */

class TabsController {
	constructor($log, $state) {
		'ngInject';
		this.$log = $log;
		this.$state = $state;
		this.tabs = [];
	}

	$onInit() {
		this.$log = this.$log.getInstance('TabsController');
		this.$log.debug('loaded');
	}

	selectTab(tab) {
		this.tabs.forEach((t) => {
			t.active = false;
		});
		tab.active = true;
	}

	addTab(tab) {
		if (!this.uiSrefActiveMode && this.tabs.length === 0) {
			tab.active = true;
		} else if (this.uiSrefActiveMode) {
			tab.active = this.$state.is(tab.route);
		}

		this.tabs.push(tab);
	}
}

export default TabsController;
