(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('SsoCtrl', SsoCtrl);

	/*
	 * @ngInject
	 */
	function SsoCtrl($log, $rootScope, $stateParams, $state, $window, ssoService, appI18n) {

		var vm = this;
		$log = $log.getInstance("SsoController");
		$log.debug(" loaded");

		vm.result = undefined;
		vm.onEpxLoginSuccess = onEpxLoginSuccess;
		vm.onEpxLoginFailure = onEpxLoginFailure;
		vm.onTableauLoginSuccess = onTableauLoginSuccess;
		vm.onTableauLoginFailure = onTableauLoginFailure;
		vm.isLoading = true;
		vm.showAccountMessage = false;

		activate();
		/////////////

		function activate() {
			ssoService.setEpxHandler(vm);
			ssoService.setTableauHandler(vm);
		}

		function setError(msg) {
			vm.isLoading = false;
			vm.hasErrors = true;
			appI18n.translate(msg).then(
				function (translation) {
					vm.errorMessage = translation;
				});
		}

		function onTableauLoginSuccess(data) {
			vm.isLoading = false;
			vm.result = data;
			if (data.success) {
				$window.location.href = data.tableauUrl;
			}
			else {
				setError("sso.tableau.failure");
			}
		}

		function onTableauLoginFailure(data) {
			vm.result = data;
			setError("sso.tableau.failure");
		}

		function onEpxLoginSuccess(data) {
			vm.isLoading = false;
			vm.result = data;
			if (data.success) {
				$window.location.href = data.epxUrl;
			}
			else {
				vm.showAccountMessage = true;
				vm.accountUrl = data.epxUrl;
			}
		}

		function onEpxLoginFailure(data) {
			vm.result = data;
			setError("sso.epx.failure");
		}
	}
})();
