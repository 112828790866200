/*
 * sortingController
 * */

class SortingController {
	constructor($log, sortingService) {
		'ngInject';
		this.$log = $log;
		this.sortingService = sortingService;
	}

	$onInit() {
		//this.$log = this.$log.getInstance('SortingController');
		//this.$log.debug('loaded');
	}

	getUriBuilder(sortData) {
		return this.sortingService.getUriBuilder(sortData);
	}

	sortAsc(sortString) {
		if (this.active && !this.reverse) {
			return;
		}

		const sortData = { fieldName: sortString, reverse: false },
			uriBuilder = this.getUriBuilder(sortData);

		this.onSort({ uriBuilder, reverse: false });
	}


	sortDesc(sortString) {
		if (this.active && this.reverse) {
			return;
		}

		const sortData = { fieldName: sortString, reverse: true },
			uriBuilder = this.getUriBuilder(sortData);

		this.onSort({ uriBuilder, reverse: true });
	}
}

export default SortingController;
