(function() {

	'use strict';

	angular
		.module("portalApp")
		.controller("SqfListTabCtrl", SqfListTabController);

	/**
	 * @ngInject
	 */
	function SqfListTabController($log, configurationService, sqfTranslation, appConfig) {

		$log = $log.getInstance("SqfListTabCtrl", "color:purple");
		$log.debug("SqfListTabCtrl:: loaded");

		var vm = this;
		vm.tabs = [];
		vm.localized = {gridColumns: {}, list: {}};
		activate();

		function activate() {
			sqfTranslation.getListViewLocalized().then(function (localized) {
				vm.localized = localized;
				vm.tabs = [
					{displayName: localized.list.savedSqf, ref: appConfig.routes.sqfListSaved},
					{displayName: localized.list.submittedSqf, ref: appConfig.routes.sqfListSubmitted}
				];
				vm.actions = [];
			});
			loadSiteMapName();

		}
		
    function loadSiteMapName() {
      configurationService.getSiteMapLabel(appConfig.siteMapModuleIds.sqf).then(
        function (label) {
          vm.moduleLabel = label;
        }
      );
    }
	}
})();
