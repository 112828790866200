import templateUrl from './ertGridCell.html';
import './ertGridCell.scss';

const ertGridCellDirective = () => {
	return {
		scope: {
			highlight: '<?',
			rowData: '<',
			formatter: '@?',
			fieldName: '@',
			cssClass: '@?',
			type: '@?'
		},
		restrict: 'A',
		replace: true,
		templateUrl
	};
};

export default ertGridCellDirective;
