/*
 * IconTextRowController
 * */

class IconTextRowController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
		this.$log = this.$log.getInstance('IconTextRowController');
		this.$log.debug('loaded');
	}
}

export default IconTextRowController;
