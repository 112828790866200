(function () {
	'use strict';

	angular.module('portalApp').directive('ertResize', ErtResize);


	/**
	 * @ngInject
	 */
	function ErtResize($window, $rootScope) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				angular.element($window).on('resize', function (e) {
					$rootScope.$broadcast('ert-resize');
				});
			}
		}
	}
})();