import templateUrl from './maskedInput.html';
import controller from './maskedInput.controller';
import './maskedInput.scss';

const maskedInput = {
	bindings: {
		name: '@',
		value: '=',
		setValue: '<?',
		options: '<',
		onChange: '&',
		componentReady: '&'
	},
	transclude: true,
	require: {
		fg: '^form'
	},
	templateUrl,
	controller
};

export default maskedInput;
