/*
 * PopupService
 * */

export default class ertBasePopupService {

	static id() {
		return 'ertBasePopupService';
	}

	constructor($log) {
		'ngInject';
		this.$log = $log.getInstance('ecgViewerService', 'color:green');
		this.defaultPopupName = 'default';
		this.popups = [];
	}

	register(popupName = this.defaultPopupName, $API) {
		this.popups[popupName] = $API;
	}

	unregister(popupName) {
		const popup = this.popups[popupName];

		if (popup) {
			this.popups[popupName] = undefined;
		}
	}

	popup(popupName = this.defaultPopupName) {
		return this.popups[popupName];
	}
}
