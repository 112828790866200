/*
 * ertBasePopupComponent
 *
 * @inputs {String} heading (string or translations string path ex. study-permissions.buttons.editUser)
 * @inputs {String} helpModuleId (string that represents help module id, ex. MSP3.StudyPermissions.SiteUsers)
 * @inputs {Array<Object>} footerActions
 * ex. [{ action: () => {}, cssClass: '-es-primary', displayName: 'app.buttons.sign-submit', isDisabled: true,
 * type: 'submit' (by default is button)}]
 * @inputs {Array<Object>} headerActions
 * ex. [{ iconClass: 'es-icon-help', action: () => { }}]
 * @inputs {String} size, ex. small, medium, large (default)
 * @inputs {Boolean} showPrint, defines whether the print icon should be shown in header
 * @outputs {Function} onShow: defines asynchronous action that will be executed before the popup is opened
 * (should return a promise)
 * @outputs {Function} onCancelEscape defines action both for cancel icon in header and escape key press
 * @outputs {Function} onEscape defines action for escape key press
 * @outputs {Function} onCancel defines action for cancel icon in header
 * */
import templateUrl from './ertBasePopup.html';
import controller from './ertBasePopup.controller';
import './ertBasePopup.scss';

const ertBasePopupComponent = {
	bindings: {
		open: '<?',
		onShow: '&?',
		heading: '@',
		helpModuleId: '@?',
		footerActions: '<',
		headerActions: '<?',
		size: '@?',
		showPrint: '<?',
		onCancelEscape: '&?',
		onEscape: '&?',
		onCancel: '&?'
	},
	templateUrl,
	controller,
	transclude: true
};

export default ertBasePopupComponent;
