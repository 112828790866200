(function () {
	'use strict';

	angular.module('portalApp').controller('effortsCtrl', EffortsController);

	/* @ngInject */
	function EffortsController($scope, $log, appConfig, appI18n,
							   clinicalDataEntryService, clinicalDataEntryApiService,
							   configurationService) {

		var vm = this;
		$log = $log.getInstance('EffortsController', 'color:blue');
		$log.debug(' loaded');

		const rrGlobalParameterId = 1;
		const TestDataTypeDlco = '23';
		var trans = appI18n.translateImmediate(['cde-pft.titles.noValue' ]);
		vm.columnForm = []; // container of referencies to all forms
		vm.parameterFields = [];
		vm.data = {};
		vm.data.efforts = [];
		vm.savedTestDataTypeId = null;

		vm.show = show;
		vm.getData = getData;
		vm.setData = setData;
		vm.getSpirometryFields = getSpirometryFields;
		vm.isValid = isValid;
		vm.reset = reset;
		vm.isReadOnlyMode = isReadOnlyMode;
		vm.compareData = compareData;
		vm.globalErrors = {
			atLeastOneEffort: false,
			atLeastOneEffortMessage: appI18n.translateImmediate('cde-pft.measurementsDetails.efforts.atLeastOneEffortMessage')
		};

		vm.selectUnitPlaceHolder = appI18n.translateImmediate('cde-pft.measurementsDetails.efforts.selectUnitPlaceHolder');

		vm.tooltipButtonsForTime = [
			{
				action: function(formDataModel, formValueGetterSetter) {
					formDataModel.notEqual = false;
					formValueGetterSetter(formDataModel.time || null);

				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(1, formDataModel.time);
				}
			},
			{
				action: function (formDataModel, formValueGetterSetter) {
					formDataModel.notEqual = false;
					formValueGetterSetter(formDataModel.second_time || null);
				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(2, formDataModel.second_time);
				}
			}
		];

		vm.tooltipButtonsForParameters = [
			{
				action: function(formDataModel, formValueGetterSetter) {
					formValueGetterSetter(formDataModel.value || 'n/a');

				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(1, formDataModel.value);
				}
			},
			{
				action: function (formDataModel, formValueGetterSetter) {
					formValueGetterSetter(formDataModel.second_value || 'n/a');
				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(2, formDataModel.second_value);
				}
			}
		];

		vm.tooltipButtonsForSelectedUnit = [
			{
				action: function(formDataModel, formValueGetterSetter) {
					formValueGetterSetter(formDataModel.selectedUnit || null);

				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(1, formDataModel.selectedUnit && formDataModel.selectedUnit.unitValue);
				}
			},
			{
				action: function (formDataModel, formValueGetterSetter) {
					formValueGetterSetter(formDataModel.second_selectedUnit || null);
				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(2, formDataModel.second_selectedUnit && formDataModel.second_selectedUnit.unitValue);
				}
			}
		];



		function createBtnLabel(entryNum, value) {
			if(entryNum === 1) {
				return (value || trans['cde-pft.titles.noValue']);
			} else if (entryNum === 2){
				return (value || trans['cde-pft.titles.noValue']);
			}
		}

		active();

		function active() {
			clinicalDataEntryService.registerEffortsHandler(vm);
		}

		function show(studyId, studyModeId, testDataTypeId, mode, efforts, cdeMode) {

			// if the user changes the event selection, refresh the parameter list
			// check for undefined to support signature cancel
			if (vm.data.testDataTypeId !== undefined && vm.data.testDataTypeId != testDataTypeId) {
				vm.data.efforts = [];
			}

			vm.isPaperEcg = cdeMode == appConfig.cdeMode.Ecg;
			vm.data.selectedStudyId = studyId;
			vm.data.selectedStudyModeId = studyModeId;
			vm.data.testDataTypeId = testDataTypeId;
			vm.mode = mode;
			if (!isAddMode() && efforts && efforts.length > 0) {
				vm.data.efforts = efforts;
			}

			clinicalDataEntryApiService.getStudyConfig(
				vm.data.selectedStudyId,
				vm.data.testDataTypeId
			).then(function onSuccess(result) {
				vm.hideParameterEntry = result.data.hideParameterEntry;
				vm.hidden = vm.hideParameterEntry || !result.data.hasConfiguredParameters
					|| (!isAddMode() && (!vm.data.efforts || vm.data.efforts.length === 0));
				if (isAddMode() && result.data.hideParameterEntry) {
					return;
				} else {
					initialize(mode);
				}
			});
		}

		function isSecondDataEntryMode() {
			return vm.mode === appConfig.dataEntryDisplayModes.secondEntry ||
				vm.mode === appConfig.dataEntryDisplayModes.secondEntryByErtForSiteSubmission;

		}

		// gets the data for new PFT, or edited pft, second data entry
		function getData() {
			var isSecondDataEntry = isSecondDataEntryMode();
			var result = _.map(vm.data.efforts, function (trial, key) {
				var params = _.map(trial.parameters, function (param, key) {
					let value = isSecondDataEntry ? param.new_value: param.value;
					let [unitId, unitName, selectedUnit] = getSelectedUnit(vm.data.efforts, param, key, isSecondDataEntry);

					return {
						name: param.abbreviation || param.name,
						value: value || null,
						new_value: param.new_value,
						unit: unitName,
						displayName: param.displayName,
						unitName: unitName,
						aitId: param.aitId,
						picDecimal: param.picDecimal,
						unitId: unitId,
						abbreviation: param.abbreviation,
						isHidden: param.isHidden,
						isUnitSelectable: param.isUnitSelectable,
						parameterUnits: param.parameterUnits,
						selectedUnit: selectedUnit,
					};
				});

				let hasValidParams = params && params.length > 0 && _.some(params, param => !!param.value);

				let time = isSecondDataEntry ? trial.new_time: trial.time;
				return {
					time: time,
					new_time: trial.new_time,
					enabled: time || hasValidParams ? true : false,
					parameters: params
				};
			});
			return result;
		}

		function getSelectedUnit(efforts, param, key, isSecondDataEntry) {
			let unitId = param.unitId;
			let unitName = param.unitName || param.unit;
			let selectedUnit = null;

			if (efforts && efforts.length >0 && efforts[0].parameters[key]) {
				selectedUnit = isSecondDataEntry ? efforts[0].parameters[key].new_selectedUnit
					                             : efforts[0].parameters[key].selectedUnit;
				if (selectedUnit) {
					unitId = selectedUnit.unitId;
					unitName = selectedUnit.unitValue;
				}
			}
			return [unitId, unitName, selectedUnit];
		}

		function getSpirometryFields() {
			return vm.parameterFields;
		}

		function setData(dataModel, cdeMode) {
			// set the state after signature redirect
			vm.isPaperEcg = cdeMode === appConfig.cdeMode.Ecg;
			vm.mode = dataModel.displayMode;
			vm.data.efforts = dataModel.efforts;
			vm.parameterFields = createParameterFields(vm.data.efforts, dataModel.visitEvent.testDataTypeId);
		}

		function isValid() {
			vm.globalErrors.atLeastOneEffort = !hasAtLeastOneEffortFilledOut(vm.parameterFields);
			return hasNoVlidationErrors(vm.columnForm) && !vm.globalErrors.atLeastOneEffort;
		}

		function hasNoVlidationErrors(form) {
			var valid = true;
			for (var x = 0; x < form.length; x++) {
				if (form[x].$invalid) {
					valid = false;
				}
				form[x].showValidation = true;
			}
			return valid;
		}

		function hasAtLeastOneEffortFilledOut(fieldsTable) {
			var effortOk = true;
			for (var x = 0; x < fieldsTable.length; x++) {
				var column = fieldsTable[x].fieldGroup;
				effortOk = true;
				// check all the fields except first (time is optional)
				var startIndex = vm.isPaperEcg ? 0 : 1;

				for(var i = startIndex; i < column.length; i++) {
					if(!column[i].model.isHidden && !column[i].value()) {
						effortOk = false;
						break;
					}
				}
				if(effortOk) { break; }
			}

			return effortOk;
		}

		function compareData() {
			if (vm.hidden) {
				return true;
			}

			var data = vm.data.efforts;

			if (vm.hideParameterEntry) {
				var hasAnyFirstEntryEfforts = false;

				for (var j = 0; j < data.length; j++) {
					if (data[j].time) {
						hasAnyFirstEntryEfforts = true;
						break;
					}
				}

				if (!hasAnyFirstEntryEfforts) {
					// no double entry errors when first entry was without parameters
					// double entry with hideParameter option will only be supported after EPF-9568
					return true;
				}
			}

			var equal = true;
			for (var x = 0; x < data.length; x++) {
				if ((data[x].time || data[x].new_time) && data[x].time != data[x].new_time) {
					data[x].second_time = data[x].new_time;
					data[x].new_time = null;
					data[x].notEqual = true;
					equal = false;
					data[x].locked = false;
				} else {
					data[x].locked = true;
				}

				var params = data[x].parameters;
				for (var i = 0; i < params.length; i++) {
					if ((params[i].value || params[i].new_value) && params[i].value != params[i].new_value) {
						params[i].second_value = params[i].new_value;
						params[i].new_value = null;
						params[i].notEqual = true;
						equal = false;
						params[i].locked = false;
					} else {
						params[i].locked = true;
					}
				}
			}

			//compare selected unit
			if (data && data.length > 0 && data[0].parameters) {
				var params = data[0].parameters;
				params.forEach( p => {
					let isEqual = (!p.selectedUnit && !p.new_selectedUnit) ||
						(p.selectedUnit && p.new_selectedUnit && p.selectedUnit.unitId === p.new_selectedUnit.unitId);

					if (isEqual) {
						p.lockedUnit = true;
					} else {
						p.second_selectedUnit = p.new_selectedUnit;
						p.new_selectedUnit = null;
						p.notEqualUnit = true;
						p.lockedUnit = false;
						equal = false;
					}

				});
			}

			return equal;
		}

		var isParameterLocked = function (param) {
			return param.locked;
		};

		function getLabel() {
			return appI18n.translateImmediate('cde-pft.measurementsDetails.efforts.label');
		}

		function getTrialLabel() {
			return appI18n.translateImmediate('cde-pft.measurementsDetails.efforts.trialLabel');
		}

		function reset() {
			vm.parameterFields = [];
			vm.data.efforts = [];
		}

		function generateParameterDataModel(trials, params) {
			var model = [];
			for (var x = 0; x < trials; x++) {
				model[x] = {
					time: null,
					parameters: JSON.parse(JSON.stringify(params))
				};
			}
			return model;
		}

		function createParameterFields(model, testDataTypeId) {

			var table = [];
			if (!model) {
				logError('createParameterFields:: model is missing');
				return;
			}

			vm.data.showUnit = getShowUnit(model, testDataTypeId);

			for (var x = 0; x < model.length; x++) {
				table[x] = {
					fieldGroup: getTrialInputs(model[x], x)
				};
			}
			return table;
		}

		function getShowUnit(model, testDataTypeId) {
			let result = false;
			if(testDataTypeId === appConfig.testDataType.Dlco.toString() && model && model.length >0)
			{
				result = _.some(model[0].parameters, {isUnitSelectable: true, isHidden: false});
			}
			return result;
		}

		function haveSomeValue(scope) {
			var fields = scope.fields || [];
			for (var x = 0; x < fields.length; x++) {
				// the value property returns only a value if all validations for the field are successful. But this is a bit
				// confusing if a user enters the first parameter clicks on the submit button and invalidates the first parameter afterwards.
				// Then all other validations messages are removed because the first parameter field is handled as empty.
				if (!fields[x].hide && (fields[x].value() || (fields[x].formControl && !!fields[x].formControl.$viewValue))) {
					return true;
				}
			}
			return false;
		}

		function haveDiscrepancies(scope) {
			var fields = scope.fields || [];
			for (var x = 0; x < fields.length; x++) {
				if (fields[x].model.notEqual) {
					return true;
				}
			}
			return false;
		}

		function allFieldsOrNoneValidator(viewValue, modelValue, scope, smth, idx) {
			if (haveSomeValue(scope) && !haveDiscrepancies(scope)) {
				vm.parameterOptions.formState.efforts[idx].required = true;
			} else {
				vm.parameterOptions.formState.efforts[idx].required = false;
			}
			return true;
		}

		function atLeastOneEffortValidator(viewValue, modelValue, scope, smth, idx) {
			var fields = scope.fields || [];
			var hasAllValues = true;
			var startIndex = vm.isPaperEcg ? 0 : 1;

			for (var x = startIndex; x < fields.length; x++) {
				if (!fields[x].value()) {
					hasAllValues = false;
					break;
				}
			}
			if(hasAllValues) {
				vm.globalErrors.atLeastOneEffort = false;
			}
			//always return true to not invalidate the form
			return true;
		}

		function hoursMinutesOptionalSecondsOnlyValidator(viewValue, modelValue, scope) {
			var value = modelValue || viewValue;
			var result = !value ||
				value === '__:__:__' ||
				/^([01][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9]|:__)?$/.test(value);
			$log.debug('value: ' + value + " result: " + result);
			return result;
		}

		function getMaxNumberOfDigitsForEcgParam(param) {
			return param.aitId == rrGlobalParameterId ? 4 : 3;
		}

		function validateEcgParameters(viewValue, modelValue, scope) {
			const maxNumberOfDigits = getMaxNumberOfDigitsForEcgParam(scope.model);
			const regexp = new RegExp(`^n\/a$|^na$|^\\d{1,${maxNumberOfDigits}}?$`, 'gi');
			var value = modelValue || viewValue;

			return !value || regexp.test(value);
		}

		function allowDecimalsAndNAValidator(viewValue, modelValue, scope) {

			var value = modelValue || viewValue;
			return !value || /^n\/a$|^na$|^\d*(\.\d+)?$/.test(value) && checkDecimalRule(value);
		}

		function checkDecimalRule(num){
			var total = totalDecimals(num);
			if (total > 3) {
				return false;
			}
			return true;
		}
		function totalDecimals(num) {
			var match = (''+num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
			if (!match) { return 0; }
			return Math.max(
				0,
				// Number of digits right of decimal point.
				(match[1] ? match[1].length : 0)
				// Adjust for scientific notation.
					- (match[2] ? +match[2] : 0));
		}

		function getTrialInputs(trial, idx) {
			vm.parameterOptions.formState.efforts[idx] = {required: false};
			var timeLocker = _.partial(isParameterLocked, trial);

			var spiro = [];

			// the hideExpression for the time was not working as expected and it caused some issues in the test automation.
			// therefore the time field config is no longer added for Paper ECG studies.
			if (!vm.isPaperEcg) {
				spiro.push({
					type: 'esInput',
					key: (function () {
						if (isSecondDataEntryMode()) {
							return 'new_time';
						}
						return 'time';
					}()),
					model: trial,
					modelOptions: {
						updateOn: "blur"
					},
					templateOptions: {
						type: 'text',
						label: "Time (24 Hr)",
						effortLabel: 'Effort ' + (idx + 1),
						placeholder: 'HH:mm[:ss]',
						id: 'trial_' + idx + '_time',
						mask: '9?9?:9?9?:?9?9?',
						modelViewValue: true,
						allowInvalidValue: true,
						validateIfPristine: true,
						hideLabel: (function () {
							if (idx !== 0) {
								return true;//hide all labels exept first
							}
							return false;
						}()),
						onChange: function (value) {
							var key;
							if (isSecondDataEntryMode()) {
								key = 'new_time';
							} else {
								key = 'time';
							}
							var enteredValue = trial[key];
							var validValue = hoursMinutesOptionalSecondsOnlyValidator(enteredValue);

							if (!validValue) {
								return;
							}
							if (trial[key] && trial[key].substring(trial[key].length - 2) === '__') {
								trial[key] = trial[key].slice(0,6) + '00';
							}
						},
						buttons: vm.tooltipButtonsForTime,
						enableTooltip: false,
						labelClass: 'trial-label'
					},
					validators: {
						hoursMinutesSecondsOnly: {
							expression: hoursMinutesOptionalSecondsOnlyValidator
						},
						allFieldsOrNone: {
							expression: _.partialRight(allFieldsOrNoneValidator, idx)
						},
						fieldComparison: {
							expression: function (viewValue, modelValue, scope) {
								var value = viewValue || modelValue;
								if (scope.model.notEqual && !value) {
									//enable tooltip
									scope.to.enableTooltip = true;
									return false;
								} else {
									return true;
								}
							}
						}
					},
					extras: {
						validateOnModelChange: true
					},
					expressionProperties: {
						"templateOptions.disabled": function () {
							return vm.isReadOnlyMode() || timeLocker();
						}
					}
				});
			}

			var params = trial.parameters;

			for (var y = 0; y < params.length; y++) {
				var param = params[y];
				if (idx === 0 && param.isUnitSelectable && !param.isHidden) {
					let selectInput = {
						type: param.isHidden ? 'hidden' : 'esSelect',
						key: (function () {
							if (isSecondDataEntryMode()) {
								return 'new_selectedUnit';
							}
							return 'selectedUnit';
						}()),
						model: param,
						templateOptions: {
							label: (function () {
								return (param.displayName || param.name);
							}()),
							placeholder: vm.selectUnitPlaceHolder,
							id: 'unit_' + idx + '_aitId_' + param.aitId,
							disabled: false,
							required: true,
							options: param.parameterUnits,
							valueProp: 'unitId',
							labelProp: 'unitValue',
							buttons: vm.tooltipButtonsForSelectedUnit,
							enableTooltip: false
						},
						controller: /* @ngInject */ function ($scope) {
							let unitId = $scope.model.unitId;
							let selected = _.find($scope.model.parameterUnits, {unitId});
							if (isSecondDataEntryMode()) {
								$scope.model.new_selectedUnit = selected;
							} else {
								$scope.model.selectedUnit = selected;
							}
						},
						expressionProperties: {
							"templateOptions.disabled": function (viewValue, modelValue, scope) {
								return vm.isReadOnlyMode() || scope.model.lockedUnit || scope.model.isHidden;
							}
						},
						validators: {
							fieldComparison: {
								expression: function (viewValue, modelValue, scope) {
									var value = viewValue || modelValue;
									if (scope.model.notEqualUnit && !viewValue) {
										//enable tooltip
										scope.to.enableTooltip = true;
										return false;
									} else {
										return true;
									}
								}
							}
						}
					};
					spiro.push(selectInput);
				}

				let trialInput = {
					type: 'esInput',
					key: (function () {
						if (isSecondDataEntryMode()) {
							return 'new_value';
						}
						return 'value';
					}()),
					model: param,
					templateOptions: {
						type: param.isHidden ? 'hidden' : 'text',
						label: (function () {
							if (param.isUnitSelectable) {
								return (param.displayName || param.name);
							}
							return (param.displayName || param.name) + ' ' + (param.unitName || param.unit);
						}()),
						effortLabel: 'Effort ' + (idx + 1),
						placeholder: '',
						disabled: false,
						id: 'trial_' + idx + '_aitId_' + param.aitId,
						validateIfPristine: true,
						maxlength: 15,
						hideLabel: (function () {
							if (!param.isUnitSelectable && !param.isHidden && idx === 0) {
								return false;
							}
							return true; //hide all labels exept first
						}()),
						buttons: vm.tooltipButtonsForParameters,
						enableTooltip: false,
						wrapperClass: param.isHidden ? 'no-margin' : '',
						labelClass: (idx === 0 && param.isUnitSelectable && !param.isHidden || param.isHidden)? '' : 'trial-label'
					},
					validation: {
						messages: {
							required: function(viewValue, modelValue, scope) {
								return appI18n.translateImmediate("app.messages.requiredFieldNA");
							}

						}
					},
					validators: {
						atLeastOneEffort: {
							expression: _.partialRight(atLeastOneEffortValidator, idx)
						},
						validParam: {
							expression: function($viewValue, $modelValue, scope) {
								return (vm.isPaperEcg)
									? validateEcgParameters($viewValue, $modelValue, scope)
									: allowDecimalsAndNAValidator($viewValue, $modelValue, scope);
							},
							message: ($viewValue, $modelValue, scope) => {
								return (vm.isPaperEcg)
									? supplant(appI18n.translateImmediate('app.messages.allowDigitsAndNA'),
											   [getMaxNumberOfDigitsForEcgParam(scope.model)])
									: appI18n.translateImmediate("app.messages.allowDecimalsAndNA");
							}
						},
						fieldComparison: {
							expression: function (viewValue, modelValue, scope) {
								var value = viewValue || modelValue;
								if (scope.model.notEqual && !viewValue) {
									//enable tooltip
									scope.to.enableTooltip = true;
									return false;
								} else {
									return true;
								}

							}
						},
						allFieldsOrNone: {
							expression: _.partialRight(allFieldsOrNoneValidator, idx)
						},
					},
					expressionProperties: {
						"templateOptions.disabled": function (viewValue, modelValue, scope) {
							return vm.isReadOnlyMode() || scope.model.locked || scope.model.isHidden;
						},
						"templateOptions.required": (view, value, scope) => {
							return !vm.isReadOnlyMode() &&
								!scope.model.isHidden && vm.parameterOptions.formState.efforts[idx].required;
						}
					},
					extras: {
						validateOnModelChange: true
					}
				};
				spiro.push(trialInput);

			}

			return spiro;
		}

		function initialize(mode) {
			vm.label = getLabel();
			vm.trialLabel = getTrialLabel();

			// used to differentiate between new entry (or additional new entry) or cancelled signature
			if (mode === appConfig.dataEntryDisplayModes.add && vm.data.efforts.length === 0) {
				vm.isLoading = true;
				// we need to reset the fields otherwise formly won't add the formControl object to the fields
				// and this is required to get the validation messages after signature cancelling
				vm.columnForm = [];
				vm.data.efforts = null;
				vm.parameterFields = null;

				if (vm.isPaperEcg) {

					clinicalDataEntryApiService.getEcgParameters(
						vm.data.selectedStudyId, vm.data.testDataTypeId).then(function onSuccess(result) {
							reset();
							let maxTrials = 1;
							vm.data.efforts = generateParameterDataModel(maxTrials, result.data);
							vm.parameterFields = createParameterFields(vm.data.efforts, vm.data.testDataTypeId);

						}, function onError(errorData, status) {
							logError(errorData, status);
						}).finally(() => {
							vm.isLoading = false;
						});
				} else {
					clinicalDataEntryApiService.getSpirometryParameters(
						vm.data.selectedStudyId,
						vm.data.selectedStudyModeId, vm.data.testDataTypeId).then(function onSuccess(result) {
							reset();
							let maxTrials = vm.data.testDataTypeId === appConfig.testDataType.Dlco.toString() || vm.data.testDataTypeId === appConfig.testDataType.bodyPlethysmography.toString() ? 5: 8;
							vm.data.efforts = generateParameterDataModel(maxTrials, result.data);
							vm.parameterFields = createParameterFields(vm.data.efforts, vm.data.testDataTypeId);

						}, function onError(errorData, status) {
							logError(errorData, status);
						}).finally(() => {
							vm.isLoading = false;
						});
				}

			} else {
				vm.parameterFields = [];
				vm.parameterFields = createParameterFields(vm.data.efforts, vm.data.testDataTypeId);
			}
		}

		vm.parameterOptions = {
			formState: {
				efforts: []
			}
		};

		function logError(errorData, status) {
			$log.error(`Error : ${errorData} status: ${status} `);
		}

		function isReadOnlyMode() {
			return (vm.mode === appConfig.dataEntryDisplayModes.review || vm.mode === appConfig.dataEntryDisplayModes.viewOnly);
		}

		function isAddMode() {
			return (vm.mode === appConfig.dataEntryDisplayModes.add);
		}
	}
})();
