/*
 * studyMetricsWidgetComponent
 * */

import templateUrl from './studyMetricsWidget.html';
import controller from './studyMetricsWidget.controller';
import './studyMetricsWidget.scss';

const studyMetricsWidgetComponent = {
	bindings: {
		heading: '@',
		expertAvailable: '<'
	},
	templateUrl,
	controller
};

export default studyMetricsWidgetComponent;
