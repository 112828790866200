import templateUrl from './calendarGrid.html';
import controller from './calendarGrid.controller';
import './calendarGrid.scss';

const calendarGridDirective = () => {
	return {
		scope: {
			options: '=',
			calendarOptions: '=',
			selectedRows: '=?',
			init: '=?',
			load: '=?',
			reload: '=?',
			replaceContent: '=',
			onRowsSelected: '&?'
		},
		controller,
		controllerAs: 'vm',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		transclude: true
	};
};

export default calendarGridDirective;
