/*
 * rowActionModule
 * */

import rowActionComponent from './rowAction.component';

const rowActionModule = angular.module('app.common.table.rowAction', [])
	.component('rowAction', rowActionComponent)
	.name;

export default rowActionModule;
