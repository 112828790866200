import templateUrl from './abpmReport.html';
import controller from './abpmReport.controller';

const abpmReport = {
	restrict: 'E',
	templateUrl,
	controller
};

export default abpmReport;
