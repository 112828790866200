/*
 * gridModule
 * */

import gridComponent from './grid.component';

const gridModule = angular.module('app.common.grid', [])
	.component('grid', gridComponent)
	.name;

export default gridModule;
