(function() {
  'use strict';

  angular.module('portalApp').directive('ertPasswordComplexity', ErtPasswordComplexity);

  ErtPasswordComplexity.$inject = [];

  function ErtPasswordComplexity() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, elm, attrs, ctrl) {
        ctrl.$validators.ertPasswordComplexity = function(modelValue, viewValue) {
          if (ctrl.$isEmpty(modelValue)) {
            return true;
          }
          var checkRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;

          if (checkRegEx.test(modelValue)) {
            return true;
          }
          return false;
        };
      }
    };
  }


})();
