const _ = require('lodash');
let subject;

/*
 * breadcrumbsService
 * */
export default class breadcrumbsService {
	/*
	 * Id of the service.
	 * @returns {} breadcrumb service id.
	 */
	static id() {
		return 'breadcrumbsService';
	}

	/*
	 * Constructor.
	 * @param {} $log
	 */
	constructor($log) {
		'ngInject';
		this.$log = $log.getInstance('breadcrumbsService', 'color:green');
		this.items = [];
		subject = new Rx.BehaviorSubject(this.items);
	}


	/*
	 * Subscribes to observer.
	 * @param {} next
	 * @param {} error
	 * @param {} complete
	 * @returns {} dispose method
	 */
	subscribe(next, error, complete) {
		const subscription = subject.subscribe(next, error, complete);

		return () => { subscription.dispose(); };
	}

	/*
	 * Adds a new breadcrumb level.
	 * @param {} item - bradcrumb level
	 */
	register(item) {
		this.items.push(item);
		subject.onNext(this.items);

		// Removes the level from the breadcrumb.
		return () => {
			_.remove(this.items, { name: item.name });
			subject.onNext(this.items);
		};
	}

	/*
	 * Removes all breadcrumb levels.
	 */
	clear() {
		this.items = [];
		subject.onNext(this.items);
	}

}
