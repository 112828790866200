class SiteInfoPopupController {
	constructor($log, loadingIndicatorService, clinicalDataService, appConfig, appI18n) {
		'ngInject';
		this.$log = $log;
		this.appI18n = appI18n;
		this.loadingIndicatorService = loadingIndicatorService;
		this.clinicalDataService = clinicalDataService;
		this.appConfig = appConfig;
	}

	$onInit() {
		this.$log = this.$log.getInstance('SiteInfoPopupController');
		this.$log.debug('loaded');
		this.setApi();
	}

	setApi() {
		const self = this;

		this.$API = {
			open: (siteId) => {
				this.appI18n.translate([
					'clinicaldata.site.info',
					'clinicaldata.site.site-info.title',
					'clinicaldata.site.site-info.error',
					'clinicaldata.site.site-info.na',
					'clinicaldata.site.site-info.no-email-given'
				])
					.then((translations) => {
						self.translations = translations;
						this.noEmailGiven = this.translations['clinicaldata.site.site-info.no-email-given'];
						self.fetchSiteInfo(siteId);
					});
			},
			close: () => {
				self.open = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({ $API: this.$API });
		}
	}

	cancelAction() {
		this.$API.close();
	}

	fetchSiteInfo(siteId) {
		this.$log.debug(`Load Site Info. SiteId: ${siteId}`);

		this.loadingIndicatorService.show();
		this.clinicalDataService.getSiteInfo(siteId).then((resp) => {
			this.siteInfo = resp.data;
			if (!this.siteInfo.siteDetails.sponsorSiteId ||
				this.siteInfo.siteDetails.sponsorSiteId.trim().length === 0) {
				this.siteInfo.siteDetails.sponsorSiteIdOrNA = this.translations['clinicaldata.site.site-info.na'];
			} else {
				this.siteInfo.siteDetails.sponsorSiteIdOrNA = this.siteInfo.siteDetails.sponsorSiteId;
			}

			this.siteInfo.pi = this.siteInfo.sitePIContacts[0];

			this.assignDataPoints(this.siteInfo.enrollmentGraph);
			this.modalTitle = `${this.translations['clinicaldata.site.site-info.title']} - ` +
					`${this.siteInfo.siteDetails.sponsorSiteId}`;

			this.open = true;
			this.loadingIndicatorService.hide();
		}, () => {
			//TODO: Show notification MPORT-3604
			this.loadingIndicatorService.hide();
			this.open = false;
		});
	}

	assignDataPoints(graph = {}) {
		if (graph.enrolled) {
			this.enrollmentColor = this.appConfig.enrollmentColors.info.enrolled;
		}
		if (graph.included) {
			this.includedColor = this.appConfig.enrollmentColors.info.included;
		}
		if (graph.created) {
			this.createdColor = this.appConfig.enrollmentColors.info.created;
		}
		if (graph.randomized) {
			this.randomizedColor = this.appConfig.enrollmentColors.info.randomized;
		}
		if (graph.earlyTerminated) {
			this.terminatedColor = this.appConfig.enrollmentColors.info.terminated;
		}
		if (graph.excluded) {
			this.excludedColor = this.appConfig.enrollmentColors.info.excluded;
		}
		if (graph.finished) {
			this.finishedColor = this.appConfig.enrollmentColors.info.finished;
		}
		if (graph.treatmentPeriodCompleted) {
			this.treatmentColor = this.appConfig.enrollmentColors.info.treatment;
		}
		if (graph.activated) {
			this.activatedColor = this.appConfig.enrollmentColors.info.activated;
		}
	}

	getContactFullAddress(contact) {
		let address = '';

		if (contact.contactAddress) {
			address += `${contact.contactAddress} `;
		}
		if (contact.contactCity) {
			address += `, ${contact.contactCity}`;
		}
		if (contact.contactZipCode) {
			address += `, ${contact.contactZipCode}`;
		}
		if (contact.usStateAbbreviation) {
			address += `, ${contact.usStateAbbreviation}`;
		} else if (contact.contactForeignProvince) {
			address += `, ${contact.contactForeignProvince}`;
		}
		return address;
	}

	/* eslint-disable complexity */
	hasEnrollmentData() {
		if (this.siteInfo && !this.isLoading && this.siteInfo.enrollmentGraph && (
			this.siteInfo.enrollmentGraph.created || this.siteInfo.enrollmentGraph.enrolled ||
			this.siteInfo.enrollmentGraph.included || this.siteInfo.enrollmentGraph.randomized ||
			this.siteInfo.enrollmentGraph.earlyTerminated || this.siteInfo.enrollmentGraph.excluded ||
			this.siteInfo.enrollmentGraph.finished || this.siteInfo.enrollmentGraph.treatmentPeriodCompleted ||
			this.siteInfo.enrollmentGraph.activated)) {
			const response =
				(this.siteInfo.enrollmentGraph.created && this.siteInfo.enrollmentGraph.created.length > 0) ||
				(this.siteInfo.enrollmentGraph.enrolled && this.siteInfo.enrollmentGraph.enrolled.length > 0) ||
				(this.siteInfo.enrollmentGraph.included && this.siteInfo.enrollmentGraph.included.length > 0) ||
				(this.siteInfo.enrollmentGraph.randomized &&
				this.siteInfo.enrollmentGraph.randomized.length > 0) ||
				(this.siteInfo.enrollmentGraph.earlyTerminated &&
				this.siteInfo.enrollmentGraph.earlyTerminated.length > 0) ||
				(this.siteInfo.enrollmentGraph.excluded &&
				this.siteInfo.enrollmentGraph.excluded.length > 0) ||
				(this.siteInfo.enrollmentGraph.finished &&
				this.siteInfo.enrollmentGraph.finished.length > 0) ||
				(this.siteInfo.enrollmentGraph.treatmentPeriodCompleted &&
				this.siteInfo.enrollmentGraph.treatmentPeriodCompleted.length > 0) ||
				(this.siteInfo.enrollmentGraph.activated && this.siteInfo.enrollmentGraph.activated.length > 0);

			return response;
		} else {
			return false;
		}
	}
	/* eslint-enable complexity */
}

export default SiteInfoPopupController;
