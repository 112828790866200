/*
 * openQueriesTableModule
 * */

import openQueriesTableComponent from './openQueriesTable.directive';

const openQueriesTableModule = angular.module('app.components.dashboard.openQueriesWidget.openQueriesTable', [])
	.directive('openQueriesTable', openQueriesTableComponent)
	.name;

export default openQueriesTableModule;
