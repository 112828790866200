import templateUrl from './popupFooter.html';
import './popupFooter.scss';

const popupFooterComponent = {
	bindings: {},
	transclude: true,
	templateUrl
};

export default popupFooterComponent;
