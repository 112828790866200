/*
 * openQueriesWidgetModule
 * */

import openQueriesWidgetComponent from './openQueriesWidget.component';
import openQueriesTableModule from './openQueriesTable/index';

const openQueriesWidgetModule = angular.module('app.components.dashboard.openQueriesWidget', [openQueriesTableModule])
	.component('openQueriesWidget', openQueriesWidgetComponent)
	.name;

export default openQueriesWidgetModule;
