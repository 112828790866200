(function () {
	'use strict';

	angular.module('portalApp').controller('pftMeasurementInfoStepCtrl', PftMeasurementInfoStepCtrl);

	/* @ngInject */
	function PftMeasurementInfoStepCtrl($scope, $log, clinicalDataEntryDataService, appConfig,
		clinicalDataEntryService, $stateParams, ertStepsWizardService, $timeout) {

		var vm = this;
		$log = $log.getInstance('PftMeasurementInfoStepCtrl', 'color:blue');
		$log.debug(' loaded');

		vm.data = clinicalDataEntryDataService.getDataModel();

		vm.cdeMode = clinicalDataEntryService.getCdeMode();

		vm.isValid = isValid;
		vm.formatData = formatData;
		vm.resetSiteComment = resetSiteComment;


		active();
		function active() {
			clinicalDataEntryService.registerMeasurementInfoHandler(vm);

			if ($scope.wizardStep) {
				$scope.wizardStep.isValid = isValid;
			}
			$scope.$on('navigateToStep1', function (event, args) {
				initialize();
			});

			$scope.$on('leaveStep1', function (event, args) {
				vm.formatData(vm.data);
			});

			$scope.$on('beforeNavigateToStep0', function (event, args) {
				if (vm.visitEventHandler) {
					vm.visitEventHandler.cleanOutOfRangeError();
				}
			});

			if ($stateParams.signKey) {
				const unregister = $scope.$watch(
					() => {return ertStepsWizardService.isWizardRegistered();},
					() => {
						if (ertStepsWizardService.isWizardRegistered()) {
							// if an entry is submitted successfully the visit event handler is destroyed and
							// the user is redirected to the entry grid.
							// But the timeout will fire afterwards and this could cause exceptions.
							$timeout(() => {
								vm.visitEventHandler = clinicalDataEntryService.getVisitEventHandler();
								vm.effortsHandler = clinicalDataEntryService.getEffortsHandler();

								if (vm.visitEventHandler) {
									vm.visitEventHandler.setData(vm.data, vm.cdeMode);
								}
								if (vm.effortsHandler) {
									vm.effortsHandler.setData(vm.data, vm.cdeMode);
								}

								initialize();
							}, 0);
							unregister();
						}
					});
			}


		}
		function initialize() {
			vm.effortsHandler = clinicalDataEntryService.getEffortsHandler();
			vm.visitEventHandler = clinicalDataEntryService.getVisitEventHandler();
			if (vm.visitEventHandler) {
				vm.visitEventHandler.show(vm.data.context.studyId, vm.data.context.studyModeId,
										  vm.data.context.internalSiteId,
										  vm.data.subject.isProficiency, appConfig.dataEntryDisplayModes.add,
										  vm.data, vm.cdeMode);
				// check age demographic range every time we go to this step
				vm.visitEventHandler.setAgeDemographic(vm.data.demographics);
			}
		}

		function isValid() {
			return isVisitEventValid() & isSpirometryFormValid();
		}

		function isSpirometryFormValid() {
			return vm.effortsHandler.isValid();
		}

		function isVisitEventValid() {
			return vm.visitEventHandler.isValid();
		}

		function resetSiteComment() {
			vm.data.comments = '';
		}

		function formatData(dataModel) {
			dataModel.visitEvent = vm.visitEventHandler.getData();
			dataModel.efforts = vm.effortsHandler.getData();
			dataModel.subject.fields = getDemographics(dataModel.demographics);

			const options = vm.visitEventHandler.getAvailableVisitEventOptions();
			const technicians = vm.visitEventHandler.getTechniciansList();

			dataModel.technicians = technicians;
			dataModel.visitEventOptions = {
				periodOptions: options.periodOptions,
				visitOptions: options.visitOptions,
				eventOptions: options.eventOptions
			};
		}

		function getDemographics(demo) {
			var res = [];
			for (var x = 0; x < demo.length; x++) {
				res[x] = {
					fieldId: demo[x].fieldId || demo[x].id,// existing and new subject
					name: demo[x].fieldName || demo[x].name, // existing and new subject
					label: demo[x].fieldLabel || demo[x].label, // existing and new subject
					value: (function (value) {
						if (_.isString(value)) {
							return value;
						} else if(_.isNumber(value)) {
							return value.toString();
						} else if (_.isObject(value)) {
							return value.name;
						} else {
							return '';
						}
					})(demo[x].demographic)
				};
			}
			return res;
		}

		vm.commentsField = [{
			type: 'esTextArea',
			key: 'comments',
			templateOptions: {
				type: 'text',
				disabled: false,
				id: 'commentsField'
			},
			ngModelElAttrs: {
				maxlength: '4000'
			},
		}];

		vm.comments0ptions = {
			formState: {
				comments: {
					disabled: false
				}
			}
		};


	}
})();
