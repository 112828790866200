/*
 * sortingModule
 * */

import sortingComponent from './sorting.component';
import sortingService from './sorting.service';

const sortingModule = angular.module('app.common.table.sorting', [])
	.component('sorting', sortingComponent)
	.service('sortingService', sortingService)
	.name;

export default sortingModule;
