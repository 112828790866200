/*
 * exportButtonComponent
 * */

import templateUrl from './exportButton.html';
import controller from './exportButton.controller';
import './exportButton.scss';

const exportButtonComponent = {
	bindings: {
		onSelect: '&',
		isDisabled: '<?'
	},
	templateUrl,
	controller
};

export default exportButtonComponent;
