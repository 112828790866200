const scrollService = () => {

	let cur = 0, observer;
	const observable = Rx.Observable.create((obsrv) => {
		observer = obsrv;
	});

	const scroll = observable.map((params) => {
		cur = cur + params.show;
		// sometimes it can be the case that popup is closed automatically (by navigation)
		// in such cases onDestroy callback will unset the scroll,
		// this may lead to negative index, to avoid this, this line added
		if (cur < 0) { cur = 0; }
		params.show = !!cur;
		return params;
	});


	return {
		setScroll(params = {}) {
			params.show = 1;
			return observer.onNext(params);
		},
		unsetScroll(params = {}) {
			params.show = -1;
			return observer.onNext(params);
		},
		getScrollSubject() {
			return scroll;
		}
	};
};

export default scrollService;

