/*
 * ertGridDirective
 * */

import templateUrl from './ertGrid.html';
import controller from './ertGrid.controller';
import './ertGrid.scss';

const ertGridDirective = () => {
	return {
		scope: {
			options: '=',
			selectedRows: '=?',
			init: '=?',
			load: '=?',
			reload: '=?',
			replaceContent: '=',
			onRowsSelected: '&?'
		},
		controller,
		controllerAs: 'vm',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		transclude: true
	};
};

export default ertGridDirective;
