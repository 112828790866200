(function () {
  'use strict';

  angular.module('portalApp').factory('studySelectService', StudySelectService);

	/**
	 * @ngInject
	 */
	function StudySelectService($q, $log, $http, $rootScope, appConfig, appSession) {

    $log = $log.getInstance("StudySelectService");
    $log.debug('loaded');

    var vm = this;

		vm.openHeaderStudySelector = function () {
			$rootScope.$broadcast(appConfig.events.headerOpenStudySelector);
		};

    vm.getStudies = function (oDataQuery) {
      var q = $q.defer();

      const url = appConfig.apiUrls.studies.all.supplant({ oDataQuery: oDataQuery || '' });

      $log.debug("getStudies() HTTP GET {0}", [url]);

		$http.get(url).then(function (response) {
        $log.debug("getStudies() HTTP GET OK, '{0}' studies ",[response.data.length]);

        q.resolve(response.data);
		}, function (response) {
        $log.error("getStudies() HTTP GET FAILED, error: {0}",[response.data]);

        q.reject({message: response.data.error, code: response.data.data});
      });

      return q.promise;
    };

		vm.getStudiesByIds = function (ids) {
			var q = $q.defer();
			var url;

			if (ids) {
				var idsString = ids.join();
				url = appConfig.apiUrls.studies.byId.supplant({ ids: idsString });
			}
			else {
				url = appConfig.apiUrls.studies.all.supplant({ oDataQuery: '' });
			}

			$http.get(url).then(function (response) {
				q.resolve(response.data);
			}, function (response) {
				$log.error("getStudies() HTTP GET FAILED, error: {0}",[response.data]);

				q.reject(response.data);
			});

			return q.promise;
		};

		vm.getStudySwitchConfig = function (studyId, modeId) {
			var url = appConfig.apiUrls.studies.switchConfig.supplant({studyId: studyId,studyModeId: modeId});
			$log.debug(url);
			return $http.get(url);
		};


		return {
			getStudies: vm.getStudies,
			getStudiesByIds: vm.getStudiesByIds,
			openHeaderStudySelector: vm.openHeaderStudySelector,
			getStudySwitchConfig: vm.getStudySwitchConfig
    };
  }
})();
