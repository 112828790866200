/*
 * ertBasePopupController
 * */

class ErtBasePopupController {
	constructor($log, loadingIndicatorService, $attrs, $q) {
		'ngInject';
		this.$log = $log;
		this.loadingIndicatorService = loadingIndicatorService;
		this.$attrs = $attrs;
		this.$q = $q;
	}

	$onInit() {
		this.$log = this.$log.getInstance('ErtBasePopupController');
		this.$log.debug('loaded');
	}

	$onChanges(changesObj) {
		if (changesObj.open && !changesObj.open.isFirstChange()) {

			if (changesObj.open.currentValue && angular.isDefined(this.$attrs.onShow)) {

				this.loadingIndicatorService.show();
				this.$q.resolve(this.onShow()).then((res) => {
					this.loadingIndicatorService.hide();
					this.showPopup = true;
					return res;
				}, (error) => {
					this.$log.error('Error while opening popup', error);
					this.loadingIndicatorService.hide();
				});
				return;
			}

			this.showPopup = changesObj.open.currentValue;
		}
	}

	escapeAction() {
		this.onEscape && this.onEscape() || this.onCancelEscape && this.onCancelEscape();
	}

	cancelAction() {
		this.onCancel && this.onCancel() || this.onCancelEscape && this.onCancelEscape();
	}

}

export default ErtBasePopupController;
