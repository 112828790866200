/*
 * bodyCssClassModule
 * */

import BodyCssClassDirective from './bodyCssClass.directive';
import bodyCssClassFactory from './bodyCssClass.factory';

const bodyCssClassModule = angular.module('app.common.bodyCssClass', [])
	  .factory('bodyCssClassService', bodyCssClassFactory)
	  .directive('bodyCssClass', () => new BodyCssClassDirective())
	  .name;

export default bodyCssClassModule;
