/*
 * editRolesPopupComponent
 * */

import templateUrl from './editRolesPopup.html';
import controller from './editRolesPopup.controller';
import './editRolesPopup.scss';

const editRolesPopupComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default editRolesPopupComponent;
