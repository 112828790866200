/*
 * filterBadgesModule
 * */

import filterBadgesComponent from './filterBadges.component';

const filterBadgesModule = angular.module('app.common.filters.filterBadges', [])
	.component('filterBadges', filterBadgesComponent)
	.name;

export default filterBadgesModule;
