/*
 * fileUploadPopupService
 * */

export default class fileUploadPopupService {

	static id() {
		return 'fileUploadPopupService';
	}

	constructor($log, appConfig, appI18n) {
		'ngInject';
		this.$log = $log.getInstance('fileUploadPopupService', 'color:green');
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.fuPopups = [];
	}

	register(componentId) {
		if (this.fuPopups[componentId]) {
			throw new Error(`File upload popup component with id ${componentId} already exists.`);
		}

		this.fuPopups[componentId] = {
			onShowPopup: new Rx.Subject(),
			onClosePopup: new Rx.Subject(),
			onWhenAddingFileFailed: new Rx.Subject(),
			onAfterAddingFile: new Rx.Subject(),
			onAfterAddingAll: new Rx.Subject(),
			onBeforeUploadItem: new Rx.Subject(),
			onProgressItem: new Rx.Subject(),
			onSuccessItem: new Rx.Subject(),
			onErrorItem: new Rx.Subject(),
			onCancelItem: new Rx.Subject(),
			onCompleteItem: new Rx.Subject(),
			onProgressAll: new Rx.Subject(),
			onCompleteAll: new Rx.Subject()
		};
		return this.fuPopups[componentId];
	}

	unregister(componentId) {
		this.fuPopups[componentId] = null;
	}

	getPopupApi(componentId) {
		if (!this.fuPopups[componentId]) {
			throw new Error(`File upload popup component with id ${componentId} does not exists.`);
		}
		return this.fuPopups[componentId];
	}

	show(componentId, config) {
		const api = this.getPopupApi(componentId);

		api.onShowPopup.onNext(config);
		return this.getPopupApi(componentId);
	}

	getDefaultFilters() {
		return [
			this.getFileTypeFilter(),
			this.getFileSizeFilter()
		];
	}

	getFileTypeFilter(validFileTypesArray = [], errorMessage) {
		const types = (validFileTypesArray.length) ? validFileTypesArray
			: this.appConfig.fileUpload.defaultFilters.validFileType;

		this.invalidFileTypeMessage =
			this.appI18n.translateImmediate('app.fileUpload.invalidFileTypeMessage');

		return {
			name: 'validFileType',
			fn: (item) => {
				let type = item.name.slice(item.name.lastIndexOf('.') + 1);

				type = (type || '').toLowerCase();
				return types.indexOf(type) !== -1;
			},
			errorMsg: errorMessage || this.invalidFileTypeMessage
		};
	}

	getFileSizeFilter(maxSize, unit, errorMessage) {
		const invalidFileSizeMsg = this.appI18n.translateImmediate('app.fileUpload.invalidFileSizeMessage');

		let maxSizeBytes = 0;
		let maxSizeUnit = 0;

		if (!maxSize) {
			maxSizeBytes = this.appConfig.fileUpload.defaultFilters.fileMaxSize;
			maxSizeUnit = (maxSizeBytes / 1048576).toFixed(0);
			unit = 'MB';
		} else {
			unit = unit || 'MB';
			maxSizeBytes = this.convertValueToBytes(maxSize, unit);
			maxSizeUnit = maxSize;
		}


		this.invalidFileSizeMessage = `${invalidFileSizeMsg} ${maxSizeUnit} ${unit}.`;
		return {
			name: 'isValidFileSize',
			fn: (item) => {
				return item.size <= maxSizeBytes;
			},
			errorMsg: errorMessage || this.invalidFileSizeMessage
		};
	}
	
	convertValueToBytes(value, unit) {

		if (!value) { return null; }
		
		unit = unit.toUpperCase();

		const base = 1024;
		let b = null;

		switch (unit) {
			case 'B':
				b = value;
				break;
			case 'KB':
				b = value * Math.pow(base, 1);
				break;
			case 'MB':
				b = value * Math.pow(base, 2);
				break;
			case 'GB':
				b = value * Math.pow(base, 3);
				break;
			case 'TB':
				b = value * Math.pow(base, 4);
				break;
			case 'PB':
				b = value * Math.pow(base, 5);
				break;
		}

		return b;
	}
}
