import appUtils from './utils/app.utils';
import appConfig from './config/app.config';
import ertNgWizard from './wizard/ertNgWizard.directive';
import wizardToolbarComponent from './wizard/ertNgWizardToolbar';
import ertButton from './button/index';
import ertFileUploader from './fileUploader/index';
import loadingIndicator from './loadingIndicator/index';
import bodyCssClassModule from './bodyCssClass/index';
import ertToolbar from './toolbar/index';
import ertCard from './card/index';
import ertSelect from './select/index';
import ertInput from './input/index';
import datePicker from './datePicker/index';
import ertMaskedInput from './maskedInput/index';
import ertTextArea from './textArea/index';
import tableWrapModule from './table/tableWrap/index';
import ertTooltip from './tooltip/index';
import confirmationPopupModule from './popup/confirmation/index';
import confirmationTextAreaPopupModule from './popup/confirmationTextArea/index';
import infoPopupModule from './popup/info/index';
import customPopupModule from './popup/custom/index';
import radioButtonModule from './radio/index';
import controlMessageModule from './controlMessage/index';
import notificationModule from './notification/index';
import pollingModule from './polling/index';
import sideNavigationModule from './sideNavigation/index';
import pageHeaderModule from './pageHeader/index';
import iconLinkModule from './iconLink/index';
import widgetModule from './widget/index';
import tabsModule from './tabs/index';
import breadcrumbsModule from './breadcrumbs/index';
import gridModule from './grid/index';
import baseSelectModule from './select/baseSelect';
import baseDatePickerModule from './datePicker/baseDatePicker';
import labelModule from './label';
import paginationModule from './pagination';
import ertBadge from './badge/index';
import ertBadgeLabel from './badgeLabel/index';
import baseInputModule from './input/baseInput';
import filtersModule from './filters';
import openToggleModule from './openToggle';
import showIFrameModule from './showIFrame';
import contentMessageModule from './contentMessage';
import pageBodyModule from './pageBody';
import simpleDatePickerModule from './datePicker/simpleDatePicker';
import ngEnterModule from './ngEnter/index';
import pageModule from './page';
import ertGridModule from './ertGrid';
import calendarGridModule from './calendarGrid';
import esDropdownModule from './esDropdown/index';
import baseTimePickerModule from '../common/timePicker/baseTimePicker';
import globalMessageModule from '../common/globalMessage';
import esFormLegendModule from '../common/esFormLegend';
import fileUploadPopupModule from './popup/fileUploadPopup';
import previewPopupModule from './popup/previewPopup';

export default angular.module('app.common', [
	appConfig,
	appUtils,
	ertNgWizard,
	ertButton,
	wizardToolbarComponent,
	ertFileUploader,
	loadingIndicator,
	bodyCssClassModule,
	ertToolbar,
	ertCard,
	ertSelect,
	ertInput,
	datePicker,
	ertMaskedInput,
	ertTextArea,
	tableWrapModule,
	ertTooltip,
	confirmationPopupModule,
	confirmationTextAreaPopupModule,
	radioButtonModule,
	infoPopupModule,
	customPopupModule,
	controlMessageModule,
	notificationModule,
	pollingModule,
	sideNavigationModule,
	pageHeaderModule,
	iconLinkModule,
	widgetModule,
	tabsModule,
	breadcrumbsModule,
	gridModule,
	baseSelectModule,
	baseDatePickerModule,
	labelModule,
	paginationModule,
	ertBadge,
	ertBadgeLabel,
	baseInputModule,
	filtersModule,
	openToggleModule,
	showIFrameModule,
	contentMessageModule,
	pageBodyModule,
	simpleDatePickerModule,
	ngEnterModule,
	pageModule,
	ertGridModule,
	calendarGridModule,
	esDropdownModule,
	baseTimePickerModule,
	globalMessageModule,
	esFormLegendModule,
	fileUploadPopupModule,
	previewPopupModule
]).name;
