/*
* User Role Model
* */
class UserRole {
	constructor(roleObj) {
		if (!roleObj.roleId || !roleObj.roleName) {
			throw Error('Role object should have roleId and roleName');
		}

		this.roleId = roleObj.roleId;
		this.roleName = roleObj.roleName;
	}
}

export default UserRole;
