/*
 * contentMessageComponent
 * */

import templateUrl from './contentMessage.html';
import controller from './contentMessage.controller';
import './contentMessage.scss';
/*
* message - defines component message
* type [info, success, warning, error] - defines type of the message and displayed icon
* view-mode [centered] - defines visual position of the elements, by default elements are positioned inline
* */
const contentMessageComponent = {
	bindings: {
		message: '@',
		type: '@?',
		viewMode: '@?'
	},
	templateUrl,
	controller
};

export default contentMessageComponent;
