export default class StudyVocabularyInterpolation {
	constructor($q, $log, $interpolate, $http, appSession, appConfig) {
		'ngInject';
		this.$q = $q;
		this.$log = $log;
		this.$http = $http;
		this.$interpolate = $interpolate;
		this.appSession = appSession;
		this.appConfig = appConfig;
		this.loadingVocabulary = false;
		this.defaultDictionary = { site: 'site', Site: 'Site', subject: 'subject', Subject: 'Subject' };
		this.vocabulary = {};
		Object.assign(this.vocabulary, this.defaultDictionary);
	}

	static id() {
		return 'studyVocabularyInterpolation';
	}

	setLocale() {
		// required, but not used
	}

	getInterpolationIdentifier() {
		return 'custom';
	}

	interpolate(string, interpolateParams, context) {
		const hasStudyVocabulary = this.getHasStudyVocabulary();

		if (hasStudyVocabulary || (interpolateParams && interpolateParams.instant) || context === 'filter') {
			let vocabulary = {};

			if (hasStudyVocabulary) {
				vocabulary = this.vocabulary;
			}
			return this.$interpolate(string)(vocabulary);
		}

		const q = this.$q.defer();

		this.getStudyInterpolateParams().then(
			(vocabulary) => {
				const t = this.$interpolate(string)(vocabulary);

				q.resolve(t);
			});

		return q.promise;
	}

	getHasStudyVocabulary() {
		const study = this.appSession.getStudy();

		if (!study) {
			return true; // no study -> no dictionary required
		}

		const studyId = study.id;

		return (this.studyId === studyId && this.vocabulary && !this.loadingVocabulary);
	}

	getStudyVocabulary(studyId) {
		const url =
					this.appConfig.apiUrls.configuration.studyVocabulary.supplant({ studyId });

		return this.$http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
	}

	getStudyInterpolateParams() {

		if (this.loadingVocabulary) {
			return this.loadPromise.promise;
		}

		this.loadPromise = this.$q.defer();

		const study = this.appSession.getStudy();

		if (study) {
			const studyId = study.id;

			if (this.studyId !== studyId) {
				this.studyId = studyId;
				this.loadingVocabulary = true;

				this.getStudyVocabulary(studyId).then(
					(result) => {
						this.vocabulary = this.setVocabularyCapitalization(result.data);
						this.loadingVocabulary = false;
						this.loadPromise.resolve(this.vocabulary);
					},
					() => {
						// no study vocabulary, use default
						this.loadingVocabulary = false;
						this.loadPromise.resolve(this.vocabulary);
					}
				);
			} else {
				this.loadPromise.resolve(this.vocabulary);
			}
		}

		return this.loadPromise.promise;
	}

	setVocabularyCapitalization(vocabulary) {
		const defaultDictionary = {};

		Object.assign(defaultDictionary, this.defaultDictionary);

		for (const entry in vocabulary) {
			// eslint-disable-next-line no-prototype-builtins
			if (vocabulary.hasOwnProperty(entry)) {
				const translation = vocabulary[entry];

				defaultDictionary[this.capitalizeFirstLetter(entry)] = this.capitalizeFirstLetter(translation);
				defaultDictionary[entry] = translation;
			}
		}
		return defaultDictionary;
	}

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
}
