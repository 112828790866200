/*
 * ecoaSessionViewerComponent
 * */

import templateUrl from './ecoaSessionViewer.html';
import controller from './ecoaSessionViewer.controller';
import './ecoaSessionViewer.scss';

const ecoaSessionViewerComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default ecoaSessionViewerComponent;
