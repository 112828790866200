(function() {
	'use strict';
	angular
		.module("portalApp")
		.service("ertButtonProgressBarWorkerService", ErtButtonProgressBarWorkerService);

	/**
	 * @ngInject
	 */
	function ErtButtonProgressBarWorkerService($log, $window, appConfig) {
		return {
			startInterval: startInterval
		};

		function startInterval(scope, step) {
			if (scope.worker) {
				scope.worker.terminate();
			}

			scope.worker = new Worker(appConfig.workers.progressBar);

			scope.worker.onmessage = function(e) {
				scope.percentage += e.data;
				if (scope.percentage >= 100) {
					scope.percentage = 100;
				}
				scope.$apply();
			};

			scope.worker.postMessage(step);

			scope.$on('$destroy', () => {
				scope.worker.terminate();
			});
		}
	}
})();
