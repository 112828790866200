import ShowIFrameDirective from './showIFrame.directive';

ShowIFrameDirective.$inject = ['$document', '$window', '$timeout'];

const ShowIFrameModule =
	  angular.module(
		  'app.common.showIFrame',
		  [])
	  .directive('showIFrame', ShowIFrameDirective)
	  .name;

export default ShowIFrameModule;
