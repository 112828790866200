/*
 * filterBadgesComponent
 * */
/*
* filters - model to display badges
* onRemove - callback fired when a badge is removed, normally should not be used if badges parent is a grid component,
* use grid callbackks instead
* */
import templateUrl from './filterBadges.html';
import controller from './filterBadges.controller';
import './filterBadges.scss';

const filterBadgesComponent = {
	bindings: {
		filters: '<',
		onRemove: '&?'
	},
	require: {
		grid: '^^?grid'
	},
	templateUrl,
	controller
};

export default filterBadgesComponent;
