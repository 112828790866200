(function() {
	'use strict';
	angular.module('portalApp')
		.filter('gridFilter', ['$filter', '$state', 'appConfig', gridFilter]);

	angular.module('portalApp')
		.filter('ertFilter', ['$filter', ertFilter]);

	angular.module('portalApp')
		.filter('reverse', function() {
			return function(items) {
				return items.slice().reverse();
			};
	});

	function gridFilter($filter, $state, appConfig) {

		const getVisitIcon = (visitIconStyle) => {
			switch (visitIconStyle) {
				case 1:
					return 'es-icon-calendar-normal es-icon-calendar-check normal-visit-color';
				case 2:
					return 'es-icon-calendar-unscheduled unscheduled-visit-color';
				case 3:
					return 'es-icon-calendar-termination termination-visit-color';
				case 4:
					return 'es-icon-calendar-randomization es-icon-calendar-check normal-visit-color';
				case 5:
					return 'es-icon-calendar-normal planned-visit-color';
				case 6:
					return 'es-icon-calendar-normal-overdue overdue-visit-color';
				case 7:
					return 'es-icon-calendar-randomization-overdue overdue-visit-color';
				case 8:
					return 'es-icon-calendar-randomization planned-visit-color';
			}
		};

		return function (value, filterName, row) {
			switch (filterName) {
				case 'eventDetailsXml':
					var cell = '<ul class="es-fluid-data">';
					var entryRegEx = /<Entry>.*?<\/Entry>/g;
					var nameRegEx = /<Name>.*<\/Name>/g;
					var valueRegEx = /<Value>.*<\/Value>/g;

					var entries = value && value.match(entryRegEx);
					if (entries && entries.length > 0) {
						entries.forEach(function (el) {
							var name = el.match(nameRegEx);
							name = name[0].substring(6, name[0].length - 7);
							var value = el.match(valueRegEx);
							value = value[0].substring(7, value[0].length - 8);
							cell += '<li>';
							if (name === '') {
								cell += value;
							} else {
								cell += `${name}:   ${value || 'n/a'}`;
							}
							cell += '</li>';
						});
					}
					cell += '</ul>';
					return cell;
				case 'dateFormat':
					return $filter('date')(value, 'dd-MMM-yyyy');
				case 'dateTimeFormat':
					return $filter('date')(value, 'dd-MMM-yyyy HH:mm:ss');
				case 'dateTimeFormatNA':
					return value ? $filter('date')(value, 'dd-MMM-yyyy HH:mm:ss') : 'N/A';
				case 'dateTimeFormatUtc':
					return value ? moment.utc(value).format('DD-MMM-YYYY HH:mm') : value;
				case 'dateTimeHSFormat':
					return $filter('date')(value, 'dd-MMM-yyyy HH:mm');
				case 'pipe':
					return value && value.replace(/\|/g,'<br/>');
				case 'bold':
					return '<b>' + value + '</b>';
				case 'lineBreak':
					return value && value.replace(/\r\n/g,'<br/>');
				case 'yesNoNa':
					return +value === 1 ? 'Yes' : +value === -1 ? 'No' : 'N/A';
				case 'yesNoEmptyIfNull':
					return value == null ? '' : (+value > 0 ? 'Yes' : 'No');
				case 'yesNo':
					return value === true || value > 0 ? 'Yes' : 'No';
				case 'activeInactive':
					return value === true || value > 0 ? 'Active' : 'Inactive';
				case 'completeIncomplete':
					return value === true || value > 0 ? 'Complete' : 'Incomplete';
				case 'title':
					return 'testfiltertest123';
				case 'userType':
					return value === 'E' ? 'External' : (value === 'I' ? 'Internal' : '');
				case 'removalStatus':
					return value === true || value > 0 ? 'Pending Removal' : 'Active';
				case 'downloadStatus':
					switch (value) {
						case '0': return 'Pending';
						case '1': return 'In Progress';
						case '2': return 'Done';
						case '3': return 'Failed';
						case '4': return 'Canceled';
						case '5': return 'Partially Done';
					}
					break;
				case 'navigateToSubject':

					return `<a href="${$state.href(appConfig.routes.clDataVisits, {
						country: row.countryId, site: row.siteId, subject: row.subjectId,
						eproSubject: row.eproSubjectId
					})}">${value}</a>`;
				case 'navigateToSpirometryViewer':
				 if (!value) return '';
				 if (row.hasSpiroViewer) {
					 const params = {
						 study: row.studyId,
						 country: row.countryId,
						 site: row.siteId,
						 subject: row.subjectId,
						 visit: row.visitName,
						 event: row.eventId,
						 tdt: row.dataType,
						 orderBy: ''
					 };

					 if (row.studyModeId) {
						 params.mode = row.studyModeId;
					 }
					return `<a href="${$state.href(appConfig.routes.spirometry, params)}">${value}</a>`;
				 } else {
					 return value;
				 }
				case 'visitCalendarNavigateToVisit':
					if (row.visitIconStyle <= 4) {
						return `<a href="${$state.href(appConfig.routes.clDataVisits, {
							country: row.countryId,
							site: row.siteId,
							subject: row.subjectId,
							eproSubject: row.eproSubjectId,
							visit: row.visitName
						})}">${value}</a>`;
					} else {
						return value;
					}
				case 'visitCalendarVisitType':
					return `<span class="es-icon ${getVisitIcon(row.visitIconStyle)}"></span>
						<span class="visit-status-column">${value}</span>`;
				case 'bytes':
					if (!value) { return ''; }

					const bytes = Number(value);

					if (isNaN(bytes)) { return value; }

					const k = 1000,
						  dm = 1,
						  sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'],
						  i = bytes ? Math.floor(Math.log(bytes) / Math.log(k)) : 0;

					return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
				case 'tatTimespan':
					if (!row.tatDisplay) { return ''; }
					let display = row.tatDisplay;

					if (row.tatDisplay.endsWith('Paused')) {
						display = `<i>${display}</i>`;
					}
					if (row.tatDisplay.startsWith('-')) {
						return `<span class="ert-grid-filter-overdue"><b>${display}</b></span>`;
					} else {
						return `<b>${display}</b>`;
					}

			}
			return value;
		};
	}

	function ertFilter($filter) {
		return function (value, type, arg1) {
			switch (type) {
				case 'splitPipe':
					var splitValue = value.split('|');
					var index = arg1 || 0;
					if (splitValue.length > index) {
						return splitValue[index];
					}
					else {
						return '';
					}
					break;
				case 'numberToString':
					switch (value) {
						case 0:
							return 'zero';
						case 1:
							return 'one';
						case 2:
							return 'two';
						case 3:
							return 'three';
						case 4:
							return 'four';
						case 5:
							return 'five';
						case 6:
							return 'six';
						case 7:
							return 'seven';
						case 8:
							return 'eight';
						case 9:
							return 'nine';
						case 10:
							return 'ten';
						default:
							return value;
					}
					break;
				case 'dateFormat':
					return $filter('date')(value, 'dd-MMM-yyyy');
				case 'dateTimeFormat':
					return $filter('date')(value, 'dd-MMM-yyyy HH:mm:ss');
				case 'dateTimeTimezoneFormat':
					return $filter('date')(value, 'dd-MMM-yyyy HH:mm:ss (Z)');
				case 'dateTimeOriginalTimezoneFormat':
					// angular format will use local timezone, for original TZ, manually format as
					// advanced date formatting is not supported in older IE versions
					// expects date format string coming in as 2014-04-22T20:15:02.625134+01:00
					var matches = value.match(/^([\d]{4}-)([\d]{2})(-[\d]{2})(T)([\d]{2}:[\d]{2}:[\d]{2})(.[\d]+)(.+)/);
					if (matches.length > 7) {
						var usMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
						var formatted = matches[1] + usMonths[+matches[2]-1] + matches[3] + ' ' +
							matches[5] + ' (' + matches[7] + ')';
						return formatted;
					}
					else {
						return 'N/A';
					}

				case 'dateTimeHSFormat':
					return $filter('date')(value, 'dd-MMM-yyyy HH:mm');
				case 'stringTimeStampToHHmm':
					if (value) {
						const parts = value.split(':');

						if (parts.length > 1) {
							value = new Date().setHours(parts[0], parts[1]);
							return $filter('date')(value, 'HH:mm');
						}
					}
					return value;
			}
			return value;
		};
	}

})();
