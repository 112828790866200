import templateUrl from './kardiaPopup.html';
import controller from './kardiaPopup.controller';
import './kardiaPopup.scss';

const kardiaPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default kardiaPopupComponent;
