/*
 * sideNavigationController
 * */

class SideNavigationController {
	// eslint-disable-next-line max-params
	constructor($log, $rootScope, $state, appSession, appConfig, appI18n, $http,
		helpService, pollingService, analyticsService, configurationService,
		notificationService, sideNavigationService, $window, $scope, $timeout) {
		'ngInject';
		const self = this;

		this.$log = $log;
		this.$rootScope = $rootScope;
		this.$state = $state;
		this.appSession = appSession;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.$http = $http;
		this.helpService = helpService;
		this.pollingService = pollingService;
		this.notificationService = notificationService;
		this.analyticsService = analyticsService;
		this.configurationService = configurationService;
		this.sideNavigationService = sideNavigationService;
		this.$window = $window;
		this.$scope = $scope;
		this.$timeout = $timeout;

		this.openNav = false;
		this.notifications = { numberOfNewDownloads: 0 };
		this.contactusTitle = this.appI18n.translateImmediate('help.contactus.title');
		this.helpTitle = this.appI18n.translateImmediate('help.title');

		this.offExpertAvailabilityChange && this.offExpertAvailabilityChange();
		this.offExpertAvailabilityChange = $rootScope.$on(this.appConfig.broadcast.expertAvailabilityChange,
			(evt, args) => {
				this.onExpertAvailabilityChange(evt, args);
			});

		this.$window.onclick = function (event) {
			// If the sidebar menu is alreafy closed we don't need to do anything here
			if (!self.openNav) {
				return;
			}

			// close the sidebar if there is a click outside of the sidebar container.
			// But that means we need to handle the close action for all clicks within
			// the sidebar container separately
			if (!angular.element(event.target).is('.es-sidebar *, .es-sidebar')) {
				self.closeSidebar();
				self.$scope.$apply();
			}
		};
	}

	$onInit() {
		this.$rootScope.$on('$stateChangeSuccess', () => {
			this.setActiveState();
		});

		this.currentStudy = this.appSession.getStudy();

		this.navItemsLoading = true;
		this.sideNavigationService.getNavigationItems().then((resp) => {
			this.navItems = resp;
			this.navItemsLoading = false;
			this.setExpertAvailability(this.appSession.getIsExpertUnavailable());

			this.appSession.refreshRecentStudies();
			this.startPolling();
			this.setActiveState();
		}, (data, status) => {
			this.navItemsLoading = false;
			this.$log.error('Error getting navigation items, data: "{0}", status: "{1}"', data, status);
		});
	}


	$onDestroy() {
		this.offExpertAvailabilityChange();
		this.offExpertAvailabilityChange = null;
	}

	setActiveState() {
		if (this.navItems) {
			this.navItems.forEach((i) => {
				if (i.routeRoot) {
					i.isActive = this.$state.includes(i.routeRoot);
				}
			});
		}
	}

	getNumberOfNewDownloads() {
		return this.appSession.getNumberOfNewDownloads();
	}

	startPolling() {
		const study = this.appSession.getStudy();
		const url = this.appConfig.apiUrls.notifications.status.supplant({
			'studyId': study.id,
			'studyModeId': study.studyModeId
		});

		return this.$http.get(url)
			.then((response) => {
				this.appSession.setNumberOfDownloads(response.data.numberOfCompletedDownloads);

				this.pollingService.start({ url }).then(() => { }, (error) => {
					this.$log.error(`Error getting notifications: ${error}`);
				}, (notify) => {
					if (this.appSession.getNumberOfDownloads() < notify.numberOfCompletedDownloads) {
						if (!this.$state.is(this.appConfig.routes.downloads)) {
							this.appSession.setNumberOfNewDownloads(
								parseInt(this.appSession.getNumberOfNewDownloads()) +
								parseInt(notify.numberOfCompletedDownloads) -
								parseInt(this.appSession.getNumberOfDownloads()));
							this.notificationService.showInfo(supplant(
								this.appI18n.translateImmediate('my-downloads.fileReady'),
								[this.$state.href(this.appConfig.routes.downloads)]));
						}
						this.appSession.setNumberOfDownloads(notify.numberOfCompletedDownloads);
					}
				});
			}, (error) => {
				this.$log.error(`Error: ${error}`);
			});
	}

	onExpertAvailabilityChange(event, args) {
		this.setExpertAvailability(args.expertUnavailable);
	}

	setExpertAvailability(expertUnavailable) {
		const navItems = this.navItems;

		for (const prop in navItems) {
			if (navItems.hasOwnProperty(prop)) {
				if (navItems[prop].requiresExpert) {
					navItems[prop].hiden = expertUnavailable;
				}
			}
		}
	}

	openHelp() {
		const moduleId = this.$state.current.moduleId || this.appConfig.constants.defaultHelpModuleId;

		this.helpService.showModuleHelp(moduleId);
	}

	toggle(e) {
		e.stopPropagation();
		this.openNav = !this.openNav;
		this.analyticsService.trackEvent(
			this.appConfig.trackCategory.toggleSidebar,
			this.openNav ? this.appConfig.trackAction.collapsed : this.appConfig.trackAction.expanded
		);
	}

	openSidebar(e) {
		e.stopPropagation();
		if (!this.openNav) {
			this.openNav = true;
		}
	}

	closeAllSubMenus(elementToExclude) {
		// remove all "-show-sub-nav" classes at once
		const sidebarElement = angular.element('.es-sidebar')[0];

		if (!sidebarElement) {
			return;
		}

		const navItems = sidebarElement.querySelectorAll('.-show-sub-nav');

		Array.prototype.forEach.call(navItems, (navItem) => {
			// element to exclude?
			if (elementToExclude && (navItem === elementToExclude[0])) {
				return;
			}

			// remove "-show-sub-nav" modifier
			const angularNavItem = angular.element(navItem);

			angularNavItem.removeClass('-show-sub-nav');
		});
	}

	closeSidebar() {
		if (this.openNav) {
			this.openNav = false;
			this.closeAllSubMenus();
		}
	}

	trackClick(name, label, trackNavigateTo) {
		const isOpen = !angular.element('.es-sidebar').hasClass('-es-closed');

		this.analyticsService.trackEventWithTitle(
			`${this.appConfig.trackCategory.navigationState}::${name}`,
			isOpen ? this.appConfig.trackAction.expanded : this.appConfig.trackAction.collapsed
		);

		if (trackNavigateTo) {
			this.analyticsService.trackEventWithTitle(
				`${this.appConfig.trackCategory.navigateTo}::${name}`, this.appConfig.trackAction.navigation, label);
		}
	}

	isNestedElement(ulElement) {
		return ulElement.css('position') === 'static';
	}

	handleItemClick(item, event, isRouteItem) {
		if (!isRouteItem) {
			item.suppressClick = false;
		}
		// we need to handle different cases. If the sidebar menu is closed we don't need to check anything
		if (!this.openNav) {
			return;
		}

		// for a open sidebar menu we need to differentiate between the two layout variants (flyout and nested)
		const ulElement = angular.element(event.target).parents('.es-nav-item').find('ul');

		// for a flyout menu no further actions are required. But for the nested menu we need to expand
		// the container by setting the focus. The problem here is that we have a routing action on
		// the specific element and therefore we need to prevent event bubbling.
		// if the item is an item that does not use a routing action but an onClick handler,
		// we suppress the click, but do not prevent event bubbling. This is used for items that need
		// dynamic changing of the link target, which the angular router does not support.
		// cmp https://github.com/angular-ui/ui-router/issues/395#issuecomment-24031510
		if (this.isNestedElement(ulElement)) {
			if (isRouteItem) {
				event.preventDefault();
				event.stopPropagation();
			} else {
				item.suppressClick = true;
			}

			const isSubNavOpen = ulElement.hasClass('-show-sub-nav');

			this.closeAllSubMenus(ulElement);
			if (isSubNavOpen) {
				// already open, close it
				ulElement.removeClass('-show-sub-nav');
			} else {
				// closed, open it
				ulElement.addClass('-show-sub-nav');
			}

		} else {
			this.closeSidebar();
		}
	}
}

export default SideNavigationController;
