/*
 * ecoaSessionViewerModule
 * */

import ecoaSessionViewerComponent from './ecoaSessionViewer.component';
import ecoaSessionViewerBulletListComponent from './expandableBulletList/index';

const ecoaSessionViewerModule = angular.module('app.components.clinicalData.ecoaSessionViewer',
											   [ecoaSessionViewerBulletListComponent])
	.component('ecoaSessionViewer', ecoaSessionViewerComponent)
	.name;

export default ecoaSessionViewerModule;
