(function () {

	'use strict';

	angular
		.module('portalApp')
		.service('clinicalDataEntryApiService', ClinicalDataEntryApiService);

	/**
	 * @ngInject
	 */
	function ClinicalDataEntryApiService(appConfig, $http, $log,appSession) {
		$log = $log.getInstance("ClinicalDataEntryService", "color:blue");
		$log.debug("loaded");

		var service = {
			getAll: getAll,
			getOne: getOne,
			getLockStatus: getLockStatus,
			getStudyConfig: getStudyConfig,
			getStudiesForUser: getStudiesForUser,
			getSites: getSites,
			getSubjectsList: getSubjectsList,
			getTechnicians,
			getVisitSchedules: getVisitSchedules,
			getPeriods: getPeriods,
			getVisitTasks: getVisitTasks,
			getSpirometryParameters: getSpirometryParameters,
			getEcgParameters: getEcgParameters,
			getSubjectDemographics: getSubjectDemographics,
			getSubjectDemographicsConfig: getSubjectDemographicsConfig,
			createEntry: createEntry,
			updateEntry: updateEntry,
			updateEntryStatus: updateEntryStatus,
			requireMoreInfo: requireMoreInfo,
			lockEntry: lockEntry,
			unLockEntry: unLockEntry,
			checkIfEventAlreadyExists: checkIfEventAlreadyExists,
			checkIfVisitAlreadyExists: checkIfVisitAlreadyExists,
			hasOpenTasks: hasOpenTasks,
			generateAuditReport
		};

		return service;

		function log (name, url) {
			$log.debug(`clinicalDataEntryService.${name} with url: ${url}`);
		}

		function hasOpenTasks() {
			var url = appConfig.apiUrls.clinicalDataEntry.hasOpenTasks.supplant(
				{'studyId': appSession.getStudy().id,
				 'studyModeId': appSession.getStudy().studyModeId});
			log('hasOpenTasks', url);
			return $http.get(url);
		}

		function getAll(options, forExport) {
			var url = appConfig.apiUrls.clinicalDataEntry.getAll.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });
			if (options) {
				url += "&" + options;
			}

			log('getAll', url);
			return $http.get(url);
		}

		function getOne(id) {
			var url = appConfig.apiUrls.clinicalDataEntry.getOne.supplant(
			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 'id': id});
			log('getOne', url);
			return $http.get(url);
		}

		function getStudiesForUser(studyId, studyModeId, cdeMode) {
			var url = appConfig.apiUrls.clinicalDataEntry.getStudiesForUser.supplant(
				{ 'studyId': studyId, 'studyModeId': studyModeId , cdeMode});
			log('getStudiesForUser', url);
			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
		}

		function getSites(studyId, studyModeId) {
			var url = appConfig.apiUrls.clinicalDataEntry.getSites.supplant(
				{ 'studyId': studyId, 'studyModeId': studyModeId});
			log('getSites', url);
			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
		}		

		function getTechnicians(studyId, studyModeId, siteId) {
			const url = appConfig.apiUrls.clinicalDataEntry.getTechnicians.supplant(
				{ studyId, studyModeId, siteId });

			log('getTechnicians', url);
			return $http.get(url);
		}

		function getSubjectsList(studyId, studyModeId, siteId) {
			var url = appConfig.apiUrls.clinicalDataEntry.getSubjectsList.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId, 'siteId': siteId});
			log('getSubjectsList', url);
			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
		}

		function getVisitSchedules(studyId, studyModeId, testDataTypeId, proficiency, cdeMode, period) {
			if (!testDataTypeId) {
				testDataTypeId = 0;
			}
			if (!period) {
				period = 0;
			}
			var url = appConfig.apiUrls.clinicalDataEntry.getVisitSchedules.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId, 'testDataTypeId': testDataTypeId,
				 'proficiency': proficiency ? 'true' : 'false' , 'period': period, cdeMode});

			log('getVisitSchedules', url);
			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
		}

		function getPeriods(studyId, studyModeId) {
			var url = appConfig.apiUrls.clinicalDataEntry.getPeriods.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId });

			log('getPeriods', url);
			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
		}

		function getVisitTasks(studyId, studyModeId, visitId, cdeMode) {
			var url = appConfig.apiUrls.clinicalDataEntry.getVisitTasks.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId, 'visitId': visitId, cdeMode});
			log('getVisitTasks', url);
			return $http.get(url);
		}

		function getEcgParameters(studyId, testDataType) {
			var url = appConfig.apiUrls.clinicalDataEntry.getEcgParameters.supplant(
				{'studyId': studyId, 'testDataType': testDataType});
			log('getEcgParameters', url);
			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
		}

		function getSpirometryParameters(studyId,studyModeId,testDataType) {
			var url = appConfig.apiUrls.clinicalDataEntry.getSpirometryParameters.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId, 'testDataType': testDataType});
				log('getSpirometryParameters', url);
			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
		}

		function getStudyConfig(studyId, testDataType) {
			var url = appConfig.apiUrls.clinicalDataEntry.getStudyConfig.supplant(
				{'studyId': studyId, 'testDataType': testDataType});
			log('getStudyConfig', url);
			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
		}

		function getSubjectDemographics(studyId, studyModeId, siteId, subjectId, cdeMode) {
			var url = appConfig.apiUrls.clinicalDataEntry.getSubjectDemographics.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId, 'siteId': siteId, 'subjectId': subjectId,
				 cdeMode});
			log('getSubjectDemographics', url);
			return $http.get(url);
		}

		function getSubjectDemographicsConfig(studyId,studyModeId, cdeMode) {
			var url = appConfig.apiUrls.clinicalDataEntry.getSubjectDemographicsConfig.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId, cdeMode});
			log('getSubjectDemographicsConfig', url);
			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } });
		}

		function createEntry(createModel) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalDataEntry.createEntry.supplant(
				{ 'studyId': study.id, 'studyModeId': study.studyModeId });
			log('createEntry', url);
			var data = JSON.stringify(createModel);
			return $http.post(url, data);
		}

		function updateEntry(id, updateModel) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalDataEntry.updateEntry.supplant(
				{ 'studyId': study.id, 'studyModeId': study.studyModeId, 'id': id });
			log('updateEntry', url);
			var data = JSON.stringify(updateModel);
			return $http.put(url, data);
		}

		function updateEntryStatus(id, model) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalDataEntry.updateEntryStatus.supplant(
				{'studyId': study.id, 'studyModeId': study.studyModeId, 'id': id });
			log('updateEntryStatus', url);

			var data = JSON.stringify(model);
			return $http.put(url, data);
		}

		function requireMoreInfo(id, comments) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalDataEntry.requireMoreInfo.supplant(
				{'studyId': study.id, 'studyModeId': study.studyModeId, 'id': id });
			log('requireMoreInfo', url);

			var updateModel = { id:id, comments:JSON.stringify(comments) };
			var data = JSON.stringify(updateModel);
			return $http.put(url, data);
		}
		function lockEntry(studyId,studyModeId, id) {
			var url = appConfig.apiUrls.clinicalDataEntry.lockEntry.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId, 'id': id });
			log('lockEntry', url);
			return $http.put(url);
		}

		function unLockEntry(studyId,studyModeId, id) {
			var url = appConfig.apiUrls.clinicalDataEntry.unLockEntry.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId, 'id': id });
			log('unLockEntry', url);
			return $http.put(url);
		}

		function getLockStatus(id) {
			var url = appConfig.apiUrls.clinicalDataEntry.getLockStatus.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 'id': id});
			log('getLockStatus', url);
			return $http.get(url);
		}

		function checkIfVisitAlreadyExists(model) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalDataEntry.checkIfVisitAlreadyExists.supplant(
				{'studyId': study.id, 'studyModeId': study.studyModeId });
			log('checkIfVisitAlreadyExists', url);

			var data = JSON.stringify(model);
			return $http.post(url, data);
		}

		function checkIfEventAlreadyExists(model) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalDataEntry.checkIfEventAlreadyExists.supplant(
				{'studyId': study.id, 'studyModeId': study.studyModeId });
			log('checkIfEventAlreadyExists', url);

			var data = JSON.stringify(model);
			return $http.post(url, data);
		}

		function generateAuditReport(studyId, studyModeId, useZip, siteId) {
			var url = appConfig.apiUrls.clinicalDataEntry.generateAuditReport.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId, 'id': studyModeId });
			if (useZip) {
				url += '&useZip=true';
			}
			if (siteId && +siteId > 0) {
				url += '&siteId=' + siteId;
			}
			log('generateAuditReport', url);
			return $http.post(url);
		}

	}
})();
