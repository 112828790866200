/*
 * iSpiroAuditReportPopupComponent
 * */

import templateUrl from './iSpiroAuditReportPopup.html';
import controller from './iSpiroAuditReportPopup.controller';
import './iSpiroAuditReportPopup.scss';

const iSpiroAuditReportPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default iSpiroAuditReportPopupComponent;
