/*
 * editISpiroDeviceValuesPopupComponent
 * */

import templateUrl from '../dispenseISpiroDevicePopup/dispenseISpiroDevicePopup.html';
import controller from './editISpiroDeviceValuesPopup.controller';
import './editISpiroDeviceValuesPopup.scss';

const editISpiroDeviceValuesPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default editISpiroDeviceValuesPopupComponent;
