(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('SqfWizardCtrl', SqfWizardController);

	/**
	 * @ngInject
	 */
	function SqfWizardController($state, $scope, $stateParams, $log, appI18n, sqfService, WizardHandler,
								 dialogService, notificationService, appConfig) {
		var vm = this;

		$log = $log.getInstance("SqfWizardController");
		$log.debug("SqfWizardController loaded, sqfId:" + $stateParams.sqfId);

		vm.name = $scope.shared.localized.wizardTitles.summary;
		vm.sqfId = 0;
		vm.IsEditMode = vm.sqfId > 0;
		vm.dataLoading = true;
		vm.loadingError = '';
		vm.saving = false;
		vm.model = {};
		vm.options = {formState: {}};
		vm.errorMsg = "";
		vm.deleteContactHandler = {showDialog: false};
		vm.submitConfirmHandler = {showDialog: false};
		vm.submitInfoHandler = {showDialog: false};
		vm.actions = [];
		sqfService.registerSummaryHandler(vm);

		vm.cancel = cancel;
		vm.editSitePI = editSitePI;
		vm.editRoles = editRoles;
		vm.showView = showView;
		vm.editContact = editContact;
		vm.addContact = addContact;
		vm.submitSqf = submitSqf;
		vm.editSiteInfo = editSiteInfo;
		vm.deleteContact = deleteContact;
		vm.hasAdditionalSiteInfo = hasAdditionalSiteInfo;

		if ($stateParams.sqfId > 0) {
			showView($stateParams.sqfId);
		}

		function clear() {
			vm.sqfId = 0;
			vm.IsEditMode = vm.sqfId > 0;
			vm.dataLoading = true;
			vm.saving = false;
			vm.loadingError = '';
			vm.model = {};
			vm.options = {formState: {}};
			vm.errorMsg = "";
		}

		function editSitePI() {
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.siteInfo);
			sqfService.showSitePIWizard(vm.sqfId, vm.name);
		}

		function editRoles() {
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.roles);
			sqfService.showRolesView(vm.sqfId);
		}

		function editSiteInfo() {
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.additionalSiteInfo);
			sqfService.showSiteInfoView(vm.sqfId);
		}

		function cancel() {
			$scope.shared.closeWizard();
		}

		function editContact(contact) {
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.contacts);
			sqfService.showContactWizard(contact.sqfId, contact.contactId, vm.model.contacts);
		}

		function addContact() {
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.contacts);
			sqfService.showContactWizard(vm.model.sqfId, 0, vm.model.contacts);
		}

		function hasAdditionalSiteInfo() {
			return vm.options.formState.config.hasAdditionalSiteInfo &&
				(vm.options.formState.config.showTransmissionInfo
				 || vm.options.formState.config.showSiteStartupInfo
				 || vm.options.formState.config.showSiteDetails);
		}

		function setActions() {
			vm.actions.length = 0;
			if (vm.options.formState.config && vm.options.formState.config.hasSubmitted !== true) {
				vm.actions.push({displayName: $scope.shared.localized.buttons.submitSqf, action: submitSqf,
								 isPrimary: true});
				vm.actions.push({displayName: $scope.shared.localized.buttons.saveClose, action: cancel, cssClass: 'btn-danger'});
			}
			$scope.shared.handleHide = $scope.shared.closeWizard;
			updateActions();
			$scope.shared.setActions(vm.actions);
		}

		function updateActions() {
			if (vm.actions.length > 1) {
				vm.actions[0].isDisabled = vm.dataLoading || vm.saving || !vm.options.formState.config.allowSubmit;
				vm.actions[1].isDisabled = vm.saving;
			}
		}

		function deleteContact(contact) {
			if (!contact.ispi && vm.options.formState.config.allowEdit) {
				vm.deleteContactHandler = dialogService.createConfirmHandler();
				vm.deleteContactHandler.show().then(function (result) {
					if (result === true) {
						sqfService.deleteContact(vm.sqfId, contact.contactId)
							.then(function () {
								vm.deleteContactHandler.hide();
								showView(vm.sqfId);
							}, function (error) {
								vm.deleteContactHandler.showErrorMessages([error.data.message || error.data]);
							});
					}
				});
			}
		}

		function submitSqf() {
			if (vm.options.formState.config.allowSubmit) {
				vm.submitConfirmHandler = dialogService.createConfirmHandler();
				vm.submitConfirmHandler.show().then(function (result) {
					if (result === true) {
						vm.options.formState.config.allowSubmit = false;
						vm.submitConfirmHandler.hide();
						$scope.shared.showGlobalLoading();
						sqfService.submitSqf(vm.sqfId)
							.then(function (response) {
								updateActions();
								vm.submittedSqfTxt = supplant(appI18n.translateImmediate('sqf.messages.submittedSqf'),
																							[vm.model.siteId.siteId]);
								notificationService.showSuccess(vm.submittedSqfTxt);
								$scope.shared.hideGlobalLoading();
								$scope.shared.closeWizard();
								$state.go(appConfig.routes.sqfListSaved);
							}, function (error) {
								vm.submitConfirmHandler.showErrorMessages([error.data.message || error.data]);
								$scope.shared.hideGlobalLoading();
								vm.options.formState.config.allowSubmit = true;
								updateActions();
							});
					}
				});
			}
		}

		function showView(sqfId) {
			clear();
			$scope.shared.newSqf = false;
			vm.sqfId = sqfId;
			setActions();
			return sqfService.getSqfSummary(vm.sqfId)
				.then(function (response) {
					vm.model = response.data.model;
					vm.options.formState.config = response.data.config;
					// if the Additional Site Info step is only visible after selection, we need
					// to use the flag of the sqf entry to decide if the step is visible or not.
					if ($scope.shared.hasAdditionalSiteInfoAfterSelection) {
						$scope.shared.hasAdditionalSiteInfo = response.data.config.hasAdditionalSiteInfo;
					}
					vm.dataLoading = false;
					setActions();
				}, function (error) {
					vm.loadingError = error.data.message || error.data;
				});
		}
	}
})();
