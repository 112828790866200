export default function run($log, analyticsService, $rootScope) {
	'ngInject';
	$log = $log.getInstance('analytics', 'color:green');
	$log.debug('loaded');

	const stateChangeSuccessCallback = $rootScope.$on('$stateChangeSuccess', () => {
		analyticsService.trackPage();
	});

	$rootScope.$on('$destroy', stateChangeSuccessCallback);
}
