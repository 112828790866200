/*
 * QueryGuideline Controller
 * */
class QueryGuidelineController {

	constructor($log) {
		'ngInject';
		this.$log = $log;
		this.gueryGuidelines = null;
	}

	$onInit() {
		this.$log = this.$log.getInstance('QueryGuideline');
		this.$log.debug('loaded');
	}
  
	set item(value) {
		this.queryGuidelines = value ? value.match(/[^\r\n]+/g) : [];
	}
}

export default QueryGuidelineController;
