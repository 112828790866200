import templateUrl from './noData.html';
import controller from './noDataRow.controller';

export default () => {
	return {
		scope: {
			rows: '<',
			headers: '<',
			msg: '@?'
		},
		require: {
			table: '^tableWrap'
		},
		controller,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		replace: true
	};
};

