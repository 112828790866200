class SubjectInfoPopupController {
	constructor($log, $q, loadingIndicatorService, clinicalDataService, eproService, configurationService,
				appConfig, appI18n) {
		'ngInject';
		this.$log = $log;
		this.$q = $q;
		this.loadingIndicatorService = loadingIndicatorService;
		this.clinicalDataService = clinicalDataService;
		this.eproService = eproService;
		this.configurationService = configurationService;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
	}

	$onInit() {
		this.$log = this.$log.getInstance('CountriesInfoPopupController');
		this.$log.debug('loaded');
		this.setApi();
	}

	setApi() {
		const self = this;

		this.$API = {
			open: (siteId, subjectId, subjectKey, eproSubjectId) => {
				this.appI18n.translate([
					'clinicaldata.subject.subject-info.title',
					'clinicaldata.subject.subject-info.no-device',
					'app.common.subjectKey'
				])
					.then((translations) => {
						self.translations = translations;
						self.fetchSubjectInfo(siteId, subjectId, subjectKey, eproSubjectId);
					});
			},
			close: () => {
				self.open = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({ $API: this.$API });
		}
	}

	cancelAction() {
		this.$API.close();
	}

	fetchSubjectInfo(siteId, subjectId, subjectKey, eproSubjectId) {
		this.$log.debug(`Load Subject Info. SubjectId: ${subjectId}`);

		this.loadingIndicatorService.show();

		this.configurationService.getStudySettings().then((data) => {
			const hasEcoaHandheld = data.hasEcoaHandheld;

			let eproServiceProm = this.$q.resolve();

			if (hasEcoaHandheld) {
				eproServiceProm = this.eproService.getQrCode(siteId, eproSubjectId)
					.then((response) => {
						if (response.data) {
							const obj = angular.fromJson(response.data);

							this.qrCode = {
								url: obj.study,
								code: obj.setupcode,
								language: obj.language,
								text: response.data
							};
						}
					});
			}

			const clinicalDataServiceProm = this.clinicalDataService.getSubjectInfo(siteId, subjectId).then((resp) => {
				this.subjectInfo = resp.data;
				this.assignDataPoints(this.subjectInfo.enrollmentGraph);
				this.modalTitle = `${this.translations['clinicaldata.subject.subject-info.title']} - ` +
					`${this.translations['app.common.subjectKey']}: ${subjectKey}`;

			});

			this.$q.all([eproServiceProm, clinicalDataServiceProm]).then(() => {
				this.open = true;
				this.loadingIndicatorService.hide();
			}, () => {
				//TODO: Show notification MPORT-3604
				this.loadingIndicatorService.hide();
				this.open = false;
			});
		});
	}

	assignDataPoints(graph = {}) {
		if (graph.enrolled) {
			this.enrollmentColor = this.appConfig.enrollmentColors.info.enrolled;
		}
		if (graph.included) {
			this.includedColor = this.appConfig.enrollmentColors.info.included;
		}
		if (graph.created) {
			this.createdColor = this.appConfig.enrollmentColors.info.created;
		}
		if (graph.randomized) {
			this.randomizedColor = this.appConfig.enrollmentColors.info.randomized;
		}
		if (graph.earlyTerminated) {
			this.terminatedColor = this.appConfig.enrollmentColors.info.terminated;
		}
		if (graph.excluded) {
			this.excludedColor = this.appConfig.enrollmentColors.info.excluded;
		}
		if (graph.finished) {
			this.finishedColor = this.appConfig.enrollmentColors.info.finished;
		}
		if (graph.treatmentPeriodCompleted) {
			this.treatmentColor = this.appConfig.enrollmentColors.info.treatment;
		}
		if (graph.activated) {
			this.activatedColor = this.appConfig.enrollmentColors.info.activated;
		}
	}

	getContactFullAddress(contact) {
		let address = '';

		if (contact.contactAddress) {
			address += `${contact.contactAddress} `;
		}
		if (contact.contactCity) {
			address += `, ${contact.contactCity}`;
		}
		if (contact.contactZipCode) {
			address += `, ${contact.contactZipCode}`;
		}
		if (contact.usStateAbbreviation) {
			address += `, ${contact.usStateAbbreviation}`;
		} else if (contact.contactForeignProvince) {
			address += `, ${contact.contactForeignProvince}`;
		}
		return address;
	}

	/* eslint-disable complexity */
	hasEnrollmentData() {
		if (this.subjectInfo && !this.isLoading && this.subjectInfo.enrollmentGraph && (
			this.subjectInfo.enrollmentGraph.created || this.subjectInfo.enrollmentGraph.enrolled ||
			this.subjectInfo.enrollmentGraph.included || this.subjectInfo.enrollmentGraph.randomized ||
			this.subjectInfo.enrollmentGraph.earlyTerminated || this.subjectInfo.enrollmentGraph.excluded ||
			this.subjectInfo.enrollmentGraph.finished || this.subjectInfo.enrollmentGraph.treatmentPeriodCompleted ||
			this.subjectInfo.enrollmentGraph.activated)) {
			const response =
				(this.subjectInfo.enrollmentGraph.created && this.subjectInfo.enrollmentGraph.created.length > 0) ||
				(this.subjectInfo.enrollmentGraph.enrolled && this.subjectInfo.enrollmentGraph.enrolled.length > 0) ||
				(this.subjectInfo.enrollmentGraph.included && this.subjectInfo.enrollmentGraph.included.length > 0) ||
				(this.subjectInfo.enrollmentGraph.randomized &&
				this.subjectInfo.enrollmentGraph.randomized.length > 0) ||
				(this.subjectInfo.enrollmentGraph.earlyTerminated &&
				this.subjectInfo.enrollmentGraph.earlyTerminated.length > 0) ||
				(this.subjectInfo.enrollmentGraph.excluded &&
				this.subjectInfo.enrollmentGraph.excluded.length > 0) ||
				(this.subjectInfo.enrollmentGraph.finished &&
				this.subjectInfo.enrollmentGraph.finished.length > 0) ||
				(this.subjectInfo.enrollmentGraph.treatmentPeriodCompleted &&
				this.subjectInfo.enrollmentGraph.treatmentPeriodCompleted.length > 0) ||
				(this.subjectInfo.enrollmentGraph.activated && this.subjectInfo.enrollmentGraph.activated.length > 0);

			return response;
		} else {
			return false;
		}
	}
	/* eslint-enable complexity */
}

export default SubjectInfoPopupController;
