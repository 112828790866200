(function() {

	'use strict';
	angular
		.module("portalApp")
		.service("studyPermissionsService", StudyPermissionsService);

	/**
	 * @ngInject
	 */
	function StudyPermissionsService($log, $http, appConfig, $state, appSession) {
		$log = $log.getInstance("StudyPermissionsService");
		$log.debug("StudyPermissionsService loaded");

		var studyPermissionsService = {
			getStudyUsers,
			getSiteAdministrators,
			getCountryUsers,
			getSiteUsers,
			getStudyPermissionsConfig,
			getUserAccessRoles,
			registerCreateSiteUserHandler,
			showCreateSiteUser,
			getContactConfig,
			getSponsorConfig,
			getSiteRoles,
			createContact,
			createSponsorUser,
			isCreateSiteUserHandlerRegistered,
			editUserRoles,
			deleteUser,
			getUser,
			goToStudyUserDelete,
			goToStudyUserEdit,
			goToCountryUserDelete,
			goToCountryUserEdit,
			goToSiteUserDelete,
			goToSiteUserEdit,
			goToSiteAdminDelete,
			goToSiteAdminEdit,
			checkSiteUserAlreadyExists,
			checkSponsorUserAlreadyExists,
			registerCreateSponsorUserHandler,
			showCreateSponsorUser,
			isCreateSponsorUserHandlerRegistered
		};

		var createSiteUserHandler;
		var createSponsorUserHandler;

		return studyPermissionsService;

		function getStudyParams() {
			const currentStudy = appSession.getStudy();
			const params = { studyId: currentStudy.id, studyModeId: currentStudy.studyModeId };
			return params;
		}

		function registerCreateSiteUserHandler(handler){
			createSiteUserHandler = handler;
		}
		function registerCreateSponsorUserHandler(handler) {
			createSponsorUserHandler = handler;
		}
		function isCreateSiteUserHandlerRegistered(){
			return !!createSiteUserHandler;
		}

		function isCreateSponsorUserHandlerRegistered() {
			return !!createSponsorUserHandler;
		}

		function showCreateSiteUser() {
			return createSiteUserHandler.activate();
		}

		function showCreateSponsorUser() {
			return createSponsorUserHandler.activate();
		}


		function getStudyUsers(options) {
			const params = getStudyParams();
			var url = appConfig.apiUrls.studyPermissions.getStudyUsers.supplant(params);
			if (options) {
				url += "&" + options;
			}
			$log.debug(url);
			return $http.get(url);
		}

		function getSiteAdministrators(options) {
			const params = getStudyParams();
			var url = appConfig.apiUrls.studyPermissions.getSiteAdmins.supplant(params);
			if (options) {
				url += "&" + options;
			}
			$log.debug(url);
			return $http.get(url);
		}

		function getCountryUsers(options) {
			const params = getStudyParams();
			var url = appConfig.apiUrls.studyPermissions.getCountryUsers.supplant(params);
			if (options) {
				url += "&" + options;
			}
			$log.debug(url);
			return $http.get(url);
		}

		function getSiteUsers(options) {
			const params = getStudyParams();
			var url = appConfig.apiUrls.studyPermissions.getSiteUsers.supplant(params);
			if (options) {
				url += "&" + options;
			}
			$log.debug(url);
			return $http.get(url);
		}

		function getStudyPermissionsConfig() {
			const params = getStudyParams();
			var url = appConfig.apiUrls.studyPermissions.getStudyPermissionsConfig.supplant(params);
			$log.debug(url);
			return $http.get(url);
		}

		function getUserAccessRoles(userPermissions){
			const params = getStudyParams();
			var url = appConfig.apiUrls.studyPermissions.getUserAccessRoles.supplant(params);
			$log.debug(url);
			return $http.post(url, userPermissions);
		}

		function editUserRoles(payload) {
			const params = getStudyParams();
			const url = appConfig.apiUrls.studyPermissions.editUserRoles.supplant(params);

			$log.debug(url);
			return $http.post(url, payload);
		}

		function deleteUser(payload) {
			const params = getStudyParams();
			const url = appConfig.apiUrls.studyPermissions.deleteAllUserRoles.supplant(params);

			$log.debug(url);
			return $http.post(url, payload);
		}

		function getContactConfig() {
			const params = getStudyParams();
			var url = appConfig.apiUrls.studyPermissions.getContactConfig.supplant(params);
			$log.debug(url);
			return $http.get(url);
		}

		function getSponsorConfig() {
			const params = getStudyParams();
			var url = appConfig.apiUrls.studyPermissions.getSponsorConfig.supplant(params);
			$log.debug(url);
			return $http.get(url);
		}

		/*
		* this method should have been called getSiteRolesIds, because it returns array of ids
		* */
		function getSiteRoles(siteId) {
			const params = getStudyParams();
			params.siteId = siteId;
			var url = appConfig.apiUrls.studyPermissions.getSiteRoles.supplant(params);
			$log.debug(url);
			return $http.get(url);
		}

		function getUser(siteId, userId, isSiteAdmin, countryId) {
			const params = getStudyParams();

			params.siteId = siteId || '';
			params.userId = userId || '';
			params.isSiteAdmin = (isSiteAdmin) ? 'true' : 'false';
			params.countryId = countryId || '';
			const url = appConfig.apiUrls.studyPermissions.getUser.supplant(params);

			$log.debug(url);
			return $http.get(url).then((resp) => {
				return resp.data;
			});
		}

		// study user
		function goToStudyUserDelete(userId) {
			$state.go(appConfig.routes.studyUsersDelete, { userId });
		}

		function goToStudyUserEdit(userId) {
			$state.go(appConfig.routes.studyUsersEdit, { userId });
		}

		// country user
		function goToCountryUserDelete(countryId, userId) {
			$state.go(appConfig.routes.countryUsersDelete, { countryId, userId });
		}

		function goToCountryUserEdit(countryId, userId) {
			$state.go(appConfig.routes.countryUsersEdit, { countryId, userId });
		}

		// site user
		function goToSiteUserDelete(siteId, userId) {
			$state.go(appConfig.routes.siteUsersDelete, { siteId, userId });
		}

		function goToSiteUserEdit(siteId, userId) {
			$state.go(appConfig.routes.siteUsersEdit, { siteId, userId });
		}

		// admins
		function goToSiteAdminDelete(countryId, siteId, userId) {
			$state.go(appConfig.routes.siteAdminsDelete, { countryId, siteId, userId });
		}

		function goToSiteAdminEdit(countryId, siteId, userId) {
			$state.go(appConfig.routes.siteAdminsEdit, { countryId, siteId, userId });
		}

		function createContact(contactData){
			const params = getStudyParams();
			var url = appConfig.apiUrls.studyPermissions.createContact.supplant(params);
			$log.debug(url);
			return $http.post(url, contactData);
		}

		function createSponsorUser(sponsorUserData) {
			const params = getStudyParams();
			var url = appConfig.apiUrls.studyPermissions.createSponsorUser.supplant(params);
			$log.debug(url);
			return $http.post(url, sponsorUserData);
		}

		function checkSiteUserAlreadyExists(siteId, email) {
			const params = getStudyParams();

			params.siteId = siteId;
			params.email = email;
			const url = appConfig.apiUrls.studyPermissions.checkSiteUserAlreadyExists.supplant(params);

			$log.debug(url);
			return $http.get(url).then((resp) => {
				return resp.data;
			});
		}

		function checkSponsorUserAlreadyExists(email) {
			const params = getStudyParams();

			params.email = email;
			const url = appConfig.apiUrls.studyPermissions.checkSponsorUserAlreadyExists.supplant(params);

			$log.debug(url);
			return $http.get(url).then((resp) => {
				return resp.data;
			});
		}
	}
})();
	
