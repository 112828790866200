/*
 * enrollmentGraphWidgetController
 * */

class EnrollmentGraphWidgetController {
	constructor($log, appConfig, dashboardService, appI18n) {
		'ngInject';
		this.$log = $log;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.dashboardService = dashboardService;
		this.dataIsAvailable = true;
	}

	$onInit() {
		this.$log = this.$log.getInstance('EnrollmentGraphWidgetController');
		this.$log.debug('loaded');
		this.getEnrollmentItems(this.config);
		this.getEnrollmentGraphDetails();
	}

	getEnrollmentItems(enrollment) {
		if (enrollment.additionalInfo && enrollment.additionalInfo.length > 0) {
			const self = this;
			const infos = enrollment.additionalInfo.split('~~~');

			if (infos.length === 2) {
				const info = infos[1].split(',');

				info.forEach((status) => {
					switch (status.trim()) {
						case 'Created':
							self.createdColor = self.appConfig.enrollmentColors.widget.created;
							self.assignedCreatedColor = self.appConfig.enrollmentColors.widget.assignedCreated;
							break;
						case 'Enrolled':
							self.enrollmentColor = self.appConfig.enrollmentColors.widget.enrolled;
							self.assignedEnrollmentColor = self.appConfig.enrollmentColors.widget.assignedEnrolled;
							break;
						case 'Included':
							self.includedColor = self.appConfig.enrollmentColors.widget.included;
							self.assignedIncludedColor = self.appConfig.enrollmentColors.widget.assignedIncluded;
							break;
						case 'Randomized':
							self.randomizedColor = self.appConfig.enrollmentColors.widget.randomized;
							self.assignedRandomizedColor = self.appConfig.enrollmentColors.widget.assignedRandomized;
							break;
						case 'EarlyTerminated':
							self.terminatedColor = self.appConfig.enrollmentColors.widget.terminated;
							self.assignedTerminatedColor = self.appConfig.enrollmentColors.widget.assignedTerminated;
							break;
						case 'Excluded':
							self.excludedColor = self.appConfig.enrollmentColors.widget.excluded;
							self.assignedExcludedColor = self.appConfig.enrollmentColors.widget.assignedExcluded;
							break;
						case 'Finished':
							self.finishedColor = self.appConfig.enrollmentColors.widget.finished;
							self.assignedFinishedColor = self.appConfig.enrollmentColors.widget.assignedFinished;
							break;
						case 'TreatmentPeriodCompleted':
							self.treatmentColor = self.appConfig.enrollmentColors.widget.treatment;
							self.assignedTreatmentColor = self.appConfig.enrollmentColors.widget.assignedTreatment;
							break;
					}
				});
			}
		}
		this.activatedColor = this.appConfig.enrollmentColors.widget.activated;
		this.assignedActivatedColor = this.appConfig.enrollmentColors.widget.assignedActivated;
	}

	getEnrollmentGraphDetails() {
		this.isLoading = true;
		this.dashboardService.getEnrollmentData().then((data) => {
			this.dataIsAvailable = this.hasEnrollmentData(data.allEnrollmentData);
			if (this.dataIsAvailable) {
				this.enrollmentData = data;
			}
		}, (error) => {
			this.$log.error(`error in getting enrollment data: ${error}`);
			this.enrollmentError = this.appI18n.translateImmediate('dashboard.loading-error');
		}).finally(() => {
			this.isLoading = false;
		});
	}

	hasEnrollmentData(graphData) {

		if (!graphData) { return false; }

		return graphData.created && graphData.created.length > 0 ||
			graphData.enrolled && graphData.enrolled.length > 0 ||
			graphData.included && graphData.included.length > 0 ||
			graphData.randomized && graphData.randomized.length > 0 ||
			graphData.earlyTerminated && graphData.earlyTerminated.length > 0 ||
			graphData.excluded && graphData.excluded.length > 0 ||
			graphData.finished && graphData.finished.length > 0 ||
			graphData.treatmentPeriodCompleted && graphData.treatmentPeriodCompleted.length > 0 ||
			graphData.activated && graphData.activated.length > 0;
	}
}

export default EnrollmentGraphWidgetController;
