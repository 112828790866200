import checker from './../../common/utils/valueChecker/valueChecker.service';
import tplUrl from './selectInput.html';
export default () => {
	return {
		link,
		restrict: 'E',
		scope: {
			error: '=?',
			id: '@',
			label: '@',
			nullOption: '@',
			onChange: '&',
			options: '=',
			readOnly: '=?',
			required: '=?',
			selectedItem: '=?',
			selectedId: '=?',
			inputCssClass: '@?',
			hasRequiredError: '=?',
			idField: '@?',
			nameField: '@?'
		},
		templateUrl: tplUrl
	};
	function link(scope, element, attr) {
		'ngInject';
		scope.idField = attr.idField || 'id';
		scope.nameField = attr.nameField || 'name';
		if (!checker.isUndefinedOrNullOrEmpty(scope.selectedId) && scope.options) {
			const filteredItems = scope.options.filter(function filterOptions(item) {
				return item[scope.idField] === scope.selectedId;
			});

			if (filteredItems.length > 0) {
				scope.selectedItem = filteredItems[0];
			} else {
				scope.selectedItem = null;
			}
		} else if (scope.selectedItem && scope.options) {
			scope.selectedId = scope.selectedItem[scope.idField];
		}
		scope.hasRequiredError = !!scope.required && scope.selectedItem && !hasSelectedValue();
		scope.expression = attr.expression ||
			'option as option.{nameField} for option in options track by option.{idField}'.supplant(scope);
		element.on('change', () => {
			if (hasSelectedValue()) {
				scope.hasRequiredError = false;
				scope.selectedId = scope.selectedItem[scope.idField];
			} else {
				scope.hasRequiredError = scope.required;
				scope.selectedId = null;
			}
			if (scope.onChange && angular.isFunction(scope.onChange)) {
				scope.$apply(() => {
					scope.onChange();
				});
			}
		});
		scope.blurUpdate = function () {
			scope.hasRequiredError = !!scope.required && !hasSelectedValue();
		};

		function hasSelectedValue() {
			return scope.selectedItem && !checker.isUndefinedOrNullOrEmpty(scope.selectedItem[scope.idField]);
		}
	}
};
