/*
 * esDropdownModule
 * */

import esDropdownDirective from './esDropdown.directive';
const esDropdownModule = angular.module('app.common.esDropdown', [])
	.directive('esDropdown', esDropdownDirective)
	.name;

export default esDropdownModule;
