/*
 * editContactDataPopupComponent
 * */

import templateUrl from './editContactDataPopup.html';
import controller from './editContactDataPopup.controller';
import './editContactDataPopup.scss';

const editContactDataPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default editContactDataPopupComponent;
