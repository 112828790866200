/*
 * tabsComponent
 * */

import templateUrl from './tabs.html';
import controller from './tabs.controller';
import './tabs.scss';

const tabsComponent = {
	bindings: {
		uiSrefActiveMode: '<'
	},
	templateUrl,
	controller,
	transclude: true
};

export default tabsComponent;
