/*
 * loadingRowController
 * */

class LoadingRowController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('LoadingRowController');
		this.$log.debug('loaded');
	}
}

export default LoadingRowController;
