var _ = require('lodash');

angular.module('portalApp').controller('pftSubmissionInfoCtrl', [
	'$log',
	'$q',
	'appSession',
	'configurationService',
	'clinicalDataService',
	'clinicalDataEntryApiService',
	'clinicalDataEntryDataService',
	'$scope',
	'userService',
	'appI18n',
	'appConfig',
	'$stateParams',
	'clinicalDataEntryService', 'ertStepsWizardService', '$timeout',
	function PftSubmissionInfoCtrl($log,
								   $q,
								   appSession,
								   configurationService,
								   clinicalDataService,
								   clinicalDataEntryApiService,
								   clinicalDataEntryDataService,
								   $scope,
								   userService,
								   appI18n,
								   appConfig,
								   $stateParams,
								   clinicalDataEntryService, ertStepsWizardService,
								   $timeout) {
		var vm = this;


		var currentStudy = appSession.getStudy();
		vm.data = clinicalDataEntryDataService.getDataModel();// data that will be sent to the server

		vm.subjectDemographicsFields = [];

		if (!$stateParams.signKey) {
			vm.data.currentStudy = currentStudy;// TODO: remove this
			vm.data.selectedStudy = {};
			vm.data.subjectDetails = {};
			vm.data.demographics = {};
			vm.data.subjectDetails.selectedSubject = null;
			vm.data.selectedStudyModeId = currentStudy.studyModeId;
		}

		vm.isValid = isValid;
		vm.dynamicDemoFields = [];

		if ($scope.wizardStep) {
			$scope.wizardStep.isValid = isValid;
		}

		appI18n.translate(['cde-pft.titles.selectSubject', 'cde-pft.titles.selectOrCreateSubject',
						'cde-pft.titles.selectSite', 'cde-pft.titles.selectStudy',
						'cde-pft.errors.numericAgeWithUnit', 'app.messages.requiredField'])
			.then((translations) => {
				vm.translations = {};
				vm.translations.numericAgeWithUnit = translations['cde-pft.errors.numericAgeWithUnit'];
				vm.translations.createSubjectText = translations['cde-pft.titles.selectOrCreateSubject'];
				vm.translations.selectSubjectText = translations['cde-pft.titles.selectSubject'];
				vm.translations.selectSiteText = translations['cde-pft.titles.selectSite'];
				vm.translations.selectStudyText = translations['cde-pft.titles.selectStudy'];
				vm.translations.requiredField = translations['app.messages.requiredField'];
				init();
			});


		function init() {
			// options passed to formly
			vm.cdeMode = clinicalDataEntryService.getCdeMode();
			vm.options = {
				formState: {
					userNameInput: {
						disabled: true
					},
					userEmailInput: {
						disabled: true
					},
					studySelection: {
						disabled: true
					},
					siteSelection: {
						disabled: true,
						options: []
					},
					subjectSelection: {
						disabled: true,
						options: []
					},
					subjectDemographics: {
						hidden: false,
						options: []
					},
					dataLoading: 0
				}
			};
			// fields passed to formly
			vm.fields = [
				{
					wrapper: 'fieldGroupWrapper',
					templateOptions: {
						title: 'Submission Details'
					},
					fieldGroup: [
						{
							type: 'esInput',
							key: 'userLastName',
							wrapper: 'esColumnControlWrap',
							templateOptions: {
								type: 'text',
								label: 'From Last Name',
								placeholder: '',
								required: true,
								id: "userLastNameInput"
							},
							ngModelElAttrs: {
								maxlength: '30'
							},
							hideExpression: "formState.userNameInput.hidden",
							expressionProperties: {
								"templateOptions.disabled": "formState.userNameInput.disabled"
							}
						},
						{
							type: 'esInput',
							key: 'userFirstName',
							wrapper: 'esColumnControlWrap',
							templateOptions: {
								type: 'text',
								label: 'From First Name',
								placeholder: '',
								required: true,
								id: "userFirstNameInput"
							},
							ngModelElAttrs: {
								maxlength: '30'
							},
							hideExpression: "formState.userNameInput.hidden",
							expressionProperties: {
								"templateOptions.disabled": "formState.userNameInput.disabled"
							}
						},
						{
							type: 'esInput',
							key: 'userEmail',
							wrapper: 'esColumnControlWrap',
							templateOptions: {
								type: 'email',
								label: 'From Email',
								placeholder: '',
								required: true,
								id: "userEmailInput"
							},
							ngModelElAttrs: {
								maxlength: '150'
							},
							hideExpression: "formState.userEmailInput.hidden",
							expressionProperties: {
								"templateOptions.disabled": "formState.userEmailInput.disabled"
							}
						},
						{
							type: 'esSelect',
							key: 'selectedStudy',
							wrapper: 'esColumnControlWrap',
							templateOptions: {
								fieldId: 'studySelect',
								label: 'Study',
								required: true,
								placeholder: vm.translations.selectStudyText,
								options: [],
								id: "selectStudySelect",
								onChange: onStudyChange
							},
							validators: {
								required: {
									expression: function ($viewValue, $modelValue) {
										var value = $modelValue || $viewValue;
										var validValue = value && value.protocolId;
										return !!validValue;
									}
								}
							},
							expressionProperties: {
								"templateOptions.disabled": "formState.dataLoading > 0 || formState.studySelection.disabled"
							},
							controller: /* @ngInject */ function ($scope) {

								var cdeMode = clinicalDataEntryService.getCdeMode();
								$scope.to.loading = $q.all([

									clinicalDataEntryApiService.getStudiesForUser(currentStudy.id,
																				  currentStudy.studyModeId,
																				  cdeMode),
									userService.getUserIsInternal()]).then(
										function onSuccess(responses) {
											var studies = responses[0].data;
											var isInternalUser = responses[1];
											vm.data.userIsInternal = isInternalUser;
											$scope.to.options = matchSelectValueToModel(studies, 'protocolName');
											// preselect study when only one study available
											if (studies && studies.length === 1 && !$stateParams.signKey) {
												vm.data.selectedStudy = studies[0];
												vm.options.formState.studySelection.disabled = true;
												// manually trigger on change
												onStudyChange();
											} else {
												vm.options.formState.studySelection.disabled = false;
											}

											if (isInternalUser === true) {
												handleInternalUserUseCase();
											} else {
												handleExternalUserUseCase();
											}
											return responses;
										}, function onError() {

										}).finally(() => {
											$log.debug('finally in getstudies');
											clinicalDataEntryService.initialStudiesLoaded();
										});
							}
						},
						{
							type: 'esSelect',
							key: 'selectedSite',
							wrapper: 'esColumnControlWrap',
							templateOptions: {
								fieldId: 'siteSelect',
								label: 'Site',
								required: true,
								placeholder: vm.translations.selectSiteText,
								options: [],
								id: "siteSelect",
								onChange: onSiteChange
							},
							expressionProperties: {
								"templateOptions.disabled": "formState.dataLoading > 0 || formState.siteSelection.disabled",
								"templateOptions.options": "formState.siteSelection.options"
							},
							validators: {
								required: {
									expression: function ($viewValue, $modelValue) {
										var value = $modelValue || $viewValue;
										var validValue = value && value.siteId;
										return !!validValue;
									}
								}
							}
						},
					]
				}];
			vm.subjectDetails = [
				{
					key: 'subjectDetails',
					wrapper: 'fieldGroupWrapper',
					templateOptions: {
						title: 'Subject Details'
					},
					fieldGroup: [
						{
							type: 'esSelect',
							key: 'selectedSubject',
							wrapper: 'esColumnControlWrap',
							templateOptions: {
								fieldId: 'subjectSelect',
								label: 'Subject',
								required: true,
								placeholder: vm.translations.createSubjectText,
								options: [],
								id: 'subjectSelect',
								onChange: onSubjectChange
							},
							expressionProperties: {
								"templateOptions.disabled": "formState.dataLoading > 0 || formState.subjectSelection.disabled",
								"templateOptions.options": "formState.subjectSelection.options",
								'templateOptions.placeholder': 'formState.subjectSelection.placeholder'
							},
							validators: {
								required: {
									expression: function ($viewValue, $modelValue) {
										var value = $modelValue || $viewValue;
										var validValue = value && value.subjectId || value && value.subjectKey;
										return !!validValue;
									}
								}
							}
						}
					]
				}
			];

			vm.subjectDemographics = [
				{
					key: 'demographics',
					wrapper: 'fieldGroupWrapper',
					templateOptions: {
						title: null
					},
					hideExpression: "formState.subjectDemographics.hidden",
					fieldGroup: vm.subjectDemographicsFields

				}
			];

			$scope.$on('beforeNavigateToStep1', function (event, args) {
				formatData(vm.data);
				vm.data.siteSubjectOptions = {
					sites: vm.options.formState.siteSelection.options,
					subjects: vm.options.formState.subjectSelection.options,
					demos: vm.data.demographics,
					studyConfig: vm.studyConfig
				};
			});
		}

		function isPaperEcg() {
			return vm.cdeMode === appConfig.cdeMode.Ecg;
		}

		function isValid() {
			var valid = isFieldsFormValid() && isSubjectDetailsFormValid() &&
				isSubjectDemographicsFormValid() && !isSomeRequestPending();
			if (!valid) {
				vm.fieldsForm.showValidation = true;
				vm.subjectDetailsForm.showValidation = true;
				vm.subjectDemographicsForm.showValidation = true;
				if (vm.ageWithUnitSubForm) {
					vm.ageWithUnitSubForm.templateOptions.showValidation = true;
				}
			}
			return valid;
		}

		function resetSubjectDependentData(isRestore) {
			var visitEventHandler = clinicalDataEntryService.getVisitEventHandler();
			if (visitEventHandler && visitEventHandler.cleanVisitList && !isRestore) {
				visitEventHandler.cleanVisitList();
				visitEventHandler.cleanEventList();
				visitEventHandler.cleanFirstEventDate();
			}
		}

		function onSubjectRestore() {
			changeSubject();
		}

		function onSubjectChange(isRestore) {
			cleanDemographics();
			resetSubjectDependentData(isRestore);
			changeSubject();

		}

		function getExistingDemographicValue(fieldId) {

			if (!vm.data.existingDemographics) {
				return null;
			}
			const demo = _.find(vm.data.existingDemographics, ['fieldId', fieldId]);
			if (demo) {
				if (demo.isDemographicHidden && demo.hasValue) {
					return "hidden";
				}
				return demo.demographic;
			}
			return null;
		}

		function setUpEditableDemographics(isExistingSubject, preselectedSubjectMode) {
			const cdeMode = clinicalDataEntryService.getCdeMode();

			clinicalDataEntryApiService.getSubjectDemographicsConfig(
				vm.data.selectedStudy.protocolId,
				vm.data.selectedStudyModeId, cdeMode).then(function onSuccess(response) {

					var data = response.data;
					var fields = data.fields;
					vm.options.formState.dataLoading--;
					vm.isLoadingDemographics = false;

					var hasDobField = hasDateOfBirth(fields);

					if (!isExistingSubject) {
						vm.subjectDemographicsFields.push({
							type: 'esSelect',
							key: 'subjectMode',
							wrapper: 'esColumnControlWrap',
							model: vm.data,
							templateOptions: {
								required: true,
								label: 'Subject Mode',
								labelProp: 'label',
								valueProp: 'value',
								id: 'subjectMode',
								options: [{value: 1, label: 'Study'}, {value: 2, label: 'Proficiency'}],
								onChange: () => applySubjectMode()
							},
							validators: {
								customValidation: {
									expression: function (viewValue, modelValue, scope) {
										var value = modelValue || viewValue;
										return !(value.value === 1 && vm.studyConfig.blockSubjectCreation);
									},
									message: '"' + appI18n.translateImmediate('cde-pft.errors.blockedSubjectCreationInSubjectMode') + '"'
								}
							}
						});
					}

					for (var x = 0; x < fields.length; x++) {

						if (isExistingSubject) {
							const existingValue = getExistingDemographicValue(fields[x].id);

							if (existingValue) {
								fields[x].demographic = existingValue == 'hidden' ? '' : existingValue;
								const formField = getReadOnlyFormField(fields[x]);
								vm.subjectDemographicsFields.push(formField);

								continue;
							}
						}

						if (fields[x].id === '2') {
							// date of birth
							if (fields[x].isPartialDob) {
								// partial date of birth (only year and month)
								vm.subjectDemographicsFields.push({
									type: 'esInput',
									key: 'demographic',
									wrapper: 'esColumnControlWrap',
									model: fields[x],
									templateOptions: {
										required: fields[x].isRequired || false,
										label: fields[x].label,
										disabled: false,
										id: 'demographic_' + fields[x].id,
										placeholder: 'MMM-YYYY/YYYY',
										validationPattern: fields[x].format,
									},
									validators: {
										customValidation: {
											expression: function (viewValue, modelValue, scope) {
												var value = modelValue || viewValue;
												var rexp = new RegExp(scope.to.validationPattern);
												return !value || rexp.test(value);
											},
											message: '"' + fields[x].formatHint + '"'
										},
										dobInPast: {
											expression: function (viewValue, modelValue, scope) {
												var value = modelValue || viewValue;

												var rexp = new RegExp(scope.to.validationPattern);
												if (!rexp.test(value)) return true;

												return dateIsInPast(value);
											}
										}
									}
								});
							} else {
								// full dob
								vm.subjectDemographicsFields.push({
									type: 'esInput',
									key: 'demographic',
									wrapper: 'esColumnControlWrap',
									model: fields[x],
									templateOptions: {
										required: fields[x].isRequired || false,
										label: fields[x].label,
										id: 'demographic_' + fields[x].id,
										placeholder: 'DD-MMM-YYYY',
										validationPattern: fields[x].format
									},
									validators: {
										customValidation: {
											expression: function (viewValue, modelValue, scope) {
												var value = modelValue || viewValue;
												var rexp = new RegExp(scope.to.validationPattern);
												return !value || rexp.test(value);
											},
											message: '"' + fields[x].formatHint + '"'
										},
										dobInPast: {
											expression: function (viewValue, modelValue, scope) {
												var value = modelValue || viewValue;

												var rexp = new RegExp(scope.to.validationPattern);
												if (!rexp.test(value)) return true;

												return dateIsInPast(value);
											}
										}
									}
								});
							}
							continue;
						}

						if (fields[x].id === '5') {
							// ethnicities
							vm.subjectDemographicsFields.push({
								type: 'esSelect',
								key: 'demographic',
								wrapper: 'esColumnControlWrap',
								model: fields[x],
								templateOptions: {
									required: fields[x].isRequired || false,
									options: getEthnicitiesOptions(data.ethnicities),
									label: fields[x].label,
									id: 'demographic_' + fields[x].id
								}
							});
							continue;
						}

						if (fields[x].id === '6') {
							// ethnicities
							vm.subjectDemographicsFields.push({
								type: 'esSelect',
								key: 'demographic',
								wrapper: 'esColumnControlWrap',
								model: fields[x],
								templateOptions: {
									required: fields[x].isRequired || false,
									options: matchSelectValueToModel([
										{name: 'Male'},
										{name: 'Female'}
									], 'name'),
									label: fields[x].label,
									id: 'demographic_' + fields[x].id
								}
							});
							continue;
						}

						// if age demographic
						if (fields[x].id === '12') {

							if (hasDobField) {
								// don't show the age demographics field
								fields[x].calculatedDemogr = true;
								fields[x].demographic = null;
								continue;
							}
							if (isPaperEcg()) {
								addAgeFieldWithUnits(fields[x]);
								continue;
							}
						}

						var field = {
							type: 'esInput',
							key: 'demographic',
							wrapper: 'esColumnControlWrap',
							model: fields[x],
							templateOptions: {
								required: fields[x].isRequired || false,
								type: 'text',
								label: fields[x].label,
								validationPattern: fields[x].format,
								id: 'demographic_' + fields[x].id
							},
							validators: {
								customValidation: {
									expression: function (viewValue, modelValue, scope) {
										var value = modelValue || viewValue;
										var rexp = new RegExp(scope.to.validationPattern);
										return !value || rexp.test(value);
									},
									message: '"' + fields[x].formatHint + '"'
								}
							}
						};

						if (fields[x].id === '3') {
							// additional validation for subjectid
							// a) site prefix
							// b) min max range
							var sbjIdField = fields[x];

							field.expressionProperties = {
								'templateOptions.disabled': 'formState.subjectIdInput.disabled',
								'templateOptions.required': '!formState.subjectIdInput.disabled',
								'templateOptions.placeholder': 'formState.subjectIdInput.placeholder'
							};

							if (sbjIdField.isSitePrefix) {
								var prefixValidator = {
									expression: function (viewValue, modelValue, scope) {
										if (isProficiency()) {
											return true;
										}

										if (!vm.data || !vm.data.selectedSite) {
											return true;
										}
										var value = modelValue || viewValue;
										if (!value) {
											return true;
										}

										var selectedSponsorSiteId = vm.data.selectedSite.sponsorSiteId;

										return value.substr(0, selectedSponsorSiteId.length) === selectedSponsorSiteId;
									},
									message: '"' + sbjIdField.label + ' must start with Site ID"'
								};

								field.validators.prefixValidator = prefixValidator;
							}

							if (sbjIdField.minimumValue || sbjIdField.maximumValue) {
								var rangeValidator = {
									expression: function (viewValue, modelValue, scope) {
										if (isProficiency()) {
											return true;
										}

										var value = modelValue || viewValue;

										if (!value) {
											return true;
										}

										var enteredValue = Number(value);

										var min = 0;
										if (sbjIdField.minimumValue) {
											min = Number(sbjIdField.minimumValue);
										}

										var max = Number.MAX_SAFE_INTEGER;
										if (sbjIdField.maximumValue) {
											max = Number(sbjIdField.maximumValue);
										}
										return enteredValue >= min && enteredValue <= max;
									},
									message: '"' + sbjIdField.label + ' must be between ' + sbjIdField.minimumValue + ' and ' + sbjIdField.maximumValue + '"'
								};

								field.validators.rangeValidator = rangeValidator;
							}
							var customValidation = {
								expression: function (viewValue, modelValue, scope) {
									if (isProficiency()) {
										return true;
									}

									var value = modelValue || viewValue;
									var rexp = new RegExp(scope.to.validationPattern);
									return !value || rexp.test(value);
								},
								message: '"' + fields[x].formatHint + '"'
							};

							var existsValidation = {
								expression: function (viewValue, modelValue, scope) {
									var value = modelValue || viewValue;
									if (!value) {
										return true;
									}
									if (isProficiency()) {
										return true;
									}

									var item = _.find(vm.subjectList, {subjectId1: value});
									return item === undefined ? true : false;
								},
								message: '"' + appI18n.translateImmediate('cde-pft.errors.subjectAlreadyExists') + '"'
							};
							field.validators.customValidation = customValidation;
							field.validators.existsValidation = existsValidation;
						} else {

							let currentField = fields[x];

							if ((currentField.minimumValue || currentField.maximumValue) &&
								!(+currentField.id === appConfig.demographics.height ||
								  +currentField.id === appConfig.demographics.weight )) {
								let rangeValidator2 = {
									expression: function (viewValue, modelValue, scope) {
										var value = modelValue || viewValue;

										if (!value) {
											return true;
										}

										// intentionally allow for 200cm to result in 200
										var enteredValue = parseFloat(value);

										var min = 0;
										if (currentField.minimumValue) {
											min = Number(currentField.minimumValue);
										}

										var max = Number.MAX_SAFE_INTEGER;
										if (currentField.maximumValue) {
											max = Number(currentField.maximumValue);
										}
										return enteredValue >= min && enteredValue <= max;
									},
									message: '"' + currentField.label +
										' must be between ' + currentField.minimumValue +
										' and ' + currentField.maximumValue + '"'
								};

								field.validators.rangeValidator = rangeValidator2;
							}

						}

						vm.subjectDemographicsFields.push(field);
					}
					vm.options.formState.subjectDemographics.hidden = false;

					if ($stateParams.signKey) {
						// restore demographic values
						for (var y = 0; y < fields.length; y++) {
							if (vm.data.demographics[y] && vm.data.demographics[y].demographic) {
								fields[y].demographic = vm.data.demographics[y].demographic;
								fields[y].demographicUnit = vm.data.demographics[y].demographicUnit;
								fields[y].demographicValue = vm.data.demographics[y].demographicValue;
							}
						}

						vm.data.demographics = fields;
						applySubjectMode(true);
					} else {
						// don't set demographic fields after signature redirect
						vm.data.demographics = fields;
						// no preselection after signature redirect
						if (preselectedSubjectMode == 1) {
							vm.data.subjectMode = {value: 1, label: 'Study'};
						} else {
							vm.data.subjectMode = {value: 2, label: 'Proficiency'};
						}
						if (!isExistingSubject) {
							applySubjectMode();
						}
					}
				}, function onError() {
					vm.options.formState.dataLoading--;
					vm.isLoadingDemographics = false;
				});
		}

		function addAgeFieldWithUnits(fieldConfig) {
			var units = [
				{value: appConfig.ageUnits.years},
				{value: appConfig.ageUnits.months},
				{value: appConfig.ageUnits.weeks},
				{value: appConfig.ageUnits.days}];
			fieldConfig.demographicUnit = units[0];

			var fieldGroup = {
				wrapper: 'esColumnMultiControlWrap',
				fieldGroup: [],
				templateOptions: {
					required: fieldConfig.isRequired || false,
					label: fieldConfig.label,
					id: 'demographicwrap_' + fieldConfig.id,
					mainInputId: 'demographic_' + fieldConfig.id
				}
			};
			var validateAge = (unitValue, unit) => {
				if (!unitValue && fieldConfig.isRequired) {
					fieldGroup.templateOptions.validationMessage = vm.translations.requiredField;
					return false;
				}
				if (unit !== appConfig.ageUnits.years) {
					var valid =  /^([1-9]\d{0,2})$/.test(unitValue);
					fieldGroup.templateOptions.validationMessage = valid ?
						'' : vm.translations.numericAgeWithUnit;
					return valid;
				} else {
					var rexp = new RegExp(fieldConfig.format);
					var yearValid = rexp.test(unitValue);

					fieldGroup.templateOptions.validationMessage = yearValid ?
						'' : fieldConfig.formatHint;
					return yearValid;
				}
			};

			var unitField = {
				type: 'esSelectInline',
				key: 'demographicUnit',
				model: fieldConfig,
				templateOptions: {
					options: units,
					labelProp: 'value',
					valueProp: 'value',
					allowInvalidValue: true,
					id: 'demographicunit_' + fieldConfig.id,
				},
				validators: {
					customValidationFormat: {
						expression: function (viewValue, modelValue, scope) {
							var ageDemo = getAgeDemographic();
							var ageValue = fieldGroup.templateOptions.viewValue;
							var ageUnit = modelValue || viewValue;
							ageUnit = ageUnit ? ageUnit.value : ageUnit;
							validateAge(ageValue, ageUnit);
							return true; // primary validation is on input field
						}
					}
				},
				extras: {
					validateOnModelChange: true
				},
			};

			var field = {
				type: 'esNarrowInput',
				key: 'demographicValue',
				model: fieldConfig,
				templateOptions: {
					required: fieldConfig.isRequired || false,
					label: fieldConfig.label,
					validationPattern: fieldConfig.format,
					id: 'demographic_' + fieldConfig.id,
					dropdownOptions: units,
					labelProp: 'value',
					valueProp: 'value',
					dropdownKey: 'unit',
					allowInvalidValue: true,
					onBlur: () => {
						fieldGroup.templateOptions.touched = true;
					}
				},
				validators: {
					customValidationFormat: {
						expression: function (viewValue, modelValue, scope) {
							var ageDemo = getAgeDemographic();
							var ageUnit = appConfig.ageUnits.years;
							if (ageDemo && ageDemo.demographicUnit && ageDemo.demographicUnit.value) {
								ageUnit = ageDemo.demographicUnit.value;
							}
							fieldGroup.templateOptions.viewValue = viewValue;
							var value = modelValue || viewValue;
							return validateAge(value, ageUnit);
						},
					},
				},
				extras: {
					validateOnModelChange: true
				},
			};

			fieldGroup.fieldGroup.push(field);
			fieldGroup.fieldGroup.push(unitField);
			vm.subjectDemographicsFields.push(fieldGroup);
			vm.ageWithUnitSubForm = fieldGroup;
		}

		function getReadOnlyFormField(data) {
			const field =
				  {
					  type: 'esInput',
					  key: 'demographic',
					  wrapper: 'esColumnControlWrap',
					  model: data,
					  templateOptions: {
						  type: 'text',
						  label: data.fieldLabel || data.label,
						  disabled: true,
						  id: 'demographic_' + (data.fieldId || data.id)
					  }
				  };

			return field;
		}

		function setUpReadOnlyDemographics() {
			const cdeMode = clinicalDataEntryService.getCdeMode();

			vm.options.formState.dataLoading++;
			vm.isLoadingDemographics = true;

			clinicalDataEntryApiService.getSubjectDemographics(
				vm.data.selectedStudy.protocolId,
				vm.data.selectedStudyModeId,
				vm.data.selectedSite.siteId,
				vm.data.subjectDetails.selectedSubject.subjectId,
				cdeMode
			).then(function success(response) {
				vm.options.formState.dataLoading--;
				vm.isLoadingDemographics = false;
				var data = response.data;
				vm.data.demographics = data;
				for (var x = 0; x < data.length; x++) {
					vm.subjectDemographicsFields.push(
						getReadOnlyFormField(data[x])
					);
				}
				vm.options.formState.subjectDemographics.hidden = false;


			}, function error(data, status) {
				vm.options.formState.dataLoading--;
				vm.isLoadingDemographics = false;
			});
		}

		function getInitialModeForNewSubject() {
			if (vm.data.subjectMode) {
				return vm.data.subjectMode.value;
			}
			if (vm.studyConfig.blockSubjectCreation) {
				return 2;
			} else {
				return 1;
			}
		}

		function changeSubject() {
			const isNewSubject = vm.data.subjectDetails.selectedSubject.subjectKey === 'new';
			let preselectedSubjectMode;

			if (isNewSubject) {
				preselectedSubjectMode = getInitialModeForNewSubject();
			} else {
				// Modes for existing subjects (coming from the API): '1' == Proficiency, '3' == Study
				// If the subject mode coming the API is equals 1 (Proficiency) then
				// preselect value 2 (Proficiency dropdown value). Otherwise defaults to 1.
				preselectedSubjectMode = vm.data.subjectDetails.selectedSubject.subjectMode === '1'
					? 2 // proficiency
					: 1; // study
			}
			const isEditableSubject = !isNewSubject;
			if (!vm.data.subjectDetails.selectedSubject) {
				return;
			} else if (isEditableSubject || isNewSubject) {
				// get getSubjectDemographicsConfig
				vm.options.formState.dataLoading++;
				vm.isLoadingDemographics = true;

				if (isEditableSubject) {
					vm.options.formState.dataLoading++;
					vm.isLoadingDemographics = true;
					clinicalDataEntryApiService.getSubjectDemographics(
						vm.data.selectedStudy.protocolId,
						vm.data.selectedStudyModeId,
						vm.data.selectedSite.siteId,
						vm.data.subjectDetails.selectedSubject.subjectId,
						vm.cdeMode
					).then(function success(response) {
						vm.options.formState.dataLoading--;
						vm.isLoadingDemographics = false;
						var data = response.data;
						vm.data.existingDemographics = data;

						setUpEditableDemographics(true, preselectedSubjectMode);


					}, function error(data, status) {
						vm.options.formState.dataLoading--;
						vm.isLoadingDemographics = false;
					});
				}
				else {
					setUpEditableDemographics(false, preselectedSubjectMode);
				}
			} else {
				setUpReadOnlyDemographics();
			}
		}

		function onSiteChange() {
			// check if a value is selected, if not
			if (!vm.data.selectedSite) {
				// need to clean up the dependant forms and disable them
				cleanSubjectsList();
				cleanDemographics();
				return;
			}
			cleanSubjectsList();
			cleanDemographics();
			getAvailableSubjects(vm.data.selectedStudy.protocolId, vm.data.selectedStudyModeId, vm.data.selectedSite.siteId);

		}

		function isProficiency() {
			return checkProficiencyForNewSubject() || checkProficiencyForExistingSubject();
		}

		function checkProficiencyForNewSubject() {
			return 	vm.data.subjectDetails.selectedSubject &&
				vm.data.subjectDetails.selectedSubject.subjectKey === 'new' &&
				vm.data.subjectMode && vm.data.subjectMode.value === 2; //from drop down list, Proficiency = 2
		}

		function checkProficiencyForExistingSubject() {
			return vm.data.subjectDetails.selectedSubject &&
				vm.data.subjectDetails.selectedSubject.subjectKey !== 'new' &&
				vm.data.subjectDetails.selectedSubject.subjectMode === '1';  //from rest api: Proficiency = '1'
		}

		function hasDateOfBirth(demographics) {
			return !!_.find(demographics, function (d) {
				return d.id === '2';
			});
		}

		function applySubjectMode(restoredSubject) {
			vm.options.formState.subjectIdInput = {
				disabled: isProficiency(),
				hidden: isProficiency()
			};

			var subjectNumberDemographic = getSubjectNumberDemographic();
			if (isProficiency()) {
				subjectNumberDemographic.demographic = subjectNumberDemographic.label + ' will be autogenerated';
			} else if (!restoredSubject) {
				subjectNumberDemographic.demographic = '';
			}
			vm.options.formState.subjectIdInput.placeholder = subjectNumberDemographic.demographic;
		}

		var onStudyChange = function () {
			cleanSitesList();
			cleanSubjectsList();
			cleanDemographics();

			if (vm.data.selectedStudy) {
				getAvailableSites(vm.data.selectedStudy.protocolId, vm.data.selectedStudyModeId);
			}
		};

		function getEthnicitiesOptions(ethnicities) {
			return matchSelectValueToModel(ethnicities, 'name');
		}

		var handleInternalUserUseCase = function () {
			// disable name and email fields
			vm.options.formState.userNameInput.disabled = false;
			vm.options.formState.userEmailInput.disabled = false;
			vm.options.formState.userNameInput.hidden = false;
			vm.options.formState.userEmailInput.hidden = false;
		};

		var handleExternalUserUseCase = function () {
			vm.options.formState.userNameInput.hidden = true;
			vm.options.formState.userEmailInput.hidden = true;
			getUserData();
		};

		function dateIsInPast(dateString) {
			if (!dateString) {
				return true;
			}
			var dob = parseDate(dateString);
			var now = new Date();
			var tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
			return dob < tomorrow;
		}

		function parseDate(s) {
			var months = {
				jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
				jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
			};
			var p = s.split('-');
			if (p.length === 3) {
				return new Date(p[2], months[p[1].toLowerCase()], p[0]);
			}
			// for partial dob, return minimum possible value
			else if (p.length === 2) {
				return new Date(p[1], months[p[0].toLowerCase()], 1);
			}
			else if (p.length === 1) {
				return new Date(p[0], 0, 1);
			}
			return null;
		}


		var getUserData = function () {
			// get basic user data
			const session = appSession.get();

			vm.data.userFirstName = session.firstName;
			vm.data.userLastName = session.lastName;
			vm.data.userEmail = session.email;
		};

		// get sites the user has access to for NewDataEntry permission
		var getAvailableSites = function (studyId, modeId) {

			vm.options.formState.dataLoading++;
			var sitesSelect = getSubmissionDetailsFieldById('siteSelect');
			sitesSelect.templateOptions.loading = clinicalDataEntryApiService.getSites(
					studyId, modeId
			).then(function (response) {
				//get sites available
				vm.options.formState.siteSelection.options = matchSelectValueToModel(response.data, "displaySiteName");
				vm.options.formState.dataLoading--;
				if (response.data.length === 1) {
					vm.options.formState.siteSelection.disabled = true;
					vm.data.selectedSite = response.data[0];
					onSiteChange();

				} else {
					vm.options.formState.siteSelection.disabled = false;
				}

			}, function onError(data, status) {
				vm.options.formState.dataLoading--;
				logError(data, status);
			});
		};

		var getAvailableSubjects = function (studyId, studyModeId, siteId) {
			vm.options.formState.dataLoading++;
			clinicalDataEntryApiService.getSubjectsList(studyId, studyModeId, siteId)
				.then(function onSuccess(response) {
					vm.options.formState.dataLoading--;
					vm.studyConfig = response.data.config;
					vm.options.formState.subjectSelection.options = [{
						selectedValue: '(Create New Subject...)',
						subjectKey: 'new'
					}].concat(matchSelectValueToModel(response.data.subjects, 'subjectKey'));

			        vm.options.formState.subjectSelection.placeholder = vm.studyConfig.blockSubjectCreation ?
					vm.translations.selectSubjectText : vm.translations.createSubjectText,
					vm.options.formState.subjectSelection.disabled = false;
					vm.subjectList = response.data.subjects;


				}, function onError(errorData, status) {
					$log.error("Error : ", errorData, " status ", status);
					vm.options.formState.dataLoading--;
					vm.options.formState.subjectSelection.disabled = true;
					vm.subjectList = null;
				});
		};

		var getSubmissionDetailsFieldById = function (fieldId) {
			var idx = _.findIndex(vm.fields[0].fieldGroup, function (field) {
				return field.templateOptions.fieldId === fieldId;
			});
			return vm.fields[0].fieldGroup[idx];
		};

		var getSubjectDetailsFieldById = function (fieldId) {
			var idx = _.findIndex(vm.subjectDetails, function (field) {
				return field.templateOptions.fieldId === fieldId;
			});
			return vm.subjectDetails[idx];
		};

		var matchSelectValueToModel = function (dataModelRef, displayName) {
			if (_.isFunction(displayName)) {
				for (var x = 0; x < dataModelRef.length; x++) {
					dataModelRef[x].selectedValue = displayName(dataModelRef[x]);
				}
				return dataModelRef;
			}
			for (var x = 0; x < dataModelRef.length; x++) {
				dataModelRef[x].selectedValue = dataModelRef[x][displayName];
			}
			return dataModelRef;
		};

		var cleanSitesList = function () {
			vm.data.selectedSite = {};// needs to be empty object for validation to work properly
			vm.options.formState.siteSelection.disabled = true;
			vm.options.formState.siteSelection.options = [];
		};

		var cleanSubjectsList = function () {
			vm.data.subjectDetails.selectedSubject = {};
			vm.options.formState.subjectSelection.disabled = true;
			vm.options.formState.subjectSelection.options = [];
			vm.subjectDemographicsForm.showValidation = false;
			if (vm.ageWithUnitSubForm) {
				vm.ageWithUnitSubForm.templateOptions.showValidation = false;
			}
		};

		function cleanDemographics() {
			vm.data.demographics = {};
			vm.subjectDemographicsFields.length = 0;
			vm.options.formState.subjectDemographics.hidden = true;
		}

		var logError = function (errorData, status) {
			$log.error("Error : ", errorData, " status ", status);
		};

		function getSubjectNumberDemographic() {
			return _.find(vm.data.demographics, function (d) {
				return d.id === '3';//Subject Number
			});
		}

		function getAgeDemographic() {
			return _.find(vm.data.demographics, function (d) {
				return d.id === '12';//age
			});
		}

		var formatData = function (dataModel) {
			dataModel.context = {
				studyId: dataModel.selectedStudy.protocolId,
				studyAccount: dataModel.selectedStudy.protocolAccount,
				internalSiteId: dataModel.selectedSite.siteId,
				sponsorSiteId: dataModel.selectedSite.sponsorSiteId,
				studyModeId: dataModel.selectedStudyModeId,
				user: {
					firstName: dataModel.userFirstName,
					lastName: dataModel.userLastName,
					email: dataModel.userEmail
				}
			};

			dataModel.subject = {
				isProficiency: false,
				id: dataModel.subjectDetails.selectedSubject.subjectId,
				sponsorSubjectId: (function (subjectKey) {
					if (subjectKey === 'new') {
						var subjectNumber = getSubjectNumberDemographic();

						if (isProficiency()) {
							subjectNumber.demographic = '';
						}

						return subjectNumber.demographic;
					} else {
						return subjectKey;
					}
				}(dataModel.subjectDetails.selectedSubject.subjectKey))
			};

			if (isPaperEcg()) {
				var ageDemo = getAgeDemographic();
				if (ageDemo) {
					if (ageDemo.demographicUnit) {
						if (ageDemo.demographicUnit !== appConfig.ageUnits.years) {
						ageDemo.demographic = ageDemo.demographicValue + ' ' + ageDemo.demographicUnit.value;
						} else {
							ageDemo.demographic = ageDemo.demographicValue;
						}
					}
				}
			}
			dataModel.subject.isProficiency = isProficiency();
		};

		var isFieldsFormValid = function () {
			return vm.fieldsForm.$valid;
		};

		var isSubjectDetailsFormValid = function () {
			return vm.subjectDetailsForm.$valid;
		};

		var isSubjectDemographicsFormValid = function () {
			return vm.subjectDemographicsForm.$valid;
		};

		var isSomeRequestPending = function () {
			return !!vm.options.formState.dataLoading;
		};

		if ($stateParams.signKey) {
			const unregister = $scope.$watch(
				() => {
					return ertStepsWizardService.isWizardRegistered() &&
						vm.options &&
						vm.options.formState &&
						vm.options.formState.siteSelection &&
						vm.options.formState.subjectSelection;},
				() => {
					if (ertStepsWizardService.isWizardRegistered() &&
						vm.options &&
						vm.options.formState &&
						vm.options.formState.siteSelection &&
						vm.options.formState.subjectSelection) {
						vm.options.formState.siteSelection.options = vm.data.siteSubjectOptions.sites;
						vm.options.formState.subjectSelection.options = vm.data.siteSubjectOptions.subjects;
						vm.data.demographics = vm.data.siteSubjectOptions.demos;
						vm.savedDemographics = angular.copy(vm.data.siteSubjectOptions.demos);
						vm.studyConfig = vm.data.siteSubjectOptions.studyConfig;
						onSubjectRestore();

						vm.options.formState.siteSelection.disabled = false;
						vm.options.formState.subjectSelection.disabled = false;
						unregister();
					}
				});
		}
	}]);
