/**
 * Created by AMorbia on 12/21/2015.
 */
(function () {
	'use strict';

	angular.module('portalApp').directive('ertProgressIndicator', ProgressIndicator);

	/**
	 * @ngInject
	 */
	function ProgressIndicator() {
		return {
			restrict: 'E',
			templateUrl: 'scripts/directives/dialogs/ertProgressIndicator.html',
			transclude: true,
				replace: false,
			/**
			 * Using isolated scope in directives is recommended
			 * 1. "@"   (  Text binding / one-way binding )
			 * . "="   ( Direct model binding / two-way binding )
			 * 3. "&"   ( Behaviour binding / Method binding  )
			 */
			scope: {
				isVisible: "=",
				errorMessage: "=",
				displayText: "="
			},
			link: function (scope, element, attributes) {


			}
		};
	}
})();
