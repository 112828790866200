class HolterReportController {

	constructor($log, $scope, appI18n, reportsService, configurationService,
		appConfig, documentService, loadingIndicatorService) {
		'ngInject';
		this.$log = $log;
		this.$scope = $scope;
		this.appI18n = appI18n;
		this.reportsService = reportsService;
		this.configurationService = configurationService;
		this.appConfig = appConfig;
		this.documentService = documentService;
		this.loadingIndicatorService = loadingIndicatorService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('HolterReportController');
		this.$log.debug(' loaded');

		this.loadingIndicatorService.show();
		this.reports = {};

		this.options = {
			rowDefs: {
				multiSelection: true,
				identifier: 'transmittalNumber'
			},
			gridRowActions: [{
				displayName: 'Reports',
				iconCssClass: 'es-icon es-icon-reports',
				enabledIf: (row) => {
					return row.safetyFeedbackFormFileName ||
						row.haReportPart1FileName ||
						row.haReportPart2FileName;
				},
				subItemsFactory: (row) => {
					if (row.reports) {
						return row.reports;
					}

					const rowReports = [];

					if (row.safetyFeedbackFormFileName) {
						rowReports.push(
							this.getReportSubItemRowAction(
								row, row.holterFeedbackFormDisplayName, 'HSFF'));
					}
					if (row.haReportPart1FileName) {
						rowReports.push(
							this.getReportSubItemRowAction(
								row, row.holterPart1DisplayName, 'HA1'));
					}
					if (row.haReportPart2FileName) {
						rowReports.push(
							this.getReportSubItemRowAction(
								row, row.holterPart2DisplayName, 'HA2'));
					}

					row.reports = rowReports;

					return rowReports;
				}
			}],
			gridActions: [{
				displayName: this.appI18n.translateImmediate(
					'reports.holter-reports.download'),
				subItems: [
					this.getHolterDownloadAction(
						'reports.holter-reports.downloadHSFFs', 'HSFF'),
					this.getHolterDownloadAction(
						'reports.holter-reports.downloadHAReports', 'HA'),
					this.getHolterDownloadAction(
						'reports.holter-reports.downloadBoth', 'BOTH')
				]
			}],

			getData: (oDataQuery) => {
				return this.getHolterReports(oDataQuery);
			}
		};

		this.configurationService.getSubjectDemographicSettings()
			.then((response) => {
				this.options.sortOrders = [];
				this.options.columnDefs = [];
				this.options.columnDefs.push({
					displayName: 'Principal Investigator',
					fieldName: 'siteName',
					isSortable: true,
					type: 'string',
					isDefaultFilter: true
				});

				this.options.columnDefs.push({
					translationKey: 'app.common.siteNumber',
					fieldName: 'sponsorSiteId',
					isSortable: true,
					type: 'string'
				});

				this.options.columnDefs.push({
					translationKey: 'app.common.subjectKey',
					fieldName: 'subjectKey',
					isSortable: true,
					type: 'string'
				});

				if (!response.data.isSubjectId2Hidden) {
					let id2DisplayName = 'Screen #';

					if (response.data.fieldLabels[this.appConfig.demographics.subjectId2]) {
						id2DisplayName = response.data.fieldLabels[this.appConfig.demographics.subjectId2];
					}
					this.options.columnDefs.push({
						displayName: id2DisplayName,
						fieldName: 'patientId2',
						isSortable: true,
						type: 'string'
					});
				}

				if (!response.data.isSubjectId1Hidden) {
					if (response.data.fieldLabels[this.appConfig.demographics.subjectId1]) {
						this.options.columnDefs.push({
							displayName: response.data.fieldLabels[this.appConfig.demographics.subjectId1],
							fieldName: 'patientId1',
							isSortable: true,
							type: 'string'
						});
					} else {
						this.options.columnDefs.push({
							translationKey: 'app.common.subjectNumber',
							fieldName: 'patientId1',
							isSortable: true,
							type: 'string'
						});
					}
				}
				if (!response.data.isDateOfBirthHidden) {
					let dobDisplayName = 'Date Of Birth';

					if (response.data.fieldLabels[this.appConfig.demographics.dob]) {
						dobDisplayName = response.data.fieldLabels[this.appConfig.demographics.dob];
					}
					this.options.columnDefs.push({
						displayName: dobDisplayName,
						fieldName: 'dateOfBirthDisplay',
						isSortable: true,
						type: 'string'
					});
				}

				if (!response.data.isInitialsHidden) {
					let initialsDisplayName = 'Initials';

					if (response.data.fieldLabels[this.appConfig.demographics.initials]) {
						initialsDisplayName = response.data.fieldLabels[this.appConfig.demographics.initials];
					}
					this.options.columnDefs.push({
						displayName: initialsDisplayName,
						fieldName: 'initials',
						isSortable: true,
						type: 'string'
					});
				}

				if (!response.data.isGenderHidden) {
					let genderDisplayName = 'Gender';

					if (response.data.fieldLabels[this.appConfig.demographics.gender]) {
						genderDisplayName = response.data.fieldLabels[this.appConfig.demographics.gender];
					}
					this.options.columnDefs.push({
						displayName: genderDisplayName,
						fieldName: 'gender',
						isSortable: true,
						type: 'string'
					});
				}

				if (!response.data.isEthnicityHidden) {
					let ethnicityDisplayName = 'Race';

					if (response.data.fieldLabels[this.appConfig.demographics.ethnicity]) {
						ethnicityDisplayName = response.data.fieldLabels[this.appConfig.demographics.ethnicity];
					}
					this.options.columnDefs.push({
						displayName: ethnicityDisplayName,
						fieldName: 'ethnicity',
						isSortable: true,
						type: 'string'
					});
				}

				this.options.columnDefs.push({
					displayName: 'Visit',
					fieldName: 'fullVisitName',
					isSortable: true,
					type: 'string'
				});
				this.options.columnDefs.push({
					displayName: 'Collection Date/Time',
					fieldName: 'collectionDateTime',
					isSortable: true,
					type: 'date',
					formatter: 'dateTimeFormat'
				});
				this.options.columnDefs.push({
					displayName: 'Transmittal #',
					fieldName: 'transmittalNumberText',
					isSortable: true,
					type: 'string'
				});
				this.options.columnDefs.push({
					displayName: 'Complete Date/Time',
					fieldName: 'completeDateTime',
					isSortable: true,
					type: 'date',
					formatter: 'dateTimeFormat'
				});
				this.options.columnDefs.push({
					displayName: 'Revised',
					fieldName: 'revisedReport',
					isSortable: true,
					type: 'enum',
					formatter: 'yesNo',
					enumType: 'bool',
					enums: [{
						displayName: 'Yes',
						values: ['1']
					}, {
						displayName: 'No',
						values: ['-1']
					}]
				});

				this.options.sortOrders.push({
					fieldName: 'siteName'
				});
				this.options.sortOrders.push({
					fieldName: 'sponsorSiteId'
				});
				if (!response.data.isSubjectId1Hidden) {
					this.options.sortOrders.push({
						fieldName: 'patientId1'
					});
				}
				this.options.sortOrders.push({
					fieldName: 'fullVisitName'
				});
				this.options.sortOrders.push({
					fieldName: 'collectionDateTime'
				});
				this.options.sortOrders.push({
					fieldName: 'transmittalNumberText'
				});

				this.activate();
			}).finally(() => {
				this.loadingIndicatorService.hide();
			});
	}

	getReportSubItemRowAction(row, displayName, type) {
		return {
			displayName,
			iconFileType: 'pdf',
			action: () => {
				this.documentService.getHolterReport([this.getReportData(row, type)])
					.then((response) => {
						this.documentService.downloadReport(
							response.data, this.$scope,
							this.appConfig.downloadDocumentType.holterReport);
					});
			}
		};
	}

	getHolterDownloadAction(translationKey, type) {
		return {
			displayName: this.appI18n.translateImmediate(translationKey),
			action: () => {
				const documents = [];

				this.selectedRows.forEach((row) => {
					documents.push(this.getReportData(row, type));
				});
				this.documentService.getHolterReport(documents)
					.then((response) => {
						this.documentService.downloadReport(
							response.data, this.$scope,
							this.appConfig.downloadDocumentType.holterReport,
							documents.length);
					});
			},
			enabledIf: () => {
				return this.selectedRows ? this.selectedRows.length > 0 : false;
			}
		};
	}

	getReportData(row, type) {
		const report = {};

		if (type === 'HA1') {
			report.haReportPart1FileName = row.haReportPart1FileName;
			report.holterPart1DisplayName = row.holterPart1DisplayName;
		} else if (type === 'HA2') {
			report.haReportPart2FileName = row.haReportPart2FileName;
			report.holterPart2DisplayName = row.holterPart2DisplayName;
		} else if (type === 'HA' || type === 'BOTH') {
			report.haReportPart1FileName = row.haReportPart1FileName;
			report.haReportPart2FileName = row.haReportPart2FileName;
			report.holterPart1DisplayName = row.holterPart1DisplayName;
			report.holterPart2DisplayName = row.holterPart2DisplayName;
		}
		if (type === 'HSFF' || type === 'BOTH') {
			report.safetyFeedbackFormFileName = row.safetyFeedbackFormFileName;
			report.holterFeedbackFormDisplayName = row.holterFeedbackFormDisplayName;
		}

		report.sponsorSiteId = row.sponsorSiteId;
		report.collectionDateTime = row.collectionDateTime;
		report.fullVisitName = row.fullVisitName;
		report.patientId1 = row.patientId1;
		report.visitName = row.visitName;
		report.transmittalNumber = row.transmittalNumber;
		return report;
	}

	activate() {
		this.load = true;
	}

	getHolterReports(options) {
		return this.reportsService.getHolterReports(null, null, options);
	}
}

export default HolterReportController;
