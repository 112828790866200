const KEY = {
	ESCAPE: {
		CODE: 27,
		KEYS: [
			'Escape',
			'Esc'
		]
	}
};

class PopupController {
	constructor($rootScope, $element, $q, loadingIndicatorService, scrollService, $timeout, $window, $attrs) {
		'ngInject';
		this.$rootScope = $rootScope;
		this.$element = $element;
		this.$q = $q;
		this.$timeout = $timeout;
		this.$window = $window;
		this.loadingIndicatorService = loadingIndicatorService;
		this.scrollService = scrollService;
		this.popupModuleId = 'ert-popup-component';
		this.$attrs = $attrs;
	}

	$onChanges(changesObj) {
		if (changesObj.show && !changesObj.show.isFirstChange()) {

			if (changesObj.show.currentValue && angular.isDefined(this.$attrs.onShow)) {

				this.loadingIndicatorService.show();
				this.$q.resolve(this.onShow()).then((res) => {
					this.loadingIndicatorService.hide();
					this.showPopup = true;
					this.setScrollAndFocus();
					return res;
				}, (error) => {
					this.$log.error('Error while opening popup', error);
					this.loadingIndicatorService.hide();
				});
				return;
			}

			this.showPopup = changesObj.show.currentValue;

			if (this.showPopup) {
				this.setScrollAndFocus();
			} else {
				this.scrollService.unsetScroll({ customClass: this.popupModuleId });
			}
		}
	}

	setScrollAndFocus() {
		this.scrollService.setScroll({ customClass: this.popupModuleId });
		this.$timeout(() => { this.setFocus(); });
	}

	$onDestroy() {
		if (this.show) {
			this.scrollService.unsetScroll({ customClass: this.popupModuleId });
		}
	}

	getModelSize() {
		let result = '-es-modal-large';
		const size = this.size;

		if (!size) {
			return result;
		}
		if (size === 'small') {
			result = '-es-modal-small';
		} else if (size === 'medium') {
			result = '-es-modal';
		} else if (size === 'large') {
			result = '-es-modal-large';
		} else {
			result = '-es-modal-xlarge';
		}

		return result;
	}
	/*
	* why keydown and not keypress?
	* because of webkit browsers keypress issue
	* */
	doKeyDown(evt) {
		if (this.checkKey(evt, KEY.ESCAPE)) {
			this.onEscape && this.onEscape();
		}
		evt.stopPropagation();
	}

	checkKey(evt, keyObj) {
		// support of escape for most browsers
		evt = evt || this.$window.event;
		let isEscape = false;

		if ('key' in evt) {
			isEscape = keyObj.KEYS.some((elem) => { return elem === evt.key; });
		} else {
			isEscape = (evt.keyCode === keyObj.ESCAPE);
		}

		return isEscape;
	}

	setFocus(/*evt*/) {
		this.$element.find('.es-popup').focus();
	}
}

export default PopupController;
