/*
 * filtersComponent
 * */

import templateUrl from './filters.html';
import controller from './filters.controller';
import './filters.scss';
/*
* columns - table columns model used to display filter select
* defaultFilter - default filter model to preselect filter from drop-down
* queryFilters - array of (pre) selected filters, used also for badges
* onUpdate - callback called when a filter is updated
* */
const filtersComponent = {
	bindings: {
		columns: '<',
		defaultFilter: '<',
		queryFilters: '<?',
		onUpdate: '&'
	},
	require: {
		grid: '^^?grid'
	},
	templateUrl,
	controller
};

export default filtersComponent;
