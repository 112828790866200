/*
 * visitCalendarLegendModule
 * */

import visitCalendarLegendComponent from './visitCalendarLegend.component';

const visitCalendarLegendModule = angular
	.module('app.components.dashboard.visitOverviewWidget.visitCalendarPopup.visitCalendarLegend', [])
	.component('visitCalendarLegend', visitCalendarLegendComponent)
	.name;

export default visitCalendarLegendModule;
