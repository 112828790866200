var BrowserDetect = require('../browserDetect/browserDetect');

/**
 * Log level constants
 * @type {{debug: number, info: number, warn: number, error: number}}
 */
var logLevel = {
	debug: 1,
	info: 2,
	warn: 3,
	error: 4
};

/**
 * Singleton object for sending buffered log messages to server.
 * Buffer size can be set in app.config.js using appConfig.log.apiBufferSize
 * apiBufferSize is a number of log message to be send to PortalApi at once
 * @type {{getInstance}}
 */
/** ngInject */
var loggerSendQueue = (function () {
	'use strict';

	/**
	 * Instance stores a reference to the Singleton
	 */
	var instance;
	var queueSend = [];

	/**
	 * Init function for a singleton
	 * @param appConfig application configuration
	 * @returns {{push: Function}}
	 */
	function init(appConfig, tokenService) {
		var queue = [];
		var lastSentInfo = {
			userAgent: {}
		};

		function logLevelAsString(logLevelNumber) {
			switch (logLevelNumber) {
				case 1:
					return 'debug';
				case 2:
					return 'info';
				case 3:
					return 'warn';
				default:
					return 'error';
			}
		}

		function getObjectChanges(oldObject, newObject) {
			if (!oldObject) {
				return newObject;
			}

			var diff = {};
			for (var p in newObject) {
				if (newObject.hasOwnProperty(p)) {
					if (newObject[p] !== oldObject[p]) {
						diff[p] = newObject[p];
					}
				}
			}

			return diff;
		}

		/**
		 * Push log item to queue
		 * @param item log item
		 * @param timeOnClient local date time of a browser client
		 * @param itemLogLevel log level, see appConfig.log.level and logLevel constants
		 * @param stack stack trace. IMPORTANT stack trace should be passed externally from error handler
		 * otherwise stack trace will be substituted with fake one and will have not a lot of sense
		 */
		var push = function (item, timeOnClient, itemLogLevel, stack) {

			var userAgent, url, studyId, logItem;
			try {
				userAgent = {
					userAgentString: window.navigator.userAgent,
					browser: BrowserDetect.browserName,
					version: BrowserDetect.browserVersion || -1,
					operatingSystem: BrowserDetect.operatingSystemName,
					locale: window.navigator.userLanguage || window.navigator.language,
					height: document.body.clientHeight || -1,
					width: document.body.clientWidth || -1
				};

				url = window.location.href;

				var clientInfo = {};
				clientInfo.userAgent = userAgent;
				clientInfo.url = url;


			} catch (error) {
				console.log('Error in loggerSendQueue during userAgent and language detection');
			}
				if (itemLogLevel >= logLevel[appConfig.log.level]) {
					queue.unshift([item, timeOnClient, itemLogLevel, stack, clientInfo]);
				}

				// Send errors immediately or if amount of items in buffer exceeds buffer size
				if (itemLogLevel === logLevel.error || queue.length >= appConfig.log.apiBufferSize) {
					send();

					// create timeout to prevent object being deleted without sending last portion of errors array
					// window.setTimeout(send, 2 * 60000);
				}
			},
			/**
			 * Private sending function. Should not be called from outside. Called from push when number of log items
			 * in buffer exceeds appConfig.log.apiBufferSize
			 */
			send = function () {
				if (queue.length === 0) {
					return;
				}

				var sessionToken = tokenService.getSessionToken();
				if (!sessionToken) {
					// Anonymous logging is not allowed to avoid denial of service attacks and spaming of our systems.
					// In these cases we need relay only on PortalApi loging and Google Analytics
					return;
				}

				while (queue && queue.length > 0) {
					var element = queue[0];
					var itemLogLevel = element[2];
					var clientInfo = element[4];

					var msgType = Object.prototype.toString.call(element[0]);
					var msg = '';

					if (element[0]) {
						if (msgType === '[object Array]') {
							msg = element[0].join();
						} else if (msgType === '[object String]') {
							msg = element[0];
						} else {
							msg = element[0].toString();
						}
					} else {
						msg = 'empty';
					}

					// Send to server only items which have higher or same log level than in config
					if (itemLogLevel >= logLevel[appConfig.log.level]) {
						var postError = {
							'level': logLevelAsString(element[2]) || 'error',
							'message': encodeURIComponent(msg),
							'clientTime': element[1],
							'origin': element[3],
							'url': clientInfo.url
						};

						var userAgentUpdate = getObjectChanges(lastSentInfo.userAgent, clientInfo.userAgent);

						lastSentInfo.userAgent = clientInfo.userAgent;

						if (itemLogLevel >= logLevel.error) {
							postError.userAgent = clientInfo.userAgent;
						} else if (userAgentUpdate) {
							postError.userAgent = userAgentUpdate;
						}

						queueSend.push(postError);
					}

					queue.shift();
				}

				if (queueSend.length > 0) {
					var body = JSON.stringify(queueSend);
					$.ajax({
						url: appConfig.log.apiUrl,
						method: 'POST',
						data: body,
						contentType: 'application/json',
						dataType: 'json',
						headers: {'Authorization': 'Bearer ' + sessionToken}
					}).done(function (data) {
						//queue = [];
					}).fail(function (data) {
						// TODO: RK-20151026 We can track failed items or even retry but here in event handler
						// it is hard to retry same amount of items correctly because of async nature
						// so failed items queue will be double each time with duplicated items
						//queue.forEach(function (element, index, array) {
						//  queueFailed.unshift(element);
						//});

						//queue = [];
					});

					queueSend = [];
				}
			};

		return {
			push: push
		};
	}

	return {

		// Get the Singleton instance if one exists
		// or create one if it doesn't
		getInstance: function (appConfig, tokenService) {

			if (!instance) {
				instance = init(appConfig, tokenService);
			}

			return instance;
		}

	};

})();

module.exports = {logLevel: logLevel, loggerSendQueue: loggerSendQueue};
