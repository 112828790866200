﻿(function() {
	'use strict';

	angular
		.module('portalApp')
		.service('logiService', LogiService);

	/*
	 * @ngInject
	 */
	function LogiService($log, $http, appSession, appConfig) {

		var epxHandler, service = {
			getLogiReportUrl: getLogiReportUrl,
			getOdsReportUrl: getOdsReportUrl,
			getLogiSecureKey: getLogiSecureKey,
			getOdsSecureKey: getOdsSecureKey,
		};

		function getLogiReportUrl(studyId, studyMode, reportName, token) {
			var url = appConfig.apiUrls.logi.redirect.supplant({ studyId, studyMode, reportName, token });
			return url;
		}

		function getLogiSecureKey(studyId) {
			var url = appConfig.apiUrls.logi.secureKey.supplant({studyId});
			return $http.get(url);
		}

		function getOdsReportUrl(studyId, studyMode, reportName, token) {
			var url = appConfig.apiUrls.ods.redirect.supplant({ studyId, studyMode, reportName, token });
			return url;
		}

		function getOdsSecureKey(studyId) {
			var url = appConfig.apiUrls.ods.secureKey.supplant({studyId});
			return $http.get(url);
		}
		return service;
	}
})();
