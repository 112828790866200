/*
 * baseInputModule
 * */

import baseInputComponent from './baseInput.component';

const baseInputModule = angular.module('app.common.input.baseInput', [])
	.component('baseInput', baseInputComponent)
	.name;

export default baseInputModule;
