/*
 * dispenseISpiroDevicePopupComponent
 * */

import templateUrl from './dispenseISpiroDevicePopup.html';
import controller from './dispenseISpiroDevicePopup.controller';
import './dispenseISpiroDevicePopup.scss';

const dispenseISpiroDevicePopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default dispenseISpiroDevicePopupComponent;
