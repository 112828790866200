/*
 * announcementsDetailsPopupController
 * */

import { CELL_DATA_TYPES } from '../../../../common/table/tableWrap/cellDataType';

class AnnouncementsPopupController {
	constructor($log, appConfig, appI18n, documentService,
				announcementService, loadingIndicatorService, $filter) {
		'ngInject';
		this.$log = $log;
		this.appI18n = appI18n;
		this.appConfig = appConfig;
		this.loadingIndicatorService = loadingIndicatorService;
		this.announcementService = announcementService;
		this.documentService = documentService;
		this.$filter = $filter;
		this.announcement = {};
	}

	$onInit() {
		this.$log = this.$log.getInstance('AnnouncementsPopupController');
		this.$log.debug('loaded');

		this.headers = [{
			value: this.appI18n.translateImmediate('dashboard.announcements.title-hdr'),
			type: CELL_DATA_TYPES.TEXT
		}];

		this.modifiedBy = this.appI18n.translateImmediate('dashboard.announcements.modified-by');
		this.createdBy = this.appI18n.translateImmediate('dashboard.announcements.created-by');
		this.setApi();
	}

	cancelAction() {
		this.$API.close();
	}

	rowSelected(row) {
		this.displayContent(row);
	}

	setApi() {
		this.$API = {
			open: () => {
				this.loadingIndicatorService.show();
				this.announcementService.getDashboardAnnouncementDetails().then((response) => {
					this.announcements = response.data;
					this.open = true;
					this.loadingIndicatorService.hide();
				}, (error) => {
					this.$log.error(`Error: ${error}`);
					this.errorMessage = this.appI18n.translateImmediate('dashboard.loading-error');
					this.loadingIndicatorService.hide();
				});
			},
			close: () => {
				this.open = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({
				$API: this.$API
			});
		}
	}

	announcementsTableReady($API) {
		if (this.announcements && this.announcements.length > 0 &&
			(this.announcements[0].contentType === 'text/html' ||
			this.announcements[0].contentType.indexOf('image/') >= 0)) {
			$API.selectRow(this.announcements[0]);
		}
	}

	setAnnouncementInfo(item) {

		this.announcement.created = this.createdBy.supplant({
			'user': item.createdBy,
			'timestamp': this.$filter('ertFilter')(item.createdOn, 'dateTimeFormat')
		});

		if (item.modifiedBy) {
			this.announcement.modified = this.modifiedBy.supplant({
				'user': item.modifiedBy,
				'timestamp': this.$filter('ertFilter')(item.modifiedOn, 'dateTimeFormat')
			});
		}
	}

	setAnnouncementContent(item) {
		if (item.contentType === 'text/html' ||
			item.contentType.indexOf('image/') >= 0) {

			this.isContentLoading = true;
			this.announcementService.getAnnouncementFile(item.announcementId).then((result) => {
				if (item.contentType === 'text/html') {
					this.documentService.getDownloadReportData(result.data,
						this.appConfig.downloadDocumentType.announcement).then((content) => {
							this.announcement.content = content.data;
						});
				} else {
					this.announcement.content = this.documentService.getDownloadReportUrl(result.data);
				}

				this.announcement.contentType = item.contentType;
			}, (error) => {
				this.$log.error(`Error while retrieving announcement file content.
					Announcement id: ${item.announcementId}. Error: ${error}`);
			}).finally(() => {
				this.isContentLoading = false;
			});
		}

	}

	displayContent(row) {
		this.announcement = {};
		this.setAnnouncementInfo(row);
		this.setAnnouncementContent(row);
	}
}

export default AnnouncementsPopupController;
