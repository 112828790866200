(function () {
	'use strict';

	angular.module('portalApp')
		.directive('ertLimitedList', LimitedList);

	angular.module('portalApp')
		.controller('ertLimitedListController', LimitedListController);

	function LimitedList() {
		return {
			restrict: 'E',
			scope: {
				items: '=',
				limit: '=',
				numMore: '=',
				numMax: '=',
				waypointContext: '=',
				waypointEnabled: '=',
				showHintMaxReached: '='
			},
			templateUrl: 'scripts/directives/list/ertLimitedList.html',
			transclude: true,
			controller: 'ertLimitedListController',
			controllerAs: 'vm',
			bindToController: true
		};
		/** @ngInject **/
	}

	function LimitedListController($timeout, $scope, $log) {
		var aWaypoint,
			vm = this;
		vm.hasMore = hasMore;
		vm.maximumReached = maximumReached;
		vm.more = showMore;
		vm.numMax = vm.numMax || 500;
		vm.numMore = vm.numMore || 50;
		vm.limit = vm.numMore;

		$log = $log.getInstance('LimitedListController');
		$log.debug(' loaded');

		activate();

		function activate() {
			$scope.$watchCollection(function () {
				return vm.items;
			}, reset);
			//refresh waypoint if number of displayed items changes
			$scope.$watch(getNumDisplayedItems, refreshWaypoint);
		}

		function getNumDisplayedItems(){
			return (hasMore() && vm.limit) || (vm.items && vm.items.length) || 0;
		}

		function hasMore() {
			return (vm.items && vm.limit < vm.items.length && vm.limit < vm.numMax);
		}

		function maximumReached() {
			return (vm.items && vm.limit >= vm.numMax);
		}

		function reset() {
			vm.limit = vm.numMore;
		}

		function showMore(direction, waypoint) {
			if (direction == 'up' || !hasMore()) {
				return;
			}
			aWaypoint = waypoint;
			vm.limit += vm.numMore;
		}

		function refreshWaypoint() {
			aWaypoint && $timeout(function () {
				aWaypoint.context.refresh();
			}, 0);
		}
	}
})();
