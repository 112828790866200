import popupComponent from './popup.component';
import popupHeaderModule from './header/index';
import popupContentModule from './content/index';
import popupFooterModule from './footer/index';
import ertBasePopupModule from './ertBasePopup/index';
import scrollModule from './scroll/index';
import popupService from './popup.factory';

const popupModule = angular.module('app.common.ertPopup', [popupHeaderModule, popupContentModule, popupFooterModule,
	ertBasePopupModule, scrollModule])
	.factory('popupService', popupService)
	.component('ertPopup', popupComponent)
	.name;

export default popupModule;
