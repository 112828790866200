/*
 * filtersModule
 * */

import filtersComponent from './filters.component';
import filtersService from './filters.service';
import filterBadgesModule from './filterBadges';
import dateRangeModule from './dateRange';
import numberRangeModule from './numberRange';

const filtersModule = angular.module('app.common.filters', [filterBadgesModule, dateRangeModule, numberRangeModule])
	.component('filters', filtersComponent)
	.service('filtersService', filtersService)
	.name;

export default filtersModule;
