/*
 * openQueriesWidgetController
 * */
import { CELL_DATA_TYPES } from '../../../common/table/tableWrap/cellDataType';

class OpenQueriesWidgetController {
	constructor($log, dashboardService, $filter, $state, appConfig, appSession, appI18n, $timeout) {
		'ngInject';
		this.$log = $log;
		this.dashboardService = dashboardService;
		this.$filter = $filter;
		this.$state = $state;
		this.appConfig = appConfig;
		this.appSession = appSession;
		this.appI18n = appI18n;
		this.$timeout = $timeout;
		this.expertAvailable = angular.isUndefined(this.expertAvailable) || this.expertAvailable;
	}

	$onInit() {
		this.$log = this.$log.getInstance('OpenQueriesWidgetController');
		this.$log.debug('loaded');
		this.getDCRQueryData();
		this.headers = this.getOpenDcrTableHeaders();
	}

	$onChanges(changesObj) {
		if (changesObj.expertAvailable && changesObj.expertAvailable.currentValue) {
			this.widgetControls = this.getWidgetControls();
		} else {
			this.widgetControls = [];
		}
	}

	$onDestroy() {
		this.dataStream.dispose();
	}

	getOpenDcrTableHeaders() {
		return [{
			value: 'dashboard.open-dcr.site',
			type: CELL_DATA_TYPES.TEXT
		}, {
			value: 'dashboard.open-dcr.pi',
			type: CELL_DATA_TYPES.TEXT
		}, {
			value: 'dashboard.open-dcr.queries',
			type: CELL_DATA_TYPES.NUMBER
		}, {
			value: 'dashboard.open-dcr.psi',
			type: CELL_DATA_TYPES.NUMBER
		}];
	}

	getWidgetControls() {
		return [{
			type: 'button',
			cssClass: 'es-icon-grid',
			disabled: !this.expertAvailable,
			displayName: 'dashboard.open-dcr.action',
			action: () => {
				if (!this.expertAvailable) {
					//TODO: Show notification MPORT-3604
					return;
				}
				this.showOpenDCRDetails();
			}
		}];
	}

	showOpenDCRDetails() {
		this.$state.go(this.appConfig.routes.tickets);
	}

	getDCRQueryData() {
		const self = this;

		this.isLoading = true;
		this.dataStream = this.dashboardService.getDcrMetricsStream().subscribe((data) => {
			self.metrics = data;
			self.metrics.querySiteCtr = 0;
			
			angular.forEach(self.metrics.tickets, (ticket) => {
				ticket.totalQueryPSI = parseInt(ticket.numberOfTicketsSentToSite) +
					parseInt(ticket.numberOfPendingSiteInput);
				if (self.openItemsThreshold && ticket.totalQueryPSI > parseInt(self.openItemsThreshold)) {
					self.metrics.querySiteCtr += 1;
				}
			});
			
			if (self.openItemsThreshold && self.metrics.querySiteCtr > 0) {
				const msg = (self.metrics.ispi === true && self.metrics.totalSites === 1
					? self.appI18n.translateImmediate('dashboard.open-dcr.query-pi-site-warning')
					: self.appI18n.translateImmediate('dashboard.open-dcr.query-site-warning'));

				self.queryWarningMessage = msg.supplant({
					'siteCtr': self.metrics.querySiteCtr,
					'threshold': self.openItemsThreshold
				});
			}
			self.topQueries = self.getTopItems(self.metrics.tickets, 5, ['-totalQueryPSI',
				'sponsorSiteId'], 'totalQueryPSI');
			self.isLoading = false;

		}, (error) => {
			self.isLoading = false;
			self.queryErrorMessage = self.appI18n.translateImmediate('dashboard.loading-error');
			self.$log.error(`error in getting dashboard DCR query data: ${error}`);
		});
	}

	getTopItems(source, count, sortOrder, filterStr) {
		const filtered = [];

		angular.forEach(source, (item /*, key*/) => {
			if (item[filterStr] > 0) {
				filtered.push(item);
			}
		});

		const sorted = this.$filter('orderBy')(filtered, sortOrder);
		const limited = this.$filter('limitTo')(sorted, count, 0);

		return limited;
	}

	openQueriesSentToSite() {
		const currentStudy = this.appSession.getStudy();
		this.$state.go(this.appConfig.routes.tickets, {
			'filter': `QueryPortalStatus eq '${this.appConfig.dataManagementState.querySentToSite}'
			&orderBy=QueryCreationDate desc
			&study=${currentStudy.id}
			&mode=${currentStudy.studyModeId}`
		});
	}

	openPendingSiteInput() {
		const currentStudy = this.appSession.getStudy();
		this.$state.go(this.appConfig.routes.cdePftList, {
			'filter': `StudyName eq '${currentStudy.name}' 
			and StateValue eq '${this.appConfig.dataEntryState.pendingSiteInput}'
			&orderBy=TatEndTime,RemainingTatInSeconds,ModifiedOn
			&study=${currentStudy.id}
			&mode=${currentStudy.studyModeId}`
		});
	}
}

export default OpenQueriesWidgetController;
