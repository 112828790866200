/*
 * loadingRowDirective
 * */

import templateUrl from './loadingRow.html';
import controller from './loadingRow.controller';
import './loadingRow.scss';

const loadingRowDirective = () => {
	return {
		scope: {
		},
		require: {
			table: '^tableWrap'
		},
		controller,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		link(/*scope, element, attr, ctr*/) {},
		replace: true
	};
};

export default loadingRowDirective;
