class EcgSourceTracingController {

	constructor($log, $scope, appI18n, reportsService, configurationService,
		appConfig, documentService, loadingIndicatorService) {
		'ngInject';
		this.$log = $log;
		this.$scope = $scope;
		this.appI18n = appI18n;
		this.reportsService = reportsService;
		this.configurationService = configurationService;
		this.appConfig = appConfig;
		this.documentService = documentService;
		this.loadingIndicatorService = loadingIndicatorService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('EcgSourceTracingController');
		this.$log.debug(' loaded');

		this.loadingIndicatorService.show();
		this.ecgSourceTracings = {};

		this.options = {
			gridRowActions: [{
				displayName: 'Reports',
				iconCssClass: 'es-icon es-icon-reports',
				enabledIf: (row) => {
					return row.documentId;
				},
				subItemsFactory: (row) => {
					if (row.ecgSourceReport) {
						return row.ecgSourceReport;
					}
					const ecgSourceReport = [];

					ecgSourceReport.push({
						displayName: 'ECG Source Report',
						iconFileType: 'pdf',
						action: () => {
							const fileName = this.documentService.getFileName(
								'ECG Source Report', row.collectionDateTime,
								row.sponsorSiteId, row.patientId1,
								row.fullVisitName, row.documentId);

							this.documentService.getEcgSourceTracingReport(
								row.documentId, fileName, 'pdf')
								.then((response) => {
									this.documentService.downloadReport(
										response.data, this.$scope,
										this.appConfig.downloadDocumentType.ecgReport,
										1, fileName, 'pdf');
								});
						}
					});

					row.ecgSourceReport = ecgSourceReport;

					return ecgSourceReport;
				}
			}],
			gridActions: [],

			getData: (oDataQuery) => {
				return this.getEcgSourceTracings(oDataQuery);
			}
		};

		this.activate();
	}

	activate() {
		this.configurationService.getSubjectDemographicSettings()
			.then((response) => {
				this.options.sortOrders = [];
				this.options.columnDefs = [];

				this.options.columnDefs.push({
					displayName: 'Principal Investigator',
					fieldName: 'siteName',
					isSortable: true,
					type: 'string',
					isDefaultFilter: true
				});
				this.options.sortOrders.push({
					fieldName: 'siteName'
				});

				this.options.columnDefs.push({
					translationKey: 'app.common.siteNumber',
					fieldName: 'sponsorSiteId',
					isSortable: true,
					type: 'string'
				});
				this.options.sortOrders.push({
					fieldName: 'sponsorSiteId'
				});

				this.options.columnDefs.push({
					translationKey: 'app.common.subjectKey',
					fieldName: 'subjectKey',
					isSortable: true,
					type: 'string'
				});

				if (!response.data.isSubjectId2Hidden) {
					let id2DisplayName = 'Screen #';

					if (response.data.fieldLabels[this.appConfig.demographics.subjectId2]) {
						id2DisplayName = response.data.fieldLabels[this.appConfig.demographics.subjectId2];
					}
					this.options.columnDefs.push({
						displayName: id2DisplayName,
						fieldName: 'patientId2',
						isSortable: true,
						type: 'string'
					});
				}

				if (!response.data.isSubjectId1Hidden) {
					if (response.data.fieldLabels[this.appConfig.demographics.subjectId1]) {
						this.options.columnDefs.push({
							displayName: response.data.fieldLabels[this.appConfig.demographics.subjectId1],
							fieldName: 'patientId1',
							isSortable: true,
							type: 'string'
						});
					} else {
						this.options.columnDefs.push({
							translationKey: 'app.common.subjectNumber',
							fieldName: 'patientId1',
							isSortable: true,
							type: 'string'
						});
					}
					this.options.sortOrders.push({
						fieldName: 'patientId1'
					});
				}
				if (!response.data.isDateOfBirthHidden) {
					let dobDisplayName = 'DOB';

					if (response.data.fieldLabels[this.appConfig.demographics.dob]) {
						dobDisplayName = response.data.fieldLabels[this.appConfig.demographics.dob];
					}
					this.options.columnDefs.push({
						displayName: dobDisplayName,
						fieldName: 'dateOfBirthDisplay',
						isSortable: true,
						type: 'string'
					});
				}
				if (!response.data.isInitialsHidden) {
					let initialsDisplayName = 'Init';

					if (response.data.fieldLabels[this.appConfig.demographics.initials]) {
						initialsDisplayName = response.data.fieldLabels[this.appConfig.demographics.initials];
					}
					this.options.columnDefs.push({
						displayName: initialsDisplayName,
						fieldName: 'initials',
						isSortable: true,
						type: 'string'
					});
				}
				if (!response.data.isGenderHidden) {
					let genderDisplayName = 'GNDR';

					if (response.data.fieldLabels[this.appConfig.demographics.gender]) {
						genderDisplayName = response.data.fieldLabels[this.appConfig.demographics.gender];
					}
					this.options.columnDefs.push({
						displayName: genderDisplayName,
						fieldName: 'gender',
						isSortable: true,
						type: 'string'
					});
				}
				if (!response.data.isEthnicityHidden) {
					let ethnicityDisplayName = 'Race';

					if (response.data.fieldLabels[this.appConfig.demographics.ethnicity]) {
						ethnicityDisplayName = response.data.fieldLabels[this.appConfig.demographics.ethnicity];
					}
					this.options.columnDefs.push({
						displayName: ethnicityDisplayName,
						fieldName: 'ethnicity',
						isSortable: true,
						type: 'string'
					});
				}

				this.options.columnDefs.push({
					displayName: 'Visit',
					fieldName: 'fullVisitName',
					isSortable: true,
					type: 'string'
				});
				this.options.sortOrders.push({
					fieldName: 'fullVisitName'
				});

				this.options.columnDefs.push({
					displayName: 'Collection Date/Time',
					fieldName: 'collectionDateTime',
					isSortable: true,
					type: 'date',
					formatter: 'dateTimeFormat'
				});
				this.options.sortOrders.push({
					fieldName: 'collectionDateTime',
					reverseOrder: true
				});

				this.options.columnDefs.push({
					displayName: 'Tr. #',
					fieldName: 'transmittalNumberText',
					isSortable: true,
					type: 'string'
				});
				this.options.sortOrders.push({
					fieldName: 'transmittalNumberText'
				});

				this.load = true;
			}).finally(() => {
				this.loadingIndicatorService.hide();
			});
	}

	getEcgSourceTracings(options) {
		return this.reportsService.getEcgSourceTracings(null, null, options);
	}
}

export default EcgSourceTracingController;
