/*
 * breadcrumbsController
 * */
class BreadcrumbsController {

	constructor($log, breadcrumbsService) {
		'ngInject';
		this.$log = $log;
		this.breadcrumbsService = breadcrumbsService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('BreadcrumbsController');
		this.$log.debug('loaded');
		this.unsubscribe = this.breadcrumbsService.subscribe((items) => {
			this.items = items;
		});
	}

	$onDestroy() {
		this.unsubscribe();
	}
}

export default BreadcrumbsController;
