class DataStatusFormsController {

	constructor($log, $q, $scope, $stateParams, appI18n, reportsService, configurationService,
		appConfig, documentService) {
		'ngInject';
		this.$q = $q;
		this.$log = $log;
		this.$stateParams = $stateParams;
		this.$scope = $scope;
		this.appI18n = appI18n;
		this.reportsService = reportsService;
		this.configurationService = configurationService;
		this.appConfig = appConfig;
		this.documentService = documentService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('DataStatusFormsController');
		this.$log.debug(' loaded');

		this.dataStatusForms = {};

		this.options = {
			columnDefs: [{
				displayName: 'Form',
				fieldName: 'formType',
				isSortable: true,
				type: 'string',
				isDefaultFilter: true
			}, {
				displayName: 'Posting Date/Time',
				fieldName: 'completeDateTime',
				isSortable: true,
				type: 'date',
				formatter: 'dateTimeFormat'
			}, {
				displayName: 'Principal Investigator',
				fieldName: 'investigatorName',
				isSortable: true,
				type: 'string',
				isDefaultFilter: true
			}, {
				translationKey: 'app.common.siteNumber',
				fieldName: 'sponsorSiteId',
				isSortable: true,
				type: 'string'
			}, {
				translationKey: 'app.common.subjectKey',
				fieldName: 'subjectKey',
				isSortable: true,
				type: 'string'
			}, {
				displayName: 'Demographics',
				fieldName: 'subjectDemographics',
				isSortable: true,
				type: 'string',
				formatter: 'pipe'
			}, {
				displayName: 'Visit',
				fieldName: 'fullVisitName',
				isSortable: true,
				type: 'string'
			}, {
				displayName: 'Collection Date/Time',
				fieldName: 'collectionDateTime',
				isSortable: true,
				type: 'date',
				formatter: 'dateTimeFormat'
			}, {
				displayName: 'Tr. #',
				fieldName: 'transmittalNumberText',
				isSortable: true,
				type: 'string'
			}],
			rowDefs: {
				multiSelection: true,
				identifier: 'documentId'
			},
			gridRowActions: [{
				displayName: 'Reports',
				iconCssClass: 'es-icon es-icon-reports',
				enabledIf: (row) => {
					return row.documentId;
				},
				subItemsFactory: (row) => {
					if (row.dataStatusFormReport) {
						return row.dataStatusFormReport;
					}
					const dataStatusFormReport = [];

					dataStatusFormReport.push({
						displayName: row.formType,
						iconFileType: 'pdf',
						action: () => {
							const fileName = this.documentService.getFileName(
								`Data Status Forms ${row.formType}`,
								row.collectionDateTime, row.sponsorSiteId,
								row.patientId1, row.fullVisitName, row.documentId);
							const report = {
								id: row.documentId,
								name: fileName,
								type: 'pdf'
							};

							this.documentService.getDataStatusFormsReport([report])
								.then((response) => {
									this.documentService.downloadReport(
										response.data, this.$scope,
										this.appConfig.downloadDocumentType.dataStatusFormsReport);
								});
						}
					});

					row.dataStatusFormReport = dataStatusFormReport;

					return dataStatusFormReport;
				}
			}],
			gridActions: [{
				displayName: this.appI18n.translateImmediate('reports.data-status-forms.download'),
				subItems: [{
					displayName: this.appI18n.translateImmediate('reports.data-status-forms.downloadSelected'),
					action: () => {
						if (!this.selectedRows) {
							return;
						}
						const reports = this.formatReportsPayload(this.selectedRows);

						this.documentService.getDataStatusFormsReport(reports).then((response) => {
							this.documentService.downloadReport(
								response.data, this.$scope,
								this.appConfig.downloadDocumentType.dataStatusFormsReport,
								reports.length);
						});
					},
					enabledIf: () => {
						return this.selectedRows && this.selectedRows.length > 0;
					}
				}, {
					displayName: this.appI18n.translateImmediate('reports.data-status-forms.downloadAll'),
					action: () => {
						const url = this.getQueryString();

						this.getDataStatusForms(url, (response) => {
							const reports = this.formatReportsPayload(response);

							this.documentService.getDataStatusFormsReport(reports).then((response) => {
								this.documentService.downloadReport(
									response.data, this.$scope,
									this.appConfig.downloadDocumentType.dataStatusFormsReport,
									reports.length);
							});
						}, (err) => {
							this.$log.debug(`Error while downloading the reports ${err}`);
						});

					},
					enabledIf: () => {
						return this.options.rowData && this.options.rowData.length > 0;
					}
				}]
			}],

			sortOrders: [{
				fieldName: 'completeDateTime'
			}],

			getData: (options) => {
				return this.$q((resolve, reject) => {
					this.getDataStatusForms(options, resolve, reject);
				});
			}
		};

		this.activate();
	}


	activate() {
		this.load = true;
	}

	getDataStatusForms(options, resolve, reject) {
		this.dataLoading = true;
		this.loadingError = '';
		this.reportsService.getDataStatusForms(null, null, options)
			.then((response) => {
				this.model = response.data;
				resolve(this.model);
			}, (error) => {
				this.loadingError = error.data.message || error.data;
				reject(this.loadingError);
			});
	}

	formatReportsPayload(reports) {
		return reports.map((r) => {
			return {
				id: r.documentId,
				name: this.documentService.getFileName(`Data Status Forms ${r.formType}`, r.collectionDateTime,
													   r.sponsorSiteId, r.patientId1, r.fullVisitName, r.documentId),
				type: 'pdf'
			};
		});
	}

	getQueryString() {
		const filterString = (this.$stateParams.filter) ? `$filter=${this.$stateParams.filter}` : '';
		const orderString = (this.$stateParams.orderBy) ? `$orderby=${this.$stateParams.orderBy}` : '';
		const union = (filterString && orderString) ? '&' : '';

		return `${filterString}${union}${orderString}`;
	}
}

export default DataStatusFormsController;
