(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('CreateSubjectController', CreateSubjectController);

    /*
     * @ngInject
     */
    function CreateSubjectController(
		$log, $stateParams, $timeout, $scope, eproService, iSpiroService,
		configurationService, appConfig,
		signatureService, appI18n, dialogService, loadingIndicatorService, ertBasePopupService,
		clinicalDataService, notificationService) {

        var vm = this;

        vm.currentStep = "";

        vm.doSubmit = doSubmit;
        vm.signSubmit = signSubmit;
        vm.onEthnicityChange = onEthnicityChange;
        vm.onGenderChange = onGenderChange;
        vm.onLanguageChange = onLanguageChange;
        vm.onScheduleChange = onScheduleChange;
        vm.onSiteChange = onSiteChange;
        vm.onTimezoneChange = onTimezoneChange;
        vm.setRolloverTime = setRolloverTime;
        vm.showCreateSubject = showCreateSubject;
        vm.showEditSubject = showEditSubject;
        vm.validateAuth1 = validateAuth1;
        vm.validateAuth2 = validateAuth2;
        vm.validateDob = validateDob;
        vm.validateEmailPin = validateEmailPin;
        vm.validateInitials = validateInitials;
        vm.validateRollover = validateRollover;
        vm.validateSubjectId = validateSubjectId;
        vm.validateSubjectId2 = validateSubjectId2;
        vm.checkFirstStepValid = checkFirstStepValid;
        vm.validateCompleteDate = validateCompleteDate;
        vm.validateCompleteTime = validateCompleteTime;
        vm.validateParametersThreshold = validateParametersThreshold;

        $log = $log.getInstance("CreateSubjectController");
        $log.debug(" loaded");

        let cancelBtnLabel, nextBtnLabel;

        appI18n.translate([
            'clinicaldata.subject.create.errors.required.general',
            'app.common.subject-information',
            'app.buttons.cancel',
            'app.buttons.next',
            'app.buttons.sign-submit'
        ]).then(function(translations) {
            vm.isRequiredText = translations['clinicaldata.subject.create.errors.required.general'];
            vm.subjectInfoTitle = translations['app.common.subject-information'];
            cancelBtnLabel = translations['app.buttons.cancel'];
            nextBtnLabel = translations['app.buttons.sign-submit'];
            activate();
        });

        function initWatchers() {
            $scope.$watch(function() {
                return vm.ethnicity;
            }, adoptEthnicity);
            $scope.$watch(function() {
                return vm.gender;
            }, adoptGender);
            $scope.$watch(function() {
                return vm.language;
            }, adoptLanguage);
            $scope.$watch(function() {
                return vm.schedule;
            }, adoptSchedule);
            $scope.$watch(function() {
                return vm.site;
            }, adoptSite);
            $scope.$watch(function() {
                return vm.timezone;
            }, adoptTimezone);
		}

        function adoptEthnicity() {
          if (vm.model) {
            vm.model.ethnicityId = vm.ethnicity && vm.ethnicity.id;
          };
        }

        function adoptGender() {
            if (vm.model) {
                vm.model.genderId = vm.gender && vm.gender.id;
            }
        }

        function adoptLanguage() {
            if (vm.model) {
                vm.model.languageId = vm.language && vm.language.id;
            }
        }

        function adoptSchedule() {
            if (vm.model) {
                vm.model.scheduleId = vm.schedule && vm.schedule.id;
            }
        }

        function adoptSite() {
            if (vm.model) {
                vm.model.siteId = vm.site && vm.site.id;
            }

            //When creating a subject, adopt timezone from site
            if (!vm.isEdit && vm.site && vm.site.timezoneId) {
                vm.timezone = getTimezoneById(vm.site.timezoneId);
                $timeout(function() {
                    validateTimezone();
                });
            }
        }

        function adoptTimezone() {
            if (vm.model) {
                vm.model.timezoneId = vm.timezone && vm.timezone.id;
            }
        }

        function onEthnicityChange() {
            clearError();
            validateEthnicity();
        }

        function onGenderChange() {
            clearError();
            validateGender();
        }

        function onLanguageChange() {
            clearError();
            validateLanguage();
        }

        function onScheduleChange() {
            clearError();
            validateSchedule();
        }

        function onSiteChange() {
            clearError();
            validateSiteId();
        }

        function onTimezoneChange() {
            clearError();
            validateTimezone();
        }

        function closeCreateEditSubject() {
            clinicalDataService.closeCreateSubject();
        }

        function showCreateSubject() {
          vm.showForm = false;
          loadingIndicatorService.show();
          vm.isEdit = false;
          vm.sigType = appConfig.sigType.createSubject;
          initForm();

          configurationService.getStudySettings().then((data) => {
            vm.isISpiroStudy = data.isiSpiroStudy;

            getCreateSubjectData($stateParams.country, $stateParams.site).then((response) => {
                handleCreateEditData(response.data);
                if (!resetStateAndSubmit($stateParams.signKey, $stateParams.tokenId)) {
                  loadingIndicatorService.hide();
                  vm.showForm = true;
                }
              }, () => {
                setError("clinicaldata.subject.create.errors.genericLoadError");
                loadingIndicatorService.hide();
              }
            );
          });
        }

        function getCreateSubjectData(country, site) {
          if(vm.isISpiroStudy) {
            return iSpiroService.getCreateSubjectData(country, site);
          } else {
            return eproService.getCreateSubjectData(country, site);
          }
        }

      function showEditSubject(row) {
        vm.showForm = false;
        loadingIndicatorService.show();

        vm.eproSubjectId = row.eproSubjectId || 0;
        vm.subjectId = row.subjectId;
        vm.siteId = row.siteId;
        vm.isEdit = true;
        vm.sigType = appConfig.sigType.editSubject;
        initForm();

        let editSbj;

        configurationService.getStudySettings().then((data) => {
          vm.isISpiroStudy = data.isiSpiroStudy;

          if(vm.isISpiroStudy) {
            editSbj = iSpiroService.getEditSubjectData($stateParams.country, vm.siteId, vm.subjectId);
          } else {
            editSbj = eproService.getEditSubjectData($stateParams.country, vm.siteId, vm.eproSubjectId, vm.subjectId);
          }

          editSbj.then(
            function (response) {
              handleCreateEditData(response.data);
              initModelForEdit(response.data);
              if (!resetStateAndSubmit($stateParams.signKey, $stateParams.tokenId)) {
                loadingIndicatorService.hide();
                vm.showForm = true;
              }
            },
            function () {
              setError("clinicaldata.subject.create.errors.genericLoadError");
              loadingIndicatorService.hide();
            }
          );
        });
      }

        function resetStateAndSubmit(signKey, tokenId) {
			if (signKey) {
				vm.state = signatureService.getState($stateParams.signKey);
				// reset state
				if (vm.state) {
					signatureService.removeState($stateParams.signKey);

					vm.ethnicity = vm.state.ethnicity;
					vm.ethnicities = vm.state.ethnicities;
					vm.gender = vm.state.gender;
					vm.genders = vm.state.genders;
					vm.language = vm.state.language;
					vm.languages = vm.state.languages;
					vm.schedule = vm.state.schedule;
					vm.schedules = vm.state.schedules;
					vm.site = vm.state.site;
					vm.sites = vm.state.sites;
					vm.timezone = vm.state.timezone;
					vm.timezones = vm.state.timezones;

					$timeout(() => {
						vm.model = vm.state.model;
						doSubmit();
					});
				}
			}
			return !!tokenId;
		}

        function activate() {
          initWatchers();
          configurationService.getStudySettings().then(function (data) {
              vm.hasEcoaHandheld = data.hasEcoaHandheld;
              vm.isISpiroStudy = data.isiSpiroStudy;
          });
          clinicalDataService.setCreateSubjectHandler(vm);
          clinicalDataService.setEditSubjectHandler(vm);

          $scope.$on('$destroy', () => {
            clinicalDataService.setCreateSubjectHandler();
            clinicalDataService.setEditSubjectHandler();
          });
          vm.actions = [
            {
              action: signSubmit,
              displayName: nextBtnLabel,
              cssClass: '-es-primary'
            },
            {
              action: closeCreateEditSubject,
              displayName: cancelBtnLabel,
              iconName: 'close'
            }
          ];
        }

        function initForm() {
            vm.createEditSubjectActive = true;

            vm.ethnicity = null;
            vm.ethnicities = null;
            vm.gender = null;
            vm.genders = null;
            vm.language = null;
            vm.languages = null;
            vm.model = {};
            vm.schedule = null;
            vm.schedules = null;
            vm.site = null;
            vm.sites = null;
            vm.timezone = null;
            vm.timezones = null;

            vm.fields = [];
            vm.model.isRollover = 1;
            vm.data = {};
            vm.model.enforceVisitWindows = true;
            vm.validation = {
                required: {},
                errors: {}
            };
            vm.errorMsg = null;
        }

        function handleCreateEditData(data) {
            vm.data = data;
            vm.sites = data.sites;
            vm.schedules = data.schedules;
            vm.timezones = data.timezones;
            vm.languages = data.languages;
            vm.ethnicities = data.ethnicities;
            vm.fields = data.fields;

	        vm.dobPlaceHolder = getIsPartialDob() ? '' : 'DD-MMM-YYYY';

            vm.genders = [{
                    "name": "Male",
                    "id": 1
                },
                {
                    "name": "Female",
                    "id": 2
                }
            ];

            if(!vm.isISpiroStudy) {
              if (!vm.schedules || vm.schedules.length === 0) {
                setError("clinicaldata.subject.create.errors.noSchedule");
              } else if (vm.schedules.length === 1) {
                vm.schedule = vm.schedules[0];
              }
            }

            if (data.showInactiveWarning) {
                showInactiveWarning();
            }

            if (vm.sites.length === 0) {
                setError("clinicaldata.subject.create.errors.noSites");
            } else if (vm.sites.length === 1) {
                vm.site = vm.sites[0];

            }
            if (vm.languages.length === 1) {
                vm.language = vm.languages[0];
            }
            if (!data.canRollover) {
                vm.model.isRollover = 0;
            }
            //set timezone depending on the sites timezone per default
            if (!vm.timezone && vm.site && vm.site.timezoneId) {
                vm.timezone = getTimezoneById(vm.site.timezoneId);
            }
        }

        function getIsPartialDob() {
		    var dobField = getField(2);
		    if (!dobField) {
			    return false;
		    }

		    if (!dobField.formatHint) {
			    return false;
		    }

		    return dobField.formatHint.indexOf("DD-MMM-YYYY") === -1;
	    }

        function initModelForEdit(data) {
            var subject = data.eproSubject || data.subjectData;
            vm.originalSiteId = subject.siteId;
            vm.site = (subject.siteId) ? getSiteById(subject.siteId) : vm.site;
            vm.schedule = (subject.eproVisitScheduleId) ? getScheduleById(subject.eproVisitScheduleId) : vm.schedule;
            vm.timezone = (subject.timezoneId && subject.timezoneId !== '0') ?
                getTimezoneById(subject.timezoneId) :
                vm.timezone;
            vm.originalLanguageId = subject.languageId;
            vm.language = (subject.languageId) ? getLanguageById(subject.languageId) : vm.language;
            vm.ethnicity = (subject.ethnicityId) ? getEthnicityById(subject.ethnicityId) : vm.ethnicity;
            // for read only fields, web api may not return all other ethnicities,
            // so getEthnicity may fail in this case
            if (subject.ethnicityId && !vm.ethnicity) {
                vm.ethnicity = {
                    id: subject.ethnicityId,
                    name: subject.ethnicity
                };
            }
            vm.gender = (subject.genderId) ? getGenderById(subject.genderId) : vm.gender;

            vm.model.initials = subject.initials;
            vm.model.dob = subject.dateOfBirth;
            vm.model.subjectId = subject.subjectId1;
            vm.model.subjectId2 = subject.subjectId2;
            vm.model.auth1 = subject.auth1;
            vm.model.auth2 = subject.auth2;
            vm.model.initials = subject.initials;

        }

        function showSaveDelayWarning() {
            clinicalDataService.closeCreateSubject();

            appI18n.translate(['clinicaldata.subject.create.saveDelay']).then((translations) => {
                const msg = translations['clinicaldata.subject.create.saveDelay'];

                notificationService.showSuccess(msg);
            });

            return false;
        }

        function showSaveDelayWarningUpdate() {
            clinicalDataService.closeCreateSubject();

            appI18n.translate(['clinicaldata.subject.create.saveDelayUpdate']).then((translations) => {
                const msg = translations['clinicaldata.subject.create.saveDelayUpdate'];

                notificationService.showSuccess(msg);
            });

            return false;
        }

        function showInactiveWarning() {
            vm.confirmInactiveDelayHandler = dialogService.createConfirmHandler();
            vm.confirmInactiveDelayHandler.show().then(function(result) {
                if (result) {
                    //ok, just close
                } else {
                    clinicalDataService.closeCreateSubject();
                }
                vm.confirmInactiveDelayHandler.hide();
            });
        }

        function getField(fieldId) {
            for (var i = 0; i < vm.fields.length; ++i) {
                if (Number(vm.fields[i].id) === fieldId) {
                    return vm.fields[i];
                }
            }

            return undefined;
        }

        function validateFieldFormat(fieldId, value) {
            var field = getField(fieldId);

            if (field && !field.isReadOnly && field.format) {
                var matches = value.match(field.format);

                if (!matches) {
                    return field.formatHint || "Invalid format";
                }
            }

            return false;
        }

        function validateSubjectFormat(subjectId, siteId) {
            var field = getField(3);
            var unPrefixedSubjectId = subjectId;
            var site = getSiteById(siteId);

            if (field.isSitePrefix && site) {
                if (!subjectId.startsWith(site.sponsorSiteId)) {
                    return appI18n.translateImmediate('clinicaldata.subject.create.errors.sitePrefix');
                }
                // format regex checks full id, but range only the unprefixed part
                unPrefixedSubjectId = subjectId.substring(site.sponsorSiteId.length);
            }

            if (field && field.format) {
                var matches = subjectId.match(field.format);

                if (!matches) {
                    return field.formatHint || "Invalid format";
                }
            }

            if (site && site.rangeHigh && site.rangeLow) {
                if (Number(unPrefixedSubjectId) < Number(site.rangeLow) ||
                    Number(unPrefixedSubjectId) > Number(site.rangeHigh)) {
                    return "Subject ID needs to be between " + site.rangeLow + " and " + site.rangeHigh;
                }
            }
            return true;
        }

        function getScheduleById(scheduleId) {
            for (var i = 0; i < vm.schedules.length; ++i) {
                if (Number(vm.schedules[i].id) === Number(scheduleId)) {
                    return vm.schedules[i];
                }
            }
            return null;
        }

        function getSiteById(siteId) {
            for (var i = 0; i < vm.sites.length; ++i) {
                if (Number(vm.sites[i].id) === Number(siteId)) {
                    return vm.sites[i];
                }
            }
            return null;
        }

        function getEthnicityById(ethnicityId) {
            if (vm.ethnicities && ethnicityId) {
                for (var i = 0; i < vm.ethnicities.length; ++i) {
                    if (Number(vm.ethnicities[i].id) === Number(ethnicityId)) {
                        return vm.ethnicities[i];
                    }
                }
            }
            return null;
        }

        function getLanguageById(languageId) {
            if (vm.languages && languageId) {
                for (var i = 0; i < vm.languages.length; ++i) {
                    if (Number(vm.languages[i].id) === Number(languageId)) {
                        return vm.languages[i];
                    }
                }
            }
            return null;
        }

        function getGenderById(genderId) {
            if (vm.genders && genderId) {
                for (var i = 0; i < vm.genders.length; ++i) {
                    if (Number(vm.genders[i].id) === Number(genderId)) {
                        return vm.genders[i];
                    }
                }
            }
            return null;
        }

        function getTimezoneById(timezoneId) {
            if (vm.timezones) {
                for (var i = 0; i < vm.timezones.length; ++i) {
                    if (Number(vm.timezones[i].id) === Number(timezoneId)) {
                        return vm.timezones[i];
                    }
                }
            }
            return null;
        }

        function isReadOnlyField(fieldId) {
            var field = getField(fieldId);

            if (field) {
                return field.isReadOnly;
            }

            return true;
        }

        function isRequiredField(fieldId) {
            var field = getField(fieldId);

            if (field) {
                return field.isRequired;
            }

            return false;
        }

        function signSubmit() {

            var isValid = validateForm();
			if (!isValid) {
				return;
			}

			// save state for signature process
			vm.state = {
				model: vm.model,
				ethnicity: vm.ethnicity,
				ethnicities: vm.ethnicities,
				gender: vm.gender,
				genders: vm.genders,
				language: vm.language,
				languages: vm.languages,
				schedule: vm.schedule,
				schedules: vm.schedules,
				site: vm.site,
				sites: vm.sites,
				timezone: vm.timezone,
				timezones: vm.timezones,
				row: {
					eproSubjectId: vm.eproSubjectId || 0,
					subjectId: vm.subjectId,
					siteId: vm.siteId
				},
				sigType: vm.sigType
			};

			vm.additionalInfo = {
				action: vm.isEdit ?
					appI18n.translateImmediate('signature.action.editSubject') : appI18n.translateImmediate('signature.action.createSubject'),
				infoKey1: appI18n.translateImmediate('signature.info.site'),
				infoValue1: vm.site.sponsorSiteId,
				infoKey2: appI18n.translateImmediate('signature.info.subject'),
				infoValue2: vm.model.subjectId

			};

			signatureService.sign(vm.sigType, vm.additionalInfo, vm.state);

		}

      function doSubmit() {
        vm.model.signKey = $stateParams.signKey;
        vm.model.signToken = $stateParams.tokenId;

        if (!signatureService.validateSignature(vm.model.signToken, vm.model.signKey)) {
          return;
        }

        if (vm.isEdit) {
          doUpdateSubject();
        } else {
          doCreateSubject();
        }
      }

      function doUpdateSubject() {
        var isValid = validateForm();

        if (isValid) {
          let editSbj;

          loadingIndicatorService.show();

          configurationService.getStudySettings().then(function (data) {
            vm.isISpiroStudy = data.isiSpiroStudy;
            if (vm.isISpiroStudy) {
              editSbj = iSpiroService.editSubject(vm.model, vm.subjectId);
            } else {
              editSbj = eproService.editSubject(vm.model, vm.eproSubjectId, vm.subjectId);
            }

            editSbj.then(function (response) {
              if (response.data.isValidationError) {
                setGenericSaveError(response);
              } else {
                showSaveDelayWarningUpdate();
                // calls qr code popup
                  ertBasePopupService.popup('iSpiroPopup')
					  .open(null, vm.siteId, vm.subjectId, this.appConfig.iSpiroActionId.dispense,
							vm.site.sponsorSiteId, vm.model.subjectId);
              }
            }, function (response) {
              setGenericSaveError(response);
            }).finally(() => {
              signatureService.removeSignatureAndState(vm.model.signKey);
              loadingIndicatorService.hide();
            });
          });
        }
      }

		function setGenericSaveError(response) {
			vm.showForm = true;
			clearError();
			var msg = (response.data && response.data.translationKey) ?
                response.data.translationKey : "clinicaldata.subject.create.errors.genericSaveError";
            if (response.data.fieldKeys && response.data.fieldKeys.length > 0) {
                var showUnexpectedError = false;
                response.data.fieldKeys.forEach(function(el) {
                    if (isFieldValidationAvailable(el)) {
                        vm.validation.errors[el] = appI18n.translateImmediate(msg);
                    } else {
                        showUnexpectedError = true;
                    }
                });

                if (showUnexpectedError) {
                    setUnexpectedError(msg);
                }


            } else {
                setError(msg);
            }
        }

        function isFieldValidationAvailable(key) {
            switch (key) {
                case 'initials':
                    return isFieldEditable(1);
                case 'dob':
                    return isFieldEditable(2);
                case 'subjectId':
                    return isFieldEditable(3);
                case 'subjectId2':
                    return isFieldEditable(4);
                case 'ethnicity':
                    return isFieldEditable(5);
                case 'gender':
                    return isFieldEditable(6);
                case 'auth1':
                    return isFieldEditable(61);
                case 'auth2':
                    return isFieldEditable(62);
                case 'emailPin':
                    return vm.data.canSendPin;
                case 'completeDate':
                    return vm.data.canRollover;
                case 'completeTime':
                    return vm.data.canRollover;
                default:
                    return false;
            }

        }

        function isFieldEditable(fieldId) {
            var field = getField(fieldId);

            if (field) {
                return !field.isReadOnly;
            }
            return false;
        }

      function doCreateSubject() {
        var isValid = validateForm();

        if (isValid) {
          let createSubject;

          loadingIndicatorService.show();

          configurationService.getStudySettings().then((data) => {
            vm.isISpiroStudy = data.isiSpiroStudy;

            if (vm.isISpiroStudy) {
              createSubject = iSpiroService.createSubject(vm.model);
            } else {
              createSubject = eproService.createSubject(vm.model);
            }

            createSubject.then(
              function (response) {
                if (response.data.isValidationError) {
                  setGenericSaveError(response);
                } else {
                  showSaveDelayWarning();
                }
              },
              function (response) {
                setGenericSaveError(response);
              }).finally(() => {
              signatureService.removeSignatureAndState(vm.model.signKey);
              loadingIndicatorService.hide();
            });
          });
        }
      }

		function validateForm(onlyValidate) {

			if (vm.isISpiroStudy) {
			  const fields = getParameterThresholdFields();

              fields.forEach((field) => {
                validateParametersThreshold(field.name, field.isReadOnly, field.isRequired, field.minimumValue,
                  field.maximumValue, field.numberOfDecimals);
              });

              validateDob(true);
              validateSiteId(true);
              validateLanguage();
              validateSubjectId(true);
              validateSubjectId2(true);
              validateAuth1(true);
              validateAuth2(true);
              validateInitials(true);
              validateEthnicity();
              validateGender();

            } else {
              validateDob(true);
              validateSiteId(true);
              validateSchedule();
              validateTimezone();
              validateLanguage();
              validateSubjectId(true);
              validateSubjectId2(true);
              validateAuth1(true);
              validateAuth2(true);
              validateInitials(true);
              validateEthnicity();
              validateGender();
              validateEmailPin(true);
              validateRollover();
              validateCompleteDate(true);
              validateCompleteTime(true);
            }

            for (var req in vm.validation.required) {
                if (vm.validation.required[req]) {
                    return false;
                }
            }

            for (var err in vm.validation.errors) {
                if (vm.validation.errors[err]) {
                    return false;
                }
            }
            return true;
        }

        function checkFirstStepValid() {
            return validateForm(true);
        }

        function validateSchedule() {
            if (!vm.data.canEditSchedule) {
                return;
            }

            vm.validation.required.schedule = false;
            if (!vm.model.scheduleId) {
                vm.validation.required.schedule = true;
            }
        }

        function validateTimezone() {
            if (!vm.model.timezoneId) {
                vm.validation.required.timezone = true;
            } else {
                vm.validation.required.timezone = false;
            }
        }

        function validateLanguage() {
            if (!vm.model.languageId) {
                vm.validation.required.language = true;
            } else {
                vm.validation.required.language = false;
            }
        }

        function validateAuth1(validateEmpty) {
            if (isReadOnlyField(61)) {
                return;
            }

            if (validateEmpty && !vm.model.auth1) {
                if (isRequiredField(61)) {
                    vm.validation.required.auth1 = true;
                }
            } else if (vm.model.auth1) {
                var error = validateFieldFormat(61, vm.model.auth1);
                vm.validation.errors.auth1 = error;
                vm.validation.required.auth1 = false;
            }
        }

        function validateAuth2(validateEmpty) {
            if (isReadOnlyField(62)) {
                return;
            }

            if (validateEmpty && !vm.model.auth2) {
                if (isRequiredField(62)) {
                    vm.validation.required.auth2 = true;
                }
            } else if (vm.model.auth2) {
                var error = validateFieldFormat(62, vm.model.auth2);
                vm.validation.errors.auth2 = error;
                vm.validation.required.auth2 = false;
            }
        }

        function validateInitials(validateEmpty) {
            if (isReadOnlyField(1)) {
                return;
            }

            if (validateEmpty && !vm.model.initials) {
                if (isRequiredField(1)) {
                    vm.validation.required.initials = true;
                }
            } else if (vm.model.initials) {
                var error = validateFieldFormat(1, vm.model.initials);
                vm.validation.errors.initials = error;
                vm.validation.required.initials = false;
            }
        }

        function validateSubjectId2(validateEmpty) {
            if (isReadOnlyField(4)) {
                return;
            }

            vm.validation.required.subjectId2 = false;
            if (validateEmpty && !vm.model.subjectId2) {
                if (isRequiredField(4)) {
                    vm.validation.required.subjectId2 = true;
                }
            } else {
                var error = validateFieldFormat(4, vm.model.subjectId2);
                vm.validation.errors.subjectId2 = error;
                vm.validation.required.subjectId2 = false;
            }
        }

        function validateSubjectId(validateEmpty) {
            if (isReadOnlyField(3)) {
                return;
            }

            if (validateEmpty && !vm.model.subjectId) {
                if (isRequiredField(3)) {
                    vm.validation.required.subjectId = true;
                }
            } else if (vm.model.subjectId) {
                var result = validateSubjectFormat(vm.model.subjectId, vm.model.siteId);
                if (result !== true) {
                    vm.validation.errors.subjectId = result;
                } else {
                    vm.validation.errors.subjectId = undefined;
                }
                vm.validation.required.subjectId = false;
            }
        }

        function getParameterThresholdFields() {
          return vm.fields.filter((f) => {
            return f.category === 'ISpiroParameter';
          });
        }

        function validateParametersThreshold(fieldName, isReadOnly, validateEmpty, min, max, decimals) {
          if (isReadOnly) { return; }
          const value = vm.model[fieldName];

          vm.validation.errors[fieldName] = undefined;
          vm.validation.required[fieldName] = false;

          if (validateEmpty && value === null) {
            vm.validation.required[fieldName] = true;
          } else if (angular.isUndefined(value)) {
            vm.validation.errors[fieldName] = 'Only numeric values are allowed.';
          } else {
            var result = validateParametersThresholdFormat(value, min, max, decimals);
            if (result !== true) {
              vm.validation.errors[fieldName] = result;
            }
          }
        }

        function validateParametersThresholdFormat(value, min, max, decimals) {
          if(value < +min || value > +max) {
            return `The value must be between ${min} and ${max}. `;
          }

          var fraction = `${value}`.split('.');
          var decimalsNr = fraction[1] && fraction[1].length;

          if (decimalsNr && decimalsNr > decimals) {
            return `Maximum number of decimals allowed is ${decimals}.`;
          }

          return true;
        }

        function validateGender() {
            if (isReadOnlyField(6)) {
                return;
            }

            vm.validation.required.gender = false;
            if (!vm.model.genderId) {
                if (isRequiredField(6)) {
                    vm.validation.required.gender = true;
                }
            }
        }

        function validateEthnicity() {
            if (isReadOnlyField(5)) {
                return;
            }

            vm.validation.required.ethnicity = false;
            if (!vm.model.ethnicityId) {
                if (isRequiredField(5)) {
                    vm.validation.required.ethnicity = true;
                }
            }
        }

        function validateEmailPin(validateEmpty) {
            if (!vm.data.canSendPin) {
                return;
            }

            if (validateEmpty && !vm.model.emailPin) {
                vm.validation.required.emailPin = true;
            } else if (vm.model.emailPin) {
                // validate email
                if (!(/(.+)@(.+){2,}\.(.+){2,}/.test(vm.model.emailPin))) {
                    vm.validation.errors.emailPin = "Invalid email";
                } else {
                    vm.validation.errors.emailPin = undefined;
                }
                vm.validation.required.emailPin = false;
            }
        }

        function getTimezoneOffset(timezone) {
            if (!timezone || !timezone.offset || timezone.offset.length !== 6) {
                return 0;
            }

            var offsetSign = timezone.offset.substring(0, 1);
            var offsetHours = timezone.offset.substring(1, 3);
            var offsetMinutes = timezone.offset.substring(4, 7);

            var hours = parseInt(offsetHours, 10);
            var minutes = parseInt(offsetMinutes, 10);
            var offset = hours * 60 + minutes;

            if (offsetSign === "-") {
                offset = -offset;
            }

            return offset;
        }

        function setRolloverTime() {
            var timezone = getTimezoneById(vm.model.timezoneId);
            var offset = 0;

            if (timezone) {
                offset = getTimezoneOffset(timezone);
            }

            var MS_PER_MINUTE = 60000;
            var date = new Date();
            date = new Date(date.getTime() + (offset + date.getTimezoneOffset()) * MS_PER_MINUTE);

            vm.model.completeTime = padNumber(date.getHours()) + ":" + padNumber(date.getMinutes());
            vm.validateCompleteTime(true);
        }

        function padNumber(num) {
            var s = "0" + num;
            return s.substr(s.length - 2);
        }

        function validateRollover() {
            clearError();

            vm.validation.required.rollover = false;
            if (vm.model.isRollover === undefined) {
                vm.validation.required.rollover = true;
            }
        }

        function validateCompleteDate(validateEmpty) {
            clearError();

            vm.validation.required.completeDate = false;
            vm.validation.errors.completeDate = null;

            if (validateEmpty && !vm.model.completeDate && vm.model.isRollover === 1) {
                vm.validation.required.completeDate = true;
            } else if (vm.model.isRollover === 1 && vm.model.completeDate) {
                const parsedDate = moment(vm.model.completeDate, 'DD-MMM-YYYY', true);

                if (!parsedDate.isValid()) {
                    vm.validation.errors.completeDate = 'Invalid format';
                }
            }
        }

        function validateCompleteTime(validateEmpty) {
            clearError();

            vm.validation.required.completeTime = false;
            vm.validation.errors.completeTime = null;
            if (validateEmpty && !vm.model.completeTime && vm.model.isRollover === 1) {
                vm.validation.required.completeTime = true;
            } else if (vm.model.isRollover === 1 && vm.model.completeTime) {
                var parseSuccess = parseTime(vm.model.completeTime);
                if (!parseSuccess) {
                    vm.validation.errors.completeTime = 'Invalid format';
                }
            }
        }

        // Parse date in "HH:mm" format. If fails to parse - false is returned.
        function parseTime(str) {
            var re = /^(\d{2}):(\d{2})$/i;
            try {
                var matches = str.match(re); // Now match dates, from parts create dates.

                if (matches.length !== 3) {
                    return null;
                }

                if (matches[1] >= 24 || matches[1] < 0) {
                    return false;
                }

                if (matches[2] >= 60 || matches[2] < 0) {
                    return false;
                }
            } catch (err) {
                return false;
            }
            return true;
        }

        function validateSiteId() {
            clearError();

            if (!vm.data.canEditSite) {
                return;
            }
            if (vm.model.siteId > 0) {
                vm.validation.required.siteId = false;
                validateSubjectId();
            } else {
                vm.validation.required.siteId = true;
            }
        }

        function validateDob(validateEmpty) {
            clearError();

            if (isReadOnlyField(2)) {
                return;
            }

            if (validateEmpty && !vm.model.dob) {
                if (isRequiredField(2)) {
                    vm.validation.required.dob = true;
                }
            } else if (vm.model.dob) {
                vm.validation.required.dob = false;
                var error = validateFieldFormat(2, vm.model.dob);
                vm.validation.errors.dob = error;
            } else {}
        }

        function clearError() {
            vm.hasErrors = false;
            vm.errorMsg = null;
        }

        function setError(msg) {
			loadingIndicatorService.hide();
            vm.hasErrors = true;
            vm.errorMsg = appI18n.translateImmediate(msg);
        }

        function setUnexpectedError(msg) {
			loadingIndicatorService.hide();
            vm.hasErrors = true;
            vm.errorMsg = appI18n.translateImmediate('clinicaldata.subject.edit.unexpectedEditError') + appI18n.translateImmediate(msg);
        }
    }
})();
