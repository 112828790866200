import templateUrl from './dcrDetailsPopup.html';
import controller from './dcrDetailsPopup.controller';
import './dcrDetailsPopup.scss';

const dcrDetailsPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default dcrDetailsPopupComponent;
