import DeviceManagementSiteController from './deviceManagement.site.controller';
import DeviceManagementSubjectController from './deviceManagement.subject.controller';
import DeviceManagementController from './deviceManagement.controller';
import DeviceManagementCodePopupDirective from './deviceManagement.code.popup.directive';
import DeviceManagementDeviceInfoPopup from './deviceInfoPopup/deviceInfoPopup.component';
import DeviceManagementMyDevicePopup from './myDeviceAccount/myDeviceAccount.component';

export default angular.module('app.deviceManagement', [])
	.controller(DeviceManagementController.id(), DeviceManagementController)
	.controller(DeviceManagementSubjectController.id(), DeviceManagementSubjectController)
	.controller(DeviceManagementSiteController.id(), DeviceManagementSiteController)
	.component('deviceInfoPopup', DeviceManagementDeviceInfoPopup)
	.component('myDeviceAccountPopup', DeviceManagementMyDevicePopup)
	.directive('ertDeviceManagementPopup', DeviceManagementCodePopupDirective).name;
