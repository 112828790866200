(function () {
	'use strict';

	angular.module('portalApp')
		.directive('ertList', List);

	angular.module('portalApp')
		.controller('ertListController', ListController);


	function List() {
		return {
			bindToController: true,
			controller: 'ertListController',
			controllerAs: 'vm',
			restrict: 'E',
			scope: {
				columns: '=?',
				data: '=?',
				options: '=?',
				onDblClickSelect: '&',
				onSelect: '&?',
				selectedItem: '=?'
			},
			templateUrl: 'scripts/directives/list/ertList.html'
		};
	}

	/** @ngInject **/
	function ListController($scope, $log) {
		var defaults = {
			hasFilter: true,
			hasSelectableItems: true,
			numMoreItems: 50,
			numMaxItems: 500,
			scrollContext: 'window',
			showLimitedItems: false,
			showMoreOnScroll: false
		},vm = this;

		vm.options = vm.options || defaults;
		vm.selectItem = selectItem;
		vm.filter = filter;

		$log = $log.getInstance('ListController');
		$log.debug(' loaded');

		activate();
		function activate() {
			$scope.$watchCollection(function () {
				return vm.data;
			}, parseData);
			$scope.$watch(function () {
				return vm.options;
			}, parseOptions);
		}
		function parseData(){
			vm.items = vm.data ? vm.data : [];
		}
		function parseOptions(){
			vm.options = angular.extend(defaults, vm.options);
		}
		function selectItem(item) {
			vm.onSelect && vm.onSelect({ item });
		}
		function filter(text) {
			vm.isFilterActive = !!text;
		}
	}

})();
