/*
 * iconTextRowDirective
 * */

import templateUrl from './iconTextRow.html';
import controller from './iconTextRow.controller';
import './iconTextRow.scss';

const iconTextRowDirective = () => {
	'ngInject';
	return {
		scope: {
			iconCssClass: '@',
			colspan: '@'
		},
		controller,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		transclude: true,
		replace: true
	};
};

export default iconTextRowDirective;
