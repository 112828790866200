import templateUrl from './controlNotifications.html';
import controller from './controlNotifications.controller';
import './controlNotifications.scss';

const controlNotifications = {
	bindings: {
		messages: '<',
		config: '<?'
	},
	transclude: true,
	templateUrl,
	controller
};

export default controlNotifications;
