/*
 * dataManagementFilesModule
 * */

import dataManagementFilesComponent from './dataManagementFiles.component';

const dataManagementFilesModule = angular.module('app.components.myDownloads.dataManagementFiles', [])
	.component('dManagementFiles', dataManagementFilesComponent)
	.name;

export default dataManagementFilesModule;
