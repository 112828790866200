class DownloadPopupController {
	constructor(appI18n, $q, $scope, $rootScope, clinicalDataService, reportsService, $timeout,
				analyticsService, loadingIndicatorService, documentService, appSession, appConfig, scrollService) {
		'ngInject';
		this.appI18n = appI18n;
		this.$q = $q;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.documentService = documentService;
		this.appConfig = appConfig;
		this.appSession = appSession;
		this.$timeout = $timeout;
		this.loadingIndicatorService = loadingIndicatorService;
		this.reportsService = reportsService;
		this.analyticsService = analyticsService;
		this.clinicalDataService = clinicalDataService;
		this.scrollService = scrollService;
		this.headerTitle = null;
		this.show = false;
		this.resolve = null;
		this.reject = null;
		this.types = [];
		this.subjects = [];
	}

	initDownloadTypeControl() {
		const self = this;

		this.downloadTypeRadioGroupCompReady = () => {};
		this.downloadTypeRadioGroupOptions = {
			required: true,
			default: 0,
			inline: false,
			label: this.appI18n.translateImmediate('reports.report-review.popup.downloadType')
		};
		this.downloadTypeRadioButtonOptions = [{
			id: 'pdfRadioOption',
			label: this.appI18n.translateImmediate('reports.report-review.popup.pdfOption'),
			value: 0
		}, {
			id: 'zipRadioOption',
			label: this.appI18n.translateImmediate('reports.report-review.popup.zipOption'),
			value: 1
		}];
		this.downloadTypeRadioGroupChange = (newValue) => {
			self.downloadOptions.useZip = !!newValue;
		};
	}

	initReportVersionControl() {
		const self = this;

		this.reportVersionRadioGroupCompReady = () => {};
		this.reportVersionRadioGroupOptions = {
			required: true,
			default: 1,
			inline: false,
			label: this.appI18n.translateImmediate('reports.report-review.columns.reportVersion')
		};
		this.reportVersionRadioButtonOptions = [{
			id: 'reportVersionAllRadioOption',
			label: this.appI18n.translateImmediate('reports.report-review.popup.reportVersionAllRadioOption'),
			value: 0
		}, {
			id: 'reportVersionLatestRadioOption',
			label: this.appI18n.translateImmediate('reports.report-review.popup.reportVersionLatestRadioOption'),
			value: 1
		}];
		this.reportVersionRadioGroupChange = (newValue) => {
			self.downloadOptions.includeOnlyLatestReportVersions = !!newValue;
			self.updateReportCount();
		};
	}

	loadData() {
		const self = this;
		const defers = this.$q.all([
			this.reportsService.getAllReportTypes(false),
			this.clinicalDataService.getSitesMinimal()
		]);

		self.sitesLoading = true;
		self.loadingIndicatorService.show();
		defers.then((resolves) => {
			self.types = _.orderBy(_.map(resolves[0].data, (type) => {
				return {
					id: `${type.documentTypeId}_${type.testDataTypeId}`,
					documentTypeId: type.documentTypeId,
					testDataTypeId: type.testDataTypeId,
					displayValues: [type.testDataTypeName, type.documentTypeName]
				};
			}), ['testDataTypeId', 'documentTypeId'], ['asc', 'asc']);

			self.sites = resolves[1].data;
			self.sitesLoading = false;

			self.loadingIndicatorService.hide();
		}, (error) => {
			self.loadingIndicatorService.hide();
			self.sitesLoading = false;
			self.$log.error('error in loadData: {0}', error);
		});
	}

	initFromToControl() {
		const self = this;

		this.dateRangeName = this.appI18n.translateImmediate(
			'reports.report-review.popup.dateRange');

		this.fromDateChange = (newValue) => {
			self.downloadOptions.fromDate = newValue;
			self.updateReportCount();
		};
		this.toDateChange = (newValue) => {
			self.downloadOptions.toDate = newValue;
			self.updateReportCount();
		};
	}

	initReportTypeControl() {
		const self = this;

		this.reportTypesLoading = true;
		this.showReportTypeGrid = false;
		this.reportTypeRadioGroupOptions = {
			required: true,
			default: 0,
			label: this.appI18n.translateImmediate('reports.report-review.popup.reportTypes')
		};
		this.reportTypeRadioButtonOptions = [{
			id: 'includeAllReports',
			label: this.appI18n.translateImmediate(
				'reports.report-review.popup.includeAllReportsOption'),
			value: 0
		}, {
			id: 'selectReports',
			label: this.appI18n.translateImmediate(
				'reports.report-review.popup.selectReportOption'),
			value: 1
		}];
		this.reportTypeRadioGroupChange = (newValue) => {
			self.showReportTypeGrid = !!newValue;
			self.downloadOptions.includeAllDocumentTypes = !self.showReportTypeGrid;
			if (self.downloadOptions.includeAllDocumentTypes) {
				self.downloadOptions.documentTypeTdtTuples = [];
			}
			self.updateReportCount();
		};
	}

	initSiteSubjectControl() {
		const self = this;

		this.showSubjectGrid = false;
		this.subjectRadioGroupOptions = {
			required: true,
			default: 0,
			inline: false,
			label: this.appI18n.translateImmediate('reports.report-review.popup.subjects')
		};

		this.subjectRadioButtonOptions = [{
			id: 'includeAllSubjectsOption',
			label: this.appI18n.translateImmediate('reports.report-review.popup.includeAllSubjectsOption'),
			value: 0,
			disabled: true
		}, {
			id: 'selectSubjectOption',
			label: this.appI18n.translateImmediate(
				'reports.report-review.popup.selectSubjectOption'),
			value: 1,
			disabled: true
		}];
		this.subjectRadioGroupChange = (newValue) => {
			self.showSubjectGrid = newValue;
			self.downloadOptions.includeAllSubjects = !self.showSubjectGrid;
			if (self.downloadOptions.includeAllSubjects) {
				self.downloadOptions.subjectIds = [];
			}
			self.updateReportCount();
		};

		this.siteChange = (value) => {
			const study = self.appSession.getStudy();

			self.subjects = [];
			self.subjectsLoading = true;
			this.reportCountLoading = true;
			self.downloadOptions.subjectIds = [];
			if (value && value.siteId) {
				self.downloadOptions.selectedSiteId = value.siteId;
				self.downloadOptions.studyName = study.name;
				self.downloadOptions.sponsorSiteId = value.sponsorSiteId;
				self.setDefaultFileName();
				self.clinicalDataService.getSubjectsMinimal(value.countryId, value.siteId)
					.then((response) => {
						self.subjects = _.sortBy(_.map(response.data, (sub) => {
							return {
								subjectId: sub.subjectId,
								subjectKey: sub.subjectKey
							};
						}), 'displayValues');
					}, (error) => {
						this.$log.error('error in getSubjectsMinimal: {0}', error);
					})
					.finally(() => {
						self.subjectsLoading = false;
						self.updateReportCount();
					});
				self.subjectRadioButtonOptions[0].disabled = false;
				self.subjectRadioButtonOptions[1].disabled = false;
			} else {
				self.subjectRadioButtonOptions[0].disabled = true;
				self.subjectRadioButtonOptions[1].disabled = true;
				self.downloadOptions.sponsorSiteId = null;
				self.setDefaultFileName();
				self.subjectsLoading = false;
				self.updateReportCount();
			}
		};
	}

	setDefaultFileName() {
		const siteNamePart = this.downloadOptions.sponsorSiteId
			  ? `${this.downloadOptions.sponsorSiteId} - `
			  : '';
		const name = `${this.appSession.getStudy().name} - ` +
			  `${siteNamePart}Report Download ${moment(new Date()).format('YYYY-MM-DD hh-mm-ss')}`;

		this.downloadOptions.fileName = name;
	}

	checkSelectedSubjectsNotValid() {
		if (!this.reportReviewFg.showValidation || !this.showSubjectGrid) {
			return false;
		}

		const numberSelectedSubjects = this.subjects.filter((el) => {
			return el.checked;
		}).length;

		return this.showSubjectGrid && numberSelectedSubjects === 0;
	}

	checkSelectedReportTypesNotValid() {
		if (!this.reportReviewFg.showValidation || !this.showReportTypeGrid) {
			return false;
		}

		const numberSelectedReportTypes = this.types.filter((el) => {
			return el.checked;
		}).length;

		return this.showReportTypeGrid && numberSelectedReportTypes === 0;
	}

	updateReportCount() {
		const self = this;

		// timeout needed, sometimes the form is handled as invalid, but all required fields are set
		self.$timeout(() => {
			self.error = null;

			if (this.reportReviewFg.$invalid ||
				this.checkSelectedReportTypesNotValid() ||
				this.checkSelectedSubjectsNotValid()) {
				this.reportCount = null;
				this.updateCanceler && this.updateCanceler.resolve();
				this.reportCountLoading = false;
				return;
			}

			if (!this.downloadOptions.includeAllDocumentTypes) {
				this.downloadOptions.documentTypeTdtTuples = [];
				self.types.forEach((el) => {
					if (el.checked) {
						this.downloadOptions.documentTypeTdtTuples.push({
							item1: el.testDataTypeId,
							item2: el.documentTypeId
						});
					}
				});
			}
			if (!this.downloadOptions.includeAllSubjects) {
				this.downloadOptions.subjectIds = [];
				self.subjects.forEach((el) => {
					if (el.checked) {
						this.downloadOptions.subjectIds.push(el.subjectId);
					}
				});
			}

			// cancel previous request
			if (!this.updateCanceler) {
				this.updateCanceler = this.$q.defer();
			} else {
				this.updateCanceler.resolve();
				this.updateCanceler = this.$q.defer();
			}
			this.reportCountLoading = true;

			this.documentService.getEventReportsForReportIdsAsyncCount(this.downloadOptions, this.updateCanceler)
				.then((response) => {
					self.reportCount = response.data;
					this.reportCountLoading = false;
				})
				.catch((error) => {
					if (error && error.status !== -1) {
						this.reportCountLoading = false;
					}
				});
		});
	}

	$onInit() {
		const self = this;

		this.$API = {
			show(config) {
				self.headerTitle = config.headerTitle;
				self.show = true;
				self.scrollService.setScroll();

				self.downloadOptions = {
					useZip: false,
					fileName: null,
					fromDate: null,
					toDate: null,
					includeAllDocumentTypes: true,
					studyName: '',
					sponsorSiteId: '',
					selectedSiteId: null,
					includeAllSubjects: true,
					includeOnlyLatestReportVersions: true
				};


				self.initDownloadTypeControl();
				self.initReportVersionControl();
				self.initReportTypeControl();
				self.initFromToControl();
				self.initSiteSubjectControl();
				self.loadData();
				self.reportCount = null;

				return self.$q((res, rej) => {
					self.resolve = res;
					self.reject = rej;
				});
			},
			hide(response) {
				self.showSubjectGrid = false;
				self.showReportTypeGrid = false;
				self.$rootScope.bodylayout = '';
				self.show = false;
				self.scrollService.unsetScroll();
				self.resolve(response);
				self.cleanUpPromise();
			}
		};

		this.setButtons();

		if (!this.componentReady) {
			return;
		}

		this.componentReady({
			$API: this.$API
		});
	}

	$onDestroy() {
		this.scrollService.unsetScroll();
	}

	setButtons() {
		const self = this;

		self.closePopup = () => {
			self.$API.hide('cancel');
		};
		self.submitForm = () => {
			self.reportReviewFg.showValidation = true;
			if (self.reportReviewFg.$invalid ||
				self.subjects.length === 0 ||
				self.checkSelectedReportTypesNotValid() ||
				self.checkSelectedSubjectsNotValid()) {
				return;
			}
			if (self.reportCount === 0) {
				self.error = self.appI18n.translateImmediate(
					'reports.report-review.popup.no-reports');
				return;
			}

			self.loadingIndicatorService.show();
			self.error = null;
			if (!this.downloadOptions.includeAllDocumentTypes) {
				this.downloadOptions.documentTypeTdtTuples = [];
				self.types.forEach((el) => {
					if (el.checked) {
						this.downloadOptions.documentTypeTdtTuples.push({
							item1: el.testDataTypeId,
							item2: el.documentTypeId
						});
					}
				});
			}
			if (!this.downloadOptions.includeAllSubjects) {
				this.downloadOptions.subjectIds = [];
				self.subjects.forEach((el) => {
					if (el.checked) {
						this.downloadOptions.subjectIds.push(el.subjectId);
					}
				});
			}

			self.documentService.getEventReportsForReportIdsAsync(this.downloadOptions)
				.then(() => {
					const fileEnding = self.downloadOptions.useZip ? 'zip' : 'pdf';

					self.analyticsService.trackEvent(
						`${self.appConfig.trackCategory.reportReview}::async::${fileEnding}`, self.reportCount);
					self.loadingIndicatorService.hide();
					self.$API.hide('ok');
				})
				.catch((error) => {
					self.loadingIndicatorService.hide();
					self.error = error.data && error.data.message;
				});
		};

	}

	cleanUpPromise() {
		this.resolve = null;
		this.reject = null;
	}

	isValidDateRange() {
		const from = this.getDate(this.downloadOptions.fromDate);
		const to = this.getDate(this.downloadOptions.toDate);

		if (from && to) {
			if (moment(from, 'DD-MMM-YYYY') > moment(to, 'DD-MMM-YYYY')) {
				return false;
			}
		}
		return true;
	}

	getDate(date) {
		if (!date) {
			return null;
		}
		//firefox new date doesn't work with date string has "-"
		date = date.replace(/-/g, ' ');
		return new Date(date);
	}
}

export default DownloadPopupController;
