(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('VisitController', VisitController);

	/*
	 * @ngInject
	 */
	function VisitController($log, $scope, $state, $stateParams, $q, $rootScope,
							 clinicalDataService, eproService, $window, appI18n,
							 popupService, ertBasePopupService, iSpiroService,
							 documentService, appConfig, configurationService, appSession,
							 $filter, remoteFileService, loadingIndicatorService,
							 notificationService,
							 analyticsService, fallbackSiteParams, $location, ssoService) {

		// some users enter the page with a countryId=0 and this causes several
		// error log entries. To avoid it we need to restore the id and update the
		// param in the query string.
		if (fallbackSiteParams && fallbackSiteParams.data &&
			fallbackSiteParams.data.countryId) {
			$stateParams.country = fallbackSiteParams.data.countryId;
			$location.search('country', fallbackSiteParams.data.countryId);
		}

		const countryId = $stateParams.country;
		const siteId = $stateParams.site;
		const subjectId = $stateParams.subject;
		const eproSubjectId = $stateParams.eproSubject;

		$log = $log.getInstance('VisitController');
		$log.debug(' loaded');

		const vm = this;

		vm.reportTypes = [];
		vm.reportTypesForDownload = [];
		vm.reports = [];
		vm.visits = {};
		vm.types = {};
		vm.downloadActionActive = false;
		vm.downloadEproAlert = downloadEproAlert;
		vm.cancelEvent = cancelEvent;
		vm.rescheduleEvent = rescheduleEvent;
		vm.getReportTypes = getReportTypes;
		vm.addTypeForDownload = addTypeForDownload;
		vm.openFile = openFile;

		vm.downloadActions = [
			{
				action: () => {
					vm.downloadActions[0].isDisabled = true;
					vm.downloadActionActive = true;
					documentService.getEventReportsForTypes(siteId, subjectId, eproSubjectId,
							vm.reportTypesForDownload)
						.then((response) => {
							const reportsForDownload = _.filter(vm.reports, (item) => {
								const found = _.find(vm.reportTypesForDownload, (typeId) => {
									return item.documentTypeId === typeId;
								});

								return !!found;
							});

							documentService.downloadReport(
								response.data, $scope,
								appConfig.downloadDocumentType.clinicalDataReport,
								reportsForDownload.length);
							vm.downloadActions[0].isDisabled = false;
							vm.downloadActionActive = false;
						}, () => {
							vm.downloadActions[0].isDisabled = false;
						});
				},
				displayName: appI18n.translateImmediate('clinicaldata.visit.download.byTypePopupButton'),
				isDisabled: true,
				isPrimary: true
			}
		];

		activate();

		vm.options = {
			sortOrders: [{
				fieldName: 'eventDate',
				reverseOrder: true
			}, {
				fieldName: 'eproVisitSequence',
				reverseOrder: true
			}, {
				fieldName: 'eventId',
				reverseOrder: true
			}],
			rowDefs: {
				multiSelection: true,
				restrictSelectionToGroup: (row) => { return !row.isScheduledEvent; },
				selectableIf: (row) => { return !row.isScheduledEvent; },
				identifier: 'eventId',
				groupHeader: {
					groupByFieldTitle: 'Visit Name',
					groupByDescriptionTitle: 'Visit Date',
					descriptionCssClass: '-es-width-6',
					groupByDisplayName: (row) => {
						return row.isScheduledEvent
							? appI18n.translateImmediate('clinicaldata.scheduledEvents.title')
							: row.visitName;
					},
					groupByFieldName: (row) => {
						return row.isScheduledEvent ? row.isScheduledEvent : row.visitName;
					},
					descriptionArray: (row) => {
						return row.groupDescriptions;
					},
					numberOfItems: (row) => {
						return row.groupCount;
					},
					numberOfItemsText: 'Number of events',
					expandGroupByNameOrFirst: () => {
						return $stateParams.visit;
					},
					rules: (row) => {
						return row.groupRules;
					}
				}
			},
			gridActions: [{
				displayName: appI18n.translateImmediate('clinicaldata.visit.download.gridAction'),
				subItems: [
					{
						displayName: appI18n.translateImmediate('clinicaldata.visit.download.selected'),
						action: () => {
							const eventIds = [];

							vm.selectedRows.forEach((el) => {
								eventIds.push(el.eventId);
							});
							documentService.getEventReportsForEventIds(siteId, subjectId, eproSubjectId, eventIds)
								.then((response) => {
									const reportsForDownload = _.filter(vm.reports, (item) => {
										const found = _.find(vm.eventIds, (eventId) => {
											return item.eventId === eventId;
										});

										return !!found;
									});

									documentService.downloadReport(response.data, $scope, appConfig.downloadDocumentType.clinicalDataReport, reportsForDownload.length);
								});
						},
						enabledIf: () => {
							if (vm.selectedRows && vm.selectedRows.length > 0 && vm.reports && vm.reports.length > 0) {
								for (let i = 0; i < vm.selectedRows.length; i++) {
									const found = $filter('filter')(vm.reports, {
										eventId: vm.selectedRows[i].eventId
									}, true);

									if (found.length > 0) {
										return true;
									}
								}
							}
							return false;
						}
					},
					{
						displayName: appI18n.translateImmediate('clinicaldata.visit.download.byType'),
						action: () => {
							vm.showDownloadByType = true;
						},
						enabledIf: () => {
							if (vm.reports && vm.reports.length > 0) {
								return true;
							}
							return false;
						}
					}]
			}],
			defaultRowAction: showViewer,
			hasRowDefaultAction,
			gridRowActions: [
				{
					iconCssClass: 'es-icon es-icon-info-circle',
					displayName: 'clinicaldata.visit.infoPopup.title',
					restrictToGroup: (row) => { return !row.isScheduledEvent; },
					enabledIf: (row) => {
						return row.operatorName || row.transmittalNumber;
					},
					action: (row) => {
						vm.showDetails = true;
						vm.eventDescription = `${row.visitName} - ${row.eventName}`;
						vm.operatorName = row.operatorName;
						vm.transmittalNumber = row.transmittalNumber;
					}
				}, {
					displayName: 'Reports',
					iconCssClass: 'es-icon es-icon-reports',
					restrictToGroup: (row) => { return !row.isScheduledEvent; },
					enabledIf: (row) => {
						return _.some(vm.reports, (report) => {
							return report.eventId === row.eventId && !report.isPaperPft;
						});
					},
					subItemsFactory: (row) => {
						if (row.reports) {
							return row.reports;
						}

						const itemReports = _.filter(vm.reports, (report) => {
							return report.eventId === row.eventId && !report.isPaperPft;
						});
						const rowReports = [];

						angular.forEach(itemReports, (item) => {
							rowReports.push({
								displayName: item.documentDisplayName || item.documentTypeName,
								iconFileType:
								item.docIsCompressed
									? appConfig.fileTypes.zip
									: item.fileFormatDisplayName ||
									appConfig.fileTypes.pdf,
								action: () => {
									documentService.getEventReport(
										item.siteId, item.subjectId, item.id)
										.then((response) => {
											documentService.downloadReport(response.data, $scope,
												appConfig.downloadDocumentType.clinicalDataReport);
										});
								}
							});
						});
						row.reports = rowReports;

						return rowReports;
					}
				}, {
					displayName: 'Attachments',
					iconCssClass: 'es-icon es-icon-paperclip',
					restrictToGroup: (row) => { return !row.isScheduledEvent; },
					enabledIf: (row) => {
						return _.some(vm.reports, (report) => {
							return report.eventId === row.eventId && report.isPaperPft;
						});
					},
					subItemsFactory: (row) => {
						if (row.attachments) {
							return row.attachments;
						}
						const itemReports = _.filter(vm.reports, (report) => {
							return report.eventId === row.eventId && report.isPaperPft;
						});
						const rowReports = [];

						angular.forEach(itemReports, (item) => {
							rowReports.push({
								displayName: item.displayName,
								iconFileType: item.fileTypeName,
								tooltip: item.tooltip,
								action: () => {
									openFile(item.url, item.originalFileName);
								}
							});
						});
						row.attachments = rowReports;

						return rowReports;
					}
				}, {
					displayName: 'Create Report',
					iconCssClass: 'es-icon es-icon-create-trans',
					restrictToGroup: (row) => { return !row.isScheduledEvent; },
					enabledIf: (row) => {
						return row.reportLinkName && row.reportLinkId;
					},
					subItemsFactory: (row) => {
						if (row.createReports) {
							return row.createReports;
						}
						const createReports = [];

						createReports.push({
							displayName: row.reportLinkName,
							iconCssClass: 'es-icon es-icon-create-trans',
							action: () => {
								const url = $state.href(appConfig.routes.createReport, {
									country: countryId,
									site: siteId,
									subject: +row.testDataTypeId === appConfig.testDataType.coaAm
										? eproSubjectId
										: subjectId,
									report: row.reportLinkId
								});

								$window.open(url, '_blank');
							}
						});
						row.createReports = createReports;

						return createReports;
					}
				}, {
					getDisplayName: (row) => { return row.canResume
											   ? 'clinicaldata.scheduledEvents.actions.resume.displayName'
											   : 'clinicaldata.scheduledEvents.actions.start.displayName'; },
					position: 'right',
					restrictToGroup: (row) => { return row.isScheduledEvent; },
					action: (row) => {
						loadingIndicatorService.show();
						iSpiroService.activateVideoSession(row.siteId, row.technicalSubjectId, row.subjectKey,
														   row.visitId, row.visitScheduleTaskId,
														   row.scheduleDateTime, row.scheduledEventId)
							.then((response) => {
								if (response.data && response.data.isValidationError) {
									notificationService.showError(
										appI18n.translateImmediate(response.data.translationKey));
									loadingIndicatorService.hide();
								} else {
									ssoService.navigateToStudyService(
										'remotespiro', 'start-session',
										{ scheduledSessionUrl: encodeURIComponent(response.data) })
										.then(() => {
											vm.reload = true;
										}).finally(() => {
											// always hide the loading indicator (success or error)
											loadingIndicatorService.hide();
											// we need to trigger a digest cycle here because the promise is resolved
											// outside of the angular digest loop.
											$scope.$apply();
										});
								}
							}, () => {
								// hide loading indicator if the call to iSpiroService failed
								loadingIndicatorService.hide();
							});
					},
					enabledIf: (row) => {
						return row.canStart || row.canResume;
					},
					actionInfo: (row) => {
						if (row.canStart) {
							return 'clinicaldata.scheduledEvents.actions.start.enabledInfo';
						} else if (row.canResume) {
							return 'clinicaldata.scheduledEvents.actions.resume.enabledInfo';
						} else {
							return 'clinicaldata.scheduledEvents.actions.start.disabledInfo';
						}
					}
				},
				{
					displayName: 'clinicaldata.scheduledEvents.actions.reschedule.displayName',
					position: 'right',
					restrictToGroup: (row) => { return row.isScheduledEvent; },
					action: (row) => {
						vm.rescheduleEvent(row);
					},
					enabledIf: (row) => {
						return row.canReschedule;
					},
					actionInfo: (row) => {
						if (row.canReschedule) {
							return 'clinicaldata.scheduledEvents.actions.reschedule.enabledInfo';
						} else {
							return 'clinicaldata.scheduledEvents.actions.reschedule.disabledInfo';
						}
					}
				},
				{
					displayName: 'clinicaldata.scheduledEvents.actions.cancel.displayName',
					position: 'right',
					restrictToGroup: (row) => { return row.isScheduledEvent; },
					action: (row) => {
						vm.cancelEvent(row);
					},
					enabledIf: (row) => {
						return row.canCancel;
					},
					actionInfo: (row) => {
						if (row.canCancel) {
							return 'clinicaldata.scheduledEvents.actions.cancel.enabledInfo';
						} else {
							return 'clinicaldata.scheduledEvents.actions.cancel.disabledInfo';
						}
					}
				}

			],

			removePaginationControls: true,

			getData: getVisits
		};

		function rescheduleEvent(row) {
			ertBasePopupService.popup('iSpiroScheduleEventPopup')
				.openForReschedule(row.siteId, row.displaySiteName, row.technicalSubjectId, row.subjectKey,
								   row.visitId, row.visitName, row.visitScheduleTaskId, row.visitScheduleTaskName,
								   row.dayMonthYear, row.time, row.remoteVideoEnabled, row.scheduledEventId)
				.then((resp) => {
					vm.reload = resp;
				});
		}

		function cancelEvent(row) {
			iSpiroService.confirmAndCancelScheduledEvent(
				row.scheduledEventId, row.siteId, row.subjectKey, row.visitId,
				row.visitScheduleTaskId, row.scheduleDateTime, () => {
					vm.reload = true;
					notificationService.showSuccess(appI18n.translateImmediate('clinicaldata.scheduledEvents.cancelSuccess'));
				});
		}

		function setGridColumnDefs(columns) {

			const hasEventDetail = columns && _.some(columns, item => {
				return (item.id || '').trim() === '5213'; //visit event detail column id
			});

			let hideEventDetail = !hasEventDetail;

			vm.options.columnDefs = [
				{
					restrictToGroup: (row) => { return row.isScheduledEvent; },
					cssClass: '-es-width-2',
					displayName: 'Visit',
					fieldName: 'visitName',
					type: 'string',
					removeFromFilter: true,
				},
				{
					restrictToGroup: (row) => { return !row.isScheduledEvent; },
					displayName: 'Visit',
					fieldName: 'visitName',
					type: 'string',
					showOnlyInFilter: true
				},
				{
					displayName: 'Event',
					cssClass: '-es-width-3',
					fieldName: 'eventName',
					type: 'string',
					isDefaultFilter: true,
					isPrimary: true,
				},
				{
					restrictToGroup: (row) => { return !row.isScheduledEvent; },
					displayName: 'Event Time',
					cssClass: hideEventDetail ? '-es-width-9' : '-es-width-3',
					fieldName: 'eventDate',
					type: 'date',
					formatter: 'dateTimeFormat'
				},
				{
					restrictToGroup: (row) => { return row.isScheduledEvent; },
					displayName: 'Event Time',
					cssClass: '-es-width-7',
					fieldName: 'eventDate',
					type: 'date',
					removeFromFilter: true,
				},
				{
					displayName: 'Details',
					cssClass: '-es-width-6',
					fieldName: 'eventDetailsXml',
					type: 'string',
					formatter: 'eventDetailsXml',
					isHidden: hideEventDetail
				},
				{
					displayName: 'Operator',
					fieldName: 'operatorName',
					type: 'string',
					showOnlyInFilter: true
				},
				{
					displayName: 'Transmittal #',
					fieldName: 'transmittalNumberText',
					type: 'string',
					showOnlyInFilter: true
				}
			];
		}

		function setEproRowAndPageActions(eproStudySettings, closeVisitSettings) {
			if (eproStudySettings && eproStudySettings.data.showAlertsTriggered) {
				vm.options.gridRowActions.push({
					iconCssClass: 'es-icon es-icon-row-triangle',
					displayName: 'clinicaldata.visit.alerts.title',
					enabledIf: isEproRow,
					action: (row) => {
						openAlerts(row);
					}
				});
			}
			if (eproStudySettings && eproStudySettings.data.showScoringResults) {
				vm.options.gridRowActions.push({
					iconCssClass: 'es-icon es-icon-line-chart',
					displayName: 'clinicaldata.visit.scoring.title',
					enabledIf: isEproRow,
					action: (row) => {
						openScoring(row);
					}
				});
			}

			if (closeVisitSettings) {
				clinicalDataService.setCanCloseVisit(
					closeVisitSettings.data.canCloseVisit && closeVisitSettings.data.permissionToClose);
			}
		}

		function setISpiroScheduleEvent(iSpiroStudyActivities) {
			if (iSpiroStudyActivities) {
				vm.scheduleEvent = iSpiroStudyActivities.data.includes(appConfig.iSpiroActionId.scheduleEvent);
			}
		}

		function setEventReportsAndAttachments(eventReports, eventAttachments) {
			vm.reports = [];
			if (eventReports) {
				vm.reports = vm.reports.concat(eventReports.data);
			}
			if (eventAttachments) {
				vm.reports = vm.reports.concat(eventAttachments.data);
			}
			vm.reports.forEach((el) => {
				getEventReports(el);
			});
		}

		async function activate() {

			// fetch study settings first to determine which additional requests are required
			vm.studySettings = await configurationService.getStudySettings();

			// generate requests object
			const requests = {
				columns: configurationService.getClinicalDataColumns(appConfig.clinicalDataModuleNames.visit)
			};

			// add additional requests based on study settings
			if (eproSubjectId) {
				requests.closeVisitSettings = eproService.getCanCloseVisit(eproSubjectId);
				requests.eproStudySettings = configurationService.getEproSettings();
			} else {
				clinicalDataService.setCanCloseVisit(false);
			}

			if (vm.studySettings.isiSpiroStudy) {
				requests.iSpiroStudyActivities = iSpiroService.getStudyActivities();
			}
			if (!$rootScope.expertUnavailable) {
				requests.eventReports = clinicalDataService.getEventReports(siteId, subjectId, eproSubjectId);

				if (vm.studySettings.hasPaperPft) {
					requests.eventAttachments = clinicalDataService.getEventAttachments(siteId, subjectId);
				}
			}


			$q.all(requests).then(async (resp) => {
				// set columnDefs
				setGridColumnDefs(resp.columns);

				// set epro row/page actions
				setEproRowAndPageActions(resp.eproStudySettings, resp.closeVisitSettings);

				// set ispiro
				setISpiroScheduleEvent(resp.iSpiroStudyActivities);

				// set eventReports
				setEventReportsAndAttachments(resp.eventReports, resp.eventAttachments);

				// initialize grid
				vm.load = true;
			}, (error) => {
				// if there was any error, still initialize the grid with a minimum of configuration
				setGridColumnDefs();
				vm.load = true;
			});

		}

		function openScoring(row) {
			vm.showScoring = false;
			loadingIndicatorService.show();
			vm.scoringResults = null;
			vm.scoringResultsErrorMsg = null;
			eproService.getScoringResults(siteId, eproSubjectId, row.eventId, row.testDataTypeId)
				.then((response) => {
					loadingIndicatorService.hide();
					vm.scoringResults = response.data;
					vm.showScoring = true;
				}, () => {
					vm.scoringResultsErrorMsg = appI18n.translateImmediate('clinicaldata.visit.scoring.loadError');
					loadingIndicatorService.hide();
					vm.showScoring = false;
				});
		}

		function openAlerts(row) {
			vm.showAlerts = false;
			loadingIndicatorService.show();
			vm.alertsResults = null;
			vm.alertsResultsErrorMsg = null;
			vm.popupRow = row;
			eproService.getAlertsTriggered(siteId, eproSubjectId, row.eventId, row.testDataTypeId)
				.then((response) => {
					loadingIndicatorService.hide();
					vm.alertsResults = response.data;
					vm.showAlerts = true;
				}, () => {
					vm.alertsResultsErrorMsg = appI18n.translateImmediate('clinicaldata.visit.alerts.loadError');
					loadingIndicatorService.hide();
					vm.showAlerts = false;
				});
		}

		function downloadEproAlert(row) {
			const messageId = row.messageId;
			const sessionToken = appSession.getSessionToken();
			const reportName = documentService.getFileName('EproAlert', row.subjectVisitAlertTriggerTimeLtz,
				vm.popupRow.sponsorSiteId, vm.popupRow.subjectKey, eproSubjectId, row.alertName, messageId);

			documentService.getEproAlertUrl(siteId, eproSubjectId, vm.popupRow.testDataTypeId,
					vm.popupRow.eventId, messageId, sessionToken, reportName)
				.then((response) => {
					documentService.downloadReport(response.data, $scope, appConfig.downloadDocumentType.clinicalDataReport);
				});
		}

		function isEproRow(row) {
			const rowTdt = +row.testDataTypeId;

			return rowTdt === appConfig.testDataType.coaPhone ||
				rowTdt === appConfig.testDataType.coaSite ||
				rowTdt === appConfig.testDataType.coaDiary ||
				rowTdt === appConfig.testDataType.syndication ||
				rowTdt === appConfig.testDataType.coaAm;
		}

		function hasRowDefaultAction(row) {
			if (row.suppressEventLink) {
				return false;
			}

			const expertAvailable = !$rootScope.expertUnavailable;
			const tdtId = +row.testDataTypeId;

			if (tdtId === appConfig.testDataType.slowSpirometry ||
				tdtId === appConfig.testDataType.forcedSpirometry ||
				tdtId === appConfig.testDataType.extractionEcg ||
				tdtId === appConfig.testDataType.homeForcedSpirometry ||
				tdtId === appConfig.testDataType.ecg) {
				return expertAvailable;
			}
			if ((tdtId === appConfig.testDataType.coaAm &&
					(row.eventName.indexOf('AM Transfer') >= 0 || row.eventName.indexOf('AM Download') >= 0)) ||
				(tdtId === appConfig.testDataType.coaPhone && (row.eproVisitStatusId !== '3')) ||
				tdtId === appConfig.testDataType.syndication ||
				tdtId === appConfig.testDataType.coaDiary ||
				(tdtId === appConfig.testDataType.coaSite && row.modalityId !== appConfig.modalityType.cgm)
			) {
				return true;
			}
			return false;
		}

		function showViewer(row, $index, $event) {
			const expertUnavailable = $rootScope.expertUnavailable;
			const tdtId = +row.testDataTypeId;

			//if click a href, then use default value
			if ($event && $event.target && $event.target.nodeName === 'A') {
				return;
			}

			//SlowSpirometry:20 OR ForcedSpirometry:21 or homeForcedSpirometry: 27
			if (tdtId === appConfig.testDataType.slowSpirometry ||
				tdtId === appConfig.testDataType.forcedSpirometry ||
				tdtId === appConfig.testDataType.homeForcedSpirometry) {
				if (!expertUnavailable) {
					analyticsService.trackEventWithTitle(
						`${appConfig.trackCategory.navigateTo}::${appConfig.trackId.clinicalDataSpiroViewer}`,
						appConfig.trackAction.gridEntry, appConfig.trackLabel.levelEvent);

					$state.go(appConfig.routes.spirometry, {
						country: countryId,
						site: siteId,
						subject: subjectId,
						eproSubject: eproSubjectId,
						visit: row.visitName,
						event: row.eventId,
						tdt: row.testDataTypeId,
						mdt: row.modalityId,
						tdv: row.testDataTypeVersionId,
						orderBy: ''
					});
				}
			} else if ((tdtId === appConfig.testDataType.ecg ||
						tdtId === appConfig.testDataType.extractionEcg) && row.transmittalNumber) {
				if (!expertUnavailable) {
					analyticsService.trackEventWithTitle(
						`${appConfig.trackCategory.navigateTo}::${appConfig.trackId.clinicalDataECGViewer}`,
						appConfig.trackAction.gridEntry, appConfig.trackLabel.levelEvent);

					$state.go(appConfig.routes.ecg, {
						country: countryId,
						site: siteId,
						subject: subjectId,
						eproSubject: eproSubjectId,
						visit: row.visitName,
						event: row.eventId,
						tdt: row.testDataTypeId,
						mdt: row.modalityId,
						orderBy: ''
					});
				}
			} else if (row.modalityId || tdtId === appConfig.testDataType.coaPhone) {
				analyticsService.trackEventWithTitle(
					`${appConfig.trackCategory.navigateTo}::${appConfig.trackId.clinicalDataEcoaViewer}`,
					appConfig.trackAction.gridEntry, appConfig.trackLabel.levelEvent);

				$state.go(appConfig.routes.ecoa, {
					country: countryId,
					site: siteId,
					subject: subjectId,
					eproSubject: eproSubjectId,
					visit: row.visitName,
					event: row.eventId,
					tdt: row.testDataTypeId,
					mdt: row.modalityId,
					orderBy: ''
				});
			} else {
				return;
			}
		}

		function getEventReports(eventReport) {
			if (!eventReport.documentTypeId) {
				return;
			}
			if (!vm.reportTypes.find(x => x.documentTypeId === eventReport.documentTypeId)) {
				vm.reportTypes.push({
					documentTypeId: eventReport.documentTypeId,
					documentTypeName: eventReport.documentTypeName || eventReport.documentDisplayName
				});
			}
		}

		function addTypeForDownload(typeId) {
			if (vm.types[typeId]) {
				vm.reportTypesForDownload.push(typeId);
			} else {
				const index = vm.reportTypesForDownload.indexOf(typeId);

				vm.reportTypesForDownload.splice(index, 1);
			}
			vm.downloadActions[0].isDisabled = vm.reportTypesForDownload.length === 0;
		}

		function getReportTypes(reports) {
			if (reports) {
				const reportTypes = [];

				vm.reportTypeIds = [];
				reports.forEach((el) => {
					if (vm.reportTypeIds.indexOf(el.documentTypeId) < 0) {
						vm.reportTypeIds.push(el.documentTypeId);
						reportTypes.push(el);
					}
				});
				return reportTypes;
			}
		}

		function getVisits(options) {
			return clinicalDataService.getVisits(countryId, siteId, subjectId, vm.scheduleEvent, options);

		}

		function openFile(url, fileName) {
			remoteFileService.openFile(url, fileName, false);
		}
	}

})();
