/*
 * expandableBulletListComponent
 * */

import templateUrl from './expandableBulletList.html';
import controller from './expandableBulletList.controller';
import './expandableBulletList.scss';

const expandableBulletListComponent = {
	bindings: {
		titleKey: '@',
		noItemsKey: '@',
		iconClass: '@?',
		items: '<',
		limitTo: '@?'
	},
	templateUrl,
	controller
};

export default expandableBulletListComponent;
