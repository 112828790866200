(function () {
	'use strict';

	/*
	 *@ngInject
	 */
	angular.module('portalApp').factory('httpCache', ($cacheFactory, cacheManager, appSession) => {
		const cache = $cacheFactory('cache');

		cache.put = function (url, value) {
			if (value && value.$$state) {
				return;
			}
			const context = cacheManager.getCacheContextInUrl(url);

			cacheManager.put(cacheManager.generateUrlCacheKey(url, appSession.getStudy(), context),
											 value,
											 context);
		};

		cache.get = function (url) {
			const context = cacheManager.getCacheContextInUrl(url);

			return cacheManager.get(cacheManager.generateUrlCacheKey(url, appSession.getStudy(), context),
															context);
		};

		cache.remove = function (url) {
			const context = cacheManager.getCacheContextInUrl(url);

			cacheManager.remove(cacheManager.generateUrlCacheKey(url, appSession.getStudy(), context),
													context);
		};

		return cache;
	});
}());

