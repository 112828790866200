class DeviceManagementCtrl {

	constructor(
		$log, appI18n, clinicalDataService, $state, $rootScope, $scope,
		configurationService, appConfig, $window) {
		'ngInject';

		this.configurationService = configurationService;
		this.state = $state.current;
		this.rootScope = $rootScope;
		this.scope = $scope;
		this.go = $state.go;
		this.appI18n = appI18n;
		this.appConfig = appConfig;
		this.window = $window;

		$log = $log.getInstance('DeviceManagementMainController');
		$log.debug(' loaded');

		this.activate();
	}

	async activate() {
		this.translations = await this.appI18n.translate([
			'deviceManagement.buttons.siteUnlockCode',
			'deviceManagement.buttons.startUpCode',
			'deviceManagement.buttons.adminAccessCode',
			'deviceManagement.buttons.temporaryAccessCode',
			'deviceManagement.devices.flowscreen',
			'deviceManagement.devices.easyOnePro',
			'deviceManagement.devices.masterscope',
			'deviceManagement.devices.spiroSphere',
			'deviceManagement.devices.iSpiro',
			'deviceManagement.devices.clarioApp',
			'deviceManagement.devices.vivalink',
			'deviceManagement.devices.cough',
			'deviceManagement.devices.sitepro',
			'deviceManagement.devices.ecoaTablet',
			'deviceManagement.devices.feno',
			'deviceManagement.devices.cgm',
			'deviceManagement.devices.ecoaHandheld',
			'deviceManagement.devices.am3',
			'deviceManagement.devices.diarypro',
			'deviceManagement.devices.cgm-web-uploader',
			'deviceManagement.labels.title',
			'deviceManagement.buttons.myDeviceAccount'
		]);
		this.adminAccessCodePopupTitle = this.translations['deviceManagement.buttons.adminAccessCode'];
		this.startUpCodeCodeOverrideTitle = this.translations['deviceManagement.buttons.startUpCode'];
		this.unlockCodePopupTitle = this.translations['deviceManagement.buttons.siteUnlockCode'];
		this.temporaryAccessCodePopupTitle = this.translations['deviceManagement.buttons.temporaryAccessCode'];
		this.deviceTitle = this.translations['deviceManagement.labels.title'];
		const settings = await this.configurationService.getUserStudySettings();

		this.setDeviceManagementTabs(settings);
		this.setActions(settings);
	}

	async setDeviceManagementTabs(settings) {
		const config = settings.data.studyConfig;

		this.tabs = [];
		if (config.hasMasterscope) {
			this.tabs.push({
				displayName: this.getDeviceName(this.appConfig.deviceIds.masterscope),
				trackId: this.appConfig.trackId.deviceManagementMasterscope,
				ref: this.appConfig.routes.deviceManagement.masterscope
			});
		}
		if (config.hasFlowScreen) {
			this.tabs.push({
				displayName: this.getDeviceName(this.appConfig.deviceIds.flowscreen),
				trackId: this.appConfig.trackId.deviceManagementFlowscreen,
				ref: this.appConfig.routes.deviceManagement.flowscreen
			});
		}
		if (config.hasEasyOnePro) {
			this.tabs.push({
				displayName: this.getDeviceName(this.appConfig.deviceIds.easyOnePro),
				trackId: this.appConfig.trackId.deviceManagementEasyOnePro,
				ref: this.appConfig.routes.deviceManagement.easyOnePro
			});
		}
		if (config.hasSitePro) {
			this.tabs.push({
				displayName: this.getDeviceName(
					this.appConfig.deviceIds.siteProCgm,
					this.appConfig.testDataType.coaSite,
					this.appConfig.modalityType.sitePro),
				trackId: this.appConfig.trackId.deviceManagementSitepro,
				ref: this.appConfig.routes.deviceManagement.sitepro
			});
		}
		if (config.hasSpiroSphere) {
			this.tabs.push({
				displayName: this.getDeviceName(this.appConfig.deviceIds.spiroSphere),
				trackId: this.appConfig.trackId.deviceManagementSpiroSphere,
				ref: this.appConfig.routes.deviceManagement.spiroSphere
			});
		}
		if (config.isiSpiroStudy) {
			this.tabs.push({
				displayName: this.getDeviceName(this.appConfig.deviceIds.iSpiro),
				trackId: this.appConfig.trackId.deviceManagementISpiro,
				ref: this.appConfig.routes.deviceManagement.iSpiro
			});
		}
		if (config.hasFeNo) {
			this.tabs.push({
				displayName: this.getDeviceName(this.appConfig.deviceIds.feno),
				trackId: this.appConfig.trackId.deviceManagementFeno,
				ref: this.appConfig.routes.deviceManagement.feno
			});
		}
		if (config.hasCgm) {
			this.tabs.push({
				displayName: this.getDeviceName(
					this.appConfig.deviceIds.siteProCgm,
					this.appConfig.testDataType.coaSite,
					this.appConfig.modalityType.cgm),
				trackId: this.appConfig.trackId.deviceManagementCgm,
				ref: this.appConfig.routes.deviceManagement.cgm
			});
		}
		if (config.hasDiaryPro) {
			this.tabs.push({
				displayName: this.getDeviceName(
					this.appConfig.deviceIds.diaryPro,
					this.appConfig.testDataType.coaDiary,
					this.appConfig.modalityType.diaryPro),
				trackId: this.appConfig.trackId.deviceManagementDiarypro,
				ref: this.appConfig.routes.deviceManagement.diarypro
			});
		}
		if (config.hasCoaAm) {
			this.tabs.push({
				displayName: this.getDeviceName(this.appConfig.deviceIds.am3),
				trackId: this.appConfig.trackId.deviceManagementAm3,
				ref: this.appConfig.routes.deviceManagement.am3
			});
		}


		if (config.hasClarioApp) {
			this.tabs.push({
				displayName: this.getDeviceName(this.appConfig.deviceIds.clarioApp),
				trackId: this.appConfig.trackId.deviceManagementClarioApp,
				ref: this.appConfig.routes.deviceManagement.clarioApp
			});
		}

		if (config.hasVivalink) {
			this.tabs.push(
				{
					displayName: this.getDeviceName(this.appConfig.deviceIds.vivalink),
					trackId: this.appConfig.trackId.deviceManagementVivalink,
					ref: this.appConfig.routes.deviceManagement.vivalink
				});
		}

		if (config.hasCough) {
			this.tabs.push({
					displayName: this.getDeviceName(this.appConfig.deviceIds.cough),
					trackId: this.appConfig.trackId.deviceManagementCough,
					ref: this.appConfig.routes.deviceManagement.cough
			});
		}

		this.setRedirect();
	}

	setRedirect() {
		const self = this;

		if (self.tabs && self.tabs.length === 0) {
			self.showNoDevicesMessage = true;
		} else {
			if (self.state.name === self.appConfig.routes.deviceManagement.root) {
				self.go(self.tabs[0].ref);
			}

			const stateChange = self.rootScope.$on('$stateChangeSuccess', (event, toState) => {
				if (toState.name === self.appConfig.routes.deviceManagement.root) {
					self.go(self.tabs[0].ref);
				}
			});

			self.scope.$on('$destroy', () => {
				stateChange();
			});
		}

	}

	setActions(settings) {
		const self = this;

		self.isInternal = settings.data.isInternal;
		self.iconActions = [{ action: () => { self.window.print(); }, iconName: 'print' }];
		this.actions = [
			{
				displayName: this.translations['deviceManagement.buttons.startUpCode'],
				visibleIn: [this.appConfig.routes.deviceManagement.ecoaTablet],
				action: () => {
					self.isStartUpCodeVisible = true;
					self.type = 'startUpCode';
				}
			},
			{
				displayName: this.translations['deviceManagement.buttons.siteUnlockCode'],
				visibleIn: [this.appConfig.routes.deviceManagement.ecoaTablet],
				action: () => {
					self.isUnlockCodePopupVisible = true;
					self.type = 'siteUnlockCode';
				}
			},
			{
				displayName: this.translations['deviceManagement.buttons.adminAccessCode'],
				visibleIn: [this.appConfig.routes.deviceManagement.ecoaHandheld],
				action: () => {
					self.isAdminAccessCodePopupVisible = true;
					self.type = 'adminAccessCode';
				}
			},
			{
				displayName: this.translations['deviceManagement.buttons.temporaryAccessCode'],
				visibleIn: [this.appConfig.routes.deviceManagement.ecoaHandheld],
				action: () => {
					self.isTemporaryAccessCodePopupVisible = true;
					self.type = 'temporaryAccessCode';
				}
			}];

		if (settings.data.studyConfig.usesMyDeviceAccount && settings.data.hasMyDeviceAccountPermission) {
			this.actions.push({
				displayName: this.translations['deviceManagement.buttons.myDeviceAccount'],
				cssClass: '-es-primary',
				visibleIn: [
					this.appConfig.routes.deviceManagement.sitepro,
					this.appConfig.routes.deviceManagement.diarypro
				],
				action: () => {
					self.$API.open();
				}
			});
		}

	}

	getMyDeviceAccountPopupApi($API) {
		this.$API = $API;
	}

	getDeviceName(deviceId, tdtId, mdtId) {
		switch (deviceId) {
			case this.appConfig.deviceIds.masterscope:
				return this.translations['deviceManagement.devices.masterscope'];
			case this.appConfig.deviceIds.am3:
				return this.translations['deviceManagement.devices.am3'];
			case this.appConfig.deviceIds.diaryPro:
				return this.translations['deviceManagement.devices.diarypro'];
			case this.appConfig.deviceIds.ecoaHandheldTablet:
				return mdtId === this.appConfig.modalityType.ecoaHandheld
					? this.translations['deviceManagement.devices.ecoaHandheld']
					: this.translations['deviceManagement.devices.ecoaTablet'];
			case this.appConfig.deviceIds.siteProCgm:
				return mdtId === this.appConfig.modalityType.sitePro
					? this.translations['deviceManagement.devices.sitepro']
					: this.translations['deviceManagement.devices.cgm'];
			case this.appConfig.deviceIds.feno:
				return this.translations['deviceManagement.devices.feno'];
			case this.appConfig.deviceIds.flowscreen:
				return this.translations['deviceManagement.devices.flowscreen'];
			case this.appConfig.deviceIds.easyOnePro:
				return this.translations['deviceManagement.devices.easyOnePro'];
			case this.appConfig.deviceIds.spiroSphere:
				return this.translations['deviceManagement.devices.spiroSphere'];
			case this.appConfig.deviceIds.iSpiro:
				return this.translations['deviceManagement.devices.iSpiro'];
			case this.appConfig.deviceIds.clarioApp:
				return this.translations['deviceManagement.devices.clarioApp'];
			case this.appConfig.deviceIds.vivalink:
				return this.translations['deviceManagement.devices.vivalink'];
			case this.appConfig.deviceIds.cough:
				return this.translations['deviceManagement.devices.cough'];
			default:
				return 'Unknown Device';
		}
	}

	static id() {
		return 'DeviceManagementCtrl';
	}
}

export default DeviceManagementCtrl;
