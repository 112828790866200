/*
 * fileUploadPopupController
 * */

class FileUploadPopupController {
	constructor($log, FileUploader, $timeout, appSession, fileUploadPopupService, appConfig) {
		'ngInject';
		this.$log = $log;
		this.FileUploader = FileUploader;
		this.$timeout = $timeout;
		this.appSession = appSession;
		this.appConfig = appConfig;
		this.fileUploadPopupService = fileUploadPopupService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('FileUploadPopupController');
		this.$log.debug('loaded');

		if (!this.popupId) {
			throw new Error('File upload popup component should have an obligatory parameter id.');
		}

		this.api = this.fileUploadPopupService.register(this.popupId);

		this.api.onShowPopup.subscribe((config) => {
			this.uploadUrl = config.uploadUrl || this.uploadUrl;
			this.infoMessage = config.infoMessage || this.infoMessage;
			this.filters = config.filters || this.filters || [];
			this.multipleUpload = config.multipleUpload || this.multipleUpload;
			this.dragAndDrop = config.dragAndDrop || this.dragAndDrop;
			this.showTextArea = config.showTextArea || this.showTextArea;
			this.open = true;
			this.initPopup();
		});
	}

	$onDestroy() {
		this.fileUploadPopupService.unregister(this.popupId);
	}

	initPopup() {
		this.token = this.appSession.getSessionToken();
		this.disableUploadBtn = false;
		this.initFileUploader();
		this.generateFooterActions();
	}

	initFileUploader() {
		const self = this;

		// FILTERS
		this.defaultFilters = this.fileUploadPopupService.getDefaultFilters();

		this.uploader = new this.FileUploader({
			url: this.uploadUrl,
			headers: {
				Authorization: `Bearer ${this.token}`
			},
			filters: _.unionBy(this.filters, this.defaultFilters, 'name')
		});

		// CALLBACKS
		this.uploader.onWhenAddingFileFailed = (item /*{File|FileLikeObject}*/, filter, options) => {
			this.$log.debug('File Upload Popup: onWhenAddingFileFailed');
			self.api.onWhenAddingFileFailed.onNext({ item, filter, options });
		};

		this.uploader.onAfterAddingFile = (fileItem) => {
			this.$log.debug('File Upload Popup: onAfterAddingFile');
			this.api.onAfterAddingFile.onNext(fileItem);

			if (!this.multipleUpload) {
				this.disableUploadBtn = true;
				fileItem.upload();
			}
		};

		this.uploader.onAfterAddingAll = (addedFileItems) => {
			this.$log.debug('File Upload Popup: onAfterAddingAll');
			this.api.onAfterAddingAll.onNext(addedFileItems);
			this.updateFooterActions();
		};

		this.uploader.onBeforeUploadItem = (item) => {
			this.$log.debug('File Upload Popup: onBeforeUploadItem');
			this.api.onBeforeUploadItem.onNext(item);
		};

		this.uploader.onProgressItem = (fileItem, progress) => {
			this.$log.debug('File Upload Popup: onProgressItem');
			this.api.onProgressItem.onNext({ fileItem, progress });
			this.updateFooterActions();
			if (progress === 100) {
				this.showItemVirusCheck(fileItem);
			}
		};

		this.uploader.onProgressAll = (progress) => {
			this.$log.debug('File Upload Popup: onProgressAll');
			this.api.onProgressAll.onNext(progress);
			this.updateFooterActions();
		};

		this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
			this.$log.debug('File Upload Popup: onSuccessItem');
			this.api.onSuccessItem.onNext({ fileItem, response, status, headers });
		};

		this.uploader.onErrorItem = (fileItem, response, status, headers) => {
			this.$log.debug('File Upload Popup: onErrorItem');
			this.api.onErrorItem.onNext({ fileItem, response, status, headers });
		};

		this.uploader.onCancelItem = (fileItem, response, status, headers) => {
			this.$log.debug('File Upload Popup: onCancelItem');
			this.api.onCancelItem.onNext({ fileItem, response, status, headers });
		};

		this.uploader.onCompleteItem = (fileItem, response, status, headers) => {
			this.$log.debug('File Upload Popup: onCompleteItem');
			this.api.onCompleteItem.onNext({ fileItem, response, status, headers });
			this.hideItemVirusCheck(fileItem);
		};

		this.uploader.onCompleteAll = () => {
			this.$log.debug('File Upload Popup: onCompleteAll');
			this.api.onCompleteAll.onNext(true);
			this.updateFooterActions();
		};
	}

	cancelAction() {
		this.uploader.cancelAll();
		this.open = false;
		const notUploadedItems = this.getNotUploadedItems;
		const uploadedItems = this.getUploadedItems();
		const failedItems = this.getFailedItems();

		this.api.onClosePopup.onNext({ uploadedItems, notUploadedItems, failedItems });
	}

	getUploadedItems() {
		return this.uploader.queue.filter((item) => {
			return item.isSuccess;
		});
	}

	getNotUploadedItems() {
		return this.uploader.getNotUploadedItems();
	}

	getFailedItems() {
		return this.uploader.queue.filter((item) => {
			return item.isError;
		});
	}

	generateFooterActions() {
		this.closeFooterAction = {
			action: () => {
				this.cancelAction();
			},
			displayName: 'app.buttons.close',
			isDisabled: false
		};

		this.cancelAllFooterAction = {
			action: () => {
				this.uploader.cancelAll();
			},
			displayName: 'app.fileUpload.cancelAll',
			isDisabled: true
		};

		this.uploadAllFooterAction = {
			action: () => {
				this.uploader.uploadAll();
			},
			cssClass: '-es-primary',
			displayName: 'app.fileUpload.uploadAll',
			isDisabled: true
		};

		this.removeAllFooterAction = {
			action: () => {
				this.uploader.clearQueue();
				this.updateFooterActions();
			},
			displayName: 'app.fileUpload.clearAll',
			isDisabled: true
		};

		this.footerActions = [];

		if (this.multipleUpload) {
			this.footerActions.push(this.uploadAllFooterAction);
			this.footerActions.push(this.removeAllFooterAction);
			this.footerActions.push(this.cancelAllFooterAction);
		}
		this.footerActions.push(this.closeFooterAction);

	}

	updateFooterActions() {
		this.uploadAllFooterAction.isDisabled = !this.uploader.getNotUploadedItems().length ||
			this.uploader.isUploading;
		this.removeAllFooterAction.isDisabled = !this.uploader.queue.length || this.uploader.isUploading;
		this.cancelAllFooterAction.isDisabled = !this.uploader.isUploading;
	}

	showItemVirusCheck(item) {
		item.checkingViruses = true;
	}

	hideItemVirusCheck(item) {
		item.checkingViruses = false;
	}
}

export default FileUploadPopupController;
