/*
 * announcementsWidgetController
 * */

class AnnouncementsWidgetController {
	constructor($log, announcementService, appI18n, $filter, $timeout) {
		'ngInject';
		this.$log = $log;
		this.appI18n = appI18n;
		this.$filter = $filter;
		this.$timeout = $timeout;
		this.announcementService = announcementService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('AnnouncementsWidgetController');
		this.$log.debug('loaded');
		this.getAnnouncement(this.heading);
		this.widgetControls = this.getWidgetControls();
	}

	getWidgetControls() {
		return [{
			type: 'button',
			cssClass: '',
			displayName: 'dashboard.details',
			action: this.openDetailsPopup.bind(this)
		}];
	}

	getAnnouncement(title) {
		this.isLoading = true;

		this.announcementService.getDashboardAnnouncement().then((data) => {
			this.dashboardAnnouncement = data;

			this.title = this.getAnnouncementTitle(title, data.title);
			this.createdDetailsInfo = this.getDetailsInfo(data.createdBy, data.createdOn);

			if (data.contentType === 'text/html' || data.contentType.indexOf('image/') >= 0) {
				this.displayContent = true;
			}
			this.isLoading = false;
		}, (data, status /*, header*/) => {
			if (status === 403) {
				this.$log.error('User has no permission');
			} else {
				this.$log.error('Error in retrieving dashboard announcement');

			}
			this.title = this.getAnnouncementTitle(title);
			this.announcementErrorMessage = this.appI18n.translateImmediate('dashboard.loading-error');
			this.isLoading = false;
		});
	}

	getAnnouncementTitle(itemTitle, announcementTitle = '') {
		const iTitle = itemTitle || '';
		let aTitle = '';

		if (announcementTitle.length > 0) {
			aTitle = (announcementTitle.length > 30)
				? ` - ${announcementTitle.substring(0, 30)}...`
				: ` - ${announcementTitle}`;
		}
		return `${iTitle}${aTitle}`;
	}

	getDetailsInfo(createdBy, createdOn) {

		const created = this.appI18n.translateImmediate('dashboard.announcements.created-by');
		const on = this.appI18n.translateImmediate('dashboard.announcements.on');

		return `${created} ${createdBy} ${on} ${this.$filter('ertFilter')(createdOn, 'dateTimeFormat')}`;
	}

	openDetailsPopup() {
		this.announcementsDetailsPopup.open();
	}
	getAnnouncementsDetailsPopupApi($API) {
		this.announcementsDetailsPopup = $API;
	}
}

export default AnnouncementsWidgetController;
