var DateTime = (function () {
  "use strict";

  var buildTimeString = function (date, format) {
    format = format || '%h:%m:%s.%f (%z) ';
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    function pad(value) {
      return (value.toString().length < 2) ? '0' + value : value;
    }

    return format.replace(/%([a-zA-Z]+)/g, function (_, fmtCode) {
      switch (fmtCode) {
        case 'Y' :
          return date.getFullYear();
        case 'UY' :
          return date.getUTCFullYear();
        case 'M' :
          return pad(date.getMonth() + 1);
        case 'UM' :
          return pad(date.getUTCMonth() + 1);
        case 'MMM' :
          return monthNames[date.getMonth()];
        case 'UMMM' :
          return monthNames[date.getUTCMonth()];
        case 'd' :
          return pad(date.getDate());
        case 'Ud' :
          return pad(date.getUTCDate());
        case 'h' :
          return pad(date.getHours());
        case 'Uh' :
          return pad(date.getUTCHours());
        case 'm' :
          return pad(date.getMinutes());
        case 'Um' :
          return pad(date.getUTCMinutes());
        case 's' :
          return pad(date.getSeconds());
        case 'Us' :
          return pad(date.getUTCSeconds());
        case 'f':
          return pad(date.getMilliseconds());
        case 'Uf':
          return pad(date.getUTCMilliseconds());
				case 'z':
					return date.getTimezoneOffset();
        default:
          throw new Error('Unsupported format code: ' + fmtCode);
      }
    });
  };

  //window.formattedNow = function () {
  //  return buildTimeString(new Date());
  //};

  return {
    formatted: buildTimeString,
    formattedDateTimeNow: function (format) {
		return buildTimeString(new Date(), format || '%Y-%M-%d %h:%m:%s.%f (%z) ');
    },
    formattedNow: function () {
      return buildTimeString(new Date());
    }
  };

})();

module.exports = DateTime;
