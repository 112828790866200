(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('StudySelectionPopupController', StudySelectionPopupController);

	/**
	 * @ngInject
	 */
	function StudySelectionPopupController($log, $state, $scope, $window, appI18n, appConfig, appSession,
																				 ssoService, studySelectService, dialogService) {
		var vm = this;

		$log = $log.getInstance("StudySelectionPopupController");
		$log.debug(" loaded");

		vm.onDblClick = function(){
			alert('dblclick');
		};

		vm.appSession = appSession;
		vm.onSelectStudy = onSelectStudy;
		vm.recentStudies = appSession.getRecentStudies();
		vm.selectedStudy = null;
		vm.showStudySelector = false;
		vm.study = appSession.getStudy();
		vm.switchStudy = switchStudy;
		vm.switchToStudy = switchToStudy;

		$scope.$on(appConfig.events.headerOpenStudySelector, onOpenStudySelector);

		function switchStudy(study) {
			vm.appSession.switchStudy(study, false, true);
		}

		function switchToStudy(study) {
			if (+study.portalVersion === 3) {
				loadLegacyStudy(study.protocolId);
			}
			else {
				var appSessionStudy = {
					id: study.protocolId,
					name: study.protocolName,
					number: study.protocolAccount,
					sponsorName: study.sponsorName
				};

				vm.showStudySelection = false;
				appSession.switchStudy(appSessionStudy, false, true);
			}
		}

		function loadLegacyStudy(studyId) {
			vm.isLoadingStudy = true;

			vm.legacyLogoutWarning = dialogService.createConfirmHandler();
			vm.legacyLogoutWarning.show().then(function (result) {
				vm.legacyLogoutWarning.hide();
				if (result) {
					legacyLogin(studyId);
				}
				else {
					vm.isLoadingStudy = false;
				}
			});
		}
		function legacyLogin(studyId) {
			ssoService.legacyLogin(studyId).then(
				function(response) {
					if (!response.data || !response.data.success) {
						showStudyLoadError();
					}
					else {
						redirectToLegacyPortal(response.data);
					}
				},
				function(error) {
					showStudyLoadError(error);

				});
		}

		function redirectToLegacyPortal(data) {
			vm.legacyCompatibilityWarning = dialogService.createConfirmHandler();
			vm.legacyCompatibilityWarning.show().then(function () {
				vm.legacyCompatibilityWarning.hide();
				$window.open(data.redirectUrl);
				vm.showStudySelection = false;
				vm.isLoadingStudy = false;
			});
		}

		function showStudyLoadError() {
			vm.legacyLoadError = dialogService.createConfirmHandler();
			vm.legacyLoadError.show().then(function () {
				vm.legacyLoadError.hide();
				vm.isLoadingStudy = false;
			});
		}

		function onOpenStudySelector() {
			if (!vm.studies) {
				activate();
			}
			vm.showStudySelection = true;
		}

		function onSelectStudy() {
			if (vm.selectedStudy) {
				switchToStudy(vm.selectedStudy);
			}
		}

		function activate() {
			vm.isLoading = true;
			studySelectService.getStudies().then(function (result) {
				vm.studies = result;
				vm.isLoading = false;
			});

			vm.actions = [
				{action: onSelectStudy, isDisable: true, displayName: 'study-switch.button-select'}
			];
			$scope.$watch('vm.selectedStudy', onSelectedStudyChanged);
		}

		function onSelectedStudyChanged(val) {
			vm.actions[0].isDisabled = (!val || val.length === 0);
		}
	}
})();
