(function() {

	'use strict';

angular
	.module('portalApp')
	.directive('ertSignature', ERTSignature);

	/**
	  * @ngInject
	  */

	function ERTSignature($log) {
		return {

			restrict : 'AE',
			replace : false,
			transclude: false,
			bindToController : true,
			scope : {
				sigType: '@sigType',
				signature: '='
			},
			templateUrl : 'scripts/directives/signature/signature.html',

			controllerAs : 'vm',

			controller : function() {
        var vm = this;

        $log.getInstance("signaturecontroller");
        $log.debug("signature controller instantiated");
				//for formly
				vm.options = { formState: {}};

				//Assign scope values
				vm.signature = this.signature;
				vm.sigType = this.sigType;


			}
		};
	}
})();





