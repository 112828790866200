/*
 * visitOverviewWidgetService
 * */
let self, visitCalendarPopupApi;

export default class visitOverviewWidgetService {

	static id() {
		return 'visitOverviewWidgetService';
	}

	constructor($log, $state, appConfig) {
		'ngInject';
		self = this;
		self.$log = $log.getInstance('visitOverviewWidgetService', 'color:green');
		self.$state = $state;
		self.appConfig = appConfig;
	}

	openVisitDetails(visit) {
		self.$state.go(self.appConfig.routes.clDataVisits, {
			country: visit.countryId, site: visit.siteId,
			subject: visit.subjectId, eproSubject: visit.eproSubjectId
		});
	}

	openSubjectCalendar(visit) {
		const calObj = {
			countryId: visit.countryId,
			siteId: visit.siteId,
			subjectId: visit.subjectId,
			siteName: `${visit.sponsorSiteId} - ${visit.siteName}`,
			subjectKey: visit.subjectKey
		};

		visitCalendarPopupApi.open(calObj);
	}

	registerVisitCalendarPopupApi($API) {
		visitCalendarPopupApi = $API;
	}

	unregisterVisitCalendarPopupApi() {
		visitCalendarPopupApi = null;
	}

}
