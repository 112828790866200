(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('SqfRolesCtrl', SqfRolesController);

	/**
	 * @ngInject
	 */
	function SqfRolesController($scope, $stateParams, $log, sqfService, WizardHandler) {
		var vm = this;

		$log = $log.getInstance("SqfRolesController");

		$log.debug("SqfRolesController loaded, sqfId:" + $stateParams.sqfId);

		vm.name = $scope.shared.localized.wizardTitles.roles;
		vm.dataLoading = true;
		vm.saving = false;
		vm.loadingError = '';
		vm.errorMsgs = [];
		vm.sqfId = 0;
		vm.model = {};
		vm.options = {};
		vm.actions = [];
		sqfService.registerRolesHandler(vm);

		vm.addContact = addContact;
		vm.showView = showView;
		vm.allowAddContact = allowAddContact;
		vm.removeContact = removeContact;
		vm.assignContact = assignContact;
		vm.isRoleValid = isRoleValid;
		vm.ValidateRoles = ValidateRoles;
		vm.saveRoleAssignment = saveRoleAssignment;

		if ($stateParams.sqfId > 0) {
			showView($stateParams.sqfId);
		}

		function clear() {
			vm.dataLoading = true;
			vm.saving = false;
			vm.loadingError = '';
			vm.errorMsgs = [];
			vm.sqfId = 0;
			vm.model = {};
			vm.options = {};
		}

		function showView(sqfId) {
			clear();
			vm.sqfId = sqfId;
			setActions();
			sqfService.getRoleContacts(vm.sqfId)
				.then(function (response) {
					if (!vm.loadingError) {
						vm.model = response.data.model;
						vm.options.config = response.data.config;
					}
					vm.dataLoading = false;
					updateActions();
				}, function (error) {
					vm.loadingError = error.data.message || error.data;
				});
		}

		function addContact() {
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.contacts);
			sqfService.showContactWizard(vm.sqfId, 0);
		}

		function allowAddContact(role) {
			return role.ispi === false && (role.singleSelect !== true || role.contacts.length === 0);
		}

		function removeContact(role, contact) {
			vm.errorMsgs = [];
			if (role.ispi === false) {
				role.contacts = $.grep(role.contacts, function (e) {
					return e.contactId != contact.contactId;
				});

				vm.options.config.contacts.forEach(function (item) {
					if (item.contactId === contact.contactId) {
						item.roles = $.grep(item.roles, function (e) {
							return e != role.id;
						});
					}
				});
			}
		}

		function assignContact(role, contact) {
			vm.errorMsgs = [];
			if (role.contacts.length > 0 && role.singleSelect === true) {
				vm.errorMsgs.push($scope.shared.localized.messages.singleContactRole.supplant(role));
				return;
			}
			var result = $.grep(role.contacts, function (e) {
				return e.contactId === contact.contactId;
			});

			if (result.length === 0) {
				role.contacts.push(contact);
				contact.roles.push(role.id);
			}
		}

		function isRoleValid(role) {
			if (role.isRequired === true && role.contacts.length === 0) {
				return false;
			}
			else if (role.singleSelect === true && role.contacts.length > 1) {
				return false;
			}
			return true;
		}

		/**
		 * @return {boolean}
		 */
		function ValidateRoles() {
			vm.model.roles.forEach(function (role) {
				if (role.isRequired === true && role.contacts.length === 0) {
					vm.errorMsgs.push($scope.shared.localized.messages.atLeastOneContactRole.supplant(role));
				}
				else if (role.singleSelect === true && role.contacts.length > 1) {
					vm.errorMsgs.push($scope.shared.localized.messages.singleContactRole.supplant(role));
				}
			});
			vm.options.config.contacts.forEach(function (contact) {
				if (contact.roles.length === 0) {
					vm.errorMsgs.push($scope.shared.localized.messages.noRoleContact.supplant(contact));
				}
			});
			return vm.errorMsgs.length == 0;
		}

		function setActions() {
			vm.actions.length = 0;
			if ($scope.shared.newSqf === true) {
				vm.actions.push({displayName: $scope.shared.localized.buttons.next, action: saveRoleAssignment
								 , isPrimary: true});
				vm.actions.push({displayName: $scope.shared.localized.buttons.back, action: showContacts});
			}
			else {
				vm.actions.push({displayName: $scope.shared.localized.buttons.save, action: saveRoleAssignment
				, isPrimary: true});
				vm.actions.push({displayName: $scope.shared.localized.buttons.cancel, action: showSummary});
			}
			$scope.shared.handleHide = function(){$scope.shared.closeWizard();};
			updateActions();
			$scope.shared.setActions(vm.actions);
		}

		function updateActions() {
			vm.actions[0].isDisabled = vm.dataLoading || vm.saving;
			if (vm.actions.length > 1) {
				vm.actions[1].isDisabled = vm.saving;
			}
		}

		function showContacts(){
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.contacts);
			sqfService.showContactWizard(vm.sqfId,-1);
		}

		function saveRoleAssignment() {
			vm.errorMsgs = [];
			if (ValidateRoles()) {
				vm.saving = true;
				updateActions();
				sqfService.saveRoleContacts(vm.sqfId, vm.model)
					.then(function (response) {
						if (response.data.isSuccessful) {
							goToNextStep();
						} else {
							vm.saving = false;
							updateActions();
							vm.errorMsgs = response.data.messages;
						}
					}, function (error) {
						vm.errorMsgs = [error.data.message || error.data];
						vm.saving = false;
						updateActions();
					});
			}
		}

		function goToNextStep() {
			if ($scope.shared.newSqf === true && $scope.shared.hasAdditionalSiteInfo === true) {
				WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.additionalSiteInfo);
				sqfService.showSiteInfoView(vm.sqfId, 0);
			}
			else {
				showSummary();
			}
		}

		function showSummary() {
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.summary);
			sqfService.showSummaryView(vm.sqfId);
		}
	}
})();
