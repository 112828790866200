import inputController from '../input/ertInput.controller';

class maskedInputController extends inputController {

	$onInit() {
		this.setApi();
	}
	$onChanges(changesObj) {
		if (changesObj.setValue) {
			this.model = changesObj.setValue.currentValue;
		}
	}
}

export default maskedInputController;
