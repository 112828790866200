/*
 * visitCalendarBreadcrumbsController
 * */

class visitCalendarBreadcrumbsController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('visitCalendarBreadcrumbsController');
		this.$log.debug('loaded');
	}

	selectCountry(country) {
		this.onCountryChanged({
			country
		});
	}

	selectSite(site) {
		this.onSiteChanged({
			site
		});
	}

	selectSubject(subject) {
		this.onSubjectChanged({
			subject
		});
	}
}

export default visitCalendarBreadcrumbsController;
