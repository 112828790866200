/*
 * baseInputComponent
 * */

import templateUrl from './baseInput.html';
import controller from './baseInput.controller';
import './baseInput.scss';

const baseInputComponent = {
	bindings: {
		componentReady: '&?'
	},
	templateUrl,
	controller,
	transclude: true
};

export default baseInputComponent;
