class DeviceManagementSubjectCtrl {

	constructor($log, clinicalDataService, eproService, $stateParams, $state, appI18n, appConfig,
		dialogService, popupService, loadingIndicatorService) {
		'ngInject';

		$log = $log.getInstance('SubjectDeviceController');
		$log.debug(' loaded');

		this.isEcoaHandheld = $state.current.params.isEcoaHandheld;
		this.dialogService = dialogService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.popupService = popupService;
		const deviceIds = $state.current.params.deviceIds;
		const tdtId = $state.current.params.tdtId;
		const mdtId = $state.current.params.mdtId;
		
		this.appI18n = appI18n;
		this.appConfig = appConfig;
		this.clinicalDataService = clinicalDataService;
		this.eproService = eproService;
		this.log = $log;

		const self = this;

		this.options = {
			columnDefs: [
				{
					translationKey: 'deviceManagement.columns.deviceId',
					fieldName: 'protocolDeviceSerialNumber',
					isSortable: true,
					type: 'string',
					isDefaultFilter: true
				},
				{
					translationKey: 'deviceManagement.columns.site',
					fieldName: 'sponsorSiteId',
					isSortable: true,
					type: 'string'
				},
				{
					translationKey: 'app.common.subjectKey',
					fieldName: 'subjectKey',
					isSortable: true,
					type: 'string'
				},
				{
					translationKey: 'deviceManagement.columns.status',
					fieldName: 'deviceStatus',
					isSortable: true,
					type: 'enum',
					formatter: 'activeInactive',
					enumType: 'bool',
					enums: [
						{
							displayName: this.appI18n.translateImmediate('deviceManagement.labels.active'),
							values: ['1']
						},
						{
							displayName: this.appI18n.translateImmediate('deviceManagement.labels.inactive'),
							values: ['-1']
						}
					]
				}
			],
			sortOrders: [
				{
					fieldName: 'sponsorSiteId'
				},
				{
					fieldName: 'deviceStatus'
				},
				{
					fieldName: 'deviceId'
				}
			],
			gridRowActions: [{
				displayName: this.appI18n.translateImmediate('deviceManagement.labels.deviceInfo'),
				iconCssClass: this.isEcoaHandheld ? 'fa fa-qrcode' : 'es-icon-info-circle',
				action: (row) => {
					self.showSubjectDeviceInfo(row);
				}
			}],
			getData: (options) => {
				return clinicalDataService.getSubjectDevices(deviceIds, tdtId, mdtId, options);
			}
		};
		// add unassign device icon
		if ($state.is(this.appConfig.routes.deviceManagement.ecoaHandheld)) {
			this.options.gridRowActions.push({
				displayName: this.appI18n.translateImmediate('deviceManagement.labels.unassignDevice'),
				enabledIf: (row) => {
					return row.deviceStatus === '1';
				},
				iconCssClass: 'fa fa-chain-broken',
				action: (row) => {
					self.showUnassignDevice(row);
				}
			});
		}

		this.load = true;
	}

	showUnassignDevice(row) {
		const title = this.appI18n.translateImmediate('deviceManagement.labels.titleUnassignDevice');

		this.unassignDeviceTitle = `${title} - ${row.protocolDeviceSerialNumber}`;
		this.unassignDeviceData = {
			eproSubjectId: row.eproSubjectId,
			siteId: row.siteId,
			deviceId: row.deviceLinkId
		};
		this.isUnassignDevicePopupVisible = true;
		this.hasError = false;
		this.isLoading = true;

		this.clinicalDataService.getDeactiveDeviceReasons().then((response) => {
			this.reasons = response.data;
			this.isLoading = false;
		}, (error) => {
			this.log.error('error in unassignDevice: {0}', error);
			this.isLoading = false;
			this.hasError = true;
		});
	}

	reasonChange(reason) {
		this.unassignDeviceData.reasonId = reason.reasonId;
	}

	cancel() {
		this.isUnassignDevicePopupVisible = false;
	}

	submit() {
		this.hasError = false;
		this.isLoading = true;
		this.clinicalDataService
			.unassignDevice(this.unassignDeviceData.siteId,
				this.unassignDeviceData.eproSubjectId,
				this.unassignDeviceData.deviceId,
				this.unassignDeviceData.reasonId)
			.then(() => {
				this.isUnassignDevicePopupVisible = false;
				this.isLoading = false;
				this.showUnassignDeviceSuccessful();
			}, (error) => {
				this.log.error('error in unassignDevice: {0}', error);
				this.hasError = true;
				this.isLoading = false;
			});
	}

	showUnassignDeviceSuccessful() {
		const self = this;

		this.confirmUnassignDeviceHandler = this.dialogService.createConfirmHandler();
		this.confirmUnassignDeviceHandler.show().then(() => {
			self.confirmUnassignDeviceHandler.hide();
			self.reload = true;
		});
	}

	showSubjectDeviceInfo(row) {
		const self = this;

		this.deviceName = row.deviceName;
		this.deviceSerialNumber = row.protocolDeviceSerialNumber;

		this.properties = [];
		this.loadingIndicatorService.show();

		this.clinicalDataService.getSubjectDeviceInfo(
				row.siteId, row.subjectId, row.eproSubjectId, row.deviceId, row.protocolDeviceSerialNumber)
			.then ((response) => {
				self.properties = response.data;
			})
			.catch((error) => {
				self.log.error('error in getSubjectDeviceInfo: {0}', error);
			})
			.finally(() => {
				self.showDeviceInfo = true;
				self.loadingIndicatorService.hide();
			});
	}

	static id() {
		return 'DeviceManagementSubjectCtrl';
	}
}

export default DeviceManagementSubjectCtrl;
