import appConfig from '../../scripts/app.config';
import appLocale from '../../scripts/app.i18n';
import TokenService from '../../scripts/services/tokenService';
import appSession from '../../scripts/app.session';
import configurationService from '../../scripts/services/configurationservice';

/* eslint-disable angular/service-name */

export default angular.module('app.config', [])
	.constant('appConfig', appConfig)
	.constant('tokenService', new TokenService(appConfig))
	.service('appI18n', appLocale)
	.service('appSession', appSession)
	.service('configurationService', configurationService)
	.name;

/* eslint-enable angular/service-name */

