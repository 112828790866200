/*
 * openDcrWidgetComponent
 * */

import templateUrl from './openDcrWidget.html';
import controller from './openDcrWidget.controller';
import './openDcrWidget.scss';

const openDcrWidgetComponent = {
	bindings: {
		heading: '@',
		expertAvailable: '<',
		openItemsThreshold: '<?'
	},
	templateUrl,
	controller
};

export default openDcrWidgetComponent;
