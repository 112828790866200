import templateUrl from './infoPopup.html';
import controller from './infoPopup.controller';
import './infoPopup.scss';

const infoPopupComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default infoPopupComponent;
