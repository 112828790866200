class QrCodeController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('QrCodeController');
		this.$log.debug('loaded');
	}

	$onChanges(/*changesObj*/) {}

	$doCheck() {}

	$onDestroy() {}

	$postLink() {}

}

export default QrCodeController;
