export default class PollingService {
	constructor($log, $q, appSession, appConfig) {
		'ngInject';
		this.$log = $log.getInstance('pollingService', 'color:black');
		this.appConfig = appConfig;
		this.$q = $q;
		this.appSession = appSession;
		this.worker = null;
		this.config = null;
	}

	start(config) {
		const defer = this.$q.defer();

		if (!config) {
			config = {};
		}

		config.token = this.appSession.getSessionToken();

		this.config = config;

		if (this.worker) {
			this.worker.terminate();
		}

		this.worker = new Worker(this.appConfig.workers.polling);
		this.worker.onmessage = (e) => {
			defer.notify(e.data);
		};
		this.worker.postMessage(config);
		return defer.promise;
	}

	restart() {
		return this.worker.postMessage({ resetPollingInterval: true });
	}

	stop() {
		if (this.worker) {
			this.worker.terminate();
			this.worker = null;
		}
	}

	isActive() {
		return !!this.worker;
	}

	static id() {
		return 'pollingService';
	}
}
