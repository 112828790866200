/*
 * visitCalendarBreadcrumbsModule
 * */

import visitCalendarBreadcrumbsComponent from './visitCalendarBreadcrumbs.component';
import breadcrumbsDropdownClick from './breadcrumbsDropdownClick/index';

const visitCalendarBreadcrumbsModule = angular.module(
	'app.components.dashboard.visitOverviewWidget.visitCalendarPopup.visitCalendarBreadcrumbs',
	[breadcrumbsDropdownClick])
	  .component('visitCalendarBreadcrumbs', visitCalendarBreadcrumbsComponent)
	  .name;

export default visitCalendarBreadcrumbsModule;
