/*
 * exportButtonModule
 * */

import exportButtonComponent from './exportButton.component';

const exportButtonModule = angular.module('app.common.table.exportButton', [])
	.component('exportButton', exportButtonComponent)
	.name;

export default exportButtonModule;
