const openToggleDirective = ($document) => {
	return {
		restrict: 'A',
		scope: {
			toggleParent: '&?'
		},
		link(scope, element) {
			const rootElement = element;

			if (scope.toggleParent) {
				element = element.parent();
			}

			const documentClickFn = () => {
				if (element.hasClass('-es-open')) {
					element.removeClass('-es-open');
					$document.off('click', documentClickFn);
				}
			};

			rootElement.on('click', (e) => {
				if (element.hasClass('-es-open')) {
					element.removeClass('-es-open');
					$document.off('click', documentClickFn);
				} else {
					const otherOpenToggles = angular.element(
						$document.context.getElementsByClassName('-es-open'));

					otherOpenToggles.removeClass('-es-open');
					element.addClass('-es-open');
					$document.on('click', documentClickFn);
					e.stopPropagation();
				}
			});

			// clean up
			scope.$on('$destroy', () => {
				$document.off('click', documentClickFn);
			});
		}
	};
};

export default openToggleDirective;
