/*
 * previewPopupService
 * */

export default class previewPopupService {

	static id() {
		return 'previewPopupService';
	}

	constructor($log, appConfig, appI18n) {
		'ngInject';
		this.$log = $log.getInstance('previewPopupService', 'color:green');
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.onShowPopup = new Rx.Subject();
		this.onClosePopup = new Rx.Subject();
	}

	show(config) {
		this.onShowPopup.onNext(config);
	}
}
