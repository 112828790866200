//TODO: safely remove this code
(function () {
    'use strict';

    angular.module('portalApp').directive('entry', Entry);

    /**
     * @ngInject
     */
    function Entry() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/directives/entry.html',
            transclude: true,
            replace: true
        };
    }
})();

