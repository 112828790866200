import templateUrl from './row.html';
import './row.scss';

export default () => {

	class Ctrl {
		constructor() {
			'ngInject';
		}

		valueChange(newValue) {
			this.onChange({
				newValue
			});
		}
	}

	return {
		scope: {
			id: '@',
			model: '=',
			onChange: '&'
		},
		controller: Ctrl,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		replace: true
	};
};
