/*
 * resentVisitsTableController
 * */

class ResentVisitsTableController {
	constructor($log, visitOverviewWidgetService) {
		'ngInject';
		this.$log = $log;
		this.openVisitDetails = visitOverviewWidgetService.openVisitDetails;
		this.openSubjectCalendar = visitOverviewWidgetService.openSubjectCalendar;
	}

	$onInit() {
		this.$log = this.$log.getInstance('ResentVisitsTableController');
		this.$log.debug('loaded');
	}
}

export default ResentVisitsTableController;
