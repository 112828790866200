(function() {
	'use strict';

	angular
		.module("portalApp")
		.service("keyboardService", KeyboardService);

	/**
	 * @ngInject
	 */

	function KeyboardService($log, appConfig) {

		$log = $log.getInstance("KeyboardService");
		$log.debug("KeyboardService loaded");


		function handleKeypress(keyMapArr, keyEvent, handler, preventDefault) {
			if(_.indexOf(keyMapArr, keyEvent.keyCode) >= 0) {
				if(typeof handler === 'function') {
					handler();
					if (preventDefault) { event.preventDefault(); }
				}
			}
		}


		return {
			handleKeypress: handleKeypress,
			keyMap: {
				'space': 32,
				'enter': 13,
				'escape': 27
			}
		};
	}
})();
