(function () {

    'use strict';

    angular
        .module('portalApp')
        .service('paperEntryDataService', PaperEntryDataService);

    /**
     * @ngInject
     */
    function PaperEntryDataService(appConfig, $http, $log,appSession, appI18n) {
        $log = $log.getInstance("PaperEntryDataService", "color:blue");
        $log.debug("loaded");

        var service = {
            getFormattedDate: getFormattedDate,
            getFormattedEventTime: getFormattedEventTime,
            getsubjectCreateDate: getsubjectCreateDate
        };

        return service;

        function log(name, url) {
            $log.debug(`PaperEntryDataService.${name} with url: ${url}`);
        }

        function getFormattedDate(input) {
            return moment(input, 'DD-MMM-YYYY').format('YYYY-MM-DD');
        }

        function getFormattedEventTime(time) {
            // provide time in HH:mm format when we have it in HHmm format
            if (time.length === 4 && time.indexOf(':') < 0) {
                return time.substring(0, 2) + ':' + time.substring(2);
            }
            return time;
        }

        function getsubjectCreateDate() {
            return new Date().toISOString();
        }
    }
})();
