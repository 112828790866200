const base64 = require('Base64');

class LogisticsController {
	constructor(
		$log,
		$stateParams,
		$state,
		appSession,
		appConfig,
		$sce
	) {
		'ngInject';
		this.$log = $log;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.appSession = appSession;
		this.appConfig = appConfig;
		this.$sce = $sce;
	}

	$onInit() {
		this.$log = this.$log.getInstance('LogisticsController');
		this.$log.debug('loaded');

		this.logisticsUrl = this.getLogisticsUrl();
	}

	getLogisticsUrl() {
		const currentStudy = this.appSession.getStudy();
		const logisticsUrl = this.appConfig.external.logisticsUrl;
		const state = {
			projectId: currentStudy.id
		};

		// check if we need to redirect the user to a specific destination URL
		if (this.$state.current.params && this.$state.current.params.microportalPage) {
			state.destinationUrl = `studies/${currentStudy.id}/${this.$state.current.params.microportalPage}`;
		}

		const base64EncodedState = encodeURIComponent(base64.btoa(angular.toJson(state)));
		const url = `${logisticsUrl}?state=${base64EncodedState}`;

		return this.$sce.trustAsResourceUrl(url);
	}

}

export default LogisticsController;
