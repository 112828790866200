(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('EditContactController', EditContactController);

	/**
	 * @ngInject
	 */
	function EditContactController($q, $log, $rootScope, appI18n, userContactService,
								   sqfService, appSession, appConfig, $timeout, $window, ssoService,
								   configurationService, notificationService, loadingIndicatorService, popupService) {
		$log = $log.getInstance('EditContactController');
		$log.debug(' loaded');
		var vm = this;

		vm.model = {};
		vm.validation = {
			required: {},
			errors: {}
		};
		vm.fieldConfig = {};
		vm.roleIdPrincipalInvestigator = '1';
		vm.showEditContact = false;
		var countryIdUsa = 71;
		var countryIdCanada = 8;
		var usCaRegex = /^[+]?1\-/g;
		let logout, back, create;

		vm.titles = [
			{"name": "D.D.S.", "id": "1"},
			{"name": "Dr.", "id": "2"},
			{"name": "M.D.", "id": "3"},
			{"name": "M.S.", "id": "4"},
			{"name": "Mr.", "id": "5"},
			{"name": "Mrs.", "id": "6"},
			{"name": "Ms.", "id": "7"},
			{"name": "D.O.", "id": "8"},
			{"name": "Prof.", "id": "9"}
		];

		vm.actions = getGlobalUserActions();
		vm.updateContact = updateContact;
		vm.validateAndFillForm = validateAndFillForm;
		vm.selectSiteType = selectSiteType;
		vm.selectCommunicationChannel = selectCommunicationChannel;
		vm.isCountryUsOrCa = isCountryUsOrCa;
		vm.isCountryUs = isCountryUs;
		vm.isNumericPlusMinus = isNumericPlusMinus;
		vm.onCountryChanged = onCountryChanged;

		vm.validateFirstName = validateFirstName;
		vm.validateLastName = validateLastName;
		vm.validateSiteType = validateSiteType;
		vm.validateInstitutionName = validateInstitutionName;
		vm.validateAddress1 = validateAddress1;
		vm.validateAddress2 = validateAddress2;
		vm.validateAddress3 = validateAddress3;
		vm.validateCity = validateCity;
		vm.validatePostalCode = validatePostalCode;
		vm.validateProvince = validateProvince;
		vm.validatePhone = validatePhone;
		vm.validateMobile = validateMobile;
		vm.validateFax = validateFax;
		vm.validateCommunicationChannel = validateCommunicationChannel;
		vm.editContactForm = null;
		vm.lastSelectedCountryUSOrCanada = false;

		activate();

		function getGlobalUserActions() {
			//logout action
			let actions = [];

			logout = {
				action: cancelAction,
				displayName: 'app.buttons.cancel'
			};

			// edit contact btns
			back = {
				action() {
					vm.contactSelected = false;
					hideEditContactBtns();
				},
				displayName: 'header.edit-contact.buttons.back',
				isHidden: true
			};

			create = {
				action() {
					vm.updateContact();
				},
				cssClass: '-es-primary',
				displayName: 'header.edit-contact.buttons.create',
				isHidden: true
			};

			$rootScope.incompleteContactsCount && (actions = [create, back, logout]);
			return actions;
		}

		function cancelAction() {
			popupService.show('confirmation', {
				headerTitle: appI18n.translateImmediate('header.edit-contact.confirmation.heading'),
				contentMsg: appI18n.translateImmediate('header.edit-contact.confirmation.message')
			}).then((resp) => {
				if (resp.answer === 'ok') {
					appSession.logout(false, true).then(() => {
						ssoService.gotoGlobalDashboard();
					});
				}
			});

		}

		function initTranslations() {
			appI18n.translate([
				'header.edit-contact.pageTitle',
				'header.edit-contact.selectTitle',
				'header.edit-contact.selectCountry',
				'header.edit-contact.genericError',
				'header.edit-contact.errors.validation.regEx',
				'header.edit-contact.selectContact',
				'header.edit-contact.updateCompleteTxt'
			])
				.then(function (translations) {
					vm.translations = translations;
					vm.editContactTitle = translations["header.edit-contact.pageTitle"];
					vm.model.titleName = translations["header.edit-contact.selectTitle"];
					vm.model.countryName = translations["header.edit-contact.selectCountry"];
					vm.genericErrorMsg = translations["header.edit-contact.genericError"];
					vm.genericRegExErrorMsg = translations["header.edit-contact.errors.validation.regEx"];
					vm.selectContact = translations["header.edit-contact.selectContact"];
					vm.contactUpdateSuccessfulMsg = translations['header.edit-contact.updateCompleteTxt'];
				});
		}

		function activate() {
			initTranslations();

			vm.isLoading = true;
			var defersContacts = $q.all([
				userContactService.getIncompleteContacts(),
				userContactService.getCompleteContacts()
			]);
			defersContacts.then(function (resolvesContacts) {
				vm.incompleteContacts = resolvesContacts[0].data;
				vm.completeContacts = resolvesContacts[1].data;

				if (vm.incompleteContacts.length > 0) {
					var roleIds = [];
					vm.incompleteContacts.forEach(function (el) {
						el.contact.isCompleted = false;
						el.roleIds.forEach(function (id) {
							if (roleIds.indexOf(id) < 0) {
								roleIds.push(id);
							}
						});
					});

					vm.completeContacts.forEach(function (el) {
						el.contact.isCompleted = true;
					});

					vm.contacts = vm.incompleteContacts.concat(vm.completeContacts);

					var defers = $q.all([
						sqfService.getCountries(),
						sqfService.getUsStates(),
						userContactService.getContactFieldConfig(roleIds)
					]);
					defers.then(function (resolves) {
						vm.countries = resolves[0].data;
						vm.states = resolves[1].data;
						vm.fieldConfig = resolves[2].data;

						if (vm.completeContacts.length === 0 && vm.incompleteContacts.length === 1) {
							fillForm(vm.incompleteContacts[0].contact, false, false);
							showEditContactBtns();
						}
						else {
							vm.descriptionTxt = appI18n.translateImmediate('header.edit-contact.notificationCompleteIncomplete');
						}
						vm.isLoading = false;
						vm.showEditContact = true;
					}, function () {
						$log.error('Error getting user contacts');
						// allow using the portal when getting contacts failed for some reasono
						closePopup();
					});
				}
				else {
					vm.isLoading = false;
					closePopup();
				}
			});
		}

		function closePopup() {
			return configurationService.getUserStudySettings().then((response) => {
				if (response.data.studyConfig.usesMyDeviceAccount && response.data.hasMyDeviceAccountPermission) {
					configurationService.showMyDeviceAccount();
				}
				$rootScope.contactEditingDone = true;
			}, () =>  {
				$log.error('Error getting user study settings');
				$rootScope.contactEditingDone = true;
			});
		};

		function setError(msg) {
			vm.isLoading = false;
			vm.hasErrors = true;
			vm.errorMsg = msg;
			// todo logging
		}

		function clearError() {
			vm.hasErrors = false;
			vm.errorMsg = '';
			vm.validation.errors = {};
		}

		function validateAndFillForm(contact) {
			if (vm.incompleteContacts.length > 0) {
				clearError();
				if (contact == null) {
					fillForm(vm.incompleteContacts[0].contact, false, true);
				} else {
					fillForm(contact, contact.isCompleted === true, false);
					validateForm();
				}
				showEditContactBtns();
			} else {
				setError(vm.selectContact);
			}
		}

		function showEditContactBtns() {
			back.isHidden =
				!(vm.contactSelected && (vm.completeContacts.length > 0 || vm.incompleteContacts.length > 1));
			create.isHidden = false;
		}

		function hideEditContactBtns() {
			back.isHidden = true;
			create.isHidden = true;
		}

		function onContactUpdate() {
			vm.contactSelected = false;
			hideEditContactBtns();
		}

		function isNumericPlusMinus(e, number) {
			var result;
			var keyCode = e.keyCode;
			if (number.length == 0) {
				result = ((keyCode > 47 && keyCode < 58) || keyCode == 43);
			}
			else if (number.endsWith('-') || number.endsWith('+')) {
				result = ((keyCode > 47 && keyCode < 58));
			}
			else {
				result = ((keyCode > 47 && keyCode < 58) || keyCode == 45);
			}
			if (!result) {
				e.preventDefault();
			}
			return result;
		}


		function forcePhoneModelUpdateForUS() {
			if (vm.model.phone && vm.model.phone.length > 0) {
				var usPhoneElement = angular.element(document.getElementById('phoneUs'))[0];
				if (usPhoneElement) {
					vm.model.phone = usPhoneElement.value;
				}
			}
			if (vm.model.mobile && vm.model.mobile.length > 0) {
				var usMobileElement = angular.element(document.getElementById('mobileUs'))[0];
				if (usMobileElement) {
					vm.model.mobile = usMobileElement;
				}
			}
			if (vm.model.fax && vm.model.fax.length > 0) {
				var usFaxElement = angular.element(document.getElementById('faxUs'))[0];
				if (usFaxElement) {
					vm.model.fax = usFaxElement.value;
				}
			}
		}

		function updateContact() {

			var isValid = validateForm();
			if (isValid) {
				loadingIndicatorService.show();

				vm.model.country = vm.model.countryCode;

				if (vm.isCountryUsOrCa()) {
					if (vm.model.phone) {
						if (vm.model.phone.indexOf(' x _') > -1) {
							vm.model.phone = vm.model.phone.substring(0, 15);
						} else if (vm.model.phone.indexOf('_') > -1) {
							vm.model.phone = vm.model.phone.substring(0, vm.model.phone.indexOf('_'));
						}
					}
					if (vm.model.fax) {
						if (vm.model.fax.indexOf(' x _') > -1) {
							vm.model.fax = vm.model.fax.substring(0, 15);
						} else if (vm.model.fax.indexOf('_') > -1) {
							vm.model.fax = vm.model.fax.substring(0, vm.model.fax.indexOf('_'));
						}
					}
					if (vm.model.mobile) {
						if (vm.model.mobile.indexOf(' x _') > -1) {
							vm.model.mobile = vm.model.mobile.substring(0, 15);
						} else if (vm.model.mobile.indexOf('_') > -1) {
							vm.model.mobile = vm.model.mobile.substring(0, vm.model.mobile.indexOf('_'));
						}
					}
				}

				vm.model.address = vm.model.address1;
				if (vm.model.address2) {
					vm.model.address += '\n' + vm.model.address2;
				}
				if (vm.model.address3) {
					vm.model.address += '\n' + vm.model.address3;
				}

				var checkedIncompleteContacts = vm.incompleteContacts;
				var urlCalls = [];

				if (!vm.useCompleteContactMode) {

					urlCalls.push(userContactService.updateContact(checkedIncompleteContacts[0].contact.contactId, vm.model));

					for (var i = 1; i < checkedIncompleteContacts.length; i++) {
						if (isPi(checkedIncompleteContacts[0]) || isPi(checkedIncompleteContacts[i])) {
							urlCalls.push(userContactService.updateContact(checkedIncompleteContacts[i].contact.contactId, vm.model));
						}
						else {
							urlCalls.push(userContactService.linkContact(
								checkedIncompleteContacts[i].contact.contactId, checkedIncompleteContacts[0].contact.contactId,
								vm.model.phone, vm.model.fax, vm.model.mobile, vm.model.institutionName));
						}
					}
				}
				else if (vm.useCompleteContactMode) {

					var selectedContact = vm.completeContacts.filter(function (el) {
						return el.contact.contactId === vm.model.contactId;
					});

					checkedIncompleteContacts.forEach(function (el) {
						if (arePisInSameStudy(selectedContact[0], el)) {
							urlCalls.push(userContactService.updateContact(el.contact.contactId, vm.model));
						}
						else {
							urlCalls.push(userContactService.linkContact(
								el.contact.contactId, selectedContact[0].contact.contactId,
								vm.model.phone, vm.model.fax, vm.model.mobile, vm.model.institutionName));
						}
					});
				}

				var hasErrors = false;
				var deferredUpdates = $q.all(urlCalls);

				deferredUpdates.then(function (resolvedUpdate) {
					resolvedUpdate.forEach(function (el) {
						if (el.data != 0) {
							hasErrors = true;
						}
					});

					if (hasErrors) {
						onContactUpdate();
						loadingIndicatorService.hide();
						setError(vm.genericErrorMsg);
					} else {
						onContactUpdate();
						if (checkedIncompleteContacts.length < vm.incompleteContacts.length) {
							showSaveDelayWarning(false);
							//remove updated incomplete contacts
							var newIncompleteContacts = vm.incompleteContacts.filter(function (el) {
								return checkedIncompleteContacts.indexOf(el) < 0;
							});
							vm.incompleteContacts = newIncompleteContacts;

						}
						else {
							showSaveDelayWarning(true);
						}
					}
				}, function (errors) {
					onContactUpdate();
					loadingIndicatorService.hide();
					setError(vm.genericErrorMsg);
				});
			}
		}

		function isPi(contact) {
			return contact.roleNames.indexOf('Principal Investigator') > -1;
		}

		function arePisInSameStudy(contact1, contact2) {
			var result = false;
			if (isPi(contact1) && isPi(contact2)) {
				contact1.studyIds.forEach(function (el) {
					if (contact2.studyIds.indexOf(el) > -1) {
						result = true;
					}
				});
			}
			return result;

		}

		function showSaveDelayWarning(close) {
			if (close) {
				closePopup().then(() => {
					loadingIndicatorService.hide();
					notificationService.showSuccess(vm.contactUpdateSuccessfulMsg);
				});
			} else {
				loadingIndicatorService.hide();
				notificationService.showSuccess(vm.contactUpdateSuccessfulMsg);
				vm.contactSelected = false;
			}
		}

		function validateForm() {
			validateFirstName();
			validateLastName();
			if (vm.hasContactPiRole) {
				validateSiteType();
				validateInstitutionName();
			}
			validateAddress1();
			validateAddress2();
			validateAddress3();
			validateCountry();
			validateCity();
			validatePostalCode();
			validateProvince();
			validatePhone();
			validateMobile();
			validateFax();
			validateCommunicationChannel();

			for (var req in vm.validation.required) {
				if (vm.validation.required[req]) {
					return false;
				}
			}

			for (var err in vm.validation.errors) {
				if (vm.validation.errors[err]) {
					return false;
				}
			}

			return true;
		}

		function validateCommunicationChannel() {
			vm.validation.required.communicationChannel = false;
			if (!vm.useCompleteContactMode && vm.fieldConfig.requiredFields.communicationChannelIsRequired && vm.model.communicationChannel === undefined) {
				vm.validation.required.communicationChannel = true;
			}
		}

		function validateFax() {
			vm.validation.required.fax = false;
			vm.validation.errors.fax = false;
			vm.validation.errors.faxMinLength = false;
			var input = vm.model.fax ? vm.model.fax.replace(' ', '') : undefined;
			if ((vm.fieldConfig.requiredFields.faxIsRequired && !input) ||
				(!input && vm.model.communicationChannel == 3)) {
				vm.validation.required.fax = true;
			}
			else if (!vm.isCountryUsOrCa() && input) {
				var matcher = new RegExp(vm.fieldConfig.phonePatternGeneral);
				if (!matcher.test(input)) {
					vm.validation.errors.fax = true;
				}
				else if (input.length < 7) {
					vm.validation.errors.faxMinLength = true;
				}
			}
		}

		function validateMobile() {
			vm.validation.required.mobile = false;
			vm.validation.errors.mobile = false;
			vm.validation.errors.mobileMinLength = false;
			var input = vm.model.mobile ? vm.model.mobile.replace(' ', '') : undefined;
			if (vm.fieldConfig.requiredFields.mobilePhoneIsRequired && !input) {
				vm.validation.required.mobile = true;
			}
			else if (!vm.isCountryUsOrCa() && input) {
				var matcher = new RegExp(vm.fieldConfig.phonePatternGeneral);
				if (!matcher.test(input)) {
					vm.validation.errors.mobile = true;
				}
				else if (input.length < 7) {
					vm.validation.errors.mobileMinLength = true;
				}
			}
		}

		function validatePhone() {
			vm.validation.required.phone = false;
			vm.validation.errors.phone = false;
			vm.validation.errors.phoneMinLength = false;
			var input = vm.model.phone ? vm.model.phone.replace(' ', '') : undefined;
			if (vm.fieldConfig.requiredFields.telephoneIsRequired && !input) {
				vm.validation.required.phone = true;
			}
			else if (!vm.isCountryUsOrCa() && input) {
				var matcher = new RegExp(vm.fieldConfig.phonePatternGeneral);
				if (!matcher.test(input)) {
					vm.validation.errors.phone = true;
				}
				else if (input.length < 7) {
					vm.validation.errors.phoneMinLength = true;
				}
			}
		}

		function validateProvince() {
			if (vm.model.countryCode && vm.model.countryCode === 'US') {
				vm.model.province = '';
				validateRestrictedInputField('stateCode', vm.fieldConfig.requiredFields.stateIsRequired);
			} else {
				vm.model.stateCode = '';
				validateRestrictedInputField('province', vm.fieldConfig.requiredFields.provinceIsRequired);
			}
		}

		function validateRestrictedInputField(fieldName, isRequired) {
			vm.validation.required[fieldName] = !vm.useCompleteContactMode && isRequired && !vm.model[fieldName];
			vm.validation.errors[fieldName] = !vm.useCompleteContactMode && vm.model[fieldName] && !appConfig.constants.patterns.restrictedInput.test(vm.model[fieldName]);
		}

		function validatePostalCode() {
			if(!vm.useCompleteContactMode) {
				if (vm.model.countryCode && vm.model.countryCode === 'US') {
					var matcher = new RegExp(appConfig.constants.patterns.usaZipCode);
					vm.validation.required.postalCode = !vm.useCompleteContactMode && vm.fieldConfig.requiredFields.zipIsRequired && !vm.model.postalCode;
					vm.validation.errors.postalCode = !vm.useCompleteContactMode && vm.model.postalCode && !matcher.test(vm.model.postalCode);
				}
				else {
					validateRestrictedInputField('postalCode', vm.fieldConfig.requiredFields.zipIsRequired);
				}
			}
		}

		function onCountryChanged() {
			$timeout(() => {
				const isUSOrCA = vm.isCountryUsOrCa();
				if (isUSOrCA != vm.lastSelectedCountryUSOrCanada) {
					vm.model.phone = null;
					vm.model.fax = null;
					vm.model.mobile = null;
				}
				vm.lastSelectedCountryUSOrCanada = isUSOrCA;
			});
		}

		function validateCountry() {
			validateRestrictedInputField('countryCode', vm.fieldConfig.requiredFields.countryIsRequired);
		}

		function validateCity() {
			validateRestrictedInputField('city', vm.fieldConfig.requiredFields.cityIsRequired);
		}

		function validateAddress3() {
			validateRestrictedInputField('address3', false);
		}

		function validateAddress2() {
			validateRestrictedInputField('address2', false);
		}

		function validateAddress1() {
			validateRestrictedInputField('address1', vm.fieldConfig.requiredFields.address1IsRequired);
		}

		function validateSiteType() {
			vm.validation.required.siteType = false;
			if (!vm.useCompleteContactMode && vm.fieldConfig.requiredFields.institutionNameIsRequired && vm.model.siteType === undefined) {
				vm.validation.required.siteType = true;
			}
		}

		function validateInstitutionName() {
			validateRestrictedInputField('institutionName', vm.fieldConfig.requiredFields.institutionNameIsRequired);
		}

		function validateLastName() {
			validateRestrictedInputField('lastName', vm.fieldConfig.requiredFields.lastNameIsRequired);
		}

		function validateFirstName() {
			validateRestrictedInputField('firstName', vm.fieldConfig.requiredFields.firstNameIsRequired);
		}

		function selectSiteType(isIndividual) {
			if (isIndividual) {
				vm.model.institutionName = 'Individual';
			}
			else {
				vm.model.institutionName = '';
			}
			validateSiteType();
		}

		function selectCommunicationChannel(isEmail) {
			validateCommunicationChannel();
		}

		function isCountryUs() {
			return vm.model.countryCode === 'US';
		}

		function isCountryUsOrCa() {
			return vm.model.countryCode === 'US' || vm.model.countryCode === 'CA';
		}

		function fillForm(contact, useCompleteContact, clearFields) {
			var incomplete = vm.incompleteContacts;
			vm.hasContactPiRole = false;
			incomplete.forEach(function (el) {
				if (el.roleIds.indexOf(vm.roleIdPrincipalInvestigator) >= 0) {
					vm.hasContactPiRole = true;
				}
			});

			vm.model = {};
			vm.contactSelected = true;
			vm.useCompleteContactMode = useCompleteContact;

			vm.model.contactId = contact.contactId;
			vm.model.protocolId = contact.protocolId;
			vm.model.reportingEMail = contact.protocolContactEMail;
			vm.model.mail = contact.personEmail;

			if (clearFields) {
				return;
			}

			vm.hasCompleteContactInstitutionName = contact.institutionName;
			if (contact.titleName && vm.hasContactPiRole) {
				vm.titles.forEach(function (el) {
					if (el.name === contact.titleName) {
						vm.model.titleName = contact.titleName;
						vm.model.titleId = el.id;
					}
				});
			}
			vm.model.firstName = contact.contactFirstName;
			vm.model.lastName = contact.contactLastName;
			if (contact.institutionName) {
				var isIndividual = contact.institutionName === 'Individual';
				vm.model.siteType = isIndividual ? 1 : 0;
				if (!isIndividual) {
					vm.model.institutionName = contact.institutionName;
				}
				else {
					vm.model.institutionName = 'Individual';
				}
			}
			if (contact.contactAddress) {
				var addressArray = contact.contactAddress.split('\n');
				vm.model.address1 = addressArray[0];
				vm.model.address2 = addressArray.length > 1 ? addressArray[1] : '';
				vm.model.address3 = addressArray.length > 2 ? addressArray[2] : '';
			}
			if (contact.contactCountryName) {
				vm.countries.forEach(function (el) {
					if (el.name === contact.contactCountryName) {
						vm.model.countryName = contact.contactCountryName;
						vm.model.countryCode = el.value;
					}
				});
			}

			vm.model.city = contact.contactCity;
			vm.model.postalCode = contact.contactZipCode;
			if (contact.contactCountryId == countryIdUsa) {
				vm.states.forEach(function (el) {
					if (el.name === contact.usStateName) {
						vm.model.stateCode = el.value;
						vm.model.stateName = el.name;
					}
				});
			}
			if (contact.contactCountryId == countryIdUsa || contact.contactCountryId == countryIdCanada) {
				vm.lastSelectedCountryUSOrCanada = true;
				if (contact.protocolContactPhone) {
					vm.model.phone = contact.protocolContactPhone.replace(usCaRegex, '');
				}
				if (contact.protocolContactCellarPhone) {
					vm.model.mobile = contact.protocolContactCellarPhone.replace(usCaRegex, '');
				}
				if (contact.protocolContactFax) {
					vm.model.fax = contact.protocolContactFax.replace(usCaRegex, '');
				}
			}
			else {
				vm.lastSelectedCountryUSOrCanada = false;
				vm.model.province = contact.contactForeignProvince;
				vm.model.phone = contact.protocolContactPhone;
				vm.model.mobile = contact.protocolContactCellarPhone;
				vm.model.fax = contact.protocolContactFax;
			}
			if (contact.preferredCommChannelId) {
				vm.model.communicationChannel = contact.preferredCommChannelId;
			}
		}

	}
})();
