/*
 * openQueriesTableDirective
 * */

import templateUrl from './openQueriesTable.html';
import controller from './openQueriesTable.controller';
import './openQueriesTable.scss';

const openQueriesTableDirective = () => {
	return {
		scope: {
			row: '<'
		},
		controller,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		link(/*scope, element, attr, ctr*/) {},
		replace: true
	};
};

export default openQueriesTableDirective;
