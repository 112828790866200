(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('GlobalHeaderController', GlobalHeaderController);

	/**
	 * @ngInject
	 */
	function GlobalHeaderController($log, $state, $timeout, $document,
									appI18n, appConfig, appSession,
									configurationService, studySelectService, ssoService,
									globalHeaderService) {
		$log = $log.getInstance('GlobalHeaderController');
		$log.debug('loaded');

		var vm = this;

		vm.studyModeMenuAdded = false;

		var globalHeaderCss = `${appConfig.gsso.getNavigatorHeaderBaseUrl()}header.css`;
		var selector = `link[href='${globalHeaderCss}']`;

		if (!$(selector).length) {
			$('<link>').attr({ rel: 'stylesheet', type: 'text/css', href: globalHeaderCss}).appendTo('body');
		}

		const study = appSession.getStudy();
		let redirecting = false;

		if (study) {
			redirecting = ssoService.redirectToDashboardIfNotEmbedded(study.id, study.number);
		}

		if (redirecting) {
			$log.debug('direct redirect from globalHeader.js');
		} else {
			loadGlobalHeader();
		}

		function loadGlobalHeader() {
			if (!appSession.isValid()) {
				$log.debug('do not load header script: no valid session');
				return;
			}


			configurationService.checkSession().then((response) => {
				// make sure to only load header when session is stil valid
				// so that MSP3 can handle any session expiration itself, not let the header
				// handle it here
				$log.debug(`check session returns: ${response.data}`);
				if (response.data) {
					loadHeaderScript();
				} else {
					// handle session expiration
					appSession.handleSessionExpiration();
				}
			});
		}
		function loadHeaderScript() {
			if (!window.ertGlobalHeader && appConfig.gsso.useGlobalHeader) {

				window.ertGlobalHeaderReady = function () {
					return getGlobalHeaderReadyReturnState();
				};

				$log.debug('adding header script tag');
				var script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = `${appConfig.gsso.getNavigatorHeaderBaseUrl()}header.js`;
				script.id = 'ert-global-header-script';
				script.setAttribute('data-client-id', 'msp3');
				document.body.appendChild(script);
			}
			activate();
		}

		function activate() {
			$log.debug('activate');
			if (!appSession.isValid()) {
				$log.debug('no valid session');
				return;
			}
			if (hasStudy()) {
				$log.debug('has study');
				configurationService.getUserStudySettings()
					.then(function (response) {
						updateGlobalHeader(response.data.studyConfig.studyModes);
					});
			}
			vm.hideGssoMenu = $state.current.name === appConfig.routes.contactCustomerCare;
		}

		function hasStudy() {
			var study = appSession.getStudy();
			return study !== null;
		}

		function updateGlobalHeader(modes) {
			$log.debug('update global header');
			var globalHeader = window.ertGlobalHeader;
			if (!globalHeader) {
				$log.debug("No global header yet");
				window.ertGlobalHeaderReady = function () {
					$timeout(() => updateGlobalHeader(modes));
					return getGlobalHeaderReadyReturnState();
				};
				return;
			}

			// send "header ready" signal
			globalHeaderService.setHeaderReady(true);

			// to be backward compatible we need to get the header height (0 or 50px)
			const gssoHeader = $document.find('#ert-global-header');
			const gssoHeaderHeight = (gssoHeader.length === 1 &&
									  gssoHeader[0].offsetHeight) || 0;

			$document.find('div.es-app')
				.css('min-height', `calc(100vh - ${gssoHeaderHeight}px)`);

			setLogoutCallback(globalHeader);
			addStudyModeDropDown(globalHeader, modes);
			registerClientRoot(globalHeader);

			$log.debug('done update global header');
		}

		function setLogoutCallback(globalHeader) {
			globalHeader.subscribe('logout', (done) => {
				$log.debug('gsso logout -> app session logout ');
				appSession.gssoLogout().then(() => {
					if (done) {
						done();
					}
				});
			});
		}

		function registerClientRoot(globalHeader) {
			// check if registerClientRoot is available
			if (globalHeader && globalHeader.registerClientRoot) {
				const study = appSession.getStudy();

				// to get the default landing page we can reuse the getStudySwitchConfig
				studySelectService.getStudySwitchConfig(study.id, study.studyModeId).then((response) => {
					const defaultRoute = appConfig.getRouteForModuleId(response.data.startingPageId);

					// the studySwitchConfig call considers all required cases (site admin,
					// starting page configured in PD, fallback to first available module)
					if (defaultRoute) {
						globalHeader.registerClientRoot(() => {
							$state.go(defaultRoute, {study: study.id, mode: study.studyModeId}, { inherit: false });
						});
					} else {
						$log.warn(`RegisterClientRoot - No starting page available.`);
					}
				});
			} else {
				$log.warn(`RegisterClientRoot - Registration failed. Parameters: globalHeader ${!!globalHeader}, registerClientRoot ${!!globalHeader.registerClientRoot}`);
			}
		}

		function getGlobalHeaderReadyReturnState() {
			const globalHeaderUpdateTime = appSession.getGlobalHeaderInitTime();
			const session = appSession.get();

			$log.debug('global header updatetime: ' + globalHeaderUpdateTime);
			$log.debug('session creation: ' + session.timestamp);

			const returnState = {};

			if (!globalHeaderUpdateTime || (+session.timestamp > +globalHeaderUpdateTime)) {
				returnState.idToken = appSession.getSessionToken();
				appSession.updateGlobalHeaderInitTime();
			}

			const study = appSession.getStudy();

			returnState.studyId = study.id;
			returnState.productCode = 'msp3';
			returnState.studyNumber = study.number;

			$log.debug('global header ready return: ' + JSON.stringify(returnState));
			return returnState;
		}

		function addStudyModeDropDown(globalHeader, modes, retryCount = 0) {
			const session = appSession.get();

			if (!session.state || !session.state.study) {
				const study = appSession.getStudy();

				globalHeader.createState(study.id, 'msp3').then((state) => {
					$log.debug(`set header state to ${state}`);

					globalHeader.setState(state);
					globalHeader.show();
				});
			}
			globalHeader.rendered(() => {
				$log.debug('global header there');
				$log.debug('controller thinks menu is already added: ' + vm.studyModeMenuAdded);
				$log.debug('globalheader thinks menu is already added: ' + globalHeader.msp3StudyModeDropdown);

				var translationKeys = [
					'header.mode.study',
					'header.mode.training',
					'header.mode.proficiency',
					'header.mode.sample',
					'header.mode.uat'
				];
					appI18n.translate(translationKeys).then(function(translations) {
					var currentMode = appSession.getStudyModeId();
					var icons = ['application-mode', 'training-mode', 'proficiency-mode', 'sample-mode', 'client-uat'];
					var menu = {
						label: translations[translationKeys[currentMode]],
						icon: icons[currentMode]
					};
					if (currentMode !== 0) {
						menu.className = 'gh-training-mode';
					}
					menu.items = getStudyModeItems(modes, translationKeys, translations, icons, currentMode);
					const oldDropdown = globalHeader.msp3StudyModeDropdown;
					if (oldDropdown) {
						$log.debug('removing previous dropdown');
						globalHeader.removeWidget(oldDropdown);
						globalHeader.msp3StudyModeDropdown = null;
					}
					if (menu.items.length > 0) {
						var dd = globalHeader.addDropdown(2, menu);
						// remember dropdown. Cannot remember it in the controller, as the
						// controller is re-initialized.
						globalHeader.msp3StudyModeDropdown = dd;
						vm.studyModeMenuAdded = true;
							globalHeader.show();
					}
				});
			});
			if ($document[0].getElementById('ert-global-header')) {
				globalHeader.show();
			} else if (retryCount < 50) {
				$log.debug('no header element yet, retry later, try: ' + retryCount);
				$timeout(() => addStudyModeDropDown(globalHeader, modes, ++retryCount), 500);
			} else {
				// avoid infinite loop
				$log.error('still no header element found, no longer retrying');
			}
		}

		function switchStudyMode(mode) {
			appSession.switchStudyMode(mode);
		}

		function getStudyModeItems(modes, labelKeys, labels, icons, currentMode) {
			var dropDownItems = [];
			for (var i = 0; i < modes.length; ++i) {
				var modeId = modes[i];

				if (modeId === currentMode) {
					continue;
				}

				var item = {
					label: labels[labelKeys[modeId]],
					icon: icons[modeId]
				};


				(function (i) {
					var item2 = {
						events: [{
							type: 'click',
							selector: 'button',
							handler: (e) => {
								switchStudyMode(i);
							}
						}],
						html: '<button class="ert-cursor-pointer ert-dark-icon"><span class="es-icon es-icon-' + icons[modeId] + '"></span><span>' + item.label + '</span></button>'
					};
					dropDownItems.push(item2);
				})(modeId);

			}

			return dropDownItems;
		}
	}

})();
