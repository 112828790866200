/*
 * adminModule
 * */

import notificationMessageModule from './notificationMessage/index';

const adminModule = angular.module('app.components.admin',
										  [notificationMessageModule])
	  .name;

export default adminModule;
