(function () {
	'use strict';

	angular.module('portalApp').directive('ertSidebar', Sidebar);

	/**
	 * @ngInject
	 */
	function Sidebar($window, $log, $rootScope, analyticsService, $stateParams, appConfig) {
		var $ = $window.$;

		function _Sidebar(id, element) {
			this.el = element;//$(element);
			this.id = id;
			this.initEvents();
		}

		_Sidebar.prototype.expandItem = function (jQItem) {
			if (this.isOpen() === false) {
				this.open();
			}
			this.collapseItems();
			jQItem.addClass('is-expanded');
		};

		_Sidebar.prototype.collapseItem = function (jQItem) {
			jQItem.removeClass('is-expanded');
		};

		_Sidebar.prototype.collapseItems = function () {
			this.el.find('.is-expanded').removeClass('is-expanded');
		};

		_Sidebar.prototype.toggleItem = function (jQItem) {
			jQItem.hasClass('is-expanded') ? this.collapseItem(jQItem) : this.expandItem(jQItem);
		};

		_Sidebar.prototype.toggle = function () {
			this.trackEvent();
			this.el.toggleClass('is-closed');
			$('#ert-main-container').toggleClass('ert-open-sidebar');
			this.collapseItems();
		};

		_Sidebar.prototype.trackEvent = function () {
			var action = this.isOpen() ? appConfig.trackAction.collapsed : appConfig.trackAction.expanded;
			$log.debug('Clario navigation action:' + action);
			analyticsService.trackEvent(appConfig.trackCategory.toggleSidebar, action);
		};

		_Sidebar.prototype.open = function () {
			this.el.removeClass('is-closed');
			$('#ert-main-container').addClass('ert-open-sidebar');
		};

		_Sidebar.prototype.close = function () {
			this.trackEvent();
			this.el.addClass('is-closed');
			$('#ert-main-container').removeClass('ert-open-sidebar');
			this.collapseItems();
		};

		_Sidebar.prototype.isOpen = function () {
			return !this.el.hasClass('is-closed');
		};

		_Sidebar.prototype.initEvents = function () {
			var sidebar = this;
			$($window.document).on('click.sidebar-' + sidebar.id, function (e) {
				var jQItem,
				hasChildren,
				clickedButton,
				clickedHyperlink,
				toggleButton;
				//in case the click occurred somewhere outside the sidebar, close it
				if (sidebar.isOpen() && !sidebar.el.find(e.target).length) {
					sidebar.close();
					return;
				}
				//in case the click occurred on the toggle button, toggle
				toggleButton = $(e.target).closest('.ert-sidebar-toggle');
				if (toggleButton.length) {
					sidebar.toggle();
					return;
				}
				//if the click occurred on a link (not a button), close the sidebar
				clickedHyperlink = $(e.target).closest('a').length;
				if (sidebar.isOpen() && clickedHyperlink) sidebar.close();

				//get the list-item that was clicked
				jQItem = $(e.target).closest('li');

				//does it have sub-items?
				hasChildren = jQItem.hasClass('has-children');

				//and did the user click on a button?
				clickedButton = $(e.target).closest('button').length;

				//if both is true, toggle the sub-items.
				if (hasChildren && clickedButton) sidebar.toggleItem(jQItem);
			});

			sidebar.el.on('$destroy', function() {
				$($window.document).off('click.sidebar-' + sidebar.id);
			});
		};

		function link(scope, element, attrs) {
			var _sidebar = new _Sidebar(scope.$id, element),
			browser = $rootScope.browserDetectString;
			//initially close sidebar for mobile and tablet
			//or if user is redirected back during signature
			browser && (!browser.match(/desktop/) || $stateParams.tokenId) && _sidebar.close();
			$log.debug('Directive::ertSidebar::linked');
		}

		return {
			link: link
		}
	}
})();
