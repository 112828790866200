class TextAreaController {
	constructor(appI18n) {
		'ngInject';
		this.model = null;
		this.input = null;
		this.appI18n = appI18n;
		this.maxlengthErrorMsg = `${this.appI18n.translateImmediate('app.messages.maxLength')} 
			${this.options.maxlength}`;
		this.notifySetValue = (angular.isDefined(this.notifySetValue)) ? !!this.notifySetValue : true;
	}

	$onInit() {
		this.setApi();
	}

	$postLink() {
		this.input = this.fg[this.name];
		// adds custom validators
		for (const index in this.options.validators) {
			this.input.$validators[index] = this.options.validators[index].fn;
		}
	}

	valueChange() {
		this.onChange({ newValue: this.model });
	}

	setApi() {
		this.$API = {
			setValue: (value) => {
				this.model = value;
				if (this.notifySetValue) {
					this.onChange({ newValue: this.model });
				}
			}
		};

		// publish api when component is ready
		if (!this.componentReady) {
			return;
		}
		this.componentReady({ $API: this.$API });
	}

	checkNotValid() {
		if (!this.input) {
			return false;
		}
		/* eslint-disable */
		return (!this.input.$valid && (this.input.$dirty || this.input.$$parentForm.showValidation));
		/* eslint-enable */
	}

}

export default TextAreaController;
