/*
 * siteInfoPopupComponent
 * */

import templateUrl from './siteInfoPopup.html';
import controller from './siteInfoPopup.controller';
import './siteInfoPopup.scss';

const siteInfoPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default siteInfoPopupComponent;
