(function () {
	'use strict';
	var _ = require('lodash');

	angular.module('portalApp').directive('ertModalForm', ModalForm2);

	/**
	 * @ngInject
	 */
	function ModalForm2($timeout, analyticsService, helpService, $location, keyboardService, scrollService, popupService) {
		return {
			restrict: 'EA',
			templateUrl: 'scripts/directives/ertModalForm.html',
			transclude: true,
			replace: true,
			/**
			 * Using isolated scope in directives is recommended
			 * 1. "@"   (  Text binding / one-way binding )
			 * 2. "="   ( Direct model binding / two-way binding )
			 * 3. "&"   ( Behaviour binding / Method binding  )
			 */
			scope: {
				showIf: "=",
				formTitle: "=",
				actions: "=",
				iconActions: "=",
				closeable: "=",
				closeOnEscape: '=?',
				helpWithPopup: "=",
				helpContent: "=",
				onShow: "&",
				onHide: "&",
				handleHide: "&?",
				cssClass: "@",
				size: "@",
				moduleId: "@",
				printExclusive: "@"
			},
			link: function (scope, element, attributes) {
				var printExclusive = (typeof attributes.printExclusive !== 'undefined')? attributes.printExclusive: true;
				scope.getModalSize = function() {
					let result = '-es-modal-large';
					const size = scope.size;

					if (!size) {
						return result;
					}
					if (size === 'small') {
						result = '-es-modal-small';
					} else if (size === 'medium') {
						result = '-es-modal';
					} else if (size === 'large') {
						result = '-es-modal-large';
					} else {
						result = '-es-modal-xlarge';
					}

					return result;
				};

				scope.hideSelf = function () {
					if (angular.isDefined(scope.handleHide) && angular.isFunction(scope.handleHide)) {
						scope.handleHide();
					} else {
						scope.showIf = false;
					}
				};

				scope.openHelp = function () {
					helpService.showModuleHelp(scope.moduleId);
				};

				scope.openHelpWithPopup = function () {
					popupService.show('custom', {
						headerTitle: 'Help',content: scope.helpContent,
					});
				};

				scope.openModuleHelp = function (moduleId) {
					helpService.showModuleHelp(moduleId);
				};

				scope.handleActionKeyPress = _.partial(keyboardService.handleKeypress, [
					keyboardService.keyMap.enter,
					keyboardService.keyMap.space]);

				scope.handleHideKeyPress = function (event) {
					keyboardService.handleKeypress([
						keyboardService.keyMap.enter,
						keyboardService.keyMap.space], event, scope.hideSelf);
				};

				scope.handleEscapeKeyPress = function (event) {
					if (scope.closeOnEscape) {
						keyboardService.handleKeypress([keyboardService.keyMap.escape], event, scope.hideSelf);
					}
				};

				function nicePrint(enable) {
					if (enable) {
						$('body').addClass('ert-print-selective');

						// remove all previous print areas
						var p = $.find('.ert-print-area');
						for (var x = 0; x < p.length; x++) {
						  $(p[x]).removeClass('ert-print-area');
						}
						element.addClass('ert-print-area');
					} else {
						$('body').removeClass('ert-print-selective');
						element.removeClass('ert-print-area');
					}
				}


				function fixScroll(newValue, oldValue) {
					if (newValue === oldValue) { return; }
					if (newValue) {
						scrollService.setScroll();
					} else {
						scrollService.unsetScroll();
					}
				}

				scope.$on('$destroy', function () {
					$('body').removeClass('ert-print-selective');
					element.removeClass('ert-print-area');
					scrollService.unsetScroll();
				});

				scope.$watch('showIf', function (newValue, oldValue) {

					nicePrint(newValue);
					fixScroll(newValue, oldValue);

					/*something weird here, watcher get's called multiple times for every user action.
					 so we have to figure out popup state in this complicated manner: */
					var opened = (newValue && !oldValue),
						closed = (!newValue && oldValue);

					if (closed) {
						$location.search('signKey', null);
						$location.search('tokenId', null);
					}

					if (printExclusive) {
						if (opened) {
							$('body').addClass('ert-print-selective');
							element.addClass('ert-print-area');
						}
						if (closed) {
							$('body').removeClass('ert-print-selective');
							element.removeClass('ert-print-area');
						}
					}

					if (newValue === true) {
						analyticsService.trackModel(attributes.id, scope.formTitle);
					}

					$timeout(function () {
						if (newValue) {
							element.focus();
						}
					}, 200);

					if (opened) {
						scope.onShow();
					} else if (closed) {
						scope.onHide();
					}
				});

				/// FOCUS CAPTURE
				// Set up elements before and after the dialog content to capture focus and
				// redirect back into the dialog.
				var topFocusTrap = document.createElement('div');
				topFocusTrap.classList.add('md-dialog-focus-trap');
				topFocusTrap.tabIndex = 0;

				var bottomFocusTrap = topFocusTrap.cloneNode(false);

				// When focus is about to move out of the dialog, we want to intercept it and redirect it
				// back to the dialog element.
				var focusHandler = function () {
					element.focus();
				};
				topFocusTrap.addEventListener('focus', focusHandler);
				bottomFocusTrap.addEventListener('focus', focusHandler);

				// The top focus trap inserted immeidately before the md-dialog element (as a sibling).
				// The bottom focus trap is inserted at the very end of the md-dialog element (as a child).
				element[0].parentNode.insertBefore(topFocusTrap, element[0]);
				element.after(bottomFocusTrap);

				function cleanup() {
					var body = $('body');

					if (printExclusive) {
						body.removeClass('ert-print-selective');
						element.removeClass('ert-print-area');
					}
				}

				scope.$on('$destroy', function () {
					cleanup();
					topFocusTrap.removeEventListener('focus', focusHandler);
					bottomFocusTrap.removeEventListener('focus', focusHandler);
				});
			}
		};
	}
})();
