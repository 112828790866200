/*
 * editISpiroDeviceValuesPopupController
 * */

import DispenseISpiroDevicePopupController from '../dispenseISpiroDevicePopup/dispenseISpiroDevicePopup.controller';

class EditISpiroDeviceValuesPopupController extends DispenseISpiroDevicePopupController {
	constructor($log, $timeout, clinicalDataService, iSpiroService,
		loadingIndicatorService, signatureService, appI18n,
		appConfig, $stateParams, ertBasePopupService, notificationService) {
		'ngInject';
		super();
		this.$log = $log;
		this.$timeout = $timeout;
		this.$stateParams = $stateParams;
		this.clinicalDataService = clinicalDataService;
		this.iSpiroService = iSpiroService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.signatureService = signatureService;
		this.ertBasePopupService = ertBasePopupService;
		this.notificationService = notificationService;
		this.appI18n = appI18n;
		this.appConfig = appConfig;
		this.onlineHelpId = 'MSP3.ClinicalData.ISpiro.EditDeviceValues';
	}

	onPopupShow() {
		return this.iSpiroService.getSitesMinimal(null, this.countryId).then((res) => {
			this.sites = res.data;
		});
	}

	configure() {
		this.contentMessage = 'clinicaldata.subject.i-spiro.editValuesPopupText';
		this.popupHeading = 'clinicaldata.subject.i-spiro.editPopupHeading';
	}

	canEditParameters() {
		return true;
	}

	getEditSubjectInfo(countryId, siteId, subjectId) {
		return this.iSpiroService.getEditDeviceValueData(countryId, siteId, subjectId).then((res) => {
			return res.data;
		});
	}

	getSubjects(newVal) {
		return this.iSpiroService.getSubjects(
			newVal.countryId, newVal.siteId, this.appConfig.iSpiroActionId.edit)
			.then((res) => {
				if (res.data && res.data.subjects.length > 0) {
					this.subjectsOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.selectSubject';
				} else {
					this.subjectsOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.noSubject';
				}

				return res.data;
			});
	}

	chooseSubmitAction(state) {
		const payload = this.generateUpdateRequestPayload(state);
		const action = this.iSpiroService.updateDeviceValues(
			payload, state.selectedSubject.subjectId, false, state.isRemoteAction);

		action.actionId = this.appConfig.iSpiroActionId.edit;

		return action;
	}

	getSignatureType() {
		return this.appConfig.sigType.editISpiroDeviceValues;
	}
}

export default EditISpiroDeviceValuesPopupController;
