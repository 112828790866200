(function () {
	'use strict';

	angular.module('portalApp')
		.directive('ertStudySelector', StudySelector);

	/**
	 * ngInject
	 */
	function StudySelector() {
		return {
			restrict: 'EA',
			template: '<ert-grid id="study-selector-grid" options="vm.options" selection="vm.selectionType" selected-rows="vm.selectedStudies" init="vm.init" load="vm.load"></ert-grid>',
			scope: {
				selectedStudies: '=',
				selectionType: '=',
				studies: '=',
				init: '='
			},
			controller: StudySelectorController,
			controllerAs: 'vm',
			bindToController: true
		};

		/* @ngInject */
		function StudySelectorController($scope, $log, studySelectService) {

			var vm = this;

			$log = $log.getInstance("StudySelectorController");
			$log.debug(" loaded");

			vm.options= {
				columnDefs: [
				{displayName: 'Sponsor', fieldName: 'sponsorName', isSortable: true, type: 'string'},
				{displayName: 'Study Name', fieldName: 'protocolName', isSortable: true, type: 'string', isDefaultFilter: true},
				{displayName: 'Study #', fieldName: 'protocolAccount', isSortable: true, type: 'string'}
				],
				rowDefs: {
					multiSelection: vm.selectionType === 'multi',
					singleSelection: vm.selectionType === 'single',
					identifier: "protocolId"
				},
				pageSize: 500,
				rowData: vm.studies,
				getData: getStudies
			};

			vm.load = true;

			function getStudies(options) {
				return studySelectService.getStudies(options);
			}
		}
	}
})();
