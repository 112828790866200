/*
 * widgetModule
 * */

import widgetComponent from './widget.component';
import iconLinkModule from '../iconLink/index';

const widgetModule = angular.module('app.common.widget', [iconLinkModule])
	.component('widget', widgetComponent)
	.name;

export default widgetModule;
