import templateUrl from './iSpiroPopup.html';
import controller from './iSpiroPopup.controller';
import './iSpiroPopup.scss';

const iSpiroPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default iSpiroPopupComponent;
