/*
 * baseRowController
 * */

class BaseRowController {
	constructor() {
		'ngInject';
	}

	registerRow(row) {
		if (!this.table) {
			throw Error(`Table row directive require table controller.
			Check directive definition: it should have require: {table: '^tableWrap'}`);
		}
		this.table && this.table.addRow(row);
	}

	rowClicked(row) {
		if (!this.table) {
			throw Error(`Table row directive require table controller.
			Check directive definition: it should have require: {table: '^tableWrap'}`);
		}
		this.table && this.table.rowSelected(row);
	}
}

export default BaseRowController;
