class FrameController {
	constructor($log, $sce, appConfig, appSession, configurationService) {
		'ngInject';
		this.$log = $log;
		this.$sce = $sce;
		this.appConfig = appConfig;
		this.appSession = appSession;
		this.configurationService = configurationService;
	}

	getIClinicaUrl() {
		if (!this.appSession) {
			this.$log.error('Session not set');
			return '';
		}

		if (!this.appSession.isValid()) {
			this.$log.error('Session unavailable');
			return '';
		}

		const url = this.appConfig.external.iClinica;
		const token = this.appSession.getSessionToken();
		const study = this.appSession.getStudy();

		if (!study) {
			this.$log.error('Study not set');
			return '';
		}
		const studyAccount = study ? study.number : 0;

		return url.supplant({ token, studyAccount });
	}

	$onInit() {
		this.loadSiteMapName();
		this.$log = this.$log.getInstance('iClinicaFrameController');
		this.$log.debug('loaded');
		this.src = this.$sce.trustAsResourceUrl(this.getIClinicaUrl());
	}

	loadSiteMapName() {
		this.configurationService.getSiteMapLabel(this.appConfig.siteMapModuleIds.iClinica).then(
			(label) => {
				this.moduleLabel = label;
			}
		);
	}
}

export default FrameController;
