(function() {
	'use strict';

	angular
		.module('portalApp')
		.controller('BreadcrumbController', BreadcrumbController);

	/**
	 * @ngInject
	 */
	function BreadcrumbController($log, $stateParams, $state, appI18n, clinicalDataService, appConfig, analyticsService, configurationService, globalHeaderService, $scope) {
		$log = $log.getInstance('BreadcrumbController');
		$log.debug(' loaded');
		var vm = this;
		vm.countries = null;
		vm.sites = null;
		vm.subjects = null;
		vm.selectedCountry = undefined;
		vm.selectSite = selectSite;
		vm.selectSubject = selectSubject;
		vm.selectEvent = selectEvent;
		vm.selectVisit = selectVisit;
		vm.selectCountry = selectCountry;
		vm.clearCountry = clearCountry;
		vm.clearSite = clearSite;
		vm.clearSubject = clearSubject;
		vm.clearVisit = clearVisit;
		vm.clearEvent = clearEvent;
		vm.showCountries = false;
		vm.showSites = false;
		vm.showSubjects = false;
		vm.showMicroPortalLevel  = false;
		vm.clearLastLevel = clearLastLevel;
		vm.getBreadcrumbPathDescription = getBreadcrumbPathDescription;
		vm.showBreadcrumbs = false;
		vm.onHeaderReadySubscription = null;
		vm.tdtId = null;
		vm.microPortalLevelName = 'Viewer';

		init();

		function init() {
			vm.destroyed = false;
			vm.breadcrumbRoot = appI18n.translateImmediate('clinicaldata.breadcrumb-root');
			
			// subscribe to "header ready" and show/hide breadcrumbs
			vm.onHeaderReadySubscription = globalHeaderService.onHeaderReady$.subscribe((headerReady) => {
				// we need to wait till the header file is loaded, otherwise we will try to send the breadcrumbs
				// to the Navigator without having the header API available
				if (headerReady) {
					const globalHeader = window.ertGlobalHeader;

					// hide breadcrumbs when add breadcrumbs method exists => new header, not needed
					vm.showBreadcrumbs = !(globalHeader && globalHeader.addBreadcrumbs);
					// initialize breadcrumbs
					loadPath($state.current, $stateParams);
				}
			});

			$scope.$on('$destroy', () => {
				vm.showMicroPortalLevel = false;
				notifyBreadcrumbsChanged();
				vm.onHeaderReadySubscription && vm.onHeaderReadySubscription.dispose();
				vm.destroyed = true;
			});

		}

		function getBreadcrumbPathDescription() {
			if (vm.selectedEvent && vm.selectedSubject && vm.selectedVisit) {
				return vm.selectedSubject.subjectKey + ' > ' +
					vm.selectedVisit.visitName + ' > ' + vm.selectedEvent.eventName;
			}
			if (vm.selectedVisit && vm.selectedSubject && vm.selectedSite) {
				return vm.selectedSite.displaySiteName + ' > ' + vm.selectedSubject.subjectKey + ' > ' +
					vm.selectedVisit.visitName;
			}
			if (vm.selectedSubject && vm.selectedSite && vm.selectedCountry) {
				return vm.selectedCountry.countryName + ' > ' + vm.selectedSite.displaySiteName + ' > ' +
					vm.selectedSubject.subjectKey;
			}
			if (vm.selectedSite && vm.selectedCountry) {
				return vm.selectedCountry.countryName + ' > ' + vm.selectedSite.displaySiteName;
			}
			if (vm.selectedCountry) {
				return vm.selectedCountry.countryName;
			}
		}

		function clearLastLevel() {
			if (vm.selectedEvent) {
				vm.clearEvent();
			}
			else if (vm.selectedVisit) {
				vm.clearVisit();
			}
			else if (vm.selectedSubject) {
				vm.clearSubject();
			}
			else if (vm.selectedSite) {
				vm.clearSite();
			}
			else if (vm.selectedCountry) {
				vm.clearCountry();
			}
		}

		function clearCountry() {
			trackEvent(appConfig.trackId.clinicalDataCountry, appConfig.trackLabel.levelStudy, false);
			$state.go(appConfig.routes.clDataCountries, {country: '', site: '', eproSubject: '', subject: '', visit: '', 'event': '', tdt: '', mdt: '', showMicroPortal: false});
		}

		function clearSite() {
			trackEvent(appConfig.trackId.clinicalDataSite, appConfig.trackLabel.levelCountry, false);
			$state.go(appConfig.routes.clDataCountrySites, {site: '', eproSubject: '', subject: '', visit: '', 'event': '', tdt: '', mdt: '', showMicroPortal: false});
		}

		function clearSubject() {
			trackEvent(appConfig.trackId.clinicalDataSubject, appConfig.trackLabel.levelSite, false);
			$state.go(appConfig.routes.clDataSiteSubjects, {eproSubject: '', subject: '', visit: '', 'event': '', tdt: '', mdt: '', showMicroPortal: false});
		}

		function clearVisit() {
			trackEvent(appConfig.trackId.clinicalDataVisit, appConfig.trackLabel.levelSubject, false);
			$state.go(appConfig.routes.clDataVisits,{ visit: '', event: '', tdt: '', mdt: '', showMicroPortal: false });
		}

		function clearEvent() {
			trackEvent(appConfig.trackId.clinicalDataVisit, appConfig.trackLabel.levelVisit, false);
			hideVisits();
			hideEvents();
			breadcrumbsChanged();
			$state.go(appConfig.routes.clDataVisits, {'event': '', tdt: '', mdt: '', showMicroPortal: false}, { reload: appConfig.routes.clDataVisits });
		}

		function loadPath(toState, toParams, fromState, fromParams) {
			(async () => await loadPathAsync(toState, toParams, fromState, fromParams))();
		}

		async function loadPathAsync(toState, toParams, fromState, fromParams) {
			vm.tdtId = +toParams.tdt;
			
			await getCountries();

			if (toParams && toParams.country) {
				if (!fromParams || toParams.country !== fromParams.country) {
					await getSites();
				}
			}
			else {
				hideSites();
			}

			if (toParams && toParams.site) {
				if (!fromParams || toParams.site !== fromParams.site) {
					await getSubjects();
				}
			}
			else {
				hideSubjects();
			}

			if (toParams && toParams.subject) {
				if (!fromParams || toParams.subject !== fromParams.subject) {
					await getVisitAndEvents();
				}
			}
			else {
				hideVisits();
				hideEvents();
			}

			if (toParams && toParams.event && toParams.event !== '0') {
				if (!fromParams || toParams.event !== fromParams.event) {
					await getVisitAndEvents();
				}
			}

			vm.showMicroPortalLevel = (toParams.showMicroPortal === 'true');
			breadcrumbsChanged();
		}

		function breadcrumbsChanged() {
			const options = [];

			options.push({
				label: vm.breadcrumbRoot,
				handler: () => vm.clearCountry()
			});

			if (vm.showCountries) {
				options.push({
					label: vm.selectedCountry.countryName,
					handler: () => vm.clearSite()
				});
			}


			if (vm.showSites) {
				options.push({
					label: vm.selectedSite.displaySiteName,
					handler: () => vm.clearSubject()
				});
			}

			if (vm.showSubjects) {
				options.push({
					label: vm.selectedSubject.subjectKey,
					handler: () => vm.clearVisit()
				});
			}

			if (vm.showVisits) {
				options.push({
					label: vm.selectedVisit.visitName,
					handler: () => vm.clearEvent()
				});
			}

			if (vm.showEvents) {
				options.push({
					label: vm.selectedEvent.eventName,
					handler: () => {}
				});
			}

			notifyBreadcrumbsChanged(options);
		}

		function getMicroPortalName() {
			return new Promise((resolve, reject) => {
				configurationService.getUserStudySettings().then(response => {
					if (response.data) {
						let studyConfig = response.data.studyConfig;
						let breadcrumbLabelText = null;
						if(studyConfig && studyConfig.hasArrhythmia && studyConfig.hasHomeBP && studyConfig.hasCough) {
							breadcrumbLabelText = 'clinicaldata.breadcrumb-patch-homeBP-cough';
						} else if(studyConfig && studyConfig.hasArrhythmia && studyConfig.hasHomeBP) {
							breadcrumbLabelText = 'clinicaldata.breadcrumb-patch-homeBP';
						} else if(studyConfig && studyConfig.hasArrhythmia && studyConfig.hasCough) {
							breadcrumbLabelText = 'clinicaldata.breadcrumb-patch-cough';
						} else if (studyConfig && studyConfig.hasHomeBP && studyConfig.hasCough) {
							breadcrumbLabelText = 'clinicaldata.breadcrumb-homebpAndCough';
						} else if (studyConfig && studyConfig.hasHomeBP) {
							breadcrumbLabelText = 'clinicaldata.breadcrumb-homebp';
						} else if (studyConfig && studyConfig.hasCough) {
							breadcrumbLabelText = 'clinicaldata.breadcrumb-cough';
						} else if(studyConfig && studyConfig.hasArrhythmia) {
							breadcrumbLabelText = 'clinicaldata.breadcrumb-patch';
						}
						resolve(breadcrumbLabelText ? appI18n.translateImmediate(breadcrumbLabelText) : "Viewer");
					}
				}, () => {
					reject();
				});
			});
		}

		function notifyBreadcrumbsChanged(options) {
			if (vm.destroyed) { return; }
			options = options || [];
			const globalHeader = window.ertGlobalHeader;

			if (globalHeader && globalHeader.addBreadcrumbs) {
				/*Adding the breadcrumb if microportal level needs to be shown
				then, push microportal name into existing breadcrumb*/
				if(vm.showMicroPortalLevel) {
					getMicroPortalName().then(res => {
						options.push({
							label: res,
							handler: () => { }
						});
						globalHeader.addBreadcrumbs(options);
					});
				} else {
					/*if this function call is not coming form microportal*/
					globalHeader.addBreadcrumbs(options);	
				}
			}
		}

		function hideCountries() {
			vm.showCountries = false;
		}

		function hideSubjects() {
			vm.showSubjects = false;
		}

		function hideSites() {
			vm.showSites = false;
		}

		function hideVisits() {
			vm.showVisits = false;
		}

		function hideEvents() {
			vm.showEvents = false;
		}

		function selectEvent(eventId, tdtId, mdtId) {

			tdtId = +tdtId;
			if (tdtId === appConfig.testDataType.slowSpirometry ||
				tdtId === appConfig.testDataType.forcedSpirometry ||
				 tdtId === appConfig.testDataType.homeForcedSpirometry) {
				trackEvent(appConfig.trackId.clinicalDataSpiroViewer, appConfig.trackLabel.levelEvent);
				$state.go(appConfig.routes.spirometry, {
					country: $stateParams.country, site: $stateParams.site,
					subject: $stateParams.subject, visit: $stateParams.visit,
					event: eventId, mdt: mdtId
				}, { reload: true });
			} else if (tdtId === appConfig.testDataType.ecg || tdtId === appConfig.testDataType.extractionEcg) {
				trackEvent(appConfig.trackId.clinicalDataECGViewer, appConfig.trackLabel.levelEvent);
				$state.go(appConfig.routes.ecg, {
					visit: $stateParams.visit,
					event: eventId,
					tdt: tdtId,
					mdt: mdtId,
					orderBy: ''
				});
			} else if (tdtId === appConfig.testDataType.coaPhone || tdtId === appConfig.testDataType.coaAm ||
				tdtId === appConfig.testDataType.coaDiary || tdtId === appConfig.testDataType.coaSite) {
				trackEvent(appConfig.trackId.clinicalDataEcoaViewer, appConfig.trackLabel.levelEvent);
				$state.go(appConfig.routes.ecoa, {
					country: $stateParams.country, site: $stateParams.site,
					subject: $stateParams.subject, visit: $stateParams.visit,
					event: eventId || "0", mdt: mdtId, tdt: tdtId
				});
			}
		}

		function selectVisit(visitName) {
			trackEvent(appConfig.trackId.clinicalDataVisit, appConfig.trackLabel.levelVisit);
			$state.go(appConfig.routes.clDataVisits, {
				country: $stateParams.country, site: $stateParams.site, subject: $stateParams.subject,
				eproSubject: $stateParams.eproSubject, visit: visitName,
				tdt: '', mdt: '', event: ''
			}, { reload: appConfig.routes.clDataVisits });
		}

		function selectSubject(subjectId, eproSubjectId) {
			trackEvent(appConfig.trackId.clinicalDataVisit, appConfig.trackLabel.levelSubject);
			$state.go(appConfig.routes.clDataVisits, {
				country: $stateParams.country, site: $stateParams.site, subject: subjectId, eproSubject: eproSubjectId,
				visit: '', event: '', tdt: '', mdt: ''
			}, { reload: appConfig.routes.clData });
		}

		function selectSite(siteId) {
			trackEvent(appConfig.trackId.clinicalDataSubject, appConfig.trackLabel.levelSite);
			$state.go(appConfig.routes.clDataSiteSubjects, {
				country: $stateParams.country, site: siteId,
				subject: '', eproSubject: '', visit: '', event: '', tdt: '', mdt: ''
			}, { reload: appConfig.routes.clData });
		}

		function selectCountry(countryId) {
			trackEvent(appConfig.trackId.clinicalDataSite, appConfig.trackLabel.levelCountry);
			$state.go(appConfig.routes.clDataCountrySites, {
				country: countryId,
				site: '', subject: '', eproSubjectId: '', visit: '', 'event': '', tdt: '', mdt: ''
			}, { reload: appConfig.routes.clData });
		}

		function setSelectedEvent() {
			if ($stateParams.event && vm.events) {
				var selectedEvent = null;
				for (var i = 0; i < vm.events.length; ++i) {
					var event = vm.events[i];
					if (event.eventId === $stateParams.event) {
						selectedEvent = event;
						break;
					}
				}
				vm.showEvents = !!selectedEvent;
				vm.selectedEvent = selectedEvent;
			}
		}

		function setSelectedVisit() {
			if ($stateParams.visit && vm.visits) {
				var selectedVisit = null;
				for (var i = 0; i < vm.visits.length; ++i) {
					var visit = vm.visits[i];
					if (visit.visitName === $stateParams.visit) {
						selectedVisit = visit;
						break;
					}
				}
				vm.showVisits = !!selectedVisit;
				vm.selectedVisit = selectedVisit;
			}
		}

		function setSelectedCountry() {
			if ($stateParams.country && vm.countries) {
				var selectedCountry = null;
				for (var i = 0; i < vm.countries.length; ++i) {
					var country = vm.countries[i];
					if (country.countryId === Number($stateParams.country)) {
						selectedCountry = country;
						break;
					}
				}
				vm.showCountries = !!selectedCountry;
				vm.selectedCountry = selectedCountry;
			}
		}

		function setSelectedSite() {
			if ($stateParams.site && vm.sites) {
				var selectedSite = null;
				for (var i = 0; i < vm.sites.length; ++i) {
					var site = vm.sites[i];
					if (Number(site.siteId) === Number($stateParams.site)) {
						selectedSite = site;
						break;
					}
				}
				vm.showSites = !!selectedSite;
				vm.selectedSite = selectedSite;
			}
		}

		function setSelectedSubject() {
			if ($stateParams.subject && vm.subjects) {
				var selectedSubject = null;
				for (var i = 0; i < vm.subjects.length; ++i) {
					var subject = vm.subjects[i];
					if (Number(subject.subjectId) === Number($stateParams.subject)) {
						selectedSubject = subject;
						break;
					}
				}
				vm.showSubjects = !!selectedSubject;
				vm.selectedSubject = selectedSubject;
			}
		}

		async function getCountries() {
			if (vm.countries) {
				setSelectedCountry();
			} else {
				const response = await clinicalDataService.getCountriesMinimal();

				if (response) {
					vm.countries = response.data;
					setSelectedCountry();
				}
			}
		}

		async function getSites() {
			if ($stateParams.country && $stateParams.country !== '0') {
				const response = await clinicalDataService.getSitesMinimal(null, $stateParams.country);

				if (response) {
					vm.sites = response.data;
					setSelectedSite();
				}
			} else {
				vm.showSites = false;
			}
		}

		async function getSubjects() {
			if (($stateParams.country && $stateParams.country !== '0')
				&& $stateParams.site) {
				const response = await clinicalDataService.getSubjectsMinimal($stateParams.country, $stateParams.site);

				if (response) {
					vm.subjects = response.data;
					setSelectedSubject();
				}
			}
			else {
				vm.showSubjects = false;
			}
		}

		async function getVisitAndEvents() {
			const response = await clinicalDataService.getEventsMinimal($stateParams.site, $stateParams.subject);
			if (response) {
				vm.eventsForSubject = response.data;
				vm.events = [];
				vm.visits = [];
				vm.visitNames = [];
				if (vm.eventsForSubject.length > 0) {
					vm.eventsForSubject.forEach(function (el) {
						if (vm.visitNames.indexOf(el.visitName) < 0) {
							vm.visitNames.push(el.visitName);
							vm.visits.push({visitName: el.visitName});
						}
						if (el.visitName === $stateParams.visit && el.isViewerEvent) {
							vm.events.push(el);
						}
					});
					setSelectedVisit();
					setSelectedEvent();
				}
			}
		}

		function trackEvent(name, label, isDropdownClick = true) {
			const category = `${appConfig.trackCategory.navigateTo}::${name}`;
			let action = appConfig.trackAction.breadcrumb;


			if (isDropdownClick) {
				action =appConfig.trackAction.breadcrumbDropdown;
			}

			analyticsService.trackEventWithTitle(category, action, label);
		}
	}
})();
