/*
 * deleteUserPopupModule
 * */

import deleteUserPopupComponent from './deleteUserPopup.component';

const deleteUserPopupModule = angular.module('app.components.studyPermissions.deleteUserPopup', [])
	.component('deleteUserPopup', deleteUserPopupComponent)
	.name;

export default deleteUserPopupModule;
