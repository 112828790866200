(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('ShipmentTrackingCtrl', ShipmentTrackingController);

	/**
	 * @ngInject
	 */
	function ShipmentTrackingController($scope, logisticsService, $log, $filter, appI18n, $window, loadingIndicatorService) {

		$log = $log.getInstance("ShipmentTrackingCtrl", "color:blue");
		$log.debug("loaded");

		var vm = this;

		vm.shipmentTrackingOptions = {
			rowDefs: {
			},
			gridRowActions: [],

			sortOrders: [{
				fieldName: 'shipmentDate',
				reverseOrder: true
			}],

			getData: getShipmentTrackingList,

			defaultRowAction: function(row) {
				var found = $filter('filter')(vm.shipmentTrackingOptions.rowData, {selected:true}, false);
				angular.forEach(found, function(value) {
					value.selected = false;
				});
				row.selected = true;
				vm.selectedShipping = {};
				vm.loadSelectedShipping(row);
			}
		};

		function getShipmentTrackingList(options) {
				return logisticsService.getShipmentTrackingList(options);
		}

		vm.loadSelectedShipping = function(row) {
			vm.isLoading = true;
			loadingIndicatorService.show();
			logisticsService.getShipmentTrackingDetails(row.siteId, row.esmsOrderId)
				.then(function(response) {
					$log.debug(" order detail data loaded.");
					vm.selectedShipping = response.data;
					vm.showShippingDetails = true;
					vm.isLoading = false;
				}, function(response){
					$log.debug("error in loading order detail data: " +  response.data);
					vm.isLoading = false;
				})
				.finally(() => {
					loadingIndicatorService.hide();
				});
		};

		function closeShippingDetails(){
			vm.showShippingDetails = false;
		}

		function printDetails() {
            if (vm.isLoading) {
                return;
            }
            $window.print();

		}

		function activate() {
			appI18n.translate([
				"logistics.shipment-tracking.title",
				"logistics.shipment-tracking.shipmentdate",
				"logistics.shipment-tracking.country",
				"logistics.shipment-tracking.investigator",
				"logistics.shipment-tracking.site#",
				"logistics.shipment-tracking.courier",
				"logistics.shipment-tracking.tracking#",
				"logistics.shipment-tracking.order#",
				"logistics.shipment-tracking.ordered-from",
				"logistics.shipment-details.close",
				"logistics.shipment-details.title"

			])
			.then(function (translations) {
            	logisticsService.setTitle(translations["logistics.shipment-tracking.title"]);
            	vm.shipmentTitle = translations["logistics.shipment-tracking.shipmentdate"];
            	vm.countryTitle = translations["logistics.shipment-tracking.country"];
            	vm.investigatorTitle = translations["logistics.shipment-tracking.investigator"];
            	vm.siteTitle = translations["logistics.shipment-tracking.site#"];
            	vm.courierTitle = translations["logistics.shipment-tracking.courier"];
            	vm.trackingTitle = translations["logistics.shipment-tracking.tracking#"];
            	vm.orderTitle = translations["logistics.shipment-tracking.order#"];
            	vm.orderedFromTitle = translations["logistics.shipment-tracking.ordered-from"];
            	vm.closeTitle = translations["logistics.shipment-details.close"];
            	vm.detailsTitle = translations["logistics.shipment-details.title"];

            	vm.iconActions = [{action: printDetails, iconName: 'print'}];

				logisticsService.getConfig().then((response) => {
					vm.config = response;
					vm.shipmentTrackingOptions.columnDefs =
						[
							{displayName: vm.orderTitle, fieldName: 'esmsOrderIdText', isSortable: true, type: 'string', isDefaultFilter: true, isPrimary: true},
							{displayName: vm.shipmentTitle, fieldName: 'shipmentDate', isSortable: true, type: 'date', formatter: 'dateTimeFormatUtc'},
							{displayName: vm.countryTitle, fieldName: 'countryName', isSortable: true, type: 'string'},
							{displayName: vm.investigatorTitle, fieldName: 'investigatorName', isSortable: true, type: 'string'},
							{displayName: vm.siteTitle, fieldName: 'sponsorSiteId', isSortable: true, type: 'string'},
							{displayName: vm.courierTitle, fieldName: 'courier', isSortable: true, type: 'string'},
							{displayName: vm.trackingTitle, fieldName: 'trackingNumber', isSortable: true, type: 'string'}
						];

					const expertStatusColumns = [
						{
							displayName: vm.orderedFromTitle, fieldName: 'orderedFrom', isSortable: true,
							showReverseSortOrder: true,
							type: 'enum',
							enumType: 'string',
							enums: [
								{ displayName: 'Expert', values: ['Expert'] },
								{ displayName: 'Clario Portal', values: ['MSP3'] }
							]
						}
					];

					const ebsStatusColumns = [
						{
							displayName: vm.orderedFromTitle, fieldName: 'orderedFrom', isSortable: true,
							showReverseSortOrder: false, type: 'enum',
							enumType: 'string',
							enums: [
								{ displayName: 'Clario', values: ['ERT'] },
								{ displayName: 'Online Portal', values: ['Online Portal'] }
							]
						}
					];

					if (vm.config.useExpert) {
						vm.shipmentTrackingOptions.columnDefs.push(...expertStatusColumns);
					} else {
						vm.shipmentTrackingOptions.columnDefs.push(...ebsStatusColumns);
					}
				});






        	});

			vm.load = true;

		}

		activate();

	}

})();
