/*
 * plannedVisitsTableModule
 * */

import plannedVisitsTableDirective from './plannedVisitsTable.directive';

const plannedVisitsTableModule = angular.module('app.components.dashboard.visitOverviewWidget.plannedVisitsTable', [])
	.directive('plannedVisitsTable', plannedVisitsTableDirective)
	.name;

export default plannedVisitsTableModule;
