/*
 * openDcrWidgetModule
 * */
import openDcrWidgetComponent from './openDcrWidget.component';
import openDcrTableModule from './openDcrTable/index';

const openDcrWidgetModule = angular.module('app.components.dashboard.openDcrWidget', [openDcrTableModule])
	.component('openDcrWidget', openDcrWidgetComponent)
	.name;

export default openDcrWidgetModule;
