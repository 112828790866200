import templateUrl from './dataStatusForm.html';
import controller from './dataStatusForm.controller';

const dataStatusForm = {
	restrict: 'E',
	templateUrl,
	controller
};

export default dataStatusForm;
