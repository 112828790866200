(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('AdminCtrl', AdminController);

	/**
	 * @ngInject
	 */

	function AdminController(adminService, $log, $state, appI18n, configurationService,
													 userService, $filter, $rootScope, $q, appConfig) {

		$log = $log.getInstance('AdminCtrl', 'color:purple');
		$log.debug('loaded');

		var vm = this;
		vm.title = '';
		vm.tabs = [];

		vm.getTitle = function() {
			return vm.title;
		};

		vm.setTitle = function(title) {
			vm.title = title;
		};

		function activate() {
			loadSiteMapName();
			$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
				if (toState.name === appConfig.routes.admin) {
					redirect();
				}
			});
			adminService.registerAdminHandler(vm);

			var defers = $q.all([
				userService.getUserIsInternal(),
				configurationService.getSiteMap()
			]);
			defers.then(function (resolves) {
				vm.userIsInternal = resolves[0];
				var map = resolves[1];

				var admin = $filter('filter')(map, {name: 'Admin'}, false);
				vm.isAdmin = admin && admin.length > 0;

				appI18n.translate([
					'admin.admin',
					'admin.study-tracking-title',
					'admin.module-tracking-title',
					'admin.user-tracking-title',
					'admin.user-action-tracking.open',
					'admin.user-login-tracking-title',
					'admin.study-configuration-title'
				])
					.then(function (translations) {
						vm.adminTitle = translations['admin.admin'];
						vm.studyTrackingTab = translations['admin.study-tracking-title'];
						vm.moduleTrackingTab = translations['admin.module-tracking-title'];
						vm.userTrackingTab = translations['admin.user-tracking-title'];
						vm.userLoginTrackingTab = translations['admin.user-login-tracking-title'];
						vm.studyConfigurationTab = translations['admin.study-configuration-title'];

						vm.moduleLabel = vm.isAdmin ? admin[0].label : vm.adminTitle;
						vm.openUserActionTrackingLabel = translations['admin.user-action-tracking.open'];

						if (vm.isAdmin) {
							vm.tabs = [
								{
									displayName: vm.studyTrackingTab,
									trackId: appConfig.trackId.adminStudyTracking,
									ref: appConfig.routes.studyTracking
								}, {
									displayName: vm.moduleTrackingTab,
									trackId: appConfig.trackId.adminModuleTracking,
									ref: appConfig.routes.moduleTracking
								}, {
									displayName: vm.userTrackingTab,
									trackId: appConfig.trackId.adminUserTracking,
									ref: appConfig.routes.userTracking
								}, {
									displayName: vm.userLoginTrackingTab,
									trackId: appConfig.trackId.adminUserLoginTracking,
									ref: appConfig.routes.userLoginTracking
								}
							];
						}
						if (vm.userIsInternal) {
							vm.tabs.push({
								displayName: vm.studyConfigurationTab,
								trackId: appConfig.trackId.adminStudyConfiguration,
								ref: appConfig.routes.studyConfiguration
							});
						}

						vm.actions = [];
						if (vm.userIsInternal) {
						vm.actions.push({
							displayName: vm.openUserActionTrackingLabel,
							iconClass: 'es-icon es-icon-reports',
							cssClass: '-es-primary',
							action: createUserActionTrackingReport,
							id: 'create-new-entry'
						});
						}
						redirect();
					});

			});


		}

		function redirect() {
			if (!vm.isAdmin && vm.userIsInternal) {
				$state.go(appConfig.routes.studyConfiguration);
			}
		}

		activate();
		function loadSiteMapName() {
			configurationService.getSiteMapLabel(appConfig.siteMapModuleIds.admin).then(
				function (label) {
					vm.moduleLabel = label;
				}
			);
		}
		function createUserActionTrackingReport() {
			adminService.showUserActionTrackingReport();
		}
	}
})();
