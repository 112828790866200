/* eslint-disable no-underscore-dangle */
class ScheduleISpiroEventPopupController {
	constructor($log, $q, iSpiroService, loadingIndicatorService, appI18n,
				appConfig, ertBasePopupService, notificationService) {
		'ngInject';
		this.$log = $log;
		this.$q = $q;
		this.iSpiroService = iSpiroService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.ertBasePopupService = ertBasePopupService;
		this.notificationService = notificationService;
		this.appI18n = appI18n;
		this.appConfig = appConfig;
		this.onlineHelpId = 'MSP3.ClinicalData.ISpiro.ScheduleEvent';

		this.isLoading = false;
		this.$API = null;
		this.scheduleEventForm = null;
		this.state = null;

		this.errors = {
			genericError: false
		};

	}

	$onInit() {
		this.$log = this.$log.getInstance('ScheduleISpiroEventPopupController');
		this.$log.debug('loaded');

		this.$API = this.setApi();
		this.ertBasePopupService.register('iSpiroScheduleEventPopup', this.$API);
		this.componentReady && this.componentReady({ $API: this.$API });
	}

	makeFormDirty() {
		angular.forEach(this.scheduleEventForm.$error.required, (field) => {
			field.$setDirty();
			field.$touched = true;
		});
		this.scheduleEventForm.showValidation = true;
	}

	initFormControls(siteId, technicalSubjectId, isReschedule) {
		this.popupHeading = isReschedule
			? 'clinicaldata.subject.i-spiro.scheduleEvent.rescheduleTitle'
			: 'clinicaldata.subject.i-spiro.scheduleEvent.title';

		this.sites = null;
		this.selectedSite = null;
		this.sitesOptions = {
			label: 'app.common.siteId',
			id: 'sitesDropdown',
			disabled: isReschedule || !!siteId, // disabled if site context is available
			required: !isReschedule,
			placeholder: 'clinicaldata.subject.create.selectSite',
			uiValue: 'displaySiteName'
		};

		this.subjects = [];
		this.selectedSubject = null;
		this.subjectsOptions = {
			label: 'app.common.subjectKey',
			id: 'subjectsDropdown',
			disabled: isReschedule || !!technicalSubjectId, // disabled if subject context is available
			required: !isReschedule,
			placeholder: 'clinicaldata.subject.i-spiro.close-dispense.selectSubject',
			uiValue: 'subjectKey'
		};

		this.visits = [];
		this.selectedVisit = null;
		this.visitsOptions = {
			label: 'app.common.visit',
			id: 'visitsDropdown',
			disabled: isReschedule,
			required: !isReschedule,
			placeholder: 'clinicaldata.subject.i-spiro.scheduleEvent.selectVisit',
			uiValue: 'visitName'
		};

		this.visitTasks = [];
		this.selectedVisitTask = null;
		this.visitTasksOptions = {
			label: 'app.common.event',
			id: 'visitTasksDropdown',
			disabled: isReschedule,
			required: !isReschedule,
			placeholder: 'clinicaldata.subject.i-spiro.scheduleEvent.selectEvent',
			uiValue: 'visitScheduleTaskName'
		};

		this.eventDateOptions = {
			label: 'app.common.date',
			id: 'eventDate',
			required: true,
			placeholder: 'DD-MMM-YYYY',
			datePattern:
			'^((((0[1-9]|[12][0-9]|3[01])[-]([Jj][Aa][Nn]|[Mm][Aa][Rr]|[Mm][Aa]' +
				'[Yy]|[Jj][Uu][Ll]|[Aa][Uu][Gg]|[Oo][Cc][Tt]|[Dd][Ee][Cc]))|((0[1-9]|[12][0-9]|30)[-]' +
				'([Aa][Pp][Rr]|[Jj][Uu][Nn]|[Ss][Ee][Pp]|[Nn][Oo][Vv]))|((0[1-9]|[12][0-9])[-]([Ff][Ee][Bb]' +
				')))[-]((19|20)\\d\\d))?$',
			validators: {
				inputFormat: {
					fn: (inputValue) => {
						const rexp = new RegExp(this.eventDateOptions.datePattern);
						const valid = !inputValue || rexp.test(inputValue);

						return valid;
					}
				}
			}
		};

		this.eventTimeOptions = {
			label: 'app.common.time',
			id: 'eventTime',
			required: true,
			placeholder: 'HH:mm',
			mask: {
				value: '99:99'
			},
			timePattern: '^([01][0-9]|2[0-3]):[0-5][0-9]',

			validators: {
				inputFormat: {
					fn: (inputValue) => {
						const rexp = new RegExp(this.eventTimeOptions.timePattern);
						const valid = !inputValue || rexp.test(inputValue);

						return valid;
					}
				}
			}
		};

		// remote video option temporary disabled
		// this.remoteVideoSelectionOptions = [
		// 	{
		// 		name: 'No', value: false
		// 	},
		// 	{
		// 		name: 'Yes', value: true
		// 	}
		// ];
		// this.selectedRemoteVideoOption = this.remoteVideoSelectionOptions[1];
		// this.remoteVideoOptions = {
		// 	label: 'clinicaldata.subject.i-spiro.scheduleEvent.remoteVideo',
		// 	id: 'remoteVideoDropdown',
		// 	required: true,
		// 	uiValue: 'name'
		// };

		// use hard coded value until the option is visible again
		this.selectedRemoteVideoOption = { value: true };

		this.isRequiredText = 'clinicaldata.subject.create.errors.required.general';

		this.submitBtn = {
			action: () => {
				this.saveScheduledEvent();
			},
			cssClass: '-es-primary',
			displayName: 'app.buttons.submit',
			isDisabled: false,
			type: 'submit'
		};

		this.cancelBtn = {
			action: () => {
				this.$API.close();
			},
			displayName: 'app.buttons.cancel',
			isDisabled: false
		};

		this.actions = [this.submitBtn, this.cancelBtn];
	}

	setApi() {
		const $API = {
			open: (countryId, siteId, displaySiteName, technicalSubjectId, subjectKey) => {
				this.errors = {};
				this.openForScheduleNew(countryId, siteId, displaySiteName, technicalSubjectId, subjectKey);

				return this.$q((res, rej) => {
					this.resolve = res;
					this.reject = rej;
				});
			},
			openForReschedule: (siteId, displaySiteName, technicalSubjectId, subjectKey, visitId,
								visitName, visitScheduleTaskId, visitScheduleTaskName,
								date, time, remoteVideoEnabled, scheduledEventId) => {
				this.errors = {};
				this.openForReschedule(siteId, displaySiteName, technicalSubjectId, subjectKey, visitId,
									   visitName, visitScheduleTaskId, visitScheduleTaskName, date, time,
									   remoteVideoEnabled, scheduledEventId);

				return this.$q((res, rej) => {
					this.resolve = res;
					this.reject = rej;
				});
			},
			close: (refresh) => {
				this.resolve(refresh);
				this.resolve = null;
				this.state = null;
				this.sites = null;
				this.preselectedSiteId = null;
				this.sitesOptions.disabled = false;
				this.subjectsOptions.disabled = false;
				this.subjects = [];
				this.selectedSite = null;
				this.selectedSubject = null;
				this.selectedVisit = null;
				this.selectedVisitTask = null;
				this.visitTasks = [];
				this.visits = [];
				this.eventDate = null;
				this.eventTime = null;
				// remote video option temporary disabled
				// this.selectedRemoteVideoOption = this.remoteVideoSelectionOptions[1];
				this.initialEventDate = null;
				this.open = false;
				this.isReschedule = false;
				this.scheduledEventId = null;
			}
		};

		return $API;
	}

	openForScheduleNew(countryId, siteId, displaySiteName, technicalSubjectId, subjectKey) {
		this.loadingIndicatorService.show();
		this.hideContentMessage = false;
		this.initFormControls(siteId, technicalSubjectId, false);

		this.loadInitialData(countryId, siteId, displaySiteName, technicalSubjectId, subjectKey).then(() => {
			this.preselectFieldsForScheduleNew(siteId, displaySiteName, technicalSubjectId, subjectKey);
			this.open = true;
		}).finally(() => {
			this.loadingIndicatorService.hide();
		});
	}

	openForReschedule(siteId, displaySiteName, technicalSubjectId, subjectKey, visitId, visitName,
					  visitScheduleTaskId, visitScheduleTaskName, date, time, remoteVideoEnabled, scheduledEventId) {
		this.isReschedule = true;
		this.scheduledEventId = scheduledEventId;

		this.initFormControls(siteId, technicalSubjectId, true);
		this.preselectFieldsForReschedule(siteId, displaySiteName, technicalSubjectId, subjectKey,
										  visitId, visitName, visitScheduleTaskId, visitScheduleTaskName,
										  date, time, remoteVideoEnabled);
		this.open = true;
	}


	loadInitialData(countryId, siteId, displaySiteName, technicalSubjectId, subjectKey) {
		const requests = [];

		if (!siteId || !displaySiteName) {
			requests.push(this.iSpiroService.getSitesMinimal(null, countryId).then((res) => {
				this.sites = res.data;
			}));
		}
		if (siteId && (!technicalSubjectId || !subjectKey)) {
			requests.push(this.getSubjects({ countryId, siteId }));
		}
		requests.push(this.iSpiroService.getVisits()
					  .then((res) => {
						  this.visits = res.data;
					  }));

		return this.$q.all(requests);
	}

	preselectSiteAndSubjectFields(siteId, displaySiteName, technicalSubjectId, subjectKey) {
		if (siteId && displaySiteName) {
			this.selectedSite = { siteId, displaySiteName };
			this.sites = [this.selectedSite];

		} else if (siteId) {
			const siteToSelect = this.sites.find((s) => {
				return s.siteId === siteId;
			});

			this.selectedSite = siteToSelect;
		}

		if (this.selectedSite && technicalSubjectId && subjectKey) {
			this.selectedSubject = { subjectKey, technicalSubjectId };
			this.subjects = [this.selectedSubject];
		} else if (this.selectedSite && technicalSubjectId) {
			const subjectToSelect = this.subjects.find((s) => {
				return s.technicalSubjectId === technicalSubjectId;
			});

			this.selectedSubject = subjectToSelect;
		} else {
			this.onSiteChange(this.selectedSite);
		}
	}

	preselectFieldsForScheduleNew(siteId, displaySiteName, technicalSubjectId, subjectKey) {
		this.preselectSiteAndSubjectFields(siteId, displaySiteName, technicalSubjectId, subjectKey);
	}

	preselectFieldsForReschedule(siteId, displaySiteName, technicalSubjectId, subjectKey, visitId,
								 visitName, visitScheduleTaskId, visitScheduleTaskName, date, time
								 /**, remoteVideoEnabled*/) {

		this.preselectSiteAndSubjectFields(siteId, displaySiteName, technicalSubjectId, subjectKey);

		this.selectedVisit = { visitName, visitId };
		this.visits = [this.selectedVisit];

		this.selectedVisitTask = { visitScheduleTaskName, visitScheduleTaskId };
		this.visitTasks = [this.selectedVisitTask];

		this.initialEventDate = date;
		this.eventTime = time;

		// remote video option temporary disabled
		// this.selectedRemoteVideoOption = this.remoteVideoSelectionOptions[remoteVideoEnabled ? 1 : 0];
	}


	cancelAction() {
		this.$API.close(false);
	}

	getSubjects(newVal) {
		return this.iSpiroService.getSubjects(
			newVal.countryId, newVal.siteId,
			this.appConfig.iSpiroActionId.scheduleEvent)
			.then((res) => {
				const subjects = res.data.subjects;

				if (subjects && subjects.length > 0) {
					this.subjectsOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.selectSubject';
				} else {
					this.subjectsOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.noSubject';
				}
				return subjects;
			});
	}

	getVisitTasks(newVal) {
		return this.iSpiroService.getVisitTasks(newVal.visitId)
			.then((res) => {
				const tasks = res.data;

				this.visitTasksOptions.placeholder = 'clinicaldata.subject.i-spiro.scheduleEvent.selectEvent';
				this.visitTasks = tasks;
				return tasks;
			});
	}

	saveScheduledEvent() {
		if (this.errors.genericError) {
			return;
		}
		if (this.scheduleEventForm.$invalid) {
			this.makeFormDirty();
			return;
		}

		// clean up backend validation errors
		this.errors.customError = false;
		this.customErrorMsg = null;

		const payload = this.isReschedule
			  ? this.generateRescheduleRequestPayload()
			  : this.generateCreateRequestPayload();

		const apiAction = this.isReschedule
			  ? this.iSpiroService.rescheduleEvent
			  : this.iSpiroService.scheduleEvent;

		const actionId = this.isReschedule
			  ? this.appConfig.iSpiroActionId.rescheduleEvent
			  : this.appConfig.iSpiroActionId.scheduleEvent;

		this.isLoading = true;
		apiAction(payload).then((resp) => {
			if (resp.data.isSuccessful) {
				const qrCode = resp.data.responseMessage;

				if (qrCode) {
					this.ertBasePopupService.popup('iSpiroPopup')
						.open(this.selectedSite.countryId, this.selectedSite.siteId,
							  this.selectedSubject.technicalSubjectId,
							  actionId,
							  this.selectedSite.displaySiteName,
							  this.selectedSubject.subjectKey,
							  qrCode);
				}
				this.$API.close(true);

			} else if (resp.data.isValidationError) {
				this.errors.customError = true;
				this.customErrorMsg = this.appI18n.translateImmediate(resp.data.responseMessage);
			} else {
				const msg = (resp.data && resp.data.responseMessageTranslationKey)
					  ? resp.data.responseMessageTranslationKey
					  : 'clinicaldata.i-spiro.scheduleEvent.errors.genericErrorSaveScheduledEvent';

				this.notificationService.showError(this.appI18n.translateImmediate(msg));
			}
		}).finally(() => {
			this.isLoading = false;
		});
	}

	generateRescheduleRequestPayload() {
		const payload = {
			technicalSubjectId: this.selectedSubject.technicalSubjectId,
			subjectKey: this.selectedSubject.subjectKey,
			siteId: this.selectedSite.siteId,
			visitId: this.selectedVisit.visitId,
			visitScheduleTaskId: this.selectedVisitTask.visitScheduleTaskId,
			date: this.eventDate,
			time: this.eventTime,
			enableRemoteVideo: this.selectedRemoteVideoOption.value,
			scheduledEventId: this.scheduledEventId
		};

		return payload;
	}

	generateCreateRequestPayload() {
		const payload = {
			siteId: this.selectedSite.siteId,
			subjectKey: this.selectedSubject.subjectKey,
			technicalSubjectId: this.selectedSubject.technicalSubjectId,
			visitId: this.selectedVisit.visitId,
			visitScheduleTaskId: this.selectedVisitTask.visitScheduleTaskId,
			date: this.eventDate,
			time: this.eventTime,
			enableRemoteVideo: this.selectedRemoteVideoOption.value
		};

		return payload;
	}

	preselectSite(siteId, disableChangingSelection) {
		this.preselectedSiteId = siteId;
		const siteToSelect = this.sites.find((s) => {
			return s.siteId === siteId;
		});

		this.selectedSite = siteToSelect || null;
		if (this.selectedSite) {
			this.sitesOptions.disabled = disableChangingSelection;
			this.onSiteChange(this.selectedSite);
		}
	}

	onSiteChange(newVal) {
		this.selectedSite = newVal;

		if (!newVal) {
			this.subjects = [];
			return;
		}

		this.isLoading = true;
		this.getSubjects(newVal).then((res) => {
			this.subjects = res;
		}).finally(() => {
			this.isLoading = false;
		});
	}

	onSubjectChange(newVal) {
		this.selectedSubject = newVal;
	}

	onVisitChange(newVal) {
		this.selectedVisit = newVal;
		if (!newVal) {
			return;
		}

		this.isLoading = true;
		this.getVisitTasks(newVal).then((res) => {
			this.vistTasks = res;
		}).finally(() => {
			this.isLoading = false;
		});
	}

	onVisitTaskChange(newVal) {
		this.selectedVisitTask = newVal;
	}

	onEventDateChange(newValue) {
		if (!newValue) {
			return;
		}
		this.eventDate = newValue;
		const eventTimeFormField = this.scheduleEventForm['\'eventTime\''];

		eventTimeFormField.$validate();
	}

	onRemoteVideoChange(newVal) {
		this.selectedRemoteVideoOption = newVal;
	}

}
/* eslint-enable no-underscore-dangle */
export default ScheduleISpiroEventPopupController;
