//(function () {
//  'use strict';
//
//  angular
//    .module("portalApp")
//    .service("appI18n", AppI18n);

  /**
   * @ngInject
   */
  module.exports = function($q, $log, $translate, appConfig) {

    $log = $log.getInstance("AppSession");
    $log.debug("loaded");

    function translate(key) {
      //it returns promise, in future if we need to modify result, wrap it in promise
      var translatePromise = $translate(key, {Site: 'VocabSite'});
			var vocabularyPromise = $q.defer();
			translatePromise.then(
				function (result) {
					vocabularyPromise.resolve(result);
				});
			return vocabularyPromise.promise;
    }

    function translateImmediate(key) {
      return $translate.instant(key, {instant: true});
    }

    function changeLanguage(key) {

      localStorage.setItem(appConfig.sessionKeys.lang, key);
      $translate.use(key);
    }

    function getLanguage() {
      return localStorage.getItem(appConfig.sessionKeys.lang);
    }

    return {
      translate: translate,
      translateImmediate: translateImmediate,
      changeLanguage: changeLanguage,
      getLanguage: getLanguage
    };
  };

//})();
