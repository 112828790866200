import templateUrl from './textArea.html';
import controller from './textArea.controller';
import './textArea.scss';

const textAreaComponent = {
	bindings: {
		name: '@',
		options: '<',
		onChange: '&?',
		componentReady: '&?',
		cssClass: '@?',
		notifySetValue: '<?'
	},
	transclude: true,
	require: {
		fg: '^form'
	},
	templateUrl,
	controller
};

export default textAreaComponent;
