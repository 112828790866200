(function () {
	'use strict';

	angular.module('portalApp').controller('pftVisitEventCtrl', PftVisitEventCtrl);

	/* @ngInject */
	function PftVisitEventCtrl($log, clinicalDataEntryService, clinicalDataEntryApiService,
							   appConfig, clinicalDataService, configurationService,
							   appI18n, $scope, $q) {

		var vm = this;
		$log = $log.getInstance('PftVisitEvent', 'color:blue');
		$log.debug(' loaded');
		vm.noValueLabel = appI18n.translateImmediate('cde-pft.titles.noValue');
		vm.createTechnicianLabel = appI18n.translateImmediate('cde-pft.titles.createTechnician');
		vm.selectTechnicianLabel = appI18n.translateImmediate('cde-pft.titles.selectTechnician');

		vm.data = {};
		vm.data.visitEventDetails = {};
		vm.savedStudyId = null;
		vm.savedIsProficiency = false;
		vm.timezone = null;

		vm.isReadOnlyMode = isReadOnlyMode;
		vm.isAddMode = isAddMode;
		vm.cleanVisitList = cleanVisitList;
		vm.cleanEventList = cleanEventList;
		vm.cleanFirstEventDate = cleanFirstEventDate;
		vm.resetEventDetails = resetEventDetails;
		vm.isValid = isValid;
		vm.show = show;
		vm.onStudyChange = onStudyChange;
		vm.getData = getData;
		vm.setData = setData;
		vm.getAvailableVisitEventOptions = getAvailableVisitEventOptions;
		vm.getTechniciansList = getTechniciansList;
		vm.addAndSetNewTechnician = addAndSetNewTechnician;
		vm.compareData = compareData;
		vm.isValidDateRange = isValidDateRange;
		vm.setAgeDemographic = setAgeDemographic;
		vm.globalErrors = {
			ageOutOfRange: false,
			ageOutOfRangeMessage: ""
		};
		vm.showTechnicianControls = false;

		vm.cleanOutOfRangeError = function () {
			vm.globalErrors.ageOutOfRange = false;
			vm.globalErrors.ageOutOfRangeMessage = '';
		};

		vm.tooltipButtonsForPeriod = [
			{
				action: function(formDataModel, formValueGetterSetter, selectOptions) {
					if (vm.savedForComparison.visitEventDetails.selectedPeriod) {
						var optionId = vm.savedForComparison.visitEventDetails.selectedPeriod.periodId;
						var selectValue = _.find(selectOptions, ['periodId', optionId]);
						formValueGetterSetter(selectValue || null);
						periodDetailsChanged();
					}

				},
				getBtnLabel: function (formDataModel) {
					if (vm.savedForComparison.visitEventDetails.selectedPeriod) {
						return createBtnLabel(1, vm.savedForComparison.visitEventDetails.selectedPeriod.periodName);
					} else {
						return '';
					}
				}
			},
			{
				action: function (formDataModel, formValueGetterSetter) {
					formValueGetterSetter(formDataModel.secondSelectedPeriod || null);
					periodDetailsChanged();
				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(2, formDataModel.secondSelectedPeriod.selectedValue);
				}
			}
		];
		vm.tooltipButtonsForVisit = [
			{
				action: function(formDataModel, formValueGetterSetter, selectOptions) {
					var optionId =vm.savedForComparison.visitEventDetails.selectedVisit.visitId;
					var selectValue = _.find(selectOptions, ['visitId', optionId]);
					formValueGetterSetter(selectValue || null);
					visitEventDetailsChanged();

				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(1, vm.savedForComparison.visitEventDetails.selectedVisit.visitName);
				}
			},
			{
				action: function (formDataModel, formValueGetterSetter) {
					formValueGetterSetter(formDataModel.secondSelectedVisit || null);
					visitEventDetailsChanged();
				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(2, formDataModel.secondSelectedVisit.selectedValue);
				}
			}
		];

		vm.tooltipButtonsForVisitEvent = [
			{
				action: function(formDataModel, formValueGetterSetter, selectOptions) {
					var optionId =vm.savedForComparison.visitEventDetails.selectedEvent.visitTaskId;
					var selectValue = _.find(selectOptions, ['visitTaskId', optionId]);
					formValueGetterSetter(selectValue || null);

				},
				getBtnLabel: function (formDataModel) {
					var event = vm.savedForComparison.visitEventDetails.selectedEvent;
					var value ='';
					if (event && event.visitScheduleTaskName) {
						value = getEventDisplayName(event);
					}
					return createBtnLabel(1, value);
				}
			},
			{
				action: function (formDataModel, formValueGetterSetter) {
					formValueGetterSetter(formDataModel.secondSelectedEvent || null);
				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(2, formDataModel.secondSelectedEvent.selectedValue);
				}
			}
		];

		vm.tooltipButtonsForEventDate = [
			{
				action: function(formDataModel, formValueGetterSetter) {
					formValueGetterSetter(vm.savedForComparison.visitEventDetails.eventDate || null);

				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(1, vm.savedForComparison.visitEventDetails.eventDate);
				}
			},
			{
				action: function (formDataModel, formValueGetterSetter) {
					formValueGetterSetter(formDataModel.secondEventDate || null);
				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(2, formDataModel.secondEventDate);
				}
			}
		];

		vm.tooltipButtonsForEventTime = [
			{
				action: function(formDataModel, formValueGetterSetter) {
					formValueGetterSetter(vm.savedForComparison.visitEventDetails.eventTime || null);
				},
				getBtnLabel: function (formDataModel) {
					var label = createBtnLabel(1, vm.savedForComparison.visitEventDetails.eventTime);
					return formatTimeString(label);
				}
			},
			{
				action: function (formDataModel, formValueGetterSetter) {
					formValueGetterSetter(formDataModel.secondEventTime || null);
				},
				getBtnLabel: function (formDataModel) {
					var label = createBtnLabel(2, formDataModel.secondEventTime);
					return formatTimeString(label);
				}
			}
		];

		vm.tooltipButtonsForTechnicianSelect = [
			{
				action: function(formDataModel, formValueGetterSetter, selectOptions) {
					vm.data.technicianEmail = vm.savedForComparison.technicianEmail;
					vm.data.technicianFirstName = vm.savedForComparison.technicianFirstName;
					vm.data.technicianLastName = vm.savedForComparison.technicianLastName;
					formValueGetterSetter(vm.savedForComparison.selectedTechnician);
				},
				getBtnLabel: function (formDataModel) {
					var value = getTechnicianDisplayText(vm.savedForComparison.selectedTechnician);

					return createBtnLabel(1, value);
				}
			},
			{
				action: function (formDataModel, formValueGetterSetter) {
					if (formDataModel.secondTechnicianSelect) {
						vm.technicianFields[1].formControl.$touched = true;
						vm.technicianFields[2].formControl.$touched = true;
						vm.technicianFields[3].formControl.$touched = true;
					}
					vm.data.technicianEmail = formDataModel.secondTechnicianEmail || '';
					vm.data.technicianFirstName = formDataModel.secondTechnicianFirstName;
					vm.data.technicianLastName = formDataModel.secondTechnicianLastName;
					formValueGetterSetter(formDataModel.secondTechnicianSelect || null);
				},
				getBtnLabel: function (formDataModel) {
					return createBtnLabel(2, formDataModel.secondTechnicianSelect.selectedValue);
				}
			}
		];

		// formats '1214' to '12:14' and '151633' to '15:16:33'
		function formatTimeString(timeString) {
			if (timeString && +timeString > 0) {
				if (timeString.length === 4) {
					timeString = timeString.slice(0, 2) + ':' + timeString.slice(2);
				} else if (timeString.length === 6) {
					timeString = timeString.slice(0, 2) + ':' + timeString.slice(2, 4) + ':' + timeString.slice(4);
				}
			}
			return timeString;
		}

		function getTechnicianDisplayText(model) {
			if (model && model.lastName && model.firstName) {
				return `${model.lastName}, ${model.firstName} ${model.email ? '('+model.email+')' : ''}`;
			}
			return '';
		}

		function createBtnLabel(entryNum, value) {
			return (value || vm.noValueLabel);
		}

		active();

		function active() {
			clinicalDataEntryService.registerVisitEventHandler(vm);

			$scope.$on('$destroy', () => {
				clinicalDataEntryService.registerVisitEventHandler();
			});

			vm.errorMessageRequired = appI18n.translateImmediate('cde-pft.errors.required');
			vm.errorMessageHasDiscrepancies = appI18n.translateImmediate('cde-pft.errors.hasDiscrepancies');
			vm.errorMessageHasDiscrepanciesVisit = appI18n.translateImmediate('cde-pft.errors.hasDiscrepanciesVisit');
			vm.errorMessageHasDiscrepanciesPeriod = appI18n.translateImmediate('cde-pft.errors.hasDiscrepanciesPeriod');
		}

		function getTechnicianControlsVisibility(mode, cdeMode, saved) {
			// show technician controls for non Paper ECG entries or for entries in view mode having technician info.
			return vm.cdeMode != appConfig.cdeMode.Ecg
				|| (mode === appConfig.dataEntryDisplayModes.viewOnly
					&& saved.visitEvent && saved.visitEvent.technician
					&& saved.visitEvent.technician.firstName
					&& saved.visitEvent.technician.lastName);
		}

		function show(studyId, studyModeId, siteId, isProficiency, mode, saved, cdeMode) {
			vm.data.selectedStudyId = studyId;
			vm.data.selectedStudyModeId = studyModeId;
			vm.mode = mode;
			vm.cdeMode = cdeMode;
			vm.showTechnicianControls = getTechnicianControlsVisibility(mode, cdeMode, saved);
			vm.saved = saved || {};

			var refreshStudy = studyChanged(studyId, isProficiency);
			var refreshSite = siteChanged(siteId);
			var refreshSubject = false;

			if (vm.saved.subject) {
				refreshSubject = subjectChanged(vm.saved.subject.id);
			}

			if (!vm.isReadOnlyMode() && (refreshStudy || refreshSite)) {
				clinicalDataService.getSiteTimezoneInStudy(studyId, studyModeId, siteId).then(
					function (response) {
						vm.timezone = response.data;

					}, function onError(errorData, status) {
						logError(errorData, status);
					});
			}

			if (!vm.isAddMode()) {
				vm.savedVisitEvent = vm.saved.visitEvent;

				if (!isSecondEntryMode()) {
					vm.data = angular.extend(vm.data, getModel(vm.savedVisitEvent));
					if (vm.isReadOnlyMode()) {
						setReadOnlyMode();
						return null;
					}
				}
			}

			if(isEditMode()) {
				setEditMode();
				refreshStudy = true;
			}

			return onStudyChange(studyId, studyModeId, siteId, isProficiency, refreshStudy,
								 refreshSite, refreshSubject);
		}

		function siteChanged(siteId) {
			if (vm.savedSiteId === siteId) {
				return false;
			}

			vm.savedSiteId = siteId;
			return true;
		}

		function studyChanged(studyId, proficiency){
			if (vm.savedStudyId === studyId && vm.savedIsProficiency === proficiency) {
				return false;
			}

			vm.savedStudyId = studyId;
			vm.savedIsProficiency = proficiency;

			if (vm.isAddMode()) {
				cleanEventList();
				cleanFirstEventDate();
				cleanVisitList();
				cleanSpirometryFields();
			}
			return true;
		}

		function subjectChanged(subjectId) {
			if (vm.savedSubjectId === subjectId) {
				return false;
			}

			vm.savedSubjectId = subjectId;
			return true;
		}


		function compareData(savedVisitEvent){
			vm.savedForComparison = getModel(savedVisitEvent);
			vm.isCompareData = true;
			return compareVisitEvent(vm.data, vm.savedForComparison);
		}

		function compareVisitEvent(current, saved) {
			var result = true;


			if (current.visitEventDetails.selectedPeriod && saved.visitEventDetails.selectedPeriod &&
					(current.visitEventDetails.selectedPeriod.periodId || saved.visitEventDetails.selectedPeriod.periodId) &&
				current.visitEventDetails.selectedPeriod.periodId !== saved.visitEventDetails.selectedPeriod.periodId){
				vm.options.formState.eventSelection.disabled = true;
				vm.options.formState.visitSelection.disabled = true;
				vm.options.formState.periodSelection.enableTooltip = true;

				current.visitEventDetails.secondSelectedPeriod = current.visitEventDetails.selectedPeriod;

				current.visitEventDetails.selectedPeriod = null;
				current.visitEventDetails.selectedVisit = null;
				current.visitEventDetails.selectedEvent = null;
				vm.visitHasDiscrepancies = true;
				vm.periodHasDiscrepancies = true;
				result = false;

			} else {
				vm.options.formState.periodSelection.disabled = true;
				if (current.visitEventDetails.selectedVisit.visitId !== saved.visitEventDetails.selectedVisit.visitId){
					vm.options.formState.eventSelection.disabled = true;
					vm.options.formState.visitSelection.enableTooltip = true;

					current.visitEventDetails.secondSelectedVisit = current.visitEventDetails.selectedVisit;
					current.visitEventDetails.selectedVisit = null;
					current.visitEventDetails.selectedEvent = null;
					vm.visitHasDiscrepancies = true;
					result = false;

				} else {
					vm.options.formState.visitSelection.disabled = true;
					if (saved.visitEventDetails.selectedEvent.visitTaskId && current.visitEventDetails.selectedEvent.visitTaskId !== saved.visitEventDetails.selectedEvent.visitTaskId){
						current.visitEventDetails.secondSelectedEvent = current.visitEventDetails.selectedEvent;
						current.visitEventDetails.selectedEvent = null;
						vm.options.formState.eventSelection.enableTooltip = true;
						result = false;
					} else {
						vm.options.formState.eventSelection.disabled = true;
					}
				}
			}

			if (current.visitEventDetails.eventDate !== saved.visitEventDetails.eventDate){
				current.visitEventDetails.secondEventDate = current.visitEventDetails.eventDate;
				current.visitEventDetails.eventDate = null;
				vm.options.formState.eventDate.enableTooltip = true;
				result = false;
			} else {
				vm.options.formState.eventDate.disabled = true;
			}

			if (current.visitEventDetails.eventTime !== saved.visitEventDetails.eventTime){
				current.visitEventDetails.secondEventTime = current.visitEventDetails.eventTime;
				current.visitEventDetails.eventTime = null;
				vm.options.formState.eventTime.enableTooltip = true;
				result = false;
			}else {
				vm.options.formState.eventTime.disabled = true;
			}

			if (vm.showTechnicianControls) {
				// after introducing the technician dropdown, we don't need to compare each single value but the complete
				// technician object.
				if (!areEqualIgnoreCase(current.selectedTechnician.selectedValue, saved.selectedTechnician.selectedValue)){
					current.secondTechnicianSelect = current.selectedTechnician;
				 	current.secondTechnicianFirstName = current.technicianFirstName;
				 	current.secondTechnicianLastName = current.technicianLastName;
				 	current.secondTechnicianEmail = current.technicianEmail;

					current.selectedTechnician = null;
					current.technicianEmail = null;
					current.technicianLastName = null;
					current.technicianFirstName = null;

					vm.technicianOptions.formState.technicianSelection.enableTooltip = true;
					result = false;
				} else {
					vm.technicianOptions.formState.technicianSelection.disabled = true;
				}

				setDirtyForm(vm.technicianForm);

			}

			return result;
		}

		function areEqualIgnoreCase(str1, str2) {
			if(!str1){
				str1 = '';
			}
			if (!str2){
				str2 = '';
			}
			return str1.toLowerCase() === str2.toLowerCase();
		}

		function setDirtyForm(form) {
			angular.forEach(form, function(value, key) {
				if (typeof value === 'object' && value.hasOwnProperty('$modelValue'))
					value.$setDirty();
			});
		}

		function setReadOnlyMode() {
			vm.isPaperEcg = vm.cdeMode == appConfig.cdeMode.Ecg;

			if (vm.data.visitEventDetails.selectedPeriod && vm.data.visitEventDetails.selectedPeriod.periodName) {
				var periods = [];
				periods.push(vm.data.visitEventDetails.selectedPeriod);
				vm.options.formState.periodSelection.options = matchSelectValueToModel(periods, 'periodName');
				vm.options.formState.periodSelection.disabled = true;
				vm.hasPeriods = true;
				vm.isPaperEcg = true;
			}
			var visits = [];
			visits.push(vm.data.visitEventDetails.selectedVisit);
			vm.options.formState.visitSelection.options = matchSelectValueToModel(visits, 'visitName');
			vm.options.formState.visitSelection.disabled = true;

			var events = [];
			if (vm.data.visitEventDetails.selectedEvent && vm.data.visitEventDetails.selectedEvent.visitTaskId) {
				events.push(vm.data.visitEventDetails.selectedEvent);
				setEventOption(events);
				vm.options.formState.eventSelection.disabled = true;
			} else {
				vm.hideVisitTasks = true;
			}

			vm.options.formState.eventDate.disabled = true;
			vm.options.formState.eventTime.disabled = true;

			var technicians = [];
			if (vm.data.selectedTechnician) {
				technicians.push(vm.data.selectedTechnician);
			}
			vm.technicianOptions.formState.technicianSelection.options =
				matchSelectValueToModel(technicians, (t) => { return getTechnicianDisplayText(t);});
			vm.technicianOptions.formState.technicianLastName.disabled = true;
			vm.technicianOptions.formState.technicianFirstName.disabled = true;
			vm.technicianOptions.formState.technicianEmail.disabled = true;

			showEfforts();
		}

		function setEditMode() {
			vm.options.formState.eventDate.disabled = false;
			vm.options.formState.eventTime.disabled = false;
			vm.technicianOptions.formState.technicianLastName.disabled = false;
			vm.technicianOptions.formState.technicianFirstName.disabled = false;
			vm.technicianOptions.formState.technicianEmail.disabled = false;
		}

		function getEventDisplayName(event) {
			if (vm.isPaperEcg || !event.testDataName) {
				return event.visitScheduleTaskName;
			} else {
				return event.visitScheduleTaskName + ' (' + event.testDataName + ')';
			}
		}

		function setEventOption(data) {
			vm.options.formState.eventSelection.options = matchSelectValueToModel(data, function (dataEntry) {
				return getEventDisplayName(dataEntry);
			});
		}

		function cleanSpirometryFields(){
			clinicalDataEntryService.getEffortsHandler().reset();
		}

		function showEfforts(){

			var testDataTypeId = getTestDatatypeId();
			if (testDataTypeId === 0) {
				return;
			}

			clinicalDataEntryService.getEffortsHandler().show(vm.data.selectedStudyId, vm.data.selectedStudyModeId,testDataTypeId, vm.mode , vm.saved.efforts, vm.cdeMode);
		}

		function getTestDatatypeId() {
			var testDataTypeId = 0;

			if (vm.isAddMode() && vm.data.visitEventDetails.selectedEvent){
				testDataTypeId = vm.data.visitEventDetails.selectedEvent.testDataType || 0;

			} else if (vm.savedTestDataTypeId) {
				testDataTypeId = vm.savedTestDataTypeId;
			} else if (vm.savedVisitEvent){
				testDataTypeId = vm.savedVisitEvent.testDataTypeId;
			}

			return testDataTypeId;
		}

		function addAndSetNewTechnician(firstName, lastName, email, guid, technicians) {

			let newTechnician = {
				firstName: firstName,
				lastName: lastName,
				email: email,
				guid: guid
			};
			newTechnician.selectedValue = getTechnicianDisplayText(newTechnician);

			technicians.push(newTechnician);

			vm.technicianOptions.formState.technicianSelection.options = technicians;

			vm.data.technicianFirstName = firstName;
			vm.data.technicianLastName = lastName;
			vm.data.technicianEmail = email;
			vm.data.guid = guid;
			vm.data.selectedTechnician = newTechnician;
			vm.data.visitEventDetails.selectedTechnician = newTechnician;
		}

		function resetEventDetails() {
			cleanSpirometryFields();

			// for visit only or period/visit only studies with no visit tasks (events) we are using a
			// hard coded test data type id to use the same workflow for Paper ECG + PFT entries.
			// therefore we need to keep the test data type id for such entries.
			if (vm.isPaperEcg && vm.hideVisitTasks) {
				vm.data.visitEventDetails.selectedEvent = {testDataType: appConfig.testDataType.ecg};
			} else {
				vm.data.visitEventDetails.selectedEvent = {};
			}

			vm.data.visitEventDetails.eventDate = null;
			vm.data.visitEventDetails.eventTime = null;
			vm.visitEventDetailsForm.showValidation = false;
			vm.visitEventDetailsForm.$setUntouched();
		}

		function resetVisitEventAndTechnician() {
			cleanEventList();
			cleanVisitList();
			cleanPeriodList();
			vm.data.visitEventDetails.eventDate = null;
			vm.data.visitEventDetails.eventTime = null;
			vm.visitEventDetailsForm.showValidation = false;
			vm.visitEventDetailsForm.$setUntouched();
			if (vm.showTechnicianControls) {
				vm.technicianForm.showValidation = false;
				vm.technicianForm.$setUntouched();
			}
		}

		function loadInitialData(studyId, studyModeId, siteId,
								 proficiency, refreshStudy, refreshSite, refreshSubject) {
			var testDataTypeId = vm.saved.visitEvent == null ? 0 : vm.saved.visitEvent.testDataTypeId;
			var requests = [];

			if (refreshStudy || refreshSubject) {
				if (isAddMode()) {
					resetVisitEventAndTechnician();
				}

				if (vm.showTechnicianControls) {
					requests.push(clinicalDataEntryApiService.getTechnicians(studyId, studyModeId, siteId));
				}
				if (vm.isPaperEcg) {
					requests.push(
						clinicalDataEntryApiService.getPeriods(
							studyId, studyModeId));

				} else {
					requests.push(
						clinicalDataEntryApiService.getVisitSchedules(
							studyId, studyModeId, testDataTypeId, proficiency, vm.cdeMode));
				}
			} else if (refreshSite && vm.showTechnicianControls) {
				requests.push(clinicalDataEntryApiService.getTechnicians(studyId, studyModeId, siteId));
			}

			if (requests.length === 0) {

				if (vm.hideVisitTasks) {
					showEfforts();
				}
				return;
			}
			vm.options.formState.dataLoading++;

			$q.all(requests).then(function (resolves) {

				if (vm.showTechnicianControls) {
					setupTechnicianControls(resolves[0].data);
					setupVisitData(resolves[1].data);
				} else {
					setupVisitData(resolves[0].data, studyId, studyModeId, testDataTypeId, proficiency);
				}

				return null;
			}, function (errorData, status) {
				logError(errorData, status);
				vm.options.formState.visitSelection.disabled = true;
			}).finally(() => {
				vm.options.formState.dataLoading--;
			});
		}

		function setupTechnicianControls(technicians) {
			vm.technicianOptions.formState.technicianSelection.disabled = false;
			vm.technicianOptions.formState.technicianSelection.options = [{
				selectedValue: vm.createTechnicianLabel
			}].concat(
				matchSelectValueToModel(technicians, (t) => {
					return getTechnicianDisplayText(t);
				}));

			const item = _.find(technicians, (t) => {
				return t.lastName === vm.data.technicianLastName
					&& t.firstName === vm.data.technicianFirstName
					&& t.email == vm.data.technicianEmail;
			});

			if (item && item.selectedValue === vm.createTechnicianLabel) {
				vm.data.selectedTechnician = {
					selectedValue: vm.createTechnicianLabel
				};
			} else if (item) {
				vm.data.selectedTechnician = item;
			}
		}

		function setupVisitData(visitData, studyId, studyModeId, testDataTypeId, proficiency) {
			if (vm.isPaperEcg) {
				let periods = visitData;
				if (!periods || periods.length === 0) {
					vm.hasPeriods = false;
					clinicalDataEntryApiService.getVisitSchedules(
						studyId, studyModeId, testDataTypeId, proficiency, vm.cdeMode).then((response) => {
							return setVisitModel(response.data);
						});
				} else {
					vm.hasPeriods = true;
					return setPeriodModel(visitData);
				}
			} else {
				return setVisitModel(visitData);
			}
		}


		function onStudyChange(studyId, studyModeId, siteId, proficiency, refreshStudy,
							   refreshSite, refreshSubject) {
			if (studyId) {

				vm.isPaperEcg = vm.cdeMode == appConfig.cdeMode.Ecg;

				if (vm.isPaperEcg && !vm.isDataRestored && refreshStudy) {
					vm.hasPeriods = true;
					vm.hideVisitTasks = false;
				}
				loadInitialData(studyId, studyModeId, siteId,
								proficiency, refreshStudy, refreshSite, refreshSubject);
			}

		}

		function setPeriodModel(periods) {
			vm.options.formState.periodSelection.disabled = false;
			vm.options.formState.periodSelection.options =
				matchSelectValueToModel(periods, 'periodName');
			let loadPromise = null;

			// init to previous value in edit mode and also in second entry mode, if first entry is from
			// site (with paramters hidden)
			if (isEditMode() || isSecondEntryByErtForSiteSubmissionMode()) {
				var item = _.find(periods, {periodId: vm.savedVisitEvent.periodId});
				if (item) {
					vm.data.visitEventDetails.selectedPeriod = item;
					loadPromise = periodDetailsChanged();
				}
			}
			showEfforts();
			return loadPromise;
		}

		function setVisitModel(visits) {
			vm.options.formState.visitSelection.disabled = false;
			vm.options.formState.visitSelection.options =
				matchSelectValueToModel(visits, 'visitName');
			let loadPromise = null;

			// init to previous value in edit mode and also in second entry mode, if first entry is from
			// site (with paramters hidden)
			if (isEditMode() || isSecondEntryByErtForSiteSubmissionMode()) {
				var item = _.find(visits, {visitId: vm.savedVisitEvent.visitId});
				if (item) {
					vm.data.visitEventDetails.selectedVisit = item;
					loadPromise = visitEventDetailsChanged();
				}
			}
			showEfforts();
			return loadPromise;
		}

		function setEventModel(events) {
			if (!vm.saved || isSecondEntryMode()) return;

			// init to previous value in edit mode and also in second entry mode, if first entry is from
			// site (with paramters hidden)
			if ((isEditMode() || isSecondEntryByErtForSiteSubmissionMode()) && vm.savedVisitEvent) {
				var item = _.find(events, {visitTaskId: vm.savedVisitEvent.eventId});
				if (item) {
					vm.data.visitEventDetails.selectedEvent = item;
				}
			}
		}

		function cleanVisitList() {
			vm.data.visitEventDetails.selectedVisit = {};
			vm.options.formState.visitSelection.options = [];
			vm.options.formState.visitSelection.disabled = true;
			vm.data.visitEventDetails.selectedVisit.fieldHint = '';
		}

		function cleanEventList() {
			vm.data.visitEventDetails.selectedEvent = {};
			vm.options.formState.eventSelection.disabled = true;
			vm.options.formState.eventSelection.options = [];
			vm.data.visitEventDetails.selectedEvent.fieldHint = '';
		}

		function cleanPeriodList() {
			vm.data.visitEventDetails.selectedPeriod = {};
			vm.options.formState.periodSelection.disabled = true;
			vm.options.formState.periodSelection.options = [];
		}

		function cleanFirstEventDate() {
			// we need to clean first event date field this way, because this field reacts to the model changes, even when it is hidden
			// (because validateOnModelChange is set to true, is needed for some custom validation).
			// Formly removes this field from the dom when it is hidden,
			// but keeps it in memory and linked to the data model. When the model is changed,
			// the hidden form (kept in memory) responds to this change and set the value to the linked model.
			// This provokes some validation messages to be displayed falsy.
			if (vm.visitEventDetails) {
				var fg = _.find(vm.visitEventDetails, {'key': 'visitEventDetails'});
				if(!fg) { return; }

				var firstVisitDateField = _.find(fg.fieldGroup, {'key': 'firstVisitDate'});
				if (typeof firstVisitDateField.resetModel === 'function') {
					firstVisitDateField.resetModel();
				}
			}
		}

		function isValid() {
			vm.isCompareData = false;
			vm.visitHasDiscrepancies = false;
			var valid = (isVisitEventFormValid() && isTechnicialFormValid());
			if (!valid) {
				vm.visitEventDetailsForm.showValidation = true;
				if (vm.showTechnicianControls) {
					vm.technicianForm.showValidation = true;
				}
			} else {
				// determine, check and set age demographics for new subject
				return setAgeDemographic(vm.saved.demographics);
			}
			return valid;
		}

		function isVisitEventFormValid() {

			return vm.visitEventDetailsForm.$valid;
		}

		function isTechnicialFormValid() {
			if (!vm.showTechnicianControls) {
				return true;
			}
			return vm.technicianForm.$valid;
		}

		function getModel(visitEvent) {
			var result = {};
			result.visitEventDetails = {};
			if (!visitEvent) return result;

			result.visitEventDetails.selectedPeriod = {
				periodId: visitEvent.periodId,
				periodName: visitEvent.periodName,
				periodAbbreviation: visitEvent.periodAbbreviation,
			};

			result.visitEventDetails.selectedVisit = {
				visitId: visitEvent.visitId,
				testDataType: visitEvent.testDataTypeId,
				visitName: visitEvent.visitName,
				visitAbbreviation: visitEvent.visitAbbreviation
			};

			result.visitEventDetails.selectedEvent = {
				visitScheduleTaskName: visitEvent.eventName,
				visitTaskOid: visitEvent.visitTaskOid,
				visitTaskId: visitEvent.eventId,
				testDataName: visitEvent.testDataName,
				VisitScheduleTaskId: visitEvent.VisitScheduleTaskId
			};
			result.visitEventDetails.eventDate = visitEvent.eventDate;
			result.visitEventDetails.eventTime = visitEvent.eventTime;

			if (vm.showTechnicianControls) {
				result.selectedTechnician = {
					firstName: visitEvent.technician.firstName,
					lastName: visitEvent.technician.lastName,
					email: visitEvent.technician.email,
					guid: visitEvent.technician.guid,	
					selectedValue: getTechnicianDisplayText(visitEvent.technician)
				};
				result.technicianFirstName = visitEvent.technician.firstName;
				result.technicianLastName = visitEvent.technician.lastName;
				result.technicianEmail = visitEvent.technician.email;
				guid: visitEvent.technician.guid;
			}

			return result;

		}

		function getData() {
			var dataModel = vm.data;
			let data =  {
				visitId: dataModel.visitEventDetails.selectedVisit.visitId,
				visitName: dataModel.visitEventDetails.selectedVisit.visitName,
				visitAbbreviation: dataModel.visitEventDetails.selectedVisit.visitAbbreviation,
				eventDate: dataModel.visitEventDetails.eventDate,
				eventTime: dataModel.visitEventDetails.eventTime,
				technician: {
					firstName: vm.data.technicianFirstName,
					lastName: vm.data.technicianLastName,
					email: vm.data.technicianEmail,
					guid: (vm.data.selectedTechnician && vm.data.selectedTechnician) ? vm.data.selectedTechnician.guid : null,
					selected: vm.data.selectedTechnician
				}
			};
			if (dataModel.visitEventDetails.selectedPeriod) {
				data.periodId = dataModel.visitEventDetails.selectedPeriod.periodId;
				data.periodName = dataModel.visitEventDetails.selectedPeriod.periodName;
				data.periodAbbreviation = dataModel.visitEventDetails.selectedPeriod.periodAbbreviation;
			}

			if (dataModel.visitEventDetails.selectedEvent) {
				data.testDataTypeId = dataModel.visitEventDetails.selectedEvent.testDataType;
				data.eventName = dataModel.visitEventDetails.selectedEvent.visitScheduleTaskName;
				data.visitScheduleTaskId = dataModel.visitEventDetails.selectedEvent.visitScheduleTaskId;
				data.visitTaskOid = dataModel.visitEventDetails.selectedEvent.visitTaskOid;
				data.eventId = dataModel.visitEventDetails.selectedEvent.visitTaskId;
				data.testDataName = dataModel.visitEventDetails.selectedEvent.testDataName;
			} else {
				data.testDataTypeId = appConfig.testDataType.ecg;
			}
			return data;
		}

		function getTechniciansList() {
			return vm.technicianOptions.formState.technicianSelection.options;
		}

		function getAvailableVisitEventOptions() {
			return {
				periodOptions: vm.options.formState.periodSelection.options,
				visitOptions: vm.options.formState.visitSelection.options,
				eventOptions: vm.options.formState.eventSelection.options
			};
		}

		function setData(dataModel, cdeMode) {
			if (!vm.isDataRestored) {
				vm.cdeMode = cdeMode || clinicalDataEntryService.getCdeMode();
				vm.options.formState.periodSelection.options = dataModel.visitEventOptions.periodOptions;
				vm.options.formState.visitSelection.options = dataModel.visitEventOptions.visitOptions;
				vm.options.formState.eventSelection.options = dataModel.visitEventOptions.eventOptions;

				if (!vm.data.visitEventDetails.selectedPeriod) {
					vm.data.visitEventDetails.selectedPeriod = {};
				}

				if (!vm.data.visitEventDetails.selectedVisit) {
					vm.data.visitEventDetails.selectedVisit = {};
					vm.data.visitEventDetails.selectedEvent = {};
				}

				if (dataModel.visitEvent.periodId) {
					vm.data.visitEventDetails.selectedPeriod.periodId = dataModel.visitEvent.periodId;
					vm.data.visitEventDetails.selectedPeriod.periodName = dataModel.visitEvent.periodName;
					vm.data.visitEventDetails.selectedPeriod.periodAbbreviation = dataModel.visitEvent.periodAbbreviation;
					vm.hasPeriods = true;
					vm.isPaperEcg = true;
					var periodItem = _.find(dataModel.visitEventOptions.periodOptions, {periodId: vm.data.visitEventDetails.selectedPeriod.periodId});
					if (periodItem) {
						vm.data.visitEventDetails.selectedPeriod = periodItem;
					}
					vm.options.formState.periodSelection.disabled = false;
				} else {
					vm.hasPeriods = false;
				}

				vm.data.visitEventDetails.selectedVisit.visitId = dataModel.visitEvent.visitId;
				vm.data.visitEventDetails.selectedEvent.testDataType = dataModel.visitEvent.testDataTypeId;
				vm.data.visitEventDetails.selectedVisit.visitName = dataModel.visitEvent.visitName;
				vm.data.visitEventDetails.selectedVisit.visitAbbreviation = dataModel.visitEvent.visitAbbreviation;

				vm.data.visitEventDetails.selectedEvent.visitScheduleTaskName = dataModel.visitEvent.eventName;
				vm.data.visitEventDetails.selectedEvent.visitScheduleTaskId = dataModel.visitEvent.visitScheduleTaskId;
				vm.data.visitEventDetails.selectedEvent.visitTaskOid = dataModel.visitEvent.visitTaskOid;
				vm.data.visitEventDetails.selectedEvent.visitTaskId = dataModel.visitEvent.eventId;
				vm.data.visitEventDetails.selectedEvent.testDataName = dataModel.visitEvent.testDataName;

				vm.data.visitEventDetails.eventDate = dataModel.visitEvent.eventDate;
				vm.data.visitEventDetails.eventTime = dataModel.visitEvent.eventTime;

				vm.showTechnicianControls = getTechnicianControlsVisibility(dataModel.displayMode, vm.cdeMode, dataModel);

				if (vm.showTechnicianControls) {
					vm.data.technicianFirstName = dataModel.visitEvent.technician.firstName;
					vm.data.technicianLastName = dataModel.visitEvent.technician.lastName;
					vm.data.technicianEmail = dataModel.visitEvent.technician.email;
					vm.data.selectedTechnician = dataModel.visitEvent.technician.selected;
					vm.technicianOptions.formState.technicianSelection.options = dataModel.technicians;
				}

				vm.savedIsProficiency = dataModel.subjectMode && dataModel.subjectMode.value === 2;

				vm.options.formState.visitSelection.disabled = false;
				vm.options.formState.eventSelection.disabled = false;

				var item = _.find(dataModel.visitEventOptions.visitOptions, {visitId: vm.data.visitEventDetails.selectedVisit.visitId});
				if (item) {
					vm.data.visitEventDetails.selectedVisit = item;
				}

				item = _.find(dataModel.visitEventOptions.eventOptions, {visitTaskId: vm.data.visitEventDetails.selectedEvent.visitTaskId});
				if (item) {
					vm.data.visitEventDetails.selectedEvent = item;
				}

				if (dataModel.visitEventOptions.eventOptions.length === 0 &&
					!vm.data.visitEventDetails.selectedEvent.visitTaskId) {

					vm.hideVisitTasks = true;
					vm.isPaperEcg = true;
				}

				if (!vm.saved) {
					vm.saved = {};
				}

				vm.mode = dataModel.displayMode;
				vm.isDataRestored = true;
				vm.savedStudyId = dataModel.context.studyId;
				vm.savedSiteId = dataModel.context.internalSiteId;
				vm.savedSubjectId = dataModel.subject.id;

				vm.data.selectedStudyId = vm.savedStudyId;
				vm.data.selectedStudyModeId = dataModel.context.studyModeId;
				vm.savedTestDataTypeId = dataModel.visitEvent.testDataTypeId;
			}
		}

		function matchSelectValueToModel(dataModelRef, displayName) {
			if (_.isFunction(displayName)) {
				for (var x = 0; x < dataModelRef.length; x++) {
					dataModelRef[x].selectedValue = displayName(dataModelRef[x]);
				}
				return dataModelRef;
			}
			for (var x = 0; x < dataModelRef.length; x++) {
				dataModelRef[x].selectedValue = dataModelRef[x][displayName];
			}
			return dataModelRef;
		}

		function isValidDateRange(input) {
			if (!input) {
				return false;
			}
			var date = new Date(input.replace(/-/g, ' '));

			var today = getToday();
			$log.debug(`$compare date: ${date}, today: ${today}`);
			return date < today;
		}

		function getToday() {
			var offset = getTimezoneOffset();
			var MS_PER_MINUTE = 60000;
			var date = new Date();
			date = new Date(date.getTime() + (offset + date.getTimezoneOffset()) * MS_PER_MINUTE);

			return date;
		}

		function getTimezoneOffset() {
			var minutes =0;

			var timezone = vm.timezone;
			if (timezone && timezone.offsetDbValue){
				var hm = timezone.offsetDbValue;
				var a = hm.split(':');
				minutes = (+a[0]) * 60 + (+a[1]);
			}

			if(isNaN(minutes)){
				minutes = 0;
			}

			return minutes;
		}

		function getDOBDemographic () {
			if(_.has(vm, 'saved.demographics')) {
				let dob =  _.find(vm.saved.demographics, {id: '2'});

				if (!dob) {
					// for existing subjects
					dob =  _.find(vm.saved.demographics, {fieldId: '2'});
				}
				return dob;
			}
			return undefined;
		}

		function getEventDate() {
			return _.has(vm, 'data.visitEventDetails') && vm.data.visitEventDetails.eventDate;
		}

		function getFirstEventDate() {
			return _.has(vm, 'data.visitEventDetails') && vm.data.visitEventDetails.firstVisitDate;
		}

		function periodDetailsChanged() {
			cleanVisitList();
			cleanEventList();
			cleanFirstEventDate();
			if (!vm.data.visitEventDetails.selectedPeriod) {
				return null;
			} else {

				vm.options.formState.dataLoading++;
				return clinicalDataEntryApiService.getVisitSchedules(
					vm.data.selectedStudyId,
					vm.data.selectedStudyModeId,
					0, 0, vm.cdeMode, vm.data.visitEventDetails.selectedPeriod.periodId
					).then(function success(response) {
						var data = response.data;
						setVisitModel(response.data);

					}, function error(data, status) {
						logError(data, status);
					}).finally(() => {
						vm.options.formState.dataLoading--;
					});
			}
		}

		function visitEventDetailsChanged() {
			cleanEventList();
			cleanFirstEventDate();
			if (!vm.data.visitEventDetails.selectedVisit) {
				return null;
			} else {

				vm.options.formState.dataLoading++;
				return clinicalDataEntryApiService.getVisitTasks(
					vm.data.selectedStudyId,
					vm.data.selectedStudyModeId,
					vm.data.visitEventDetails.selectedVisit.visitId, vm.cdeMode).then(function success(response) {

					vm.options.formState.eventSelection.disabled = false;
					var data = response.data;

					if (data.length == 0 && vm.isPaperEcg) {
						vm.hideVisitTasks = true;
						vm.data.visitEventDetails.selectedEvent = {testDataType: appConfig.testDataType.ecg};
						showEfforts();
					} else {
						vm.hideVisitTasks = false;
					}
					if(isSecondEntryMode() || isSecondEntryByErtForSiteSubmissionMode() || isEditMode()) {
						data = _.filter(data, {'testDataType': getTestDatatypeId()});
					}

					setEventOption(data);
					setEventModel(data);

					if (vm.hideVisitTasks && !isSecondEntryMode()) {
						checkIfVisitAlreadyExists();
					}
				}, function error(data, status) {
					logError(data, status);
				}).finally(() => {
					vm.options.formState.dataLoading--;
				});
			}
		}

		function checkIfEventAlreadyExists() {
			if (vm.data.visitEventDetails.selectedEvent.isUnscheduled) {
				return;
			}
			var model = {
				id : vm.saved.id || 0,
				studyId: vm.savedStudyId,
				siteId: vm.saved.context.internalSiteId,
				sponsorSiteId: vm.saved.context.sponsorSiteId,
				subjectId: vm.saved.subject.id || 0,
				sponsorSubjectId: vm.saved.subject.sponsorSubjectId,
				visitName: vm.data.visitEventDetails.selectedVisit.visitName,
				eventName: vm.data.visitEventDetails.selectedEvent.visitScheduleTaskName
			};

			if(!isSecondEntryMode() && !isSecondEntryByErtForSiteSubmissionMode()) {
				var fieldHint = appI18n.translateImmediate('cde-pft.hints.eventAlreadyExists');
				clinicalDataEntryApiService.checkIfEventAlreadyExists(model)
					.then(function(response) {
						if (response.data) {
							vm.data.visitEventDetails.selectedEvent.fieldHint = fieldHint;
						}
						else {
							vm.data.visitEventDetails.selectedEvent.fieldHint = '';
						}
					});
			}
		}

		function checkIfVisitAlreadyExists() {
			var fieldHint = appI18n.translateImmediate('cde-pft.hints.visitAlreadyExists');
			if (vm.data.visitEventDetails.selectedVisit.isUnscheduled) {
				return;
			}
			var model = {
				id : vm.saved.id || 0,
				studyId: vm.savedStudyId,
				siteId: vm.saved.context.internalSiteId,
				sponsorSiteId: vm.saved.context.sponsorSiteId,
				subjectId: vm.saved.subject.id || 0,
				sponsorSubjectId: vm.saved.subject.sponsorSubjectId,
				visitName: vm.data.visitEventDetails.selectedVisit.visitName,
			};
			clinicalDataEntryApiService.checkIfVisitAlreadyExists(model)
				.then(function(response) {
					if (response.data) {
						vm.data.visitEventDetails.selectedVisit.fieldHint = fieldHint;
					}
					else {
						vm.data.visitEventDetails.selectedVisit.fieldHint = '';
					}
				});
		}
		function compareField(viewValue, modelValue, scope) {
			var value = viewValue || modelValue;
			if (vm.isCompareData && value !== '' && !value) {
				return false;
			}
			return true;
		}

		function setVisitErrorMessage(viewValue, modelValue, scope) {
			if (vm.periodHasDiscrepancies) {
				return vm.errorMessageHasDiscrepanciesPeriod;
			} else {
				return setErrorMessage(viewValue, modelValue, scope);
			}
		}

		function setEventErrorMessage(viewValue, modelValue, scope) {
			if (vm.visitHasDiscrepancies) {
				return vm.errorMessageHasDiscrepanciesVisit;
			} else {
				return setErrorMessage(viewValue, modelValue, scope);
			}
		}

		function setErrorMessage(viewValue, modelValue, scope) {
			if(vm.isCompareData){
				return scope.to.label + ' ' + vm.errorMessageHasDiscrepancies;
			}
			return setRequiredErrorMessage(viewValue, modelValue, scope);
		}

		function setRequiredErrorMessage(viewValue, modelValue, scope) {
			return scope.to.label + ' ' + vm.errorMessageRequired;
		}

		function checkDobAfterSubjectChange() {
			const dobDemogr = getDOBDemographic();
			const eventDate = vm.data.visitEventDetails.eventDate;

			if (!dobDemogr || !eventDate) {return true;}
			return isDobBeforeEventDate(dobDemogr.demographic, eventDate);
		}
		// sets age demographics for new subject
		function setAgeDemographic(demogrModel) {
			const dobAfterEvent = !checkDobAfterSubjectChange();

			setDobAfterEventErrorMessage(dobAfterEvent);

			if (dobAfterEvent) {
				setAgeRangeErrorMessage(false);
				return false;
			}

			var demogrObj = getAgeDemographic(demogrModel);
			if(!demogrObj) { return true; }
			demogrObj.model.demographic = checkAgeDemographicRange(demogrObj);
			if(demogrObj.model.demographic === null) {
				setAgeRangeErrorMessage(true, demogrObj.minValue, demogrObj.maxValue);
				return false;
			} else {
				setAgeRangeErrorMessage(false);
				return true;
			}
		}

		function getAgeDemographic(demogrModel) {
			var ageDemographicModel = _.find(demogrModel, {id: '12'});

			if(ageDemographicModel && ageDemographicModel.calculatedDemogr) {
				var dobDemographicModel = _.find(demogrModel, {id: '2'});
				return getCalculatedAge(ageDemographicModel, dobDemographicModel);
			} else {
				return null;
			}
		}

		function getCalculatedAge(ageDemographicModel, dobDemographicModel) {
			var result = {};
			ageDemographicModel.demographic = null;
			var dob = dobDemographicModel.demographic;
			var firstDate = null;
			if(isFirstVisit()) {
				// age will be calculated based on the visit date provided in the paper PFT entry
				firstDate = vm.data.visitEventDetails.eventDate;
			} else {
				// date of the first study visit should be used for age calculation
				firstDate = vm.data.visitEventDetails.firstVisitDate;
			}
			// short cut the age demographic logic for cases when we are first time on the second step
			// and no error message should be shown
			if (!firstDate) { return null; }

			var age = calculateAge(dob, firstDate);
			var decimals = ageDemographicModel.numberOfDecimals || 0;
			result.age = truncateDecimals(age, decimals);
			result.minValue = ageDemographicModel.minimumValue;
			result.maxValue = ageDemographicModel.maximumValue;
			result.model = ageDemographicModel;
			return result;
		}

		function truncateDecimals(num, decimals) {
			num = num || 0;
			decimals = decimals || 0;

			const re = new RegExp("(\\d+\\.\\d{" + decimals + "})(\\d)");
			const m = num.toString().match(re);

			return m ? parseFloat(m[1]).toFixed(decimals) : num.toFixed(decimals);
		}

		function checkAgeDemographicRange (demogrObj) {
			// no min/max values available => skip validation
			if(!demogrObj.minValue && !demogrObj.maxValue) {
				return demogrObj.age;
			}
			//only max value avaliable
			if(!demogrObj.minValue && demogrObj.maxValue) {
				return (demogrObj.age <= demogrObj.maxValue)? demogrObj.age : null;
			}
			//only min value available
			if(demogrObj.minValue && !demogrObj.maxValue) {
				return (demogrObj.age >= demogrObj.minValue)? demogrObj.age : null;
			}
			// both min and max values availble
			return (parseFloat(demogrObj.age) >= parseFloat(demogrObj.minValue)
				&& parseFloat(demogrObj.age) <= parseFloat(demogrObj.maxValue))? demogrObj.age : null;
		}

		function setDobAfterEventErrorMessage(dobIsAfterEvent) {
			vm.globalErrors.dobAfterEvent = dobIsAfterEvent;
			if (dobIsAfterEvent) {
				appI18n.translate('cde-pft.errors.dobAfterEvent').then(
					(translation) => {
						vm.globalErrors.dobAfterEventMessage = translation;
					});
			} else {
				vm.globalErrors.dobAfterEventMessage = '';
			}
		}

		function setAgeRangeErrorMessage (outOfRange, minVal, maxVal) {
			if (outOfRange) {
				vm.globalErrors.ageOutOfRange = true;
				if(minVal && maxVal) {
					vm.globalErrors.ageOutOfRangeMessage = `The age value is out of range.
					It should be between ${minVal} and ${maxVal}. Please, check the Date of Birth and Visit Event date.`;
				} else if(!minVal && maxVal) {
					vm.globalErrors.ageOutOfRangeMessage = `The age value is out of range.
					It should not be more than ${maxVal}. Please, check the Date of Birth and Visit Event date.`;
				} else if(minVal && !maxVal) {
					vm.globalErrors.ageOutOfRangeMessage = `The age value is out of range.
					It should not be less than ${minVal}. Please, check the Date of Birth and Visit Event date.`;
				}
			} else {
				vm.globalErrors.ageOutOfRange = false;
				vm.globalErrors.ageOutOfRangeMessage = '';
			}
		}

		function isFirstVisit() {
			return _.has(vm.data, 'visitEventDetails.selectedVisit.isFirstClinicalVisit')
				&& vm.data.visitEventDetails.selectedVisit.isFirstClinicalVisit;
		}

		function calculateAge(dobDateStr, visitDateStr) {
			dobDateStr = getFullDobString(dobDateStr);
			var visitDate = moment(visitDateStr, 'DD-MMM-YYYY');
			var dobDate = moment(dobDateStr, 'DD-MMM-YYYY');
			return visitDate.diff(dobDate, 'years', true);
		}


		vm.visitEventDetails = [
			{
				key: 'visitEventDetails',
				wrapper: 'fieldGroupWrapper',
				fieldGroup: [
					{
						type: 'esSelect',
						key: 'selectedPeriod',
						wrapper: 'esColumnControlWrap',
						templateOptions: {
							fieldId: 'periodSelect',
							label: appI18n.translateImmediate('cde-pft.titles.period'),
							disabled: true,
							placeholder: appI18n.translateImmediate('cde-pft.titles.selectPeriod'),
							options: [],
							id: 'periodSelect',
							onChange: function () {
								periodDetailsChanged();
							},
							buttons: vm.tooltipButtonsForPeriod,
							enableTooltip: false
						},
						hideExpression: () => { return !vm.isPaperEcg || !vm.hasPeriods; },
						validators: {
							required: {
								expression: function($viewValue, $modelValue) {
									if (vm.isReadOnlyMode()) return true;

									var value = $modelValue || $viewValue;
									var validValue = value && value.periodId;
									return !!validValue;
								}
							}
						},
						expressionProperties: {
							"templateOptions.disabled": () => {
								return vm.options.formState.periodSelection.disabled ||
									vm.options.formState.dataLoading > 0 ||
									vm.isReadOnlyMode();
							},
							"templateOptions.required": () => !vm.isReadOnlyMode(),
							"templateOptions.options": "formState.periodSelection.options",
							"templateOptions.enableTooltip": "formState.periodSelection.enableTooltip"
						},
						validation: { messages: { required: setErrorMessage }}
					},
					{
						type: 'esSelect',
						key: 'selectedVisit',
						wrapper: 'esColumnControlHintWrap',
						templateOptions: {
							fieldId: 'visitSelect',
							label: 'Visit Name',
							disabled: true,
							placeholder: appI18n.translateImmediate('cde-pft.titles.selectVisit'),
							options: [],
							id: 'visitSelect',
							onChange: function () {
								visitEventDetailsChanged();
							},
							buttons: vm.tooltipButtonsForVisit,
							enableTooltip: false
						},
						validators: {
							required: {
								expression: function($viewValue, $modelValue) {
									if (vm.isReadOnlyMode()) return true;

									var value = $modelValue || $viewValue;
									var validValue = value && value.visitId;
									return !!validValue;
								}
							}
						},
						expressionProperties: {
							"templateOptions.disabled": () => {
								return vm.options.formState.visitSelection.disabled ||
									vm.options.formState.dataLoading > 0 ||
									vm.isReadOnlyMode();
							},
							"templateOptions.required": () => !vm.isReadOnlyMode(),
							"templateOptions.options": "formState.visitSelection.options",
							"templateOptions.enableTooltip": "formState.visitSelection.enableTooltip"
						},
						validation: { messages: { required: setVisitErrorMessage }}
					},
					{
						type: 'esDatePickerInput',
						key: 'firstVisitDate',
						wrapper: 'esColumnControlWrap',
						templateOptions: {
							label: 'Date of First Study Visit',
							id: 'firstStudyVisit',
							placeholder: 'DD-MMM-YYYY',
							validationPattern: "^((((0?[1-9]|[12][0-9]|3[01])[-]([Jj][Aa][Nn]|[Mm][Aa][Rr]|[Mm][Aa][Yy]|[Jj][Uu][Ll]|[Aa][Uu][Gg]|[Oo][Cc][Tt]|[Dd][Ee][Cc]))|((0?[1-9]|[12][0-9]|30)[-]([Aa][Pp][Rr]|[Jj][Uu][Nn]|[Ss][Ee][Pp]|[Nn][Oo][Vv]))|((0?[1-9]|[12][0-9])[-]([Ff][Ee][Bb])))[-]((19|20)\\d\\d))?$"
						},
						hideExpression: function ($viewValue, $modelValue, scope) {
							// hide on all modes except first entry mode
							if (!vm.isAddMode()) { return true; }
							// the model is still empty (on load), hide by default
							if (!scope.model.selectedVisit || scope.model.selectedVisit.isFirstClinicalVisit === undefined) return true;

							//The proficiency visit should be considered to be the first visit.
							if (scope.model.selectedVisit.visitTypeName === 'Proficiency') {
								return true;
							}

							// no age demographics - no need to calculate it
							// no dob - it's impossible to calculate age
							var ageDemographicModel = _.find(vm.saved.demographics, {id: '12'});
							var dobDemographicsModel = _.find(vm.saved.demographics, {id: '2'});
							if (!ageDemographicModel || !dobDemographicsModel) { return true; }

							return scope.model.selectedVisit.isFirstClinicalVisit;
						},
						extras: {
							validateOnModelChange: true
						},
						validators: {
							dateFormatForPicker: {
								expression: function (viewValue, modelValue, scope) {
									if (vm.isReadOnlyMode()) return true;

									var value = modelValue || viewValue;
									var rexp = new RegExp(scope.to.validationPattern);
									return !value || rexp.test(value);
								}
							},
							dateInPast: {
								expression: function (viewValue, modelValue, scope) {
									var value = modelValue || viewValue;

									return moment(value, 'DD-MMM-YYYY').isSameOrBefore(Date.now(), 'day');
								},
								message: '"The date must be in the past"'
							},
							firstEventDateIsSameOrBeforeEventDate: {
								expression: function (viewValue, modelValue, scope) {
									var firstEventDate = modelValue || viewValue;
									var eventDate = getEventDate();
									if(!eventDate || !firstEventDate) { return true; }

									return isFirstEventDateSameOrBeforeEventDate(firstEventDate, eventDate);
								},
								message: '"The First Study Visit Date must be the same or before Event Date"'
							},
							isAfterDateOfBirth: {
								expression: function (viewValue, modelValue, scope) {
									var firstEventDate = modelValue || viewValue;
									var dobDemogr = getDOBDemographic();
									// no validation if no dob demographic
									if (!dobDemogr || !firstEventDate) {return true;}
									let isBefore = isDobBeforeEventDate(dobDemogr.demographic, firstEventDate);

									if (isBefore) {
										setDobAfterEventErrorMessage(false);
									}

									return isBefore;
								},
								message: '"The date must be after the date of birth"'
							}
						},
						expressionProperties: {
							"templateOptions.required": () => !vm.isReadOnlyMode(),
						},
					},
					{
						type: 'esSelect',
						key: 'selectedEvent',
						wrapper: 'esColumnControlHintWrap',
						hideExpression: () => { return vm.isPaperEcg && vm.hideVisitTasks; },
						templateOptions: {
							fieldId: 'eventSelect',
							label: ' Visit Event',
							placeholder: appI18n.translateImmediate('cde-pft.titles.selectVisitEvent'),
							disabled: true,
							options: [],
							id: "eventSelect",
							onChange: function () {
								if (vm.saved.subject && vm.saved.subject.sponsorSubjectId && vm.data.visitEventDetails.selectedEvent
										&& vm.data.visitEventDetails.selectedVisit) {
									checkIfEventAlreadyExists();
								}

								if (!vm.data.visitEventDetails.selectedEvent && vm.isAddMode()){
									cleanSpirometryFields();
							}
								else {
									showEfforts();

								}
							},
							buttons: vm.tooltipButtonsForVisitEvent,
							enableTooltip: false
						},
						validators: {
							required: {
								expression: function($viewValue, $modelValue) {
									if (vm.isReadOnlyMode()) return true;

									var value = $modelValue || $viewValue;
									var validValue = value && value.testDataType;
									return !!validValue;
								}
							}
						},
						expressionProperties: {
							"templateOptions.disabled": () => {
								return vm.options.formState.eventSelection.disabled ||
									vm.options.formState.dataLoading > 0 ||
									vm.isReadOnlyMode();
							},
							"templateOptions.required": () => !vm.isReadOnlyMode(),
							"templateOptions.options": "formState.eventSelection.options",
							"templateOptions.enableTooltip": "formState.eventSelection.enableTooltip"
						},
						validation: { messages: { required: setEventErrorMessage }}
					},
					{
						type: 'esDatePickerInput',
						key: 'eventDate',
						wrapper: 'esColumnControlWrap',
						templateOptions: {
							fieldId: 'eventDateInput',
							label: 'Event Date',
							placeholder: 'DD-MMM-YYYY',
							id: 'eventDateInput',
							validationPattern: "^((((0?[1-9]|[12][0-9]|3[01])[-]([Jj][Aa][Nn]|[Mm][Aa][Rr]|[Mm][Aa][Yy]|[Jj][Uu][Ll]|[Aa][Uu][Gg]|[Oo][Cc][Tt]|[Dd][Ee][Cc]))|((0?[1-9]|[12][0-9]|30)[-]([Aa][Pp][Rr]|[Jj][Uu][Nn]|[Ss][Ee][Pp]|[Nn][Oo][Vv]))|((0?[1-9]|[12][0-9])[-]([Ff][Ee][Bb])))[-]((19|20)\\d\\d))?$",
							buttons: vm.tooltipButtonsForEventDate,
							enableTooltip: false
						},
						extras: {
							validateOnModelChange: true
						},
						validators: {
							required: {
								expression: compareField
							},
							dateFormatForPicker: {
								expression: function (viewValue, modelValue, scope) {
									if (vm.isReadOnlyMode()) return true;

									var value = modelValue || viewValue;
									var rexp = new RegExp(scope.to.validationPattern);
									return !value || rexp.test(value);
								}
							},
							dateRange: {
								expression: function (viewValue, modelValue, scope) {
									if (vm.isReadOnlyMode()) return true;

									var value = modelValue || viewValue;
									if (value && vm.isValidDateRange(value)) {
										return true;
									}
									return false;
								}
							},
							firstEventDateIsSameOrBeforeEventDate: {
								expression: function (viewValue, modelValue, fieldScope) {
									var eventDate = modelValue || viewValue;
									var firstEventDate = getFirstEventDate();
									if(!firstEventDate || !eventDate) { return true; }

									return isFirstEventDateSameOrBeforeEventDate(firstEventDate, eventDate);
								},
								message: '"The Event Date must be the same or later than the First Study Visit"'
							},
							isAfterDateOfBirth: {
								expression: function (viewValue, modelValue, scope) {
									var eventDate = modelValue || viewValue;
									var dobDemogr = getDOBDemographic();
									//no validation if no dob demographic
									if (!dobDemogr || !eventDate) { return true; }
									let isBefore = isDobBeforeEventDate(dobDemogr.demographic, eventDate);

									if (isBefore) {
										setDobAfterEventErrorMessage(false);
									}

									return isBefore;
								},
								message: '"The date must be after the date of birth"'
							}
						},
						expressionProperties: {
							"templateOptions.disabled": function () {
								if (vm.isAddMode()) {
									return vm.options.formState.visitSelection.disabled;
								}

								return vm.options.formState.eventDate.disabled || vm.isReadOnlyMode();
							},
							"templateOptions.required": () => !vm.isReadOnlyMode(),
							"templateOptions.enableTooltip": "formState.eventDate.enableTooltip"
						},
						validation: { messages: { required: setErrorMessage }}
					},
					{
						type: 'esInput',
						key: 'eventTime',
						wrapper: 'esColumnControlWrap',
						modelOptions: {
							updateOn: "blur"
						},
						templateOptions: {
							fieldId: 'eventTimeInput',
							label: ' Event Time (24 Hr)',
							placeholder: 'HH:mm[:ss]',
							maskPlaceholder: '',
							mask: '9?9?:9?9?:?9?9?',
							maskChar: 'space',
							allowInvalidValue: true,
							defaultPlaceholder: false,
							disabled: false,
							id: 'eventTimeInput',
							validationPattern: '^([01][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?|^([01][0-9]|2[0-3])[0-5][0-9]([0-5][0-9])?$',
							buttons: vm.tooltipButtonsForEventTime,
							enableTooltip: false,

							onChange: function (value) {
								$log.debug("onChagne" + value);
								if (vm.data.visitEventDetails.eventTime && vm.data.visitEventDetails.eventTime.length === 4) {
									vm.data.visitEventDetails.eventTime = vm.data.visitEventDetails.eventTime + '00';
								}
							}
						},
						validators: {
							hoursMinutesSecondsOnly: {
								expression: function (viewValue, modelValue, scope) {
									if (vm.isReadOnlyMode()) return true;

									var value = modelValue || viewValue;
									$log.debug("viewvalue: " + viewValue + " model: " + modelValue);
									var rexp = new RegExp(scope.to.validationPattern);
									var valid = !value || rexp.test(value);

									return valid;
								}
							}
						},
						expressionProperties: {
							"templateOptions.disabled": function () {
								if (vm.isAddMode()) {
									return vm.options.formState.visitSelection.disabled;
								}

								return vm.options.formState.eventTime.disabled || vm.isReadOnlyMode();
							},
							"templateOptions.required": () => !vm.isReadOnlyMode(),
							"templateOptions.enableTooltip": "formState.eventTime.enableTooltip"
						},
						validation: { messages: { required: setErrorMessage }}
					}
				]
			}
		];

		vm.options = {
			formState: {
				periodSelection: {
					disabled: true,
					options: [],
					enableTooltip: false
				},
				visitSelection: {
					disabled: true,
					options: [],
					enableTooltip: false
				},
				eventSelection: {
					disabled: true,
					options: [],
					enableTooltip: false
				},
				eventDate: {
					disabled: false,
					enableTooltip: false
				},
				eventTime: {
					disabled: false,
					enableTooltip: false
				},
				dataLoading: 0
			}
		};
		vm.technicianFields = [
			{
				type: 'esSelect',
				key: 'selectedTechnician',
				wrapper: 'esColumnControlHintWrap',
				templateOptions: {
					fieldId: 'technicianSelect',
					label: 'Technician',
					disabled: false,
					placeholder: vm.selectTechnicianLabel,
					options: [],
					id: "technicianSelect",
					onChange: function () {
						if (vm.data.selectedTechnician) {
							if (vm.isCompareData) {
								vm.technicianFields[1].formControl.$touched = true;
								vm.technicianFields[2].formControl.$touched = true;
								vm.technicianFields[3].formControl.$touched = true;
							}
							vm.data.technicianEmail = vm.data.selectedTechnician.email;
							vm.data.technicianFirstName = vm.data.selectedTechnician.firstName;
							vm.data.technicianLastName = vm.data.selectedTechnician.lastName;
							vm.data.guid = vm.data.selectedTechnician.guid;
						} else {
							vm.data.technicianEmail = '';
							vm.data.technicianFirstName = '';
							vm.data.technicianLastName = '';
						}
					},
					buttons: vm.tooltipButtonsForTechnicianSelect,
					enableTooltip: false
				},
				expressionProperties: {
					"templateOptions.disabled": () => {
						return vm.technicianOptions.formState.technicianSelection.disabled ||
							vm.isReadOnlyMode();
					},
					"templateOptions.required": () => !vm.isReadOnlyMode(),
					"templateOptions.options": "formState.technicianSelection.options",
					"templateOptions.enableTooltip": "formState.technicianSelection.enableTooltip"
				},
				validation: { messages: { required: setErrorMessage }}
			},
			{
				type: 'esInput',
				key: 'technicianLastName',
				wrapper: 'esColumnControlWrap',
				templateOptions: {
					type: 'text',
					label: 'Last Name',
					placeholder: '',
					id: 'technicianLastNameInput'
				},
				ngModelElAttrs: {
					maxlength: '30'
				},
				hideExpression: () => { return !vm.data.selectedTechnician; },
				expressionProperties: {
					'templateOptions.disabled': () => {
						return vm.technicianOptions.formState.technicianLastName.disabled
							|| vm.isReadOnlyMode()
							|| (vm.data.selectedTechnician && vm.data.selectedTechnician.selectedValue !== vm.createTechnicianLabel);
					},
					"templateOptions.required": () => !vm.isReadOnlyMode()
				},
				validation: { messages: { required: setRequiredErrorMessage }}
			},
			{
				type: 'esInput',
				key: 'technicianFirstName',
				wrapper: 'esColumnControlWrap',
				templateOptions: {
					type: 'text',
					label: 'First Name',
					placeholder: '',
					id: 'technicianFirstNameInput'
				},
				ngModelElAttrs: {
					maxlength: '30'
				},
				hideExpression: () => { return !vm.data.selectedTechnician; },
				expressionProperties: {
					'templateOptions.disabled': () => {
						return vm.technicianOptions.formState.technicianFirstName.disabled
							|| vm.isReadOnlyMode()
							|| (vm.data.selectedTechnician && vm.data.selectedTechnician.selectedValue !== vm.createTechnicianLabel);
					},
					"templateOptions.required": () => !vm.isReadOnlyMode()
				},
				validation: { messages: { required: setRequiredErrorMessage }}
			},
			{
				type: 'esInput',
				key: 'technicianEmail',
				wrapper: 'esColumnControlWrap',
				templateOptions: {
					type: 'email',
					label: 'Email',
					placeholder: '',
					id: 'technicianEmailInput',
					required: false
				},
				ngModelElAttrs: {
					maxlength: '150'
				},
				hideExpression: () => { return !vm.data.selectedTechnician; },
				expressionProperties: {
					'templateOptions.disabled': () => {
						return vm.technicianOptions.formState.technicianEmail.disabled
							|| vm.isReadOnlyMode()
							|| (vm.data.selectedTechnician && vm.data.selectedTechnician.selectedValue !== vm.createTechnicianLabel);
					}
				},
				validation: { messages: { required: setRequiredErrorMessage }}
			}];

		vm.technicianOptions = {
			formState: {
				technicianLastName: {
					disabled: false
				},
				technicianFirstName: {
					disabled: false
				},
				technicianEmail: {
					disabled: false
				},
				technicianSelection: {
					disabled: false,
					options: [],
					enableTooltip: false
				},

			}
		};

		function isAddMode () {
			return vm.mode === appConfig.dataEntryDisplayModes.add;
		}

		function isEditMode () {
			return vm.mode === appConfig.dataEntryDisplayModes.edit ||
				vm.mode === appConfig.dataEntryDisplayModes.firstEntryByErtForSiteSubmission;
		}

		function isReadOnlyMode() {
			return (vm.mode === appConfig.dataEntryDisplayModes.review || vm.mode === appConfig.dataEntryDisplayModes.viewOnly);
		}

		function isSecondEntryMode () {
			return vm.mode === appConfig.dataEntryDisplayModes.secondEntry;
		}

		function isSecondEntryByErtForSiteSubmissionMode () {
			return vm.mode === appConfig.dataEntryDisplayModes.secondEntryByErtForSiteSubmission;
		}


		function isReviewMode () {
			return vm.mode === appConfig.dataEntryDisplayModes.review;
		}

		function isViewOnlyMode () {
			return vm.mode === appConfig.dataEntryDisplayModes.viewOnly;
		}

		function isFirstEventDateSameOrBeforeEventDate(firstEventDate, eventDate) {
			if (!firstEventDate || !eventDate) { return true; }
			return moment(firstEventDate, 'DD-MMM-YYYY').isSameOrBefore(moment(eventDate, 'DD-MMM-YYYY'),'day');
		}

		function getFullDobString(dob) {
			// if partial DOB (either with or without month), consider as first possible date
			if (dob.length === 4) {
				dob = 'JAN-' + dob;
			}
			if (dob.length === 8) {
				dob = '01-' + dob;
			}
			return dob;
		}

		function isDobBeforeEventDate(dobStr, eventDateStr) {
			if(!dobStr || !eventDateStr) { return true; }
			dobStr = getFullDobString(dobStr);
			return moment(dobStr, 'DD-MMM-YYYY').isBefore(moment(eventDateStr, 'DD-MMM-YYYY'),'day');
		}

		function logError(errorData, status) {
			$log.error(`Error : ${errorData} status: ${status} `);
		}
	}
})();
