(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('UserTrackingCtrl', UserTrackingController);

	/**
	 * @ngInject
	 */
	function UserTrackingController(adminService, $filter, $log, $scope, appI18n) {

		$log = $log.getInstance("UserTrackingController");
		$log.debug("loaded");

		var vm = this;
		var today = new Date();
		var fromDate = new Date(today).setDate(today.getDate()-7);

		vm.userTrackingOptions = {
			rowDefs: {
			},
			gridRowActions: [],

			sortOrders: [{
				fieldName: 'logDateTime',
				reverseOrder: true
			}],

			preSelectedFilters: [{
				fieldName: 'logDateTime',
				displayName: 'Date/Time (UTC)',
				filterConditions: [
					{
					 displayValue: $filter('date')(fromDate, 'dd-MMM-yyyy'),
					 filterValue: $filter('date')(fromDate, 'yyyy-MM-dd'),
					 operator: 'ge',
					 type: 'date'
					},
					{
					 displayValue: $filter('date')(today, 'dd-MMM-yyyy'),
					 filterValue: $filter('date')(today, 'yyyy-MM-dd'),
					 operator: 'le',
					 type: 'date'
					}
				]
			}],

			getData: getUserTracking
		};

		function getUserTracking(options) {
				return adminService.getUserTracking(options);
		}

		function activate() {
			appI18n.translate( [
				"admin.user-tracking-title",
				"admin.user-tracking.user",
				"admin.user-tracking.email",
				"admin.user-tracking.action",
				"admin.user-tracking.action-by",
				"admin.user-tracking.role",
				"admin.user-tracking.datetime",
				"admin.user-tracking.type"
			])
			.then(function (translations) {
	            adminService.setTitle(translations["admin.user-tracking-title"]);
	            vm.userTitle = translations["admin.user-tracking.user"];
	            vm.emailTitle = translations["admin.user-tracking.email"];
	            vm.actionTitle = translations["admin.user-tracking.action"];
	            vm.actionByTitle = translations["admin.user-tracking.action-by"];
	            vm.roleTitle = translations["admin.user-tracking.role"];
	            vm.dateTitle = translations["admin.user-tracking.datetime"];
	            vm.typeTitle = translations["admin.user-tracking.type"];

	            vm.userTrackingOptions.columnDefs = [
								{displayName: vm.userTitle, fieldName: 'userName', isSortable: true, type: 'string', isDefaultFilter: true},
					{displayName: vm.emailTitle, fieldName: 'emailAddress', isSortable: true, type: 'string'},
					{displayName: vm.actionTitle, fieldName: 'action', isSortable: true, type: 'enum',
						enumType: 'string',
						enums: [
							{displayName: 'Access Granted', values: ['Access Granted']},
							{displayName: 'Access Revoked', values: ['Access Revoked']},
							{displayName: 'Role Revoke Requested', values: ['Role Revoke Requested']},
							{displayName: 'Role Revoke Rejected', values: ['Role Revoke Rejected']},
							{displayName: 'Protocol Account Enabled', values: ['Protocol Account Enabled']},
							{displayName: 'Protocol Account Disabled', values: ['Protocol Account Disabled']}
						]
					},
					{displayName: vm.actionByTitle, fieldName: 'actionBy', isSortable: true, type: 'string'},
					{displayName: vm.roleTitle, fieldName: 'roleName', isSortable: true, type: 'string'},
					{displayName: vm.dateTitle, fieldName: 'logDateTime', isSortable: true, type: 'date', formatter: 'dateTimeFormat'},
					{displayName: vm.typeTitle, fieldName: 'contactType', isSortable: true, type: 'enum',
						formatter: 'userType',
						enumType: 'string',
						enums: [
								{displayName: 'External', values: ['E']},
								{displayName: 'Internal', values: ['I']}
						]}
				];
	        });

			vm.load = true;
		}

		activate();
	}

})();
