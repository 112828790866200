/*
 * selectableRowModule
 * */

import announcementsRowDirective from './announcementsRow.directive';

const selectableRowModule = angular.module('app.common.table.selectableRow', [])
	.directive('announcementsRow', announcementsRowDirective)
	.name;

export default selectableRowModule;
