(function () {
	'use strict';

	angular.module('portalApp').directive('ertConfirmDialog', ConfirmDialog);

	/**
	 * @ngInject
	 */
	function ConfirmDialog(appI18n) {
		return {
			restrict: 'E',
			templateUrl: 'scripts/directives/dialogs/ertConfirmDialog.html',
			transclude: true,
			replace: true,
			/**
			 * Using isolated scope in directives is recommended
			 * 1. "@"   (  Text binding / one-way binding )
			 * . "="   ( Direct model binding / two-way binding )
			 * 3. "&"   ( Behaviour binding / Method binding  )
			 */
			scope: {
				handler: "=",
				dialogTitle: "@",
				yesText: "@",
				noText: "@",
				yesCssClass: "@",
				yesIconCssClass: "@",
				noCssClass: "@",
				noIconCssClass: "@",
				hideNoButton: "@",
				hideYesButton: "@",
				closeable:"@",
				size: '@'
			},
			link: function (scope, element, attributes) {
				appI18n.translate([
					scope.yesText || 'app.buttons.yes',
					scope.noText || 'app.buttons.no',
					scope.dialogTitle || 'app.dialog.confirmTitle'
				]).then(function (translations) {
					scope.options = {
						yesText: translations[scope.yesText || 'app.buttons.yes'],
						noText: translations[scope.noText || 'app.buttons.no'],
						title: translations[scope.dialogTitle || 'app.dialog.confirmTitle']
					};
					scope.$watch('handler', () => {
						if (scope.handler) {
							scope.actions = [];
							if (!scope.hideYesButton && !(
								scope.handler.actionResponse && scope.handler.actionResponse.messages)
							   ) {
								scope.actions.push(
									{ action: () => scope.handler.yesAction(),
									  displayName: scope.options.yesText,
									  isDisabled: scope.handler.disableActions, isPrimary: true } );
							}
							if (!scope.hideNoButton) {
								scope.actions.push(
									{ action: () => scope.handler.hide(),
									  displayName: scope.options.noText,
								  isDisabled: scope.handler.disableActions }
								);
							}
						}
					});
				});
			}
		};
	}
})();
