(function() {
	'use strict';

	angular
		.module('portalApp')
		.controller('SpirometryViewerCtrl', Controller);

	/**
	 * @ngInject
	 */
	function Controller($log, $state, $stateParams, $scope, spirometryService, appI18n, dataManagementService,
						appConfig, loadingIndicatorService, configurationService, clinicalDataService, $q) {
		$log = $log.getInstance("SpirometryViewerCtrl");
		$log.debug(" loaded");
		var vm = this;
		vm.siteId = $stateParams.site;
		vm.subjectId = $stateParams.subject;
		vm.eventId = $stateParams.event;
		vm.tdtId = $stateParams.tdt;
		vm.tdvId = $stateParams.tdv || 1;
		vm.$q = $q;
		activate();

		function activate() {
			const isSignatureActive = ($stateParams.signKey);
			const userStudySettings = configurationService.getUserStudySettings();
			const eventAttachments = clinicalDataService.getEventAttachments(vm.siteId, vm.subjectId, vm.eventId);
			const defers = vm.$q.all([userStudySettings, eventAttachments]);
			defers.then((resolves) => {
				vm.spirometryEventViewerConfig = getSpirometryEventViewerConfig(resolves[0].data, resolves[1].data);
				const viewerVersion = vm.spirometryEventViewerConfig.viewerVersion;
				if (viewerVersion === appConfig.constants.spiroViewerVersion.spiro2019) {
					vm.spiroViewerUrl = spirometryService.getSpiroViewerUrl(vm.siteId, vm.subjectId, vm.eventId, viewerVersion, null);
				} else {
					if (!isSignatureActive) {
						loadingIndicatorService.show();
					}
					appI18n.translate(['spiro-viewer.patientInfo']).then(function (translations) {
						const subjectCardTitle = translations['spiro-viewer.patientInfo'];

						spirometryService.getSubjectInfo(vm.siteId, vm.subjectId, vm.eventId)
							.then(response => {
								response.data.subjectCardTitle = subjectCardTitle;
								vm.spiroViewerUrl = spirometryService.getSpiroViewerUrl(
									vm.siteId, vm.subjectId, vm.eventId, viewerVersion, response.data);
							}).finally(() => {
								if (!isSignatureActive) {
									loadingIndicatorService.hide();
								}
							});
					});
				}
			}, (error) => {
				$log.error('Error while loading spirometry viewer configuration', error);
			});
			
		}

		function getSpirometryEventViewerConfig(userStudySettings, eventAttachments) {
			const spiroViewerConfigs = userStudySettings.spiroViewerConfig;
			return spiroViewerConfigs.find(config => config.testDataTypeId === vm.tdtId 
				&& (config.testDataTypeVersionId == null || config.testDataTypeVersionId == vm.tdvId) 
				&& (config.hasPaper == null || config.hasPaper == eventAttachments.length > 0));
		}
	}
})();
