import templateUrl from './qrCode.html';
import controller from './qrCode.controller';

const qrCodeComponent = {
	bindings: {
		title: '@',
		qrCode: '<'
	},
	templateUrl,
	controller
};

export default qrCodeComponent;
