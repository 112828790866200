/*
 * editContactDataPopupModule
 * */

import editContactDataPopupComponent from './editContactDataPopup.component';

const editContactDataPopupModule = angular.module('app.components.clinicalData.subjects.editContactDataPopup', [])
	.component('editContactDataPopup', editContactDataPopupComponent)
	.name;

export default editContactDataPopupModule;
