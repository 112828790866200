(function() {
	'use strict';

	angular.module('portalApp').controller('cdePftListCtrl', CdePftList);

	/*
	 * @ngInject
	 */
	function CdePftList($log, $q, $stateParams, $scope, $location, userService, 
			clinicalDataEntryService, clinicalDataEntryApiService, appI18n, signatureService, clinicalDataService,
			configurationService, clinicalDataEntryDataService, dialogService, notificationService,
			appSession, appConfig, loadingIndicatorService, documentService) {

		$log = $log.getInstance('cdePftListCtrl');
		$log.debug('loaded');

		const pftAnalysisReportDocumentId = 4062;
		const vm = this;

		vm.pftGridOptions = {
			defaultRowAction: function(row, index) {
				openPftEntry(row, index);
			},

			getData: getData
		};

		activate();

		function activate() {
			const signKey = $stateParams.signKey;
			let state;

			if (signKey) {
				state = signatureService.getState(signKey);
			}

			const defers = $q.all([
				clinicalDataEntryApiService.hasOpenTasks(),
				configurationService.getUserStudySettings(),
				userService.getUserIsInternal(),
				userService.getUserHasPermission(appConfig.businessPermissions.newDataEntry)
			]);
			defers.then(resolves => {
				vm.userIsSiteUserWithRequiredRoles = resolves[0].data;
				const userIsInternal = resolves[1].data.isInternal;
				const hasPaperEcg = resolves[1].data.studyConfig.hasPaperEcg;
				const hasPaperPft = resolves[1].data.studyConfig.hasPaperPft;
				const studyHasSiteCertification = resolves[1].data.studyConfig.hasSiteCertification;
				// Has permission to call the new data entry if an user is internal or has permissions.
				vm.hasNewDataEntryPermission = resolves[2] || resolves[3]; 

				loadGridOptions(userIsInternal, hasPaperPft, hasPaperEcg, studyHasSiteCertification);
				clinicalDataEntryService.registerRefresh(refreshGrid);
				if (state) {
					if (state.sigType === appConfig.sigType.CreateNewPaperPFT) {
						createNewPftEntry();
					} else {
						const unregister = $scope.$watch('vm.pftGridOptions.rowData', () => {
							if (vm.pftGridOptions.rowData) {
								const item = {
									id: state.id,
									studyId: state.savedData && state.savedData.studyId,
									dataType: state.savedData && state.savedData.dataType,
								};

								vm.pftGridOptions.defaultRowAction(item);
								unregister();
							}
						});
					}
				} else if (!$stateParams.popup || !$stateParams.popupParam) {
					loadingIndicatorService.hide();
				}
			}, () => {
				loadingIndicatorService.hide();
			});
		}

		function loadGridOptions(userIsInternal, hasPaperPft, hasPaperEcg, studyHasSiteCertification) {

			appI18n.translate([
				'cde-pft.grid.labels.studyName',
				'cde-pft.grid.labels.siteName',
				'app.common.subjectKey',
				'cde-pft.grid.labels.status',
				'cde-pft.grid.labels.periodName',
				'cde-pft.grid.labels.visitName',
				'cde-pft.grid.labels.visitEvent',
				'cde-pft.grid.labels.createdDate',
				'cde-pft.grid.labels.createdByName',
				'cde-pft.grid.labels.modifiedDate',
				'cde-pft.grid.labels.createEntryLabel',
				'cde-pft.grid.labels.createEcgEntryLabel',
				'cde-pft.grid.labels.createPftEntryLabel',
				'cde-pft.grid.labels.createAuditLabel',
				'cde-pft.grid.labels.modifiedByName',
				'cde-pft.grid.labels.dataEntryId',
				'cde-pft.grid.labels.OpenAllTitle',
				'cde-pft.grid.labels.OpenMyTasksTitle',
				'cde-pft.moduleLabel',
				'cde-pft.grid.labels.tatLabel',
				'cde-pft.grid.labels.additionalInfo',
				'cde-pft.grid.labels.transmittalNumber',
				'cde-pft.grid.labels.btrGrade',
				'cde-pft.grid.labels.siteCertification',
				'cde-pft.grid.labels.tatTitle',
				'cde-pft.grid.labels.noReports'
			]).then(function(translations) {
				vm.studyNameLabel = translations['cde-pft.grid.labels.studyName'];
				vm.siteNameLabel = translations['cde-pft.grid.labels.siteName'];
				vm.subjectNameLabel = translations['app.common.subjectKey'];
				vm.statusLabel = translations['cde-pft.grid.labels.status'];
				vm.periodNameLabel = translations['cde-pft.grid.labels.periodName'];
				vm.visitNameLabel = translations['cde-pft.grid.labels.visitName'];
				vm.visitEventLabel = translations['cde-pft.grid.labels.visitEvent'];
				vm.createdDateLabel = translations['cde-pft.grid.labels.createdDate'];
				vm.createdByLabel = translations['cde-pft.grid.labels.createdByName'];
				vm.modifiedDateLabel = translations['cde-pft.grid.labels.modifiedDate'];
				vm.createEntryLabel = translations['cde-pft.grid.labels.createEntryLabel'];
				vm.createEcgEntryLabel = translations['cde-pft.grid.labels.createEcgEntryLabel'];
				vm.createPftEntryLabel = translations['cde-pft.grid.labels.createPftEntryLabel'];
				vm.createAuditLabel = translations['cde-pft.grid.labels.createAuditLabel'];
				vm.OpenAllTitle = translations['cde-pft.grid.labels.OpenAllTitle'];
				vm.OpenMyTasksTitle = translations['cde-pft.grid.labels.OpenMyTasksTitle'];
				vm.modifiedByName = translations['cde-pft.grid.labels.modifiedByName'];
				vm.dataEntryIdLabel = translations['cde-pft.grid.labels.dataEntryId'];
				vm.tatLabel = translations['cde-pft.grid.labels.tatLabel'];
				vm.additionalInfoLabel = translations['cde-pft.grid.labels.additionalInfo'];
				vm.moduleLabel = translations['cde-pft.moduleLabel'];
				vm.tatTitle = translations['cde-pft.grid.labels.tatTitle'];
				vm.noReports = translations['cde-pft.grid.labels.noReports'];
				vm.pftGridOptions.sortOrders = [
					{
						fieldName: 'tatEndTime'
					}, {
						fieldName: 'remainingTatInSeconds'
					}, {
						fieldName: 'modifiedOn'
					}];
				vm.pftGridOptions.columnDefs = [
					{
						displayName: vm.dataEntryIdLabel,
						fieldName: 'id',
						isSortable: true,
						type: 'string',
						removeFromFilter: true,
						formatter: 'string',
						isDefaultFilter: false,
						isPrimary: true
					},
					{
						displayName: vm.siteNameLabel,
						fieldName: 'sponsorSiteId',
						isSortable: true,
						type: 'string',
						isDefaultFilter: false
					},
					{
						displayName: vm.subjectNameLabel,
						fieldName: 'sponsorSubjectId',
						isSortable: true,
						type: 'string',
						isDefaultFilter: false
					},
					{
						displayName: vm.periodNameLabel,
						fieldName: 'periodName',
						isSortable: true,
						type: 'string',
						isHidden: !hasPaperEcg,
						isDefaultFilter: false
					},
					{
						displayName: vm.visitNameLabel,
						fieldName: 'visitName',
						isSortable: true,
						type: 'string',
						isDefaultFilter: false
					},
					{
						displayName: vm.visitEventLabel,
						fieldName: 'eventName',
						isSortable: true,
						type: 'string',
						isDefaultFilter: false,
						suppressDefaultRowAction: true,
						formatter: 'navigateToSpirometryViewer'
					},
					{
						displayName: vm.statusLabel,
						fieldName: 'stateValue',
						isSortable: true,
						isDefaultFilter: true,
						type: 'enum',
						enumType: 'string',
						enums: getStatusValues(userIsInternal)
					},
					{
						displayName: vm.createdDateLabel,
						fieldName: 'createdOn',
						isSortable: true,
						type: 'date',
						formatter: 'dateTimeFormat',
						isDefaultFilter: false
					},
					{
						displayName: vm.createdByLabel,
						fieldName: 'createdByName',
						isSortable: true,
						type: 'string',
						isDefaultFilter: false
					},
					{
						displayName: vm.modifiedDateLabel,
						fieldName: 'modifiedOn',
						isSortable: true,
						type: 'date',
						formatter: 'dateTimeFormat',
						isDefaultFilter: false
					},
					{
						displayName: vm.modifiedByName,
						fieldName: 'modifiedByName',
						isSortable: true,
						type: 'string',
						isDefaultFilter: false
					},
					{
						displayName: vm.dataEntryIdLabel,
						fieldName: 'idString',
						type: 'string',
						showOnlyInFilter: true
					},
					{
						displayName: vm.tatLabel,
						fieldName: 'tatEndTime',
						isSortable: true,
						removeFromFilter: true,
						type: 'string',
						formatter: 'tatTimespan',
						title: vm.tatTitle
					},
					{
						displayName: vm.additionalInfoLabel,
						fieldName: 'additionalInfo',
						type: 'string',
						removeSortControls: true,
						removeFromFilter: true,
						formatter: 'lineBreak',
						cssClass: '-es-width-3'
					}
				];


				if (hasPaperPft) {
					const trlColumnDef =
						  {
							  displayName: translations['cde-pft.grid.labels.transmittalNumber'],
							  fieldName: 'transmittalNumberString',
							  type: 'string',
							  isSortable: true,
							  formatter: 'string'
						  };
					vm.pftGridOptions.columnDefs.splice(1, 0, trlColumnDef);

					const gradeColumnDef =
						  {
							  displayName: translations['cde-pft.grid.labels.btrGrade'],
							  fieldName: 'grade',
							  type: 'string',
							  isSortable: true,
						  };
					vm.pftGridOptions.columnDefs.splice(8, 0, gradeColumnDef);

					const hasSiteCertification = userIsInternal || studyHasSiteCertification;

					if (hasSiteCertification) {
						const siteCertificationColumn =
							  {
								  displayName: translations['cde-pft.grid.labels.siteCertification'],
								  fieldName: 'siteCertification',
								  type: 'string',
								  isSortable: true,
							  };
						vm.pftGridOptions.columnDefs.splice(9, 0, siteCertificationColumn);
					}
				}

				const hasSiteCertification = hasPaperPft && (userIsInternal || studyHasSiteCertification);

				var studyLabel = {
					displayName: vm.studyNameLabel,
					fieldName: 'studyName',
					isSortable: true,
					type: 'string',
					isDefaultFilter: true
				};
				if (userIsInternal) {
					const studyName = appSession.getStudy().name;

					vm.pftGridOptions.columnDefs.splice(hasPaperPft ? 2 : 1, 0, studyLabel);
					vm.pftGridOptions.preSelectedFilters = [{
						fieldName: 'studyName',
						displayName: vm.studyNameLabel,
						filterConditions: [{
							displayValue: studyName,
							filterValue: studyName,
							operator: 'eq',
							type: 'string'
						}],
					}, {
						fieldName: 'stateValue',
						displayName: 'Status',
						filterConditions: [
							{
								displayValue: vm.OpenMyTasksTitle,
								filterValue: appConfig.dataEntryState.pendingReview,
								operator: 'eq',
								enumType: 'string',
								type: 'enum'
							},
							{
								displayValue: vm.OpenMyTasksTitle,
								filterValue: appConfig.dataEntryState.pendingSiteInputReview,
								operator: 'eq',
								enumType: 'string',
								type: 'enum'
							},
							{
								displayValue: vm.OpenMyTasksTitle,
								filterValue: appConfig.dataEntryState.pendingAdditionalReview,
								operator: 'eq',
								enumType: 'string',
								type: 'enum'
							},
							{
								displayValue: vm.OpenMyTasksTitle,
								filterValue: appConfig.dataEntryState.pendingFirstEntryByErtForSiteSubmission,
								operator: 'eq',
								enumType: 'string',
								type: 'enum'
							},
							{
								displayValue: vm.OpenMyTasksTitle,
								filterValue: appConfig.dataEntryState.pendingSecondEntry,
								operator: 'eq',
								enumType: 'string',
								type: 'enum'
							},
							{
								displayValue: vm.OpenMyTasksTitle,
								filterValue: appConfig.dataEntryState.OpenMyTasks,
								operator: 'eq',
								enumType: 'string',
								type: 'enum'
							}
						]
					}];

				} else if (vm.userIsSiteUserWithRequiredRoles){

					vm.pftGridOptions.preSelectedFilters = [
						{
							fieldName: 'stateValue',
							displayName: 'Status',
							filterConditions: [
								{
									displayValue: vm.OpenMyTasksTitle,
									filterValue: appConfig.dataEntryState.OpenMyTasks,
									operator: 'eq',
									enumType: 'string',
									type: 'enum'
								},
								{
									displayValue: vm.OpenMyTasksTitle,
									filterValue: appConfig.dataEntryState.pendingSiteInput,
									operator: 'eq',
									enumType: 'string',
									type: 'enum'
								}]
						}];
				}

				if ($stateParams.popup === appConfig.popups.paperPftEntry && $stateParams.popupParam) {
					vm.pftGridOptions.preSelectedFilters = [{
						fieldName: 'idString',
						displayName: vm.dataEntryIdLabel,
						filterConditions: [{
							displayValue: $stateParams.popupParam,
							filterValue: $stateParams.popupParam,
							operator: 'eq',
							type: 'string'
						}]
					}];

					var unregister = $scope.$watch('vm.pftGridOptions.rowData', function() {
						if (vm.pftGridOptions.rowData && vm.pftGridOptions.rowData.length === 1) {
							vm.pftGridOptions.defaultRowAction(vm.pftGridOptions.rowData[0]);
							$location.search('popup', null);
							$location.search('popupParam', null);
							unregister();
						}
					});

				}

				// Actions in header row
				vm.actions = [];
				// New Data Entry action is available for a specific roles
				if (vm.hasNewDataEntryPermission == true) {
					if (hasPaperPft && hasPaperEcg) {
						vm.actions.push({
						displayName: vm.createEntryLabel,
						iconClass: 'es-icon es-icon-data-management',
						cssClass: '-es-primary',
						id: 'create-new-entry',

						subItems: [
							{
							displayName: vm.createEcgEntryLabel,
							cssClass: '-es-primary',
							iconClass: 'es-icon-cardiac',

							action: createNewEcgEntry,
							}, {
							displayName: vm.createPftEntryLabel,
							cssClass: '-es-primary',
							iconClass: 'es-icon-respiratory',
							action: createNewPftEntry,
							}]
						});
					} else if (hasPaperPft) {
						vm.actions.push({
						displayName: vm.createEntryLabel,
						iconClass: 'es-icon es-icon-data-management',
						cssClass: '-es-primary',
						action: createNewPftEntry,
						id: 'create-new-entry'
						});
					} else if (hasPaperEcg) {
						vm.actions.push({
						displayName: vm.createEntryLabel,
						iconClass: 'es-icon es-icon-data-management',
						cssClass: '-es-primary',
						action: createNewEcgEntry,
						id: 'create-new-entry'
						});
					}
				}

				// Audit Report action
				if (userIsInternal) {
					vm.actions.push({
						displayName: vm.createAuditLabel,
						iconClass: 'es-icon es-icon-reports',
						cssClass: '-es-primary',
						action: createAuditReport,
						id: 'create-new-entry'
					});
				}
				vm.load = true;
			});
			addGridRowActions();
		}

		function addGridRowActions() {

			const reportLoadingAction = [{
				iconCssClass: 'es-loading-icon ert-dropdown-content-loading'
			}];

			vm.pftGridOptions.gridRowActions = [
				{
					displayName: 'Reports',
					iconCssClass: 'es-icon es-icon-reports',
					enabledIf: (row) => {
						return row.dataType !== '1' && row.state === appConfig.paperDataEntryState.approved &&
								row.subjectId;
					},
					subItemsFactory: (row) => {
						if (!row.reports) {
							return reportLoadingAction;
						}
						return row.reports;
					},
					subItemsFactoryAction: (row) => {
						// request is sent out everytime when the user interacts with the row action.
						// therefore we need to leave the function once the row.reports array is set.
						// todo: should be refactored
						if (row.reports) {
							return row.reports;
						}

						clinicalDataService.getEventReportsAccrossStudies(
							row.studyId, row.studyModeId || appSession.getStudy().studyModeId,
							row.siteId, row.subjectId, null, row.eventId, pftAnalysisReportDocumentId)
							.then((resp) => {
								const rowReports = [];

								// The grid component doesn't handle an empty subItems array.
								// Therefore we need to add an element to show the user that no reports are available.
								if (resp.data.length === 0) {
									rowReports.push({
										displayName: vm.noReports,
										iconCssClass: 'es-icon-info'
									});
								} else {
									for (let i = 0; i < resp.data.length; i++) {
										let report = resp.data[i];

										rowReports.push(
											{
												displayName: report.documentDisplayName || report.documentTypeName,
												iconFileType: appConfig.fileTypes.pdf,
												action: () => {
													documentService.getEventReportAcrossStudies(
														row.studyId, row.studyModeId || appSession.getStudy().studyModeId,
														row.siteId, row.subjectId, report.id)
														.then((response) => {
															documentService.downloadReport(
																response.data, $scope,
																appConfig.downloadDocumentType.clinicalDataReport);
														});
												}
											});
									}
								}

								row.reports = rowReports;
								return rowReports;
							});
					}
				}
			];
		}

		function suppressDataLoad() {
			vm.pftGridOptions.getData = () => {
				return $q.resolve({
					data: []
				});
			};
		}

		function showBothEcgAndPftConfiguredErrorMessage() {
			appI18n.translate(['cde-pft.messages.bothEcgAndPftError']).then((translations) => {
				const msg = translations['cde-pft.messages.bothEcgAndPftError'];
				notificationService.showError(msg);
			});
		}

		function getStatusValues(userIsInternal) {
			var result = [
				{
					displayName: vm.OpenAllTitle,
					values: [
						appConfig.dataEntryState.pendingReview,
						appConfig.dataEntryState.pendingAdditionalReview,
						appConfig.dataEntryState.pendingSiteInputReview,
						appConfig.dataEntryState.pendingFirstEntryByErtForSiteSubmission,
						appConfig.dataEntryState.pendingSecondEntry,
						// uses same name as pendingSecondEntry, so not needed for now, but needed if state name changes:
						// appConfig.dataEntryState.pendingSecondEntryByErtForSiteSubmission,
						appConfig.dataEntryState.pendingSiteInput,
						appConfig.dataEntryState.overreadCompleted
					]
				},
				{
					displayName: vm.OpenMyTasksTitle,
					values: getMyTasksValues(userIsInternal)
				},
				{
					displayName: appConfig.dataEntryState.pendingReview,
					values: [appConfig.dataEntryState.pendingReview]
				},
				{
					displayName: appConfig.dataEntryState.pendingAdditionalReview,
					values: [appConfig.dataEntryState.pendingAdditionalReview]
				},
				{
					displayName: appConfig.dataEntryState.pendingSiteInputReview,
					values: [appConfig.dataEntryState.pendingSiteInputReview]
				},
				{
					displayName: appConfig.dataEntryState.pendingFirstEntryByErtForSiteSubmission,
					values: [appConfig.dataEntryState.pendingFirstEntryByErtForSiteSubmission]
				},
				{
					displayName: appConfig.dataEntryState.pendingSecondEntry,
					values: [appConfig.dataEntryState.pendingSecondEntry]
				},
				{
					displayName: appConfig.dataEntryState.pendingSiteInput,
					values: [appConfig.dataEntryState.pendingSiteInput]
				},
				{
					displayName: appConfig.dataEntryState.approved,
					values: [appConfig.dataEntryState.approved]
				},
				{
					displayName: appConfig.dataEntryState.rejected,
					values: [appConfig.dataEntryState.rejected]
				},
				{
					displayName: appConfig.dataEntryState.overreadCompleted,
					values: [appConfig.dataEntryState.overreadCompleted]
				}
			];

			return result;
		}

		function getMyTasksValues(userIsInternal) {
			return userIsInternal ? [
				appConfig.dataEntryState.pendingSiteInputReview,
				appConfig.dataEntryState.pendingReview,
				appConfig.dataEntryState.pendingAdditionalReview,
				appConfig.dataEntryState.pendingFirstEntryByErtForSiteSubmission,
				appConfig.dataEntryState.pendingSecondEntry,
				appConfig.dataEntryState.OpenMyTasks
			] : [appConfig.dataEntryState.OpenMyTasks, appConfig.dataEntryState.pendingSiteInput];
		}

		function getData(options, forExport) {
			return clinicalDataEntryApiService.getAll(options).then((resp) => {
				return formatAdditionalInfo(resp, forExport);
			});
		}

		function formatAdditionalInfo(resp, forExport) {
			resp.data.forEach((entry) => {
				const commentTitle = entry.commentTitle;
				const documentsRequired = (entry.documentsRequired || []).join('<br/>');
				const displayCommentLimit = 200;
				let comment = entry.comment;
				let additionalInfo = '';

				if (comment) {
					//Limit comment characters during display only
					if (!forExport && comment.length > displayCommentLimit) {
						comment = `${comment.substring(0, displayCommentLimit)} [...]`;
					}

					additionalInfo = formatComments(commentTitle, comment);
				}

				if (documentsRequired) {
					if (additionalInfo) {
						additionalInfo += '<br/>';
					}

					additionalInfo += formatComments('cde-pft.grid.labels.additionalInfoDocumentsRequested',
													 documentsRequired);
				}

				entry.additionalInfo = additionalInfo;

			});
			return resp;
		}

		function formatComments(title, comments) {
			let result = '';

			if (comments) {
				result = `
						<b>${appI18n.translateImmediate(title)}</b>:
						<br/>
						${comments}
					`;
			}
			return result;
		}

		function refreshGrid() {
			vm.reload = true;
		}

		function openPftEntry(row, index) {
			clinicalDataEntryService.showEntry(row, index);
		}

		function createNewPftEntry() {
			clinicalDataEntryService.gotoNewEntry(appConfig.cdeMode.Pft);
		}

		function createNewEcgEntry() {
			clinicalDataEntryService.gotoNewEntry(appConfig.cdeMode.Ecg);
		}

		function createAuditReport() {
			clinicalDataEntryService.showAuditReport();
		}
	}
})();
