(function() {
	'use strict';

	angular
		.module('portalApp')
		.service('ssoService', SsoService);

	/*
	 * @ngInject
	 */
	function SsoService($log, $http, $window, appSession, appConfig) {

		var epxHandler, tableauHandler, service = {
			startLoginToEpx: startLoginToEpx,
			startLoginToTableau: startLoginToTableau,
			legacyLogin: legacyLogin,
			userLogin: userLogin,
			userLogout: userLogout,
			setEpxHandler: setEpxHandler,
			setTableauHandler: setTableauHandler,
			gssoLoginInfo: gssoLoginInfo,
			navigateToStudyService,
			getSignatureRedirect,
			gotoGlobalDashboard,
			redirectToDashboardIfNotEmbedded,
			validateSignature
		};

		function isEmbeddedInNavigator() {
			if (!appConfig.gsso.useGlobalHeader) {
				return true; // not forcing to use global header or navigator in local dev env
			}

			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		}

		function navigateToStudyService(clientId, useCase, data) {
			if (window.ertGlobalHeader && window.ertGlobalHeader.navigation
				&& window.ertGlobalHeader.navigation.navigateByUseCase) {
				const study = appSession.getStudy();
				const project = {
					studyId: study.id,
					productCode: 'msp3',
					studyNumber: study.number
				};

				return window.ertGlobalHeader.navigation.navigateByUseCase(clientId, useCase, data, project);
			}
			else {
				$log.error(`Redirect failed. Header API doesn't support navigateByUseCase. clientId: ${clientId}, useCase: ${useCase}`);
			}
		}

		function redirectToDashboardIfNotEmbedded(studyId, studyNumber) {
			if (!studyId || !studyNumber) {
				return false;
			}

			if (!window.ertGlobalHeader && appConfig.gsso.useGlobalHeader) {
				const isEmbedded = isEmbeddedInNavigator();

				if (!isEmbedded) {
					const token = appSession.getSessionToken();
					const navigatorState = getNavigatorState(studyId, studyNumber, token);
					const data = encodeURIComponent(JSON.stringify(navigatorState));
					const baseUrl = appConfig.navigator.baseUrl.replace(/\/+$/, '');
					const redirectUrl = `${baseUrl}/redirect?rs=${data}`;

					$log.info(`direct navigator redirect: ${redirectUrl}`);
					window.location.href = redirectUrl;
					return true;
				}
			}
			return false;
		}

		function getNavigatorState(studyId, studyNumber, token) {
			const returnState = {};

			returnState.studyId = studyId;
			returnState.studyNumber = studyNumber;
			returnState.productCode = 'msp3';
			returnState.destinationUrl = window.location.href;

			if (token) {
				returnState.idToken = token;
			}

			$log.debug('getNavigatorState ' + JSON.stringify(returnState));
			return returnState;
		}

		function gotoGlobalDashboard() {
			if ($window.ertGlobalHeader && $window.ertGlobalHeader.redirect) {
				$window.ertGlobalHeader.redirect('navigator', 'global-dashboard');
			} else {
				var url = appConfig.auth.gssoLoginUrl;

				$log.debug(url);
				$window.location.href = url;
			}
		}

		function setEpxHandler(handler) {
			epxHandler = handler;
		}

		function setTableauHandler(handler) {
			tableauHandler = handler;
		}

		function getSignatureRedirect(redirectUri, signKey, additionalInfo) {
			const url = appConfig.apiUrls.sso.signRedirect.supplant();
			const data = additionalInfo || {};

			data.redirectUri = redirectUri;
			data.signKey = signKey;
			return $http.post(url, data);
		}

		function validateSignature(signKey) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.sso.validateSignKey.supplant({ signKey });

			return $http.get(url);
		}

		function gssoLoginInfo(studyId, token) {
			var url = appConfig.apiUrls.sso.gssoLoginInfo.supplant({'studyId': studyId});

			// this call shouldn't use the token stored in the session object but the token
			// provided by the gsso auth url
			return $http.get(url, { token });
		}

		function legacyLogin(studyId, username, password, token) {
			var url = appConfig.apiUrls.sso.legacy.supplant({'studyId': studyId, 'token': token});
			var data = {
				'username': username,
				'password': password
			};
			return $http.post(url, data);
		}

		function userLogin(username, password) {
			return $http.post(appConfig.apiUrls.sso.loginUser, {
				'username': username,
				'password': password
			});
		}

		function userLogout(username) {
			var url = appConfig.apiUrls.sso.logoutUser;
			return $http.post(url);
		}

		function startLoginToTableau() {
			loginToTableau().then(
				function(response) {
					if (tableauHandler) {
						tableauHandler.onTableauLoginSuccess(response.data);
					}
				},
				function(response) {
					if (tableauHandler) {
						tableauHandler.onTableauLoginFailure(response);
					}
				}
			);
		}

		function startLoginToEpx() {
			var dt = new Date();
			var offset = dt.getTimezoneOffset();
			loginToEpx(offset).then(
				function(response) {
					if (epxHandler) {
						epxHandler.onEpxLoginSuccess(response.data);
					}
				},
				function(response) {
					if (epxHandler) {
						epxHandler.onEpxLoginFailure(response);
					}
				}
			);
		}

		function loginToEpx(clientTimezoneOffset) {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sso.epx
					.supplant({'studyId': currentStudy.id, 'clientTimezoneOffset': clientTimezoneOffset});
			return $http.get(url);
		}

		function loginToTableau() {
			const currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.sso.tableau
					.supplant({ 'studyId': currentStudy.id });
			return $http.get(url);
		}

		return service;
	}
})();
