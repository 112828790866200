(function() {
	'use strict';

	angular
		.module("portalApp")
		.service("userService", UserService);

	/**
	 * @ngInject
	 */

	function UserService($http, $log, $q, configurationService, appSession, appConfig) {

		$log = $log.getInstance("UserService");
		$log.debug("UserService loaded");


		var vm = {
			getUserAccessLevel: getUserAccessLevel,
			getEproPermissions: getEproPermissions,
			getUserIsInternal: getUserIsInternal,
			getAvailableStudyModes: getAvailableStudyModes,
			getUserHasPermission: getUserHasPermission
		};

		return vm;

		//////////////////

		function getAvailableStudyModes() {
			var q = $q.defer();

			configurationService.getUserStudySettings().then(
				function(response) {
					q.resolve(response.data.studyConfig.studyModes);
				}
			);
			return q.promise;
		}


		function getUserAccessLevel() {
			var userAccessLevelDfd = $q.defer();
			configurationService.getUserStudySettings().then(
				function (response) {
					userAccessLevelDfd.resolve(response.data.accessLevel);
					userAccessLevelDfd = null;
				},
				function(error) {
					userAccessLevelDfd.reject(error);
					userAccessLevelDfd = null;
				});
			return userAccessLevelDfd.promise;
		}

		function getEproPermissions() {
			var q = $q.defer();

			if (appSession.getIsExpertUnavailable()) {
				q.resolve({});
			}
			else {
				configurationService.getUserStudySettings().then(
					function(response) {
						// need to double check after getting service response
						if (appSession.getIsExpertUnavailable()) {
							q.resolve({});
						}
						else {
							q.resolve(response.data.eproPermissions);
						}
					}
				);
			}
			return q.promise;
		}

		function getUserIsInternal() {
			return getUserHasPermission("Internal");
		}

		function getUserHasPermission(permissionName) {
			var q = $q.defer(); 
			configurationService.getUserStudySettings().then(function(response) {
				var hasPermission = false;
				var permissions = response.data.permissions;
				for (var i = 0; i < permissions.length; ++i) {
					if (permissions[i].id === permissionName) {
						if (appSession.getIsExpertUnavailable()) {
							hasPermission = !permissions[i].requiresExpert;
						}
						else {
							hasPermission = true;
						}
						break;
					}
				}
				q.resolve(hasPermission);
			});

			return q.promise;
		}
	}
})();
