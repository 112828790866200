/*
 * pageHeaderComponent
 * */

import templateUrl from './pageHeader.html';
import controller from './pageHeader.controller';
import './pageHeader.scss';

const pageHeaderComponent = {
	bindings: {
		heading: '@',
		buttons: '<?'
	},
	transclude: {
		subTitle: '?subTitle',
		breadcrumb: '?breadcrumb'
	},
	templateUrl,
	controller
};

export default pageHeaderComponent;
