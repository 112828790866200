const datePickerFactory = function () {

	const validators = {
		dateInPast: (value) => {
			return !value || moment(value, 'DD-MMM-YYYY').isSameOrBefore(Date.now(), 'day');
		}
	};

	return { validators };
};

export default datePickerFactory;
