var _ = require('lodash');

(function() {
	'use strict';

	angular
		.module('portalApp')
		.controller('NewEditRefMaterialFileCtrl', NewEditRefMaterialFileController);

	/**
	 *  @ngInject
	 */

	function NewEditRefMaterialFileController($log, refMatService, appConfig, $scope, $state, FileUploader, appSession,
		appI18n,documentService, loadingIndicatorService) {

		$log = $log.getInstance("NewEditRefMaterialFileController");
		$log.debug("loaded");

		var vm = this;
		vm.file = {};
		vm.multipleUpload = false;

		function activate() {
			refMatService.registerNewEditFileController(vm);
			$scope.$on('$destroy', () => {
				refMatService.registerNewEditFileController();
			});

			initFileUploader(vm);
			appI18n.translate( [
				"refmat.edit.save",
				"refmat.new.file-title",
				"refmat.edit.file-title",
				"refmat.edit.file-message",
				"refmat.edit.error-save-file",
				"refmat.edit.file-max-error",
				"refmat.edit.file-75MB-warning",
				"app.buttons.chooseMultipleFiles",
				"app.buttons.chooseSingleFile",
				'refmat.edit.file-upload',
				'refmat.edit.files-upload',
				'refmat.files',
				'refmat.file'
			])
			.then(function(translations) {
				vm.saveTitle = translations["refmat.edit.save"];
				vm.newTitle = translations["refmat.new.file-title"];
				vm.editTitle = translations["refmat.edit.file-title"];
				vm.fileError = translations["refmat.edit.file-message"];
				vm.fileSaveError = translations["refmat.edit.error-save-file"];
				vm.fileMaxSizeError = translations["refmat.edit.file-max-error"];
				vm.fileSize75MBWarning = translations["refmat.edit.file-75MB-warning"];
				vm.chooseMultipleFiles = translations["app.buttons.chooseMultipleFiles"];
				vm.chooseSingleFile = translations["app.buttons.chooseSingleFile"];
				vm.uploadFile = translations["refmat.edit.file-upload"];
				vm.uploadFiles = translations["refmat.edit.files-upload"];
				vm.filesLabel = translations["refmat.files"];
				vm.fileLabel = translations["refmat.file"];

				vm.fileActions = [{
					action: vm.addEditFile,
					displayName: vm.saveTitle,
					isPrimary: true
				}];
			});
		}
		activate();

		vm.loadRefMatFile = function (file, permission) {
			if (!permission.isAdmin && !permission.canEditFiles) {
				$state.go(appConfig.routes.refMaterials);
			}
			vm.showNewEditFile = true;
			vm.file = file;
			vm.saveClicked = false;
			vm.errorMessage = "";
			vm.fileErrorMessage = "";
			vm.fileWarning = "";
			vm.file.fileChanged = false;
			vm.newEditTitle = vm.newTitle;
			vm.dataLoading = false;
			if (vm.file.fileId > 0) {
				vm.newEditTitle = vm.editTitle;
			}
		};

		vm.showData = function() {
			vm.dataLoading = true;
			refMatService.getFile(vm.file.folderId, vm.file.fileId)
			.then(function(response) {
				documentService.downloadReport(response.data, $scope, appConfig.downloadDocumentType.refMat);
				vm.dataLoading = false;
			}, function() {
				vm.dataLoading = false;
			});
		};

		vm.updateFileChangeAll = function (fileItems) {
			vm.file.fileChanged = true;
			vm.file.files = fileItems;
		};

		vm.updateFileChange = function (fileItem) {
			vm.file.fileChanged = true;
			vm.file.fileData = fileItem;
		};

		vm.isFileInvalid = function () {
			var res = vm.saveClicked && ((vm.file.fileChanged === true && !vm.file.files) || 
				(vm.file.fileId === 0 && !vm.file.files)); 
			if (vm.file.files) {
				if (_.some(vm.file.files, (file) => {
					return file._file.size > appConfig.fileUpload.referenceMaterial.fileMaxSize;
				})) {
					vm.fileErrorMessage = vm.fileMaxSizeError;
					vm.fileWarning = '';
					res = true;
				} else if (_.some(vm.file.files, (file) => file._file.size > appConfig.fileUpload.referenceMaterial.fileSize75MB)) {
					vm.fileWarning = vm.fileSize75MBWarning;
					vm.fileErrorMessage = '';
				} else {
					vm.fileWarning = '';
					vm.fileErrorMessage = '';
				}
			} else if (res && !vm.file.files) {
				vm.fileErrorMessage = vm.fileError;
			}
			return res;
		};

		function closeFile() {
			refMatService.refreshGrid();
			vm.showNewEditFile = false;
		}
		function initFileUploader(vm) {
			vm.uploader = new FileUploader();

			vm.uploader.onProgressItem = function (fileItem, progress) {
				$log.debug(`onProgressItem file: ${fileItem.file.name} progress: ${progress}`);
			};

			vm.uploader.onSuccessItem = function (fileItem, response, status, headers) {
				$log.log(`Success ${fileItem.file.name} uploaded. Response: ${response}`);
				$log.debug('onSuccessItem', fileItem, response, status, headers);
			};

			vm.uploader.onErrorItem = function (fileItem, response, status, headers) {
				vm.errorMessage = vm.fileSaveError;
				$log.log('Error status:', response);
				$log.debug('onErrorItem', fileItem, response, status, headers);
			};

			vm.uploader.onCompleteAll = function () {
				vm.uploader.clearQueue();
				vm.dataLoading = false;
				loadingIndicatorService.hide();

				if (!vm.errorMessage) {
					closeFile();
				}
			};
			vm.uploader.onAfterAddingFile = function (fileItem) {
				vm.updateFileChange(fileItem);
			};

			vm.uploader.onAfterAddingAll = function (fileItems) {
				vm.updateFileChangeAll(fileItems);
			};
			FileUploader.FileSelect.prototype.isEmptyAfterSelection = function () {
				return true;
			};
		}
		vm.addEditFile = function() {
			vm.errorMessage = "";
			vm.saveClicked = true;
			if (vm.isFileInvalid()) {
				return;
			}
			loadingIndicatorService.show();
			var url;
			var active = vm.file.isFileActive ? "true" : "false";
			var unzip = vm.file.unzip ? "true" : "false";

			if (vm.file.fileId === 0) {
				url = appConfig.apiUrls.refMat.addfile.supplant(
		 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 
		 				'folderId': vm.file.folderId, 'isActive': active, 'unzipIfZipped': unzip });
			}
			else {
				url = appConfig.apiUrls.refMat.updatefile.supplant(
		 			{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 
		 				'fileId': vm.file.fileId, 'isActive': active, 'folderId': vm.file.folderId });
			}
			if (vm.file.fileId === 0 || vm.file.fileChanged) {
				for (var i = 0; i < vm.file.files.length; ++i) {
					var fileItem = vm.file.files[i];
					fileItem.url = url + "&token=" + appSession.getSessionToken();
					vm.uploader.uploadItem(fileItem);
				}
			}
			else {
				refMatService.updateFileStatus(vm.file)
				.then(function() {
					loadingIndicatorService.hide();
					closeFile();
				}, function() {
					loadingIndicatorService.hide();
					vm.errorMessage = vm.fileSaveError;
				});
			}
		};

		vm.getFileNames = function (files, multipleUpload) {
			const amountFiles = files.length;

			if (!amountFiles) {
				return '';
			}

			const names = files.map((f) => {
				return f.file.name;
			});

			if (multipleUpload) {
				const label = (amountFiles > 1) ? vm.filesLabel : vm.fileLabel;

				return `${amountFiles} ${label} (${names.join(', ')})`;
			}

			return names.join(', ');

		};

		vm.getUploadBtnLable = function () {
			return (vm.multipleUpload) ? vm.chooseMultipleFiles : vm.chooseSingleFile;
		};

		vm.getFileUploadLabel = function () {
			return (vm.multipleUpload) ? vm.uploadFiles : vm.uploadFile;
		};
	}

})();
