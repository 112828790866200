(function () {

	'use strict';

	angular
		.module('portalApp')
		.controller('createUserWizardCtrl', Controller);

	/**
	 * @ngInject
	 */
	function Controller($scope, $log, studyPermissionsService, studyPermissionsTrans, $stateParams,
						WizardHandler, appI18n, appConfig, notificationService,
						loadingIndicatorService, signatureService, popupService) {
		var vm = this;

		$log = $log.getInstance('CreateUserWizardCtrl');
		$log.debug('CreateUserWizardCtrl loaded');

		vm.loadingError = '';
		vm.model = {};
		vm.options = { formState: {} };
		vm.forms = {};
		vm.actions = [];
		vm.localized = {};
		vm.isWizardOpen = false;
		vm.cancel = cancel;
		vm.activate = activate;
		vm.checkUserInfo = checkUserInfo;

		studyPermissionsService.registerCreateSiteUserHandler(vm);
		$scope.$on('$destroy', () => {
			studyPermissionsService.registerCreateSiteUserHandler();
		});
		$scope.$on('wizard:stepChanged', onStepChanged);

		function getFields() {
			return angular.copy({
				userInfo: [
					{
						wrapper: 'twoColumn',
						fieldGroup: [
							{
								key: 'siteId',
								type: 'selectInline',
								wrapper: ['loading', 'validation'],
								templateOptions: {
									label: vm.localized.contact.siteId,
									options: [],
									required: true,
									onChange: function ($viewValue, $modelValue, scope) {
										var value = $viewValue || $modelValue.value();
										vm.model.selectRoles = {};
										vm.options.formState.config.siteRoles = [];
										vm.options.formState.config.hasShippingContact = false;
										vm.options.formState.rolesLoadingMessage = '';
										if (value != null && value.length > 0) {
											vm.isLoading = true;
											vm.options.formState.isRolesLoading = studyPermissionsService.getSiteRoles(value).then(function (response) {
												vm.options.formState.siteRolesLoaded = true;
												vm.options.formState.config.hasShippingContact = response.data.hasShippingContact;
												vm.options.formState.config.siteRoles = vm.options.formState.config.availableRoles.filter(function (item) {
													return _.some(response.data.roles, (r) => {
														return r.roleId === item.value;
													});;
												});
												vm.options.formState.config.hasRoleWarning = (vm.options.formState.config.siteRoles.length !== vm.options.formState.config.availableRoles.length);
											}, function (error) {
												vm.options.formState.rolesLoadingMessage = error.data.message || error.data;
											}).finally(() => {
												vm.isLoading = false;
											});
										}
									}
								},
								controller: /* @ngInject */ function ($scope) {
									if (!$stateParams.signKey && vm.model && vm.model.siteId) {
										$scope.options.templateOptions.onChange(vm.model.siteId);
									}
								},
								expressionProperties: {
									"templateOptions.options": "formState.config.studySites"
								}
							},
							{
								template: '<div class="ert-form-field"><div class="es-form-message -es-warning">{hasSiteSpecificPermissions}</div></div>'.supplant(vm.localized.messages),
								hideExpression: 'formState.config.hasSiteRoleWarning !== true'
							},
							{
								key: 'needPortalAccess',
								type: 'radioInline',
								templateOptions: {
									label: vm.localized.contact.needPortalAccess,
									required: true,
									"options": []
								},
								controller: /* @ngInject */ function ($scope) {
									if (vm.model.ispi) {
										$scope.to.options = [
											{
												"name": vm.localized.contact.portalAccessGranted,
												"value": "1"
											}];
									}
									else {
										$scope.to.options = [
											{
												"name": vm.localized.options.yes,
												"value": "1"
											},
											{
												"name": vm.localized.options.no,
												"value": "-1"
											}];
									}
								}
							},
							{
								key: 'email',
								type: 'emailInline',
								templateOptions: {
									label: vm.localized.contact.email
								},
								ngModelElAttrs: {
									maxlength: '150'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.emailIsRequired"
								}
							},
							{
								key: 'repeatEmail',
								type: 'emailInline',
								templateOptions: {
									label: vm.localized.contact.repeatEmail
								},
								ngModelElAttrs: {
									maxlength: '150'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.emailIsRequired"
								},
								validators: {
									fieldMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											return value === vm.model.email;
										},
										message: '"{emailMatch}"'.supplant(vm.localized.messages)
									}
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'titleId',
								type: 'selectInline',
								templateOptions: {
									label: vm.localized.contact.title,
									options: []
								},
								controller: /* @ngInject */ function ($scope) {
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.titleIsRequired",
									"templateOptions.options": "formState.config.titles"
								}
							},
							{
								key: 'firstName',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.firstName
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.firstNameIsRequired"
								}
							},
							{
								key: 'lastName',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.lastName
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.lastNameIsRequired"
								}
							},
							{
								key: 'country',
								type: 'selectInline',
								wrapper: ['loading', 'validation'],
								templateOptions: {
									label: vm.localized.contact.country,
									options: [],
									onChange: function ($viewValue, $modelValue, scope) {
										var value = $viewValue || $modelValue.value();
										if (value === 'US' || vm.oldCountry === 'US' ||
												value === 'CA' || vm.oldCountry === 'CA') {
											vm.model.mobile = '';
											vm.model.phone = '';
											vm.model.fax = '';
										}
										vm.oldCountry = value;
									}
								},
								controller: /* @ngInject */ function ($scope, sqfService) {
									$scope.to.loading = sqfService.getCountries().then(function (response) {
										$scope.to.options = response.data;
										vm.options.formState.config.countries = response.data;
										return response;
									}, function (data) {
										vm.loadingError = data;
									});
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.countryIsRequired"
								}
							}
						]
					},
					{
						wrapper: 'twoColumn',
						fieldGroup: [
							{
								key: 'preferredCommChannel',
								type: 'radioInline',
								templateOptions: {
									label: vm.localized.contact.communicationChannel,
									options: [
										{
											"name": vm.localized.options.email,
											"value": "Email"
										},
										{
											"name": vm.localized.options.fax,
											"value": "Fax"
										}
									]
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.communicationChannelIsRequired"
								}
							},
							{
								key: 'phone',
								type: 'inputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.phone
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.telephoneIsRequired"
								},
								hideExpression: "model.country === 'CA' || model.country === 'US'",
								validators: {
									fieldMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											if (vm.model.country === 'CA' || vm.model.country === 'US' || utility.isNullOrEmpty(value)) {
												return true;
											}
											else {
												var matcher = new RegExp(vm.options.formState.config.phonePatternGeneral);
												return matcher.test(value);
											}
										},
										message: '"{contactNumberField}"'.supplant(vm.localized.messages)
									},
									minLengthMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											if (vm.model.country === 'CA' || vm.model.country === 'US' || utility.isNullOrEmpty(value)) {
												return true;
											}
											return value.length >= 7;
										},
										message: '"{contactNumberFieldMinLength}"'.supplant(vm.localized.messages)
									}
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'phone',
								type: 'maskedInput',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.phone,
									mask: '',
									onChange: function ($viewValue, $modelValue, scope) {
										if (vm.model.country === 'US' || vm.model.country === 'CA') {
											vm.model.phone = removeUnderscore($viewValue);
										}
									}
								},
								ngModelElAttrs: {
									maxlength: '30',
									'model-view-value': 'true'
								},
								hideExpression: "model.country !== 'CA' && model.country !== 'US'",
								expressionProperties: {
									'templateOptions.mask': "formState.config.phoneMaskUS",
									'templateOptions.required': "formState.config.requiredFields.telephoneIsRequired"
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'mobile',
								type: 'inputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.mobile
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								hideExpression: "model.country === 'CA' || model.country === 'US'",
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.mobilePhoneIsRequired"
								},
								validators: {
									fieldMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											if (vm.model.country === 'CA'
													|| vm.model.country === 'US'
													|| utility.isNullOrEmpty(value)) {
												return true;
											}
											else {
												var matcher = new RegExp(vm.options.formState.config.phonePatternGeneral);
												return matcher.test(value);
											}
										},
										message: '"{contactNumberField}"'.supplant(vm.localized.messages)
									}
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'mobile',
								type: 'maskedInput',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.mobile,
									mask: '',
									onChange: function ($viewValue, $modelValue, scope) {
										if (vm.model.country === 'CA' || vm.model.country === 'US') {
											vm.model.mobile = removeUnderscore($viewValue);
										}
									}
								},
								hideExpression: "model.country !== 'US' && model.country !== 'CA'",
								ngModelElAttrs: {
									maxlength: '30',
									'model-view-value': 'true'
								},
								expressionProperties: {
									'templateOptions.mask': "formState.config.phoneMaskUS",
									'templateOptions.required': "formState.config.requiredFields.mobilePhoneIsRequired"
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'fax',
								type: 'inputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.fax
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								hideExpression: "model.country === 'US' || model.country === 'CA'",
								expressionProperties: {
									'templateOptions.required': "model.preferredCommChannel === 'Fax' || formState.config.requiredFields.faxIsRequired"
								},
								validators: {
									fieldMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											if (vm.model.country === 'US' || vm.model.country === 'CA' || utility.isNullOrEmpty(value)) {
												return true;
											}
											else {
												var matcher = new RegExp(vm.options.formState.config.phonePatternGeneral);
												return matcher.test(value);
											}
										},
										message: '"{contactNumberField}"'.supplant(vm.localized.messages)
									}
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'fax',
								type: 'maskedInput',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.fax,
									mask: '',
									onChange: function ($viewValue, $modelValue, scope) {
										if (vm.model.country === 'US' || vm.model.country === 'CA') {
											vm.model.fax = removeUnderscore($viewValue);
										}
									}
								},
								ngModelElAttrs: {
									maxlength: '30',
									'model-view-value': 'true'
								},
								hideExpression: "model.country !== 'US' && model.country !== 'CA'",
								expressionProperties: {
									'templateOptions.mask': "formState.config.phoneMaskUS",
									'templateOptions.required': "model.preferredCommChannel === 'Fax' || formState.config.requiredFields.faxIsRequired"
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'hasReportingEmail',
								type: 'checkboxInline',
								templateOptions: {
									label: vm.localized.contact.hasReportingEmail,
									checkboxLabel: ' ',
									onChange: function ($viewValue, $modelValue, scope) {
										if ($viewValue !== true) {
											vm.model.reportingEmail = '';
											vm.model.repeatReportingEmail = '';
										}
									}
								}
							},
							{
								key: 'reportingEmail',
								type: 'emailInline',
								templateOptions: {
									label: vm.localized.contact.reportingEmail
								},
								ngModelElAttrs: {
									maxlength: '150'
								},
								expressionProperties: {
									'templateOptions.disabled': "!model.hasReportingEmail",
									'templateOptions.required': "model.hasReportingEmail"
								},

								validators: {
									fieldMatchEmail: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											return value !== vm.model.email;
										},
										message: '"{reportingEmailSameAsEmail}"'.supplant(vm.localized.messages)
									}
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'repeatReportingEmail',
								type: 'emailInline',
								templateOptions: {
									label: vm.localized.contact.repeatReportingEmail
								},
								ngModelElAttrs: {
									maxlength: '150'
								},
								expressionProperties: {
									'templateOptions.disabled': "!model.hasReportingEmail",
									'templateOptions.required': "model.hasReportingEmail"
								},
								validators: {
									fieldMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											return !vm.model.reportingEmail || value === vm.model.reportingEmail;
										},
										message: '"{reportingEmailMatch}"'.supplant(vm.localized.messages)
									}
								},
								extras: {
									validateOnModelChange: true
								}
							}]
					}
				],
				userAddressRoles: [
					{
						wrapper: 'twoColumn',
						fieldGroup: [
							{
								template: '<div class="es-content-box"><div class="es-text-group"><h3>{userAddress}</h3></div></div>'.supplant(vm.localized.wizardTitles)
							},
							{
								key: 'address1',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.address1
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.address1IsRequired"
								}
							},
							{
								key: 'address2',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.address2
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {}
							},
							{
								key: 'address3',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.address3
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {}
							},
							{
								key: 'city',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.city
								},
								ngModelElAttrs: {
									maxlength: '50'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.cityIsRequired"
								}
							},
							{
								key: 'zipCode',
								type: 'inputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.postalCode,
									pattern: '',
									patternValidationMessage: ''
								},
								ngModelElAttrs: {
									maxlength: '10'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.zipIsRequired",
									'templateOptions.pattern': "model.country === 'US' ? '{usaZipCode}' : '{restrictedInputEscaped}'".supplant(appConfig.constants.patterns),
									'templateOptions.patternValidationMessage': "model.country === 'US' ? '{usaZipCodeField}' : '{restrictedInputPatternFieldEscaped}'".supplant(vm.localized.messages)
								}
							},
							{
								key: 'province',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.province
								},
								ngModelElAttrs: {
									maxlength: '50'
								},
								hideExpression: "model.country === 'US'",
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.provinceIsRequired"
								}
							},
							{
								key: 'state',
								type: 'selectInline',
								wrapper: ['loading', 'validation'],
								templateOptions: {
									type: 'text',
									label: vm.localized.contact.state,
									options: []
								},
								controller: /* @ngInject */ function ($scope, sqfService) {
									$scope.to.loading = sqfService.getUsStates().then(function (response) {
										$scope.to.options = response.data;
										return response;
									}, function (error) {
										vm.loadingError = error.data.message || error.data;
									});
								},
								hideExpression: "model.country !== 'US'",
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.stateIsRequired"
								}
							}
						]
					},
					{
						wrapper: 'twoColumn',
						fieldGroup: [
							{
								template: '<h3 class="red-star">{siteRoleAssignment}</h3>'.supplant(vm.localized.wizardTitles)
							},
							{
								key: 'selectRoles',
								type: 'checkList',
								//wrapper:['loading','validation'],
								templateOptions: {
									options: [],
									controlDivClass: '',
									//noOptionsLabel: '{loading}...'.supplant(vm.localized.common),
									required: true
								},
								controller: /* @ngInject */ function ($scope) {
									$scope.onRequired = function () {
										return !hasRoleSelected();
									};
									$scope.isLoading = function () {
										return vm.options.formState.isRolesLoading && vm.options.formState.isRolesLoading.$$state && vm.options.formState.isRolesLoading.$$state.status === 0;
									};
								},
								ngModelElAttrs: {
									'ng-required': 'onRequired()'
								},
								expressionProperties: {
									"templateOptions.options": "formState.config.siteRoles",
									"templateOptions.noOptionsLabel": "(model.siteId > 0) ? formState.rolesLoadingMessage:'{selectSite}'".supplant(vm.localized.messages)
								}
							},
							{
								template: '<div class="ert-form-field"><div class="es-form-message -es-warning">{existingShippingContact}</div></div>'.supplant(vm.localized.messages),
								//ShippingContact: 10
								hideExpression: 'formState.config.hasShippingContact !== true || model.selectRoles["10"] !== true'
							},
							{
								template: '<div class="ert-form-field"><div class="es-form-message -es-warning">{hasSiteSpecificRoles}</div></div>'.supplant(vm.localized.messages),
								hideExpression: 'formState.config.hasRoleWarning !== true'
							},
							{
								template: '<div class="ert-form-field"><div class="es-form-message -es-warning">{noSiteRoles}</div></div>'.supplant(vm.localized.messages),
								hideExpression: 'formState.config.siteRoles.length !== 0'
							}
						]
					}
				]
			});
		}

		function removeUnderscore(value) {
			if (value != null && value.length > 0) {
				return value.replace(/[\sx_]+$/g, '');
			}
			return value;
		}

		function wizard() {
			return WizardHandler.wizard("siteUserWizard");
		}

		function clear() {
			vm.model = {};
			vm.options = { formState: {} };
			vm.forms = {};
			vm.loadingError = '';
			vm.state = null;
			vm.userExists = false;
		}

		function onStepChanged(event, data) {
			if (data.step.wzTitle === vm.localized.wizardTitles.userInfo) {
				setActions(1);
			} else if (data.step.wzTitle === vm.localized.wizardTitles.userAddressRoles) {
				setActions(2);
			}
		}

		function setActions(stepIndex, reset) {
			if (vm.currentStep !== stepIndex || reset) {
				vm.actions.length = 0;
				if (stepIndex === 1) {
					vm.actions.push({
						displayName: vm.localized.buttons.next,
						action: goToNextStep,
						iconName: '',
						cssClass: '-es-primary'
					});
					vm.actions.push({
						displayName: vm.localized.buttons.cancel,
						action: cancel,
						iconName: 'close',
						cssClass: 'btn-danger'
					});
				} else if (stepIndex === 2) {
					vm.actions.push({
						displayName: vm.localized.buttons['sign-submit'], action: saveContact, cssClass: '-es-primary'
					});

					vm.actions.push({
						displayName: vm.localized.buttons.back,
						action: goToPreviousStep,
						iconName: 'chevron-left'
					});
					vm.actions.push({
						displayName: vm.localized.buttons.cancel,
						action: cancel,
						iconName: 'close',
						cssClass: 'btn-danger'
					});
				}

				vm.currentStep = stepIndex;
			}
		}

		function getSponsorSiteId(siteId) {
			if (!siteId) {
				return undefined;
			}

			const sites = vm.sites || [];
			const site = sites.find((site) => { return site.value === siteId; });

			if (site) {
				const siteParts = site.name.split(' - ');

				if (siteParts.length > 1){
					return siteParts[0];
				}
			}
			return undefined;
		}

		function closeWizard() {
			vm.isWizardOpen = false;
		}

		function cancel() {
			var isDirty = (vm.forms.userInfo && vm.forms.userInfo.$dirty) ||
				(vm.forms.userAddressRoles && vm.forms.userAddressRoles.$dirty);
			if (isDirty) {

				popupService.show('confirmation', {
					headerTitle: appI18n.translateImmediate('app.dialog.confirmTitle'),
					contentMsg: appI18n.translateImmediate('app.messages.unsavedChanges')
				}).then((resp) => {
					if (resp.answer === 'ok') {
						closeWizard();
					} else {
						// just close confirmation
					}
				}, (error) => {
					$log.error(`Error: ${error}`);
				});
			} else {
				closeWizard();
			}
		}

		function hasRoleSelected() {
			if (vm.model.selectRoles != {}) {
				if (vm.options.formState.config.availableRoles.some(function (item) {
					var role = vm.model.selectRoles[item.value];
					return !!role;
				})) {
					return true;
				}
			}
			return false;
		}

		function checkUserInfo() {
			if (!vm.forms.userInfo.$valid) { return false; }

			vm.isLoading = true;
			return studyPermissionsService.checkSiteUserAlreadyExists(vm.model.siteId, vm.model.email)
				.then((userExists) => {
					vm.userExists = userExists;
					return !userExists;
				}).finally(() => {
					vm.isLoading = false;
				});
		}

		function getSelectedRoles() {
			var roles = [];
			if (vm.model.selectRoles != {}) {
				vm.options.formState.config.availableRoles.forEach(function (item) {
					var role = vm.model.selectRoles[item.value];
					if (role) {
						roles.push(item.value);
					}
				});
			}
			return roles;
		}

		function goToPreviousStep() {
			wizard().previous();
		}

		function goToNextStep() {
			if (vm.currentStep === 1) {
				if (!vm.forms.userInfo.$valid) {
					vm.forms.userInfo.$setSubmitted();
				} else {
					wizard().next();
					return true;
				}
				return false;
			} else if (vm.currentStep === 2) {
				if (!vm.forms.userAddressRoles.$valid) {
					vm.forms.userAddressRoles.$setSubmitted();
					return false;
				}
				return true;
			}
		}

		function setupSignature() {
			vm.state = vm.model;
			vm.state.options = vm.options;
			vm.state.forms = vm.forms;
			vm.sigType = appConfig.sigType.createUser;
			vm.additionalInfo = {
				action: appI18n.translateImmediate('signature.action.createSiteUser'),
				infoKey1: appI18n.translateImmediate('signature.info.site'),
				infoValue1: getSponsorSiteId(vm.model.siteId),
				infoKey2: appI18n.translateImmediate('signature.info.userMail'),
				infoValue2: vm.model.email
			};
		}

		function saveContact() {
			if (!goToNextStep()) { return; }
			setupSignature();
			signatureService.sign(vm.sigType, vm.additionalInfo, vm.state);
		}

		function submitContact() {

			vm.model.roles = getSelectedRoles();
			vm.model = vm.state;
			vm.model.signKey = $stateParams.signKey;
			vm.model.signToken = $stateParams.tokenId;

			studyPermissionsService.createContact(vm.model).then((response) => {
				if (response.data.isSuccessful) {
					signatureService.removeStateParams();
					notificationService.showSuccess(
						appI18n.translateImmediate('study-permissions.messages.createdSiteUser'));
				} else {
					const error = response.data.messages;

					handleError(error);
				}
			}, (error) => {
				const msgs = [error.data.message || error.data];

				handleError(msgs);
			});
		}

		function activate() {
			clear();

			studyPermissionsTrans.getWizardLocalized().then((localized) => {
				loadingIndicatorService.show();
				vm.localized = localized;
				vm.modalTitle = vm.localized.wizardTitles.createUserWizard;
				vm.fields = getFields();

				studyPermissionsService.getContactConfig().then((response) => {
					vm.model = response.data.model;
					vm.model.selectRoles = {};
					vm.options.formState.config = response.data.config;
					vm.sites = response.data.config.studySites;

					if ($stateParams.signKey) {
						vm.state = signatureService.getState($stateParams.signKey);
						if (!vm.state || vm.state && vm.state.sigType !== appConfig.sigType.createUser) {
							vm.isWizardOpen = true;
							return;
						}
					}

					if ($stateParams.signKey) {
						const unbindState = $scope.$watch(() => {
							return !!vm.state;
						}, () => {
							vm.state = signatureService.getState($stateParams.signKey);
							if (vm.state) {
								signatureService.removeState($stateParams.signKey);

								vm.model = vm.state;
								vm.options = vm.state.options;
								unbindState();

								if ($stateParams.tokenId) {
									submitContact();
								}

							}
						});

						signatureService.validateSignature($stateParams.tokenId, $stateParams.signKey);

						if (!$stateParams.tokenId) {
							const unbind = $scope.$watch(() => {
								return !!wizard() && !!vm.state;
							}, () => {
								const wz = wizard();

								if (wz && vm.state) {
									wz.goTo(wz.totalStepCount() - 1);
									unbind();
								}
								vm.isWizardOpen = true;
							});
						}
						return;
					}

					vm.isWizardOpen = true;
				}).catch((error) => {
					handleError(error);
				}).finally(() => {
					loadingIndicatorService.hide();
				});
			});
		}

		function handleError(error) {
			notificationService.showError(appI18n.translateImmediate('app.toaster.tryLaterError'));
			$log.error(JSON.stringify(error));
		}
	}
})();
