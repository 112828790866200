/*
 * downloadsController
 * */

class DownloadsController {
	constructor($log, $scope, $state, appConfig, appI18n, reportsService,
	notificationService, documentService, popupService, appSession, loadingIndicatorService) {
		'ngInject';
		this.$log = $log;
		this.$scope = $scope;
		this.$state = $state;
		this.appConfig = appConfig;
		this.popupService = popupService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.reportsService = reportsService;
		this.notificationService = notificationService;
		this.documentService = documentService;
		this.appSession = appSession;
		this.appI18n = appI18n;
		this.tabs = [];
	}

	$onInit() {
		this.$log = this.$log.getInstance('DownloadsController');
		this.$log.debug('loaded');
		this.numberOfDownloads = this.appSession.getNumberOfDownloads();
		this.load = true;

		this.appI18n.translate([
			'reports.report-review.columns.report-type',
			'reports.report-review.columns.report-name',
			'reports.report-review.columns.reportVersion',
			'reports.report-review.columns.sponsor-site-id',
			'app.common.subjectKey',
			'reports.report-review.columns.visit-event-name',
			'my-downloads.title',
			'my-downloads.popup.errorHint',
			'my-downloads.popup.description',
			'my-downloads.popup.title',
			'data-management-files.tabName'
		]).then((translations) => {
			const self = this;

			self.translations = translations;

			self.options = {
				columnDefs: [{
					translationKey: 'my-downloads.columns.name',
					fieldName: 'fileName',
					isSortable: true,
					type: 'string'
				}, {
					translationKey: 'my-downloads.columns.status',
					fieldName: 'statusId',
					isSortable: true,
					type: 'enum',
					formatter: 'downloadStatus',
					enumType: 'number',
					enums: [{
						displayName: 'Pending',
						values: [0]
					}, {
						displayName: 'In Progress',
						values: [1]
					}, {
						displayName: 'Done',
						values: [2]
					}, {
						displayName: 'Failed',
						values: [3]
					}, {
						displayName: 'Canceled',
						values: [4]
					}, {
						displayName: 'Partially Done',
						values: [5]
					}]
				}, {
					translationKey: 'my-downloads.columns.requestDate',
					fieldName: 'requestDateTime',
					isSortable: true,
					type: 'date',
					formatter: 'dateTimeFormat'
				}, {
					translationKey: 'my-downloads.columns.updateDate',
					fieldName: 'completionDateTime',
					isSortable: true,
					type: 'date',
					formatter: 'dateTimeFormat'
				}, {
					translationKey: 'my-downloads.columns.id',
					fieldName: 'queueId',
					isSortable: true,
					type: 'id'
				}],
				sortOrders: [
					{
						fieldName: 'queueId',
						reverseOrder: true
					}
				],
				rowDefs: {
					identifier: 'queueId'
				},
				gridRowActions: [{
					displayName: self.translations['my-downloads.popup.title'],
					iconCssClass: 'es-icon-info-circle',
					enabledIf: (row) => {
						return row.typeId === '1';
					},
					action: (row) => {
						self.loadingIndicatorService.show();
						self.documentService.getEventReportDownloadInfo(row.queueId)
							.then((response) => {

								const headers = [
									{ header: self.translations['reports.report-review.columns.report-type'] },
									{ header: self.translations['reports.report-review.columns.report-name'] },
									{
										header: self.translations['reports.report-review.columns.reportVersion'],
										cssClass: 'es-data-number'
									},
									{ header: self.translations['reports.report-review.columns.sponsor-site-id'] },
									{ header: self.translations['app.common.subjectKey'] },
									{ header: self.translations['reports.report-review.columns.visit-event-name'] }
								];
								const rows = [];
								const columns = {};

								response.data.forEach((el, i) => {
									rows.push({ id: i });
									if (el.hasError) {
										rows[i].cssClass = '-es-selected has-error';
									}
									columns[i] = [
										{ data: el.testDataTypeName },
										{ data: el.documentName },
										{ data: el.versionNumber, cssClass: 'es-data-number' },
										{ data: el.sponsorSiteId },
										{ data: el.subjectKey },
										{ data: `${el.visitName} / ${el.eventName}` }
									];
								});

								let description = self.translations['my-downloads.popup.description'];

								if (_.some(response.data, { 'hasError': true })) {
									description += self.translations['my-downloads.popup.errorHint'];
								}

								self.popupService.show('table', {
									helpModuleId: 'MSP3.MyDownloads',
									headerTitle: self.translations['my-downloads.popup.title'],
									description,
									headers,
									rows,
									columns
								});
							})
							.finally(() => {
								self.loadingIndicatorService.hide();
							});
					}
				}, {
					displayName: 'Reports',
					iconCssClass: 'es-icon es-icon-reports',
					enabledIf: (row) => {
						return row.statusId === '2' || row.statusId === '5';
					},
					subItemsFactory: (row) => {
						if (row.myDownloadsReport) {
							return row.myDownloadsReport;
						}
						const myDownloadsReport = [];

						const fileFormat = this.getFileFormatName(row.reportPath);

						myDownloadsReport.push({
							displayName: row.documentName || row.fileName,
							iconFileType: fileFormat,
							action: () => {
								self.documentService.getMyDownloadsDocument([row.queueId])
									.then((response) => {
										self.documentService.downloadReport(
											response.data, self.$scope,
											self.appConfig.downloadDocumentType.myDownload,
											1, row.documentName, fileFormat);
									});
							}
						});

						row.myDownloadsReport = myDownloadsReport;

						return myDownloadsReport;
					}
				}],
				autoUpdateSeconds: 60,

				getData: (options) => {
					if (angular.isUndefined(self.numberOfDownloads)) {
						self.numberOfDownloads = self.appSession.getNumberOfDownloads();
					}
					if (self.numberOfDownloads < self.appSession.getNumberOfDownloads()) {
						self.numberOfDownloads = self.appSession.getNumberOfDownloads();
						self.notificationService.showInfo(
							self.appI18n.translateImmediate(
								'my-downloads.fileReadyMyDownloads'));
					}
					return self.documentService.getEventReportDownloads(options);
				}
			};
		});
	}

	getFileFormatName(fileName) {
		if (!fileName) {
			return '';
		}
		if (fileName.indexOf('.zip') >= 0) {
			return this.appConfig.fileTypes.zip;
		} else if (fileName.indexOf('.xlsx') >= 0) {
			return this.appConfig.fileTypes.xlsx;
		}
		return this.appConfig.fileTypes.pdf;
	}
}

export default DownloadsController;
