/*
 * numberRangeModule
 * */

import numberRangeComponent from './numberRange.component';

const numberRangeModule = angular.module('app.common.filters.numberRange', [])
	.component('numberRange', numberRangeComponent)
	.name;

export default numberRangeModule;
