/*
 * badgeComponent
 * */

import templateUrl from './badge.html';
import './badge.scss';

const badgeComponent = {
	bindings: {
		content: '@',
		iconClass: '@',
		cssClass: '@'
	},
	templateUrl
};

export default badgeComponent;
