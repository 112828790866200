import templateUrl from './popupHeader.html';
import controller from './popupHeader.controller';
import './popupHeader.scss';

const popupHeaderComponent = {
	bindings: {
		cancel: '&?',
		heading: '@',
		helpModuleId: '@?',
		iconActions: '<?',
		showPrint: '<?'
	},
	require: {
		popup: '^ertPopup'
	},
	templateUrl,
	controller
};

export default popupHeaderComponent;
