/*
 * globalMessageComponent
 * */

import templateUrl from './globalMessage.html';
import controller from './globalMessage.controller';
import './globalMessage.scss';

const globalMessageComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default globalMessageComponent;
