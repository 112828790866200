import confirmationPopupController from '../../../common/popup/confirmation/confirmationPopup.controller';
class InfoPopupController extends confirmationPopupController {
	constructor(popupService, appI18n) {
		'ngInject';
		super();
		this.srv = popupService;
		this.appI18n = appI18n;
		this.popupName = 'table';
	}
}

export default InfoPopupController;
