import templateUrl from './controlErrors.html';
import controller from './controlErrors.controller';
import './controlErrors.scss';

const controlErros = {
	bindings: {
		messages: '<',
		show: '<?'
	},
	transclude: true,
	templateUrl,
	controller
};

export default controlErros;
