import templateUrl from './select.html';
import controller from './select.controller';
import './select.scss';

const ertSelect = {
	bindings: {
		name: '@',
		options: '<', //TODO: deprecate this parameter, see: https://crucible.ert.com/crucible/cru/CR-MPORT-4280#CFR-500139
		disable: '<',
		data: '<',
		onChange: '&',
		setValue: '<?',
		notifySetValue: '<?',
		hintsConfig: '<?',
		hints: '<?'
	},
	transclude: true,
	require: {
		fg: '^form'
	},
	templateUrl,
	controller
};

export default ertSelect;
