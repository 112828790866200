/*
 * QueryGuideline Component
 * */

import templateUrl from './queryGuideline.html';
import controller from './queryGuideline.controller';

const queryGuidelineComponent = {
	bindings: {
		item: '<'
	},
	templateUrl,
	controller
};

export default queryGuidelineComponent;
