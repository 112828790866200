/*
 * breadcrumbsDropdownClickModule
 * */

import breadcrumbsDropdownClickDirective from './breadcrumbsDropdownClick.directive';

breadcrumbsDropdownClickDirective.$inject = ['$document'];

const breadcrumbsDropdownClickModule =
	  angular.module(
		  'app.components.dashboard.visitOverviewWidget.visitCalendarPopup.breadcrumbsDropdownClick',
		  [])
	.directive('breadcrumbsDropdownClick', breadcrumbsDropdownClickDirective)
	.name;

export default breadcrumbsDropdownClickModule;
