import templateUrl from './frame.html';
import controller from './frame.controller';
import './frame.scss';

const frameComponent = {
	bindings: {
		title: '@'
	},
	templateUrl,
	controller
};

export default frameComponent;
