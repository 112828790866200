import templateUrl from './myDeviceAccount.html';
import controller from './myDeviceAccount.controller';
import './myDeviceAccount.scss';

const myDeviceAccount = {
	bindings: {
		componentReady: '&'
	},
	controller,
	templateUrl
};

export default myDeviceAccount;

