/*
 * selectableRowDirective
 * */

import templateUrl from './announcementsRow.html';
import controller from './announcementsRow.controller';
import './announcementsRow.scss';

const selectableRowDirective = () => {
	return {
		scope: {
			isSelected: '<',
			row: '<'
		},
		require: {
			table: '^tableWrap'
		},
		controller,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		replace: true
	};
};

export default selectableRowDirective;
