import templateUrl from './ertCard.html';
import controller from './ertCard.controller';
import './ertCard.scss';

const ertCard = {
	bindings: {
		header: '@',
		mediumSize: '<?',
		cssClass: '@?',
		sameHeightGroupName: '@?'
	},
	transclude: true,
	templateUrl,
	controller
};

export default ertCard;

