(function () {
	'use strict';

	angular.module('portalApp').controller('LoginCtrl', LoginCtrl);

	/**
	 * Controller to handle login view
	 *
	 * @ngInject
	 */
	function LoginCtrl($log, $state, $rootScope, $scope, $window,
		appConfig, appI18n, appSession, authenticator, dialogService,
		studySelectService, emailConfirmationService, accountService, ssoService) {

		$log = $log.getInstance('LoginCtrl', 'color:#021b41; font-size:1em; background-color:#dedede;');
		$log.debug('loaded');

		var vm = this;
		vm.username = '';
		vm.password = '';

		vm.hasError = false;
		vm.errorMessage = '';
		vm.dataLoading = false;
		vm.selectedStudy = null;
		vm.confirmStudyHandler =true;
		vm.noStudyHandler =true;

		vm.showChangePassword = showChangePassword;
		vm.showResetPassword = showResetPassword;
		vm.doResetPassword = doResetPassword;
		vm.showResendConfirmation = showResendConfirmation;
		vm.showLoginHelp = showLoginHelp;
		vm.onHideLoginHelp = onHideLoginHelp;
		vm.onHideRegistration = onHideRegistration;
		vm.onHideStudySelection = onHideStudySelection;
		vm.onSelectStudy = onSelectStudy;
		vm.submitResend = submitResend;
        vm.hideWarning = hideWarning;

		// -------------------------------------------
		// start: site registration
		// -------------------------------------------

		$scope.form = {};
		vm.initSiteRegistrationModel = function () {
			vm.siteRegistration = {
				isFormVisible: false,
				formTitle: 'New Site Registration',
				isLoading: false,
				serviceResult: {},
				serviceError: {},
				errorMsg: '',
				currentStep: 'register',
				formModel: {
					email: '',
					firstName: '',
					lastName: '',
					qualificationPin: ''
				}
			};
		};

		vm.initSiteRegistrationModel();

		activate();

		function activate() {
			appI18n.translate('login.select-study.title').then(function (translation) {
				vm.studySelectionTitle = translation;
			});

			vm.studySelectionActions = [
				{action: onSelectStudy, isDisabled: true, displayName: 'login.select-study.button-select'}
			];

			$scope.$watch('vm.selectedStudy', onSelectedStudyChanged);

			var existingSession = appSession.get();

            //Display Login warning
            vm.showWarning = true;

			if (existingSession) {
				showStudySelection();
			}
		}

		function onSelectedStudyChanged(val) {
			vm.studySelectionActions[0].isDisabled = (!val || val.length === 0);
		}

		function onSelectStudy() {
			if (vm.selectedStudy) {
				selectStudy(vm.selectedStudy);
			}
		}

    function showResendConfirmation() {
      initResendConfirmationForm();
      vm.resendConfirmation.isFormVisible = true;
    }

		vm.initEmailConfirmationForm = function () {
			vm.emailConfirmation = {
				isFormVisible: false,
				formTitle: 'Confirm Email',
				userCanLoginToPortal: false,
				isLoading: false,
				serviceResult: {},
				serviceError: {},
				errorMsg: '',
				currentStep: 'loading',
				isTokenFromUrl: false,
				isManualConfirmation: false,
				isTempPasswordEnabled: false,
				newPassword: '',
				newPasswordConfirmation: '',
				formModel: {
					email: '',
					password: '',
					token: '',
					newPassword: '',
					newPasswordConfirmation: ''
				}
			};
		};

		vm.initEmailConfirmationForm();

		vm.showEmailConfirmation = function () {
			vm.initEmailConfirmationForm();
			vm.emailConfirmation.isFormVisible = true;
		};

		function showSiteRegistration() {
			vm.initSiteRegistrationModel();
			vm.siteRegistration.isFormVisible = true;
			if ($scope.form.registrationForm) {
				$scope.form.registrationForm.$setPristine();
				$scope.form.registrationForm.$setUntouched();
			}
		}

		vm.submitRegistration = function () {
			vm.siteRegistration.isLoading = true;
			vm.siteRegistration.errorMsg = '';

			var email = vm.siteRegistration.formModel.email;
			var firstName = vm.siteRegistration.formModel.firstName;
			var lastName = vm.siteRegistration.formModel.lastName;
			var qualificationPin = vm.siteRegistration.formModel.qualificationPin;

			emailConfirmationService.preStartRegistration(email, firstName, lastName, qualificationPin)
				.then(function (response) {
					vm.siteRegistration.isLoading = false;
					if (response.data.isValid)
					{
						vm.studyName = response.data.message;
						vm.studyConfirmHandler = dialogService.createConfirmHandler();
						vm.studyConfirmHandler.show().then(function (result) {
							vm.studyConfirmHandler.hide();
							if(result){
								vm.doSubmitRegistration(email, firstName, lastName, qualificationPin)
							}
							else {
								showStudyIncorrect();
							}
						});
					}
					else {
						vm.siteRegistration.errorMsg = response.data.message;
					}
				}, function (response) {
					vm.siteRegistration.serviceError = response.data;
					$log.error('Error starting email registration for email: \'{0}\', firstName: \'{1}\', lastName: \'{2}\', qualificationPin: \'{3}\', error: \'{4}\'',
						email, firstName, lastName, qualificationPin, vm.siteRegistration.serviceError);

					vm.siteRegistration.isLoading = false;
				});
		};

		vm.doSubmitRegistration = function (email, firstName, lastName, qualificationPin) {
			vm.siteRegistration.isLoading = true;
			vm.siteRegistration.errorMsg = '';

			emailConfirmationService.startRegistration(email, firstName, lastName, qualificationPin)
				.then(function (response) {
					vm.siteRegistration.serviceResult = response.data;
					vm.siteRegistration.isLoading = false;
					vm.siteRegistration.errorMsg = response.data.message;

					if (response.data.isValid) {
						vm.siteRegistration.currentStep = 'mailsent';
						vm.siteRegistration.formTitle = 'Next Step: Check for new email from Clario';
					}
				}, function (response) {
					vm.siteRegistration.serviceError = response.data;
					$log.error('Error starting email registration for email: \'{0}\', firstName: \'{1}\', lastName: \'{2}\', qualificationPin: \'{3}\', error: \'{4}\'',
						email, firstName, lastName, qualificationPin, vm.siteRegistration.serviceError);

					vm.siteRegistration.isLoading = false;
				});
		};

		// -------------------------------------------
		// end: site registration
		// -------------------------------------------

		// -------------------------------------------
		// start: email confirmation
		// -------------------------------------------

		function showStudyIncorrect() {
			vm.studyIncorrectHandler = dialogService.createConfirmHandler();
			vm.studyIncorrectHandler.show().then(function () {
				vm.studyIncorrectHandler.hide();
			});
		}

		vm.confirmManually = function () {

			vm.emailConfirmation.isLoading = true;

			vm.emailConfirmation.isTokenFromUrl = false;
			vm.emailConfirmation.isManualConfirmation = true;
			vm.emailConfirmation.isTempPasswordEnabled = true;

			vm.emailConfirmation.isLoading = false;
			vm.emailConfirmation.currentStep = 'confirm';
		};

		vm.confirmFromToken = function (token) {
			//$log.debug("submit");
			//$log.debug(JSON.stringify(vm.model));

			vm.emailConfirmation.isLoading = true;

			emailConfirmationService.loadRegistration(token)
				.then(function (response) {
					vm.emailConfirmation.serviceResult = response.data;
					//$log.debug(JSON.stringify(result));

					vm.emailConfirmation.isLoading = false;

					// if token is empty -> do not try to load data from server
					// allow input in email address, token and temp password

					if (response.data.isValid) {
						vm.emailConfirmation.formModel.email = response.data.confirmationEmail;
						vm.emailConfirmation.formModel.token = token;

						vm.emailConfirmation.isTokenFromUrl = !!vm.emailConfirmation.formModel.token; // hide token input field if token is defined already
						vm.emailConfirmation.isManualConfirmation = !(vm.emailConfirmation.formModel.email);
						vm.emailConfirmation.isTempPasswordEnabled = true;

						if (response.data.userIsAlreadyConfirmed) {
							vm.emailConfirmation.userCanLoginToPortal = response.data.userCanLoginToPortal;

							if (response.data.userCanLoginToPortal) {
								vm.emailConfirmation.confirmedUser = vm.emailConfirmation.formModel.email;
							}
							vm.emailConfirmation.currentStep = 'alreadyConfirmed';
							vm.emailConfirmation.formTitle = 'Already Confirmed';
						} else if (response.data.confirmationSucceeded) {
							vm.emailConfirmation.currentStep = 'confirmed';
							vm.emailConfirmation.formTitle = 'Confirmed';
						} else {
							vm.emailConfirmation.currentStep = 'confirm';
						}
					}
					else {
						vm.emailConfirmation.errorMsg = response.data.message;
						vm.emailConfirmation.currentStep = 'error';
					}
				}, function (response) {
					vm.emailConfirmation.serviceError = response.data;
					$log.error('Error loading registration data for token: \'{0}\', error: \'{1}\'', token, vm.emailConfirmation.serviceError);

					vm.emailConfirmation.isLoading = false;
					vm.emailConfirmation.currentStep = 'error';
				});
		};

		vm.submitConfirmation = function () {

			vm.emailConfirmation.errorMsg = '';
			vm.emailConfirmation.isLoading = true;

			var email = vm.emailConfirmation.formModel.email;
			var token = vm.emailConfirmation.formModel.token;
			var password = vm.emailConfirmation.formModel.password;

			emailConfirmationService.confirmRegistration(email, token, password)
				.then(function (response) {
					vm.emailConfirmation.serviceResult = response.data;

					vm.emailConfirmation.isLoading = false;

					if (response.data.isValid) {
						vm.emailConfirmation.userCanLoginToPortal = response.data.userCanLoginToPortal;
						if (response.data.userCanLoginToPortal) {
							vm.emailConfirmation.confirmedUser = vm.emailConfirmation.formModel.email;
						}
						vm.emailConfirmation.userShouldChangePassword = response.data.userShouldChangePassword;
						vm.emailConfirmation.currentStep = 'confirmed';
					}
					else {
						vm.emailConfirmation.errorMsg = response.data.message;
					}
				}, function (response) {
					vm.emailConfirmation.serviceError = response.data;
					$log.error('Error confirming email registration for email: \'{0}\', token: \'{1}\', error: \'{2}\'', email, token, vm.emailConfirmation.serviceError);

					vm.emailConfirmation.isLoading = false;
					vm.emailConfirmation.currentStep = 'error';
				});

		};

		if ($state.params.registerMode) {
			showSiteRegistration();
		}

		if ($state.params.helpMode) {
			vm.showLoginHelp();
		}

		if ($state.params.resendMode) {
			vm.showResendConfirmation();
		}

    if ($state.params.resetPasswordMode) {
      vm.showResetPassword();
    }

		if ($state.params.confirmMode) {
			$log.debug('confirmmode');
			vm.showEmailConfirmation();
			if ($state.params.t) {
				vm.confirmFromToken($state.params.t);
			}
			else {
				vm.confirmManually();
			}
		}

		if ($state.params.prefillUserName) {
			vm.username = $state.params.prefillUserName;
		}

		vm.onHideEmailConfirmation = function () {
			goToLogin(vm.emailConfirmation.confirmedUser);
		};

		function initResendConfirmationForm() {
			vm.resendConfirmation = {
				isFormVisible: false,
				customerCare: appConfig.external.customerCare,
				isLoading: false,
				serviceError: null,
				currentStep: 'email',
				formModel: {
					email: ''
				}
			};
		}

		function submitResend() {
			vm.resendConfirmation.isLoading = true;

			var email = vm.resendConfirmation.formModel.email;

			emailConfirmationService.resendConfirmation(email).then(
				function () {
					vm.resendConfirmation.isLoading = false;
					vm.resendConfirmation.currentStep = 'mailsent';
				},
				function () {
					vm.resendConfirmation.serviceError = true;
					vm.resendConfirmation.isLoading = false;
				}
			 );
		}
		// -------------------------------------------
		// end: email confirmation
		// -------------------------------------------

		// default filter predicate means 'by all columns'
		vm.studiesFilter = '$';
		// filter predicates definition. Please not it hard coded to be aligned with PortalApi response
		vm.studiesSearch = {name: '', number: '', sponsorName: '', $: ''};

		/**
		 * Studies filtering after Apply. Changes filter predicate when user changes filter column/type
		 */
		vm.changeStudiesFilterTo = function (predicate) {
			vm.studiesFilter = predicate;
		};

		/**
		 * Studies filtering after Apply. On apply button clicked
		 */
		vm.studiesFilterApply = function (filterToApply) {
			// Reset old filter at first
			vm.studiesSearch = {name: '', number: '', sponsorName: '', $: ''};
			// Apply new filters
			vm.studiesSearch[vm.studiesFilter] = filterToApply;
		};

		vm.login = function () {
			$log.debug('login( \'{username}\' )', vm);
			vm.dataLoading = true;
			loginUser(vm.username, vm.password);
		};

		function loginUser(username, password) {
			authenticator
				.login(username, password)
				.then(
					function (result) {

						$log.debug('login_onResult( \'{email}\' )', result);

						vm.hasError = false;
						vm.errorMessage = '';
						if ($rootScope.returnState && $rootScope.returnParams && $rootScope.returnParams.study) {
							var studyId = $rootScope.returnParams.study;
							var studyModeId = $rootScope.returnParams.mode;
							$log.debug('studyId from returnState: ' + studyId);
							var knownStudy = appSession.getStudyFromRecentStudies(studyId);
							if (knownStudy) {
								knownStudy.studyModeId = studyModeId;
								appSession.publishStudy(knownStudy);
								$log.debug('study retrieved from recentStudies, go');
								goToSavedState();
								return;
							}
							else {
								$log.debug('get saved study from api');
								studySelectService.getStudies().then(function (result) {
									for (var i = 0; i < result.length; ++i) {
										if (result[i].protocolId === studyId) {
											var appSessionStudy = appSession.studyDto2SessionStudy(result[i]);
											appSessionStudy.studyModeId = studyModeId;
											appSession.publishStudy(appSessionStudy);
											goToSavedState();
											$log.debug('user has access to saved study, go');
											return;
										}
									}
									$log.debug('user has No access to saved study, showStudySelection');
									showStudySelection();
								});
							}
						}
						else {
							showStudySelection();
						}

						$log.debug('login end');
					},
					function (message, status) {
						vm.hasError = true;
						var errorReason;
						if (message) {
							errorReason = message.error_description;
						}
						else {
							errorReason = 'Failure';
						}

						var errorMessageKey = 'login.messages.generic';

						if (errorReason) {
							if (errorReason === 'Failure') {
								errorMessageKey = 'login.messages.auth-failed';
							}
							else if (errorReason === 'UserUnconfirmed') {
								errorMessageKey = 'login.messages.unconfimed';
							}
							else if (errorReason === 'UserLocked') {
								errorMessageKey = 'login.messages.locked';
							}
							else if (errorReason === 'UserHasNoRoles') {
								errorMessageKey = 'login.messages.no-studies';
							}
							else if (errorReason === 'TempPassword' || errorReason === 'UserPasswordExpired') {
								errorMessageKey = '';
								showChangePassword(vm.username, vm.password);
							}
						}
						setError(errorMessageKey);
					});
		}

		function setError(translationKey) {

			if (translationKey) {
				appI18n.translate(translationKey).then(function (translation) {
					vm.errorMessage = translation;
					vm.hasError = true;
				});
				vm.dataLoading = false;
			}
			else {
				vm.errorMessage = '';
				vm.hasError = false;
			}
		}

		function selectStudy(study) {
			vm.studySelected = true;
			if (+study.portalVersion === 3) {
				loadLegacyStudy(study.protocolId, vm.password, vm.password);
			}
			else {
				vm.showStudySelection = null;
				var appSessionStudy = appSession.studyDto2SessionStudy(study);
				appSession.switchStudy(appSessionStudy, false, true);
			}
		}

		function loadLegacyStudy(studyId, username, password) {
			vm.isLoadingStudy = true;

			if (vm.hasVersion3Access) {
				vm.legacyLogoutWarning = dialogService.createConfirmHandler();
				vm.legacyLogoutWarning.show().then(function (result) {
					vm.legacyLogoutWarning.hide();
					if (result) {
						legacyLogin(studyId, username, password);
					}
					else {
						vm.isLoadingStudy = false;
					}
				});
			}
			else {
				legacyLogin(studyId, username, password);
			}
		}

		function legacyLogin(studyId, username, password) {

			ssoService.legacyLogin(studyId, username, password).then(
				function(response) {
					if (!response.data || !response.data.success) {
						showStudyLoadError();
					}
					else {
						redirectToLegacyPortal(response.data);
					}
				},
				function(error) {
					showStudyLoadError(error);

				});
		}

		function redirectToLegacyPortal(data) {
			vm.legacyCompatibilityWarning = dialogService.createConfirmHandler();
			vm.legacyCompatibilityWarning.show().then(function () {
				vm.legacyCompatibilityWarning.hide();
				appSession.destroy(true);
				$window.location.href = data.redirectUrl;
			});
		}

		function showStudyLoadError() {
			vm.legacyLoadError = dialogService.createConfirmHandler();
			vm.legacyLoadError.show().then(function () {
				vm.legacyLoadError.hide();
				vm.isLoadingStudy = false;
			});
		}

		function checkVersionAccess() {
			for (var i = 0; i < vm.studies.length; ++i) {
				var study = vm.studies[i];

				if (+study.portalVersion === 4) {
					vm.hasVersion3Access = true;
					return;
				}
			}
		}

		function showStudySelection() {
			studySelectService.getStudies().then(
				function (result) {
					vm.dataLoading = false;
					vm.studies = result;
					checkVersionAccess();
					if (vm.studies.length === 1) {
						selectStudy(vm.studies[0]);

					} else if (vm.studies.length < 1) {

						$log.error('Empty study list received');
						setError('login.messages.no-studies');

					} else {
						$log.debug('showStudySelection');
						vm.showStudySelection = true;
					}
				},

				function (result) {
					if (!result.code || !result.code === 401) {
						$log.error('Get studies failed');
						setError('login.messages.generic');
					}
				},

				function (fault) {
					vm.dataLoading = false;

					$log.error('login_onFault( \'{0}\' )', [fault]);

					vm.hasError = true;
					setError('login.messages.authFailed');
				});
		}

		function goToSavedState() {
			$log.debug('use return state: {state}', {state: JSON.stringify($rootScope.returnState) + JSON.stringify($rootScope.returnParams)});
			$state.go($rootScope.returnState, $rootScope.returnParams);
			clearSavedState();
		}

		function clearSavedState() {
			$rootScope.returnState = null;
			$rootScope.returnParams = null;
		}

		function onChangePasswordSuccess(username, newPassword) {
			$log.debug('OnchangePasswordSuccess: ' + username + ' ' + newPassword);
			loginUser(username, newPassword);
		}

		function showChangePassword(username, password) {
			accountService.showChangePassword(username, password, onChangePasswordSuccess);
			vm.dataLoading = false;
		}

		function doResetPassword() {
			vm.resetPassword.isLoading = true;
			accountService.resetPassword(vm.resetPassword.formModel.email).then(
				function () {
					vm.resetPassword.isLoading = false;
					vm.resetPassword.currentStep = 'done';
				},
				function (response) {
					vm.resetPassword.isLoading = false;
					var message;
					if (response.data && response.data.message) {
						message = response.data.message;
					}
					else {
						message = response.statusText;
					}
					appI18n.translate(message).then(function (translation) {
						if (message === translation) {
							translation = 'An error occurred during password reset: ' + message;
						}
						vm.resetPassword.errorMsg = translation;
					});
				});
		}

		function showResetPassword() {
			initResetPasswordForm();
			vm.resetPassword.isFormVisible = true;
		}

		function initResetPasswordForm() {
			vm.resetPassword = {
				isFormVisible: false,
				isLoading: false,
				serviceResult: {},
				serviceError: {},
				errorMsg: '',
				currentStep: 'reset',
				formModel: {
					email: '',
				}
			};
		}

		function onHideRegistration() {
			goToLogin();
		}

		function onHideLoginHelp() {
			goToLogin();
		}

		function onHideStudySelection() {
			if (!vm.studySelected) {
				appSession.logout();
				vm.dataLoading = false;
				vm.isLoadingStudy = false;
			}
		}

		function goToLogin(prefillUserName) {
			if (prefillUserName) {
				$state.go(appConfig.routes.login, {'prefillUserName': prefillUserName});
			}
			else {
				$state.go(appConfig.routes.login);
			}
		}

		function showLoginHelp() {
			vm.loginHelp = {};

			appI18n.translate('login.help.title').then(function (t) {
				vm.loginHelp.formTitle = t;
			});

			vm.loginHelp.isFormVisible = true;
        }

        function hideWarning() {
            appSession.setLoginWarningInfo();
            vm.showWarning = false;
		}

	}

})();
