﻿(function () {
	'use strict';

	angular
		.module("portalApp")
		.service("stateParamsService", stateParamsService);

	function stateParamsService($stateParams) {

		return {
			getStudyId: getStudyId,
			getCountryId: getCountryId
		};

		function getStudyId()  {
			if ($stateParams.studyId) {
				return $stateParams.studyId;
			}
			return null;
		}
		function getCountryId() {
			if ($stateParams.countryId) {
				return $stateParams.countryId;
			}
			return null;
		}
	}
})();
