(function () {
	'use strict';

	angular.module('portalApp').controller('userActionTrackingReportCtrl', UserActionTrackingReportCtrl);

	/*
	 * @ngInject
	 */
	function UserActionTrackingReportCtrl($log, $scope, $state, appI18n,
		adminService,
		loadingIndicatorService, appConfig,
		appSession, notificationService) {

		$log = $log.getInstance('userActionTrackingReportCtrl');
		$log.debug('userActionTrackingReportCtrl:: loaded');

		const vm = this;

		vm.active = false;
		vm.cancelAction = cancelAction;
		vm.onInitialLoaded = onInitialLoaded;

		vm.submit = submit;
		vm.resetState = resetState;

		vm.showUserActionTrackingReport = showUserActionTrackingReport;
		vm.actions = [{
			action: vm.submit,
			displayName: 'Submit',
			isPrimary: true
		}, {
			action: vm.cancelAction,
			displayName: 'Cancel',
			cssClass: 'btn-danger md-close',
			iconName: 'close'
		}];

		register();

		function register() {
			adminService.registerShowUserActionTrackingReport(vm.showUserActionTrackingReport);
			$scope.$on('$destroy', () => {
				adminService.unregisterShowUserActionTrackingReport(vm.showUserActionTrackingReport);
			});
		}

		function showUserActionTrackingReport() {
			loadingIndicatorService.show();
			initialLoad();
		}

		function initialLoad() {
			resetModel();
			loadStudies().finally(() => {
				onInitialLoaded();
			});
		}

		function loadStudies() {
			return adminService.getStudies()
				.then((response) => {
					vm.studies = response.data;
				});
		}

		function resetModel() {
			vm.validation = {
				required: {}
			};
			vm.model = {
				user: '',
				study: null
			};
			vm.showValidation = false;
		}

		function onInitialLoaded() {
			$log.debug('onInitialLoaded in userActionTrackingReportCtrl');
			loadingIndicatorService.hide();
			vm.isInitialLoading = false;
			vm.active = true;
		}

		function closePopup() {
			vm.active = false;
		}

		function cancelAction() {
			closePopup();
		}

		function doSubmit() {
			vm.isLoading = true;
			const currentStudy = appSession.getStudy();

			adminService.generateAuditReport(
				vm.model.user || '',
				vm.model.study ? vm.model.study.studyId : null
				)
				.then(() => {
					closePopup();
					notificationService.showSuccess(
						supplant(
							appI18n.translateImmediate('cde-pft.auditReport.successMessage'),
							[$state.href(appConfig.routes.downloads)])

					);
				})
				.finally(() => {
					vm.isLoading = false;
				});
		}

		function submit() {
			vm.showValidation = true;
			const isValid = validate();

			if (isValid) {
				doSubmit();
			}
		}

		function validate() {
			if (!vm.model.user && (!vm.model.study || vm.model.study <= 0)) {
				vm.validation.needStudyOrUser = true;
				return false;
			}
			return true;
		}

		function resetState() {
			vm.isLoading = false;
			loadingIndicatorService.hide();
		}
	}
})();
