﻿(function() {
	'use strict';

	angular
		.module("portalApp")
		.service("eproService", EproService);

	/*
	 * @ngInject
	 */
	function EproService($log, $http, appSession, appConfig) {

		return {
			getQrCode: getQrCode,
			getResponseAudioSourceUrl: getResponseAudioSourceUrl,
			getCreateSubjectData: getCreateSubjectData,
			getEditSubjectData: getEditSubjectData,
			activateSubject: activateSubject,
			deactivateSubject: deactivateSubject,
			createSubject: createSubject,
			editSubject: editSubject,
			getCloseVisitData: getCloseVisitData,
			getCanCloseVisit: getCanCloseVisit,
			closeVisit: closeVisit,
			getSendPinData: getSendPinData,
			sendPin: sendPin,
			editContact: editContact,
			getEditContactData: getEditContactData,
			getScoringResults: getScoringResults,
			getAlertsTriggered: getAlertsTriggered,
			getDailySessions: getDailySessions,
			getSessionData: getSessionData,
			getResponses: getResponses,
			getParameters: getParameters,
			getParameterDefinitions: getParameterDefinitions,
			getEcoaPhoneQuestionnaire: getEcoaPhoneQuestionnaire,
			getClosedVisit: getClosedVisit,
			getClinicalPrompts: getClinicalPrompts
		};

		function getQrCode(siteId, eproSubjectId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.qrCode
					.supplant({'studyId': currentStudy.id, 'eproSubjectId': eproSubjectId,
										 'siteId': siteId, 'studyModeId': currentStudy.studyModeId});
			return $http.get(url);
		}

		function getResponseAudioSourceUrl(siteId, eproSubjectId, response) {
			var currentStudy = appSession.getStudy();
			var token = appSession.getSessionToken();

			var url = appConfig.apiUrls.clinicalData.epro.audioData
				.supplant({ 'studyId': currentStudy.id, 'responseId': response.responseId,
							'eproSubjectId': eproSubjectId, 'siteId': siteId,
							'studyModeId': currentStudy.studyModeId, 'token': token });
			return url;
		}

		function getEcoaPhoneQuestionnaire(siteId, eproSubjectId, eventId, tdtId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.ecoaphoneQuestionnaire
					.supplant({'studyId': currentStudy.id, 'eproSubjectId': eproSubjectId,
										 'siteId': siteId, 'studyModeId': currentStudy.studyModeId,
										 'tdtId': tdtId, 'eventId': eventId});
			return $http.get(url);
		}

		function getScoringResults(siteId, eproSubjectId, eventId, tdtId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.scoringResults
					.supplant({'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
										 'siteId': siteId, 'eproSubjectId': eproSubjectId,
										 'tdtId': tdtId, 'eventId': eventId});
			return $http.get(url);
		}

		function getAlertsTriggered(siteId, eproSubjectId, eventIds, tdtId, alertType) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.alertsTriggered
					.supplant({'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
										'siteId': siteId, 'eproSubjectId': eproSubjectId,
										 'tdtId': tdtId, 'alertType': alertType === undefined ? '' : alertType});

			var eventIdArray = getArrayForString(eventIds);

			eventIdArray.forEach(function (el) {
				url = addParameterToUrl(url, 'eventId', el.trim());
			});

			return $http.get(url);
		}

		function getClosedVisit(siteId, eproSubjectId, eventId, tdtId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.closedVisit
				.supplant({'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
									 'siteId': siteId, 'eproSubjectId': eproSubjectId,
									 'tdtId': tdtId, 'eventId': eventId});
			return $http.get(url);
		}

		function getParameterDefinitions(mdtId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.ecoa.parameterDefs
				.supplant({'studyId': currentStudy.id, 'mdtId': mdtId});
			return $http.get(url);
		}

		function getClinicalPrompts(moduleId, languageId, mdtId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.ecoa.clinicalPrompts
					.supplant({'studyId': currentStudy.id, 'mdtId': mdtId,
										 'moduleId': moduleId, 'languageId': languageId});
			return $http.get(url);
		}

		function getParameters(siteId, eproSubjectId, eventId, sessionDate, sessionTime, sessionTypeId, mdtId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.ecoa.parameters
				.supplant({'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
									'siteId': siteId, 'eproSubjectId': eproSubjectId,
									'sessionDate': sessionDate, 'sessionTime': sessionTime,
									 sessionTypeId,
									'mdtId': mdtId, 'eventId': eventId || ''});
			return $http.get(url);
		}

		function getResponses(siteId, eproSubjectId, eventId, sessionDate, sessionTime, sessionTypeId, tdtId, mdtId, sessionId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.ecoa.sessionResponses
				.supplant({'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
									'siteId': siteId, 'eproSubjectId': eproSubjectId,
									'sessionDate': sessionDate, 'sessionTime': sessionTime,
									 sessionTypeId,
									'tdtId': tdtId, 'mdtId': mdtId, 'eventId': eventId || '',
									'sessionId': sessionId || '' });
			return $http.get(url);
		}

		function getDailySessions(siteId, eproSubjectId, eventId, tdtId, mdtId, oDataQuery) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.ecoa.dailySessions
				.supplant({'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
									'siteId': siteId, 'eproSubjectId': eproSubjectId,
									 'oDataQuery': oDataQuery || '',
									 'tdtId': tdtId, 'mdtId': mdtId, 'eventId': (!eventId || eventId == 0) ? '' : eventId});
			return $http.get(url);
		}

		function getSessionData(siteId, eproSubjectId, sessionDate, eventIds, mdtId, timeout) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.ecoa.sessionData
				.supplant({'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId, 'siteId': siteId,
									'eproSubjectId': eproSubjectId, 'sessionDate': sessionDate,'mdtId': mdtId
				});

			var eventIdArray = getArrayForString(eventIds);

			eventIdArray.forEach(function (el) {
				url = addParameterToUrl(url, 'eventId', el.trim());
			});

			return $http.get(url, { timeout: timeout.promise });
		}

		function getArrayForString(arrayString) {
			var idArray = [];
			if (arrayString && arrayString.indexOf(',') > 0) {
				idArray = arrayString.split(',');
			}
			else if (arrayString) {
				idArray.push(arrayString);
			}
			return idArray;
		}

		function addParameterToUrl(url, paramName, paramValue) {
			url += '&' + paramName + '=' + paramValue;
			return url;
		}

		function getCloseVisitData(eproSubjectId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.closeVisit.data.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
					'eproSubjectId': eproSubjectId});
			return $http.get(url);
		}

		function getCanCloseVisit(eproSubjectId) {
			if (!eproSubjectId) {
				return null;
			}

			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.closeVisit.canClose.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
					'eproSubjectId': eproSubjectId});
			return $http.get(url);
		}

		function getCreateSubjectData(countryId, siteId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.createSubject.data.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
					'countryId': countryId || '0', 'siteId': siteId || '0'});
			return $http.get(url);
		}

		function getEditSubjectData(countryId, siteId, eproSubjectId, subjectId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.editSubject.data.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
					'countryId': countryId || '0', 'siteId': siteId || '0', 'eproSubjectId': eproSubjectId,
					'subjectId': subjectId});
			return $http.get(url);
		}

		function deactivateSubject(siteId, eproSubjectId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.editSubject.deactivate.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
					'siteId': siteId || '0', 'eproSubjectId': eproSubjectId});
			return $http.put(url);
		}

		function activateSubject(siteId, eproSubjectId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.editSubject.activate.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
					'siteId': siteId || '0', 'eproSubjectId': eproSubjectId});
			return $http.put(url);
		}

		function getEditContactData(eproSubjectId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.contact.data.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
					'eproSubjectId': eproSubjectId});
			return $http.get(url);
		}

		function getSendPinData(eproSubjectId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.pin.data.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
					'eproSubjectId': eproSubjectId});
			return $http.get(url);
		}

		function editSubject(subject, eproSubjectId, subjectId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.editSubject.update.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
					'eproSubjectId': eproSubjectId, 'subjectId': subjectId});
			return $http.put(url, subject);
		}

		function closeVisit(subjectId, eproSubjectId, visit) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.closeVisit.close.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
					'eproSubjectId': eproSubjectId, 'subjectId': subjectId});

			return $http.post(url, visit);
		}

		function createSubject(subject) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.createSubject.create.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId });
			return $http.post(url, subject);
		}

		function sendPin(siteId, eproSubjectId, pinData) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.pin.send.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId, 'siteId': siteId,
					'eproSubjectId': eproSubjectId});
			var apiData = {
				'useExistingPin': pinData.changePin === 0,
				'Pin': pinData.newPin,
				'generatePin': pinData.changePin === 1,
				'sendPinToSubjectEmail': pinData.sendPin === 1,
				'sendPinToEmail': pinData.pinEmail
			};
			return $http.post(url, apiData);
		}

		function editContact(siteId, eproSubjectId, editContactData) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.clinicalData.epro.contact.update.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId, 'siteId': siteId,
					'eproSubjectId': eproSubjectId});

			var apiData = {
				clearPhone: editContactData.deletePhone,
				clearMobile: editContactData.deleteMobile,
				clearEmail: editContactData.deleteEmail,
				email: editContactData.email
			};

			if (editContactData.phoneFormat === 0) {
				apiData.phone = editContactData.phoneUS;
				apiData.mobile = editContactData.mobileUS;
			}
			else {
				apiData.phone = editContactData.phoneInternational;
				apiData.mobile = editContactData.mobileInternational;
			}

			return $http.put(url, apiData);
		}
	}
})();
