(function () {
  'use strict';

  angular.module('portalApp').directive('ertDropdownKeyjump', ['$log', ertDropdownKeyjump]);

  /**
   * @ngInject
   */
  function ertDropdownKeyjump($log) {
    return {
      restrict: 'C',
      // This directive must not use isolated scope, because meant to be used together with other directives like ng-controller or ert-dashboard
      scope: false,
      link: function (scope, element) {

        $log.debug('Directive::ertDropdownKeyjump::linked');

        var KEY_ARROW_UP = 38, KEY_ARROW_DOWN = 40, KEY_ENTER = 13;

        element.bind('shown.bs.dropdown', function () {

          var $this = $(this),
            activeIndex = -1;

          element.bind("keydown.ertDropdownKeyjump", function(e){

            if (e.which == KEY_ENTER){
              var dropdownTextEl = element.find(".dropdown-text")[0];
              var selectedListEl = element.find(".dropdown-menu > li.active")[0];
              selectedListEl.classList.remove('active');

              if (dropdownTextEl) {
                //scope.$apply(function() {
                //  dropdownTextEl.textContent = selectedListEl.textContent;
                //});
              } else {
                var valueEl = element.find('.ert-dropdown-keyjump-value')[0];
                if (valueEl) {
                  scope.$apply(function () {
                    valueEl.textContent = selectedListEl.textContent.trim();
                  });
                }
              }

              // means somewhere click action is used instead of value binding -> trigger click
              var actionClickEl = angular.element(selectedListEl).find('.ert-dropdown-keyjump-click');
              if (actionClickEl) {
                actionClickEl.click();
                element.find('.dropdown-toggle').dropdown('toggle');
              }
            }

            if (e.which == KEY_ARROW_DOWN) {
              $log.debug('Directive::ertDropdownKeyJump keydown ARROW DOWN');

              var listItems = $this.find("li");
              listItems.each(function (idx, item) {

                if (item.classList.contains('active')) {
                  item.classList.remove('active');
                  activeIndex = idx;
                }
              });

              if (activeIndex === -1) {
                listItems[0].classList.add("active");
              } else if (activeIndex + 1 < listItems.length && activeIndex > -1) {
                listItems[activeIndex + 1].classList.add("active");
              } else {
                listItems[0].classList.add("active");
              }

              e.preventDefault();
            }

            if (e.which == KEY_ARROW_UP) {
              $log.debug('Directive::ertDropdownKeyJump keydown ARROW UP');

              var listItems = $this.find("li");
              listItems.each(function (idx, item) {

                if (item.classList.contains('active')) {
                  item.classList.remove('active');
                  activeIndex = idx;
                }
              });

              if (activeIndex - 1 >= 0) {
                listItems[activeIndex - 1].classList.add("active");
              } else if (activeIndex - 1 < 0) {
                listItems[listItems.length - 1].classList.add("active");
              } else {
                listItems[0].classList.add("active");
              }

              e.preventDefault();
            }
          });

          // attach key listener when dropdown is shown
          element.bind('keypress.ertDropdownKeyjump', function(e){
            // get the key that was pressed
            var key = String.fromCharCode(e.which);
            $log.debug('Directive::ertDropdownKeyJump keypress: ' + key);
            // look at all of the items to find a first char match
            $this.find("li").each(function(idx,item){
              // clear previous active highlighted item
              $(item).removeClass("active");
              var text = $(item).text().trim();
              if (text.length > 0 && text.charAt(0).toLowerCase() == key.toLowerCase()) {
                // set the item to selected (active)
                $(item).addClass("active");
                return false;
              }
            });
          });

          $log.debug("Directive::ertDropdownKeyjump bound on 'shown.bs.dropdown'");
        });

        // unbind key event when dropdown is hidden
        element.bind('hide.bs.dropdown', function () {
          element.unbind("keypress.ertDropdownKeyjump");
          element.unbind("keydown.ertDropdownKeyjump");

          $log.debug("Directive::ertDropdownKeyjump UN-bound on 'hide.bs.dropdown'");
        });

      }
    };
  }

})();
