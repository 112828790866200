class PopupHeaderController {
	constructor($window, $document, helpService) {
		'ngInject';
		this.$window = $window;
		this.helpService = helpService;
		this.$document = $document;
	}

	openHelp() {
		this.helpService.showModuleHelp(this.helpModuleId);
	}

	print() {
		const printableArea = this.$document.find('body .printable-content').eq(0),
			$popupContent = this.popup.$element.html();

		printableArea.append($popupContent);
		this.$window.print();
		printableArea.empty();
	}
}

export default PopupHeaderController;
