/*
 * announcementsWidgetComponent
 * */

import templateUrl from './announcementsWidget.html';
import controller from './announcementsWidget.controller';
import './announcementsWidget.scss';

const announcementsWidgetComponent = {
	bindings: {
		heading: '@'
	},
	templateUrl,
	controller
};

export default announcementsWidgetComponent;
