/*
 * filtersController
 * */

class FiltersController {
	constructor($log, $filter, filtersService, appI18n) {
		'ngInject';
		this.$log = $log;
		this.$filter = $filter;
		this.filtersService = filtersService;
		this.queryFilters = this.queryFilters || [];
		this.numberRangeModel = {};
		this.dateRangeModel = {};

		appI18n.translate([
			'grid.header.filter-from',
			'grid.header.filter-to'
		]).then((translations) => {
			this.fromFilterTxt = translations['grid.header.filter-from'];
			this.toFilterTxt = translations['grid.header.filter-to'];
		});
	}

	$onInit() {
		this.$log = this.$log.getInstance('FiltersController');
		this.$log.debug('loaded');

		this.setButtons();
	}

	$onChanges(changesObj) {
		if (changesObj.defaultFilter) {
			this.filterSelectModel = changesObj.defaultFilter.currentValue;
		}
		if (changesObj.queryFilters && !changesObj.queryFilters.isFirstChange()) {
			this.clearBtn.disabled = !changesObj.queryFilters.currentValue ||
				!changesObj.queryFilters.currentValue.length;
		}
	}

	applyQueryFilters(sourceFiltersArr = [], filters) {
		if (!angular.isArray(filters)) {
			filters = [filters];
		}

		let newFilters = [];
		const filtersToAdd = [];

		filters.forEach((f) => {
			newFilters = sourceFiltersArr.filter((ftr) => {
				return f.filterName !== ftr.filterName;
			});
			filtersToAdd.push(f);
		});

		return newFilters.concat(filtersToAdd);
	}

	setButtons() {
		this.applyBtn = {
			disabled: true,
			action: () => {
				this.applyBtn.disabled = true;
				this.clearBtn.disabled = false;
				this.applyAction();
			},
			displayName: 'grid.header.btn-add'
		};

		this.clearBtn = {
			cssClass: '',
			disabled: true,
			action: () => {
				this.applyBtn.disabled = true;
				this.clearBtn.disabled = true;
				this.filterInputModel = null;
				this.filterSelectModel = null;
				this.clearAction();
			},
			displayName: 'grid.header.btn-clear-all'
		};
	}

	enterAction() {
		if (this.isAddFilterEnabled()) {
			this.applyAction();
		}
	}

	isAddFilterEnabled() {
		return !this.applyBtn.disabled;
	}


	applyAction() {
		const fltr = this.processFilter();

		this.queryFilters = this.applyQueryFilters(this.queryFilters, fltr);

		const uriBuilder = this.filtersService.getUriBuilder(this.queryFilters);

		this.notifyGrid(uriBuilder, this.queryFilters);
		this.onUpdate({ queryFilters: this.queryFilters });
	}
	/*
	* Notifies grid parent component about filter change
	* */
	notifyGrid(uriBuilder, filters) {
		if (this.grid) {
			this.grid.onFilterChange(uriBuilder, filters);
		}
	}

	clearAction() {
		this.queryFilters = [];
		const uriBuilder = this.filtersService.getUriBuilder(this.queryFilters);

		this.notifyGrid(uriBuilder, this.queryFilters);
		this.onUpdate({ queryFilters: this.queryFilters });
	}

	filterSelected(filter) {
		this.clearFiltersModels(); //this is important !
		this.applyBtn.disabled = !filter || !this.filterInputModel;
		if (filter.type.startsWith('enum')) {
			this.enumFilters = filter.enums;
		}
	}

	exactNumberFilterInputChange(model) {
		this.exactNumberModel = model;
		this.applyBtn.disabled = !this.filterSelectModel || !model;
	}

	filterInputChange(inputStr) {
		this.applyBtn.disabled = !this.filterSelectModel || !inputStr;
	}

	numberRangeChange(model, isValid) {
		this.numberRangeModel = model;
		this.applyBtn.disabled = !this.filterSelectModel || !isValid;
	}

	dateRangeChange(model, isValid) {
		this.dateRangeModel = model;
		this.applyBtn.disabled = !this.filterSelectModel || !isValid;
	}

	enumFilterSelected(model) {
		this.enumFilterModel = model;
		this.applyBtn.disabled = !this.filterSelectModel || !model;
	}

	clearFiltersModels() {
		this.filterInputModel = null;
		this.numberRangeModel = {};
		this.dateRangeModel = {};
		this.enumFilterModel = null;
	}

	processFilter() {

		if (!this.filterSelectModel) {
			return;
		}

		if (this.filterInputModel) {
			const val = this.getInputFilter(this.filterInputModel);

			this.filterInputModel = null;
			return val;
		}

		if (this.exactNumberModel) {
			const val = this.getExactNumberFilter(this.exactNumberModel);

			this.exactNumberModel = null;
			return val;
		}

		if (this.numberRangeModel &&
			(this.numberRangeModel.from || this.numberRangeModel.to)) {
			const val = this.getNumberRangeFilter(this.numberRangeModel);

			this.numberRangeModel = {};
			return val;
		}

		if (this.dateRangeModel &&
			(this.dateRangeModel.from || this.dateRangeModel.to)) {
			const val = this.getDateRangeFilter(this.dateRangeModel);

			this.dateRangeModel = {};
			return val;
		}

		if (this.enumFilterModel) {
			const val = this.getEnumFilter(this.enumFilterModel);

			this.enumFilterModel = null;
			return val;
		}
	}

	getInputFilter(filterValue) {
		return {
			filterName: this.filterSelectModel.fieldName,
			filterValue,
			displayName: this.filterSelectModel.value,
			displayValue: filterValue,
			operator: 'contains',
			type: 'string'
		};
	}

	getExactNumberFilter(filterValue) {
		return {
			filterName: this.filterSelectModel.fieldName,
			filterValue,
			displayName: this.filterSelectModel.value,
			displayValue: filterValue,
			operator: 'equals',
			type: 'id'
		};
	}

	getNumberRangeFilter(filterValue) {
		const newFilter = [];
		const displayValue = this.getDisplayValueForRangeFilters(filterValue.from, filterValue.to);

		if (filterValue.from) {
			newFilter.push({
				filterName: this.filterSelectModel.fieldName,
				filterValue: filterValue.from,
				displayName: this.filterSelectModel.value,
				displayValue,
				operator: 'ge',
				type: 'number'
			});
		}

		if (filterValue.to) {
			newFilter.push({
				filterName: this.filterSelectModel.fieldName,
				filterValue: filterValue.to,
				displayName: this.filterSelectModel.value,
				displayValue,
				operator: 'le',
				type: 'number'
			});
		}
		return newFilter;
	}

	getDateRangeFilter(filterValue) {
		const newFilter = [];
		const fromFilterValue = this.getFormatedFilterDate(filterValue.from, 'yyyy-MM-dd');
		const toFilterValue = this.getFormatedFilterDate(filterValue.to, 'yyyy-MM-dd', true);
		const displayValue = this.getDisplayValueForRangeFilters(fromFilterValue, toFilterValue);

		if (filterValue.from) {
			newFilter.push({
				filterName: this.filterSelectModel.fieldName,
				filterValue: fromFilterValue,
				displayName: this.filterSelectModel.value,
				displayValue,
				operator: 'ge',
				type: 'date'
			});
		}

		if (filterValue.to) {
			newFilter.push({
				filterName: this.filterSelectModel.fieldName,
				filterValue: toFilterValue,
				displayName: this.filterSelectModel.value,
				displayValue,
				operator: 'le',
				type: 'date'
			});
		}

		return newFilter;
	}

	getEnumFilter(filterValue) {
		const newFilter = [];

		for (let i = 0; i < filterValue.values.length; i++) {
			const item = filterValue.values[i];

			newFilter.push({
				filterName: this.filterSelectModel.fieldName,
				filterValue: item,
				displayName: this.filterSelectModel.value,
				displayValue: filterValue.displayName,
				operator: 'eq',
				type: this.filterSelectModel.type
			});
		}

		return newFilter;
	}

	getDisplayValueForRangeFilters(from, to) {
		let displayValue = '';

		if (from) {
			displayValue += `${this.fromFilterTxt} ${from} `;
		}

		if (to) {
			displayValue += `${this.toFilterTxt} ${to}`;
		}

		return displayValue;
	}

	getFormatedFilterDate(dateIn, dateFormat, isToDate) {
		if (!dateIn) { return '';}

		let date = dateIn;
		const dates = date.split(/ |-|\//);

		//only have month and year
		if (dates.length === 2) {
			date = `1 ${dates[0]} ${dates[1]}`;

			if (isToDate) {
				date = moment(new Date(date)).endOf('month').format('DD MMM YYYY');
			}

		} else if (dates.length === 1) {
			date = isToDate ? `31 Dec ${dates[0]}` : `1 Jan ${dates[0]}`;
		}

		return this.$filter('date')(new Date(date), dateFormat);
	}
}

export default FiltersController;
