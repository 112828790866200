(function () {
	'use strict';
	
	angular
	.module('portalApp')
	.controller('ContactFileUploaderController', ContactFileUploaderController);
	
	/**
	 * @ngInject
	 */
	function ContactFileUploaderController($log, $scope, appI18n, FileUploader, remoteFileService, appConfig,
									   dataManagementService, dialogService, contactService) {
		$log = $log.getInstance("ContactFileUploaderController");
		
		var vm = this;
		
		appI18n.translate([
			'data-management.addAttachment.errors.saveErrorMessage',
			'data-management.addAttachment.errors.saveErrorMessageVirus',
			'data-management.addAttachment.errors.invalidFileTypeMessage',
			'data-management.addAttachment.errors.invalidFileSizeMessage',
			'data-management.addAttachment.errors.requireUploadFiles',
			'data-management.addAttachment.errors.saveInProgress'
		])
		.then(function (translations) {
			vm.saveErrorMessage = translations['data-management.addAttachment.errors.saveErrorMessage'];
			vm.saveErrorMessageVirus = translations['data-management.addAttachment.errors.saveErrorMessageVirus'];
			vm.invalidFileTypeMessage = translations['data-management.addAttachment.errors.invalidFileTypeMessage'];
			vm.invalidFileSizeMessage = translations['data-management.addAttachment.errors.invalidFileSizeMessage'];
			vm.errorMessageRequireUploadFiles = translations['data-management.addAttachment.errors.requireUploadFiles'];
			vm.errorMessageSavingFile = translations['data-management.addAttachment.errors.saveInProgress'];
			vm.agreementErrorMessage = null;
		});
		
		vm.isValid = isValid;
		vm.remove = remove;
		vm.openFile = openFile;
		vm.canDeleteFile = canDeleteFile;

		vm.errorMessage = '';
		vm.uploadErrorMessage = '';

		vm.data = contactService.getSharedData();
		vm.data.attachments = vm.data.attachments || [];
		vm.data.getUploadedFiles = getUploadedFiles;
		vm.data.isValid = isValid;
		vm.data.initialize = initialize;
		vm.data.savingFile = false;
		vm.fileItems = [];

		initFileUploader(vm);
		
		function initialize() {
			vm.errorMessage = '';
			vm.uploadErrorMessage = '';
			$log.debug('initialize');
		}
		
		function isValid(showError) {
			if (vm.data.savingFile) {
				vm.errorMessage = vm.errorMessageSavingFile;
				return false;
			}
			var attachments = getAttachments();
			vm.data.attachments = attachments;
			
			return true;
		}

		function getAttachments() {
			var attachments = vm.data.attachments && vm.data.attachments.filter(function (attachment) {
				return !attachment.errorMessage;
				});
			return attachments;
		}

		function clearAgreementErrorMessage() {
			var attachments = getAttachments();
			if (attachments === null || attachments.length === 0) {
				vm.agreementErrorMessage = '';
			}
		}

		function getUploadedFiles() {
			return vm.data.attachments && vm.data.attachments.filter(function (attachment) {
					return !attachment.errorMessage;
				});
		}
		
		function remove(index) {
			removeIntenal(index);
		}
		function removeIntenal(index, Comments) {
			var file = vm.data.attachments[ index ];
			var item = _.find(vm.fileItems, { index: file.index });
			if (file && file.url && file.url.length > 0) {
				remoteFileService.deleteContactFile(file.url);
			}
			if (item) {
				try {
					item.fileItem.remove();
				} catch (ex) {
					$log.error('try to remove item:' + ex);
				}
			}
			_.remove(vm.fileItems, { index: file.index });
			vm.data.attachments.splice(index, 1);
			clearAgreementErrorMessage();

		}

		function isValidFile(fileItem) {
			var file = fileItem.file;
			
			return isValidFileType(file) && isValidFileSize(file);
		}
		
		function isValidFileSize(item) {
			var maxSize = appConfig.fileUpload.paperPFT.fileMaxSize;
			var maxSizeMb = maxSize / 1048576;
			
			var result = item.size <= maxSize;
			if (!result) {
				vm.errorMessage = `${vm.invalidFileSizeMessage} of ${maxSizeMb}MB.`;
			}
			return result;
		}
		
		function isValidFileType(item) {
			return true;
			// var type = item.name.slice(item.name.lastIndexOf('.') + 1);
			// type = (type || '').toLowerCase();
			// var result = appConfig.fileUpload.dcr.validFileType.indexOf(type) !== -1;
			// if (!result) {
			// 	vm.errorMessage = vm.invalidFileTypeMessage;
			// }
			// return result;
		}
		
		function initFileUploader(vm) {
			vm.uploader = new FileUploader({
				url: remoteFileService.getContactPostFileUrl(),
				autoUpload: true
			});
			
			vm.uploader.onProgressItem = function (fileItem, progress) {
				$log.log(`onProgressItem file: ${fileItem.file.name} progress: ${progress}`);
			};
			
			vm.uploader.onAfterAddingFile = function (fileItem) {
				vm.errorMessage = '';
				var index = -100;
				var errorMessage = '';
				var itemSaving = false;
				if (!isValidFile(fileItem)) {
					vm.uploader.removeFromQueue(fileItem);
					errorMessage = vm.errorMessage;
					vm.errorMessage = '';
				} else {
					itemSaving = true;
					vm.data.savingFile = true;
					index = vm.uploader.getIndexOfItem(fileItem);
				}

				vm.data.attachments.unshift({
					url: '',
					originalFilename: fileItem.file.name,
					comments: '',
					index: index,
					userIsInternal: vm.data.userIsInternal,
					saving: itemSaving,
					errorMessage: errorMessage
				});
				vm.fileItems.unshift({ fileItem: fileItem, index: index });
				$log.debug('onAfterAddingFile' + index, fileItem);
			};
			
			vm.uploader.onSuccessItem = function (fileItem, response, status, headers) {
				var index = vm.uploader.getIndexOfItem(fileItem);
				var item = _.find(vm.data.attachments, { index: index });
				if (item) {
					item.url = response.url;
					$log.debug(item.url);
					item.index = -1;
					item.saving = false;
				}
				
				$log.log(`Success ${fileItem.file.name} uploaded. Response: ${response}`);
				$log.debug('onSuccessItem', fileItem, response, status, headers);
			};
			
			vm.uploader.onErrorItem = function (fileItem, response, status, headers) {
				var index = vm.uploader.getIndexOfItem(fileItem);
				var item = _.find(vm.data.attachments, { index: index });
				if (item) {
					if (response.message && response.message.indexOf('Virus') >= 0) {
						item.errorMessage = vm.saveErrorMessageVirus;
					} else {
						item.errorMessage = vm.saveErrorMessage;
					}
					item.saving = false;
				}
				$log.log('Error status:', response);
				$log.debug('onErrorItem', fileItem, response, status, headers);
			};
			
			vm.uploader.onCompleteAll = function () {
				vm.data.savingFile = false;
				vm.errorMessage = '';
				vm.uploader.clearQueue();
				vm.fileItems = [];
				$log.debug('onCompleteAll');
			};
		}
		
		function openFile(url, name) {
			remoteFileService.openContactFile(url, name, true);
			$log.debug(`open file ${url}`);
		}

		function canDeleteFile(item) {
			if (item.errorMessage) {
				return false;
			}
			return true;
		}
	}
})();
