/*
 * fileUploadPopupComponent
 * */

import templateUrl from './fileUploadPopup.html';
import controller from './fileUploadPopup.controller';
import './fileUploadPopup.scss';

const fileUploadPopupComponent = {
	bindings: {
		popupId: '@',
		uploadUrl: '@',
		multipleUpload: '<?',
		infoMessage: '@?',
		filters: '<?',
		dragAndDrop: '<?',
		showTextArea: '<?'
	},
	templateUrl,
	controller
};

export default fileUploadPopupComponent;
