(function () {
	'use strict';

	angular.module('portalApp')
		.directive('ertListFilter', ListFilter);

	angular.module('portalApp')
		.controller('ertListFilterController', FilterController);

	function ListFilter() {
		return {
			restrict: 'E',
			templateUrl: 'scripts/directives/list/ertListFilter.html',
			scope: {
				columns: '=',
				data: '=',
				items: '=',
				onFilter: '&'
			},
			controller: 'ertListFilterController',
			controllerAs: 'vm',
			bindToController: true
		};
	}

	/** @ngInject **/
	function FilterController($log,analyticsService, appConfig) {
		var NO_COLUMN_SELECTED = 0,
			previousColumn = NO_COLUMN_SELECTED,
			previousText = '',
			vm = this;
		$log = $log.getInstance('ListFilterController');
		$log.debug(' loaded');

		vm.apply = apply;
		vm.clear = clear;
		vm.column = NO_COLUMN_SELECTED;
		vm.hasChanged = hasChanged;
		vm.isApplicable = isApplicable;
		vm.setColumn = setColumn;
		vm.text = '';

		activate();

		function activate() {
			if (vm.columns && vm.columns.length === 1) {
				vm.filter.setColumn(vm.columns[0]);
			}
		}

		function apply() {
			var columns = [],
				text = vm.text.toLowerCase();
			previousText = vm.text;
			previousColumn = vm.column;
			//use selected column for filtering
			if (vm.column) {
				columns.push(vm.column.fieldName);
			} else {
				vm.columns.forEach(function (column) {
					columns.push(column.fieldName);
				});
			}
			var filterInfo = columns.join();
			analyticsService.trackEvent(appConfig.trackCategory.ertListFilter,  filterInfo);

			vm.items = vm.data.filter(function (item) {
				var i;
				for (i = 0; i < columns.length; i++) {
					var content = item[columns[i]];
					if (angular.isString(content) &&
						content.toLowerCase().indexOf(text) !== -1) {
						return true;
					}
				}
				return false;
			});
			vm.onFilter && vm.onFilter({ text: vm.text });
		}

		function isApplicable() {
			return !!vm.text;
		}

		function hasChanged() {
			return vm.column !== previousColumn || vm.text !== previousText;
		}

		function setColumn(column) {
			!column && (column = NO_COLUMN_SELECTED);
			vm.column = column;
		}

		function clear() {
			previousColumn = NO_COLUMN_SELECTED;
			previousText = '';
			vm.items = vm.data;
			vm.column = NO_COLUMN_SELECTED;
			vm.text = '';
			vm.onFilter && vm.onFilter({ text: '' });
		}
	}

})();
