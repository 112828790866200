const ShowIFrameDirective = ($document, $window, $timeout) => {
	return {
		scope: {
			isFullPage: '@'
		},
		restrict: 'A',
		link(scope, element) {

			function setIFrameHeight() {
				const portalHeader = $document.find('page-header');
				const portalHeaderHeight = (portalHeader.length === 1 &&
											portalHeader[0].offsetHeight) || 0;
				const gssoHeader = $document.find('#ert-global-header');
				const gssoHeaderHeight = (gssoHeader.length === 1 &&
										  gssoHeader[0].offsetHeight) || 0;

				// this directive is used for iframes shown below a msp3 page header container
				// and for full height iframes therefore we need to adapt the height calculation.
				const spacingFactor = scope.isFullPage ? 0 : 10;

				// window height - portal header - gsso header - top iframe spacing
				element.css('height', $window.innerHeight - portalHeaderHeight - gssoHeaderHeight - spacingFactor);
			}

			function onResize() {
				$timeout(setIFrameHeight, 0);
			}

			$document.find('footer.es-app-footer').hide();
			element.css('display', 'none');
			element.parent().append(`<div class="iframe-loading-container">
<div class="es-content-box">
<div class="es-panel">
<div class="es-content-box">
<div class="es-loading-indicator -es-visible"><span class="es-loading-icon"></span></div></div></div></div></div>`);

			element.on('load', () => {
				element.css('display', 'block');
				element.parent().find('.iframe-loading-container').remove();
				setIFrameHeight();
			});

			angular.element($window).on('resize', () => {
				onResize();
			});

			// clean up
			scope.$on('$destroy', () => {
				$document.find('footer.es-app-footer').show();
				angular.element($window).off('resize', onResize);
			});
		}

	};
};

export default ShowIFrameDirective;
