const maskedInputFactory = function () {
	const validators = {
		hoursMinutesOnly: (value) => {
			return !value || /^([01]\d|2[0-3]):?([0-5]\d)$/.test(value);
		}
	};

	return { validators };
};

export default maskedInputFactory;
