/**
 *
 */
(function() {

	'use strict';

	angular
		.module("portalApp")
		.factory("ertSgModal", ModalService);

	/**
	 * @ngInject
	 */
	function ModalService($log, $animate, $document, $compile, $controller, $http, $rootScope, $q, $templateRequest, $timeout) {
    
    var self = this;
	  
		$log = $log.getInstance("ModalService");
		$log.debug("ModalService loaded");

		return {
			open: open
		};

		function open(options) {
      //  Get the body of the document, we'll add the modal to this.
      var body = angular.element($document[0].body);
      
      //  Create a deferred we'll resolve when the modal is ready.
      var deferred = $q.defer();
      
      //  Validate the input parameters.
      var controllerName = options.controller;
      if (!controllerName) {
        deferred.reject("No controller has been specified.");
        return deferred.promise;
      }
      
      //  Get the actual html of the template.
      getTemplate(options.template, options.templateUrl)
        .then(function(template) {
          
          //  Create a new scope for the modal.
          var modalScope = (options.scope || $rootScope).$new();
          var rootScopeOnClose = $rootScope.$on('$routeChangeSuccess', cleanUpClose);
          modalScope.$on('esSgCloseModal', function ($event, result) {
            cleanUpClose(result);
          });
          
          //  Create the inputs object to the controller
          var closeDeferred = $q.defer();
          var closedDeferred = $q.defer();
          var inputs = {
            $scope: modalScope,
            close: function(result, delay) {
              if (delay === undefined || delay === null) delay = 0;
              $timeout(function() {
                
                cleanUpClose(result);
                
              }, delay);
            }
          };
          
          //  If we have provided any inputs, pass them to the controller.
          if (options.inputs) angular.extend(inputs, options.inputs);
          
          //  Compile then link the template element, building the actual element.
          //  Set the $element on the inputs so that it can be injected if required.
          var linkFn = $compile(template);
          var modalElement = linkFn(modalScope);
          inputs.$element = modalElement;
          
          //  Create the controller, explicitly specifying the scope to use.
          var controllerObjBefore = modalScope[options.controllerAs];
          var modalController = $controller(options.controller, inputs, false, options.controllerAs);
          
          if (options.controllerAs && controllerObjBefore) {
            angular.extend(modalController, controllerObjBefore);
          }
          
          //  Finally, append the modal to the dom.
          if (options.appendElement) {
            // append to custom append element
            appendChild(options.appendElement, modalElement);
          } else {
            // append to body when no custom append element is specified
            appendChild(body, modalElement);
          }
          
          //  We now have a modal object...
          var modal = {
            controller: modalController,
            scope: modalScope,
            element: modalElement,
            close: closeDeferred.promise,
            closed: closedDeferred.promise
          };
          
          //  ...which is passed to the caller via the promise.
          deferred.resolve(modal);
          
          function cleanUpClose(result) {
            
            //  Resolve the 'close' promise.
            closeDeferred.resolve(result);
            
            //  Let angular remove the element and wait for animations to finish.
            $animate.leave(modalElement)
              .then(function () {
                //  Resolve the 'closed' promise.
                closedDeferred.resolve(result);
                
                //  We can now clean up the scope
                modalScope.$destroy();
                
                //  Remove references to clean memory
                inputs.close = null;
                deferred = null;
                closeDeferred = null;
                modal = null;
                inputs = null;
                modalElement = null;
                modalScope = null;
              });
            
            // remove event watcher
            rootScopeOnClose && rootScopeOnClose();
          }
          
        })
        .then(null, function(error) {
          deferred.reject(error);
        });
      
      return deferred.promise;
		}
    
    function getTemplate(template, templateUrl) {
      var deferred = $q.defer();
      if (template) {
        deferred.resolve(template);
      } else if (templateUrl) {
        $templateRequest(templateUrl, true)
          .then(function(template) {
            deferred.resolve(template);
          }, function(error) {
            deferred.reject(error);
          });
      } else {
        deferred.reject("No template or templateUrl has been specified.");
      }
      return deferred.promise;
    }
    
    function appendChild(parent, child) {
      var children = parent.children();
      if (children.length > 0) {
        return $animate.enter(child, parent, children[children.length - 1]);
      }
      return $animate.enter(child, parent);
    }
	}
})();

