import templateUrl from './subjectInfoPopup.html';
import controller from './subjectInfoPopup.controller';
import './subjectInfoPopup.scss';

const subjectInfoPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default subjectInfoPopupComponent;
