var body;
var notificationsOptions = {
    containerClass: '',
    appearancePosition: 'right',
    appearanceWidth: ''
};

/**
 * Configure notification options.
 * appearancePosition: left, center or right
 * appearanceWidth: small
 * @return {void}
 */
function configureNotifications(options) {
    if (!options) options = {};
    body = options.body || document.body;
    defaultMessageOptions.timeout = options.timeout || defaultMessageOptions.timeout;
    defaultMessageOptions.animation = options.animation || defaultMessageOptions.animation;
    notificationsOptions.appearancePosition = options.appearancePosition || notificationsOptions.appearancePosition;
    notificationsOptions.appearanceWidth = options.appearanceWidth || notificationsOptions.appearanceWidth;
    notificationsOptions.containerClass = options.containerClass || notificationsOptions.containerClass;
}

/**
 * Show notification message.
 * @param  {Object} options Options
 * @return {void}
 */
function notificationMessage(options) {
    var message = new Message(options);
    var notificationsBarElement = createNotificationsBar(options);
    notificationsBarElement.insertBefore(message.element, notificationsBarElement.firstChild);
    if (message.type() !== 'danger') {
        setTimeout(function () {
            removeElement(message.element);
        }, message.option('timeout', 15000));
    }
    return message;
}

/**
 * Create or get existing notifications bar container.
 * @param  {Object} options Options for notifications bar:
 * appearancePosition: left, center or right
 * appearanceWidth: full or small
 * @return {HTMLElement} Notifications bar container element.
 */
function createNotificationsBar(options) {
    var notificationsBarElement = body.querySelector('.es-notification-center');
    var appearancePosition = getValue(options, 'appearancePosition', notificationsOptions.appearancePosition);
    var appearanceWidth = getValue(options, 'appearanceWidth', notificationsOptions.appearanceWidth);
    if (notificationsBarElement
        && dataset(notificationsBarElement, 'position') === appearancePosition
        && dataset(notificationsBarElement, 'width') === appearanceWidth) {
        return notificationsBarElement;
    } else {
        if (notificationsBarElement) {
            body.removeChild(notificationsBarElement);
        }
    }
    var notificationBarTemplate = '<div class="es-notification-center"></div>';
    notificationsBarElement = createElement(notificationBarTemplate);

    dataset(notificationsBarElement, 'position', appearancePosition);
    addClass(notificationsBarElement, es(appearancePosition));

    dataset(notificationsBarElement, 'width', appearanceWidth);
    appearanceWidth && addClass(notificationsBarElement, es(appearanceWidth));

    if (notificationsOptions.containerClass) {
        addClass(notificationsBarElement, notificationsOptions.containerClass);
    }

    body.appendChild(notificationsBarElement);
    initializeEvents(notificationsBarElement);
    return notificationsBarElement;
}

/**
 * Initialize events delegation.
 * @param  {HTMLElement} container
 * @return {void}
 */
function initializeEvents(container) {

    function traverse(node) {
        if (node.matches('.es-notification')) {
            // Messages should close when you click on them and danger messages are only closing if you dismiss them.
            if (dataset(node, 'type') !== 'danger') {
                removeElement(node);
            }
        } else if (node.matches('.es-notification .es-close')) {
            removeElement(node.parentNode);
        } else {
            var parentNode = node.parentNode;
            if (parentNode) {
                traverse(parentNode);
            }
        }
    }

    container.addEventListener('click', function (e) {
        traverse(e.target);
    });

}

var defaultMessageOptions = {
    animation: 'slide-in-right',
    priority: 'normal',
    timeout: 15000,
    type: ''
};

var typeIcons = {
    info: 'info',
    success: 'alert-success',
    warning: 'alert-warning',
    danger: 'alert-danger'
};

/**
 * Create message object.
 * @param {Object} options:
 * animation: slide-in-right or fade-in-down (default: slide-in-right)
 * priority: high, normal or low (default: normal)
 * type: success, info, warning or danger (default: none)
 */
function Message(options) {
    this.options = options;
    this.element = createElement('<div class="es-notification"><span class="es-icon"></span><p></p><span class="es-icon es-icon-close es-close"></span></div>');
    this.textElement = this.element.querySelector('p');
    this.iconElement = this.element.querySelector('.es-icon');
    this.closeElement = this.element.querySelector('.close');

    this.text(this.option('text', ''));
    this.type(this.option('type'));
    this.icon(this.option('icon'));
    this.iconSize(this.option('iconSize'));
    this.priority(this.option('priority'));
    this.animation(this.option('animation'));

    dataset(this.element, 'type', this.type());
}

Message.prototype.option = function (name, defaultValue) {
    if (defaultValue === undefined) {
        defaultValue = defaultMessageOptions[name];
    }
    return getValue(this.options, name, defaultValue);
};

Message.prototype.text = function (text) {
    this.textElement.innerHTML = text;
};

Message.prototype.type = function (type) {
    if (type === undefined) {
        return this.typeValue;
    }
    this.typeValue = type;
    addClass(this.element, es(type));

    this.icon(typeIcons[type]);
};

Message.prototype.icon = function (icon) {
    if (icon) {
        this.iconElement.className = this.iconElement.className
            .replace(/es-icon-\S+/g, '')
            .replace(/\s+/g, ' ')
            .replace(/(^\s+|\s+$)/g, '');
        addClass(this.iconElement, 'es-icon-' + icon);
    }
};

Message.prototype.iconSize = function (size) {
    if (size) {
        addClass(this.iconElement, es(size));
    }
};

Message.prototype.priority = function (p) {
    addClass(this.element, es('priority-' + p));
};

Message.prototype.animation = function (animation) {
    addClass(this.element, '-es-animated ' + es(animation));
};

// ============================================================
// Helpers.
// ============================================================

function createElement(html) {
    var template = document.createElement('template');
    template.innerHTML = html;
    if (template.content && template.content.firstChild) {
        return template.content.firstChild;
    }
    return template.firstChild;
}

function addClass(node, className) {
    node.className += ' ' + className;
}

function es(value) {
    return '-es-' + value;
}

function dataset(element, name, value) {
    var attrName = 'data-' + name;
    return (value === undefined) ? element.getAttribute(attrName) : element.setAttribute(attrName, value);
}

function removeElement(element) {
    var node = element && element.parentNode;
    if (node) {
        node.removeChild(element);
    }
}

function getValue(data, key, defaultValue, remove) {
    var result = defaultValue;
    if (typeof data[key] !== 'undefined') {
        result = data[key];
    }
    if (remove) {
        delete data[key];
    }
    return result;
}

module.exports = {
    message: notificationMessage,
    configure: configureNotifications
};;