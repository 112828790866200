/*
 * ertGridModule
 * */

import rowActionEnableIfFilter from './filter/enableIfFilter';

import ertGridCellDirective from './body/ertGridCell/ertGridCell.directive';
import ertGridDirective from './ertGrid.directive';
/* eslint-disable */
import ertGridBody from './body/ertGridBody.html';
import ertGridBodyGroup from './body/ertGridBodyGroup.html';
import ertGridBodyNormal from './body/ertGridBodyNormal.html';
import ertGridNoResults from './body/ertGridNoResults.html';
import ertGridRowActions from './body/ertGridRowActions.html';
import ertGridRowActionsRightAligned from './body/ertGridRowActionsRightAligned.html';
import ertGridRules from './body/ertGridRules.html';

import ertGridActions from './header/ertGridActions.html';
import ertGridFilters from './header/ertGridFilters.html';
import ertGridHeader from './header/ertGridHeader.html';

import collapse from './header/ertGridActions/collapse.html';
import exportGrid from './header/ertGridActions/export.html';
import filterActions from './header/ertGridActions/filterActions.html';
import pagination from './header/ertGridActions/pagination.html';
/* eslint-enable */

const ertGridModule = angular.module('app.common.ertGrid', [])
	  .filter('ertGridRowEnableIfFilter', rowActionEnableIfFilter)
	  .directive('ertGrid', ertGridDirective)
	  .directive('ertGridCell', ertGridCellDirective)
	  .name;

export default ertGridModule;
