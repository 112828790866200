/*
 * iconLinkComponent
 * */

import templateUrl from './iconLink.html';
import './iconLink.scss';

const iconLinkComponent = {
	bindings: {
		iconClass: '@',
		action: '&',
		isDisabled: '<?'
	},
	templateUrl
};

export default iconLinkComponent;
