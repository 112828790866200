import templateUrl from './popupContent.html';
import './popupContent.scss';

const popupContentComponent = {
	bindings: {
		heading: '@?',
		msg: '@'
	},
	templateUrl,
	transclude: true
};

export default popupContentComponent;
