(function() {

	'use strict';
	angular
		.module("portalApp")
		.service("adminService", AdminService);

	/**
	 * @ngInject
	 */

	function AdminService(appConfig, $http, $log, appSession) {

		$log = $log.getInstance("AdminService");
		$log.debug("loaded");

		var adminHandler;
		var showUserActionTrackingReport;

		var adminService = {
			getStudyConfiguration: getStudyConfiguration,
			getStudyTracking: getStudyTracking,
			getStudies: getStudies,
			getUserTracking: getUserTracking,
			getUserLoginTracking: getUserLoginTracking,
			getModuleTracking: getModuleTracking,
			registerAdminHandler: registerAdminHandler,
			setTitle: setTitle,
			registerShowUserActionTrackingReport: registerShowUserActionTrackingReport,
			unregisterShowUserActionTrackingReport: unregisterShowUserActionTrackingReport,
			showUserActionTrackingReport: showUserActionTrackingReport,
			generateAuditReport: generateAuditReport
		};

		return adminService;

		function getStudyConfiguration() {
			var url = appConfig.apiUrls.admin.studyConfig.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });
			$log.debug("adminService.GetStudyConfiguration with url: {url} ", {url:url});
			return $http.get(url);
		}

		function getStudyTracking(options) {

			var url = appConfig.apiUrls.admin.studyTracking.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });

			//Check for default filter of 7 days
			if (options) {
				url += "&" + options;
			}
			$log.debug("adminService.GetStudyTracking with url: {url}", {url:url});
			return $http.get(url);
		}

		function getUserTracking(options) {
			var url = appConfig.apiUrls.admin.userTracking.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });

			if (options) {
				url += "&" + options;
			}
			$log.debug("adminService.GetUserTracking with url: {url}", {url:url});
			return $http.get(url);
		}

		function getUserLoginTracking(options) {
			var url = appConfig.apiUrls.admin.userLoginTracking.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });

			if (options) {
				url += "&" + options;
			}
			$log.debug("adminService.GetUserLoginTracking with url: {url}", {url:url});
			return $http.get(url);
		}

		function getModuleTracking(options) {
			var url = appConfig.apiUrls.admin.moduleTracking.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });

			if (options) {
				url += "&" + options;
			}
			$log.debug("adminService.GetModuleTracking with url: {url}", {url:url});
			return $http.get(url);
		}

		function registerAdminHandler(handler) {
			adminHandler = handler;
		}

		function setTitle(title) {
			return adminHandler.setTitle(title);
		}


		function registerShowUserActionTrackingReport(handler) {
			showUserActionTrackingReport = handler;
		}

		function unregisterShowUserActionTrackingReport(handler) {
			if (showUserActionTrackingReport == handler) {
				$log.debug('unregister show entry handler');
				showUserActionTrackingReport = null;
			} else {
				$log.debug('show entry handler alredy overwritten with new handler');
			}
		}

		function showUserActionTrackingReport() {
			if (showUserActionTrackingReport) {
				showUserActionTrackingReport();
			}
		}

		function getStudies() {
			var url = appConfig.apiUrls.admin.studies.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });

			$log.debug("adminService.getStudies with url: {url}", {url:url});
			return $http.get(url);
		}

		function generateAuditReport(trackingUserLogin, trackingStudyId) {
			var url = appConfig.apiUrls.admin.trackingReport.supplant(
				{'studyId': appSession.getStudy().id,
				 'studyModeId': appSession.getStudy().studyModeId,
				 trackingUserLogin});

			if (trackingStudyId) {
				url += "&trackingStudyId=" + trackingStudyId;
			}
			$log.debug("adminService.getStudies with url: {url}", {url:url});
			return $http.post(url);
		}
	}

})();
