/*
 * globalMessageController
 * */

class GlobalMessageController {
	constructor($log, globalMessageService) {
		'ngInject';
		this.$log = $log;
		this.globalMessageService = globalMessageService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('GlobalMessageController');
		this.$log.debug('loaded');

		this.setApi();
	}

	$onDestroy() {
		this.globalMessageService.unregister();
	}

	setApi() {
		const self = this;

		this.$API = {
			show: (heading, subHeading) => {
				self.heading = heading;
				self.subHeading = subHeading;
				self.show = true;
			},
			hide: () => {
				self.heading = '';
				self.subHeading = '';
				self.show = false;
			},
			isActive: () => {
				return self.show;
			}
		};
		this.globalMessageService.register(this.$API);
	}
}

export default GlobalMessageController;
