/*
 * baseSelectModule
 * */

import baseSelectComponent from './baseSelect.component';
//import baseSelectDirective from './baseSelect.directive';
//import baseSelectService from './baseSelect.service'; // TODO: enable or remove
//import baseSelectFactory from './baseSelect.factory'; //TODO: enable or remove

const baseSelectModule = angular.module('app.common.select.baseSelect', [])
	.component('baseSelect', baseSelectComponent)
	//.directive('baseSelect', baseSelectDirective)
	//.service('baseSelectService', baseSelectService) //TODO: enable or remove
	//.factory('baseSelectService', baseSelectFactory) //TODO: enable or remove
	.name;

export default baseSelectModule;
