/*
 * iconsRowModule
 * */

import iconsRowDirective from './iconsRow.directive';

const iconsRowModule = angular.module('app.common.card.contactsRow', [])
	.directive('ertCardIconsRow', iconsRowDirective)
	.name;

export default iconsRowModule;
