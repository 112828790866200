(function () {
	'use strict';

	angular.module('portalApp').service('clinicalDataEntryService', ClinicalDataEntryService);

	/**
	 * @ngInject
	 */
	function ClinicalDataEntryService(appConfig, $state, $stateParams,
									  $location, $log, loadingIndicatorService) {

		var refreshHandler;
		var showEntryHandler;
		var showAuditReportHandler;
		var measurementInfoHandler = {};
		var visitEventHandler = {};
		var effortsHandler = {};
		var uploadHandler = {};
		var initialStudiesLoadedCB = {};

		var service = {
			registerRefresh: registerRefresh,
			refreshGrid: refreshGrid,
			gotoListView: gotoListView,
			gotoNewEntry: gotoNewEntry,
			getCdeMode: getCdeMode,
			registerShowEntry: registerShowEntry,
			unregisterShowEntry: unregisterShowEntry,
			showEntry: showEntry,
			registerShowAuditReport: registerShowAuditReport,
			unregisterShowAuditReport: unregisterShowAuditReport,
			showAuditReport: showAuditReport,
			registerVisitEventHandler: registerVisitEventHandler,
			getVisitEventHandler: getVisitEventHandler,
			getMeasurementInfoHandler,
			registerMeasurementInfoHandler,
			initialStudiesLoaded: initialStudiesLoaded,
			registerInitialStudiesLoaded: registerInitialStudiesLoaded,
			registerEffortsHandler: registerEffortsHandler,
			getEffortsHandler: getEffortsHandler,
			registerUploadHandler: registerUploadHandler,
			getUploadHandler: getUploadHandler,
			matchSelectValueToModel: matchSelectValueToModel,
			isShowEntryHandlerRegistered
		};

		return service;

		function registerUploadHandler(handler) {
			uploadHandler = handler;
		}

		function registerInitialStudiesLoaded(cb) {
			initialStudiesLoadedCB = cb;
		}

		function initialStudiesLoaded() {
			if (initialStudiesLoadedCB) {
				initialStudiesLoadedCB();
				initialStudiesLoadedCB = null;
			}
		}

		function getUploadHandler() {
			return uploadHandler;
		}

		function registerMeasurementInfoHandler(handler) {
			measurementInfoHandler = handler;
		}

		function getMeasurementInfoHandler() {
			return measurementInfoHandler;
		}

		function registerVisitEventHandler(handler) {
			visitEventHandler = handler;
		}

		function getVisitEventHandler() {
			return visitEventHandler;
		}
		function registerEffortsHandler(handler) {
			effortsHandler = handler;
		}

		function getEffortsHandler() {
			return effortsHandler;
		}

		function registerRefresh(handler) {
			refreshHandler = handler;
		}

		function refreshGrid() {
			if (refreshHandler) {
				refreshHandler();
			}
		}

		function isShowEntryHandlerRegistered() {
			return showEntryHandler;
		}

		function registerShowEntry(handler) {
			showEntryHandler = handler;
		}

		function unregisterShowEntry(handler) {
			if (showEntryHandler == handler) {
				$log.debug('unregister show entry handler');
				showEntryHandler = null;
			} else {
				$log.debug('show entry handler alredy overwritten with new handler');
			}
		}
		function showEntry(entry, index) {
			if (showEntryHandler && entry) {
				showEntryHandler(entry, index);
			}
		}

		function registerShowAuditReport(handler) {
			showAuditReportHandler = handler;
		}

		function unregisterShowAuditReport(handler) {
			if (showAuditReportHandler == handler) {
				$log.debug('unregister show entry handler');
				showAuditReportHandler = null;
			} else {
				$log.debug('show entry handler alredy overwritten with new handler');
			}
		}
		function showAuditReport() {
			if (showAuditReportHandler) {
				showAuditReportHandler();
			}
		}

		function gotoListView() {
			$state.go(appConfig.routes.cdePftList, {'signKey': null, 'tokenId': null}, { inherit: false });
		}

		function gotoNewEntry(mode) {
			$state.go(appConfig.routes.cdePftNewEntry, {'signKey': null, 'tokenId': null, 'cdeMode': mode});
		}

		function getCdeMode() {
			return $stateParams.cdeMode;
		}

        function matchSelectValueToModel(dataModelRef, displayName) {
            if (_.isFunction(displayName)) {
                for (var x = 0; x < dataModelRef.length; x++) {
                    dataModelRef[x].selectedValue = displayName(dataModelRef[x]);
                }
                return dataModelRef;
            }
            for (var x = 0; x < dataModelRef.length; x++) {
                dataModelRef[x].selectedValue = dataModelRef[x][displayName];
            }
            return dataModelRef;
        }
	}
})();
