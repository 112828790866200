import tplUrl from './deviceManagement.code.popup.html';
export default () => {
	return {
		scope: {
			internal: '=',
			type: '=',
			visible: '='
		},
		link,
		controller,
		controllerAs: 'vm',
		bindToController: true,
		restrict: 'A',
		templateUrl: tplUrl
	};

	function link() {
		'ngInject';

	}

	function controller(clinicalDataService, appI18n, $log, $filter) {
		'ngInject';
		const momentDateFormat = 'DD-MMM-YYYY';
		const vm = this;

		vm.isLoading = true;

		vm.model = {};
		vm.clinicalDataService = clinicalDataService;
		vm.siteChanged = siteChanged;
		vm.subjectChanged = subjectChanged;
		vm.dateChanged = dateChanged;
		vm.appI18n = appI18n;

		vm.close = () => {
			vm.visible = false;

		};

		switch (vm.type) {
			case 'siteUnlockCode':
				vm.isLoading = false;
				vm.dateTableCaptionDate = appI18n.translateImmediate('deviceManagement.labels.date');
				vm.dateTableCaptionCode = appI18n.translateImmediate('deviceManagement.labels.unlockCode');
				vm.description = appI18n.translateImmediate('deviceManagement.descriptions.siteUnlockCode');
				vm.isValid = (model) => {
					return model.date;
				};
				vm.getCode = getUnlockCode;
				setDate();
				break;
			case 'startUpCode':
				vm.showCodeTable = true;
				vm.codeTableCaptionCode = appI18n.translateImmediate('deviceManagement.labels.ecoaTabletStartupCode');
				vm.codeTableCaptionDesc = appI18n.translateImmediate('deviceManagement.labels.site');
				vm.description = appI18n.translateImmediate('deviceManagement.descriptions.startUpCode');
				vm.isValid = (model) => {
					return model.siteId;
				};
				vm.getCode = (model) => {
					vm.codesLoaded = false;
					clinicalDataService.
						getEcoaTabletStartupCode(model.siteId)
						.then((result) => {
							vm.displayTextForCode = vm.selectedSite.displaySiteName;
							if (result.data) {
								vm.code = result.data;
							} else {
								vm.code = '';
								vm.errors = appI18n.translateImmediate('deviceManagement.errors.noStartUpCode');
							}
							vm.codesLoaded = true;
						}, (error) => {
							$log.error('error in getEcoaTabletStartupCode: {0}', error);
							vm.codesLoaded = true;
						});
				};
				setSites();
				break;
			case 'adminAccessCode':
				vm.showCodeTable = true;
				vm.codeTableCaptionCode =
					appI18n.translateImmediate('deviceManagement.labels.accessCodeForAdminGateway');
				vm.codeTableCaptionDesc = appI18n.translateImmediate('deviceManagement.labels.site');
				vm.description = appI18n.translateImmediate('deviceManagement.descriptions.adminAccessCode');
				vm.isValid = (model) => {
					return model.siteId;
				};
				vm.getCode = (model) => {
					vm.codesLoaded = false;
					clinicalDataService.getEcoaHandheldAdminAccessCode(model.siteId)
						.then((result) => {
							vm.displayTextForCode = vm.selectedSite.displaySiteName;
							if (result.data) {
								vm.code = result.data;
							} else {
								vm.code = '';
								vm.errors = appI18n.translateImmediate('deviceManagement.errors.noAdminCode');
							}
							vm.codesLoaded = true;
						}, (error) => {
							$log.error('error in getEcoaHandheldAdminAccessCode: {0}', error);
							vm.codesLoaded = true;
						});
				};
				setSites();
				break;
			case 'temporaryAccessCode':
				vm.isLoading = false;
				vm.dateTableCaptionDate = appI18n.translateImmediate('deviceManagement.labels.date');
				vm.dateTableCaptionCode = appI18n.translateImmediate('deviceManagement.labels.resetCode');
				vm.description = appI18n.translateImmediate('deviceManagement.descriptions.temporaryAccessCode');
				vm.isValid = (model) => {
					return model.date;
				};
				vm.getCode = getUnlockCode;
				setDate();
				break;
		}

		function getUnlockCode(model) {
			vm.codesLoaded = false;
			vm.today = moment(model.date, momentDateFormat).toDate();
			vm.yesterday = new Date(vm.today).setDate(vm.today.getDate() - 1);
			vm.tomorrow = new Date(vm.today).setDate(vm.today.getDate() + 1);
			clinicalDataService.getUnlockCode(model.date)
				.then((result) => {
					if (result.data && result.data.length === 3) {
						vm.codes = result.data;
						vm.codeYesterday = vm.codes[0].code;
						vm.codeToday = vm.codes[1].code;
						vm.codeTomorrow = vm.codes[2].code;
					} else {
						vm.codeYesterday = '';
						vm.codeToday = '';
						vm.codeTomorrow = '';
						vm.errors = appI18n.translateImmediate('deviceManagement.errors.noUnlockCode');
					}
					vm.codesLoaded = true;
				}, (error) => {
					$log.error('error in getUnlockCode: {0}', error);
					if (error && error.data) {
						vm.errors = error.data.message ||
							appI18n.translateImmediate('deviceManagement.errors.noUnlockCode');
					}
					vm.codesLoaded = true;
				});
		}

		function validateAndShowCodes() {
			if (vm.isValid && vm.isValid(vm.model)) {
				vm.getCode(vm.model);
			}
		}

		function dateChanged(date) {
			if (date && moment(date, momentDateFormat).isValid()) {
				validateAndShowCodes();
				vm.errors = '';
			} else {
				vm.errors = appI18n.translateImmediate('deviceManagement.errors.invalidDate');
			}

		}

		function siteChanged(siteId) {
			if (vm.showSubjectSelection) {
				vm.notes = '';
				setSubjects(siteId);
			}
			vm.selectedSite = vm.sites.find((site) => {
				return site.siteId === siteId;
			});
			validateAndShowCodes();
		}

		function subjectChanged(subjectId) {
			const id = parseInt(subjectId);

			vm.selectedSubject = vm.subjects.find((subject) => {
				return subject.subjectId === id;
			});
			validateAndShowCodes();
		}

		function setSites() {
			vm.showSiteSelection = true;
			vm.clinicalDataService.getSitesMinimal()
				.then ((response) => {
					vm.sites = response.data;
					vm.isLoading = false;
					if (vm.sites && vm.sites.length === 1) {
						vm.model.siteId = vm.sites[0].siteId;
						vm.selectedSite = vm.sites[0];
						if (vm.showSubjectSelection) {
							vm.selectedSubject = {};
							setSubjects(vm.selectedSite.siteId);
						} else {
							validateAndShowCodes();
						}
					}
				}, (error) => {
					$log.error('error in getSitesMinimal: {0}', error);
				});
		}

		function setSubjects(siteId) {
			vm.clinicalDataService.getSubjectsMinimal(null, siteId)
				.then ((response) => {
					vm.subjects = response.data.filter((subject) => {
						return subject.eproSubjectId;
					});
					if (vm.subjects.length !== response.data.length) {
						vm.notes = vm.appI18n.translateImmediate('deviceManagement.errors.subjectsFiltered');
					}

					if (vm.subjects && vm.subjects.length === 1) {
						vm.model.subjectId = vm.subjects[0].subjectId.toString();
						vm.selectedSubject = vm.subjects[0];
						validateAndShowCodes();
					} else {
						vm.model.subjectId = '';
					}

				}, (error) => {
					$log.error('error in getSubjectsMinimal: {0}', error);
				});
		}

		function setDate() {
			vm.showDatePicker = true;
			vm.showCodeDateTable = true;
			vm.model.date = $filter('date')(Date.now(), 'dd-MMM-yyyy');
			validateAndShowCodes();
		}
	}

};
