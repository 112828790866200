window.Rx = require('rx');

var angular = require('angular');
var templates = require('templates');

// AngularJs libraries
var ngCookies = require('angular-cookies');
var ngMessages = require('angular-messages');
var ngResource = require('angular-resource');
var ngAnimate = require('angular-animate');
var ngSanitize = require('angular-sanitize');
var ngTouch = require('angular-touch');

// third-party
var uiRouter = require('angular-ui-router');
var ngTranslate = require('angular-translate');
require('angular-wizard');
var formly = require('angular-formly');
var formlyBootstrap = require('angular-formly-templates-bootstrap');
var uiMask = require('angular-ui-mask');

require('angular-file-upload');
require('../scripts/directives/imageCropper');

var common = require('../common/common').default;
var components = require('../components/components').default;

//TODO: move this to separate file(s)
angular.module('appToast.directives', []);
angular.module('appToast.provider', []);
angular.module('appToast', [
	'ngSanitize',
	'appToast.directives',
	'appToast.provider'
]);

module.exports = angular.module('portalApp', [
	uiRouter,
	ngTranslate, //'pascalprecht.translate',
	'mgo-angular-wizard', //'mgo-angular-wizard',
	ngMessages, //'ngMessages',
	ngCookies, //'ngCookies',
	ngResource, //'ngResource',
	ngAnimate, //'ngAnimate',
	ngSanitize, //'ngSanitize'
	ngTouch,
	'appToast',
	formly, //'formly',
	formlyBootstrap, //'formlyBootstrap',
	uiMask, //'ui.mask',
	'angularFileUpload',
	'imageCropper',
	components, //all components
	common,
	templates.default
]).name;

