import openToggleDirective from './openToggle.directive';

openToggleDirective.$inject = ['$document'];

const openToggleModule =
	  angular.module(
		  'app.common.openToggle',
		  [])
	.directive('openToggle', openToggleDirective)
	.name;

export default openToggleModule;
