/*
 * announcementsDetailsPopupComponent
 * */

import templateUrl from './announcementsDetailsPopup.html';
import controller from './announcementsDetailsPopup.controller';
import './announcementsDetailsPopup.scss';

const announcementsDetailsPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default announcementsDetailsPopupComponent;
