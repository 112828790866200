/*
 * globalMessageModule
 * */

import globalMessageComponent from './globalMessage.component';
import globalMessageService from './globalMessage.service';

const globalMessageModule = angular.module('app.common.globalMessage', [])
	.component('globalMessage', globalMessageComponent)
	.service('globalMessageService', globalMessageService)
	.name;

export default globalMessageModule;
