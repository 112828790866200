/*
 * dispenseKardiaPopupModule
 * */

import dispenseKardiaPopupComponent from './dispenseKardiaPopup.component';

const dispenseKardiaPopupModule = angular.module('app.components.dispenseKardiaPopup', [])
	.component('dispenseKardiaPopup', dispenseKardiaPopupComponent)
	.name;

export default dispenseKardiaPopupModule;
