class ReportReview {

	constructor($log, $scope, $state, $filter, appConfig, appI18n, reportsService,
				documentService, loadingIndicatorService,
				analyticsService, notificationService, pollingService, configurationService) {
		'ngInject';
		const self = this;

		this.selectedRows = [];
		this.log = $log;
		this.$scope = $scope;
		this.$state = $state;
		this.$filter = $filter;
		this.loadingIndicatorService = loadingIndicatorService;
		this.appConfig = appConfig;
		this.reportsService = reportsService;
		this.analyticsService = analyticsService;
		this.pollingService = pollingService;
		this.notificationService = notificationService;
		this.documentService = documentService;
		this.configurationService = configurationService;
		this.appI18n = appI18n;
		this.columnDefsStatic =
			 [{
				translationKey: 'reports.report-review.columns.report-type',
				fieldName: 'testDataTypeName',
				isSortable: true,
				type: 'string'
			}, {
				translationKey: 'reports.report-review.columns.report-name',
				fieldName: 'documentTypeName',
				isSortable: true,
				type: 'string'
			},	{
				 translationKey: 'reports.report-review.columns.reportVersion',
				 fieldName: 'versionNumber',
				 isSortable: true,
				 type: 'number'
			 }, {
				translationKey: 'reports.report-review.columns.sponsor-site-id',
				fieldName: 'sponsorSiteId',
				isSortable: true,
				type: 'string'
			}, {
				translationKey: 'app.common.subjectKey',
				fieldName: 'subjectKey',
				isDefaultFilter: true,
				isSortable: true,
				type: 'string'
			}, {
				translationKey: 'reports.report-review.columns.visit-name',
				fieldName: 'visitName',
				isSortable: true,
				type: 'string'
			}, {
				translationKey: 'reports.report-review.columns.event-name',
				fieldName: 'eventName',
				isSortable: true,
				type: 'string'
			}, {
				translationKey: 'reports.report-review.columns.event-date',
				fieldName: 'eventDate',
				isSortable: true,
				type: 'date',
				formatter: 'dateTimeFormat'
			}, {
				translationKey: 'reports.report-review.columns.revised',
				fieldName: 'isRevised',
				isSortable: true,
				type: 'enum',
				formatter: 'yesNo',
				enumType: 'bool',
				enums: [{
					displayName: 'Yes',
					values: [1]
				}, {
					displayName: 'No',
					values: [-1]
				}],
				showOnlyInFilter: true
			}, {
				translationKey: 'reports.report-review.columns.first-complete-date',
				fieldName: 'firstCompleteDateTime',
				isSortable: true,
				type: 'date',
				formatter: 'dateTimeFormat'
			}, {
				translationKey: 'reports.report-review.columns.last-complete-date',
				fieldName: 'lastCompleteDateTime',
				isSortable: true,
				type: 'date',
				formatter: 'dateTimeFormat'
			}];

		this.options = {
			sortOrders: [{
				fieldName: 'lastCompleteDateTime',
				reverseOrder: true
			}],
			rowDefs: {
				multiSelection: true,
				identifier: 'id'
			},
			gridActions: [{
				displayName: `${self.appI18n.translateImmediate('clinicaldata.visit.download.gridAction')}`,
				subItems: [
					{
						displayName: self.appI18n.translateImmediate(
							'reports.report-review.actions.downloadSelectedPdf'),
						action: () => {
							if (self.selectedRows.length > 0) {
								const reportIds = [];

								self.selectedRows.forEach((el) => {
									reportIds.push(el.id);
								});
								self.analyticsService.trackEvent(
									`${self.appConfig.trackCategory.reportReview}::sync::pdf`,
									reportIds.length);
								self.documentService.getEventReportsForReportIds(reportIds, false)
									.then((response) => {
										self.documentService.downloadReport(
											response.data, self.$scope,
											self.appConfig.downloadDocumentType.reviewReport, reportIds.length);
									});
							}
						},
						enabledIf: () => {
							return self.selectedRows && self.selectedRows.length > 0;
						},
						showNumberSelected: true
					},
					{
						displayName: self.appI18n.translateImmediate(
							'reports.report-review.actions.downloadSelectedZip'),
						action: () => {
							if (self.selectedRows.length > 0) {
								const reportIds = [];

								self.selectedRows.forEach((el) => {
									reportIds.push(el.id);
								});
								self.analyticsService.trackEvent(
									`${self.appConfig.trackCategory.reportReview}::sync::zip`, reportIds.length);
								self.documentService.getEventReportsForReportIds(reportIds, true)
									.then((response) => {
										self.documentService.downloadReport(
											response.data, self.$scope,
											self.appConfig.downloadDocumentType.reviewReport, reportIds.length);
									});
							}
						},
						enabledIf: () => {
							return self.selectedRows && self.selectedRows.length > 0;
						},
						showNumberSelected: true
					},
					{
						displayName: self.appI18n.translateImmediate('reports.report-review.actions.downloadPopup'),
						action: () => {
							self.downloadPopupApi
								.show({
									headerTitle: self.appI18n.translateImmediate('reports.report-review.popup.title')
								})
								.then((response) => {
									if (response === 'ok') {
										self.notificationService.showSuccess(
											self.appI18n.translateImmediate(
												'reports.report-review.actions.downloadConfirmation.message')
										);
										self.pollingService.restart();
									}
								});
						},
						enabledIf: () => {
							return true;
						}
					}
				]
			}],
			gridRowActions: [{
				displayName: 'Reports',
				iconCssClass: 'es-icon es-icon-reports',
				enabledIf: (row) => {
					return row.id;
				},
				subItemsFactory: (row) => {
					if (row.reportReviewReport) {
						return row.reportReviewReport;
					}
					const reportReviewReport = [];

					reportReviewReport.push({
						displayName: row.documentDisplayName,
						iconFileType: row.fileFormatName,
						action: () => {
							self.documentService.getEventReport(
								row.siteId, row.subjectId, row.id)
								.then((response) => {
									self.documentService.downloadReport(
										response.data, self.$scope,
										self.appConfig.downloadDocumentType.reviewReport);
								});
						}
					});

					row.reportReviewReport = reportReviewReport;

					return reportReviewReport;
				}
			}
							],

			getData: (options) => {
				if (this.hasReportConfirmation) {
					return self.reportsService.getAllReportsConfirmation(options);
				} else {
					return self.reportsService.getAllReports(options);
				}
			}
		};

		this.downloadPopupComponentReady = function ($API) {
			self.downloadPopupApi = $API;
		};

		this.confirmationPopupComponentReady = function ($API) {
			self.confirmationPopupApi = $API;
		};

		this.reportsService.setTitle(this.appI18n.translateImmediate('reports.report-review.main-title'));
	}

	loadColumnConfig() {
		return this.configurationService.getUserStudySettings().then((response) => {
			this.hasReportConfirmation = response.data.studyConfig.hasReportConfirmation;
			this.numberOfReportsToConfirm = response.data.numberOfReportsToConfirm;

			if (this.hasReportConfirmation) {
				this.columnDefsStatic.push(
					{
						translationKey: 'reports.report-review.columns.confirmed',
						fieldName: 'confirmationStatus',
						type: 'enum',
						isSortable: true,
						formatter: 'yesNoNa',
						enumType: 'bool',
						enums: [{
							translationKey: 'app.common.yes',
							values: [1]
						}, {
							translationKey: 'app.common.no',
							values: [-1]
						}, {
							translationKey: 'app.common.na',
							values: [0]
						}]
					});
				this.columnDefsStatic.push(
					{
						translationKey: 'reports.report-review.columns.dateReviewed',
						fieldName: 'confirmationDate',
						isSortable: true,
						formatter: 'dateTimeFormat',
						type: 'date'
					});
				this.columnDefsStatic.push(
					{
						translationKey: 'reports.report-review.columns.reviewedBy',
						fieldName: 'confirmedByFullName',
						isSortable: true,
						type: 'string'
					});

				this.options.gridRowActions.push(
					{
						displayName: 'reports.report-review.actions.confirm',
						iconCssClass: 'es-icon es-icon-report-confirm',
						enabledIf: (row) => {
							return row.shouldConfirm === 1;
						},
						action: (row) => {
							this.openConfirmSingle(row);
						}
					});

				this.columnDefsStatic.push(
					{
						translationKey: 'reports.report-review.columns.requiresConfirmation',
						fieldName: 'shouldConfirm',
						type: 'enum',
						enumType: 'bool',
						formatter: 'yesNo',
						showOnlyInFilter: true,
						enums: [
							{ translationKey: 'app.common.yes', values: [1] },
							{ translationKey: 'app.common.no', values: [-1] }
						]

					});

				this.options.gridActions.push({
					displayName: 'reports.report-review.actions.confirmSelected',
					iconCssClass: 'es-icon-report-confirm',
					action: () => {
						this.openConfirmSelected();
					},
					isDisabled: () => {
						const selectedRows = this.getSelectedRows();

						return selectedRows.length === 0 ||
							!_.some(selectedRows, { 'shouldConfirm': 1 });
					}
				});


			}

			if (this.numberOfReportsToConfirm > 0) {
				this.options.preSelectedFilters =
					[{
						fieldName: 'shouldConfirm',
						displayName: 'Requires Approval',
						filterConditions: [
							{
								displayValue: 'Yes',
								filterValue: 1,
								operator: 'eq',
								enumType: 'number',
								type: 'enum'
							}
						]
					}
					];
			}

			this.options.columnDefs = this.columnDefsStatic;
		});
	}

	getSelectedRows() {
		return this.selectedRows;
	}

	openConfirmSelected() {
		const selectedRows = this.getSelectedRows();

		this.confirmationPopupApi
			.show({
				headerTitle: this.appI18n.translateImmediate('reports.report-review.confirmationMultiple.popup.title'),
				reportRows: selectedRows
			})
			.then((response) => {
				if (response === 'ok') {
					this.refreshGrid();
					this.notificationService.showSuccess(
						this.appI18n.translateImmediate(
							'reports.report-review.actions.confirmationConfirmation.messagePlural')
					);
				}
			});
	}

	openConfirmSingle(row) {
		this.confirmationPopupApi
			.show({
				headerTitle: this.appI18n.translateImmediate('reports.report-review.confirmationSingle.popup.title'),
				reportId: row.id
			})
			.then((response) => {
				if (response === 'ok') {
					this.refreshGrid();
					this.notificationService.showSuccess(
						this.appI18n.translateImmediate(
							'reports.report-review.actions.confirmationConfirmation.message')
					);
				}
			});
	}

	refreshGrid() {
		this.reload = true;
	}

	$onInit() {
		this.loadingIndicatorService.show();
		this.loadColumnConfig().then(() => {
			this.load = true;
		}, (error) => {
			this.$log.debug(`error loading column config: ${error}`);
		}).finally(() => {
			this.loadingIndicatorService.hide();
		});
	}

	$onChanges() {}
}

export default ReportReview;
