﻿/**
 * @ngInject
 */
 module.exports = function(appConfig) {
    var vm = this;
	vm.sessionToken = null;

	return {
		setSessionToken: setSessionToken,
		getSessionToken: getSessionToken,
	};

	function getSessionToken() {
		var storedSession = localStorage.getItem(appConfig.localStorageKeys.session);
		return storedSession ? vm.sessionToken : null;
	}

	function setSessionToken(sessionToken) {
		vm.sessionToken = sessionToken;
	}
};
