/*
 * enrollmentGraphWidgetModule
 * */

import enrollmentGraphWidgetComponent from './enrollmentGraphWidget.component';

const enrollmentGraphWidgetModule = angular.module('app.components.dashboard.enrollmentGraphWidget', [])
	.component('enrollmentGraphWidget', enrollmentGraphWidgetComponent)
	.name;

export default enrollmentGraphWidgetModule;
