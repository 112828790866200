/*
 * dataManagementFilesComponent
 * */

import templateUrl from './dataManagementFiles.html';
import controller from './dataManagementFiles.controller';
import './dataManagementFiles.scss';

const dataManagementFilesComponent = {
	bindings: {
		viewConfig: '<?'
	},
	templateUrl,
	controller
};

export default dataManagementFilesComponent;
