(function() {

	'use strict';

	angular
		.module("portalApp")
		.controller("SqfListCtrl", SqfListController);

	/**
	 * @ngInject
	 */
	function SqfListController($scope, $log, $state, $q, $stateParams, sqfService, sqfTranslation,
							   dialogService, configurationService, appConfig, loadingIndicatorService
							  ) {

		$log = $log.getInstance("SqfListCtrl", "color:purple");
		$log.debug("SqfListCtrl:: loaded");

		var vm = this;

		if ($state.current.name === appConfig.routes.sqfList) {
			$state.go(appConfig.routes.sqfListSaved);
		}
		vm.dataLoading = true;
		vm.loadingError = '';
		vm.model = {};
		vm.options = {};
		vm.errorMsg = "";
		vm.localized = {gridColumns: {}, list: {}};
		vm.moduleLabel = '';
		vm.createNewSqf = createNewSqf;
		$scope.refreshGrid = refreshGrid;
		vm.confirmHandler = {showDialog: false};
		vm.newSqfConfirmHandler = {showDialog: false};
		vm.savedSqfList = {
			gridActions: [],
			sortOrders: [{
				fieldName: 'updatedTimestamp',
				reverseOrder: true
			}, {
				fieldName: 'savedTimestamp',
				reverseOrder: true
			}],

			defaultRowAction: openSqf,

			getData: function (options) {
				return $q(function (resolve, reject) {
					loadData(false, options, resolve, reject);
				});
			}
		};
		vm.submittedSqfList = {
			gridActions: [],
			preSelectedFilters: [],
			sortOrders: [{
				fieldName: 'submittedTimestamp',
				reverseOrder: true
			}],

			defaultRowAction: openSqf,

			getData: function (options) {
				return $q(function (resolve, reject) {
					loadData(true, options, resolve, reject);
				});
			}
		};



		activate();
		function activate() {
			vm.moduleLabel = loadSiteMapName();
			vm.studyName = sqfService.getStudyName();
			sqfTranslation.getListViewLocalized().then(function (localized) {
				vm.localized = localized;

				vm.submittedSqfList.columnDefs = [
					{displayName: localized.gridColumns.siteId, fieldName: 'siteId', isSortable: true, type: 'string', isDefaultFilter: true, isPrimary: true},
					{displayName: localized.gridColumns.principalInvestigator, fieldName: 'principalInvestigatorName', isSortable: true, type: 'string'},
					{displayName: localized.gridColumns.status, fieldName: 'isCompleted', isSortable: true, type: 'enum', showReverseSortOrder: true,
					 formatter: 'completeIncomplete', enumType: 'bool', enums: [
						{displayName: 'Complete', values:[1]},
						{displayName: 'Incomplete', values:[-1]},
					]},
					{displayName: localized.gridColumns.created, fieldName: 'savedTimestamp', isSortable: true, type: 'date', formatter: 'dateTimeFormat'},
					{displayName: localized.gridColumns.modified, fieldName: 'updatedTimestamp', isSortable: true, type: 'date', formatter: 'dateTimeFormat'},
					{displayName: localized.gridColumns.submitted, fieldName: 'submittedTimestamp', isSortable: true, type: 'date', formatter: 'dateTimeFormat'}
				];

				vm.submittedSqfList.gridRowActions = [
					{
						displayName: localized.list.view,
						iconCssClass: 'es-icon-info-circle',
						action: openSqf
					}
				];

				vm.savedSqfList.columnDefs = [
					{displayName: localized.gridColumns.siteId, fieldName: 'siteId', isSortable: true, type: 'string', isDefaultFilter: true, isPrimary: true },
					{displayName: localized.gridColumns.principalInvestigator, fieldName: 'principalInvestigatorName', isSortable: true, type: 'string'},
					{displayName: localized.gridColumns.status, fieldName: 'isCompleted', isSortable: true, type: 'enum', showReverseSortOrder: true,
					 formatter: 'completeIncomplete', enumType: 'bool', enums: [
						 {displayName: 'Complete', values:[1]},
						 {displayName: 'Incomplete', values:[-1]},
					 ]},
					{displayName: localized.gridColumns.created, fieldName: 'savedTimestamp', isSortable: true, type: 'date', formatter: 'dateTimeFormat'},
					{displayName: localized.gridColumns.modified, fieldName: 'updatedTimestamp', isSortable: true, type: 'date', formatter: 'dateTimeFormat'}
				];

				vm.savedSqfList.gridRowActions = [
					{
						displayName: localized.list.edit,
						iconCssClass: 'es-icon-function-edit',
						action: openSqf
					},
					{
						displayName: localized.list.delete,
						iconCssClass: 'es-icon-function-delete',
						action: deleteSqf
					}
				];

				if ($stateParams.popup === appConfig.popups.sqfWizard && $stateParams.popupParam) {
					vm.submittedSqfList.preSelectedFilters = [{
						fieldName: 'siteId',
						displayName: localized.gridColumns.siteId,
						filterConditions: [
							{
								displayValue: $stateParams.popupParam,
								filterValue: $stateParams.popupParam,
								operator: 'eq',
								type: 'string'
							}
						]
					}];

					var unregister = $scope.$watch('vm.submittedSqfList.rowData', function() {
						if (vm.submittedSqfList.rowData && vm.submittedSqfList.rowData.length === 1) {
							vm.submittedSqfList.defaultRowAction(vm.submittedSqfList.rowData[0]);
							unregister();
						}
					});
				}
				$scope.$parent.vm.actions = [];
				if ($state.current.name === appConfig.routes.sqfListSaved) {
					$scope.$parent.vm.actions.push({
						id: 'newSqf',
						displayName: vm.localized.list.newSqf,
						action: createNewSqf,
						disabled: true,
						cssClass: '-es-primary',
						iconClass: 'es-icon-site-qualification-form'
					});
				}

				loadGridData();

			});
		}
		function loadSiteMapName() {
			configurationService.getSiteMapLabel(appConfig.siteMapModuleIds.sqf).then(
				function (label) {
					vm.moduleLabel = label;
				}
			);
		}

		function loadGridData() {
			switch ($state.current.name) {
				case appConfig.routes.sqfListSaved:
					vm.loadSaved = true;
					break;
				case appConfig.routes.sqfListSubmitted:
					vm.loadSubmitted = true;
					break;
			}
		}

		function openSqf(row) {
			sqfService.showSqfWizard(row.id, vm.options.config.hasAdditionalSiteInfo,
									 vm.options.config.hasAdditionalSiteInfoAfterSelection);
		}

		function createNewSqf() {
			vm.newSqfConfirmHandler = dialogService.createConfirmHandler();
			vm.newSqfConfirmHandler.show().then(function (result) {
				if (result === true) {
					vm.newSqfConfirmHandler.hide();
					sqfService.showSqfWizard(0, vm.options.config.hasAdditionalSiteInfo,
											 vm.options.config.hasAdditionalSiteInfoAfterSelection);
				}
			});
		}

		function deleteSqf(row) {
			vm.confirmHandler = dialogService.createConfirmHandler();
			vm.confirmHandler.show().then(function (result) {
				if (result === true) {
					loadingIndicatorService.show();
					sqfService.deleteSqf(row.id)
						.then(function () {
							vm.confirmHandler.hide();
							loadingIndicatorService.hide();
							vm.createShown = true;
							refreshGrid();
						}, function (error) {
							loadingIndicatorService.hide();
							vm.confirmHandler.showErrorMessages(error.data.messages);
						});
				}
			});
		}

    function refreshGrid() {
			switch ($state.current.name) {
				case appConfig.routes.sqfListSaved:
					vm.reloadSaved = true;
					break;
				case appConfig.routes.sqfListSubmitted:
					vm.reloadSubmitted = true;
					break;
			}
		}

		function loadData(submitted, options, resolve, reject) {
			vm.dataLoading = true;
			vm.loadingError = '';
			sqfService.getSqfs(submitted, options)
				.then(function (response) {
					vm.model = response.data.model;
					vm.options.config = response.data.config;
					vm.dataLoading = false;
					if ($scope.$parent.vm.actions && $scope.$parent.vm.actions.length) {
						$scope.$parent.vm.actions[0].disabled = !vm.options.config.allowSubmit;
					}
					resolve(vm.model);
					// show sqf only if submit is allowed
					if (vm.options.config.allowSubmit && vm.options.config.showNewSqf === true &&
							!vm.createShown) {
						vm.createShown = true;
						createNewSqf();
					}
				}, function (error) {
					vm.loadingError = error.data.message || error.data;
					reject(vm.loadingError);
				});
		}
	}
})();
