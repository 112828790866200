(function () {
  'use strict';

  angular
    .module("portalApp")
    .service("emailConfirmationService", EmailConfirmationService);

  EmailConfirmationService.$inject = ['$log', '$http', 'appConfig'];

  function EmailConfirmationService($log, $http, appConfig) {
    $log = $log.getInstance("EmailConfirmationService");

    var service = {
		preStartRegistration: preStartRegistration,
      startRegistration: startRegistration,
      loadRegistration: loadRegistration,
      confirmRegistration: confirmRegistration,
      resendConfirmation: resendConfirmation
    };

    return service;

	  function preStartRegistration(email, firstName, lastName, qualificationPin) {
		  var url = appConfig.apiUrls.emailConfirmation.preStartRegistration;
		  var postData = {
			  'email': email,
			  'firstName': firstName,
			  'lastName': lastName,
			  'qualificationPin': qualificationPin
		  };
		  return $http.post(url, postData);
	  }
    function startRegistration(email, firstName, lastName, qualificationPin) {
      var url = appConfig.apiUrls.emailConfirmation.startRegistration;
			var postData = {
        'email': email,
        'firstName': firstName,
        'lastName': lastName,
        'qualificationPin': qualificationPin
      };
      return $http.post(url, postData);
    }

    function loadRegistration(token) {
      var url = appConfig.apiUrls.emailConfirmation.loadRegistration;
      return $http.post(url, "'" + token + "'");
    }

    function resendConfirmation(email) {
      var url = appConfig.apiUrls.emailConfirmation.resendConfirmation;
      return $http.post(url, "'" + email + "'");
    }

    function confirmRegistration(email, token, password) {
      var url = appConfig.apiUrls.emailConfirmation.confirmRegistration;
			var postData = {
				username: email,
				token: token,
				password: password
			};
      return $http.post(url, postData);
    }

  }
})();

