/*
 * simpleDatePickerComponent
 * */

import templateUrl from './simpleDatePicker.html';
import controller from './simpleDatePicker.controller';
import './simpleDatePicker.scss';

const simpleDatePickerComponent = {
	bindings: {
		model: '<',
		onUpdate: '&'
	},
	transclude: true,
	templateUrl,
	controller
};

export default simpleDatePickerComponent;
