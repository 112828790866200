/*
 * esFormLegendModule
 * */

import esFormLegendComponent from './esFormLegend.component';

const esFormLegendModule = angular.module('app.common.esFormLegend', [])
	.component('esFormLegend', esFormLegendComponent)
	.name;

export default esFormLegendModule;
