export default class QrCode {
	make(element, qrCode, width, height) {
		new QRCode(element, {
			text: qrCode,
			width: width || 128,
			height: height || 128,
			colorDark: '#000000',
			colorLight: '#ffffff',
			correctLevel: QRCode.CorrectLevel.L
		});
	}

	static id() {
		return 'qrCodeService';
	}

}
