const base64 = require('Base64');

export default class SignatureService {
	constructor($q, $location, $window, $state, $log, appConfig, notificationService, appI18n,
				cacheManager, ssoService, loadingIndicatorService, appSession) {
		'ngInject';
		this.cacheManager = cacheManager;
		this.$location = $location;
		this.$state = $state;
		this.$log = $log;
		this.notificationService = notificationService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.ssoService = ssoService;
		this.$window = $window;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.appSession = appSession;
		this.$q = $q;
	}

	static id() {
		return 'signatureService';
	}

	saveState(sigType, signKey, state) {
		if (signKey && sigType && state) {
			state.sigType = sigType;
			this.cacheManager.put(signKey, state, { sessionStorage: true });
		}
	}

	getState(signKey) {
		let state;

		if (signKey) {
			state = this.cacheManager.get(signKey, { sessionStorage: true });
		}
		if (!state) {
			this.removeStateParams();
		}

		return state;
	}

	checkSignatureWorkflow(signKey, tokenId, onSuccess, onCancel, context) {
		if (signKey) {
			const state = this.getState(signKey);

			// reset state
			if (state) {
				this.removeState(signKey);

				// signature was cancelled
				if (!this.validateSignature(tokenId, signKey)) {
					onCancel && onCancel.apply(context, [state]);
					return true;
				}

				// signature ok
				onSuccess && onSuccess.apply(context, [state]);

				// return true only if the state was available
				return true;
			}
		}
		return false;
	}

	checkSignatureWorkflowAsync(signKey, tokenId) {
		if (signKey) {
			const state = this.getState(signKey);

			// reset state
			if (state) {
				this.removeState(signKey);

				// signature was cancelled, perform state restore
				if (!this.validateSignature(tokenId, signKey)) {
					return this.$q.reject({ signature: { valid: false, state } });
				}
				// signature ok perform submit
				return this.$q.reject({ signature: { valid: true, state } });
			}

			return this.$q.resolve(null);
		}
		// perform normal initialization
		return this.$q.resolve(null);
	}

	validateSignature(token, signKey) {
		if (!token || !signKey) {
			this.notificationService.showWarning(
				this.appI18n.translateImmediate('app.common.signature-cancel'));
			return false;
		}
		return true;
	}

	removeState(signKey) {
		if (signKey) {
			this.cacheManager.remove(signKey, { sessionStorage: true });
		}
	}

	removeSignatureAndState(signKey) {
		this.removeStateParams();
		this.removeState(signKey);
	}

	removeStateParams() {
		this.$location.search('signKey', null);
		this.$location.search('tokenId', null);
	}

	get isDisableSignature() {
		return this.appConfig.developmentSettings &&
			this.appConfig.developmentSettings.disableSignature;
	}

	sign(sigType, additionalInfo, state) {

		this.loadingIndicatorService.show();
		if (this.isDisableSignature) {
			const signKey = 'disabled';

			return new Promise((resolve) => {
				this.loadingIndicatorService.hide();
				this.saveState(sigType, signKey, state);
				this.$location.search('signKey', 'disabled');

				if (this.$window.confirm('Debug mode: Click Yes to simulate confirm process')) {
					this.$location.search('tokenId', 'disabled');
					this.$window.location.reload();
					resolve({ submit: true });
				} else {
					this.$window.location.reload();
					resolve({ submit: false });
				}
			});
		} else {
			const signKey = new Date().getTime();

			this.saveState(sigType, signKey, state);
			this.$location.search('signKey', signKey);
			this.$location.search('tokenId', null);

			const token = this.appSession.getSessionToken();
			const redirectState = this.appSession.get().state;

			redirectState.destinationUrl = this.$location.absUrl();

			let base64EncodedState = angular.toJson(redirectState);

			try {
				base64EncodedState = encodeURIComponent(base64.btoa(base64EncodedState));
			} catch (e) {
				this.$log.error(`signature redirect: can't create base64 encoded state => ${base64EncodedState}`);
				// Error handling is done in ssoRedirectCtrl
			}

			const url = this.$state.href('auth.redirect', { 'id_token': token, state: base64EncodedState },
				{ absolute: true });

			return new Promise((resolve, reject) => {
				this.ssoService.getSignatureRedirect(url, signKey, additionalInfo)
					.then((res) => {
						resolve({ reload: true });
						this.$window.location.href = res.data;
					}, () => {
						this.loadingIndicatorService.hide();
						reject();
					});
			});
		}

	}
}
