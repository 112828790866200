/*
 * countriesComponent
 * */

import templateUrl from './countries.html';
import controller from './countries.controller';
import './countries.scss';

const countriesComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default countriesComponent;
