/*
 * ngEnterModule
 * */

import ngEnterDirective from './ngEnter.directive';

const ngEnterModule = angular.module('app.common.ngEnter', [])
	.directive('ngEnter', ngEnterDirective)
	.name;

export default ngEnterModule;
