/*
 * countriesController
 * */

class CountriesController {

	constructor($log, $state, clinicalDataService, configurationService, appConfig,
				loadingIndicatorService, analyticsService) {
		'ngInject';
		this.$log = $log;
		this.$state = $state;
		this.clinicalDataService = clinicalDataService;
		this.configurationService = configurationService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.appConfig = appConfig;
		this.analyticsService = analyticsService;

		this.countries = [];
	}

	$onInit() {
		this.$log = this.$log.getInstance('CountriesController');
		this.$log.debug('loaded');

		this.loadingIndicatorService.show();
		this.options = this.getGridConfig();

		this.configurationService.getClinicalDataColumns(
			this.appConfig.clinicalDataModuleNames.country)
			.then((resp) => {
				this.updateColumnList(resp);
				this.load = true;
			}, (error) => {
				this.$log.debug(`error loading configuration for countries: ${error}`);
			}).finally(() => {
				this.loadingIndicatorService.hide();
			});
	}

	updateColumnList(pdColumnConfig) {
		const columns = [];

		pdColumnConfig.forEach(el => {
			const column = this.options.columnDefsStatic.find(c => c.fieldName === el.fieldName);

			if (column) {
				column.displayName = el.label;
				columns.push(column);
			}
		});
		this.options.columnDefs = columns;
	}

	getGridConfig() {
		return {
			columnDefsStatic: [
				{
					fieldName: 'countryName',
					type: 'string',
					isDefaultFilter: true,
					isPrimary: true
				},
				{
					fieldName: 'numberOfSites',
					type: 'number'
				},
				{
					fieldName: 'numberOfSubjects',
					type: 'number'
				},
				{
					fieldName: 'numberOfActiveSubjects',
					type: 'number'
				},
				{
					fieldName: 'numberOfInactiveSubjects',
					type: 'number'
				},
				{
					fieldName: 'numberOfCreatedSubjects',
					type: 'number'
				},
				{
					fieldName: 'numberOfEnrolledSubjects',
					type: 'number'
				},
				{
					fieldName: 'cumulativeNumberOfEnrolledSubjects',
					type: 'number'
				},
				{
					fieldName: 'numberOfIncludedSubjects',
					type: 'number'
				},
				{
					fieldName: 'cumulativeNumberOfIncludedSubjects',
					type: 'number'
				},
				{
					fieldName: 'numberOfRandomizedSubjects',
					type: 'number'
				},
				{
					fieldName: 'cumulativeNumberOfRandomizedSubjects',
					type: 'number'
				},
				{
					fieldName: 'numberOfTreatmentPeriodCompletedSubjects',
					type: 'number'
				},
				{
					fieldName: 'cumulativeNumberOfTreatmentPeriodCompletedSubjects',
					type: 'number'
				},
				{
					fieldName: 'numberOfFinishedSubjects',
					type: 'number'
				},
				{
					fieldName: 'numberOfEarlyTerminatedSubjects',
					type: 'number'
				},
				{
					fieldName: 'numberOfExcludedSubjects',
					type: 'number'
				},
				{
					fieldName: 'avgFev1Grade',
					type: 'string'
				},
				{
					fieldName: 'avgFvcGrade',
					type: 'string'
				},
				{
					fieldName: 'orFev1AcceptableRate',
					type: 'number'
				},
				{
					fieldName: 'orFvcAcceptableRate',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageCombined',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageSlow',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageForced',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageDlco',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageMbw',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageBody',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageHfs',
					type: 'number'
				},
				{
					fieldName: 'numberOfCompletedVisits',
					type: 'number'
				},
				{
					fieldName: 'lastVisitDate',
					type: 'date',
					formatter: 'dateFormat'
				},
				{
					fieldName: 'lastVisitName',
					type: 'string'
				},
				{
					fieldName: 'nextVisitDate',
					type: 'date',
					formatter: 'dateFormat'
				},
				{
					fieldName: 'nextVisitName',
					type: 'string'
				},
				{
					fieldName: 'numberOfOpenDcrToErt',
					type: 'number'
				},
				{
					fieldName: 'numberOfQueriesToSite',
					type: 'number'
				},
				{
					fieldName: 'overallCompliance',
					type: 'number'
				},
				{
					fieldName: 'compliance',
					type: 'number'
				},
				{
					fieldName: 'numberOfSubjectReceivedDiary',
					type: 'number'
				},
				{
					fieldName: 'numberOfSubjectDays',
					type: 'number'
				}
			],
			defaultRowAction: (row) => {
				this.rowClicked(row);
			},
			gridRowActions: [
				{
					displayName: 'Info',
					iconCssClass: 'es-icon-info-circle',
					action: (row) => {
						this.infoIconClicked(row);
					}
				}
			],
			gridActions: [],
			sortOrders: [{
				fieldName: 'countryName'
			}],

			getData: (oDataQuery) => {
				return this.loadCountries(oDataQuery);
			}
		};
	}

	loadCountries(queryStr) {
		return this.clinicalDataService.getCountries(queryStr);
	}

	infoIconClicked(row) {
		this.infoPopupApi.open(row.countryId);
	}

	getInfoPopupApi($API) {
		this.infoPopupApi = $API;
	}

	rowClicked(row) {
		this.analyticsService.trackEventWithTitle(
			`${this.appConfig.trackCategory.navigateTo}::${this.appConfig.trackId.clinicalDataSite}`,
			this.appConfig.trackAction.gridEntry, this.appConfig.trackLabel.levelCountry);
		this.$state.go(this.appConfig.routes.clDataCountrySites, {
			country: row.countryId
		});
	}
}

export default CountriesController;
