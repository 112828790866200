/*
 * sendPinPopupModule
 * */

import sendPinPopupComponent from './sendPinPopup.component';

const sendPinPopupModule = angular.module('app.components.clinicalData.subjects.sendPinPopup', [])
	.component('sendPinPopup', sendPinPopupComponent)
	.name;

export default sendPinPopupModule;
