class DcrDetailsPopupController {
	constructor($log, $q, dataManagementService, appSession, appI18n,
				remoteFileService, configurationService, loadingIndicatorService) {
		'ngInject';
		this.$log = $log;
		this.$q = $q;
		this.appI18n = appI18n;
		this.loadingIndicatorService = loadingIndicatorService;
		this.dataManagementService = dataManagementService;
		this.remoteFileService = remoteFileService;
		this.configurationService = configurationService;
		this.appSession = appSession;
	}

	$onInit() {
		this.$log = this.$log.getInstance('DcrDetailsPopupController');
		this.$log.debug('loaded');
		this.setApi();
	}

	setApi() {

		this.$API = {
			open: (tickets, ticket, index) => {
				this.appI18n.translate([
					'data-management.details.information',
					'data-management.details.status',
					'data-management.details.description',
					'data-management.details.fileNotAvailable',
					'clinicaldata.site.site-info.title',
					'clinicaldata.site.site-info.error',
					'clinicaldata.site.site-info.na'
				]).then((translations) => {
					this.translations = translations;
					this.activate(tickets, ticket, index).then(() => {
						this.open = true;
					});
				});
			},
			close: () => {
				this.open = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({ $API: this.$API });
		}
	}

	cancelAction() {
		this.$API.close();
	}

	activate(tickets, ticket, index) {
		this.actions = [
			{ action: () => this.nextTicket(), displayName: 'Next', cssClass: '-es-primary' },
			{ action: () => this.previousTicket(), displayName: 'Previous' }
		];
		this.study = this.appSession.getStudy();
		this.loadingIndicatorService.show();
		return this.$q.all([
			this.getShowMasterScopeDcrId(),
			this.getCanUploadFiles(),
			this.showDcrDetails(tickets, ticket, index)]).then((/*resp*/) => {
				this.loadingIndicatorService.hide();
			}, (/*error*/) => {
				this.loadingIndicatorService.hide();
			});
	}

	nextTicket() {
		if (this.tickets.length > this.index - 1) {
			++this.index;
			const newTicket = this.tickets[this.index];

			this.showDcrDetails(this.tickets, newTicket, this.index);
		}
	}

	previousTicket() {
		if (this.tickets.length > 0) {
			--this.index;
			const newTicket = this.tickets[this.index];

			this.showDcrDetails(this.tickets, newTicket, this.index);
		}
	}

	updateActions() {
		this.actions[1].isDisabled = this.index === 0;
		this.actions[0].isDisabled = this.index === this.tickets.length - 1;
	}

	getShowMasterScopeDcrId() {
		return this.dataManagementService.getTicketsViewConfig().then((data) => {
			this.showMasterScopeDcrId = data.showMasterScopeDcrId;
		});
	}

	getCanUploadFiles() {
		return this.configurationService.getUserStudySettings().then(response => {
			this.canUploadFiles = response.data.expertStudySetup.showQueryAttachment;
			this.hasVisitDemographics = response.data.hasVisitDemographics;
		});
	}

	showDcrDetails(tickets, ticket, index) {
		this.tickets = tickets;
		this.index = index;
		this.clear();
		this.ticketId = ticket.queryId;
		this.ticket = ticket;
		this.title = `${this.ticket.ticketType} #: ${this.ticket.queryId}`;
		this.updateActions();
		return this.getTicketData(ticket.queryId);
	}

	hasSubjectInfo() {
		const hasInfo =
			!!(this.ticket && (
				this.ticket.subjectCriticalValues ||
					(this.demographics && this.demographics.length)));

		return hasInfo;
	}

	hasEventInfo() {
		const hasInfo =
			!!(this.ticket && this.ticket.transmittalNumber);

		return hasInfo;
	}

	hasAttachmentsInfo() {
		const hasInfo =
			!!(this.ticket &&
			   this.ticket.attachments &&
			   this.ticket.attachments.length > 0);

		return hasInfo;
	}

	clear() {
		this.ticketData = {};
		this.ticket = {};
		this.demographics = {};
		this.transmittalDemographics = {};
		this.actionLogs = {};
		this.siteName = undefined;
		this.siteId = undefined;
		this.siteContact = {};
		this.title = '';
		this.clearError();
	}

	getTicketData(ticketId) {
		this.isLoading = true;
		return this.dataManagementService.getTicketData(ticketId).then((response) => {
			this.ticketData = response.data[0];
			this.ticket = response.data[0].queryIncident;
			this.ticket.attachments = response.data[0].attachments || [];
			this.demographics = response.data[0].subjectDemographics;
			this.eventInformationList = response.data[0].eventInformationList;
			this.actionLogs = response.data[0].listOfQueryActionLogs;
			this.siteName = response.data[0].siteName;
			this.siteId = response.data[0].siteId;
			this.siteContact = response.data[0].siteContact;
			this.clearError();
			this.ticketHeader =
				`${this.ticket.ticketType} ${this.translations['data-management.details.information']}`;
			this.statusHeader =
				`${this.ticket.ticketType} ${this.translations['data-management.details.status']}`;
			this.descriptionHeader =
				`${this.ticket.ticketType} ${this.translations['data-management.details.description']}`;

			for (let i = 0; i < this.ticket.attachments.length; ++i) {
				if (!this.ticket.attachments[i].url && !this.ticket.attachments[i].originalFilename) {
					this.ticket.attachments[i].originalFilename =
						this.translations['data-management.details.fileNotAvailable'];
				}
			}

			this.isLoading = false;
		}, (response) => {
			this.$log.error('TicketDetailsController.getTicketData error: {data} and status: {status}',
							{ data: response.data, status: response.status });
			this.setError('data-management.genericErrorLoadingDetails');
			this.isLoading = false;

		});
	}

	clearError() {
		this.errorMsg = '';
		this.isLoading = false;
	}

	setError(message) {
		this.isLoading = false;
		this.appI18n.translate(message).then((translation) => {
			this.errorMsg = translation;
		});
	}

	openFile(url, name) {
		this.remoteFileService.openDcrFile(url, name, true);
		this.$log.debug(`open file ${url}`);
	}
}

export default DcrDetailsPopupController;
