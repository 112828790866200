(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('CloseVisitController', CloseVisitController);

	/*
	 * @ngInject
	 */
	function CloseVisitController($log, $stateParams, eproService, configurationService, appSession,
								  appConfig, appI18n, dialogService, notificationService,
								  clinicalDataService, loadingIndicatorService) {

		var vm = this;
		$log = $log.getInstance("CloseVisitController");
		$log.debug(" loaded");

		vm.showCloseVisit = showCloseVisit;
		vm.closeCloseVisit = closeCloseVisit;
		vm.setRolloverTime = setRolloverTime;
		vm.validateCompleteDate = validateCompleteDate;
		vm.validateCompleteTime = validateCompleteTime;

		vm.doCloseVisit = doCloseVisit;

		activate();

		function setRolloverTime() {
			var offset = vm.closeVisitData.timezoneOffset;

			var MS_PER_MINUTE = 60000;
			var date = new Date();
			date = new Date(date.getTime() + (offset + date.getTimezoneOffset()) * MS_PER_MINUTE);

			vm.model.completeTime = padNumber(date.getHours()) + ":" + padNumber(date.getMinutes());

			vm.validateCompleteTime(true);
		}

		function padNumber(num) {
			var s = "0" + num;
			return s.substr(s.length - 2);
		}

		function showCloseVisit() {
			loadingIndicatorService.show();
			initForm();
		}

		function closeCloseVisit() {
			vm.isVisible = false;
		}

		function activate() {
			clinicalDataService.setCloseVisitHandler(vm);

			vm.closeVisitActions = [
				{
					action: () => {
						vm.doCloseVisit();
					},
					displayName: appI18n.translateImmediate('app.buttons.ok'),
					isPrimary: true
				},
				{
					action: () => {
						vm.closeCloseVisit();
					},
					displayName: appI18n.translateImmediate('app.buttons.cancel')
				}
			];
		}

		function initForm() {
			vm.model = {};
			vm.validation = {
				required: {},
				errors: {}
			};
			vm.errorMsg = null;

			vm.eproSubjectId = $stateParams.eproSubject;
			vm.subjectId = $stateParams.subject;

			eproService.getCloseVisitData(vm.eproSubjectId).then(
				function (response) {
					vm.closeVisitData = response.data;

					if (!response.data.canCloseVisit) {
						showCannotCloseVisitWarning();
					}
				},
				function() {
					setError("app.errors.genericLoadError");
				})
				.finally(() => {
					loadingIndicatorService.hide();
					vm.isVisible = true;
				});
			;
		}

		function showCannotCloseVisitWarning() {
			vm.cannotCloseVisitHandler = dialogService.createConfirmHandler();
			vm.cannotCloseVisitHandler.show().then(function () {
				vm.cannotCloseVisitHandler.hide();
				clinicalDataService.closeCloseVisit();
			});
		}

		function clearError() {
			vm.hasErrors = false;
			vm.errorMsg = null;
		}

		// Parse date in "HH:mm" format. If fails to parse - false is returned.
		function parseTime(str) {
			var re = /^(\d{2}):(\d{2})$/i;
			try {
				var matches = str.match(re); // Now match dates, from parts create dates.

				if (matches.length !== 3) {
					return null;
				}

				if (matches[1] >= 24 || matches[1] < 0) {
					return false;
				}

				if (matches[2] >= 60 || matches[2] < 0) {
					return false;
				}
			} catch (err) {
				return false;
			}
			return true;
		}

		function validateCompleteDate(validateEmpty) {
			clearError();

			vm.validation.required.completeDate = false;
			vm.validation.errors.completeDate = null;

			if (validateEmpty && !vm.model.completeDate) {
				vm.validation.required.completeDate = true;
			} else if (vm.model.completeDate) {
				const parsedDate = moment(vm.model.completeDate, 'DD-MMM-YYYY', true);

				if (!parsedDate.isValid()) {
					vm.validation.errors.completeDate = 'Invalid format';
				}
			}
		}

		function validateCompleteTime(validateEmpty) {
			clearError();

			vm.validation.required.completeTime = false;
			vm.validation.errors.completeTime = null;
			if (validateEmpty && !vm.model.completeTime) {
				vm.validation.required.completeTime = true;
			} else if (vm.model.completeTime) {
				var parseSuccess = parseTime(vm.model.completeTime);
				if (!parseSuccess) {
					vm.validation.errors.completeTime = 'Invalid format';
				}
			}
		}

		function validateForm() {
			validateCompleteDate(true);
			validateCompleteTime(true);

			for (var req in vm.validation.required) {
				if (vm.validation.required[req]) {
					return false;
				}
			}

			for (var err in vm.validation.errors) {
				if (vm.validation.errors[err]) {
					return false;
				}
			}

			return true;
		}

		function doCloseVisit() {
			var isValid = validateForm();

			if (isValid) {
				loadingIndicatorService.show();

				eproService.closeVisit(vm.subjectId, vm.eproSubjectId, vm.model).then(
					function (response) {
						if (response.data.isValidationError) {
							setGenericSaveError(response);
						} else {
							showSaveDelayWarning();
						}
					},
					function (response) {
						setGenericSaveError(response);
					}).finally(() => {
						loadingIndicatorService.hide();
					})
				;
			}
		}

		function showSaveDelayWarning() {
			notificationService.showSuccess(appI18n.translateImmediate(
				'clinicaldata.subject.closeVisit.saveDelay'));
			closeCloseVisit();
		}

		function setGenericSaveError(response) {
			if (response.data && response.data.translationKey) {
				setError(response.data.translationKey);
			} else if (response.data && response.data.message) {
				vm.errorMsg = response.data.message;
			}
		}

		function setError(translationKey) {
			appI18n.translate(translationKey).then(function (translation) {
				vm.errorMsg = translation;
			});
		}
	}
})();
