/*
 * breadcrumbsComponent
 * */

import templateUrl from './breadcrumbs.html';
import controller from './breadcrumbs.controller';
import './breadcrumbs.scss';

const breadcrumbsComponent = {
	templateUrl,
	controller
};

export default breadcrumbsComponent;
