/*
 * pageComponent
 * */

import templateUrl from './page.html';
import controller from './page.controller';
import './page.scss';

const pageComponent = {
	bindings: {
		heading: '<?',
		actions: '<?'
	},
	transclude: {
		subTitle: '?subTitle',
		breadcrumb: '?breadcrumb'
	},
	templateUrl,
	controller
};

export default pageComponent;
