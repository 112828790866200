import templateUrl from './table.html';
import controller from './table.controller';
import './table.scss';

/*
* Parameters
* caption - sets caption to the table
* headers - array of headers data that will be uset to render table headers
* full - table will occupy the 100% ow with available
* loading = shows loading indicator within the table
* sort - callback called when a sorting action is performed
* saveSorting - saves sorting in the data query object (available in url)
* onRowSelected - callback performed when row is selected (has selected row data as a parameter)
* componentReady - callback that provides table api as a parameter (for example to preselect a value on initialization)
* */
const tableComponent = {
	bindings: {
		caption: '@?',
		headers: '<',
		full: '<?',
		loading: '<?',
		sort: '&',
		saveSorting: '<?',
		onRowSelected: '&?',
		componentReady: '&?'
	},
	require: {
		grid: '^^?grid'
	},
	transclude: true,
	templateUrl,
	controller
};

export default tableComponent;
