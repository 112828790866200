import '../components/components.js';

import deviceManagementSiteTemplateUrl from '../components/deviceManagement/deviceManagement.site.html';
import deviceManagementSubjectTemplateUrl from '../components/deviceManagement/deviceManagement.subject.html';
import deviceManagementTemplateUrl from '../components/deviceManagement/deviceManagement.html';
var base64 = require('Base64');

(function () {
	'use strict';


	angular.module('portalApp').config(RoutesConfig);

	angular.module('portalApp').run(Matcher);

	/**
	 * @ngInject
	 */
	function Matcher($urlMatcherFactory) {
		var urlCaseSensitive = $urlMatcherFactory.caseInsensitive(true);
	}

	/**
	 * @ngInject
	 */
	function RoutesConfig($stateProvider, $urlRouterProvider, $injector) {

		// For any unmatched url, redirect to /
		//$urlRouterProvider.otherwise('/');

		const appConfig = $injector.get('appConfig');

		$urlRouterProvider.otherwise(function ($injector, $location) {
			var appSession = $injector.get('appSession');
			var state = $injector.get('$state');
			var $log = $injector.get('$log');

			if (window.location.hash.indexOf('access_token') >= 0) {
				var url = '/sso-redirect?' + window.location.href.substr(window.location.href.indexOf('#') + 2);
				$log.debug('-----------------sso-redirect:' + url);
				return url;
			}

			// redirect to default page if session not exist
			if (!appSession.isValid()) {
				state.transitionTo(appConfig.routes.login);
			} else {
				state.transitionTo(appConfig.routes.default);
			}
		});

		// Now set up the states
		$stateProvider
			.state('app', {
				abstract: true,
				url: '',
				templateUrl: 'views/app.html',
				controller: 'mainCtrl as vm'
			})
			.state('app.home', {
				url: '/home?study&mode',
				views: {
					'header': {
						templateUrl: 'views/header/index.html'
					},
					'sideNav': {
						template: '<side-navigation></side-navigation>'
					},
					'': {
						template: '<ui-view/>'
					},
					'footer': {
						templateUrl: 'views/footer/index.html'
					}
				}
			})
			.state('app.home.dashboard', {
				url: '/dashboard',
				template: '<dashboard></dashboard>',
				cache: false,
				moduleId: 'MSP3.Dashboard',
				onEnter: (configurationService, $state) => {
					configurationService.getSiteMap().then((navItems) => {
						const dashboardModule = _.find(navItems, { id: appConfig.siteMapModuleIds.dashboard });

						if (dashboardModule) {
							return;
						}

						// if the dashboard module is not accessible (disabled by config)
						if (navItems && navItems.length > 0) {
							// then redirect the user to the first available module
							$state.go(appConfig.getRouteForModuleId(navItems[0].id), { inherit: false });
						} else {
							// or to the my downloads module which is available to everybody as fallback
							$state.go(appConfig.routes.downloads);
						}
					});
				}
			})
			.state(appConfig.routes.clData, {
				url: `/clinicalData?country&site&subject&eproSubject&event&tdt&mdt&visit&signKey&tokenId&tdv&showMicroPortal`,
				reloadOnSearch: false,
				template: '<clinical-data></clinical-data>',
			})
			.state(appConfig.routes.clDataCountries, {
				url: '/countries?filter&orderBy',
				template: '<clinical-data-countries></clinical-data-countries>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.Countries'
			})
			.state(appConfig.routes.clDataSites, {
				url: '/sites?filter&orderBy',
				template: '<clinical-data-sites></clinical-data-sites>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.Sites'
			})
			.state(appConfig.routes.clDataCountrySites, {
				url: '/country/sites?filter&orderBy',
				template: '<clinical-data-sites></clinical-data-sites>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.Sites'
			})
			.state(appConfig.routes.clDataSubjects, {
				url: '/subjects?filter&orderBy',
				template: '<clinical-data-subjects></clinical-data-subjects>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.Subjects'
			})
			.state(appConfig.routes.clDataSiteSubjects, {
				url: '/site/subjects?filter&orderBy',
				template: '<clinical-data-subjects></clinical-data-subjects>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.Subjects'
			})
			.state(appConfig.routes.reports.dataStatusForm, {
				url: '/statusForms?filter&orderBy',
				template: '<status-form></status-form>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.StatusForms'
			})
			.state(appConfig.routes.reports.ecgSourceTracing, {
				url: '/sourceTracings?filter&orderBy',
				template: '<ecg-source-tracing></ecg-source-tracing>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.SourceTracing'
			})
			.state(appConfig.routes.reports.holter, {
				url: '/holterReports?filter&orderBy',
				template: '<holter-report></holter-report>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.HolterReports'
			})
			.state(appConfig.routes.reports.abpm, {
				url: '/abpmReports?filter&orderBy',
				template: '<abpm-report></abpm-report>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.AbpmReports'
			})
			.state(appConfig.routes.clDataVisits, {
				url: '/visits?filter&orderBy',
				templateUrl: 'views/clinicaldata/visits.html',
				controller: 'VisitController as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.Visits',
				resolve: {
					fallbackSiteParams: ($stateParams, clinicalDataService) => {
						if ((!$stateParams.country || $stateParams.country === '0') &&
							$stateParams.site && $stateParams.site !== '0') {
							return clinicalDataService.getSite($stateParams.site);
						}
						return {};
					}
				}
			})
			.state(appConfig.routes.clDataScheduledEvents, {
				url: '/site/scheduledEvents?filter&orderBy',
				template: '<scheduled-events></scheduled-events>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.ScheduledEvents'
			})
			.state(appConfig.routes.iClinicaDashboard, {
				url: '/iclinica',
				template: '<i-clinica-frame></i-clinica-frame>',
				reloadOnSearch: false,
				moduleId: 'MSP3.IClinica'
			})
			.state(appConfig.routes.ecoa, {
				url: '/ecoa?filter&orderBy',
				template: '<ecoa-session-viewer></ecoa-session-viewer>',
				reloadOnSearch: false,
				moduleId: 'MSP3.ClinicalData.Ecoa'
			})
			.state(appConfig.routes.spirometry, {
				url: '/spirometryViewer',
				templateUrl: 'views/clinicaldata/spirometryViewer.html',
				reloadOnSearch: false,
				controller: 'SpirometryViewerCtrl as vm'
			})
			.state(appConfig.routes.ecg, {
				url: '/ecgViewer',
				template: '<clinical-data-ecg-viewer></clinical-data-ecg-viewer>',
				moduleId: 'MSP3.ClinicalData.Ecg'
			})
			.state(appConfig.routes.deviceMicroPortal, {
				url: '/deviceMicroPortalViewer',
				template: '<clinical-data-device-micro-portal-viewer></clinical-data-device-portal-viewer>',
				moduleId: 'MSP3.ClinicalData.DeviceMicroPortal',
				params: {showMicroPortal: false}
			})
			.state(appConfig.routes.tickets, {
				url: '/tickets?popup&popupParam&signKey&tokenId&filter&orderBy',
				templateUrl: 'views/dataManagement/tickets.html',
				controller: 'TicketViewerController as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.DataManagement.Tickets'
			})
			.state(appConfig.routes.reports.root, {
				url: '/reports',
				templateUrl: 'views/reports/reports.html',
				controller: 'ReportsCtrl as vm',
				reloadOnSearch: false
			})
			.state(appConfig.routes.createReport, {
				url: '/createreport?report&country&site&subject&filter&orderBy',
				templateUrl: 'views/reports/createReport.html',
				controller: 'CreateReportCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Reports.CreateReport'
			})
			.state(appConfig.routes.myReports, {
				url: '/myreports?filter&orderBy',
				templateUrl: 'views/reports/myReports.html',
				controller: 'MyReportsCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Reports.MyReports'
			})
			.state(appConfig.routes.logiReports, {
				url: '/livereports',
				templateUrl: 'views/reports/logi.html',
				controller: 'LogiCtrl as vm',
				reloadOnSearch: false
			})
			.state(appConfig.routes.reportsTraining, {
				url: '/training?filter&orderBy',
				templateUrl: 'views/reports/trainingDocument.html',
				controller: 'TraningDocumentsCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Reports.Training'
			})
			.state(appConfig.routes.ecgDataExport, {
				url: '/ecgdataexport',
				templateUrl: 'views/reports/ecgDataExport.html',
				controller: 'EcgDataExportCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Reports.EcgDataExport'
			})
			.state(appConfig.routes.reportReview, {
				url: '/reportreview?filter&orderBy',
				template: '<report-review></report-review>',
				reloadOnSearch: false,
				moduleId: 'MSP3.Reports.Review'
			})
			.state(appConfig.routes.myDownloads, {
				url: '/mydownloads',
				template: '<my-downloads view-config="$resolve.viewConfig"></my-downloads>',
				reloadOnSearch: false,
				moduleId: 'MSP3.MyDownloads',
				onEnter(appSession) {
					appSession.setNumberOfNewDownloads(0);
				},
				resolve: {
					viewConfig: (configurationService) => {
						return configurationService.getDownloadsViewConfig();
					}
				},
				redirectTo: appConfig.routes.downloads
			})
			.state(appConfig.routes.downloads, {
				url: '/downloads?filter&orderBy',
				template: '<downloads></downloads>',
				reloadOnSearch: false,
				moduleId: 'MSP3.MyDownloads'
			})
			.state(appConfig.routes.dataManagementFiles, {
				url: '/data-management-files?filter&orderBy',
				template: '<d-management-files view-config="$resolve.viewConfig"></d-management-files>',
				reloadOnSearch: false,
				moduleId: 'MSP3.MyDownloads'
			})
			.state(appConfig.routes.deviceManagement.root, {
				moduleId: 'MSP3.DeviceManagement.Root',
				url: '/device-management?deviceId&mdtId&tdtId',
				templateUrl: deviceManagementTemplateUrl,
				params: {deviceId: ''},
				reloadOnSearch: false,
				controller: 'DeviceManagementCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.flowscreen, {
				moduleId: 'MSP3.DeviceManagement.Flowscreen',
				url: '/flowscreen?filter&orderBy',
				templateUrl: deviceManagementSiteTemplateUrl,
				reloadOnSearch: false,
				params: { deviceId: appConfig.deviceIds.flowscreen },
				controller: 'DeviceManagementSiteCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.sitepro, {
				moduleId: 'MSP3.DeviceManagement.Sitepro',
				url: '/sitepro?filter&orderBy',
				templateUrl: deviceManagementSiteTemplateUrl,
				reloadOnSearch: false,
				params: {
					deviceId: appConfig.deviceIds.siteProCgm,
					tdtId: appConfig.testDataType.coaSite,
					mdtId: appConfig.modalityType.sitePro
				},
				controller: 'DeviceManagementSiteCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.easyOnePro, {
				moduleId: 'MSP3.DeviceManagement.EasyOnePro',
				url: '/easyonepro?filter&orderBy',
				templateUrl: deviceManagementSiteTemplateUrl,
				reloadOnSearch: false,
				params: { deviceId: appConfig.deviceIds.easyOnePro },
				controller: 'DeviceManagementSiteCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.masterscope, {
				moduleId: 'MSP3.DeviceManagement.Masterscope',
				url: '/masterscope?filter&orderBy',
				templateUrl: deviceManagementSiteTemplateUrl,
				reloadOnSearch: false,
				params: { deviceId: appConfig.deviceIds.masterscope },
				controller: 'DeviceManagementSiteCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.spiroSphere, {
				moduleId: 'MSP3.DeviceManagement.SpiroSphere',
				url: '/spirosphere?filter&orderBy',
				templateUrl: deviceManagementSiteTemplateUrl,
				reloadOnSearch: false,
				params: { deviceId: appConfig.deviceIds.spiroSphere },
				controller: 'DeviceManagementSiteCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.clarioApp, {
				moduleId: 'MSP3.DeviceManagement.ClarioApp',
				url: '/clarioApp?filter&orderBy',
				templateUrl: deviceManagementSubjectTemplateUrl,
				reloadOnSearch: false,
				params: { deviceIds: appConfig.deviceIds.clarioApp },
				controller: 'DeviceManagementSubjectCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.vivalink, {
				moduleId: 'MSP3.DeviceManagement.Vivalink',
				url: '/vivalink?filter&orderBy',
				templateUrl: deviceManagementSubjectTemplateUrl,
				reloadOnSearch: false,
				params: { deviceIds: appConfig.deviceIds.vivalink },
				controller: 'DeviceManagementSubjectCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.cough, {
				moduleId: 'MSP3.DeviceManagement.Cough',
				url: '/cough?filter&orderBy',
				templateUrl: deviceManagementSubjectTemplateUrl,
				reloadOnSearch: false,
				params: { deviceIds: appConfig.deviceIds.cough },
				controller: 'DeviceManagementSubjectCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.cgm, {
				moduleId: 'MSP3.DeviceManagement.Cgm',
				url: '/cgm?filter&orderBy',
				templateUrl: deviceManagementSubjectTemplateUrl,
				reloadOnSearch: false,
				params: {
					deviceIds: appConfig.deviceIds.siteProCgm,
					tdtId: appConfig.testDataType.coaSite,
					mdtId: appConfig.modalityType.cgm
				},
				controller: 'DeviceManagementSubjectCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.feno, {
				moduleId: 'MSP3.DeviceManagement.Feno',
				url: '/feno?filter&orderBy',
				templateUrl: deviceManagementSubjectTemplateUrl,
				reloadOnSearch: false,
				params: { deviceIds: appConfig.deviceIds.feno },
				controller: 'DeviceManagementSubjectCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.iSpiro, {
				moduleId: 'MSP3.DeviceManagement.ISpiro',
				url: '/ispiro?filter&orderBy',
				templateUrl: deviceManagementSubjectTemplateUrl,
				reloadOnSearch: false,
				params: { deviceIds: appConfig.deviceIds.iSpiro },
				controller: 'DeviceManagementSubjectCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.am3, {
				moduleId: 'MSP3.DeviceManagement.Am3',
				url: '/am3?filter&orderBy',
				templateUrl: deviceManagementSubjectTemplateUrl,
				reloadOnSearch: false,
				params: { deviceIds: appConfig.deviceIds.am3 },
				controller: 'DeviceManagementSubjectCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.deviceManagement.diarypro, {
				moduleId: 'MSP3.DeviceManagement.Diarypro',
				url: '/diarypro?filter&orderBy',
				templateUrl: deviceManagementSubjectTemplateUrl,
				reloadOnSearch: false,
				params: {
					deviceIds: appConfig.deviceIds.diaryPro,
					tdtId: appConfig.testDataType.coaDiary,
					mdtId: appConfig.modalityType.diaryPro
				},
				controller: 'DeviceManagementSubjectCtrl',
				controllerAs: 'vm'
			})
			.state(appConfig.routes.studyPermissions, {
				url: '/study-permissions?signKey&tokenId',
				templateUrl: 'views/studyPermissions/studyPermissions.html',
				reloadOnSearch: false,
				controller: 'StudyPermissionsCtrl as vm'
			})
			.state(appConfig.routes.studyUsers, {
				url: '/study-users?filter&orderBy',
				templateUrl: 'views/studyPermissions/studyUsers.html',
				reloadOnSearch: false,
				controller: 'StudyUsersCtrl as vm',
				moduleId: 'MSP3.StudyPermissions.StudyUsers'
			})
			.state(appConfig.routes.studyUsersEdit, {
				url: '/edit/:userId',
				template: '<edit-roles-popup></edit-roles-popup>',
				reloadOnSearch: false,
				moduleId: 'MSP3.StudyPermissions.EditUser'
			})
			.state(appConfig.routes.studyUsersDelete, {
				url: '/delete/:userId',
				template: '<delete-user-popup></delete-user-popup>',
				reloadOnSearch: false,
				moduleId: 'MSP3.StudyPermissions.StudyUsers'
			})
			.state(appConfig.routes.siteUsers, {
				url: '/site-users?filter&orderBy',
				templateUrl: 'views/studyPermissions/siteUsers.html',
				reloadOnSearch: false,
				controller: 'SiteUsersCtrl as vm',
				moduleId: 'MSP3.StudyPermissions.SiteUsers'
			})
			.state(appConfig.routes.siteUsersEdit, {
				url: '/edit/:siteId/:userId',
				template: '<edit-roles-popup></edit-roles-popup>',
				reloadOnSearch: false,
				moduleId: 'MSP3.StudyPermissions.EditUser'
			})
			.state(appConfig.routes.siteUsersDelete, {
				url: '/delete/:siteId/:userId',
				template: '<delete-user-popup></delete-user-popup>',
				reloadOnSearch: false,
				moduleId: 'MSP3.StudyPermissions.RemoveUser'
			})
			.state(appConfig.routes.siteAdmins, {
				url: '/site-admins',
				templateUrl: 'views/studyPermissions/siteAdministrators.html',
				reloadOnSearch: false,
				controller: 'SiteAdministratorsCtrl as vm',
				moduleId: 'MSP3.StudyPermissions.SiteUsers'
			})
			.state(appConfig.routes.siteAdminsEdit, {
				url: '/edit/:userId?countryId&siteId',
				template: '<edit-roles-popup></edit-roles-popup>',
				params: {
					isSiteAdmin: true
				},
				reloadOnSearch: false,
				moduleId: 'MSP3.StudyPermissions.EditUser'
			})
			.state(appConfig.routes.siteAdminsDelete, {
				url: '/delete/:userId?countryId&siteId',
				template: '<delete-user-popup></delete-user-popup>',
				params: {
					isSiteAdmin: true
				},
				reloadOnSearch: false,
				moduleId: 'MSP3.StudyPermissions.RemoveUser'
			})
			.state(appConfig.routes.countryUsers, {
				url: '/county-users?filter&orderBy',
				templateUrl: 'views/studyPermissions/countryUsers.html',
				reloadOnSearch: false,
				controller: 'CountryUsersCtrl as vm',
				moduleId: 'MSP3.StudyPermissions.CountryUsers'
			})
			.state(appConfig.routes.countryUsersEdit, {
				url: '/edit/:countryId/:userId',
				template: '<edit-roles-popup></edit-roles-popup>',
				reloadOnSearch: false,
				moduleId: 'MSP3.StudyPermissions.StudyUsers'
			})
			.state(appConfig.routes.countryUsersDelete, {
				url: '/delete/:countryId/:userId',
				template: '<delete-user-popup></delete-user-popup>',
				reloadOnSearch: false,
				moduleId: 'MSP3.StudyPermissions.RemoveUser'
			})
			.state(appConfig.routes.logisticsMicroportal.root, {
				url: '/logisticsMicroportal',
				template: '<logistics></logistics>',
				reloadOnSearch: false,
				moduleId: 'MSP3.Logistics.SupplyOrder'
			})
			.state(appConfig.routes.logisticsMicroportal.supplyOrder, {
				url: '/supplyorder',
				template: '<logistics></logistics>',
				params: {
					microportalPage: appConfig.logisticsPage.supplyOrder
				},
				reloadOnSearch: false,
				moduleId: 'MSP3.Logistics.SupplyOrder'
			})
			.state(appConfig.routes.logisticsMicroportal.shipmentTracking, {
				url: '/shipmenttracking',
				template: '<logistics></logistics>',
				params: {
					microportalPage: appConfig.logisticsPage.shipmentTracking
				},
				reloadOnSearch: false,
				moduleId: 'MSP3.Logistics.ShipmentTracking'
			})
			.state(appConfig.routes.logisticsMicroportal.returnRequests, {
				url: '/returnrequests',
				template: '<logistics></logistics>',
				params: {
					microportalPage: appConfig.logisticsPage.returnRequests
				},
				reloadOnSearch: false,
				moduleId: 'MSP3.Logistics.ReturnRequest'
			})
			.state(appConfig.routes.logistics, {
				url: '/logistics?popup&popupParam&signKey&tokenId',
				templateUrl: 'views/logistics/logistics.html',
				controller: 'LogisticsCtrl as vm',
				reloadOnSearch: false
			})
			.state(appConfig.routes.supplyOrder, {
				url: '/supplyorder?filter&orderBy',
				templateUrl: 'views/logistics/supplyOrder.html',
				controller: 'SupplyOrderingCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Logistics.SupplyOrder'
			})
			.state(appConfig.routes.shipmentTracking, {
				url: '/shipmenttracking?filter&orderBy',
				templateUrl: 'views/logistics/shipmentTracking.html',
				controller: 'ShipmentTrackingCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Logistics.ShipmentTracking'
			})
			.state(appConfig.routes.returnRequests, {
				url: '/returnrequests?filter&orderBy',
				templateUrl: 'views/logistics/returnRequests.html',
				controller: 'ReturnRequestsCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Logistics.ReturnRequest'
			})
			.state(appConfig.routes.announcements, {
				url: '/announcements?filter&orderBy',
				templateUrl: 'views/announcements/announcement.html',
				controller: 'AnnouncementCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Announcements'
			})
			.state('app.home.admin', {
				url: '/admin',
				templateUrl: 'views/admin/admin.html',
				controller: 'AdminCtrl as vm',
				reloadOnSearch: false
			})
			.state('app.home.admin.studyTracking', {
				url: '/studytracking?filter&orderBy',
				templateUrl: 'views/admin/studyTracking.html',
				controller: 'StudyTrackingCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Admin.StudyTracking'
			})
			.state('app.home.admin.moduleTracking', {
				url: '/moduletracking?filter&orderBy',
				templateUrl: 'views/admin/moduleTracking.html',
				controller: 'ModuleTrackingCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Admin.ModuleTracking'
			})
			.state('app.home.admin.userTracking', {
				url: '/usertracking?filter&orderBy',
				templateUrl: 'views/admin/userTracking.html',
				controller: 'UserTrackingCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Admin.UserTracking'
			})
			.state('app.home.admin.userLoginTracking', {
				url: '/userlogintracking?filter&orderBy',
				templateUrl: 'views/admin/userLoginTracking.html',
				controller: 'UserLoginTrackingCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Admin.UserLoginTracking'
			})
			.state('app.home.admin.studyConfiguration', {
				url: '/studyconfiguration',
				templateUrl: 'views/admin/studyConfig.html',
				controller: 'StudyConfigCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Admin.StudyConfiguration'
			})
			.state('app.home.sqfList', {
				url: '/sqf?popup&popupParam&signKey&tokenId',
				templateUrl: 'views/sqf/sqfList.html',
				controller: 'SqfListTabCtrl as vm',
				reloadOnSearch: false
			})
			.state('app.home.sqfList.saved', {
				url: '/saved?filter&orderBy',
				templateUrl: 'views/sqf/savedSqf.html',
				controller: 'SqfListCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Sqf.Saved'
			})
			.state('app.home.sqfList.submitted', {
				url: '/submitted?filter&orderBy',
				templateUrl: 'views/sqf/submittedSqf.html',
				controller: 'SqfListCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Sqf.Submitted'
			})
			.state('app.home.refMaterials', {
				url: '/refMaterials?filter&orderBy',
				templateUrl: 'views/referenceMaterials/refMaterial.html',
				controller: 'RefMaterialCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.ReferenceMaterials'
			})
			.state('app.home.epx', {
				url: '/epx?',
				templateUrl: 'views/sso/epx.html',
				controller: 'SsoCtrl as vm',
				onEnter: /* @ngInject */
					function (ssoService) {
						ssoService.startLoginToEpx();
					}
			})
			.state('app.home.tableau', {
				url: '/tableau?',
				templateUrl: 'views/sso/tableau.html',
				controller: 'SsoCtrl as vm',
				onEnter: /* @ngInject */
					function (ssoService) {
						ssoService.startLoginToTableau();
					}
			})
			.state('app.home.eci', {
				url: '/eci?study',
				templateUrl: 'assets/eci.html'
			})
			.state('auth', {
				abstract: true,
				url: '',
				templateUrl: 'views/app-gateway.html'
			})
			.state('auth.redirect', {
				url: '/sso-redirect?state&access_token&id_token&token_type&expires_in&scope&tokenId',
				templateUrl: 'views/login/redirect.html',
				allowUnauthenticated: true,
				logoutUser: false,
				controller: 'SsoRedirectCtrl as vm',
				resolve: {
					gssoLoginInfo: ($q, $log, $stateParams, ssoService) => {
						var decoded, decodedPromise, loginInfoPromise;

						// try to decode the provided state
						try {
							var base64EncodedString = decodeURIComponent($stateParams.state).replace(/ /g, '+');
							decoded = angular.fromJson(base64.atob(base64EncodedString));

							// The sso-redirect route is also the landing page for all signature related redirects.
							// After adding the signature a tokenId parameter is attached to the configured
							// redirect URL, but the tokenId parameter needs to be attached to the
							// destinationUrl property of the decoded state to have it available in the specific
						    // page controllers.
							if ($stateParams.tokenId && decoded.destinationUrl) {
								decoded.destinationUrl += `&tokenId=${$stateParams.tokenId}`;
							}

						} catch(e) {
							$log.error(`sso-redirect resolver: can't parse state => ` + $stateParams.state);
							// Error handling is done in ssoRedirectCtrl
						}
						decodedPromise = Promise.resolve(decoded);

						// we expect a study context in the state, if no study is available
						// no gsso login info can be fetched
						if (decoded && decoded.study && decoded.study.studyId) {
							loginInfoPromise = ssoService.gssoLoginInfo(decoded.study.studyId, $stateParams.id_token);
						} else {
							$log.error(`sso redirect resolver: can't find study, state => ` + angular.toJson($stateParams.state));
							// set to empty resolved promise to complete resolver step
							loginInfoPromise = Promise.resolve();
							// Error handling is done in ssoRedirectCtrl
						}

						// return combined promise
						return $q.all([
							decodedPromise,
							loginInfoPromise
						]);
					}
				}
			})
			.state('auth.login', {
				url: '/login',
				templateUrl: 'views/login/loginRedirect.html',
				controller: 'LoginRedirectCtrl as vm',
				params: {'prefillUserName': null}
			})
			.state('auth.loginHelp', {
				url: '/loginhelp',
				templateUrl: 'views/login/login.html',
				controller: 'LoginCtrl as vm',
				allowUnauthenticated: true,
				logoutUser: true,
				params: {helpMode: true}
			})
			.state('auth.resetPassword', {
				url: '/reset-password',
				templateUrl: 'views/login/redirect.html',
				controller: 'SsoRedirectCtrl as vm',
				allowUnauthenticated: true,
				logoutUser: true,
				params: {resetPasswordMode: true},
				resolve: {
					gssoLoginInfo: ($q) => {
						return $q.all([
							Promise.resolve(),
							Promise.resolve()
						]);
					}
				}
			})
			.state('auth.resend', {
				url: '/resend',
				templateUrl: 'views/login/emailConfirmation.html',
				controller: 'EmailConfirmationCtrl as vm',
				allowUnauthenticated: true,
				logoutUser: true,
				params: {resendMode: true}
			})
			.state(appConfig.routes.confirm, {
				url: '/confirm?t',
				templateUrl: 'views/login/emailConfirmation.html',
				controller: 'EmailConfirmationCtrl as vm',
				logoutUser: true,
				allowUnauthenticated: true,
				params: {confirmMode: true}
			})
			.state(appConfig.routes.confirmTokenPath, {
				url: '/confirm/{t}',
				templateUrl: 'views/login/emailConfirmation.html',
				controller: 'EmailConfirmationCtrl as vm',
				allowUnauthenticated: true,
				logoutUser: true,
				params: {confirmMode: true}
			})
			.state(appConfig.routes.confirmLegacy, {
				url: '/Login/SQF/ConfirmEmail.aspx?t',
				templateUrl: 'views/login/emailConfirmation.html',
				controller: 'EmailConfirmationCtrl as vm',
				allowUnauthenticated: true,
				logoutUser: true,
				params: {confirmMode: true}
			})
			.state(appConfig.routes.cdePftList, {
				url: '/cdepftlist?popup&popupParam&signKey&tokenId&filter&orderBy',
				templateUrl: 'views/clinicalDataEntry/pftList.html',
				controller: 'cdePftListCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Paper.PFT'
			})
			.state(appConfig.routes.cdePftNewEntry, {
				url: '/newentry?cdeMode',
				templateUrl: 'views/clinicalDataEntry/newPftEntry.html',
				controller: 'newPftEntryCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.Paper.PFT'
			})
			.state(appConfig.routes.logi, {
				url: '/logitest',
				templateUrl: 'views/logitest/logi.html',
				controller: 'LogiCtrl as vm',
				reloadOnSearch: false
			})
			.state('app.help', {
				abstract: true,
				url: '',
				views: {
					'header': {
						templateUrl: 'views/header/index.html'
					},
					'': {
						template: '<ui-view/>'
					},
					'footer': {
						templateUrl: 'views/footer/index.html'
					}
				}
			})
			.state(appConfig.routes.contactCustomerCare, {
				url: '/contact?study&mode',
				templateUrl: 'views/help/contactus.html',
				controller: 'ContactUsCtrl as vm',
				reloadOnSearch: false,
				moduleId: 'MSP3.CustomerCare.CreateTicket'
			});
	}
})();
