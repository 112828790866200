/*
 * tabsModule
 * */

import tabsComponent from './tabs.component';
import tabModule from './tab/index';

const tabsModule = angular.module('app.common.tabs', [tabModule])
	.component('tabs', tabsComponent)
	.name;

export default tabsModule;
