import templateUrl from './tooltip.html';
import controller from './tooltip.controller';
import './tooltip.scss';

const tooltip = {
	bindings: {
		active: '<',
		enabled: '<'
	},
	transclude: {
		element: 'ttElement',
		content: 'ttContent'
	},
	templateUrl,
	controller
};

export default tooltip;

