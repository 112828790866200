(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('SupplyOrderingCtrl', SupplyOrderingController);

	/**
	 * @ngInject
	 */
	function SupplyOrderingController($scope, $window, $log, $filter, loadingIndicatorService,
									  $stateParams, $location, logisticsService, appI18n, appConfig) {

		$log = $log.getInstance("SupplyOrderingCtrl", "color:blue");
		$log.debug("loaded");

		var vm = this;

		vm.supplyOrderOptions = {

			rowDefs: {},
			gridRowActions: [],

			gridActions: [],

			sortOrders: [{
				fieldName: 'orderedDate',
				reverseOrder: true
			}],

			getData: getSupplyOrderList,

			defaultRowAction: function(row) {
				var found = $filter('filter')(vm.supplyOrderOptions.rowData, {
					selected: true
				}, false);
				angular.forEach(found, function(value) {
					value.selected = false;
				});
				row.selected = true;
				vm.selectedOrder = {};
				vm.loadSelectedOrder(row);
			}
		};

		function getSupplyOrderList(options) {
			return logisticsService.getSupplyOrderList(options);
		}

		vm.loadSelectedOrder = function(row) {
			vm.isLoading = true;
			loadingIndicatorService.show();
			logisticsService.getSupplyOrderDetails(row.siteId, row.mspOrderId)
				.then(function(response) {
					$log.debug(" order detail data loaded.");
					vm.detailsTitle = vm.detailsHeader + row.mspOrderId;
					vm.selectedOrder = response.data;
					vm.showOrderDetails = true;
					vm.isLoading = false;
				}, function(response) {
					$log.debug("error in loading order detail data: " + response.data);
					vm.isLoading = false;
				})
				.finally(() => {
					loadingIndicatorService.hide();
				});
		};

		function closeOrderDetails() {
			vm.showOrderDetails = false;
		}

		function printDetails() {
			if (vm.isLoading) {
				return;
			}
			$window.print();
		}

		function refreshGrid() {
			vm.reload = true;
		}

		function activate() {
			appI18n.translate([
				"logistics.supply-ordering.title",
				"logistics.supply-ordering.orderdate",
				"logistics.supply-ordering.investigator",
				"logistics.supply-ordering.site#",
				"logistics.supply-ordering.order#",
				"logistics.supply-ordering.order-submitter",
				"logistics.supply-ordering.ordered-from",
				"logistics.supply-ordering.order-status",
				"logistics.order-details.title",
				"logistics.order-details.close",
				"logistics.order-details.order",
				"logistics.order-details.order-status"

			])
				.then(function(translations) {
					logisticsService.setTitle(translations["logistics.supply-ordering.title"]);
					vm.orderDateTimeTitle = translations["logistics.supply-ordering.orderdate"];
					vm.investigatorTitle = translations["logistics.supply-ordering.investigator"];
					vm.siteTitle = translations["logistics.supply-ordering.site#"];
					vm.orderTitle = translations["logistics.supply-ordering.order#"];
					vm.submitterTitle = translations["logistics.supply-ordering.order-submitter"];
					vm.orderedFromTitle = translations["logistics.supply-ordering.ordered-from"];
					vm.orderStatusTitle = translations["logistics.supply-ordering.order-status"];
					vm.orderDetailsStatusTitle = translations["logistics.order-details.order-status"];
					vm.detailsHeader = translations["logistics.order-details.title"] + " #: ";
					vm.closeTitle = translations["logistics.order-details.close"];
					vm.orderDetailsStatusTitle = translations["logistics.order-details.order-status"];

					vm.iconActions = [{
						action: printDetails,
						iconName: 'print'
					}];
					logisticsService.getConfig().then((response) => {
						vm.config = response;
						vm.supplyOrderOptions.columnDefs = [
							{
								displayName: vm.orderTitle,
								fieldName: 'mspOrderIdText',
								isSortable: true,
								type: 'string',
								isDefaultFilter: true,
								isPrimary: true
							},
							{
								displayName: vm.orderDateTimeTitle,
								fieldName: 'orderedDate',
								isSortable: true,
								type: 'date',
								formatter: 'dateTimeFormatUtc'
							},
							{
								displayName: vm.investigatorTitle,
								fieldName: 'investigatorName',
								isSortable: true,
								type: 'string'
							},
							{
								displayName: vm.siteTitle,
								fieldName: 'sponsorSiteId',
								isSortable: true,
								type: 'string'
							},
							{
								displayName: vm.submitterTitle,
								fieldName: 'orderedBy',
								isSortable: true,
								type: 'string'
							}
						];

						const expertStatusColumns = [{
							displayName: vm.orderedFromTitle,
							fieldName: 'orderedFrom',
							isSortable: true,
							showReverseSortOrder: true,
							type: 'enum',
							enumType: 'string',
							enums: [
								{
									displayName: 'Expert',
									values: ['Expert']
								},
								{
									displayName: 'Clario Portal',
									values: ['ERT Portal']
								}
							]
						},
							{
								displayName: vm.orderStatusTitle,
								fieldName: 'orderStatus',
								isSortable: true,
								type: 'enum',
								enumType: 'string',
								enums: [
									{
										displayName: 'Pending',
										values: ['Pending']
									},
									{
										displayName: 'Dispatched',
										values: ['Dispatched']
									},
									{
										displayName: 'Closed',
										values: ['Closed']
									},
									{
										displayName: 'Cancelled',
										values: ['Cancelled']
									}
								]
							}
						];

						const ebsStatusColumns = [
							{
								displayName: vm.orderedFromTitle,
								fieldName: 'orderedFrom',
								isSortable: true,
								showReverseSortOrder: false,
								type: 'enum',
								enumType: 'string',
								enums: [
									{
										displayName: 'Clario',
										values: ['ERT']
									},
									{
										displayName: 'Online Portal',
										values: ['Online Portal']
									}
								]
							},
							{
								displayName: vm.orderStatusTitle,
								fieldName: 'orderStatus',
								isSortable: true,
								type: 'enum',
								enumType: 'string',
								enums: [
									{
										displayName: 'Cancelled',
										values: ['Cancelled']
									},
									{
										displayName: 'Closed',
										values: ['Closed']
									},
									{
										displayName: 'Pending',
										values: ['Pending']
									}
								]
							}
						];

						if (vm.config.useExpert) {
							vm.supplyOrderOptions.columnDefs.push(...expertStatusColumns);
						} else {
							vm.supplyOrderOptions.columnDefs.push(...ebsStatusColumns);
						}

					});

				});

			if ($stateParams.popup === appConfig.popups.orderDetails && $stateParams.popupParam) {
				vm.supplyOrderOptions.preSelectedFilters = [{
					fieldName: 'mspOrderIdText',
					displayName: vm.orderTitle,
					filterConditions: [{
						displayValue: $stateParams.popupParam,
						filterValue: $stateParams.popupParam,
						operator: 'eq',
						type: 'string'
					}]
				}];

				var unregister = $scope.$watch('vm.supplyOrderOptions.rowData', function() {
					if (vm.supplyOrderOptions.rowData && vm.supplyOrderOptions.rowData.length === 1) {
						vm.supplyOrderOptions.defaultRowAction(vm.supplyOrderOptions.rowData[0]);
						$location.search('popup', null);
						$location.search('popupParam', null);
						unregister();
					}
				});
			}

			vm.load = true;
			logisticsService.registerRefresh(refreshGrid);
		}
		activate();
	}
})();
