(function() {

	'use strict';
	angular
		.module("portalApp")
		.service("dataManagementService", DataManagementService);

	/**
	 * @ngInject
	 */
	function DataManagementService($http, $log, $q, appConfig, appSession, ertSgModal, loadingIndicatorService) {
		var service = {
			getTickets: getTickets,
			getTicketsViewConfig: getTicketsViewConfig,
			getTicketData: getTicketData,
			createTicket: createTicket,
			answerTicket: answerTicket,
			showAnswerDcr: showAnswerDcr,
			showNewDcr: showNewDcr,
			showDcrDetails: showDcrDetails,
			refreshTicketsGrid: refreshTicketsGrid,
			registerAnswerDcr: registerAnswerDcr,
			registerDcrDetails: registerDcrDetails,
			registerRefresh: registerRefresh,
			getSites: getSites,
			getSubjects: getSubjects,
			getDemographics: getDemographics,
			getSubjectDemographics: getSubjectDemographics,
			getSubjectVisits: getSubjectVisits,
			getSubjectVisitEvents: getSubjectVisitEvents,
			getSubjectVisitDetails: getSubjectVisitDetails,
			getSubjectEventDetails: getSubjectEventDetails,
			getIsCreateDcrAllowedForSite: getIsCreateDcrAllowedForSite,
			getSharedData: getSharedData,
			isAnswerDcrHandlerRegistered
		};

		var answerDcrHandler;
		var dcrDetailsHandler;
		var refreshHandler;
		var sharedData = {};

		$log = $log.getInstance("DatamanementService");

		return service;

		function getSharedData() {
			return sharedData;
		}

		function registerRefresh(handler) {
			refreshHandler = handler;
		}

		function registerDcrDetails(handler) {
			dcrDetailsHandler = handler;
		}

		function registerAnswerDcr(handler) {
			answerDcrHandler = handler;
		}

		function isAnswerDcrHandlerRegistered() {
			return !!answerDcrHandler;
		}

		function refreshTicketsGrid() {
			if (refreshHandler) {
				refreshHandler();
			}
		}

		function getDemographics(siteId, subjectId, transmittalId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.demographics.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'siteId': siteId,
				'subjectId': subjectId,
				'transmittalId': transmittalId || ''
			});
			return $http.get(url);
		}

		function getSubjectDemographics(siteId, subjectId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.subjectDemographics.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'siteId': siteId,
				'subjectId': subjectId
			});
			return $http.get(url);
		}

		function getSubjectVisits(siteId, subjectId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.subjectVisits.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'siteId': siteId,
				'subjectId': subjectId
			});
			return $http.get(url);
		}

		function getSubjectVisitEvents(siteId, subjectId, visitName) {
			var currentStudy = appSession.getStudy();
			visitName = encodeURIComponent(visitName);
			var url = appConfig.apiUrls.dataManagement.subjectVisitEvents.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'siteId': siteId,
				'subjectId': subjectId,
				'visitName': visitName
			});
			return $http.get(url);
		}

		function getSubjectVisitDetails(siteId, subjectId, visitName) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.subjectVisitDetails.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'siteId': siteId,
				'subjectId': subjectId,
				'visitName': visitName
			});
			return $http.get(url);
		}

		function getSubjectEventDetails(siteId, subjectId, visitName, eventId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.subjectEventDetails.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'siteId': siteId,
				'subjectId': subjectId,
				'visitName': visitName,
				'eventId': eventId
			});
			return $http.get(url);
		}

		function getSites() {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.sitesForNewDcr.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId
			});
			return $http.get(url);
		}

		function getSubjects(siteId) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.subjects.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'siteId': siteId
			});
			return $http.get(url);
		}

		function getTicketData(ticketId) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.ticketdata.supplant({
				studyId: study.id,
				studyModeId: study.studyModeId,
				ticketId: ticketId
			});
			$log.debug("DatamanementService.GetTicket with url:  `{0}` ", [url]);
			return $http.get(url);
		}

		function getIsCreateDcrAllowedForSite(site) {
			var q = $q.defer();

			if (site) {
				var currentStudy = appSession.getStudy();

				var url = appConfig.apiUrls.dataManagement.createDcrPermissionForSite.supplant({
					'studyId': currentStudy.id,
					'studyModeId': currentStudy.studyModeId,
					siteId: site
				});

				$http.get(url, {
					cache: true,
					params: {
						cacheContext: 'clinicalData'
					}
				}).then(function(permission) {
					q.resolve(permission.data);
				});
			} else {
				q.resolve(false);
			}

			return q.promise;
		}

		function getTicketsViewConfig(options) {
			if (!service.ticketViewConfigQ) {
				var q = $q.defer();
				service.ticketViewConfigQ = q;
			}

			if (!service.ticketViewConfigLoading) {
				service.ticketViewConfigLoading = true;
				var study = appSession.getStudy();
				var url = appConfig.apiUrls.dataManagement.ticketsViewConfig.supplant({
					'studyId': study.id,
					'studyModeId': study.studyModeId
				});
				$log.debug("DatamanementService.GetTicketsViewConfig with url:  `{0}` ", [url]);
				$http.get(url, {
					cache: true,
					params: {
						cacheContext: 'study'
					}
				}).then(function(response) {
					service.ticketViewConfigLoading = false;
					service.ticketViewConfigQ.resolve(response.data);
					service.ticketViewConfigQ = null;
				}, function(response) {
					service.ticketViewConfigLoading = false;
					service.ticketViewConfigQ.reject(response.data);
				});
			}
			return service.ticketViewConfigQ.promise;
		}

		function getTickets(options, forExport) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.tickets.supplant({
				'studyId': study.id,
				'studyModeId': study.studyModeId,
				'oDataQuery': options,
				'forExport': forExport ? '1' : '0'
			});

			return $http.get(url);
		}

		function createTicket(ticket) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.createTicket.supplant({
				'studyId': study.id,
				'studyModeId': study.studyModeId
			});
			var data = JSON.stringify(ticket);
			return $http.post(url, data);
		}

		function answerTicket(ticketId, ticket) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.dataManagement.answerTicket.supplant({
				'studyId': study.id,
				'studyModeId': study.studyModeId,
				ticketId: ticket.ticketId
			});
			var data = JSON.stringify(ticket);
			return $http.post(url, data);
		}

		function showAnswerDcr(ticketId) {
			if (answerDcrHandler) {
				loadingIndicatorService.show();
				answerDcrHandler.showAnswerDcr(ticketId);
			}
		}

		function showDcrDetails(tickets, ticket, index) {
			ertSgModal.open({
				templateUrl: "views/dataManagement/ticketdetails.html",
				controller: "TicketDetailsController",
				controllerAs: "vm",
				inputs: {
					options: {
						tickets,
						ticket,
						index
					}
				}
			});
		}


		function showNewDcr() {
			$log.debug("showNewDetails");
			ertSgModal.open({
				templateUrl: "views/dataManagement/newDcrWizard.html",
				controller: "NewDcrController",
				controllerAs: "vm"
			});
		}
	}
})();
