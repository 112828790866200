import templateUrl from './radioButtonGroup.html';
import controller from './radioButtonGroup.controller';
import './radioButtonGroup.scss';

const radioButtonGroup = {
	restrict: 'E',
	bindings: {
		name: '@',
		options: '<',
		buttonOptions: '<',
		onChange: '&',
		componentReady: '&'
	},
	transclude: true,
	require: {
		fg: '^form'
	},
	templateUrl,
	controller
};

export default radioButtonGroup;

