/*
 * baseTimePickerModule
 * */

import baseTimePickerComponent from './baseTimePicker.component';

const baseTimePickerModule = angular.module('app.common.timePicker.baseTimePicker', [])
	.component('baseTimePicker', baseTimePickerComponent)
	.name;

export default baseTimePickerModule;
