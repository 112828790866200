(function () {
	'use strict';

	angular
		.module('portalApp')
		.service('clinicalDataService', clinicalDataService);

	/*
	 *@ngInject
	 */
	function clinicalDataService($log, $http, $q, appSession, appConfig) {

		var service = {
			unassignDevice,
			getDeactiveDeviceReasons,
			getEcoaHandheldAdminAccessCode,
			getUnlockCode,
			getEcoaTabletStartupCode,
			getSiteDeviceInfo,
			getSubjectDeviceInfo,
			getCountries: getCountries,
			getCountriesMinimal: getCountriesMinimal,
			getSubjects: getSubjects,
			getSubjectsMinimal: getSubjectsMinimal,
			getSitesMinimal: getSitesMinimal,
			getSites: getSites,
			getSite,
			getEventsMinimal: getEventsMinimal,
			getCountryInfo: getCountryInfo,
			getSiteInfo: getSiteInfo,
			getSiteTimezoneInStudy: getSiteTimezoneInStudy,
			getSubjectInfo,
			getVisits: getVisits,
			getSubjectCalendar: getSubjectCalendar,
			getSiteCalendar: getSiteCalendar,
			openCreateSubject: openCreateSubject,
			openCloseVisit: openCloseVisit,
			openCalendar: openCalendar,
			closeCreateSubject: closeCreateSubject,
			openEditSubject: openEditSubject,
			closeEditSubject: closeEditSubject,
			closeCloseVisit: closeCloseVisit,
			openSendPin: openSendPin,
			openEditContactData: openEditContactData,
			setClinicalDataController: setClinicalDataController,
			unsetClinicalDataController: unsetClinicalDataController,
			setCreateSubjectHandler: setCreateSubjectHandler,
			setSendPinHandler: setSendPinHandler,
			setCalendarHandler: setCalendarHandler,
			setEditSubjectHandler: setEditSubjectHandler,
			setEditContactDataHandler: setEditContactDataHandler,
			setCloseVisitHandler: setCloseVisitHandler,
			getEventReports: getEventReports,
			getEventReportsAccrossStudies,
			getEventAttachments: getEventAttachments,
			setCanCloseVisit: setCanCloseVisit,
			getSiteDevices: getSiteDevices,
			getSubjectDevices: getSubjectDevices,
			isEditSubjectHandlerRegistered,
			isCreateSubjectHandlerRegistered,
			// ispiro
			showDispenseDevicePopup,
			showEditDeviceValuesPopup,
			showLostDevicePopup,
			showResetPinPopup,
			showScheduleEventPopup,
			showCloseDevicePopup,
			showRemoteCloseDevicePopup,
			showDispenseKardiaPopup,
			showCloseKardiaPopup,
			setRefreshCallback,
			unsetRefreshCallback
		};

		var clinicalDataController;
		var createSubjectHandler;
		var sendPinHandler;
		var editContactDataHandler;
		var closeVisitHandler;
		var editSubjectHandler;
		var calendarHandler;
		var vm = this;

		return service;

		function showDispenseKardiaPopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
			if (clinicalDataController) {
				clinicalDataController.showDispenseKardiaPopup(
					countryId, siteId, subjectId, displaySiteName, subjectKey);
			}
		}

		function showCloseKardiaPopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
			if (clinicalDataController) {
				clinicalDataController.showCloseKardiaPopup(
					countryId, siteId, subjectId, displaySiteName, subjectKey);
			}
		}
		// ISpiro
		function showDispenseDevicePopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
			if (clinicalDataController) {
				clinicalDataController.showDispenseDevicePopup(
					countryId, siteId, subjectId, displaySiteName, subjectKey);
			}
		}

		function showEditDeviceValuesPopup(
			isRemoteAction, countryId, siteId, subjectId, displaySiteName, subjectKey) {
			if (clinicalDataController) {
				clinicalDataController.showEditDeviceValuesPopup(
					isRemoteAction, countryId, siteId, subjectId, displaySiteName, subjectKey);
			}
		}

		function showResetPinPopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
			if (clinicalDataController) {
				clinicalDataController.showResetPinPopup(
					countryId, siteId, subjectId, displaySiteName, subjectKey);
			}
		}

		function showScheduleEventPopup(countryId, siteId, displaySiteName, technicalSubjectId, subjectKey) {
			if (clinicalDataController) {
				clinicalDataController.showScheduleEventPopup(countryId, siteId, displaySiteName, technicalSubjectId, subjectKey);
			}
		}

		function showLostDevicePopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
			if (clinicalDataController) {
				clinicalDataController.showLostDevicePopup(
					countryId, siteId, subjectId, displaySiteName, subjectKey);
			}
		}

		function showCloseDevicePopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
			if (clinicalDataController) {
				clinicalDataController.showCloseDevicePopup(countryId, siteId, subjectId, displaySiteName, subjectKey);
			}
		}

		function showRemoteCloseDevicePopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
			if (clinicalDataController) {
				clinicalDataController.showRemoteCloseDevicePopup(countryId, siteId, subjectId, displaySiteName, subjectKey);
			}
		}
		// ISpiro end

		function setEditContactDataHandler(ctrl) {
			editContactDataHandler = ctrl;
		}

		function setCloseVisitHandler(ctrl) {
			closeVisitHandler = ctrl;
		}

		function setSendPinHandler(ctrl) {
			sendPinHandler = ctrl;
		}

		function setCreateSubjectHandler(ctrl) {
			createSubjectHandler = ctrl;
		}

		function isCreateSubjectHandlerRegistered() {
			return !!createSubjectHandler;
		}

		function setEditSubjectHandler(ctrl) {
			editSubjectHandler = ctrl;
		}

		function isEditSubjectHandlerRegistered(ctrl) {
			return !!editSubjectHandler;
		}

		function setClinicalDataController(ctrl) {
			clinicalDataController = ctrl;
		}

		function unsetClinicalDataController(ctrl) {
			if (clinicalDataController == ctrl) {
				clinicalDataController = null;
			}
		}

		function setRefreshCallback(fn) {
			if (clinicalDataController) {
				clinicalDataController.refreshCallback = fn;
			}
		}

		function unsetRefreshCallback() {
			if (clinicalDataController) {
				clinicalDataController.refreshCallback = null;
			}
		}

		function setCalendarHandler(ctrl) {
			calendarHandler = ctrl;
		}

		function openSendPin(row) {
			if (!row.eproSubjectId) {
				clinicalDataController.showNoDeviceSubjectMessage(row);
			}
			else if (sendPinHandler) {
				sendPinHandler.showSendPin(row);
			}
		}

		function openCloseVisit() {
			if (closeVisitHandler) {
				closeVisitHandler.showCloseVisit();
			}
		}

		function openEditContactData(row) {
			if (!row.eproSubjectId) {
				clinicalDataController.showNoDeviceSubjectMessage(row);
			}
			else if (editContactDataHandler) {
				editContactDataHandler.showEditContact(row);
			}
		}

		function openCalendar(countryId, siteId, subjectId) {
			if (calendarHandler) {
				calendarHandler.showCalendar(countryId, siteId, subjectId);
			}
		}

		function openCreateSubject() {
			if (clinicalDataController) {
				clinicalDataController.isVisibleSubjectCreatePopup = true;
				clinicalDataController.editCreateSubjectTitle = 'clinicaldata.subject.create.title';
			}
			if (createSubjectHandler) {
				createSubjectHandler.showCreateSubject();
			}
		}

		function closeCreateSubject() {
			if (clinicalDataController) {
				clinicalDataController.isVisibleSubjectCreatePopup = false;
			}
		}

		function openEditSubject(row) {
			if (clinicalDataController) {
				clinicalDataController.isVisibleSubjectCreatePopup = true;
				clinicalDataController.editCreateSubjectTitle = 'clinicaldata.subject.edit.title';
			}
			if (editSubjectHandler) {
				editSubjectHandler.showEditSubject(row);
			}
		}

		function closeEditSubject() {
			if (clinicalDataController) {
				clinicalDataController.isVisibleSubjectCreatePopup = false;
			}
		}

		function closeCloseVisit() {
			if (closeVisitHandler) {
				closeVisitHandler.closeCloseVisit();
			}
		}

		function getSiteDevices(deviceId, tdtId, mdtId, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.deviceManagement.siteDevices.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				deviceId: deviceId, oDataQuery: oDataQuery
			});
			return $http.get(url);
		}

		function getSubjectDevices(deviceIds, tdtId, mdtId, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.deviceManagement.subjectDevices.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				deviceIds: deviceIds || '', tdtId: tdtId || '', mdtId: mdtId || '',
				oDataQuery: oDataQuery || ''
			});
			return $http.get(url);
		}

		function getSiteCalendar(countryId, siteId, isVisitCalendar, from, to, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.siteCalendar.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				countryId: countryId, siteId: siteId, isVisitCalendar: isVisitCalendar ? 'true' : 'false',
				from: from ? from : '', to: to ? to : '', oDataQuery: oDataQuery ? oDataQuery : ''
			});
			return $http.get(url);
		}

		function getSubjectCalendar(countryId, siteId, subjectId, isVisitCalendar, from, to, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.subjectCalendar.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				countryId: countryId, siteId: siteId, isVisitCalendar: isVisitCalendar ? 'true' : 'false',
				subjectId: subjectId, from: from, to: to, oDataQuery: oDataQuery ? oDataQuery : ''
			});
			return $http.get(url);
		}

		function getCountries(oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.countries.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				'oDataQuery': (oDataQuery || '')
			});

			$log.debug('clinicalDataService.getCountries with url: {url}', {url: url});
			return $http.get(url);
		}

		function getCountriesMinimal() {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.countriesMinimal.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId
			});
			return $http.get(url, {cache: true, params: {cacheContext: 'clinicalData'}});
		}

		function getSubjects(countryId, siteId, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.subjects.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				'countryId': (countryId || ''),
				'siteId': (siteId || ''), 'oDataQuery': (oDataQuery || '')
			});
			$log.debug('clinicalDataService.getSubjects with url: {url}', {url: url});
			return $http.get(url);
		}

		function getSubjectsMinimal(countryId, siteId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.subjectsMinimal.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				countryId: countryId, siteId: siteId
			});
			return $http.get(url, {cache: true, params: {cacheContext: 'clinicalData'}});
		}

		function getEventsMinimal(siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.eventsMinimal.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				siteId: siteId, subjectId: subjectId
			});
			return $http.get(url, {cache: true, params: {cacheContext: 'clinicalData'}});
		}

		function getVisits(countryId,siteId, subjectId, withScheduledEvents, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.visits.supplant({
				studyId: currentStudy.id, studyModeId: currentStudy.studyModeId,
				countryId: countryId,siteId: siteId, subjectId: subjectId,
				oDataQuery: oDataQuery, withScheduledEvents: withScheduledEvents ? 'true' : 'false'
			});
			$log.debug('clinicalDataService.getVisits with url: {url}', {url: url});
			return $http.get(url);
		}

		function getEventReportsAccrossStudies(studyId, studyModeId, siteId, subjectId, eproSubjectId, eventId, documentTypeId) {
			const url = appConfig.apiUrls.clinicalData.eventReports.supplant({
				studyId,
				studyModeId,
				siteId,
				subjectId,
				eproSubjectId: (eproSubjectId || ''),
				eventId: eventId || '',
				documentTypeId: documentTypeId || ''
			});
			$log.debug('clinicalDataService.getEventReportsAccrossStudies with url: {url}', {url: url});
			return $http.get(url);
		}

		function getEventReports(siteId, subjectId, eproSubjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.eventReports.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				siteId,
				subjectId,
				eproSubjectId: (eproSubjectId || ''),
				eventId: '',
				documentTypeId: ''
			});
			$log.debug('clinicalDataService.getVisits with url: {url}', {url: url});
			return $http.get(url);
		}

		function getEventAttachments(siteId, subjectId, eventId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.eventAttachments.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				siteId: siteId, subjectId: subjectId, eventId: eventId || ''
			});
			$log.debug('clinicalDataService.eventAttachments with url: {url}', {url: url});
			return $http.get(url);
		}

		function getSite(siteId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.site.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				'siteId': siteId,
			});

			$log.debug('clinicalDataService.getSite with url: {url}', {url: url});
			return $http.get(url);
		}

		function getSites(countryId, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.sites.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				'countryId': countryId,
				'oDataQuery': oDataQuery
			});
			$log.debug('clinicalDataService.getSites with url: {url}', {url: url});
			return $http.get(url);
		}

		function getSitesMinimal(studyObj, countryId) {
			let currentStudy = {};
			if (studyObj && studyObj.id && studyObj.studyModeId != null) {
				currentStudy = studyObj;
			} else {
				currentStudy = appSession.getStudy();
			}
			const url = appConfig.apiUrls.clinicalData.sitesMinimal.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				'countryId': countryId || ''
			});

			$log.debug('clinicalDataService.getSites with url: {url}', {url: url});
			return $http.get(url, {cache: true, params: {cacheContext: 'clinicalData'}});
		}
		function getCountryInfo(countryId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.countryInfo.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				'countryId': countryId
			});

			$log.debug('clinicalDataService.getCountryInfo with url: {url}', {url: url});
			return $http.get(url);
		}

		function getSiteInfo(siteId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.siteInfo.supplant(
				{'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId, 'siteId': siteId});
			return $http.get(url);

		}

		function getSiteTimezoneInStudy(studyId, studyModeId, siteId) {
			const url = appConfig.apiUrls.clinicalData.siteTimezone.supplant(
				{'studyId': studyId, 'studyModeId': studyModeId, 'siteId': siteId});
			return $http.get(url, {cache: true, params: {cacheContext: 'clinicalData'}});

		}

		function getSubjectInfo(siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.subjectInfo.supplant(
				{
					'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId, 'siteId': siteId, 'subjectId': subjectId
				});
			return $http.get(url);
		}

		function getSubjectDeviceInfo(siteId, subjectId, eproSubjectId, deviceId, deviceSerialNumber) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.deviceManagement.getSubjectDeviceInfo.supplant(
				{
					'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId, 'siteId': siteId, 'subjectId': subjectId, 'eproSubjectId': eproSubjectId || 0, deviceId, deviceSerialNumber
				});
			return $http.get(url);
		}

		function getSiteDeviceInfo(siteId, deviceId, deviceSerialNumber) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.deviceManagement.getSiteDeviceInfo.supplant(
				{
					'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId, 'siteId': siteId, deviceId, deviceSerialNumber
				});
			return $http.get(url);

		}

		function setCanCloseVisit(canClose) {
			if (!clinicalDataController || !clinicalDataController.loaded) {
				return;
			}

			clinicalDataController.loaded.then(
				function() {
					if (!clinicalDataController.actions) {
						return;
					}

					for (var i = 0; i < clinicalDataController.actions.length; ++i) {

						var action = clinicalDataController.actions[i];
						if (action.id === appConfig.actions.closeVisit) {
							if (canClose) {
								action.visibleIn = [appConfig.routes.clDataVisits];
							}
							else {
								action.visibleIn = ['nowhere'];
							}
							clinicalDataController.actions.updated = true;
						}
					}
				}
			);
		}

		function getEcoaHandheldAdminAccessCode(siteId) {
			vm.ecoaHandheldAdminAccessCodeDfd = $q.defer();
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.deviceManagement.getEcoaHandheldAdminAccessCode.supplant(
				{
					'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId, siteId
				});

			$http.get(url).then(
				(response) => {
					vm.ecoaHandheldAdminAccessCodeDfd.resolve(response);
				},
				(error) => {
					$log.error('Error getting admin access code');
					vm.ecoaHandheldAdminAccessCodeDfd.reject(error);
				}
			);
			return vm.ecoaHandheldAdminAccessCodeDfd.promise;
		}

		function getEcoaTabletStartupCode(siteId) {
			vm.ecoaTabletStartUpCodeDfd = $q.defer();
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.deviceManagement.getEcoaTabletStartupCode.supplant(
				{
					'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId, siteId
				});

			$http.get(url).then(
				(response) => {
					vm.ecoaTabletStartUpCodeDfd.resolve(response);
				},
				(error) => {
					$log.error('Error getting startup code');
					vm.ecoaTabletStartUpCodeDfd.reject(error);
				}
			);
			return vm.ecoaTabletStartUpCodeDfd.promise;
		}

		function getUnlockCode(date) {
			vm.unlockCodeDfd = $q.defer();
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.deviceManagement.getUnlockCode.supplant(
				{
					'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId, date
				});

			$http.get(url).then(
				(response) => {
					vm.unlockCodeDfd.resolve(response);
				},
				(error) => {
					$log.error('Error getting unlock code');
					vm.unlockCodeDfd.reject(error);
				}
			);
			return vm.unlockCodeDfd.promise;
		}

		function unassignDevice(siteId, eproSubjectId, deviceId, reasonId) {
			return $q((resolve, reject) => {
				const currentStudy = appSession.getStudy();
				const url = appConfig.apiUrls.deviceManagement.unassignDevice.supplant(
					{
						'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId
					});

				const data = {
					siteId,
					eproSubjectId,
					deviceId,
					reasonId
				};
				$http.post(url, data).then(
					(response) => {
						resolve(response);
					},
					(error) => {
						$log.error('Error unassigning device');
						reject(error);
					}
				);
			});
		}

		function getDeactiveDeviceReasons() {
			return $q((resolve, reject) => {
				const currentStudy = appSession.getStudy();
				const url = appConfig.apiUrls.deviceManagement.getDeactiveDeviceReasons.supplant(
					{
						'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId
					});

				$http.get(url).then(
					(response) => {
						resolve(response);
					},
					(error) => {
						$log.error('Error getting unassign reasons');
						reject(error);
					}
				);
			});
		}
	}
})();
