/*
 * announcementsDetailsPopupModule
 * */

import announcementsDetailsPopupComponent from './announcementsDetailsPopup.component';
import selectableRowModule from './announcementsRow/index';

const announcementsDetailsPopupModule = angular
	.module('app.components.dashboard.announcementsWidget.announcementsDetailsPopup', [selectableRowModule])
	.component('announcementsDetailsPopup', announcementsDetailsPopupComponent)
	.name;

export default announcementsDetailsPopupModule;
