class DeviceManagementSiteCtrl {

	constructor($log, clinicalDataService, eproService, loadingIndicatorService, popupService,
				$stateParams, $state, appI18n) {
		'ngInject';

		$log = $log.getInstance('SiteDeviceController');
		$log.debug(' loaded');

		this.log = $log;

		const deviceId = $state.current.params.deviceId;
		const tdtId = $state.current.params.tdtId;
		const mdtId = $state.current.params.mdtId;
		const self = this;

		this.appI18n = appI18n;
		this.isEcoaHandheld = $state.current.params.isEcoaHandheld;
		this.clinicalDataService = clinicalDataService;
		this.popupService = popupService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.eproService = eproService;
		this.log = $log;

		this.options = {
			columnDefs: [
				{ translationKey: 'deviceManagement.columns.deviceId', fieldName: 'protocolDeviceSerialNumber',
					isSortable: true, type: 'string', isDefaultFilter: true },
				{ translationKey: 'deviceManagement.columns.site',
				 fieldName: 'sponsorSiteId', isSortable: true, type: 'string' },
				{ translationKey: 'deviceManagement.columns.status', fieldName: 'deviceStatus', isSortable: true,
					type: 'enum', formatter: 'activeInactive', enumType: 'bool',
					enums: [
						{ displayName: this.appI18n.translateImmediate('deviceManagement.labels.active'),
							values: ['1'] },
						{ displayName: this.appI18n.translateImmediate('deviceManagement.labels.inactive'),
							values: ['-1'] }
					]
				}
			],
			sortOrders: [
				{ fieldName: 'sponsorSiteId' },
				{ fieldName: 'deviceStatus' },
				{ fieldName: 'deviceId' }
			],
			gridRowActions: [{
				displayName: this.appI18n.translateImmediate('deviceManagement.labels.deviceInfo'),
				iconCssClass: this.isEcoaHandheld ? 'fa fa-qrcode' : 'es-icon-info-circle',
				action: (row) => {
					self.showSiteDeviceInfo(row);
				}
			}],
			getData: (options) => {
				return clinicalDataService.getSiteDevices(deviceId, tdtId, mdtId, options);
			}

		};

		this.load = true;
	}


	showSiteDeviceInfo(row) {
		const self = this;

		this.deviceName = row.deviceName;
		this.deviceSerialNumber = row.protocolDeviceSerialNumber;

		this.properties = [];
		this.loadingIndicatorService.show();

		this.clinicalDataService.getSiteDeviceInfo(row.siteId, row.deviceId, row.protocolDeviceSerialNumber)
			.then ((response) => {
				self.properties = response.data;
			})
			.catch((error) => {
				self.log.error('error in getSiteDeviceInfo: {0}', error);
				self.hasError = true;
			})
			.finally(() => {
				self.showDeviceInfo = true;
				self.loadingIndicatorService.hide();
			});
	}

	static id() {
		return 'DeviceManagementSiteCtrl';
	}
}

export default DeviceManagementSiteCtrl;
