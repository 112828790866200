import templateUrl from './scheduledEvents.html';
import controller from './scheduledEvents.controller';
import './scheduledEvents.scss';

const scheduledEventsComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default scheduledEventsComponent;
