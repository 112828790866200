(function () {

	'use strict';

	angular
		.module('portalApp')
		.controller('SqfSitePIWizardCtrl', SqfSitePIWizardController);

	/**
	 * @ngInject
	 */
	function SqfSitePIWizardController($scope, $stateParams, $log, sqfService, WizardHandler, dialogService,
									  loadingIndicatorService) {
		var vm = this;

		$log = $log.getInstance("SqfSitePIWizardController");
		$log.debug("SqfSitePIWizardController loaded, sqfId:" + $stateParams.sqfId);

		vm.name = $scope.shared.localized.wizardTitles.siteInfo;
		vm.dataLoading = true;
		vm.saving = false;
		vm.loadingError = '';
		vm.errorMsgs = [];
		vm.sqfId = 0;
		vm.model = {};
		vm.options = {formState: {}};
		vm.forms = {};
		vm.confirmHandler = {showDialog: false};
		vm.showWizard = showWizard;
		vm.actions = [];
		sqfService.registerSitePIHandler(vm);

		if ($stateParams.sqfId >= 0) {
			//$scope.shared= {newSqf:true, setActions : function () {}};
			showWizard($stateParams.sqfId);
		}

		vm.saveSiteInformation = saveSiteInformation;
		vm.back = back;
		function getFields() {
			return [
				{
					wrapper: 'twoColumn',
					fieldGroup: [
						{
							key: 'siteId.siteIdType',
							type: 'radioInline',
							templateOptions: {
								label: $scope.shared.localized.siteInfo.siteIdQuestion,
								options: [
									{name: $scope.shared.localized.options.yes, value: 'knownSiteId'},
									{name: $scope.shared.localized.options.no, value: 'tempSiteId'}
								]
							}
						},
						{
							template: "<div class='es-content-box'><div class='es-form-message -es-info'>" + $scope.shared.localized.siteInfo.siteIdDescription.supplant({siteIdFormatHint: '{{formState.config.siteIdFormatHint}}'}) + "</div></div>",
							hideExpression: "model.siteId.siteIdType !== 'knownSiteId'"
						},
						{
							key: 'siteId.siteId',
							type: 'inputInline',
							templateOptions: {
								type: 'text',
								label: $scope.shared.localized.siteInfo.siteId,
								onChange: function ($viewValue, $modelValue) {
									if (vm.model.siteId.siteId) {
										vm.model.siteId.siteId = vm.model.siteId.siteId.toUpperCase();
									}
								},
							},
							ngModelElAttrs: {
								maxlength: '50'
							},
							hideExpression: "model.siteId.siteIdType !== 'knownSiteId'",
							expressionProperties: {
								'templateOptions.required': "model.siteId.siteIdType === 'knownSiteId'",
								'templateOptions.patternValidationMessage': 'formState.config.siteIdFormatHint',
								'templateOptions.pattern': 'formState.config.siteIdFormatRegexp'
							},
							validators: {
								maxValue: {
									expression: function (viewValue, modelValue) {
										var value = modelValue || viewValue;
										var valueNumber = +value;
										if (vm.options.formState.config.siteIdMaxValue !== null) {
											if (!$.isNumeric(value) || valueNumber > vm.options.formState.config.siteIdMaxValue) {
												return false;
											}
										}
										return true;
									},
									message: "'{message} {value}'".supplant({message: $scope.shared.localized.messages.maxField, value: vm.options.formState.config.siteIdMaxValue})
								},
								minValue: {
									expression: function (viewValue, modelValue) {
										var value = modelValue || viewValue;
										var valueNumber = +value;
										if (vm.options.formState.config.siteIdMinValue !== null) {
											if (!$.isNumeric(value) || valueNumber < vm.options.formState.config.siteIdMinValue) {
												return false;
											}
										}
										return true;
									},
									message: "'{message} {value}'".supplant({message: $scope.shared.localized.messages.minField, value: vm.options.formState.config.siteIdMinValue})
								}
							}
						},
						{
							key: 'siteId.tempSiteId',
							type: 'inputInline',
							templateOptions: {
								type: "text",
								label: $scope.shared.localized.siteInfo.tempSiteId,
								disabled: true
							},
							hideExpression: "model.siteId.siteIdType !== 'tempSiteId'"
						},
						{
							key: 'timezoneId',
							type: 'selectInline',
							wrapper: ['loading', 'validation'],
							templateOptions: {
								label: $scope.shared.localized.siteInfo.timezone,
								options: []
							},
							controller: /* @ngInject */ function ($scope) {
								$scope.to.loading = sqfService.getTimezones().then(function (response) {
									$scope.to.options = response.data;
									return response;
								}, function (error) {
									vm.loadingError = error.data.message || error.data;
								});
							},
							expressionProperties: {
								'templateOptions.required': "formState.config.timeZoneIsRequired"
							},
							extras: {
								validateOnModelChange: true
							}
						},
						{
							key: 'language',
							type: 'restrictedInputInline',
							templateOptions: {
								type: 'text',
								label: $scope.shared.localized.siteInfo.language
							},
							ngModelElAttrs: {
								maxlength: '50'
							},
							expressionProperties: {'templateOptions.required': "formState.config.languageIsRequired"}
						}
					]
				},
				{
					wrapper: 'twoColumn',
					fieldGroup: [
						{
							key: 'piContact.isIndividual',
							type: 'radioInline',
							templateOptions: {
								label: $scope.shared.localized.siteInfo.siteType,
								controlClass: 'radio-inline',
								options: [{
									"name": $scope.shared.localized.options.individual,
									"value": true
								},
									{
										"name": $scope.shared.localized.options.institution,
										"value": false
									}],
								onChange: function ($viewValue, $modelValue, scope) {
									if ($modelValue.value() === true) {
										vm.model.piContact.institutionName = '';
									}
								}
							},
							expressionProperties: {
								'templateOptions.required': "formState.config.institutionNameIsRequired"
							}
						},
						{
							key: 'piContact.institutionName',
							type: 'restrictedInputInline',
							templateOptions: {
								type: 'text',
								label: $scope.shared.localized.siteInfo.institution
							},
							ngModelElAttrs: {
								maxlength: '100'
							},
							expressionProperties: {
								'templateOptions.required': "!model.piContact.isIndividual && formState.config.institutionNameIsRequired",
								'templateOptions.disabled': "model.piContact.isIndividual"
							}
						}
					]
				}
			];
		}

		function clear() {
			vm.dataLoading = true;
			vm.saving = false;
			vm.loadingError = '';
			vm.errorMsgs = [];
			vm.sqfId = 0;
			vm.model = {};
			vm.options = {formState: {}};
			vm.forms = {};
		}

		function setActions() {
			vm.actions.length = 0;
			if ($scope.shared.newSqf === true) {
				vm.actions.push({displayName: $scope.shared.localized.buttons.next,
								 action: saveSiteInformation, isPrimary: true});
				//vm.actions.push({displayName: $scope.shared.localized.buttons.close, action: back, cssClass: 'btn-danger'});
			}
			else {
				vm.actions.push({displayName: $scope.shared.localized.buttons.save, action: saveSiteInformation,
								 isPrimary: true});
				vm.actions.push({displayName: $scope.shared.localized.buttons.cancel, action: back});
			}
			$scope.shared.handleHide = function(){confirmClose();};
			updateActions();
			$scope.shared.setActions(vm.actions);
		}

		function updateActions() {
			vm.actions[0].isDisabled = vm.dataLoading || vm.saving;
			if (vm.actions.length > 1) {
				vm.actions[1].isDisabled = vm.saving;
			}
		}

		function goToNextStep() {
			if ($scope.shared.newSqf === true) {
				WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.contacts);
				sqfService.showContactWizard(vm.sqfId, 0);
			}
			else {
				showSummary();
			}
		}

		function showSummary() {
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.summary);
			sqfService.showSummaryView(vm.sqfId);
		}

		function back(confirmed) {
			if (vm.forms.$dirty !== true || confirmed) {
				if (vm.sqfId > 0 && $scope.shared.newSqf === false) {
					showSummary();
				}
				else {
					$scope.shared.closeWizard();
				}
			}
			else {
				vm.confirmHandler = dialogService.createConfirmHandler();
				vm.confirmHandler.show().then(function (response) {
					if (response === true) {
						vm.confirmHandler.hide();
						back(true);
					}
				});
			}
		}

		function confirmClose() {
			if (vm.forms.$dirty !== true) {
				$scope.shared.closeWizard();
			}
			else {
				vm.confirmHandler = dialogService.createConfirmHandler();
				vm.confirmHandler.show().then(function (response) {
					if (response === true) {
						vm.confirmHandler.hide();
						$scope.shared.closeWizard();
					}
				});
			}
		}

		function saveSiteInformation() {
			if (!vm.forms.$valid) {
				vm.forms.$setSubmitted();
				return false;
			}
			vm.errorMsgs = [];
			vm.saving = true;
			updateActions();
			sqfService.saveSitePIInfo(vm.model)
				.then(function (response) {
					if (response.data.result.sqfId > 0) {
						vm.sqfId = response.data.result.sqfId;
						vm.model.sqfId = response.data.result.sqfId;
					}
					if (response.data.result.tempSiteId) {
						vm.model.siteId.tempSiteId = response.data.result.tempSiteId;
					}
					if (response.data.isSuccessful) {
						goToNextStep();
					} else {
						vm.saving = false;
						updateActions();
						vm.errorMsgs = response.data.messages;
					}
				}, function (error) {
					vm.errorMsgs = [error.data.message || error.data];
					vm.saving = false;
					updateActions();
				});
		}

		function showWizard(sqfId) {
			clear();
			vm.sqfId = sqfId;
			setActions();
			return sqfService.getSitePIInfo(vm.sqfId)
				.then(function (response) {
					if (!vm.loadingError) {
						//vm.model = response.data.model;
						vm.model.sqfId = response.data.model.sqfId;
						vm.model.timezoneId = response.data.model.timezoneId;
						vm.model.language = response.data.model.language;
						vm.model.siteId = response.data.model.siteId;
						vm.model.piContact = response.data.model.piContact;
						vm.options.formState.config = response.data.config;
						vm.dataLoading = false;
						vm.fields = getFields();
						vm.originalFields = getFields();
						updateActions();
						loadingIndicatorService.hide();
					}
				}, function (error) {
					vm.loadingError = error.data.message || error.data;
					loadingIndicatorService.hide();
				});
		}
	}
})();
