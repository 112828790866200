/*
 * sendPinPopupController
 * */

class SendPinPopupController {
	constructor($log, $stateParams, eproService, configurationService, appSession,
	            appConfig, appI18n, dialogService, clinicalDataService, loadingIndicatorService) {
		'ngInject';
		this.$log = $log;
		this.$stateParams = $stateParams;
		this.eproService = eproService;
		this.configurationService = configurationService;
		this.appSession = appSession;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.dialogService = dialogService;
		this.clinicalDataService = clinicalDataService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.emailRegEx = /(.+)@(.+){2,}\.(.+){2,}/;

		this.actions = [{
			type: 'submit',
			cssClass: '-es-primary',
			displayName: 'app.buttons.ok'
		}, {
			action: () => {
				this.closeSendPin();
			},
			displayName: 'app.buttons.cancel'
		}];
	}

	$onInit() {
		this.$log = this.$log.getInstance('SendPinPopupController');
		this.$log.debug('loaded');

		this.$API = {
			open: (row) => {
				this.row = row;
				this.subjectId = row.subjectKey;
				this.isVisible = true;
			},
			close: () => {
				this.isVisible = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({ $API: this.$API });
		}
	}

	closeSendPin() {
		this.isVisible = false;
	}

	initForm(eproSubjectId) {
		this.loadingIndicatorService.show();
		this.model = {};
		this.model.changePin = 0;
		this.model.sendPin = 0;
		this.fields = [];
		this.pinData = {};
		this.validation = {
			required: {},
			errors: {}
		};
		this.errorMsg = null;

		this.eproSubjectId = eproSubjectId;
		return this.eproService.getSendPinData(eproSubjectId).then((response) => {
			this.loadingIndicatorService.hide();
			this.pinData = response.data;
			if (response.data.showInactiveWarning) {
				this.showInactiveWarning();
			}
		}, () => {
			this.setError('clinicaldata.subject.pin.errors.genericLoadError');
		});
	}

	validateForm() {
		this.validation.required = {};
		this.validation.errors = {};

		if (this.model.changePin === 2) {
			this.validateNewPin(true);
			this.validateConfirmPin(true);
		}

		if (this.model.sendPin === 2) {
			this.validatePinEmail(true);
		}

		if (this.model.changePin === 0 && this.model.sendPin === 0) {
			this.setFormError('clinicaldata.subject.pin.errors.nothingToDo');
			return false;
		}

		if (this.model.changePin === 1 && this.model.sendPin === 0) {
			this.setFormError('clinicaldata.subject.pin.errors.generatedPinMustBeSend');
			return false;
		}

		for (const req in this.validation.required) {
			if (this.validation.required[req]) {
				return false;
			}
		}

		for (const err in this.validation.errors) {
			if (this.validation.errors[err]) {
				return false;
			}
		}
		return true;
	}

	setFormError(errorMessage) {
		this.appI18n.translate(errorMessage).then((translation) => {
			this.validation.errors.formError = translation;
		});
	}

	validateNewPin(validateEmpty) {
		if (validateEmpty && (angular.isUndefined(this.model.newPin) || this.model.newPin.length === 0)) {
			this.validation.required.newPin = true;
		} else {
			this.validation.required.newPin = false;
		}

		// numeric 4-20 digits
		this.validation.errors.pinFormat = false;
		if (angular.isDefined(this.model.newPin) && this.model.newPin.length > 0) {
			const regex = /^[0-9]{4,20}$/;

			if (!this.model.newPin.match(regex)) {
				this.validation.errors.pinFormat = true;
			}
		}
	}

	validateConfirmPin(validateEmpty) {
		this.validation.errors.pinMismatch = false;
		if (!validateEmpty && (angular.isUndefined(this.model.confirmPin) || this.model.confirmPin.length === 0)) {
			return;
		}

		if (this.model.confirmPin !== this.model.newPin) {
			this.validation.errors.pinMismatch = true;
		}
	}

	validatePinEmail(validateEmpty) {
		this.validation.errors.pinEmailFormat = false;

		if (this.model.sendPin !== 2) {
			return;
		}

		if (!validateEmpty && (angular.isUndefined(this.model.pinEmail) || this.model.pinEmail.length === 0)) {
			return;
		}

		if (!(this.emailRegEx.test(this.model.pinEmail))) {
			this.validation.errors.pinEmailFormat = true;
		}
	}

	showInactiveWarning() {
		this.confirmInactiveDelayHandler = this.dialogService.createConfirmHandler();
		this.confirmInactiveDelayHandler.show().then((result) => {
			if (!result) {
				this.closeSendPin();
			}

			this.confirmInactiveDelayHandler.hide();
		});
	}

	doSendPin() {
		const isValid = this.validateForm();

		if (isValid) {
			this.loadingIndicatorService.show();

			const siteId = this.row.siteId;

			this.eproService.sendPin(siteId, this.eproSubjectId, this.model).then(
				(response) => {
					if (response.data.isValidationError) {
						this.setGenericSaveError(response);
					} else {
						this.closeSendPin();
					}
					this.loadingIndicatorService.hide();
				}, (response) => {
				this.setGenericSaveError(response);
			});
		}
	}

	setGenericSaveError(response) {
		const msg = (response.data && response.data.translationKey) ? response.data.translationKey
				: 'clinicaldata.subject.create.errors.genericSaveError';

		this.setError(msg);
	}

	setError(msg) {
		this.loadingIndicatorService.hide();
		this.hasErrors = true;
		this.appI18n.translate(msg).then((translation) => {
			this.errorMsg = translation;
		});
	}


}

export default SendPinPopupController;
