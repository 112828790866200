/*
 * breadcrumbsModule
 * */

import breadcrumbsComponent from './breadcrumbs.component';
import breadcrumbsService from './breadcrumbs.service';

const breadcrumbsModule = angular.module('app.common.breadcrumbs', [])
	  .component('breadcrumbs', breadcrumbsComponent)
	  .service('breadcrumbsService', breadcrumbsService)
	  .name;

export default breadcrumbsModule;
