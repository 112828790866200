/*
 * dataManagementFilesController
 * */

class DataManagementFilesController {
	constructor($log, appI18n, $window,
				notificationService, remoteFileService, popupService, loadingIndicatorService, fileUploadPopupService) {
		'ngInject';
		this.$log = $log;
		this.$window = $window;
		this.popupService = popupService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.notificationService = notificationService;
		this.remoteFileService = remoteFileService;
		this.appI18n = appI18n;

		const fileUploadApi = fileUploadPopupService.getPopupApi('documentsManagement');

		this.onClosePopupSubject = fileUploadApi.onClosePopup;
		this.onWhenAddingFileFailedSubject = fileUploadApi.onWhenAddingFileFailed;
		this.onSuccessItemSubject = fileUploadApi.onSuccessItem;
	}

	$onInit() {
		this.$log = this.$log.getInstance('DataManagementFilesController');
		this.$log.debug('loaded');

		this.load = true;
		this.appI18n.translate([
			'data-management-files.tabName',
			'data-management-files.messages.confirmDelete',
			'data-management-files.messages.successOnDelete',
			'data-management-files.messages.errorOnDelete',
			'data-management-files.messages.successOnUpload',
			'data-management-files.columns.deleteFile',
			'data-management-files.columns.downloadFile'
		]).then((translations) => {
			this.translations = translations;
			this.generateGridConfig();
			this.setUpFileUploadListeners();
		});
	}

	setUpFileUploadListeners() {
		this.onClosePopupSubject.subscribe((data) => {
			if (data.uploadedItems.length) {
				this.reloadGrid();
			}
		});
		this.onWhenAddingFileFailedSubject.subscribe((data) => {
			this.notificationService.showWarning(data.filter.errorMsg);
		});
		this.onSuccessItemSubject.subscribe((/*data*/) => {
			const msg = this.translations['data-management-files.messages.successOnUpload'];

			this.notificationService.showSuccess(msg);
		});
	}

	generateGridRowActions(config) {
		const self = this, actions = [];

		if (config.canDeleteFiles) {
			const deleteAction = {
				displayName: self.translations['data-management-files.columns.deleteFile'],
				iconCssClass: 'es-icon-function-delete',
				action: (row) => {
					this.popupService.show('confirmation', {
						headerTitle: self.translations['data-management-files.columns.deleteFile'],
						contentMsg: self.translations['data-management-files.messages.confirmDelete']
					}).then((resp) => {
						if (resp.answer === 'ok') {
							this.deleteFile(row.fileId);
						}
					}, (error) => {
						this.$log.error(`Error: ${error}`);
					});
				}
			};

			actions.push(deleteAction);
		}

		const downloadFileAction = {
			displayName: self.translations['data-management-files.columns.downloadFile'],
			iconCssClass: 'es-icon-download',
			action: (row) => {
				const url = this.remoteFileService.getDataManagementDownloadFileUrl(row.fileId);

				this.$window.location.assign(url);
			}
		};

		actions.push(downloadFileAction);

		return actions;
	}

	generateGridConfig() {
		const self = this,
			gridRowActions = this.generateGridRowActions(this.viewConfig);

		this.options = {
			columnDefs: [{
				translationKey: 'my-downloads.columns.name',
				fieldName: 'fileName',
				isSortable: true,
				type: 'string'
			}, {
				translationKey: 'data-management-files.columns.uploadDate',
				fieldName: 'created',
				isSortable: true,
				type: 'date',
				formatter: 'dateTimeFormat'
			}, {
				translationKey: 'data-management-files.columns.fileSize',
				fieldName: 'fileSize',
				isSortable: true,
				type: 'date',
				removeFromFilter: true,
				formatter: 'bytes'
			}],
			sortOrders: [
				{
					fieldName: 'fileName',
					reverseOrder: true
				}
			],
			rowDefs: {
				identifier: 'fileName'
			},
			gridRowActions,
			getData: (oDataQuery) => {
				return self.remoteFileService.getDataManagementFiles(oDataQuery);
			}
		};
	}

	deleteFile(fileId) {
		this.loadingIndicatorService.show();
		return this.remoteFileService.deleteDataManagementFile(fileId).then((/*resp*/) => {
			this.showSuccessNotification();
			this.reloadGrid();
		}, (error) => {
			this.$log.error(`Error: ${error}`);
			this.showErrorNotification();
		}).finally(() => {
			this.loadingIndicatorService.hide();
		});
	}

	reloadGrid() {
		this.reloadGridData = true;
	}

	showSuccessNotification() {
		this.notificationService.showSuccess(this.translations['data-management-files.messages.successOnDelete']);
	}

	showErrorNotification() {
		this.notificationService.showError(this.translations['data-management-files.messages.errorOnDelete']);
	}
}

export default DataManagementFilesController;
