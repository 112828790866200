/*
 * rowActionController
 * */

class RowActionController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
	}

	$onInit() {
		//this.$log = this.$log.getInstance('RowActionController');
		//this.$log.debug('loaded');
	}

	onActionClick(evt) {
		evt.stopPropagation();
		this.action();
	}
}

export default RowActionController;
