/*
 * labelComponent
 * */

import templateUrl from './label.html';
import controller from './label.controller';
import './label.scss';

const labelComponent = {
	bindings: {
		text: '@',
		forId: '@'
	},
	templateUrl,
	controller
};

export default labelComponent;
