import tplUrl from './signature.html';

export default () => {
	return {
		restrict: 'A',
		scope: {
			sigType: '@',
			popup: '=',
			isValid: '=',
			state: '=',
			additionalInfo: '='
		},
		templateUrl: tplUrl,
		controllerAs: 'vm',
		bindToController: true,
		controller
	};

	function controller($log, $location, $window, $stateParams, ssoService, appConfig, signatureService) {
		'ngInject';
		$log.getInstance('GssoSignature');
		$log.debug('instantiated');
		const vm = this;

		// if there is no sign key, the signature cannot be valid
		if ($stateParams.signKey) {
			// check if a state is stored, if not continue with current state
			const tempState = signatureService.getState($stateParams.signKey);

			if (tempState) {
				vm.state = tempState;
				signatureService.removeState($stateParams.signKey);
				vm.isValid = !!$stateParams.tokenId;
			} else {
				$location.search('signKey', null);
				$location.search('tokenId', null);
				vm.isValid = false;
			}
		} else {
			$location.search('tokenId', null);
			vm.isValid = false;
		}

		vm.addSignature = () => {
			if (appConfig.developmentSettings &&
				appConfig.developmentSettings.disableSignature) {
				$stateParams.signKey = 'disabled';
				$stateParams.tokenId = 'disabled';
				vm.isValid = true;
			} else {
				vm.isLoading = true;
				const signKey = new Date().getTime();

				$location.search('signKey', signKey);
				$location.search('tokenId', null);

				const url = $location.absUrl();

				signatureService.saveState(vm.sigType, signKey, vm.state);
				ssoService.getSignatureRedirect(url, signKey, vm.additionalInfo)
					.then((res) => {
						$window.location.href = res.data;
					}, () => {
						vm.isLoading = false;
						vm.isValid = false;
					});
			}
		};

		vm.initialized = true;
	}
};
