(function () {

	'use strict';

	angular
		.module('portalApp')
		.controller('LogisticsCtrl', LogisticsController);

	/**
	 * @ngInject
	 */

	function LogisticsController(logisticsService, $log, $scope, appI18n, appConfig,
								 $stateParams, loadingIndicatorService,
								 signatureService, configurationService, globalMessageService) {

		$log = $log.getInstance("LogisticsCtrl", "color:blue");
		$log.debug("loaded");

		var vm = this;
		let newOrderButton = {};

		vm.title = '';

 var ebsObservable = logisticsService.getEbsAvailabilityObservable();
		var ebsSubscription = ebsObservable.subscribe(isEbsAvailable => {
			newOrderButton.disabled = !isEbsAvailable;

			if (!isEbsAvailable && !globalMessageService.isActive()) {
				globalMessageService.show('header.ebsUnavailableShort', 'header.ebsUnavailable');
				return;
			}

			if (isEbsAvailable) {
				globalMessageService.hide();
			}
		});

		vm.getTitle = function () {
			return vm.title;
		};

		vm.setTitle = function (title) {
			vm.title = title;
		};

		vm.openNewOrder = function () {
			loadingIndicatorService.show();
			logisticsService.showNewOrder();
		};

		function activate() {
			loadSiteMapName();
			logisticsService.registerHandler(vm);

			if ($stateParams.signKey) {
				const state = signatureService.getState($stateParams.signKey);

				if (state) {
					if (state.sigType === appConfig.sigType.createOrder) {
						loadingIndicatorService.show();
						const unbind = $scope.$watch('logisticsService.isNewOrderHandlerRegistered()', () => {
							logisticsService.showNewOrder();
							unbind();
						});
					}
				} else {
					vm.showSignatureNotificationPopup = true;
				}
			}

			appI18n.translate([
                "logistics.return-requests.title",
                "logistics.supply-ordering.title",
                "logistics.shipment-tracking.title"
            ])
				.then(function (translations) {
					vm.supplyTab = translations["logistics.supply-ordering.title"];
					vm.shipmentTab = translations["logistics.shipment-tracking.title"];
					vm.returnTab = translations["logistics.return-requests.title"];

					vm.tabs = [
						{
							displayName: vm.supplyTab,
							trackId: appConfig.trackId.logisticsSupplyOrder,
							ref: appConfig.routes.supplyOrder
						}, {
							displayName: vm.shipmentTab,
							trackId: appConfig.trackId.logisticsShipmentTracking,
							ref: appConfig.routes.shipmentTracking
						}, {
							displayName: vm.returnTab,
							trackId: appConfig.trackId.logisticsReturnRequests,
							ref: appConfig.routes.returnRequests
						}
					];
				});

      logisticsService.getPermission()
				.then(function (response) {
					if (response.data) {
						newOrderButton = {
							displayName: 'logistics.new-order.title',
							iconClass: 'es-icon es-icon-supply-ordering',
							cssClass: '-es-primary',
							action: vm.openNewOrder,
							visibleIn: [appConfig.routes.supplyOrder],
							disabled: true
						};

						vm.actions = [
							newOrderButton
						];
					}
				});

		}
		activate();

		function loadSiteMapName() {
			configurationService.getSiteMapLabel(appConfig.siteMapModuleIds.logistics).then(
				function (label) {
					vm.moduleLabel = label;
				}
			);
		}

		$scope.$on('$destroy', () => {
      ebsSubscription.dispose();
      globalMessageService.hide();
		});
	}
})();
