/* eslint-disable */
 /*

  clone the code from https://github.com/bcabanes/angular-image-cropper version 1.2.0
  remove statement "if (ON_TEST)" from  imageCropperDirective.js
  comment out the following lines from imageCropper.js, the variables don't seem to be used in anywhere, and caused
  the memory leak issue in chrome browser
     self.originalArrayBuffer = this.response;
     self.originalBase64 = 'data:image/jpeg;base64,' + self.base64ArrayBuffer(this.response);

*/

var angular = require('angular');

var ngModule = angular.module('imageCropper', []);

var Cropper = require('./imageCropper');
require('./imageCropperDirective')(angular, Cropper);

module.exports = 'imageCropper';
