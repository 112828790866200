(function () {

	'use strict';

	angular
		.module('portalApp')
		.controller('SqfContactWizardCtrl', SqfContactWizardController);

	/**
	 * @ngInject
	 */
	function SqfContactWizardController($timeout, $scope, $stateParams, $log, sqfService, WizardHandler, dialogService,
										appConfig) {
		var vm = this;

		$log = $log.getInstance("SqfContactWizardController");

		$log.debug(`SqfContactWizardController loaded, sqfId:${$stateParams.sqfId}`);

		vm.name = $scope.shared.localized.contact.contact;
		vm.dataLoading = true;
		vm.saving = false;
		vm.loadingError = '';
		vm.errorMsgs = [];
		vm.sqfId = 0;
		vm.contactId = 0;
		vm.isEditMode = false;
		vm.contacts = [];
		vm.model = {};
		vm.options = {formState: {config: {hasAddedSelfAsContact: false}}};
		vm.forms = {};
		vm.showContactSummary = false;
		vm.hasSiteAddress = false;
		vm.confirmDeleteHandler = {showDialog: false};
		vm.confirmUnsavedHandler = {showDialog: false};
		vm.contactConfirmHandler = null;
		vm.actions = [];
		vm.localized = $scope.shared.localized;
		vm.showWizard = showWizard;
		vm.validateFrom = validateFrom;
		vm.deleteContact = deleteContact;
		vm.editContact = editContact;
		vm.showRoles = showRoles;
		sqfService.registerContactHandler(vm);
		vm.currentStep = 1;
		$scope.$on("wizard:stepChanged", onStepChanged);

		function getFields() {
			return {
				personInfo: [
					{
						wrapper: 'twoColumn',
						fieldGroup: [
							{
								key: 'needPortalAccess',
								type: 'radioInline',
								templateOptions: {
									label: $scope.shared.localized.contact.needPortalAccess,
									required: true,
									"options": []
								},
								controller: /* @ngInject */ function ($scope) {
									if (vm.model.ispi) {
										$scope.to.options = [
											{
												"name": vm.localized.contact.portalAccessGranted,
												"value": "1"
											}];
									}
									else {
										$scope.to.options = [
											{
												"name": vm.localized.options.yes,
												"value": "1"
											},
											{
												"name": vm.localized.options.no,
												"value": "-1"
											}];
									}
								}
							},
							{
								template: '<div class="es-form-field -es-aligned"><div class="es-label">{copyMyContact}</div><div class="-es-form-control ert-sqf-copy-contact-icon"><a class="es-icon es-icon-function-scoring-results" ng-click="copyFullUserContactData()" title="{copyMyContactTooltip}"></a></div></div>'.supplant($scope.shared.localized.contact),
								controller: /* @ngInject */ function ($scope) {
									$scope.copyFullUserContactData = copyFullUserContactData;
								},
								hideExpression: function($viewValue, $modelValue, scope) {
									return scope.model.ispi || vm.options.formState.config.hasAddedSelfAsContact === true;
								}
							},
							{
								key: 'email',
								type: 'emailInline',
								templateOptions: {
									label: $scope.shared.localized.contact.email
								},
								ngModelElAttrs: {
									maxlength: '150'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.emailIsRequired"
								}
							},
							{
								key: 'repeatEmail',
								type: 'emailInline',
								templateOptions: {
									label: $scope.shared.localized.contact.repeatEmail
								},
								ngModelElAttrs: {
									maxlength: '150'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.emailIsRequired"
								},
								validators: {
									fieldMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											return value === vm.model.email;
										},
										message: '"{emailMatch}"'.supplant($scope.shared.localized.messages)
									}
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'titleId',
								type: 'selectInline',
								templateOptions: {
									label: $scope.shared.localized.contact.title,
									options: [],
									onChange: function ($viewValue, $modelValue, scope) {
										var value = $viewValue || $modelValue.value();
										vm.model.title = scope.getLabel(value);
									}
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.titleIsRequired",
									"templateOptions.options": "formState.config.titles"
								}
							},
							{
								key: 'firstName',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: $scope.shared.localized.contact.firstName
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.firstNameIsRequired"
								}
							},
							{
								key: 'lastName',
								type: 'restrictedInputInline',
								templateOptions: {
									type: 'text',
									label: $scope.shared.localized.contact.lastName
								},
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.lastNameIsRequired"
								}
							},
							{
								key: 'country',
								type: 'selectInline',
								wrapper: ['loading', 'validation'],
								templateOptions: {
									label: $scope.shared.localized.contact.country,
									options: [],
									onChange: function ($viewValue, $modelValue, scope) {
										var value = $viewValue || $modelValue.value();
										vm.model.countryName = scope.getLabel(value);
										if (value === 'US' || vm.oldCountry === 'US' ||
												value === 'CA' || vm.oldCountry === 'CA') {
											vm.model.mobile = '';
											vm.model.phone = '';
										}
										vm.oldCountry = value;
									}
								},
								controller: /* @ngInject */ function ($scope) {
									$scope.to.loading = sqfService.getCountries().then(function (response) {
										$scope.to.options = response.data;
										vm.options.formState.config.countries = response.data;
										return response;
									}, function (data) {
										vm.loadingError = data;
									});
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.countryIsRequired"
								}
							}
						]
					},
					{
						wrapper: 'twoColumn',
						fieldGroup: [
							{
								key: 'phone',
								type: 'inputInline',
								templateOptions: {
									type: 'text',
									label: $scope.shared.localized.contact.phone
								},
								hideExpression: "model.country === 'CA' || model.country === 'US'",
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.telephoneIsRequired"
								},
								validators: {
									fieldMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											if (vm.model.country === 'CA' || vm.model.country === 'US' || utility.isNullOrEmpty(value)) {
												return true;
											}
											else {
												var matcher = new RegExp(vm.options.formState.config.phonePatternGeneral);
												return matcher.test(value);
											}
										},
										message: '"{contactNumberField}"'.supplant($scope.shared.localized.messages)
									},
									minLengthMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											if (vm.model.country === 'CA' || vm.model.country === 'US' || utility.isNullOrEmpty(value)) {
												return true;
											}
											return value.length >= 7;
										},
										message: '"{contactNumberFieldMinLength}"'.supplant($scope.shared.localized.messages)
									}
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'phone',
								type: 'maskedInput',
								templateOptions: {
									type: 'text',
									label: $scope.shared.localized.contact.phone,
									mask: '',
									onChange: function ($viewValue, $modelValue, scope) {
										if (vm.model.country === 'CA' || vm.model.country === 'US') {
											vm.model.phone = removeUnderscore($viewValue);
										}
									}
								},
								hideExpression: "model.country !== 'CA' && model.country !== 'US'",
								ngModelElAttrs: {
									maxlength: '30',
									'model-view-value': 'true'
								},
								expressionProperties: {
									'templateOptions.mask': "formState.config.phoneMaskUS",
									'templateOptions.required': "formState.config.requiredFields.telephoneIsRequired"
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'mobile',
								type: 'inputInline',
								templateOptions: {
									type: 'text',
									label: $scope.shared.localized.contact.mobile
								},
								hideExpression: "model.country === 'CA' || model.country === 'US'",
								ngModelElAttrs: {
									maxlength: '30'
								},
								expressionProperties: {
									'templateOptions.required': "formState.config.requiredFields.mobilePhoneIsRequired"
								},
								validators: {
									fieldMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											if (vm.model.country === 'CA' || vm.model.country === 'US' || utility.isNullOrEmpty(value)) {
												return true;
											}
											else {
												var matcher = new RegExp(vm.options.formState.config.phonePatternGeneral);
												return matcher.test(value);
											}
										},
										message: '"{contactNumberField}"'.supplant($scope.shared.localized.messages)
									},
									minLengthMatch: {
										expression: function (viewValue, modelValue) {
											var value = modelValue || viewValue;
											if (vm.model.country === 'CA' || vm.model.country === 'US' || utility.isNullOrEmpty(value)) {
												return true;
											}
											return value.length >= 7;
										},
										message: '"{contactNumberFieldMinLength}"'.supplant($scope.shared.localized.messages)
									}
								},
								extras: {
									validateOnModelChange: true
								}
							},
							{
								key: 'mobile',
								type: 'maskedInput',
								templateOptions: {
									type: 'text',
									label: $scope.shared.localized.contact.mobile,
									mask: '',
									onChange: function ($viewValue, $modelValue, scope) {
										if (vm.model.country === 'CA' || vm.model.country === 'US') {
											vm.model.mobile = removeUnderscore($viewValue);
										}
									}
								},
								hideExpression: "model.country !== 'CA' && model.country !== 'US'",
								ngModelElAttrs: {
									maxlength: '30',
									'model-view-value': 'true'
								},
								expressionProperties: {
									'templateOptions.mask': "formState.config.phoneMaskUS",
									'templateOptions.required': "formState.config.requiredFields.mobilePhoneIsRequired"
								},
								extras: {
									validateOnModelChange: true
								}
							}]
					}
				],
				address: [
					{
						template: '<div class="es-content-box"><div class="es-form-message -es-info"><p><strong>{defaultSiteAddressInContact}</strong></p><p>{siteAddressHint}</p></div></div>'.supplant($scope.shared.localized.messages),
						hideExpression: function($viewValue, $modelValue, scope) {
							return !vm.hasSiteAddress || scope.model.ispi;
						}
					},
					{
						template: '<div class="es-content-box"><div class="es-form-message -es-info"><p>{siteAddressHint}</p></div></div>'.supplant($scope.shared.localized.messages),
						hideExpression: function($viewValue, $modelValue, scope) {
							return vm.hasSiteAddress && !scope.model.ispi;
						}
					},
					{
						template: '<div class="es-form-field -es-aligned"><div class="es-label">{copyMyContact}</div><div class="-es-form-control ert-sqf-copy-contact-icon"><a class="es-icon es-icon-function-scoring-results" ng-click="copyUserAddress()" title="{copyMyContactTooltip}"></a></div></div>'.supplant($scope.shared.localized.contact),
						controller: /* @ngInject */ function ($scope) {
							$scope.copyUserAddress = copyUserAddress;
						},
						hideExpression: function($viewValue, $modelValue, scope) {
							return vm.hasSiteAddress || !scope.model.ispi;
						}
					},
					{
						key: 'address1',
						type: 'restrictedInputInline',
						templateOptions: {
							type: 'text',
							label: $scope.shared.localized.contact.address1
						},
						ngModelElAttrs: {
							maxlength: '30'
						},
						expressionProperties: {
							'templateOptions.required': "formState.config.requiredFields.address1IsRequired"
						}
					},
					{
						key: 'address2',
						type: 'restrictedInputInline',
						templateOptions: {
							type: 'text',
							label: $scope.shared.localized.contact.address2
						},
						ngModelElAttrs: {
							maxlength: '30'
						},
						expressionProperties: {}
					},
					{
						key: 'address3',
						type: 'restrictedInputInline',
						templateOptions: {
							type: 'text',
							label: $scope.shared.localized.contact.address3
						},
						ngModelElAttrs: {
							maxlength: '30'
						},
						expressionProperties: {}
					},
					{
						key: 'city',
						type: 'restrictedInputInline',
						templateOptions: {
							type: 'text',
							label: $scope.shared.localized.contact.city
						},
						ngModelElAttrs: {
							maxlength: '50'
						},
						expressionProperties: {
							'templateOptions.required': "formState.config.requiredFields.cityIsRequired"
						}
					},
					{
						key: 'zipCode',
						type: 'inputInline',
						templateOptions: {
							type: 'text',
							label: $scope.shared.localized.contact.postalCode,
							pattern: '',
							patternValidationMessage: ''
						},
						ngModelElAttrs: {
							maxlength: '10'
						},
						expressionProperties: {
							'templateOptions.required': "formState.config.requiredFields.zipIsRequired",
							'templateOptions.pattern': "model.country === 'US' ? '{usaZipCode}' : '{restrictedInputEscaped}'".supplant(appConfig.constants.patterns),
							'templateOptions.patternValidationMessage': "model.country === 'US' ? '{usaZipCodeField}' : '{restrictedInputPatternFieldEscaped}'".supplant($scope.shared.localized.messages)
						}
					},
					{
						key: 'province',
						type: 'restrictedInputInline',
						templateOptions: {
							type: 'text',
							label: $scope.shared.localized.contact.province
						},
						ngModelElAttrs: {
							maxlength: '50'
						},
						hideExpression: "model.country === 'US'",
						expressionProperties: {
							'templateOptions.required': "formState.config.requiredFields.provinceIsRequired"
						}
					},
					{
						key: 'state',
						type: 'selectInline',
						wrapper: ['loading', 'validation'],
						templateOptions: {
							type: 'text',
							label: $scope.shared.localized.contact.state,
							options: []
						},
						controller: /* @ngInject */ function ($scope) {
							$scope.to.loading = sqfService.getUsStates().then(function (response) {
								$scope.to.options = response.data;
								return response;
							}, function (error) {
								vm.loadingError = error.data.message || error.data;
							});
						},
						hideExpression: "model.country !== 'US'",
						expressionProperties: {
							'templateOptions.required': "formState.config.requiredFields.stateIsRequired"
						}
					}
				]
			};
		}

		function removeUnderscore(value) {
			if (value && value.length) {
				return value.replace(/[\sx_]+$/g, '');
			}
			return value;
		}

		function wizard() {
			return WizardHandler.wizard('2');
		}

		function updateAddedSelfAsContact() {
			vm.options.formState.config.hasAddedSelfAsContact = false;
			if (vm.contacts.length > 0) {
				if (vm.options.formState.config && vm.options.formState.config.user) {
					vm.contacts.forEach(function (item) {
						if (item.email === vm.options.formState.config.user.email) {
							vm.options.formState.config.hasAddedSelfAsContact = true;
						}
					});
				}
			}
		}

		function copyFullUserContactData() {
			vm.model.email = vm.options.formState.config.user.email;
			vm.model.repeatEmail = vm.options.formState.config.user.email;
			vm.model.title = vm.options.formState.config.user.title;
			vm.model.titleId = vm.options.formState.config.user.titleId;
			vm.model.firstName = vm.options.formState.config.user.firstName;
			vm.model.lastName = vm.options.formState.config.user.lastName;

			if (vm.options.formState.config.user.country) {
				vm.model.address1 = vm.options.formState.config.user.address1;
				vm.model.address2 = vm.options.formState.config.user.address2;
				vm.model.address3 = vm.options.formState.config.user.address3;
				vm.model.city = vm.options.formState.config.user.city;
				vm.model.country = vm.options.formState.config.user.country;
				vm.model.zipCode = vm.options.formState.config.user.zipCode;
				vm.model.state = vm.options.formState.config.user.state;
				vm.model.province = vm.options.formState.config.user.province;
				vm.model.phone = vm.options.formState.config.user.phone;
				vm.model.mobile = vm.options.formState.config.user.mobile;
				if (vm.model.country === 'CA' || vm.model.country === 'US') {
					if (vm.model.phone != null && vm.model.phone.startsWith('1')) vm.model.phone = `+${vm.model.phone}`;
					if (vm.model.mobile != null && vm.model.mobile.startsWith('1')) vm.model.mobile = `+${vm.model.mobile}`;
				}
				vm.hasSiteAddress = false;
			}
		}

		function copyUserAddress() {
			if (vm.options.formState.config.user.country) {
				vm.model.address1 = vm.options.formState.config.user.address1;
				vm.model.address2 = vm.options.formState.config.user.address2;
				vm.model.address3 = vm.options.formState.config.user.address3;
				vm.model.city = vm.options.formState.config.user.city;
				vm.model.zipCode = vm.options.formState.config.user.zipCode;
				vm.model.state = vm.options.formState.config.user.state;
				vm.model.province = vm.options.formState.config.user.province;
				vm.hasSiteAddress = false;
			}
		}

		function copySiteAddress() {
			vm.model.address1 = vm.options.formState.config.piContact.address1;
			vm.model.address2 = vm.options.formState.config.piContact.address2;
			vm.model.address3 = vm.options.formState.config.piContact.address3;
			vm.model.countryName = vm.options.formState.config.piContact.countryName;
			vm.model.country = vm.options.formState.config.piContact.country;
			vm.model.city = vm.options.formState.config.piContact.city;
			vm.model.province = vm.options.formState.config.piContact.province;
			vm.model.state = vm.options.formState.config.piContact.state;
			vm.model.zipCode = vm.options.formState.config.piContact.zipCode;
			vm.hasSiteAddress = true;
		}

		function clear() {
			vm.dataLoading = true;
			vm.model = {};
			vm.options = {formState: {config: {hasAddedSelfAsContact: false}}};
			vm.forms = {};
			vm.saving = false;
			vm.showContactSummary = false;
			vm.loadingError = '';
			vm.errorMsgs = [];
			vm.contactId = 0;
			vm.isEditMode = false;
			vm.fields = getFields();
		}

		function clearActions() {
			vm.actions.length = 0;
			$scope.shared.setActions(vm.actions);
		}

		function onStepChanged(event, data) {
			if (data.step.wzTitle === $scope.shared.localized.wizardTitles.contactInfo) {
				setActions(1);
			}
			else if (data.step.wzTitle === $scope.shared.localized.wizardTitles.contactAddress) {
				setActions(2);
			}
		}

		function setActions(stepIndex, reset) {
			if (vm.currentStep !== stepIndex || reset) {
				vm.actions.length = 0;
				if (stepIndex === 1) {
					vm.actions.push({
						displayName: $scope.shared.localized.buttons.next,
						action: goToContactAddress,
						isPrimary: true
					});
					if ($scope.shared.newSqf) {
						vm.actions.push({
							displayName: $scope.shared.localized.buttons.back,
							action: showSiteInfo
						});
					}
					vm.actions.push({displayName: $scope.shared.localized.buttons.cancel, action: cancel});
				}
				else if (stepIndex === 2) {
					vm.actions.push({
						displayName: $scope.shared.newSqf ? $scope.shared.localized.buttons.next : $scope.shared.localized.buttons.save,
						action: saveContact,
						isPrimary: true
					});
					vm.actions.push({
						displayName: $scope.shared.localized.buttons.back,
						action: goToContactInformation
					});
					vm.actions.push({displayName: $scope.shared.localized.buttons.cancel, action: cancel});
				}
				else if (stepIndex === 3) {
					vm.actions.push({
						displayName: $scope.shared.localized.buttons.next,
						action: showContactConfirm,
						isPrimary: true
					});
					vm.actions.push({displayName: $scope.shared.localized.buttons.addContact, action: addContact});
					if ($scope.shared.newSqf === false) {
						vm.actions.push({displayName: $scope.shared.localized.buttons.cancel, action: cancel});
					}
				}
				$scope.shared.setActions(vm.actions);
				vm.currentStep = stepIndex;
				$scope.shared.handleHide = function () {
					confirmClose();
				};
			}
			updateActions();
		}

		function updateActions() {
			if (vm.actions.length > 0) {
				vm.actions[0].isDisabled = vm.dataLoading || vm.saving;
				if (vm.actions.length > 1) {
					vm.actions[1].isDisabled = vm.dataLoading || vm.saving;
				}
				vm.actions[vm.actions.length - 1].isDisabled = vm.saving;
			}
		}

		function goToContactAddress() {
			if (!vm.forms.personInfo.$valid) {
				vm.forms.personInfo.$setSubmitted();
			}
			else {
				wizard().next();
				setActions(2);
			}
		}

		function goToContactInformation() {
			wizard().previous();
			setActions(1);
		}

		function confirmClose() {
			if (vm.contactId < 0 || vm.forms.personInfo.$dirty !== true) {
				$scope.shared.closeWizard();
			}
			else {
				vm.confirmUnsavedHandler = dialogService.createConfirmHandler();
				vm.confirmUnsavedHandler.show().then(function (response) {
					if (response === true) {
						vm.confirmUnsavedHandler.hide();
						$scope.shared.closeWizard();
					}
				});
			}
		}

		function closeWizard() {
			$scope.shared && $scope.shared.closeWizard && $scope.shared.closeWizard();
		}

		function cancel() {
			if (vm.contactId >= 0) {
				var isDirty = (vm.forms.personInfo && vm.forms.personInfo.$dirty) || (vm.forms.address && vm.forms.address.$dirty);
				if (isDirty) {
					vm.confirmUnsavedHandler = dialogService.createConfirmHandler();
					vm.confirmUnsavedHandler.show().then(function (response) {
						vm.confirmUnsavedHandler.hide();
						if (response === true) {
							closeWizard();
						}
					});
				}
				else {
					closeWizard();
				}
			}
			else {
				closeWizard();
			}
		}

		function goToNextStep(contact) {
			if ($scope.shared.newSqf === true) {
				showContactSummary(contact);
			}
			else {
				showSummary();
			}
		}

		function resetToAddMoreContact(contact, finished) {
			vm.loadingError = '';
			vm.dataLoading = true;
			vm.contactId = 0;
			if (finished) {
				vm.saving = false;
				vm.errorMsgs = [];
				vm.contactId = contact ? contact.contactId : 0;
				vm.model = contact || {
					contactId: 0,
					sqfId: vm.sqfId,
					isIndividual: false,
					needPortalAccess: 1,
					ispi: vm.contacts.every(function (item) {
						return item.ispi === false;
					})
				};
				vm.isEditMode = vm.contactId > 0;
				if (vm.isEditMode) {
					vm.hasSiteAddress = false;
				} else {
					copySiteAddress();
				}
				vm.forms = {};
				vm.fields = getFields();
				vm.dataLoading = false;
				setActions(1);
			}
			else {
				$timeout(function () {
					resetToAddMoreContact(contact, true);
				}, 500);
			}
		}

		function deleteContact(contact) {
			if (!contact.ispi && vm.options.formState.config.allowEdit) {
				vm.confirmDeleteHandler = dialogService.createConfirmHandler();
				vm.confirmDeleteHandler.show().then(function (result) {
					if (result === true) {
						sqfService.deleteContact(vm.sqfId, contact.contactId)
							.then(function () {
								vm.contacts.forEach(function (result, index) {
									if (result.contactId === contact.contactId) {
										vm.contacts.splice(index, 1);
									}
								});
								updateAddedSelfAsContact();
								vm.confirmDeleteHandler.hide();
							}, function (error) {
								vm.confirmDeleteHandler.showErrorMessages([error.data.message || error.data]);
							});
					}
				});
			}
		}

		function showContactConfirm() {
			if ($scope.shared.hideContactConfirmDialog) {
				showRoles();
				return;
			}

			if (vm.contacts && vm.contacts.length > 1) {
				showRoles();
				return;
			}

			vm.contactConfirmHandler = dialogService.createConfirmHandler();
			vm.contactConfirmHandler.show().then((isConfirm) => {
				$scope.shared.hideContactConfirmDialog = true;
				vm.contactConfirmHandler.hide();
				if (isConfirm) {
					showRoles();
				}
			});
		}


		function editContact(contact) {
			resetToAddMoreContact(angular.copy(contact));
		}

		function addContact() {
			resetToAddMoreContact();
		}

		function showSummary() {
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.summary);
			sqfService.showSummaryView(vm.sqfId);
		}

		function showRoles() {
			WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.roles);
			sqfService.showRolesView(vm.sqfId);
		}

		function showSiteInfo() {

			if (vm.contactId >= 0) {
				var isDirty = (vm.forms.personInfo && vm.forms.personInfo.$dirty) || (vm.forms.address && vm.forms.address.$dirty);
				if (isDirty) {
					vm.confirmUnsavedHandler = dialogService.createConfirmHandler();
					vm.confirmUnsavedHandler.show().then(function (response) {
						if (response === true) {
							vm.confirmUnsavedHandler.hide();
							WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.siteInfo);
							sqfService.showSitePIWizard(vm.sqfId);
						}
					});
				}
				else {
					WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.siteInfo);
					sqfService.showSitePIWizard(vm.sqfId);
				}
			}
			else {
				WizardHandler.wizard("sqfWizard").goTo($scope.shared.localized.wizardTitles.siteInfo);
				sqfService.showSitePIWizard(vm.sqfId);
			}
		}

		function validateFrom(from) {
			if (from.$valid) {
				stepSuccessful();
			}
		}

		function stepSuccessful() {
			vm.errorMsgs = [];
			wizard().next();
		}

		function saveContact() {
			var errorStep = '';
			if (!vm.forms.personInfo.$valid) {
				errorStep = $scope.shared.localized.wizardTitles.contactInfo;
				vm.forms.personInfo.$setSubmitted();
			}
			else if (!vm.forms.address.$valid) {
				errorStep = $scope.shared.localized.wizardTitles.contactAddress;
				vm.forms.address.$setSubmitted();
			}
			if (errorStep.length > 0) {
				wizard().goTo(errorStep);
				return false;
			}
			stepSuccessful();
			vm.saving = true;
			updateActions();
			sqfService.saveContact(vm.sqfId, vm.contactId, vm.model)
				.then(function (response) {
					if (response.data.isSuccessful) {
						if (vm.contactId <= 0 && response.data.result > 0) {
							vm.model.contactId = response.data.result;
						}
						goToNextStep(vm.model);
						vm.saving = false;
					} else {
						vm.saving = false;
						vm.errorMsgs = response.data.messages;
						updateActions();
					}
				}, function (error) {
					vm.errorMsgs = [error.data.message || error.data];
					vm.saving = false;
					updateActions();
				});
		}

		function showContactSummary(contact) {
			clearActions();
			if (contact) {
				vm.contacts.some(function (item, index) {
					if (item.contactId === contact.contactId) {
						vm.contacts.splice(index, 1);
						return true;
					}
					else {
						return false;
					}
				});
				if (contact.ispi) {
					vm.contacts.splice(0, 0, contact);
					vm.options.formState.config.piContact = contact;
				} else {
					vm.contacts.push(contact);
				}
			}
			updateAddedSelfAsContact();
			//show contact summary
			goToContactSummary();
		}

		function goToContactSummary(stopCounter) {
			vm.showContactSummary = false;
			vm.contactId = -1;
			//let existing wizard with name '2' destroyed before adding new wizard with name '2',
			//so that correct one get destroyed
			if (!stopCounter) {
				$timeout(function () {
					vm.showContactSummary = true;
				}, 300);
			}
			$timeout(function () {
				stopCounter = (stopCounter + 1) || 1;
				var contactWizard = wizard();

				if (contactWizard) {
					setActions(3);
					contactWizard.goTo($scope.shared.localized.wizardTitles.contactSummary);
				} else if (stopCounter <= 5) {
					goToContactSummary(stopCounter);
				}
			}, 300);
		}

		function showWizard(sqfId, contactId, contacts) {
			vm.sqfId = sqfId;
			vm.contactId = contactId;
			if (contacts) {
				vm.contacts = contacts;
			} else {
				sqfService.getContacts(vm.sqfId)
					.then(function success(response) {
						vm.contacts = response.data;
					}, function error(er) {
						vm.loadingError = er.data.message || er.data;
					});
			}
			loadContact(sqfId, contactId);
		}

		function loadContact(sqfId, contactId) {
			clear();
			vm.sqfId = sqfId;
			vm.contactId = contactId;
			setActions(contactId === -1 ? 3 : 1, true);
			sqfService.getContact(vm.sqfId, vm.contactId)
				.then(function (response) {
					vm.model = response.data.model;
					if (vm.contactId !== -1) {
						vm.contactId = vm.model.contactId;
						vm.isEditMode = vm.contactId > 0 && !utility.isNullOrEmpty(vm.model.email);
					}
					vm.options.formState.config = response.data.config;
					vm.dataLoading = false;
					if (vm.model.contactId === 0 && !vm.model.ispi) {
						copySiteAddress();
					}
					if (vm.contactId === -1) {
						goToContactSummary();
					}
					updateActions();
				}, function (error) {
					vm.loadingError = error.data.message || error.data;
				});
		}

		function disableFields(fields) {
			angular.forEach(fields, function (field) {
				if (field.key) {
					field.expressionProperties = field.expressionProperties || {};
					field.expressionProperties['templateOptions.disabled'] = "'true'";
				}
				else if (field.fieldGroup) {
					disableFields(field.fieldGroup);
				}
			});
		}
	}
})();
