(function() {

	'use strict';

	angular
		.module("portalApp")
		.controller("AnnouncementCtrl", AnnouncementController);

	/**
	 * @ngInject
	 */
	function AnnouncementController($scope, $log, announcementService, appConfig,
									appI18n, dialogService, documentService,
									configurationService, loadingIndicatorService) {
		$log = $log.getInstance("AnnouncementController");
		$log.debug("loaded");

		var vm = this;

		vm.announcementOptions = {
			rowDefs: {},
			gridRowActions: [],
			gridActions: [],

			sortOrders: [{
				fieldName: 'priority',
				reverseOrder: true
			}, {
				fieldName: 'modifiedOn',
				reverseOrder: true
			}, {
				fieldName: 'createdOn',
				reverseOrder: true
			}],

			getData: getAnnouncements,

			defaultRowAction: function(row) {
				vm.showData(row.announcementId, row.contentType);
			}

		};

		function getAnnouncements(options) {
			return announcementService.getAllAnnouncements(options);
		}

		vm.showData = function(announcementId, fileType) {
			vm.isLoading = true;
			announcementService.getAnnouncementFile(announcementId)
				.then(function(response) {
					$log.debug(" announcement file content data loaded.");
					documentService.openDownloadReport(response.data, appConfig.downloadDocumentType.announcement, $scope);
					vm.isLoading = false;
				}, function(response) {
					$log.debug("error in loading announcement file content data: " + response.data);
					vm.isLoading = false;
				});
		};

		vm.deleteAnnouncement = function(row) {
			vm.confirmHandler = dialogService.createConfirmHandler();
			vm.confirmHandler.show()
				.then(function(result) {
					if (result === true) {
						vm.isLoading = true;
						vm.errorMessage = "";
						announcementService.deleteAnnouncement(row.announcementId)
							.then(function() {
								$log.debug(" announcement deleted successfully.");
								vm.isLoading = false;
								vm.confirmHandler.hide();
								announcementService.refreshGrid();
							}, function(error) {
								$log.debug("error in deleting announcement: " + error);
								vm.isLoading = false;
								vm.confirmHandler.showErrorMessages(vm.deleteErrorMessage);
							});
					}
				});
		};

		vm.openNewAnnouncement = function() {
			announcementService.showNewAnnouncement(0);
		};

		function refreshGrid() {
			vm.reload = true;
		}

		function activate() {
			loadingIndicatorService.show();
			loadSiteMapName();
			appI18n.translate([
				"announcements.title",
				"announcements.new.title",
				"announcements.edit.name",
				"announcements.delete.name",
				"announcements.preview",
				"announcements.priority",
				"announcements.grid-title",
				"announcements.active",
				"announcements.global",
				"announcements.created",
				"announcements.created-by",
				"announcements.modified",
				"announcements.modified-by",
				"announcements.size",
				"announcements.content-type",
				"announcements.study#",
				"announcements.delete.error-message",
				"announcements.delete.message"

			])
				.then(function(translations) {
					vm.title = translations["announcements.title"];
					vm.newTitle = translations["announcements.new.title"];
					vm.editTitle = translations["announcements.edit.name"];
					vm.deleteTitle = translations["announcements.delete.name"];
					vm.previewTitle = translations["announcements.preview"];
					vm.priorityTitle = translations["announcements.priority"];
					vm.gridTitle = translations["announcements.grid-title"];
					vm.activeTitle = translations["announcements.active"];
					vm.globalTitle = translations["announcements.global"];
					vm.createdTitle = translations["announcements.created"];
					vm.createdByTitle = translations["announcements.created-by"];
					vm.modifiedTitle = translations["announcements.modified"];
					vm.modifiedByTitle = translations["announcements.modified-by"];
					vm.sizeTitle = translations["announcements.size"];
					vm.contentTypeTitle = translations["announcements.content-type"];
					vm.studyTitle = translations["announcements.study#"];

					vm.permissionError = translations["announcements.permission-error"];
					vm.deleteErrorMessage = translations["announcements.delete.error-message"];
					vm.deleteMessage = translations["announcements.delete.message"];

					vm.announcementOptions.columnDefs = [{
						displayName: vm.priorityTitle,
						fieldName: 'priority',
						isSortable: true,
						type: 'number'
					}, {
						displayName: vm.gridTitle,
						fieldName: 'title',
						isSortable: true,
						type: 'string',
						isDefaultFilter: true,
						isPrimary: true
					}, {
						displayName: vm.activeTitle,
						fieldName: 'isActive',
						isSortable: true,
						type: 'enum',
						formatter: 'yesNo',
						enumType: 'bool',
						enums: [{
							displayName: 'Yes',
							values: [true]
						}, {
							displayName: 'No',
							values: [false]
						}]
					}, {
						displayName: vm.globalTitle,
						fieldName: 'isGlobal',
						isSortable: true,
						type: 'enum',
						formatter: 'yesNo',
						enumType: 'bool',
						enums: [{
							displayName: 'Yes',
							values: [true]
						}, {
							displayName: 'No',
							values: [false]
						}]
					}, {
						displayName: vm.studyTitle,
						fieldName: 'displayStudyName',
						isSortable: true,
						type: 'string',
						removeFromFilter: true
					}, {
						displayName: vm.createdTitle,
						fieldName: 'createdOn',
						isSortable: true,
						type: 'date',
						formatter: 'dateTimeFormat'
					}, {
						displayName: vm.createdByTitle,
						fieldName: 'displayCreatedBy',
						isSortable: true,
						type: 'string'
					}, {
						displayName: vm.modifiedTitle,
						fieldName: 'modifiedOn',
						isSortable: true,
						type: 'date',
						formatter: 'dateTimeFormat'
					}, {
						displayName: vm.modifiedByTitle,
						fieldName: 'displayModifiedBy',
						isSortable: true,
						type: 'string'
					}, {
						displayName: vm.sizeTitle,
						fieldName: 'contentSizeDisplay',
						removeSortControls: true,
						type: 'string',
						removeFromfilter: true
					}, {
						displayName: vm.contentTypeTitle,
						fieldName: 'contentType',
						isSortable: true,
						type: 'string'
					}];
				});

			announcementService.getPermission()
				.then(function(response) {
					if (response.data) {
						vm.actions = [{
							displayName: vm.newTitle,
							iconClass: 'es-icon-bell',
							cssClass: '-es-primary',
							action: vm.openNewAnnouncement
						}];

						vm.announcementOptions.gridRowActions = [
							{
								displayName: vm.editTitle,
								iconCssClass: 'es-icon-function-edit',
								enabledIfFieldName: 'hasEditPermission',
								action: function(row) {
									announcementService.showNewAnnouncement(row);
								}
							},
							{
								displayName: vm.deleteTitle,

								enabledIfFieldName: 'hasDeletePermission',
								iconCssClass: 'es-icon-function-delete',
								action: function(row) {
									vm.deleteAnnouncement(row);

								}
							}
						];

						vm.announcementOptions.gridActions = [];
						vm.deleteActions = [{
							action: vm.delete,
							displayName: vm.deleteTitle
						}];
					}
					vm.load = true;
					announcementService.registerRefresh(refreshGrid);
				}, function(response) {
					$log.error("Get permission failed with error: " + response.data);
					vm.errorMessage = vm.permissionError;
				}).finally(() => {
					loadingIndicatorService.hide();
				});


		}

		activate();

		function loadSiteMapName() {
			configurationService.getSiteMapLabel(appConfig.siteMapModuleIds.announcements).then(
				function(label) {
					vm.moduleLabel = label;
				}
			);
		}

	}

})();
