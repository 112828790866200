/*
 * baseTimePickerController
 * */

class BaseTimePickerController {
	constructor($log, $element, $timeout) {
		'ngInject';
		this.$log = $log;
		this.$element = $element;
		this.$timeout = $timeout;
	}

	$onInit() {
		this.$log = this.$log.getInstance('BaseTimePickerController');
		this.$log.debug('loaded');
	}

	$postLink() {
		this.timeInput = this.$element.find('.es-date-input');
		this.timeInput.append('<span class="es-icon es-icon-content-time"></span>');
		this.timeIcon = this.$element.find('.es-icon-content-time');
		this.timeIcon.on('click', () => {
			this.$timeout(this.onIconClicked, 0);
		});
	}

	$onDestroy() {
		this.timeIcon.off('click');
	}

}

export default BaseTimePickerController;
