(function () {
	'use strict';

	angular.module('portalApp').controller('cdeAuditReportCtrl', AuditReportController);

	/*
	 * @ngInject
	 */
	function AuditReportController($log, $scope, $state, appI18n,
		clinicalDataEntryService, clinicalDataEntryApiService,
		loadingIndicatorService, appConfig,
		appSession, notificationService, clinicalDataService, configurationService) {

		$log = $log.getInstance('newPftEntryCtrl');
		$log.debug('newPftEntryCtrl:: loaded');

		const vm = this;

		vm.active = false;
		vm.cancelAction = cancelAction;
		vm.onInitialLoaded = onInitialLoaded;

		vm.submit = submit;
		vm.resetState = resetState;
		vm.onStudyChange = onStudyChange;
		vm.onSiteChange = onSiteChange;
		vm.onStudyOrSiteSelection = onStudyOrSiteSelection;

		vm.showAuditReport = showAuditReport;
		vm.actions = [{
			action: vm.submit,
			displayName: 'Submit',
			isPrimary: true
		}, {
			action: vm.cancelAction,
			displayName: 'Cancel',
			cssClass: 'btn-danger md-close',
			iconName: 'close'
		}];

		register();

		function register() {
			clinicalDataEntryService.registerShowAuditReport(vm.showAuditReport);
			$scope.$on('$destroy', () => {
				clinicalDataEntryService.unregisterShowAuditReport(vm.showAuditReport);
			});
		}

		function showAuditReport() {
			loadingIndicatorService.show();
			var cdemode = 'Ecg'; //default value
			configurationService.getUserStudySettings().then((response) => {
				if(response && response.data) {
					var data = response.data;
					cdemode = (data.studyConfig.hasPaperPft) ? 'Pft': 'Ecg';
					initialLoad(cdemode);
				} else {
					initialLoad(cdemode); //if above call fails or no data, load with default value
				}
			});
			
		}

		function initialLoad(cdemode) {
			resetModel();
			loadStudies(cdemode).then(() => {
				onInitialLoaded();
			});
		}

		function loadStudies(cdemode) {
			const currentStudy = appSession.getStudy();

			return clinicalDataEntryApiService.getStudiesForUser(currentStudy.id, currentStudy.studyModeId, cdemode)
				.then((response) => {
					vm.studies = response.data;
				});
		}

		function resetModel() {
			vm.model = {
				allSites: 1
			};
			vm.validation = {
				required: {}
			};
			vm.showValidation = false;
		}

		function onInitialLoaded() {
			$log.debug('onInitialLoaded in newPftEntryCtrl');
			loadingIndicatorService.hide();
			vm.isInitialLoading = false;
			vm.active = true;
		}

		function closePopup() {
			vm.active = false;
			gotoListView();
		}

		function cancelAction() {
			closePopup();
		}

		function onSiteChange() {
			if (vm.showValidation) {
				validateSite();
			}
		}

		function onStudyChange() {
			if (vm.showValidation) {
				validate();
			}

			vm.model.site = null;
			if (vm.model.study && vm.model.study.protocolId) {
				loadSites();
			} else {
				vm.sites = null;
				vm.currentSitesStudyId = null;
			}
		}

		function onStudyOrSiteSelection() {
			if (vm.showValidation) {
				validate();
			}
			if (vm.model.study && vm.model.study.protocolId) {
				loadSites();
			}
		}

		function loadSites() {
			const selectedStudyId = vm.model.study.protocolId;

			// only load if not already loaded and single site option is selected
			if (!selectedStudyId || vm.currentSitesStudyId === selectedStudyId || vm.model.allSites !== 0) {
				return;
			}

			vm.isLoading = true;
			clinicalDataService.getSitesMinimal({
				id: selectedStudyId,
				studyModeId: appSession.getStudy().studyModeId
			}).then((response) => {
				vm.sites = response.data;
				vm.currentSitesStudyId = selectedStudyId;
				if (vm.sites.length === 0) {
					vm.sites.push({
						siteId: 0,
						displaySiteName: 'No Sites'
					});
				}
			}).finally(() => {
				vm.isLoading = false;
			});
		}

		function doSubmit() {
			vm.isLoading = true;
			const currentStudy = appSession.getStudy();

			clinicalDataEntryApiService.generateAuditReport(
				vm.model.study.protocolId,
				currentStudy.studyModeId,
				vm.model.allSites === 2,
				vm.model.site ? vm.model.site.siteId : null)
				.then(() => {
					closePopup();
					notificationService.showSuccess(
						supplant(
							appI18n.translateImmediate('cde-pft.auditReport.successMessage'),
							[$state.href(appConfig.routes.downloads)])

					);
				})
				.finally(() => {
					vm.isLoading = false;
				});
		}

		function submit() {
			vm.showValidation = true;
			const isValid = validate();

			if (isValid) {
				doSubmit();
			}
		}

		function validate() {
			const studyValid = validateStudy();
			const siteValid = validateSite();

			return studyValid && siteValid;
		}

		function validateStudy() {
			if (vm.model.study) {
				vm.validation.required.studyId = false;
				return true;
			} else {
				vm.validation.required.studyId = true;
				return false;
			}
		}

		function validateSite() {
			if (vm.model.allSites !== 0 || (vm.model.site && vm.model.site.siteId > 0)) {
				vm.validation.required.siteId = false;
				return true;
			} else {
				vm.validation.required.siteId = true;
				return false;
			}
		}

		function resetState() {
			vm.isLoading = false;
			loadingIndicatorService.hide();
		}

		function gotoListView() {
			clinicalDataEntryService.gotoListView();
		}
	}
})();
