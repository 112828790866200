/*eslint-disable indent*/

class SubjectDemographics {

    $onInit() {
        // options passed to formly
        this.options = {
            formState: {
                subjectDemographics: {
                    hidden: true
                }
            }
        };

        this.demogrForms = [];
        this.demogrFg = null;
        this.demogrModel = null;

    }

    setDemogr(demogr) {
        this.demogrForms = [];
        for (let x = 0; x < demogr.length; x++) {

            this.demogrForms.push({
                type: 'sgInput',
                key: 'demographic',
                wrapper: 'sgControlWrap',
                model: demogr[x],
                templateOptions: {
                    type: 'text',
                    label: demogr[x].fieldLabel,
                    disabled: true,
                    id: `demographic_${demogr[x].fieldId}`
                }
            });
        }
    }

    $onChanges(changesObj) {
        if (changesObj.demographics && !changesObj.demographics.isFirstChange()) {
            this.setDemogr(changesObj.demographics.currentValue);
        }
    }
}

export default SubjectDemographics;
/*eslint-enable indent*/
