class DeviceInfoPopupController {
	constructor(clinicalDataService, appI18n) {
		'ngInject';
		this.appI18n = appI18n;
	}

	$onInit() {
	}
}

export default DeviceInfoPopupController;
