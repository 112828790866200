class AbpmReportController {

	constructor($log, $scope, reportsService, configurationService,
		appConfig, documentService) {
		'ngInject';
		this.$log = $log;
		this.$scope = $scope;
		this.reportsService = reportsService;
		this.configurationService = configurationService;
		this.appConfig = appConfig;
		this.documentService = documentService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('AbpmReportController');
		this.$log.debug('loaded');
		this.reports = {};

		this.options = {

			gridRowActions: [{
				displayName: 'Reports',
				iconCssClass: 'es-icon es-icon-reports',
				enabledIf: (row) => {
					return row.abpmFeedbackFormFile ||
						row.abpmReportPartFile;
				},
				subItemsFactory: (row) => {
					if (row.reports) {
						return row.reports;
					}

					const rowReports = [];

					if (row.abpmFeedbackFormFile) {
						rowReports.push(
							this.getReportSubItemRowAction(
								row, row.abpmFeedbackFormDisplayName,
								row.abpmFeedbackFormFile));
					}
					if (row.abpmReportPartFile) {
						rowReports.push(
							this.getReportSubItemRowAction(
								row, row.abpmReportDisplayName,
								row.abpmReportPartFile));
					}

					row.reports = rowReports;

					return rowReports;
				}
			}],

			sortOrders: [{
				fieldName: 'investigatorName'
			}],

			getData: (oDataQuery) => {
				return this.getAbpmReports(oDataQuery);
			}
		};

		this.configurationService.getSubjectDemographicSettings()
			.then((response) => {
				this.options.columnDefs = [];

				this.options.columnDefs.push({
					displayName: 'Principal Investigator',
					fieldName: 'investigatorName',
					isSortable: true,
					type: 'string',
					isDefaultFilter: true
				});

				this.options.columnDefs.push({
					translationKey: 'app.common.siteNumber',
					fieldName: 'siteName',
					isSortable: true,
					type: 'string'
				});

				this.options.columnDefs.push({
					translationKey: 'app.common.subjectKey',
					fieldName: 'subjectKey',
					isSortable: true,
					type: 'string'
				});

				if (!response.data.isSubjectId1Hidden) {
					if (response.data.fieldLabels[this.appConfig.demographics.subjectId1]) {
						this.options.columnDefs.push({
							displayName: response.data.fieldLabels[this.appConfig.demographics.subjectId1],
							fieldName: 'patientId1',
							isSortable: true,
							type: 'string'
						});
					} else {
						this.options.columnDefs.push({
							translationKey: 'app.common.subjectNumber',
							fieldName: 'patientId1',
							isSortable: true,
							type: 'string'
						});
					}
				}
				this.options.columnDefs.push({
					displayName: 'Visit',
					fieldName: 'fullVisitName',
					isSortable: true,
					type: 'string'
				});

				if (!response.data.isInitialsHidden) {
					let initialsDisplayName = 'Initials';

					if (response.data.fieldLabels[this.appConfig.demographics.initials]) {
						initialsDisplayName = response.data.fieldLabels[this.appConfig.demographics.initials];
					}
					this.options.columnDefs.push({
						displayName: initialsDisplayName,
						fieldName: 'initials',
						isSortable: true,
						type: 'string'
					});
				}
				if (!response.data.isDateOfBirthHidden) {
					let dobDisplayName = 'Date Of Birth';

					if (response.data.fieldLabels[this.appConfig.demographics.dob]) {
						dobDisplayName = response.data.fieldLabels[this.appConfig.demographics.dob];
					}
					this.options.columnDefs.push({
						displayName: dobDisplayName,
						fieldName: 'dateOfBirthDisplay',
						isSortable: true,
						type: 'string'
					});
				}
				this.options.columnDefs.push({
					displayName: 'Collection Date/Time',
					fieldName: 'collectionDateTime',
					isSortable: true,
					type: 'date',
					formatter: 'dateTimeFormat'
				});
				this.options.columnDefs.push({
					displayName: 'Transmittal #',
					fieldName: 'transmittalNumberText',
					isSortable: true,
					type: 'string'
				});
				this.options.columnDefs.push({
					displayName: 'Complete Date/Time',
					fieldName: 'completeDateTime',
					isSortable: true,
					type: 'date',
					formatter: 'dateTimeFormat'
				});
				this.options.columnDefs.push({
					displayName: 'Revised',
					fieldName: 'revisedReport',
					isSortable: true,
					type: 'enum',
					formatter: 'yesNo',
					enumType: 'bool',
					enums: [{
						displayName: 'Yes',
						values: ['1']
					}, {
						displayName: 'No',
						values: ['-1']
					}]
				});
				this.activate();
			});
	}

	getReportSubItemRowAction(row, displayName, filePath) {
		return {
			displayName,
			iconFileType: 'pdf',
			action: () => {
				const fileName = this.documentService.getFileName(
					displayName, row.collectionDateTime, row.siteName,
					row.subjectKey, row.fullVisitName, row.transmittalNumber);

				this.documentService.getAbpmReport(filePath, fileName)
					.then((response) => {
						this.documentService.downloadReport(
							response.data, this.$scope,
							this.appConfig.downloadDocumentType.abpmReport, 1,
							displayName, 'pdf');
					});
			}
		};
	}

	activate() {
		this.load = true;
	}

	getAbpmReports(options) {
		return this.reportsService.getAbpmReports(null, null, options);
	}
}

export default AbpmReportController;
