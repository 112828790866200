import reportReviewComponent from './reportReview/reportReview.component';
import downloadPopupComponent from './reportReview/downloadPopup/downloadPopup.component';
import confirmationPopupComponent from './reportReview/confirmationPopup/confirmationPopup.component';
import reportTypeCheckboxRowDirective from './reportReview/downloadPopup/reportTypeCheckboxRow/row.directive';
import abpmReportComponent from './abpm/abpmReport.component';
import holterReportComponent from './holter/holterReport.component';
import ecgSourceTracingComponent from './ecgSourceTracing/ecgSourceTracing.component';
import dataStatusFormComponent from './dataStatusForm/dataStatusForm.component';

export default angular.module('app.reports', [])
	.component('statusForm', dataStatusFormComponent)
	.component('ecgSourceTracing', ecgSourceTracingComponent)
	.component('abpmReport', abpmReportComponent)
	.component('holterReport', holterReportComponent)
	.component('reportReview', reportReviewComponent)
	.component('reportReviewPopup', downloadPopupComponent)
	.component('reportConfirmationPopup', confirmationPopupComponent)
	.directive('reportTypeCheckboxRow', reportTypeCheckboxRowDirective)
	.name;
