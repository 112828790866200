/*
 * fileUploadPopupModule
 * */

import fileUploadPopupComponent from './fileUploadPopup.component';
import fileUploadPopupService from './fileUploadPopup.service';

const fileUploadPopupModule = angular.module('app.components.myDownloads.dataManagementFiles.fileUploadPopup', [])
	.component('fileUploadPopup', fileUploadPopupComponent)
	.service('fileUploadPopupService', fileUploadPopupService)
	.name;

export default fileUploadPopupModule;
