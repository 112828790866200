(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('CreateReportCtrl', CreateReportController);

	/**
	 * @ngInject
	 */

	function CreateReportController(reportsService, logiService, appSession, $log, appI18n,
									$stateParams, $filter, configurationService, appConfig) {

		$log = $log.getInstance("CreateReportCtrl", "color:blue");
		$log.debug("loaded");

		var vm = this;

		vm.createReportOptions = {
			gridRowActions: [],

			removePaginationControls: true,
			sortOrders: [{
				fieldName: 'reportName',
				reverseOrder: false
			}],
			getData: getReportTemplates

		};

		function getReportTemplates(options) {
			return reportsService.getReportTemplates(options);
		}

		activate();

		function activate() {
			configurationService.getUserStudySettings().then((r) => {
				if(_.some(r.data.sitemaps, { name: appConfig.trackId.reportsOds })) {
					showOdsMessage();
				}
				activateReports();
			});
		}

		function showOdsMessage() {
			vm.showOdsMessage = true;
			appI18n.translate(['reports.create-report.title']).then(function(translations) {
				vm.title = translations['reports.create-report.title'];
				reportsService.setTitle(vm.title);
			});
		}

		function activateReports() {

			appI18n.translate([
				"reports.create-report.title",
				"reports.create-report.name",
				"reports.create-report.description",
				'reports.create-report.default-category-title',
				'reports.create-report.category_header_title'
			])
				.then(function(translations) {
					vm.title = translations["reports.create-report.title"];
					vm.nameTitle = translations["reports.create-report.name"];
					vm.descriptionTitle = translations["reports.create-report.description"];
					vm.defaultCategoryTitle = translations["reports.create-report.default-category-title"];
					vm.categoryHeaderTitle = translations["reports.create-report.category_header_title"];

					reportsService.setTitle(vm.title);

					vm.createReportOptions.columnDefs = [
						{
							displayName: vm.nameTitle,
							fieldName: 'reportName',
							isSortable: true,
							type: 'string',
							cssClass: '-es-width-3',
							isDefaultFilter: true
						},
						{
							displayName: vm.descriptionTitle,
							fieldName: 'shortDescription',
							isSortable: true,
							cssClass: '-es-width-9',
							type: 'string'
						}
					];
					vm.createReportOptions.rowDefs = {
						multiSelection: false,
						identifier: 'categoryId',
						groupHeader: {
							hideGroupDescription: true,
							groupByFieldTitle: vm.categoryHeaderTitle,
							groupByDisplayName: function(row) {
								return row.categoryName || vm.defaultCategoryTitle;
							},
							groupByFieldName: function(row) {
								return row.categoryName;
							},
							numberOfItems: function(row) {
								return row.groupCount;
							},
							expandGroupByNameOrFirst: function() {
								return true;
							}
						}
					};
					vm.createReportOptions.gridRowActions = [{
						displayName: vm.title,
						iconCssClass: 'es-icon-create-trans',
						action: function(row) {
							if (row.isLogi) {
								openLogi(row.reportCommand);
							} else if (row.isOds) {
								openOds(row.reportCommand);
							} else {
								reportsService.showCreateReport(row);
							}
						}
					}];

					showDefaultReport();
				});

		}

		function openOds(name) {
			var studyId = appSession.getStudy().id;
			var studyMode = appSession.getStudy().studyModeId;
			var url = logiService.getOdsReportUrl(studyId, studyMode, name, appSession.getSessionToken());
			window.open(url);
		}

		function openLogi(name) {
			var studyId = appSession.getStudy().id;
			var studyMode = appSession.getStudy().studyModeId;
			var url = logiService.getLogiReportUrl(studyId, studyMode, name, appSession.getSessionToken());
			window.open(url);
		}

		function showDefaultReport() {
			const reportId = $stateParams.report;

			if (!reportId) {
				vm.load = true;
				return;
			}
			$log.debug("get report Id:" + reportId);

			reportsService.getReportTemplate(reportId).then((response) => {
				const reportTemplate = response.data;

				if (reportTemplate) {
					// prefilter grid
					vm.createReportOptions.preSelectedFilters = [{
						fieldName: 'reportName',
						displayName: vm.nameTitle,
						filterConditions: [
							{
								displayValue: reportTemplate.reportName,
								filterValue: reportTemplate.reportName,
								operator: 'eq',
								type: 'string'
							}
						]
					}];

					const report = {
						reportName: reportTemplate.reportName,
						reportId: reportTemplate.reportId,
						shortDescription: reportTemplate.shortDescription
					};

					reportsService.showCreateReport(report);
				}
				vm.load = true;
			});
		}
	}
})();
