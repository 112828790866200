/*
 * visitCalendarLegendComponent
 * */

import templateUrl from './visitCalendarLegend.html';
import controller from './visitCalendarLegend.controller';
import './visitCalendarLegend.scss';

const visitCalendarLegendComponent = {
	bindings: {
		currentCalendar: '<'
	},
	templateUrl,
	controller
};

export default visitCalendarLegendComponent;
