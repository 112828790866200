var BrowserDetect = require('./browserDetect/browserDetect');

function partial(func /*, 0..n args */) {
	var args = Array.prototype.slice.call(arguments).splice(1);
	return function() {
		var allArguments = args.concat(Array.prototype.slice.call(arguments));
		return func.apply(this, allArguments);
	};
}
function toPascalCase(word) {
	if (word && word.length > 0) {
		return word.charAt(0).toUpperCase() + word.slice(1);
	}
	return word;
}

function toCamelCase(word) {
	if (word && word.length > 0) {
		return word.charAt(0).toLowerCase() + word.slice(1);
	}
	return word;
}

(function () {
	"use strict";

	window.utility = {
		groupBy: function (collection, key) {
			var result = {};
			var prop;
			collection.forEach(function (elm) {
				prop = elm[key];
				if (!result[prop]) {
					result[prop] = [];
				}
				result[prop].push(elm);
			});
			return result;
		},
		isNullOrEmpty: function (value) {
			return (value === undefined || value === null || value.toString().length === 0);
		}
	};
})();

function saveReport(data, fileName, fileType, mimeType) {
	var mimetype = mimeType || 'application/octet-stream';
	var name = fileName;
	if (fileType) {
		name += '.' + fileType;
	}

	var isSafari = BrowserDetect.browser === 'Safari';
	if (fileType) {
		switch(fileType.toUpperCase()) {
		case 'ZIP':
			mimetype = 'application/zip';
			break;
		case 'CSV':
			mimetype = 'text/csv;charset=utf-8;';
			break;
		case 'XML':
			mimetype = 'application/xml';
			break;
		case 'PDF':
			mimetype = 'application/pdf';
			break;
		case 'XLS':
			mimetype = 'application/vnd.ms-excel';
			break;
		case 'XLSX':
			mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
			break;
		}
	}
	if (isSafari) {
		name = '';
	}

	var blob = new Blob([data], {
		type: mimetype
	});
	saveAs(blob,name);
}

module.exports = { partial: partial, toPascalCase: toPascalCase, toCamelCase: toCamelCase, utility: window.utility, saveReport: saveReport };
