/*
 * NoDataRowController
 * */

class NoDataRowController {
	constructor(appI18n) {
		'ngInject';
		this.msg = appI18n.translateImmediate('grid.no-data-text');
	}

}

export default NoDataRowController;
