var ls = require('./loggerSendQueue');
var logLevel = ls.logLevel;
var loggerSendQueue = ls.loggerSendQueue;
var DateTime = require('../datetime');
var BrowserDetect = require('../browserDetect/browserDetect');

var logger = function ($log, appConfig, tokenService) {

	'use strict';

	var separator = '::',
//    _$log = null,
		/**
		 * Capture the original $log functions; for use in enhancedLogFn()
		 */
		_$log = (function ($log) {
			return {
				log: $log.log,
				info: $log.info,
				warn: $log.warn,
				debug: $log.debug,
				error: $log.error
			};
		})($log),
		/**
		 * Chrome Dev tools supports color logging
		 * @see https://developers.google.com/chrome-developer-tools/docs/console#styling_console_output_with_css
		 */
		colorify = function (message, colorCSS) {
			var isChrome = ( BrowserDetect.browser == 'Chrome'),
				canColorize = isChrome && (colorCSS !== undefined );

			return canColorize ? ['%c' + message, colorCSS] : [message];
		},
		/**
		 * Partial application to pre-capture a logger function
		 */
		prepareLogFn = function (logFn, logLevelNumber, className, colorCSS) {
			/**
			 * Invoke the specified `logFn` with the supplant functionality...
			 */
			var enhancedLogFn = function () {

				if (typeof console === 'undefined') {
					return;
				}

				var consoleMinimumLevel = logLevel.error;

				if (appConfig.log.consoleLogLevel) {
					// TODO: perhaps add a way to override disabled log for debugging deployed systems?
					switch (appConfig.log.consoleLogLevel) {
						case 'debug':
							consoleMinimumLevel = logLevel.debug;
							break;
						case 'info':
							consoleMinimumLevel = logLevel.info;
							break;
						case 'warn':
							consoleMinimumLevel = logLevel.warn;
							break;
						case 'error':
							consoleMinimumLevel = logLevel.error;
							break;
						case 'off':
							consoleMinimumLevel = logLevel.error + 1;
							break;
					}
				}

				if (angular.isUndefined(className)) {
					className = '';
				}

				var args = Array.prototype.slice.call(arguments),
					now = DateTime.formattedDateTimeNow(),
					indicatedError = false;

				if (consoleMinimumLevel <= logLevelNumber) {
					// check  reference error in code
					if (args[0] instanceof ReferenceError) {
						indicatedError = true;
						console.error('ReferenceError indicated by logger: ', args[0].message);
					}

					if (args[0] instanceof TypeError) {
						indicatedError = true;
						console.error('TypeError indicated by logger: ', args[0].message);
					}

					if (args[0] instanceof SyntaxError) {
						indicatedError = true;
						console.error('SyntaxError indicated by logger: ', args[0].message, args[0]);
					}

					if (args[0] instanceof URIError) {
						indicatedError = true;
						console.error('URIError indicated by logger: ', args[0].message, args[0]);
					}

					if (args[0] instanceof RangeError) {
						indicatedError = true;
						console.error('RangeError indicated by logger: ', args[0].message, args[0]);
					}

					if (args[0] instanceof EvalError) {
						indicatedError = true;
						console.error('EvalError indicated by logger: ', args[0].message, args[0]);
					}

					if (args[0] instanceof Error) {
						indicatedError = true;
						console.error('Error indicated by logger: ', args[0].message, args[0].stack);
					}
				}

				if (args[0] === undefined) {
					args[0] = '[UNDEFINED]';
				}
				var stack = args[0].stack;

				// prepend a optional classname to the original output message
				args[0] = supplant('{0}{1}', [className, args[0] + '']);

				args = colorify(supplant.apply(null, args), colorCSS);

				if (stack) {
					loggerSendQueue.getInstance(appConfig, tokenService).push(args, now, logLevel.error, stack);
				} else {
					loggerSendQueue.getInstance(appConfig, tokenService).push(args, now, logLevelNumber);
				}

				if (indicatedError === false && consoleMinimumLevel <= logLevelNumber) {
					logFn.apply(null, args);
				}
			};

			return enhancedLogFn;
		},
		/**
		 * Support to generate class-specific logger instance with classname only
		 *
		 * @param className Name of object in which $log.<function> calls is invoked.
		 * @param colorCSS Object with CSS style color information for Chrome Dev Tools console log colorizing
		 *
		 * @returns {*} Logger instance
		 */
		getInstance = function (className, colorCSS) {
			className = ( className !== undefined ) ? className + separator : '';

			return {
				log: prepareLogFn(_$log.log, logLevel.info, className, colorCSS),
				info: prepareLogFn(_$log.info, logLevel.info, className, colorCSS),
				warn: prepareLogFn(_$log.warn, logLevel.warn, className, colorCSS),
				debug: prepareLogFn(_$log.debug, logLevel.debug, className, colorCSS),
				error: prepareLogFn(_$log.error, logLevel.error, className)  // NO styling of ERROR messages
			};
		};

	$log.log = prepareLogFn($log.log, logLevel.info);
	$log.info = prepareLogFn($log.info, logLevel.info);
	$log.warn = prepareLogFn($log.warn, logLevel.warn);
	$log.debug = prepareLogFn($log.debug, logLevel.debug);
	$log.error = prepareLogFn($log.error, logLevel.error);

	// Add special method to AngularJS $log
	$log.getInstance = getInstance;

	return {
		getInstance: getInstance,
		prepareLogFn: prepareLogFn
	}
};

module.exports = logger;
