import appCommon from '../common/common';
import analyticsComponent from './analytics/analytics';
import signatureComponent from './signature/signature';
import selectInputComponent from './selectInput/selectInput';
import deviceManagementComponent from './deviceManagement/deviceManagement';
import StudyVocabularyInterpolation from './studyVocabulary/studyvocabularyinterpolation.service';
import deviceManagementUnassignDeviceModule from './deviceManagement/unassignDevice/unassignDevice';
import subjectDemographicsModule from './clinicalDataEntry/common/subjectDemographics/subjectDemographics';
import reportsModule from './reports/reports';
import myDownloadsModule from './myDownloads/myDownloads';
import dashboardModule from './dashboard/index';
import clinicalDataModule from './clinicalData/index';
import dataManagementModule from './dataManagement/index';
import adminModule from './admin/index';
import logisticsModule from './logistics/index';
import editRolesPopupModule from './studyPermissions/editRolesPopup';
import deleteUserPopupModule from './studyPermissions/deleteUserPopup';
import userRolesModule from './studyPermissions/userRoles';
import dataManagementFilesModule from './myDownloads/dataManagementFiles';
import iClinicaFrameModule from './iClinica/index';
import downloadsModule from './myDownloads/downloads';
import dispenseISpiroDevicePopupModule from './clinicalData/dispenseISpiroDevicePopup';
import editISpiroDeviceValuesPopupModule from './clinicalData/editISpiroDeviceValuesPopup';
import iSpiroAuditReportPopupModule from './clinicalData/iSpiroAuditReportPopup';
import dispenseKardiaPopupModule from './clinicalData/dispenseKardiaPopup';
import scheduleISpiroEventPopupModule from './clinicalData/scheduleISpiroEventPopup';
import queryGuidelineComponent from './queryGuideline/queryGuideline';


export default angular.module('app.components', [
	appCommon,
	adminModule,
	analyticsComponent,
	selectInputComponent,
	deviceManagementComponent,
	signatureComponent,
	deviceManagementUnassignDeviceModule,
	subjectDemographicsModule,
	reportsModule,
	myDownloadsModule,
	logisticsModule,
	dashboardModule,
	clinicalDataModule,
	dataManagementModule,
	editRolesPopupModule,
	deleteUserPopupModule,
	userRolesModule,
	iClinicaFrameModule,
	dataManagementFilesModule,
	downloadsModule,
	dispenseISpiroDevicePopupModule,
	dispenseKardiaPopupModule,
	editISpiroDeviceValuesPopupModule,
	iSpiroAuditReportPopupModule,
	scheduleISpiroEventPopupModule,
	queryGuidelineComponent
])

	.service(StudyVocabularyInterpolation.id(), StudyVocabularyInterpolation)
	.name;
