angular.module('portalApp').directive('formlyErrorSummary', function() {
    return {
        scope: {},
        bindToController: {
            form: '=',
            fields: '='
        },

        templateUrl: 'scripts/directives/clinicalDataEntry/formly-error-summary.html',
        controllerAs: 'vm',
        controller: /* @ngInject */ function() {
            var vm = this;

            vm.getErrorAsList = getErrorAsList;
            vm.fieldsFocused = fieldsFocused;

            function fieldsFocused() {
                var res = vm.fields.findIndex(function(field){
                	if(field.formControl && field.formControl.focused) {
						return field.formControl.focused;
					}
                });
                return (res >= 0);
            }

            function getErrorAsList(field) {
                return Object.keys(field.formControl.$error).map(function(error) {
                    // note, this only works because the custom input type we have defined.
                    return field.data.getValidationMessage(error);
                }).join(', ');
            }
        }
    };
});
