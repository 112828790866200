(function() {

	'use strict';

	angular
		.module("portalApp")
		.controller("StudyPermissionsCtrl", StudyPermissionsController);

	/**
	 * @ngInject
	 */
	function StudyPermissionsController($log, $state, $scope, $rootScope, studyPermissionsService, loadingIndicatorService,
										studyPermissionsTrans, configurationService, appConfig, $stateParams,
										signatureService) {

		$log = $log.getInstance("StudyPermissionsCtrl", "color:purple");
		$log.debug("StudyPermissionsCtrl:: loaded");

		var vm = this;
		vm.localized = {gridColumns: {}, list: {}};
		vm.config={};

		activate();

		function activate() {
			$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
				if (toState.name === appConfig.routes.studyPermissions) {
					redirect();
				}
			});
			loadingIndicatorService.show();
			loadSiteMapName();
			studyPermissionsService.getStudyPermissionsConfig()
				.then(function (response) {
					vm.config = response.data;

					studyPermissionsTrans.getListViewLocalized().then(function (localized) {
						vm.localized = localized;
						vm.tabs = [];

						if(vm.config.allowStudyLevelPermission === true){
							vm.tabs.push({displayName: localized.list.studyUsers, ref: appConfig.routes.studyUsers});
						}
						if(vm.config.allowCountryLevelPermission === true){
							vm.tabs.push({displayName: localized.list.countryUsers, ref: appConfig.routes.countryUsers});
						}
						vm.tabs.push({displayName: localized.list.siteUsers, ref: appConfig.routes.siteUsers});

						if (vm.config.allowSiteAdministratorPermission) {
							vm.tabs.push({ displayName: localized.list.siteAdministrators, ref: appConfig.routes.siteAdmins });
						}
						vm.moduleActions = [];
						if(vm.config.createSiteUser === true) {
							vm.moduleActions = [{
								displayName: localized.buttons.createUser,
								iconClass: 'es-icon-user-management-study-permission',
								action: createSiteUser,
								cssClass: '-es-primary'
							}];
						}
						if(vm.config.createStudyUser === true) {
							vm.moduleActions.push({
								displayName: 'Create Sponsor User',
								iconClass: 'es-icon-user-management-study-permission',
								action: createSponsorUser,
								cssClass: '-es-primary'
							});
						}

						redirect();
					});
				}, function (error) {
					vm.loadingError = error.data.message || error.data;
				}).finally(() => {
					loadingIndicatorService.hide();
				});

			if ($stateParams.signKey) {
				const state = signatureService.getState($stateParams.signKey);

				if (state) {
					if (state.sigType === appConfig.sigType.createUser) {
						const unbind = $scope.$watch('studyPermissionsService.isCreateSiteUserHandlerRegistered()', () => {
							studyPermissionsService.showCreateSiteUser();
							unbind();
						});
					}
					if(state.sigType === appConfig.sigType.createSponsorUser) {
						const unbind = $scope.$watch('studyPermissionsService.isCreateSponsorUserHandlerRegistered()', () => {
							studyPermissionsService.showCreateSponsorUser();
							unbind();
						});
					}
				} else {
					vm.showSignatureNotificationPopup = true;
				}


			}
		}

		function redirect() {

			if ($state.current.name !== appConfig.routes.studyPermissions) {
				return;
			}

			if (vm.config.allowStudyLevelPermission === true) {
				$state.go(appConfig.routes.studyUsers);
			}
			else if (vm.config.allowCountryLevelPermission === true) {
				$state.go(appConfig.routes.countryUsers);
			}
			else {
				$state.go(appConfig.routes.siteUsers);
			}
		}

		function loadSiteMapName() {
			configurationService.getSiteMapLabel(appConfig.siteMapModuleIds.studyPermissions).then(
				function (label) {
					vm.moduleLabel = label;
				}
			);
		}

		function createSiteUser(){
			studyPermissionsService.showCreateSiteUser();
		}

		function createSponsorUser() {
			studyPermissionsService.showCreateSponsorUser();
		}
	}
})();
