import templateUrl from './previewPopup.html';
import controller from './previewPopup.controller';
import './previewPopup.scss';

const previewPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default previewPopupComponent;
