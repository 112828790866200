(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('NewEditRefMaterialCtrl', NewEditRefMaterialController);

	/**
	 *  @ngInject
	 */

	function NewEditRefMaterialController($scope, $log, refMatService, studySelectService, $state, $filter, appI18n,
			loadingIndicatorService) {

		$log = $log.getInstance("NewEditRefMaterialController");
		$log.debug("loaded");

		var vm = this;
		vm.folder = {};

		function activate() {
			refMatService.registerNewEditFolderController(vm);
			$scope.$on('$destroy', () => {
				refMatService.registerNewEditFolderController();
			});
			vm.load = true;
			appI18n.translate( [
				"refmat.edit.save",
				"refmat.new.title",
				"refmat.edit.title",
				"refmat.edit.number-files",
				"refmat.edit.created-by",
				"refmat.edit.created-on",
				"refmat.edit.modified-by",
				"refmat.edit.modified-on",
				"refmat.study-assignment.sponsor",
				"refmat.study-assignment.study-name",
				"refmat.study-assignment.study-id",
				"refmat.edit.error-loading",
				"refmat.edit.error-save",
				"refmat.edit.folder-message",
				"refmat.edit.study-select-error"
			])
			.then(function(translations) {
				vm.saveTitle = translations["refmat.edit.save"];
				vm.newTitle = translations["refmat.new.title"];
				vm.editTitle = translations["refmat.edit.title"];
				vm.numFileText = translations["refmat.edit.number-files"];
				vm.createdByMessage = translations["refmat.edit.created-by"];
				vm.createdOnMessage = translations["refmat.edit.created-on"];
				vm.modifiedByMessage = translations["refmat.edit.modified-by"];
				vm.modifiedOnMessage = translations["refmat.edit.modified-on"];
				vm.sponsorTitle = translations["refmat.study-assignment.sponsor"];
				vm.studyTitle = translations["refmat.study-assignment.study-name"];
				vm.studyNumTitle = translations["refmat.study-assignment.study-id"];
				vm.loadingErrorMsg = translations["refmat.edit.error-loading"];
				vm.saveErrMsg = translations["refmat.edit.error-save"];
				vm.folderError = translations["refmat.edit.folder-message"];
				vm.studySelectError = translations["refmat.edit.study-select-error"];

				//Get user permission to show/hide buttons
				
				vm.folderActions = [{
					action: vm.addEditFolder, displayName: vm.saveTitle,
					isDisabled: vm.isLoading,
					isPrimary: true
				}];
				vm.studyOptions.columnDefs = [
						{ displayName: vm.sponsorTitle, fieldName: 'sponsorName', type: 'string' },
						{ displayName: vm.studyTitle, fieldName: 'protocolName', type: 'string' },
						{ displayName: vm.studyNumTitle, fieldName: 'protocolAccount', type: 'string' }
				];
			});
		}

		activate();

		vm.rowsSelected = function (rows) {
			vm.errorMessage = '';
			if (rows.length > 0) { return; }
			vm.errorMessage = vm.studySelectError;
		}

		vm.showNewRefmat = function(folderId, permission) {
			loadingIndicatorService.show();
			$log.debug("showNewRefmat");
			if (!permission.isAdmin) {
				$state.go(appConfig.routes.refMaterials);
			}
			vm.id = folderId;
			vm.folder = {};
			vm.selectedRows = [];
			vm.saveClicked = false;
			vm.errorMessage = "";
			vm.folder.deletedStudies = [];

			vm.newEditTitle = vm.newTitle;
			vm.active = true;


			if (vm.id > 0) {
				vm.newEditTitle = vm.editTitle;
				vm.loadRefMat();
			} else {
				vm.folder.isActive = true;
				vm.showStudyGrid = true;
				vm.showStudyTable = false;
			}
		};

		vm.showGrid = function() {
			vm.showStudyGrid = true;
			vm.showStudyTable = false;
		};


		vm.loadRefMat = function() {
			vm.isLoading = true;

			//Load data
			refMatService.getFolder(vm.id)
			.then(function(response) {
				vm.folder = response.data;
				vm.selectedRows = vm.folder.selectedStudies;
				vm.createdOnValue = $filter('ertFilter')(vm.folder.createdOn, 'dateTimeFormat');
				vm.modifiedOnValue = $filter('ertFilter')(vm.folder.modifiedOn, 'dateTimeFormat');
				vm.showStudyTable = true;
				vm.showStudyGrid = false;
			}, function() {
				vm.errorMessage = vm.loadingErrorMsg;
			}).finally(() => {
				vm.isLoading = false;
				vm.showNewEditFolder = true;
				loadingIndicatorService.hide();
			});
		};

		vm.studySelected = function() {
			return vm.selectedRows && vm.selectedRows.length > 0;
		};

		function closeFolder() {
			vm.showNewEditFolder = false;
			refMatService.refreshGrid();

		}

		function validateForm() {
			let hasError = false;

			if (!vm.folder.folderName) {
				hasError = true;
			}
			if (vm.folder.newSelectedStudies.length === 0) {
				vm.errorMessage = vm.studySelectError;
				hasError = true;
			}
			return hasError;
		}

		vm.addEditFolder =function() {
			vm.errorMessage = "";
			vm.saveClicked = true;

			//Get selected studies from portal grid
			vm.folder.newSelectedStudies = [];
			angular.forEach(vm.selectedRows, function(value) {
				vm.folder.newSelectedStudies.push(value.protocolId);
			});

			if (validateForm()) {
				return;
			}

			loadingIndicatorService.show();

			//Find deleted items after comparing the original array with new array
			vm.folder.deletedStudies = [];
			angular.forEach(vm.folder.selectedStudies, function(value) {
				var found = $filter('filter')(vm.folder.newSelectedStudies, value.protocolId, false);
				if (found.length === 0) {
					vm.folder.deletedStudies.push(value.protocolId);
				}
			});
			var folder = {'folderId': vm.id, 'folderName': vm.folder.folderName, 'isActive': vm.folder.isActive,
				'checkedProtocolList': vm.folder.newSelectedStudies.join(','), 
				'uncheckedProtocolList': vm.folder.deletedStudies.join(',')};
			refMatService.saveFolder(folder)
			.then(function(){
				loadingIndicatorService.hide();
				closeNewRefmat();
			}, function() {
				vm.errorMessage = vm.saveErrMsg;
				loadingIndicatorService.hide();

			});

		};

		vm.studyOptions = {
			rowDefs: {
				multiSelection: true,
				identifier: 'protocolId'
			},
			gridRowActions: [],
			gridActions: [],

			removeExportControls: true,
			stateParamPrefix: 'rfs',

			getData: getStudies
		};

		activate();

		function getStudies(options) {
			return studySelectService.getStudies(options).then(response => {
				vm.showNewEditFolder = true;
				loadingIndicatorService.hide();
				return response;
			});
		}

		function closeNewRefmat() {
			vm.showNewEditFolder = false;
			vm.selectedRows = [];
			refMatService.refreshGrid();
		}
	}

})();
