/*
 * loadingRowModule
 * */

import loadingRowDirective from './loadingRow.directive';

const loadingRowModule = angular.module('app.common.table.loadingRow', [])
	.directive('loadingRow', loadingRowDirective)
	.name;

export default loadingRowModule;
