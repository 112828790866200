(function () {
	'use strict';

	angular
		.module('portalApp')
		.directive('ertSgSignature', ERTSignature);

	function ERTSignature() {
		return {
			restrict: 'AE',
			bindToController: true,
			scope: {
				sigType: '@',
				signature: '='
			},
			templateUrl: 'scripts/directives/signature/signatureSg.html',
			controller: SignatureController,
			controllerAs: 'vm'
		};

		/** @ngInject */
		function SignatureController($log) {
			$log.getInstance('signaturecontroller');
			$log.debug('signature controller instantiated');
			this.getId = () => {
				if (this.sigType) {
					return `agreecheck_${this.sigType.replace(/ /g, '_')}`;
				}
				return 'agreecheck';
			};
		}
	}
})();
