import templateUrl from './datePicker.html';
import controller from './datePicker.controller';
import './datePicker.scss';

const datePickerComponent = {
	restrict: 'E',
	bindings: {
		name: '@',
		value: '=',
		setValue: '<?',
		options: '<',
		onChange: '&',
		componentReady: '&'
	},
	transclude: true,
	require: {
		fg: '^form'
	},
	templateUrl,
	controller
};

export default datePickerComponent;
