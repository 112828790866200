/*
 * esDropdownDirective
 * */



const esDropdownDirective = () => {
	'ngInject';
	return {
		scope: {
		},
		restrict: 'C',
		link(scope, elem) {
			const dropdown = elem.children().first();
			const list = elem.children().last();

			dropdown.on('mousedown', () => {
				elem.toggleClass('-es-open');
			}).on('blur', () => {
				elem.removeClass('-es-open');
			});

			list.on('mousedown', (e) => {
				e.preventDefault();
			}).on('click', () => {
				dropdown.blur();
			});
		}
	};
};

export default esDropdownDirective;
