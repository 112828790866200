/*
 * clinicalDataController
 * */

class ClinicalDataController {
	/* eslint-disable max-params */
	constructor(
		$log, $stateParams, $state, $scope, $rootScope, $q, userService,
		appConfig, dataManagementService, appSession, signatureService,
		configurationService, appI18n, clinicalDataService, dialogService,
		analyticsService, ertBasePopupService, bodyCssClassService, iSpiroService,
		kardiaService) {
		'ngInject';
		this.$log = $log;
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$q = $q;
		this.userService = userService;
		this.appConfig = appConfig;
		this.dataManagementService = dataManagementService;
		this.appSession = appSession;
		this.signatureService = signatureService;
		this.configurationService = configurationService;
		this.ertBasePopupService = ertBasePopupService;
		this.appI18n = appI18n;
		this.dialogService = dialogService;
		this.clinicalDataService = clinicalDataService;
		this.analyticsService = analyticsService;
		this.bodyCssClassService = bodyCssClassService;
		this.iSpiroService = iSpiroService;
		this.kardiaService = kardiaService;

		this.editISpiroSubjectApi = null;
		this.dispenseDevicePopupApi = null;
		this.dispenseKardiaPopupApi = null;
		this.refreshCallback = null;

		this.clinicalDataService.setClinicalDataController(this);

		this.dcrActionId = 'dcr';

		this.loaded = new Promise((res) => {
			this.resolvePromise = res;
		});

		this.isMicroPortalAllowed = false;
		this.canUseHomeBPActions = false;
		this.canUseCoughDeviceActions = false;
		this.canUseArrhythmiaDeviceActions = false;
		
	}

	/* eslint-enable max-params */

	$onInit() {
		this.$log = this.$log.getInstance('ClinicalDataController');
		this.$log.debug('loaded');

		this.appI18n.translate([
			'clinicaldata.clinical-data-title',
			'clinicaldata.countries-title',
			'clinicaldata.sites-title',
			'clinicaldata.subjects-title',
			'clinicaldata.visits-title',
			'clinicaldata.ecoa-viewer-title',
			'clinicaldata.subject.buttons.create',
			'clinicaldata.subject.buttons.editDeviceValues',
			'clinicaldata.subject.buttons.dispenseDevice',
			'clinicaldata.subject.buttons.closeDevice',
			'clinicaldata.subject.buttons.lostDevice',
			'clinicaldata.subject.buttons.resetPin',
			'clinicaldata.subject.buttons.closeNextVisit',
			'clinicaldata.subject.buttons.generateISpiroReport',
			'clinicaldata.subject.buttons.scheduleEvent',
			'spiro-viewer.dataCorrection',
			'clinicaldata.subject.i-spiro.dropdownActionsText',
			'clinicaldata.scheduledEvents.title',
			'clinicaldata.scheduledEvents.cancel',
			'clinicaldata.scheduledEvents.start',
			'clinicaldata.scheduledEvents.reschedule',
			'clinicaldata.subject.kardia.dropdownActionsText',
			'clinicaldata.subject.homebp.dropdownActionsText',
			'clinicaldata.subject.coughDevice.dropdownActionsText',
			'clinicaldata.subject.homebpAndCoughDevice.dropdownActionsText',
			'clinicaldata.subject.patch.dropdownActionsText',
			'clinicaldata.subject.patchAndHomeBP.dropdownActionsText',
			'clinicaldata.subject.patchAndCough.dropdownActionsText',
			'clinicaldata.subject.patchAndHomeBPAndCough.dropdownActionsText'

		]).then((translations) => {
			this.translations = translations;
			this.clindataTitle = translations['clinicaldata.clinical-data-title'];
			this.countriesTitle = translations['clinicaldata.countries-title'];
			this.sitesTitle = translations['clinicaldata.sites-title'];
			this.subjectsTitle = translations['clinicaldata.subjects-title'];
			this.visits = translations['clinicaldata.visits-title'];
			this.ecoaViewerTitle = translations['clinicaldata.ecoa-viewer-title'];
			this.dataCorrection = translations['spiro-viewer.dataCorrection'];
			this.activate();
		});
	}

	$onDestroy() {
	}

	activate() {

		// open the correct popup after signature was added
		if (this.$stateParams.signKey) {
			const state = this.signatureService.getState(this.$stateParams.signKey);

			if (state) {
				if (state.sigType === this.appConfig.sigType.createSubject) {
					const unbind = this.$scope.$watch('clinicalDataService.isCreateSubjectHandlerRegistered()',
						() => {
							this.clinicalDataService.openCreateSubject();
							unbind();
						});
				} else if (state.sigType === this.appConfig.sigType.editSubject) {
					const unbind = this.$scope.$watch('clinicalDataService.isEditSubjectHandlerRegistered()', () => {
						this.clinicalDataService.openEditSubject(state.row);
						unbind();
					});
				} else if (state.sigType === this.appConfig.sigType.createDcr) {
					// hide the page content
					this.bodyCssClassService.setClass({ customClass: 'signature-active' });
					this.dataManagementService.showNewDcr();
				}
			} else {
				this.showSignatureNotificationPopup = true;
			}
		}

		const stateChange = this.$rootScope.$on('$stateChangeSuccess', (event, toState/*, toParams*/) => {
			this.checkCreateDcr();
			if (toState.name === this.appConfig.routes.clData) {
				this.checkUserAccessLevels();
			}
		});

		if (this.$state.current.name === this.appConfig.routes.clData) {
			this.checkUserAccessLevels();
		}

		this.addButtons();
		const expertAvailabilityChange = this.$rootScope.$on(this.appConfig.broadcast.expertAvailabilityChange,
			this.expertAvailabilityChange);

		this.$scope.$on('$destroy', () => {
			expertAvailabilityChange();
			stateChange();
			this.clinicalDataService.unsetClinicalDataController(this);
		});
	}

	expertAvailabilityChange() {
		this.addButtons();
	}

	getClinicalDataModuleNameForRouteName(routeName) {
		switch (routeName) {
			case this.appConfig.routes.clDataCountries:
				return this.appConfig.clinicalDataModuleNames.country;
			case this.appConfig.routes.clDataCountrySites:
			case this.appConfig.routes.clDataSites:
				return this.appConfig.clinicalDataModuleNames.site;
			case this.appConfig.routes.clDataSiteSubjects:
			case this.appConfig.routes.clDataSubjects:
				return this.appConfig.clinicalDataModuleNames.subject;
			case this.appConfig.routes.clDataVisits:
				return this.appConfig.clinicalDataModuleNames.visit;
			default: return undefined;
		}
	}

	getClinicalDataColumnsPromise(routeName) {
		const moduleName = this.getClinicalDataModuleNameForRouteName(routeName);

		return moduleName
			? this.configurationService.getClinicalDataColumns(moduleName)
			: this.$q.resolve();
	}

	addButtons() {

		// preload clinical data columns for country, site, subject or visit
		const clinicalDataColumnsPromise = this.getClinicalDataColumnsPromise(this.$state.current.name);

		const expertUnavailable = this.$rootScope.expertUnavailable;
		const defers = this.$q.all([
			this.configurationService.getStudySettings(),
			this.userService.getEproPermissions(),
			this.userService.getUserHasPermission(this.appConfig.businessPermissions.iSpiroDeviceAction),
			this.userService.getUserIsInternal(),
			this.userService.getUserHasPermission(this.appConfig.businessPermissions.kardiaAction),
			clinicalDataColumnsPromise,
			this.userService.getUserHasPermission(this.appConfig.businessPermissions.homeBPDeviceAction),
			this.userService.getUserHasPermission(this.appConfig.businessPermissions.coughDeviceAction),
			this.userService.getUserHasPermission(this.appConfig.businessPermissions.arrhythmiaDeviceAction)
		]);


		defers.then((resolves) => {
			const canUseISpiroActions = resolves[2];
			const canUseKardiaActions = resolves[4];
			const isInternal = resolves[3];

			this.canUseHomeBPActions = resolves[6];
			this.canUseCoughDeviceActions = resolves[7];
			this.canUseArrhythmiaDeviceActions = resolves[8];

			this.studyConfig = resolves[0];

			if (this.isHomeBPAllowed() || this.isCoughAllowed() || this.isArrhythmiaAllowed()) {
				this.isMicroPortalAllowed = true;
			}

			this.getTabs();

			this.actions = [];

			if (!expertUnavailable) {
				this.actions.push({
					id: this.dcrActionId,
					displayName: this.dataCorrection,
					action: this.dataManagementService.showNewDcr,
					hidden: true,
					visibleIn: [
						this.appConfig.routes.spirometry,
						this.appConfig.routes.ecoa],
					iconClass: 'es-icon es-icon-data-management',
					cssClass: '-es-primary'
				});
			}

			this.checkCreateDcr();

			const visibility = [
				this.appConfig.routes.clDataSubjects,
				this.appConfig.routes.clDataCountries,
				this.appConfig.routes.clDataCountrySites,
				this.appConfig.routes.clDataSites,
				this.appConfig.routes.clDataScheduledEvents,
				this.appConfig.routes.clDataSiteSubjects];

			if (this.studyConfig.isiSpiroStudy && (canUseISpiroActions || isInternal)) {
				this.addISpiroActions(visibility, canUseISpiroActions, isInternal)
					.then(() => {
						// we need to trigger a digest cycle here because otherwise
						// the update of the actions.updated property is not detected correctly.
						this.actions.updated = true;
						this.$scope.$apply();
					});
			}
			if (resolves[1].createSubject && this.studyConfig.hasCreateSubject) {
				this.actions.push({
					displayName: this.translations['clinicaldata.subject.buttons.create'],
					cssClass: '-es-primary',
					iconClass: 'es-icon-user-name',
					action: () => {
						this.showCreateSubjectPopup();
					},
					visibleIn: visibility
				});
			}

			if (this.studyConfig.isKardiaStudy && canUseKardiaActions) {
				this.addKardiaActions(visibility);
				this.actions.updated = true;
			}

			
			if (this.isMicroPortalAllowed) {
				this.createMicroPortalActions(visibility);
			}
			
			if (resolves[1].closeVisit) {
				this.actions.push(
					{
						id: this.appConfig.actions.closeVisit,
						displayName: this.translations['clinicaldata.subject.buttons.closeNextVisit'],
						cssClass: '-es-primary',
						action: () => {
							this.showCloseVisitPopup();
						},
						visibleIn: ['nowhere']
					}
				);
				this.resolvePromise();
			}

			this.actions.updated = true;
		});
	}


	isHomeBPAllowed() {
		return this.studyConfig.hasHomeBP && this.canUseHomeBPActions;
	}

	isCoughAllowed() {
		 return this.studyConfig.hasCough && this.canUseCoughDeviceActions;
	}

	isArrhythmiaAllowed() {
		return this.studyConfig.hasArrhythmia && this.canUseArrhythmiaDeviceActions;
	}

	getMicroportalActionName() {
	
		if (this.isHomeBPAllowed() && this.isCoughAllowed() && this.isArrhythmiaAllowed()) {
			return 'patchAndHomeBPAndCough';
		} else if (this.isHomeBPAllowed() && this.isArrhythmiaAllowed()) {
			return 'patchAndHomeBP';
		} else if (this.isCoughAllowed() && this.isArrhythmiaAllowed()) {
			return 'patchAndCough';
		} else if (this.isHomeBPAllowed() && this.isCoughAllowed()) {
			return 'homebpAndCoughDevice';
		} else if (this.isHomeBPAllowed()) {
			return 'homebp';
		} else if (this.isCoughAllowed()) {
			return 'coughDevice';
		} else if (this.isArrhythmiaAllowed()) {
			return 'patch';
		}
		
	}


	createMicroPortalActions(visibility) {
		this.addDeviceMicroPortalActions(visibility, this.getMicroportalActionName());
		this.actions.updated = true;
	}

	addDeviceMicroPortalActions(visibility, actionName) {
		const microPortalAction = {
			displayName: this.translations[`clinicaldata.subject.${actionName}.dropdownActionsText`],
			cssClass: '-es-primary',
			visibleIn: visibility,
			action: () => {
				this.navigateToDeviceMicroportalUrl();
			}
		};

		this.actions.push(microPortalAction);
	}

	navigateToDeviceMicroportalUrl() {
		this.tabs = [];
		this.$state.go(this.appConfig.routes.deviceMicroPortal, { showMicroPortal: true });
	}


	async addISpiroActions(visibility, canUseISpiroActions, isInternal) {

		// generate iSpiro page action object
		const iSpiroAction = {
			displayName: this.translations['clinicaldata.subject.i-spiro.dropdownActionsText'],
			cssClass: '-es-primary',
			visibleIn: visibility,
			subItems: []
		};

		// if the user has the required business permission then add device actions based on
		// study-activities
		if (canUseISpiroActions) {
			const deviceActions = await this.getISpiroDeviceActions();

			iSpiroAction.subItems = deviceActions;
		}
		// if the user is an internal user in an iSpiro study add the generate audit report action.
		if (isInternal) {
			// add divider if device actions are available
			if (iSpiroAction.subItems.length > 0) {
				iSpiroAction.subItems.push({ isDivider: true });
			}
			iSpiroAction.subItems.push({
				displayName: this.translations['clinicaldata.subject.buttons.generateISpiroReport'],
				iconClass: 'es-icon-reports',
				action: () => {
					this.showGenerateISpiroAuditReportPopup();
				}
			});
		}

		// add page action
		this.actions.push(iSpiroAction);

		return this.$q.resolve();
	}

	addKardiaDeviceActions(kardiaActions) {
		kardiaActions.push(
			{
				displayName: this.translations['clinicaldata.subject.buttons.dispenseDevice'],
				cssClass: '-es-primary',
				iconClass: 'es-icon-supply-ordering',
				action: () => {
					this.showDispenseKardiaPopup();
				}
			}
		);
	}

	async getISpiroDeviceActions() {
		const iSpiroDeviceActions = [];
		const studyActivities = await this.iSpiroService.getStudyActivities();
		const isMicroPortalActivated = this.$stateParams.showMicroPortal === 'true' ? true : false;

		if (this.$stateParams.site && !this.$stateParams.subject && !isMicroPortalActivated &&
			studyActivities.data.includes(this.appConfig.iSpiroActionId.scheduleEvent)) {
			this.tabs.push({
				displayName: this.translations['clinicaldata.scheduledEvents.title'],
				ref: this.appConfig.routes.clDataScheduledEvents,
				trackId: this.appConfig.trackId.iSpiroScheduledEvents,
				trackLabel: this.appConfig.trackLabel.levelStudy
			});

		}

		if (studyActivities.data.includes(this.appConfig.iSpiroActionId.dispense)) {
			iSpiroDeviceActions.push(
				{
					displayName: this.translations['clinicaldata.subject.buttons.dispenseDevice'],
					cssClass: '-es-primary',
					iconClass: 'es-icon-supply-ordering',
					action: () => {
						this.showDispenseDevicePopup();
					}
				}
			);
		}

		if (studyActivities.data.includes(this.appConfig.iSpiroActionId.edit)) {
			const isRemoteAction = true;

			iSpiroDeviceActions.push(
				{
					displayName: this.translations['clinicaldata.subject.buttons.editDeviceValues'],
					cssClass: '-es-primary',
					iconClass: 'es-icon-function-edit',
					action: () => {
						this.showEditDeviceValuesPopup(isRemoteAction);
					}
				}
			);
		}

		if (studyActivities.data.includes(this.appConfig.iSpiroActionId.close)) {
			iSpiroDeviceActions.push(
				{
					displayName: this.translations['clinicaldata.subject.buttons.closeDevice'],
					cssClass: '-es-primary',
					iconClass: 'es-icon-row-close',
					action: () => {
						this.showRemoteCloseDevicePopup();
					}
				}
			);
		}

		if (studyActivities.data.includes(this.appConfig.iSpiroActionId.lost)) {
			iSpiroDeviceActions.push(
				{
					displayName: this.translations['clinicaldata.subject.buttons.lostDevice'],
					iconClass: 'es-icon-missed',
					action: () => {
						this.showLostDevicePopup();
					}
				}
			);
		}

		if (studyActivities.data.includes(this.appConfig.iSpiroActionId.resetPin)) {
			iSpiroDeviceActions.push(
				{
					displayName: this.translations['clinicaldata.subject.buttons.resetPin'],
					cssClass: '-es-primary',
					iconClass: 'es-icon-unlock',
					action: () => {
						this.showResetPinPopup(this.$stateParams.country, this.$stateParams.site);
					}
				}
			);
		}

		if (studyActivities.data.includes(this.appConfig.iSpiroActionId.scheduleEvent)) {
			iSpiroDeviceActions.push(
				{
					displayName: this.translations['clinicaldata.subject.buttons.scheduleEvent'],
					cssClass: '-es-primary',
					iconClass: 'es-icon-calendar',
					action: () => {
						this.showScheduleEventPopup(this.$stateParams.country, this.$stateParams.site);
					}
				}
			);
		}

		return iSpiroDeviceActions;
	}

	addKardiaActions(visibility) {
		const kardiaAction = {
			displayName: this.translations['clinicaldata.subject.kardia.dropdownActionsText'],
			cssClass: '-es-primary',
			visibleIn: visibility,
			subItems: []
		};

		this.actions.push(kardiaAction);

		return this.addKardiaDeviceActions(kardiaAction.subItems);

	}

	

	checkCreateDcr() {
		if (this.appSession.getIsExpertUnavailable()) {
			this.setCreateDcrHidden(true);
		} else {
			this.dataManagementService.getIsCreateDcrAllowedForSite(this.$stateParams.site).then((result) => {
				this.setCreateDcrHidden(!result);
			});
		}
	}

	setCreateDcrHidden(hidden) {
		if (!this.actions) {
			return;
		}

		for (let i = 0; i < this.actions.length; ++i) {
			if (this.actions[i].id === this.dcrActionId) {
				this.actions[i].hidden = hidden;
				this.actions.updated = true;
				break;
			}
		}
	}

	isCreateDcrAllowed() {
		return this.dataManagementService.getIsCreateDcrAllowedForSite(this.$stateParams.site);
	}

	showNoDeviceSubjectMessage(row) {
		this.$log.debug('showNoDeviceSubjectMessage');

		this.noDeviceSubjectMessageHandler = this.dialogService.createConfirmHandler();
		this.noDeviceSubjectMessageHandler.show().then(function () {
			this.clinicalDataService.openEditSubject(row);
			this.noDeviceSubjectMessageHandler.hide();
		});
	}

	showCreateSubjectPopup() {
		this.clinicalDataService.openCreateSubject();
	}

	onEditDeviceValuesPopupReady($API) {
		this.editDeviceValuesPopupApi = $API;
	}

	showEditDeviceValuesPopup(isRemoteAction, countryId, siteId, subjectId, displaySiteName, subjectKey) {
		if (countryId && siteId && subjectId) {
			this.editDeviceValuesPopupApi.open(isRemoteAction, countryId, siteId, subjectId, displaySiteName,
											   subjectKey);
			return;
		} else if (this.$state.is(this.appConfig.routes.clDataScheduledEvents) ||
				   this.$state.is(this.appConfig.routes.clDataSiteSubjects) ||
				   this.$state.is(this.appConfig.routes.clDataCountrySites)) {
			this.editDeviceValuesPopupApi.open(isRemoteAction, this.$stateParams.country, this.$stateParams.site);
			return;
		}
		this.editDeviceValuesPopupApi.open();
	}

	onDispenseDevicePopupReady($API) {
		this.dispenseDevicePopupApi = $API;
	}

	showDispenseDevicePopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
		if (countryId && siteId && subjectId) {
			this.dispenseDevicePopupApi.open(false, countryId, siteId, subjectId, displaySiteName, subjectKey);
			return;
		} else if (this.$state.is(this.appConfig.routes.clDataScheduledEvents) ||
				   this.$state.is(this.appConfig.routes.clDataSiteSubjects) ||
				   this.$state.is(this.appConfig.routes.clDataCountrySites)) {
			this.dispenseDevicePopupApi.open(false, this.$stateParams.country, this.$stateParams.site);
			return;
		}
		this.dispenseDevicePopupApi.open();
	}

	showScheduleEventPopup(countryId, siteId, displaySiteName, technicalSubjectId, subjectKey) {
		this.ertBasePopupService.popup('iSpiroScheduleEventPopup').open(
			countryId, siteId, displaySiteName, technicalSubjectId, subjectKey)
			.then(() => {
				this.refreshCallback && this.refreshCallback();
			});
	}


	showGenerateISpiroAuditReportPopup() {
		this.ertBasePopupService.popup('iSpiroAuditReportPopup')
			.open(this.$stateParams.country, this.$stateParams.site);
	}

	showLostDevicePopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
		this.ertBasePopupService.popup('iSpiroPopup')
			.open(countryId || this.$stateParams.country, siteId || this.$stateParams.site,
				  subjectId, this.appConfig.iSpiroActionId.lost, displaySiteName, subjectKey);
	}

	showResetPinPopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
		this.ertBasePopupService.popup('iSpiroPopup')
			.open(countryId || this.$stateParams.country, siteId || this.$stateParams.site,
				  subjectId, this.appConfig.iSpiroActionId.resetPin, displaySiteName, subjectKey);
	}

	showRemoteCloseDevicePopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
		this.ertBasePopupService.popup('iSpiroPopup')
			.open(countryId || this.$stateParams.country, siteId || this.$stateParams.site,
				  subjectId, this.appConfig.iSpiroActionId.close, displaySiteName, subjectKey);
	}

	showCloseVisitPopup() {
		this.clinicalDataService.openCloseVisit();
	}

	onDispenseKardiaPopupReady($API) {
		this.dispenseKardiaPopupApi = $API;
	}

	showDispenseKardiaPopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
		if (countryId && siteId && subjectId) {
			this.dispenseKardiaPopupApi.open(false, countryId, siteId, subjectId, displaySiteName, subjectKey);
			return;
		} else if (this.$state.is(this.appConfig.routes.clDataSiteSubjects) ||
				   this.$state.is(this.appConfig.routes.clDataCountrySites)) {
			this.dispenseKardiaPopupApi.open(false, this.$stateParams.country, this.$stateParams.site);
			return;
		}
		this.dispenseKardiaPopupApi.open();
	}

	showCloseKardiaPopup(countryId, siteId, subjectId, displaySiteName, subjectKey) {
		this.ertBasePopupService.popup('kardiaPopup')
			.open(countryId || this.$stateParams.country, siteId || this.$stateParams.site,
				  subjectId, this.appConfig.kardiaActionId.close, displaySiteName, subjectKey);
	}

	checkUserAccessLevels() {
		this.userService.getUserAccessLevel()
			.then((data) => {
				if (data.accessToOnlyOneSite) {
					this.analyticsService.trackEventWithTitle(
						`${this.appConfig.trackCategory.navigateTo}::${this.appConfig.trackId.clinicalDataSubject}`,
						this.appConfig.trackAction.navigation, this.appConfig.trackLabel.levelSite);

					this.$state.go(this.appConfig.routes.clDataSiteSubjects, {
						country: data.countryId,
						site: data.siteId,
						showMicroPortal: false
					});
				} else if (data.accessToSitesInOnlyOneCountry) {
					this.analyticsService.trackEventWithTitle(
						`${this.appConfig.trackCategory.navigateTo}::${this.appConfig.trackId.clinicalDataSite}`,
						this.appConfig.trackAction.navigation, this.appConfig.trackLabel.levelCountry);

					this.$state.go(this.appConfig.routes.clDataCountrySites, {
						country: data.countryId,
						showMicroPortal: false
					});
				} else {
					this.analyticsService.trackEventWithTitle(
						`${this.appConfig.trackCategory.navigateTo}::${this.appConfig.trackId.clinicalDataCountry}`,
						this.appConfig.trackAction.navigation, this.appConfig.trackLabel.levelStudy);

					this.$state.go(this.appConfig.routes.clDataCountries, { showMicroPortal: false });
				}
			});
	}

	// TODO: refactor this
	/* eslint-disable complexity */
	getTabs() {
		this.tabs = [];

		const expertUnavailable = this.$rootScope.expertUnavailable;
		const trackId = this.appConfig.trackId;

		this.$log.debug(`gettabs with expert ${expertUnavailable}`);
		const tdtId = +this.$stateParams.tdt;
		const isMicroPortalActivated = this.$stateParams.showMicroPortal === 'true' ? true : false;

		if (tdtId === this.appConfig.testDataType.ecg ||
			tdtId === this.appConfig.testDataType.extractionEcg ||
			tdtId === this.appConfig.testDataType.slowSpirometry ||
			tdtId === this.appConfig.testDataType.homeForcedSpirometry ||
			tdtId === this.appConfig.testDataType.forcedSpirometry ||
			isMicroPortalActivated) {
			//no tabs from here, spirometry viewer will add it
		} else {
			if (!this.$stateParams.country) {
				this.tabs.push({
					displayName: this.countriesTitle,
					ref: this.appConfig.routes.clDataCountries,
					trackId: trackId.clinicalDataCountry,
					trackLabel: this.appConfig.trackLabel.levelStudy
				});

				if (!this.$stateParams.site) {
					this.tabs.push({
						displayName: this.sitesTitle,
						ref: this.appConfig.routes.clDataSites,
						trackId: trackId.clinicalDataSite,
						trackLabel: this.appConfig.trackLabel.levelStudy
					});
				}
				if (!this.$stateParams.subject) {
					this.tabs.push({
						displayName: this.subjectsTitle,
						ref: this.appConfig.routes.clDataSubjects,
						trackId: trackId.clinicalDataSubject,
						trackLabel: this.appConfig.trackLabel.levelStudy
					});
				}
			} else {
				if (!this.$stateParams.site) {
					this.tabs.push({
						displayName: this.sitesTitle,
						ref: this.appConfig.routes.clDataCountrySites,
						trackId: trackId.clinicalDataSite,
						trackLabel: this.appConfig.trackLabel.levelCountry
					});
				}
				if (!this.$stateParams.subject) {
					const trackLabel = !this.$stateParams.site ? this.appConfig.trackLabel.levelCountry
						: this.appConfig.trackLabel.levelSite;

					this.tabs.push({
						displayName: this.subjectsTitle,
						ref: this.appConfig.routes.clDataSiteSubjects,
						trackId: trackId.clinicalDataSubject,
						trackLabel
					});
				}
			}

			if (this.$stateParams.subject && (!this.$stateParams.event || this.$stateParams.event === '0')) {
				this.tabs.push({
					displayName: this.visits,
					ref: `${this.appConfig.routes.clDataVisits}({event: "", mdt: "", tdt: ""})`,
					trackId: trackId.clinicalDataVisit
				});
			}

			if (this.$stateParams.mdt === this.appConfig.modalityType.am3) {
				this.ecoaViewerTitle = this.appI18n.translateImmediate('clinicaldata.subject-device-viewer-title');
				this.tabs.push({
					displayName: this.ecoaViewerTitle,
					ref: this.appConfig.routes.ecoa,
					trackId: trackId.clinicalDataEcoaViewer,
					trackLabel: this.appConfig.trackLabel.levelEvent
				});
			} else if (this.$stateParams.eproSubject && this.studyConfig.hasCoaAm && this.$stateParams.subject &&
				(!this.$stateParams.event || this.$stateParams.event === '0')) {
				this.ecoaViewerTitle = this.appI18n.translateImmediate('clinicaldata.subject-device-viewer-title');
				this.tabs.push({
					displayName: this.ecoaViewerTitle,
					ref: `${this.appConfig.routes.ecoa}({event: 0, mdt: ${this.appConfig.modalityType.am3},
							tdt: ${this.appConfig.testDataType.coaAm}})`,
					trackId: trackId.clinicalDataEcoaViewer,
					trackLabel: this.appConfig.trackLabel.levelEvent
				});
			}

			if (this.$stateParams.mdt === this.appConfig.modalityType.diaryPro) {
				this.ecoaViewerTitle = this.appI18n.translateImmediate('clinicaldata.subject-device-viewer-title');
				this.tabs.push({
					displayName: this.ecoaViewerTitle,
					ref: this.appConfig.routes.ecoa,
					trackId: trackId.clinicalDataEcoaViewer,
					trackLabel: this.appConfig.trackLabel.levelEvent
				});
			} else if (this.$stateParams.eproSubject &&
				(this.studyConfig.hasEcoaHandheld || this.studyConfig.hasDiaryPro) &&
				this.$stateParams.subject && (!this.$stateParams.event || this.$stateParams.event === '0')) {
				this.ecoaViewerTitle = this.appI18n.translateImmediate('clinicaldata.subject-device-viewer-title');

				const mdt = (this.studyConfig.hasEcoaHandheld) ? this.appConfig.modalityType.ecoaHandheld
					: this.appConfig.modalityType.diaryPro;
				const tdt = (this.studyConfig.hasEcoaHandheld) ? this.appConfig.testDataType.syndication
					: this.appConfig.testDataType.coaDiary;

				this.tabs.push({
					displayName: this.ecoaViewerTitle,
					ref: `${this.appConfig.routes.ecoa}({event: 0, mdt: ${mdt}, tdt: ${tdt}})`,
					trackId: trackId.clinicalDataEcoaViewer,
					trackLabel: this.appConfig.trackLabel.levelEvent
				});
			}

			if (tdtId === this.appConfig.testDataType.coaPhone) {
				this.ecoaViewerTitle = this.appI18n.translateImmediate('clinicaldata.subject-device-viewer-title');
				this.tabs.push({
					displayName: this.ecoaViewerTitle,
					ref: this.appConfig.routes.ecoa,
					trackId: trackId.clinicalDataEcoaViewer,
					trackLabel: this.appConfig.trackLabel.levelEvent
				});
			}
			if (tdtId === this.appConfig.testDataType.coaSite) {
				this.ecoaViewerTitle = this.appI18n.translateImmediate('clinicaldata.site-device-viewer-title');
				this.tabs.push({
					displayName: this.ecoaViewerTitle,
					ref: this.appConfig.routes.ecoa,
					trackId: trackId.clinicalDataEcoaViewer,
					trackLabel: this.appConfig.trackLabel.levelEvent
				});
			}
		}
	}

	getTitle() {
		return this.clindataTitle;
	}

	/* eslint-enable complexity */

}

export default ClinicalDataController;
