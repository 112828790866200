class MyDeviceAccountController {
	constructor($log, appI18n, configurationService, loadingIndicatorService) {
		'ngInject';
		this.$log = $log;
		this.appI18n = appI18n;
		this.loadingIndicatorService = loadingIndicatorService;
		this.configurationService = configurationService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('MyDeviceAccountController');
		this.$log.debug('initialize');
		this.loadingIndicatorService.show();
		this.isDeviceInfoLoading = true;
		this.getMyDeviceInfo();

		this.$API = {
			open: () => {
				this.isVisible = true;
			},
			close: () => {
				this.isVisible = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({ $API: this.$API });
		}
	}


	getMyDeviceInfo() {
		this.configurationService.getMyDeviceAccountInfo().then((response) => {
			this.info = response.data;
		}, (error) => {
			this.errorMessage = error;
		}).finally(() => {
			this.isDeviceInfoLoading = false;
			this.loadingIndicatorService.hide();
		});
	}

	cancel() {
		this.$API.close();
	}

	assign() {
		this.isAssignDeviceLoading = true;
		this.configurationService.assignDevice().then((response) => {
			this.info = {
				isDeviceInfo: true,
				deviceInfos: {}
			};
			this.info.deviceInfos[response.data.deviceId] = response.data.devicePassword;
		}, (error) => {
			this.errorMessage = error;
		}).finally(() => {
			this.isAssignDeviceLoading = false;
		});
	}
}
export default MyDeviceAccountController;

