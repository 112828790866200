(function () {
	'use strict';

	/**
	 * Module to initialize global staff. Log some global things.
	 */
	angular.module('portalApp').run(Startup);

	/**
	 * @ngInject
	 */
	function Startup($log, $rootScope, appConfig, appToast) {
		var version = appConfig.version;

		if (appConfig.buildnumber) {
				version = version + ' [' + appConfig.buildnumber + ']';
		}

		if (appConfig.environment) {
			version = version + ', ' + appConfig.environment;
		}

		$rootScope.version = version;
		$rootScope.year = new Date().getFullYear();

		$rootScope.links = {};
		$rootScope.links.customerCareLink = appConfig.external.customerCare;
		$rootScope.links.imprintLink = appConfig.external.imprint;
		$rootScope.links.privacyPolicyUrl = appConfig.external.privacyPolicyUrl;
		$rootScope.links.dataPrivacyStatementUrl = appConfig.external.dataPrivacyStatementUrl;

		appToast.settings.maxNumber = 5;
		appToast.settings.dismissOnTimeout = true;
		appToast.settings.dismissButton = true;
		appToast.settings.verticalPosition = 'bottom';
		appToast.settings.combineDuplications = true;

		var language = window.navigator.userLanguage || window.navigator.language;
		$log.info("window.navigator.language: " + language);
		
		var webstyleVersion = getErtWebStyleGuideVersion();
		$rootScope.webstyleVersion = webstyleVersion.substring(1,6);
		$log.info("Webstyle Version: " + webstyleVersion);
	}

	function getErtWebStyleGuideVersion(){
		var test = document.createElement('span');
		test.setAttribute('class', 'es-sg-version');
		document.body.appendChild(test);
		var version = window.getComputedStyle(
			test, '::after'
		).getPropertyValue('content');
		return version;
	}
	
	angular.module('portalApp')
		.config(function($provide) {
			// $compileProvider.preAssignBindingsEnabled(true);
			// $locationProvider.hashPrefix('');
			$provide.decorator('$exceptionHandler', ['$log', '$delegate', //'appToast',
				function($log, $delegate) { //, appToast) {
					return function(exception, cause) {
						if (typeof console !== 'undefined' && console.log) {
							console.log('Default exception handler. Exception: ', exception, "Cause: ", cause);
						}
						//appToast.create({ content: exception.message, className: 'danger', dismissOnTimeout: false });
						$delegate(exception, cause);
					};
				}
			]);
		});

})();
