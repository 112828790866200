(function () {
	'use strict';

	angular.module('portalApp').factory('httpInterceptor', HttpInterceptor);

	/*
	 * @ngInject
	 */
	angular.module('portalApp').config(($httpProvider) => {
		disableCacheHttpGet($httpProvider);
		$httpProvider.interceptors.push('httpInterceptor');
	});

	function disableCacheHttpGet($httpProvider) {
		if (!$httpProvider.defaults.headers.get) {
			$httpProvider.defaults.headers.get = {};
		}
		//disable IE ajax request caching, need any past valid HTTP-date
		$httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
		$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
		$httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
	}

	/*
	 * @ngInject
	 */
	function HttpInterceptor($injector, $q, $log, $rootScope, $location, appConfig,
		appSession, httpCache, globalMessageService,
		notificationService) {

		$log = $log.getInstance('httpInterceptor');
		$log.debug('loaded');

		const expertAvailability = appSession.getExpertAvailabilityObservable();

		return {
			/**
			 * Called when error during HTTP request happened
			 * @param rejection
			 * @returns {Promise}
			 */
			responseError: (rejection) => {
				// NOTICE: You can get rejection.status 0 or -1. Means either web server hadn't answered or browser
				// was unable to do actual HTTP request,
				// for example when connection refused. You will get message from browser net:ERR_CONNECTION_REFUSED
				// but not rejection.status or other details from rejection
				// Or another one good example is when browser hadn't received needed CORS headers in response
				const errorMsg = `Error response HTTP {status} with status text '{statusText}'.
					Error cause HTTP {config.method} request to '{config.url}'`;

				// don't log canceled requests as errors
				if (rejection.status === -1) {
					$log.warn(errorMsg, rejection);
				} else {
					$log.error(errorMsg, rejection);
				}

				let message = '';

				if (rejection.data) {
					message = 'Exception details.';

					if (rejection.data.message) {
						message += ' Message: "{data.message}".';
					}

					if (rejection.data.exceptionMessage) {
						message += ' Exception message "{data.exceptionMessage}".';
					}

					if (rejection.data.exceptionType) {
						message += ' Exception type "{data.exceptionType}".';
					}

					if (rejection.data.stackTrace) {
						message += ' Stack trace "{data.stackTrace}".';
					}

					$log.error(message, rejection);

					if (rejection.data.innerException) {
						$log.error(`Inner exception details. Message: '{data.innerException.message}',
						exception message '{data.innerException.exceptionMessage}'
						exception type '{data.innerException.exceptionType}',
						stack trace {data.innerException.stackTrace}`,
							rejection);
					}
				}

				const appI18n = $injector.get('appI18n');

				if (rejection.status === -1) {
					// aborted request, probably by refresh, no toast
					message = null;
				} else if (rejection.status === 0) {
					// process it here
				} else if (rejection.status === 401) {
					appSession.handleSessionExpiration();
					message = null;
				} else if (rejection.status === 404 || rejection.status === 500 || rejection.status === -1) {
					// might be a temporay or network issue, tell user to tray again later (-1 is connection abort)
					message = appI18n.translateImmediate('app.toaster.tryLaterError');
				} else {
					message = appI18n.translateImmediate('app.toaster.contactCustomerCareError');
				}

				if (message) {
					notificationService.showError(message);
				}

				return $q.reject(rejection);
			},
			request: (config) => {
				if (config.cache === true) {
					config.cache = httpCache;
				}

				if (config.url === appConfig.auth.tokenUrl) {
					return config;
				}
				if (config.corsSimpleRequest) {
					// don't add auth header, only accept plain text, should not trigger
					// cors preflight request
					config.headers = {};
					config.headers.Accept = "text/plain";
					return config;

				}

				// if a token is provided in the http call use it
				// otherwise use exisiting flow
				if (config.token) {
					config.headers.Authorization = `Bearer ${config.token}`;
					return config;
				}

				const sessionToken = appSession.getSessionToken();
				if (sessionToken) {
					config.headers.Authorization = `Bearer ${sessionToken}`;
					config.headers.studyId = appSession.getWindowStudy();
				} 

				return config;

			},

			/**
			 * Called on HTTP
			 * @param response
			 *
			 * @returns {response}
			 */
			response: (response) => {
				if (response) {
					const expertAvailabilityHeader = response.headers(appConfig.constants.expertAvailability.header);

					if (expertAvailabilityHeader) {
						if (expertAvailabilityHeader === appConfig.constants.expertAvailability.unavailable) {
							setExpertAvailability(true);
						} else if (expertAvailabilityHeader === appConfig.constants.expertAvailability.available) {
							setExpertAvailability(false);
						}

					}
				}
				return response;

			}
		};

		function setExpertAvailability(isUnavailable) {
			if (angular.isUndefined($rootScope.expertUnavailable) || $rootScope.expertUnavailable !== isUnavailable || ($rootScope.expertUnavailable && !globalMessageService.isActive())) {
				if ($rootScope.expertUnavailable &&
					!globalMessageService.isActive()) {
					expertAvailability.onNext(isUnavailable);
				} else {
					$rootScope.expertUnavailable = isUnavailable;
					expertAvailability.onNext(isUnavailable);
					// TODO: GET RID OF BROADCAST
					$rootScope.$broadcast(appConfig.broadcast.expertAvailabilityChange,
						{ expertUnavailable: isUnavailable });
				}
			}
		}
	}

}());
