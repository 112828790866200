(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('ReturnRequestsCtrl', ReturnRequestsController);

	/**
	 * @ngInject
	 */

	function ReturnRequestsController($scope, appConfig, logisticsService, $log, documentService, appI18n) {

		$log = $log.getInstance("ReturnRequestsCtrl", "color:blue");
		$log.debug("loaded");

		var vm = this;

		vm.returnRequestsOptions = {
			sortOrders: [{
				fieldName: 'postingDateTime',
				reverseOrder: true
			}],
			gridRowActions: [
				{
					displayName: 'Reports',
					iconCssClass: 'es-icon es-icon-reports',
					enabledIf: (row) => {
						return row.documentId;
					},
					subItemsFactory: (row) => {
						if (row.returnRequestReport) {
							return row.returnRequestReport;
						}
						const returnRequestReport = [];

						returnRequestReport.push({
							displayName: row.esmsOrderIdText,
							iconFileType: 'pdf',
							action: () => {
								documentService.getReturnRequestReport(row.documentId, row.orderId)
									.then(function(response) {
										documentService.downloadReport(
											response.data, $scope,
											appConfig.downloadDocumentType.returnRequest,
											1, row.esmsOrderIdText, 'pdf');
									});
							}
						});

						row.returnRequestReport = returnRequestReport;

						return returnRequestReport;
					}
				}
			],

			getData: getReturnRequestList

		};

		function getReturnRequestList(options) {
			return logisticsService.getReturnRequestList(options);
		}

		function activate() {

			appI18n.translate([
				"logistics.return-requests.title",
				"logistics.return-requests.postingdate",
				"logistics.return-requests.order#",
				"logistics.return-requests.status",
				"logistics.return-requests.courier",
				"logistics.return-requests.country",
				"logistics.return-requests.investigator",
				"logistics.return-requests.site#"
				])
			.then(function (translations) {
            	logisticsService.setTitle(translations["logistics.return-requests.title"]);
            	vm.postingTitle = translations["logistics.return-requests.postingdate"];
            	vm.orderTitle = translations["logistics.return-requests.order#"];
            	vm.statusTitle = translations["logistics.return-requests.status"];
            	vm.courierTitle = translations["logistics.return-requests.courier"];
            	vm.countryTitle = translations["logistics.return-requests.country"];
            	vm.investigatorTitle = translations["logistics.return-requests.investigator"];
            	vm.siteTitle = translations["logistics.return-requests.site#"];

				logisticsService.getConfig().then((response) => {
					vm.config = response;
					vm.returnRequestsOptions.columnDefs = [
						{displayName: vm.orderTitle, fieldName: 'esmsOrderIdText', isSortable: true, type: 'string', isDefaultFilter: true},
						{displayName: vm.postingTitle, fieldName: 'postingDateTime', isSortable: true, type: 'date', formatter: 'dateTimeFormatUtc'},
						{displayName: vm.courierTitle, fieldName: 'courier', isSortable: true, type: 'string'},
						{displayName: vm.countryTitle, fieldName: 'country', isSortable: true, type: 'string'},
						{displayName: vm.investigatorTitle, fieldName: 'investigatorName', isSortable: true, type: 'string'},
						{displayName: vm.siteTitle, fieldName: 'sponsorSiteId', isSortable: true, type: 'string'}
					];

					const expertStatusColumns = [
						{
							displayName: vm.statusTitle, fieldName: 'status', isSortable: true, type: 'enum',
							enumType: 'string',
							enums: [
								{ displayName: 'First Recall', values: ['First Recall'] },
								{ displayName: 'Second Recall', values: ['Second Recall'] }
							]
						}
					];

					const ebsStatusColumns = [
						{
							displayName: vm.statusTitle, fieldName: 'status', isSortable: true, type: 'enum',
							enumType: 'string',
							enums: [
								{ displayName: 'First Recall', values: ['First Recall'] },
								{ displayName: 'Second Recall', values: ['Second Recall'] },
								{ displayName: 'Escalated', values: ['Escalated'] }
							]
						}
					];

					if (vm.config.useExpert) {
						vm.returnRequestsOptions.columnDefs.push(...expertStatusColumns);
					} else {
						vm.returnRequestsOptions.columnDefs.push(...ebsStatusColumns);
					}
				});

			});

			vm.load = true;
		}

		activate();

	}

})();
