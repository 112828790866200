class PaginationController {
	constructor($log, appConfig) {
		'ngInject';
		this.$log = $log;
		this.dataStore = [];
		this.currentPageNr = 1;
		this.totalPagesLoaded = 0;
		this.pageSize = this.pageSize || appConfig.defaultPaginationPageSize;
		this.maxItemsPerLoad = appConfig.oDataQueryMaxItemsNumber;
		this.loadIndex = 1;
		this.pagesBeforeEnd = 2;
	}

	$onInit() {
		this.$log = this.$log.getInstance('PaginationController');
		this.$log.debug('loaded');

		this.api = {
			/*
			* Set more data after initial load
			* */
			setMoreData: (data = []) => {
				this.loadIndex += 1;
				this.dataStore = this.dataStore.concat(data);
				this.$log.debug(`Total loaded entries: ${this.dataStore.length}`);
				return this.paginateData(this.dataStore);
			},
			/*
			* Reload with pagination with provided data
			* */
			reloadData: (data = []) => {
				this.dataStore = data;
				this.$log.debug(`Total loaded entries: ${this.dataStore.length}`);
				return this.paginateData(this.dataStore);
			}
		};

		this.componentReady && this.componentReady({ $API: this.api });
	}

	$onChanges(changesObj) {
		if (changesObj.initData && !changesObj.initData.isFirstChange()) {
			const data = changesObj.initData.currentValue || [];

			this.dataStore = data; //init data store
			this.currentPageNr = 1; // reset page number
			this.loadIndex = 1; // reset load index
			this.$log.debug(`Total loaded entries: ${this.dataStore.length}`);

			const pageData = this.paginateData(data); // split data into pages

			this.onPagination({ pageData }); // fire pagination component callback that returns paginated data
		}
	}

	paginateData(data) {
		const begin = ((this.currentPageNr - 1) * this.pageSize),
			end = begin + this.pageSize,
			page = data.slice(begin, end);

		this.totalPagesLoaded = Math.ceil(data.length / this.pageSize); // sets number of available pages
		this.$log.debug(`Pages: ${this.currentPageNr} / ${this.totalPagesLoaded}`);
		return page;
	}

	onNext() {
		this.currentPageNr++;
		if (this.checkNeedPreload()) {
			const query = this.buildPreloadQuery();

			this.loadMore(query);
		}
		const data = this.paginateData(this.dataStore);

		this.onPagination({ pageData: data });
	}

	onPrevious() {
		this.currentPageNr--;
		const data = this.paginateData(this.dataStore);

		this.onPagination({ pageData: data });
	}

	refresh() {
		const skip = 0;
		const top = this.maxItemsPerLoad * this.loadIndex;

		const uri = `$skip=${skip}&$top=${top}`;

		this.isRefreshing = true;
		// lets grid provide combined uri (filtering, sorting + pagination)
		this.grid && this.grid.onPaginationReload(uri).then((data) => {
			this.api.reloadData(data);
		}).finally(() => {
			this.isRefreshing = false;
		});
	}
	/*
	* Fires grid callback and its own
	* */
	loadMore(uri) {
		this.isLoadingMore = true;
		// lets grid provide combined uri (filtering, sorting + pagination)
		this.grid && this.grid.onPaginationLoad(uri).then((data) => {
			this.api.setMoreData(data);
		}).finally(() => {
			this.isLoadingMore = false;
		});
		this.onMoreDataLoad({ uri }); // fires it own callback with ONLY pagination query string
	}
	/*
	* Builds query string to load more data
	* */
	buildPreloadQuery() {
		const skip = this.maxItemsPerLoad * this.loadIndex;
		const top = this.maxItemsPerLoad;

		return `$skip=${skip}&$top=${top}`;
	}

	/*
	* Checks if it is time to load more data for pagination
	* */
	checkNeedPreload() {
		return (this.maxItemsPerLoad * this.loadIndex === this.dataStore.length) &&
			(this.totalPagesLoaded - this.currentPageNr < this.pagesBeforeEnd);
	}
}

export default PaginationController;
