/*
 * numberRangeComponent
 * */

import templateUrl from './numberRange.html';
import controller from './numberRange.controller';
import './numberRange.scss';

const numberRangeComponent = {
	bindings: {
		model: '<',
		rangeChanged: '&',
		onEnter: '&?'
	},
	templateUrl,
	controller
};

export default numberRangeComponent;
