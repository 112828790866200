/*
* Shows loading icon
*
* */

import templateUrl from './loadingIcon.html';
import controller from './loadingIcon.controller';
import './loadingIcon.scss';

const loadingIndicatorIcon = {
	bindings: {
		show: '<?'
	},
	templateUrl,
	controller
};

export default loadingIndicatorIcon;

