(function () {

	'use strict';

	angular.module('portalApp').controller('SiteAdministratorsCtrl', Controller);

	/**
	 * @ngInject
	 */
	function Controller($scope, $log, $q, studyPermissionsService, studyPermissionsTrans) {

		$log = $log.getInstance('SiteAdministratorsCtrl', 'color:purple');
		$log.debug('SiteAdministratorsCtrl:: loaded');

		const vm = this;

		vm.dataLoading = true;
		vm.loadingError = '';
		vm.model = {};
		vm.options = {};
		vm.errorMsg = '';
		vm.localized = {
			gridColumns: {},
			list: {}
		};

		$scope.$parent.loadGridData = loadGridData;

		vm.confirmHandler = {
			showDialog: false
		};
		vm.gridOptions = {
			rowDefs: {},
			sortOrders: [
				{
					fieldName: 'fullName',
					reverseOrder: false
				}
			],
			gridActions: [],

			getData: (options) => {
				return $q((resolve, reject) => {
					loadData(options, resolve, reject);
				});
			}
		};
		activate();

		function activate() {
			studyPermissionsTrans.getListViewLocalized().then((localized) => {
				vm.localized = localized;

				vm.gridOptions.columnDefs = [
					{
						displayName: localized.gridColumns.userName,
						fieldName: 'fullName',
						type: 'string',
						isDefaultFilter: true
					},
					{
						displayName: localized.gridColumns.userEmail,
						fieldName: 'personEmail',
						type: 'string'
					},
					{
						displayName: localized.gridColumns.accountCreated,
						fieldName: 'userCreatedDate',
						type: 'date',
						formatter: 'dateTimeFormatNA'
					},
					{
						displayName: localized.gridColumns.sitePI,
						fieldName: 'siteInvestigatorCountry',
						isSortable: true,
						type: 'string',
						formatter: 'pipe'
					},
					{
						displayName: localized.gridColumns.lastLogon,
						fieldName: 'lastLoginDate',
						type: 'date',
						formatter: 'dateTimeFormat'
					},
					{
						displayName: localized.gridColumns.portalAccount,
						fieldName: 'isPortalAccountEnabled',
						type: 'enum',
						formatter: 'yesNo',
						enumType: 'bool',
						enums: [{
							translationKey: 'app.common.yes',
							values: [1]
						}, {
							translationKey: 'app.common.no',
							values: [-1]
						}]
					}
				];

				vm.gridOptions.gridRowActions = [];
				loadGridData();
			});
		}

		function loadGridData(/*reload*/) {
			vm.load = true;
		}

		function loadData(options, resolve, reject) {
			vm.dataLoading = true;
			vm.loadingError = '';
			studyPermissionsService.getSiteAdministrators(options).then((response) => {
				vm.gridOptions.gridRowActions = [];

				vm.model = response.data.model;
				vm.options.config = response.data.config;
				if (vm.options.config.requestUserRoleRemoval === true) {
					vm.gridOptions.gridRowActions.push(
						{
							displayName: vm.localized.buttons.removeUser,
							action(row) {
								studyPermissionsService.goToSiteAdminDelete(row.countryId, row.siteId, row.userId);
							},
							iconCssClass: 'es-icon-function-delete'
						});
				}
				if (vm.options.config.editUser === true) {
					vm.gridOptions.gridRowActions.push(
						{
							displayName: vm.localized.buttons.editUser,
							action(row) {
								studyPermissionsService.goToSiteAdminEdit(row.countryId, row.siteId, row.userId);
							},
							iconCssClass: 'es-icon-user-name'
						}
					);
				}
				vm.dataLoading = false;
				resolve(vm.model);
			}, (error) => {
				vm.loadingError = error.data.message || error.data;
				reject(vm.loadingError);
			});
		}
	}
})();
