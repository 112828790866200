(function () {

	'use strict';

	angular
		.module('portalApp')
		.service('remoteFileService', RemoteFileService);

	/**
	 * @ngInject
	 */
	function RemoteFileService(appConfig, $http, $log, appSession, $window, $timeout, previewPopupService) {

		$log = $log.getInstance('RemoteFileService', 'color:blue');
		$log.debug('loaded');

		var service = {
			openFile: openFile,
			getPostFileUrl: getPostFileUrl,
			openDcrFile: openDcrFile,
			getDcrPostFileUrl: getDcrPostFileUrl,
			deleteDcrFile: deleteDcrFile,
			getDocumentManagementPostFileUrl,
			getDataManagementFiles,
			deleteDataManagementFile,
			getDataManagementDownloadFileUrl
		};



		return service;

		function log(name, url) {
			$log.debug(`RemoteFileService.${name} with url: ${url}`);
		}

		function openFile(path, reportName, isInline, boxes, changeCallback) {
			openFileInternal(path, reportName, isInline, appConfig.apiUrls.remoteFile.getFile, boxes, changeCallback);
		}

		function getPostFileUrl() {
			var token = appSession.getSessionToken();
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.remoteFile.getPostFileUrl.supplant(
				{ 'studyId': study.id, 'studyModeId': study.studyModeId, 'token': token });
			return url;
		}

		function openDcrFile(path, reportName, isInline, boxes, changeCallback) {
			openFileInternal(path, reportName, isInline, appConfig.apiUrls.remoteFile.getDcrFile, boxes, changeCallback);
		}

		function getDcrPostFileUrl() {
			var token = appSession.getSessionToken();
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.remoteFile.getDcrPostFileUrl.supplant(
				{ 'studyId': study.id, 'studyModeId': study.studyModeId, 'token': token });
			return url;

		}

		function deleteFileCommon(path, type) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.remoteFile.removeFileCommon.supplant(
				{ 'studyId': study.id, 'studyModeId': study.studyModeId, 'url': path, type: type });

			log('deleteFileCommon', url);
			return $http.delete(url);
		}

		function deleteFilesCommon(paths, type) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.remoteFile.removeFilesCommon.supplant(
				{ 'studyId': study.id, 'studyModeId': study.studyModeId, type: type });
			var model = {
				urls: paths
			};

			var data = JSON.stringify(model);
			return $http.post(url, data);
		}

		function getPostFileUrlCommon(type) {
			var token = appSession.getSessionToken();
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.remoteFile.getPostFileUrlCommon.supplant(
				{ 'studyId': study.id, 'studyModeId': study.studyModeId, 'token': token, 'type': type });
			return url;
		}

		function getFileExtension(filename) {
			return filename.split('.').pop().toLowerCase();
		}

		function isImage(fileExtension) {
			return ["png", "jpg", "jpeg", "gif"].indexOf(fileExtension) >= 0;
		}

		function isPdf(fileExtension) {
			return fileExtension === "pdf";
		}

		function isPreviewSupported(filename) {
			var fileExtension = getFileExtension(filename);
			return isImage(fileExtension) || isPdf(fileExtension);
		}

		function openFileInternal(path, reportName, isInline, apiUrl, boxes, changeCallback) {
			var url = getFile(path, reportName, isInline, apiUrl, undefined);

			if (isPreviewSupported(reportName) && changeCallback != null) {
				previewPopupService.show({
					changeCallback,
					url,
					filename: reportName,
					boxes
				});
			} else {
				if (isInline) {
					var open = $window.open(url, '_preview', 'width=1000&height=800');
					if (!open) {
						$window.location.assign(url);
					}
				} else {
					$window.location.assign(url);
				}
			}
		}

		function getFile(path, reportName, isInline, apiUrl, type) {

			var study = appSession.getStudy();
			path = encodeURIComponent(path);
			reportName = encodeURIComponent(reportName);
			var url = apiUrl.supplant({
				studyId: study.id,
				studyModeId: study.studyModeId,
				'url': path,
				'reportName': reportName,
				'isInline': isInline ? 'true' : 'false',
				'type': type
			});

			url += '&token=' + appSession.getSessionToken() + '&timeStamp=' + new Date().getTime();//prevent cache
			log('getFile', url);
			return url;
		}

		function deleteDcrFile(model) {

			var study = appSession.getStudy();
			var url = appConfig.apiUrls.remoteFile.removeDcrFileUrl.supplant(
				{ 'studyId': study.id, 'studyModeId': study.studyModeId });

			log('deleteDcrFile', model);
			var data = JSON.stringify(model);
			return $http.post(url, data);
		}

		function dataManagementFilesFormatter(data) {
			return data.map((f) => {
				return {
					fileName: f.fileName,
					created: f.created,
					fileSize: f.fileSize,
					fileId: f.fileId
				};
			});
		}

		function getDocumentManagementPostFileUrl() {
			const study = appSession.getStudy();

			return appConfig.apiUrls.downloadFiles.postFileUrl.supplant({
				studyId: study.id,
				studyModeId: study.studyModeId
			});
		}

		function getDataManagementFiles(oDataQuery) {
			const token = appSession.getSessionToken(),
				study = appSession.getStudy(),
				url = appConfig.apiUrls.downloadFiles.getAllFilesUrl.supplant({
					studyId: study.id,
					studyModeId: study.studyModeId,
					'oDataQuery': (oDataQuery || '')
				});

			log('getDataManagementFiles', url);
			return $http.get(url, {
				headers: { Authorization: `Bearer ${this.token}` }
			}).then((resp) => {
				return dataManagementFilesFormatter(resp.data);
			});
		}

		function deleteDataManagementFile(fileId) {
			const token = appSession.getSessionToken(),
				study = appSession.getStudy(),
				url = appConfig.apiUrls.downloadFiles.deleteFileUrl.supplant({
					id: fileId,
					studyId: study.id,
					studyModeId: study.studyModeId
				});

			log('deleteDataManagementFile', url);
			return $http.delete(url, {
				headers: { Authorization: `Bearer ${this.token}` }
			});
		}

		function getDataManagementDownloadFileUrl(fileId) {
			const token = appSession.getSessionToken(),
				study = appSession.getStudy(),
				url = appConfig.apiUrls.downloadFiles.getSingleFileUrl.supplant({
					id: fileId,
					studyId: study.id,
					studyModeId: study.studyModeId,
					token
				});

			log('getSingleDataManagementFile', url);
			return url;
		}
	}

})();
