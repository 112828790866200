const loadingIndicatorService = ($log) => {
	'ngInject';

	const log = $log.getInstance('loadingIndicatorService', 'color:red');

	log.debug('loaded');

	const spinners = {};
	const deferredShow = {};

	function register(data) {
		if (!data.hasOwnProperty('name')) {
			log.error('Loading indicator must specify a name when registering with the spinner service.');
			return;
		}

		if (spinners[data.name]) {
			log.error(`The loading indicator with name ${data.name} is already registered 
				in the loading indicator service.`);
			return;
		}
		spinners[data.name] = data;

		if (deferredShow[data.name]) {
			log.debug(`deferred show for ${data.name}.`);
			this.show(data.name);
			delete deferredShow[data.name];
		}
	}

	function unregister(name) {
		if (spinners.hasOwnProperty(name)) {
			delete spinners[name];
		}
	}

	function unregisterGroup(group) {
		for (const name in spinners) {
			if (spinners[name].group === group) {
				delete spinners[name];
			}
		}
	}

	function unregisterAll() {
		for (const name in spinners) {
			delete spinners[name];
		}
	}

	return {
		/* private, don't use directly */
		register,
		unregister,
		unregisterGroup,
		unregisterAll,
		/* private, don't use directly (end) */

		show(name = 'global') {
			log.debug('show');

			const spinner = spinners[name];

			if (!spinner) {
				log.debug(`No loading indicator named ${name} is registered.`);
				if (name === 'global') {
					deferredShow[name] = true;
				}
				return;
			}
			spinner.show();
		},
		hide(name = 'global') {
			log.debug('hide');
			const spinner = spinners[name];

			if (!spinner) {
				log.debug(`No loading indicator named ${name}  is registered.`);
				delete deferredShow[name];
				return;
			}
			spinner.hide();
		},
		showGroup(group) {
			let groupExists = false;

			for (const name in spinners) {
				const spinner = spinners[name];

				if (spinner.group === group) {
					spinner.show();
					groupExists = true;
				}
			}
			if (!groupExists) {
				log.error(`No loading indicators found with group ${group}.`);
				return;
			}
		},
		hideGroup(group) {
			let groupExists = false;

			for (const name in spinners) {
				const spinner = spinners[name];

				if (spinner.group === group) {
					spinner.hide();
					groupExists = true;
				}
			}
			if (!groupExists) {
				log.error(`No loading indicators found with group ${group}.`);
				return;
			}
		},
		showAll() {
			for (const name in spinners) {
				spinners[name].show();
			}
		},
		hideAll() {
			for (const name in spinners) {
				spinners[name].hide();
			}
		}
	};
};

export default loadingIndicatorService;

