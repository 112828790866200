(function () {
	'use strict';

	angular
		.module('portalApp')
		.service('kardiaService', KardiaService);

	/*
	 * @ngInject
	 */
	function KardiaService($log, $http, appSession, appConfig) {

		return {
			getRegistrationCode,
			getCreateSubjectData,
			createSubject,
			closeDevice,
			getDispenseSubjectData,
			getSubjects,
			getSitesMinimal,
			getActions,
			getStudyActivities
		};

		function getStudyActivities() {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.kardia.studyActivities
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
				  });

			return $http.get(url);
		}

		function getActions(countryId, siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.kardia.actions
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  countryId,
					  siteId,
					  subjectId
				  });

			return $http.get(url);
		}

		function closeDevice(countryId, siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.kardia.close
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  countryId,
					  siteId,
					  subjectId
				  });

			return $http.post(url);
		}

		function getSubjects(countryId, siteId, actionId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.kardia.subjects
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  countryId,
					  siteId: siteId || '',
					  actionId
				  });

			return $http.get(url);
		}

		function getSitesMinimal(studyObj, countryId) {
			let currentStudy = {};

			if (studyObj && studyObj.id && studyObj.studyModeId !== null) {
				currentStudy = studyObj;
			} else {
				currentStudy = appSession.getStudy();
			}
			const url = appConfig.apiUrls.clinicalData.kardia.sitesMinimal.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'countryId': countryId || ''
			});

			$log.debug('clinicalDataService.getSites with url: {url}', {
				url
			});
			return $http.get(url, {
				cache: true,
				params: {
					cacheContext: 'clinicalData'
				}
			});
		}

		function getRegistrationCode(siteId, subjectId, actionId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.kardia.registrationCode
				  .supplant({
					  'studyId': currentStudy.id,
					  'studyModeId': currentStudy.studyModeId,
					  siteId,
					  subjectId,
					  actionId
				  });

			return $http.get(url);
		}

		function getCreateSubjectData(countryId, siteId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.kardia.createSubject.data.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'countryId': countryId || '0',
				'siteId': siteId || '0'
			});

			return $http.get(url);
		}

		function createSubject(subject) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.kardia.createSubject.create.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId
			});

			return $http.post(url, subject);
		}


		function getDispenseSubjectData(countryId, siteId, subjectId) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.clinicalData.kardia.editSubject.dispense.supplant({
				'studyId': currentStudy.id,
				'studyModeId': currentStudy.studyModeId,
				'countryId': countryId || '0',
				'siteId': siteId || '0',
				subjectId
			});

			return $http.get(url);
		}
	}
})();
