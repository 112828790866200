/*
 * tableExportService
 * */

export default class tableExportService {

	static id() {
		return 'tableExportService';
	}

	constructor($log, documentService, appSession, $http) {
		'ngInject';
		this.$log = $log.getInstance('tableExportService', 'color:green');
		this.$http = $http;
		this.documentService = documentService;
		this.appSession = appSession;
	}

	doExport(scope, url, headers, parameters, format) {
		const currentStudy = this.appSession.getStudy();

		if (!parameters) {
			parameters = {};
		} else {
			for (const key in parameters) {
				if (parameters.hasOwnProperty(key)) {
					parameters[key] = parameters[key] || '';
				}

			}
		}
		parameters.format = format;
		parameters.studyId = currentStudy.id;
		parameters.studyModeId = currentStudy.studyModeId;

		const requestUrl = url.supplant(parameters);
		const columns = _.map(headers, (column) => {
			return {
				caption: column.displayName,
				name: column.fieldName
			};
		});
		const data = {
			columns,
			format
		};

		this.$http.post(requestUrl, data)
			.then((response) => {
				this.documentService.downloadReport(
					response.data, scope, 'Grid', 1, 'export', format);
			});
	}
}
