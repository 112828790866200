class EcgViewerController {
	constructor(
		$log,
		$stateParams,
		appSession,
		appConfig,
		$sce
	) {
		'ngInject';
		this.$log = $log;
		this.$stateParams = $stateParams;
		this.appSession = appSession;
		this.appConfig = appConfig;
		this.$sce = $sce;
	}

	$onInit() {
		this.$log = this.$log.getInstance('EcgViewerController');
		this.$log.debug('loaded');

		this.siteId = this.$stateParams.site;
		this.subjectId = this.$stateParams.subject;
		this.eventId = this.$stateParams.event;
		this.testDataTypeId = this.$stateParams.testDataType;
		this.visitName = this.$stateParams.visit;
		this.ecgViewerUrl = this.getEcgViewerUrl(this.siteId, this.subjectId, this.eventId);
	}
	getEcgViewerUrl(siteId, subjectId, eventId) {
		const currentStudy = this.appSession.getStudy();
		const token = this.appSession.getSessionToken();
		const ecgViewerUrl = this.appConfig.external.ecgViewerUrl;
		// eslint-disable-next-line max-len
		const url = `${ecgViewerUrl}?study=${currentStudy.id}&mode=${currentStudy.studyModeId}&site=${siteId}&subject=${subjectId}&event=${eventId}&si=msp3&jwt=${token}`;

		this.$log.debug(`ecgViewUrl=${url}`);
		//test url
		//url = 'https://epf-ecgviewer-02.k8s.int.ert.com/#/ecgViewer?study=21237&mode=0&site=1323359&subject=468216&event=3529905&si=msp3&jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2dzc28tMDEuZGV2LmVydC5jb20vaWRwL29hdXRoMiIsImlhdCI6MTUyNzQ5OTU3NCwiZXhwIjoxNzAwMjk5NTc0LCJzdWIiOiI3OTgwMDUwOC0yYmZhLTQyMWYtYjU2OC03NWVmZTAxNzAzZjQiLCJuYW1lIjoiZmFrZSIsImZhbWlseV9uYW1lIjoiQmxhc2Noa2UiLCJnaXZlbl9uYW1lIjoiTWFydGluIiwic3RhdHVzIjoiQWN0aXZlIiwiZW1haWwiOiJtYXJ0aW4uYmxhc2Noa2VAZXJ0LmNvbSIsImF1ZCI6Im1zcDMifQ.OmCiBguo3nobyRYe9eKIybYgMCrRi6dkgiaaj5bUfik';
		return this.$sce.trustAsResourceUrl(url);
	}

}

export default EcgViewerController;
