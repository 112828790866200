/*
 * openDcrWidgetController
 * */

import { CELL_DATA_TYPES } from '../../../common/table/tableWrap/cellDataType';

class OpenDcrWidgetController {
	constructor($log, dashboardService, $filter, $state, appConfig, appI18n, $timeout) {
		'ngInject';
		this.$log = $log;
		this.$log.debug('OpenDcrWidgetConstructor');
		this.dashboardService = dashboardService;
		this.$filter = $filter;
		this.$state = $state;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.$timeout = $timeout;
		this.expertAvailable = angular.isUndefined(this.expertAvailable) || this.expertAvailable;
	}


	$onInit() {
		this.$log = this.$log.getInstance('OpenDcrWidgetController');
		this.$log.debug('loaded');
		this.getDCRQueryData();
		this.headers = this.getOpenDcrTableHeaders();
	}

	$onChanges(changesObj) {
		if (changesObj.expertAvailable && changesObj.expertAvailable.currentValue) {
			this.widgetControls = this.getWidgetControls();
		} else {
			this.widgetControls = [];
		}
	}

	$onDestroy() {
		this.dataStream.dispose();
	}

	getOpenDcrTableHeaders() {
		return [{
			value: 'dashboard.open-dcr.site',
			type: CELL_DATA_TYPES.TEXT
		}, {
			value: 'dashboard.open-dcr.pi',
			type: CELL_DATA_TYPES.TEXT
		}, {
			value: 'dashboard.open-dcr.dcrs',
			type: CELL_DATA_TYPES.NUMBER
		}, {
			value: 'dashboard.open-dcr.queries',
			type: CELL_DATA_TYPES.NUMBER
		}];
	}

	getWidgetControls() {
		return [{
			type: 'button',
			cssClass: '',
			disabled: !this.expertAvailable,
			displayName: 'dashboard.open-dcr.action',
			action: () => {
				if (!this.expertAvailable) {
					//TODO: Show notification MPORT-3604
					return;
				}
				this.showOpenDCRDetails();
			}
		}];
	}

	showOpenDCRDetails() {
		this.$state.go(this.appConfig.routes.tickets);
	}

	getDCRQueryData() {
		const self = this;

		this.isLoading = true;
		this.dataStream = this.dashboardService.getDcrMetricsStream().subscribe((data) => {
			self.metrics = data;
			self.metrics.dcrSiteCtr = 0;
			self.metrics.querySiteCtr = 0;

			angular.forEach(self.metrics.tickets, (ticket) => {
				ticket.totalDcrQuery = parseInt(ticket.numberOfDcr) + parseInt(ticket.numberOfQry);
				if (self.openItemsThreshold && ticket.totalDcrQuery > parseInt(self.openItemsThreshold)) {
					self.metrics.dcrSiteCtr += 1;
				}
				if (self.openQueryThreshold && ticket.numberOfTicketsSentToSite > parseInt(self.openQueryThreshold)) {
					self.metrics.querySiteCtr += 1;
				}
			});

			if (self.openItemsThreshold && self.metrics.dcrSiteCtr > 0) {
				const msg = (self.metrics.ispi === true && self.metrics.totalSites === 1)
					? self.appI18n.translateImmediate('dashboard.open-dcr.pi-site-warning')
					: self.appI18n.translateImmediate('dashboard.open-dcr.site-warning');

				self.dcrWarningMessage = msg.supplant({
					'siteCtr': self.metrics.dcrSiteCtr,
					'threshold': self.openItemsThreshold
				});
			}

			if (self.openQueryThreshold && self.metrics.querySiteCtr > 0) {
				const msg = (self.metrics.ispi === true && self.metrics.totalSites === 1
					? self.appI18n.translateImmediate('dashboard.open-dcr.query-pi-site-warning')
					: self.appI18n.translateImmediate('dashboard.open-dcr.query-site-warning'));

				self.queryWarningMessage = msg.supplant({
					'siteCtr': self.metrics.querySiteCtr,
					'threshold': self.openQueryThreshold
				});
			}
			self.metrics.topDcr = self.getTopItems(self.metrics.tickets, 5, ['-totalDcrQuery', 'sponsorSiteId'],
				'totalDcrQuery');
			self.metrics.topQuery = self.getTopItems(self.metrics.tickets, 5, ['-numberOfTicketsSentToSite',
				'sponsorSiteId'], 'numberOfTicketsSentToSite');
			this.$timeout(() => {
				self.isLoading = false;
			});
		}, (error) => {
			self.isLoading = false;
			self.dcrErrorMessage = self.appI18n.translateImmediate('dashboard.loading-error');
			self.$log.error(`error in getting dashboard DCR query data: ${error}`);
		});
	}

	getTopItems(source, count, sortOrder, filterStr) {
		const filtered = [];

		angular.forEach(source, (item /*, key*/) => {
			if (item[filterStr] > 0) {
				filtered.push(item);
			}
		});

		const sorted = this.$filter('orderBy')(filtered, sortOrder);
		const limited = this.$filter('limitTo')(sorted, count, 0);

		return limited;
	}
}

export default OpenDcrWidgetController;
