/**
 * Based on Easy to use Wizard library for Angular JS
 * @version v0.10.0 - 2016-12-22 * @link https://github.com/mgonto/angular-wizard
 *
 * Basic Usage
 * <ert-ng-wizard on-finish='finishedWizard()' indicators-position='top'>
 *     <ert-wz-step wz-title='Starting'>
 *         <input type='submit' wz-next value='Continue' />
 *     </ert-wz-step>
 *     <ert-wz-step wz-title='Continuing'>
 *          <h1>Continuing</h1>
 *          <p>You have continued here!</p>
 *          <input type='submit' ert-wz-next value='Go on'/>
 *      </ert-wz-step>
 *      <ert-wz-step wz-title='More steps'>
 *          <p>Even more steps!!</p>
 *          <input type='submit' ert-wz-next value='Finish now'/>
 *      </ert-wz-step>
 * </ert-ng-wizard>
 */

/*eslint-disable indent*/
//TODO: remove this later

import stepTplUrl from './ertWizardStep.html';
import wizardTplUrl from './ertWizard.html';
import './ertWizard.scss';

angular.module('app.ertNgWizard', []);

angular.module('app.ertNgWizard').directive('ertWzStep', () => {
    return {
        replace: true,
        transclude: true,
        scope: {
            wzTitle: '@',
            canenter: '=',
            canexit: '=',
            disabled: '@?wzDisabled',
            description: '@',
            wzData: '=',
            wzOrder: '@?'
        },
        require: '^ertNgWizard',
        templateUrl: (element, attributes) => {
            return attributes.template || stepTplUrl;
        },
        link: ($scope, $element, $attrs, ertNgWizard) => {
            $attrs.$observe('wzTitle', () => {
                $scope.title = $scope.wzTitle;
            });
            $scope.title = $scope.wzTitle;
            ertNgWizard.addStep($scope);
            $scope.$on('$destroy', () => {
                ertNgWizard.removeStep($scope);
            });
        }
    };
});

//wizard directive
angular.module('app.ertNgWizard').directive('ertNgWizard', () => {
    return {
        transclude: true,
        scope: {
            currentStep: '=',
            onFinish: '&',
            hideIndicators: '=',
            editMode: '=',
            name: '@',
            indicatorsPosition: '@?'
        },
        templateUrl: (element, attributes) => {
            return attributes.template || wizardTplUrl;
        },

        //controller for wizard directive, treat this just like an angular controller
        controller: ['$scope', '$element', '$log', 'ErtNgWizardService', '$q', '$timeout',
            function ($scope, $element, $log, ErtNgWizardService, $q, $timeout) {
            //setting default step position if none declared.
            if ($scope.indicatorsPosition) {
                $scope.indicatorsPosition = 'bottom';
            }
            //this variable allows directive to load without having to pass any step validation
            let firstRun = true;

            //creating instance of wizard, passing this as second argument allows access to functions
            // attached to this via Service
            ErtNgWizardService.addWizard($scope.name || ErtNgWizardService.defaultName, this);

            $scope.$on('$destroy', () => {
                ErtNgWizardService.removeWizard($scope.name || ErtNgWizardService.defaultName);
            });

            //steps array where all the scopes of each step are added
            $scope.steps = [];

            const stepIdx = (step) => {
                let idx = 0;
                let res = -1;

                angular.forEach($scope.getEnabledSteps(), (currStep) => {
                    if (currStep === step) {
                        res = idx;
                    }
                    idx++;
                });
                return res;
            };

            const stepByTitle = (titleToFind) => {
                let foundStep = null;

                angular.forEach($scope.getEnabledSteps(), (step) => {
                    if (step.wzTitle === titleToFind) {
                        foundStep = step;
                    }
                });
                return foundStep;
            };

            //access to context object for step validation
            $scope.context = {};

            //watching changes to currentStep
            $scope.$watch('currentStep', (step) => {
                //checking to make sure currentStep is truthy value
                if (!step) { return; }
                //setting stepTitle equal to current step title or default title
                const stepTitle = $scope.selectedStep.wzTitle;

                if ($scope.selectedStep && stepTitle !== $scope.currentStep) {
                    //invoking goTo() with step title as argument
                    $scope.goTo(stepByTitle($scope.currentStep));
                }
            });

            //watching steps array length and editMode value, if edit module is undefined or null the nothing is done
            //if edit mode is truthy, then all steps are marked as completed
            $scope.$watch('[editMode, steps.length]', () => {
                const editMode = $scope.editMode;

                if (angular.isUndefined(editMode) || (editMode === null)) { return; }

                //Set completed for all steps to the value of editMode
                angular.forEach($scope.steps, (step) => {
                    step.completed = editMode;
                });

                //If editMode is false, set ONLY ENABLED steps with index lower then completedIndex to completed
                if (!editMode) {
                    const completedStepsIndex = $scope.currentStepNumber() - 1;

                    angular.forEach($scope.getEnabledSteps(), (step, stepIndex) => {
                        if (stepIndex < completedStepsIndex) {
                            step.completed = true;
                        }
                    });
                }
            }, true);

            //called each time step directive is loaded
            this.addStep = (step) => {
                const wzOrder = (step.wzOrder >= 0 && !$scope.steps[step.wzOrder]) ? step.wzOrder : $scope.steps.length;

                //adding the scope of directive onto step array
                $scope.steps[wzOrder] = step;
                //if this step is the new first then goTo it
                if ($scope.getEnabledSteps()[0] === step) {
                    //goTo first step
                    $scope.goTo($scope.getEnabledSteps()[0]);
                }
            };

            //called each time step directive is destroyed
            this.removeStep = (step) => {
                const index = $scope.steps.indexOf(step);

                if (index > 0) {
                    $scope.steps.splice(index, 1);
                }
            };

            this.context = $scope.context;

            $scope.getStepNumber = (step) => {
                return stepIdx(step) + 1;
            };

            $scope.goTo = (step) => {
                //if this is the first time the wizard is loading it bi-passes step validation
                if (firstRun) {
                    //deselect all steps so you can set fresh below
                    unselectAll();
                    $scope.selectedStep = step;
                    //making sure current step is not undefined
                    if (angular.isDefined($scope.currentStep)) {
                        $scope.currentStep = step.wzTitle;
                    }
                    //setting selected step to argument passed into goTo()
                    step.selected = true;
                    //emit event upwards with data on goTo() invoktion
                    $scope.$emit('ertNgWizard:stepChanged', { step, index: stepIdx(step) });
                    //setting variable to false so all other step changes must pass validation
                    firstRun = false;
                } else {
                    //creating variables to capture current state that goTo() was invoked from and allow booleans
                    let thisStep;

                    //getting data for step you are transitioning out of
                    if ($scope.currentStepNumber() > 0) {
                        thisStep = $scope.currentStepNumber() - 1;
                    } else if ($scope.currentStepNumber() === 0) {
                        thisStep = 0;
                    }
                    //$log.log('steps[thisStep] Data: ', $scope.getEnabledSteps()[thisStep].canexit);
                    $q.all([canExitStep($scope.getEnabledSteps()[thisStep], step), canEnterStep(step)])
                        .then((data) => {
                            if (data[0] && data[1]) {
                            // set enabled steps to complete state
                            $scope.getEnabledSteps()[thisStep].completed = true;

                            //deselect all steps so you can set fresh below
                            unselectAll();

                            //$log.log('value for canExit argument: ', $scope.currentStep.canexit);
                            $scope.selectedStep = step;
                            //making sure current step is not undefined
                            if (angular.isDefined($scope.currentStep)) {
                                $scope.currentStep = step.wzTitle;
                            }
                            //setting selected step to argument passed into goTo()
                            step.selected = true;
                            //emit event upwards with data on goTo() invoktion
                            $scope.$emit('ertNgWizard:stepChanged', { step, index: stepIdx(step) });
                            //$log.log('current step number: ', $scope.currentStepNumber());
                        }
                    });
                }
            };

            function canEnterStep(step) {
                let canEnter = null;

                //If no validation function is provided, allow the user to enter the step
                if (angular.isUndefined(step.canenter)) {
                    return true;
                }
                //If canenter is a boolean value instead of a function, return the value
                if (typeof step.canenter === 'boolean') {
                    return step.canenter;
                }
                //Check to see if the canenter function is a promise which needs to be returned
                canEnter = step.canenter($scope.context);
                if (angular.isFunction(canEnter.then)) {
                    return $q.resolve(canEnter);
                } else {
                    return canEnter === true;
                }
            }

            function canExitStep(step, stepTo) {
                let canExit = null;

                //Exiting the step should be allowed if no validation function was provided
                // or if the user is moving backwards
                if (angular.isUndefined(step.canexit) || $scope.getStepNumber(stepTo) < $scope.currentStepNumber()) {
                    return true;
                }
                //If canexit is a boolean value instead of a function, return the value
                if (typeof step.canexit === 'boolean') {
                    return step.canexit;
                }
                //Check to see if the canexit function is a promise which needs to be returned
                canExit = step.canexit($scope.context);
                if (angular.isFunction(canExit.then)) {
                    return $q.resolve(canExit);
                } else {
                    return canExit === true;
                }
            }

            $scope.currentStepNumber = () => {
                //retreive current step number
                return stepIdx($scope.selectedStep) + 1;
            };

            $scope.getEnabledSteps = () => {
                return $scope.steps.filter((step) => {
                    return step && step.disabled !== 'true';
                });
            };

            //unSelect All Steps
            function unselectAll() {
                //traverse steps array and set each 'selected' property to false
                angular.forEach($scope.getEnabledSteps(), (step) => {
                    step.selected = false;
                });
                //set selectedStep variable to null
                $scope.selectedStep = null;
            }

            function uncompleteAll() {
                //traverse steps array and set each 'completed' property to false
                angular.forEach($scope.steps, (step) => {
                    step.completed = false;
                });
            }

            //ALL METHODS ATTACHED TO this ARE ACCESSIBLE VIA ErtNgWizardService.wizard().methodName()

            this.currentStepTitle = () => {
                return $scope.selectedStep.wzTitle;
            };

            this.currentStepDescription = () => {
                return $scope.selectedStep.description;
            };

            this.currentStep = () => {
                return $scope.selectedStep;
            };

            this.totalStepCount = () => {
                return $scope.getEnabledSteps().length;
            };

            //Access to enabled steps from outside
            this.getEnabledSteps = () => {
                return $scope.getEnabledSteps();
            };

            //Access to current step number from outside
            this.currentStepNumber = () => {
                return $scope.currentStepNumber();
            };
            //method used for next button within step
            this.next = (callback) => {
                const enabledSteps = $scope.getEnabledSteps();
                //setting variable equal to step  you were on when next() was invoked
                const index = stepIdx($scope.selectedStep);

                //checking to see if callback is a function
                if (angular.isFunction(callback)) {
                    if (callback()) {
                        performNext(index, enabledSteps);
                    } else {
                        return;
                    }
                }
               /* if (!callback) {
                    //completed property set on scope which is used to add class/remove class from progress bar
                    $scope.selectedStep.completed = true;
                }*/
                performNext(index, enabledSteps);
            };

            function performNext(selectedStepIdx, enabledSteps) {
                //checking to see if this is the last step.  If it is next behaves the same as finish()
                if (selectedStepIdx === enabledSteps.length - 1) {
                    this.finish();
                } else {
                    //invoking goTo() with step number next in line
                    $scope.goTo(enabledSteps[selectedStepIdx + 1]);
                }
            }

            //used to traverse to any step, step number placed as argument
            this.goTo = (step) => {
                //wrapped inside $timeout so newly enabled steps are included.
                $timeout(() => {
                    const enabledSteps = $scope.getEnabledSteps();
                    let stepTo;

                    //checking that step is a Number
                    if (angular.isNumber(step)) {
                        stepTo = enabledSteps[step];
                    } else {
                        //finding the step associated with the title entered as goTo argument
                        stepTo = stepByTitle(step);
                    }
                    //going to step
                    $scope.goTo(stepTo);
                });
            };

            //calls finish() which calls onFinish() which is declared on an attribute and linked to controller
            // via wizard directive.
            this.finish = () => {
                if ($scope.onFinish) {
                    $scope.onFinish();
                }
            };

            this.previous = () => {
                //getting index of current step
                const index = stepIdx($scope.selectedStep);

                //ensuring you aren't trying to go back from the first step
                if (index === 0) {
                    throw new Error('Can\'t go back. It\'s already in step 0');
                } else {
                    //go back one step from current step
                    $scope.goTo($scope.getEnabledSteps()[index - 1]);
                }
            };

            //cancel is alias for previous.
            this.cancel = () => {
                //getting index of current step
                const index = stepIdx($scope.selectedStep);

                //ensuring you aren't trying to go back from the first step
                if (index === 0) {
                    throw new Error('Can\'t go back. It\'s already in step 0');
                } else {
                    //go back one step from current step
                    return $scope.goTo($scope.getEnabledSteps()[0]);
                }
            };

            //reset
            this.reset = () => {
                const firstStepObj = $scope.getEnabledSteps()[0];

                //deselect all steps so you can set fresh below
                unselectAll();
                // test complete property to false for all steps
                uncompleteAll();

                //go to first step
                $scope.selectedStep = firstStepObj;

                //making sure current step is not undefined
                if (angular.isDefined($scope.currentStep)) {
                    $scope.currentStep = firstStepObj.wzTitle;
                }
                //setting selected step
                firstStepObj.selected = true;

                //emit event upwards with data on goTo() invokation
                $scope.$emit('ertNgWizard:stepChanged', { step: firstStepObj, index: stepIdx(firstStepObj) });
                //$log.log('current step number: ', $scope.currentStepNumber());
            };
        }]
    };
});

function wizardButtonDirective(action) {
    angular.module('app.ertNgWizard').directive(action, () => {
            return {
                restrict: 'A',
                //replace: false,
                require: '^ertNgWizard',
                link: ($scope, $element, $attrs, ertNgWizard) => {

                    $element.on('click', (e) => {
                        e.preventDefault();
                        $scope.$apply(() => {
                            $scope.$eval($attrs[action]);
                            ertNgWizard[action.replace('ertWz', '').toLowerCase()]();
                        });
                    });
                }
            };
        });
}

wizardButtonDirective('ertWzNext');
wizardButtonDirective('ertWzPrevious');
wizardButtonDirective('ertWzFinish');
wizardButtonDirective('ertWzCancel');
wizardButtonDirective('ertWzReset');

angular.module('app.ertNgWizard').factory('ErtNgWizardService', () => {
    const service = {};

    const wizards = {};

    service.defaultName = 'default-ert-wizard';

    service.addWizard = (name, wizard) => {
        wizards[name] = wizard;
    };

    service.removeWizard = (name) => {
        delete wizards[name];
    };

    service.wizard = (name) => {
        let nameToUse = name;

        if (!name) {
            nameToUse = service.defaultName;
        }

        return wizards[nameToUse];
    };

    return service;
});

export default angular.module('app.ertNgWizard').name;

/*eslint-enable indent*/
