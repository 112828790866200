import ertCardComponent from './ertCard.component';
import ertCardRowModule from './row/index';
import iconsRowModule from './iconsRow';
import iconTextRowModule from './iconTextRow';

const ertCardModule = angular.module('app.common.ertCard', [ertCardRowModule, iconsRowModule, iconTextRowModule])
	.component('ertCard', ertCardComponent)
	.name;

export default ertCardModule;

