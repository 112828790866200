/*
 * rowActionComponent
 * */

import templateUrl from './rowAction.html';
import controller from './rowAction.controller';
import './rowAction.scss';

const rowActionComponent = {
	bindings: {
		iconClass: '@',
		action: '&',
		label: '@'
	},
	templateUrl,
	controller
};

export default rowActionComponent;
