/*
 * expandableBulletListModule
 * */

import expandableBulletListComponent from './expandableBulletList.component';

const expandableBulletListModule = angular.module(
	'app.components.clinicalData.ecoaSessionViewer.expandableBulletList', [])
	.component('expandableBulletList', expandableBulletListComponent)
	.name;

export default expandableBulletListModule;
