import templateUrl from './customPopup.html';
import controller from './customPopup.controller';
import './customPopup.scss';

const customPopupComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default customPopupComponent;
