import templateUrl from './deviceInfoPopup.html';
import controller from './deviceInfoPopup.controller';
import './deviceInfoPopup.scss';

const deviceInfoPopup = {
	restrict: 'E',
	bindings: {
		deviceName: '<',
		deviceSerialNumber: '<',
		properties: '<',
		hasError: '<'
	},
	templateUrl,
	controller
};

export default deviceInfoPopup;
