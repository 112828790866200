(function () {
	'use strict';
	angular.module('portalApp').factory('cacheManager', CacheManager);

	/*
	 * @ngInject
	 */
	function CacheManager($log,
												appConfig,
												appSession) {

		$log = $log.getInstance('CacheManager', 'color:blue; font-size:1em; background-color:#dedede;');
		$log.debug('loaded');
		const regexUrl = /\//g;
		const regexParams = /[\?&=\s\$]/g;

		return {
			get,
			put,
			remove,
			removeCacheForUrl,
			getCacheContextInUrl,
			generateUrlCacheKey
		};

		function get(key, cacheContext) {
			let container = {};

			if (cacheContext.sessionStorage) {
				container = angular.fromJson(sessionStorage.getItem(key));
			} else if (cacheContext.localStorage) {
				container = angular.fromJson(localStorage.getItem(key));
			}

			if (container) {
				if ((container.timestamp + (cacheContext.expirationTime * 1000)) < Date.now()) {
					remove(key, cacheContext);
				} else {
					return container.value;
				}
			}
			return undefined;
		}

		function put(key, value, cacheContext) {
			$log.debug('put ' + key);
			const cacheContainer = {
				timestamp: Date.now(),
				value
			};

			if (cacheContext.sessionStorage) {
				sessionStorage.setItem(key, angular.toJson(cacheContainer));
			} else if (cacheContext.localStorage) {
				localStorage.setItem(key, angular.toJson(cacheContainer));
			}
		}

		function remove(key, cacheContext) {
			$log.debug('remove ' + key);
			if (cacheContext.sessionStorage) {
				sessionStorage.removeItem(key);
			} else if (cacheContext.localStorage) {
				localStorage.removeItem(key);
			}
		}

		function getCacheContextInUrl(url) {
			const cacheContextName = url.substring(url.indexOf('cacheContext') + 13);
			const cacheContext = appConfig.cacheContext[cacheContextName];

			cacheContext.name = cacheContextName;
			return cacheContext;
		}

		function removeCacheForUrl(url, cacheContextName) {
			url = `${url}&cacheContext=${cacheContextName}`;
			const study = appSession.getStudy();
			const cacheContext = appConfig.cacheContext[cacheContextName];

			var key = generateUrlCacheKey(url, study, cacheContext);
			remove(key, cacheContext);
		}

		function generateUrlCacheKey(url, study, cacheContext) {
			const user = appSession.get();
			const userId = user ? user.userId : 0;
			var build = appConfig.buildnumber;

			study = study || { id: '', studyModeId: '' };
			var key = url;
			key = key.replace(regexUrl, '-');
			key = key.replace(regexParams, '_');

			if (cacheContext.hasStudyContext === false || study.id === '') {
				key = '{userId}_{cacheContext}_{build}_{key}'.supplant(
					{
						userId, 'cacheContext': cacheContext.cacheKey, build, key
					});
			} else {
				key = '{userId}_{studyId}_{studyMode}_{cacheContext}_{build}_{key}'.supplant(
					{
						userId, 'studyId': study.id, 'studyMode': study.studyModeId,
						'cacheContext': cacheContext.cacheKey, build, key
					});
			}
			return key;
		}
	}
})();
