/*
 * visitOverviewWidgetModule
 * */

import visitOverviewWidgetComponent from './visitOverviewWidget.component';
import resentVisitsTableModule from './resentVisitsTable/index';
import plannedVisitsTableModule from './plannedVisitsTable/index';
import visitOverviewWidgetService from './visitOverviewWidget.service';
import visitCalendarPopupComponent from './visitCalendarPopup/index';

const visitOverviewWidgetModule = angular.module('app.components.dashboard.visitOverviewWidget', [
	resentVisitsTableModule,
	plannedVisitsTableModule,
	visitCalendarPopupComponent
]).component('visitOverviewWidget', visitOverviewWidgetComponent)
	.service('visitOverviewWidgetService', visitOverviewWidgetService)
	.name;

export default visitOverviewWidgetModule;
