const api = new WeakMap();

class LoadingIndicator {

	constructor($rootScope, $log, loadingIndicatorService, bodyCssClassService) {
		'ngInject';
		this.rootScope = $rootScope;
		this.log = $log;
		this.loadingIndicatorService = loadingIndicatorService;
		this.bodyCssClassService = bodyCssClassService;
	}

	$onInit() {
		const self = this;

		// respond to router events
		this.rootScope.$on('$stateChangeStart', () => {
			if (this.listenRouter) {
				this.show = true;
				this.bodyCssClassService.setClass({ customClass: 'global-loading' });
			}
			this.log.debug('ROUTE STATE CHANGE STARTED');
		});

		this.rootScope.$on('$stateChangeSuccess', () => {
			if (this.listenRouter) {
				this.show = false;
				this.bodyCssClassService.unsetClass({ customClass: 'global-loading' });
			}
			this.log.debug('ROUTE STATE CHANGE SUCCEED');
		});

		// Declare a mini-API to hand off to our service so the service
		// doesn't have a direct reference to this directive's scope.
		api.set(this, {
			name: this.name,
			group: this.group,
			show: () => {
				if (self.show) { return; }
				self.show = true;
				self.bodyCssClassService.setClass({ customClass: 'global-loading' });
			},
			hide: () => {
				if (!self.show) { return; }
				self.show = false;
				self.bodyCssClassService.unsetClass({ customClass: 'global-loading' });
			},
			toggle: () => {
				self.show = !self.show;
				if (self.show) {
					self.bodyCssClassService.setClass({ customClass: 'global-loading' });
				} else {
					self.bodyCssClassService.unsetClass({ customClass: 'global-loading' });
				}
			}
		});

		// Register this loadingIndicator with the loadingIndicator service.
		if (this.name) {
			this.loadingIndicatorService.register(api.get(this));
		}

		// This loadingIndicator is good to go. Fire the onLoaded expression.
		if (this.onLoaded) {
			this.onLoaded(this.loadingIndicatorService, api.get(this));
		}
	}

	$onDestroy() {
		if (this.name) {
			this.loadingIndicatorService.unregister(this.name);
			this.bodyCssClassService.unsetClass({ customClass: 'global-loading' });
		}
	}
}

export default LoadingIndicator;

