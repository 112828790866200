const _ = require('lodash');

class ISpiroPopupController {
	constructor($log, $q, loadingIndicatorService, clinicalDataService, ertBasePopupService,
		eproService, iSpiroService, configurationService, appConfig, notificationService,
		appI18n, qrCodeService) {
		'ngInject';
		this.$log = $log;
		this.$q = $q;
		this.loadingIndicatorService = loadingIndicatorService;
		this.qrCodeService = qrCodeService;
		this.iSpiroService = iSpiroService;
		this.clinicalDataService = clinicalDataService;
		this.notificationService = notificationService;
		this.ertBasePopupService = ertBasePopupService;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.setInitialOptions();
		this.onlineHelpId = '';
	}

	$onInit() {
		this.$log = this.$log.getInstance('ISpiroPopupController');
		this.$log.debug('loaded');
		this.setApi();
		this.ertBasePopupService.register('iSpiroPopup', this.$API);
	}

	$onDestroy() {
		this.ertBasePopupService.unregister('iSpiroPopup');
	}

	setApi() {
		this.$API = {
			open: (countryId, siteId, subjectId, iSpiroActionId, displaySiteName, subjectKey, qrCode) => {
				this.reset();
				if (siteId) {
					this.siteId = +siteId;
				}
				if (countryId) {
					this.countryId = +countryId;
				}
				if (subjectId) {
					this.subjectId = subjectId;
				}

				this.iSpiroActionId = iSpiroActionId;
				this.initAction(iSpiroActionId);

				this.loadingIndicatorService.show();
				if (displaySiteName && subjectKey) {
					this.sites = [{ displaySiteName }];
					this.subjects = [{ subjectKey }];
					this.preselectSite(this.sites[0]);
					this.preselectSubject(this.subjects[0]);

					if (iSpiroActionId === this.appConfig.iSpiroActionId.lost ||
						iSpiroActionId === this.appConfig.iSpiroActionId.close ||
						iSpiroActionId === this.appConfig.iSpiroActionId.resetPin) {
						this.showPopup();
						this.loadingIndicatorService.hide();

					} else if (iSpiroActionId === this.appConfig.iSpiroActionId.edit ||
							   iSpiroActionId === this.appConfig.iSpiroActionId.scheduleEvent ||
							   iSpiroActionId === this.appConfig.iSpiroActionId.rescheduleEvent ||
							   (iSpiroActionId === this.appConfig.iSpiroActionId.dispense &&
								qrCode)) {
						this.qrCode = qrCode;
						this.loading = false;
						this.showPopup();
						this.loadingIndicatorService.hide();
					} else {
						this.getAndShowQrCode().then(() => {
							this.showPopup();
						}).finally(() => {
							this.loadingIndicatorService.hide();
						});
					}
				} else {
					this.loadSites().then(() => {
						this.showPopup();
					}).finally(() => {
						this.loadingIndicatorService.hide();
					});
				}
			},
			close: () => {
				this.open = false;
			}
		};
		this.componentReady && this.componentReady({ $API: this.$API });
	}

	initAction(iSpiroActionId) {
		switch (iSpiroActionId) {
			case this.appConfig.iSpiroActionId.dispense:
				this.popupTitle = 'clinicaldata.subject.i-spiro.close-dispense.dispenseTitle';
				this.onlineHelpId = 'MSP3.ClinicalData.ISpiro.DispenseDevice';
				break;
			case this.appConfig.iSpiroActionId.edit:
				this.popupTitle = 'clinicaldata.subject.i-spiro.close-dispense.editTitle';
				this.description = 'clinicaldata.subject.i-spiro.close-dispense.editTextAfter';
				this.onlineHelpId = 'MSP3.ClinicalData.ISpiro.EditDeviceValues';
				break;
			case this.appConfig.iSpiroActionId.scheduleEvent:
				this.popupTitle = 'clinicaldata.subject.i-spiro.scheduleEvent.title';
				this.description = 'clinicaldata.subject.i-spiro.scheduleEvent.afterScheduleQrCodeDescription';
				this.onlineHelpId = 'MSP3.ClinicalData.ISpiro.ScheduleEvent';
				break;
			case this.appConfig.iSpiroActionId.rescheduleEvent:
				this.popupTitle = 'clinicaldata.subject.i-spiro.scheduleEvent.rescheduleTitle';
				this.description = 'clinicaldata.subject.i-spiro.scheduleEvent.afterScheduleQrCodeDescription';
				this.onlineHelpId = 'MSP3.ClinicalData.ISpiro.RescheduleEvent';
				break;
			case this.appConfig.iSpiroActionId.lost:
				this.showDispensedSubjects = true;
				this.popupTitle = 'clinicaldata.subject.i-spiro.close-dispense.lostTitle';
				this.description = 'clinicaldata.subject.i-spiro.close-dispense.lostText';
				this.onlineHelpId = 'MSP3.ClinicalData.ISpiro.LostDevice';
				this.actions = [{
					action: () => {
						this.submitLostDeviceForm(this.closeISpiroForm, iSpiroActionId);
					},
					cssClass: '-es-primary',
					displayName: 'app.buttons.submit'
				}, {
					action: () => {
						this.closePopup();
					},
					displayName: 'app.buttons.cancel'
				}];

				break;

			case this.appConfig.iSpiroActionId.resetPin:
				this.showDispensedSubjects = true;
				this.popupTitle = 'clinicaldata.subject.i-spiro.close-dispense.resetPinTitle';
				this.description = 'clinicaldata.subject.i-spiro.close-dispense.resetPinText';
				this.onlineHelpId = 'MSP3.ClinicalData.ISpiro.ResetPIN';
				this.actions = [{
					action: () => {
						this.submitResetPinForm(this.closeISpiroForm);
					},
					cssClass: '-es-primary',
					displayName: 'app.buttons.submit'
				}, {
					action: () => {
						this.closePopup();
					},
					displayName: 'app.buttons.cancel'
				}];

				break;
			case this.appConfig.iSpiroActionId.close:
				this.showDispensedSubjects = true;
				this.popupTitle = 'clinicaldata.subject.i-spiro.close-dispense.remoteCloseTitle';
				this.description = 'clinicaldata.subject.i-spiro.close-dispense.remoteCloseText';
				this.onlineHelpId = 'MSP3.ClinicalData.ISpiro.CloseDevice';
				this.actions = [{
					action: () => {
						this.submitRemoteCloseForm(this.closeISpiroForm);
					},
					cssClass: '-es-primary',
					displayName: 'app.buttons.submit'
				}, {
					action: () => {
						this.closePopup();
					},
					displayName: 'app.buttons.cancel'
				}];

				break;
		}
	}

	reset() {
		this.error = '';
		this.showDispensedSubjects = false;
		this.countryId = null;
		this.siteId = null;
		this.subjectId = null;
		this.description = '';
		this.actions = null;
		this.clearQrCode();
		this.setInitialOptions();
	}

	setInitialOptions() {
		this.sites = null;
		this.sitesOptions = {
			label: 'app.common.siteId',
			disabled: false,
			id: 'sitesDropdown',
			required: true,
			placeholder: 'clinicaldata.subject.i-spiro.close-dispense.selectSite',
			uiValue: 'displaySiteName'
		};

		this.subjects = null;
		this.subjectsOptions = {
			label: 'app.common.subjectKey',
			disabled: true,
			id: 'subjectsDropdown',
			required: true,
			placeholder: 'clinicaldata.subject.i-spiro.close-dispense.selectSubject',
			uiValue: 'subjectKey'
		};
		this.hideLegend = false;
	}

	loadSites() {
		return this.clinicalDataService.getSitesMinimal(null, this.countryId).then((res) => {
			this.sites = res.data;
			if (res.data && res.data.length > 0) {
				this.sitesOptions.disabled = false;
				this.sitesOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.selectSite';
				if (this.siteId) {
					const site = _.find(this.sites, { siteId: String(this.siteId) });

					this.preselectSite(site);

					// show loading indicator during loadSubjects if site is preselected
					this.isLoading = true;
					this.loadSubjects().finally(() => {
						this.isLoading = false;
					});
				}
			} else {
				this.sitesOptions.disabled = true;
				this.sitesOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.noSite';
			}
		});
	}

	preselectSite(site) {
		this.preselectedSite = site;
		this.sitesOptions.disabled = true;
		this.sitesOptions.required = false;
	}

	preselectSubject(subject) {
		this.preselectedSubject = subject;
		this.subjectsOptions.disabled = true;
		this.subjectsOptions.required = false;
		this.hideLegend = true;
	}

	clearQrCode() {
		this.qrCode = null;
	}

	getAndShowQrCode() {
		return this.getAndSetQrCode(this.siteId, this.subjectId, this.iSpiroActionId);
	}

	showQrCodeResult(code) {
		this.qrCode = code;
		this.actions = [];
		this.sitesOptions.disabled = true;
		this.sitesOptions.required = false;
		this.subjectsOptions.disabled = true;
		this.subjectsOptions.required = false;
		this.hideLegend = true;
	}

	onSiteChange(newVal) {
		this.clearQrCode();
		if (!newVal) {
			this.subjects = null;
			this.subjectId = null;
			this.subjectsOptions.disabled = true;
			return;
		}
		this.isLoading = true;
		this.siteId = +newVal.siteId;
		this.countryId = +newVal.countryId;
		this.loadSubjects().finally(() => {
			this.isLoading = false;
		});
	}

	loadSubjects() {
		return this.iSpiroService.getSubjects(this.countryId, this.siteId, this.iSpiroActionId).then((res) => {
			this.subjects = res.data.subjects;

			if (res.data && res.data.subjects.length > 0) {
				this.subjectsOptions.disabled = false;
				this.subjectsOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.selectSubject';
			} else {
				this.subjectsOptions.disabled = true;
				this.subjectsOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.noSubject';
			}
		});
	}

	onSubjectChange(newVal) {
		this.clearQrCode();
		if (!newVal) {
			return;
		}

		this.subjectId = newVal.subjectId;
	}

	submitLostDeviceForm(form, actionId) {
		if (form.$valid) {
			this.isLoading = true;
			this.iSpiroService.lostDevice(this.countryId, this.siteId, this.subjectId, actionId)
				.then(() => {
					this.closePopup();
					this.showLostTriggeredMessage();
				})
				.catch((error) => {
					if (error && error.data && error.data.message) {
						this.error = error.data.message;
					} else {
						this.error = this.appI18n.translateImmediate(
							'app.errors.genericLoadError');
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		} else {
			form.showValidation = true;
		}
	}

	showLostTriggeredMessage() {
		this.appI18n.translate(['clinicaldata.subject.i-spiro.lostTriggeredMessage']).then((translations) => {
			const msg = translations['clinicaldata.subject.i-spiro.lostTriggeredMessage'];

			this.notificationService.showSuccess(msg);
		});

		return false;
	}

	submitRemoteCloseForm(form) {
		if (form.$valid) {
			this.isLoading = true;
			this.iSpiroService.remoteCloseDevice(this.countryId, this.siteId, this.subjectId)
				.then((result) => {
					this.description = 'clinicaldata.subject.i-spiro.close-dispense.remoteCloseTextAfter';
					this.showQrCodeResult(result.data.qrCode);
				})
				.catch((error) => {
					if (error && error.data && error.data.message) {
						this.error = error.data.message;
					} else {
						this.error = this.appI18n.translateImmediate(
							'app.errors.genericLoadError');
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		} else {
			form.showValidation = true;
		}
	}

	submitResetPinForm(form) {
		if (form.$valid) {
			this.isLoading = true;
			this.iSpiroService.resetPin(this.countryId, this.siteId, this.subjectId)
				.then((result) => {
					this.description = 'clinicaldata.subject.i-spiro.close-dispense.resetPinTextAfter';
					this.showQrCodeResult(result.data.qrCode);
				})
				.catch((error) => {
					if (error && error.data && error.data.message) {
						this.error = error.data.message;
					} else {
						this.error = this.appI18n.translateImmediate(
							'app.errors.genericLoadError');
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		} else {
			form.showValidation = true;
		}
	}


	getAndSetQrCode(siteId, subjectId, actionId) {
		this.loading = true;
		return this.iSpiroService.getQrCode(siteId, subjectId, actionId).then((result) => {
			this.qrCode = result.data.qrCode;
			this.loading = false;
		});
	}

	closePopup() {
		this.$API.close();
	}

	showPopup() {
		this.open = true;
	}
}

export default ISpiroPopupController;
