(function () {
	'use strict';

	angular.module('portalApp')
		.directive('ertSelectableListItems', SelectableListItems);

	angular.module('portalApp')
		.controller('ertSelectableItemsController', SelectableListItemsController);

	function SelectableListItems() {
		return {
			restrict: 'E',
			templateUrl: 'scripts/directives/list/ertSelectableListItems.html',
			scope: {
				columns: '=?',
				items: '=',
				limit: '=?',
				onDblClickSelect: '&',
				onSelect: '&?',
				filterActive: '=',
				selectable: '=?',
				selected: '=?'
			},
			controller: 'ertSelectableItemsController',
			controllerAs: 'vm',
			bindToController: true
		};
	}

	/** @ngInject **/
	function SelectableListItemsController($scope, $timeout, $log) {
		var vm = this;

		vm.isSelected = isSelected;
		vm.toggle = toggle;
		vm.onItemDblClick = vm.onDblClickSelect && wrapSelectHandler(vm.onDblClickSelect);

		$log = $log.getInstance('SelectableItemsController');
		$log.debug(' loaded');

		activate();

		function activate() {
			$scope.$watchCollection(function () {
				return vm.items;
			}, refresh);
		}

		function deselect(item) {
			isSelected(item) && (vm.selected = null);
		}

		function isInList(item) {
			return (vm.items.indexOf(item) !== -1);
		}

		function isSelected(item) {
			return (vm.selected === item);
		}

		//deselect invisible items
		function refresh() {
			!isInList(vm.selected) && deselect(vm.selected);
		}

		function select(item) {
			vm.selected = item;
			vm.onSelect && vm.onSelect({ item });
		}

		function toggle(item) {
			isSelected(item) ? deselect(item) : select(item);
		}

		function wrapSelectHandler(fn){
			return function (item) {
				select(item);
				$timeout(function(){
					fn();
				}, 0);
				return false;
			};
		}
	}

})();
