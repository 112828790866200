import templateUrl from './subjectDemographics.html';
import controller from './subjectDemographics.controller';

/*eslint-disable indent*/
//TODO: remove this later
const subjectDemographics = {
    restrict: 'E',
    bindings: {
        demographics: '<'
    },
    templateUrl,
    controller
};

export default subjectDemographics;
/*eslint-enable indent*/
