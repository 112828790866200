(function () {
	'use strict';

	angular.module('portalApp').directive('ertFilterLinks', FilterLinks);
	angular.module('portalApp').directive('ertDatetimePicker', DatetimePicker);

	angular.module('portalApp').directive('ertDataGrid', DataGrid);
	angular.module('portalApp').directive('ertGridScrollbar', GridScrollbar);
	angular.module('portalApp').directive('ertCardSameHeight', CardSameHeight);
	angular.module('portalApp').directive('ertDesignEcoaViewer', EcoaViewer);
	angular.module('portalApp').directive('ertEcoaCollapse', EcoaCollapse);
	angular.module('portalApp').directive('ertWizardInitialize', ErtWizardInitialize);
	angular.module('portalApp').directive('ertIFrameResizer', ErtIFrameResizer);

	function ErtIFrameResizer() {
		return {
			priority: 1, // run after standart and our custom directives
			restrict: 'A',
			link: function (scope, elem, attrs) {
				$(elem).iFrameResize({
					checkOrigin: false,
					heightCalculationMethod: 'lowestElement',
					log: false
				});
				elem.on('$destroy', function () {
					if ($(elem).iFrameResizer){
						$(elem).iFrameResizer.close();
					}

				});
			}
		};
	}

	/**
	 * @ngInject
	 */
	function ErtWizardInitialize($timeout) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				$timeout(function () {
					//set wizard step width
					var steps = $('.wizard-steps').find('.wizard-step');
					steps.width(100 / steps.length + '%');
					$('.wizard-line').width((99.5 - (100 / steps.length)) + '%');

					var smallSteps = $('.wizard-small-steps').find('.wizard-step');
					smallSteps.width(100 / smallSteps.length + '%');
					$('.wizard-small-line').width((99.5 - (100 / smallSteps.length)) + '%');
				}, 0);
			}
		}
	}

	function EcoaViewer() {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				var breakpoint_mobile = 501;
				var breakpoint_tablet = 768;
				var breakpoint_desktop = 1024;

				$(window).resize(function(){
					setTimeout(function(){
						// $('.more-scores').attr('style','');
						// $('.results-container').attr('style','');

				});

				window.is_not_mobile = $(window).width() > breakpoint_tablet;
				$(window).resize(function(){
					if( !is_not_mobile && $(window).width() >= breakpoint_tablet ) {
						is_not_mobile = true;
						$('.tiles-list-container').attr('style','');
					}
					if( is_not_mobile && $(window).width() < breakpoint_tablet ) {
						is_not_mobile = false;
					}
				});

				window.is_not_desktop = $(window).width() < breakpoint_desktop;
				});

				var warning_and_scores;

				$('.tiles-list-container ul li').click(function(){
					if($(window).width() < 768){
						$('.tiles-list-container').css({'display':'none'});
						$('.tiles-list-container').addClass('tiles-list-container-hidden');

					}
				});

				$('.results-container .assessment-heading .icon-close').click(function(){
					$('.tiles-list-container').css({'display':'block'});
					$('.tiles-list-container').removeClass('tiles-list-container-hidden');
					$('.results-container').attr('style','');

				});

				if ($('.session-block').innerWidth() > $('.assessment-scores').innerWidth() ) {
					$('.more-scores-hidden').hide();
				}


				$('.select-answers').click(function(){
					$('.btn-group').find('input:radio').next().fadeToggle('');
					$('.btn-group').find('input:radio:checked').next().fadeToggle('');
				});


				var list_collapse = $('.warning-and-scores a');

				$('.collapse-answers').blur(function(){
					list_collapse.click();
				});

				$(window).scroll(function(){


					if( $(window).width() < breakpoint_mobile  && $('.tiles-list-container').hasClass('tiles-list-container-hidden') && $(window).scrollTop() > $('.data-container').offset().top) {
						$('.results-container').css({
							'position': 'fixed',
							'z-index': 2,
							'width': $('.data-container').width(),
							'top': 0
						});
					}
					else if( $(window).width()  > breakpoint_mobile && $(window).width() < breakpoint_tablet  && $('.tiles-list-container').hasClass('tiles-list-container-hidden') && $(window).scrollTop() > $('.data-container').offset().top) {
						$('.results-container').css({
							'position': 'fixed',
							'z-index': 2,
							'width': $('.data-container').width(),
							'top': 70
						});
					}
					else{
						$('.results-container').css({
							'position': 'relative'

						});
						$('.results-container').attr('style','');
					}
				});

			}
		};
	}

	/**
	 * @ngInject
	 */
	function CardSameHeight($timeout, $window) {
		return {
			restrict: 'C',
			link: function (scope, element, attrs) {
				if (scope.$last === undefined || scope.$last === true) {
					$timeout(resetHeight, 0);
					$timeout(fixHeight, 0);

					angular.element($window).bind('resize', onResize);
					scope.$on('$destroy', cleanUp);
				}

				function cleanUp () {
					angular.element($window).off('resize', onResize);
				}

				function onResize(){
					$timeout(resetHeight, 0);
					$timeout(fixHeight, 0);
				}

				function resetHeight(){
					var cards = $('.ert-card-same-height');
					cards.css('minHeight', 'auto');
				}

				function fixHeight(){
					var cards = $('.ert-card-same-height');
					var tallest = 0;
					cards.each(function() {
						var height = $(this).outerHeight();
						if (height > tallest) {
							tallest = height;
						}
					});
					cards.css('minHeight', tallest);
				}
			}
		};
	}

	function EcoaCollapse($window) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				var breakpoint_desktop = 1024;

				angular.element($window).bind('resize', function() {
					if (document.body.clientWidth >= breakpoint_desktop) {
						var container = element.parent().parent().children();
						if (container.first().children().first().children().hasClass('collapsed')) {
							container.first().css('width','');
							container.last().css('width','');
							container.first().children().first().children().last().removeClass('ert-ecoa-scoring-container-uncollapsed');
							container.first().children().first().children().last().children().css('display','inline');
						}
						else {
							container.first().css('width','30%');
							container.last().css('width','70%');
							// ert-ecoa-scoring
							container.first().children().first().children().last().children().css('display','block');
							container.first().children().first().children().last().addClass('ert-ecoa-scoring-container-uncollapsed');
						}
					}
					else {
						var container = element.parent().parent().children();
						container.first().css('width','');
						container.last().css('width','');
						if (container.first().children().first().children().hasClass('collapsed')) {
							container.first().children().first().children().last().removeClass('ert-ecoa-scoring-container-uncollapsed');
							container.first().children().first().children().last().children().css('display','inline');
						}
						else {
							container.first().children().first().children().last().children().css('display','block');
							container.first().children().first().children().last().addClass('ert-ecoa-scoring-container-uncollapsed');
						}
					}
				});

				element.on('show.bs.collapse', function() {
					var container = element.parent().parent().children();
					if (document.body.clientWidth >= breakpoint_desktop) {
						container.first().css('width','30%');
						container.last().css('width','70%');
						// ert-ecoa-scoring
						container.first().children().first().children().last().children().css('display','block');
						container.first().children().first().children().last().addClass('ert-ecoa-scoring-container-uncollapsed');
					}
					else {
						container.first().children().first().children().last().children().css('display','block');

					}
				});
				element.on('hide.bs.collapse', function() {
				});

				element.on('shown.bs.collapse', function() {
				});
				element.on('hidden.bs.collapse', function() {
					var container = element.parent().parent().children();
					container.first().css('width','');
					container.last().css('width','');
					container.first().children().first().children().last().removeClass('ert-ecoa-scoring-container-uncollapsed');

					// ert-ecoa-scoring-container
					container.first().children().first().children().last().css('white-space','nowrap');
					// ert-ecoa-scoring
					container.first().children().first().children().last().children().css('display','inline');
				});
			}
		};
	}

	function GridScrollbar() {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				if (scope.$last){
									$(window).resize(function () {
										setScrollBar();
									});
									setScrollBar();
                }
            }
        };
    }

	function setScrollBar() {
		// TODO: think about a better way of dooing it
		setTimeout(function(){
			var hasScroll = document.body.scrollWidth > document.body.clientWidth;
			if (hasScroll) {
				var $outer = $('<div>').css({visibility: 'hidden', width: 100, overflow: 'scroll'}).appendTo('body');
				var widthWithScroll = $('<div>').css({width: '100%'}).appendTo($outer).outerWidth();
				$outer.remove();
				var scrollbarSize = 100 - widthWithScroll;

				var pixel = window.innerHeight;
				var pixelNum = parseInt(pixel) - 165 - scrollbarSize;

				var viewContainer = document.getElementById('ui-view-container');

				if (viewContainer) {
					viewContainer.style.minHeight = pixelNum + 'px';
				}

			}  else {
				var viewContainer = document.getElementById('ui-view-container');

				if (viewContainer) {
					viewContainer.style.minHeight = '';
				}


			}
		},300);
	}

	function DataGrid() {
		return {
			priority: 1, // run after standard and our custom directives
			restrict: 'A',
			link: function (scope, elem, attrs) {}
		};
	}

	function FilterLinks() {
		return {
			priority: 1, // run after standart and our custom directives
			restrict: 'A',
			link: function (scope, elem, attrs) {

				$('.js-filter-link-basis').on('click', function(event) {
					event.preventDefault();
					if ( !$(this).closest('.filtering').hasClass('filtering-basic') ) {
						$('.filtering.filtering-advanced').slideUp();
						$('.filtering.filtering-basic').slideDown();
					}
				});

				$('.js-filter-link-advanced').on('click', function(event) {
					event.preventDefault();
					if ( !$(this).closest('.filtering').hasClass('filtering-advanced') ) {
						$('.filtering.filtering-basic').slideUp();
						$('.filtering.filtering-advanced').slideDown();
					}
				});

				if ($('.filtering.filtering-basic').length === 0) {
					$('.filtering.filtering-advanced').slideDown();
				}

				var el = elem.parent().parent();
				if (el.hasClass('ert-grid-filter-active')) {
					if (el.hasClass('filtering-advanced')) {
						$('.filtering.filtering-basic').slideUp();
						$('.filtering.filtering-advanced').slideDown();
					}
					else {
						$('.filtering.filtering-basic').slideDown();
						$('.filtering.filtering-advanced').slideUp();
					}
				}
			}
		};
	}

	function DatetimePicker() {
		return {
			priority: 1, // run after standart and our custom directives
			restrict: 'A',
			scope: {
				orientation: '@'
			},
			link: function (scope, elem, attrs) {

				const orientation = scope.orientation || 'bottom auto';
				const container = $(elem).parent();

				if (elem.hasClass('ert-grid-date-filter')) {
					$(elem).datepicker({
						format: 'dd M yyyy',
						weekStart: 1,
						autoclose: true,
						disableTouchKeyboard: true,
						todayHighlight: true,
						startDate: '1 Jan 2000',
						endDate: '1 Jan 2030',
						forceParse: false,
						orientation,
						container
					});
				} else {
					$(elem).datepicker({
						format: 'dd-M-yyyy',
						weekStart: 1,
						autoclose: true,
						disableTouchKeyboard: true,
						todayHighlight: true,
						forceParse: false,
						orientation,
						container
					});
				}
				elem.on('$destroy', function () {
					$(elem).datepicker('destroy');
				});
			}
		};
	}


})();
