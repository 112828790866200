import templateUrl from './ertButton.html';
import './ertButton.scss';

const ertButton = {
	bindings: {
		config: '<'
	},
	templateUrl
};

export default ertButton;

