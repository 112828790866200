(function() {

	'use strict';
	angular
		.module("portalApp")
		.service("helpService", Service);

	/**
	 * @ngInject
	 */
	function Service($log, $http, $window) {
		$log = $log.getInstance("helpService");
		$log.debug("helpService loaded");

		return {
			getHelpMappingData: getHelpMappingData,
			showModuleHelp: showModuleHelp
		};

		function getHelpMappingData() {
			var url = UmsConfig.help.mappingFileUrl;
			return $http.get(url, {corsSimpleRequest: true, cache: true, params: {cacheContext: 'help'}});
		}

		function showModuleHelp (moduleId) {
			getHelpMappingData().then(function (response) {
				var map = response.data.filter(function (el) {
					return el.ModuleId === moduleId;
				});
				if (map.length > 0) {
					var newUrl = UmsConfig.help.mappingFormat.supplant({contextId: map[0].ContextId});
					$window.open(newUrl, '_blank');
				}
			});
			//TODO: Add notification MPORT-3604
		}
	}
})();
