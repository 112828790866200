/*
 * studyMetricsDetailsPopupController
 * */

class StudyMetricsDetailsPopupController {
	constructor($log, appI18n, dashboardService, loadingIndicatorService) {
		'ngInject';
		this.$log = $log;
		this.appI18n = appI18n;
		this.dashboardService = dashboardService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.na = this.appI18n.translateImmediate('dashboard.study-metrics.na');
		this.open = false;
	}

	$onInit() {
		this.$log = this.$log.getInstance('StudyMetricsDetailsPopupController');
		this.$log.debug('loaded');
		this.setApi();
	}

	cancelAction() {
		this.$API.close();
	}

	setApi() {
		this.$API = {
			open: () => {
				this.loadingIndicatorService.show();
				this.dashboardService.getStudyMetricsDetails().then((response) => {
					this.metricsDetails = response.data;
					this.loadingIndicatorService.hide();
					this.open = true;

				}, () => {
					this.loadingIndicatorService.hide();
					this.errorMessage = this.appI18n.translateImmediate('dashboard.loading-error');
					//TODO: Show notification MPORT-3604
				});
			},
			close: () => {
				this.open = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({ $API: this.$API });
		}
	}
}

export default StudyMetricsDetailsPopupController;
