/*
 * announcementsWidgetModule
 * */

import announcementsWidgetComponent from './announcementsWidget.component';
import announcementsDetailsPopupModule from './announcementsDetailsPopup/index';

const announcementsWidgetModule = angular.module('app.components.dashboard.announcementsWidget', [
	announcementsDetailsPopupModule])
	.component('announcementsWidget', announcementsWidgetComponent)
	.name;

export default announcementsWidgetModule;
