/*
 * ertGridController
 * */

import ErtGridController from '../ertGrid/ertGrid.controller.js';

class CalendarGridController extends ErtGridController {
	constructor($scope, $stateParams, $log, $filter, $location, $timeout,
				$window, oDataHelper, appConfig, appI18n, $document,
				analyticsService, dialogService, loadingIndicatorService) {
		'ngInject';
		super();
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.loadingIndicatorService = loadingIndicatorService;
		this.$log = $log;
		this.$filter = $filter;
		this.$document = $document;
		this.$window = $window;
		this.$location = $location;
		this.$timeout = $timeout;
		this.oDataHelper = oDataHelper;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.analyticsService = analyticsService;
		this.dialogService = dialogService;
	}

	selectDay(day) {
		if (day.inPreviousMonth) {
			this.previousMonth(1).then(() => {
				this.calendarOptions.selectDay(day.id);
			});
			return;
		}
		if (day.inNextMonth) {
			this.nextMonth(this.calendarOptions.numberOfMonths).then(() => {
				this.calendarOptions.selectDay(day.id);
			});
			return;
		}
		this.calendarOptions.selectDay(day.id);
	}

	previousMonth(numberOfMonths) {
		numberOfMonths = numberOfMonths || 1;
		this.calendarOptions.previousMonth(numberOfMonths);
		this.options.isRowDataLoadingComplete = false;
		return this.fetchData();
	}

	nextMonth(numberOfMonths) {
		numberOfMonths = numberOfMonths || 1;
		this.calendarOptions.nextMonth(numberOfMonths);
		this.options.isRowDataLoadingComplete = false;
		return this.fetchData();
	}
}

export default CalendarGridController;
