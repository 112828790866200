import qrCodeComponent from './qrCode.component';
import qrCodeDirective from './qrCode.directive';

qrCodeDirective.$inject = ['qrCodeService'];
const qrCodeModule = angular.module('app.components.clinicalData.qrCode', [])
	.component('qrCode', qrCodeComponent)
	.directive('qrCodeDisplay', qrCodeDirective)
	.name;

export default qrCodeModule;
