/*
 * plannedVisitsTableDirective
 * */

import templateUrl from './plannedVisitsTable.html';
import controller from './plannedVisitsTable.controller';
import './plannedVisitsTable.scss';

const plannedVisitsTableDirective = () => {
	return {
		scope: {
			row: '<',
			showCalendar: '<'
		},
		controller,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		replace: true,
		link(/*scope, element, attr, ctr*/) {}
	};
};

export default plannedVisitsTableDirective;
