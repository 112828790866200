(function () {

	'use strict';
	angular
		.module('portalApp')
		.service('accountService', AccountService);

	/**
	 * @ngInject
	 */
	function AccountService($http, $log, appConfig, authenticator) {
		var service = {
			showChangePassword,
			resetPassword,
			registerShowChangePassword
		};

		$log = $log.getInstance('AccountService');
		var vm = this;

		return service;

		function showChangePassword(username, password, successHandler) {
			if (vm.showChangePasswortHandler) {
				vm.showChangePasswortHandler(username, password, successHandler);
			}
		}

		function registerShowChangePassword(handler) {
			vm.showChangePasswortHandler = handler;
		}

		function resetPassword(email, isGsso) {
			var url = appConfig.apiUrls.account.resetPassword;
			var data = {
				userName: email,
				isGsso: isGsso
			};

			return $http.post(url, data);
		}
	}
})();
