/*
 * userRolesComponent
 * */

import templateUrl from './userRoles.html';
import controller from './userRoles.controller';
import './userRoles.scss';

const userRolesComponent = {
	bindings: {
		availableRoles: '<',
		currentRoles: '<',
		onRoleChanges: '&?',
		componentReady: '&?',
		state: '<?'
	},
	templateUrl,
	controller
};

export default userRolesComponent;
