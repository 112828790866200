/*
 * countriesInfoPopupController
 * */

class CountriesInfoPopupController {
	constructor($log, loadingIndicatorService, clinicalDataService, appConfig) {
		'ngInject';
		this.$log = $log;
		this.loadingIndicatorService = loadingIndicatorService;
		this.clinicalDataService = clinicalDataService;
		this.appConfig = appConfig;
	}

	$onInit() {
		this.$log = this.$log.getInstance('CountriesInfoPopupController');
		this.$log.debug('loaded');
		this.setApi();
	}

	setApi() {
		const self = this;

		this.$API = {
			open: (countryId) => {
				self.fetchCountryInfo(countryId);
			},
			close: () => {
				self.open = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({ $API: this.$API });
		}
	}

	cancelAction() {
		this.$API.close();
	}

	fetchCountryInfo(countryId) {
		this.$log.debug(`Load Country Info. CountryId: ${countryId}`);

		this.loadingIndicatorService.show();
		this.clinicalDataService.getCountryInfo(countryId).then((resp) => {
			this.countryInfo = resp.data;
			this.localSupporters = this.splitInChunks(resp.data.localSupporters, 2);
			this.assignDataPoints(this.countryInfo.enrollmentGraph);
			this.modalTitle = `${this.countryInfo.countryName}, ${this.countryInfo.countryCode}`;

			this.open = true;
			this.loadingIndicatorService.hide();
		}, () => {
			//TODO: Show notification MPORT-3604
			this.loadingIndicatorService.hide();
			this.open = false;
		});
	}

	splitInChunks(dataArr, size) {
		const newArr = [];

		if (dataArr.length) {
			for (let i = 0; i < dataArr.length; i += size) {
				newArr.push(dataArr.slice(i, i + size));
			}
		}
		return newArr;
	}

	assignDataPoints(graph = {}) {
		if (graph.enrolled) {
			this.enrollmentColor = this.appConfig.enrollmentColors.info.enrolled;
		}
		if (graph.included) {
			this.includedColor = this.appConfig.enrollmentColors.info.included;
		}
		if (graph.created) {
			this.createdColor = this.appConfig.enrollmentColors.info.created;
		}
		if (graph.randomized) {
			this.randomizedColor = this.appConfig.enrollmentColors.info.randomized;
		}
		if (graph.earlyTerminated) {
			this.terminatedColor = this.appConfig.enrollmentColors.info.terminated;
		}
		if (graph.excluded) {
			this.excludedColor = this.appConfig.enrollmentColors.info.excluded;
		}
		if (graph.finished) {
			this.finishedColor = this.appConfig.enrollmentColors.info.finished;
		}
		if (graph.treatmentPeriodCompleted) {
			this.treatmentColor = this.appConfig.enrollmentColors.info.treatment;
		}
		if (graph.activated) {
			this.activatedColor = this.appConfig.enrollmentColors.info.activated;
		}
	}

	getContactFullAddress(contact) {
		let address = '';

		if (contact.contactAddress) {
			address += `${contact.contactAddress} `;
		}
		if (contact.contactCity) {
			address += `, ${contact.contactCity}`;
		}
		if (contact.contactZipCode) {
			address += `, ${contact.contactZipCode}`;
		}
		if (contact.usStateAbbreviation) {
			address += `, ${contact.usStateAbbreviation}`;
		} else if (contact.contactForeignProvince) {
			address += `, ${contact.contactForeignProvince}`;
		}
		return address;
	}

	/* eslint-disable complexity */
	hasEnrollmentData() {
		if (this.countryInfo && !this.isLoading && this.countryInfo.enrollmentGraph && (
			this.countryInfo.enrollmentGraph.created || this.countryInfo.enrollmentGraph.enrolled ||
			this.countryInfo.enrollmentGraph.included || this.countryInfo.enrollmentGraph.randomized ||
			this.countryInfo.enrollmentGraph.earlyTerminated || this.countryInfo.enrollmentGraph.excluded ||
			this.countryInfo.enrollmentGraph.finished || this.countryInfo.enrollmentGraph.treatmentPeriodCompleted ||
			this.countryInfo.enrollmentGraph.activated)) {
			const response =
				(this.countryInfo.enrollmentGraph.created && this.countryInfo.enrollmentGraph.created.length > 0) ||
				(this.countryInfo.enrollmentGraph.enrolled && this.countryInfo.enrollmentGraph.enrolled.length > 0) ||
				(this.countryInfo.enrollmentGraph.included && this.countryInfo.enrollmentGraph.included.length > 0) ||
				(this.countryInfo.enrollmentGraph.randomized &&
				this.countryInfo.enrollmentGraph.randomized.length > 0) ||
				(this.countryInfo.enrollmentGraph.earlyTerminated &&
				this.countryInfo.enrollmentGraph.earlyTerminated.length > 0) ||
				(this.countryInfo.enrollmentGraph.excluded &&
				this.countryInfo.enrollmentGraph.excluded.length > 0) ||
				(this.countryInfo.enrollmentGraph.finished &&
				this.countryInfo.enrollmentGraph.finished.length > 0) ||
				(this.countryInfo.enrollmentGraph.treatmentPeriodCompleted &&
				this.countryInfo.enrollmentGraph.treatmentPeriodCompleted.length > 0) ||
				(this.countryInfo.enrollmentGraph.activated && this.countryInfo.enrollmentGraph.activated.length > 0);

			return response;
		} else {
			return false;
		}
	}
	/* eslint-enable complexity */
}

export default CountriesInfoPopupController;
