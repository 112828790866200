import inputController from '../input/ertInput.controller';

class datePickerController extends inputController {
	constructor($scope, $document) {
		'ngInject';
		super();
		this.input = null;
		this.$scope = $scope;
		this.$document = $document;
	}
	$onInit() {
		this.setApi();
	}

	$onChanges(changesObj) {
		if (changesObj.setValue) {
			this.lastSetValue = changesObj.setValue.currentValue;
			if (this.datePicker) {
				angular.element(this.datePicker).datepicker('update', changesObj.setValue.currentValue);
			}
		}
	}

	checkNotValid() {
		if (!this.input) {
			return false;
		}
		/* eslint-disable */
		const invalid = (!this.input.$valid &&
				(this.input.$touched || this.input.$$parentForm.showValidation));
		/* eslint-enable */

		return invalid;
	}

	$postLink() {
		const unregister = this.$scope.$watch(() => {
			return this.$document[0].getElementById(this.options.id);
		}, (newValue) => {
			if (newValue !== null) {
				unregister();

				this.datePicker = newValue;
				if (this.lastSetValue) {
					angular.element(newValue).datepicker('update', this.lastSetValue);
				}
			}
		});

		this.input = this.fg[this.name];
		// adds custom validators
		if (this.options && this.options.validators) {
			for (const v in this.options.validators) {
				this.input.$validators[v] = this.options.validators[v].fn;
			}
		}
	}
}

export default datePickerController;
