class ertSelectController {
	constructor($timeout) {
		'ngInject';
		this.model = null;
		this.select = null;
		this.timeout = $timeout;
		this.matchSelectValueToModel(this.data, this.options.uiValue);
	}

	$onInit() {
	}

	$postLink() {
		this.timeout(() => {
			this.select = this.fg[this.name];
			// adds custom validators
			for (const key in this.options.validators) {
				this.select.$validators[key] = this.options.validators[key].fn;
			}
		});
	}

	$onChanges(changesObj) {
		if (changesObj.data && !changesObj.data.isFirstChange()) {
			this.matchSelectValueToModel(changesObj.data.currentValue, this.options.uiValue);
		}
		if (changesObj.setValue) {
			this.setDefaultValue(changesObj.setValue.currentValue, this.notifySetValue);
		}
	}

	valueChange(newValue) {
		this.onChange({ newValue });
	}

	setDefaultValue(val, notify) {
		// if no value provided return, ex. on initialization
		if (!val) {
			this.model = null;
			return;
		}
		// by some reason _.find returns first value in array if val is undefined
		const newVal = _.find(this.data, val || 0) || null;

		// if value is not found, but val argument was valid, ex. when resetting select value
		this.model = newVal;
		if (notify) { this.valueChange(this.model); }

	}
	/*
	 * Function that takes a model array and a string that represents the value (uiValue)
	 * that will be shown in the select list
	 * */
	matchSelectValueToModel(dataModelRef, displayName) {
		if (!dataModelRef) {
			return;
		}

		if (angular.isFunction(displayName)) {
			for (let x = 0; x < dataModelRef.length; x++) {
				dataModelRef[x].selectedValue = displayName(dataModelRef[x]);
			}
			return dataModelRef;
		}
		for (let x = 0; x < dataModelRef.length; x++) {
			dataModelRef[x].selectedValue = dataModelRef[x][displayName];
		}
		return dataModelRef;
	}

	checkNotValid() {
		if (!this.select) {
			return false;
		}
		/* eslint-disable */
		return (!this.select.$valid && (this.select.$dirty || this.select.$$parentForm.showValidation));
		/* eslint-enable */
	}

	showLoading() {
		return this.options.showLoading && (!this.data || (angular.isArray(this.data) && !this.data.length));
	}

}

export default ertSelectController;
