import templateUrl from './popup.html';
import controller from './popup.controller';
import './popup.scss';

const popupComponent = {
	bindings: {
		show: '<?',
		onShow: '&?',
		onEscape: '&?',
		size: '@'
	},
	transclude: true,
	templateUrl,
	controller
};

export default popupComponent;
