(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('LogiCtrl', LogiCtrl);

	/*
	 * @ngInject
	 */
	function LogiCtrl($log, $interval, $compile, $scope, logiService, appSession) {

		var vm = this;
		$log = $log.getInstance("LogiController");
		$log.debug(" loaded");

		vm.result = undefined;
		vm.isLoading = true;
		vm.showAccountMessage = false;

		vm.loadReportWhenReady = loadReportWhenReady;
		activate();
		/////////////

		function activate() {
			var studyId = appSession.getStudy().id;
			var studyMode = appSession.getStudy().studyModeId;
			vm.studyId = studyId;
			vm.linkParams = { ptl_id: studyId, studyMode: studyMode };
			logiService.getLogiSecureKey(studyId).then(function (response) {
				vm.url = response.data.redirectUrl;
				vm.secureKey = response.data.secureKey;
				vm.logiBase = response.data.baseUrl;
				vm.logiEmbedUrl = vm.logiBase + 'rdTemplate/rdEmbedApi/rdEmbed.js';
				vm.startReportName = response.data.startReportName;
				$("#logi").append( $compile("<script src='" + vm.logiEmbedUrl + "'>")($scope));
				vm.isLoading = false;
				vm.logiReportOptions = {
					applicationUrl: vm.logiBase,
					report: response.data.startReportName,
					secureKey: vm.secureKey,
					autoSizing: 'all',
					linkParams: vm.linkParams
				};
				loadReport();
			});
		}

		function loadReport() {
			var rem;
			rem = $interval(function() {
				loadReportWhenReady(rem);
			}, 250, 60);
		}

		function loadReportWhenReady(interval) {
			if (typeof EmbeddedReporting === 'undefined') {
				return;
			}
			$interval.cancel(interval);
			EmbeddedReporting.create('logi', vm.logiReportOptions);
		}

		function setError(msg) {
			vm.isLoading = false;
			vm.hasErrors = true;
			appI18n.translate(msg).then(
				function (translation) {
					vm.errorMessage = translation;
				});
		}

		function onEpxLoginSuccess(data) {
			vm.isLoading = false;
			vm.result = data;
			if (data.success) {
				$window.location.href = data.epxUrl;
			}
			else {
				vm.showAccountMessage = true;
				vm.accountUrl = data.epxUrl;
			}
		}

		function onEpxLoginFailure(data) {
			vm.result = data;
			setError("sso.epx.failure");
		}
	}
})();
