import templateUrl from './ecgViewer.html';
import controller from './ecgViewer.controller';
import './ecgViewer.scss';

const ecgViewerComponent = {
	bindings: {
		title: '@'
	},
	templateUrl,
	controller
};

export default ecgViewerComponent;
