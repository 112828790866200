import templateUrl from './ertCardRow.html';
import './ertCardRow.scss';

export default () => {

	const controller = (/*$scope, $element, $attrs*/) => {
		'ngInject';
	};

	const link = (/*$scope, $element, $attrs*/) => {
		'ngInject';
	};

	return {
		scope: {
			header: '@',
			data: '@',
			naText: '@'
		},
		link,
		controller,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		replace: true
	};
};
