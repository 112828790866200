(function () {
  'use strict';

  angular.module('portalApp').directive('ertButtonLoading', ButtonLoading);

  /**
   * @ngInject
   */
  function ButtonLoading() {
    return {
      restrict: 'EA',
      templateUrl: 'scripts/directives/ertButtonLoading.html',
      transclude: true,
      replace: true,
      /**
       * Using isolated scope in directives is recommended
       * 1. "@"   (  Text binding / one-way binding )
       * . "="   ( Direct model binding / two-way binding )
       * 3. "&"   ( Behaviour binding / Method binding  )
       */
      scope: {
        loading: "=",
        disabled: "=",
        buttonType: '=',
        id: "=",
        translateKey: "=",
        click: "&"
      },
      link: function (scope, element, attributes) {
      }
    };
  }
})();
