import templateUrl from './activatePopup.html';
import controller from './activatePopup.controller';
import './activatePopup.scss';

const activatePopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default activatePopupComponent;
