(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('TicketViewerController', TicketViewerController);

	/*
	 * @ngInject
	 */
	function TicketViewerController($log, $scope, $q, $location, dataManagementService,
									configurationService, loadingIndicatorService,
									signatureService, $stateParams, appConfig) {

		var vm = this;
		$log = $log.getInstance('TicketViewerController');
		$log.debug('loaded');

		vm.getTickets = getTickets;
		vm.openNewDcr = openNewDcr;
		vm.closeNewDcr = closeNewDcr;
		vm.closeDcrDetails = closeDcrDetails;
		vm.onDcrDetailsReady = onDcrDetailsReady;

		vm.tickets = {};
		vm.moduleLabel = null;
		vm.viewConfig = undefined;

		activate();
		loadingIndicatorService.show();

		$scope.$on('$destroy', () => {
			dataManagementService.registerAnswerDcr();
		});

		function activate() {
			vm.ticketGridOptions = {

				sortOrders: [{
					fieldName: 'queryCreationDate',
					reverseOrder: true
				}],
				preSelectedFilters: [{
					fieldName: 'queryPortalStatus',
					displayName: 'State',
					filterConditions: [
						{
							displayValue: 'All open tickets',
							filterValue: 'Query answered',
							operator: 'eq',
							enumType: 'string',
							type: 'enum'
						},
						{
							displayValue: 'All open tickets',
							filterValue: 'Created',
							operator: 'eq',
							enumType: 'string',
							type: 'enum'
						},
						{
							displayValue: 'All open tickets',
							filterValue: 'Query sent to site',
							operator: 'eq',
							enumType: 'string',
							type: 'enum'
						}
					]
				}],
				defaultRowAction: function(row, index) {
					openDcrDetails(vm.ticketGridOptions.rowData, row, index);
				},
				gridRowActions: [
					{
						displayName: 'Answer',
						iconCssClass: 'es-icon-reply',
						enabledIfFieldName: 'allowAnswering',
						action: function(row) {
							openAnswerDcr(row);
						}
					}
				],

				getData: getTicketData
			};
			if (($stateParams.popup === appConfig.popups.ticketDetails ||
				 $stateParams.popup === appConfig.popups.answerDcr) &&
				$stateParams.popupParam) {
				vm.ticketGridOptions.preSelectedFilters = [{
					fieldName: 'queryIdText',
					displayName: 'DCR',
					filterConditions: [
						{
							displayValue: $stateParams.popupParam,
							filterValue: $stateParams.popupParam,
							operator: 'eq',
							type: 'string'
						}
					]
				}];

				var unregister = $scope.$watch('vm.ticketGridOptions.rowData', function() {
					if (vm.ticketGridOptions.rowData && vm.ticketGridOptions.rowData.length === 1) {
						if ($stateParams.popup === appConfig.popups.answerDcr) {
							openAnswerDcr(vm.ticketGridOptions.rowData[0]);
						}
						else {
							openDcrDetails(vm.ticketGridOptions.rowData, vm.ticketGridOptions.rowData[0], 0);
						}
						$location.search('popup', null);
						$location.search('popupParam', null);
						unregister();
					}
				});
			}
			if ($stateParams.signKey) {
				const state = signatureService.getState($stateParams.signKey);

				if (state) {
					if (state.sigType === appConfig.sigType.createDcr) {
						openNewDcr();
					} else if (state.sigType === appConfig.sigType.answerDcr) {
						const unbind = $scope.$watch('dataManagementService.isAnswerDcrHandlerRegistered()', () => {
							dataManagementService.showAnswerDcr(state.ticketId);
							unbind();
						});
					} else {
						loadingIndicatorService.hide();
					}
				} else {
					loadingIndicatorService.hide();
					vm.showSignatureNotificationPopup = true;
				}
			}
			loadConfig();
			loadSiteMapName();
			registerHandlers();

		}


		function loadSiteMapName() {
			configurationService.getSiteMapLabel(appConfig.siteMapModuleIds.dataManagement).then(
				function (label) {
					vm.moduleLabel = label;
				}
			);
		}

		function registerHandlers() {
			dataManagementService.registerRefresh(refreshGrid);
		}

		function refreshGrid() {
			vm.reload = true;
		}

		function getTicketData(options, forExport) {
			return dataManagementService.getTickets(options, forExport);
		}

		function getColumns(viewConfig, demographicsConfig) {
			const columnDefs = [
				{ displayName: 'DCR', fieldName: 'queryIdText', type: 'string',
				  isDefaultFilter: true, isPrimary: true },
				{ displayName: 'MSCCT DCR ID', fieldName: 'masterScopeDcrId', type: 'string',
				 isHidden: !viewConfig.showMasterScopeDcrId },
				{ displayName: 'State', fieldName: 'queryPortalStatus',
				 type: 'enum', enumType: 'string', enums: [
					 { displayName: 'All',
					  values: ['Created', 'Closed', 'Query sent to site', 'Query answered'] },
					 { displayName: 'All open tickets',
					  values: ['Query answered', 'Created', 'Query sent to site'] },
					 { displayName: 'Query Answered', values: ['Query answered'] },
					 { displayName: 'Created', values: ['Created'] },
					 { displayName: 'Closed', values: ['Closed'] },
					 { displayName: 'Query Sent To Site', values: ['Query sent to site'] }
				 ] },
				{ translationKey: 'data-management.columns.site',
				  fieldName: 'sponsorSiteId', type: 'string' },
				{ translationKey: 'app.common.subjectKey',
				  fieldName: 'subjectCriticalValues', type: 'string' }
			];

			if (!demographicsConfig.isSubjectId1Hidden) {
				if (demographicsConfig.fieldLabels[appConfig.demographics.subjectId1]) {
					columnDefs.push(
						{ displayName: demographicsConfig.fieldLabels[appConfig.demographics.subjectId1],
						  fieldName: 'subjectId1', type: 'string' });
				} else {
					columnDefs.push(
						{ translationKey: 'data-management.columns.subject', fieldName: 'subjectId1',
						 type: 'string' });
				}
			}

			const columnDefs2 = [
				{ displayName: 'Transmittal #', fieldName: 'transmittalNumber',
				  type: 'number' },
				{ displayName: 'Description', fieldName: 'queryMessage', removeSortControls: true, type: 'string',
				 formatter: 'lineBreak' },
				{ displayName: 'Created (UTC)', fieldName: 'queryCreationDate', type: 'date',
				 formatter: 'dateTimeFormat' },
				{ displayName: 'Created by', fieldName: 'createdBy', type: 'string' },
				{ displayName: 'Modified (UTC)', fieldName: 'queryModifiedDate', type: 'date',
				 formatter: 'dateTimeFormat' },
				{ displayName: 'DM Type', fieldName: 'ticketType', type: 'string' }
			];

			Array.prototype.push.apply(columnDefs, columnDefs2);

			return columnDefs;

		}
		function loadConfig() {
			$q.all(
				[dataManagementService.getTicketsViewConfig(),
				 configurationService.getSubjectDemographicSettings()]
			).then(
				function (resolves) {
					const viewConfig = resolves[0];
					const demographicsConfig = resolves[1].data;
					$log.debug('load config tickets view config OK');

					vm.viewConfig = viewConfig;
					vm.ticketGridOptions.columnDefsStatic = getColumns(viewConfig, demographicsConfig);

					vm.ticketGridOptions.columnDefs = vm.ticketGridOptions.columnDefsStatic;

					if (viewConfig.allowCreateDcr) {
						$log.debug('allow create dcr is configured');

						vm.actions = [{
							displayName: 'New DCR',
							iconClass: 'es-icon-data-management',
							cssClass: '-es-primary',
							action: vm.openNewDcr
						}];
					}
					vm.load = true;
				},
				function (data, status) {
					$log.error('loadConfig error: {data} and status: {status}', { data: data, status: status });
				}
			).finally(() => {
				if (!$stateParams.signKey && (!$stateParams.popup || !$stateParams.popupParam)) {
					loadingIndicatorService.hide();
				}
			});
		}

		function openAnswerDcr(ticket) {
			if (ticket.queryPortalStatusId === 2) {
				dataManagementService.showAnswerDcr(ticket.queryId);
			} else {
				loadingIndicatorService.hide();
				alert('not answerable state');
			}
		}

		function openDcrDetails(tickets, ticket, index) {
			vm.dcrDetailsPopupApi.open(tickets, ticket, index);
		}

		function closeDcrDetails() {
			vm.showDcrDetailsForm = false;
		}

		function openNewDcr() {
			dataManagementService.showNewDcr();
		}

		function closeNewDcr() {
			dataManagementService.closeNewDcr();
		}

		function getTickets() {
			dataManagementService.getTickets().then(function(response) {
				vm.tickets = response.data;
			}, function(response, status) {
					$log.error('TicketViewerController.getTickets error: {data} and status: {status}', {data: response.data, status: response.status});
				});
		}

		function onDcrDetailsReady(api) {
			vm.dcrDetailsPopupApi = api;
		}
	}
})();
