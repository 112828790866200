(function () {

	'use strict';

	angular
		.module("portalApp")
		.controller("StudyUsersCtrl", Controller);

	/**
	 * @ngInject
	 */
	function Controller($scope, $log, $q, studyPermissionsService, studyPermissionsTrans) {

		$log = $log.getInstance("StudyUsersCtrl", "color:purple");
		$log.debug("StudyUsersCtrl:: loaded");

		var vm = this;

		vm.dataLoading = true;
		vm.loadingError = '';
		vm.model = {};
		vm.options = {};
		vm.errorMsg = "";
		vm.localized = {
			gridColumns: {},
			list: {}
		};
		$scope.$parent.loadGridData = loadGridData;
		vm.confirmHandler = {
			showDialog: false
		};
		vm.gridOptions = {
			rowDefs: {},
			sortOrders: [
				{
					fieldName: 'fullName',
					reverseOrder: false
				}
			],
			gridActions: [],

			getData: function (options) {
				return $q(function (resolve, reject) {
					loadData(options, resolve, reject);
				});
			}
		};
		activate();

		function activate() {
			studyPermissionsTrans.getListViewLocalized().then(function (localized) {
				vm.localized = localized;

				vm.gridOptions.columnDefs = [
					{
						displayName: localized.gridColumns.userName,
						fieldName: 'fullName',
						isSortable: true,
						type: 'string',
						isDefaultFilter: true
					},
					{
						displayName: localized.gridColumns.userEmail,
						fieldName: 'personEmail',
						isSortable: true,
						type: 'string'
					},
					{
						displayName: localized.gridColumns.accountCreated,
						fieldName: 'userCreatedDate',
						isSortable: true,
						type: 'date',
						formatter: 'dateTimeFormatNA'
					},
					{
						displayName: localized.gridColumns.roles,
						fieldName: 'userRoles',
						removeSortControls: true,
						type: 'string'
					},
					{
						displayName: localized.gridColumns.lastLogon,
						fieldName: 'lastLoginDate',
						isSortable: true,
						type: 'date',
						formatter: 'dateTimeFormat'
					},
					{
						displayName: localized.gridColumns.portalAccount,
						fieldName: 'isPortalAccountEnabled',
						isSortable: true,
						type: 'enum',
						formatter: 'yesNo',
						enumType: 'bool',
						enums: [{
							displayName: 'Yes',
							values: [1]
						}, {
							displayName: 'No',
							values: [-1]
						}]
					}
				];

				vm.gridOptions.gridRowActions = [];
				loadGridData();
			});
		}

		function loadGridData(reload) {
			vm.load = true;
		}

		function loadData(options, resolve, reject) {
			vm.dataLoading = true;
			vm.loadingError = '';
			studyPermissionsService.getStudyUsers(options)
				.then(function (response) {
					vm.gridOptions.gridRowActions = [];
					vm.model = response.data.model;
					vm.options.config = response.data.config;

					if (vm.options.config.requestUserRoleRemoval === true) {
						vm.gridOptions.gridRowActions.push(
							{
								displayName: vm.localized.buttons.removeUser,
								action(row) {
									studyPermissionsService.goToStudyUserDelete(row.userId);
								},
								iconCssClass: 'es-icon-function-delete'
							});
					}
					if (vm.options.config.editUser === true) {
						vm.gridOptions.gridRowActions.push({
							displayName: vm.localized.buttons.editUser,
							action(row) {
								studyPermissionsService.goToStudyUserEdit(row.userId);
							},
							iconCssClass: 'es-icon-user-name'
						});
					}
					vm.dataLoading = false;
					resolve(vm.model);
				}, function (error) {
					vm.loadingError = error.data.message || error.data;
					reject(vm.loadingError);
				});
		}
	}
})();
