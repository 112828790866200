const _ = require('lodash');

class ErtCardController {
	constructor($log, $timeout, $window) {
		'ngInject';
		this.$log = $log;
		this.$timeout = $timeout;
		this.$window = $window;
		this.minHeight = 'auto';
		this.onResize = _.debounce(() => {
			this.$timeout(() => {
				this.resetHeight();
			}).then(() => {
				this.$timeout(() => {
					this.fixHeight();
				});
			});
		// will execute at a rate of 15fps
		}, 66, { trailing: true, leading: true });
		this.cardsElements = null;
	}

	$onInit() {
		if (this.sameHeightGroupName) {
			angular.element(this.$window).on('resize', () => {
				this.onResize();
			});
		}
	}

	$postLink() {
		if (this.sameHeightGroupName) {
			this.$timeout(() => {
				this.fixHeight();
			});
		}
	}

	$onDestroy() {
		if (this.sameHeightGroupName) {
			angular.element(this.$window).off('resize', this.onResize);
		}
	}

	resetHeight() {
		this.minHeight = 'auto';
	}

	getCardsElements() {
		return this.cardsElements || angular.element(`.same-height.${this.sameHeightGroupName}`);
	}

	fixHeight() {
		const cards = this.getCardsElements();
		let tallest = 0;

		cards.each(function () {
			const height = angular.element(this).outerHeight();

			if (height > tallest) {
				tallest = height;
			}
		});

		this.minHeight = `${tallest}px`;
	}
}

export default ErtCardController;
