(function () {
	'use strict';

	angular.module('portalApp').directive('ertButtonProgressBar', ButtonProgressBar);

	/**
	 * @ngInject
	 */
	function ButtonProgressBar($interval, $window, ertButtonProgressBarWorkerService) {
		return {
			restrict: 'E',
			templateUrl: 'scripts/directives/ertButtonProgressBar.html',
			replace: true,
			scope: {
				timer: '@',
				percentage: '=',
				disabled: '<'
			},
			link: function (scope, element, attributes) {
				const step = 100 / scope.timer;

				if ($window.Worker) {
					ertButtonProgressBarWorkerService.startInterval(scope, step);
					scope.$watch('percentage', function(newValue, oldValue) {
						updateCss(scope, element, newValue);
					});
				}
				else {
					const intervalProgress = $interval(() => {
						scope.percentage += step;
						if (scope.percentage >= 100) {
							scope.percentage = 100;
						}
						updateCss(scope, element, scope.percentage);
					}, 1000);
					scope.$on('$destroy', () => {
						$interval.cancel(intervalProgress);
					});
				}
			}
		};

		function updateCss(scope, element, value) {
			if (value < 100) {
				element.children().first().css('width', value + '%');
			}
			else {
				element.children().first().css('width', '100%');
			}
		}


	}
})();
