class ertInputController {
	constructor() {
		'ngInject';
		this.input = null;
	}

	$onInit() {
		this.model = this.value || null;
		if (!this.options.type ||
			(this.options.type !== 'text' && this.options.type !== 'email' &&
			 this.options.type !== 'radio' && this.options.type !== 'password' &&
			 this.options.type !== 'radio' && this.options.type !== 'number' &&
			 this.options.type !== 'checkbox')) {
			throw Error(`The input ${this.name} must have a type
					(text, email, password, number, checkbox, radio) specified in options`);
		}
		this.setApi();
	}

	$postLink() {
		this.input = this.fg[this.name];
		// adds custom validators
		if (this.options && this.options.validators) {
			for (const v in this.options.validators) {
				this.input.$validators[v] = this.options.validators[v].fn;
			}
		}
	}

	valueChange(newValue) {
		const updatedValue = this.onChange({
			newValue
		});

		if (updatedValue) {
			newValue = updatedValue;
			this.model = updatedValue;
		}
		this.value = newValue;
	}

	setApi() {

		this.$API = {
			setValue: (value) => {
				this.model = value;
				this.valueChange(value);
			}
		};

		// publish api when component is ready
		if (!this.componentReady) {
			return;
		}
		this.componentReady({
			$API: this.$API
		});
	}

	checkNotValid() {
		if (!this.input) {
			return false;
		}
		/* eslint-disable */
		return (!this.input.$valid &&
			(this.input.$dirty || this.input.$$parentForm.showValidation));
		/* eslint-enable */
	}

}

export default ertInputController;
