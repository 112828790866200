(function () {
	'use strict';
	
	angular
	.module('portalApp')
	.controller('dcrFileUploaderController', dcrFileUploaderController);
	
	/**
	 * @ngInject
	 */
	function dcrFileUploaderController($log, $scope, appI18n, FileUploader, remoteFileService, appConfig,
									   dataManagementService, dialogService, popupService) {
		$log = $log.getInstance("dcrFileUploaderController");
		
		var vm = this;
		
		appI18n.translate([
			'data-management.addAttachment.errors.saveErrorMessage',
			'data-management.addAttachment.errors.saveErrorMessageVirus',
			'data-management.addAttachment.errors.invalidFileTypeMessage',
			'data-management.addAttachment.errors.invalidFileSizeMessage',
			'app.addAttachment.addByERTMessage',
			'app.addAttachment.addBySiteMessage',
			'data-management.addAttachment.errors.requireUploadFiles',
			'data-management.addAttachment.errors.saveInProgress'
		])
		.then(function (translations) {
			vm.saveErrorMessage = translations['data-management.addAttachment.errors.saveErrorMessage'];
			vm.saveErrorMessageVirus = translations['data-management.addAttachment.errors.saveErrorMessageVirus'];
			vm.invalidFileTypeMessage = translations['data-management.addAttachment.errors.invalidFileTypeMessage'];
			vm.invalidFileSizeMessage = translations['data-management.addAttachment.errors.invalidFileSizeMessage'];
			vm.addByERTMessage = translations['app.addAttachment.addByERTMessage'];
			vm.addBySiteMessage = translations['app.addAttachment.addBySiteMessage'];
			vm.errorMessageRequireUploadFiles = translations['data-management.addAttachment.errors.requireUploadFiles'];
			vm.errorMessageSavingFile = translations['data-management.addAttachment.errors.saveInProgress'];
			vm.agreementErrorMessage = null;
		});
		
		vm.isValid = isValid;
		vm.remove = remove;
		vm.openFile = openFile;
		vm.isAddMode = isAddMode;
		vm.isEditMode = isEditMode;
		vm.isAddOrEditMode = isAddOrEditMode;
		vm.isViewOnlyMode = isViewOnlyMode;
		vm.isShowComments = isShowComments;
		vm.canDeleteFile = canDeleteFile;
		vm.canEditFileDescription = canEditFileDescription;
		vm.getAddBy = getAddBy;

		vm.errorMessage = '';
		vm.uploadErrorMessage = '';

		vm.data = dataManagementService.getSharedData();
		vm.data.attachments = vm.data.attachments || [];
		vm.data.getUploadedFiles = getUploadedFiles;
		vm.data.isValid = isValid;
		vm.data.initialize = initialize;
		vm.data.savingFile = false;
		vm.fileItems = [];

		initFileUploader(vm);
		
		function initialize() {
			vm.errorMessage = '';
			vm.uploadErrorMessage = '';
			$log.debug('initialize');
		}
		
		function isValid(showError) {
			if (vm.data.savingFile) {
				vm.errorMessage = vm.errorMessageSavingFile;
				return false;
			}
			var attachments = getAttachments();

			if (vm.isAddMode()) {
				if (attachments && attachments.length && !vm.data.agreement) {
					if (showError) {
						vm.agreementErrorMessage = appI18n.translateImmediate('data-management.addAttachment.errors.agreementRequired');
					}
					return false;
				}
			}
			
			vm.data.attachments = attachments;
			
			return true;
		}

		function getAttachments() {
			var attachments = vm.data.attachments && vm.data.attachments.filter(function (attachment) {
				return !attachment.errorMessage;
				});
			return attachments;
		}

		function clearAgreementErrorMessage() {
			var attachments = getAttachments();
			if (attachments === null || attachments.length === 0) {
				vm.agreementErrorMessage = '';
			}
		}

		function getUploadedFiles() {
			return vm.data.attachments && vm.data.attachments.filter(function (attachment) {
					return !attachment.errorMessage;
				});
		}
		
		function remove(index) {
			showConfirm(index);
		}
		function removeInternal(index, Comments) {
			var file = vm.data.attachments[ index ];
			var item = _.find(vm.fileItems, { index: file.index });
			if (file && file.url && file.url.length > 0) {
				var model = {
					queryId: file.queryId || 0,
					id: file.id || 0,
					url: file.url,
					comments: Comments
				};
				remoteFileService.deleteDcrFile(model);
			}
			if (item) {
				try {
					item.fileItem.remove();
				} catch (ex) {
					$log.error('try to remove item:' + ex);
				}
			}
			_.remove(vm.fileItems, { index: file.index });
			vm.data.attachments.splice(index, 1);
			clearAgreementErrorMessage();

		}

		function showConfirm(index) {
			vm.requireDeleteFileReason = false;
			vm.invalidReason = false;

			const confirmationObj = {
				headerTitle: appI18n.translateImmediate('data-management.addAttachment.confirmDeleteFileTitle'),
				contentMsg: appI18n.translateImmediate('data-management.addAttachment.confirmDeleteFileMessage')
			};
			let confirmationType = 'confirmation';

			if (vm.data.attachments[index].queryId) {
				confirmationObj.textAreaControlName = 'reason';
				confirmationObj.textAreaConfig = {
					id: 'reason',
					label: appI18n.translateImmediate(
						'data-management.addAttachment.removeFileReasonTitle'),
					required: true,
					disabled: false,
					placeholder: appI18n.translateImmediate(
						'data-management.addAttachment.removeFileReasonPlaceholder')
				};
				confirmationType += 'TextArea';
			}

			popupService.show(confirmationType, confirmationObj)
				.then((resp) => {
					if (resp.answer === 'ok') {
						removeInternal(index, resp.reason);
					}
				});

		}

		function isValidFile(fileItem) {
			var file = fileItem.file;
			
			return isValidFileType(file) && isValidFileSize(file);
		}
		
		function isValidFileSize(item) {
			var maxSize = appConfig.fileUpload.paperPFT.fileMaxSize;
			var maxSizeMb = maxSize / 1048576;
			
			var result = item.size <= maxSize;
			if (!result) {
				vm.errorMessage = `${vm.invalidFileSizeMessage} of ${maxSizeMb}MB.`;
			}
			return result;
		}
		
		function isValidFileType(item) {
			var type = item.name.slice(item.name.lastIndexOf('.') + 1);
			type = (type || '').toLowerCase();
			var result = appConfig.fileUpload.dcr.validFileType.indexOf(type) !== -1;
			if (!result) {
				vm.errorMessage = vm.invalidFileTypeMessage;
			}
			return result;
		}
		
		function initFileUploader(vm) {
			vm.uploader = new FileUploader({
				url: remoteFileService.getDcrPostFileUrl(),
				autoUpload: true
			});
			
			vm.uploader.onProgressItem = function (fileItem, progress) {
				$log.log(`onProgressItem file: ${fileItem.file.name} progress: ${progress}`);
			};
			
			vm.uploader.onAfterAddingFile = function (fileItem) {
				vm.errorMessage = '';
				var index = -100;
				var errorMessage = '';
				var itemSaving = false;
				if (!isValidFile(fileItem)) {
					vm.uploader.removeFromQueue(fileItem);
					errorMessage = vm.errorMessage;
					vm.errorMessage = '';
				} else {
					itemSaving = true;
					vm.data.savingFile = true;
					index = vm.uploader.getIndexOfItem(fileItem);
				}

				vm.data.attachments.unshift({
					url: '',
					originalFilename: fileItem.file.name,
					comments: '',
					index: index,
					userIsInternal: vm.data.userIsInternal,
					saving: itemSaving,
					errorMessage: errorMessage,
					isNewFile: true
				});
				vm.fileItems.unshift({ fileItem: fileItem, index: index });
				$log.debug('onAfterAddingFile' + index, fileItem);
			};
			
			vm.uploader.onSuccessItem = function (fileItem, response, status, headers) {
				var index = vm.uploader.getIndexOfItem(fileItem);
				var item = _.find(vm.data.attachments, { index: index });
				if (item) {
					item.url = response.url;
					item.size = response.fileSize;
					item.sizeDisplay = response.fileSizeDisplay;
					if (item.sizeDisplay) {
						item.filenameDisplay = `${item.originalFilename} (${item.sizeDisplay})` ;
					}
					else {
						item.filenameDisplay = item.originalFilename;
					}
					$log.debug(item.url);
					item.index = -1;
					item.saving = false;
				}
				
				$log.log(`Success ${fileItem.file.name} uploaded. Response: ${response}`);
				$log.debug('onSuccessItem', fileItem, response, status, headers);
			};
			
			vm.uploader.onErrorItem = function (fileItem, response, status, headers) {
				var index = vm.uploader.getIndexOfItem(fileItem);
				var item = _.find(vm.data.attachments, { index: index });
				if (item) {
					if (response.message && response.message.indexOf('Virus') >= 0) {
						item.errorMessage = vm.saveErrorMessageVirus;
					} else {
						item.errorMessage = vm.saveErrorMessage;
					}
					item.saving = false;
				}
				$log.log('Error status:', response);
				$log.debug('onErrorItem', fileItem, response, status, headers);
			};
			
			vm.uploader.onCompleteAll = function () {
				vm.data.savingFile = false;
				vm.errorMessage = '';
				vm.uploader.clearQueue();
				vm.fileItems = [];
				$log.debug('onCompleteAll');
			};
		}
		
		function openFile(url, name) {
			remoteFileService.openDcrFile(url, name, true);
			$log.debug(`open file ${url}`);
		}
		
		function isAddMode() {
			return true;
		}
		
		function isEditMode() {
				return false;
		}
		
		function isAddOrEditMode() {
			return isAddMode() || isEditMode();
		}
		
		function isViewOnlyMode() {
			return vm.data.displayMode && (vm.data.displayMode === appConfig.dataEntryDisplayModes.review
				|| vm.data.displayMode === appConfig.dataEntryDisplayModes.viewOnly);
		}
		
		function isShowComments(comments) {
			if (!isViewOnlyMode()) {
				return true;
			}
			return !!comments;
		}
		
		function canEditFileDescription(item) {
			return !item.errorMessage && item.isNewFile;
		}

		function canDeleteFile(item) {
			if (item.errorMessage) {
				return false;
			}

			if (isAddMode()) {
				return true;
			}

			return false;
		}
		
		function getAddBy(item) {
			var result = '';
			if (isEditMode()) {
				if (item.userIsInternal !== null && item.userIsInternal !== vm.data.userIsInternal) {
					result = item.userIsInternal ? vm.addByERTMessage : vm.addBySiteMessage;
				}
			}
			return result;

		}
	}
})();
