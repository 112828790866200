class ertUnassignDeviceController {
	constructor(clinicalDataService, appI18n) {
		'ngInject';
		this.clinicalDataService = clinicalDataService;
		this.appI18n = appI18n;
	}

	$onInit() {
		this.submitEnabled = false;
		this.reasonSelectName = 'reasonSelect';
		this.reasonSelect = {
			id: this.subjectSelectName,
			label: this.appI18n.translateImmediate('deviceManagement.labels.reason'),
			required: true,
			disabled: false,
			placeholder: this.appI18n.translateImmediate('deviceManagement.labels.selectReason'),
			uiValue: 'reasonName'
		};
	}

	$postLink() {
	}

	reasonChange(newValue) {
		this.submitEnabled = !!newValue;
		if (newValue) {
			this.onReasonChange({ val: newValue });
		}
	}

	submit(form) {
		if (form.$valid) {
			this.onSubmit();
		} else {
			form.showValidation = true;
		}
	}

	cancel() {
		this.onCancel();
	}

}

export default ertUnassignDeviceController;
