/*
 * badgeModule
 * */

import badgeComponent from './badge.component';

const badgeModule = angular.module('app.common.badge', [])
	.component('badge', badgeComponent)
	.name;

export default badgeModule;
