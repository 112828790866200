class ConfirmationPopupController {
	constructor(appI18n, $q, $scope, $rootScope, reportsService,
				analyticsService, loadingIndicatorService, appSession, appConfig, scrollService,
				sideNavigationService) {
		'ngInject';
		this.appI18n = appI18n;
		this.$q = $q;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.appConfig = appConfig;
		this.appSession = appSession;
		this.loadingIndicatorService = loadingIndicatorService;
		this.reportsService = reportsService;
		this.sideNavigationService = sideNavigationService;
		this.analyticsService = analyticsService;
		this.scrollService = scrollService;
		this.headerTitle = null;
		this.show = false;
		this.resolve = null;
		this.reject = null;
		this.types = [];
		this.subjects = [];
	}

	$onInit() {
		const self = this;

		this.$API = {
			show(config) {
				self.headerTitle = config.headerTitle;
				self.show = true;
				self.scrollService.setScroll();
				self.reportId = config.reportId;
				self.reportRows = config.reportRows;

				if (self.reportId) {
					self.single = true;
				} else {
					self.single = false;
					const part = _.partition(self.reportRows, (r) => r.shouldConfirm === 1);

					self.reportsToConfirm = part[0];
					self.reportsNotConfirmable = part[1];
				}

				return self.$q((res, rej) => {
					self.resolve = res;
					self.reject = rej;
				});
			},

			hide(response) {
				self.show = false;
				self.scrollService.unsetScroll();
				self.resolve(response);
				self.cleanUpPromise();
			}
		};
		this.setButtons();
		if (!this.componentReady) {
			return;
		}
		this.componentReady({
			$API: this.$API
		});

	}

	$onDestroy() {
		this.scrollService.unsetScroll();
	}

	setButtons() {
		const self = this;

		self.closePopup = () => {
			self.$API.hide('cancel');
		};
		self.submitForm = () => {
			self.isSubmitting = true;
			let reportIds;

			if (self.single) {
				reportIds = [self.reportId];
			} else {
				reportIds = _.map(self.reportsToConfirm, 'id');
			}
			self.reportsService.confirmReports(reportIds)
				.then(() => {
					self.isSubmitting = false;
					self.$API.hide('ok');
					self.sideNavigationService.updateReportReviewBadge(reportIds.length);
				})
				.catch((error) => {
					self.isSubmitting = false;
					self.error = error.data && error.data.message;
				});
		};

	}
	cleanUpPromise() {
		this.resolve = null;
		this.reject = null;
	}
}

export default ConfirmationPopupController;
