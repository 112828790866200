const _ = require('lodash');

class KardiaPopupController {
	constructor($log, $q, loadingIndicatorService, clinicalDataService, ertBasePopupService,
		eproService, kardiaService, configurationService, appConfig, notificationService,
		appI18n) {
		'ngInject';
		this.$log = $log;
		this.$q = $q;
		this.loadingIndicatorService = loadingIndicatorService;
		this.kardiaService = kardiaService;
		this.clinicalDataService = clinicalDataService;
		this.notificationService = notificationService;
		this.ertBasePopupService = ertBasePopupService;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.setInitialOptions();
		this.onlineHelpId = '';
	}

	$onInit() {
		this.$log = this.$log.getInstance('KardiaPopupController');
		this.$log.debug('loaded');
		this.setApi();
		this.ertBasePopupService.register('kardiaPopup', this.$API);
	}

	$onDestroy() {
		this.ertBasePopupService.unregister('kardiaPopup');
	}

	setApi() {
		this.$API = {
			open: (countryId, siteId, subjectId, kardiaActionId, displaySiteName, subjectKey, registrationCode) => {
				this.reset();
				if (siteId) {
					this.siteId = +siteId;
				}
				if (countryId) {
					this.countryId = +countryId;
				}
				if (subjectId) {
					this.subjectId = subjectId;
				}

				this.kardiaActionId = kardiaActionId;
				this.initAction(kardiaActionId);

				this.loadingIndicatorService.show();
				if (siteId && subjectId) {
					this.sites = [{ displaySiteName }];
					this.subjects = [{ subjectKey }];
					this.preselectSite(this.sites[0]);
					this.preselectSubject(this.subjects[0]);

					if (kardiaActionId === this.appConfig.kardiaActionId.close) {
						this.showPopup();
						this.loadingIndicatorService.hide();

					} else if (kardiaActionId === this.appConfig.kardiaActionId.dispense &&
								registrationCode) {
						this.registrationCode = registrationCode;
						this.loading = false;
						this.showPopup();
						this.loadingIndicatorService.hide();
					} else {
						this.getAndShowRegistrationCode().then(() => {
							this.showPopup();
						}).finally(() => {
							this.loadingIndicatorService.hide();
						});
					}
				} else {
					this.loadSites().then(() => {
						this.showPopup();
					}).finally(() => {
						this.loadingIndicatorService.hide();
					});
				}
			},
			close: () => {
				this.open = false;
			}
		};
		this.componentReady && this.componentReady({ $API: this.$API });
	}

	initAction(kardiaActionId) {
		switch (kardiaActionId) {
			case this.appConfig.kardiaActionId.dispense:
				this.popupTitle = 'clinicaldata.subject.kardia.close-dispense.dispenseTitle';
				this.onlineHelpId = 'MSP3.ClinicalData.Kardia.DispenseDevice';
				break;
			case this.appConfig.kardiaActionId.close:
				this.showDispensedSubjects = true;
				this.popupTitle = 'clinicaldata.subject.kardia.close-dispense.closeTitle';
				this.description = 'clinicaldata.subject.kardia.close-dispense.closeText';
				this.onlineHelpId = 'MSP3.ClinicalData.Kardia.CloseDevice';
				this.actions = [{
					action: () => {
						this.submitCloseDeviceForm(this.closeKardiaForm, kardiaActionId);
					},
					cssClass: '-es-primary',
					displayName: 'app.buttons.submit'
				}, {
					action: () => {
						this.closePopup();
					},
					displayName: 'app.buttons.cancel'
				}];

				break;
		}
	}

	reset() {
		this.error = '';
		this.showDispensedSubjects = false;
		this.countryId = null;
		this.siteId = null;
		this.subjectId = null;
		this.description = '';
		this.actions = null;
		this.clearRegistrationCode();
		this.setInitialOptions();
	}

	setInitialOptions() {
		this.sites = null;
		this.sitesOptions = {
			label: 'app.common.siteId',
			disabled: false,
			id: 'sitesDropdown',
			required: true,
			placeholder: 'clinicaldata.subject.kardia.close-dispense.selectSite',
			uiValue: 'displaySiteName'
		};

		this.subjects = null;
		this.subjectsOptions = {
			label: 'app.common.subjectKey',
			disabled: true,
			id: 'subjectsDropdown',
			required: true,
			placeholder: 'clinicaldata.subject.kardia.close-dispense.selectSubject',
			uiValue: 'subjectKey'
		};
		this.hideLegend = false;
	}

	loadSites() {
		return this.clinicalDataService.getSitesMinimal(null, this.countryId).then((res) => {
			this.sites = res.data;
			if (res.data && res.data.length > 0) {
				this.sitesOptions.disabled = false;
				this.sitesOptions.placeholder = 'clinicaldata.subject.kardia.close-dispense.selectSite';
				if (this.siteId) {
					const site = _.find(this.sites, { siteId: String(this.siteId) });

					this.preselectSite(site);
					this.loadSubjects();
				}
			} else {
				this.sitesOptions.disabled = true;
				this.sitesOptions.placeholder = 'clinicaldata.subject.kardia.close-dispense.noSite';
			}
		});
	}

	preselectSite(site) {
		this.preselectedSite = site;
		this.sitesOptions.disabled = true;
		this.sitesOptions.required = false;
	}

	preselectSubject(subject) {
		this.preselectedSubject = subject;
		this.subjectsOptions.disabled = true;
		this.subjectsOptions.required = false;
		this.hideLegend = true;
	}

	clearRegistrationCode() {
		this.registrationCode = null;
	}

	getAndShowRegistrationCode() {
		return this.getAndSetRegistrationCode(this.siteId, this.subjectId, this.kardiaActionId);
	}

	showRegistrationCodeResult(code) {
		this.registrationCode = code;
		this.actions = [];
		this.sitesOptions.disabled = true;
		this.sitesOptions.required = false;
		this.subjectsOptions.disabled = true;
		this.subjectsOptions.required = false;
		this.hideLegend = true;
	}

	onSiteChange(newVal) {
		this.clearRegistrationCode();
		if (!newVal) {
			this.subjects = null;
			this.subjectId = null;
			this.subjectsOptions.disabled = true;
			return;
		}
		this.isLoading = true;
		this.siteId = +newVal.siteId;
		this.countryId = +newVal.countryId;
		this.loadSubjects().finally(() => {
			this.isLoading = false;
		});
	}

	loadSubjects() {
		return this.kardiaService.getSubjects(this.countryId, this.siteId, this.kardiaActionId).then((res) => {
			this.subjects = res.data;

			if (res.data && res.data.length > 0) {
				this.subjectsOptions.disabled = false;
				this.subjectsOptions.placeholder = 'clinicaldata.subject.kardia.close-dispense.selectSubject';
			} else {
				this.subjectsOptions.disabled = true;
				this.subjectsOptions.placeholder = 'clinicaldata.subject.kardia.close-dispense.noSubject';
			}
		});
	}

	onSubjectChange(newVal) {
		this.clearRegistrationCode();
		if (!newVal) {
			return;
		}

		this.subjectId = newVal.subjectId;

	}

	submitCloseDeviceForm(form, actionId) {
		if (form.$valid) {
			this.isLoading = true;
			this.kardiaService.closeDevice(this.countryId, this.siteId, this.subjectId, actionId)
				.then(() => {
					this.closePopup();
					this.showCloseTriggeredMessage();
				})
				.catch((error) => {
					if (error && error.data && error.data.message) {
						this.error = error.data.message;
					} else {
						this.error = this.appI18n.translateImmediate(
							'app.errors.genericLoadError');
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		} else {
			form.showValidation = true;
		}
	}

	showCloseTriggeredMessage() {
		this.appI18n.translate(['clinicaldata.subject.kardia.closeTriggeredMessage']).then((translations) => {
			const msg = translations['clinicaldata.subject.kardia.closeTriggeredMessage'];

			this.notificationService.showSuccess(msg);
		});

		return false;
	}


	getAndSetRegistrationCode(siteId, subjectId, actionId) {
		this.loading = true;
		return this.kardiaService.getRegistrationCode(siteId, subjectId, actionId).then((result) => {
			this.registrationCode = result.data.registrationCode;
			this.loading = false;
		});
	}

	closePopup() {
		this.$API.close();
	}

	showPopup() {
		this.open = true;
	}
}

export default KardiaPopupController;
