/*
 * deleteUserPopupController
 * */

class DeleteUserPopupController {
	constructor($log, $stateParams, $timeout, $state, appConfig, loadingIndicatorService,
				appI18n, studyPermissionsService, notificationService, signatureService) {
		'ngInject';
		this.$log = $log;
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.loadingIndicatorService = loadingIndicatorService;
		this.studyPermissionsService = studyPermissionsService;
		this.notificationService = notificationService;
		this.signatureService = signatureService;
		this.$timeout = $timeout;
	}

	$onInit() {
		this.$log = this.$log.getInstance('DeleteUserPopupController');
		this.$log.debug('loaded');

		this.loadingIndicatorService.show();

		if (this.signatureService.checkSignatureWorkflow(this.$stateParams.signKey, this.$stateParams.tokenId,
				this.doSubmit, this.onSignatureCancel, this)) {
			return;
		}

		this.initializePopup();
	}

	cancelAction() {
		this.open = false;
		this.$state.go('^');
	}

	openPopup() {
		this.open = true;
	}

	initializePopup() {
		this.appI18n.translate(['study-permissions.messages.userDeleteConfirmation']).then((t) => {
			this.confirmationMsg = t['study-permissions.messages.userDeleteConfirmation'];
		}).then(() => {
			return this.studyPermissionsService.getUser(
				this.$stateParams.siteId,
				this.$stateParams.userId,
				this.$stateParams.isSiteAdmin,
				this.$stateParams.countryId);
		}).then((user) => {
			this.user = user;
			this.userHasPiRole = this.checkHasPiRole(user);
			this.userHasShippingContactRole = this.checkHasShippingContactRole(user);
			this.setActions();
			this.openPopup();
		}, (/*error*/) => {
			this.notificationService.showError(this.appI18n.translateImmediate('app.toaster.tryLaterError'));
			this.cancelAction();
		}).finally(() => {
			this.loadingIndicatorService.hide();
		});
	}

	checkHasPiRole(user) {
		return !!user.assignedRoles.find((role) => {
			return role.roleId === this.appConfig.roleIds.principalInvestigator;
		});

	}

	checkHasShippingContactRole(user) {
		return !!user.assignedRoles.find((role) => {
			return role.roleId === this.appConfig.roleIds.shippingContact;
		});
	}

	setActions() {
		this.signSubmitBtn = {
			action: () => {
				this.initiateSignature();
			},
			cssClass: '-es-primary',
			displayName: 'app.buttons.sign-submit',
			isDisabled: this.userHasPiRole || this.userHasShippingContactRole
		};

		this.cancelBtn = {
			displayName: 'app.buttons.cancel',
			action: () => {
				this.cancelAction();
			}
		};
		this.actions = [
			this.signSubmitBtn,
			this.cancelBtn
		];
	}

	initiateSignature() {

		const sigType = this.appConfig.sigType.deleteUser;

		// save state for signature process
		const state = {
			sigType: this.sigType,
			user: this.user
		};

		const additionalInfo = {
			action: this.appI18n.translateImmediate('signature.action.deleteUser'),
			infoKey1: this.appI18n.translateImmediate('signature.info.site'),
			infoValue1: this.user.sponsorSiteId,
			infoKey2: this.appI18n.translateImmediate('signature.info.subject'),
			infoValue2: this.user.userId
		};

		this.signatureService.sign(sigType, additionalInfo, state);
	}

	onSignatureCancel(/*state*/) {
		this.initializePopup();
	}

	doSubmit(state) {
		// loading indicator is already shown
		const payload = this.getDeleteUserPayload(state);

		this.studyPermissionsService.deleteUser(payload).then((/*response*/) => {
			this.notificationService.showSuccess(
				this.appI18n.translateImmediate('study-permissions.messages.deleteSiteUserDelayMessage')
			);
		}, (error) => {
			this.$log.error(error);
			this.notificationService.showError(this.appI18n.translateImmediate('app.toaster.tryLaterError'));
		}).finally(() => {
			this.$state.go('^');
			this.$timeout(() => {
				this.loadingIndicatorService.hide();
			});
		});

	}

	getDeleteUserPayload(state) {
		const user = state.user;

		return {
			accessLevel: user.accessLevel,
			protocolId: user.protocolId,
			countryId: user.countryId,
			siteId: user.siteId,
			userId: user.personId
		};
	}
}

export default DeleteUserPopupController;
