/*
 * ertGridController
 * */

const cs = require('../../scripts/utils/custom');
const toPascalCase = cs.toPascalCase;
const toCamelCase = cs.toCamelCase;
const saveReport = cs.saveReport;
const DataExport = require('../../scripts/utils/gridExporter');
const _ = require('lodash');

class ErtGridController {

	constructor($scope, $stateParams, $log, $filter, $location, $timeout,
				$window, oDataHelper, appConfig, appI18n, $document,
				analyticsService, dialogService, loadingIndicatorService) {
		'ngInject';
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.loadingIndicatorService = loadingIndicatorService;
		this.$log = $log;
		this.$filter = $filter;
		this.$document = $document;
		this.$window = $window;
		this.$location = $location;
		this.$timeout = $timeout;
		this.oDataHelper = oDataHelper;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.analyticsService = analyticsService;
		this.dialogService = dialogService;
	}

	$onDestroy() {
		angular.element(this.$window).off(`resize.ertGrid-${this.gridId}`);
	}

	$onInit() {
		this.$log = this.$log.getInstance('ErtGrid', 'color:yellow; font-size:1em; background-color:black;');
		this.$log.debug(' loaded');

		this.gridControlsLoading = true;
		this.filterRuleAction = this.filterRuleActions();

		this.currentEnumFilterColumn = {};
		this.currentFilterColumn = {};
		this.filters = [];
		this.filtersDisplay = [];
		this.filter = {};
		this.gridData = [];

		this.options.sortOrderCssClass = '';
		this.options.currentPage = 1;

		this.intervalPercentage = 0;
		this.gridId = this.$scope.$id;

		this.activate();
	}

	activate() {
		const self = this;

		if (!this.options.removePaginationControls) {
			this.pageSize = this.options.pageSize || this.appConfig.constants.gridPageSize;
		}

		this.checkAllTxtTemplate = this.appI18n.translateImmediate('grid.header.select-all-text');
		this.clickCheckAllTxtTemplate = this.appI18n.translateImmediate('grid.header.click-select-all-text');
		this.checkAllGroupTxt = this.appI18n.translateImmediate('grid.header.select-all-group-text');

		this.$scope.$watch(() => {
			return this.gridData;
		}, () => {
			this.onGridDataChange();
		});

		this.options.expandAllVisible = true;
		this.legacyCompatibilityWarningHandler = {
			showDialog: false
		};

		if (this.options.autoUpdateSeconds) {
			this.$scope.$watch('vm.intervalPercentage', () => {
				if (!this.isRowDataLoadingComplete && this.intervalPercentage >= 100) {
					this.checkForUpdate();
				}
			});
		}

		this.appI18n.translate([
			'grid.header.select-value',
			'grid.header.select-column',
			'grid.header.filter-to',
			'grid.header.filter-input',
			'grid.error.report-not-available'])
			.then((translations) => {
				this.selectValueTxt = translations['grid.header.select-value'];
				this.selectColumnTxt = translations['grid.header.select-column'];
				this.filterInputTxt = translations['grid.header.filter-input'];
				this.toFilterTxt = translations['grid.header.filter-to'];
				this.reportNotAvailableTxt = translations['grid.error.report-not-available'];

				const unbindColumnDefs = this.$scope.$watch(() => {
					return !!self.options.columnDefs && !!self.load;
				}, () => {
					if (this.options.columnDefs && this.load) {
						this.setPreDefinedFilters();
						this.setSortData();
						this.gridControlsLoading = false;
						this.initGrid();
						unbindColumnDefs();
					}
				});

				this.$scope.$watch('vm.reload', () => {
					if (this.reload) {
						this.initGrid(true);
						this.reload = false;
					}
				});

				if (!this.selectedRows) {
					this.selectedRows = [];
				}
			});

	}

	// GROUPING
	expandAll() {
		this.options.expandAllVisible = false;
		this.getGroupHeaders().forEach((el) => {
			if (el.itemCount > 0) {
				el.uncollapsed = true;
			}
		});
	}

	collapseAll() {
		this.options.expandAllVisible = true;
		this.getGroupHeaders().forEach((el) => {
			if (el.itemCount > 0) {
				el.uncollapsed = false;
			}
		});
	}

	getGroupMembers(id) {
		const result = [];

		if (this.options.rowData) {
			this.options.rowData.forEach((row) => {
				const rowIdentifier = this.options.rowDefs.groupHeader.groupByFieldName(row);

				if (rowIdentifier === id) {
					result.push(row);
				}
			});
		}
		return result;
	}

	getRules(id) {
		const res = [];

		if (this.options.rowData) {
			this.options.rowData.forEach((row) => {
				const rowIdentifier = this.options.rowDefs.groupHeader.groupByFieldName(row);

				if (rowIdentifier === id && this.options.rowDefs.groupHeader.rules) {
					res.push(this.options.rowDefs.groupHeader.rules(row));
				}
			});
		}
		return res;
	}

	sortTopRulesByIdAndAction(rulesArr) {
		let res = _.flatten(rulesArr);

		res = _.filter(res, (r) => !_.isUndefined(r));

		res = _.orderBy(res, ['orderId']);

		res = _.groupBy(res, (item) => {
			return item.action;
		});

		res = _.sortBy(res, (group) => {
			return group[0].orderId;
		});

		return _.flatten(res);
	}

	getFormattedGroupRules(groupId) {
		return this.sortTopRulesByIdAndAction(this.getRules(groupId));
	}

	filterRuleActions() {
		let actionName = '';

		return (key, rule) => {
			if (key === 0) {
				actionName = '';
			}
			if (actionName !== rule.action) {
				actionName = rule.action;
				return true;
			}
			return false;
		};
	}

	toggleGroup(group) {
		group.uncollapsed = !group.uncollapsed;
		if (this.groupHeaders) {
			for (let i = 0; i < this.groupHeaders.length; i++) {
				if (this.groupHeaders[i].itemCount > 0 && this.groupHeaders[i].uncollapsed !== group.uncollapsed) {
					return;
				}
			}
		}
		this.options.expandAllVisible = !group.uncollapsed;
	}

	getGroupColumnDefs(row) {
		return this.options.columnDefs.filter((el) => {
			return !el.restrictToGroup || el.restrictToGroup(row);
		});
	}

	getGroupRowActions(row) {
		return this.options.gridRowActions.filter((el) => {
			return !el.restrictToGroup || el.restrictToGroup(row);
		});
	}

	getGroupMultiSelection(row) {
		return this.options.rowDefs.multiSelection && this.options.rowDefs.restrictSelectionToGroup &&
			this.options.rowDefs.restrictSelectionToGroup(row);
	}

	getGroupHeaders() {
		if (this.options.rowDefs.groupHeader.groups) {
			return this.options.rowDefs.groupHeader.groups;

		}
		this.options.rowDefs.groupHeader.groups = [];

		if (this.options.rowData) {
			for (let b = this.options.rowData.length - 1; b >= 0; b--) {
				const row = this.options.rowData[b];
				const field = this.options.rowDefs.groupHeader.groupByFieldName &&
					  this.options.rowDefs.groupHeader.groupByFieldName(row);

				if (field) {
					if (!_.some(this.options.rowDefs.groupHeader.groups, (el) => {
						return el.id === field;
					})) {
						this.options.rowDefs.groupHeader.groups.unshift({
							id: field,
							groupMultiSelection: this.getGroupMultiSelection(row),
							groupRowActions: this.getGroupRowActions(row),
							groupColumnDefs: this.getGroupColumnDefs(row),
							name: this.options.rowDefs.groupHeader.groupByDisplayName(row),
							itemCount: this.options.rowDefs.groupHeader.numberOfItems(row),
							descriptionArray: this.options.rowDefs.groupHeader.hideGroupDescription
								? '' : this.options.rowDefs.groupHeader.descriptionArray(row),
							descriptionCssClass: this.options.rowDefs.groupHeader.hideGroupDescription
								? '' : this.options.rowDefs.groupHeader.descriptionCssClass,
							actions: this.options.rowDefs.groupHeader.actions
						});
					}
				}
			}
		}
		this.setGroupExpanded();

		return this.options.rowDefs.groupHeader.groups;
	}

	setGroupExpanded() {
		let groupExpanded = false;

		if (this.options.rowDefs.groupHeader.expandGroupByNameOrFirst) {
			const groupName = this.options.rowDefs.groupHeader.expandGroupByNameOrFirst();

			if (groupName) {
				for (let i = 0; i < this.options.rowDefs.groupHeader.groups.length; i++) {
					if (this.options.rowDefs.groupHeader.groups[i].name === groupName) {
						this.options.rowDefs.groupHeader.groups[i].uncollapsed = true;
						groupExpanded = true;
						break;
					}
				}
			} else {
				for (let j = 0; j < this.options.rowDefs.groupHeader.groups.length; j++) {
					if (this.options.rowDefs.groupHeader.groups[j].itemCount > 0) {
						this.options.rowDefs.groupHeader.groups[j].uncollapsed = true;
						groupExpanded = true;
						break;
					}
				}
			}
			if (!groupExpanded && this.options.rowDefs.groupHeader.groups.length > 0 &&
				this.options.rowDefs.groupHeader.groups[0].itemCount > 0) {
				this.options.rowDefs.groupHeader.groups[0].uncollapsed = true;
			}

		}
	}

	checkItemsInGroup(group) {
		const rowIdentifier = this.options.rowDefs.identifier;

		group.checked = !group.checked;
		this.options.rowData.forEach((el) => {
			if (this.options.rowDefs.groupHeader.groupByFieldName(el) === group.id) {
				el.selected = group.checked;
				const index = _.findIndex(this.selectedRows, (r) => {
					return r[rowIdentifier] === el[rowIdentifier];
				});

				if (group.checked) {
					if (index < 0) {
						this.selectedRows.push(el);
					}
				} else {
					this.selectedRows.splice(index, 1);
				}
			}
		});
		this.onRowsSelected && this.onRowsSelected({ rows: this.selectedRows });
	}

	getAllItemsInGroupSelectedText(group) {
		return supplant(this.checkAllGroupTxt, [group.itemCount]);
	}

	getRangeForGroup(group) {
		let groupMin;
		let groupMax;
		let range = '';

		if (group.rangeMin) {
			groupMin = this.$filter('date')(group.rangeMin, 'dd-MMM-yyyy');
			range = groupMin;
		}
		if (group.rangeMax) {
			groupMax = this.$filter('date')(group.rangeMax, 'dd-MMM-yyyy');
			if (groupMin !== groupMax) {
				range += ` - ${groupMax}`;
			}
		}
		return range;
	}

	// Filtering
	handleKeypress($event) {
		$event.stopPropagation();
		$event.preventDefault();
		return true;
	}

	setPreDefinedFilters() {
		// check if a filter is already set
		let stateParamName = this.getPrefixedStateParam('filter');

		if (this.$stateParams[stateParamName] && !this.$stateParams.popup) {
			this.processFilterQuery(this.$stateParams[stateParamName]);
		} else if (this.options.preSelectedFilters && !this.$stateParams.signKey && !this.$stateParams.tokenId) {
			this.options.preSelectedFilters.forEach((el) => {
				this.addToFilterArray(el);
			});
		}
		// set order by
		stateParamName = this.getPrefixedStateParam('orderBy');
		if (this.$stateParams[stateParamName]) {
			this.processOrderByQuery(this.$stateParams[stateParamName]);
		}
	}

	getEnumValuesForFilterColumn() {
		const column = this.options.columnDefs.filter((el) => {
			return el.fieldName === this.currentFilterColumn.fieldName;
		})[0];

		if (!this.currentEnumFilterColumn.displayName) {
			this.currentEnumFilterColumn.displayName = this.selectValueTxt;
		}
		return column.enums;
	}

	setDefaultFilter() {
		const filterOptions = [];

		this.options.columnDefs.forEach((columnDef) => {
			filterOptions.push({
				fieldName: columnDef.fieldName,
				displayName: columnDef.displayName,
				type: columnDef.type,
				isQuickFilter: columnDef.isQuickFilter,
				isDefaultFilter: columnDef.isDefaultFilter,
				enumType: columnDef.enumType,
				removeFromFilter: columnDef.removeFromFilter,
				isHidden: columnDef.isHidden,
				enums: columnDef.enums
			});
			if (columnDef.additionalFilters) {
				columnDef.additionalFilters.forEach((additionalFilter) => {
					filterOptions.push({
						fieldName: columnDef.fieldName,
						displayName: additionalFilter.displayName,
						type: columnDef.type,
						isQuickFilter: true,
						isDefaultFilter: additionalFilter.isDefaultFilter,
						from: additionalFilter.from,
						to: additionalFilter.to,
						value: additionalFilter.value,
						enumValues: additionalFilter.enumValues
					});
				});
			}
		});

		this.filterDropdownOptions = filterOptions;

		// set configured filter column
		this.defaultFilters = filterOptions.filter((el) => {
			return el.isDefaultFilter;
		});
		if (this.defaultFilters && this.defaultFilters.length > 0) {
			this.changeCurrentFilterColumn(
				this.defaultFilters[0].displayName, this.defaultFilters[0].fieldName,
				this.defaultFilters[0].type, this.defaultFilters[0].enumType,
				this.defaultFilters[0].isQuickFilter);
		} else if (filterOptions) {
			// if no default column is set, use the first available
			const column = _.find(
				_.orderBy(filterOptions, ['displayName'], ['asc']), (c) => {
					return !c.isHidden;
				});

			if (column) {
				this.changeCurrentFilterColumn(
					column.displayName, column.fieldName,
					column.type, column.enumType, column.isQuickFilter);
			}
		}
	}

	getPaste(ev) {
		return ev.originalEvent.clipboardData.getData('text/plain');
	}

	changeCurrentEnumFilterColumn(displayName, values, operator) {
		this.currentEnumFilterColumn.displayName = displayName;
		this.currentEnumFilterColumn.values = values;
		this.currentEnumFilterColumn.operator = operator;
	}

	changeCurrentFilterColumn(displayName, fieldName, type, enumType, isQuickFilter) {
		this.currentFilterColumn.displayName = displayName;
		this.currentFilterColumn.fieldName = fieldName;
		this.currentFilterColumn.type = type;
		this.currentFilterColumn.enumType = enumType;
		this.currentFilterColumn.isQuickFilter = isQuickFilter,
		this.currentEnumFilterColumn = {};
		this.resetFilterFields();
		if (this.currentFilterColumn.isQuickFilter) {
			if (this.currentFilterColumn.type === 'date') {
				const filter = _.find(this.filterDropdownOptions, { fieldName, displayName });

				this.filter.dateFrom = filter.from;
				this.filter.dateTo = filter.to;
			}

		}
	}

	resetFilterFields() {
		switch (this.currentFilterColumn.type) {
			case 'number':
				this.filter.dateFrom = '';
				this.filter.dateTo = '';
				this.filter.text = '';
				break;
			case 'string':
				this.filter.dateFrom = '';
				this.filter.dateTo = '';
				this.filter.numberFrom = '';
				this.filter.numberTo = '';
				this.filter.text = '';
				break;
			case 'id':
				this.filter.dateFrom = '';
				this.filter.dateTo = '';
				this.filter.numberFrom = '';
				this.filter.numberTo = '';
				this.filter.text = '';
				break;
			case 'date':
				this.filter.numberFrom = '';
				this.filter.numberTo = '';
				this.filter.text = '';
				break;
			case 'enum':
				this.filter.numberFrom = '';
				this.filter.numberTo = '';
				this.filter.text = '';
				this.filter.dateFrom = '';
				this.filter.dateTo = '';
				break;
		}
	}

	isApplyFilterEnabled(filter) {
		return this.currentFilterColumn.fieldName !== '' && ((this.currentEnumFilterColumn.displayName &&
				this.currentEnumFilterColumn.displayName !== this.selectValueTxt) ||
			(filter.text && filter.text !== ''));
	}

	isApplyFilterVisible(filter) {
		if (this.filters.length > 0) {

			if (this.isEnumFilterEnabled()) {
				return this.filters[0].fieldName !== this.currentFilterColumn.fieldName ||
					this.filtersDisplay[0].displayValue !== this.currentEnumFilterColumn.displayName;
			} else {
				return this.currentFilterColumn.fieldName !== '' &&
					filter.text !== '' &&
					(this.filters[0].filterValue !== filter.text ||
						this.filters[0].fieldName !== this.currentFilterColumn.fieldName);
			}
		}
		return true;
	}

	isAddFilterEnabled(filter) {
		return this.currentFilterColumn.fieldName !== '' &&
			((this.currentEnumFilterColumn.displayName &&
					this.currentEnumFilterColumn.displayName !== this.selectValueTxt) ||
				(filter.text && filter.text !== '') || (filter.numberFrom || filter.numberTo) ||
			 ((filter.dateFrom && this.isDateValid(filter.dateFrom)) ||
			  (filter.dateTo && this.isDateValid(filter.dateTo))));
	}

	isTextFilterEnabled() {
		return !this.currentFilterColumn.isQuickFilter && this.currentFilterColumn.type === 'string';
	}

	isDateRangeFilterEnabled() {
		return !this.currentFilterColumn.isQuickFilter && this.currentFilterColumn.type === 'date';
	}

	isEnumFilterEnabled() {
		return !this.currentFilterColumn.isQuickFilter && this.currentFilterColumn.type === 'enum';
	}

	isNumberRangeFilterEnabled() {
		return !this.currentFilterColumn.isQuickFilter && this.currentFilterColumn.type === 'number';
	}

	isExactNumberFilterEnabled() {
		return !this.currentFilterColumn.isQuickFilter && this.currentFilterColumn.type === 'id';
	}

	isDateValid(date) {
		return date && moment(date, 'DD-MMM-YYYY', true).isValid();
	}

	addFilter(filter) {
		let newFilter = {};

		if (this.currentFilterColumn.fieldName !== '') {
			newFilter = {
				fieldName: this.currentFilterColumn.fieldName,
				displayName: this.currentFilterColumn.displayName,
				filterConditions: []
			};

			if (filter.text) {
				if (this.isExactNumberFilterEnabled()) {
					newFilter.filterConditions.push({
						displayValue: filter.text,
						filterValue: filter.text,
						operator: 'equals',
						type: 'number'
					});
				} else {
					newFilter.filterConditions.push({
						displayValue: filter.text,
						filterValue: filter.text,
						operator: 'contains',
						type: 'string'
					});
				}
			}

			if (this.isDateValid(this.getDate(filter.dateFrom))) {
				newFilter.filterConditions.push({
					displayValue: this.getFormatedFilterDate(filter.dateFrom, 'dd-MMM-yyyy'),
					filterValue: this.getFormatedFilterDate(filter.dateFrom, 'yyyy-MM-dd'),
					operator: 'ge',
					type: 'date'
				});
			}

			if (this.isDateValid(this.getDate(filter.dateTo))) {
				newFilter.filterConditions.push({
					displayValue: this.getFormatedFilterDate(filter.dateTo, 'dd-MMM-yyyy', true),
					filterValue: this.getFormatedFilterDate(filter.dateTo, 'yyyy-MM-dd', true),
					operator: 'le',
					type: 'date'
				});
			}
			if (filter.numberFrom && angular.isNumber(parseInt(filter.numberFrom, 10))) {
				newFilter.filterConditions.push({
					displayValue: filter.numberFrom,
					filterValue: filter.numberFrom,
					operator: 'ge',
					type: 'number'
				});
			}
			if (filter.numberTo && angular.isNumber(parseInt(filter.numberTo, 10))) {
				newFilter.filterConditions.push({
					displayValue: filter.numberTo,
					filterValue: filter.numberTo,
					operator: 'le',
					type: 'number'
				});
			}
			if (this.currentEnumFilterColumn && this.currentEnumFilterColumn.values &&
				this.currentEnumFilterColumn.displayName !== '') {

				this.currentEnumFilterColumn.values.forEach((el) => {
					const filterObj = {
						displayValue: this.currentEnumFilterColumn.displayName,
						filterValue: el,
						type: 'enum',
						enumType: this.currentFilterColumn.enumType
					};

					if (this.currentEnumFilterColumn.operator) {
						filterObj.operator = this.currentEnumFilterColumn.operator;
					} else {
						filterObj.operator = 'eq';
					}
					newFilter.filterConditions.push(filterObj);
				});
			}

			if (newFilter.filterConditions.length > 0) {
				this.addToFilterArray(newFilter);
			}
		}
		this.options.currentPage = 1;
		this.gridData = [];

		const filterInfo = this.filters.map((item) => item.fieldName).join();

		this.analyticsService.trackEvent(this.appConfig.trackCategory.gridFilter, filterInfo);
		this.updateData();
	}

	getFormatedFilterDate(dateIn, dateFormat, isToDate) {
		let date = dateIn;
		const dates = date.split(/ |-|\//);

		//only have month and year
		if (dates.length === 2) {
			date = `1 ${dates[0]} ${dates[1]}`;

			if (isToDate) {
				date = moment(this.getDate(date)).endOf('month').format('DD MMM YYYY');
			}

		} else if (dates.length === 1) {
			//only have year
			date = isToDate ? `31 Dec ${dates[0]}` : `1 Jan ${dates[0]}`;
		}

		return this.$filter('date')(this.getDate(date), dateFormat);
	}

	getDate(date) {
		if (!date) {
			return null;
		}
		//firefox new date doesn't work with date string has "-"
		date = date.replace(/-/g, ' ');
		return new Date(date);
	}

	addToFilterArray(filterObj) {
		// overwrite existing filters with same fieldName
		this.filters = this.filters.filter((el) => {
			return el.fieldName !== filterObj.fieldName;
		});
		this.filtersDisplay = this.filtersDisplay.filter((el) => {
			return el.fieldName !== filterObj.fieldName;
		});

		filterObj.filterConditions.forEach((item) => {
			this.filters.push({
				fieldName: filterObj.fieldName,
				operator: item.operator,
				filterValue: item.filterValue,
				type: item.type,
				enumType: item.enumType
			});
		});

		this.filtersDisplay.push({
			fieldName: filterObj.fieldName,
			displayName: filterObj.displayName,
			displayValue: this.getFilterDisplayValue(filterObj)
		});
	}

	getFilterDisplayValue(filterObj) {
		let cond = {};

		if (filterObj.filterConditions.length > 1 && filterObj.filterConditions[0].type !== 'enum') {
			return supplant('{0} {1} {2}', [filterObj.filterConditions[0].displayValue,
							this.toFilterTxt,
							filterObj.filterConditions[1].displayValue]);
		}

		cond = filterObj.filterConditions[0];
		if (cond.type === 'string' || cond.type === 'enum' || cond.type === 'number' ||
			cond.type === 'id') {
			return cond.displayValue;
		}

		if (cond.operator === 'ge') {
			return supplant('{0} {1}', [cond.displayValue, this.toFilterTxt]);
		}

		if (cond.operator === 'le') {
			return supplant('{0} {1}', [this.toFilterTxt, cond.displayValue]);
		}
	}

	clearFilter(filter) {
		this.gridData = [];
		if (filter && filter.fieldName) {
			this.filters = this.filters.filter((el) => {
				return el.fieldName !== filter.fieldName;
			});
			this.filtersDisplay = this.filtersDisplay.filter((el) => {
				return el.fieldName !== filter.fieldName;
			});
		} else {
			this.filters = [];
			this.filtersDisplay = [];

			this.filter.text = '';
			this.filter.dateFrom = '';
			this.filter.dateTo = '';
			this.filter.numberFrom = '';
			this.filter.numberTo = '';

			this.currentEnumFilterColumn = {
				displayName: this.selectValueTxt
			};
			this.setDefaultFilter();
		}
		this.options.currentPage = 1;
		this.updateData();
	}

	enterAction() {
		if (this.isAddFilterEnabled(this.filter)) {
			this.addFilter(this.filter);
		}
	}

	// Sorting
	setSortData() {
		if (this.options.sortOrders) {
			this.options.sortOrders.forEach((order) => {
				const column = this.options.columnDefs.find((c) => {
					return order.fieldName === c.fieldName;
				});

				if (column) {
					column.sortOrder = order.reverseOrder || false;
					if (column.showReverseSortOrder) {
						column.sortOrder = !column.sortOrder;
					}
				}
			});
		}
	}

	sort(fieldName) {
		this.options.columnDefs.forEach((c) => {
			c.sortOrder = undefined;
		});

		this.gridData = [];
		const el = this.options.sortOrders &&
			this.options.sortOrders.find(o => o.fieldName === fieldName);

		this.options.sortOrders = [];
		if (el) {
			el.reverseOrder = !el.reverseOrder;
			this.options.sortOrders.push(el);
		} else {
			this.options.sortOrders.push({
				fieldName,
				reverseOrder: false
			});
		}
		this.setSortData();

		const sortInfo = '${el.fieldName} | reverse order: ${el.reverseOrder}';

		this.$log.debug('sort: ${sortInfo}}');
		this.analyticsService.trackEvent(this.appConfig.trackCategory.gridSort, sortInfo);

		this.updateData();
	}

	// Style
	getDocumentIconCssClass(fileEnding) {
		if (!fileEnding) {
			return 'es-icon-file-alt';
		}
		const ending = fileEnding.toLowerCase();

		if (this.appConfig.documentIconTypes.word.includes(ending)) {
			return 'es-icon-file-word';
		}
		if (this.appConfig.documentIconTypes.pdf.includes(ending)) {
			return 'es-icon-file-pdf';
		}
		if (this.appConfig.documentIconTypes.excel.includes(ending)) {
			return 'es-icon-file-excel';
		}
		if (this.appConfig.documentIconTypes.powerpoint.includes(ending)) {
			return 'es-icon-file-powerpoint';
		}
		if (this.appConfig.documentIconTypes.image.includes(ending)) {
			return 'es-icon-file-image';
		}
		if (this.appConfig.documentIconTypes.text.includes(ending)) {
			return 'es-icon-file-text';
		}
		if (this.appConfig.documentIconTypes.xml.includes(ending)) {
			return 'es-icon-file-code';
		}
		if (this.appConfig.documentIconTypes.zip.includes(ending)) {
			return 'es-icon-file-alt';
		}

		return 'es-icon-file-alt';
	}

	getIconCssClass(gridRowAction) {
		let cssClasses = 'es-icon';

		if (gridRowAction.iconCssClass === 'icon icon-create' ||
			gridRowAction.iconCssClass === 'icon-create') {
			cssClasses += ' es-icon-android-add-circle';
		} else if (gridRowAction.iconCssClass === 'icon icon-delete' ||
			gridRowAction.iconCssClass === 'icon-delete') {
			cssClasses += ' es-icon--delete';
		} else if (gridRowAction.iconCssClass === 'icon icon-edit' ||
			gridRowAction.iconCssClass === 'icon-edit') {
			cssClasses += ' es-icon--edit';
		} else if (gridRowAction.iconCssClass === 'icon icon-answer' ||
			gridRowAction.iconCssClass === 'icon-answer') {
			cssClasses += ' es-icon-reply';
		} else if (gridRowAction.iconCssClass) {
			cssClasses += ` ${gridRowAction.iconCssClass}`;
		} else {
			cssClasses += ' es-icon-base';
		}
		return cssClasses;
	}

	// Export
	exportGrid(type) {
		if (angular.isDefined(typeof Blob)) {
			this.exportGridData(type);
		} else {
			this.legacyCompatibilityWarningHandler = this.dialogService.createConfirmHandler();
			this.legacyCompatibilityWarningHandler.show().then(() => {
				this.legacyCompatibilityWarningHandler.hide();
			});
		}
	}

	exportGridData(type) {
		const query = this.getODataQuery(true);

		this.loadingIndicatorService.show();
		const forExport = true;

		this.options.getData(query.toUri(), forExport)
			.then((response) => {
				const rowData = response.data || response;
				const fileName = 'Export';
				const groupHeader = (this.options.rowDefs && this.options.rowDefs.groupHeader)
					  ? this.options.rowDefs.groupHeader
					  : null;

				if (type === 'xslx') {
					const wbout = DataExport.gridExportXslx(
						this.options.columnDefs, groupHeader, rowData, this.$filter);

					const buf = new ArrayBuffer(wbout.length);
					const view = new Uint8Array(buf);

					for (let i = 0; i !== wbout.length; ++i) {
						view[i] = wbout.charCodeAt(i) & 0xFF;
					}
					saveReport(buf, fileName, 'xlsx',
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
				} else {
					const csv = DataExport.gridExportCsv(this.options.columnDefs, groupHeader, rowData, this.$filter);

					saveReport(csv, fileName, 'csv',
						'text/csv;charset=utf-8;');
				}
				this.loadingIndicatorService.hide();
			}, () => {
				this.loadingIndicatorService.hide();
			});
	}

	// Selection
	globalCheckBoxClick(checked) {
		this.options.rowData.forEach((el) => {
			if (this.selectableIf(el)) {
				const key = el[this.options.rowDefs.identifier];
				let index = -1;

				for (let i = 0, len = this.selectedRows.length; i < len; i++) {
					if (this.selectedRows[i][this.options.rowDefs.identifier] === key) {
						index = i;
						i = len;
					}
				}
				const isInArray = index > -1;

				if (checked && !isInArray) {
					this.selectedRows.push(el);
				} else if (!checked && isInArray) {
					this.selectedRows.splice(index, 1);
				}
				el.selected = checked;
			}
		});

		this.onRowsSelected && this.onRowsSelected({ rows: this.selectedRows });
	}

	toggleRow(event, row) {
		if (this.options.rowDefs) {
			// remove selection for singleSelection
			if (this.options.rowDefs.singleSelection) {
				this.options.rowData.forEach((el) => {
					el.selected = false;
				});
				this.selectedRows.pop();
				row.selected = !row.selected;
			} else if (this.options.rowDefs.multiSelection) {
				if (this.options.rowDefs.groupHeader) {
					const groupId = this.options.rowDefs.groupHeader.groupByFieldName(row);
					const group = _.find(this.getGroupHeaders(), { id: groupId });

					if (group) {
						group.checked = _.every(this.getGroupMembers(groupId), (row) => {
							return this.options.rowDefs.selectableIf
								? this.options.rowDefs.selectableIf(row) && row.selected
								: row.selected;
						});

					}
				} else {
					this.globalCheckBox = _.every(this.options.rowData, (row) => {
						return this.options.rowDefs.selectableIf
							? this.options.rowDefs.selectableIf(row) && row.selected
							: row.selected;
					});
				}
			}

			if (row.selected) {
				this.selectedRows.push(row);
			} else {
				this.selectedRows = this.selectedRows.filter((el) => {
					return el[this.options.rowDefs.identifier] !== row[this.options.rowDefs.identifier];
				});
			}
			this.onRowsSelected && this.onRowsSelected({ rows: this.selectedRows });
		}
		event.stopPropagation();
	}

	selectableIf(row) {
		if (this.options.rowDefs && this.options.rowDefs.selectableIf) {
			return this.options.rowDefs.selectableIf(row);
		}

		return this.options.rowDefs &&
			(this.options.rowDefs.singleSelection || this.options.rowDefs.multiSelection);
	}

	// Refresh
	checkForUpdate(forceRefresh) {
		this.options.expandAllVisible = true;
		if (forceRefresh) {
			this.options.isRowDataLoadingComplete = false;
		}

		this.$log.debug(' check for update.');
		const page = this.options.currentPage - 1;
		const query = this.getODataQuery(false, page * this.pageSize);

		if (this.options.removePaginationControls) {
			this.refresh();
		} else {
			this.options.getData(query.toUri())
				.then(response => {
					const data = (response.data || response);

					if (forceRefresh || (this.gridData.length > 0 &&
										 this.hasRowDataChanged(this.gridData[page].data, data))) {
						if (this.gridData.length > 0) {
							this.gridData[page].isLoading = true;
						}

						// we have already the updated page data, if we update the grid immediately,
						// then the user won't recognize the update. Therefore show the
						// loading indicator for a few milliseconds.
						this.$timeout(() => {
							this.refresh(data);
						}, 100);

						this.$log.debug(' grid data refreshed.');
					}
				}, () => {
					this.$log.debug(' error in auto refresh.');
				});
		}
	}

	refresh(data) {

		this.gridData = [];
		const pageIndex = this.options.currentPage - 1;

		if (data) {
			this.gridData[pageIndex] = {
				data,
				isLoading: false
			};
		}
		this.updateData();
	}

	hasRowDataChanged(current, update) {
		const a = angular.toJson(current);
		const b = angular.toJson(update);

		return a !== b;
	}

	// Paging
	nextPage() {
		const nextPageIndex = this.options.currentPage;

		if (this.gridData[nextPageIndex] &&
			!this.gridData[nextPageIndex].isLoading &&
			this.gridData[nextPageIndex].data &&
			this.gridData[nextPageIndex].data.length > 0) {
			this.options.currentPage += 1;
			this.options.expandAllVisible = true;
			this.updateData();
		}
	}

	previousPage() {
		const previousPageIndex = this.options.currentPage - 2;

		if (this.options.currentPage > 1 &&
			this.gridData[previousPageIndex] &&
				!this.gridData[previousPageIndex].isLoading &&
				this.gridData[previousPageIndex].data &&
				this.gridData[previousPageIndex].data.length > 0) {
			this.options.currentPage -= 1;
			this.options.expandAllVisible = true;
			this.updateData();
		}
	}

	initGrid(reload) {
		if (reload) {
			this.gridData = [];
			this.updateData();
		}
		if (!reload && this.options.columnDefs) {
			const translationKeys = [];

			this.options.columnDefs.forEach((el) => {
				if (el.translationKey) {
					translationKeys.push(el.translationKey);
				}
				if (el.enums) {
					el.enums.forEach((enumValue) => {
						if (enumValue.translationKey) {
							translationKeys.push(enumValue.translationKey);
						}
					});
				}
				if (el.additionalFilters) {
					el.additionalFilters.forEach((filter) => {
						if (filter.translationKey) {
							translationKeys.push(filter.translationKey);
						}
					});
				}
			});

			this.appI18n.translate(translationKeys)
				.then((translations) => {
					this.options.columnDefs.forEach((el) => {
						if (el.translationKey) {
							el.displayName = translations[el.translationKey];
						}
						if (el.enums) {
							el.enums.forEach((enumValue) => {
								if (enumValue.translationKey) {
									enumValue.displayName = translations[enumValue.translationKey];
								}
							});
						}
						if (el.additionalFilters) {
							el.additionalFilters.forEach((filter) => {
								if (filter.translationKey) {
									filter.displayName = translations[filter.translationKey];
								}
							});
						}
					});
					this.setDefaultFilter();
					this.updateData();
				});
		}
	}

	onGridDataChange() {
		const pageIndex = this.options.currentPage - 1;

		if (!this.gridData[pageIndex] || this.gridData[pageIndex].isLoading) {
			this.options.isRowDataLoadingComplete = false;
			return;
		}
		this.options.rowData = this.gridData[pageIndex].data;

		if (this.options.rowDefs && this.options.rowDefs.groupHeader) {
			this.groupHeaders = this.getGroupHeaders();
		}

		let selectedRowsCount = 0;

		if (this.options.rowData && this.selectedRows && this.options.rowDefs) {
			this.options.rowData.forEach((row) => {
				this.selectedRows.forEach((el) => {
					if (el[this.options.rowDefs.identifier] === row[this.options.rowDefs.identifier]) {
						row.selected = true;
						selectedRowsCount++;
					}
				});
			});
		}

		if (this.options.rowDefs && this.options.rowDefs.groupHeader) {
			this.groupHeaders.forEach((group) => {
				group.checked = _.every(this.getGroupMembers(group.id), (row) => {
					return this.options.rowDefs.selectableIf
						? this.options.rowDefs.selectableIf(row) && row.selected
						: row.selected;
				});
			});
		} else {
			let count = 0;

			if (this.options.rowDefs && this.options.rowDefs.selectableIf) {
				this.options.rowData.forEach((el) => {
					if (this.options.rowDefs.selectableIf(el)) {
						count++;
					}
				});
			} else {
				count = this.options.rowData.length;
			}

			this.globalCheckBox = selectedRowsCount === count && count > 0;
			this.checkAllTxt = supplant(this.checkAllTxtTemplate, [count]);
			this.clickCheckAllTxt = supplant(this.clickCheckAllTxtTemplate, [count]);
		}

		this.intervalPercentage = 0;
		this.options.isRowDataLoadingComplete = true;

		this.$timeout(() => {
			// workaround to align the row actions cells
			const elements = angular.element('.row-action-cell');
			const rowActionLength = Math.max(...elements.map((e, i) => {
				return angular.element(i).children().length;
			}));
			const width = rowActionLength > 0 ? (rowActionLength - 1) * 25 + 20 : 20;
			const columns = angular.element('.row-action-column');

			angular.forEach(columns, (el) => {
				angular.element(el).width(width);
			});

		});
	}

	updateData() {
		if (this.options.rowDefs && this.options.rowDefs.groupHeader) {
			this.options.rowDefs.groupHeader.groups = null;
		}

		if (!this.options.removePaginationControls) {
			const pageIndex = this.options.currentPage - 1;

			if (this.gridData[pageIndex]) {
				this.onGridDataChange();
			}

			const query = this.getODataQuery(true, 0);
			const where = query.buildWhere();
			const sort = query.buildOrderBy();

			if (this.preloadInfo) {
				// if we have a pending preload with different sort/filter, cancel it.
				// the previous (async) preloadData invocation has a reference to this object
				// and (if still running) can cancel itself based on the canceled flag
				if (this.preloadInfo.where !== where || this.preloadInfo.sort !== sort) {
					this.preloadInfo.canceled = true;
				}
			}

			const preloadInfo = {
				canceled: false,
				where,
				sort
			};

			this.preloadInfo = preloadInfo;

			this.preloadData(preloadInfo);
		} else {
			this.fetchData(0, true);
		}
	}

	async preloadData(preloadInfo) {
		let pageIndex = this.options.currentPage - 1;
		const preloadOrder = [0, 1, -2, 3, -4];

		for (let i = 0; i < 5; i++) {
			pageIndex += preloadOrder[i];

			if (preloadInfo.canceled) {
				this.$log.debug('preload canceled');
				return;
			}

			if (pageIndex >= 0 && !this.gridData[pageIndex]) {
				if ((pageIndex <= this.options.currentPage - 1) ||
					(pageIndex > 0 && this.gridData[pageIndex - 1] &&
					 this.gridData[pageIndex - 1].isLoading === false &&
					 this.gridData[pageIndex - 1].data.length >= this.pageSize)) {
					await this.fetchData((pageIndex) * this.pageSize, i === 0, preloadInfo);
				} else if (pageIndex > 0 && this.gridData[pageIndex - 1] &&
					this.gridData[pageIndex - 1].isLoading === false &&
					this.gridData[pageIndex - 1].data.length < this.pageSize) {
					this.gridData[pageIndex] = {
						data: [],
						isLoading: false
					};
				}
			}
		}
	}

	addGridRows(rows, page) {
		this.gridData[page] = {
			data: rows,
			isLoading: false
		};

		if ((this.options.currentPage - 1) === page) {
			this.onGridDataChange();
		}
	}

	fetchData(from, saveToUrl, preloadInfo) {

		const query = this.getODataQuery(false, from);
		let page = 0;

		if (from && this.pageSize) {
			page = from / this.pageSize;
		}

		if (saveToUrl) {
			// save to url fragment on first page load
			this.$location.search(this.getPrefixedStateParam('filter'), query.buildWhere());
			this.$location.search(this.getPrefixedStateParam('orderBy'), query.buildOrderBy());
		}

		this.gridData[page] = {
			isLoading: true
		};
		this.$log.debug(`query: ${query.toUri()}`);
		const promise = this.options.getData(query.toUri());

		promise.then((response) => {
			if (preloadInfo && preloadInfo.canceled) {
				this.$log.debug('retrieved data for canceled request, ignore');
				return;
			}
			this.$log.debug(' row data loaded.');
			this.addGridRows(response.data || response, page);
		}, () => {
			if (preloadInfo && preloadInfo.canceled) {
				return;
			}
			this.$log.debug(' error in loading row data.');
			this.addGridRows([], page);
		});
		return promise;
	}

	// OData
	getODataQuery(ignorePaging, from) {
		const query = this.oDataHelper
			.buildUriFor('');

		if (!ignorePaging && !this.options.removePaginationControls) {
			if (from >= 0) {
				query.skip(from);
			}
			query.top(this.pageSize);
		}

		if (this.options.sortOrders && this.options.sortOrders.length > 0) {
			this.options.sortOrders.forEach((el) => {
				query.orderBy(toPascalCase(el.fieldName), el.reverseOrder);
			});
		}
		const stringType = 'string';
		const numberType = 'number';

		for (let i = 0; i < this.filters.length; i++) {
			let queryValue = '';
			const type = this.filters[i].type;
			const enumType = this.filters[i].enumType;
			const operator = this.filters[i].operator;

			if (type === stringType || enumType === stringType) {
				queryValue = `'${this.filters[i].filterValue}'`;
			} else if (type === numberType || type === 'id' ||
				enumType === numberType || enumType === 'bool' || enumType === 'null') {
				queryValue = this.filters[i].filterValue;
			} else if (type === 'date' || enumType === 'date') {
				queryValue = this.filters[i].filterValue;
			}

			query.where(toPascalCase(this.filters[i].fieldName), operator, queryValue, type);
		}

		this.options.oDataQuery = query;

		return query;
	}

	getColumnDefForFieldName(fieldName) {
		return this.options.columnDefs.find(column => {
			return column.fieldName === fieldName;
		});
	}

	processOrderByQuery(odataQuery) {
		const conditions = odataQuery.split(',');

		this.options.sortOrders = [];

		conditions.forEach((item) => {
			try {
				const isDesc = item.endsWith(' desc');
				const fieldName = isDesc
					  ? toCamelCase(item.substring(0, item.indexOf(' ')))
					  : toCamelCase(item);

				this.options.sortOrders.push({
					fieldName,
					reverseOrder: isDesc
				});
			} catch (ex) {
				this.$log.debug(` unable to convert odata orderBy data: ${ex}`);
			}
		});
	}

	processFilterQuery(odataQuery) {
		const re = /(\w+\s(le|ge|eq)\s(true|false|'.+?'|\d+\-\d+\-\d+|\-{0,1}\d+))|(contains\(\w+,'.+?'\))/g;
		const conditions = odataQuery.match(re);

		const queryFilters = {};

		conditions.forEach((item) => {

			try {

				const isContains = item.startsWith('contains(');
				const fieldName = isContains
					  ? toCamelCase(item.substring(item.indexOf('(') + 1, item.indexOf(',')))
					  : toCamelCase(item.substring(0, item.indexOf(' ')));
				const columnConfig = this.getColumnDefForFieldName(fieldName);
				const isDate = columnConfig.type === 'date';

				if (!queryFilters[fieldName]) {
					queryFilters[fieldName] = {
						fieldName,
						displayName: columnConfig.translationKey
							? this.appI18n.translateImmediate(columnConfig.translationKey)
							: columnConfig.displayName,
						filterConditions: []
					};
				}

				queryFilters[fieldName].filterConditions.push({
					displayValue: isContains
						? item.substring(item.indexOf(',') + 2, item.indexOf(')') - 1)
						: isDate
						? this.$filter('date')(this.getDate(item.substring(fieldName.length + 4)), 'dd-MMM-yyyy')
						: item.substring(fieldName.length + 4).replace(/'/g, ''),
					filterValue: isContains
						? item.substring(item.indexOf(',') + 2, item.indexOf(')') - 1)
						: item.substring(fieldName.length + 4).replace(/'/g, ''),
					operator: isContains
						? 'contains'
						: item.indexOf(' ge ') >= 0 ? 'ge' : item.indexOf(' le ') >= 0 ? 'le' : 'eq',
					type: columnConfig.type,
					enumType: columnConfig.enumType
				});
			} catch (ex) {
				this.$log.debug(` unable to convert odata filter data: ${ex}`);
			}
		});


		for (const property in queryFilters) {
			if (queryFilters.hasOwnProperty(property)) {

				if (queryFilters[property].filterConditions.length > 0 &&
					queryFilters[property].filterConditions[0].type === 'enum') {

					const enumValues = [];

					for (const f of queryFilters[property].filterConditions) {
						enumValues.push(f.filterValue.toString());
					}

					const columnConfig = this.getColumnDefForFieldName(queryFilters[property].fieldName);

					for (const v of columnConfig.enums) {
						const values = Array.from(v.values, x => x.toString());

						if (_.difference(values, enumValues).length === 0) {
							queryFilters[property].filterConditions[0].displayValue = v.displayName;
							break;
						}
					}
				}
				this.addToFilterArray(queryFilters[property]);
			}
		}
	}

	getPrefixedStateParam(param) {
		if (!this.options.stateParamPrefix) {
			return param;
		} else {
			return this.options.stateParamPrefix + param;
		}
	}

	// Other
	rowActionCellClick(e) {
		e.stopPropagation();
	}

}

export default ErtGridController;
