/*
 * labelModule
 * */

import labelComponent from './label.component';

const labelModule = angular.module('app.common.label', [])
	.component('ertLabel', labelComponent)
	.name;

export default labelModule;
