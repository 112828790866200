/*
 * filtersService
 * */
const cs = require('../../scripts/utils/custom');
const parser = require('odata-v4-parser');
const toCamelCase = cs.toCamelCase;
const toPascalCase = cs.toPascalCase;
const URL_PARAM_NAME = 'filter';

export default class filtersService {

	static id() {
		return 'filtersService';
	}

	constructor($log, $location, analyticsService, oDataService) {
		'ngInject';
		this.$log = $log.getInstance('filtersService', 'color:green');
		this.$location = $location;
		this.analyticsService = analyticsService;
		this.oDataService = oDataService;
	}

	setDataToUrl(data) {
		this.$location.search(URL_PARAM_NAME, data);
	}

	getDataFromUri() {
		const value = this.$location.search()[URL_PARAM_NAME];

		if (value) {
			return `$${URL_PARAM_NAME}=${value}`;
		}
		return '';
	}

	getQueryFilterFromUrl() {
		const oDataString = this.$location.search()[URL_PARAM_NAME];

		if (oDataString) {
			return this._convertOData(parser.filter(oDataString), []);
		}
		return null;
	}

	/* eslint-disable */
	_convertOData(parsed, urlFilters, isOr) {
		let op = '';
		let typ = '';

		switch (parsed.type) {
			case 'BoolParenExpression':
				this._convertOData(parsed.value, urlFilters);
				break;
			case 'OrExpression':
				this._convertOData(parsed.value.left, urlFilters, true);
				this._convertOData(parsed.value.right, urlFilters, true);
				break;
			case 'AndExpression':
				this._convertOData(parsed.value.left, urlFilters);
				this._convertOData(parsed.value.right, urlFilters);
				break;
			case 'EqualsExpression':
				op = 'eq';
				break;
			case 'NotEqualsExpression':
				op = 'ne';
				break;
			case 'LesserThanExpression':
				op = 'lt';
				break;
			case 'LesserOrEqualsExpression':
				op = 'le';
				break;
			case 'GreaterThanExpression':
				op = 'gt';
				break;
			case 'GreaterOrEqualsExpression':
				op = 'ge';
				break;
			case 'MethodCallExpression':
				op = 'contains';
				break;
		}

		if (op) {
			let parsedType = '';
			let columnName = '';
			let columnValue = '';

			if (op === 'contains') {
				parsedType = parsed.value.parameters[1].value;
				columnValue = parsed.value.parameters[1].raw;
				columnName = parsed.value.parameters[0].raw;
			} else {
				parsedType = parsed.value.right.value;
				columnValue = parsed.value.right.raw;
				columnName = parsed.value.left.raw;
			}

			switch (parsedType) {
				case 'Edm.String':
					typ = 'string';
					columnValue = columnValue.slice(1, -1);
					break;
				case 'Edm.Date':
					typ = 'date';
					break;
				case 'Edm.Boolean':
					typ = 'bool';
					break;
				default:
					typ = 'number';
					break;
			}

			urlFilters.push({
				displayName: columnName,
				displayValue: columnValue,
				filterName: toCamelCase(columnName),
				filterValue: columnValue,
				operator: op,
				type: isOr ? `enum-${typ}` : typ
			});
		}

		return urlFilters;
	}
	/* eslint-enable */

	initFiltering(gridId, columns, notInUrl) {
		const newQueryFilters = this.getQueryFilterFromUrl();

		if (newQueryFilters) {
			this.updateDisplayValues(newQueryFilters, columns);
			this.updateQueryFiltersForGrid(gridId, newQueryFilters, notInUrl);
			return newQueryFilters;
		} else {
			const defaultQueryFilters = this.setDefaultFilters(columns);

			if (defaultQueryFilters) {
				this.updateQueryFiltersForGrid(gridId, defaultQueryFilters, notInUrl);
			}
			return defaultQueryFilters;
		}
	}

	removeGridFilter(gridId) {
		this.oDataService.clearUriBuilders(gridId);
	}

	setDefaultFilters(columns) {
		const defaultQueryFilters = [];
		const filterColumns = _.filter(columns, (column) => {
			return column.filters;
		});

		filterColumns.forEach((column) => {
			column.filters.forEach((filter) => {
				if (column.type.startsWith('enum') && filter.values) {
					for (let i = 0; i < filter.values.length; i++) {
						const item = filter.values[i];

						defaultQueryFilters.push({
							displayName: column.displayName,
							displayValue: filter.displayValue || filter.value,
							filterName: column.fieldName,
							filterValue: item,
							operator: filter.op,
							type: column.type
						});
					}
				} else {
					defaultQueryFilters.push({
						displayName: column.displayName,
						displayValue: filter.displayValue || filter.value,
						filterName: column.fieldName,
						filterValue: filter.value,
						operator: filter.op,
						type: column.type
					});
				}
			});
		});
		return defaultQueryFilters;

	}

	getFromToDisplayString(type, val1, val2) {
		let result = '';

		if (type === 'date') {
			if (val1) {
				result += ` from ${moment(val1.filterValue, 'YYYY-MM-DD').format('DD-MMM-YYYY')}`;
			}
			if (val2) {
				result += ` to ${moment(val2.filterValue, 'YYYY-MM-DD').format('DD-MMM-YYYY')}`;
			}
		} else {
			if (val1) {
				result += ` from ${val1.filterValue}`;
			}
			if (val2) {
				result += ` to ${val2.filterValue}`;
			}
		}
		return result;
	}

	updateDisplayValues(newQueryFilters, columns) {
		newQueryFilters.forEach((queryFilter) => {
			const columnDef = _.find(columns, (column) => {
				return column.fieldName === queryFilter.filterName;
			});

			if (columnDef) {
				queryFilter.displayName = columnDef.value;

				if (queryFilter.operator === 'ge' || queryFilter.operator === 'gt') {
					const lt = _.find(columns, (column) => {
						return column.filterName === queryFilter.filterName &&
							(column.operator === 'le' || column.operator === 'lt');
					});

					queryFilter.displayValue = this.getFromToDisplayString(queryFilter.type, queryFilter, lt);
				}

				if (queryFilter.operator === 'le' || queryFilter.operator === 'lt') {
					const gt = _.find(columns, (column) => {
						return column.filterName === queryFilter.filterName &&
							(column.operator === 'ge' || column.operator === 'gt');
					});

					queryFilter.displayValue = this.getFromToDisplayString(queryFilter.type, gt, queryFilter);
				}

				if (queryFilter.operator && queryFilter.type.startsWith('enum')) {
					const filters = _.filter(columns, (column) => {
						return column.filterName === queryFilter.filterName;
					});
					const values = _.map(filters, 'filterValue');

					columnDef.enums.forEach((num) => {
						if (_.intersection(num.values, values).length === 0) {
							queryFilter.displayValue = num.displayName;
						}
					});
				}
			}
		});
	}

	updateQueryFiltersForGrid(gridId, queryFilters, notInUrl) {
		const query = this.getODataQuery(queryFilters);

		this.oDataService.combineUriBuilder(gridId, 'filter', query);

		if (!notInUrl) {
			const uri = query.buildWhere();

			this.setDataToUrl(uri);
		}
	}

	getUriBuilder(filtersData) {
		return this.getODataQuery(filtersData);
	}

	getODataQuery(queryFilters) {
		const query = this.oDataService.buildUriFor('');

		if (!queryFilters) {
			return query;
		}

		if (!angular.isArray(queryFilters)) {
			queryFilters = [queryFilters];
		}

		for (let i = 0; i < queryFilters.length; i++) {
			let queryValue = '';
			const type = queryFilters[i].type;
			const operator = queryFilters[i].operator;

			/* eslint-disable angular/typecheck-string*/
			/* eslint-disable angular/typecheck-number*/
			if (type === 'string' ||
				type === 'enum-string') {
				queryValue += `'${queryFilters[i].filterValue}'`;
			} else if (type === 'number' ||
				type === 'id' ||
				type === 'enum-number' ||
				type === 'enum-bool' ||
				type === 'enum-null' ||
				type === 'date' ||
				type === 'enum-date') {
				queryValue = queryFilters[i].filterValue;
			}
			/* eslint-enable angular/typecheck-string */
			/* eslint-enable angular/typecheck-number */

			query.where(toPascalCase(queryFilters[i].filterName), operator, queryValue, type);
		}
		return query;
	}
}
