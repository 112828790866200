import templateUrl from './confirmationTextAreaPopup.html';
import controller from './confirmationTextAreaPopup.controller';
import './confirmationTextAreaPopup.scss';

const confirmationTextAreaPopupComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default confirmationTextAreaPopupComponent;
