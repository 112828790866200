/*
 * gridComponent
 * */

import templateUrl from './grid.html';
import controller from './grid.controller';
import './grid.scss';

const gridComponent = {
	bindings: {
		onFilteringChange: '&?',
		onSortingChange: '&?',
		onPaginationMoreLoad: '&?',
		onPaginationRefresh: '&?',
		saveState: '<?',
		componentReady: '&?'
	},
	templateUrl,
	controller,
	transclude: {
		toolbar: 'ertToolbar',
		content: 'tableWrap'
	}
};

export default gridComponent;
