/*
 * clinicalDataComponent
 * */

import templateUrl from './clinicalData.html';
import controller from './clinicalData.controller';
import './clinicalData.scss';

const clinicalDataComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default clinicalDataComponent;
