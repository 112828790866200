/*
 * dateRangeComponent
 * */

import templateUrl from './dateRange.html';
import controller from './dateRange.controller';
import './dateRange.scss';

const dateRangeComponent = {
	bindings: {
		model: '<',
		rangeChanged: '&',
		onEnter: '&?'
	},
	templateUrl,
	controller
};

export default dateRangeComponent;
