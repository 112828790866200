export default class ValueChecker {

	static isUndefinedOrNullOrEmpty(value) {
		return (angular.isUndefined(value) || value === null || (angular.isString(value) && !value.length));
	}

	static id() {
		return 'valueCheckerService';
	}

}
