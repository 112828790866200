(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('UserLoginTrackingCtrl', UserLoginTrackingController);

	/**
	 * @ngInject
	 */
	function UserLoginTrackingController(adminService, $filter, $log, $scope, appI18n) {

		$log = $log.getInstance("UserLoginTrackingController");
		$log.debug("loaded");

		var vm = this;
		var today = new Date();
		var fromDate = new Date(today).setDate(today.getDate()-7);

		vm.userLoginTrackingOptions = {
			rowDefs: {
			},
			gridRowActions: [],

			sortOrders: [{
				fieldName: 'loginDate',
				reverseOrder: true
			}],

			preSelectedFilters: [{
				fieldName: 'loginDate',
				displayName: 'Login Date/Time (UTC)',
				filterConditions: [
					{
					 displayValue: $filter('date')(fromDate, 'dd-MMM-yyyy'),
					 filterValue: $filter('date')(fromDate, 'yyyy-MM-dd'),
					 operator: 'ge',
					 type: 'date'
					},
					{
					 displayValue: $filter('date')(today, 'dd-MMM-yyyy'),
					 filterValue: $filter('date')(today, 'yyyy-MM-dd'),
					 operator: 'le',
					 type: 'date'
					}
				]
			}],

			getData: getUserLoginTracking
		};

		function getUserLoginTracking(options) {
				return adminService.getUserLoginTracking(options);
		}

		function activate() {
			appI18n.translate( [
				"admin.user-login-tracking-title",
				"admin.user-login.user",
				"admin.user-login.email",
				"admin.user-login.status",
				"admin.user-login.login-date"
			])
			.then(function (translations) {
	            adminService.setTitle(translations["admin.user-login-tracking-title"]);
	            vm.userTitle = translations["admin.user-login.user"];
	            vm.emailTitle = translations["admin.user-login.email"];
	            vm.statusTitle = translations["admin.user-login.status"];
	            vm.loginDateTitle = translations["admin.user-login.login-date"];

	            vm.userLoginTrackingOptions.columnDefs = [
								{displayName: vm.userTitle, fieldName: 'loginName', isSortable: true, type: 'string', isDefaultFilter: true},
					{displayName: vm.emailTitle, fieldName: 'personEmail', isSortable: true, type: 'string'},
					{displayName: vm.statusTitle, fieldName: 'loginStatus', isSortable: true, type: 'enum',
						enumType: 'string',
						enums: [
							{displayName: 'Success', values: ['Success']},
							{displayName: 'Failure', values: ['Failure']}
						]
					},
					{displayName: vm.loginDateTitle, fieldName: 'loginDate', isSortable: true, type: 'date', formatter: 'dateTimeFormat'}
				];
    	    });
	
			vm.load = true;
		}

		activate();
	}

})();
