/*
 * simpleDatePickerModule
 * */

import simpleDatePickerComponent from './simpleDatePicker.component';

const simpleDatePickerModule = angular.module('app.common.datePicker.simpleDatePicker', [])
	.component('simpleDatePicker', simpleDatePickerComponent)
	.name;

export default simpleDatePickerModule;
