(function() {
	'use strict';
	angular.module('portalApp')
	.filter('trust', trustFilter);

	/**
	 * @ngInject
	 */

	function trustFilter($sce) {
		return function(htmlCode){
			return $sce.trustAsHtml(htmlCode);
		}
	}

})();


