/*
 * labelController
 * */

class LabelController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('LabelController');
		this.$log.debug('loaded');
	}
}

export default LabelController;
