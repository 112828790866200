/*
 * baseSelectComponent
 * */

import templateUrl from './baseSelect.html';
import controller from './baseSelect.controller';
import './baseSelect.scss';

const baseSelectComponent = {
	bindings: {
	},
	templateUrl,
	controller,
	transclude: true
};

export default baseSelectComponent;
