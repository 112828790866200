/*
 * deleteUserPopupComponent
 * */

import templateUrl from './deleteUserPopup.html';
import controller from './deleteUserPopup.controller';
import './deleteUserPopup.scss';

const deleteUserPopupComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default deleteUserPopupComponent;
