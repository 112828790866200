/*
 * sortingComponent
 * */

import templateUrl from './sorting.html';
import controller from './sorting.controller';
import './sorting.scss';

const sortingComponent = {
	bindings: {
		sortString: '@',
		active: '<',
		reverse: '<?',
		onSort: '&'
	},
	templateUrl,
	controller
};

export default sortingComponent;
