const _ = require('lodash');

class ScheduledEventsController {
	constructor($log, $scope, $stateParams, $state, ertBasePopupService, ssoService,
				iSpiroService, filtersService,
				loadingIndicatorService, notificationService, analyticsService,
				appConfig, appI18n, clinicalDataService) {
		'ngInject';
		this.$log = $log;
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.iSpiroService = iSpiroService;
		this.filtersService = filtersService;
		this.analyticsService = analyticsService;
		this.ertBasePopupService = ertBasePopupService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.notificationService = notificationService;
		this.ssoService = ssoService;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.clinicalDataService = clinicalDataService;
	}

	loadScheduledEvents() {
		const filter = this.filtersService.getQueryFilterFromUrl();

		const subjectKeyFilter = _.first(filter, { 'name': 'subjectKey' });

		let subjectKey;

		if (subjectKeyFilter) {
			subjectKey = subjectKeyFilter.filterValue;
		}

		const startDate = this.calendarOptions.start.format('YYYYMMDD');
		const endDate = this.calendarOptions.start.clone().add(
			this.calendarOptions.numberOfMonths, 'months').format('YYYYMMDD');

		this.resetSelectedDay();

		return this.iSpiroService.getScheduledEvents(this.siteId, subjectKey,
													 startDate, endDate);
	}

	$onInit() {
		this.clinicalDataService.setRefreshCallback(() => { this.reload = true; });

		this.$scope.$on('$destroy', () => {
			this.clinicalDataService.unsetRefreshCallback();
		});

		this.siteId = +this.$stateParams.site;
		this.$log = this.$log.getInstance('ScheduledEventsController');
		this.$log.debug(' loaded');

		this.options = {
			showCollapseButton: true,
			useToggleViewMode: false,
			removeExportControls: true,
			rowActions: [
				{
					getDisplayName: (row) => {
						return row.canResume
							? 'clinicaldata.scheduledEvents.actions.resume.displayName'
							: 'clinicaldata.scheduledEvents.actions.start.displayName';
					},
					action: (row) => {
						this.loadingIndicatorService.show();
						this.iSpiroService.activateVideoSession(
							row.siteId, row.technicalSubjectId, row.subjectKey,
							row.visitId, row.visitScheduleTaskId, row.scheduleDateTime, row.scheduledEventId)
							.then((response) => {
								if (response.data && response.data.isValidationError) {
									this.notificationService.showError(
										this.appI18n.translateImmediate(response.data.translationKey));
									this.loadingIndicatorService.hide();
								} else {
									this.ssoService.navigateToStudyService(
										'remotespiro', 'start-session',
										{ scheduledSessionUrl: encodeURIComponent(response.data) })
										.then(() => {
											this.reload = true;
										}).finally(() => {
											// always hide the loading indicator (success or error)
											this.loadingIndicatorService.hide();
											// we need to trigger a digest cycle here because the promise is resolved
											// outside of the angular digest loop.
											this.$scope.$apply();
										});
								}
							}, () => {
								// hide loading indicator if the call to iSpiroService failed
								this.loadingIndicatorService.hide();
							});
					},
					enabledIf: (row) => {
						return row.canStart || row.canResume;
					},
					actionInfo: (row) => {
						if (row.canStart) {
							return 'clinicaldata.scheduledEvents.actions.start.enabledInfo';
						} else if (row.canResume) {
							return 'clinicaldata.scheduledEvents.actions.resume.enabledInfo';
						} else {
							return 'clinicaldata.scheduledEvents.actions.start.disabledInfo';
						}
					}
				},
				{
					displayTranslationKey: 'clinicaldata.scheduledEvents.actions.reschedule.displayName',
					action: (row) => {
						this.rescheduleEvent(row);
					},
					enabledIf: (row) => {
						return row.canReschedule;
					},
					actionInfo: (row) => {
						if (row.canReschedule) {
							return 'clinicaldata.scheduledEvents.actions.reschedule.enabledInfo';
						} else {
							return 'clinicaldata.scheduledEvents.actions.reschedule.disabledInfo';
						}
					}
				},
				{
					displayTranslationKey: 'clinicaldata.scheduledEvents.actions.cancel.displayName',
					action: (row) => {
						this.cancelEvent(row);
					},
					enabledIf: (row) => {
						return row.canCancel;
					},
					actionInfo: (row) => {
						if (row.canCancel) {
							return 'clinicaldata.scheduledEvents.actions.cancel.enabledInfo';
						} else {
							return 'clinicaldata.scheduledEvents.actions.cancel.disabledInfo';
						}
					}
				}
			]
		};

		this.options.getData = (oDataQuery) => {
			return this.loadScheduledEvents(oDataQuery);
		};

		this.options.columnDefs = [{
			translationKey: 'app.common.subjectKey',
			fieldName: 'subjectKey',
			type: 'string',
			isDefaultFilter: true
		}];

		this.options.defaultRowAction = (row) => { this.navigateToVisitSummary(row); };
		this.options.removePaginationControls = true;

		this.options.rowDefs =
		{
			multiSelection: false,
			reportsLoaded: true,
			identifier: 'scheduledSessionId'
		};

		this.activate();
	}

	navigateToVisitSummary(row) {
		this.loadingIndicatorService.show();
		this.iSpiroService.getSubjectMinimal(row.siteId, row.technicalSubjectId)
			.then((resp) => {
				this.analyticsService.trackEventWithTitle(
					`${this.appConfig.trackCategory.navigateTo}::${this.appConfig.trackId.clinicalDataVisit}`,
					this.appConfig.trackAction.gridEntryScheduledEvents,
					this.appConfig.trackLabel.levelSubject
				);
				this.$state.go(this.appConfig.routes.clDataVisits, {
					country: resp.data.countryId,
					site: resp.data.siteId,
					subject: resp.data.subjectId,
					eproSubject: resp.data.eproSubjectId
				});
			}, (err) => {
				this.$log.error(`Error during getSubject: ${err}`);
			}).finally(() => {
				this.loadingIndicatorService.hide();
			});
	}

	activate() {
		// get and set current site time
		this.iSpiroService.getSiteDetails(this.siteId).then((resp) => {
			this.today = moment(resp.data.timezoneDisplay, 'YYYYMMDD');
		}, (() => {
			// use client time as fallback
			this.today = new Date().setHours(0, 0, 0, 0);
		})).finally(() => {
			this.initCalendar();
			this.loadData();
		});
	}

	loadData() {
		this.load = true;
		this.$scope.$watch('$ctrl.options.rowData', () => {
			if (this.options.rowData) {
				this.showCalendar();
			}
		});
	}

	rescheduleEvent(row) {
		this.ertBasePopupService.popup('iSpiroScheduleEventPopup')
			.openForReschedule(row.siteId, row.displaySiteName, row.technicalSubjectId, row.subjectKey,
							   row.visitId, row.visitName, row.visitScheduleTaskId, row.visitScheduleTaskName,
							   row.dayMonthYear, row.time, row.remoteVideoEnabled, row.scheduledEventId)
			.then((resp) => {
				this.reload = resp;
			});
	}

	cancelEvent(row) {
		this.iSpiroService.confirmAndCancelScheduledEvent(
			row.scheduledEventId, row.siteId, row.subjectKey, row.visitId,
			row.visitScheduleTaskId, row.scheduleDateTime, () => {
				this.reload = true;
				this.notificationService.showSuccess(
					this.appI18n.translateImmediate('clinicaldata.scheduledEvents.cancelSuccess'));
			});
	}

	initCalendar() {
		const firstMonth = moment(this.today).startOf('month');

		this.calendarOptions = {
			initialized: true,
			previousMonth: (numberOfMonths) => this.previousMonth(numberOfMonths),
			nextMonth: (numberOfMonths) => this.nextMonth(numberOfMonths),
			selectDay: (id) => this.selectDay(id),
			numberOfMonths: 2,
			start: firstMonth,
			dataLoaded: false
		};
	}

	showCalendar() {
		if (!this.calendarOptions || !this.calendarOptions.initialized) {
			this.initCalendar();
		}
		this.loadCalendar();
	}

	nextMonth(numberOfMonths) {
		numberOfMonths = numberOfMonths || 1;
		this.calendarOptions.start.add(numberOfMonths, 'month');
		this.calendarOptions.dataLoaded = false;
	}

	previousMonth(numberOfMonths) {
		numberOfMonths = numberOfMonths || 1;
		this.calendarOptions.start.subtract(numberOfMonths, 'month');
		this.calendarOptions.dataLoaded = false;
	}

	selectDay(id) {
		this.selectedDay = id;
		this.hideDataBeforeDay(id);
	}

	resetSelectedDay() {
		this.rowDataCopy = null;
		this.selectedDay = null;
	}

	hideDataBeforeDay(dayId) {
		if (!this.rowDataCopy) {
			this.rowDataCopy = [...this.options.rowData];
		}

		const rowDataSlice = [];

		for (let i = 0; i < this.rowDataCopy.length; ++i) {
			const row = this.rowDataCopy[i];

			if (row.date >= dayId) {
				rowDataSlice.push(row);
			}
		}

		this.options.rowData = rowDataSlice;
		this.loadCalendar();
	}

	loadCalendar() {
		const firstMonth = moment(this.today).startOf('month');
		const start = this.calendarOptions.start;
		const numberOfMonths = this.calendarOptions.numberOfMonths;
		const months = this.getMonths(moment(this.today), start, numberOfMonths);

		this.calendarOptions.months = months;
		this.calendarOptions.start = start;
		this.calendarOptions.canNavigateToPreviousMonth = !firstMonth.isSame(start, 'day');
		this.calendarOptions.canNavigateToNextMonth = true;

		this.calendarOptions.dataLoaded = true;

	}

	getMonths(today, firstMonth, numberOfMonths) {
		moment.locale('en');
		const months = [];
		const startOfMonth = firstMonth.clone();
		const firstDay = firstMonth.clone();
		const lastDay = firstMonth.clone().add(numberOfMonths, 'month').subtract(1, 'day');

		for (let i = 0; i < numberOfMonths; ++i) {
			const month = {
				name: moment(startOfMonth).format('MMMM YYYY'),
				weeks: this.getWeeks(today.clone(), startOfMonth.clone(), firstDay, lastDay)
			};

			months.push(month);
			startOfMonth.add(1, 'month');
		}
		return months;
	}

	getWeeks(today, month, firstDay, lastDay) {
		const day = month.clone().startOf('isoWeek');

		const weeks = [];

		do {
			const week = {
				days: []
			};

			for (let dayInWeek = 0; dayInWeek < 7; ++dayInWeek) {
				const id = day.format('YYYYMMDD');

				week.days.push({
					id,
					name: day.format('D'),
					differentMonth: day.month() !== month.month(),
					today: day.isSame(today, 'day'),
					numberOfEvents: this.getNumberOfEvents(id),
					selected: day.month() === month.month() && id === this.selectedDay,
					beforeToday: day < today,
					inPreviousMonth: day.year() * 12 + day.month() < firstDay.year() * 12 + firstDay.month(),
					inNextMonth: day.year() * 12 + day.month() > lastDay.year() * 12 + lastDay.month()
				});
				day.add(1, 'day');
			}

			weeks.push(week);

		} while (day.month() === month.month());

		return weeks;
	}

	getNumberOfEvents(id) {
		const rowData = this.rowDataCopy || this.options.rowData;

		if (rowData) {
			const data = _.find(rowData, { 'date': id });

			if (data) {
				return data.events.length;
			}
		}
		return 0;
	}
}

export default ScheduledEventsController;
