/*
 * pageBodyModule
 * */

import pageBodyComponent from './pageBody.component';

const pageBodyModule = angular.module('app.common.pageBody', [])
	.component('pageBody', pageBodyComponent)
	.name;

export default pageBodyModule;
