/*
 * sitesComponent
 * */

import templateUrl from './sites.html';
import controller from './sites.controller';
import './sites.scss';

const sitesComponent = {
	bindings: {
		title: '@'
	},
	templateUrl,
	controller
};

export default sitesComponent;
