/*
 * downloadsComponent
 * */

import templateUrl from './downloads.html';
import controller from './downloads.controller';
import './downloads.scss';

const downloadsComponent = {
	bindings: {},
	templateUrl,
	controller
};

export default downloadsComponent;
