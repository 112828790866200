module.exports = (function () {
  'use strict';

  angular.module('portalApp').factory('authenticator', Authenticator);

  /**
   * Authenticator object communicates between AngularJs controller and ESAS Token service
   * @param $q Promise
   * @param $log Log, under $log is hidden our log wrapper that sends logging info to server
   * @param $http for HTTP AJAX requests
   * @param appConfig application configuration
   * @param appSession represents user session saved in memory and localStorage, usefull to get useId, user names, email etc.
   * @param ssoService service used to enable switch to MSP3 1.x and EPX by saving user password in encrypted anonymized form
   * @returns {{login: Function}}      
   */

	/** @ngInject */
  function Authenticator($q, $log, $http, appConfig, appSession, ssoService) {

    $log = $log.getInstance('Authenticator', 'color:green; font-size:1em; background-color:#dedede;');
    $log.debug('loaded');

    /**
     * Login operation. Sends request for JWT token
     * @returns {Deferred.promise}
     */
    var login = function (username, password) {
        var q = $q.defer();

        $log.debug('authenticator.login( `{0}` )', [username]);

        //IMPORTANT NOTICE: object, field names should remain same, because they are part of contract between OAuth server and this client
        var credentials = {
          username: username,
          password: password,
          grant_type: appConfig.auth.grantType,
          client_id: appConfig.auth.clientId
        };

        $log.debug('authenticator, prepare to HTTP POST token( \'{username}\', \'{grant_type}\', \'{client_id}\' )', credentials);
        $log.debug('authenticator, HTTP POST to auth \'{0}\'', [appConfig.auth.tokenUrl]);    

        $http({
          method: 'POST',
          url: appConfig.auth.tokenUrl,
          headers: {'Content-Type': appConfig.constants.applicationJson},
          transformRequest: function (obj) {
            // Constructs url encoded form request where all fields are URL encoded and concatenated using '&'
            var str = [];

            for (var p in obj) {
              str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }

            $log.debug('transofrm', str.join('&'));

            return str.join('&');
          },
          data: credentials
        }).then(function (response) {

            $log.debug('authenticator POST success with user \'{0}\' )', [response.data]);

            if (response.data) {
              var session = appSession.create(response.data);
              
							ssoService.userLogin(username, password).then(
								function() {
									q.resolve(session);
								},
								function() {
                  var msg = supplant('Token endpoint request finished successfully. Failed ssoService request to {0}', [appConfig.apiUrls.sso.loginUser]);                  
                  $log.error(msg);
									q.reject(msg);
								});
                
            } else {
              var msg = 'Token endpoint request finished successfully. But no access token in response';
              $log.error(msg);
              q.reject(msg);
            }
        }, function (response) {
            $log.error('Error during login, request to {0} failed with an error: {1}', appConfig.auth.tokenUrl, response.data);

            q.reject(response.data);
        });

        return q.promise;
      },
      /**
       * ValidatePassword operation. Sends request to server to validate password for the user
       */
      validatePassword = function(userid, password) {

        var token = appSession.getSessionToken();

        // TODO Sindu-20151014 Invoke the the ESAS server method to get the results
        return true;
      };		

    return {
      login: login,     
      validatePassword: validatePassword
    };
  }
})();
