var DataExport = (function () {
	'use strict';

	var gridExportXslx = function (columnDefs, groupHeader, rowData, $filter) {

    var availableColumnDefs = columnDefs.filter(function (item) {
      return item.isHidden !== true && item.showOnlyInFilter !== true;
    });
		var data = fillDataArray(availableColumnDefs, groupHeader, rowData, $filter);

		var rowCount = data.length;
		// we have always a grid header
		var columnCount = data[0].length;

		//fill worksheet
		var ws = {};
		var range = {s: {c:0, r:0}, e: {c:columnCount, r:rowCount}};

		for (var tableRow = 0; tableRow !== data.length; tableRow++) {
			for (var tableCell = 0; tableCell !== data[tableRow].length; tableCell++) {
				var cell = {v: data[tableRow][tableCell]};
				if (cell.v === null) { continue; }
				var cellRef = XLSX.utils.encode_cell({c:tableCell,r:tableRow});

				if (typeof cell.v === 'number') {
					cell.t = 'n';
				}
				else {
					cell.t = 's';
				}
				ws[cellRef] = cell;
			}
		}

		// set the range of the worksheet
		ws['!ref'] = XLSX.utils.encode_range(range);

		// create workbook and set sheet
		var wb = {SheetNames: [], Sheets: {}};

		wb.SheetNames.push('Data');
		wb.Sheets.Data = ws;

		var wopts = {bookType: 'xlsx', bookSST:false, type:'binary'};

		var wbout = XLSX.write(wb, wopts);

		return wbout;
	};

	var gridExportCsv = function (columnDefs, groupHeader, rowData, $filter) {
    var availableColumnDefs = columnDefs.filter(function (item) {
	    return item.isHidden !== true && item.showOnlyInFilter !== true;
    });
		var data = fillDataArray(availableColumnDefs, groupHeader, rowData, $filter);
		var csvContent = '';
		data.forEach(function(arr, index) {
			var dataString = '"' + arr.join('","') + '"';
			dataString = dataString.replace(/[\n\r]+/g, '');
			csvContent += index < data.length ? dataString + '\n' : dataString;
		});

		return csvContent;

	};

	function fillDataArray(columnDefs, groupHeader, rowData, $filter) {
		// set Header
		var data = [];
		data[0] = [];
		if(groupHeader != null){
			data[0].push(groupHeader.groupByFieldTitle || 'Group Title');
			if (!groupHeader.hideGroupDescription) {
				data[0].push(groupHeader.groupByDescriptionTitle || 'Group Description');
			}
		}
		columnDefs.forEach(function(el) {
			data[0].push(escapeDoubleQuotes(el.displayName));
		});

		// add data rows
		for (var i = 0; i < rowData.length; i++) {
			var row = rowData[i];
			data[i + 1] = [];
			if(groupHeader != null){
				data[i + 1].push(groupHeader.groupByDisplayName(row));
				if (!groupHeader.hideGroupDescription) {
					data[i + 1].push(groupHeader.descriptionArray(row).join());
				}
			}
			for (var j = 0; j < columnDefs.length; j++) {
				// use the same formatting as the grid uses
				if (columnDefs[j].formatter) {
					var value = $filter('gridFilter')(row[columnDefs[j].fieldName], columnDefs[j].formatter, row);
					// but remove html line breaks
					if (value && (columnDefs[j].formatter === 'pipe' || columnDefs[j].formatter === 'lineBreak')) {
						value = value.replace(/<br\/>/gi, '\n');
						value = value.replace(/<b>(.*?)<\/b>/gi, '$1');
						value = value.replace(/<i>(.*?)<\/i>/gi, '$1');
					}
					else if (value && columnDefs[j].formatter === 'bold') {
						value = value.replace(/<b>(.*?)<\/b>/gi, '$1');
					}
					else if (value && columnDefs[j].formatter === 'eventDetailsXml') {
						value = '';
						var match = {};
						var re = /<Entry><Name>(.*?)<\/Name><Value>(.*?)<\/Value><\/Entry>/g;
						while (match = re.exec(row[columnDefs[j].fieldName])) {
							if (value.length > 0) {
								value += '\n';
							}
							if (match[1] !== null && match[1].length > 0) {
								value += match[1] + ': ';
							}
							value += match[2];
						}
					}
					data[i + 1].push(escapeDoubleQuotes(value));
				}
				else {
					data[i + 1].push(escapeDoubleQuotes(row[columnDefs[j].fieldName]));
				}
			}
		}
		return data;
	}

	return {
		gridExportXslx: gridExportXslx,
		gridExportCsv: gridExportCsv
	};

	function escapeDoubleQuotes(str) {
		if (!str) {
			return '';
		}
		if (typeof str === 'string') {
			return str.replace(/"/g, '\\""');
		}
		else {
			return String(str);
		}
	}

})();

module.exports = DataExport;
