class SitesController {
	constructor($log, $q, $state, $stateParams, clinicalDataService, configurationService, appConfig,
		loadingIndicatorService, appI18n, analyticsService) {
		'ngInject';
		this.$log = $log;
		this.$q = $q;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.loadingIndicatorService = loadingIndicatorService;
		this.clinicalDataService = clinicalDataService;
		this.configurationService = configurationService;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.sites = [];
		this.analyticsService = analyticsService;
	}

	$onInit() {
		this.$log = this.$log.getInstance('SitesController');
		this.$log.debug('loaded');

		this.loadingIndicatorService.show();
		this.countryId = this.$stateParams.country;
		this.options = this.getGridConfig();

		this.$q.all({
			columns: this.configurationService.getClinicalDataColumns(this.appConfig.clinicalDataModuleNames.site),
			studySettings: this.configurationService.getStudySettings(),
			translations: this.appI18n.translate([
				'clinicaldata.site.info',
				'clinicaldata.site.calendar',
				'app.common.site',
				'app.common.country'
			])
		}).then(resp => {
			this.translations = resp.translations;
			this.options.columnDefsStatic[0].displayName = `${this.translations['app.common.site']} /
				${this.translations['app.common.country']}`;

			this.options.gridRowActions = this.generateGridRowActions(resp.studySettings);
			this.updateColumnList(resp.columns);
			this.load = true;
		}, (error) => {
			this.$log.debug(`error loading configuration for sites: ${error}`);
		}).finally(() => {
			this.loadingIndicatorService.hide();
		});
	}

	updateColumnList(pdColumnConfig) {
		const columns = [];

		if (!this.countryId) {
			columns.push(this.options.columnDefsStatic[0]);
			this.options.sortOrders = [{ fieldName: 'siteAndInvestigatorAndCountry' }];
		} else {
			columns.push(this.options.columnDefsStatic[1]);
			this.options.sortOrders = [{ fieldName: 'siteAndInvestigator' }];
		}
		pdColumnConfig.forEach(el => {
			const column = this.options.columnDefsStatic.find(c => c.fieldName === el.fieldName);

			if (column) {
				column.displayName = el.label;
				columns.push(column);
			}
		});
		this.options.columnDefs = columns;
	}

	getGridConfig() {
		return {
			columnDefsStatic: [
				{
					displayName: 'Site / Country',
					fieldName: 'siteAndInvestigatorAndCountry',
					type: 'string',
					formatter: 'pipe',
					isDefaultFilter: true,
					isPrimary: true
				},
				{
					displayName: 'Site / PI',
					fieldName: 'siteAndInvestigator',
					type: 'string',
					formatter: 'pipe',
					isDefaultFilter: true,
					isPrimary: true
				},
				{
					displayName: 'NumberOfSubjects',
					fieldName: 'numberOfSubjects',
					type: 'number'
				},
				{
					displayName: 'NumberOfActiveSubjects',
					fieldName: 'numberOfActiveSubjects',
					type: 'number'
				},
				{
					displayName: 'NumberOfInactiveSubjects',
					fieldName: 'numberOfInactiveSubjects',
					type: 'number'
				},
				{
					displayName: 'NumberOfCreatedSubjects',
					fieldName: 'numberOfCreatedSubjects',
					type: 'number'
				},
				{
					displayName: 'NumberOfEnrolledSubjects',
					fieldName: 'numberOfEnrolledSubjects',
					type: 'number'
				},
				{
					displayName: 'CumulativeNumberOfEnrolledSubjects',
					fieldName: 'cumulativeNumberOfEnrolledSubjects',
					type: 'number'
				},
				{
					displayName: 'NumberOfIncludedSubjects',
					fieldName: 'numberOfIncludedSubjects',
					type: 'number'
				},
				{
					DisplayName: 'CumulativeNumberOfIncludedSubjects',
					fieldName: 'cumulativeNumberOfIncludedSubjects',
					type: 'number'
				},
				{
					displayName: 'NumberOfRandomizedSubjects',
					fieldName: 'numberOfRandomizedSubjects',
					type: 'number'
				},
				{
					displayName: 'CumulativeNumberOfRandomziedSubjects',
					fieldName: 'cumulativeNumberOfRandomizedSubjects',
					type: 'number'
				},
				{
					displayName: 'NumberOfTreatmentPeriodCompletedSubjects',
					fieldName: 'numberOfTreatmentPeriodCompletedSubjects',
					type: 'number'
				},
				{
					displayName: 'CumulativeNumberOfTreatmentPeriodCompletedSubjects',
					fieldName: 'cumulativeNumberOfTreatmentPeriodCompletedSubjects',
					type: 'number'
				},
				{
					displayName: 'NumberOfFinishedSubjects',
					fieldName: 'numberOfFinishedSubjects',
					type: 'number'
				},
				{
					displayName: 'NumberOfEarlyTerminatedSubjects',
					fieldName: 'numberOfEarlyTerminatedSubjects',
					type: 'number'
				},
				{
					displayName: 'NumberOfExcludedSubjects',
					fieldName: 'numberOfExcludedSubjects',
					type: 'number'
				},
				{
					fieldName: 'avgFev1Grade',
					type: 'string'
				},
				{
					fieldName: 'avgFvcGrade',
					type: 'string'
				},
				{
					fieldName: 'orFev1AcceptableRate',
					type: 'number'
				},
				{
					fieldName: 'orFvcAcceptableRate',
					type: 'number'
				},
				{
					displayName: 'QcFindingsPercentageCombined',
					fieldName: 'qcFindingsPercentageCombined',
					type: 'number'
				},
				{
					displayName: 'QcFindingsPercentageSlow',
					fieldName: 'qcFindingsPercentageSlow',
					type: 'number'
				},
				{
					displayName: 'QcFindingsPercentageForced',
					fieldName: 'qcFindingsPercentageForced',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageDlco',
					type: 'number'
				},
				{
					displayName: 'qcFindingsPercentageMbw',
					fieldName: 'qcFindingsPercentageMbw',
					type: 'number'
				},
				{
					displayName: 'qcFindingsPercentageBody',
					fieldName: 'qcFindingsPercentageBody',
					type: 'number'
				},
				{
					fieldName: 'qcFindingsPercentageHfs',
					type: 'number'
				},
				{
					displayName: 'NumberOfCompletedVisits',
					fieldName: 'numberOfCompletedVisits',
					type: 'number'
				},
				{
					displayName: 'LastVisitDate',
					fieldName: 'lastVisitDate',
					type: 'date',
					formatter: 'dateFormat'
				},
				{
					displayName: 'LastVisitName',
					fieldName: 'lastVisitName',
					type: 'string'
				},
				{
					displayName: 'NextVisitDate',
					fieldName: 'nextVisitDate',
					type: 'date',
					formatter: 'dateFormat'
				},
				{
					displayName: 'NextVisitName',
					fieldName: 'nextVisitName',
					type: 'string'
				},
				{
					displayName: 'LastDataTransferDateTime',
					fieldName: 'lastDataTransferDateTime',
					type: 'date',
					formatter: 'dateTimeFormat'
				},
				{
					displayName: 'LastDataTransferDate',
					fieldName: 'lastDataTransferDate',
					type: 'date',
					formatter: 'dateFormat'
				},
				{
					displayName: 'NumberOfOpenDcrToErt',
					fieldName: 'numberOfOpenDcrToErt',
					type: 'number'
				},
				{
					displayName: 'NumberOfQueriesToSite',
					fieldName: 'numberOfQueriesToSite',
					type: 'number'
				},
				{
					displayName: 'OverallCompliance',
					fieldName: 'overallCompliance',
					type: 'number'
				},
				{
					displayName: 'NumberOfSubjectReceivedDiary',
					fieldName: 'numberOfSubjectReceivedDiary',
					type: 'number'
				},
				{
					displayName: 'NumberOfSubjectDays',
					fieldName: 'numberOfSubjectDays',
					type: 'number'
				}
			],
			defaultRowAction: (row) => {
				this.rowClicked(row);
			},
			gridRowActions: [],
			gridActions: [],
			getData: (oDataQuery) => {
				return this.loadSites(oDataQuery);
			}
		};
	}

	generateGridRowActions(studySettings) {
		const gridRowActions = [];

		if (!studySettings.hideVisitCalendar) {
			gridRowActions.push({
				displayName: this.translations['clinicaldata.site.calendar'],
				iconCssClass: 'es-icon-calendar',
				action: (row) => {
					const calObj = {
						countryId: row.countryId,
						siteId: row.siteId,
						subjectId: row.subjectId,
						siteName: `${row.sponsorSiteId} - ${row.investigator}`
					};

					this.calendarPopupApi.open(calObj);
				}
			});
		}

		gridRowActions.push({
			displayName: this.translations['clinicaldata.site.info'],
			iconCssClass: 'es-icon-info-circle',
			action: (row) => {
				this.showSiteInfoPopup(row);
			}
		});
		return gridRowActions;
	}

	loadSites(queryStr) {
		return this.clinicalDataService.getSites(this.countryId, queryStr);
	}

	getCalendarPopupApi($API) {
		this.calendarPopupApi = $API;
	}

	rowClicked(row) {
		this.analyticsService.trackEventWithTitle(
			`${this.appConfig.trackCategory.navigateTo}::${this.appConfig.trackId.clinicalDataSubject}`,
			this.appConfig.trackAction.gridEntry, this.appConfig.trackLabel.levelSite);
		this.$state.go(this.appConfig.routes.clDataSiteSubjects, {
			country: row.countryId,
			site: row.siteId
		});
	}

	showSiteInfoPopup(row) {
		this.infoPopupApi.open(row.siteId);
	}

	getInfoPopupApi($API) {
		this.infoPopupApi = $API;
	}
}

export default SitesController;
