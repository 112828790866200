(function(){

	'use strict';

	angular
		.module("portalApp")
		.service("ertStepsWizardService", ErtStepsWizardService);

	/**
	 * @ngInject
	 */

	function ErtStepsWizardService($log, $http, appConfig, appSession, $q) {

		$log = $log.getInstance("ErtWizardService", "color:red");
		$log.debug("loaded");
		var wizard = null;
		var wizardStepChanged = null;
		var submitHandler = null;
		var enableWizardButtonsHandler = null;
		var disableWizardButtonsHandler = null;
		var wizardCanNavigateForwardToStep = null;

		function registerDisableWizardButtonsHandler(handler) {
			disableWizardButtonsHandler = handler;
		}
		function registerEnableWizardButtonsHandler(handler) {
			enableWizardButtonsHandler = handler;
		}

		function enableWizardButtons() {
			if (enableWizardButtonsHandler) {
				enableWizardButtonsHandler();
			}
		}
		function disableWizardButtons() {
			if (disableWizardButtonsHandler) {
				disableWizardButtonsHandler();
			}
		}

		function registerWizard(handler) {
			wizard =  handler;
		}
		function isCurrentStepValid() {
			return wizard.isCurrentStepValid();
		}

		function reset() {
			wizard =  null;
			wizardStepChanged = null;
			submitHandler = null;
			enableWizardButtonsHandler = null;
			disableWizardButtonsHandler = null;
			wizardCanNavigateForwardToStep = null;
		}

		function registerWizardStepChanged(handler) {
			wizardStepChanged =  handler;
		}

		function registerWizardCanNavigateForwardToStep(handler) {
			wizardCanNavigateForwardToStep =  handler;
		}

		function registerSubmit(handler) {
			submitHandler =  handler;
		}

		function doSubmit(handler) {
			if (submitHandler)		    {
				submitHandler();
			}
		}

		function onWizardStepChanged(index) {
			if (wizardStepChanged) {
				wizardStepChanged(index);
			}
		}

		function canNavigateForwardToStep(currentIndex, nextIndex) {
			if (wizardCanNavigateForwardToStep) {
				return wizardCanNavigateForwardToStep(currentIndex, nextIndex);
			}
			return true;
		}

		function currentStepNumber() {
			return wizard.currentStepNumber();
		}
		function isLastStep() {
			return wizard.isLastStep();
		}
		function nextStep() {
			return wizard.nextStep();
		}
		function previousStep() {
			return wizard.previousStep();
		}
		function goTo(index) {
			return wizard.goTo(index);
		}

		function isWizardRegistered() {
			return !!wizard;
		};


		var wizardService = {
			registerWizardCanNavigateForwardToStep: registerWizardCanNavigateForwardToStep,
			canNavigateForwardToStep: canNavigateForwardToStep,
			registerWizardStepChanged: registerWizardStepChanged,
			onWizardStepChanged: onWizardStepChanged,
			registerWizard: registerWizard,
			isCurrentStepValid: isCurrentStepValid,
			reset: reset,
			currentStepNumber: currentStepNumber,
			isLastStep: isLastStep,
			nextStep: nextStep,
			previousStep: previousStep,
			registerSubmit: registerSubmit,
			doSubmit: doSubmit,
			registerDisableWizardButtonsHandler: registerDisableWizardButtonsHandler,
			registerEnableWizardButtonsHandler: registerEnableWizardButtonsHandler,
			enableWizardButtons: enableWizardButtons,
			disableWizardButtons: disableWizardButtons,
			goTo,
			isWizardRegistered
		};

		return wizardService;
	}

})();
