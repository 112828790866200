/*
 * openDcrTableController
 * */

class OpenDcrTableController {
	constructor($log, $state, appConfig) {
		'ngInject';
		this.$log = $log;
		this.$state = $state;
		this.appConfig = appConfig;
	}

	$onInit() {
		this.$log = this.$log.getInstance('OpenDcrTableController');
		this.$log.debug('loaded');
	}

	openTicket(dcr) {
		this.$state.go(this.appConfig.routes.tickets, {
			'filter': `contains(SponsorSiteId,'${dcr.sponsorSiteId}')`
		});
	}
}

export default OpenDcrTableController;
