export default function () {
	return (value, row) => {
		const filtered = [];

		if (value && angular.isArray(value)) {
			value.forEach((item) => {
				if (item && ((!item.enabledIfFieldName && !item.enabledIf) ||
							 (item.enabledIfFieldName && row[item.enabledIfFieldName]) ||
							 (item.enabledIf && item.enabledIf(row)))) {
					filtered.push(item);
				}
			});
		}

		return filtered;
	};
}
