(function(){

    'use strict';

    /**
     * @ngInject
     */
    angular.module('portalApp').directive('clickOutside', function($timeout, $document) {
        return {
            restrict: 'A',
            link: function(scope, element){

                scope.openItem = false;

                scope.toggleSelect = function(){
                    scope.openItem = !scope.openItem;
                };
	
				$document.on('click', clickHandler);
				
				scope.$on('$destroy', function () {
					$document.off('click', clickHandler);
				});
	
				function clickHandler(event){
					var isClickedElementChildOfPopup = element.find(event.target).length > 0;
		
					if (isClickedElementChildOfPopup)
						return;
		
					$timeout(function(){
						scope.openItem = false;
					});
				}
            }
        };
    });
}());
