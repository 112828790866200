/*
 * widgetComponent
 * */

import templateUrl from './widget.html';
import controller from './widget.controller';
import './widget.scss';

const widgetComponent = {
	bindings: {
		heading: '@',
		controls: '<?'
	},
	templateUrl,
	controller,
	transclude: true
};

export default widgetComponent;
