/*
 * baseDatePickerModule
 * */

import baseDatePickerComponent from './baseDatePicker.component';

const baseDatePickerModule = angular.module('app.common.datePicker.baseDatePicker', [])
	.component('baseDatePicker', baseDatePickerComponent)
	.name;

export default baseDatePickerModule;
