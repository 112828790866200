import ticketsComponent from './tickets.component';
import ticketsTableModule from './ticketsTable/index';
import dcrDetailsPopupModule from './dcrDetailsPopup/index';

const dataManagementModule = angular.module('app.components.dataManagement.tickets', [
	ticketsTableModule,
	dcrDetailsPopupModule
])
	.component('datamanagementTickets', ticketsComponent)
	.name;

export default dataManagementModule;
