/*
 * visitCalendarPopupComponent
 * */

import templateUrl from './visitCalendarPopup.html';
import controller from './visitCalendarPopup.controller';
import './visitCalendarPopup.scss';

const visitCalendarPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default visitCalendarPopupComponent;
