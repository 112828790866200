(function() {

	'use strict';

	angular
		.module('portalApp')
		.service('logisticsService', LogisticsService);

	/**
	 * @ngInject
	 */

	function LogisticsService(appConfig, $http, $log, appSession, globalMessageService){

		$log = $log.getInstance("logisticsService", "color:green");
		$log.debug("loaded");

		var newOrderHandler;
		var logisticsHandler;
		var refreshHandler;
    var ebsAvailability = new Rx.ReplaySubject();

		var logisticsService = {
			getSupplyOrderData : getSupplyOrderData,
			createOrder : createOrder,
			getSupplyOrderList : getSupplyOrderList,
			getShipmentTrackingList : getShipmentTrackingList,
			getReturnRequestList : getReturnRequestList,
			getSupplyOrderDetails : getSupplyOrderDetails,
			getShipmentTrackingDetails : getShipmentTrackingDetails,
			getPermission: getPermission,
			showNewOrder: showNewOrder,
			registerNewOrder: registerNewOrder,
			setTitle: setTitle,
			registerHandler: registerHandler,
			registerRefresh: registerRefresh,
			refreshOrderGrid : refreshOrderGrid,
			getSitesForPermission: getSitesForPermission,
			isNewOrderHandlerRegistered,
      getEbsAvailabilityObservable,
      getConfig
    };

		return logisticsService;

    function getEbsAvailabilityObservable() {
      return ebsAvailability;
    }

		function registerRefresh(handler) {
			refreshHandler = handler;
		}

		function registerNewOrder(handler) {
			newOrderHandler = handler;
		}

		function isNewOrderHandlerRegistered() {
			return !!newOrderHandler;
		}

		function registerHandler(handler) {
			logisticsHandler = handler;
		}

		function setTitle(title) {
			logisticsHandler.setTitle(title);
		}

		function refreshOrderGrid() {
			if (refreshHandler) {
				refreshHandler();
			}
		}

		function getSupplyOrderData(siteId, timeout) {
			var url = appConfig.apiUrls.logistics.supplyorderdata.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 'siteId': siteId})
			$log.debug("logisticsService.getSupplyOrderData with url: {url} ", {url:url});
			return $http.get(url, { timeout: timeout.promise });
		}

		function createOrder(siteId, comments, items, signature){
			var url = appConfig.apiUrls.logistics.createorder.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });
			var itemList = [];
			angular.forEach(items, function(item){
				if (item.quantity > 0) {
					itemList.push({productId:item.itemId, name: item.itemName, quantity: item.quantity});
				}
			});
			var data = JSON.stringify({siteId: siteId, comment: comments, items: itemList, signKey: signature.key, signToken: signature.token});
			$log.debug("logisticsService.createOrder with url: {url} and data: {data} ", {url: url, data: data});
			return $http.post(url, data);
			

		}

    function getSupplyOrderList(options) {
			return getConfig().then((config) => {
				if (config.isAlive) {
					let url = appConfig.apiUrls.logistics.supplyorderlist.supplant({
						'studyId': appSession.getStudy().id,
						'studyModeId': appSession.getStudy().studyModeId
					});

					if (options) {
						url += '&' + options;
					}

					$log.debug('logisticsService.getSupplyOrderList with url: {url}', {url});

					return $http.get(url).then((resp) => {
						setEbsAvailability(true);
						return resp;
					});
				} else {
					setEbsAvailability(false);
					return Promise.resolve({
						data: []
					});
				}
			});
		}

		function setEbsAvailability(isAvailable) {
			ebsAvailability.onNext(isAvailable);
		}

		function getShipmentTrackingList(options) {

			return getConfig().then((config) => {
				if (config.isAlive) {
					let url = appConfig.apiUrls.logistics.shipmenttracking.supplant({
						'studyId': appSession.getStudy().id,
						'studyModeId': appSession.getStudy().studyModeId
					});

					if (options) {
						url += '&' + options;
					}

					$log.debug('logisticsService.getShipmentTrackingList with url: {url} ', { url });

					return $http.get(url).then((resp) => {
						setEbsAvailability(true);
						return resp;
					});
				} else {
					setEbsAvailability(false);
					return Promise.resolve({
						data: []
					});
				}
			});
		}

    function getReturnRequestList(options) {
			return getConfig().then((config) => {
				if (config.isAlive) {
					let url = appConfig.apiUrls.logistics.returnrequests.supplant({
						'studyId': appSession.getStudy().id,
						'studyModeId': appSession.getStudy().studyModeId
					});

					if (options) {
						url += '&' + options;
					}

					$log.debug('logisticsService.getReturnRequestList with url: {url}', { url });

					return $http.get(url).then((resp) => {
						setEbsAvailability(true);
						return resp;
					});
				} else {
					setEbsAvailability(false);
					return Promise.resolve({
						data: []
					});
				}
			});
		}

		function getSupplyOrderDetails(siteId, orderId, options) {
			var url = appConfig.apiUrls.logistics.supplyorderdetails.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId,
					'siteId': siteId, 'orderId' : orderId});
			if (options) {
				url += "&" + options;
			}
			$log.debug("logisticsService.getSupplyOrderDetails with url: {url}", {url:url});
			return $http.get(url);

		}

		function getShipmentTrackingDetails(siteId, orderId, options){
			var url = appConfig.apiUrls.logistics.shipmenttrackingdetails.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId,
					'siteId': siteId, 'orderId': orderId});
			if (options) {
				url += "&" + options;
			}
			$log.debug("logisticsService.getShipmentTrackingDetails with url: {url}", {url:url});
			return $http.get(url);
		}

		function getPermission() {
			var url = appConfig.apiUrls.logistics.permissions.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });

			return $http.get(url);
		}

		function showNewOrder() {
			$log.debug("showNewOrder");
			if (newOrderHandler) {
				newOrderHandler.showNewOrder();
			}
		}

		function getSitesForPermission() {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.logistics.sitesForPermission.supplant(
				{'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId });

			return $http.get(url);

		}

		function getConfig() {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.logistics.config.supplant(
				{ 'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId });
			return $http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } }).then((resp) => {
				return resp.data;
			});

		}
	}

})();
