import ODataUriBuilder from './oDataUriBuilder';
const grids = {};

/*
 * oDataService
 * */

export default class oDataService {

	static id() {
		return 'oDataService';
	}

	constructor($log, appConfig) {
		'ngInject';
		this.$log = $log.getInstance('oDataService', 'color:green');
		this.appConfig = appConfig;
	}

	buildUriFor(from) {
		return ODataUriBuilder.buildUriFor(from);
	}

	combineUriBuilder(gridId, uriBuilderName, oDataUriBuilder) {
		if (!gridId || !uriBuilderName) {
			throw Error('Any name was provided for oDataUriBuilder object');
		}
		if (!grids[gridId]) {
			grids[gridId] = {};

		}
		grids[gridId][uriBuilderName] = oDataUriBuilder;
	}

	clearUriBuilders(gridId) {
		delete grids[gridId];
	}

	getCombinedUri(gridId) {
		const uriParts = [];

		for (const key in grids[gridId]) {
			if (grids[gridId].hasOwnProperty(key) && grids[gridId][key]) {
				const uri = grids[gridId][key].toUri();

				if (uri) {
					uriParts.push(uri);
				}
			}

		}

		return `${uriParts.join('&')}&$skip=0&$top=${this.appConfig.oDataQueryMaxItemsNumber}`;
	}
}

