/*
 * openQueriesWidgetComponent
 * */

import templateUrl from './openQueriesWidget.html';
import controller from './openQueriesWidget.controller';
import './openQueriesWidget.scss';

const openQueriesWidgetComponent = {
	bindings: {
		heading: '@',
		expertAvailable: '<',
		openItemsThreshold: '<?'
	},
	templateUrl,
	controller
};

export default openQueriesWidgetComponent;
