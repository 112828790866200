/*
 * ertBasePopupModule
 * */

import ertBasePopupComponent from './ertBasePopup.component';
import ertBaserPopupService from './ertBasePopup.service';

const ertBasePopupModule = angular.module('app.common.popup.ertBasePopup', [])
	.component('ertBasePopup', ertBasePopupComponent)
	.service('ertBasePopupService', ertBaserPopupService)
	.name;

export default ertBasePopupModule;
