/*
 * pageHeaderController
 * */

class PageHeaderController {
	constructor() {
		'ngInject';
	}

	$onInit() {}
}

export default PageHeaderController;
