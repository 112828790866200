/*
 * clinicalDataModule
 * */

import clinicalDataComponent from './clinicalData.component';
import countriesModule from './countries/index';
import sitesModule from './sites/index';
import subjectsModule from './subjects/index';
import qrCodeModule from './qrCode/index';
import ecgViewerModule from './ecgViewer/index';
import ecoaSessionViewer from './ecoaSessionViewer/index';
import scheduledEvents from './scheduledEvents/index';
import externalMicroPortalViewerModule from './deviceMicroPortalViewer/index';

const clinicalDataModule = angular.module(
	'app.components.clinicalData',
	[countriesModule, sitesModule, subjectsModule, qrCodeModule, ecgViewerModule,
	 ecoaSessionViewer, scheduledEvents, externalMicroPortalViewerModule])
	.component('clinicalData', clinicalDataComponent)
	.name;

export default clinicalDataModule;
