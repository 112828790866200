var _ = require('lodash');

angular.module('portalApp').directive('ertWizardStep', ['$log',
    function ErtWizardStep($log) {
        'use strict';

        return {
            restrict: 'E',
            require: "^ertStepsWizard",
            scope: true,
            template: '<div ng-include="templateUrl"></div>',
            link: function ($scope, $element, $attr, stepWizardCtrl) {
                $log.debug("Directive::ertWizardStep linked");
                // registers steps, so that the step scope will be available in the stepWizardCtrl

	            $scope.wizardStep = {};
                stepWizardCtrl.registerStep($attr.label, $scope);
                $scope.templateUrl = $attr.templateUrl;

            }
        }
    }]);
