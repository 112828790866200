var _ = require('lodash');

/**
 * @ngInject
 */
module.exports = function($log, $rootScope, $location, $injector, $q, $interval, appConfig, $cookies, tokenService) {
    var vm = this,
        expertAvailability = new Rx.ReplaySubject();
    vm.session = null;
    vm.studySession = null;
    vm.status = {};
    vm.studies = null;
    $log = $log.getInstance('AppSession', 'color:magenta;');

    return {
        create: create,
        destroy: destroy,
        logout: logout,
        clearCurrentStudy: clearCurrentStudy,
        get: get,
        getSessionToken: getSessionToken,
        getStudy: getStudy,
        getStudyModeId: getStudyModeId,
        getOrLoadStudy: getOrLoadStudy,
        publishStudy: publishStudy,
        handleSessionExpiration: handleSessionExpiration,
        switchStudy: switchStudy,
        switchStudyMode: switchStudyMode,
        getRecentStudies: getRecentStudies,
        refreshRecentStudies: refreshRecentStudies,
        getLastRecentStudy: getLastRecentStudy,
        getStudyFromRecentStudies: getStudyFromRecentStudies,
        currentStudy: vm.currentStudy,
        recentStudies: vm.recentStudies,
        recordStudyLogin: recordStudyLogin,
        getIsExpertUnavailable: getIsExpertUnavailable,
        saveState: saveState,
        studyDto2SessionStudy: studyDto2SessionStudy,
        getLoginWarningInfo: getLoginWarningInfo,
        setLoginWarningInfo: setLoginWarningInfo,
        getTrackerInfo: getTrackerInfo,
        getWindowStudy: getWindowStudy,
        isValid: isValid,
        gssoLogout: gssoLogout,
        updateGlobalHeaderInitTime: updateGlobalHeaderInitTime,
        getGlobalHeaderInitTime: getGlobalHeaderInitTime,
        getNumberOfDownloads,
        setNumberOfDownloads,
        getNumberOfNewDownloads,
        setNumberOfNewDownloads,
        getExpertAvailabilityObservable,
        setTrackerInfo
    };

    function getNumberOfDownloads() {
        return vm.status.numberOfDownloads;
    }

    function setNumberOfDownloads(downloads) {
        vm.status.numberOfDownloads = +downloads;
    }

    function getNumberOfNewDownloads() {
        return vm.status.numberOfNewDownloads || 0;
    }

    function setNumberOfNewDownloads(downloads) {
        vm.status.numberOfNewDownloads = downloads;
    }

    function isValid() {
        var session = get();
        //todo we might need to check global session
        return session !== null;
    }

    function setTrackerInfo(study) {

        const trackerInfo = {
            study,
            userAccessLevel: null,
            userIsInternal: null
        };

        const userService = $injector.get('userService');
        const defers = $q.all([
            userService.getUserAccessLevel(),
            userService.getUserIsInternal()
        ]);

        return defers.then((resolves) => {
            trackerInfo.userAccessLevel = resolves[0];
            trackerInfo.userIsInternal = resolves[1];
            sessionStorage.setItem(appConfig.sessionKeys.trackerInfo, JSON.stringify(trackerInfo));
        });
    }

    function getTrackerInfo() {
        var info = sessionStorage.getItem(appConfig.sessionKeys.trackerInfo) || '{}';
        return angular.fromJson(info);
    }

    function resetTrackerInfo() {
        sessionStorage.removeItem(appConfig.sessionKeys.trackerInfo);
    }

    function recordStudyLogin(studyId) {
        $log.debug('recordStudyLogin: ' + studyId);
        var $http = $injector.get('$http');
        var url = appConfig.apiUrls.studies.recordLogin.supplant({
            'studyId': studyId
        });

        $http.post(url).then(() => {}, (response) => {
            $log.error('recordStudyLogin error with status: ' + response.data.status);
            if (response.data.status === 403) {
                $log.error('recordStudyLogin returned 403, logging user out');
                destroy();
                var $state = $injector.get('$state');
                $state.go(appConfig.routes.login);
            }
        });
    }

    function saveState(toState, toParams) {
        $rootScope.returnState = toState;
        $rootScope.returnParams = toParams;
        $log.debug('session save state: ' + toState + ' params' +
            angular.toJson(toParams));
    }

	function cleanUpSessionStorage() {
		// removes all keys which were stored by using appConfig.sessionKeys.
		// it keeps signature related keys but these will be deleted by signature service.
		for (var key in appConfig.sessionKeys) {
			if (appConfig.sessionKeys.hasOwnProperty(key)) {
				sessionStorage.removeItem(appConfig.sessionKeys[key]);
			}
		}
	}

    function create(userInfo) {
		// clear data cache
		cleanUpSessionStorage();
		vm.session = {
			sessionId: userInfo.sessionId,
            userId: userInfo.userinfo_Id,
            firstName: userInfo.userinfo_FirstName,
            lastName: userInfo.userinfo_LastName,
            email: userInfo.userinfo_email,
            guid: userInfo.userinfo_guid,
            gssoUserId: userInfo.userinfo_gssoUserId,
            tokenExpire: userInfo.expires_in,
            timestamp: Date.now(),
            state: userInfo.state
        };

        tokenService.setSessionToken(userInfo.id_token);
        localStorage.setItem(appConfig.localStorageKeys.session, JSON.stringify(vm.session));
        clearGlobalHeaderInitTime();
        createGssoSession(vm.session);

        // propagate session to root scope for hiding elements in views through bindings
        $rootScope.session = vm.session;

        return vm.session;
    }

    function clearGlobalHeaderInitTime() {
        localStorage.removeItem(appConfig.localStorageKeys.globalHeaderInitTime);
    }

    function updateGlobalHeaderInitTime() {
        localStorage.setItem(appConfig.localStorageKeys.globalHeaderInitTime, Date.now());
    }

    function getGlobalHeaderInitTime() {
        return localStorage.getItem(appConfig.localStorageKeys.globalHeaderInitTime);
    }

    function createGssoSession(session) {
        const gssoSession = {
            accessToken: session.accessToken,
            idToken: session.token,
            timestamp: Date.now(),
            expires: session.tokenExpire,
            account: {
                userId: session.gssoUserId
            },
        };

        storeGssoSession(gssoSession);

    }

    function storeGssoSession(gssoSession) {
        localStorage.setItem(appConfig.localStorageKeys.gssoSession, JSON.stringify(gssoSession));
    }

    function removeGssoSession(gssoSession) {
        localStorage.removeItem(appConfig.localStorageKeys.gssoSession);
    }



    function switchStudyMode(newMode) {
        var currentStudy = getStudy();
        switchStudyAndMode(currentStudy, newMode, false, true);
    }

    function switchStudy(study, useSavedState, switchByStudySelector) {
        return switchStudyAndMode(study, 0, useSavedState, switchByStudySelector);
    }

    function switchStudyAndMode(study, newMode, useSavedState, switchByStudySelector) {
        study.studyModeId = newMode;
        var $state = $injector.get('$state');

        if (switchByStudySelector) {
            $rootScope.studySwitching = true;
        }
        $log.debug('switchtStudyAndMode, set root scope study to null');
        $rootScope.study = null;
        var studySelectService = $injector.get('studySelectService');

        return studySelectService.getStudySwitchConfig(study.id, newMode).then(
            function(response) {
                if (switchByStudySelector) {
                    $rootScope.studySwitching = false;
                }
                if ($state.current.url === appConfig.constants.loginUrl ||
                    $state.current.url === appConfig.constants.empty ||
                    switchByStudySelector) {

                    const defaultRoute = appConfig.getRouteForModuleId(response.data.startingPageId);

                    // the studySwitchConfig call considers all required cases (site admin,
                    // starting page configured in PD, fallback to first available module)
                    if (defaultRoute) {
                        $state.go(defaultRoute, {
                            study: study.id,
                            mode: newMode
                        }, {
                            reload: true
                        });
                    } else {
                        throw Error(`No landing page available. page id: ${response.data.startingPageId}`);
                    }
                } else {
                    $state.go($state.current.name, {
                        study: study.id,
                        mode: newMode
                    }, {
                        reload: true
                    });
                }
                publishStudy(study);
                return response.data;
            },
            function(error) {
                $log.error('switchStudyAndMode: ' + error);
                var newUrl = '';
                if (useSavedState && $rootScope.returnState) {
                    if (!$rootScope.returnParams) {
                        $rootScope.returnParams = {};
                    }
                    $rootScope.returnParams.study = study.id;
                    $rootScope.returnParams.mode = newMode;
                    newUrl = $state.href($rootScope.returnState, $rootScope.returnParams, {
                        absolute: true
                    });
                    clearReturnState();
                }

                window.location.href = newUrl;
                window.location.reload();
            });
    }

    function clearReturnState() {
        $rootScope.returnState = null;
        $rootScope.returnParams = null;
    }

    function gssoLogout() {
        const q = $q.defer();

        clearCurrentStudy();
        destroy(true).then(() => q.resolve(), () => q.resolve());

        return q.promise;
    }

    function logout(withSaveState, noRedirect) {
        $log.debug("app session logout");
        var q = $q.defer();
        clearCurrentStudy();
        destroy(true).then(
            function() {
                var $state = $injector.get('$state');
                if (withSaveState) {
                    saveState($state.current.name, $state.params);
                }
                if (!noRedirect && $state.current.controller !== 'LoginCtrl') {
                    $state.go(appConfig.routes.login, {}, {
                        reload: false
                    });
                    $rootScope.studySwitching = false;
                }
                q.resolve();
            });
        return q.promise;
    }

    function destroy(withLogout) {
        $rootScope.studySwitching = true;
        var q = $q.defer();
        var hasSession = get();
        if (hasSession && withLogout) {
            var ssoService = $injector.get('ssoService');
            ssoService.userLogout().then(
                function() {
                    destroySession();
                    q.resolve();
                },
                function() {
                    destroySession();
                    q.resolve();
                }
            );
        } else {
            destroySession();
            q.resolve();
        }
        return q.promise;
    }

    function destroySession() {
        vm.session = null;
        tokenService.setSessionToken(null);
        vm.studySession = null;
        setWindowStudy(null);
        $rootScope.session = null;
        $rootScope.study = null;
        vm.studies = null;
        localStorage.removeItem(appConfig.localStorageKeys.session);
        removeGssoSession();
        resetTrackerInfo();
    }

    function clearCurrentStudy() {
        vm.studySession = null;
        $rootScope.study = null;
        var configurationService = $injector.get('configurationService');
        configurationService.invalidateDeferredObjects();
    }

    function get() {
        var storedSession = localStorage.getItem(appConfig.localStorageKeys.session);
        if (storedSession) {
            vm.session = JSON.parse(storedSession);
        } else {
            vm.session = null;
        }

        // propagate session to root scope for hiding elements in views through bindings
        $rootScope.session = vm.session;

        return vm.session;
    }

    function getSessionToken() {
        return tokenService.getSessionToken();
    }

    function getIsExpertUnavailable() {
        return $rootScope.expertUnavailable;
    }

    function getExpertAvailabilityObservable() {
        return expertAvailability;
    }

    function publishStudy(study) {
        if (!$rootScope.study || ($rootScope.study && $rootScope.study.id !== study.id)) {
            // propagate session to root scope for hiding elements in views through bindings
            $log.debug('publish study, root study was null or different study, set now');
            $rootScope.study = study;
            checkIncompleteContacts(study.id);
        }

        $log.debug('publish study, root study was ' + $rootScope.study);
        var lastUsedStudyInWindow = getWindowStudy();

        if (lastUsedStudyInWindow !== study.id) {
            vm.studySession = study;
            setWindowStudy(study.id);
            addToRecentStudies(study);
            recordStudyLogin(study.id);
            checkIncompleteContacts(study.id);
        } else {
            var lastUsedStudyModeInWindow = getWindowStudyMode();
            if (lastUsedStudyInWindow !== study.studyModeId) {
                setWindowStudyMode(study.id);
            }
        }
        var studyFromRecent = getStudyFromRecentStudies(study.id);
        if (!studyFromRecent) {
            addToRecentStudies(study);
        }
    }

    function checkIncompleteContacts(studyId) {
        var userContactService = $injector.get('userContactService');
        userContactService.getIncompletesCount(studyId)
            .then(function(response) {
                $rootScope.incompleteContactsCount = response.data;
                if (response.data > 0) {
                    $rootScope.contactEditingDone = false;
                }
            });
    }

    function getStudy(studyId) {
        if (!studyId) {
            studyId = getWindowStudy();
        }

        if (studyId) {
            var study;
            if (vm.studySession && vm.studySession.id === studyId) {
                study = vm.studySession;
            } else {
                study = getStudyFromRecentStudies(studyId);
            }
            if (study) {
                var $state = $injector.get('$state');
                study.studyModeId = $state.params.mode || 0;
            }
            return study;
        }

        return vm.studySession;
    }

    function getStudyModeId() {
        var study = getStudy();
        var currentMode = 0;
        if (study) {
            currentMode = Number(study.studyModeId);
        }
        return currentMode;
    }

    function getOrLoadStudy(studyId) {
        var q = $q.defer();
        if (!studyId) {
            studyId = $location.search().study;
        }

        var knownStudy = getStudy(studyId);
        if (knownStudy && knownStudy.id === studyId) {
            q.resolve(knownStudy);
        } else {
            var studySelectService = $injector.get('studySelectService');

            studySelectService.getStudies().then(
                function(result) {
                    for (var i = 0; i < result.length; ++i) {
                        if (result[i].protocolId === studyId) {
                            var appSessionStudy = studyDto2SessionStudy(result[i]);
                            q.resolve(appSessionStudy);
                            return;
                        }
                    }
                    q.reject();
                },
                function() {
                    q.reject();
                });
        }

        return q.promise;
    }

    function studyDto2SessionStudy(study) {
        var appSessionStudy = {
            id: study.protocolId,
            name: study.protocolName,
            number: study.protocolAccount,
            sponsorName: study.sponsorName
        };
        return appSessionStudy;
    }

    function addToRecentStudies(study) {
        var studies = getRecentStudies();
        var containsStudy = false;

        for (var i = 0; i < studies.length; ++i) {
            if (studies[i].id === study.id) {
                containsStudy = true;
                // update values as they may have changed
                updateStudyProperties(studies[i], study);
                // put to first pos;
                studies.splice(0, 0, studies.splice(i, 1)[0]);

                break;
            }
        }

        if (!containsStudy) {
            studies.unshift(study);
        }

        if (studies.length > appConfig.constants.recentStudiesLength + 1) {
            studies.pop();
        }
        setRecentStudies(studies);
    }

    function setRecentStudies(studies) {
        vm.studies = studies;
        setUserItem(appConfig.localStorageKeys.studies, JSON.stringify(studies));
        $rootScope.$broadcast(appConfig.broadcast.recentStudiesUpdated);
    }

    function removeUserItem(key) {
        var userSession = get();

        if (!userSession) {
            return;
        }

        var userId = userSession.userId;
        localStorage.removeItem(userId + '-' + key);
    }

    function setUserItem(key, item) {
        var userSession = get();

        if (!userSession) {
            return;
        }

        var userId = userSession.userId;
        localStorage.setItem(userId + '-' + key, item);
    }

    function setPortalItem(key, item) {
        localStorage.setItem(key, item);
    }

    function getPortalItem(key) {
        return localStorage.getItem(key);
    }


    function getUserItem(key) {
        var userSession = get();

        if (userSession && userSession.userId) {
            var userId = userSession.userId;
            return localStorage.getItem(userId + '-' + key);
        } else {
            return null;
        }
    }

    function setWindowStudy(studyId) {
        sessionStorage.setItem(appConfig.sessionKeys.study, studyId);
    }

    function getWindowStudy() {
        let studyId = sessionStorage.getItem(appConfig.sessionKeys.study);

        if (!studyId || studyId === 'null') {
            studyId = $location.search().study;
        }
        return studyId;
    }

    function setWindowStudyMode(mode) {
        sessionStorage.setItem(appConfig.sessionKeys.studyMode, mode);
    }

    function getWindowStudyMode() {
        let mode = sessionStorage.getItem(appConfig.sessionKeys.studyMode);

        if (!mode || mode === 'null') {
            mode = $location.search().mode;
        }
        return mode;
    }

    function getStudyFromRecentStudies(studyId) {
        var recentStudies = getRecentStudies();

        for (var i = 0; i < recentStudies.length; ++i) {
            if (recentStudies[i].id === studyId) {
                return recentStudies[i];
            }
        }
        return undefined;
    }

    function getLastRecentStudy() {
        var recentStudies = getRecentStudies();
        if (recentStudies.length > 0) {
            return recentStudies[0];
        }

        return null;
    }

    function getRecentStudies() {
        var storedStudies = getUserItem(appConfig.localStorageKeys.studies);

        if (storedStudies === null) {
            vm.studies = [];
        } else {
            vm.studies = JSON.parse(storedStudies);
        }

        return vm.studies;
    }

    function refreshRecentStudies() {
        var recentStudies = getRecentStudies();

        if (!recentStudies || recentStudies.length === 0) {
            return;
        }

        var studyIds = [];

        for (var i = 0; i < recentStudies.length; ++i) {
            studyIds.push(recentStudies[i].id);
        }

        var studySelectService = $injector.get('studySelectService');

        studySelectService.getStudiesByIds(studyIds).then(
            function(result) {
                recentStudies = getRecentStudies();

                var accessibleStudies = [];

                for (var i = 0; i < result.length; ++i) {
                    var study = studyDto2SessionStudy(result[i]);
                    if (vm.studySession && +vm.studySession.id === +study.id) {
                        updateStudyProperties(vm.studySession, study);

                    }
                    for (var j = 0; j < recentStudies.length; ++j) {
                        if (+recentStudies[j].id === +study.id) {
                            // update values as they may have changed
                            updateStudyProperties(recentStudies[j], study);
                            accessibleStudies.push(+study.id);
                        }
                    }
                }

                var newRecentStudies = [];

                for (var k = 0; k < recentStudies.length; ++k) {
                    if (_.includes(accessibleStudies, +recentStudies[k].id)) {
                        newRecentStudies.push(recentStudies[k]);
                    }
                }

                setRecentStudies(newRecentStudies);
            });
    }

    function updateStudyProperties(target, source) {
        target.name = source.name;
        target.sponsorName = source.sponsorName;
        target.number = source.number;
    }

    function getLoginWarningInfo() {
        var warningInfo = getPortalItem(appConfig.localStorageKeys.loginWarning);
        return warningInfo;
    }

    function setLoginWarningInfo() {
        setPortalItem(appConfig.localStorageKeys.loginWarning, Date.now());
    }

    function handleSessionExpiration() {
        // Clear session because old one was invalid
        const state = $injector.get('$state');
        $log.debug('session expiration in state: ' + state.current.name);

        if (state.current.name !== appConfig.routes.login &&
            state.current.name !== appConfig.routes.authRedirect) {
            // if not already going to login, go now to login
            destroy();

            saveState(state.current.name, state.params);
            $rootScope.returnLocation = $location.absUrl();

            $log.debug('gotoLoginWithReturn() session empty, returning to login \'{0}\'' +
                ', returnLocation \'{1}\'',
                [appConfig.routes.login, $rootScope.returnLocation]);
            $log.debug('state: ' + state.current.name + ' params: ' +
                angular.toJson(state.params));

            $rootScope.$broadcast(appConfig.broadcast.sessionExpiration);

            // redirect to the login page and pass the state parameter
            state.transitionTo(appConfig.routes.login);
        }
    }
};
