var DateTime = require('../../utils/datetime');
var _ = require('lodash');

(function () {
    'use strict';

    angular.module('portalApp').directive('ertEnrollmentGraph', EnrollmentGraph);

    /**
     * @ngInject
     */
    function EnrollmentGraph($window, $log, appI18n, $filter, $timeout) {

        return {
            restrict: 'AE',
            replace: false,
            transclude: false,
            priority: 15,
            /**
             * Using isolated scope in directives is recommended
             * 1. "@" (Text binding / one-way binding)
             * 2. "="  (Direct model binding / two-way binding)
             * 3. "&" (Behaviour binding / method binding)
             */
            scope: {
                graphData: '=',
                dateFormat: '@',
                renderer: '@',
                pixelsPerTick: '@'
            },

            templateUrl: 'scripts/directives/enrollmentGraph/enrollmentGraph.html',

            link: function (scope, element, attributes) {
                $log.debug("Directive::ertEnrollmentGraph linked");

                var labelEnrolled = appI18n.translateImmediate('enrollment-graph.enrolled'),
                    labelRandomized = appI18n.translateImmediate('enrollment-graph.randomized'),
                    labelIncluded = appI18n.translateImmediate('enrollment-graph.included'),
                    labelCreated = appI18n.translateImmediate('enrollment-graph.created'),
                    labelAllSites = appI18n.translateImmediate('enrollment-graph.allsites'),
                    labelAssignedSites = appI18n.translateImmediate('enrollment-graph.assigned-sites'),
                    labelTotals = appI18n.translateImmediate('enrollment-graph.totals'),
                    labelTreatment = appI18n.translateImmediate('enrollment-graph.treatment'),
                    labelTerminated = appI18n.translateImmediate('enrollment-graph.terminated'),
                    labelFinished = appI18n.translateImmediate('enrollment-graph.finished'),
                    labelExcluded = appI18n.translateImmediate('enrollment-graph.excluded'),
                    labelActivated = appI18n.translateImmediate('enrollment-graph.activated');

                var legend = element.find('#legend')[0];
                var defaultLegend = element.find('#default_legend')[0];
                var labelLegend1 = element.find('#label_legend1')[0];
                var labelLegend2 = element.find('#label_legend2')[0];
                var defaultLegendAssigned = element.find('#default_legend_assigned')[0];

                if (!scope.pixelsPerTick) {
                    scope.pixelsPerTick = 150;
                }

                /* Mapping function. See description below where used */
                var mappingFunction = function (item) {
									var mappedItem = {};
									mappedItem.x = item.x;
									mappedItem.y = item.y;
									return mappedItem;
								};

                /* Filling function. See description below where used */
                var fillingFunction = function (array, fillTillLength) {
                    if (array && array.length != 0 && array.length < fillTillLength) {
                        var lastItem = array[array.length - 1];

                        var fillItemsCount = fillTillLength - array.length;
                        for (var i = 0; i < fillItemsCount; i++) {
                            array.push(lastItem);
                        }
                    }
                };

                function preRenderLabel(labelVal, legendVal) {
                    legendVal.innerHTML = labelVal;
                    return legendVal;
                }


                function preRenderLegend(labelName, swatchColor, value, legendVal) {
                    var line = document.createElement('div');
                    line.className = 'legend_item';

                    var swatch = document.createElement('div');
                    swatch.className = 'swatch';
                    swatch.style.backgroundColor = swatchColor;

                    var label = document.createElement('div');
                    label.className = 'item_title';
                    label.innerHTML = labelName + ': ' + $filter('number')(value, 0);

                    line.appendChild(swatch);
                    line.appendChild(label);
                    legendVal.appendChild(line);

                    return legendVal;

                };

                var graph;

                scope.$watchCollection('graphData', function (newVal, oldVal) {
                    if (!newVal) {
                        return;
                    }

                    render(newVal);

                    angular.element($window).unbind('resize.ertWidget');
                    angular.element($window).bind('resize.ertWidget', _.debounce( function () {
                        scope.$apply(function () {

                            element.find('#y_axis')[0].innerHTML = "";
                            element.find('#x_axis')[0].innerHTML = "";
                            element.find('#chart')[0].innerHTML = "";
                            element.find('#default_legend')[0].innerHTML = "";
                            element.find('#label_legend1')[0].innerHTML = "";
                            element.find('#label_legend2')[0].innerHTML = "";
                            element.find('#default_legend_assigned')[0].innerHTML = "";
                            $timeout(render(newVal), 0);


                            $log.debug('Directive::ertEnrollmentGraph reender on resize');
                        });
                    }, 300));

                    function render(newVal) {

                        /* Transform input AJAX data from JSON object to JSON objects of following format [{x:0, y:0},{x:1,y1},...] */
                        var enrollmentPoints = newVal.enrolled ? newVal.enrolled.map(mappingFunction) : [],
                            includedPoints = newVal.included ? newVal.included.map(mappingFunction) : [],
                            createdPoints = newVal.created ? newVal.created.map(mappingFunction) : [],
                            randomizedPoints = newVal.randomized ? newVal.randomized.map(mappingFunction) : [],
                            treatmentPoints = newVal.treatmentPeriodCompleted ? newVal.treatmentPeriodCompleted.map(mappingFunction) : [],
                            terminatedPoints = newVal.earlyTerminated ? newVal.earlyTerminated.map(mappingFunction) : [],
                            finishedPoints = newVal.finished ? newVal.finished.map(mappingFunction) : [],
                            excludedPoints = newVal.excluded ? newVal.excluded.map(mappingFunction) : [],
                            activatedPoints = newVal.activated ? newVal.activated.map(mappingFunction) : [];

                        var enrollmentAssignedPoints = newVal.enrolledAssigned ? newVal.enrolledAssigned.map(mappingFunction) : [],
                            includedAssignedPoints = newVal.includedAssigned ? newVal.includedAssigned.map(mappingFunction) : [],
                            createdAssignedPoints = newVal.createdAssigned ? newVal.createdAssigned.map(mappingFunction) : [],
                            randomizedAssignedPoints = newVal.randomizedAssigned ? newVal.randomizedAssigned.map(mappingFunction) : [],
                            treatmentAssignedPoints = newVal.treatmentPeriodCompletedAssigned ? newVal.treatmentPeriodCompletedAssigned.map(mappingFunction) : [],
                            terminatedAssignedPoints = newVal.earlyTerminatedAssigned ? newVal.earlyTerminatedAssigned.map(mappingFunction) : [],
                            finishedAssignedPoints = newVal.finishedAssigned ? newVal.finishedAssigned.map(mappingFunction) : [],
                            excludedAssignedPoints = newVal.excludedAssigned ? newVal.excludedAssigned.map(mappingFunction) : [],
                            activatedAssignedPoints = newVal.activatedAssigned ? newVal.activatedAssigned.map(mappingFunction) : [];

                        var maxLength = Math.max(enrollmentPoints.length, includedPoints.length, createdPoints.length, randomizedPoints.length,
                            treatmentPoints.length, terminatedPoints.length, finishedPoints.length, excludedPoints.length,
                            enrollmentAssignedPoints.length, includedAssignedPoints.length, createdAssignedPoints.length,
                            randomizedAssignedPoints.length, treatmentAssignedPoints.length, terminatedAssignedPoints.length,
                            finishedAssignedPoints.length, excludedAssignedPoints.length, activatedPoints.length, activatedAssignedPoints.length);

                        /* Filling functions make points arrays to be same length for better plotting
                         Before use of filling functions array of max length is identified,
                         then arrays that are smaller will be filled till max lendth with last item
                         */
                        fillingFunction(enrollmentPoints, maxLength);
                        fillingFunction(includedPoints, maxLength);
                        fillingFunction(createdPoints, maxLength);
                        fillingFunction(randomizedPoints, maxLength);
                        fillingFunction(treatmentPoints, maxLength);
                        fillingFunction(terminatedPoints, maxLength);
                        fillingFunction(finishedPoints, maxLength);
                        fillingFunction(excludedPoints, maxLength);
                        fillingFunction(activatedPoints, maxLength);
                        fillingFunction(enrollmentAssignedPoints, maxLength);
                        fillingFunction(includedAssignedPoints, maxLength);
                        fillingFunction(createdAssignedPoints, maxLength);
                        fillingFunction(randomizedAssignedPoints, maxLength);
                        fillingFunction(treatmentAssignedPoints, maxLength);
                        fillingFunction(terminatedAssignedPoints, maxLength);
                        fillingFunction(finishedAssignedPoints, maxLength);
                        fillingFunction(excludedAssignedPoints, maxLength);
                        fillingFunction(activatedAssignedPoints, maxLength);

                        var dotSize = 2;
                        if (maxLength > 25 && maxLength < 100) {
                            dotSize = 1;
                        } else if (maxLength >= 100) {
                            dotSize = 0;
                        }

                        var width = "750", height = "450", delta = 75;
                        width = element.find('#chart').width();

                        if (attributes.widgetWidth) {
                            width = attributes.widgetWidth;
                        }

                        if (attributes.widgetHeight) {
                            height = attributes.widgetHeight;
                        }

                        //var width = element[0].offsetWidth - delta;
                        if (element[0].offsetWidth <= 550) {
                            scope.pixelsPerTick = 100;
                        }

                        if (element[0].offsetWidth <= 400) {
                            scope.pixelsPerTick = 75;
                        }

                        if (element[0].offsetWidth <= 325) {
                            scope.pixelsPerTick = 50;
                        }

                        var availableSeries = [];
                        if (attributes.createdColor) {
                            availableSeries.push(
                                {
                                    name: labelCreated,
                                    data: createdPoints,
                                    color: attributes.createdColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: createdPoints,
                                    color: attributes.createdColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.enrollmentColor) {
                            availableSeries.push(
                                {
                                    name: labelEnrolled,
                                    data: enrollmentPoints,
                                    color: attributes.enrollmentColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: enrollmentPoints,
                                    color: attributes.enrollmentColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.includedColor) {
                            availableSeries.push(
                                {
                                    name: labelIncluded,
                                    data: includedPoints,
                                    color: attributes.includedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: includedPoints,
                                    color: attributes.includedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.randomizedColor) {
                            availableSeries.push(
                                {
                                    name: labelRandomized,
                                    data: randomizedPoints,
                                    color: attributes.randomizedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: randomizedPoints,
                                    color: attributes.randomizedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }

                        if (attributes.treatmentColor) {
                            availableSeries.push(
                                {
                                    name: labelTreatment,
                                    data: treatmentPoints,
                                    color: attributes.treatmentColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: treatmentPoints,
                                    color: attributes.treatmentColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.terminatedColor) {
                            availableSeries.push(
                                {
                                    name: labelTerminated,
                                    data: terminatedPoints,
                                    color: attributes.terminatedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: terminatedPoints,
                                    color: attributes.terminatedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.excludedColor) {
                            availableSeries.push(
                                {
                                    name: labelExcluded,
                                    data: excludedPoints,
                                    color: attributes.excludedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: excludedPoints,
                                    color: attributes.excludedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.finishedColor) {
                            availableSeries.push(
                                {
                                    name: labelFinished,
                                    data: finishedPoints,
                                    color: attributes.finishedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: finishedPoints,
                                    color: attributes.finishedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.activatedColor) {
                            availableSeries.push(
                                {
                                    name: labelActivated,
                                    data: activatedPoints,
                                    color: attributes.activatedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: activatedPoints,
                                    color: attributes.activatedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }


                        if (attributes.createdAssignedColor) {
                            availableSeries.push(
                                {
                                    name: labelCreated,
                                    data: createdAssignedPoints,
                                    color: attributes.createdAssignedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: createdAssignedPoints,
                                    color: attributes.createdAssignedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.enrollmentAssignedColor) {
                            availableSeries.push(
                                {
                                    name: labelEnrolled,
                                    data: enrollmentAssignedPoints,
                                    color: attributes.enrollmentAssignedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: enrollmentAssignedPoints,
                                    color: attributes.enrollmentAssignedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.includedAssignedColor) {
                            availableSeries.push(
                                {
                                    name: labelIncluded,
                                    data: includedAssignedPoints,
                                    color: attributes.includedAssignedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: includedAssignedPoints,
                                    color: attributes.includedAssignedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.randomizedAssignedColor) {
                            availableSeries.push(
                                {
                                    name: labelRandomized,
                                    data: randomizedAssignedPoints,
                                    color: attributes.randomizedAssignedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: randomizedAssignedPoints,
                                    color: attributes.randomizedAssignedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.treatmentAssignedColor) {
                            availableSeries.push(
                                {
                                    name: labelTreatment,
                                    data: treatmentAssignedPoints,
                                    color: attributes.treatmentAssignedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: treatmentAssignedPoints,
                                    color: attributes.treatmentAssignedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.terminatedAssignedColor) {
                            availableSeries.push(
                                {
                                    name: labelTerminated,
                                    data: terminatedAssignedPoints,
                                    color: attributes.terminatedAssignedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: terminatedAssignedPoints,
                                    color: attributes.terminatedAssignedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.excludedAssignedColor) {
                            availableSeries.push(
                                {
                                    name: labelExcluded,
                                    data: excludedAssignedPoints,
                                    color: attributes.excludedAssignedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: excludedAssignedPoints,
                                    color: attributes.excludedAssignedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.finishedAssignedColor) {
                            availableSeries.push(
                                {
                                    name: labelFinished,
                                    data: finishedAssignedPoints,
                                    color: attributes.finishedAssignedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: finishedAssignedPoints,
                                    color: attributes.finishedAssignedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }
                        if (attributes.activatedAssignedColor) {
                            availableSeries.push(
                                {
                                    name: labelActivated,
                                    data: activatedAssignedPoints,
                                    color: attributes.activatedAssignedColor,
                                    renderer: 'line'
                                },
                                {
                                    legendIgnore: true,
                                    data: activatedAssignedPoints,
                                    color: attributes.activatedAssignedColor,
                                    renderer: 'scatterplot'
                                }
                            );
                        }

                        graph = new Rickshaw.Graph({
                            element: element.find('#chart')[0],
                            width: width,
                            height: height,
                            dotSize: dotSize,
                            series: availableSeries,
                            /* Line smoothing / interpolation method (see D3 docs https://github.com/mbostock/d3/wiki/SVG-Shapes#line_interpolate); notable options:
                             - linear: straight lines between points
                             - step-after: square steps from point to point
                             - cardinal: smooth curves via cardinal splines (default)
                             - basis: smooth curves via B-splines
                             For us suitable are linear or basis
                             */
                            interpolation: "linear",
                            stroke: true,
                            /*
                             A string containing the name of the renderer to be used. Options include area, stack, bar, line, and scatterplot.
                             Also see the multi meta renderer in order to support different renderers per series.
                             */
                            renderer: 'multi'
                            //renderer: scope.renderer
                        });
                        graph.render();

                        var xAxis = new Rickshaw.Graph.Axis.X({
                            element: element.find('#x_axis')[0],
                            graph: graph,
                            /* Distance between X-axis value, bigger distance like 200 less ticks on x-axis, smaller more ticks */
                            pixelsPerTick: scope.pixelsPerTick,
                            /* Format your X-axis date&time values here */
                            tickFormat: function (x) {
                                return (new Date(x * 1000).getUTCMonth() + 1).toString() + '-' + new Date(x * 1000).getUTCFullYear().toString();
                            }
                        });

                        var yAxis = new Rickshaw.Graph.Axis.Y({
                            element: element.find('#y_axis')[0],
                            graph: graph
                        });
                        var hoverDetail = new Rickshaw.Graph.HoverDetail({
                            graph: graph,
                            formatter: function (series, x, y) {
                                var date = '<span class="date">' + DateTime.formatted(new Date(x * 1000), scope.dateFormat) + '</span>';
                                var swatch = '<span class="detail_swatch" style="background-color: ' + series.color + '"></span>';
                                var content = swatch + series.name + ": " + parseInt(y) + '<br>' + date;
                                return content;
                            }
                        });

                        var renderDefaultLegend = function () {

                            var labelLegendPlaceholder1 = document.createElement('div');
                            var labelLegendPlaceholder2 = document.createElement('div');
                            var defaultLegendPlaceholder = document.createElement('div');
                            var defaultLegendAssignedPlaceholder = document.createElement('div');

                            if (attributes.isWidget === 'true') {
                                labelLegendPlaceholder1 = preRenderLabel(labelAllSites, labelLegendPlaceholder1);
                            } else {
                                labelLegendPlaceholder1 = preRenderLabel(labelTotals, labelLegendPlaceholder1);
                            }

                            if (attributes.createdColor && createdPoints && createdPoints.length > 0) {
                                preRenderLegend(labelCreated, attributes.createdColor,
                                    createdPoints.length > 0 ? createdPoints[maxLength - 1].y : 0, defaultLegendPlaceholder);
                            }
                            if (attributes.enrollmentColor && enrollmentPoints && enrollmentPoints.length > 0) {
                                preRenderLegend(labelEnrolled, attributes.enrollmentColor,
                                    enrollmentPoints.length > 0 ? enrollmentPoints[maxLength - 1].y : 0, defaultLegendPlaceholder);
                            }
                            if (attributes.includedColor && includedPoints && includedPoints.length > 0) {
                                preRenderLegend(labelIncluded, attributes.includedColor,
                                    includedPoints.length > 0 ? includedPoints[maxLength - 1].y : 0, defaultLegendPlaceholder);
                            }
                            if (attributes.randomizedColor && randomizedPoints && randomizedPoints.length > 0) {
                                preRenderLegend(labelRandomized, attributes.randomizedColor,
                                    randomizedPoints.length > 0 ? randomizedPoints[maxLength - 1].y : 0, defaultLegendPlaceholder);
                            }
                            if (attributes.terminatedColor && terminatedPoints && terminatedPoints.length > 0) {
                                preRenderLegend(labelTerminated, attributes.terminatedColor,
                                    terminatedPoints.length > 0 ? terminatedPoints[maxLength - 1].y : 0, defaultLegendPlaceholder);
                            }
                            if (attributes.excludedColor && excludedPoints && excludedPoints.length > 0) {
                                preRenderLegend(labelExcluded, attributes.excludedColor,
                                    excludedPoints.length > 0 ? excludedPoints[maxLength - 1].y : 0, defaultLegendPlaceholder);
                            }
                            if (attributes.treatmentColor && treatmentPoints && treatmentPoints.length > 0) {
                                preRenderLegend(labelTreatment, attributes.treatmentColor,
                                    treatmentPoints.length > 0 ? treatmentPoints[maxLength - 1].y : 0, defaultLegendPlaceholder);
                            }
                            if (attributes.finishedColor && finishedPoints && finishedPoints.length > 0) {
                                preRenderLegend(labelFinished, attributes.finishedColor,
                                    finishedPoints.length > 0 ? finishedPoints[maxLength - 1].y : 0, defaultLegendPlaceholder);
                            }
                            if (attributes.activatedColor && activatedPoints && activatedPoints.length > 0) {
                                preRenderLegend(labelActivated, attributes.activatedColor,
                                    activatedPoints.length > 0 ? activatedPoints[maxLength - 1].y : 0, defaultLegendPlaceholder);
                            }

                            if (attributes.randomizedAssignedColor || attributes.enrollmentAssignedColor || attributes.includedAssignedColor ||
                                attributes.createdAssignedColor || attributes.treatmentAssignedColor || attributes.terminatedAssignedColor ||
                                attributes.finishedAssignedColor || attributes.excludedAssignedColor || attributes.activatedAssignedColor) {
                                labelLegendPlaceholder2 = preRenderLabel(labelAssignedSites, labelLegendPlaceholder2);
                            }

                            if (attributes.createdAssignedColor && createdAssignedPoints && createdAssignedPoints.length > 0) {
                                preRenderLegend(labelCreated, attributes.createdAssignedColor,
                                    createdAssignedPoints.length > 0 ? createdAssignedPoints[maxLength - 1].y : 0, defaultLegendAssignedPlaceholder);
                            }
                            if (attributes.enrollmentAssignedColor && enrollmentAssignedPoints && enrollmentAssignedPoints.length > 0) {
                                preRenderLegend(labelEnrolled, attributes.enrollmentAssignedColor,
                                    enrollmentAssignedPoints.length > 0 ? enrollmentAssignedPoints[maxLength - 1].y : 0, defaultLegendAssignedPlaceholder);
                            }
                            if (attributes.includedAssignedColor && includedAssignedPoints && includedAssignedPoints.length > 0) {
                                preRenderLegend(labelIncluded, attributes.includedAssignedColor,
                                    includedAssignedPoints.length > 0 ? includedAssignedPoints[maxLength - 1].y : 0, defaultLegendAssignedPlaceholder);
                            }
                            if (attributes.randomizedAssignedColor && randomizedAssignedPoints && randomizedAssignedPoints.length > 0) {
                                preRenderLegend(labelRandomized, attributes.randomizedAssignedColor,
                                    randomizedAssignedPoints.length > 0 ? randomizedAssignedPoints[maxLength - 1].y : 0, defaultLegendAssignedPlaceholder);
                            }
                            if (attributes.terminatedAssignedColor && terminatedAssignedPoints && terminatedAssignedPoints.length > 0) {
                                preRenderLegend(labelTerminated, attributes.terminatedAssignedColor,
                                    terminatedAssignedPoints.length > 0 ? terminatedAssignedPoints[maxLength - 1].y : 0, defaultLegendAssignedPlaceholder);
                            }
                            if (attributes.excludedAssignedColor && excludedAssignedPoints && excludedAssignedPoints.length > 0) {
                                preRenderLegend(labelExcluded, attributes.excludedAssignedColor,
                                    excludedAssignedPoints.length > 0 ? excludedAssignedPoints[maxLength - 1].y : 0, defaultLegendAssignedPlaceholder);
                            }
                            if (attributes.treatmentAssignedColor && treatmentAssignedPoints && treatmentAssignedPoints.length > 0) {
                                preRenderLegend(labelTreatment, attributes.treatmentAssignedColor,
                                    treatmentAssignedPoints.length > 0 ? treatmentAssignedPoints[maxLength - 1].y : 0, defaultLegendAssignedPlaceholder);
                            }
                            if (attributes.finishedAssignedColor && finishedAssignedPoints && finishedAssignedPoints.length > 0) {
                                preRenderLegend(labelFinished, attributes.finishedAssignedColor,
                                    finishedAssignedPoints.length > 0 ? finishedAssignedPoints[maxLength - 1].y : 0, defaultLegendAssignedPlaceholder);
                            }
                            if (attributes.activatedAssignedColor && activatedAssignedPoints && activatedAssignedPoints.length > 0) {
                                preRenderLegend(labelActivated, attributes.activatedAssignedColor,
                                    activatedAssignedPoints.length > 0 ? activatedAssignedPoints[maxLength - 1].y : 0, defaultLegendAssignedPlaceholder);
                            }


                            /// ---- render all things at once
                            labelLegend1.appendChild(labelLegendPlaceholder1);
                            labelLegend2.appendChild(labelLegendPlaceholder2);
                            defaultLegend.appendChild(defaultLegendPlaceholder);
                            defaultLegendAssigned.appendChild(defaultLegendAssignedPlaceholder);
                        };

                        renderDefaultLegend();
                        xAxis.render();
                        yAxis.render();
                    }
                });
            }
        };
    }
})();

