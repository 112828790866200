(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('MyReportsCtrl', MyReportsController);

	/**
	 * @ngInject
	 */

	function MyReportsController($scope, appConfig, reportsService, loadingIndicatorService, configurationService,
								 $log, documentService, $filter, appI18n, dialogService, appSession, notificationService) {

		$log = $log.getInstance('MyReportsCtrl', 'color:blue');
		$log.debug('loaded');

		var vm = this;

		vm.delete = function (row) {
			vm.confirmHandler = dialogService.createConfirmHandler();
			vm.confirmHandler.show()
				.then(function (result) {
					if (result === true) {
						loadingIndicatorService.show();
						vm.confirmHandler.hide();
						reportsService.deleteReport(row.reportInstanceId)
							.then(function () {
								loadingIndicatorService.hide();
								vm.reload = true;
								$log.debug(' report deleted successfully.');
							}, function (response) {
								loadingIndicatorService.hide();
								$log.debug('error in deleting report: ' + response.data);
								appI18n.translate('reports.my-reports.delete.delete-error-message')
									.then(function (translation) {
										notificationService.showError(translation);
									});
							});
					}
				});
		};

		vm.myReportsOptions = {
			rowDefs: {
				multiSelection: true,
				selectableIf: function (row) {
					return row.status === 'Done';
				},
				identifier: 'reportInstanceId'
			},

			autoUpdateSeconds: 60,

			getData: getMyReports

		};

		function getMyReports(options) {
			return reportsService.getMyReports(options);
		}


		function closeCriteria() {
			vm.criteriaDetails = {};
			vm.showCriteria = false;
		}

		function closeOpenError() {
			vm.openError = false;
			vm.modalErrorMessage = '';
		}

		function getSelectedReports() {
			return $filter('filter')(vm.myReportsOptions.rowData, { selected: true }, false);
		}

		function download() {
			vm.selectedReports = [];
			//Get selected reports from portal grid
			var selectedReports = getSelectedReports();
			loadingIndicatorService.show();

			angular.forEach(selectedReports, function (value) {
				if (value.documentId !== null) {
					vm.selectedReports.push({
						id: value.documentId,
						name: value.reportFileName,
						source: value.reportSource,
						type: value.fileExtension
					});
				}
			});

			documentService.getMyReports(vm.selectedReports)
				.then(function (response) {
					documentService.downloadReport(response.data, $scope, appConfig.downloadDocumentType.studyReport, vm.selectedReports.length);
					loadingIndicatorService.hide();
				}, function (response) {
					$log.debug('error in downloading reports: ' + response.data);
					appI18n.translate('reports.my-reports.download-error')
						.then(function (translation) {
							vm.modalErrorMessage = translation;
							vm.openError = true;
						});
					loadingIndicatorService.hide();
				});
		}

		function activate() {
			configurationService.getUserStudySettings().then((r) => {
				if(_.some(r.data.sitemaps, { name: appConfig.trackId.reportsOds })) {
					showOdsMessage();
				}
				activateReports();
			});
		}

		function showOdsMessage() {
			vm.showOdsMessage = true;
			appI18n.translate( ['reports.my-reports.title']).then(function (translations) {
				vm.title = translations['reports.my-reports.title'];
				reportsService.setTitle(vm.title);
			});
		}


		function activateReports() {
			appI18n.translate([
				'reports.my-reports.title',
				'reports.my-reports.close',
				'reports.my-reports.reports',
				'reports.my-reports.status',
				'reports.my-reports.created',
				'reports.my-reports.created-by',
				'reports.my-reports.format',
				'reports.my-reports.criteria',
				'reports.my-reports.delete',
				'reports.my-reports.download',
				'reports.my-reports.delete-title',
				'reports.my-reports.selection-criteria',
				'reports.my-reports.open-report',
				'reports.my-reports.delete.title',
				'reports.my-reports.delete.message',
				'app.common.subjectKey'
			])
				.then(function (translations) {
					reportsService.setTitle(translations['reports.my-reports.title']);
					vm.closeTitle = translations['reports.my-reports.close'];
					vm.reportTitle = translations['reports.my-reports.reports'];
					vm.statusTitle = translations['reports.my-reports.status'];
					vm.createdTitle = translations['reports.my-reports.created'];
					vm.createdByTitle = translations['reports.my-reports.created-by'];
					vm.formatTitle = translations['reports.my-reports.format'];
					vm.criteriaTitle = translations['reports.my-reports.criteria'];
					vm.deleteTitle = translations['reports.my-reports.delete'];
					vm.downloadTitle = translations['reports.my-reports.download'];
					vm.deleteReportTitle = translations['reports.my-reports.delete-title'];
					vm.selectionCriteriaTitle = translations['reports.my-reports.selection-criteria'];
					vm.openReportTitle = translations['reports.my-reports.open-report'];
					vm.deleteModalTitle = translations['reports.my-reports.delete.title'];
					vm.deleteMessage = translations['reports.my-reports.delete.message'];
					vm.subjectKeyLabel = translations['app.common.subjectKey'];


					vm.myReportsOptions.columnDefs = [
						{
							displayName: vm.reportTitle,
							fieldName: 'reportTemplate',
							isSortable: true,
							type: 'string',
							isDefaultFilter: true
						},
						{
							displayName: vm.statusTitle,
							fieldName: 'status',
							isSortable: true,
							type: 'string'
						},
						{
							displayName: vm.createdTitle,
							fieldName: 'createdOn',
							isSortable: true,
							type: 'date',
							formatter: 'dateTimeFormat'
						},
						{
							displayName: vm.createdByTitle,
							fieldName: 'createdBy',
							isSortable: true,
							type: 'string'
						},
						{
							displayName: vm.formatTitle,
							fieldName: 'format',
							isSortable: true,
							type: 'string'
						}
					];

					vm.myReportsOptions.sortOrders = [{
						fieldName: 'createdOn',
						reverseOrder: true
					}];

					vm.myReportsOptions.gridRowActions = [
						{
							iconCssClass: 'es-icon es-icon-info-circle',
							displayName: vm.criteriaTitle,
							action: function (row) {
								vm.reportName = row.reportTemplate;
								loadingIndicatorService.show();
								reportsService.getSelectionCriteria(row.reportInstanceId)
									.then(function (response) {
										vm.criteriaDetails = _.map(response.data, (criterion) => {
											return {
												name: criterion.name === 'Subject Number' ? vm.subjectKeyLabel : criterion.name,
												value: criterion.value,
											};
										});

										vm.showCriteria = true;
										loadingIndicatorService.hide();
									}, function (response) {
										$log.debug('error in loading criteria details: ' + response.data);
										appI18n.translate('reports.my-reports.criteria-error')
											.then(function (translation) {
												vm.showCriteria = false;
												loadingIndicatorService.hide();
												notificationService.showError(translation);
											});
									});
							}
						},
						{
							iconCssClass: 'es-icon es-icon-function-delete',
							displayName: 'Delete',
							enabledIfFieldName: 'allowDelete',
							action: function (row) {
								vm.delete(row);
							}
						},
						{
							displayName: 'Reports',
							iconCssClass: 'es-icon es-icon-reports',
							enabledIf: (row) => {
								return row.allowOpen && row.documentId;
							},
							subItemsFactory: (row) => {
								if (row.myReport) {
									return row.myReport;
								}
								const myReport = [];

								myReport.push({
									displayName: row.reportFileName,
									iconFileType: row.format,
									action: () => {
										const report = [];

										report.push({
											id: row.documentId,
											name: row.reportFileName,
											type: row.fileExtension,
											source: row.reportSource
										});
										documentService.getMyReports(report)
											.then((response) => {
												const type = row.fileExtension;

												documentService.downloadReport(
													response.data, $scope,
													appConfig.downloadDocumentType.studyReport,
													1, row.reportFileName, type);
											});
									}
								});
								row.myReport = myReport;

								return myReport;
							}
						}


					];

					vm.myReportsOptions.gridActions = [
						{
							displayName: vm.downloadTitle,
							action: function () {
								download();
							},
							isDisabled: () => {
								// wait the data is loaded
								if (vm.myReportsOptions.rowData && vm.myReportsOptions.rowData.length) {
									return getSelectedReports().length === 0;
								}
								return true;
							}
						}
					];

					vm.load = true;
				});
		}

		activate();

	}
})();
