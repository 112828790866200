/*
 * expandableBulletListController
 * */

class ExpandableBulletListController {
	constructor($log, appI18n) {
		'ngInject';
		this.$log = $log;
		this.appI18n = appI18n;
	}

	$onInit() {
		this.$log = this.$log.getInstance('ExpandableBulletListController');
		this.$log.debug('loaded');
		this.showMoreTxt = supplant(
			this.appI18n.translateImmediate('clinicaldata.ecoa.show-more'),
			[this.items.length - this.limitTo > 0 ? this.items.length - this.limitTo : 0]);
	}

	showAll() {
		this.limitTo = undefined;
		this.removeLink = true;
	}
}

export default ExpandableBulletListController;
