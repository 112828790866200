class fileItem {

	canDelete() {
		return !this.isReadOnly() && (angular.isUndefined(this.item.canDelete)) ? true : this.item.canDelete;
	}

	canComment() {
		return !this.isReadOnly() && (angular.isUndefined(this.item.canComment)) ? true : this.item.canComment;
	}

	isReadOnly() {
		return this.readOnly;
	}
}

export default fileItem;

