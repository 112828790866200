/**
 * supplant() does variable substitution on the string. It scans through the string looking for
 * expressions enclosed in { } braces. If an expression is found, use it as a key on the object,
 * and if the key has a string value or number value, it is substituted for the bracket expression
 * and it repeats.
 *
 * Usages:
 *
 *      var user = {
 *              first : "John",
 *              last  : "Doe",
 *              address : {
 *                  city : "Boston",
 *                  state: "Massachusetts"
 *              }
 *          },
 *          message = "Hello Mr. {first} {last}. How's life in {address.city}, {address.state} ?";
 *
 *     supplant( message, user ); => "Hello Mr. John Doe. How's the weather in {address.city}, {address.state} ?";
 *
 * http://javascript.crockford.com/remedial.html
 */
(function () {
  "use strict";

  // supplant() method from Crockfords `Remedial Javascript`
  var supplant = function (template, values, pattern) {
    pattern = pattern || /\{([^\{\}]*)\}/g;

    return template.replace(pattern, function (a, b) {
      var p = b.split('.'),
        r = values;

      try {
        for (var s in p) {
          r = r[p[s]];
        }
      } catch (e) {
        r = a;
      }

      return (typeof r === 'string' || typeof r === 'number') ? r : a;
    });
  };

  var supplantTransform = function (template, values, pattern, transform) {
    pattern = pattern || /\{([^\{\}]*)\}/g;

    return template.replace(pattern, function (a, b) {
      var p = b.split('.'),
        r = values;

      try {
        for (var s in p) {
          r = r[p[s]];
        }
      } catch (e) {
        r = a;
      }

      return transform((typeof r === 'string' || typeof r === 'number') ? r : a);
    });
  };

  var urlParamReplace = function (template, values, pattern) {
    return supplantTransform(template, values, pattern, encodeURIComponent);
  };

  // supplant() method from Crockfords `Remedial Javascript`
  Function.prototype.method = function (name, func) {
    this.prototype[name] = func;
    return this;
  };

  String.method("supplant", function (values, pattern) {
    var self = this;
    return supplant(self, values, pattern);
  });

  String.method("urlParamReplace", function (values, pattern, transform) {
    var self = this;
    return urlParamReplace(self, values, pattern, transform);
  });

  // Publish this global function...
  window.supplant = String.supplant = supplant;
  window.urlParamReplace = String.urlParamReplace = urlParamReplace;

})();
