/*
 * paginationComponent
 * */

import templateUrl from './pagination.html';
import controller from './pagination.controller';
import './pagination.scss';

const paginationComponent = {
	bindings: {
		componentReady: '&',
		initData: '<',
		pageSize: '<?',
		onPagination: '&',
		onMoreDataLoad: '&',
		isDisabled: '<?'
	},
	require: {
		grid: '^^?grid'
	},
	templateUrl,
	controller
};

export default paginationComponent;
