angular.module('portalApp').directive('tooltip', function() {
    return {
        restrict: 'AE',
        transclude: {
            element: 'ttElement',
            content: 'ttContent'
        },
        scope: {},
        bindToController: {
            active: '=',
            enabled: '='
        },
        templateUrl: 'scripts/directives/clinicalDataEntry/tooltip.html',
        controllerAs: 'tt',
        controller: /* @ngInject */ function($scope, $element, $timeout, $window, $document) {
            var tt = this;
            tt.active = tt.active || false;
            tt.enabled = tt.enabled || false;

            tt.canBeShown = function () {
                return tt.enabled;
            };

	        tt.positionPopup = function () {
		        const targetElem = angular.element($element.find('.interactive-tooltip'));
		        const hostElem = angular.element($element.find('.host'));

		        if (targetElem && hostElem && targetElem.length > 0 && hostElem.length > 0) {
			        const ttPosition = positionElements(hostElem, targetElem);

			        targetElem.css({ top: `${ttPosition.top}px`, left: `${ttPosition.left}px`, bottom: 'auto' });
		        }
	        }

	        $scope.$watch('tt.active', function(value) {
		        if (value && tt.enabled) {
		            $timeout(function() {
			            tt.positionPopup();
			            angular.element($window).on('resize', tt.positionPopup);
		            }, 0, false);
		        } else {
			        angular.element($window).off('resize', tt.positionPopup);
		        }
	        });

	        function positionElements(hostElem, targetElem) {
		        hostElem = getRawNode(hostElem);
		        targetElem = getRawNode(targetElem);

		        // need to read from prop to support tests.
		        const targetWidth = angular.isDefined(targetElem.offsetWidth) ? targetElem.offsetWidth : targetElem.prop('offsetWidth');
		        const targetHeight = angular.isDefined(targetElem.offsetHeight) ? targetElem.offsetHeight : targetElem.prop('offsetHeight');

		        const hostElemPos = position(hostElem);
		        const targetElemPos = { top: 0, left: 0 };

		        targetElemPos.top = hostElemPos.top - targetHeight - 20;
		        targetElemPos.left = hostElemPos.left;

		        targetElemPos.top = Math.round(targetElemPos.top);
		        targetElemPos.left = Math.round(targetElemPos.left);

		        return targetElemPos;
	        }

	        function getRawNode(elem) {
		        return elem.nodeName ? elem : elem[0] || elem;
	        }

	        function parseStyle(value) {
		        value = parseFloat(value);
		        return isFinite(value) ? value : 0;
	        }

	        function offsetParent(elem) {
		        elem = getRawNode(elem);

		        var offsetParent = elem.offsetParent || $document[0].documentElement;

		        function isStaticPositioned(el) {
			        return ($window.getComputedStyle(el).position || 'static') === 'static';
		        }

		        while (offsetParent && offsetParent !== $document[0].documentElement && isStaticPositioned(offsetParent)) {
			        offsetParent = offsetParent.offsetParent;
		        }

		        return offsetParent || $document[0].documentElement;
	        }

	        function position(elem, includeMargins) {
		        elem = getRawNode(elem);

		        var elemOffset = offset(elem);
		        if (includeMargins) {
			        var elemStyle = $window.getComputedStyle(elem);
			        elemOffset.top -= parseStyle(elemStyle.marginTop);
			        elemOffset.left -= parseStyle(elemStyle.marginLeft);
		        }
		        var parent = offsetParent(elem);
		        var parentOffset = { top: 0, left: 0 };

		        if (parent !== $document[0].documentElement) {
			        parentOffset = offset(parent);
			        parentOffset.top += parent.clientTop - parent.scrollTop;
			        parentOffset.left += parent.clientLeft - parent.scrollLeft;
		        }

		        return {
			        width: Math.round(angular.isNumber(elemOffset.width) ? elemOffset.width : elem.offsetWidth),
			        height: Math.round(angular.isNumber(elemOffset.height) ? elemOffset.height : elem.offsetHeight),
			        top: Math.round(elemOffset.top - parentOffset.top),
			        left: Math.round(elemOffset.left - parentOffset.left)
		        };
	        }

	        function offset(elem) {
		        elem = getRawNode(elem);
		        var elemBCR = elem.getBoundingClientRect();
		        return {
			        width: Math.round(angular.isNumber(elemBCR.width) ? elemBCR.width : elem.offsetWidth),
			        height: Math.round(angular.isNumber(elemBCR.height) ? elemBCR.height : elem.offsetHeight),
			        top: Math.round(elemBCR.top + ($window.pageYOffset || $document[0].documentElement.scrollTop)),
			        left: Math.round(elemBCR.left + ($window.pageXOffset || $document[0].documentElement.scrollLeft))
		        };
	        }
        },
        link: function (scope, element, attr, ctrl) {
        }
    };
});
