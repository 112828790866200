/*
 * dispenseKardiaPopupController
 * */
/* eslint-disable no-underscore-dangle */
class DispenseKardiaPopupController {
	constructor($log, $timeout, kardiaService, loadingIndicatorService,
		signatureService, appI18n, appConfig, $stateParams, $q,
		ertBasePopupService, notificationService, userService) {
		'ngInject';
		this.$log = $log;
		this.$timeout = $timeout;
		this.$stateParams = $stateParams;
		this.kardiaService = kardiaService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.signatureService = signatureService;
		this.ertBasePopupService = ertBasePopupService;
		this.notificationService = notificationService;
		this.userService = userService;
		this.appI18n = appI18n;
		this.appConfig = appConfig;
		this.$q = $q;
		this.onlineHelpId = 'MSP3.ClinicalData.Kardia.Dispense';

		this.isLoading = false;
		this.$API = null;
		this.kardiaForm = null;
		this.state = null;

		this.errors = {
			genericError: false
		};

		// SITES
		this.sites = null;
		this.selectedSite = null;
		this.sitesOptions = {
			label: 'app.common.siteId',
			disabled: false,
			id: 'sitesDropdown',
			required: true,
			placeholder: 'clinicaldata.subject.create.selectSite',
			uiValue: 'displaySiteName'
		};

		// SUBJECTS
		this.subjects = [];
		this.selectedSubject = null;
		this.subjectsOptions = {
			label: 'app.common.subjectKey',
			id: 'subjectsDropdown',
			required: true,
			placeholder: 'clinicaldata.subject.kardia.close-dispense.selectSubject',
			uiValue: 'subjectKey'
		};

		// SUBJECT FIELDS
		this.subjectFields = null;

		// PARAMETER FIELDS
		this.parameterFields = [];
		this.parametersModel = {
			FEV1: null,
			FVC: null,
			PEF: null
		};

		// PARAMETERS VALIDATION
		this.onlyNumeric = {
			fn: (inputValue) => {
				if (!inputValue) { return true; }
				return !isNaN(Number(inputValue));
			}
		};

		this.getMinMaxValidator = (field) => {
			return {
				fn: (inputValue) => {
					if (!inputValue) { return true; }
					return !(inputValue < +field.minimumValue || inputValue > +field.maximumValue);
				}
			};
		};

		this.getNumberOfDecimalsValidator = (field) => {
			return {
				fn: (inputValue) => {
					if (!inputValue) { return true; }

					const decimals = field.numberOfDecimals;
					const fraction = `${inputValue}`.split('.');
					const decimalsNr = fraction[1] && fraction[1].length;

					return !(decimalsNr && decimalsNr > decimals);
				}
			};
		};

		this.isRequiredText = 'clinicaldata.subject.create.errors.required.general';
		this.onlyNumericText = 'clinicaldata.subject.create.validation.onlyNumeric';

		this.getMinMaxMessage = (field) => {
			return this.appI18n.translateImmediate('clinicaldata.subject.create.validation.minMaxText').supplant({
				minValue: field.minimumValue,
				maxValue: field.maximumValue
			});
		};

		this.getNumberOfDecimalsText = (field) => {
			return this.appI18n.translateImmediate('clinicaldata.subject.create.validation.numberOfDecimals').supplant({
				numberOfDecimals: field.numberOfDecimals
			});
		};

		// ACTION BUTTONS
		this.signSubmitBtn = {
			action: () => {
				this.initiateSignature();
			},
			cssClass: '-es-primary',
			displayName: 'app.buttons.sign-submit',
			isDisabled: false,
			type: 'submit'
		};

		this.cancelBtn = {
			action: () => {
				this.$API.close();
			},
			displayName: 'app.buttons.cancel',
			isDisabled: false
		};

		this.actions = [this.signSubmitBtn, this.cancelBtn];
	}

	$onInit() {
		this.$log = this.$log.getInstance('DispenseKardiaPopupController');
		this.$log.debug('loaded');

		this.configure();

		this.$API = this.setApi();
		this.componentReady && this.componentReady({ $API: this.$API });

		if (this.$stateParams.signKey) {
			const state = this.signatureService.getState(this.$stateParams.signKey);

			if (state) {
				if (state.sigType === this.getSignatureType()) {
					if (this.signatureService.checkSignatureWorkflow(this.$stateParams.signKey,
						this.$stateParams.tokenId,
						this.doSubmit, this.onSignatureCancel, this)) {
						return;
					}
				}
			}
		}
	}

	configure() {
		this.popupHeading = 'clinicaldata.subject.i-spiro.close-dispense.dispenseTitle';
	}

	makeFormsDirty() {
		angular.forEach(this.kardiaForm.$error.required, (field) => {
			field.$setDirty();
		});
		this.kardiaForm.showValidation = true;
	}

	initiateSignature() {
		if (this.errors.genericError) {
			return;
		}
		if (this.kardiaForm.$invalid) {
			this.makeFormsDirty();
			return;
		}
		const sigType = this.getSignatureType();

		// save state for signature process
		this.state = {
			_openPopupParams: {
				preselectedSite: this.preselectedSiteId,
				preselectedSubject: this.preselectedSubjectId
			},
			_sites: this.sites,
			selectedSite: this.selectedSite,

			_subjects: this.subjects,
			selectedSubject: this.selectedSubject,


			initials: this.initialsValue,
			dob: this.dobValue,

			subjectId: this.subjectIdValue,

			subjectId2: this.subjectId2Value,

			age: this.ageValue,
			weight: this.weightValue,
			height: this.heightValue,

			_ethnicities: this.ethnicities,
			selectedEthn: this.selectedEthn,

			_genders: this.genders,
			selectedGender: this.selectedGender,

			_parameterFields: this.parameterFields,
			parametersModel: this.parametersModel,
			isRemoteAction: this.isRemoteAction,

			sigType
		};

		this.additionalInfo = {
			action: (this.isCreatingSubject())
				? this.appI18n.translateImmediate('signature.action.createSubject')
				: this.appI18n.translateImmediate('signature.action.editSubject'),
			infoKey1: this.appI18n.translateImmediate('signature.info.site'),
			infoValue1: this.selectedSite.sponsorSiteId,
			infoKey2: this.appI18n.translateImmediate('signature.info.subject'),
			infoValue2: this.isCreatingSubject() ? this.subjectIdValue : this.selectedSubject.subjectKey
		};

		this.signatureService.sign(sigType, this.additionalInfo, this.state);
	}

	getSignatureType() {
		return this.appConfig.sigType.createEditKardiaSubject;
	}

	doSubmit(state) {
		const action = this.chooseSubmitAction(state);

		action && action.then((resp) => {
			if (resp.data.isValidationError) {
				const msg = (resp.data && resp.data.responseMessageTranslationKey)
					  ? resp.data.responseMessageTranslationKey
					  : 'clinicaldata.subject.create.errors.genericSaveError';

				this.notificationService.showError(this.appI18n.translateImmediate(msg));
				this.open = true;
				this.restorePopupState(state);
			} else {
				const isCreateSubject = (state.selectedSubject.subjectId === null);

				const qrCode = isCreateSubject
					  ? resp.data[0].qrCode
					  : resp.data.qrCode;

				this.ertBasePopupService.popup('kardiaPopup')
					.open(state.selectedSite.countryId, state.selectedSite.siteId,
						  isCreateSubject
						  ? resp.data[0].subjectId
						  : state.selectedSubject.subjectId,
						  action.actionId,
						  state.selectedSite.displaySiteName,
						  isCreateSubject
						  ? resp.data[0].subjectKey
						  : state.selectedSubject.subjectKey,
						  qrCode);

				if (isCreateSubject) {
					this.showSaveDelayWarning();
				}
			}
		}, (/*error*/) => {
			this.open = true;
			this.restorePopupState(state);
		});
	}

	showSaveDelayWarning() {
		this.appI18n.translate(['clinicaldata.subject.create.saveDelay']).then((translations) => {
			const msg = translations['clinicaldata.subject.create.saveDelay'];

			this.notificationService.showSuccess(msg);
		});

		return false;
	}

	chooseSubmitAction(state) {
		let action;
		const isCreateSubject = (state.selectedSubject.subjectId === null);
		const isDispenseOnly = !this.parametersAreEditable(state._parameterFields);

		if (isCreateSubject) {
			const payload = this.generateCreateRequestPayload(state);

			action = this.kardiaService.createSubject(payload);
			action.actionId = this.appConfig.kardiaActionId.dispense;
		} else {
			// the parameters are not editable
			if (isDispenseOnly) {
				// call popup with dispense qr code
				this.ertBasePopupService.popup('kardiaPopup').open(
					state.selectedSite.countryId, state.selectedSite.siteId,
					state.selectedSubject.subjectId, this.appConfig.kardiaActionId.dispense,
					state.selectedSite.displaySiteName, state.selectedSubject.subjectKey);
				return null;
			}
			// is dispensing device + change threshold values
			const payload = this.generateUpdateRequestPayload(state);

			action = this.kardiaService.getRegistrationCode(payload, state.selectedSubject.subjectId, true);
			action.actionId = this.appConfig.kardiaActionId.dispense;
		}
		return action;
	}

	onSignatureCancel(state) {
		this.$timeout(() => {
			this.open = true;
			this.restorePopupState(state);
		});
	}

	restorePopupState(state) {

		if (state._openPopupParams.preselectedSite) {
			this.sitesOptions.disabled = true;
		}
		if (state._openPopupParams.preselectedSubject) {
			this.subjectsOptions.disabled = true;
		}
		this.sites = state._sites;
		this.selectedSite = state.selectedSite;

		this.subjects = state._subjects;
		this.selectedSubject = state.selectedSubject;

		let getSubjectInfo;

		this.isLoading = true;
		if (this.isCreatingSubject()) {
			getSubjectInfo = this.getCreateSubjectInfo(this.selectedSite.countryId, this.selectedSite.siteId);
		} else {
			getSubjectInfo = this.getEditSubjectInfo(this.selectedSite.countryId, this.selectedSite.siteId,
				this.selectedSubject.subjectId);
		}

		return getSubjectInfo.then((data) => {

			this.subjectFields = data.fields;
			this.ethnicities = data.ethnicities;
			this.generateSubjectFields(this.subjectFields, this.selectedSite.sponsorSiteId);

			this.initialsValue = state.initials;
			this.dobValue = state.dob;
			this.subjectIdValue = state.subjectId;
			this.subjectId2Value = state.subjectId2;
			this.heightValue = state.height;
			this.weightValue = state.weight;
			this.ageValue = state.age;
			this.selectedEthn = state.selectedEthn;
			this.selectedGender = state.selectedGender;

			this.isLoading = false;
		});
	}

	generateCreateRequestPayload(state) {
		return {
			siteId: +state.selectedSite.siteId, //number
			subjectId1: state.subjectId,
			subjectId2: state.subjectId2,
			height: state.height,
			weight: state.weight,
			age: state.age,
			genderId: state.selectedGender ? state.selectedGender.id : null,
			ethnicityName: state.selectedEthn ? state.selectedEthn.name : null,
			ethnicityId: state.selectedEthn ? state.selectedEthn.id : null,
			dateOfBirth: state.dob,
			initials: state.initials,
			signKey: this.$stateParams.signKey,
			signToken: this.$stateParams.tokenId
		};
	}

	generateUpdateRequestPayload(state) {
		return {
			siteId: +state.selectedSite.siteId, //number
			Pef: state.parametersModel.PEF,
			Fev1: state.parametersModel.FEV1,
			FVC: state.parametersModel.FVC,
			signKey: this.$stateParams.signKey,
			signToken: this.$stateParams.tokenId
		};
	}

	setApi() {
		const $API = {
			open: (isRemoteAction, countryId, siteId, subjectId, displaySiteName, subjectKey) => {
				this.errors.genericError = false;
				this.loadingIndicatorService.show();
				this.isRemoteAction = isRemoteAction;
				this.countryId = countryId;
				if (siteId && subjectId) {
					this.hideContentMessage = true;
					this.preselectedSiteId = siteId;
					this.selectedSite = {
						countryId: +countryId,
						siteId: +siteId,
						displaySiteName
					};
					this.sites = [this.selectedSite];
					this.sitesOptions.disabled = true;

					this.preselectedSubjectId = subjectId;
					this.selectedSubject = {
						subjectKey,
						subjectId
					};
					this.subjects = [this.selectedSubject];
					this.subjectsOptions.disabled = true;

					this.proceedWithEditSubject(this.selectedSubject).then(() => {
						this.open = true;
					}).finally(() => {
						this.loadingIndicatorService.hide();
					});

				} else {
					this.hideContentMessage = false;
					this.onPopupShow().then(() => {
						siteId && this.preselectSite(siteId.toString());
						this.open = true;
					}).finally(() => {
						this.loadingIndicatorService.hide();
					});
				}

			},
			close: () => {
				this.state = null;
				this.sites = null;
				this.preselectedSiteId = null;
				this.sitesOptions.disabled = false;
				this.subjectsOptions.disabled = false;
				this.subjects = [];
				this.selectedSite = null;
				this.selectedSubject = null;
				this.resetDemographics();
				this.open = false;
			}
		};

		return $API;
	}

	onPopupShow() {
		return this.$q.all([
			this.kardiaService.getSitesMinimal(null, this.countryId).then((res) => {
				this.sites = res.data;
			}),
			this.appI18n.translate(['clinicaldata.subject.buttons.create']).then(
				(res) => {
					this.translations = res;
				}
			),
			this.userService.getUserHasPermission(
				this.appConfig.businessPermissions.createKardiaSubject)
				.then((res) => {
					this.canCreateSubjects = res;
				})
		]);
	}

	getEditSubjectInfo(countryId, siteId, subjectId) {
		return this.kardiaService.getDispenseSubjectData(countryId, siteId, subjectId).then((res) => {
			return res.data;
		});
	}

	getCreateSubjectInfo(country, site) {
		return this.kardiaService.getCreateSubjectData(country, site).then((res) => {
			return res.data;
		}, (error) => {
			this.errors.genericError = true;
			this.isLoading = false;
			return error;
		});
	}

	cancelAction() {
		this.$API.close();
	}

	onSiteChange(newVal) {

		this.resetDemographics();
		this.selectedSite = newVal;

		if (!newVal) {
			this.subjects = [];
			return;
		}

		this.isLoading = true;
		this.getSubjects(newVal).then((res) => {
			this.subjects = res;
		}).finally(() => {
			this.isLoading = false;
		});
	}

	getSubjects(newVal) {
		return this.kardiaService.getSubjects(
			newVal.countryId, newVal.siteId,
			this.appConfig.kardiaActionId.dispense)
			.then((res) => {
				const subjects = res.data;

				if (this.canCreateSubjects) {
					subjects.unshift({
						'subjectKey': this.translations['clinicaldata.subject.buttons.create'],
						subjectId: null
					});
				}

				if (subjects && subjects.length > 0) {
					this.subjectsOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.selectSubject';
				} else {
					this.subjectsOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.noSubject';
				}
				return subjects;
			});
	}

	preselectSite(siteId) {
		this.preselectedSiteId = siteId;
		const siteToSelect = this.sites.find((s) => {
			return s.siteId === siteId;
		});

		this.selectedSite = siteToSelect || null;
		if (this.selectedSite) {
			this.sitesOptions.disabled = true;
			this.onSiteChange(this.selectedSite);
		}
	}

	onSubjectChange(newVal) {
		this.resetDemographics();
		this.selectedSubject = newVal;
		if (!newVal) { return; }

		if (this.isCreatingSubject()) {
			this.proceedWithCreateSubject();
		} else {
			this.proceedWithEditSubject(newVal);
		}
	}

	proceedWithEditSubject(newVal) {
		this.isLoading = true;
		return this.getEditSubjectInfo(this.selectedSite.countryId, this.selectedSite.siteId, newVal.subjectId)
			.then((data) => {
				this.subjectFields = data.fields;
				this.ethnicities = data.ethnicities;
				this.generateSubjectFields(this.subjectFields, this.selectedSite.sponsorSiteId);
				this.initEditSubjectFields(data.subjectData);
				this.isLoading = false;
			}, (/*error*/) => {
				this.isLoading = false;
			});
	}

	proceedWithCreateSubject() {
		this.isLoading = true;
		this.getCreateSubjectInfo(this.selectedSite.countryId, this.selectedSite.siteId).then((data) => {
			this.subjectFields = data.fields;
			this.ethnicities = data.ethnicities;
			this.generateSubjectFields(this.subjectFields, this.selectedSite.sponsorSiteId);
			this.isLoading = false;
		});
	}

	initEditSubjectFields(subjectData) {

		this.initialsValue = subjectData.initials;

		this.dobValue = subjectData.dateOfBirth;

		this.subjectIdValue = subjectData.subjectId1;

		this.subjectId2Value = subjectData.subjectId2;
		this.weightValue = subjectData.weight;
		this.heightValue = subjectData.height;
		this.ageValue = subjectData.age;

		if (this.ethnicities) {
			this.selectedEthn = this.ethnicities.find((eth) => {
				return eth.id === subjectData.ethnicityId;
			});
		}
		if (this.genders) {
			this.selectedGender = this.genders.find((gen) => {
				return +gen.id === +subjectData.genderId;
			});
		}
	}

	generateSubjectFields(fieldsData, sponsorSiteId) {

		fieldsData.forEach((field) => {
			switch (+field.id) {
				case this.appConfig.demographics.initials:
					this.initInitialsField(field);
					break;
				case this.appConfig.demographics.dob:
					this.initDOBField(field);
					break;
				case this.appConfig.demographics.subjectId1:
					this.initSubjectIdField(field, sponsorSiteId);
					break;
				case this.appConfig.demographics.subjectId2:
					this.initSubjectID2Field(field);
					break;
				case this.appConfig.demographics.ethnicity:
					this.initEthnicityField(field);
					break;
				case this.appConfig.demographics.gender:
					this.initGender(field);
					break;
				case this.appConfig.demographics.height:
					this.initHeightField(field);
					break;
				case this.appConfig.demographics.weight:
					this.initWeightField(field);
					break;
				case this.appConfig.demographics.age:
					this.initAgeField(field);
					break;
			}
		});
	}

	initParameterField(field) {
		this.parameterFields.push(field);
	}

	canEditParameters() {
		return false;
	}

	initInitialsField(field) {
		this.initialsValue = null;
		this.initialsOptions = {
			label: field.label,
			id: 'initialsInput',
			required: field.isRequired,
			disabled: !this.isCreatingSubject(),
			type: 'text',
			placeholder: '',
			fieldFormatHint: field.formatHint,
			validators: {
				inputFormat: {
					fn: (inputValue) => {
						if (!this.isCreatingSubject() || !inputValue) {
							return true;
						}
						return this.validateFormat(inputValue, field);
					}
				}
			}
		};
	}

	initDOBField(field) {
		this.dobValue = null;
		this.dobOptions = {
			label: field.label,
			id: 'dobInput',
			required: field.isRequired,
			disabled: !this.isCreatingSubject(),
			type: 'text',
			placeholder: '',
			fieldFormatHint: field.formatHint,
			validators: {
				inputFormat: {
					fn: (inputValue) => {
						if (!this.isCreatingSubject() || !inputValue) { return true; }
						return this.validateFormat(inputValue, field);
					}
				}
			}
		};
	}

	initSubjectIdField(field, sponsorSiteId) {
		this.subjectIdValue = null;
		this.subjectIdOptions = {
			label: field.label,
			id: 'subjectIdInput',
			required: field.isRequired,
			disabled: !this.isCreatingSubject(),
			type: 'text',
			placeholder: '',
			fieldFormatHint: field.formatHint,
			validators: {
				siteIdPrefix: {
					fn: (inputValue) => {
						if (!this.isCreatingSubject() || !inputValue ||
							!field.isSitePrefix || !sponsorSiteId) { return true; }
						return inputValue.startsWith(sponsorSiteId);
					}
				},
				inputFormat: {
					fn: (inputValue) => {
						if (!this.isCreatingSubject() || !inputValue) { return true; }
						return this.validateFormat(inputValue.trim(), field);
					}
				}
			}
		};

		if (field.isSitePrefix && sponsorSiteId && this.isCreatingSubject()) {
			this.subjectIdValue = sponsorSiteId;
		}
	}


	initTextField(field, name) {
		const textField = {
			label: field.label,
			id: name,
			required: field.isRequired,
			disabled: !this.isCreatingSubject(),
			type: 'text',
			placeholder: '',
			fieldFormatHint: field.formatHint,
			validators: {
				inputFormat: {
					fn: (inputValue) => {
						if (!this.isCreatingSubject() || !inputValue) { return true; }
						return this.validateFormat(inputValue, field);
					}
				}
			}
		};

		return textField;
	}

	initSubjectID2Field(field) {
		this.subjectId2Options = this.initTextField(field, 'subjectID2Input');
		this.subjectId2Value = null;
	}

	initHeightField(field) {
		this.heightOptions = this.initTextField(field, 'heightInput');
		this.heightValue = null;
	}

	initWeightField(field) {
		this.weightOptions = this.initTextField(field, 'weightInput');
		this.weightValue = null;
	}

	initAgeField(field) {
		this.ageOptions = this.initTextField(field, 'ageInput');
		this.ageValue = null;
	}

	initEthnicityField(field) {
		this.selectedEthn = null;
		this.ethnOptions = {
			label: field.label,
			disabled: !this.isCreatingSubject(),
			id: 'ethnDropdown',
			required: field.isRequired,
			placeholder: 'clinicaldata.subject.create.selectEthnicity',
			uiValue: 'name'
		};

		this.onEthnChange = (newVal) => {
			this.selectedEthn = newVal;
		};
	}

	initGender(field) {
		this.selectedGender = null;
		this.genders = [{ name: 'Male', id: 1 }, { name: 'Female', id: 2 }];
		this.genderOptions = {
			label: field.label,
			disabled: !this.isCreatingSubject(),
			id: 'genderDropdown',
			required: field.isRequired,
			placeholder: 'clinicaldata.subject.create.selectGender',
			uiValue: 'name'
		};

		this.onGenderChange = (newVal) => {
			this.selectedGender = newVal;
		};
	}

	validateFormat(inputValue, field) {
		const matches = inputValue.match(field.format);

		return !matches ? false : true;
	}

	isCreatingSubject() {
		return this.selectedSubject.subjectId === null;
	}

	parametersAreEditable(parameterFields) {
		const res = parameterFields.some((p) => {
			return p.isReadOnly === false;
		});

		return !!res;
	}

	resetDemographics() {
		this.initialsOptions = null;
		this.initialsValue = null;

		this.dobOptions = null;
		this.dobValue = null;

		this.subjectIdOptions = null;
		this.subjectIdValue = null;

		this.subjectId2Options = null;
		this.subjectId2Value = null;

		this.ethnOptions = null;
		this.selectedEthn = null;

		this.genderOptions = null;
		this.selectedGender = null;

		this.heightOptions = null;
		this.heightValue = null;

		this.weightOptions = null;
		this.weightValue = null;

		this.ageOptions = null;
		this.ageValue = null;
	}
}
/* eslint-enable no-underscore-dangle */
export default DispenseKardiaPopupController;
