/*
 * previewPopupModule
 * */

import previewPopupService from './previewPopup.service';
import previewPopupComponent from './previewPopup.component';
import popupModule from '../index';

const previewPopupModule = angular.module('app.common.previewPopup', [popupModule])
	.component('previewPopup', previewPopupComponent)
	.service('previewPopupService', previewPopupService)
	.name;

export default previewPopupModule;
