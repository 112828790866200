(function() {

	'use strict';
	angular
		.module('portalApp')
		.controller('SelectExportColumnsCtrl', SelectExportColumnsController);

	/**
	 * @ngInject
	 */
	function SelectExportColumnsController(reportsService, $log, $filter, $scope, appI18n, documentService,
	                                       configurationService, appConfig, loadingIndicatorService) {

		$log = $log.getInstance("SelectExportColumnsCtrl", "color:blue");
		$log.debug("loaded");

		var vm = this;
		vm.format = "csv";
		vm.exportType = "currentfilter";
		vm.isLead = false;

		function activate() {
			reportsService.registerColumnSelectHandler(vm);
			$scope.$on('$destroy', () => {
				reportsService.registerColumnSelectHandler();
			});

			appI18n.translate( [
				"reports.ecg-export.title",
				"app.common.subjectKey",
				"reports.select-columns.export",
				"reports.select-columns.study-cols.col1",
				"reports.select-columns.study-cols.col2",
				"reports.select-columns.study-cols.col3",
				"reports.select-columns.study-cols.col4",
				"reports.select-columns.study-cols.col5",
				"reports.select-columns.study-cols.col6",
				"reports.select-columns.study-cols.col7",
				"reports.select-columns.study-cols.col8",
				"reports.select-columns.study-cols.col9",
				"reports.select-columns.study-cols.col10",
				"reports.select-columns.study-cols.col11",
				"reports.select-columns.study-cols.col12",
				"reports.select-columns.study-cols.col13",
				"reports.select-columns.study-cols.col14",
				"reports.select-columns.study-cols.col15",
				"reports.select-columns.study-cols.col16",
				"reports.select-columns.study-cols.col17",
				"reports.select-columns.ecg-cols.col1",
				"reports.select-columns.ecg-cols.col2",
				"reports.select-columns.ecg-cols.col3",
				"reports.select-columns.ecg-cols.col4",
				"reports.select-columns.ecg-cols.col5",
				"reports.select-columns.ecg-cols.col6",
				"reports.select-columns.ecg-cols.col7",
				"reports.select-columns.ecg-cols.col8",
				"reports.select-columns.ecg-cols.col9",
				"reports.select-columns.ecg-cols.col10",
				"reports.select-columns.ecg-cols.col11",
				"reports.select-columns.ecg-cols.col12",
				"reports.select-columns.ecg-cols.col13",
				"reports.select-columns.ecg-cols.col14",
				"reports.select-columns.ecg-cols.col15",
				"reports.select-columns.ecg-cols.col16",
				"reports.select-columns.ecg-cols.col17",
				"reports.select-columns.interval-cols.col1",
				"reports.select-columns.interval-cols.col2",
				"reports.select-columns.interval-cols.col3",
				"reports.select-columns.interval-cols.col4",
				"reports.select-columns.interval-cols.col5",
				"reports.select-columns.interval-cols.col6",
				"reports.select-columns.interval-cols.col7",
				"reports.select-columns.interval-cols.col8",
				"reports.select-columns.interval-cols.col9",
				"reports.select-columns.interval-cols.col10",
				"reports.select-columns.interval-cols.col11",
				"reports.select-columns.interval-cols.col12",
				"reports.select-columns.interval-cols.col13",
				"reports.select-columns.interval-cols.col14",
				"reports.select-columns.interval-cols.col15",
				"reports.select-columns.interval-cols.col16",
				"reports.select-columns.interval-cols.col17",
				"reports.select-columns.interval-cols.col18",
				"reports.select-columns.interval-cols.col19",
				"reports.select-columns.interval-cols.col20",
				"reports.select-columns.interval-cols.col21",
				"reports.select-columns.interval-cols.col22",
				"reports.select-columns.interval-cols.col23",
				"reports.select-columns.interval-cols.col24",
				"reports.select-columns.finding-cols.col1",
				"reports.select-columns.finding-cols.col2",
				"reports.select-columns.finding-cols.col3",
				"reports.select-columns.finding-cols.col4",
				"reports.select-columns.finding-cols.col5",
				"reports.select-columns.finding-cols.col6",
				"reports.select-columns.finding-cols.col7",
				"reports.select-columns.finding-cols.col8",
				"reports.select-columns.finding-cols.col9",
				"reports.select-columns.finding-cols.col10",
				"reports.select-columns.finding-cols.col11",
				"reports.select-columns.finding-cols.col12",
				"reports.select-columns.finding-cols.col13"
			])
			.then(function (translations) {
				vm.title = translations["reports.ecg-export.title"];
				vm.exportTitle = translations["reports.select-columns.export"];

				vm.leadCol1 = translations["reports.select-columns.study-cols.col1"];
				vm.leadCol2 = translations["reports.select-columns.study-cols.col2"];
				vm.leadCol3 = translations["reports.select-columns.study-cols.col3"];
				vm.leadCol4 = translations["reports.select-columns.study-cols.col4"];
				vm.leadCol5 = translations["reports.select-columns.study-cols.col5"];
				vm.leadCol6 = translations["reports.select-columns.study-cols.col6"];
				vm.leadCol7 = translations["reports.select-columns.study-cols.col7"];
				vm.leadCol8 = translations["reports.select-columns.study-cols.col8"];
				vm.leadCol9 = translations["reports.select-columns.study-cols.col9"];
				vm.leadCol10 = translations["reports.select-columns.study-cols.col10"];
				vm.leadCol11 = translations["reports.select-columns.study-cols.col11"];
				vm.leadCol12 = translations["reports.select-columns.study-cols.col12"];
				vm.leadCol13 = translations["reports.select-columns.study-cols.col13"];
				vm.leadCol14 = translations["reports.select-columns.study-cols.col14"];
				vm.leadCol15 = translations["reports.select-columns.study-cols.col15"];
				vm.leadCol16 = translations["reports.select-columns.study-cols.col16"];
				vm.leadCol17 = translations["reports.select-columns.study-cols.col17"];
				vm.leadColSubjectKey = translations["app.common.subjectKey"];

				vm.ecgCol1 = translations["reports.select-columns.ecg-cols.col1"];
				vm.ecgCol2 = translations["reports.select-columns.ecg-cols.col2"];
				vm.ecgCol3 = translations["reports.select-columns.ecg-cols.col3"];
				vm.ecgCol4 = translations["reports.select-columns.ecg-cols.col4"];
				vm.ecgCol5 = translations["reports.select-columns.ecg-cols.col5"];
				vm.ecgCol6 = translations["reports.select-columns.ecg-cols.col6"];
				vm.ecgCol7 = translations["reports.select-columns.ecg-cols.col7"];
				vm.ecgCol8 = translations["reports.select-columns.ecg-cols.col8"];
				vm.ecgCol9 = translations["reports.select-columns.ecg-cols.col9"];
				vm.ecgCol10 = translations["reports.select-columns.ecg-cols.col10"];
				vm.ecgCol11 = translations["reports.select-columns.ecg-cols.col11"];
				vm.ecgCol12 = translations["reports.select-columns.ecg-cols.col12"];
				vm.ecgCol13 = translations["reports.select-columns.ecg-cols.col13"];
				vm.ecgCol14 = translations["reports.select-columns.ecg-cols.col14"];
				vm.ecgCol15 = translations["reports.select-columns.ecg-cols.col15"];
				vm.ecgCol16 = translations["reports.select-columns.ecg-cols.col16"];
				vm.ecgCol17 = translations["reports.select-columns.ecg-cols.col17"];

				vm.intervalCol1 = translations["reports.select-columns.interval-cols.col1"];
				vm.intervalCol2 = translations["reports.select-columns.interval-cols.col2"];
				vm.intervalCol3 = translations["reports.select-columns.interval-cols.col3"];
				vm.intervalCol4 = translations["reports.select-columns.interval-cols.col4"];
				vm.intervalCol5 = translations["reports.select-columns.interval-cols.col5"];
				vm.intervalCol6 = translations["reports.select-columns.interval-cols.col6"];
				vm.intervalCol7 = translations["reports.select-columns.interval-cols.col7"];
				vm.intervalCol8 = translations["reports.select-columns.interval-cols.col8"];
				vm.intervalCol9 = translations["reports.select-columns.interval-cols.col9"];
				vm.intervalCol10 = translations["reports.select-columns.interval-cols.col10"];
				vm.intervalCol11 = translations["reports.select-columns.interval-cols.col11"];
				vm.intervalCol12 = translations["reports.select-columns.interval-cols.col12"];
				vm.intervalCol13 = translations["reports.select-columns.interval-cols.col13"];
				vm.intervalCol14 = translations["reports.select-columns.interval-cols.col14"];
				vm.intervalCol15 = translations["reports.select-columns.interval-cols.col15"];
				vm.intervalCol16 = translations["reports.select-columns.interval-cols.col16"];
				vm.intervalCol17 = translations["reports.select-columns.interval-cols.col17"];
				vm.intervalCol18 = translations["reports.select-columns.interval-cols.col18"];
				vm.intervalCol19 = translations["reports.select-columns.interval-cols.col19"];
				vm.intervalCol20 = translations["reports.select-columns.interval-cols.col20"];
				vm.intervalCol21 = translations["reports.select-columns.interval-cols.col21"];
				vm.intervalCol22 = translations["reports.select-columns.interval-cols.col22"];
				vm.intervalCol23 = translations["reports.select-columns.interval-cols.col23"];
				vm.intervalCol24 = translations["reports.select-columns.interval-cols.col24"];

				vm.findingCol1 = translations["reports.select-columns.finding-cols.col1"];
				vm.findingCol2 = translations["reports.select-columns.finding-cols.col2"];
				vm.findingCol3 = translations["reports.select-columns.finding-cols.col3"];
				vm.findingCol4 = translations["reports.select-columns.finding-cols.col4"];
				vm.findingCol5 = translations["reports.select-columns.finding-cols.col5"];
				vm.findingCol6 = translations["reports.select-columns.finding-cols.col6"];
				vm.findingCol7 = translations["reports.select-columns.finding-cols.col7"];
				vm.findingCol8 = translations["reports.select-columns.finding-cols.col8"];
				vm.findingCol9 = translations["reports.select-columns.finding-cols.col9"];
				vm.findingCol10 = translations["reports.select-columns.finding-cols.col10"];
				vm.findingCol11 = translations["reports.select-columns.finding-cols.col11"];
				vm.findingCol12 = translations["reports.select-columns.finding-cols.col12"];
				vm.findingCol13 = translations["reports.select-columns.finding-cols.col13"];

				configurationService.getSubjectDemographicSettings()
					.then(function(response){
						if (response.data.fieldLabels[appConfig.demographics.initials]) {
							vm.leadCol9 = response.data.fieldLabels[appConfig.demographics.initials];
						}
						if (response.data.fieldLabels[appConfig.demographics.dob]) {
							vm.leadCol10 = response.data.fieldLabels[appConfig.demographics.dob];
						}
						if (response.data.fieldLabels[appConfig.demographics.subjectId1]) {
							vm.leadCol7 = response.data.fieldLabels[appConfig.demographics.subjectId1];
						}
						if (response.data.fieldLabels[appConfig.demographics.ethnicity]) {
							vm.leadCol12 = response.data.fieldLabels[appConfig.demographics.ethnicity];
						}
						if (response.data.fieldLabels[appConfig.demographics.gender]) {
							vm.leadCol11 = response.data.fieldLabels[appConfig.demographics.gender];
						}

						vm.leadColumns = [
							{name: vm.leadCol1, value: "StudyName", selected: true},
							{name: vm.leadCol2, value: "SponsorName", selected: true},
							{name: vm.leadCol3, value: "SponsorStudyName", selected: true},
							{name: vm.leadCol4, value: "CountryName", selected: true},
							{name: vm.leadCol5, value: "SiteName", selected: true},
							{name: vm.leadCol6, value: "SponsorSiteId", selected: true},
							{name: vm.leadColSubjectKey, value: "SubjectKey", selected: true},
							{name: vm.leadCol7, value: "PatientId1", selected: !response.data.isSubjectId1Hidden,
							 hidden: response.data.isSubjectId1Hidden},
							{name: vm.leadCol8, value: "PatientId2", selected: true},
							{name: vm.leadCol9, value: "Initials", selected: !response.data.isInitialsHidden,
							 hidden: response.data.isInitialsHidden},
							{name: vm.leadCol10, value: "DateOfBirthDisplay", selected: !response.data.isDateOfBirthHidden,
							 hidden: response.data.isDateOfBirthHidden},
							{name: vm.leadCol11, value: "Gender", selected: !response.data.isGenderHidden,
							 hidden: response.data.isGenderHidden},
							{name: vm.leadCol12, value: "Ethnicity", selected: !response.data.isEthnicityHidden,
							 hidden: response.data.isEthnicityHidden},
							{name: vm.leadCol13, value: "PeriodName", selected: true},
							{name: vm.leadCol14, value: "VisitName", selected: true},
							{name: vm.leadCol15, value: "TimepointName", selected: true},
							{name: vm.leadCol16, value: "Repeat", selected: true},
							{name: vm.leadCol17, value: "BaselineVisit", selected: true}
						];

						vm.ecgColumns = [
							{name: vm.ecgCol1, value: "TransmittalNumber", selected: true},
							{name: vm.ecgCol2, value: "CollectionDateTime", selected: true},
							{name: vm.ecgCol3, value: "CompleteDateTime", selected: true},
							{name: vm.ecgCol4, value: "Lead", selected: false},
							{name: vm.ecgCol5, value: "ReviewerName", selected: true},
							{name: vm.ecgCol6, value: "ReviewComments", selected: true},
							{name: vm.ecgCol7, value: "EcgRemarks", selected: true},
							{name: vm.ecgCol8, value: "HrAlert", selected: true},
							{name: vm.ecgCol9, value: "RrAlert", selected: true},
							{name: vm.ecgCol10, value: "PrAlert", selected: true},
							{name: vm.ecgCol11, value: "QrsAlert", selected: true},
							{name: vm.ecgCol12, value: "QtAlert", selected: true},
							{name: vm.ecgCol13, value: "QtcbAlert", selected: true},
							{name: vm.ecgCol14, value: "QtcfAlert", selected: true},
							{name: vm.ecgCol15, value: "QtclAlert", selected: true},
							{name: vm.ecgCol16, value: "ReviewAlert", selected: true},
							{name: vm.ecgCol17, value: "ClinAlert", selected: true}
						];

						vm.intervalColumns = [
							{name: vm.intervalCol1, value: "Hr", selected: true},
							{name: vm.intervalCol2, value: "HrBase", selected: true},
							{name: vm.intervalCol3, value: "HrChange", selected: true},
							{name: vm.intervalCol4, value: "Rr", selected: true},
							{name: vm.intervalCol5, value: "RrBase", selected: true},
							{name: vm.intervalCol6, value: "RrChange", selected: true},
							{name: vm.intervalCol7, value: "Pr", selected: true},
							{name: vm.intervalCol8, value: "PrBase", selected: true},
							{name: vm.intervalCol9, value: "PrChange", selected: true},
							{name: vm.intervalCol10, value: "Qrs", selected: true},
							{name: vm.intervalCol11, value: "QrsBase", selected: true},
							{name: vm.intervalCol12, value: "QrsChange", selected: true},
							{name: vm.intervalCol13, value: "Qt", selected: true},
							{name: vm.intervalCol14, value: "QtBase", selected: true},
							{name: vm.intervalCol15, value: "QtChange", selected: true},
							{name: vm.intervalCol16, value: "Qtcb", selected: true},
							{name: vm.intervalCol17, value: "QtcbBase", selected: true},
							{name: vm.intervalCol18, value: "QtcbChange", selected: true},
							{name: vm.intervalCol19, value: "Qtcf", selected: true},
							{name: vm.intervalCol20, value: "QtcfBase", selected: true},
							{name: vm.intervalCol21, value: "QtcfChange", selected: true},
							{name: vm.intervalCol22, value: "Qtcl", selected: true},
							{name: vm.intervalCol23, value: "QtclBase", selected: true},
							{name: vm.intervalCol24, value: "QtclChange", selected: true}
						];

						vm.findingsColumns = [
							{name: vm.findingCol1, value: "Rhythm", selected: true},
							{name: vm.findingCol2, value: "Conduction", selected: true},
							{name: vm.findingCol3, value: "Ectopy", selected: true},
							{name: vm.findingCol4, value: "Morphology", selected: true},
							{name: vm.findingCol5, value: "MyocardialInfarct", selected: true},
							{name: vm.findingCol6, value: "StSegment", selected: true},
							{name: vm.findingCol7, value: "TWaves", selected: true},
							{name: vm.findingCol8, value: "UWaves", selected: true},
							{name: vm.findingCol13, value: "Axis", selected: true},
							{name: vm.findingCol9, value: "OverallInterpretation", selected: true},
							{name: vm.findingCol10, value: "Comparison", selected: true},
							{name: vm.findingCol11, value: "ClinicalRelevance", selected: true},
							{name: vm.findingCol12, value: "MachineAlgorithm", selected: true}
						];

						vm.selectActions = [
							{action: exportData , displayName: vm.exportTitle, isDisabled: vm.dataLoading, isPrimary: true}
						];
						vm.exportAction = vm.selectActions[0];
					});
			});
		}

		activate();

		vm.changeColumn = function(columns, row) {
			if (row && !row.selected && row.value === 'Lead') {
				vm.isLead = false;
			}
			return _.every(_.filter(columns, (el) => { return !el.hidden; }), { 'selected': true });
		};

		vm.openExport = function(options) {
			vm.showExport = true;
			vm.options = options;
			vm.dataLoading = false;
			vm.errorMessage = '';
			vm.restoreDefault();
		};

		vm.resetSelection = function(columns, selected) {
			angular.forEach(columns, function(value) {
				value.selected = selected;
			});
			vm.handleButtonState();
		};

		vm.selectLead = function() {
			var leadColumns = $filter('filter')(vm.ecgColumns, {value: 'Lead'}, false);
			leadColumns[0].selected = vm.isLead;
			vm.ecgAll = vm.changeColumn(vm.ecgColumns, leadColumns[0]);
		};

		vm.restoreDefault = function() {
			vm.studyAll = true;
			vm.ecgAll = false;
			vm.intervalAll = true;
			vm.findingsAll = true;
			vm.isLead = false;
			vm.format = "csv";
			vm.exportType = "currentfilter";

			selectAllIn(vm.leadColumns);
			selectAllIn(vm.ecgColumns);
			selectAllIn(vm.intervalColumns);
			selectAllIn(vm.findingsColumns);
			vm.exportAction.isDisabled = false;

			var selectedColumns = $filter('filter')(vm.ecgColumns, {value: 'Lead'}, false);
			selectedColumns[0].selected = false;

		};

		vm.handleButtonState = function () {
			var selectedColumns = $filter('filter')(vm.leadColumns, { selected: true }, false);
			selectedColumns = selectedColumns.concat($filter('filter')(vm.ecgColumns, { selected: true }, false));
			selectedColumns = selectedColumns.concat($filter('filter')(vm.intervalColumns, { selected: true }, false));
			selectedColumns = selectedColumns.concat($filter('filter')(vm.findingsColumns, { selected: true }, false));

			var selected = selectedColumns.some(function (value) {
				return !!value.value;
			});

			vm.exportAction.isDisabled = !selected;
		}

		function exportData() {
			vm.dataLoading = true;
			loadingIndicatorService.show();
			vm.errorMessage = "";
			var columns = "";
			var selectedColumns = $filter('filter')(vm.leadColumns, {selected: true}, false);
			selectedColumns = selectedColumns.concat($filter('filter')(vm.ecgColumns, {selected: true}, false));
			selectedColumns = selectedColumns.concat($filter('filter')(vm.intervalColumns, {selected: true}, false));
			selectedColumns = selectedColumns.concat($filter('filter')(vm.findingsColumns, {selected: true}, false));
			angular.forEach(selectedColumns, function(value){
				columns += value.value + ",";
			});

			//get the filter part of the OdataQuery of the grid
			var filterOptions;
			if (vm.exportType === 'currentfilter' && vm.options) {
				var uri = vm.options.toUri();
				var startIndex = uri.indexOf("$filter");
				var endIndex = uri.indexOf("&", startIndex);
				filterOptions = uri.substr(startIndex, (endIndex - startIndex));
			}

			if (vm.isLead) {
				reportsService.exportByLead(columns, vm.format, filterOptions)
				.then(function(response){
					documentService.downloadReport(response.data, $scope, appConfig.downloadDocumentType.ecgExport);
					vm.dataLoading = false;
					loadingIndicatorService.hide();
				}, function(response) {
					$log.error("Eror in exporting data: " + response.data);
					vm.errorMessage = "Error in downloading ECG Export data";
					vm.dataLoading = false;
					loadingIndicatorService.hide();
				});
			}
			else {
				reportsService.exportByTransmittal(columns, vm.format, filterOptions)
				.then(function(response){
					documentService.downloadReport(response.data, $scope, appConfig.downloadDocumentType.ecgExport);
					vm.dataLoading = false;
					loadingIndicatorService.hide();
				}, function(response) {
					$log.error("Eror in exporting data: " + response.data);
					vm.errorMessage = "Error in downloading ECG Export data";
					vm.dataLoading = false;
					loadingIndicatorService.hide();
				});
			}


		}

		function selectAllIn(array) {
			angular.forEach(array, function(value){
				value.selected = true;
			});
		}

	}
})();
