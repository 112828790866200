/*
 * iSpiroAuditReportPopupModule
 * */

import iSpiroAuditReportPopupComponent from './iSpiroAuditReportPopup.component';

const iSpiroAuditReportPopupModule = angular.module('app.components.iSpiroAuditReportPopup', [])
	.component('iSpiroAuditReportPopup', iSpiroAuditReportPopupComponent)
	.name;

export default iSpiroAuditReportPopupModule;
