/*
 * exportButtonController
 * */

class ExportButtonController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
		this._removeMe = 'exportButton';
	}

	$onInit() {
		this.$log = this.$log.getInstance('ExportButtonController');
		this.$log.debug('loaded');
	}

	export(format) {
		return this.onSelect({ format });
	}
}

export default ExportButtonController;
