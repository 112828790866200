/**
 * Created by AMorbia on 12/22/2015.
 */
(function() {

	'use strict';
	angular
		.module("portalApp")
		.service("studyPermissionsTrans", StudyPermissionsTrans);

	/**
	 * @ngInject
	 */
	function StudyPermissionsTrans($log, $q, appConfig, appI18n) {
		$log = $log.getInstance("StudyPermissionsTrans");
		$log.debug("StudyPermissionsTrans loaded");

		var listViewLocalized = {
			lang: 'unknown',
			gridColumns: {},
			list: {},
			buttons:{},
			common:{}
		};

		var wizardLocalized = {
			lang: 'unknown',
			buttons: {},
			messages: {},
			wizardTitles: {},
			contact: {},
			options:{},
			common:{}
		};

		var gridColumns = [
			"study-permissions.gridColumns.userName",
			"study-permissions.gridColumns.userEmail",
			"study-permissions.gridColumns.accountCreated",
			"study-permissions.gridColumns.roles",
			"study-permissions.gridColumns.role",
			"study-permissions.gridColumns.lastLogon",
			"study-permissions.gridColumns.status",
			"study-permissions.gridColumns.country",
			"study-permissions.gridColumns.sitePI",
			"study-permissions.gridColumns.constraint",
			"study-permissions.gridColumns.requestDate",
			"study-permissions.gridColumns.requester",
			"study-permissions.gridColumns.requesterEmail",
			"study-permissions.gridColumns.portalAccount"
		];

		var list = [
			"study-permissions.list.studyUsers",
			"study-permissions.list.countryUsers",
			"study-permissions.list.siteUsers",
			"study-permissions.list.siteAdministrators"
		];

		var buttons = [
			"study-permissions.buttons.createUser",
			"study-permissions.buttons.createSponsorUser",
			"study-permissions.buttons.removeUser",
			"study-permissions.buttons.requestRemoval",
			"study-permissions.buttons.editUser",
			"study-permissions.buttons.performRemoval",
			"study-permissions.buttons.rejectRemoval",

			"app.buttons.cancel",
			"app.buttons.back",
			"app.buttons.next",
			"app.buttons.submit",
			"app.buttons.sign-submit",
		];

		var messages = [
			"study-permissions.messages.noUserRoleSelected",
			"study-permissions.messages.selectSite",
			"study-permissions.messages.contactNumberField",
			"study-permissions.messages.existingShippingContact",
			"study-permissions.messages.hasSiteSpecificPermissions",
			"study-permissions.messages.contactNumberFieldMinLength",
			"study-permissions.messages.hasSiteSpecificRoles",
			"study-permissions.messages.noSiteRoles",
			"study-permissions.messages.noRolesAvailableMessage",
			"app.messages.requiredField",
			"app.messages.usaZipCodeField",
			"app.messages.restrictedInputPatternField",
			"study-permissions.messages.emailMatch",
			"study-permissions.messages.reportingEmailMatch",
			"study-permissions.messages.reportingEmailSameAsEmail",
			"study-permissions.messages.userAlreadyExists",
			"study-permissions.messages.sponsorUserAlreadyExists",
			"study-permissions.messages.sponsorConfirmationMessage",
			"study-permissions.messages.sponsorDisclaimerMessage"
		];

		var wizardTitles = [
			"study-permissions.wizardTitles.createUserWizard",
			"study-permissions.wizardTitles.createSponsorUserWizard",
			"study-permissions.wizardTitles.userInfo",
			"study-permissions.wizardTitles.userAddressRoles",
			"study-permissions.wizardTitles.confirm",
			"study-permissions.wizardTitles.userAddress",
			"study-permissions.wizardTitles.siteRoleAssignment",
			"study-permissions.wizardTitles.signature",
			"study-permissions.wizardTitles.sponsorUserInfo",
			"study-permissions.wizardTitles.sponsorUserConfirmation"
		];

		var contact = [
			"study-permissions.contact.contact",
			"study-permissions.contact.piInfo",
			"study-permissions.contact.piAddress",
			"study-permissions.contact.needPortalAccess",
			"study-permissions.contact.hasPortalAccess",
			"study-permissions.contact.email",
			"study-permissions.contact.repeatEmail",
			"study-permissions.contact.title",
			"study-permissions.contact.firstName",
			"study-permissions.contact.lastName",
			"study-permissions.contact.communicationChannel",
			"study-permissions.contact.phone",
			"study-permissions.contact.mobile",
			"study-permissions.contact.fax",
			"study-permissions.contact.hasReportingEmail",
			"study-permissions.contact.reportingEmail",
			"study-permissions.contact.repeatReportingEmail",
			"study-permissions.contact.portalAccessGranted",
			"study-permissions.contact.address1",
			"study-permissions.contact.address2",
			"study-permissions.contact.address3",
			"study-permissions.contact.country",
			"study-permissions.contact.city",
			"study-permissions.contact.state",
			"study-permissions.contact.postalCode",
			"study-permissions.contact.province",
			"study-permissions.contact.siteId",
			"study-permissions.contact.copyMyContactTooltip",
			"study-permissions.contact.siteAddress",
			"study-permissions.contact.copySiteAddress",
			"study-permissions.contact.copySiteAddressTooltip",
			"study-permissions.contact.roles",
			"study-permissions.contact.address",
			"study-permissions.contact.communication",
			"study-permissions.contact.accessTypes",
			"study-permissions.contact.sponsorRoles",
			"study-permissions.contact.receiverRoles",
			"study-permissions.contact.confirmation",
			"study-permissions.contact.reportingNeeds"
		];

		var options = [
			"study-permissions.options.email",
			"study-permissions.options.fax",
			"study-permissions.options.yes",
			"study-permissions.options.no"
		];

		var common = [
			"app.common.loading",
			"app.common.saving",
			"app.common.select"
		];


		return {
			getWizardLocalized: getWizardLocalized,
			getListViewLocalized: getListViewLocalized
		};

		function getListViewLocalized() {
			var deferred = $q.defer();
			if (listViewLocalized.lang && listViewLocalized.lang === appI18n.getLanguage()) {
				deferred.resolve(listViewLocalized);
			}
			else {
				appI18n.translate(
					gridColumns
						.concat(list)
						.concat(buttons)
						.concat(common)
				).then(function (translations) {
					setLocalizedProperty(translations, listViewLocalized.gridColumns, gridColumns);
					setLocalizedProperty(translations, listViewLocalized.list, list);
					setLocalizedProperty(translations, listViewLocalized.buttons, buttons);
					setLocalizedProperty(translations, listViewLocalized.common, common);
					listViewLocalized.lang = appI18n.getLanguage();
					deferred.resolve(listViewLocalized);
				});
			}
			return deferred.promise;
		}

		/* TODO: remove this */
		function getWizardLocalized() {
			var deferred = $q.defer();
			if (listViewLocalized.lang && listViewLocalized.lang === appI18n.getLanguage()) {
				deferred.resolve(wizardLocalized);
			}
			else {
				appI18n.translate(
					wizardTitles
						.concat(buttons)
						.concat(messages)
						.concat(contact)
						.concat(options)
						.concat(common)
					)
					.then(function (translations) {
						setLocalizedProperty(translations, wizardLocalized.buttons, buttons);
						setLocalizedProperty(translations, wizardLocalized.messages, messages);
						setLocalizedProperty(translations, wizardLocalized.wizardTitles, wizardTitles);
						setLocalizedProperty(translations, wizardLocalized.contact, contact);
						setLocalizedProperty(translations, wizardLocalized.options, options);
						setLocalizedProperty(translations, wizardLocalized.common, common);

						wizardLocalized.messages.restrictedInputPatternFieldEscaped = wizardLocalized.messages.restrictedInputPatternField.replace("'","\\'");

						wizardLocalized.lang = appI18n.getLanguage();
						deferred.resolve(wizardLocalized);
					});
			}
			return deferred.promise;
		}

		function setLocalizedProperty(translations, dest, array) {
			array.forEach(function (translationId) {
				var name = translationId.split('.').pop();
				dest[name] = translations[translationId];
			});
		}
	}
})();
