class ControlNotification {
	activate() {
		const msgs = this.messages;

		for (const key in msgs) {
			if (msgs.hasOwnProperty(key) && msgs[key]) {
				return true;
			}
		}
		return false;
	}
}

export default ControlNotification;
