class ActivatePopupController {
	constructor($log, $stateParams, eproService, appSession,
				appConfig, appI18n, notificationService, loadingIndicatorService) {
		'ngInject';
		this.$log = $log;
		this.$stateParams = $stateParams;
		this.eproService = eproService;
		this.appSession = appSession;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.notificationService = notificationService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.isVisible = false;
	}

	$onInit() {
		this.$log = this.$log.getInstance('ActivatePopupController');
		this.$log.debug('loaded');

		this.$API = {
			open: (siteId, eproSubjectId, isDeactivate) => {
				this.siteId = siteId;
				this.isDeactivate = isDeactivate;
				this.eproSubjectId = eproSubjectId;
				this.isVisible = true;
				if (isDeactivate) {
					this.appI18n.translate('clinicaldata.subject.status.confirmDeactivateTitle').then((translation) => {
						this.title = translation;
					});
				} else {
					this.appI18n.translate('clinicaldata.subject.status.confirmActivateTitle').then((translation) => {
						this.title = translation;
					});
				}
			},
			close: () => {
				this.isVisible = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({ $API: this.$API });
		}
	}

	close() {
		this.isVisible = false;
	}

	showSaveDelayWarningStatusChange() {
		this.appI18n.translate('clinicaldata.subject.status.saveDelayStatusChange').then((translation) => {
			const msg = translation;

			this.notificationService.showSuccess(msg);
		});
	}

	doActivate() {
		this.loadingIndicatorService.show();
		let backendCall;

		if (this.isDeactivate) {
			backendCall = this.eproService.deactivateSubject;
		} else {
			backendCall = this.eproService.activateSubject;
		}

		backendCall(this.siteId, this.eproSubjectId).then(
			() => {
				this.close();
				this.loadingIndicatorService.hide();
				this.showSaveDelayWarningStatusChange();
			},
			(response) => {
				this.setGenericSaveError(response);
			}
		);
	}

	setGenericSaveError(response) {
		const msg = (response.data && response.data.translationKey) ? response.data.translationKey
			  : 'clinicaldata.subject.activate.errors.genericSaveErrorActivate';

		this.setError(msg);
	}

	setError(msg) {
		this.loadingIndicatorService.hide();
		this.hasErrors = true;
		this.appI18n.translate(msg).then((translation) => {
			this.errorMsg = translation;
		});
	}
}

export default ActivatePopupController;
