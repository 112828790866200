import loadingIndicatorComponent from './loadingIndicator.component';
import loadingIndicatorIconModule from './icon/index';
import loadingIndicatorService from './loadingIndicator.service';

const loadingIndicatorModule = angular.module('app.common.loadingIndicator', [loadingIndicatorIconModule])
	.factory('loadingIndicatorService', loadingIndicatorService)
	.component('loadingIndicator', loadingIndicatorComponent)
	.name;

export default loadingIndicatorModule;
