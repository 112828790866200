/*
 * iconsRowDirective
 * */

import templateUrl from './iconsRow.html';
import controller from './iconsRow.controller';
import './iconsRow.scss';

const iconsRowDirective = () => {
	'ngInject';
	return {
		scope: {
			data: '@',
			type: '@',
			heading: '@?',
			headingSize: '<'
		},
		controller,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		link: {
			post: (/*scope, element, attr, ctrl*/) => {}
		},
		replace: true
	};
};

export default iconsRowDirective;
