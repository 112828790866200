/*
 * openQueriesTableController
 * */

class OpenQueriesTableController {
	constructor($log, $state, appConfig) {
		'ngInject';
		this.$log = $log;
		this.$state = $state;
		this.appConfig = appConfig;
	}

	$onInit() {
		this.$log = this.$log.getInstance('OpenQueriesTableController');
		this.$log.debug('loaded');
	}

	openTicket(q) {
		this.$state.go(this.appConfig.routes.tickets, {
			'filter': `contains(SponsorSiteId,'${q.sponsorSiteId}') and QueryPortalStatus eq 'Query sent to site'`
		});
	}
}

export default OpenQueriesTableController;
