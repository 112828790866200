﻿(function () {

	'use strict';

	angular
	.module('portalApp')
	.service('contactService', ContactService);

	/**
	 * @ngInject
	 */
	function ContactService($log) {

		$log = $log.getInstance('ContactService', 'color:blue');
		$log.debug('loaded');

		var sharedData = {};
		var service = {
			getSharedData:getSharedData,
			clearSharedData: clearSharedData
		};

		return service;

		function getSharedData() {
			return sharedData;
		}

		function clearSharedData () {
			sharedData = {};
		}
	}

})();
