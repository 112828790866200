class SimpleDatePickerController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('SimpleDatePickerController');
		this.$log.debug('loaded');
		this.cssClass = '';
		this.datePattern = 'DD-MMM-YYYY';
	}

	$onChanges(changes) {
		this.model = angular.copy(changes.model.currentValue);
	}

	updateData() {
		this.updateUI();
		this.onUpdate({
			$event: {
				model: this.model
			}
		});
	}

	updateUI() {
		if (!this.model) {
			return;
		}

		const valid = this.isValidDate();

		this.cssClass = !valid ? '-es-invalid' : '';
	}

	isValidDate() {
		return !!this.model && moment(this.model, this.datePattern, true).isValid();
	}
}

export default SimpleDatePickerController;
