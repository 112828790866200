(function () {
	'use strict';

	angular.module('portalApp')
		.directive('ertWaypointHandler', WaypointHandler);

	/**
	 * @ngInject
	 */
	function WaypointHandler($parse, $window, $log) {
		return {
			link: link,
			restrict: 'A'
		};
		function link($scope, $element, $attrs) {
			var Waypoint = $window.Waypoint,
				waypoint;

			activate();

			function activate(){
				waypoint = new Waypoint(getOptions());
			}
			function getHandler(){
				var handler = $parse($attrs.ertWaypointHandler)($scope);
				return function (direction) {
					safeApply(function () {
						handler(direction, waypoint);
					});
				};
			}
			function getOptions() {
				var contextSelector = $parse($attrs.ertWaypointContext)($scope),
					context = (contextSelector && $element.closest(contextSelector)) || $window,
					offset = $parse($attrs.ertWaypointOffset)($scope) || 0;
				return {
					element: $element,
					handler: getHandler(),
					context: context,
					offset: offset
				}
			}
			function safeApply(fn) {
				var phase = $scope.$root.$$phase;
				if (phase == '$apply' || phase == '$digest') {
					if (fn && (typeof(fn) === 'function')) {
						fn();
					}
				} else {
					$scope.$apply(fn);
				}
			}
		}
	}
})();
