/*
 * dispenseKardiaPopupComponent
 * */

import templateUrl from './dispenseKardiaPopup.html';
import controller from './dispenseKardiaPopup.controller';
import './dispenseKardiaPopup.scss';

const dispenseKardiaPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default dispenseKardiaPopupComponent;
