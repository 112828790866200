/*
 * selectableRowController
 * */

import baseRowController from '../../../../../common/table/baseRow/baseRow.controller';

class AnnouncementsRowController extends baseRowController {
	constructor($log) {
		'ngInject';
		super();
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('AnnouncementsRowController');
		this.$log.debug('loaded');
		this.registerRow(this.row);
	}
}

export default AnnouncementsRowController;
