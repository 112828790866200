import templateUrl from './ertToolbar.html';
import './ertToolbar.scss';

const ertToolbar = {
	bindings: {},
	transclude: true,
	templateUrl
};

export default ertToolbar;

