/*
 * visitCalendarPopupController
 * */
class VisitCalendarPopupController {
	constructor($log, $state, configurationService, oDataHelper, $filter, appConfig, appI18n, $window,
				clinicalDataService, loadingIndicatorService, $q) {
		'ngInject';
		this.$log = $log;
		this.$state = $state;
		this.configurationService = configurationService;
		this.$filter = $filter;
		this.oDataHelper = oDataHelper;
		this.appConfig = appConfig;
		this.appI18n = appI18n;
		this.$window = $window;
		this.clinicalDataService = clinicalDataService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.$q = $q;
		this.open = false;

		this.currentDate = Date.now();
		this.calendarItems = [];
		this.currentPage = 1;
		this.pageSize = 30;

		this.customFilterVisible = false;
		this.isPhoneCalendar = false;
		this.isVisitCalendar = false;
		this.currentCalendarMode = true;
	}
	// TODO: remove logic related to popup breadcrumbs, once the desicion confirmed.
	$onInit() {
		this.$log = this.$log.getInstance('VisitCalendarPopupController');
		this.$log.debug('loaded');
		this.setApi();
		this.options = this.getGridConfig();
		this.load = true;

		this.appI18n.translate([
			'clinicaldata.calendar.phoneTitle',
			'clinicaldata.calendar.siteVisitTitle'
		]).then((translations) => {
			this.phoneTitleTxt = translations['clinicaldata.calendar.phoneTitle'];
			this.siteTitleTxt = translations['clinicaldata.calendar.siteVisitTitle'];

		});
	}

	getGridConfig() {
		const self = this;

		return {
			removeRefreshControls: true,
			removeExportControls: true,
			stateParamPrefix: 'vcp',
			columnDefs: [
				{
					fieldName: 'visitDate',
					translationKey: 'app.common.date',
					type: 'date',
					removeSortControls: true,
					formatter: 'dateFormat',
					additionalFilters: [{
						translationKey: 'clinicaldata.calendar.filterCurrentMonth',
						isDefaultFilter: true,
						from: moment().startOf('month').format('DD-MMM-YYYY'),
						to: moment().endOf('month').format('DD-MMM-YYYY')
					}, {
						translationKey: 'clinicaldata.calendar.filterTwoMonths',
						from: moment().startOf('month').subtract(1, 'month').format('DD-MMM-YYYY'),
						to: moment().endOf('month').format('DD-MMM-YYYY')
					}, {
						translationKey: 'clinicaldata.calendar.filterThreeMonths',
						from: moment().startOf('month').subtract(2, 'month').format('DD-MMM-YYYY'),
						to: moment().endOf('month').format('DD-MMM-YYYY')
					}]
				},
				{
					fieldName: 'subjectKey',
					translationKey: 'app.common.subjectKey',
					formatter: 'navigateToSubject',
					removeSortControls: true,
					removeFromFilter: true,
					type: 'string'
				},
				{
					fieldName: 'visitName',
					translationKey: 'app.common.visitName',
					formatter: 'visitCalendarNavigateToVisit',
					removeSortControls: true,
					removeFromFilter: true,
					type: 'string'
				},
				{
					fieldName: 'visitDisplayName',
					translationKey: 'app.common.status',
					formatter: 'visitCalendarVisitType',
					removeSortControls: true,
					removeFromFilter: true,
					type: 'string'
				},
				{
					fieldName: 'dateRangeDisplay',
					translationKey: 'app.common.dateRange',
					type: 'string',
					removeSortControls: true,
					removeFromFilter: true
				}
			],
			sortOrders: [{
				fieldName: 'visitDate'
			}],

			getData: (oDataQuery) => {
				return this.updateCalendarData(oDataQuery).then((resp) => {
					self.loadingIndicatorService.hide();
					self.setVisible = true;
					return resp;
				});
			}
		};
	}

	setApi() {
		const self = this;

		this.$API = {
			open: (visit) => {
				self.loadingIndicatorService.show();
				self.configurationService.getStudySettings().then((data) => {
					if (data.hasEcoaPhoneWebText) {
						self.isPhoneCalendar = true;
						self.calendarTitle = self.phoneTitleTxt;
					} else {
						self.isVisitCalendar = true;
						self.calendarTitle = self.siteTitleTxt;
					}

					self.showCalendar(visit);
					self.open = true;

				}, (/*error*/) => {
					self.loadingIndicatorService.hide();
					self.open = false;
					//TODO: Show notification MPORT-3604
				});
			},
			close: () => {
				self.setVisible = false;
				self.open = false;
			}
		};

		if (this.componentReady) {
			this.componentReady({ $API: this.$API });
		}
	}

	cancelAction() {
		this.$API.close();
	}

	updateCalendarData(oDataQuery) {
		this.isCalendarLoading = true;
		if (this.selectedSubjectId) {
			return this.clinicalDataService.getSubjectCalendar(
				this.selectedCountryId,
				this.selectedSiteId,
				this.selectedSubjectId,
				this.currentCalendarMode,
				'',
				'',
				oDataQuery);
		} else {
			return this.clinicalDataService.getSiteCalendar(
				this.selectedCountryId,
				this.selectedSiteId,
				this.currentCalendarMode,
				'',
				'',
				oDataQuery);
		}
	}

	showCalendar(calObj) {
		this.selectedCountryId = calObj.countryId;
		this.selectedSiteId = calObj.siteId;
		this.selectedSubjectId = calObj.subjectId;
		this.countryName = calObj.countryName;
		this.sponsorSiteId = calObj.siteName;
		this.subjectKey = calObj.subjectKey;
		this.currentCalendarMode = this.isVisitCalendar;
	}
}

export default VisitCalendarPopupController;
