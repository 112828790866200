var _ = require('lodash');

/**
 * @ngInject
 */
module.exports = function ($http, $q, $log, $cacheFactory, cacheManager, appSession, appConfig) {

	$log = $log.getInstance("ConfigurationService", "color:purple");
	$log.debug("loaded");

	var service = {
		getSiteMap: getSiteMap,
		getDashboard: getDashboard,
		getEproSettings: getEproSettings,
		getSiteMapLabel: getSiteMapLabel,
		getClinicalDataColumns: getClinicalDataColumns,
		getStudySettings: getStudySettings,
		getMyDeviceAccountInfo,
		showMyDeviceAccount,
		getUserPermissionOnSite,
		registerMyDeviceAccountHandler,
		assignDevice: assignDevice,
		getUserStudySettings: getUserStudySettings,
		clearUserStudySettings: clearUserStudySettings,
		getSubjectDemographicSettings: getSubjectDemographicSettings,
		checkSession: checkSession,
		invalidateDeferredObjects,
		getDownloadsViewConfig,
		downloadsViewConfigFormatter
	};
	var dashboardDfd = null;
	var studySettingsCache = null;
	var studySettingsDfd = null;
	var userStudySettingsDfd = null;
	var keys = {};
	var vm = this;
	var cacheStudyId = null;
	var cacheUserId = null;

	return service;

	function getSubjectDemographicSettings() {
		var currentStudy = appSession.getStudy();
		var url = appConfig.apiUrls.configuration.subjectDemographicsSettings.supplant(
			{'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId});
		$log.debug('getSubjectDemographicSettings with url: {url}', {url: url});
		return $http.get(url);
	}

	function invalidateStudyCacheForNewStudy() {
		var study = appSession.getStudy();
		var user = appSession.get();

		// sometimes the studyModeId is a number and sometimes a string (0 vs '0')
		// this causes some issues on our pages because during the page load the userStudySettingsDfd object
		// is set to null while other code parts try to access the stored promise.
		if ((study && (study.id !== vm.cacheStudyId || +study.studyModeId !== +vm.cacheStudyModeId)) ||
				(user && user.userId !== vm.cacheUserId)) {
			$log.debug("invalidate study cache");
			vm.studySettingsDfd = null;
			vm.dashboardDfd = null;
			vm.userStudySettingsDfd = null;
			if(study) {
				vm.cacheStudyId = study.id;
				vm.cacheStudyModeId = study.studyModeId;
			}

			vm.cacheUserId = user.userId;
			vm.keys = [];
			vm.myDeviceAccountInfo = null;
			return true;
		}
		return false;
	}

	function invalidateDeferredObjects() {
		$log.debug("invalidate defer cache");
		vm.studySettingsDfd = null;
		vm.dashboardDfd = null;
		vm.userStudySettingsDfd = null;
	}

	function getUserPermissionOnSite(permissionName, siteId) {
		const study = appSession.getStudy();
		const url = appConfig.apiUrls.configuration.userPermissionOnSite.supplant({
			studyId: study.id, studyModeId: study.studyModeId,
			permissionName, siteId
		});

		return $http.get(url);
	}

	function checkSession(permissionName, siteId) {
		const study = appSession.getStudy();
		const url = appConfig.apiUrls.sso.checkSession;

		return $http.get(url);
	}

	function clearUserStudySettings() {
		var study = appSession.getStudy();

		var url = appConfig.apiUrls.configuration.userStudySettings.supplant({
			studyId: study.id, studyModeId: study.studyModeId
		});

		cacheManager.removeCacheForUrl(url, 'study');
	}

	function getUserStudySettings() {
		invalidateStudyCacheForNewStudy();

		if (!vm.userStudySettingsDfd) {
			vm.userStudySettingsDfd = $q.defer();
			var study = appSession.getStudy();

			var url = appConfig.apiUrls.configuration.userStudySettings.supplant({
				studyId: study.id, studyModeId: study.studyModeId
			});

			$http.get(url, { cache: true, params: { cacheContext: 'study' } }).then(
				function (response) {
					vm.userStudySettingsDfd.resolve(response);
				},
				function (error) {
					$log.error('Error gettings user study settings');
					vm.userStudySettingsDfd.reject(error);
					vm.userStudySettingsDfd = null;
				}
			);

		} else {
			return vm.userStudySettingsDfd.promise;
		}
		return vm.userStudySettingsDfd.promise;
	}

	function getClinicalDataColumns(moduleName) {
		invalidateStudyCacheForNewStudy();
		var q = $q.defer();
		var key = 'clinicalDataColumns'.concat(moduleName);
		var keyQs= 'clinicalDataColumnsQ'.concat(moduleName);
		var keyLoading = 'clinicalDataColumnsLoading'.concat(moduleName);
		var study = appSession.getStudy();

		if (!vm.keys[key] && !vm.keys[keyLoading]) {
			vm.keys[keyQs] = q;
			vm.keys[keyLoading] = true;

			var url = appConfig.apiUrls.configuration.clinicalDataColumns.supplant({
				"studyId": study.id, "studyModeId": study.studyModeId, "moduleId": moduleName
			});

			$http.get(url).then(function(response) {
					vm.keys[key] = response.data;
					vm.keys[keyLoading] = false;
					q.resolve(response.data);
			}, function(response) {
					vm.keys[keyLoading] = false;
					q.reject(response.data);
				});

		} else if (vm.keys[keyLoading]) {
			return vm.keys[keyQs].promise;
		} else {
			q.resolve(vm.keys[key]);
		}

		return q.promise;
	}

	function getSiteMapLabel(id) {
		var q = $q.defer();

		getSiteMap().then(function (siteMap) {
			var label;
			for (var i = 0; i < siteMap.length; ++i) {
				if (Number(siteMap[i].id) === id) {
					label = siteMap[i].label;
					break;
				}
			}
			q.resolve(label);
		}, function () {
			q.reject();
		});

		return q.promise;
	}

	function getDashboard() {

		invalidateStudyCacheForNewStudy();
		if (!vm.dashboardDfd) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.configuration.dashboard.supplant({ 'studyId': currentStudy.id,
				'studyModeId' : currentStudy.studyModeId });
			vm.dashboardDfd = $q.defer();

			$http.get(url, { cache: true, params: { cacheContext: 'clinicalData' } }).then(function(response) {
					vm.dashboardDfd.resolve(response.data);
			}, function(response) {
					vm.dashboardDfd.reject(response.data);
					vm.dashboardDfd = null;
				});
		} else {
			return vm.dashboardDfd.promise;
		}
		return vm.dashboardDfd.promise;
	}

	function getEproSettings() {
		var currentStudy = appSession.getStudy();
		var url = appConfig.apiUrls.configuration.epro.supplant(
			{ 'studyId': currentStudy.id});
		return $http.get(url);
	}

	function getStudySettings(invalidateCache) {
		invalidateStudyCacheForNewStudy();

		//is not loading and cache is empty -> load data
		if (!vm.studySettingsDfd) {
			var currentStudy = appSession.getStudy();
			var url = appConfig.apiUrls.configuration.study.supplant({studyId: currentStudy.id, studyModeId: currentStudy.studyModeId});
			vm.studySettingsDfd = $q.defer();
			// in case the data is being fetched and getSiteMap is invoked again,
			// setting cache to null to prevent outdated data
			$http.get(url, { cache: true, params: { cacheContext: 'study' } }).then(function(response) {
					vm.studySettingsDfd.resolve(response.data);
			}, function(response) {
					vm.studySettingsDfd.reject(response.data);
					vm.studySettingsDfd = null;
				});
		}

		return vm.studySettingsDfd.promise;
	}

	function getSiteMap() {
		let siteMapDfd = $q.defer();
		getUserStudySettings().then(
			function (response) {
				siteMapDfd.resolve(response.data.sitemaps);
			},
			function(error) {
				siteMapDfd.reject(error);
			}
		);

		return siteMapDfd.promise;
	}

	function getMyDeviceAccountInfo() {
		var currentStudy = appSession.getStudy();
		var url = appConfig.apiUrls.configuration.myDeviceAccountInfo.supplant({
			studyId: currentStudy.id, studyModeId: currentStudy.studyModeId});
		return $http.get(url);
	}

	function showMyDeviceAccount() {
		if (vm.showMyDeviceAccountHandler) {
			vm.showMyDeviceAccountHandler();
		}
	}

	function registerMyDeviceAccountHandler(handler) {
		vm.showMyDeviceAccountHandler = handler;
	}

	function assignDevice() {
		var currentStudy = appSession.getStudy();
		var url = appConfig.apiUrls.configuration.assignDevice.supplant(
			{'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId});
		return $http.get(url);
	}

	function getDownloadsViewConfig() {
		return this.getUserStudySettings().then(response => {
			const data = response.data;

			return downloadsViewConfigFormatter(data);
		});
	}

	function downloadsViewConfigFormatter(data) {
		return {
			showDMFilesTab: data.downloadDataManagementFiles,
			showUploadFilesBtn: data.uploadDataManagementFiles,
			canDeleteFiles: data.deleteDataManagementFiles
		};
	}
};
