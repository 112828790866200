/*
 * badgeLabelController
 * */

class BadgeLabelController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('BadgeLabelController');
		this.$log.debug('loaded');
		this.labelPosition = this.labelPosition || 'before';
	}
}

export default BadgeLabelController;
