(function() {

	'use strict';
	angular
		.module("portalApp")
		.service("dashboardService", DashboardService);

	/**
	 * @ngInject
	 */

	function DashboardService(appConfig, $http, $log, appSession, $q) {

		$log = $log.getInstance("DashboardService");
		$log.debug("loaded");


		var dashboardService = {
			getStudyMetrics: getStudyMetrics,
			getStudyMetricsDetails: getStudyMetricsDetails,
			getEnrollmentData: getEnrollmentData,
			getDcrMetrics : getDcrMetrics,
			getVisitOverview : getVisitOverview,
			getDcrMetricsStream : getDcrMetricsStream,
			unsetDcrMericsSteram: unsetDcrMericsSteram
		};

		var metricsHandler, announcementsHandler, dcrMetricsStream;

		return dashboardService;


		function getStudyMetrics() {
			var q = $q.defer();
			dashboardService.studyMetricsQ = q;
			var currentStudy = appSession.getStudy();
			if (currentStudy) {
				var url = appConfig.apiUrls.dashboard.studyMetrics.supplant(
					{'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId});
				$http.get(url)
					.then(function(response) {
						q.resolve(response.data);
					}, function(response) {
						q.reject(response.data);
					});
			}
			return q.promise;
		}

		function getStudyMetricsDetails() {
			var currentStudy = appSession.getStudy();
			if (currentStudy) {
				var url = appConfig.apiUrls.dashboard.studyMetricsDetails.supplant(
					{'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId});
				return $http.get(url);
			}
		}

		function getEnrollmentData() {
			var q = $q.defer();
			var currentStudy = appSession.getStudy();

			if (currentStudy) {
				var url = appConfig.apiUrls.dashboard.enrollment.supplant(
					{'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId});
				$http.get(url)
					.then(function(response) {
						q.resolve(response.data);
					}, function(response) {
						q.reject(response.data);
					});
			}

			return q.promise;
		}

		function getDcrMetrics() {
			var q = $q.defer();
			var currentStudy = appSession.getStudy();
			if (currentStudy) {
				var url = appConfig.apiUrls.dashboard.openDcrs.supplant(
					{'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId});
				$http.get(url)
					.then(function(response) {
						q.resolve(response.data);
					}, function(response) {
						q.reject(response.data);
					});
			}
			dcrMetricsStream = Rx.Observable.fromPromise(q.promise);
			return q.promise;
		}
		function getDcrMetricsStream() {
			return dcrMetricsStream;
		}

		function unsetDcrMericsSteram() {
			dcrMetricsStream = null;
		}

		function getVisitOverview(showRecentVisits) {
			const currentStudy = appSession.getStudy();

			if (currentStudy) {
				const url = appConfig.apiUrls.dashboard.visitOverview.supplant({
					'studyId': currentStudy.id,
					'studyModeId': currentStudy.studyModeId,
					'showRecent': showRecentVisits ? 'true' : 'false' });

				return $http.get(url).then((resp) => {
					return resp.data;
				});
			}

			return $q.reject('Error: current study data is not available.');
		}
	}
})();
