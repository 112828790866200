/*
 * numberRangeController
 * */

class NumberRangeController {
	constructor($log) {
		'ngInject';
		this.$log = $log;
		this.MAX_VALUE = 1000000;
		this.MIN_VALUE = 0;
		this.model = {
			from: null,
			to: null
		};
	}

	$onInit() {
		this.$log = this.$log.getInstance('NumberRangeController');
		this.$log.debug('loaded');
	}

	$onChanges(changesObj) {
		if (changesObj.model && !changesObj.model.isFirstChange()) {
			this.model.from = angular.isDefined(changesObj.model.currentValue.from)
				? changesObj.model.currentValue.from : null;
			this.model.to = angular.isDefined(changesObj.model.currentValue.to)
			? changesObj.model.currentValue.to : null;
		}
	}

	enterAction($event) {
		if (this.onEnter) {
			this.onEnter($event);
		}
	}

	/*
	* Returns object with from and to values
	*
	* */
	valueChange(/*model*/) {
		const isValid = this.validateFromToValues(this.model.from, this.model.to);
		const model = {
			'from': this.model.from,
			'to': this.model.to
		};

		this.rangeChanged({ model, isValid });
	}

	fromComponentReady($API) {
		this.fromInputApi = $API;
	}

	toComponentReady($API) {
		this.toInputApi = $API;
	}

	/*
	* Validates inputs values, highlights inputs with wrong values
	* */
	validateFromToValues(from, to) {
		this.fromInvalid = false;
		this.toInvalid = false;
		let fromBelowZero = false;
		let toBelowZero = false;

		this.fromInvalid = angular.isUndefined(from);
		this.toInvalid = angular.isUndefined(to);

		// some input validation failed -> return false
		if (this.fromInvalid || this.toInvalid) {
			return false;
		}

		if (angular.isNumber(from) && from < 0) {
			fromBelowZero = true;
			this.fromInvalid = true;
		}

		if (angular.isNumber(to) && to < 0) {
			toBelowZero = true;
			this.toInvalid = true;
		}

		// some input has negative value -> return false
		if (fromBelowZero || toBelowZero) {
			return false;
		}

		const fromIsNull = (from === null);
		const toIsNull = (to === null);

		// both inputs have no value -> return false
		if (fromIsNull && toIsNull) {
			return false;
		}

		// both inputs have 0 value
		if (from === 0 && to === 0) {
			return false;
		}

		// some input has no value, but another has a valid value -> return true
		if ((fromIsNull || toIsNull) && !(from <= 0 && to <= 0)) {
			return true;
		}

		// if from is less than to -> return true
		if (angular.isNumber(from) && angular.isNumber(to) && from <= to) {
			return true;
		} else if (fromIsNull || toIsNull) {
			return false;
		} else {
			this.toInvalid = true;
			return false;
		}
	}
}

export default NumberRangeController;
