import templateUrl from './fileUploader.html';
import controller from './fileUploader.controller';
import './fileUploader.scss';
/*
 * Create custom filters like this:
 * vm.fileUploadFilters = [{
 *   name: 'myCustomFilter1',
 *   fn: () => {
 *       return true;
 *   },
 *   errorMsg: `My custom error message`
 * }];
 *
 * */
const ertFileUploaderComponent = {
	bindings: {
		uploadUrl: '@',
		filters: '<?',
		componentReady: '&?',
		title: '@?',
		instructions: '@?',
		canUpload: '<?',
		readOnly: '<?',
		onSuccessItem: '&?',
		onDeleteFile: '&?',
		onOpenFile: '&?',
		isOptional: '<?'
	},
	templateUrl,
	controller
};

export default ertFileUploaderComponent;

