/*
 * editISpiroDeviceValuesPopupModule
 * */

import editISpiroDeviceValuesPopupComponent from './editISpiroDeviceValuesPopup.component';

const editISpiroDeviceValuesPopupModule = angular.module('app.components.editISpiroDeviceValuesPopup', [])
	.component('editISpiroDeviceValuesPopup', editISpiroDeviceValuesPopupComponent)
	.name;

export default editISpiroDeviceValuesPopupModule;
