/*
 * baseDatePickerController
 * */

class BaseDatePickerController {
	constructor($log, $element) {
		'ngInject';
		this.$log = $log;
		this.$element = $element;
	}

	$onInit() {
		this.$log = this.$log.getInstance('BaseDatePickerController');
		this.$log.debug('loaded');

	}
	/* eslint-disable angular/angularelement */
	$postLink() {
		const dp = this.$element.find('input');

		dp.datepicker({
			format: 'dd-M-yyyy',
			weekStart: 1,
			autoclose: true,
			disableTouchKeyboard: true,
			todayHighlight: true,
			forceParse: false,
			orientation: this.orientation || 'bottom auto',
			startDate: '01-jan-1900'
		});

		this.defaultValue && dp.datepicker('setDate', this.defaultValue);
	}
	$onDestroy() {
		this.$element.find('input').datepicker('destroy');
	}
	/* eslint-enable angular/angularelement */
}

export default BaseDatePickerController;
