(function() {
	'use strict';

	angular
		.module("portalApp")
		.service("userContactService", UserContactService);

	/**
	 * @ngInject
	 */

	function UserContactService($http, $log, $q, appSession, appConfig) {

		$log = $log.getInstance("UserContactService");
		$log.debug(" loaded");


		var vm = {
			getIncompletesCount: getIncompletesCount,
			getIncompleteContacts: getIncompleteContacts,
			getCompleteContacts: getCompleteContacts,
			getContactFieldConfig: getContactFieldConfig,
			updateContact: updateContact,
			linkContact: linkContact
		};

		return vm;

		//////////////////

		function linkContact(sourceContactId, targetContactId, telephone, fax, mobile, institutionName) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.userContacts.linkContact.supplant(
				{'studyId': study.id, 
				'sourceContactId': sourceContactId, 'targetContactId': targetContactId,
				 'telephone': telephone || '', 'fax': fax || '', 'mobile': mobile || '', 'institutionName': institutionName || ''});
			return $http.post(url);
		}

		function updateContact(contactId, contact) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.userContacts.updateContact.supplant(
				{'studyId': study.id,
				 'contactId': contactId});
			return $http.post(url, contact);
		}

		function getIncompletesCount(studyId) {
			var url = appConfig.apiUrls.userContacts.incompletesCount.supplant(
				{'studyId': studyId });
			return $http.get(url);
		}

		function getIncompleteContacts() {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.userContacts.incompleteContacts.supplant({studyId: study.id});
			return $http.get(url);
		}

		function getCompleteContacts() {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.userContacts.completeContacts.supplant({studyId: study.id});
			return $http.get(url);
		}

		function getContactFieldConfig(roleIds) {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.userContacts.fieldConfig.supplant({studyId: study.id});
			roleIds.forEach(function(el) {
				url += '&id=' + el;
			});
			return $http.get(url);
		}
	}

})();
