(function () {

	'use strict';

	angular
		.module('portalApp')
		.controller('ReportsCreateReportCtrl', ReportsCreateReportController);

	/**
	 * @ngInject
	 */

	function ReportsCreateReportController(reportsService, $log, appI18n, appSession, $filter, $scope,
										   $stateParams, notificationService, loadingIndicatorService) {

		$log = $log.getInstance("ReportsCreateReportCtrl", "color:blue");
		$log.debug("loaded");

		var vm = this;
		vm.selectCountry = selectCountry;
		vm.selectSite = selectSite;
		vm.selectSubject = selectSubject;
		vm.selectRadioOrListOption = selectRadioOrListOption;
		vm.createReport = createReport;
		vm.getDefaultOptionValue = getDefaultOptionValue;
		vm.openCreate = openCreate;
		vm.isDateValid = isDateValid;

		activate();

		function getDefaultOptionValue(criteria) {
			var result = "";
			if (!criteria)
				return result;

			if (criteria.reportParamOptions.length === 0) {
				result = vm.selectOption.noOptions;
			} else if (criteria.isMandatoryOnMSP3) {
				result = vm.selectOption.select;
			} else if (criteria.reportParamOptions.length > 1) {
				result = vm.selectOption.all;
			}

			return result.length == 0 ? "" : "-- " + result + " --";
		}

		function getSites(countryId) {
			if (vm.siteCriteria) {
				vm.isLoading = true;
				reportsService.getSelectionCriteriaParameter(vm.reportId, vm.siteCriteria.parameterId, countryId)
					.then(function (response) {
						vm.sites = response.data;
						if (response.data && response.data.length > 0) {
							vm.noSites = false;
						} else if (response.data && response.data.length === 1) {
							vm.selectedSite = response.data[0];
							vm.noSites = false;
						}
					}, function (response) {
						$log.error("get sites failed with error: " + response.data);
					}).finally(() => {
						vm.isLoading = false;
					});
			}
		}

		function getSubjects(siteId, countryId) {
			if (vm.subjectCriteria) {
				vm.isLoading = true;
				reportsService.getSelectionCriteriaParameter(vm.reportId, vm.subjectCriteria.parameterId, siteId, countryId)
					.then(function (response) {
						vm.subjects = response.data;
						if (response.data && response.data.length > 0) {
							vm.noSubjects = false;
						} else if (response.data && response.data.length === 1) {
							vm.selectedSubject = response.data[0];
							vm.noSubjects = false;
						}
					}, function (response) {
						$log.error("get subjects failes with error: " + response.data);
					}).finally(() => {
						vm.isLoading = false;
					});
			}
		}

		function isDateValid(date) {
			return date && moment(date, 'DD-MMM-YYYY', true).isValid();
		}

		function isInvalid() {
			let invalid = false;

			vm.criteria.forEach((criteria) => {
				if (criteria.paramMapLabel === 'Country' &&
					criteria.isMandatoryOnMSP3 &&
					!vm.selectedCountry &&
					!vm.noCountries) {
					invalid = true;
				} else if (criteria.paramMapLabel === 'Investigator' &&
						   criteria.isMandatoryOnMSP3 &&
						   !vm.selectedSite && !vm.noSites) {
					invalid = true;
				} else if (criteria.paramMapLabel === 'Subject Number' &&
						   criteria.isMandatoryOnMSP3 &&
						   !vm.selectedSubject &&
						   !vm.noSubjects) {
					invalid = true;
				} else if (criteria.paramMapLabel !== 'Country' &&
						   criteria.paramMapLabel !== 'Investigator' &&
						   criteria.paramMapLabel !== 'Subject Number' &&
						   criteria.reportParamDataTypeNameString === 'List' &&
						   criteria.isMandatoryOnMSP3 &&
						   !criteria.selectedOption &&
						   criteria.reportParamOptions &&
						   criteria.reportParamOptions.length > 0) {
					invalid = true;
				} else if (criteria.reportParamDataTypeNameString === 'DATE' &&
						   ((criteria.isMandatoryOnMSP3 && !criteria.dateValue) ||
							(criteria.dateValue && !vm.isDateValid(criteria.dateValue)))) {
					invalid = true;
				} else if (criteria.reportParamDataTypeNameString === 'TEXT' &&
						   criteria.isMandatoryOnMSP3 &&
						   !criteria.selectedText) {
					invalid = true;
				} else if (criteria.reportParamDataTypeNameString === 'Radio' &&
						   criteria.isMandatoryOnMSP3 &&
						   !criteria.selectedOption &&
						   criteria.reportParamOptions &&
						   criteria.reportParamOptions.length > 0) {
					invalid = true;
				}
			});
			return invalid;

		}

		function getSelectionCriteria() {
			var selections = [];
			vm.criteria.forEach(function (criteria) {
				if (criteria.paramMapLabel === 'Country' && vm.selectedCountry) {
					selections.push({
						value: vm.selectedCountry.reportParamOptionId,
						displayValue: vm.selectedCountry.reportParamOptionValue,
						id: criteria.parameterId
					});
				} else if (criteria.paramMapLabel === 'Investigator' && vm.selectedSite) {
					selections.push({
						value: vm.selectedSite.reportParamOptionId,
						displayValue: vm.selectedSite.reportParamOptionValue,
						id: criteria.parameterId
					});
				} else if (criteria.paramMapLabel === 'Subject Number' && vm.selectedSubject) {
					selections.push({
						value: vm.selectedSubject.reportParamOptionId,
						displayValue: vm.selectedSubject.reportParamOptionValue,
						id: criteria.parameterId
					});
				} else if (criteria.reportParamDataTypeNameString === 'List' && criteria.selectedOption) {
					selections.push({
						value: criteria.selectedOption.reportParamOptionId,
						displayValue: criteria.selectedOption.reportParamOptionValue,
						id: criteria.parameterId
					});
				} else if (criteria.reportParamDataTypeNameString === 'DATE' && criteria.dateValue) {
					var value = $filter('date')(toDate(criteria.dateValue), 'MM/dd/yyyy');
					selections.push({
						value: value,
						displayValue: criteria.dateValue,
						id: criteria.parameterId
					});
				} else if (criteria.reportParamDataTypeNameString === 'TEXT' && criteria.selectedText) {
					selections.push({
						value: criteria.selectedText,
						displayValue: criteria.selectedText,
						id: criteria.parameterId
					});
				} else if (criteria.reportParamDataTypeNameString === 'Radio' && criteria.selectedOption) {
					selections.push({
						value: criteria.selectedOption.reportParamOptionId,
						displayValue: criteria.selectedOption.reportParamOptionValue,
						id: criteria.parameterId
					});
				}
			});
			selections.push({
				value: vm.selectedFormat,
				displayValue: vm.selectedFormat,
				id: 1005
			});

			return selections;

		}

		function getDefaultOption(options, stateParameter) {

			var result = null;
			if (options && options.length === 1) {
				result = options[0];
			} else if (stateParameter && options && options.length > 1) {
				var find = $filter('filter')(options, {
					reportParamOptionId: stateParameter
				})[0];
				if (find) {
					result = find;
				}
			}
			return result;

		}

		function openCreate(row) {
			loadingIndicatorService.show();
			vm.reportId = row.reportId;
			vm.title = vm.createTitle + " " + row.reportName;
			vm.isLoading = true;
			vm.selectedReport = row;
			vm.selectedCountry = null;
			vm.selectedSite = null;
			vm.selectedSubject = null;
			vm.criteria = null;
			vm.errorMessage = null;
			vm.actions[0].isDisabled = false;
			vm.createReport.sendByEmail = false;
			vm.selectedFormat = null;
			vm.saveClicked = false;

			reportsService.getAvailableSelectionCriteria(row.reportId)
				.then(function (response) {
					vm.criteria = response.data;
					vm.half = vm.criteria.length <= 10 ? 5 : Math.ceil(vm.criteria.length / 2);
					angular.forEach(response.data, function (criteria) {
						criteria.displayLabel = criteria.paramMapLabel;
						if (criteria.paramMapLabel === 'Country') {
							vm.countryCriteria = criteria;
							vm.countries = criteria.reportParamOptions;
							if (vm.countries && vm.countries.length > 0) {
								vm.noCountries = false;
							}
							vm.selectedCountry = getDefaultOption(criteria.reportParamOptions, $stateParams.country);
						} else if (criteria.paramMapLabel === 'Investigator') {
							vm.siteCriteria = criteria;
							vm.sites = criteria.reportParamOptions;
							if (criteria.reportParamOptions && criteria.reportParamOptions.length > 0) {
								vm.noSites = false;
							}
							vm.selectedSite = getDefaultOption(criteria.reportParamOptions, $stateParams.site);
						} else if (criteria.paramMapLabel === 'Subject Number') {
							criteria.displayLabel = vm.subjectKeyLabel;

							vm.subjectCriteria = criteria;
							vm.subjects = criteria.reportParamOptions;
							if (criteria.reportParamOptions && criteria.reportParamOptions.length > 0) {
								vm.noSubjects = false;
							}
							vm.selectedSubject = getDefaultOption(criteria.reportParamOptions, $stateParams.subject);
						} else if (criteria.reportParamOptions && criteria.reportParamOptions.length === 1
							&& criteria.reportParamDataTypeNameString === 'List') {
							criteria.selectedOption = criteria.reportParamOptions[0];
							criteria.selected = true;
						}
					});

					reportsService.getSelectionCriteriaParameter(row.reportId, 1005, -1)
						.then(function (response) {
							vm.formatOptions = response.data;
							if (response.data && response.data.length === 1) {
								vm.selectedFormat = response.data[0].reportParamOptionId;
							}

						}, function (response) {
							$log.error("getSelectionCriteriaParameter failed with error: " + response.data);
						}).finally(() => {
							vm.isLoading = false;
							vm.showCreate = true;
							loadingIndicatorService.hide();
						});

				}, function (response) {
					$log.error("getAvailableSelectionCriteria failes with error: " + response.data);
					vm.isLoading = false;
				});
		}

		function selectCountry(country) {
			vm.selectedCountry = country;
			vm.selectedSite = null;
			vm.selectedSubject = null;
			vm.noSites = true;
			vm.noSubjects = true;

			var countryId = country === null ? null : country.reportParamOptionId;
			getSites(countryId);
			getSubjects(null, country === null ? null : country.reportParamOptionId);

		}

		function selectSite(site) {
			vm.selectedSite = site;
			vm.selectedSubject = null;
			vm.noSubjects = true;
			var siteId = site === null ? null : site.reportParamOptionId;
			getSubjects(siteId);

		}

		function selectSubject(subject) {
			vm.selectedSubject = subject;
		}

		function selectRadioOrListOption(criteria, option) {
			criteria.selected = true;
			criteria.selectedOption = option;
		}

		function showSaveMessage() {
			appI18n.translate(['reports.create-report.success-message']).then((translations) => {
				const msg = translations['reports.create-report.success-message'];

				notificationService.showSuccess(msg);
				vm.showCreate = false;
			});
		}

		function createReport() {
			vm.saveClicked = true;
			if (!vm.selectedFormat || isInvalid()) {
				return;
			}

			loadingIndicatorService.show();
			vm.actions[0].isDisabled = true;
			var report = {
				reportId: vm.selectedReport.reportId,
				templateName: vm.selectedReport.reportName,
				emailRecipients: vm.createReport.sendByEmail ? appSession.get().email : ""
			};
			report.selectionCriteria = getSelectionCriteria();
			reportsService.createReport(report)
				.then(function (response) {
					if (response.data.isSuccessful) {
						showSaveMessage();
					} else {
						$log.error("createReport failes with error: " + response.data);
						vm.isSuccessful = false;
						appI18n.translate("reports.create-report.error-create")
							.then(function (translation) {
								vm.errorMessage = translation;
							});
					}
					loadingIndicatorService.hide();
					vm.actions[0].isDisabled = false;
				}, function (response) {
					$log.error("createReport failes with error: " + response.data);
					appI18n.translate("reports.create-report.error-create")
						.then(function (translation) {
							vm.errorMessage = translation;
						});
					loadingIndicatorService.hide();
					vm.actions[0].isDisabled = false;
				});
		}

		function toDate(dateStr) {
			var parts = dateStr.split('-');
			var dateString = `${parts[1]} ${parts[0]}, ${parts[2]}`;
			return new Date(dateString);
		}


		function activate() {
			reportsService.registerCreateReportHandler(vm);
			$scope.$on('$destroy', () => {
				reportsService.registerCreateReportHandler();
			});

			vm.noCountries = true;

			appI18n.translate([
				"reports.create-report.create",
				"reports.create-report.title",
				"app.common.select",
				"app.common.all",
				"app.common.noOptions",
				'app.common.subjectKey'
			])
				.then(function (translations) {
					vm.createTitle = translations["reports.create-report.create"];
					vm.baseTitle = translations["reports.create-report.title"];
					vm.subjectKeyLabel = translations["app.common.subjectKey"];
					vm.selectOption = {
						select: translations["app.common.select"],
						all: translations["app.common.all"],
						noOptions: translations["app.common.noOptions"],
					};
					vm.actions = [
						{
							isPrimary: true,
							displayName: vm.baseTitle,
							action: vm.createReport
						}
					];


				});

		}

	}
})();
