(function () {
	'use strict';

	angular.module('portalApp').controller('newPftEntryCtrl', NewPftEntryController);

	/*
	 * @ngInject
	 */
	function NewPftEntryController($log, appI18n, ertStepsWizardService, $stateParams,
			signatureService, clinicalDataEntryService, $location, $timeout, clinicalDataEntryApiService,
			loadingIndicatorService, clinicalDataEntryDataService, $scope, appConfig, notificationService) {

		$log = $log.getInstance('newPftEntryCtrl');
		$log.debug('newPftEntryCtrl:: loaded');

		var vm = this;
		vm.active = false;
		vm.createNewEntryAfterSubmit = false;
		vm.technicianGuidForAfterSubmit = null;
		vm.nextStep = nextStep;
		vm.previousStep = previousStep;
		vm.cancelAction = cancelAction;
		vm.onInitialLoaded = onInitialLoaded;

		vm.submit = submit;
		vm.resetState = resetState;

		vm.actions = [
			{ action: vm.nextStep, displayName: 'Next', isPrimary: true },
			{
				action: submitAndCreateNewEntry,
				displayName: appI18n.translateImmediate('app.buttons.new-entry'),
				isHidden: true
			},
			{ action: vm.previousStep, displayName: 'Back' },
			{ action: vm.cancelAction, displayName: 'Cancel', cssClass: 'btn-danger md-close', iconName: 'close' }
		];

		active();
		function active() {
			loadingIndicatorService.show();
			clinicalDataEntryDataService.clearDataModel();
			ertStepsWizardService.registerSubmit(vm.submit);

			vm.submissionInfoStep = appI18n.translateImmediate('cde-pft.titles.submissionInfoStep');
			vm.measurementInfoStep = appI18n.translateImmediate('cde-pft.titles.measurementInfoStep');
			vm.addAttachmentsStep = appI18n.translateImmediate('cde-pft.titles.addAttachmentsStep');
			vm.submitStep = appI18n.translateImmediate('cde-pft.titles.submitStep');

			ertStepsWizardService.registerWizardStepChanged(onWizardStepChanged);
			ertStepsWizardService.registerDisableWizardButtonsHandler(() => {
				for (let i = 0; i < vm.actions.length; i++) {
					vm.actions[i].isDisabled = true;
				}
			});
			ertStepsWizardService.registerEnableWizardButtonsHandler(() => {
				for (let i = 0; i < vm.actions.length; i++) {
					vm.actions[i].isDisabled = false;
				}
			});

			ertStepsWizardService.registerWizardCanNavigateForwardToStep((currentIndex, nextIndex) => {
				return nextIndex <= currentIndex + 1;
			});

			if ($stateParams.signKey) {
				handleAutoSubmit();
			}
			else {
				clinicalDataEntryService.registerInitialStudiesLoaded(vm.onInitialLoaded);
			}
			$timeout(() => {
				vm.isInitialLoading = true;
				vm.active = true;
			});
		}

		function onInitialLoaded() {
			$log.debug('onInitialLoaded in newPftEntryCtrl');
			loadingIndicatorService.hide();
			vm.isInitialLoading = false;
		}

		function handleAutoSubmit() {
			vm.isAutoSubmit = true;
			const signKey = $stateParams.signKey;
			const tokenId = $stateParams.tokenId;
			const state = signatureService.getState(signKey);

			if (state) {
				clinicalDataEntryDataService.restoreDataModel(state);
			}


			const unregister = $scope.$watch(
				() => {return ertStepsWizardService.isWizardRegistered();},
				() => {
					if (ertStepsWizardService.isWizardRegistered()) {
						const state = signatureService.getState(signKey);

						if (state) {
							vm.createNewEntryAfterSubmit = state.createNewEntryAfterSubmit;

							signatureService.removeState(signKey);
							vm.data = clinicalDataEntryDataService.getDataModel();
							// move to last step and submit form
							$timeout(() => {
								ertStepsWizardService.goTo(2);
								submitDataEntry(signKey, tokenId);
							}, 0);
						} else {
							vm.cancelAction();
						}

						unregister();
					}
				});
		}

		function onWizardStepChanged(index) {
			if (ertStepsWizardService.isLastStep()) {
				vm.actions[0].displayName = appI18n.translateImmediate('app.buttons.submit');
				vm.actions[1].isHidden = false;
			} else {
				vm.actions[0].displayName = appI18n.translateImmediate('app.buttons.next');
				vm.actions[1].isHidden = true;
			}

			vm.actions[2].isDisabled = index === 0;
		}

		function submitAndCreateNewEntry() {
			if (ertStepsWizardService.isCurrentStepValid()) {
				vm.createNewEntryAfterSubmit = true;
				ertStepsWizardService.doSubmit();
			}
		}

		function nextStep() {
			if (ertStepsWizardService.isLastStep()) {
				if (ertStepsWizardService.isCurrentStepValid()) {
					ertStepsWizardService.doSubmit();
				}
			} else {
				ertStepsWizardService.nextStep();
			}
		}

		function previousStep() {
			ertStepsWizardService.previousStep();
		}


		function cancelAction() {
			gotoListView();
		}

		function updateadditionalInfo() {
			vm.sigType = appConfig.sigType.createPaperPft;
			vm.data = clinicalDataEntryDataService.getDataModel();
			vm.state = vm.data;
			vm.state.createNewEntryAfterSubmit = vm.createNewEntryAfterSubmit;
			vm.state.displayMode = appConfig.dataEntryDisplayModes.add;

			vm.additionalInfo = {
				action: appI18n.translateImmediate('signature.action.newPaperPft'),
				infoKey1: appI18n.translateImmediate('signature.info.site'),
				infoValue1: vm.data.selectedSite.sponsorSiteId,
				infoKey2: appI18n.translateImmediate('signature.info.subject'),
				infoValue2: vm.data.subject.sponsorSubjectId,
				infoKey3: appI18n.translateImmediate('signature.info.visitName'),
				infoValue3: vm.data.visitEvent.visitName,
				infoKey4: appI18n.translateImmediate('signature.info.eventName'),
				infoValue4: vm.data.visitEvent.eventName
			};
			if (vm.data.visitEvent.periodName) {
				vm.additionalInfo.infoKey3 = appI18n.translateImmediate('signature.info.visitPeriodName');
				vm.additionalInfo.infoValue3 = `${vm.additionalInfo.infoValue3} (${vm.data.visitEvent.periodName})`;
			}

		}
		function submit() {
			vm.errorMsg = '';
			ertStepsWizardService.registerSubmit(null);
			updateadditionalInfo();
			signatureService.sign(vm.sigType, vm.additionalInfo, vm.state).then(
				() => {}, () =>
					{
						ertStepsWizardService.registerSubmit(vm.submit);

					});
		}

		function submitDataEntry(signKey, tokenId) {
			vm.errorMsg = '';
			const model = clinicalDataEntryDataService.getNewEntryData(vm.data);

			model.signKey = signKey;
			model.signToken = tokenId;
			vm.isLoading = true;

			if (!model.signToken || !model.signKey) {
				$log.debug('no sign token, signature aborted?');
			}
			if (!signatureService.validateSignature(model.signToken, model.signKey)) {
				vm.resetState();
				return;
			}

			clinicalDataEntryApiService.createEntry(model).then(
				response => handleSuccess(response.data),
				error => handleError(error.data)
			);
		}
		function resetState() {
			vm.isLoading = false;
			vm.isAutoSubmit = false;
			vm.isInitialLoading = false;
			loadingIndicatorService.hide();
			vm.isSignatureValid = false;
			vm.createNewEntryAfterSubmit = false;
			vm.technicianGuidForAfterSubmit = null;
		}

		function handleSuccess(data) {
			if (data && data.isSuccessful) {
				showSuccess();

				// if the user clicked on the Add New Entry button, reset the wizard forms and navigate to step 2
				if (vm.createNewEntryAfterSubmit) {
					vm.createNewEntryAfterSubmit = false;
					vm.technicianGuidForAfterSubmit = data.result.technicianGuid;
					setupPrefilledEntry();
					ertStepsWizardService.goTo(1);
				} else {
					gotoListView();
				}
				resetState();
			} else {
				const error = data.messages ? data.messages[0] : data.translationKey ? data.translationKey : data;

				vm.errorMsg = appI18n.translateImmediate(error);
				$log.debug(vm.errorMsg);
				notificationService.showError(vm.errorMsg);
				resetState();
			}
		}

		/**
		 * If the user wants to create an additional entry the fields of the previously submitted entry
		 * are reused to prefill the forms. All not required fields are cleared.
		 */
		function setupPrefilledEntry() {
			// fetch wizard step controllers and data model
			const visitEventHandler = clinicalDataEntryService.getVisitEventHandler();
			const effortsHandler = clinicalDataEntryService.getEffortsHandler();
			const uploadHandler = clinicalDataEntryService.getUploadHandler();
			const measurementInfoHandler = clinicalDataEntryService.getMeasurementInfoHandler();

			let dataModel = clinicalDataEntryDataService.getDataModel();

			// update values in data model to avoid side effects
			dataModel = resetValuesInDataModel(dataModel);
			// update the values in the different controllers to be in sync with the data model.
			resetValuesInHandlers(visitEventHandler, effortsHandler, uploadHandler, measurementInfoHandler);

			// update technician options if a new technican was created in the first entry
			addNewlyCreatedTechnicianIfRequired(
				visitEventHandler,
				dataModel.visitEvent.technician.firstName,
				dataModel.visitEvent.technician.lastName,
				dataModel.visitEvent.technician.email,
				vm.technicianGuidForAfterSubmit,
				dataModel.technicians);

			// store updated data model
			clinicalDataEntryDataService.restoreDataModel(dataModel);
		}

		/**
		 * If the user creates a new technician and wants to create an additional entry, we
		 * add the technician to the technician list and use it for the additional entry.
		 */
		function addNewlyCreatedTechnicianIfRequired(visitEventHandler,
			technicianFirstName, technicianLastName, technicianEmail, technicianGuid, technicians) {

			// try to find technician in technician options
			const technicianInOptions = _.find(technicians, (t) => {
				return t.lastName === technicianLastName
					&& t.firstName === technicianFirstName
					&& t.email == technicianEmail
					&& t.guid === technicianGuid;
			});

			// if technician was not found then the entry was created in the entry before and we need to add it
			if (!technicianInOptions) {
				visitEventHandler.addAndSetNewTechnician(
					technicianFirstName,
					technicianLastName,
					technicianEmail,
					technicianGuid,
					technicians
				);
			}
		}

		/**
		 * Resets some form values of the different step controllers. Used to prefill only the required
		 * form fields for an additional entry.
		 */
		function resetValuesInHandlers(visitEventHandler, effortsHandler, uploadHandler, measurementInfoHandler) {
			// keep study, site, subject and visit information but:
			// reset selected event, date, time
			visitEventHandler.resetEventDetails();
			// reset efforts
			effortsHandler.reset();
			// reset attachments
			uploadHandler.resetAttachments();
			// reset site comment
			measurementInfoHandler.resetSiteComment();
		}

		/**
		 * Resets some data model values and keep only the required fields for an additional entry.
		 */
		function resetValuesInDataModel(dataModel) {
			dataModel.visitEvent.selectedEvent = {};
			dataModel.visitEvent.eventDate = null;
			dataModel.visitEvent.eventTime = null;
			dataModel.comments = '';
			if (dataModel.visitEvent.visitTaskOid) {
				dataModel.visitEvent.eventId = null;
				dataModel.visitEvent.eventName = null;
				dataModel.visitEvent.testDataName = null;
				dataModel.visitEvent.visitTaskOid = null;
				dataModel.visitEvent.testDataTypeId = null;
			}
			dataModel.efforts = [];
			dataModel.attachments = [];



			return dataModel;
		}

		function handleError(error) {
			vm.errorMsg = error;
			$log.debug(vm.errorMsg);
			notificationService.showError(vm.errorMsg);
			resetState();
		}

		function showSuccess() {
			notificationService.showSuccess(appI18n.translateImmediate('cde-pft.dialog.successSubmitDataContent'));
		}

		function gotoListView() {
			$location.search('signKey', null);
			$location.search('tokenId', null);
			clinicalDataEntryService.gotoListView();
		}

	}
})();
