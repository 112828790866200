/*
 * sendPinPopupComponent
 * */

import templateUrl from './sendPinPopup.html';
import controller from './sendPinPopup.controller';
import './sendPinPopup.scss';

const sendPinPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default sendPinPopupComponent;
