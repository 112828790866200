var base64 = require('Base64');

(function () {
	'use strict';

	angular.module('portalApp').controller('SsoRedirectCtrl', SsoRedirectCtrl);

	/**
	 * Controller to handle login view
	 *
	 * @ngInject
	 */
	function SsoRedirectCtrl($log, $state, $stateParams, $window, appConfig, appI18n, appSession, dialogService,
							 accountService, ssoService, loadingIndicatorService, gssoLoginInfo) {

		$log = $log.getInstance('SsoRedirectCtrl', 'color:#021b41; font-size:1em; background-color:#dedede;');
		$log.debug('loaded');
		var vm = this;
		vm.showRedirectMessage = false;

		vm.loadMsp3 = loadMsp3;
		vm.loadMsp1x = loadMsp1x;
		vm.showResetPassword = showResetPassword;
		vm.doResetPassword = doResetPassword;
		vm.doFinishResetPassword = doFinishResetPassword;


		vm.active = false;
		vm.isGssoResetPassword = false;

		if ($state.params.resetPasswordMode) {
			vm.isGssoResetPassword = true;
			vm.showResetPassword();
		} else {
			activate();
		}

		function activate() {

			if(!gssoLoginInfo || gssoLoginInfo.length !== 2) {
				displayError('error', 'State or login info missing.');
				return;
			}

			var decodedState = gssoLoginInfo[0];
			var loginInfo = gssoLoginInfo[1];

			const isValid = validateStateAndLoginInfo(decodedState, loginInfo);

			if (!isValid) {
				return;
			}

			$log.debug($stateParams.state);
			$log.debug($stateParams.access_token);
			$log.debug($stateParams.id_token);
			$log.debug($stateParams.token_type);
			$log.debug($stateParams.expires_in);
			$log.debug($stateParams.scope);

			setGssoInfo($stateParams, decodedState);
			login(loginInfo.data);
		}

		function validateStateAndLoginInfo(decodedState, loginInfo) {
			// assure that study context is available
			if (!decodedState || !decodedState.study || !decodedState.study.studyId) {
				displayError('noStudy', 'No study context found in state.');
				return false;
			}

			// the backend checks the provided token, the study information and the number of linked user accounts
			// if there was any error the isValid property is set to false and we should display the provided error
			if (!loginInfo.data.isValid) {
				displayError(loginInfo.data.error, loginInfo.data.errorMessage);

				return false;
			}

			return true;
		}


		function setGssoInfo($stateParams, decodedState) {
			vm.gsso = {};
			vm.gsso.state = $stateParams.state;
			vm.gsso.access_token = $stateParams.access_token;
			vm.gsso.id_token = $stateParams.id_token;
			vm.gsso.token_type = $stateParams.token_type;
			vm.gsso.expires_in = $stateParams.expires_in;
			vm.gsso.scope = $stateParams.scope;
			vm.gsso.decodedState = decodedState;
		}

		function displayError(code, message) {
			$log.debug(`error:${code} ${message}`);
			switch (code) {
				case 'wrongToken':
					setError('login.messages.invalidateToken'); // invalidate token
					break;
				case 'wrongStudy':
					setError('login.messages.wrongStudy'); // can't find study
					break;
				case 'noStudy':
					setError('login.messages.noStudy'); // gsso didn't pass study to msp
					break;
				case 'noUser':
					setError('login.messages.noUser'); // can't find user for this study
					break;
				case 'serverError':
					setError('login.messages.serverError'); // server errors
					break;

				default:
					setError('login.messages.otherError'); //other errors
					break;
			}
			vm.active = true;
		}

		function setError(translationKey) {
			if (translationKey) {
				appI18n.translate(translationKey).then(function (translation) {
					vm.errorMessage = translation;
				});
			}
			else {
				vm.errorMessage = '';
			}
		}

		function login(loginInfo) {
			vm.study = loginInfo.study;
			vm.selectedUser = loginInfo.users[0];

			vm.username = vm.selectedUser.eMail;
			if (loginInfo.isEmailConfirmed === false) {
				resendConfirmationEmail(vm.username);
			}
			else if (loginInfo.hasMultipleLinkedUsers) {
				showMultipleLinkedUsersWarning(loginInfo.users, loginInfo.studyName);
			} else {
				vm.isLoading = true;
				loadMsp3();
			}

		}

		function showMultipleLinkedUsersWarning(users, studyName) {
			appI18n.translate(['login.multipleUsers.message1', 'login.multipleUsers.message2',
							   'login.multipleUsers.message3']).then(
				function (translation) {
					vm.multipleUsersMessage1 = translation['login.multipleUsers.message1'].supplant({studyName});
					var selectedUser = vm.selectedUser.eMail + ' (' + vm.selectedUser.firstName + ' ' + vm.selectedUser.lastName + ')';
					vm.multipleUsersMessage2 = translation['login.multipleUsers.message2'].supplant({selectedUser});
					vm.multipleUsersMessage3 = translation['login.multipleUsers.message3'];
					vm.duplicateUsers = users;
					vm.hasMultipleLinkedUsers = true;
					vm.active = true;
				});

		}

		function loadMsp3() {
			vm.dataLoading = true;
			var user = vm.selectedUser;
			createUserSession(user);
			loadStudy(vm.study);
		}

		function redirectToLegacyPortal(data) {
			vm.legacyCompatibilityWarning = dialogService.createConfirmHandler();
			loadingIndicatorService.hide('global');
			vm.legacyCompatibilityWarning.show().then(function () {
				vm.legacyCompatibilityWarning.hide();
				appSession.destroy(true);
				$window.open(data.redirectUrl, '_msp1');
				ssoService.gotoGlobalDashboard();
			});
		}

		function showPasswordResetFailure() {
			vm.passwordResetSuccess = dialogService.createConfirmHandler();
			vm.passwordResetSuccess.show().then(function () {
				vm.passwordResetSuccess.hide();
				ssoService.gotoGlobalDashboard();
			});
		}

		function showPasswordResetSuccess() {
			vm.passwordResetSuccess = dialogService.createConfirmHandler();
			vm.passwordResetSuccess.show().then(function () {
				vm.passwordResetSuccess.hide();
				appSession.logout(false);
				ssoService.gotoGlobalDashboard();
			});
		}
		function showStudyLoadError() {
			vm.legacyLoadError = dialogService.createConfirmHandler();
			vm.legacyLoadError.show().then(function () {
				vm.legacyLoadError.hide();
				vm.isLoadingStudy = false;
			});
		}

		function showAccountLockedError() {
			vm.accountLockedError = dialogService.createConfirmHandler();
			vm.accountLockedError.show().then(
				function (result) {
					if (result) {
						doResetPassword2();
					}
				vm.accountLockedError.hide();
				vm.isLoadingStudy = false;
			});
		}

		function createUserSession(user) {
			var userInfo = {
				userinfo_Id: user.id,
				userinfo_FirstName: user.firstName,
				userinfo_LastName: user.lastName,
				userinfo_email: user.eMail,
				userinfo_guid: user.guId,
				userinfo_gssoUserId: user.gssoUserId,
				expires_in: vm.gsso.expires_in,
				id_token: vm.gsso.id_token,
				access_token: vm.gsso.access_token,
				sessionId: user.sessionId,
				state: vm.gsso.decodedState
			};

			appSession.create(userInfo);
		}

		function loadStudy(study) {

			var appSessionStudy = appSession.studyDto2SessionStudy(study);
			var returnUrl = vm.gsso.decodedState.destinationUrl;
			appSession.publishStudy(appSessionStudy);
			$log.debug('gsso return url:' + returnUrl);

			appSession.setTrackerInfo(appSessionStudy)
				.then(() => {
					$log.debug('finished set tracker information');
				})
				.finally(() => {
					// to keep the functionality of an automatic redirect to MSP3 when a logistics microportal page
					// is opened in a new tab we need to keep the header.js integrated.
					// But then the client URL query parameter is updated with the URL of the microportal and not
					// with the URL of the MSP3 page. If a user initiates a page refresh, we still need to stay in
					// MSP3 and therefore the MSP3 needs a mapping of Logistics Microportal page -> MSP3 page.
					if (isLogisticsMicroportalUrl(returnUrl)) {
						navigateToLogisticsMicroportalUrl(appSessionStudy, returnUrl);
					} else if (isUrl(returnUrl)) {
						$window.location.href = returnUrl;
					} else {
						appSession.switchStudy(appSessionStudy, false, true);
					}
				});
		}

		function isLogisticsMicroportalUrl(url) {
			return url && url.startsWith(appConfig.external.logisticsUrl);
		}

		function navigateToLogisticsMicroportalUrl(study, logisticsUrl) {
			// This is a workaround for now because it creates a dependency to the logistics routing.
			// Therefore we always redirect to the Logistics Microportal landing page if an 'unknown'
			// route is detected.
			// Once the Navigator sidebar is available then this mapping will be obsolete.
			const regex = /studies\/\d+\/([\w-]+)/;
			const match = regex.exec(logisticsUrl);
			const logisticsRoute = mapUrlToLogisticsRoute(match.length > 1 ? match[1] : '');

			$state.go(logisticsRoute, {
				study: study.id,
				mode: study.studyModeId
			});
		}

		function mapUrlToLogisticsRoute(matchedRouteName) {
			switch (matchedRouteName) {
				case 'orders': return appConfig.routes.logisticsMicroportal.supplyOrder;
				case 'shipment-tracking': return appConfig.routes.logisticsMicroportal.shipmentTracking;
				case 'return-requests': return appConfig.routes.logisticsMicroportal.returnRequests;
				default: return appConfig.routes.logisticsMicroportal.root; // fallback
			}
		}

		function isUrl(s) {
			if (!s) {
				return false;
			}
			const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

			return regexp.test(s);
		}

		function doResetPassword2() {
			vm.resetPasswordLoading = true;
			accountService.resetPassword(vm.username, vm.isGssoResetPassword).then(
				function () {
					vm.resetPasswordLoading = false;
					showPasswordResetSuccess();
				},
				function (response) {
					showPasswordResetFailure();
				});
		}


		function doResetPassword() {
			vm.resetPasswordActions[0].isDisabled = true;
			vm.resetPasswordActions[1].isDisabled = true;

			vm.resetPassword.isLoading = true;
			accountService.resetPassword(vm.resetPassword.formModel.email, vm.isGssoResetPassword).then(
				function () {
					vm.resetPasswordActions[0].isHidden = true;
					vm.resetPasswordActions[1].isHidden = true;
					vm.resetPasswordActions[2].isHidden = false;
					vm.resetPassword.isLoading = false;
					vm.resetPassword.currentStep = 'done';
				},
				function (response) {
					vm.resetPassword.isLoading = false;
					vm.resetPasswordActions[0].isDisabled = true;
					vm.resetPasswordActions[1].isDisabled = true;
					var message;
					if (response.data && response.data.message) {
						message = response.data.message;
					}
					else {
						message = response.statusText;
					}
					appI18n.translate(message).then(function (translation) {
						if (message === translation) {
							translation = 'An error occurred during password reset: ' + message;
						}
						vm.resetPassword.errorMsg = translation;
					});
				});
		}

		function loadMsp1x() {
			loadingIndicatorService.show('global');
			var studyId = vm.study.protocolId;
			ssoService.legacyLogin(studyId, vm.username, vm.password, vm.gsso.id_token).then(
				function(response) {
					if (!response.data || !response.data.success) {
						loadingIndicatorService.hide('global');
						if (response.data && response.data.isValidationError) {
							vm.hasError = true;
							if (+response.data.errorCode === -23003) {
								showAccountLockedError(response.data);
							}
							vm.errorMessage = appI18n.translateImmediate(response.data.translationKey);
						} else if (response.data.code === 'ChangePassword'){
							accountService.showChangePassword(vm.username, vm.password, on1xPasswordChanged);
						} else {
							showStudyLoadError(response.data);
						}
					} else {
						redirectToLegacyPortal(response.data);
					}
				},
				function(error) {
					loadingIndicatorService.hide('global');
					showStudyLoadError(error);

				});
		}

		function on1xPasswordChanged(username, password) {
			vm.password = password;
			loadMsp1x();
		}

		function showResetPassword() {
			initResetPasswordForm();
			vm.resetPasswordActions = [{
				action: () => {
					vm.resetPasswordForm.$setSubmitted();
					if (vm.resetPasswordForm.$valid) {
						vm.doResetPassword();
					}
				},
				isDisabled: false,
				isPrimary: true,
				displayName: 'app.buttons.submit'
			}, {
				action: () => { vm.resetPassword.isFormVisible = false; },
				isDisabled: false,
				isPrimary: false,
				displayName: 'app.buttons.cancel'
			}, {
				action: vm.doFinishResetPassword,
				isHidden: true,
				isPrimary: true,
				displayName: 'app.buttons.close'
			}];
			vm.resetPassword.isFormVisible = true;
		}
		function initResetPasswordForm() {
			vm.resetPassword = {
				isFormVisible: false,
				isLoading: false,
				serviceResult: {},
				serviceError: {},
				errorMsg: '',
				currentStep: 'reset',
				formModel: {
					email: ''
				}
			};
		}

		function doFinishResetPassword() {
			vm.resetPassword.isFormVisible = false;
		}

		function resendConfirmationEmail(email) {
			vm.customerCare = appConfig.external.customerCare;
			vm.email = email;
			vm.resendConfirmation = dialogService.createConfirmHandler();
			vm.resendConfirmation.show().then( () => {
				vm.resendConfirmation.hide();
				ssoService.gotoGlobalDashboard();
			});
		}

	}
})();
