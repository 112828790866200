/*
 * enrollmentGraphWidgetComponent
 * */

import templateUrl from './enrollmentGraphWidget.html';
import controller from './enrollmentGraphWidget.controller';
import './enrollmentGraphWidget.scss';

const enrollmentGraphWidgetComponent = {
	bindings: {
		heading: '@',
		config: '<'
	},
	templateUrl,
	controller
};

export default enrollmentGraphWidgetComponent;
