export default class AnalyticsService {
	constructor(Analytics, $log, appSession) {
		'ngInject';
		this.analytics = Analytics;
		this.$log = $log.getInstance('analytics.service', 'color:green');
		this.appSession = appSession;
	}

	trackPage() {
		const title = this.getPageTitle();
		const url = this.getPageUrl();

		this.trackPageInternal(url, title);
	}

	trackModel(modelId, title) {
		let url = this.getPageUrl();

		switch (modelId) {
			case 'form.header.studySelection':
				url = 'studyselection';
				break;
			case 'form.header.changePassword':
				url = 'changePassword';
				break;
			default:
				url = `${url}/${encodeURIComponent(modelId)}`;
				break;
		}

		this.trackPageInternal(url, title);
	}

	trackEventWithTitle(category, action, label, value) {
		this.trackEvent(category, action, label, value, undefined, undefined, true);
	}

	trackEvent(category, action, label, value, noninteraction, custom, hidePageUrl) {
		if (!hidePageUrl) {
			category += `::${this.getPageUrl()}`;
		}
		this.$log.debug(`tracker event -> category:${category}, action:${action}`);
		this.setCustomSettings();
		this.analytics.trackEvent(category, action, label, value, noninteraction, custom);
	}

	//private methods
	setCustomSettings() {
		const [protocol, userType, maxConstraintLevel] = this.getSettings();

		this.setSettings(protocol, userType, maxConstraintLevel);
	}

	getPageTitle() {
		return this.getPageUrl();
	}

	getPageUrl() {
		return this.analytics.getUrl();
	}

	getSettings() {
		let maxConstraintLevel = 'Unknown',
			userType = 'Unknown',
			protocol = 'Unknown';

		try {
			const info = this.appSession.getTrackerInfo();

			if (info && info.study) {
				protocol = info.study.name;
				if (info.userIsInternal && info.userIsInternal === true) {
					userType = 'Internal';
				} else {
					userType = 'External';
				}

				if (info.userAccessLevel) {
					maxConstraintLevel = this.getUserConstraintLevel(info.userAccessLevel);
				}
			}
			this.$log.debug(`tracker Info:${angular.toJson(info)}`);
			this.$log.debug(`[protocol:${protocol}, userType:${userType}, maxConstraintLevel:${maxConstraintLevel}]`);
		} catch (err) {
			this.$log.debug(`getSettings errors:${err}`);
		}

		return [protocol, userType, maxConstraintLevel];
	}

	setSettings(protocol, userType, maxConstraintLevel) {
		if (protocol) {
			this.analytics.set('dimension1', protocol);
		}
		if (userType) {
			this.analytics.set('dimension2', userType);
		}

		if (maxConstraintLevel) {
			this.analytics.set('dimension3', maxConstraintLevel);
		}
	}

	getUserConstraintLevel(access) {
		let result = 'None';

		if (!access) {
			return result;
		}

		if (access.global) {
			result = 'Global';
		} else if (access.study) {
			result = 'Protocol';
		} else if (access.country) {
			result = 'Country';
		} else if (access.site) {
			result = 'Site';
		}

		return result;
	}

	trackPageInternal(url, title) {
		this.setCustomSettings();
		this.$log.debug(`tracker page ->  url:${url}, title:${title}`);
		this.analytics.trackPage(url, title);
	}

	static id() {
		return 'analyticsService';
	}
}
