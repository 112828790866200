import templateUrl from './reportReview.html';
import controller from './reportReview.controller';

const reportReview = {
	restrict: 'E',
	templateUrl,
	controller
};

export default reportReview;
