/*
* Shows loading indicator.
* */

import templateUrl from './loadingIndicator.html';
import controller from './loadingIndicator.controller';
import './loadingIndicator.scss';

const loadingIndicator = {
	bindings: {
		name: '@?',
		group: '@?',
		show: '<?',
		onLoaded: '&?',
		shadow: '<?',
		listenRouter: '<?',
		backdropContrast: '<?',
		standalone: '<?'
	},
	templateUrl,
	controller
};

export default loadingIndicator;

