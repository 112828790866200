/*
 * gridController
 * */

class GridController {
	constructor($log, sortingService, filtersService, appConfig) {
		'ngInject';
		this.$log = $log;
		this.sortingService = sortingService;
		this.filtersService = filtersService;
		this.appConfig = appConfig;
		this.filtersUri = '';
		this.sortingUri = '';
		this.paginationUri = '';
		this.defaultPaginationUri = `&$skip=0&$top=${this.appConfig.oDataQueryMaxItemsNumber}`;
	}

	$onInit() {
		this.$log = this.$log.getInstance('GridController');
		this.$log.debug('loaded');
		const getQueryUri = this.getQueryUri.bind(this);

		this.api = {
			getQueryUri
		};
		this.componentReady && this.componentReady({ $API: this.api });
	}

	onFilterChange(uriBuilder, filters) {
		this.filtersUri = uriBuilder.toUri();
		if (this.saveState) {
			this.filtersService.setDataToUrl(uriBuilder.buildWhere());
		}
		this.checkUrlData();
		this.paginationUri = this.defaultPaginationUri;

		const uri = this.getQueryUri();

		this.onFilteringChange({ uri, filters });
	}

	onSortChange(uriBuilder) {
		this.sortingUri = uriBuilder.toUri();
		if (this.saveState) {
			this.sortingService.setDataToUrl(uriBuilder.buildOrderBy());
		}
		this.paginationUri = this.defaultPaginationUri;

		const uri = this.getQueryUri();

		this.onSortingChange({ uri });
	}

	onPaginationLoad(paginationUri) {
		this.paginationUri = paginationUri;
		const queryUri = this.getQueryUri();

		return this.onPaginationMoreLoad && this.onPaginationMoreLoad({ uri: queryUri });
	}

	onPaginationReload(paginationUri) {
		this.paginationUri = paginationUri;
		const queryUri = this.getQueryUri();

		return this.onPaginationRefresh && this.onPaginationRefresh({ uri: queryUri });
	}
	/*
	* Checks if there are filtering and sorting data in the url and initializes it
	* */
	checkUrlData() {
		if (this.saveState) {
			if (!this.filtersUri) {
				this.filtersUri = this.filtersService.getDataFromUri();
			}

			if (!this.sortingUri) {
				this.sortingUri = this.sortingService.getDataFromUri();
			}
		}
	}

	/*
	 * Gets combined query uri data, combination depends on parameters
	 * by default returns all (sorting, filtering, pagination) parameters if they exist
	 * */
	getQueryUri(excludeSorting, excludeFiltering, excludePagination) {
		let uri = '';

		this.checkUrlData();

		if (this.sortingUri && !excludeSorting) {
			uri = `${this.sortingUri}`;
		}

		if (this.filtersUri && !excludeFiltering) {
			uri = (uri) ? `${uri}&${this.filtersUri}` : `${this.filtersUri}`;
		}

		if (this.paginationUri && !excludePagination) {
			uri = (uri) ? `${uri}&${this.paginationUri}` : `${this.paginationUri}`;
		}
		return uri;
	}
}

export default GridController;
