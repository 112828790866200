/*
 * baseInputController
 * */

class BaseInputController {
	constructor($log, $element) {
		'ngInject';
		this.$log = $log;
		this.$element = $element;
	}

	$onInit() {
		this.$log = this.$log.getInstance('BaseInputController');
		this.$log.debug('loaded');
	}

	$postLink() {
		this.$element.find('input').addClass('es-input');

		this.ngModel = this.$element.find('input').controller('ngModel');
		const $API = {
			ngModel: this.ngModel
		};

		this.componentReady && this.componentReady({ $API });
	}
}

export default BaseInputController;
