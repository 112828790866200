(function () {
	'use strict';

	angular.module('portalApp')
		.directive('ertQrCode', QrCode);


	/** @ngInject **/
	function QrCode(qrCodeService) {
		return {
			restrict: 'E',
			template: '<div></div>',
			scope: {
				text: '=',
				width: '<',
				height: '<'
			},
			link: function (scope, el, attrs) {
				qrCodeService.make(el[0], scope.text, scope.width, scope.height);
			}
		};
	}

})();
