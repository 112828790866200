(function () {
	'use strict';

	angular
		.module('portalApp')
		.filter('studyConfigRoleFilter', StudyConfigRoleFilter)
		.controller('StudyConfigCtrl', StudyConfigController);

	function StudyConfigRoleFilter() {
		return (roles, userRoles) => {
			let str = '<ul class="es-fluid-data"><li>';

			if (roles && userRoles) {
				for (let i = 0; i < roles.length; i++) {
					if (userRoles.indexOf(roles[i]) >= 0 || roles[i] === 'Everyone') {
						roles[i] = `<strong>${roles[i]}</strong>`;
					}
				}
				str += roles.join('</li><li>');
			}
			str += '</li></ul>';
			return str;
		};
	}

	/*
	 * @ngInject
	 */
	function StudyConfigController(adminService, $log, appI18n, $filter, loadingIndicatorService) {
		const vm = this;

		vm.studyConfig = null;
		vm.errorMessage = '';
		vm.isUserRole = isUserRole;

		$log = $log.getInstance('StudyConfigCtrl');
		$log.debug('loaded');


		function activate() {
			appI18n.translate('admin.study-configuration-title').then((translation) => {
				adminService.setTitle(translation);
			});

			loadingIndicatorService.show();

			adminService.getStudyConfiguration()
				.then((response) => {
					vm.studyConfig = response.data;
				}, () => {
					vm.errorMessage = appI18n.translateImmediate('admin.error-loading');
					$log.error('get configuration failed with data');
				})
				.finally(() => {
					loadingIndicatorService.hide();
				});
		}

		function isUserRole(val) {
			if (vm.studyConfig) {
				const found = $filter('filter')(vm.studyConfig.roles, val, true);

				return found && found.length > 0;
			}
			return false;

		}

		activate();
	}
})();
