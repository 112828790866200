/*
* ngEnterDirective
* */

const ngEnterDirective = () => {
	return {
		restrict: 'A',
		link(scope, element, attrs) {
			element.on('keypress', (event) => {
				if (event.which === 13) {
					scope.$apply(() => {
						scope.$eval(attrs.ngEnter, { $event: event });
					});
					event.preventDefault();
				}
			});
		}
	};
};

export default ngEnterDirective;
