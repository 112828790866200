(function () {
	'use strict';

	angular.module('portalApp').controller('mainCtrl', MainCtrl);

	/**
	 * mainCtrl
	 *
	 * @ngInject
	 */
	function MainCtrl($log, $scope, appSession, globalMessageService) {

		$log = $log.getInstance('mainCtrl', 'color:#021b41; font-size:1em; background-color:#dedede;');
		$log.debug('loaded');
		const vm = this;

		vm.expertObservable = appSession.getExpertAvailabilityObservable();
		vm.expertSubscription = vm.expertObservable.subscribe((isExpertUnavailable) => {
			if (isExpertUnavailable && !globalMessageService.isActive()) {
				globalMessageService.show('header.expertUnavailableShort', 'header.expertUnavailable');
				return;
			}
			globalMessageService.hide();
		});


		$scope.$on('$destroy', () => {
			vm.expertSubscription.dispose();
		});
	}

})();
