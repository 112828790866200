import templateUrl from './ticketsTable.html';
import controller from './ticketsTable.controller';
import './ticketsTable.scss';

const ticketsTableDirective = () => {
	return {
		scope: {
			row: '<',
			columns: '<',
			rowClicked: '&',
			gridRowActions: '<'
		},
		require: {
			table: '^^tableWrap'
		},
		controller,
		controllerAs: '$ctrl',
		bindToController: true,
		restrict: 'E',
		templateUrl,
		link(/*scope, element, attr, ctr*/) {},
		replace: true
	};
};

export default ticketsTableDirective;
