class ertFileUploader {
	constructor($log, appI18n, FileUploader, appConfig) {
		'ngInject';

		this.log = $log;
		this.FileUploader = FileUploader;
		this.appConfig = appConfig;

		this.$API = null;
		this.fileItems = [];
		this.filesToDelete = [];
		this.addedFiles = [];
		this.errorMessage = '';
		this.uploadErrorMessage = '';
		this.title = this.title || appI18n.translateImmediate('cde-pft.addAttachment.title');

		this.saveErrorMessage = appI18n.translateImmediate('cde-pft.addAttachment.errors.saveErrorMessage');
		this.saveErrorMessageVirus = appI18n.translateImmediate('cde-pft.addAttachment.errors.saveErrorMessageVirus');
		this.invalidFileTypeMessage = appI18n.translateImmediate('cde-pft.addAttachment.errors.invalidFileTypeMessage');
		this.defaultMaxSize = appConfig.fileUpload.defaultFilters.fileMaxSize;
		const invalidFileSizeMsg = appI18n.translateImmediate('cde-pft.addAttachment.errors.invalidFileSizeMessage');

		this.invalidFileSizeMessage = `${invalidFileSizeMsg} ${(this.defaultMaxSize / 1048576).toFixed(0)} MB.`;
		this.errorMessageSavingFile = appI18n.translateImmediate('cde-pft.addAttachment.errors.saveInProgress');
	}

	$onInit() {

		this.log.debug('initialize ertFileUploader');

		this.defaultFilters = [{
			name: 'validFileType',
			fn: (item) => {
				let type = item.name.slice(item.name.lastIndexOf('.') + 1);

				type = (type || '').toLowerCase();
				return this.appConfig.fileUpload.defaultFilters.validFileType.indexOf(type) !== -1;
			},
			errorMsg: this.invalidFileTypeMessage
		}, {
			name: 'isValidFileSize',
			fn: (item) => {
				return item.size <= this.defaultMaxSize;
			},
			errorMsg: this.invalidFileSizeMessage
		}];

		// INIT FILE UPLOADER
		this.uploader = new (this.FileUploader)({
			url: this.uploadUrl,
			autoUpload: true,
			filters: _.unionBy(this.filters, this.defaultFilters, 'name')
		});

		// Fires after adding all the dragged or selected files to the queue.
		this.uploader.onAfterAddingFile = (fileItem) => {
			fileItem.isSaving = true;
			this.fileItems.unshift(fileItem);
		};

		// On file successfully uploaded
		this.uploader.onSuccessItem = (fileItem, response/*, status, headers*/) => {
			fileItem.file.url = response.url;
			fileItem.isSaving = false;
			this.addedFiles.push(fileItem.file);
			this.onSuccessItem({ addedFile: fileItem.file, validFiles: this.$API.getValidItems() });
		};

		// On upload error
		this.uploader.onErrorItem = (fileItem, response, status, headers) => {
			fileItem.isSaving = false;
			if (response && response.indexOf && response.indexOf('Virus') >= 0) {
				fileItem.file.errorMessage = this.saveErrorMessageVirus;
			} else {
				fileItem.file.errorMessage = this.saveErrorMessage;
			}

			this.log.log('Error status:', response);
			this.log.debug('onErrorItem', fileItem, response, status, headers);
		};

		// Ex. when filter prevents file to be added
		this.uploader.onWhenAddingFileFailed =
			(file /*{File|FileLikeObject}*/, filter /*options*/) => {
				file.errorMessage = filter.errorMsg;
				this.fileItems.unshift({ file });
			};

		this.$API = {
			getValidItems: () => {
				return _.filter(this.fileItems, (item) => {
					return _.has(item, 'file.url') && item.file.url.length;
				});
			},
			setItems: (files) => {
				this.fileItems = files.map((file) => {
					if (!file.name || !file.url) {
						throw Error('ertFileUploader:fileItems:The file object should have name and url fields');
					}
					// convert files to fileItem instances
					const fi = new (this.FileUploader.FileItem)(this.uploader, file, null);

					fi.file.url = file.url || null;
					fi.file.comments = file.comments || null;
					fi.file.info = file.info;
					fi.file.userIsInternal = file.userIsInternal;
					fi.canDelete = file.canDelete;
					fi.canComment = file.canComment;

					return fi;
				});
			},
			getItemsToDelete: () => {
				return this.filesToDelete;
			},
			getAddedFiles: () => {
				return this.addedFiles;
			},
			isUploading: () => {
				return _.some(this.fileItems, (item) => {
					return item.isSaving || item.isUploading;
				});
			},
			hasFilesInError: () => {
				return _.some(this.fileItems, (item) => {
					return item.isError || item.file && item.file.errorMessge;
				});
			}
		};

		// publish api when component is ready
		this.componentReady({ $API: this.$API });
	}

	removeFile(item) {
		// remove file item from array (affects UI)
		const fileIdx = this.fileItems.indexOf(item);
		const removedItem = this.fileItems.splice(fileIdx, 1);

		// the item to remove is a valid file item (i.e. has an url)
		if (_.has(item, 'file.url') && item.file.url.length > 0) {

			const removedFile = removedItem.map((f) => {
				return f.file;
			});

			// make changes in files to delete array
			this.filesToDelete = this.filesToDelete.concat(removedFile);

			// make changes in added files array
			if (removedFile.length) {
				const i = this.addedFiles.indexOf(removedFile[0]);

				// shouldn't remove item if not found in addedFiles collection,
				// this could happen for old upload attachments
				if (i !== -1) {
					this.addedFiles.splice(i, 1);
				}
			}

			this.onDeleteFile({ file: item.file });
		}
	}

	openFile(url, name) {
		this.onOpenFile({ url, name });
		this.log.debug(`open file ${url}`);
	}

	showUploadBtn() {
		return !this.isReadOnly() && (angular.isUndefined(this.canUpload)) ? true : this.canUpload;
	}

	isReadOnly() {
		return this.readOnly;
	}
}
export default ertFileUploader;

