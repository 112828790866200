import sitesComponent from './sites.component';
import siteInfoPopupModule from './siteInfoPopup/index';

const sitesModule = angular.module('app.components.clinicalData.sites', [
	siteInfoPopupModule
])
	.component('clinicalDataSites', sitesComponent)
	.name;

export default sitesModule;
