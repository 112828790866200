/*
 * studyMetricsWidgetController
 * */

class StudyMetricsWidgetController {
	// TODO: implement expert unavaliable buisenes logic
	constructor($log, dashboardService, appI18n) {
		'ngInject';
		this.$log = $log;
		this.dashboardService = dashboardService;
		this.appI18n = appI18n;
		this.expertAvailable = angular.isUndefined(this.expertAvailable) || this.expertAvailable;
	}

	$onInit() {
		this.$log = this.$log.getInstance('StudyMetricsWidgetController');
		this.$log.debug('loaded');

		this.isLoading = true;
		this.dashboardService.getStudyMetrics().then((data) => {
			this.metrics = data;
			this.isLoading = false;
		}, (/*data*/) => {
			this.isLoading = false;
			this.errorMessage = this.appI18n.translateImmediate('dashboard.loading-error');
		});
	}

	$onChanges(changesObj) {
		if (changesObj.expertAvailable && changesObj.expertAvailable.currentValue) {
			this.widgetControls = this.getWidgetControls();
		} else {
			this.widgetControls = [];
		}
	}

	getWidgetControls() {
		return [{
			type: 'button',
			iconClass: '',
			displayName: 'dashboard.details',
			action: () => {
				if (!this.expertAvailable) {
					//TODO: Show notification MPORT-3604
					return;
				}
				this.openDetailsPopup();
			}
		}];
	}

	openDetailsPopup() {
		this.metricsDetailsPopup.open();
	}

	getMetricsDetalsPopupApi($API) {
		this.metricsDetailsPopup = $API;
	}
}

export default StudyMetricsWidgetController;
