import templateUrl from './confirmationPopup.html';
import controller from './confirmationPopup.controller';
import './confirmationPopup.scss';

const confirmationPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default confirmationPopupComponent;
