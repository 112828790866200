/*
 * visitOverviewWidgetController
 * */
import { CELL_DATA_TYPES } from '../../../common/table/tableWrap/cellDataType';

class VisitOverviewWidgetController {
	constructor($log, appI18n, dashboardService, configurationService, visitOverviewWidgetService) {
		'ngInject';
		this.$log = $log;
		this.appI18n = appI18n;
		this.dashboardService = dashboardService;
		this.configurationService = configurationService;
		this.visitOverviewWidgetService = visitOverviewWidgetService;
		this.visitOverviewRecent = [];
		this.visitOverviewPlanned = [];
	}

	$onInit() {
		this.$log = this.$log.getInstance('VisitOverviewWidgetController');
		this.$log.debug('loaded');

		this.headers = this.getTableHeaders();

		this.isLoading = true;
		this.dashboardService.getVisitOverview(true).then((data) => {
			this.visitOverviewRecent = (data.length) ? data : [];
			this.isLoading = false;
		}, (error) => {
			this.isLoading = false;
			this.recentVisitsErrorMessage = this.appI18n.translateImmediate('dashboard.loading-error');
			this.$log.error(`error getting dashboard visit overview: ${error}`);
		});

		this.configurationService.getStudySettings().then((data) => {
			this.isCalendarVisible = !data.hideVisitCalendar;
			if (!data.hasEasyOnePro) {
				this.dashboardService.getVisitOverview(false).then((data) => {
					this.visitOverviewPlanned = (data.length) ? data : [];
				}, (error) => {
					this.$log.error(`error getting dashboard visit overview: ${error}`);
					this.planedVisitsErrorMessage = this.appI18n.translateImmediate('dashboard.loading-error');
				});
			}
		});
	}

	$onDestroy() {
		this.visitOverviewWidgetService.unregisterVisitCalendarPopupApi();
	}

	getTableHeaders() {
		return [{
			value: '',
			type: '',
			hideSorting: true
		}, {
			value: 'dashboard.visit-overview.site-pi',
			type: CELL_DATA_TYPES.TEXT

		}, {
			value: 'app.common.subjectKey',
			type: CELL_DATA_TYPES.TEXT
		}, {
			value: 'dashboard.visit-overview.visit-name',
			type: CELL_DATA_TYPES.TEXT
		}, {
			value: 'dashboard.visit-overview.visit-date',
			type: CELL_DATA_TYPES.TEXT
		}];
	}

	getCalendarPopupApi($API) {
		this.visitOverviewWidgetService.registerVisitCalendarPopupApi($API);
	}
}

export default VisitOverviewWidgetController;
