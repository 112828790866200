(function () {
    'use strict';

    angular.module('portalApp').run(FormlyConfig);

    /**
     * @ngInject
     */
    function FormlyConfig($log, formlyConfig, formlyValidationMessages, appI18n, appConfig) {
        $log = $log.getInstance("formlyConfig");
        $log.debug("loaded");

        if (typeof console === 'undefined' || !console.warn || !console.warn.apply) {
            // formly tries to warn using console, without console, it better should not try
            formlyConfig.disableWarnings = true;
        }
        //formlyValidationMessages.addTemplateOptionValueMessage(name, prop, prefix, suffix, alternate);
        //formlyValidationMessages.addStringMessage('number', '$viewValue + " is not a valid number"');


        formlyValidationMessages.addStringMessage('required', appI18n.translateImmediate('app.messages.requiredField'));
        formlyValidationMessages.addStringMessage('mask', appI18n.translateImmediate('app.messages.maskField'));
        formlyValidationMessages.addTemplateOptionValueMessage('pattern', 'patternValidationMessage', '', '', appI18n.translateImmediate('app.messages.patternField'));
        formlyValidationMessages.messages.email = appI18n.translateImmediate('app.messages.emailField');
        formlyValidationMessages.addTemplateOptionValueMessage('max', 'max', appI18n.translateImmediate('app.messages.maxField'), '', 'Too big');
        formlyValidationMessages.addTemplateOptionValueMessage('min', 'min', appI18n.translateImmediate('app.messages.minField'), '', 'Too small');
        formlyValidationMessages.addStringMessage('hoursMinutesOnly', appI18n.translateImmediate('app.messages.hoursMinutesOnly'));
        formlyValidationMessages.addStringMessage('hoursMinutesSecondsOnly', appI18n.translateImmediate('app.messages.hoursMinutesSecondsOnly'));
        formlyValidationMessages.addStringMessage('dateFormatForPicker', appI18n.translateImmediate('app.messages.dateFormatForPicker'));
        formlyValidationMessages.addStringMessage('dateRange', appI18n.translateImmediate('app.messages.dateRange'));
        formlyValidationMessages.addStringMessage('allFieldsOrNone', appI18n.translateImmediate('app.messages.allFieldsOrNone'));
        formlyValidationMessages.addStringMessage('fieldComparison', appI18n.translateImmediate('app.messages.fieldComparison'));
        formlyValidationMessages.addStringMessage('allowDecimalsAndNA', appI18n.translateImmediate('app.messages.allowDecimalsAndNA'));
        formlyValidationMessages.addStringMessage('dobInPast', appI18n.translateImmediate('app.messages.dobInPast'));

        formlyConfig.setType({
            name: 'inputInline',
            extends: 'input',
            template: '<input class="form-control es-input" ng-model="model[options.key]" ng-class="{\'-es-invalid\': showError}" />',
            wrapper: ['labelInline', 'formGroupInline'],
            defaultOptions: {
                templateOptions: {
                    labelClass: '',
                    controlDivClass: ''
                }
            }
        });

        formlyConfig.setType({
            name: 'emailInline',
            extends: 'inputInline',
            defaultOptions: {
                templateOptions: {
                    patternValidationMessage: appI18n.translateImmediate('app.messages.emailPatternField'),
                    pattern: '[a-zA-Z0-9\\._\\-\\+]+@[a-zA-Z0-9\\.\\-_]+\\.[a-z0-9A-Z]{2,10}'
                }
            }
        });

        formlyConfig.setType({
            name: 'restrictedInputInline',
            extends: 'inputInline',
            defaultOptions: {
                templateOptions: {
                    patternValidationMessage: appI18n.translateImmediate('app.messages.restrictedInputPatternField'),
                    pattern: '{restrictedInputEscaped}'.supplant(appConfig.constants.patterns)
                }
            }
        });

        formlyConfig.setType({
            name: 'datePickerInline',
            extends: 'inputInline',
            templateUrl: 'scripts/directives/formly/datepicker.html'
        });

        formlyConfig.setType({
            name: 'maskedInput',
            extends: 'inputInline',
            template: '<input class="es-input form-control" ng-model="model[options.key]" ng-class="{\'-es-invalid\': showError}" />',
            defaultOptions: {
                ngModelAttrs: {
                    mask: {
                        attribute: 'ui-mask'
                    },
                    maskPlaceholder: {
                        attribute: 'ui-mask-placeholder'
                    },
                    uiOptions: { attribute: 'ui-options' }
                },
                templateOptions: {
                    maskPlaceholder: '',
                    uiOptions: '{clearOnBlur : false,  eventsToHandle: ["keyup", "click", "focus"]}'
                }
            }
        });


        formlyConfig.setType({
            name: 'select',
            templateUrl: 'scripts/directives/formly/selectWhite.html',
            overwriteOk: true,
        });

        formlyConfig.setType({
            name: 'selectEx',
            extends: 'select',
            wrapper: ['label', 'formGroup']
        });

        formlyConfig.setType({
            name: 'selectInline',
            extends: 'select',
            wrapper: ['labelInline', 'formGroupInline'],
            defaultOptions: {
                templateOptions: {
                    labelClass: '',
                    controlDivClass: ''
                }
            },
            controller: /* @ngInject */ function ($scope) {
                $scope.getLabel = function (value) {
                    if (value !== null && value !== '') {
                        var matched = $scope.to.options.filter(function (item) {
                            return item[$scope.to.valueProp || 'value'] === value;
                        });
                        if (matched.length > 0) {
                            return matched[0][$scope.to.labelProp || 'name'];
                        }
                    }
                    return '';
                };
            }
        });

        formlyConfig.setType({
            name: 'esSgSelect',
            templateUrl: 'scripts/directives/formly/esSgSelect.html',
            wrapper: ['esMessages', 'esLabel', 'esControl'],
            defaultOptions: {
                templateOptions: {

                }
            },
            controller: /* @ngInject */ function ($scope) {
                $scope.getLabel = function (value) {
                    if (value !== null && value !== '') {
                        var matched = $scope.to.options.filter(function (item) {
                            return item[$scope.to.valueProp || 'value'] === value;
                        });
                        if (matched.length > 0) {
                            return matched[0][$scope.to.labelProp || 'name'];
                        }
                    }
                    return '';
                };
            }
        });

        formlyConfig.setType({
            name: 'esSgInput',
            templateUrl: 'scripts/directives/formly/esSgInput.html',
            wrapper: ['esMessages', 'esLabel', 'esControl']
        });

        formlyConfig.setType({
            name: 'selectWithLoadIndicator',
            extends: 'selectInline',
            wrapper: ['labelInlineLoading', 'formGroupInline']
        });

        formlyConfig.setType({
            name: 'inputEx',
            extends: 'input',
            template: '<input class="form-control" ng-model="model[options.key]"  ng-class="{\'-es-invalid\': showError}" />',
            wrapper: ['label', 'formGroup']
        });


        formlyConfig.setType({
            name: 'checkboxEx',
            extends: 'checkbox',
            templateUrl: 'scripts/directives/formly/checkboxTemplate.html'
        });

        formlyConfig.setType({
            name: 'checkboxInline',
            extends: 'checkbox',
            templateUrl: 'scripts/directives/formly/checkboxInline.html',
            wrapper: ['labelInline', 'formGroupInline'],
            defaultOptions: {
                templateOptions: {
                    labelClass: '',
                    controlDivClass: ''
                }
            }
        });

        formlyConfig.setType({
            name: 'checkList',
            templateUrl: 'scripts/directives/formly/checkList.html',
            wrapper: ['labelInline', 'formGroup'],
            defaultOptions: {
                templateOptions: {
                    controlClass: 'checkbox',
					labelClass: '',
					controlDivClass: ''
                }
            }
        });

        formlyConfig.setType({
            name: 'radioEx',
            extends: 'radio',
            templateUrl: 'scripts/directives/formly/radioTemplate.html',
            wrapper: ['label', 'formGroup'],
            defaultOptions: {
                templateOptions: {
                    controlClass: 'radio-inline'
                }
            }
        });

        formlyConfig.setType({
            name: 'radioInlineMessage',
            extends: 'radioEx',
            templateUrl: 'scripts/directives/formly/radioInlineMessage.html',
            wrapper: ['labelInline', 'formGroupInline'],
            defaultOptions: {
                templateOptions: {
                    controlClass: ''
                }
            }
        });

        formlyConfig.setType({
            name: 'radioInline',
            extends: 'radioEx',
            wrapper: ['labelInline', 'formGroupInline'],
            defaultOptions: {
                templateOptions: {
                    labelClass: '',
                    controlDivClass: ''
                }
            }
        });
        formlyConfig.setType({
            name: 'esInput',
            templateUrl: 'scripts/directives/formly/esInput.html',
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            },
            defaultOptions: {
                ngModelAttrs: {
                    mask: {
                        attribute: 'ui-mask'
                    },
                    maskPlaceholder: {
                        attribute: 'ui-mask-placeholder'
                    },
                    modelViewValue: {
                        attribute: 'model-view-value'
                    },
                    allowInvalidValue: {
                        attribute: 'allow-invalid-value'
                    }
                },
                templateOptions: {
                    maskPlaceholder: '',
                    modelViewValue: false,
                    allowInvalidValue: false
                }
            },
            controller: ['$scope', function ($scope) {
                $scope.options.data.getValidationMessage = getValidationMessage;

                function getValidationMessage(key) {
                    var message = $scope.options.validation.messages[key];
                    if (message) {
                        return message($scope.fc.$viewValue, $scope.fc.$modelValue, $scope);
                    }
                }
            }]
        });
        formlyConfig.setType({
            name: 'esNarrowInput',
            templateUrl: 'scripts/directives/formly/esNarrowInput.html',
            defaultOptions: {
                ngModelAttrs: {
                    mask: {
                        attribute: 'ui-mask'
                    },
                    maskPlaceholder: {
                        attribute: 'ui-mask-placeholder'
                    },
                    modelViewValue: {
                        attribute: 'model-view-value'
                    },
                    allowInvalidValue: {
                        attribute: 'allow-invalid-value'
                    }
                },
                templateOptions: {
                    maskPlaceholder: '',
                    modelViewValue: false,
                    allowInvalidValue: false
                }
            }
        });
        formlyConfig.setType({
            name: 'sgInput',
            templateUrl: 'scripts/directives/formly/sgInput.html',
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            },
            defaultOptions: {
                ngModelAttrs: {
                    mask: {
                        attribute: 'ui-mask'
                    },
                    maskPlaceholder: {
                        attribute: 'ui-mask-placeholder'
                    },
                    modelViewValue: {
                        attribute: 'model-view-value'
                    },
                    allowInvalidValue: {
                        attribute: 'allow-invalid-value'
                    }
                },
                templateOptions: {
                    maskPlaceholder: '',
                    modelViewValue: false,
                    allowInvalidValue: false
                }
            },
            controller: ['$scope', function ($scope) {
                $scope.options.data.getValidationMessage = getValidationMessage;

                function getValidationMessage(key) {
                    var message = $scope.options.validation.messages[key];
                    if (message) {
                        return message($scope.fc.$viewValue, $scope.fc.$modelValue, $scope);
                    }
                }
            }]
        });
        formlyConfig.setType({
            name: 'esCheckbox',
            wrapper: ['esControl'],
            templateUrl: 'scripts/directives/formly/esCheckbox.html',
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            }
        });
        formlyConfig.setType({
            name: 'esSelect',
            templateUrl: 'scripts/directives/formly/esSelect.html',
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            },
            controller: ['$scope', function ($scope) {
                $scope.options.data.getValidationMessage = getValidationMessage;

                function getValidationMessage(key) {
                    var message = $scope.options.validation.messages[key];
                    if (message) {
                        return message($scope.fc.$viewValue, $scope.fc.$modelValue, $scope);
                    }
                }
            }]
        });
        formlyConfig.setType({
            name: 'esAutoComplete',
            templateUrl: 'scripts/directives/formly/esAutoComplete.html',
            wrapper: ['labelInline', 'formGroupInline'],
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            },
            controller: ['$scope', '$document', function ($scope, $document) {
                $scope.filteredOptions = [];
                $scope.selectedItems = [];
                $scope.showChip = false;
                $scope.selectedChips = [];
                $scope.showDiv = false;
                $scope.filterOptions = function () {
                    $scope.showDiv = true;
                    let options = $scope.to.options.slice();
                    $scope.filteredOptions = [];
                    const searchKey = $scope.model[$scope.options.key];
                    if (!searchKey || (searchKey && searchKey.length < 1)) return;
                    $scope.filteredOptions = options.filter(function (option) {
                        option.selected = false;
                        resetFilteredOptionForSelection(option);
                        return option.label && option.label.toLowerCase().includes(searchKey.toLowerCase());
                    });
                };

                function resetFilteredOptionForSelection(option) {
                    $scope.selectedItems.forEach(element => {
                        if (option.value == element.value) {
                            option.selected = true;
                        }
                    });
                }

                $scope.toggleSelection = function (option) {
                    if (option.selected) {
                        $scope.selectedItems.push(option);
                    } else {
                        var index = $scope.selectedItems.indexOf(option);
                        if (index > -1) {
                            $scope.selectedItems.splice(index, 1);
                        }
                    }
                    updateCountriesModel();
                    validateCountry();
                }

                $scope.singleSelection = function (option) {
                    $scope.filteredOptions.forEach(element => {
                        element.selected = false;
                    });
                    $scope.selectedItems = [];
                    option.selected = true;
                    $scope.selectedItems.push(option);
                    updateCountriesModel();
                    validateCountry();
                }

                function validateCountry() {
                    $scope.to.countryValidaion.isValid = $scope.selectedItems.length > 0;
                }

                $scope.removeSelected = function (option) {
                    $scope.selectedItems.forEach((item, index) => {
                        if (item.value === option.value) {
                            $scope.selectedItems.splice(index, 1);
                        }
                    });

                    var index = $scope.filteredOptions.indexOf(option);
                    if (index > -1) {
                        $scope.selectedItems.filteredOptions[index].selected = false;
                    }

                    $scope.prepareAutoCompleteChips();
                    validateCountry();
                }

                $scope.clearAllOptions = function ($event) {
                    $scope.to.options.forEach(option => {
                        option.selected = ($event.target?.checked);
                        $scope.toggleSelection(option);
                    });
                    validateCountry();
                };

                $scope.prepareAutoCompleteChips = function() {
                    $scope.selectedChips = [];
                    if ($scope.selectedItems.length > 0) {
                        $scope.showChip = true;
                        const oldLabel = $scope.selectedItems[0].label;
                        const newLabel = (oldLabel.length > 10 && $scope.selectedItems.length > 1)
                            ? oldLabel.substring(0, 10) + '...'
                            : oldLabel;
                        createAutoCompleteChip(newLabel, $scope.selectedItems[0].selected, $scope.selectedItems[0].value);
                        if ($scope.selectedItems.length > 1) {
                            createAutoCompleteChip($scope.selectedItems.length - 1 + ' more', false, '');
                        }
                    } else {
                        $scope.showChip = false;
                    }
                    updateCountriesModel();
                }

                function updateCountriesModel() {
                    $scope.model.selectedCountries = [];
                    $scope.model.selectedCountries = $scope.selectedItems.slice();
                }

                function createAutoCompleteChip(lable, isSelected, value) {
                    $scope.selectedChips.push(
                        {
                            label: lable,
                            selected: isSelected,
                            value: value
                        });
                }

                function closeAutoCompleteBox() {
                    $scope.filteredOptions = [];
                    $scope.prepareAutoCompleteChips();
                }

                $scope.isTypingStart = function () {
					if (!$scope.to.disabled) {
						$scope.showChip = false;
						$scope.filteredOptions = $scope.selectedItems.slice();
						$scope.showDiv = true;
					}
                }

                function onClick(event) {
                    var targetElement = event.target;
                    var divElement = document.querySelector('.options-wrapper');
                    if (divElement && !divElement.contains(targetElement) && (!targetElement.classList.contains('autocomplete-textbox'))) {
                        $scope.$apply(function () {
                            $scope.showDiv = false;
                            closeAutoCompleteBox();
                        });
                    }
                }

                $document.on('click', onClick);

                $scope.$on('$destroy', function () {
                    $document.off('click', onClick);
                });
            }]
        });
        formlyConfig.setType({
            name: 'esSelectInline',
            templateUrl: 'scripts/directives/formly/esSelectInline.html',
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            },
            controller: ['$scope', function ($scope) {
                $scope.options.data.getValidationMessage = getValidationMessage;

                function getValidationMessage(key) {
                    var message = $scope.options.validation.messages[key];
                    if (message) {
                        return message($scope.fc.$viewValue, $scope.fc.$modelValue, $scope);
                    }
                }
            }]
        });
        formlyConfig.setType({
            name: 'sgSelect',
            templateUrl: 'scripts/directives/formly/sgSelect.html',
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            }
        });
        formlyConfig.setType({
            name: 'esTextArea',
            templateUrl: 'scripts/directives/formly/esTextArea.html',
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            }
        });

        formlyConfig.setType({
            name: 'esSgTextAreaAligned',
            wrapper: ['esMessages', 'esControl'],
            templateUrl: 'scripts/directives/formly/esSgTextArea.html',
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            }
        });
        formlyConfig.setType({
            name: 'esSgTextArea',
            wrapper: ['esMessages', 'esControlUnaligned'],
            templateUrl: 'scripts/directives/formly/esSgTextArea.html',
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            }
        });

        formlyConfig.setType({
            name: 'esDatePickerInput',
            templateUrl: 'scripts/directives/formly/esDatePickerInput.html',
            apiCheck: function (check) {
                return {
                    templateOptions: {
                        id: check.string
                    }
                };
            }
        });

        formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'fc.$touched || form.$submitted || form.showValidation';
        formlyConfig.extras.ngModelAttrsManipulatorPreferUnbound = true;
        //DEPRECATED: esColumnControlHintWrapper use sgControlWrap
        formlyConfig.setWrapper({
            name: 'esColumnControlHintWrap',
            templateUrl: 'scripts/directives/formly/esColumnControlHintWrapper.html'
        });
        //DEPRECATED: esColumnControlWrapper use sgControlWrap
        formlyConfig.setWrapper({
            name: 'esColumnControlWrap',
            templateUrl: 'scripts/directives/formly/esColumnControlWrapper.html'
        });
        formlyConfig.setWrapper({
            name: 'esColumnMultiControlWrap',
            templateUrl: 'scripts/directives/formly/esColumnMultiControlWrapper.html'
        });
        formlyConfig.setWrapper({
            name: 'sgControlWrap',
            templateUrl: 'scripts/directives/formly/sgControlWrapper.html'
        });
        formlyConfig.setWrapper({
            name: 'esColumnsWrap',
            templateUrl: 'scripts/directives/formly/esColumnsWrapper.html'
        });
        formlyConfig.setWrapper({
            name: 'esColumnWrap',
            templateUrl: 'scripts/directives/formly/esColumnWrapper.html'
        });
        formlyConfig.setWrapper({
            name: 'esLabel',
            templateUrl: 'scripts/directives/formly/esLabel.html'
        });
        formlyConfig.setWrapper({
            name: 'esControl',
            templateUrl: 'scripts/directives/formly/esControl.html'
        });
        formlyConfig.setWrapper({
            name: 'esControlUnaligned',
            templateUrl: 'scripts/directives/formly/esControlUnaligned.html'
        });
        formlyConfig.setWrapper({
            name: 'esMessages',
            templateUrl: 'scripts/directives/formly/esMessages.html'
        });
        formlyConfig.setWrapper({
            name: 'spiroColWrapper',
            templateUrl: 'scripts/directives/formly/spiroColWrapper.html'
        });
        formlyConfig.setWrapper({
            name: 'fieldGroupWrapper',
            templateUrl: 'scripts/directives/formly/fieldGroupWrapper.html'
        });
        formlyConfig.setWrapper({
            name: 'panel',
            templateUrl: 'scripts/directives/formly/panel.html'
        });

        formlyConfig.setWrapper({
            name: 'twoColumn',
            templateUrl: 'scripts/directives/formly/twoColumn.html'
        });


        formlyConfig.setWrapper({
            name: 'loading',
            templateUrl: 'scripts/directives/formly/loading.html'
        });
        formlyConfig.setWrapper({
            name: 'labelInline',
            templateUrl: 'scripts/directives/formly/labelInline.html'
        });

        formlyConfig.setWrapper({
            name: 'labelInlineLoading',
            templateUrl: 'scripts/directives/formly/labelInlineLoading.html'
        });

        formlyConfig.setWrapper({
            name: 'label',
            templateUrl: 'scripts/directives/formly/label.html'
        });

        formlyConfig.setWrapper({
            name: 'formGroup',
            templateUrl: 'scripts/directives/formly/formGroup.html'
        });

        formlyConfig.setWrapper({
            name: 'formRow',
            templateUrl: 'scripts/directives/formly/formRow.html'
        });

        formlyConfig.setWrapper({
            name: 'formCol',
            templateUrl: 'scripts/directives/formly/formCol.html'
        });

        formlyConfig.setWrapper({
            name: 'formGroupInline',
            templateUrl: 'scripts/directives/formly/formGroupInline.html'
        });

        formlyConfig.setWrapper({
            name: 'validation',
            types: ['input', 'inputEx', 'inputInline', 'restrictedInputInline', 'emailInline', 'datePickerInline', 'maskedInput', 'checkbox', 'checkboxEx', 'checkboxInline', 'select', 'selectInline', 'textarea', 'radio', 'radioEx', 'radioInline', 'input-loader',
                'selectWithLoadIndicator', 'checkList', 'esAutoComplete'],
            templateUrl: 'scripts/directives/formly/error-messages.html'
        });
    }
})();
