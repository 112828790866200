export default class ScrollDirective {

	controller(scrollService) {
		'ngInject';
		this.scrollService = scrollService;
		this.restrict = 'A';
	}

	link(scope, element, attrs, ctrl) {

		ctrl.scrollService.getScrollSubject().subscribe((config) => {
			if (config.show) {
				element.addClass('-es-popup-opened');
				config.customClass && element.addClass(config.customClass);
			} else {
				element.removeClass('-es-popup-opened');
				config.customClass && element.removeClass(config.customClass);
			}
		});
	}
}
