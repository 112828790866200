(function() {

	'use strict';

	angular
		.module("portalApp")
		.service("dialogService", DialogService);

	/**
	 * @ngInject
	 */
	function DialogService($log, $q) {
		$log = $log.getInstance("DialogService");
		$log.debug("DialogService loaded");


		return {
			createConfirmHandler: createConfirmHandler,
			createProgressHandler: createProgressHandler
		};

		function createConfirmHandler() {
			return new ConfirmHandler();
		}

		function ConfirmHandler() {
			var self = this;
			self.showDialog = false;
			self.disableActions = false;
			self.actionResponse = {};
			self.validFunc = null;

			self.setValidFunc = function (func) {
				self.validFunc = func;
			};

			self.clearPromise = function () {
				self.resolve = undefined;
			};
			self.yesAction = function () {
				if (self.validFunc && !self.validFunc()) {
					return;
				}

				if (self.resolve) {
					self.disableActions = true;
					self.resolve(true);
				}
				self.clearPromise();
			};
			self.show = function () {
				self.disableActions = false;
				self.actionResponse = {};
				self.showDialog = true;
				return $q(function (resolve) {
					self.resolve = resolve;
				});
			};
			self.showSuccessMessages = function (messages) {
				self.disableActions = false;
				self.actionResponse.isSuccessful = true;
				self.actionResponse.messages = messages;
			};
			self.showErrorMessages = function (messages) {
				self.disableActions = false;
				self.actionResponse.isSuccessful = false;
				self.actionResponse.messages = messages;
			};

			//hide and noAction
			self.hide = function () {
				self.showDialog = false;
				self.actionResponse = {};
				if (self.resolve) {
					self.resolve(false);
					self.clearPromise();
				}
			};
		}

		function createProgressHandler() {
			var self = this;

			self.title = '    Please Wait';
			self.showDialog = false;
			self.progressText = '';
			self.actionResponse = {};
			self.closeAction = function () {
				self.showDialog = false;
				self.actionResponse = {};
			};
			self.show = function (progressText) {
				self.progressText = progressText;
				self.actionResponse = {};
				self.showDialog = true;
			};
			self.showSuccessMessages = function (messages) {
				self.actionResponse.isSuccessful = true;
				self.actionResponse.messages = messages;
			};
			self.showErrorMessages = function (messages) {
				self.actionResponse.isSuccessful = false;
				self.actionResponse.messages = messages;
			};
			self.hide = function () {
				self.showDialog = false;
				self.actionResponse = {};
			};
			return self;
		}
	}
})();

