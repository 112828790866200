/*
 * pageModule
 * */

import pageComponent from './page.component';

const pageModule = angular.module('app.common.page', [])
	.component('page', pageComponent)
	.name;

export default pageModule;
