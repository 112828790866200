(function () {
	'use strict';

	/**
	 * Module to intercept routes/URL changes. Redirect to login if user has no session is done here
	 */
	angular.module('portalApp').run(RoutingInterceptor);

	/**
	 * @ngInject
	 */
	function RoutingInterceptor($log, $rootScope, $window, $location, $state, $injector, appConfig,
	                            appSession, ssoService, appI18n, clinicalDataService,
								$filter, appToast, $anchorScroll) {


		$log = $log.getInstance('routingInterceptor', 'color:#FF33CC');

        $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
			$rootScope.showStateChangeLoadingIndicator = false;
        });

		$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
			$log.debug("---------- stateChangeSuccess to " + angular.toJson(toState));
			$rootScope.showStateChangeLoadingIndicator = false;
			$anchorScroll();
			if (toParams.study) {
				var study = appSession.getStudy();
				if (study && study.id) {
					appSession.publishStudy(study);
				}
			}
			setPageTitle(toState, toParams, $log);
		});

		$rootScope.$on('$stateChangeStart', async function (event, toState, toParams, fromState, fromParams) {
			// clear error toasts on state change start

			$rootScope.showStateChangeLoadingIndicator = true;
			$log.debug(`---------- stateChangeStart to : from: ${angular.toJson(fromState)} to: ${angular.toJson(toState)} toParams: ${angular.toJson(toParams)}` );

			appToast.dismiss();

			if (toState.name === appConfig.routes.authRedirect
				|| toState.name === appConfig.routes.login
				|| toState.allowUnauthenticated) {
				$log.debug(`---------- sso-redirect: in redirect`);
				return;
			}

			var isInStudySwitch = false;
			var isInLogin = false;
			var isInLogout = false;
			var hasSession = appSession.get() !== null;

			if (fromParams.filter && toParams.filter && fromParams.filter === toParams.filter) {
				toParams.filter = '';
			}
			if (fromParams.orderBy && toParams.orderBy && fromParams.orderBy === toParams.orderBy) {
				toParams.orderBy = '';
			}

			if (toState.logoutUser) {
				// never auto login etc when going to confirm
				appSession.destroy();
				$rootScope.studySwitching = false;
				return;
			}

			if (fromParams.study && toParams.study && (toParams.study !== fromParams.study)) {
				isInStudySwitch = true;
			}

			if (fromState.name === appConfig.routes.login && toParams.study && (toParams.study !== fromParams.study)) {
				isInLogin = true;
			}

			if (toState.name === appConfig.routes.login) {
				isInLogout = true;
				appSession.clearCurrentStudy();
			}

			if (!hasSession && toState.name !== appConfig.routes.login && !toState.allowUnauthenticated) {
				// Navigating to any view except login with empty session -> redirect to login with save return location
				event.preventDefault();
				goToLogin(event, toState, toParams);
			}

			var currentStudy = appSession.getStudy();
			var hasCurrentStudy = currentStudy && currentStudy.id > 0;

			if (hasSession && toState.name !== appConfig.routes.login
				&& (!hasCurrentStudy || (toParams.study && currentStudy.id && toParams.study != currentStudy.id))) {
				// if we have studyid, try to load study, else, go to last
				if (toParams.study) {
					var studyPromise = appSession.getOrLoadStudy(toParams.study);
					studyPromise.then(
						function (study) {
							appSession.switchStudy(study);
						},
						function () {
							// when study is not known, user shouldn't really try to access it, redirect to login
							// where the user can then select a study from the study selection
							goToLogin(event, toState, toParams);
						});
				}

				else {
					// try get last study
					var savedStudy = appSession.getLastRecentStudy();
					var hasSavedStudy = savedStudy && savedStudy.id > 0;

					if (hasSavedStudy) {
						// this case will for happen, when a user ctrl/middle clicks the navigation entry
						// in this case we can normally load the study from the recent studies
						// and go to the requested state (which should be available)
						appSession.saveState(toState.name, toState.params);
						// load with study and with saved state
						appSession.switchStudy(savedStudy, true);
						event.preventDefault();
						return;
					}
					else {
						goToLogin(event, toState, toParams);
					}
				}
			}

			if (!isInStudySwitch && !isInLogin && !isInLogout) {
				if (hasCurrentStudy) {
					// this is the earliest we can redirect to navigator in case we are not embedded
					// as navigator needs the study number for the redirect to work.
					const redirecting = ssoService.redirectToDashboardIfNotEmbedded(currentStudy.id, currentStudy.number);

					if (redirecting) {
						event.preventDefault();
						return;
					}
				}

				if (!toParams.study && $rootScope.study && $rootScope.study.id) {
					toParams.study = $rootScope.study.id;
					toParams.mode = fromParams.mode || 0;
				}


				if (hasSession && !toParams.study && hasCurrentStudy) {
					toParams.study = currentStudy.id;
					toParams.mode = fromParams.mode || 0;

					if (!toState.name) {
						toState.name = appConfig.routes.default;
					}
					event.preventDefault();
					$state.transitionTo(toState.name, {study: currentStudy.id, mode: 0}, {reload: true});
				}
			}
			if (toState.redirectTo) {
				event.preventDefault();
				if (hasSession) {
					$state.go(toState.redirectTo, toParams);
				}
			}

		});

		async function setPageTitle(toState, toParams, $log) {

			var pageTitle = getWebsiteTitle();
			try {

				var pageName = getPageName(toState);
				var extraInfo = await getExtraTitleInfo(toState, toParams);

				pageTitle = `${pageTitle}${pageName}${extraInfo}`;

			} finally {
				$log.debug(pageTitle);
				$rootScope.pageTitle = pageTitle;
			}
		}

		function getWebsiteTitle () {
			return  appI18n.translateImmediate('page-title.site');
		}

		function getPageName (toState) {

			var stateName = `page-title.${toState.name}`;
			var pageName = appI18n.translateImmediate(stateName);

			//return empty value if we can't find translation key
			if (pageName === stateName) {
				pageName = '';
			}

			if (pageName) {
				pageName = ` [${pageName}]`;
			}
			return pageName;
		}

		async function  getExtraTitleInfo(toState,toParams) {
			var studyName = '';
			var clinicalDataPageTitle = '';
			if (toParams && toParams.study) {
				studyName = getStudyName();
				var clinicalDataPageTitle = await getClinicalDataPageTitle(toState, toParams);
			}
			return `${studyName}${clinicalDataPageTitle}`;
		}

		function getStudyName() {
			var result = '';
			var study = appSession.getStudy();
			if (study && study.number) {
				result = ` ${study.name} (${study.number})`;
			}
			return result;
		}

		async function getClinicalDataPageTitle(toState, toParams) {
			var moduleId = toState.moduleId || '';
			if (moduleId.indexOf('ClinicalData') === -1 && moduleId.indexOf('Spirometry') === -1) {
				return '';
			}
			var country = getCountryTitle(toParams.country);
			var site = getSiteTitle(toParams);
			var subject = getSubjectTitle(toParams);
			var visit = toPageTitleFormat(toParams.visit);
			var event = getEventTitle(toParams);

			var promises = [country, site, subject, visit, event];
			var results = await Promise.all(promises);

			return results.join('');
		}

		function toPageTitleFormat(src) {
			return !src ? '' : ` - ${src}`;
		}

		async function getCountryTitle(countryId)	{
			if (!validateStateParam(countryId)) {
				return '';
			}
			var result = countryId;
			var response = await clinicalDataService.getCountriesMinimal();
			if(response && response.data && angular.isArray(response.data)) {
				var countries = $filter('filter')(response.data, countryId);
				if (countries && countries.length > 0) {
					result = countries[0].countryName;
				}
			}
			return toPageTitleFormat(result);
		}

		async function getSiteTitle(stateParams)	{
			let country = stateParams.country;
			let site = stateParams.site;
			if (!validateStateParam(country) || !site) {
				return '';
			}

			var result = site;
			var response = await clinicalDataService.getSitesMinimal(null, country);
			if(response && response.data && angular.isArray(response.data)) {
				var sites = $filter('filter')(response.data, site);
				if (sites && sites.length > 0) {
					result = sites[0].sponsorSiteId;
				}
			}
			return toPageTitleFormat(result);
		}

		function validateStateParam(param) {
			return param && param !== '0';
		}

		async function getSubjectTitle(stateParams)	{
			let country = stateParams.country;
			let site = stateParams.site;
			let subject = stateParams.subject;

			if (!validateStateParam(country) || !site || !subject) {
				return '';
			}

			var result = subject;
			var response = await clinicalDataService.getSubjectsMinimal(country, site);
			if(response && response.data && angular.isArray(response.data)) {
				var subjects = $filter('filter')(response.data, subject);
				if (subjects && subjects.length > 0) {
					result = subjects[0].subjectKey;
				}
			}
			return toPageTitleFormat(result);
		}

		async function getEventTitle(stateParams)	{
			var site = stateParams.site;
			var subject = stateParams.subject;
			var event = stateParams.event;
			var visit = stateParams.visit;

			if (!site || !event) {
				return '';
			}

			var result = event;
			var response = await clinicalDataService.getEventsMinimal(site, subject);
			if (response && response.data && angular.isArray(response.data)) {
				var events = $filter('filter')(response.data, { 'visitName': visit, 'eventId': event });
				if (events && events.length > 0) {
					result = events[0].eventName;
				}
			}
			return toPageTitleFormat(result);
		}


		function goToLogin(event, toState, toParams) {
			event.preventDefault();

			var newUrl = $state.href(toState.name, toParams, {absolute: true});
			$rootScope.returnLocation = newUrl;
			appSession.saveState(toState.name, toParams);
			// redirect to the login page and pass the state parameter
			$state.transitionTo(appConfig.routes.login);
		}
	}
})
();
