import templateUrl from './downloadPopup.html';
import controller from './downloadPopup.controller';
import './downloadPopup.scss';

const downloadPopupComponent = {
	bindings: {
		componentReady: '&'
	},
	templateUrl,
	controller
};

export default downloadPopupComponent;
