(function () {

	'use strict';

	angular
	.module('portalApp')
	.service('globalHeaderService', GlobalHeaderService);

	/**
	 * @ngInject
	 */
	function GlobalHeaderService(appConfig, $http, $log, appSession) {
		$log = $log.getInstance("GlobalHeaderService", "color:blue");
		$log.debug("loaded");

		var vm = this;
		vm.onHeaderReady$ = new Rx.BehaviorSubject(false);

		var service = {
			onHeaderReady$: vm.onHeaderReady$,
			setHeaderReady: setHeaderReady
		};

		return service;

		function setHeaderReady(headerReady) {
			vm.onHeaderReady$.onNext(headerReady || true);
		}
	}
})();
