const _ = require('lodash');
(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('ModuleTrackingCtrl', ModuleTrackingController);

	/**
	 * @ngInject
	 */
	function ModuleTrackingController(adminService, $filter, $scope, $log, appI18n,
		configurationService) {

		$log = $log.getInstance("ModuleTrackingController");
		$log.debug("loaded");

		var vm = this;
		var today = new Date();
		var fromDate = new Date(today).setDate(today.getDate()-7);

		vm.moduleTrackingOptions = {
			rowDefs: {
			},
			gridRowActions: [],

			sortOrders: [{
				fieldName: 'logDateTime',
				reverseOrder: true
			}],

			preSelectedFilters: [{
				fieldName: 'logDateTime',
				displayName: 'Date/Time (UTC)',
				filterConditions: [
					{
					 displayValue: $filter('date')(fromDate, 'dd-MMM-yyyy'),
					 filterValue: $filter('date')(fromDate, 'yyyy-MM-dd'),
					 operator: 'ge',
					 type: 'date'
					},
					{
					 displayValue: $filter('date')(today, 'dd-MMM-yyyy'),
					 filterValue: $filter('date')(today, 'yyyy-MM-dd'),
					 operator: 'le',
					 type: 'date'
					}
				]
			}],

			getData: getModuleTracking
		};

		function getModuleTracking(options) {
				return adminService.getModuleTracking(options);
		}

		function activate() {
			var moduleEnums = [
				{displayName: 'SQF', values:['SQF']},
				{displayName: 'DCR/Query', values:['DCR/Query']},
				{displayName: 'Reporting', values:['Reporting']},
				{displayName: 'My Downloads', values:['My Downloads']},
				{displayName: 'Supplies', values:['Supplies']},
				{displayName: 'iSpiro', values:['iSpiro']},
				{displayName: 'Home ECG', values:['Home ECG']}
			];
			var actionEnums = [];
			configurationService.getStudySettings()
				.then(function(data) {
					if (data.hasEcoaPhoneWebText) {
						moduleEnums.push({displayName: 'ePRO', values:['ePRO']});
					}
					if (data.usesMyDeviceAccount) {
						moduleEnums.push({displayName: 'Device Account', values:['Device Account']});
					}
					actionEnums = [
						{displayName: 'Answered', values:['Answered']},
						{displayName: 'Created', values:['Created']},
						{displayName: 'Deleted', values:['Deleted']},
						{displayName: 'Downloaded', values:['Downloaded']},
						{displayName: 'Requested', values:['Requested']},
						{displayName: 'Saved', values:['Saved']},
						{displayName: 'Submitted', values:['Submitted']},
						{displayName: 'Uploaded', values:['Uploaded']},
					];
					if (data.usesEProSubjectModuleAction) {
						actionEnums.push({displayName: 'Activate', values:['Activate']});
						actionEnums.push({displayName: 'Close Visit', values:['Close Visit']});
						actionEnums.push({displayName: 'Deactivate', values:['Deactivate']});
						actionEnums.push({displayName: 'Edit Contact Details', values:['Edit Contact Details']});
						actionEnums.push({displayName: 'Edit Subject', values:['Edit Subject']});
						actionEnums.push({displayName: 'Subject PIN Reset', values:['Subject PIN Reset']});
						actionEnums.push({displayName: 'Subject PIN Sent', values:['Subject PIN Sent']});
					}
					if (data.isiSpiroStudy) {
						actionEnums.push({displayName: 'Dispense Device', values:['Dispense Device']});
						actionEnums.push({displayName: 'Create Subject', values:['Create Subject']});
						actionEnums.push({displayName: 'Edit Device Values', values:['Edit Device Values']});
						actionEnums.push({displayName: 'Lost Device', values:['Lost Device']});
						actionEnums.push({displayName: 'Close Device', values:['Close Device']});
					}
					if (data.isKardiaStudy) {
						actionEnums.push({displayName: 'Dispense Device', values:['Dispense Device']});
						actionEnums.push({displayName: 'Create Subject', values:['Create Subject']});
					}

					actionEnums = _.uniq(actionEnums, 'displayName');
					actionEnums = _.orderBy(actionEnums, ['displayName'], ['asc']);


					appI18n.translate( [
						"admin.module-tracking-title",
						"admin.module-tracking.module",
						"admin.module-tracking.action",
						"admin.module-tracking.status",
						"admin.module-tracking.sitepi",
						"admin.module-tracking.user",
						"admin.module-tracking.datetime",
						"admin.module-tracking.additional-info"
					])
						.then(function (translations) {
							adminService.setTitle(translations["admin.module-tracking-title"]);
							vm.moduleTitle = translations["admin.module-tracking.module"];
							vm.actionTitle = translations["admin.module-tracking.action"];
							vm.statusTitle = translations["admin.module-tracking.status"];
							vm.siteTitle = translations["admin.module-tracking.sitepi"];
							vm.userTitle = translations["admin.module-tracking.user"];
							vm.dateTitle = translations["admin.module-tracking.datetime"];
							vm.additionalTitle = translations["admin.module-tracking.additional-info"];

							vm.moduleTrackingOptions.columnDefs =  [
								{displayName: vm.moduleTitle, fieldName: 'module', isSortable: true, type: 'enum',
								 enumType: 'string',	enums: moduleEnums, isDefaultFilter: true},
								{displayName: vm.actionTitle, fieldName: 'action', isSortable: true, type: 'enum',
								 enumType: 'string',	enums: actionEnums },
								{displayName: vm.statusTitle, fieldName: 'status', isSortable: true, type: 'enum',
								 enumType: 'string',
								 enums: [
									 {displayName: 'Success', values:['Success']},
									 {displayName: 'Failure', values:['Failure']},
									 {displayName: 'Pending', values:['Pending']}
								 ]},
								{displayName: vm.siteTitle, fieldName: 'site', isSortable: true, type: 'string'},
								{displayName: vm.userTitle, fieldName: 'userName', isSortable: true, type: 'string'},
								{displayName: vm.dateTitle, fieldName: 'logDateTime', isSortable: true, type: 'date', formatter: 'dateTimeFormat'},
								{displayName: vm.additionalTitle, fieldName: 'info', isSortable: true, type: 'string'}
							];
						});

					vm.load = true;

				});

		}

		activate();
	}

})();
