/*
 * filterBadgesController
 * */

const _ = require('lodash');

class FilterBadgesController {
	constructor($log, filtersService) {
		'ngInject';
		this.$log = $log;
		this.filtersService = filtersService;
		this.name = this.name || 'default';
		this.filtersApi = {};
		this.uniqueFilters = this.removeDuplicates(this.filters);
	}

	$onInit() {
		this.$log = this.$log.getInstance('FilterBadgesController');
		this.$log.debug('loaded');
	}

	$onChanges(changesObj) {
		if (changesObj.filters && !changesObj.filters.isFirstChange()) {
			this.uniqueFilters = this.removeDuplicates(changesObj.filters.currentValue);
		}
	}

	removeDuplicates(filters) {
		return _.uniqBy(filters, 'filterName');
	}

	removeFilter(criterion) {
		this.filters = _.remove(this.filters, (item) => {
			return criterion.filterName !== item.filterName;
		});

		const uriBuilder = this.filtersService.getUriBuilder(this.filters);

		this.notifyGrid(uriBuilder, this.filters || []);

		this.onRemove && this.onRemove({ queryFilters: this.filters || [] });

	}

	notifyGrid(uriBuilder, filters) {
		this.grid && this.grid.onFilterChange(uriBuilder, filters);
	}

}

export default FilterBadgesController;
