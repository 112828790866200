/*
 * baseTimePickerComponent
 * */

import templateUrl from './baseTimePicker.html';
import controller from './baseTimePicker.controller';
import './baseTimePicker.scss';

const baseTimePickerComponent = {
	bindings: {
		cssClass: '@?',
		isInvalid: '<?',
		onIconClicked: '&?'
	},
	transclude: true,
	templateUrl,
	controller
};

export default baseTimePickerComponent;
