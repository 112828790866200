/*
 * notificationMessageModule
 * */

import notificationMessageComponent from './notificationMessage.component';

const notificationMessageModule = angular.module('app.components.admin.notificationMessage', [])
	.component('notificationMessage', notificationMessageComponent)
	.name;

export default notificationMessageModule;
