(function(){

	'use strict';

	angular
		.module("portalApp")
		.service("announcementService", AnnouncementService);

	/**
	 * @ngInject
	 */

	function AnnouncementService($http, $log, appConfig, appSession, $q) {

		$log = $log.getInstance("AnnouncementService", "color:red");
		$log.debug("loaded");

		var newAnnouncementHandler;
		var refreshHandler;

		var announcementService = {
			getAnnouncements: getAnnouncements,
			getAllAnnouncements: getAllAnnouncements,
			getAnnouncementFile: getAnnouncementFile,
			deleteAnnouncement: deleteAnnouncement,
			getAnnouncementDetails: getAnnouncementDetails,
			addAnnouncement: addAnnouncement,
			updateAnnouncement: updateAnnouncement,
			getPermission: getPermission,
			showNewAnnouncement: showNewAnnouncement,
			registerNewAnnouncement: registerNewAnnouncement,
			registerRefresh: registerRefresh,
			refreshGrid : refreshGrid,
			getDashboardAnnouncement: getDashboardAnnouncement,
			getDashboardAnnouncementDetails: getDashboardAnnouncementDetails,
			getAnnouncementFileContent: getAnnouncementFileContent 

		};

		return announcementService;

		function registerRefresh(handler) {
			refreshHandler = handler;
		}

		function refreshGrid() {
			if (refreshHandler) {
				refreshHandler();
			}
		}

		function registerNewAnnouncement(handler) {
			newAnnouncementHandler = handler;
		}

		function showNewAnnouncement(announcementId) {
			$log.debug("showNewAnnouncement");
			if (newAnnouncementHandler) {
				newAnnouncementHandler.showNewAnnouncement(announcementId);
			}
		}

		function getAnnouncements(options) {
			var url = appConfig.apiUrls.announcements.announcements.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });
			if (options) {
				url += "&" + options;
			}
			else {
				url += "&$orderby=Priority";
			}
			return $http.get(url);
			
		}

		function getAllAnnouncements(options) {
			var url = appConfig.apiUrls.announcements.all.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });
			if (options) {
				url += "&" + options;
			}
			else {
				url += "&$orderby=Priority";
			}
			return $http.get(url);
		}

		function getAnnouncementFile(announcementId){
			var url = appConfig.apiUrls.announcements.announcementfile.supplant(
				{'announcementId': announcementId, 'studyId': appSession.getStudy().id, 
					'studyModeId': appSession.getStudy().studyModeId });
			//return $http.get(url, {responseType: "arraybuffer"});
			return $http.get(url);
		}

		function getAnnouncementFileContent(announcementId){
			var url = appConfig.apiUrls.announcements.announcementfile.supplant(
				{'announcementId': announcementId, 'studyId': appSession.getStudy().id, 
					'studyModeId': appSession.getStudy().studyModeId });
			return $http.get(url);	
		}

		function deleteAnnouncement(announcementId) {
			var url = appConfig.apiUrls.announcements.announcementfile.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 
					'announcementId': announcementId});
			return $http.delete(url);
		}

		function getAnnouncementDetails(announcementId, isAdmin) {
			var url = appConfig.apiUrls.announcements.details.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 
					'announcementId': announcementId, 'isAdmin': isAdmin.toString() });
			return $http.get(url);
		}

		function addAnnouncement(data) {
			var url = appConfig.apiUrls.announcements.announcements.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });

			return $http.post(url, data);
		}

		function updateAnnouncement(announcement) {
			var url = appConfig.apiUrls.announcements.updateannouncement.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 
					'announcementId' : announcement.announcementId});

			return $http.post(url, announcement);
		}

		function getPermission() {
			var url = appConfig.apiUrls.announcements.permissions.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId });

			return $http.get(url);
		}

		function getDashboardAnnouncement() {
			var q = $q.defer();
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.announcements.dashboardAnnouncement.supplant(
				{'studyId': study.id, 'studyModeId': study.studyModeId });

			$http.get(url, { cache: true, params: { cacheContext: 'study' } })
				.then(function(response) {
					q.resolve(response.data);
				}, function(response) {
					q.reject(response.data);
				});

			return q.promise;
		}

		function getDashboardAnnouncementDetails() {
			var study = appSession.getStudy();
			var url = appConfig.apiUrls.announcements.dashboardAnnouncementDetails.supplant(
				{'studyId': study.id, 'studyModeId': study.studyModeId });

			return $http.get(url);
		}
	}

})();
