/*
 * visitOverviewWidgetComponent
 * */

import templateUrl from './visitOverviewWidget.html';
import controller from './visitOverviewWidget.controller';
import './visitOverviewWidget.scss';

const visitOverviewWidgetComponent = {
	bindings: {
		heading: '@'
	},
	templateUrl,
	controller
};

export default visitOverviewWidgetComponent;
