/*
 * sideNavigationModule
 * */

import sideNavigationComponent from './sideNavigation.component';
import sideNavigationService from './sideNavigation.service';

const sideNavigationModule = angular.module('app.common.sideNavigation', [])
	.service('sideNavigationService', sideNavigationService)
	.component('sideNavigation', sideNavigationComponent)
	.name;

export default sideNavigationModule;
