(function() {

	'use strict';

	angular
		.module('portalApp')
		.service('reportsService', ReportService);

	/**
	 * @ngInject
	 */
	function ReportService(appConfig, $http, $log, appSession) {

		$log = $log.getInstance("reportService", "color:green");
		$log.debug("loaded");

		var reportsHandler, columnSelectHandler, ecgExportOptions, createReportHandler;

		var reportService = {
			getAllReports,
			getAllReportsConfirmation,
			confirmReports,
			getAllReportTypes,
			getReportTemplates: getReportTemplates,
			getReportTemplate: getReportTemplate,
			getMyReports: getMyReports,
			registerHandler: registerHandler,
			setTitle: setTitle,
			deleteReport: deleteReport,
			getSelectionCriteria: getSelectionCriteria,
			getAvailableSelectionCriteria: getAvailableSelectionCriteria,
			getSelectionCriteriaParameter: getSelectionCriteriaParameter,
			getTrainingDocuments: getTrainingDocuments,
			getPermission: getPermission,
			getEcgDataExport: getEcgDataExport,
			registerColumnSelectHandler: registerColumnSelectHandler,
			showOpenExportECG: showOpenExportECG,
			exportByTransmittal: exportByTransmittal,
			exportByLead: exportByLead,
			setEcgExportOptions: setEcgExportOptions,
			showCreateReport: showCreateReport,
			registerCreateReportHandler: registerCreateReportHandler,
			createReport: createReport,
			getAbpmReports,
			getHolterReports,
			getDataStatusForms,
			getEcgSourceTracings
		};

		return reportService;

		function registerHandler(handler) {
			reportsHandler = handler;
		}

		function registerColumnSelectHandler(handler) {
			columnSelectHandler = handler;
		}

		function registerCreateReportHandler(handler) {
			createReportHandler = handler;
		}

		function setTitle(title) {
			reportsHandler.setTitle(title);
		}

		function showOpenExportECG() {
			columnSelectHandler.openExport(ecgExportOptions.oDataQuery);
		}

		function showCreateReport(row) {
			createReportHandler.openCreate(row);

		}

		function setEcgExportOptions(options) {
			ecgExportOptions = options;
		}

		function getDataStatusForms(countryId, siteId, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.reports.dataStatusForms.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				countryId: countryId || '', siteId: siteId || '', oDataQuery
			});

			$log.debug('reportsService.getDataStatusForms with url: {url}', { url });
			return $http.get(url);
		}

		function getEcgSourceTracings(countryId, siteId, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.reports.sourceTracings.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				countryId: countryId || '', siteId: siteId || '', oDataQuery
			});

			$log.debug('reportsService.getEcgSourceTracings with url: {url}', { url });
			return $http.get(url);
		}


		function getHolterReports(countryId, siteId, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.reports.holterReports.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				countryId: countryId || '', siteId: siteId || '', oDataQuery
			});

			$log.debug('reportsService.getHolterReports with url: {url}', { url });
			return $http.get(url);
		}

		function getAbpmReports(countryId, siteId, oDataQuery) {
			const currentStudy = appSession.getStudy();
			const url = appConfig.apiUrls.reports.abpmReports.supplant({
				'studyId': currentStudy.id, 'studyModeId': currentStudy.studyModeId,
				countryId: countryId || '', siteId: siteId || '', oDataQuery
			});

			$log.debug('reportsService.getAbpmReports with url: {url}', { url });
			return $http.get(url);
		}


		function getAllReports(options) {
			let url = appConfig.apiUrls.reports.all.supplant({
				'studyId': appSession.getStudy().id,
				'studyModeId': appSession.getStudy().studyModeId
			});

			if (options) {
				url += `&${options}`;
			}
			$log.debug(`reportService.getAllReports with url: ${url}`);
			return $http.get(url);
		}

		function getAllReportsConfirmation(options) {
			let url = appConfig.apiUrls.reports.confirmation.supplant({
				'studyId': appSession.getStudy().id,
				'studyModeId': appSession.getStudy().studyModeId
			});

			if (options) {
				url += `&${options}`;
			}
			$log.debug(`reportService.getAllReportsConfirmation with url: ${url}`);
			return $http.get(url);
		}

		function confirmReports(reportIds){
			var url = appConfig.apiUrls.reports.confirmReports.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId});
			$log.debug("reportService.confirmReport with url: {url} ", {url:url});
			var data = JSON.stringify({ids: reportIds});
			return $http.post(url, data);
		}

		function getAllReportTypes(includeFDA) {
			let url = appConfig.apiUrls.reports.types.supplant({
				'studyId': appSession.getStudy().id,
				'studyModeId': appSession.getStudy().studyModeId
			});

			if (includeFDA) {
				url += '&includeFDA=true';
			}
			$log.debug(`reportService.getAllReportTypes with url: ${url}`);
			return $http.get(url);
		}

		function getReportTemplates(options) {
			var url = appConfig.apiUrls.reports.reportTemplates.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId});
			if (options) {
				url += "&" + options;
			}
			$log.debug("reportService.getReportTemplates with url: {url} ", {url:url});
			return $http.get(url);
		}

		function getReportTemplate(templateId) {
			var url = appConfig.apiUrls.reports.reportTemplate.supplant(
				{'studyId': appSession.getStudy().id,
				 'studyModeId': appSession.getStudy().studyModeId,
				 'templateId': templateId });
			$log.debug("reportService.getReportTemplate with url: {url} ", {url:url});
			return $http.get(url);
		}

		function getMyReports(options) {
			var url = appConfig.apiUrls.reports.myReports.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId});
			if (options) {
				url += "&" + options;
			}
			$log.debug("reportService.getMyReports with url: {url} ", {url:url});
			return $http.get(url);
		}

		function deleteReport(reportId) {
			var url = appConfig.apiUrls.reports.deleteReport.supplant(
				{'studyId': appSession.getStudy().id, 'reportId': reportId});

			$log.debug("reportService.deleteReport with url: {url} ", {url:url});
			return $http.delete(url);
		}

		function getSelectionCriteria(instanceId) {
			var url = appConfig.apiUrls.reports.selectionCriteria.supplant(
				{'studyId': appSession.getStudy().id, 'reportInstanceId': instanceId});
			$log.debug("reportService.getSelectionCriteria with url: {url} ", {url:url});
			return $http.get(url);
		}

		function getAvailableSelectionCriteria(reportId) {
			var url = appConfig.apiUrls.reports.availableSelectionCriteria.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 'reportId': reportId});
			$log.debug("reportService.getAvailableSelectionCriteria with url: {url} ", {url:url});
			return $http.get(url);
		}

		function getSelectionCriteriaParameter(reportId, paramId, parentId, grandParentId) {
			var url = appConfig.apiUrls.reports.selectionCriteriaParameters.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId, 'reportId': reportId,
					'paramId': paramId, 'parentId': parentId, 'grandParentId': grandParentId});
			$log.debug("reportService.getSelectionCriteriaParameter with url: {url} ", {url:url});
			return $http.get(url);
		}

		function createReport(report){
			var url = appConfig.apiUrls.reports.createReport.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId});
			$log.debug("reportService.createReport with url: {url} ", {url:url});
			var data = JSON.stringify(report);
			return $http.post(url, data);
		}

		function getTrainingDocuments(options){
			var url = appConfig.apiUrls.reports.trainingDocuments.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId});
			if (options) {
				url += "&" + options;
			}
			$log.debug("reportService.getTrainingDocuments with url: {url} ", {url:url});
			return $http.get(url);
		}

		function getPermission(){
			var url = appConfig.apiUrls.reports.permission.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId});
			$log.debug("reportService.getPermission with url: {url} ", {url:url});
			return $http.get(url);
		}

		function getEcgDataExport(options){
			var url = appConfig.apiUrls.ecgData.ecgDataExport.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId});
			if (options) {
				url += "&" + options;
			}
			$log.debug("reportService.getEcgDataExport with url: {url} ", {url:url});
			return $http.get(url);
		}

		function exportByTransmittal(columns, format, options) {
			var url = appConfig.apiUrls.ecgData.exportByTransmittal.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId,
				'columns': columns, 'format': format, 'studyName': appSession.getStudy().name});
			if (options) {
				url += "&" + options;
			}
			$log.debug("reportService.exportByTransmittal with url: {url} ", {url:url});
			//return $http.get(url, {responseType: 'arraybuffer'});
			return $http.get(url);
		}

		function exportByLead(columns, format, options) {
			var url = appConfig.apiUrls.ecgData.exportByLead.supplant(
				{'studyId': appSession.getStudy().id, 'studyModeId': appSession.getStudy().studyModeId,
				'columns': columns, 'format': format, 'studyName': appSession.getStudy().name});
			if (options) {
				url += "&" + options;
			}
			$log.debug("reportService.exportByLead with url: {url} ", {url:url});
			//return $http.get(url, {responseType: 'arraybuffer'});
			return $http.get(url);
		}

	}

})();
