(function () {
	'use strict';

	angular
		.module('portalApp')
		.controller('AnswerDcrController', AnswerDcrController);

	/**
	 * @ngInject
	 */
	function AnswerDcrController($log, $scope, WizardHandler, $stateParams, $timeout,
								 appSession, appI18n, dataManagementService,
								 configurationService, appConfig,
								 signatureService, loadingIndicatorService, notificationService) {
		$log = $log.getInstance("AnswerDcrcontroller");
		var vm = this;
		vm.submit = submit;
		vm.signature = {};
		vm.isResponseEntered = isResponseEntered;
		vm.ticketId = null;
		vm.hasEventInfo = hasEventInfo;
		vm.hasSubjectInfo = hasSubjectInfo;
		vm.loading = true;
		vm.showAnswerDcr = showAnswerDcr;
		vm.closeAnswerDcrRequest = closeAnswerDcrRequest;
		vm.answerDcrActive = false;
		vm.formModel = {};
		vm.form = {};
		vm.ticketData = {};
		vm.ticket = {};
		vm.demographics = {};
		vm.actionLogs = {};
		vm.siteName = undefined;
		vm.siteId = undefined;
		vm.siteContact = {};
		vm.showMasterScopeDcrId = null;
		vm.canUploadFiles = false;
		vm.isFilesUploaded = isFilesUploaded;
		vm.sigType = appConfig.sigType.answerDcr;
		vm.data = dataManagementService.getSharedData();
		vm.getTitleForSiteContact = getTitleForSiteContact;
		vm.preSign = preSign;
		vm.resetState = resetState;
		vm.maxAnswerLength = 2500;

		activate();

		function activate() {
			dataManagementService.registerAnswerDcr(vm);
			getShowMasterScopeDcrId();
			getShowUploadFiles();
		}

		function handleAutoSubmit() {
			// This controller is initialized when the user is redirected back from the sign page
			// (for Answer DCR or New DCR)
			// That means also for New DCR we trigger the auto submit workflow for answer DCR and
			// set some properties.
			if ($stateParams.signKey) {
				const state = signatureService.getState($stateParams.signKey);

				// check if the stored sigType matches
				if (state && state.sigType === vm.sigType) {
					if ($stateParams.tokenId) {
						vm.isAutoSubmit = true;
					}

					const unbind = $scope.$watchGroup(
						[() => { return !!wizard(); }, () => { return !!vm.isLoading; }],
						() => {
							const wz = wizard();

							if (wz && vm.isLoading === false) {
								vm.state = state;
								signatureService.removeState($stateParams.signKey);

								if (vm.state) {
									vm.siteId = vm.state.siteId;
									vm.siteContactHeader = vm.getTitleForSiteContact(vm.siteId);
									vm.formModel.answerText = vm.state.message;
									vm.data.attachments = vm.state.attachments;
									vm.data.agreement = vm.data.attachments && vm.data.attachments.length > 0 ? true : false;
									$timeout(() => {
										wz.goTo(wz.totalStepCount() - 1);
										vm.submit();
									}, 0);
								}
								unbind();
							}
						});
				}


			}

		}
		function getShowUploadFiles() {
			configurationService.getUserStudySettings()
				.then(response => {
					vm.canUploadFiles = response.data.expertStudySetup.showQueryAttachment;
					if (vm.canUploadFiles) {
						vm.actions.splice(1, 0, backAction);
					}
					handleAutoSubmit();
				});
		}

		function getShowMasterScopeDcrId() {
			dataManagementService.getTicketsViewConfig()
				.then(data => vm.showMasterScopeDcrId = data.showMasterScopeDcrId);
		}

		var closeAction = {
			action: closeAnswerDcrRequest,
			displayName: 'Cancel',
			cssClass: 'es-button -es-cancel',
			iconName: 'row-close',
			iconOnTheLeft: true
		},
				backAction = { action: previousStep, displayName: 'Back', isDisabled: true, iconName: '', iconOnTheLeft: false },
				proceedAction = { action: nextStep, displayName: 'Next', isDisabled: false, iconName: '', isPrimary: true };

		vm.actions = [
			proceedAction,
			closeAction
		];

		initForm();
		function initForm() {
			vm.formFields = [
				{
					template: `
								<div class="es-content-box">
									<div class="es-text-group">
										<h3 translate="data-management.messageTitle"></h3>
										<p translate="data-management.queryTextLabel"></p>
								    </div>
								</div>
							`
				},
				{
					key: 'queryText',
					type: 'esSgTextArea',
					className: '-es-width-large',
					templateOptions: {
						className: '-es-width-large',
						label: '',
						disabled: true,
						rows: 10,
						id: 'message'
					}
				},
				{
					template: `
								<div class="es-content-box">
									<div class="es-text-group">
										<h3 class="red-star" translate="data-management.responseTitle"></h3>
										<p translate="data-management.answerTextLabel"></p>
								    </div>
								</div>
							`
				},
				{
					key: 'answerText',
					type: 'esSgTextArea',
					className: '-es-width-large',
					templateOptions: {
						className: '-es-width-large',
						label: '',
						rows: 10,
						placeholder: 'Enter answer here',
						required: true,
						focus: false,
						  id: 'response',
					},
					validators: {
						maxValue: {
							expression: function (viewValue, modelValue) {
							  var value = modelValue || viewValue;
								if(value) {
									var newLines = value.match(/(\r\n|\n|\r)/g);
									var addition = newLines !== null ? newLines.length : 0;

									$log.debug(`value length:${value.length},newLines length:${addition}, toal length:${value.length + addition}, value=${value}`);
									if (value.length + addition > vm.maxAnswerLength) {
										return false;
									}
								}
								return true;
							},
							message: "'{message} {value}'".supplant({ message: appI18n.translateImmediate('app.messages.maxLength'), value: vm.maxAnswerLength })
						}
					}
				}
			];
		}
		function hasSubjectInfo() {
			var hasInfo =
					!!(vm.ticket && (
						vm.ticket.subjectCriticalValues ||
							(vm.demographics && vm.demographics.length)));

			return hasInfo;
		}

		function hasEventInfo() {
			var hasInfo =
					!!(vm.ticket && (
						vm.ticket.collectionDate ||
							vm.ticket.collectionTime ||
							vm.ticket.transmittalNumber));

			return hasInfo;
		}

		function isBackDisabled() {
			var wz = wizard();
			return wz ? wz.currentStepNumber() === 1 : false;
		}

		function onStepChanged() {
			if (vm.answerDcrActive) {
				updateActions();
			} else if (vm.unsub) {
				vm.unsub();
			}
			updateActions();
		}

		function wizard() {
			return WizardHandler.wizard('answerDcrWizard');
		}

		function updateActions() {
			backAction.isDisabled = isBackDisabled();
			var wz = wizard();
			if (wz) {
				var currentIndex = wz.currentStepNumber();
				proceedAction.displayName = (currentIndex ===2 && vm.canUploadFiles || currentIndex ===1 && !vm.canUploadFiles)
					? appI18n.translateImmediate('app.buttons.sign-submit'):
					appI18n.translateImmediate('app.buttons.next');

				if (currentIndex >= 1 && currentIndex === wz.totalStepCount()) {

					vm.state = {};
					vm.state.siteId = vm.siteId;
					vm.state.ticketId = vm.ticketId;
					vm.state.message = vm.formModel.answerText;
					vm.state.attachments = vm.data.getUploadedFiles && vm.data.getUploadedFiles();
					vm.additionalInfo = {
						action: appI18n.translateImmediate('signature.action.answerDcr'),
						infoKey1: appI18n.translateImmediate('signature.info.site'),
						infoValue1: vm.ticket && vm.ticket.sponsorSiteId,
						infoKey2: appI18n.translateImmediate('signature.info.subject'),
						infoValue2: vm.ticket && vm.ticket.subjectId1
					};

				}
			}
		}

		function nextStep() {
			var wz = wizard();
			if (!wz) {
				return;
			}

			var currentIndex = wz.currentStepNumber();
			switch (currentIndex) {
				case 1:
					vm.form.answerText.$setSubmitted();
					if (validateAnswerLength(vm.formModel.answerText)) {
						preSign(wz, currentIndex);
					}
					break;
				case 2:
					if (vm.isFilesUploaded(true)) {
						preSign(wz, currentIndex);
					}
					break;
				default:
					wizard().next();
					break;
			}
		}

		function preSign(wizard, currentIndex) {
			const isLastStep = currentIndex === wizard.totalStepCount();

			if (!isLastStep) {
				wizard.next();
				return;
			}
			updateActions();
			signatureService.sign(vm.sigType, vm.additionalInfo, vm.state);
		}

		function previousStep() {
			var currentIndex = wizard().currentStepNumber();
			if (currentIndex > 0) {
				wizard().previous();
			}
		}

		function validateAnswerLength(value) {
        return value && value.length <= vm.maxAnswerLength;
		}

		function clear() {
			var wz = wizard();
			if (wz) {
				wz.reset();
			}

			vm.formModel = {};

			if (vm.formOptions) {
				vm.formOptions.resetModel();
			}


			vm.ticket = null;
			vm.demographics = null;
			vm.actionLogs = null;
			vm.siteName = null;
			vm.siteId = null;
			vm.siteContact = null;
			vm.signature = {};
			vm.errorMsg = '';
			vm.isSignatureValid = false;
		}


		function submit() {
			proceedAction.isDisabled = true;

			const ticket = vm.state;
			ticket.signKey = $stateParams.signKey;
			ticket.signToken = $stateParams.tokenId;

			if (!signatureService.validateSignature(ticket.signToken, ticket.signKey)) {
				vm.resetState();
				return;
			}

			dataManagementService.answerTicket(vm.ticketId, ticket)
				.then(function (response) {
					if (response.data && response.data.isValidationError) {
						vm.errorMsg = appI18n.translateImmediate(response.data.translationKey);
						notificationService.showError(vm.errorMsg);
					} else {
						closeAnswerDcrRequest();
						notificationService.showSuccess(appI18n.translateImmediate('data-management.new.save-success'));
						dataManagementService.refreshTicketsGrid();
					}
				}, function (response) {
					if (response.status === 400 && response.data.message) {
						vm.errorMsg = appI18n.translateImmediate(response.data.message);
					} else {
						$log.error('error creating ticket with error: {data}, status: {status}',
											 {data: response.data, status: response.status});
						vm.errorMsg = 'An error occurred saving your answer. Please try again!';
					}

				}).finally(() => {
					vm.resetState();

				});
		}

		function resetState() {
			proceedAction.isDisabled = false;
			vm.actions[0].isDisabled = false;
			vm.isSignatureValid = false;
			vm.isAutoSubmit = false;
			loadingIndicatorService.hide();

		}
		function verifySignature() {
			if (!vm.signature.email || !vm.signature.password || !vm.signature.agreecheck) {
				appI18n.translate("signature.incomplete-error").then(function (translation) {
					vm.errorMsg = translation;
				});

				vm.signStatus = false;
			}
			else {
				var user = appSession.get();

				if (user.email.toLowerCase() !== vm.signature.email.toLowerCase()) {
					appI18n.translate("signature.email-mismatch-error").then(function (translation) {
						vm.errorMsg = translation;
					});

					vm.signStatus = false;
				}
				else {
					vm.signStatus = true;
					vm.errorMsg = "";
				}
			}

			return vm.signStatus;
		}

		function canClose() {
			return true;
		}

		function showAnswerDcr(ticketId) {
			vm.answerDcrActive = true;
			vm.unsub = $scope.$on("wizard:stepChanged", onStepChanged);
			clear();
			vm.ticketId = ticketId;
			loadTicketData(ticketId);
		}

		function loadTicketData(ticketId) {
			vm.isLoading = true;
			vm.errorMsg = "";

			dataManagementService.getTicketData(ticketId)
				.then(function (response) {
					var data = response.data[0];
					vm.formModel.queryText = data.queryIncident.queryMessage;
					vm.ticket = data.queryIncident;
					if (!$stateParams.signKey){
						vm.data.attachments = data.attachments || [];
					}

					vm.data.agreement = vm.data.attachments.length > 0 ? true : false;
					vm.demographics = data.subjectDemographics;
					vm.eventInformationList = data.eventInformationList;
					vm.actionLogs = data.listOfQueryActionLogs;
					vm.siteName = data.siteName;
					vm.siteId = data.siteId;
					vm.siteContactHeader = vm.getTitleForSiteContact(vm.siteId);
					vm.siteContact = data.siteContact;
					vm.isLoading = false;
				}, function () {
					vm.errorMsg = "Error opening ticket";
					vm.isLoading = false;
				}).finally(() => {
					if (!vm.isAutoSubmit) {
						loadingIndicatorService.hide();
					}
				});
	}

		function closeAnswerDcrRequest() {
			if (canClose()) {
				if (vm.unsub) {
					vm.unsub();
				}
				vm.answerDcrActive = false;
				clear();
			}
		}

		function isResponseEntered() {
			const result = !!vm.formModel.answerText && vm.formModel.answerText.length > 0;
			return result;
		}

		function isFilesUploaded(showError) {
			var valid = vm.data.isValid && vm.data.isValid(showError);
			return !!valid;
		}

		function getTitleForSiteContact(siteId) {
			return `${appI18n.translateImmediate('data-management.columns.site')}: ${siteId}`;
		}
	}
})();
