angular.module('portalApp').directive('ertStepsWizard', ['$log', 'ertStepsWizardService', function ErtStepsWizard($log, ertStepsWizardService) {
	'use strict';

	return {
		restrict: 'AE',
		transclude: true,
		scope: true,
		templateUrl: 'scripts/directives/ertWizard/ertStepsWizard.html',
		controllerAs: "stepWizardCtrl",

		controller: /* @ngInject */ function ($scope) {

			//private things
			var currentStepIdx = 0;
			var currentStep = {};

			this.isCurrentStepValid = function() {
				var isValid = currentStep.scope.wizardStep.isValid;
				isValid = isValid === null || isValid();
				return isValid;
			}

			var canNavigateToStep = function (stepIndex) {
				var isValid = currentStep.scope.wizardStep.isValid;
				isValid = isValid === null || isValid();
				if (isValid) {
					isValid = ertStepsWizardService.canNavigateForwardToStep(currentStepIdx, stepIndex);
				}
				return isValid;
			};

			var isCurrentStep = function (index) {
				return index === currentStepIdx;
			};

			var selectStep = function (index) {
				$scope.$broadcast(`leaveStep${currentStepIdx}`, null);
				$scope.$broadcast('beforeNavigateToStep'+index, null);

				currentStepIdx = index;
				currentStep = $scope.steps[index];
				for (var x = 0; x < $scope.steps.length; x++) {
					$scope.steps[x].scope.current = (currentStepIdx === x);
				}
				onWizardStepChanged(index);

				$scope.$broadcast('navigateToStep' + index, null);
			};

			var onWizardStepChanged = function (index) {
				ertStepsWizardService.onWizardStepChanged(index);
			};

			// scope interaction
			$scope.steps = [];

			$scope.getStepCssClass = function (step, index) {
				var c = '';
				if (step.isCompleted) {
					c = '-es-visited';
				}
				if (isCurrentStep(index)) {
					c = '-es-active';
				}
				return c;

			};

			$scope.onStepClicked = function (step, index) {

				var isNext = index > currentStepIdx;
				if (isNext && !canNavigateToStep(index)) return;

				selectStep(index);
			};

			this.registerStep = function (label, scope) {
				if ($scope.steps.length === 0) {
					scope.current = true;
					currentStep = {label: label, isCompleted: false, scope: scope};
					$scope.steps.push(currentStep);
				} else {
					scope.current = false;
					$scope.steps.push({label: label, isCompleted: false, scope: scope});
				}
				onWizardStepChanged(0);
			};


			this.nextStep = function () {
				var nextStepIdx = currentStepIdx + 1;
				if (canNavigateToStep(nextStepIdx)) {
					if ($scope.steps.length <= nextStepIdx) {
						return;
					}
					$scope.onStepClicked($scope.steps[nextStepIdx], nextStepIdx);
				}
			};

			this.previousStep = function () {
				var prevStepIdx = currentStepIdx - 1;
				if (prevStepIdx < 0) {
					return;
				}
				$scope.onStepClicked($scope.steps[prevStepIdx], prevStepIdx);
			};

			this.goTo = (index) => {
				const isNext = index > currentStepIdx;
				const step = $scope.steps[index];

				selectStep(index);
			};

			this.isLastStep = function () {
				return $scope.steps.length === currentStepIdx + 1;

			};

			ertStepsWizardService.registerWizard(this);

		},
		link: function (scope, element, attr) {
			$log.debug("Directive::ertStepsWizard linked");
			element.on('$destroy', function() {
				$log.debug("Directive::ertStepsWizard destroy");
				ertStepsWizardService.reset(this);
			});
		}
	}
}]);
