var base64 = require('Base64');

(function () {
	'use strict';

	angular.module('portalApp').controller('LoginRedirectCtrl', LoginRedirectCtrl);

	/**
	 * Controller to handle login view
	 *
	 * @ngInject
	 */
	function LoginRedirectCtrl($log, $state, $rootScope, $window,appConfig) {

		$log = $log.getInstance('LoginRedirectCtrl', 'color:blue;');
		$log.debug('loaded');
		var vm = this;

		active();

		function active() {
			redirectOpenAm();
		}

		function redirectOpenAm() {
			let returnUrl = '';
			let studyId = '';
			let studyModeId = '';

			if ($rootScope.returnState && $rootScope.returnParams && $rootScope.returnParams.study) {
				returnUrl = $state.href($rootScope.returnState, $rootScope.returnParams, { absolute: true });
				studyId = $rootScope.returnParams.study;
				studyModeId = $rootScope.returnParams.mode;
			}
			const [authUrl, gssoLoginUrl, redirectUri, clientId, responseType, scope, nonce] = getAuthConfigurations();
			let state = '';
			let url = '';
			let gssoAuthUrl = '';

			if (studyId) {
				state = encodeURIComponent(getGssoState(studyId, studyModeId, returnUrl));
				url = `${authUrl}?redirect_uri=${encodeURIComponent(redirectUri)}&client_id=${clientId}&response_type=${responseType}&scope=${scope}&nonce=${nonce}&state=${state}`;
			} else {
				url = gssoLoginUrl;
			}

			$log.debug(`global login url: ${url}`);

			$window.location = url;
		}

		function getAuthConfigurations() {
			var authUrl = appConfig.auth.tokenUrl;
			var gssoLoginUrl = appConfig.auth.gssoLoginUrl;
			var redirectUri = appConfig.auth.redirectUri;
			var clientId = appConfig.auth.clientId;
			var responseType = appConfig.auth.responseType;
			var scope = appConfig.auth.scope;
			var nonce = appConfig.auth.nonce;

			return [authUrl, gssoLoginUrl, redirectUri, clientId, responseType, scope, nonce];
		}

		function getGssoState(studyId, studyMode, returnUrl) {
			var result = {
				study: {
					studyId: studyId || ''
				},
				studyMode: studyMode || '',
				internalUid: '',
				destinationUrl: returnUrl || ''
			};
			$log.debug(result);
			return base64.btoa(angular.toJson(result));
		}

	}

})();
