/*
 * tabModule
 * */

import tabComponent from './tab.component';

const tabModule = angular.module('app.common.tabs.tab', [])
	  .component('tab', tabComponent)
	  .name;

export default tabModule;
