/**
 * Created by AMorbia on 12/22/2015.
 */
(function() {

	'use strict';
	angular
		.module("portalApp")
		.service("sqfTranslation", SqfTranslation);

	/**
	 * @ngInject
	 */
	function SqfTranslation($log, $q, appConfig, appI18n) {
		$log = $log.getInstance("SqfTranslation");
		$log.debug("SqfTranslation loaded");

		var listViewLocalized = {
			lang: 'unknown',
			gridColumns: {},
			list: {}
		};

		var wizardLocalized = {
			lang: 'unknown',
			buttons: {},
			messages: {},
			wizardTitles: {},
			siteInfo: {},
			contact: {},
			additionalSiteInfo:{},
			options:{},
			common:{},
			roles:{}
		};

		var appButtons = [
			"app.buttons.back",
			"app.buttons.next",
			"app.buttons.previous",
			"app.buttons.save",
			"app.buttons.close",
			"app.buttons.edit",
			"app.buttons.cancel"
		];

		var gridColumns = [
			"sqf.gridColumns.siteId",
			"sqf.gridColumns.principalInvestigator",
			"sqf.gridColumns.status",
			"sqf.gridColumns.created",
			"sqf.gridColumns.modified",
			"sqf.gridColumns.submitted"
		];

		var list = [
			"sqf.list.newSqf",
			"sqf.list.savedSqf",
			"sqf.list.submittedSqf",
			"sqf.list.sqfWizard",

			"app.buttons.delete",
			"app.buttons.edit",
			"app.buttons.view"
		];

		var sqfButtons = [
			"sqf.buttons.saveContact",
			"sqf.buttons.submitSqf",
			"sqf.buttons.addContact",
			"sqf.buttons.editContact",
			"sqf.buttons.deleteContact",
			"sqf.buttons.editRoleAssignment",
			"sqf.buttons.saveClose"
		];

		var messages = [
			"sqf.messages.deleteContact",
			"sqf.messages.unsavedChanges",
			"sqf.messages.singleContactRole",
			"sqf.messages.atLeastOneContactRole",
			"sqf.messages.noRoleContact",
			"sqf.messages.emailMatch",
			"sqf.messages.reportingEmailMatch",
			"sqf.messages.reportingEmailSameAsEmail",
			"sqf.messages.considerAddingYourself",
			"sqf.messages.contactNumberField",
			"sqf.messages.contactNumberFieldMinLength",
			"sqf.messages.defaultSiteAddressInContact",
			"sqf.messages.equipmentNeededDateInFuture",
			"sqf.messages.siteAddressHint",
			"app.messages.usaZipCodeField",
			"app.messages.maxField",
			"app.messages.minField",
			"app.messages.maxLength",
			"app.messages.restrictedInputPatternField"
		];

		var wizardTitles = [
			"sqf.list.sqfWizard",
			"sqf.wizardTitles.help",
			"sqf.wizardTitles.siteInfo",
			"sqf.wizardTitles.contacts",
			"sqf.wizardTitles.roles",
			"sqf.wizardTitles.additionalSiteInfo",
			"sqf.wizardTitles.summary",
			"sqf.wizardTitles.contactInfo",
			"sqf.wizardTitles.contactAddress",
			"sqf.wizardTitles.contactSummary"
		];

		var siteInfo = [
			"sqf.siteInfo.siteIdQuestion",
			"sqf.siteInfo.siteIdDescription",
			"sqf.siteInfo.siteId",
			"sqf.siteInfo.tempSiteId",
			"sqf.siteInfo.language",
			"sqf.siteInfo.timezone",
			"sqf.siteInfo.institution",
			"sqf.siteInfo.siteType"
		];

		var contact = [
			"sqf.contact.contact",
			"sqf.contact.piInfo",
			"sqf.contact.piAddress",
			"sqf.contact.needPortalAccess",
			"sqf.contact.hasPortalAccess",
			"sqf.contact.email",
			"sqf.contact.repeatEmail",
			"sqf.contact.title",
			"sqf.contact.firstName",
			"sqf.contact.lastName",
			"sqf.contact.communicationChannel",
			"sqf.contact.phone",
			"sqf.contact.mobile",
			"sqf.contact.fax",
			"sqf.contact.portalAccessGranted",
			"sqf.contact.address1",
			"sqf.contact.address2",
			"sqf.contact.address3",
			"sqf.contact.country",
			"sqf.contact.city",
			"sqf.contact.state",
			"sqf.contact.postalCode",
			"sqf.contact.province",
			"sqf.contact.copyMyContact",
			"sqf.contact.copyMyContactTooltip",
			"sqf.contact.siteAddress",
			"sqf.contact.copySiteAddress",
			"sqf.contact.copySiteAddressTooltip",
			"sqf.contact.roles",
			"sqf.contact.address",
			"sqf.contact.communication"
		];

		var additionalSiteInfo=[
			"sqf.additionalSiteInfo.siteDeviceInfo",
			"sqf.additionalSiteInfo.siteDeviceQuestions.vendor",
			"sqf.additionalSiteInfo.siteDeviceQuestions.model",
			"sqf.additionalSiteInfo.siteDeviceQuestions.serialNumber",
			"sqf.additionalSiteInfo.siteDeviceQuestions.softwareVersion",
			"sqf.additionalSiteInfo.siteDeviceQuestions.fileType",
			"sqf.additionalSiteInfo.siteTransmissionInfo",
			"sqf.additionalSiteInfo.siteDialingInfoSummary",
			"sqf.additionalSiteInfo.hasAccessToAnalogLine",
			"sqf.additionalSiteInfo.hasOutsideAccessLineNumber",
			"sqf.additionalSiteInfo.outsideAccessLineNumber",
			"sqf.additionalSiteInfo.hasDialTone",
			"sqf.additionalSiteInfo.dialType",
			"sqf.additionalSiteInfo.canDialInternationalTollFree",
			"sqf.additionalSiteInfo.canDialInternationalNumber",
			"sqf.additionalSiteInfo.hasDataTransferRequirements",
			"sqf.additionalSiteInfo.dataTransferRequirements",
			"sqf.additionalSiteInfo.siteStartUpInformation",
			"sqf.additionalSiteInfo.equipmentNeededDate",
			"sqf.additionalSiteInfo.equipmentNeededDateSummary",
			"sqf.additionalSiteInfo.siteDetails",
			"sqf.additionalSiteInfo.siteDetailsSummary",
			"sqf.additionalSiteInfo.canRecordA4ECG"
		];

		var options = [
			"sqf.options.yes",
			"sqf.options.no",
			"sqf.options.individual",
			"sqf.options.institution",
			"sqf.options.pulse",
			"sqf.options.tone",
			"sqf.options.email",
			"sqf.options.fax"
		];

		var common = [
			"app.common.loading",
			"app.common.saving",
			"app.common.select"
		];

		var roles = [
			"sqf.roles.roleAssignment",
			"sqf.roles.roleSummary",
			"sqf.roles.pleaseSelectContact"
		];

		return {
			getWizardLocalized: getWizardLocalized,
			getListViewLocalized: getListViewLocalized
		};

		function getListViewLocalized() {
			var deferred = $q.defer();
			if (listViewLocalized.lang && listViewLocalized.lang === appI18n.getLanguage()) {
				deferred.resolve(listViewLocalized);
			}
			else {
				appI18n.translate(gridColumns.concat(list))
					.then(function (translations) {
						updateSqfLocalized(translations, listViewLocalized.gridColumns, gridColumns);
						updateSqfLocalized(translations, listViewLocalized.list, list);
						listViewLocalized.lang = appI18n.getLanguage();
						deferred.resolve(listViewLocalized);
					});
			}
			return deferred.promise;
		}

		function getWizardLocalized() {
			var deferred = $q.defer();
			if (listViewLocalized.lang && listViewLocalized.lang=== appI18n.getLanguage()) {
				deferred.resolve(wizardLocalized);
			}
			else {
				appI18n.translate(
					appButtons
						.concat(sqfButtons)
						.concat(messages)
						.concat(wizardTitles)
						.concat(siteInfo)
						.concat(contact)
						.concat(additionalSiteInfo)
						.concat(options)
						.concat(common)
						.concat(roles)
					)
					.then(function (translations) {
						updateSqfLocalized(translations, wizardLocalized.buttons, appButtons);
						updateSqfLocalized(translations, wizardLocalized.buttons, sqfButtons);
						updateSqfLocalized(translations, wizardLocalized.messages, messages);
						updateSqfLocalized(translations, wizardLocalized.wizardTitles, wizardTitles);
						updateSqfLocalized(translations, wizardLocalized.siteInfo, siteInfo);
						updateSqfLocalized(translations, wizardLocalized.contact, contact);
						updateSqfLocalized(translations, wizardLocalized.additionalSiteInfo, additionalSiteInfo);
						updateSqfLocalized(translations, wizardLocalized.options, options);
						updateSqfLocalized(translations, wizardLocalized.common, common);
						updateSqfLocalized(translations, wizardLocalized.roles, roles);

						wizardLocalized.messages.restrictedInputPatternFieldEscaped = wizardLocalized.messages.restrictedInputPatternField.replace("'","\\'");
						wizardLocalized.lang = appI18n.getLanguage();
						deferred.resolve(wizardLocalized);
					});
			}
			return deferred.promise;
		}

		function updateSqfLocalized(translations, dest, array) {
			array.forEach(function (translationId) {
				var name = translationId.split('.').pop();
				dest[name] = translations[translationId];
			});
		}
	}
})();
