const _ = require('lodash');

import baseRowController from '../../../../common/table/baseRow/baseRow.controller';

class SubjectsTableController extends baseRowController {
	constructor($log) {
		'ngInject';
		super();
		this.$log = $log;
	}

	$onInit() {
		this.$log = this.$log.getInstance('SubjectsTableController');
		this.$log.debug('loaded');
		this.registerRow(this.row);
	}

	hasGridRowActionGroup() {
		return this.gridRowActions && _.some(
			this.gridRowActions, (e) => {
				let hasSubAction = false;

				if (e.groupName) {
					if (e.enabledIfFieldName) {
						hasSubAction = this.row[e.enabledIfFieldName];
					} else if (e.enabledIf) {
						hasSubAction = e.enabledIf(this.row);
					} else {
						hasSubAction = true;
					}
				}

				return hasSubAction;
			}
		);
	}
}

export default SubjectsTableController;
