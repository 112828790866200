/*
 * openDcrTableModule
 * */

import openDcrTableDirective from './openDcrTable.directive';

const openDcrTableModule = angular.module('app.components.dashboard.openDcrWidget.openDcrTable', [])
	.directive('openDcrTable', openDcrTableDirective)
	.name;

export default openDcrTableModule;
