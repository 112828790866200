/*
 * userRolesModule
 * */

import userRolesComponent from './userRoles.component';

const userRolesModule = angular.module('app.components.studyPermissions.userRoles', [])
	.component('userRoles', userRolesComponent)
	.name;

export default userRolesModule;
