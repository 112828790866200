(function() {

	'use strict';

	angular
		.module('portalApp')
		.controller('StudyTrackingCtrl', StudyTrackingController);

	/**
	 * @ngInject
	 */
	function StudyTrackingController(adminService,  $filter, $log, $scope, appI18n) {

		$log = $log.getInstance("StudyTrackingController");
		$log.debug("loaded");

		var vm = this;
		var today = new Date();
		var fromDate = new Date(today).setDate(today.getDate()-7);

		vm.studyTrackingOptions = {
			rowDefs: {
			},
			gridRowActions: [],

			sortOrders: [{
				fieldName: 'logDateTime',
				reverseOrder: true
			}],

			preSelectedFilters: [{
				fieldName: 'logDateTime',
				displayName: 'Date/Time (UTC)',
				filterConditions: [
					{
					 displayValue: $filter('date')(fromDate, 'dd-MMM-yyyy'),
					 filterValue: $filter('date')(fromDate, 'yyyy-MM-dd'),
					 operator: 'ge',
					 type: 'date'
					},
					{
					 displayValue: $filter('date')(today, 'dd-MMM-yyyy'),
					 filterValue: $filter('date')(today, 'yyyy-MM-dd'),
					 operator: 'le',
					 type: 'date'
					}
				]
			}],

			getData: getStudyTracking
		};

		function getStudyTracking(options) {
			return adminService.getStudyTracking(options);
		}

		function activate() {
			appI18n.translate( [
				"admin.study-tracking-title",
				"admin.study-tracking.study",
				"admin.study-tracking.site",
				"admin.study-tracking.action",
				"admin.study-tracking.action-by",
				"admin.study-tracking.old-study",
				"admin.study-tracking.new-study",
				"admin.study-tracking.datetime"
			])
			.then(function (translations) {
				adminService.setTitle(translations["admin.study-tracking-title"]);
				vm.studyTitle = translations["admin.study-tracking.study"];
				vm.siteTitle = translations["admin.study-tracking.site"];
				vm.actionTitle = translations["admin.study-tracking.action"];
				vm.actionByTitle = translations["admin.study-tracking.action-by"];
				vm.oldStudyTitle = translations["admin.study-tracking.old-study"];
				vm.newStudyTitle = translations["admin.study-tracking.new-study"];
				vm.dateTimeTitle = translations["admin.study-tracking.datetime"];

				vm.studyTrackingOptions.columnDefs =  [
					{displayName: vm.studyTitle, fieldName: 'studyName', isSortable: true, type: 'string'},
					{displayName: vm.siteTitle, fieldName: 'investigatorSite', isSortable: true, type: 'string', isDefaultFilter: true},
					{displayName: vm.actionTitle, fieldName: 'action', isSortable: true, type: 'enum',
						enumType: 'string',
						enums: [
							{displayName: 'Grant Access', values:['Grant Access']},
							{displayName: 'Changed Name', values:['Changed Name']},
							{displayName: 'Revoke Access', values:['Revoke Access']},
							{displayName: 'Protocol Account Enabled', values:['Protocol Account Enabled']},
							{displayName: 'Protocol Account Disabled', values:['Protocol Account Disabled']}
						]
					},
					{displayName: vm.actionByTitle, fieldName: 'actionBy', isSortable: true, type: 'string'},
					{displayName: vm.oldStudyTitle, fieldName: 'oldStudyStudyRole', isSortable: true, type: 'string'},
					{displayName: vm.newStudyTitle, fieldName: 'newStudyAssigneeEmail', isSortable: true, type: 'string'},
					{displayName: vm.dateTimeTitle, fieldName: 'logDateTime', isSortable: true, type: 'date', formatter: 'dateTimeFormat'}
				];
			});

			vm.load = true;
		}

		activate();
	}

})();
