/*
 * pageController
 * */

class PageController {
	constructor($log, $state) {
		'ngInject';
		this.$log = $log;
		this.$state = $state;
	}

	$onInit() {
		this.$log = this.$log.getInstance('PageController');
		this.$log.debug('loaded');
		this.buttons = this.getActions(this.actions);
		this.route = this.$state.current.name;
	}

	$onChanges(changesObj) {
		if (changesObj.actions && !changesObj.actions.isFirstChange()) {
			this.buttons = this.getActions(changesObj.actions.currentValue);
		}
	}

	$doCheck() {
		if (this.route !== this.$state.current.name) {
			this.route = this.$state.current.name;
			this.buttons = this.getActions(this.actions);
		} else if (this.actions && this.actions.updated) {
			this.buttons = this.getActions(this.actions);
			this.actions.updated = false;
		}
	}

	getActions(actions) {

		if (!actions) {
			return [];
		}

		return _.filter(actions, (item) => {
			if (item.visibleIn) {
				const index = item.visibleIn.indexOf(this.$state.current.name);

				if (index !== -1) {
					if (item.hidden) {
						return false;
					}
					return true;
				}
			} else {
				// return item, if visibleIn is not set
				return true;
			}
			return false;
		});
	}
}

export default PageController;
