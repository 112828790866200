/*
 * studyMetricsWidgetModule
 * */
import studyMetricsDetailsPopupModule from './studyMetricsDetailsPopup/index';
import studyMetricsWidgetComponent from './studyMetricsWidget.component';

const studyMetricsWidgetModule = angular.module('app.components.dashboard.studyMetricsWidget', [
	studyMetricsDetailsPopupModule
]).component('studyMetricsWidget', studyMetricsWidgetComponent).name;

export default studyMetricsWidgetModule;
