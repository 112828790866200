var BrowserDetect = require('./browserDetect');

(function () {
    'use strict';

    angular.module('portalApp').run(BrowserDetection);
    /**
     * @ngInject
     */
    function BrowserDetection($log, $rootScope) {
        $log = $log.getInstance('BrowserDetection');

        var browserDetectString = BrowserDetect.browserName + ' ' + BrowserDetect.browserVersion + ', ' + BrowserDetect.operatingSystemName;
        $rootScope.browserDetectString = browserDetectString;

        $log.info('Detected browser ' + $rootScope.browserDetectString);
    }

})();