/*
 * widgetController
 * */

class WidgetController {
	constructor() {
		'ngInject';
		this.collapsed = false;
	}

	collapse() {
		this.collapsed = !this.collapsed;
	}
}

export default WidgetController;
