(function() {

	'use strict';
	angular
		.module('portalApp')
		.controller('EcgDataExportCtrl', EcgDataExportController);

	/**
	 * @ngInject
	 */
	function EcgDataExportController($scope, reportsService, $log, clinicalDataService,
									 loadingIndicatorService,
									 $filter, appI18n, configurationService, $q, appConfig) {

		$log = $log.getInstance("EcgDataExportCtrl", "color:blue");
		$log.debug("loaded");

		var vm = this;

		vm.ecgDataExportOptions = {
			rowDefs: {
				reportCellSize: 'xs'
			},
			gridRowActions: [
			],

			getData: getEcgDataExport

		};

		function getEcgDataExport(options) {
				return reportsService.getEcgDataExport(options);
		}

		function getDemographicSettings() {
			configurationService.getSubjectDemographicSettings()
			.then(function(response){
				if (response.data.isInitialsHidden) {
					vm.ecgDataExportOptions.columnDefsStatic[getIndex('initials')].isHidden = true;
				}
				if (response.data.isDateOfBirthHidden) {
					vm.ecgDataExportOptions.columnDefsStatic[getIndex('dateOfBirth')].isHidden = true;
				}
				if (response.data.isSubjectId1Hidden) {
					vm.ecgDataExportOptions.columnDefsStatic[getIndex('patientId1')].isHidden = true;
				}
				if (response.data.isEthnicityHidden) {
					vm.ecgDataExportOptions.columnDefsStatic[getIndex('ethnicity')].isHidden = true;
				}
				if (response.data.isGenderHidden) {
					vm.ecgDataExportOptions.columnDefsStatic[getIndex('gender')].isHidden = true;
				}
				if (response.data.fieldLabels[appConfig.demographics.initials]) {
					vm.ecgDataExportOptions.columnDefsStatic[getIndex('initials')].displayName =
						response.data.fieldLabels[appConfig.demographics.initials];
				}
				if (response.data.fieldLabels[appConfig.demographics.dob]) {
					vm.ecgDataExportOptions.columnDefsStatic[getIndex('dateOfBirth')].displayName =
						response.data.fieldLabels[appConfig.demographics.dob];
				}
				if (response.data.fieldLabels[appConfig.demographics.subjectId1]) {
					vm.ecgDataExportOptions.columnDefsStatic[getIndex('patientId1')].displayName =
						response.data.fieldLabels[appConfig.demographics.subjectId1];
				}
				if (response.data.fieldLabels[appConfig.demographics.ethnicity]) {
					vm.ecgDataExportOptions.columnDefsStatic[getIndex('ethnicity')].displayName =
						response.data.fieldLabels[appConfig.demographics.ethnicity];
				}
				if (response.data.fieldLabels[appConfig.demographics.gender]) {
					vm.ecgDataExportOptions.columnDefsStatic[getIndex('gender')].displayName =
						response.data.fieldLabels[appConfig.demographics.gender];
				}
				vm.ecgDataExportOptions.columnDefs = vm.ecgDataExportOptions.columnDefsStatic;
				vm.load = true;
			}, (error) => {
				$log.debug(`error loading subject demographics: ${error}`);
			}).finally(() => {
				loadingIndicatorService.hide();
			});
		}

		function getIndex(name){
			var item = $filter('filter')(vm.ecgDataExportOptions.columnDefsStatic, {fieldName: name}, false);
			return vm.ecgDataExportOptions.columnDefsStatic.indexOf(item[0]);
		}

		function activate() {
			loadingIndicatorService.show();
			//Get configuration
			var defers = $q.all([
				configurationService.getStudySettings(),
				reportsService.getPermission()

			]);
			defers.then(function(resolves) {
				if (resolves[0].hasEcg && resolves[1].data) {
					appI18n.translate( [
					"reports.ecg-export.main-title",
					"reports.ecg-export.investigator",
					"reports.ecg-export.site",
					"reports.ecg-export.patient",
					"reports.ecg-export.dob",
					"reports.ecg-export.init",
					"reports.ecg-export.gndr",
					"reports.ecg-export.race",
					"reports.ecg-export.visit",
					"reports.ecg-export.collection-date",
					"reports.ecg-export.complete-date",
					"reports.ecg-export.transmittal",
					"reports.ecg-export.overall-evaluation",
					"reports.ecg-export.alerts",
					"reports.ecg-export.qt",
					"reports.ecg-export.qt-change",
					"reports.ecg-export.qtcb",
					"reports.ecg-export.qtcb-change",
					"reports.ecg-export.qtcf",
					"reports.ecg-export.qtcf-change",
					"reports.ecg-export.ectopy",
					"reports.ecg-export.conduction",
					"reports.ecg-export.morphology",
					"reports.ecg-export.myocardial",
					"reports.ecg-export.rhythm",
					"reports.ecg-export.st",
					"reports.ecg-export.twaves",
					"reports.ecg-export.uwaves",
					"reports.ecg-export.axis",
					"reports.ecg-export.machine",
					"reports.ecg-export.clinical",
					"reports.ecg-export.protocol",
					"reports.ecg-export.exclusion"
					])
					.then(function (translations) {

						reportsService.setTitle(translations["reports.ecg-export.main-title"]);
						vm.investigatorTitle = translations["reports.ecg-export.investigator"];
						vm.siteTitle = translations["reports.ecg-export.site"];
						vm.patientTitle = translations["reports.ecg-export.patient"];
						vm.dobTitle = translations["reports.ecg-export.dob"];
						vm.initTitle = translations["reports.ecg-export.init"];
						vm.gndrTitle = translations["reports.ecg-export.gndr"];
						vm.raceTitle = translations["reports.ecg-export.race"];
						vm.visitTitle = translations["reports.ecg-export.visit"];
						vm.collectionTitle = translations["reports.ecg-export.collection-date"];
						vm.completeTitle = translations["reports.ecg-export.complete-date"];
						vm.transmittalTitle = translations["reports.ecg-export.transmittal"];
						vm.overallTitle = translations["reports.ecg-export.overall-evaluation"];
						vm.alertTitle = translations["reports.ecg-export.alerts"];
						vm.qtTitle = translations["reports.ecg-export.qt"];
						vm.qtChangeTitle = translations["reports.ecg-export.qt-change"];
						vm.qtcbTitle = translations["reports.ecg-export.qtcb"];
						vm.qtcbChangeTitle = translations["reports.ecg-export.qtcb-change"];
						vm.qtcfTitle = translations["reports.ecg-export.qtcf"];
						vm.qtcfChangeTitle = translations["reports.ecg-export.qtcf-change"];
						vm.ectopyTitle = translations["reports.ecg-export.ectopy"];
						vm.conductionTitle = translations["reports.ecg-export.conduction"];
						vm.morphologyTitle = translations["reports.ecg-export.morphology"];
						vm.myocardialTitle = translations["reports.ecg-export.myocardial"];
						vm.rhythmTitle = translations["reports.ecg-export.rhythm"];
						vm.stTitle = translations["reports.ecg-export.st"];
						vm.twavesTitle = translations["reports.ecg-export.twaves"];
						vm.uwavesTitle = translations["reports.ecg-export.uwaves"];
						vm.axisTitle = translations["reports.ecg-export.axis"];
						vm.machineTitle = translations["reports.ecg-export.machine"];
						vm.clinicalTitle = translations["reports.ecg-export.clinical"];
						vm.protocolTitle = translations["reports.ecg-export.protocol"];
						vm.exclusionTitle = translations["reports.ecg-export.exclusion"];

						vm.ecgDataExportOptions.columnDefsStatic =  [
							{displayName: vm.investigatorTitle, fieldName: 'siteName', isSortable: true, type: 'string'},
							{displayName: vm.siteTitle, fieldName: 'sponsorSiteId', isSortable: true, type: 'string'},
							{translationKey: 'app.common.subjectKey', fieldName: 'subjectKey', type: 'string', isDefaultFilter: true},
							{displayName: vm.patientTitle, fieldName: 'patientId1', isSortable: true, type: 'string'},
							{displayName: vm.dobTitle, fieldName: 'dateOfBirth', type: 'date', isSortable: true, formatter: 'dateFormat'},
							{displayName: vm.initTitle, fieldName: 'initials', isSortable: true, type: 'string'},
							{displayName: vm.gndrTitle, fieldName: 'gender', isSortable: true,
								type: 'enum',
								enumType: 'string',
								enums: [
									{displayName: 'Male', values: ['Male']},
									{displayName: 'Female', values: ['Female']},
									{displayName: 'Undifferentiated', values: ['Undifferentiated']}
								]
							},
							{displayName: vm.raceTitle, fieldName: 'ethnicity', isSortable: true, type: 'string'},
							{displayName: vm.visitTitle, fieldName: 'visitName', isSortable: true, type: 'string'},
							{displayName: vm.collectionTitle, fieldName: 'collectionDateTime', isSortable: true, type: 'date', formatter: 'dateTimeFormat'},
							{displayName: vm.completeTitle, fieldName: 'completeDateTime', isSortable: true, type: 'date', formatter: 'dateTimeFormat'},
							{displayName: vm.transmittalTitle, fieldName: 'transmittalNumber', isSortable: true, type: 'number'},
							{displayName: vm.overallTitle, fieldName: 'overallInterpretation', isSortable: true,
								type: 'enum',
								enumType: 'string',
								enums: [
									{displayName: 'All', values: ['Normal', 'Abnormal', 'Unable to Evaluate']},
									{displayName: 'Normal', values: ['Normal']},
									{displayName: 'Abnormal', values: ['Abnormal']},
									{displayName: 'Unable to Evaluate', values: ['Unable to Evaluate']}
								]
							},
							{displayName: vm.alertTitle, fieldName: 'alerts', isSortable: true, formatter: 'yesNoEmptyIfNull',
								type: 'enum',
								enumType: 'number',
								enums: [
									{displayName: 'Yes', operator: 'ne', values:[0]},
									{displayName: 'No', operator: 'eq', values:[0]}
								]
							},
							{displayName: vm.qtTitle, fieldName: 'qt', type: 'number', showOnlyInFilter: true},
							{displayName: vm.qtChangeTitle, fieldName: 'qtChange', type: 'number', showOnlyInFilter: true},
							{displayName: vm.qtcbTitle, fieldName: 'qtcb', type: 'number', showOnlyInFilter: true},
							{displayName: vm.qtcbChangeTitle, fieldName: 'qtcbChange', type: 'number', showOnlyInFilter: true},
							{displayName: vm.qtcfTitle, fieldName: 'qtcf', type: 'number', showOnlyInFilter: true},
							{displayName: vm.qtcfChangeTitle, fieldName: 'qtcfChange', type: 'number', showOnlyInFilter: true},
							{displayName: vm.ectopyTitle, fieldName: 'ectopy', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'null',
								enums: [
									{displayName: 'Yes', operator: 'ne', values: ['null']},
									{displayName: 'No', operator: 'eq', values: ['null']}
								]
							},
							{displayName: vm.conductionTitle, fieldName: 'conduction', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'null',
								enums: [
									{displayName: 'Yes', operator: 'ne', values: ['null']},
									{displayName: 'No', operator: 'eq', values: ['null']}
								]
							},
							{displayName: vm.morphologyTitle, fieldName: 'morphology', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'null',
								enums: [
									{displayName: 'Yes', operator: 'ne', values: ['null']},
									{displayName: 'No', operator: 'eq', values: ['null']}
								]
							},
							{displayName: vm.myocardialTitle, fieldName: 'myocardialInfarct', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'null',
								enums: [
									{displayName: 'Yes', operator: 'ne', values: ['null']},
									{displayName: 'No', operator: 'eq', values: ['null']}
								]
							},
							{displayName: vm.rhythmTitle, fieldName: 'rhythm', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'null',
								enums: [
									{displayName: 'Yes', operator: 'ne', values: ['null']},
									{displayName: 'No', operator: 'eq', values: ['null']}
								]
							},
							{displayName: vm.stTitle, fieldName: 'stSegment', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'null',
								enums: [
									{displayName: 'Yes', operator: 'ne', values: ['null']},
									{displayName: 'No', operator: 'eq', values: ['null']}
								]
							},
							{displayName: vm.twavesTitle, fieldName: 'tWaves', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'null',
								enums: [
									{displayName: 'Yes', operator: 'ne', values: ['null']},
									{displayName: 'No', operator: 'eq', values: ['null']}
								]
							},
							{displayName: vm.uwavesTitle, fieldName: 'uWaves', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'null',
								enums: [
									{displayName: 'Yes', operator: 'ne', values: ['null']},
									{displayName: 'No', operator: 'eq', values: ['null']}
								]
							},
							{displayName: vm.axisTitle, fieldName: 'axis', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'null',
								enums: [
									{displayName: 'Yes', operator: 'ne', values: ['null']},
									{displayName: 'No', operator: 'eq', values: ['null']}
								]
							},
							{displayName: vm.machineTitle, fieldName: 'machineAlgorithm', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'null',
								enums: [
									{displayName: 'Yes', operator: 'ne', values: ['null']},
									{displayName: 'No', operator: 'eq', values: ['null']}
								]
							},
							{displayName: vm.clinicalTitle, fieldName: 'clinAlert', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'string',
								enums: [
									{displayName: 'Yes', values: ['Y']},
									{displayName: 'No', values: ['N']}
								]
							},
							{displayName: vm.protocolTitle, fieldName: 'protocolAlert', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'string',
								enums: [
									{displayName: 'Yes', values: ['Y']},
									{displayName: 'No', values: ['N']}
								]
							},
							{displayName: vm.exclusionTitle, fieldName: 'excAlert', showOnlyInFilter: true,
								type: 'enum',
								enumType: 'string',
								enums: [
									{displayName: 'Yes', values: ['Y']},
									{displayName: 'No', values: ['N']}
								]
							}
						];

						vm.ecgDataExportOptions.sortOrders = [
							{
								fieldName: 'siteName'
							},
							{
								fieldName: 'sponsorSiteId'
							},
							{
								fieldName: 'patientId1'
							},
							{
								fieldName: 'visitName'
							},
							{
								fieldName: 'collectionDateTime',
								reverseOrder: true
							}
						];

						getDemographicSettings();
						reportsService.setEcgExportOptions(vm.ecgDataExportOptions);
					});
				}
			});
		}

		activate();
	}

})();
