/*
 * BodyCssClassFactory
 * */
const bodyCssClassFactory = () => {

	// private variables
	let cssClassObserver;
	const observable = Rx.Observable.create((obsrv) => {
		cssClassObserver = obsrv;
	});

	const cssClass = observable.map((params) => {
		return params;
	});

	return {
		setClass(params = {}) {
			params.add = true;
			return cssClassObserver.onNext(params);
		},
		unsetClass(params = {}) {
			params.add = false;
			return cssClassObserver.onNext(params);
		},
		getBodyCssClassSubject() {
			return cssClass;
		}
	};
};

export default bodyCssClassFactory;
