/*
 * breadcrumbsDropdownClickDirective
 * */

const breadcrumbsDropdownClickDirective = ($document) => {
	return {
		restrict: 'A',
		link(scope, element) {
			const documentClickFn = (e) => {
				if (!element[0].contains(e.target) && element.hasClass('-es-open')) {
					element.removeClass('-es-open');
					$document.off('click', documentClickFn);
				}
			};

			element.on('click', () => {
				if (element.hasClass('-es-open')) {
					element.removeClass('-es-open');
					$document.off('click', documentClickFn);
				} else {
					element.addClass('-es-open');
					$document.on('click', documentClickFn);
				}
			});

			// clean up
			scope.$on('$destroy', () => {
				$document.off('click', documentClickFn);
			});
		}
	};
};

export default breadcrumbsDropdownClickDirective;
